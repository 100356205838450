import { border, color, layout, overflow, space } from 'styled-system';
import styled from '@marvelapp/styled';
export const Ratio = styled.div `
  ${space};
  ${color};
  ${overflow};
  ${border};
  ${layout};
  position: relative;
`;
export const Child = styled.div `
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;
