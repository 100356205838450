import { jsx as _jsx } from "react/jsx-runtime";
import { observer } from 'mobx-react';
import { createContext, useContext } from 'react';
import * as React from 'react';
const SlideUIContext = createContext({});
export const useSlideUIContext = () => {
    const context = useContext(SlideUIContext);
    if (!context) {
        throw new Error('useSlideUIContext must be used within a SlideUIContextProvider');
    }
    return context;
};
export function hasStep(context) {
    return context.step !== null;
}
export const SlideUIContextProvider = observer(function SlideUIContextProvider({ children, slideProps, }) {
    return (_jsx(SlideUIContext.Provider, { value: slideProps, children: children }));
});
