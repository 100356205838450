import { __rest } from "tslib";
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { CARET_ICON, SORT_ICON } from '@marvelapp/ballpark-theme';
import { Icon } from '@marvelapp/ui';
import { DropdownLink, DropdownTriggerElement, DropdownTriggerText, } from './styles';
export default function DropdownTrigger(_a) {
    var { children, hideOnMobile = false, isActive = false, isDisabled } = _a, props = __rest(_a, ["children", "hideOnMobile", "isActive", "isDisabled"]);
    const displayDesktop = hideOnMobile
        ? ['block', 'block', 'block', 'block', 'block', 'none']
        : 'inherit';
    const displayMobile = hideOnMobile
        ? ['none', 'none', 'none', 'none', 'none', 'block']
        : 'inherit';
    return (_jsx(DropdownTriggerElement, Object.assign({ fontSize: 14, hideOnMobile: hideOnMobile, isActive: isActive, isDisabled: isDisabled }, props, { children: _jsxs(DropdownTriggerText, { children: [_jsx(DropdownLink, { display: displayMobile, ml: [0, 0, 0], children: children }), _jsx(Icon, { display: displayMobile, paths: CARET_ICON }), hideOnMobile && _jsx(Icon, { display: displayDesktop, paths: SORT_ICON })] }) })));
}
