import { jsx as _jsx } from "react/jsx-runtime";
import { observer } from 'mobx-react';
import * as React from 'react';
import { Button as SmallButton } from '@marvelapp/ballpark-components';
import { CARET_ICON } from '@marvelapp/ballpark-theme';
import styled from '@marvelapp/styled';
import { Icon } from '@marvelapp/ui';
const ArrowButton = observer(function ArrowButton({ id, isDisabled, onClick, size = 's', direction }) {
    return (_jsx(SquareButton, { "data-testid": id, isDisabled: isDisabled, kind: "ghost", size: size, onClick: onClick, children: _jsx(Icon, { paths: CARET_ICON, style: direction === 'left'
                ? { marginRight: '2px', transform: 'rotate(90deg)' }
                : { marginLeft: '1px', transform: 'rotate(-90deg)' } }) }));
});
const LargerButton = styled(SmallButton) `
  height: 60px;
  width: 40px;
`;
const SquareButton = observer(function SquareButton(props) {
    const { size } = props;
    const Button = size === 's' ? SmallButton : LargerButton;
    return (_jsx(Button, Object.assign({ alignItems: "center", display: "flex", isSquare: true, justifyContent: "center", kind: "transparent", px: 0, size: "s" }, props)));
});
export default ArrowButton;
