import { IdentityOperator, MultipleSelectionMode, ScreenerUtils, } from '@marvelapp/user-test-creator';
export function getHelpText(intl, multipleSelectionMode, selectionRange) {
    if (!multipleSelectionMode)
        return intl.formatMessage({ id: "oYO9r7", defaultMessage: "Select 1 choice" });
    const [lowerRangeLimit, upperRangeLimit] = selectionRange;
    if (multipleSelectionMode === MultipleSelectionMode.Range) {
        if (upperRangeLimit && lowerRangeLimit) {
            return intl.formatMessage({ id: "30FXly", defaultMessage: "Select between {lowerRangeLimit} and {upperRangeLimit, plural, one {# choice} other {# choices} }" }, {
                lowerRangeLimit,
                upperRangeLimit,
            });
        }
        if (lowerRangeLimit) {
            return intl.formatMessage({ id: "ArUVnr", defaultMessage: "Select at least {lowerRangeLimit, plural, one {# choice} other {# choices} }" }, {
                lowerRangeLimit,
            });
        }
        if (upperRangeLimit) {
            return intl.formatMessage({ id: "kenQZh", defaultMessage: "Select up to {upperRangeLimit, plural, one {# choice} other {# choices} }" }, {
                upperRangeLimit,
            });
        }
    }
    if (multipleSelectionMode === MultipleSelectionMode.ExactNumber &&
        upperRangeLimit) {
        return intl.formatMessage({ id: "JVoA7k", defaultMessage: "Select {upperRangeLimit, plural, one {# choice} other {# choices} }" }, {
            upperRangeLimit,
        });
    }
    if (multipleSelectionMode === MultipleSelectionMode.Unlimited) {
        return intl.formatMessage({ id: "cxcahM", defaultMessage: "Choose as many as you like" });
    }
    return null;
}
export function getIsButtonDisabled(multipleSelectionMode, selectionRange, numberOfSelectedAnswers, extraCheck) {
    const [lowerRangeLimit, upperRangeLimit] = selectionRange;
    const hasNotMetMinRange = multipleSelectionMode === MultipleSelectionMode.Range &&
        lowerRangeLimit &&
        numberOfSelectedAnswers < lowerRangeLimit;
    const hasNotSelectedExactNumberOfOptions = multipleSelectionMode === MultipleSelectionMode.ExactNumber &&
        upperRangeLimit &&
        numberOfSelectedAnswers !== upperRangeLimit;
    return !!(extraCheck ||
        hasNotMetMinRange ||
        hasNotSelectedExactNumberOfOptions);
}
export function getScreenerChoiceTitle(step, currentChoiceUUID) {
    var _a;
    const title = (_a = step.choiceDefinitions[currentChoiceUUID]) !== null && _a !== void 0 ? _a : '';
    const choice = ScreenerUtils.getRequirement({
        mutable: step,
        choiceUUID: currentChoiceUUID,
    });
    if (!choice)
        return title;
    const { emoji } = screenerInfo[choice === null || choice === void 0 ? void 0 : choice.operator];
    return `${emoji} ${title}`;
}
export function getScreenerChoiceStatus(step, currentChoiceUUID) {
    const choice = ScreenerUtils.getRequirement({
        mutable: step,
        choiceUUID: currentChoiceUUID,
    });
    if (!choice)
        return null;
    return screenerInfo[choice === null || choice === void 0 ? void 0 : choice.operator].status;
}
const screenerInfo = {
    [IdentityOperator.Is]: { status: 'Accept', emoji: '✅' },
    [IdentityOperator.IsNot]: { status: 'Decline', emoji: '⛔️' },
    [IdentityOperator.IsOkay]: { status: 'Can Select', emoji: '✅' },
};
