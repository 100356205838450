import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import fuzzysort from 'fuzzysort';
import { observer } from 'mobx-react';
import { Fragment, forwardRef, startTransition, useEffect, useMemo, useRef, useState, } from 'react';
import { useAction } from '@marvelapp/ballpark-application';
import { Search, Stack, Tooltip, getIconForFilter, useComboboxState, useCommandState, } from '@marvelapp/ballpark-components';
import { useRecruitmentState } from '../RecruitmentContext';
export const FilterSearch = observer(forwardRef(function FilterSearch({ collisionContainerRef, }) {
    return (
    // disable in-built filtering because we provide our own search and filtering logic
    _jsx(Search.Root, { shouldFilter: false, children: _jsx(FilterSearchContent, { collisionContainerRef: collisionContainerRef }) }));
}));
const MIN_SEARCH_LENGTH = 2;
const FilterSearchContent = observer(forwardRef(function FilterSearchContent({ collisionContainerRef, }) {
    const inputContainerRef = useRef(null);
    const contentRef = useRef(null);
    const [search, setSearch] = useState('');
    const searchResults = useSearchFilter(search);
    const { onOpenChange } = useComboboxState();
    const onBlur = () => {
        onOpenChange(false);
    };
    const onFocus = () => onOpenChange(search.length >= MIN_SEARCH_LENGTH);
    const onValueChange = (value) => {
        setSearch(value);
        onOpenChange(value.length >= MIN_SEARCH_LENGTH);
    };
    const onPointerDownOutside = (event) => {
        var _a;
        // don't close the popover if the user is interacting with the input
        if ((_a = inputContainerRef.current) === null || _a === void 0 ? void 0 : _a.contains(document.activeElement))
            event.preventDefault();
    };
    useEffect(() => {
        // scroll the search results back to the top when the search results change
        setTimeout(() => { var _a; return (_a = contentRef.current) === null || _a === void 0 ? void 0 : _a.scrollTo({ top: 0, behavior: 'instant' }); }, 0);
    }, [searchResults]);
    return (_jsxs(_Fragment, { children: [_jsx(Stack, { ref: inputContainerRef, className: "p-2.5", children: _jsx(Search.Input, { value: search, onValueChange: onValueChange, onFocus: onFocus, onBlur: onBlur, "data-testid": "filter-search-input" }) }), _jsxs(Search.Content, { onPointerDownOutside: onPointerDownOutside, className: "max-h-[--radix-popover-content-available-height]", ref: contentRef, "data-testid": "filter-search-content", collisionBoundary: collisionContainerRef, collisionPadding: 10, avoidCollisions: true, children: [_jsx(Search.Empty, { children: "No matches found\u2026" }), search.length >= MIN_SEARCH_LENGTH && (_jsx(SearchResults, { results: searchResults, search: search }))] })] }));
}));
const SearchResults = observer(function SearchResults({ results, search, }) {
    const recruitmentState = useRecruitmentState();
    if (!results.size)
        return null;
    const { filterGroups: filterGroupMap } = recruitmentState.filters.other;
    const filterGroups = Object.values(filterGroupMap);
    // don't group if there is only one filter group
    const ignoreGroup = filterGroups.length === 1;
    return (_jsx(_Fragment, { children: Array.from(results.values()).map((group) => (_jsx(FilterSearchGroup, { group: group, search: search, ignoreGroup: ignoreGroup }, group.id))) }));
});
const FilterSearchGroup = observer(function FilterSearchGroup({ group, search, ignoreGroup, }) {
    var _a, _b, _c;
    const recruitmentState = useRecruitmentState();
    const { onOpenChange } = useComboboxState();
    const filters = Array.from(group.filters.values());
    const selectGroup = useAction((id) => {
        recruitmentState.updateCurrentlyOpenDetailedFilterGroup(id);
        onOpenChange(false);
    }, [recruitmentState, onOpenChange]);
    if (!filters.length)
        return (_jsx(Search.Group, { children: _jsx(Search.FilterGroupItem, { leadingIcon: getIconForFilter(group.id), filterTitle: (_b = (_a = group.result) === null || _a === void 0 ? void 0 : _a.highlight(highlighter)) !== null && _b !== void 0 ? _b : group.name, highlight: search, selected: false, value: group.id, onSelect: selectGroup, "data-testid": "filter-search-result-group", "data-groupid": group.id, "data-score": (_c = group.result) === null || _c === void 0 ? void 0 : _c.score, 
                // this pointer down is needed to make the onSelect work this is a side effect of:
                // https://github.com/marvelapp/mkiii/blob/ac4f2bd188ced64bc18d77d7099b783bff923423/src/packages/ballpark-components/src/Search.tsx#L92-L93
                // it is unsure why it causes this behaviour
                onPointerDown: (event) => {
                    event.preventDefault();
                } }) }));
    return (_jsx(Search.Group, { children: filters.map((filter) => (_jsx(FilterItem, { group: group, filter: filter, search: search, ignoreGroup: ignoreGroup, "data-testid": "filter-search-result-filter" }, filter.id))) }));
});
function highlighter(match, i) {
    return (_jsx("span", { className: "bg-amber-200", children: match }, i));
}
const FilterItem = observer(function FilterItem({ filter, group, search, ignoreGroup, }) {
    var _a, _b, _c, _d, _e, _f, _g;
    const selectedValue = useCommandState((state) => state.value);
    const recruitmentState = useRecruitmentState();
    const { onOpenChange } = useComboboxState();
    const { getSelectedOptionsByFilterAndGroupId, updateMultiSelectFilterSelection, } = recruitmentState;
    const selectedOptions = getSelectedOptionsByFilterAndGroupId(filter.id, group.id);
    const targetFilter = recruitmentState.filters.other.filterGroups[group.id].filters[filter.id];
    const selectFilter = useAction((id) => {
        recruitmentState.updateCurrentlyOpenDetailedFilterGroup(group.id);
        recruitmentState.currentlyOpenDetailedFilter = id;
        onOpenChange(false);
    }, [recruitmentState, group.id, onOpenChange]);
    const toggleOption = useAction((id) => {
        const option = targetFilter.options.find((o) => o.id === id);
        if (!option)
            return;
        updateMultiSelectFilterSelection({
            name: targetFilter.name,
            id: targetFilter.id,
            maxSelection: targetFilter.maxSelection,
        }, Object.assign(Object.assign({}, option), { filterId: filter.id, groupId: group.id, customValue: null }));
        recruitmentState.updateCurrentlyOpenDetailedFilterGroup(group.id);
        recruitmentState.currentlyOpenDetailedFilter = targetFilter.id;
    }, [
        filter.id,
        group.id,
        targetFilter,
        updateMultiSelectFilterSelection,
        recruitmentState,
    ]);
    // when ignoring groups, each filter is its own group
    const Wrapper = ignoreGroup ? Search.Group : Fragment;
    return (_jsxs(Wrapper, { children: [ignoreGroup ? (_jsx(Search.FilterGroupItem, { leadingIcon: getIconForFilter(filter.id), filterTitle: (_b = (_a = filter.result) === null || _a === void 0 ? void 0 : _a.highlight(highlighter)) !== null && _b !== void 0 ? _b : filter.name, highlight: search, selected: false, value: filter.id, onSelect: selectFilter, "data-testid": "filter-search-result-filter", "data-groupid": group.id, "data-filterid": filter.id, "data-score": (_c = filter.result) === null || _c === void 0 ? void 0 : _c.score, 
                // this pointer down is needed to make the onSelect work this is a side effect of:
                // https://github.com/marvelapp/mkiii/blob/ac4f2bd188ced64bc18d77d7099b783bff923423/src/packages/ballpark-components/src/Search.tsx#L92-L93
                // it is unsure why it causes this behaviour
                onPointerDown: (event) => {
                    event.preventDefault();
                } })) : (_jsx(Search.FilterGroupItem, { leadingIcon: getIconForFilter((_d = group === null || group === void 0 ? void 0 : group.id) !== null && _d !== void 0 ? _d : filter.id), groupTitle: group === null || group === void 0 ? void 0 : group.name, filterTitle: (_f = (_e = filter.result) === null || _e === void 0 ? void 0 : _e.highlight(highlighter)) !== null && _f !== void 0 ? _f : filter.name, highlight: search, selected: false, value: filter.id, onSelect: selectFilter, "data-testid": "filter-search-result-filter", "data-groupid": group.id, "data-filterid": filter.id, "data-score": (_g = filter.result) === null || _g === void 0 ? void 0 : _g.score, 
                // this pointer down is needed to make the onSelect work this is a side effect of:
                // https://github.com/marvelapp/mkiii/blob/ac4f2bd188ced64bc18d77d7099b783bff923423/src/packages/ballpark-components/src/Search.tsx#L92-L93
                // it is unsure why it causes this behaviour
                onPointerDown: (event) => {
                    event.preventDefault();
                } })), filter.options.map((option) => {
                const isSelected = selectedOptions.some((selectedOption) => selectedOption.id === option.obj.id);
                const isDisabled = !!targetFilter.maxSelection &&
                    !isSelected &&
                    selectedOptions.length >= targetFilter.maxSelection;
                const filterOption = (_jsx(Search.FilterOptionItem, { optionValue: option.highlight(highlighter), highlight: search, selected: isSelected, disabled: isDisabled, value: option.obj.id, onSelect: toggleOption, 
                    // pointer down is needed to prevent the input from blurring
                    onPointerDown: (event) => {
                        event.preventDefault();
                    }, "data-testid": "filter-search-result-option", "data-groupid": group.id, "data-filterid": filter.id, "data-score": option.score }));
                if (isDisabled) {
                    return (_jsxs(Tooltip.Root, { open: selectedValue === option.obj.id, children: [_jsx(Tooltip.Content, { align: "start", "data-testid": "filter-option-disabled-tooltip", alignOffset: 0, children: "Maximum amount of options already selected for this filter" }), _jsx(Tooltip.Trigger, { children: filterOption })] }));
                }
                return filterOption;
            })] }));
});
function useSearchFilter(search) {
    const recruitmentState = useRecruitmentState();
    const { filterGroups: filterGroupMap } = recruitmentState.filters.other;
    const [results, setResults] = useState(new Map());
    const targets = useMemo(() => {
        const searchTargets = [];
        Object.values(filterGroupMap).forEach((group) => {
            searchTargets.push({
                id: group.id,
                name: group.name,
                type: 'group',
                prepared: fuzzysort.prepare(group.name),
            });
            Object.values(group.filters).forEach((filter) => {
                searchTargets.push({
                    id: filter.id,
                    name: filter.name,
                    groupId: group.id,
                    groupName: group.name,
                    type: 'filter',
                    prepared: fuzzysort.prepare(filter.name),
                });
                filter.options.forEach((option) => {
                    searchTargets.push({
                        id: option.id,
                        name: option.name,
                        filterId: filter.id,
                        filterName: filter.name,
                        groupId: group.id,
                        groupName: group.name,
                        type: 'option',
                        prepared: fuzzysort.prepare(option.name),
                    });
                });
            });
        });
        return searchTargets;
    }, [filterGroupMap]);
    useEffect(() => {
        startTransition(() => {
            if (search.length < MIN_SEARCH_LENGTH) {
                setResults(new Map());
                return;
            }
            const searchResults = fuzzysort.go(search, targets, {
                key: 'prepared',
                limit: 50,
                // threshold refers to how close the search has to be to the result to be considered a match
                // this is an arbitrary number that seems to work well
                threshold: 0.5,
            });
            const resultMap = new Map();
            searchResults.forEach((result) => {
                if (result.obj.type === 'group') {
                    const groupId = result.obj.id;
                    const group = resultMap.get(groupId) || {
                        filters: new Map(),
                        name: result.obj.name,
                        id: result.obj.id,
                    };
                    group.result = result;
                    resultMap.set(groupId, group);
                }
                else if (result.obj.type === 'filter') {
                    const { groupId } = result.obj;
                    const filterId = result.obj.id;
                    const group = resultMap.get(groupId) || {
                        filters: new Map(),
                        name: result.obj.groupName,
                        id: result.obj.groupId,
                    };
                    const filter = group.filters.get(result.obj.id) || {
                        options: [],
                        name: result.obj.name,
                        id: result.obj.id,
                    };
                    filter.result = result;
                    group.filters.set(filterId, filter);
                    resultMap.set(groupId, group);
                }
                else {
                    const { groupId, filterId } = result.obj;
                    const group = resultMap.get(groupId) || {
                        filters: new Map(),
                        name: result.obj.groupName,
                        id: result.obj.groupId,
                    };
                    const filter = group.filters.get(filterId) || {
                        options: [],
                        name: result.obj.filterName,
                        id: result.obj.filterId,
                    };
                    filter.options.push(result);
                    group.filters.set(filterId, filter);
                    resultMap.set(groupId, group);
                }
            });
            setResults(resultMap);
        });
    }, [targets, search]);
    return results;
}
