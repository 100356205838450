import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { observer } from 'mobx-react';
import * as React from 'react';
import { useAction, useSlideUIContext } from '@marvelapp/ballpark-application';
import { StepUtils } from '@marvelapp/user-test-creator';
import HalfSlide, { LeftScrollableHalfSlide } from './HalfSlide';
import ImageUpload from './ImageUpload';
import TwoColumnSlide from './TwoColumnSlide';
export const DecoratedSlide = observer(function DecoratedSlide({ children, }) {
    var _a;
    const { step } = useSlideUIContext();
    const onImageUploaded = useAction((fileURL) => {
        if (!step)
            return;
        StepUtils.setImage(step, fileURL);
    }, [step]);
    const onDelete = useAction(() => {
        if (!step)
            return;
        StepUtils.removeImage(step);
    }, [step]);
    return (_jsxs(TwoColumnSlide, { children: [_jsx(LeftScrollableHalfSlide, { children: children }), _jsx(HalfSlide, { children: _jsx(ImageUpload, { onImageUploaded: onImageUploaded, onDelete: onDelete, testId: 'step-image-upload', url: (_a = step === null || step === void 0 ? void 0 : step.decorativeImage) === null || _a === void 0 ? void 0 : _a.url }) })] }));
});
