import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { observer } from 'mobx-react';
import { useState } from 'react';
import { useAction } from '@marvelapp/ballpark-application';
import { DateDropdowns } from '@marvelapp/ballpark-components';
import styled from '@marvelapp/styled';
import { Box } from '@marvelapp/ui';
import { DateFormat, IdentityOperator, OrdinalOperator, } from '@marvelapp/user-test-creator';
import { updateRequirement } from '../../../utils';
import { MultiConditionLogic, OperatorSelect, } from '../shared/multiConditionLogic';
const DATE_OPERATORS = [
    [IdentityOperator.Is, 'is on'],
    [IdentityOperator.IsNot, 'is not on'],
    [OrdinalOperator.IsLessThan, 'is before'],
    [OrdinalOperator.IsLessOrEqualTo, 'is before or on'],
    [OrdinalOperator.IsGreaterThan, 'is after'],
    [OrdinalOperator.IsGreaterOrEqualTo, 'is after or on'],
];
export function createDefaultRequirement(combinator) {
    return {
        operator: IdentityOperator.Is,
        combinator,
        value: null, // TODO default to today?
    };
}
function validateRequirement({ value }) {
    return value !== null;
}
export const DateConditionalLogic = observer(function DateConditionalLogic({ step, }) {
    const renderAnswerComponent = ({ ruleIndex, requirementIndex, setValue: setAnswerRule, setOperator, }) => {
        var _a, _b, _c, _d;
        const requirement = (_c = (_b = (_a = step === null || step === void 0 ? void 0 : step.conditions) === null || _a === void 0 ? void 0 : _a.rules) === null || _b === void 0 ? void 0 : _b[ruleIndex]) === null || _c === void 0 ? void 0 : _c.requirements[requirementIndex];
        return (_jsx(DateAnswerDropdown, { setOperator: setOperator, savedValue: requirement !== null && requirement !== void 0 ? requirement : null, setRule: setAnswerRule, currentOperator: (_d = requirement === null || requirement === void 0 ? void 0 : requirement.operator) !== null && _d !== void 0 ? _d : IdentityOperator.Is }));
    };
    return (_jsx(MultiConditionLogic, { createRequirement: createDefaultRequirement, updateRequirement: updateRequirement, step: step, canAddRequirement: true, renderRequirement: renderAnswerComponent, validateRequirement: validateRequirement }));
});
const DateAnswerDropdown = observer(function DateAnswerDropdown({ savedValue, setRule, currentOperator, setOperator, }) {
    var _a;
    const [value, setValue] = useState((_a = savedValue === null || savedValue === void 0 ? void 0 : savedValue.value) !== null && _a !== void 0 ? _a : '');
    const onChange = useAction((value) => {
        setValue(value);
        setRule({
            value: { value },
        });
    }, [setRule]);
    return (_jsxs(_Fragment, { children: [_jsx(OperatorSelect, { operator: currentOperator, operatorOptions: DATE_OPERATORS, setRule: setRule, setOperator: setOperator }), _jsx(DateDropdownsContainer, { children: _jsx(DateDropdowns, { dateFormat: DateFormat.DDMMYYYY, value: value, onChange: onChange }) })] }));
});
const DateDropdownsContainer = styled(Box) `
  flex-basis: 100%;
  margin-left: 70px;
`;
