import { jsx as _jsx } from "react/jsx-runtime";
import { observer } from 'mobx-react';
import * as React from 'react';
import { useIntl } from 'react-intl';
import { NotFound } from '@marvelapp/ballpark-components';
export const DeviceBlock = observer(function DeviceBlock({ settings, }) {
    const intl = useIntl();
    const allowedDevices = [];
    if (settings.isAvailableOnDesktopDevice) {
        allowedDevices.push(intl.formatMessage({ id: "jOACiK", defaultMessage: "desktop" }));
    }
    if (settings.isAvailableOnMobileDevice) {
        allowedDevices.push(intl.formatMessage({ id: "aiuddE", defaultMessage: "mobile" }));
    }
    if (settings.isAvailableOnTabletDevice) {
        allowedDevices.push(intl.formatMessage({ id: "LRENo/", defaultMessage: "tablet" }));
    }
    const allowedDeviceList = intl.formatList(allowedDevices, {
        type: 'conjunction',
    });
    return (_jsx(NotFound, { heading: intl.formatMessage({ id: "SVTZVt", defaultMessage: "This test can only be accessed from {allowedDeviceList} devices" }, { allowedDeviceList }), subheading: intl.formatMessage({ id: "SwclPr", defaultMessage: "The researcher has restricted this test to {allowedDeviceList} devices only. Please switch device and try again." }, { allowedDeviceList }), testId: "device-block-message" }));
});
