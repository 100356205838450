import { jsx as _jsx } from "react/jsx-runtime";
import { AnimatePresence } from 'framer-motion';
import { observer } from 'mobx-react';
import * as React from 'react';
import { Grid } from '@marvelapp/ui';
export default observer(function PreferenceGrid({ children, columns = [
    'repeat(1, minmax(0, 1fr))',
    'repeat(1, minmax(0, 1fr))',
    'repeat(2, minmax(0, 1fr))',
    'repeat(2, minmax(0, 1fr))',
    'repeat(3, minmax(0, 1fr))',
    'repeat(4, minmax(0, 1fr))',
], }) {
    return (_jsx(Grid, { gridGap: "m", gridTemplateColumns: columns, marginTop: "l", marginBottom: [0, 0, 'm'], width: "100%", children: _jsx(AnimatePresence, { children: children }) }));
});
