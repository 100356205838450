import { __rest } from "tslib";
import { jsx as _jsx } from "react/jsx-runtime";
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { observer } from 'mobx-react';
import { useCallback, useEffect, useState } from 'react';
import * as React from 'react';
import { useIntl } from 'react-intl';
import { useAction, useSlideUIContext, useUserTestAction, } from '@marvelapp/ballpark-application';
import { formatStringOrMessage, getStepTitle } from '@marvelapp/ballpark-copy';
import { usePublishSelection } from '@marvelapp/ballpark-realtime';
import styled from '@marvelapp/styled';
import { Box } from '@marvelapp/ui';
import { ConditionalLogicUtils, StepUtils, } from '@marvelapp/user-test-creator';
import { SidebarCard } from './SidebarCard';
import { SlidePresenceAvatars } from './SlidePresenceAvatars';
const SlideRowCard = observer(function SlideRowCard({ active, attributes, onCloneStep, onDeleteStep, getCardTypeIcon, getStepText, hasHoverMenu, id, isDisabled, isDragging, listeners, setActiveSlideUUID, showStepSettingsIcons, skipped, testId, userTest, }) {
    const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState(false);
    const { step, fixedStepType } = useSlideUIContext();
    const videoGuide = StepUtils.getInstruction(step);
    const isStepRequired = StepUtils.getIsRequired(step);
    const publishSelection = usePublishSelection();
    const hasConditions = ConditionalLogicUtils.getConditionsCount(step, userTest) > 0;
    const intl = useIntl();
    useEffect(() => {
        if (active)
            publishSelection(id);
    }, [publishSelection, id, active]);
    const onCardClick = useCallback((event) => {
        if (event.defaultPrevented || !setActiveSlideUUID)
            return;
        setActiveSlideUUID(step.uuid);
    }, [setActiveSlideUUID, step]);
    const handleDeleteStep = useAction(() => {
        const hasLogicSet = ConditionalLogicUtils.getIsDeletedStepConditional(userTest, step.uuid);
        if (hasLogicSet && !showConfirmDeleteModal) {
            setShowConfirmDeleteModal(true);
        }
        else if (onDeleteStep) {
            onDeleteStep(step);
        }
    }, [userTest, step, showConfirmDeleteModal, onDeleteStep]);
    const handleCloneStep = useUserTestAction(() => onCloneStep && onCloneStep(step), [onCloneStep, step]);
    return (_jsx(SidebarCard, { active: active, attributes: attributes, hasConditions: hasConditions, hasHoverMenu: hasHoverMenu, id: id, isDragging: isDragging, isDisabled: isDisabled, isStepRequired: isStepRequired, listeners: listeners, onCardClick: onCardClick, onStepClone: handleCloneStep, onStepDelete: handleDeleteStep, setShowConfirmDeleteModal: setShowConfirmDeleteModal, showConfirmDeleteModal: showConfirmDeleteModal, showStepSettingsIcons: showStepSettingsIcons, skipped: skipped, slideAvatars: _jsx(SlidePresenceAvatars, { sidebarSlideId: id }), stepIcon: getCardTypeIcon(step, fixedStepType), stepName: getStepText(userTest, step, fixedStepType), stepTitle: formatStringOrMessage(intl, getStepTitle(step, fixedStepType)), testId: testId ||
            `sidebar-step-${step.className}${'type' in step ? `-${step === null || step === void 0 ? void 0 : step.type}` : ''}`, videoGuideStatus: videoGuide === null || videoGuide === void 0 ? void 0 : videoGuide.status }));
});
const DraggableSlideRowCard = observer(function DraggableSlideRowCard(_a) {
    var { id } = _a, otherProps = __rest(_a, ["id"]);
    const { attributes, listeners, setNodeRef, transform, transition, isDragging, } = useSortable({ id });
    const style = {
        transition,
        transform: CSS.Transform.toString(transform),
        zIndex: isDragging ? 100 : 'unset',
    };
    return (_jsx(DraggableContainer, { ref: setNodeRef, 
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        style: style, mb: "s", children: _jsx(SlideRowCard, Object.assign({ attributes: attributes, listeners: listeners }, otherProps, { isDisabled: isDragging, id: id })) }));
});
const DraggableContainer = styled(Box) `
  position: relative;
`;
export { SlideRowCard as default, DraggableSlideRowCard };
