import { __rest } from "tslib";
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { isNil, memoize, shuffle } from 'lodash-es';
import { observer } from 'mobx-react';
import { useCallback, useState } from 'react';
import * as React from 'react';
import { useIntl } from 'react-intl';
import { useAction } from '@marvelapp/ballpark-application';
import { Text } from '@marvelapp/ballpark-components';
import { getThumbnail, getThumbnail2x } from '@marvelapp/core';
import { PreferenceStepResponseUtils, PreferenceStepUtils, } from '@marvelapp/user-test-creator';
import PreferenceGrid from '../../components/PreferenceGrid';
import { SelectablePreferenceCard } from '../../components/SelectablePreferenceCard';
import { getHelpText } from '../../utils/choiceStepUtils';
import SlideRecord from '../common/SlideRecord';
import PreferenceTestImageModal from './PreferenceTestImageModal';
const getChoices = memoize((step) => {
    return PreferenceStepUtils.getIsRandomizeSet(step)
        ? shuffle(step.choices)
        : step.choices;
});
const PreferenceRecord = observer(function PreferenceRecord(_a) {
    var { step, stepResponse } = _a, rest = __rest(_a, ["step", "stepResponse"]);
    const intl = useIntl();
    // If the user hasn't supplied any options in the builder,
    // we don't want to disable the button even if isRequired is true.
    // This will ensure that the participant doesn't get stuck when taking the test
    const [imageModalUuid, setImageModalUuid] = useState(null);
    const onCloseModal = useCallback(() => {
        setImageModalUuid(null);
    }, []);
    const choices = getChoices(step);
    const multipleSelectionMode = PreferenceStepUtils.getMultipleSelectionMode(step);
    const selectionRange = PreferenceStepUtils.getSelectionRange(step);
    PreferenceStepUtils.getMultipleSelectionMode(step);
    const helpText = getHelpText(intl, multipleSelectionMode, selectionRange);
    function getImageUuid(offset) {
        if (!imageModalUuid)
            return null;
        const currentIndex = choices.indexOf(imageModalUuid);
        if (currentIndex < 0)
            return null;
        return choices[(currentIndex + offset + choices.length) % choices.length];
    }
    let onNextImage;
    let onPrevImage;
    if (choices.length > 1) {
        onNextImage = () => setImageModalUuid(getImageUuid(+1));
        onPrevImage = () => setImageModalUuid(getImageUuid(-1));
    }
    const selectedChoicesForStep = PreferenceStepResponseUtils.getAnswer(stepResponse);
    const [_, upperRangeLimit] = PreferenceStepUtils.getSelectionRange(step);
    const numberOfSelectedChoices = selectedChoicesForStep.length;
    const hasSelectedMaxOptions = !!(multipleSelectionMode &&
        upperRangeLimit &&
        numberOfSelectedChoices >= upperRangeLimit);
    const onChooseImage = useAction(() => {
        if (!isNil(imageModalUuid)) {
            const isChecked = selectedChoicesForStep.includes(imageModalUuid);
            PreferenceStepResponseUtils.updateChoices(stepResponse, imageModalUuid, isChecked, multipleSelectionMode);
        }
        setImageModalUuid(null);
    }, [
        imageModalUuid,
        selectedChoicesForStep,
        stepResponse,
        multipleSelectionMode,
    ]);
    return (_jsxs(SlideRecord, Object.assign({ maxWidth: 1380, step: step }, rest, { children: [helpText && _jsx(Text, { children: helpText }), !!step.choices.length && (_jsxs(PreferenceGrid, { children: [choices.map((uuid, index) => (_jsx(PreferenceCard, { handleImageClick: () => {
                            setImageModalUuid(uuid);
                        }, index: index, multipleSelectionMode: multipleSelectionMode, step: step, stepResponse: stepResponse, selectedChoicesForStep: selectedChoicesForStep, maxOptionsReached: hasSelectedMaxOptions, uuid: uuid }, uuid))), _jsx(PreferenceTestImageModal, { item: imageModalUuid
                            ? step.choiceDefinitions[imageModalUuid]
                            : undefined, optionIndex: imageModalUuid ? choices.indexOf(imageModalUuid) : 0, onClose: onCloseModal, onChooseImage: onChooseImage, onNextImage: onNextImage, onPrevImage: onPrevImage, isChecked: selectedChoicesForStep.includes(imageModalUuid !== null && imageModalUuid !== void 0 ? imageModalUuid : ''), maxOptionsReached: hasSelectedMaxOptions })] }))] })));
});
const PreferenceCard = observer(function PreferenceCard({ handleImageClick, index, maxOptionsReached, step, stepResponse, selectedChoicesForStep, multipleSelectionMode, uuid, }) {
    const intl = useIntl();
    const isChecked = selectedChoicesForStep.includes(uuid);
    const choice = step.choiceDefinitions[uuid];
    const toggleChoices = useAction(() => PreferenceStepResponseUtils.updateChoices(stepResponse, uuid, isChecked, multipleSelectionMode), [stepResponse, uuid, isChecked, multipleSelectionMode]);
    return (_jsx(SelectablePreferenceCard, { id: uuid, isChecked: isChecked, name: choice.choiceTitle ||
            intl.formatMessage({ id: "1yAmrx", defaultMessage: "Choice {number}" }, {
                number: index + 1,
            }), onChange: toggleChoices, optionNumber: index, readOnly: maxOptionsReached && !isChecked, thumbnail: getThumbnail(choice.src, 400), thumbnail2x: getThumbnail2x(choice.src, 400), onImageClick: handleImageClick }));
});
export default PreferenceRecord;
