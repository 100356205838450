import { __rest } from "tslib";
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { CarouselLayout, GridLayout, MediaDeviceSelect, ParticipantTile, RoomAudioRenderer, useLocalParticipant, useTrackToggle, useTracks, } from '@livekit/components-react';
import '@livekit/components-styles';
import '@livekit/components-styles/prefabs';
import { Track } from 'livekit-client';
import { observer } from 'mobx-react';
import { BtwButton, BtwHeading, BtwModal, BtwPopover, BtwText, BtwTooltip, Stack, cn, } from '@marvelapp/ballpark-components';
import { ChevronBottomSmallIcon, MicrophoneDisabledIcon, MicrophoneIcon, ScreenDisabledIcon, ScreenIcon, WebcamDisabledIcon, WebcamIcon, } from '@marvelapp/ballpark-icons';
import { useRecorderState } from './RecorderContext';
// Using a plain css file to be able to select and override livekit things better
import './Room.css';
import { LiveSessionStatus } from './useLiveSessionStatus';
export const ParticipantRoom = observer(function ParticipantRoom() {
    const recorderState = useRecorderState();
    if (!recorderState.isLive) {
        return null;
    }
    if (recorderState.liveSessionStatus === LiveSessionStatus.Lobby) {
        return (_jsx(_Fragment, { children: _jsx(BtwModal.Root, { open: true, children: _jsx(BtwModal.Content, { position: "full", size: "full", children: _jsxs(BtwModal.Body, { className: "lk-waiting-room flex h-screen w-screen flex-col items-center justify-center gap-14 p-10", children: [_jsxs(Stack, { align: "center", gap: "2", children: [_jsx(BtwHeading, { size: "xl", weight: "bold", variant: "secondary", children: "Please wait\u2026" }), _jsx(BtwText, { size: "base", weight: "normal", children: "Waiting for researchers to join and start the session\u2026" })] }), _jsx(LiveKitComponentsContainer, { children: _jsx(Grid, {}) })] }) }) }) }));
    }
    if (recorderState.liveSessionStatus === LiveSessionStatus.Recording) {
        return (_jsx(LiveKitComponentsContainer, { children: _jsx(Controls, {}) }));
    }
    return null;
});
const LiveKitComponentsContainer = observer(function LiveKitComponentsContainer({ children, }) {
    return (_jsxs(_Fragment, { children: [_jsx(RoomAudioRenderer, {}), children] }));
});
const Carousel = observer(function Carousel() {
    // `useTracks` returns all camera and screen share tracks. If a user
    // joins without a published camera track, a placeholder track is returned.
    const cameraTracks = useNonObserverTracks();
    return (_jsx(CarouselLayout, { tracks: cameraTracks, orientation: "vertical", className: "flex w-full max-w-fit flex-1 flex-col gap-2", children: _jsx(ParticipantTile, { className: "h-[60px] w-[60px]" }) }));
});
const Grid = observer(function Grid() {
    const cameraTracks = useNonObserverTracks();
    return (_jsx(GridLayout, { tracks: cameraTracks, className: "!h-fit !w-auto justify-center justify-items-center !gap-6", children: _jsx(ParticipantTile, { className: "max-w-full" }) }));
});
const Controls = observer(function Controls() {
    return (_jsxs(Stack, { justify: "between", className: "lk-participant-view h-full", children: [_jsx(Carousel, {}), _jsxs(Stack, { gap: "2", children: [_jsx(CameraButton, {}), _jsx(MicButton, {}), _jsx(ScreenShareButton, {})] })] }));
});
const CameraButton = observer(function CameraButton() {
    const { buttonProps, enabled } = useTrackToggle({
        source: Track.Source.Camera,
    });
    // Omit the className from buttonProps
    const { className } = buttonProps, otherButtonProps = __rest(buttonProps, ["className"]);
    return (_jsxs(Stack, { direction: "row", className: "ease-smooth transition-transform duration-300 hover:-translate-y-px has-[:active]:translate-y-px", children: [_jsx(BtwTooltip, { content: enabled ? 'Turn off camera' : 'Turn on camera', collisionPadding: 8, children: _jsx(BtwButton, Object.assign({}, otherButtonProps, { standaloneIcon: enabled ? _jsx(WebcamIcon, {}) : _jsx(WebcamDisabledIcon, {}), variant: "secondary", size: "intermediate", className: cn('peer rounded-br-none rounded-tr-none hover:translate-y-0 active:translate-y-0 data-[lk-enabled=false]:bg-white/50') })) }), _jsxs(BtwPopover.Root, { children: [_jsx(BtwPopover.Trigger, { asChild: true, children: _jsx(BtwButton, { standaloneIcon: _jsx(ChevronBottomSmallIcon, {}), variant: "secondary", size: "intermediate", className: cn('w-7 rounded-bl-none rounded-tl-none hover:translate-y-0 active:translate-y-0 peer-data-[lk-enabled=false]:bg-white/50') }) }), _jsx(BtwPopover.Content, { collisionPadding: 8, side: "top", children: _jsx(MediaDeviceSelect, { kind: "videoinput" }) })] })] }));
});
const MicButton = observer(function MicButton() {
    const { buttonProps, enabled } = useTrackToggle({
        source: Track.Source.Microphone,
    });
    // Omit the className from buttonProps
    const { className } = buttonProps, otherButtonProps = __rest(buttonProps, ["className"]);
    return (_jsxs(Stack, { direction: "row", className: "ease-smooth transition-transform duration-300 hover:-translate-y-px has-[:active]:translate-y-px", children: [_jsx(BtwTooltip, { content: enabled ? 'Mute microphone' : 'Unmute microphone', collisionPadding: 8, children: _jsx(BtwButton, Object.assign({}, otherButtonProps, { standaloneIcon: enabled ? _jsx(MicrophoneIcon, {}) : _jsx(MicrophoneDisabledIcon, {}), variant: "secondary", size: "intermediate", className: cn('peer rounded-br-none rounded-tr-none hover:translate-y-0 active:translate-y-0 data-[lk-enabled=false]:bg-white/50') })) }), _jsxs(BtwPopover.Root, { children: [_jsx(BtwPopover.Trigger, { asChild: true, children: _jsx(BtwButton, { standaloneIcon: _jsx(ChevronBottomSmallIcon, {}), variant: "secondary", size: "intermediate", className: "w-7 rounded-bl-none rounded-tl-none hover:translate-y-0 active:translate-y-0 peer-data-[lk-enabled=false]:bg-white/50" }) }), _jsx(BtwPopover.Content, { collisionPadding: 8, side: "top", children: _jsx(MediaDeviceSelect, { kind: "audioinput" }) })] })] }));
});
const ScreenShareButton = observer(function ScreenShareButton() {
    const { buttonProps, enabled } = useTrackToggle({
        source: Track.Source.ScreenShare,
    });
    // Omit the className from buttonProps
    const { className } = buttonProps, otherButtonProps = __rest(buttonProps, ["className"]);
    return (_jsx(BtwTooltip, { content: enabled ? 'Stop sharing screen' : 'Share your screen', collisionPadding: 8, children: _jsx(BtwButton, Object.assign({}, otherButtonProps, { standaloneIcon: enabled ? _jsx(ScreenIcon, {}) : _jsx(ScreenDisabledIcon, {}), variant: "secondary", size: "intermediate", className: cn('w-full data-[lk-enabled=false]:bg-white/50') })) }));
});
function useNonObserverTracks() {
    const cameraTracks = useTracks([{ source: Track.Source.Camera, withPlaceholder: true }], { onlySubscribed: false });
    const { localParticipant } = useLocalParticipant();
    return cameraTracks.filter((track) => {
        var _a;
        return (((_a = track.participant.attributes) === null || _a === void 0 ? void 0 : _a.video_role) === 'RESEARCHER' ||
            track.participant.sid === (localParticipant === null || localParticipant === void 0 ? void 0 : localParticipant.sid));
    });
}
