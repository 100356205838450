import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useCurrentUser, userSelectors } from '@marvelapp/ballpark-application';
import theme from '@marvelapp/ballpark-theme';
import { UserRoleType } from '@marvelapp/core';
import { Box, Flex, Heading, Radio, Semibold, Text } from '@marvelapp/ui';
import Link from '../Link';
import TooltipWrap from '../TooltipWrap';
import UserRoleSelect from '../UserRoleSelect';
const copy = {
    [UserRoleType.Member]: {
        description: 'Can view, comment, or also create and edit all workspace projects and folders. Typically used for employees.',
        name: 'Team member',
        role: 'team members',
    },
    [UserRoleType.Guest]: {
        description: 'Can only access projects that you specifically choose. Typically used for clients and stakeholders.',
        name: 'Guest',
        role: 'guest',
    },
};
export const testIds = {
    [UserRoleType.Member]: {
        testId: 'member-role-row',
        radioTestId: 'member-role',
    },
    [UserRoleType.Guest]: {
        testId: 'guest-role-row',
        radioTestId: 'guest-role',
    },
};
export default function RoleRow({ isRowSelected = false, maxAllowance, onRadioClick, onRoleChange, readOnly = false, roleOptions, roleSelectDirection = 'bottom', roleType, seatsUsed, selectedRole, }) {
    const user = useCurrentUser();
    const canInviteContributors = !(userSelectors.isFree(user) || userSelectors.isPro(user));
    const { testId, radioTestId } = testIds[roleType];
    const { name, description, role } = copy[roleType];
    return (_jsxs(Flex, { alignItems: ['flex-start', 'center'], bg: isRowSelected ? theme.colors.snowA : 'transparent', borderTop: "1px solid", 
        // eslint-disable-next-line
        borderColor: "smoke", "data-testid": testId, flexDirection: ['column', 'row'], padding: 30, transition: "smooth.fast", children: [_jsxs(Box, { children: [_jsx(Flex, { alignItems: "center", children: _jsx(Heading, { fontSize: 3, fontWeight: 500, children: name }) }), _jsx(Text, { fontSize: 14, marginTop: 2, children: description })] }), _jsxs(Flex, { alignItems: "center", flexDirection: ['row-reverse', 'row'], justifyContent: "flex-end", marginTop: [2, 0], minWidth: 120, children: [_jsx(UserRoleSelect, { disabled: readOnly, isReversed: roleSelectDirection === 'top', onSelection: onRoleChange, options: roleOptions, selectedRole: selectedRole }), _jsx(TooltipWrap, { content: _jsx(Text, { fontSize: 1, fontWeight: 500, children: getTooltipText({
                                maxAllowance,
                                readOnly,
                                seatsUsed,
                                name,
                                role,
                                canInviteContributors,
                            }) }), showTooltip: readOnly, children: _jsx(Radio, { "data-testid": radioTestId, isChecked: isRowSelected, isDisabled: readOnly, kind: "defaultBlue", name: "role", onChange: () => onRadioClick(), readOnly: readOnly, size: 20 }) })] })] }));
}
function getTooltipText({ maxAllowance, readOnly, seatsUsed, name, role, canInviteContributors, }) {
    if (readOnly) {
        if (!canInviteContributors) {
            return (_jsxs(_Fragment, { children: [name, "s are not available on your plan. Please", ' ', _jsx(Link, { kind: "white", target: "_blank", to: "/plans?intent=more-user-roles", children: "upgrade" }), ' ', "to get access to ", role, "s."] }));
        }
        if (maxAllowance !== undefined && seatsUsed !== undefined) {
            return (_jsxs(_Fragment, { children: ["You can only add up to ", _jsx(Semibold, { children: maxAllowance }), " ", role, " to your account.", ' ', seatsUsed >= maxAllowance
                        ? `Remove ${role} before trying to add more.`
                        : `Remove some emails to be able to switch to adding ${role}.`] }));
        }
    }
}
