import { jsx as _jsx } from "react/jsx-runtime";
import { orderBy } from 'lodash-es';
import { observer } from 'mobx-react';
import { useMemo } from 'react';
import * as React from 'react';
import { CardSortingResponse as CardSortingContainer } from '@marvelapp/ballpark-components';
import { CardSortingUtils, } from '@marvelapp/user-test-creator';
const UNSORTED_CATEGORY_ID = 'Unsorted';
export const CardSortingResponse = observer(function CardSortingResponse({ step, stepResponse, }) {
    const categories = useMemo(() => getCategories({ step, stepResponse }), [step, stepResponse]);
    const showCardNumber = CardSortingUtils.getIsNumberOrderInCategoryVisible(step);
    return (
    // Workaround to remove the outer padding, until we update the response page
    // to use the new StepBox component.
    _jsx("div", { className: "m-[-32px] pt-4", children: _jsx("div", { className: "border-t border-gray-200", children: _jsx(CardSortingContainer.Root, { type: categories.length < 3 ? 'auto' : 'always', children: categories.map((category) => (_jsx(CardSortingContainer.Category, { cardsCount: category.cards.length, categoriesCount: categories.length, title: category.title || '', children: category.cards.map((card, index) => (_jsx(CardSortingContainer.Card, { index: showCardNumber ? index : undefined, imageSrc: card.imageUrl, title: card.title || '' }, card.id))) }, category.id))) }) }) }));
});
function getCategories({ step, stepResponse, }) {
    const cardUUIDMap = CardSortingUtils.getCards(step).reduce((acc, card) => {
        // eslint-disable-next-line no-param-reassign
        acc[card.uuid] = card;
        return acc;
    }, {});
    const categoryUUIDMap = CardSortingUtils.getCategories(step).reduce((acc, category) => {
        // eslint-disable-next-line no-param-reassign
        acc[category.uuid] = Object.assign(Object.assign({}, category), { cards: [] });
        return acc;
    }, {});
    const unsortedCardUUIDMap = Object.assign({}, cardUUIDMap);
    ((stepResponse === null || stepResponse === void 0 ? void 0 : stepResponse.cards) || []).forEach(({ cardUuid, categoryUuid, customCategoryName }) => {
        // Skip invalid or non-existing card or category UUIDs
        if (!cardUuid || !categoryUuid || !cardUUIDMap[cardUuid]) {
            return;
        }
        if (!categoryUUIDMap[categoryUuid]) {
            categoryUUIDMap[categoryUuid] = {
                uuid: categoryUuid,
                id: categoryUuid,
                title: customCategoryName || '',
                cards: [],
            };
        }
        categoryUUIDMap[categoryUuid].cards.push(cardUUIDMap[cardUuid]);
        delete unsortedCardUUIDMap[cardUuid];
    });
    // Sort categories by number of cards, then by title
    const categories = orderBy(Object.values(categoryUUIDMap), [(category) => category.cards.length, 'title'], ['desc', 'asc']);
    if (!step.isNumberOrderInCategoryVisible) {
        // Sort cards within each category by title
        categories.forEach((category) => {
            // eslint-disable-next-line no-param-reassign
            category.cards.sort((card1, card2) => (card1.title || '').localeCompare(card2.title || ''));
        });
    }
    const unsortedCategory = {
        uuid: UNSORTED_CATEGORY_ID,
        id: UNSORTED_CATEGORY_ID,
        title: 'Unsorted',
        cards: [],
    };
    // Add remaining unsorted cards to the "unsorted" category.
    // Note: this might cause issues if cards are edited after publishing a user test.
    // Ideally the backend should return the full list of cards in the response,
    // unsorted included.
    Object.values(unsortedCardUUIDMap).forEach((card) => {
        unsortedCategory.cards.push(card);
    });
    // "unsorted" cards are placed before the first category with 0 cards,
    // keeping categories with cards first.
    if (unsortedCategory.cards.length > 0) {
        const firstZeroCardsIndex = categories.findIndex((category) => category.cards.length === 0);
        if (firstZeroCardsIndex !== -1) {
            categories.splice(firstZeroCardsIndex, 0, unsortedCategory);
        }
        else {
            categories.push(unsortedCategory);
        }
    }
    return categories;
}
