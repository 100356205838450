import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { observer } from 'mobx-react';
import * as React from 'react';
import { BtwModal, Separator } from '@marvelapp/ballpark-components';
import { DeviceSettings } from './DeviceSettings';
import { InviteOnlySetting } from './InviteOnlySetting';
import { LocaleSelect } from './LocaleSelect';
import { PauseProjectSetting } from './PauseProjectSetting';
export const ProjectSettings = observer(function ProjectSettings({ deviceSettings, visibility, prototypePk, status, pausedAt, userTestPk, }) {
    return (_jsxs(BtwModal.Content, { "data-testid": "project-settings-modal", children: [_jsx(BtwModal.BigHeader, { onlyChild: false, title: "Project Settings" }), _jsxs(BtwModal.Body, { children: [_jsx(PauseProjectSetting, { pausedAt: pausedAt, projectStatus: status, userTestPk: userTestPk }), _jsx(InviteOnlySetting, { prototypePk: prototypePk, visibility: visibility }), _jsx(Separator, {}), _jsx(DeviceSettings, { deviceSettings: deviceSettings, prototypePk: prototypePk, userTestPk: userTestPk }), _jsx(Separator, {}), _jsx(LocaleSelect, {})] })] }));
});
