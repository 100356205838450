import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { observer } from 'mobx-react';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link, Text } from '@marvelapp/ballpark-components';
import { ENABLE_SCREEN_SHARING } from '@marvelapp/ballpark-help';
import { deviceDetection } from '@marvelapp/core';
import ErrorBox from './ErrorBox';
export default observer(function NoScreenShareError() {
    const helpLink = getHelpLink();
    return (_jsxs(ErrorBox, { children: [_jsx(Text, { fontSize: "s", children: _jsx(FormattedMessage, { id: 'HCBAXU', defaultMessage: 'It looks like this browser doesn\'t have permission to access your screen.' }) }), helpLink && (_jsx(Text, { fontSize: "s", mt: "xs", children: _jsx(FormattedMessage, { id: 'HexI9i', defaultMessage: 'Hit refresh and try again. If that doesn\'t work, <link>follow our instructions</link>.', values: {
                        link: (chunks) => _jsx(Link, { to: helpLink, children: chunks }),
                    } }) }))] }));
});
function getHelpLink() {
    const { isSafari, isMacOs } = deviceDetection;
    if (isSafari || isMacOs) {
        return ENABLE_SCREEN_SHARING;
    }
    // Windows and Linux have no system wide settings for access to this
    return null;
}
