import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { observer } from 'mobx-react';
import * as React from 'react';
import { useIntl } from 'react-intl';
import { Badge, Heading, StepIcon, Text, getIconType, } from '@marvelapp/ballpark-components';
import { formatStringOrMessage, getStepName, getStepTitle, } from '@marvelapp/ballpark-copy';
import styled from '@marvelapp/styled';
import { TextEditor } from '@marvelapp/text-editor';
import { Box, Flex, PinTR } from '@marvelapp/ui';
import { FixedStep, TextUtils } from '@marvelapp/user-test-creator';
import { useStepContext } from '../../utils/stepContext';
import { ReadMoreWrapper } from '../ReadMoreWrapper';
import ResultLoading from '../ResultLoading';
export const StepHeader = observer(function StepHeader({ currentStepNumber, secondary, notAnswered, stillLoading, totalStepsNumber, step, isScreenerStep = false, }) {
    const intl = useIntl();
    const { step: contextStep } = useStepContext();
    // This is temporary, and is due to the fact that response that also is using
    // this component doesn't use context yet
    const currentStep = contextStep || step;
    const fixedStepType = getFixedStepType(currentStepNumber, totalStepsNumber);
    const stepName = isScreenerStep
        ? 'Screener'
        : getStepName(currentStep, fixedStepType);
    const hasDescriptionText = currentStep.description &&
        TextUtils.richTextToString(currentStep.description) !== '';
    const iconType = getIconType(currentStep, fixedStepType, isScreenerStep);
    return (_jsxs(Header, { children: [iconType && (_jsx(PinTR, { padding: "m", filter: notAnswered ? 'grayscale(1)' : undefined, children: _jsx(StepIcon, { type: iconType }) })), _jsxs(Flex, { alignItems: "center", children: [_jsx(StepInfo, { badgeNumber: `${currentStepNumber} / ${totalStepsNumber}`, stepName: formatStringOrMessage(intl, stepName), isScreenerStep: isScreenerStep, notAnswered: notAnswered }), stillLoading && (_jsx(Box, { marginLeft: "xs", children: _jsx(ResultLoading, {}) }))] }), _jsx(Heading, { marginTop: "s", marginBottom: "xs", size: "l", "data-testid": "step-title", 
                // Preserves line breaks in the text, if there are any
                whiteSpace: "pre-line", children: formatStringOrMessage(intl, getStepTitle(currentStep)) }), hasDescriptionText && (_jsx(ReadMoreWrapper, { children: _jsx(TextEditor, { disabled: true, id: `${stepName}-${currentStepNumber}`, value: currentStep.description, testId: "step-description" }) })), secondary && (_jsx(Text, { marginTop: "xs", kind: "additional", size: "s", children: secondary }))] }));
});
const Header = styled(Box).attrs({
    paddingTop: 'l',
    paddingBottom: 'l',
    paddingLeft: 'l',
    paddingRight: 66 + 24, // To make sure text doesn't touch the item icon
}) `
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
`;
function getFixedStepType(currentStepNumber, totalStepsNumber) {
    const isExitStep = currentStepNumber === totalStepsNumber;
    const isWelcomeStep = currentStepNumber === 1;
    if (isWelcomeStep) {
        return FixedStep.Welcome;
    }
    if (isExitStep) {
        return FixedStep.Exit;
    }
}
const StepInfo = observer(function StepInfo({ stepName, isScreenerStep, notAnswered, badgeNumber, }) {
    if (isScreenerStep) {
        return (_jsx(Text, { lineHeight: "base", size: "s", children: stepName }));
    }
    return (_jsxs(_Fragment, { children: [_jsx(Badge, { size: "sm", variant: notAnswered ? 'gray' : 'sky', children: badgeNumber }), _jsx(Text, { lineHeight: "base", marginLeft: "xs", size: "s", children: stepName })] }));
});
