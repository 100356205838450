import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect } from 'react';
import { heapTrackEvent } from '@marvelapp/core';
import Link from '../Link';
import BaseError from './BaseError';
export default function NotFound({ heading = '404 — page not found', headingMaxWidth, subheading = 'Check out some of the useful links below:', subheadingMaxWidth, }) {
    useEffect(() => {
        heapTrackEvent('404 Appeared', { pathname: window.location.pathname });
    }, []);
    const linkComponent = ({ target, url, name }) => (_jsx(Link, { display: "block", kind: "marvel", mb: 2, target: target, to: url, children: name }, url));
    return (_jsx(BaseError, { heading: heading, headingMaxWidth: headingMaxWidth, renderLinkComponent: linkComponent, subheading: subheading, subheadingMaxWidth: subheadingMaxWidth }));
}
