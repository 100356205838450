import themeGet from '@styled-system/theme-get';
import { border, color, layout, shadow, space } from 'styled-system';
import styled from '@marvelapp/styled';
export const CardElement = styled.div `
  ${border};
  ${color};
  ${layout};
  ${shadow};
  ${space};
  position: relative;
  transition: all 400ms ${themeGet('easings.smooth.string')};
`;
