import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { groupBy, isNil } from 'lodash-es';
import { observer } from 'mobx-react';
import { useCallback, useEffect, useRef, useState } from 'react';
import * as React from 'react';
import { useIntl } from 'react-intl';
import { useGetUserTest } from '@marvelapp/ballpark-application';
import { BtwButton, BtwInput, BtwText, BtwTooltip, Combobox, DatePicker, Select, Stack, cn, } from '@marvelapp/ballpark-components';
import { formatStringOrMessage, getStepName, getStepTitle, } from '@marvelapp/ballpark-copy';
import { ChevronRightIcon, TrashCanIcon } from '@marvelapp/ballpark-icons';
import { useDebouncedState } from '@marvelapp/hooks';
import { ScreenerUtils, getStepByUUID, } from '@marvelapp/user-test-creator';
import { useResponsesContext } from './ResponsesContext';
import { TruncatedTextContainer } from './TruncatedTextContainer';
import { groupColumn } from './columnUtils';
import { getDeviceMakeIcon, getResultStatusIcon } from './stepUtils';
export const Filter = observer(function Filter({ availableFilters, type, definitionId, onFilterDefinitionIdChange, availableOperators, index, onOperatorChange, operator, value, onValueChange, onDelete, values = [], inputPlaceholder, }) {
    const [debouncedValue, setValue, latestValue] = useDebouncedState(value, {
        onFlush: onValueChange,
    });
    useEffect(() => {
        onValueChange === null || onValueChange === void 0 ? void 0 : onValueChange(debouncedValue);
    }, [debouncedValue, onValueChange]);
    const isEmpty = !definitionId || !onOperatorChange || !onValueChange || !type;
    return (_jsxs(Stack, { width: "full", direction: "row", gap: "2.5", "data-testid": "filter", children: [_jsx(Stack, { children: _jsx(LogicalOperatorDisplay, { index: index }) }), _jsxs(Stack, { width: "full", gap: "2.5", className: "min-w-0 flex-1", children: [_jsx(Stack, { width: "full", direction: "row", children: _jsx(FilterNameSelect, { filters: availableFilters, onChange: onFilterDefinitionIdChange, value: definitionId }) }), _jsxs(Stack, { direction: "row", width: "full", gap: "2.5", align: "center", children: [_jsx(Stack, { children: isEmpty ? (_jsx(EmptySelect, { placeholder: "is" })) : (_jsx(OperatorSelect, { operators: availableOperators, onChange: onOperatorChange, value: operator })) }), _jsx(Stack, { width: "full", className: "min-w-0", children: isEmpty ? (_jsx(EmptySelect, { placeholder: "Select a value\u2026" })) : (_jsx(FilterValue, { type: type, filterValues: values, onChange: setValue, value: latestValue, placeholder: inputPlaceholder })) })] })] }), _jsx(Stack, { children: _jsx(BtwButton, { testId: "delete-filter", standaloneIcon: _jsx(TrashCanIcon, {}), onClick: onDelete, variant: "ghost", size: "intermediate" }) })] }));
});
const EmptySelect = observer(function EmptySelect({ placeholder, }) {
    return (_jsx(Combobox.Root, { variant: "single-select", children: _jsx(Combobox.Trigger, { hasPlaceholder: false, disabled: true, width: "full", size: "intermediate", children: placeholder }) }));
});
const LogicalOperatorDisplay = observer(function LogicalOperatorDisplay({ index, }) {
    const { logicalOperator } = useResponsesContext();
    const displayLogicalOperator = logicalOperator === 'AND' ? 'and' : 'or';
    if (index === 0)
        return _jsx(LogicalOperatorItem, { children: "Where" });
    if (index === 1)
        return _jsx(LogicalOperatorSelect, {});
    if (index > 1)
        return _jsx(LogicalOperatorItem, { children: displayLogicalOperator });
    return null;
});
const LogicalOperatorItem = observer(function LogicalOperatorItem({ children, }) {
    return (_jsx(Stack, { justify: "center", className: "h-8 w-20 rounded-md bg-gray-50 px-3 ring-1 ring-gray-600/10", children: _jsx(BtwText, { size: "13", weight: "medium", "data-testid": "select-logical-operator", "data-state": "disabled", children: children }) }));
});
const FilterNameSelect = observer(function FilterNameSelect({ filters, onChange, value, }) {
    const currentFilter = filters.find((filter) => filter.value === value);
    const currentName = currentFilter ? (_jsx(FilterItem, { stepUUID: currentFilter.filterDef.stepUUID, filterName: currentFilter.filterDef.name })) : ('Select a property');
    const userTest = useGetUserTest();
    const groupedFilters = groupBy(filters, (filter) => {
        let ID = filter.filterDef.stepUUID;
        if (filter.filterDef.name === 'Participant ID') {
            ID = 'participantId';
        }
        else if (filter.filterDef.name === 'Study ID') {
            ID = 'studyId';
        }
        return groupColumn(ID, userTest);
    });
    const filter = useCallback((value, search) => {
        // Due to a known issue (https://github.com/pacocoursey/cmdk/issues/199),
        // multiple items with the same title are highlighted.
        // To work around this we add a hidden stepUUID to the search.
        // However this breaks the search functionality.
        // Therefore, we need to remove the stepUUID from the text node to filter
        // the results correctly.
        const valueWithoutStepUUID = value.replace(/step_[^\s]*\s/, '');
        if (valueWithoutStepUUID.includes(search))
            return 1;
        return 0;
    }, []);
    return (_jsxs(Combobox.Root, { variant: "single-select", children: [_jsx(Combobox.Trigger, { hasPlaceholder: false, width: "full", truncate: true, "data-testid": "select-property", size: "intermediate", className: "group", children: currentName }), _jsxs(Combobox.Content, { filter: filter, headerInputSize: "intermediate", children: [_jsx(Combobox.Empty, {}), Object.entries(groupedFilters).map(([group, filters]) => {
                        return (_jsx(Combobox.Group, { heading: group, children: filters.map((filter) => (_jsx(ComboboxFilterItem, { filter: filter, value: value, onChange: onChange }, filter.value))) }, group));
                    })] })] }));
});
const ComboboxFilterItem = observer(function ComboboxFilterItem(props) {
    const { filter, value, onChange } = props;
    const intl = useIntl();
    const userTest = useGetUserTest();
    const step = getStepProps(userTest, filter.filterDef.stepUUID || filter.filterDef.operand);
    return (_jsx(Combobox.Item, Object.assign({ selected: value === filter.value, value: `${filter.filterDef.stepUUID} ${step.name} ${step.title} ${filter.filterDef.name}`.toLowerCase(), disabled: filter.disabled, onSelect: () => onChange(filter.value), "data-testid": `combobox-list-item-filter-name-select-${filter.filterDef.id}` }, (filter.filterDef.stepUUID
        ? {
            'data-step-uuid': filter.filterDef.stepUUID,
        }
        : undefined), { className: "group", children: _jsx(BtwTooltip, { content: filter.disabled
                ? 'Filter unavailable: No more options left to apply.'
                : '', children: _jsxs("div", { children: [_jsxs(Stack, { direction: "row", gap: "1", align: "center", children: [_jsxs("div", { className: "hidden", "aria-hidden": "true", children: [filter.filterDef.stepUUID, ' '] }), step.name && (_jsx(Stack, { direction: "row", gap: "1", align: "center", children: _jsxs(_Fragment, { children: [_jsx(BtwText, { size: "xs", weight: "semibold", children: formatStringOrMessage(intl, step.name) }), filter.filterDef.name && (_jsxs(_Fragment, { children: [_jsx(ChevronRightIcon, { className: "size-2.5 bg-transparent text-gray-500" }), _jsx(BtwText, { size: "xs", weight: "semibold", children: filter.filterDef.name })] }))] }) }))] }), _jsx(BtwText, { truncate: true, className: "relative h-5 w-full", size: "13", variant: "primary", weight: "normal", children: step.title ? (_jsx(TruncatedTextContainer, { title: formatStringOrMessage(intl, step.title) })) : (filter.filterDef.name) })] }) }) }), filter.filterDef.id));
});
const FilterItem = observer(function FilterItem({ stepUUID, filterName, }) {
    const intl = useIntl();
    const userTest = useGetUserTest();
    const step = getStepProps(userTest, stepUUID);
    const stepTitleRef = useRef(null);
    const [isTruncated, setIsTruncated] = useState(false);
    useEffect(() => {
        const container = stepTitleRef.current;
        if (container) {
            const { clientWidth, scrollWidth } = container;
            if (scrollWidth > clientWidth)
                setIsTruncated(true);
        }
    }, [step.title]);
    // General filter
    if (!stepUUID)
        return _jsx("div", { children: filterName });
    // Step filter
    return (_jsxs(Stack, { direction: "row", gap: "2", className: cn('min-w-0 transition-all duration-500 ease-in-out group-hover:[transition-delay:800ms]', `${isTruncated && 'group-hover:gap-0'}`), align: "center", children: [_jsxs(Stack, { direction: "row", gap: "1", align: "center", className: cn(
                // Base classes
                'max-w-sm transition-all duration-500 ease-in-out group-hover:delay-700', 
                // Transitions when the step title is long enough to be truncated
                `${isTruncated && 'group-hover:max-w-0 group-hover:opacity-0'}`), children: [_jsx(BtwText, { size: "13", weight: "medium", children: formatStringOrMessage(intl, step.name) }), filterName && (_jsxs(_Fragment, { children: [_jsx(ChevronRightIcon, { className: "size-2.5 text-gray-500" }), _jsx(BtwText, { size: "13", weight: "medium", children: filterName })] }))] }), _jsx("div", { className: "truncate", ref: stepTitleRef, children: formatStringOrMessage(intl, step.title) })] }));
});
const OperatorSelect = observer(function OperatorSelect({ operators, onChange, value, }) {
    var _a, _b;
    const currentName = (_b = (_a = operators.find((operator) => operator.value === value)) === null || _a === void 0 ? void 0 : _a.name) !== null && _b !== void 0 ? _b : 'Select an operator';
    const isDisabled = !!value && operators.length === 1;
    return (_jsxs(Select.Root, { onValueChange: (operator) => onChange(operator), disabled: isDisabled, children: [_jsx(Select.Trigger, { disabled: isDisabled, testId: "select-operator", size: "intermediate", children: currentName }), _jsx(Select.Content
            // Adding an !important override here, because content defaults max-width to trigger width.
            // In this instance, we want it to grow a little larger due to the operator
            // titles being often quite short, truncating most items.
            , { 
                // Adding an !important override here, because content defaults max-width to trigger width.
                // In this instance, we want it to grow a little larger due to the operator
                // titles being often quite short, truncating most items.
                className: "!max-w-80", children: operators.map((operator, index) => (_jsx(Select.Item
                // Index is fine because the content is static
                // eslint-disable-next-line react/no-array-index-key
                , { value: operator.value, 
                    // Overriding Select item's default font size.
                    className: "[&>div]:text-13", children: operator.name }, index))) })] }));
});
const LogicalOperatorSelect = observer(function LogicalOperatorSelect() {
    const { logicalOperator, setLogicalOperator } = useResponsesContext();
    return (_jsx("div", { children: _jsxs(Select.Root, { onValueChange: (value) => setLogicalOperator(value), children: [_jsx(Select.Trigger, { isTruncated: true, asChild: true, className: "w-20", testId: "select-logical-operator", size: "intermediate", children: logicalOperator.toLowerCase() }), _jsxs(Select.Content, { className: cn('max-w-[auto]'), children: [_jsx(Select.Item, { value: "AND", className: "[&>div]:text-13", testId: "logical-and", children: "and" }), _jsx(Select.Item, { value: "OR", className: "[&>div]:text-13", testId: "logical-or", children: "or" })] })] }) }));
});
const FilterValue = observer(function FilterValue({ type, filterValues = [], onChange, value, placeholder = 'Enter a value…', }) {
    var _a, _b, _c, _d;
    switch (type) {
        case 'enum<boolean>':
        case 'enum<string>':
        case 'enum<number>': {
            const placeholder = 'Select a value...';
            const currentName = (_b = (_a = filterValues.find((filterValue) => filterValue.value === value)) === null || _a === void 0 ? void 0 : _a.name) !== null && _b !== void 0 ? _b : placeholder;
            return (_jsxs(Select.Root, { onValueChange: (value) => {
                    let parsedValue = value;
                    if (type === 'enum<number>') {
                        parsedValue = parseInt(parsedValue);
                    }
                    if (type === 'enum<boolean>') {
                        parsedValue = !!parsedValue;
                    }
                    onChange(parsedValue);
                }, children: [_jsx(Select.Trigger, { asChild: true, width: "full", testId: "select-answer", size: "intermediate", children: _jsxs(Stack, { direction: "row", gap: "2", align: "center", width: "full", children: [getResultStatusIcon(value, 'size-4') ||
                                    getDeviceMakeIcon(value), _jsx("span", { className: "truncate", children: currentName })] }) }), _jsx(Select.Content, { children: filterValues.map((option) => (_jsx(Select.Item, { value: option.value, disabled: option.disabled, className: "[&>div]:text-13", testId: `option-${option.value}`, children: _jsxs(Stack, { direction: "row", gap: "2", align: "center", width: "full", children: [getResultStatusIcon(option.value, 'size-4') || getDeviceMakeIcon(option.value), _jsx("span", { className: "truncate", children: option.name })] }) }, option.name))) })] }));
        }
        case 'string':
            return (_jsx(BtwInput.Root, { width: "full", size: "intermediate", children: _jsx(BtwInput.Field, { id: "filter-value", "data-testid": "filter-value", type: "text", placeholder: placeholder, value: (_c = value) !== null && _c !== void 0 ? _c : '', onChange: (e) => onChange(e.target.value) }) }));
        case 'number':
            return (_jsxs(BtwInput.Root, { width: "full", size: "intermediate", children: [_jsx(BtwInput.Field, { id: "filter-value", "data-testid": "filter-value", type: "number", value: (_d = value) !== null && _d !== void 0 ? _d : '', onChange: (e) => {
                            let newValue = parseFloat(e.target.value);
                            // eslint-disable-next-line no-restricted-globals
                            if (isNaN(newValue)) {
                                newValue = null;
                            }
                            onChange(newValue);
                        }, placeholder: placeholder }), ' '] }));
        case 'duration':
            return (_jsx(BtwInput.Root, { width: "full", className: "flex flex-row", children: _jsx(DurationInputs, { value: value, onChange: onChange }) }));
        case 'date':
            return (_jsx(DatePicker, { value: typeof value === 'number'
                    ? createLocalTimeStampFromDate(value)
                    : undefined, onSelect: (date) => {
                    if (!date)
                        return;
                    onChange(createUTCTimestampFromDate(date));
                }, size: "intermediate" }));
        default:
            return null;
    }
});
function getStepProps(userTest, stepUUID) {
    const emptyStep = { name: '', title: '' };
    if (!stepUUID)
        return emptyStep;
    const step = getStepByUUID(userTest, stepUUID);
    if (!step)
        return emptyStep;
    const isScreenerStep = ScreenerUtils.isScreenerStep(userTest, step.uuid);
    const title = getStepTitle(step);
    const name = getStepName(step, undefined, isScreenerStep);
    return { name, title };
}
function createUTCTimestampFromDate(date) {
    return Date.UTC(date.getFullYear(), date.getMonth(), date.getDate());
}
function createLocalTimeStampFromDate(selectedDateInMs) {
    return new Date(selectedDateInMs +
        new Date(selectedDateInMs).getTimezoneOffset() * 60 * 1000);
}
function getMinutesSeconds(time) {
    if (time && typeof time === 'number') {
        const minutes = Math.floor(time / 60);
        const seconds = time - minutes * 60;
        return { minutes, seconds };
    }
    return { minutes: 0, seconds: 0 };
}
const DurationInputs = observer(function DurationInputs({ value, onChange, }) {
    const { minutes: initialMinutes, seconds: initialSeconds } = getMinutesSeconds(value);
    const hasDurationBeenPreset = initialMinutes > 0 || initialSeconds > 0;
    const [displayMinutes, setDisplayMinutes] = useState(hasDurationBeenPreset ? initialMinutes.toString() : '');
    const [displaySeconds, setDisplaySeconds] = useState(hasDurationBeenPreset ? initialSeconds.toString() : '');
    const setMinuteValue = useCallback((updatedMinutes) => {
        if (isNil(updatedMinutes)) {
            onChange(null);
        }
        else {
            const { seconds } = getMinutesSeconds(value);
            // add seconds to updated minutes
            const newValue = updatedMinutes * 60 + seconds;
            onChange(newValue);
        }
    }, [onChange, value]);
    const onMinutesChange = useCallback((e) => {
        const updatedMinutes = parseFloat(e.target.value);
        if (Number.isNaN(updatedMinutes)) {
            setDisplayMinutes('');
            setMinuteValue(null);
        }
        else {
            if (e.target.value.length > 4)
                return null;
            setDisplayMinutes(e.target.value);
            setMinuteValue(updatedMinutes);
        }
    }, [setMinuteValue]);
    const setSecondsValue = useCallback((updatedSeconds) => {
        if (isNil(updatedSeconds)) {
            onChange(null);
        }
        else {
            const { minutes } = getMinutesSeconds(value);
            // add minutes to updated seconds
            const newValue = minutes * 60 + updatedSeconds;
            onChange(newValue);
        }
    }, [onChange, value]);
    const onSecondsChange = useCallback((e) => {
        const updatedSeconds = parseFloat(e.target.value);
        if (Number.isNaN(updatedSeconds)) {
            setDisplaySeconds('');
            setSecondsValue(null);
        }
        else {
            if (e.target.value.length > 2)
                return null;
            setDisplaySeconds(e.target.value);
            setSecondsValue(updatedSeconds);
        }
    }, [setSecondsValue]);
    // Doing a little dirty here and making these one-off inline labels for the duration inputs
    // If we ever have the need to use these elsewhere, we can componentize this
    const inlineLabelClasses = 'absolute right-3 h-full !text-gray-500 justify-center';
    return (_jsxs(_Fragment, { children: [_jsxs(BtwInput.Root, { size: "intermediate", children: [_jsx(BtwInput.Label, { className: cn(inlineLabelClasses), htmlFor: "filter-value-mins", children: "min" }), _jsx(BtwInput.Field, { className: "w-full", id: "filter-value-mins", "data-testid": "filter-value-mins", type: "number", value: displayMinutes.length ? displayMinutes : '', onChange: onMinutesChange, placeholder: "0" })] }), _jsxs(BtwInput.Root, { size: "intermediate", children: [_jsx(BtwInput.Label, { className: cn(inlineLabelClasses), htmlFor: "filter-value-secs", children: "sec" }), _jsx(BtwInput.Field, { className: "w-full", id: "filter-value-secs", "data-testid": "filter-value-secs", type: "number", value: displaySeconds.length ? displaySeconds : '', onChange: onSecondsChange, placeholder: "0" })] })] }));
});
