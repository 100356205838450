import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { observer } from 'mobx-react';
import { useState } from 'react';
import * as React from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import styled from '@marvelapp/styled';
import { Box } from '@marvelapp/ui';
import { StepUtils } from '@marvelapp/user-test-creator';
import ZoomImageModal from '../../../components/ZoomImageModal';
import { MAX_DECORATIVE_IMAGE_DIMENSIONS_DESKTOP } from '../../../constants/image';
import ContainedImage from '../../Instruction/ContainedImage';
export default observer(function DecoratedSlide({ children, maxWidth = 866, paddingTop, imageUrl, step, zoomedImageUrl, }) {
    const instruction = StepUtils.getReadyInstruction(step);
    const [isModalOpen, setIsModalOpen] = useState(false);
    return (_jsxs(_Fragment, { children: [_jsxs(Box, { display: "flex", flexDirection: 'row', width: "100%", height: "100%", children: [_jsx(Scrollbars, { style: { display: 'flex', flex: 1 }, children: _jsx(Left, { alignItems: ['flex-start', 'flex-start', 'space-between'], flexDirection: "column", hasInstructions: !!instruction, justifyContent: ['flex-start', 'flex-start', 'space-between'], maxWidth: maxWidth, paddingTop: paddingTop, children: children }) }), _jsx(Box, { flex: 1, display: "flex", flexDirection: "column", justifyContent: "center", children: _jsx(Box, { width: "100%", height: "100%", padding: "xl", onClick: () => setIsModalOpen(true), children: _jsx(ContainedImage, { image: imageUrl, maxWidth: MAX_DECORATIVE_IMAGE_DIMENSIONS_DESKTOP.width, maxHeight: MAX_DECORATIVE_IMAGE_DIMENSIONS_DESKTOP.height, cursor: "zoom-in" }) }) })] }), _jsx(ZoomImageModal, { image: zoomedImageUrl, isOpen: isModalOpen, onClose: () => setIsModalOpen(false) })] }));
});
const Left = styled(Box).attrs((props) => ({
    paddingRight: ['m', 'm', 88],
    paddingLeft: ['m', 'm', 88],
    paddingBottom: props.hasInstructions ? [120, 120, 'l'] : [74, 74, 'l'],
})) `
  width: 100%;
  min-height: 100%;
  display: flex;
`;
