export var ResponseStatus;
(function (ResponseStatus) {
    ResponseStatus["InProgress"] = "IN_PROGRESS";
    ResponseStatus["Completed"] = "COMPLETED";
    ResponseStatus["Incomplete"] = "INCOMPLETE";
    ResponseStatus["QualifiedViewed"] = "QUALIFIED_VIEWED";
    ResponseStatus["ScreeningAbandoned"] = "SCREENING_ABANDONED";
    ResponseStatus["ScreeningDeclined"] = "SCREENING_DECLINED";
    ResponseStatus["ScreeningStarted"] = "SCREENING_STARTED";
    ResponseStatus["Rejected"] = "REJECTED";
})(ResponseStatus || (ResponseStatus = {}));
export var RecordingStatus;
(function (RecordingStatus) {
    RecordingStatus["Success"] = "UPLOAD_SUCCESS";
    RecordingStatus["Failed"] = "UPLOAD_FAILED";
    RecordingStatus["Pending"] = "UPLOAD_PENDING";
    RecordingStatus["InProgress"] = "UPLOAD_IN_PROGRESS";
    RecordingStatus["AcquiringMedia"] = "UPLOAD_ACQUIRING_MEDIA";
})(RecordingStatus || (RecordingStatus = {}));
