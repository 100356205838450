import { __awaiter } from "tslib";
import { showErrorNotification } from '@marvelapp/ui-internal';
// Note: for security reason the API should not be exposed client side.
// When moving OpenAI server-side we need to generate a new key
const OPENAI_API_KEY = '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';
export function completions(_a) {
    return __awaiter(this, arguments, void 0, function* ({ prompt, model = 'gpt-3.5-turbo-instruct', temperature = 1, maxTokens, topP = 1, frequencyPenalty = 0, presencePenalty = 0, }) {
        const body = JSON.stringify({
            model,
            prompt,
            temperature,
            max_tokens: maxTokens,
            top_p: topP,
            frequency_penalty: frequencyPenalty,
            presence_penalty: presencePenalty,
        });
        try {
            const response = yield fetch('https://api.openai.com/v1/completions', {
                body,
                headers: {
                    Authorization: `Bearer ${OPENAI_API_KEY}`,
                    'Content-Type': 'application/json',
                },
                method: 'POST',
            });
            const data = yield response.json();
            if (!response.ok) {
                if (response.status === 429) {
                    showErrorNotification({
                        toastId: 'openai-rate-limit',
                        content: 'It looks like there are a lot of requests right now, try again in a few minutes',
                    });
                }
                else {
                    showGenericErrorNotification();
                }
                return [null, false];
            }
            return [data.choices[0].text.trim(), true];
        }
        catch (_b) {
            showGenericErrorNotification();
            return [null, false];
        }
    });
}
function showGenericErrorNotification() {
    showErrorNotification({
        toastId: 'openai-error',
        content: 'Something went wrong, please try again',
    });
}
