import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { observer } from 'mobx-react';
import { Stack } from '@marvelapp/ballpark-components';
import { PhoneIcon, StudioDisplayIcon, TabletIcon, } from '@marvelapp/ballpark-icons';
export const DeviceDataCell = observer(function DeviceDataCell({ device, }) {
    return (_jsxs(Stack, { direction: "row", gap: "2", align: "center", children: [getDeviceIcon(device.platform), _jsx("p", { children: device.device })] }));
});
function getDeviceIcon(platform) {
    if (platform === 'Desktop') {
        return _jsx(StudioDisplayIcon, { className: "size-4" });
    }
    if (platform === 'Mobile') {
        return _jsx(PhoneIcon, { className: "size-4" });
    }
    if (platform === 'Tablet') {
        return _jsx(TabletIcon, { className: "size-4" });
    }
    return null;
}
