import { __rest } from "tslib";
import { jsx as _jsx } from "react/jsx-runtime";
import { Rnd } from 'react-rnd';
/** @param {any} */
function Floaty(_a) {
    var { children, defaultWidth = 'auto', defaultHeight = 'auto', defaultX = 0, defaultY = 0, zIndex = 0, bounds = 'parent', pointerEvents, onDragStart } = _a, rest = __rest(_a, ["children", "defaultWidth", "defaultHeight", "defaultX", "defaultY", "zIndex", "bounds", "pointerEvents", "onDragStart"]);
    // this is needed for marvel-docs because during the build Rnd is not available, which breaks the build
    // please don't remove
    if (!Rnd) {
        return null;
    }
    return (_jsx(Rnd, Object.assign({ bounds: bounds, default: {
            x: defaultX,
            y: defaultY,
            width: defaultWidth,
            height: defaultHeight,
        }, onDragStart: (e) => {
            onDragStart();
            e.preventDefault(); // Avoids cursor selection of elements behind the Floaty
        }, onResizeStart: (e) => {
            e.preventDefault(); // Avoids cursor selection of elements behind the Floaty
        }, style: { zIndex, pointerEvents } }, rest, { children: children })));
}
export default Floaty;
