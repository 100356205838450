import { jsx as _jsx } from "react/jsx-runtime";
import { noop } from 'lodash-es';
import { observer } from 'mobx-react';
import * as React from 'react';
import styled from '@marvelapp/styled';
import { Box } from '@marvelapp/ui';
import EditableButton from '../../components/Editable/EditableButton';
export default observer(function MainCTA({ initialButtonValue, onSaveButtonText = noop, testId, placeholder, readOnly, }) {
    return (_jsx(ButtonWrapper, { children: _jsx(EditableButton, { kind: "primary", onSaveButtonText: onSaveButtonText, placeholder: placeholder, readOnly: readOnly, size: "l", testId: testId, value: initialButtonValue }) }));
});
const ButtonWrapper = styled(Box).attrs({
    marginTop: 's',
}) ``;
