import { __rest } from "tslib";
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { IMAGE_ICON } from '@marvelapp/ballpark-theme';
import Flex from '../Flex';
import Icon from '../Icon';
import Position from '../Position';
import { getCardKind } from './kinds';
import { CardElement } from './styles';
/** @type {any} */
const Card = (_a) => {
    var { children, hasImage, iconSize, isDragged, isSelected } = _a, props = __rest(_a, ["children", "hasImage", "iconSize", "isDragged", "isSelected"]);
    return (_jsxs(CardElement, Object.assign({ boxShadow: getCardKind(isSelected, isDragged) }, props, { children: [!hasImage && (_jsx(Position, { bottom: 0, left: 0, position: "absolute", right: 0, top: 0, children: _jsx(Flex, { alignItems: "center", height: "100%", justifyContent: "center", children: _jsx(Icon, { fill: "smokeExtraDark", height: iconSize, paths: IMAGE_ICON, width: iconSize }) }) })), children] })));
};
Card.defaultProps = {
    bg: 'snow',
    borderRadius: 2,
    hasImage: true,
    iconSize: 32,
};
/** @type {any} */
export default Card;
