import { jsx as _jsx } from "react/jsx-runtime";
import { observer } from 'mobx-react';
import * as React from 'react';
import { useRouteMatch } from 'react-router-dom';
import { BtwButton, Link } from '@marvelapp/ballpark-components';
import { DownloadIcon } from '@marvelapp/ballpark-icons';
import { useResponsesContext } from '../ResponsesContext';
import { useCreateDataQueryFromState } from '../responseQueryUtils';
import { useDownloadCSV } from './useDownloadCSV';
export const MoreActionsDropdown = observer(function MoreActionsDropdown() {
    const { totalFilteredResults } = useResponsesContext();
    const isDisabled = totalFilteredResults === 0;
    const match = useRouteMatch();
    const projectPk = match.params.id;
    const dataQuery = useCreateDataQueryFromState();
    const { triggerDownload, isDownloadCSVAvailable, isLoading } = useDownloadCSV({ projectPk, dataQuery });
    if (!isDownloadCSVAvailable) {
        return (_jsx(Link, { to: "/plans?intent=csv-download", children: _jsx(BtwButton, { testId: "table-actions-dropdown", disabled: isDisabled, variant: "ghost", leadingIcon: _jsx(DownloadIcon, {}), isLoading: isLoading, size: "intermediate", children: "Download CSV" }) }));
    }
    return (_jsx(BtwButton, { testId: "download-csv", disabled: isDisabled, variant: "ghost", onClick: triggerDownload, leadingIcon: _jsx(DownloadIcon, {}), isLoading: isLoading, size: "intermediate", children: "Download CSV" }));
});
