import { __rest } from "tslib";
import { jsx as _jsx } from "react/jsx-runtime";
import { isNil } from 'lodash-es';
import { observer } from 'mobx-react';
import { useCallback } from 'react';
import * as React from 'react';
import { useAction } from '@marvelapp/ballpark-application';
import styled from '@marvelapp/styled';
import { Box } from '@marvelapp/ui';
import { QuestionResponseUtils, QuestionStepType, } from '@marvelapp/user-test-creator';
import { useStateFromProp } from '../../hooks/useStateFromProp';
import SlideRecord from '../common/SlideRecord';
export default observer(function BaseQuestionRecord(_a) {
    var { renderInput, stepResponse, type = QuestionStepType.Text } = _a, rest = __rest(_a, ["renderInput", "stepResponse", "type"]);
    const questionAnswer = QuestionResponseUtils.getAnswer(stepResponse);
    // saving state to the store causes a rerender which causes issues with the cursor
    // losing its place in the input so we need to save an internal state of the
    // input's value to get around this
    const [internalValue, setInternalValue] = useStateFromProp(
    // the value can be 0, we don't want to revert to placeholder in those cases
    !isNil(questionAnswer) ? questionAnswer : '');
    const setAnswer = useAction((value) => {
        QuestionResponseUtils.setAnswer(stepResponse, 0, value, type);
    }, [stepResponse, type]);
    const onChange = useCallback((value) => {
        setInternalValue(value);
        setAnswer(value);
    }, [setAnswer, setInternalValue]);
    return (_jsx(SlideRecord, Object.assign({}, rest, { children: _jsx(InputContainer, { children: renderInput({ value: internalValue, onChange }) }) })));
});
const InputContainer = styled(Box).attrs({
    mt: 's',
    mb: 'xxs',
}) `
  width: 100%;
  max-width: 500px;
`;
