export var ScreenerStep;
(function (ScreenerStep) {
    ScreenerStep["Intro"] = "screener-intro-step";
    ScreenerStep["Screener"] = "screener-step";
    ScreenerStep["Decline"] = "screener-decline-step";
})(ScreenerStep || (ScreenerStep = {}));
export var RecordingStatus;
(function (RecordingStatus) {
    RecordingStatus["Success"] = "UPLOAD_SUCCESS";
    RecordingStatus["Failed"] = "UPLOAD_FAILED";
    RecordingStatus["Pending"] = "UPLOAD_PENDING";
    RecordingStatus["InProgress"] = "UPLOAD_IN_PROGRESS";
    RecordingStatus["AcquiringMedia"] = "UPLOAD_ACQUIRING_MEDIA";
})(RecordingStatus || (RecordingStatus = {}));
export var RecordingFailureType;
(function (RecordingFailureType) {
    RecordingFailureType["ClientStreaming"] = "CLIENT_STREAMING";
    RecordingFailureType["HardwarePermissions"] = "HARDWARE_PERMISSIONS";
    RecordingFailureType["Server"] = "SERVER";
})(RecordingFailureType || (RecordingFailureType = {}));
