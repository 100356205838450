import { __awaiter } from "tslib";
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import LogRocket from 'logrocket';
import { useCallback, useState } from 'react';
import { useConfig } from '@marvelapp/ballpark-application';
import { BtwLink } from '@marvelapp/ballpark-components';
import { ArrowTopRightIcon } from '@marvelapp/ballpark-icons';
import { showSuccessNotification } from '@marvelapp/ui-internal';
export function useCheckFigmaPermissions() {
    const [response, setResponse] = useState(null);
    const config = useConfig();
    const checkPermissions = useCallback((prototypeUrl, notifyOnSuccess) => __awaiter(this, void 0, void 0, function* () {
        setResponse('pending');
        try {
            const proxyUrl = getProxyUrl(prototypeUrl, config);
            const response = yield fetch(proxyUrl.toString());
            const [code, message] = getCodeAndMessageForResponse(response.status, `Builder`);
            setResponse({
                code,
                message,
            });
            if (response.status === 200 && notifyOnSuccess) {
                showSuccessNotification({
                    toastId: 'figma-permissions-valid',
                    content: (_jsx("span", { "data-testid": "figma-permissions-valid", children: "Looks good! This prototype can be accessed by all testers" })),
                });
            }
        }
        catch (error) {
            setResponse({ code: 200, message: _jsx(_Fragment, {}) });
        }
    }), [config]);
    const resetPermissionsError = useCallback(() => {
        setResponse(null);
    }, []);
    return {
        checkPermissions,
        resetPermissionsError,
        response,
        isLoading: response === 'pending',
    };
}
export function getCodeAndMessageForResponse(code, page) {
    if (code === 401) {
        LogRocket.track(`${page}PasswordProtectedPrototype`);
        return [
            401,
            _jsx(_Fragment, { children: "It looks like there has been a password set on this prototype. Please disable password protection and check again." }),
        ];
    }
    if (code === 403 || code === 412) {
        LogRocket.track(`${page}NonPublicPrototype`);
        return [
            code,
            _jsxs(_Fragment, { children: ["This prototype is not available publicly and will not be accessible to testers. Please change the permission on the file to \u2018Anyone can view\u2019 and try again.", ' ', _jsxs(BtwLink, { variant: "current", className: "[&>svg]:-ml-0.5 [&>svg]:inline-block [&>svg]:h-3.5 [&>svg]:w-3.5", href: "https://help.ballparkhq.com/en/articles/8329830-how-to-add-a-figma-prototype-share-link-to-your-test-or-survey", children: ["Learn more ", _jsx(ArrowTopRightIcon, {})] }), ' '] }),
        ];
    }
    if (code === 409) {
        LogRocket.track(`${page}VersionBugPrototype`);
        return [
            code,
            _jsxs(_Fragment, { children: ["Note: Testers will be shown the live version of this prototype.", ' ', _jsxs(BtwLink, { variant: "current", className: "[&>svg]:-ml-0.5 [&>svg]:inline-block [&>svg]:h-3.5 [&>svg]:w-3.5", href: "https://help.ballparkhq.com/en/articles/8329835-how-to-manually-update-your-figma-or-marvel-prototype-in-your-task", children: ["Learn more ", _jsx(ArrowTopRightIcon, {})] })] }),
        ];
    }
    return [200, _jsx(_Fragment, {})];
}
export function getProxyUrl(embedUrl, config) {
    if (!embedUrl) {
        throw new Error('No embed URL provided');
    }
    const proxy = new URL(embedUrl);
    const pie = new URL(config.pie.host);
    proxy.host = pie.host;
    proxy.protocol = pie.protocol;
    return proxy;
}
