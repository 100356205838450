import { MultipleChoiceStepUtils, StepClass, StepUtils, TextUtils, } from '@marvelapp/user-test-creator';
export function getAnswerOptions({ step, searchTerm = '', // TODO remove the search stuff from this function
 }) {
    const formattedOptions = [];
    if (StepUtils.isOfType(step, StepClass.MultipleChoice)) {
        MultipleChoiceStepUtils.mapChoices(step, (choiceValue, choiceUUID) => {
            // only include options that have text in conditional answer list
            if (choiceValue &&
                choiceValue.toLowerCase().includes(searchTerm.toLowerCase())) {
                formattedOptions.push({ choiceUUID, choiceValue });
            }
            return null;
        });
    }
    if (StepUtils.isOfType(step, StepClass.PreferenceTest)) {
        step.choices.forEach((choiceUUID, index) => {
            const choiceValue = step.choiceDefinitions[choiceUUID];
            if (choiceValue &&
                choiceValue.choiceTitle.toLowerCase().includes(searchTerm.toLowerCase())) {
                const formattedValue = choiceValue.choiceTitle.length > 0
                    ? choiceValue.choiceTitle
                    : `Choice ${index + 1}`;
                formattedOptions.push({ choiceUUID, choiceValue: formattedValue });
            }
        });
        return formattedOptions;
    }
    if (StepUtils.isOfType(step, StepClass.TaglineCopyTest)) {
        step.choices.forEach((choiceUUID) => {
            let formattedValue = '';
            const choice = step.choiceDefinitions[choiceUUID];
            const subHeading = TextUtils.richTextToString(choice.paragraph);
            if (choice.heading) {
                if (choice.heading.toLowerCase().includes(searchTerm.toLowerCase())) {
                    formattedValue = choice.heading;
                }
            }
            else if (subHeading &&
                subHeading.toLowerCase().includes(searchTerm.toLowerCase())) {
                formattedValue = subHeading;
            }
            if (formattedValue.length > 0) {
                formattedOptions.push({ choiceUUID, choiceValue: formattedValue });
            }
        });
    }
    return formattedOptions;
}
export function getDisplayValue(mutable, uuid) {
    var _a;
    if (mutable.className === StepClass.PreferenceTest) {
        return {
            label: mutable.choiceDefinitions[uuid].choiceTitle ||
                `Choice ${Object.keys(mutable.choiceDefinitions).indexOf(uuid) + 1}`,
            value: uuid,
        };
    }
    if (mutable.className === StepClass.TaglineCopyTest &&
        mutable.choiceDefinitions[uuid]) {
        const choice = mutable.choiceDefinitions[uuid];
        return {
            label: (choice === null || choice === void 0 ? void 0 : choice.heading) || TextUtils.richTextToString(choice === null || choice === void 0 ? void 0 : choice.paragraph),
            value: uuid,
        };
    }
    if (mutable.className === StepClass.MultipleChoice) {
        const choice = (_a = mutable.choiceDefinitions[uuid]) !== null && _a !== void 0 ? _a : '';
        return {
            label: choice,
            value: uuid,
        };
    }
    return null;
}
export function createRequirement(operator, combinator, value) {
    return {
        operator,
        combinator,
        value: value.value,
    };
}
export function updateRequirement(requirement, value) {
    // eslint-disable-next-line no-param-reassign
    requirement.value = value.value;
}
