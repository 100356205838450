import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { observer } from 'mobx-react';
import { Button, Link, Text } from '@marvelapp/ballpark-components';
import { Box } from '@marvelapp/ui';
import { FigmaIcon } from '../../../icons';
export const FigmaProTip = observer(function FigmaProTip() {
    return (_jsxs(Box, { borderRadius: "m", bg: "pink3", padding: "m", display: "flex", alignItems: "center", gridGap: "m", position: [
            'relative',
            'relative',
            'relative',
            'relative',
            'relative',
            'absolute',
        ], bottom: [undefined, undefined, undefined, undefined, undefined, 0], left: [undefined, undefined, undefined, undefined, undefined, 0], margin: "m", "data-testid": "figma-pro-tip", children: [_jsx(Box, { width: 32, transform: "scale(1.5)", children: _jsx(FigmaIcon, {}) }), _jsxs(Text, { fontSize: 2, children: [_jsx("strong", { children: "Pro tip:" }), " Figma auto-loads the entire file that powers your prototype. Please copy only the frames you need to a new file to prevent large downloads for participants."] }), _jsx(Box, { children: _jsx(Button, { as: Link, kind: "ghost", href: "https://help.ballparkhq.com/hc/en-us/articles/8430019855889-Optimising-your-Figma-and-Marvel-prototypes-for-testing", children: "Learn more" }) })] }));
});
