import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { observer } from 'mobx-react';
import * as React from 'react';
import { useGetUserTest } from '@marvelapp/ballpark-application';
import theme from '@marvelapp/ballpark-theme';
import styled from '@marvelapp/styled';
import { Loader } from '@marvelapp/ui';
import { BuilderSidebarPane } from '../../components/SidebarPane';
import ActiveSlides from './ActiveSlides';
import { AddStepSidebar } from './AddStepSidebar';
export default observer(function ActiveSlidesSidebar({ isAddSidebarOpen, isLoading, onAddNewStep, onCloseAddSidebar, }) {
    const userTest = useGetUserTest();
    if (isLoading || !userTest) {
        return (_jsx(Container, { children: _jsx(BuilderSidebarPane, { children: _jsx(LoaderWrapper, { children: _jsx(Loader, {}) }) }) }));
    }
    return (_jsxs(Container, { "data-testid": "active-slides-sidebar", children: [_jsx(ActiveSlides, { onAddNewStep: onAddNewStep }), _jsx(AddStepSidebar, { isOpen: isAddSidebarOpen, onClose: onCloseAddSidebar })] }));
});
const Container = styled.div `
  display: flex;
  flex-direction: column;
  height: calc(100vh - ${theme.sizes.barHeight}px);
  position: relative;
`;
const LoaderWrapper = styled.div `
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;
