import styled, { css, keyframes } from '@marvelapp/styled';
import { Box } from '@marvelapp/ui';
export const fadeInKeyframes = keyframes `
  0% { opacity: 0; }
  100% { opacity: 1; }
`;
const FadeIn = styled(Box) `
  opacity: 0;
  ${(props) => props.animate &&
    css `
      animation: ${fadeInKeyframes} 1s ease-in-out 1 forwards;
    `};
`;
export default FadeIn;
