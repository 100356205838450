import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { observer } from 'mobx-react';
import { useRef } from 'react';
import { Stack, cn } from '@marvelapp/ballpark-components';
import { AlignRightIcon } from '@marvelapp/ballpark-icons';
import { useRecruitmentState } from '../RecruitmentContext';
import { RecruitmentSection } from '../common';
import { DetailedTargetingFooter } from './DetailedTargetingFooter';
import { FilterGroupsColumn } from './FilterGroupsColumn';
import { FilterOptionsColumn } from './FilterOptionsColumn';
import { FilterSearch } from './FilterSearch';
import { FiltersColumn } from './FiltersColumn';
export const DetailedTargeting = observer(function Attributes() {
    const recruitmentState = useRecruitmentState();
    const { filters } = recruitmentState;
    const filterGroupsCount = filters.other.originalFilterGroupOrder.length;
    const collisionContainerRef = useRef(null);
    return (_jsxs(Stack, { width: "full", className: "divide-y divide-gray-600/10 rounded-xl bg-white ring-1 ring-gray-600/10", ref: collisionContainerRef, children: [_jsx(FilterSearch, { collisionContainerRef: collisionContainerRef.current }), _jsxs(RecruitmentSection, { className: cn('rounded-t-none ring-0'), children: [_jsxs(Stack, { className: cn(
                        // Base styles
                        // Height of the scrollable area minus header and footer
                        'h-[520px] divide-x divide-gray-600/10', 
                        // Setting even width to all columns because responsively
                        // it somehow mucks things up without it.
                        filterGroupsCount > 1 ? '*:w-1/3' : '*:w-1/2', 
                        // Adding some magic sauce responsive styles to the 3-column layout
                        filterGroupsCount > 1 && [
                            // Base styling for the container on smallest viewport
                            'relative overflow-hidden pl-16 sm:pl-0',
                            // Special rules for the first column
                            'first:*:w-64 sm:first:*:w-1/3',
                            // Transition styles
                            '*:ease-smooth *:transition-all *:duration-300',
                        ]), direction: "row", width: "full", align: "stretch", children: [_jsx(FilterGroupsColumn, {}), _jsx(FiltersColumn, {}), _jsx(FilterOptionsColumn, {}), _jsx(AlignRightIcon, { className: "absolute left-5 top-[18px] z-50 !size-5 border-none peer-hover:z-10 peer-hover:translate-x-48 peer-hover:opacity-0 sm:hidden" })] }), _jsx(DetailedTargetingFooter, {})] })] }));
});
