import { __rest } from "tslib";
import { jsx as _jsx } from "react/jsx-runtime";
import { observer } from 'mobx-react';
import * as React from 'react';
import { useLocation } from 'react-router-dom';
import styled from '@marvelapp/styled';
import { Box } from '@marvelapp/ui';
import { Link } from '@marvelapp/ui-internal';
export default observer(function Tab(_a) {
    var { children, isActive, onClick, tabLink } = _a, props = __rest(_a, ["children", "isActive", "onClick", "tabLink"]);
    const location = useLocation();
    const active = isActive || location.pathname === tabLink;
    return (_jsx(LinkWrapper, { isActive: active, tabLink: tabLink, children: _jsx(TabElement, Object.assign({ borderColor: active ? 'primary' : 'transparent', color: active ? 'slate12' : 'slate11', cursor: active ? 'default' : 'pointer', fontWeight: active ? 'medium' : 'regular', hoverBorderColor: active ? 'primaryHighlight' : 'slate7', onClick: onClick }, props, { children: children })) }));
});
const LinkWrapper = observer(function LinkWrapper({ children, isActive, tabLink, }) {
    return !isActive ? _jsx(TabLink, { to: tabLink, children: children }) : children;
});
const TabLink = styled(Link) `
  line-height: inherit;
  height: 100%;
`;
const TabElement = styled(Box).attrs({
    fontSize: 's',
    mx: 'xs',
    transition: 'smooth.fast',
    hoverColor: 'slate12',
    px: 'xxs',
}) `
  display: flex;
  align-items: center;
  border-style: solid;
  border-bottom-width: 4px;
  height: 100%;
`;
