import { __rest } from "tslib";
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { observer } from 'mobx-react';
import * as React from 'react';
import { useIntl } from 'react-intl';
import { Button, Icon } from '@marvelapp/ballpark-components';
import { CLOSE_ICON } from '@marvelapp/ballpark-theme';
import styled from '@marvelapp/styled';
import { Box } from '@marvelapp/ui';
import constants from './constants';
export default observer(function CloseButton(_a) {
    var { onClick } = _a, rest = __rest(_a, ["onClick"]);
    const intl = useIntl();
    return (_jsxs(Button, Object.assign({ alignItems: "center", "data-testid": "close-instructions", display: "flex", kind: "ghost", marginLeft: "xs", onClick: onClick, paddingLeft: "6px", size: "s" }, rest, { children: [_jsx(CloseIconContainer, { children: _jsx(Icon, { paths: CLOSE_ICON }) }), _jsx(Box, { children: intl.formatMessage(constants.CLOSE_INSTRUCTIONS) })] })));
});
const CloseIconContainer = styled(Box) `
  display: flex;
  align-items: center;
  width: 28px;
`;
