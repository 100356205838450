import { jsx as _jsx } from "react/jsx-runtime";
import { observer } from 'mobx-react';
import { useEffect, useRef } from 'react';
import * as React from 'react';
import styled from '@marvelapp/styled';
import { Box } from '@marvelapp/ui';
export const StepBox = observer(function StepBox({ active, children, id, notAnswered, testId, kind, }) {
    return (_jsx(StepContainer, { active: active, id: id, notAnswered: notAnswered, testId: testId, kind: kind, children: children }));
});
export const StepContainer = observer(function StepContainer({ active, children, id, notAnswered, testId, kind, }) {
    const ref = useRef(null);
    useEffect(() => {
        if (active && ref.current) {
            ref.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
    }, [active]);
    return (_jsx(Container, { border: "1px solid", "data-active": !!active, borderColor: getBorderColor({ active, notAnswered }), boxShadow: getBoxShadow({ active, notAnswered }), "data-testid": testId, id: id, ref: ref, kind: kind, children: children }));
});
function getBorderColor({ active, notAnswered }) {
    if (active && notAnswered) {
        return 'slate8';
    }
    if (active && !notAnswered) {
        return 'blue8';
    }
    return 'slate6';
}
function getBoxShadow({ active, notAnswered }) {
    if (!notAnswered && active) {
        return 'typeCard.active';
    }
    if (notAnswered && active) {
        return 'typeCard.skipped';
    }
    return 'button.secondary';
}
export const Container = styled(Box).attrs((props) => ({
    bg: 'background',
    borderRadius: 'xl',
    marginX: 'auto',
    width: props.kind === 'summary' ? '100%' : undefined,
})) `
  position: relative;
  &:last-child {
    margin-bottom: 0;
  }
`;
