import { __rest } from "tslib";
import { jsx as _jsx } from "react/jsx-runtime";
import { observer } from 'mobx-react';
import * as React from 'react';
import { useParams } from 'react-router-dom';
import { PrototypeStepType, StepClass, StepUtils, isExitStep, } from '@marvelapp/user-test-creator';
import { StepContext, useStepContext } from '../../utils/stepContext';
import { getSortedAnswers } from './getSortedAnswers';
import { SummaryCardSorting } from './steps/SummaryCardSorting/SummaryCardSorting';
import SummaryFirstClick from './steps/SummaryFirstClick';
import SummaryFiveSecond from './steps/SummaryFiveSecond';
import { SummaryMultipleChoice } from './steps/SummaryMultipleChoice';
import SummaryPreference from './steps/SummaryPreference';
import SummaryPrototype from './steps/SummaryPrototype';
import SummaryQuestion from './steps/SummaryQuestion';
import { SummaryRating } from './steps/SummaryRating';
import { SummaryStep } from './steps/SummaryStep';
import SummaryTagline from './steps/SummaryTagline';
import SummaryWebsite from './steps/SummaryWebsite';
import SummaryYesNo from './steps/SummaryYesNo';
const StepSummary = observer(function StepSummary(_a) {
    var _b, _c, _d, _e;
    var { active } = _a, rest = __rest(_a, ["active"]);
    const { step, summary, userTest } = useStepContext();
    const { id } = useParams();
    const commonProps = Object.assign({ active, showAllLink: StepUtils.isOfType(step, StepClass.Instruction) ||
            StepUtils.isOfType(step, StepClass.MediaSettings)
            ? ''
            : `/project/${id}/responses?s=${step.uuid}` }, rest);
    if (!summary) {
        // If there is no summary, it often means that the step was recently added
        // and there are no responses for it yet (hence no aggregates either)
        return _jsx(SummaryStep, Object.assign({}, commonProps));
    }
    const type = summary.__typename;
    switch (type) {
        case 'StepAggregateInstructionNode':
            return _jsx(SummaryStep, Object.assign({}, commonProps));
        case 'StepAggregateMultipleChoiceNode': {
            const multipleChoiceStep = step;
            return (_jsx(SummaryMultipleChoice, Object.assign({}, commonProps, { stats: summary.choiceStats, sortedChoices: getSortedAnswers((_b = multipleChoiceStep === null || multipleChoiceStep === void 0 ? void 0 : multipleChoiceStep.choices) !== null && _b !== void 0 ? _b : [], summary.choiceStats) })));
        }
        case 'StepAggregatePreferenceTestNode': {
            const preferenceStep = step;
            return (_jsx(SummaryPreference, Object.assign({}, commonProps, { stats: summary.preferenceStats, sortedChoices: getSortedAnswers((_c = preferenceStep === null || preferenceStep === void 0 ? void 0 : preferenceStep.choices) !== null && _c !== void 0 ? _c : [], summary.preferenceStats) })));
        }
        case 'StepAggregateTaglineCopyTestNode': {
            const taglineCopyStep = step;
            return (_jsx(SummaryTagline, Object.assign({}, commonProps, { stats: summary.taglineStats, sortedChoices: getSortedAnswers((_d = taglineCopyStep === null || taglineCopyStep === void 0 ? void 0 : taglineCopyStep.choices) !== null && _d !== void 0 ? _d : [], summary.taglineStats) })));
        }
        case 'StepAggregatePrototypeTaskNode': {
            const prototypeStep = step;
            if (StepUtils.isOfPrototypeSubType(prototypeStep, PrototypeStepType.FirstClick)) {
                return (_jsx(SummaryFirstClick, Object.assign({}, commonProps, { stats: summary.prototypeStats })));
            }
            return (_jsx(SummaryPrototype, Object.assign({}, commonProps, { prototypeStats: summary.prototypeStats, showHeatmaps: !!summary.totalVisitsCompleted })));
        }
        case 'StepAggregateQuestionNode':
            return (_jsx(SummaryQuestion, Object.assign({}, commonProps, { stats: summary.questionAnswers })));
        case 'StepAggregateYesOrNoNode':
            return _jsx(SummaryYesNo, Object.assign({}, commonProps, { stats: summary.radioButtonStats }));
        case 'StepAggregateRatingScaleNode':
            return (_jsx(SummaryRating, Object.assign({}, commonProps, { averageRating: summary.averageAnswer })));
        case 'StepAggregateFiveSecondTestNode':
            return _jsx(SummaryFiveSecond, Object.assign({}, commonProps));
        case 'StepAggregateWebsiteTaskNode':
            return (_jsx(SummaryWebsite, Object.assign({}, commonProps, { averageDuration: ((_e = summary.mediaStats) === null || _e === void 0 ? void 0 : _e.averageRecordingDuration) ||
                    summary.averageStepDuration })));
        case 'StepAggregateCardsortNode': {
            const { cardAgreement, categoryAgreementAvg, categoryFrequency, categoryTotals, cardSimilarity, categoryPositionAvg, } = summary;
            // These type assertions remove the need for null checks
            const cardSortProps = {
                cardAgreement: cardAgreement,
                categoryAgreementAvg: categoryAgreementAvg,
                categoryFrequency: categoryFrequency,
                categoryTotals: categoryTotals,
                cardSimilarity: cardSimilarity,
                categoryPositionAvg: categoryPositionAvg,
                userTestUUID: userTest.uuid,
            };
            return _jsx(SummaryCardSorting, Object.assign({}, commonProps, cardSortProps));
        }
        default:
            return null;
    }
});
export const StepCard = observer(function StepCard({ hasOpenAiSummary, isActiveStep, totalStarts, step, stepStats, userTest, isScreenerStep = false, backingProjectPk, }) {
    if (!stepStats || !backingProjectPk)
        return null;
    const currentStepStats = stepStats.find((stepStat) => (stepStat === null || stepStat === void 0 ? void 0 : stepStat.stepResponseUuid) === step.uuid);
    const dividerProps = {
        totalStarts,
        isExitStep: isExitStep(userTest, step.uuid),
    };
    return (_jsx(StepContext.Provider, { value: {
            step,
            userTest,
            backingProjectPk,
            summary: currentStepStats !== null && currentStepStats !== void 0 ? currentStepStats : null,
        }, children: _jsx(StepSummary, { active: isActiveStep, hasOpenAiSummary: hasOpenAiSummary, dividerProps: dividerProps, isScreenerStep: isScreenerStep }) }));
});
