/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.
/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.
//==============================================================
// START Enums and Input Objects
//==============================================================
export var CreateRoomErrorEnum;
(function (CreateRoomErrorEnum) {
    CreateRoomErrorEnum["INVALID_PARAMETERS"] = "INVALID_PARAMETERS";
    CreateRoomErrorEnum["INVALID_ROOM_NAME"] = "INVALID_ROOM_NAME";
    CreateRoomErrorEnum["PERMISSION_DENIED"] = "PERMISSION_DENIED";
    CreateRoomErrorEnum["REQUEST_FAILED"] = "REQUEST_FAILED";
    CreateRoomErrorEnum["ROOM_ALREADY_EXISTS"] = "ROOM_ALREADY_EXISTS";
    CreateRoomErrorEnum["TEST_RESULT_NOT_FOUND"] = "TEST_RESULT_NOT_FOUND";
    CreateRoomErrorEnum["USER_TEST_NOT_FOUND"] = "USER_TEST_NOT_FOUND";
})(CreateRoomErrorEnum || (CreateRoomErrorEnum = {}));
export var LiveKitEgressTypeEnum;
(function (LiveKitEgressTypeEnum) {
    LiveKitEgressTypeEnum["ROOM_COMPOSITE_EGRESS"] = "ROOM_COMPOSITE_EGRESS";
    LiveKitEgressTypeEnum["TRACK_COMPOSITE_EGRESS"] = "TRACK_COMPOSITE_EGRESS";
    LiveKitEgressTypeEnum["TRACK_EGRESS"] = "TRACK_EGRESS";
})(LiveKitEgressTypeEnum || (LiveKitEgressTypeEnum = {}));
export var StartVideoEgressErrorEnum;
(function (StartVideoEgressErrorEnum) {
    StartVideoEgressErrorEnum["AUDIO_OR_VIDEO_ID_NOT_SUPPLIED_FOR_STARTING_TRACK_COMPOSITE_EGRESS"] = "AUDIO_OR_VIDEO_ID_NOT_SUPPLIED_FOR_STARTING_TRACK_COMPOSITE_EGRESS";
    StartVideoEgressErrorEnum["BAD_EGRESS_TYPE"] = "BAD_EGRESS_TYPE";
    StartVideoEgressErrorEnum["PERMISSION_DENIED"] = "PERMISSION_DENIED";
    StartVideoEgressErrorEnum["REQUEST_FAILED"] = "REQUEST_FAILED";
    StartVideoEgressErrorEnum["TEST_RECORDING_DOES_NOT_EXIST"] = "TEST_RECORDING_DOES_NOT_EXIST";
    StartVideoEgressErrorEnum["TRACK_ID_NOT_SUPPLIED_FOR_STARTING_TRACK_EGRESS"] = "TRACK_ID_NOT_SUPPLIED_FOR_STARTING_TRACK_EGRESS";
})(StartVideoEgressErrorEnum || (StartVideoEgressErrorEnum = {}));
export var StopVideoEgressErrorEnum;
(function (StopVideoEgressErrorEnum) {
    StopVideoEgressErrorEnum["PERMISSION_DENIED"] = "PERMISSION_DENIED";
    StopVideoEgressErrorEnum["REQUEST_FAILED"] = "REQUEST_FAILED";
    StopVideoEgressErrorEnum["TEST_RECORDING_DOES_NOT_EXIST"] = "TEST_RECORDING_DOES_NOT_EXIST";
})(StopVideoEgressErrorEnum || (StopVideoEgressErrorEnum = {}));
//==============================================================
// END Enums and Input Objects
//==============================================================
