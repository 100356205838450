import { jsx as _jsx } from "react/jsx-runtime";
import { observer } from 'mobx-react';
import { useGetUserTest } from '@marvelapp/ballpark-application';
import { Stack } from '@marvelapp/ballpark-components';
import { StepClass, StepUtils, getStepByUUID, } from '@marvelapp/user-test-creator';
import { useConditionalLogicState } from '../ConditionalLogicContext';
import BaseLogic from './conditionalLogic/BaseLogic';
import { YesNoConditionalLogic } from './conditionalLogic/boolean/booleanLogic';
import { ChoiceConditionalLogic } from './conditionalLogic/choice/choiceLogic';
import { DateConditionalLogic } from './conditionalLogic/date/dateLogic';
import { NumericalConditionalLogic } from './conditionalLogic/numerical/numericalLogic';
import { PrototypeGoalConditionalLogic } from './conditionalLogic/prototypeTask/prototypeTaskLogic';
import { TextConditionalLogic } from './conditionalLogic/text/textLogic';
export const RulesContent = observer(function RulesContent() {
    const userTest = useGetUserTest();
    const { activeStepUUID } = useConditionalLogicState();
    const step = getStepByUUID(userTest, activeStepUUID);
    if (!step)
        return null;
    const isYesNoStep = StepUtils.isYesNoStep(step);
    const isChoiceStep = StepUtils.isChoiceStep(step);
    const isGoalBasedPrototypeStep = StepUtils.isGoalBasedPrototype(step);
    const isNumericStep = StepUtils.isNumericalStep(step);
    const isRatingStep = StepUtils.isOfType(step, StepClass.RatingScale);
    const isEmailStep = StepUtils.isEmailStep(step);
    const isTextStep = StepUtils.isTextStep(step);
    const isDateStep = StepUtils.isDateStep(step);
    if (isYesNoStep)
        return _jsx(YesNoConditionalLogic, { step: step });
    if (isChoiceStep)
        return _jsx(ChoiceConditionalLogic, { step: step });
    if (isGoalBasedPrototypeStep)
        return _jsx(PrototypeGoalConditionalLogic, { step: step });
    if (isNumericStep)
        return _jsx(NumericalConditionalLogic, { step: step });
    if (isRatingStep)
        return _jsx(NumericalConditionalLogic, { step: step });
    if (isDateStep)
        return _jsx(DateConditionalLogic, { step: step });
    if (isTextStep || isEmailStep)
        return _jsx(TextConditionalLogic, { step: step });
    return (_jsx(Stack, { width: "full", className: "h-full bg-gray-50", children: _jsx(BaseLogic, {}) }, activeStepUUID));
});
