import { gql, useQuery } from '@apollo/client';
import { useMemo } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { mapFilterNullish } from '@marvelapp/core';
const GET_LIVE_PROJECT_SESSIONS = gql `
  query getLiveProjectSessions($pk: Int) {
    userTest(pk: $pk) {
      pk
      uuid
      prototype {
        isLocked
      }
      liveSessions(first: 25) {
        edges {
          node {
            observers {
              avatarUrl
              displayName
            }
            researchers {
              avatarUrl
              displayName
            }
            shareLinks {
              panelShareLink
            }
            testRecording {
              egressStartTime
              pk
              roomName
              roomStartTime
            }
          }
        }
      }
    }
  }
`;
export function useLiveProjectSessions() {
    var _a, _b, _c, _d;
    const match = useRouteMatch();
    const projectPk = match.params.id;
    const { data, loading } = useQuery(GET_LIVE_PROJECT_SESSIONS, {
        variables: { pk: Number(projectPk) },
    });
    const userTest = (data === null || data === void 0 ? void 0 : data.userTest)
        ? {
            uuid: data.userTest.uuid,
            prototype: {
                isLocked: (_b = (_a = data.userTest.prototype) === null || _a === void 0 ? void 0 : _a.isLocked) !== null && _b !== void 0 ? _b : false,
            },
        }
        : null;
    const liveSessions = useMemo(() => {
        var _a, _b, _c;
        return mapFilterNullish((_c = (_b = (_a = data === null || data === void 0 ? void 0 : data.userTest) === null || _a === void 0 ? void 0 : _a.liveSessions) === null || _b === void 0 ? void 0 : _b.edges) !== null && _c !== void 0 ? _c : [], (edge) => {
            if (!(edge === null || edge === void 0 ? void 0 : edge.node))
                return null;
            const { node: liveSession } = edge;
            const { observers, researchers, testRecording, shareLinks } = liveSession;
            if (!testRecording || !shareLinks)
                return null;
            return {
                observers: formatUserInfo(observers),
                moderators: formatUserInfo(researchers),
                roomName: testRecording.roomName,
                shareUrl: shareLinks.panelShareLink,
                startTime: testRecording.egressStartTime || testRecording.roomStartTime,
                status: testRecording.egressStartTime ? 'Live' : 'Waiting',
            };
        });
    }, [(_d = (_c = data === null || data === void 0 ? void 0 : data.userTest) === null || _c === void 0 ? void 0 : _c.liveSessions) === null || _d === void 0 ? void 0 : _d.edges]);
    return {
        isLoadingLiveSessions: loading,
        liveSessions,
        userTest,
    };
}
function formatUserInfo(users) {
    if (!users)
        return [];
    return mapFilterNullish(users, (user) => {
        if (!user || !user.displayName)
            return null;
        const { avatarUrl, displayName } = user;
        return {
            avatarUrl: avatarUrl !== null && avatarUrl !== void 0 ? avatarUrl : '',
            displayName,
        };
    });
}
