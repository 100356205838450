'use client';
import { __rest } from "tslib";
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useFormInputProps } from '@udecode/plate-common';
import { flip, offset, } from '@udecode/plate-floating';
import { FloatingLinkUrlInput, LinkOpenButton, useFloatingLinkEdit, useFloatingLinkEditState, useFloatingLinkInsert, useFloatingLinkInsertState, } from '@udecode/plate-link';
import { forwardRef, useCallback } from 'react';
import * as React from 'react';
import { BtwButton, BtwInput, Stack, cn, } from '@marvelapp/ballpark-components';
import { ArrowTopRightIcon, LinkIcon, NotesIcon, PencilIcon, TrashCanIcon, } from '@marvelapp/ballpark-icons';
const floatingOptions = {
    middleware: [
        offset(12),
        flip({
            fallbackPlacements: ['bottom-end', 'top-start', 'top-end'],
            padding: 12,
        }),
    ],
    placement: 'bottom-start',
};
export function LinkFloatingToolbar({ state }) {
    const insertState = useFloatingLinkInsertState(Object.assign(Object.assign({}, state), { floatingOptions: Object.assign(Object.assign({}, floatingOptions), state === null || state === void 0 ? void 0 : state.floatingOptions) }));
    const { hidden, props: insertProps, ref: insertRef, textInputProps, } = useFloatingLinkInsert(insertState);
    const editState = useFloatingLinkEditState(Object.assign(Object.assign({}, state), { floatingOptions: Object.assign(Object.assign({}, floatingOptions), state === null || state === void 0 ? void 0 : state.floatingOptions) }));
    const { editButtonProps, props: editProps, ref: editRef, unlinkButtonProps, } = useFloatingLinkEdit(editState);
    const inputProps = useFormInputProps({
        preventDefaultOnEnterKeydown: true,
    });
    const preventDefault = useCallback((e) => e.preventDefault(), []);
    if (hidden)
        return null;
    const input = (_jsxs(Stack, Object.assign({ gap: "3", className: "w-[330px] p-4" }, inputProps, { children: [_jsx(BtwInput.Root, { width: "full", leadingIcon: _jsx(LinkIcon, {}), children: _jsx(FloatingLinkUrlInput, { asChild: true, placeholder: "https://your-link.url/", "data-testid": "link-input", children: _jsx(BtwInput.Field, { id: "link-input" }) }) }), _jsx(BtwInput.Root, { width: "full", leadingIcon: _jsx(NotesIcon, {}), children: _jsx(BtwInput.Field, Object.assign({ id: "text-input", placeholder: "Text to display" }, textInputProps)) })] })));
    const editContent = editState.isEditing ? (input) : (_jsxs(Stack, { gap: "1", children: [_jsx(PopoverButton, Object.assign({ leadingIcon: _jsx(PencilIcon, {}), 
                // this is very important to prevent the editor from losing focus inbetween the URL input
                // appearing and gaining focus which will prevent the URL input from opening
                onMouseDown: preventDefault }, editButtonProps, { children: "Edit link" })), _jsx(PopoverButton, { leadingIcon: _jsx(ArrowTopRightIcon, {}), asChild: true, 
                // this is very important to prevent the editor from losing focus and closing the popover
                // before the link is opened
                onMouseDown: preventDefault, children: _jsx(LinkOpenButton, { children: "Open link" }) }), _jsx(PopoverButton, Object.assign({ leadingIcon: _jsx(TrashCanIcon, {}) }, unlinkButtonProps, { onMouseDown: preventDefault, children: "Remove link" }))] }));
    return (_jsxs(_Fragment, { children: [_jsx(Popover, Object.assign({ ref: insertRef }, insertProps, { children: input })), _jsx(Popover, Object.assign({ ref: editRef }, editProps, { children: editContent }))] }));
}
const Popover = forwardRef(function Popover(props, ref) {
    const { className } = props, rest = __rest(props, ["className"]);
    return (_jsx("div", Object.assign({ className: cn('p-1.5', 'w-auto', 'outline-none', 'print:hidden', 'border-none', 'ring-1', 'bg-white', 'shadow-xl', 'rounded-xl', 'ring-gray-600/10', className), ref: ref }, rest)));
});
const PopoverButton = forwardRef(function PopoverButton(props, ref) {
    return (_jsx(BtwButton, Object.assign({ className: "justify-start font-medium", ref: ref, size: "intermediate", type: "button", variant: "ghost", width: "full" }, props)));
});
