import { transact } from '@ballpark/realtime-crdt';
import { MouseSensor, TouchSensor, closestCenter, getFirstCollision, pointerWithin, rectIntersection, useSensor, useSensors, } from '@dnd-kit/core';
import { action } from 'mobx';
import { useCallback, useEffect, useReducer, useRef, useState } from 'react';
import { RingBuffer } from 'ring-buffer-ts';
import { CardSortingResponseUtils, } from '@marvelapp/user-test-creator';
export const UNSORTED_ID = 'unsorted-cards';
export const ADD_NEW_CATEGORY_ID = 'add-new-category';
const CARD_CATEGORY_MOVES_HISTORY_LENGTH = 10;
// Adapted from:
// https://github.com/clauderic/dnd-kit/blob/694dcc2f62e5269541fc941fa6c9af46ccd682ad/stories/2%20-%20Presets/Sortable/MultipleContainers.tsx#L151
export function useMultiContainerDndContextProps({ stepResponse, step, categoryUUIDs, addCategory, }) {
    const getItems = useCallback(() => {
        const unsortedCardUUIDs = CardSortingResponseUtils.getUnsortedCardUUIDs(stepResponse, step);
        const _items = {
            [UNSORTED_ID]: unsortedCardUUIDs,
        };
        categoryUUIDs.forEach((categoryUUID) => {
            _items[categoryUUID] = [];
        });
        CardSortingResponseUtils.getCardCategoryMapping(stepResponse).forEach(([cardUUID, categoryUUID]) => {
            if (!_items[categoryUUID]) {
                _items[categoryUUID] = [];
            }
            _items[categoryUUID].push(cardUUID);
        });
        return _items;
    }, [step, stepResponse, categoryUUIDs]);
    const itemsRef = useRef(getItems());
    useState(null);
    const activeOriginalContainerRef = useRef(null);
    const activeIdRef = useRef(null);
    const lastOverIdRef = useRef(null);
    const recentlyMovedToNewContainer = useRef(false);
    // Note: this is a workaround to force a re-render of the cards on drag start
    // and drag end. The previous implementation used state to store activeId and
    // lastOverId, which caused stack overflow issues ("Maximum update depth exceeded")
    // coming from the dnd-kit library.
    // See for example: https://github.com/clauderic/dnd-kit/issues/900
    // Until these issues are resolved, we use a forceUpdate function to trigger a
    // re-render. We might be able to revert https://github.com/marvelapp/mkiii/pull/5435
    // when the dnd-kit issues are resolved.
    const forceUpdate = useReducer(() => ({}), {})[1];
    // keep track of card moves so we can detect an unstable position when a card
    // flip flops between two categories
    const moves = useRef(new RingBuffer(CARD_CATEGORY_MOVES_HISTORY_LENGTH));
    const recomputeItems = useCallback(() => {
        itemsRef.current = getItems();
    }, [getItems]);
    useEffect(() => {
        recomputeItems();
    }, [recomputeItems]);
    // Custom collision detection strategy optimized for multiple containers
    // - First, find any droppable containers intersecting with the pointer.
    // - If there are none, find intersecting containers with the active draggable.
    // - If there are no intersecting containers, return the last matched intersection
    const collisionDetection = useCallback((args) => {
        var _a;
        const items = itemsRef.current;
        if (activeIdRef.current && activeIdRef.current in items) {
            return closestCenter(Object.assign(Object.assign({}, args), { droppableContainers: args.droppableContainers.filter((container) => container.id in items) }));
        }
        // Start by finding any intersecting droppable
        const pointerIntersections = pointerWithin(args);
        const intersections = pointerIntersections.length > 0
            ? // If there are droppables intersecting with the pointer, return those
                pointerIntersections
            : rectIntersection(args);
        let overId = getFirstCollision(intersections, 'id');
        if (overId != null) {
            if (overId in items) {
                const containerItems = items[overId];
                // If a container is matched and it contains items
                if (containerItems.length > 0) {
                    // Return the closest droppable within that container
                    overId = (_a = closestCenter(Object.assign(Object.assign({}, args), { droppableContainers: args.droppableContainers.filter((container) => container.id !== overId &&
                            containerItems.includes(container.id)) }))[0]) === null || _a === void 0 ? void 0 : _a.id;
                }
            }
            lastOverIdRef.current = overId;
            return [{ id: overId }];
        }
        // When a draggable item moves to a new container, the layout may shift
        // and the `overId` may become `null`. We manually set the cached `lastOverId`
        // to the id of the draggable item that was moved to the new container, otherwise
        // the previous `overId` will be returned which can cause items to incorrectly shift positions
        if (recentlyMovedToNewContainer.current) {
            lastOverIdRef.current = activeIdRef.current;
        }
        // If no droppable is matched, return the last match
        return lastOverIdRef.current ? [{ id: lastOverIdRef.current }] : [];
    }, []);
    const sensors = useSensors(useSensor(MouseSensor), useSensor(TouchSensor));
    const findContainer = useCallback((id) => {
        const items = itemsRef.current;
        if (id in items) {
            return id;
        }
        return Object.keys(items).find((key) => items[key].includes(id)) || null;
    }, []);
    const onDragStart = useCallback(({ active }) => {
        activeIdRef.current = active.id.toString();
        activeOriginalContainerRef.current = findContainer(active.id);
        forceUpdate();
    }, [findContainer, forceUpdate]);
    const onDragOver = useCallback(({ active, over }) => {
        const overId = over === null || over === void 0 ? void 0 : over.id;
        const items = itemsRef.current;
        if (overId == null || active.id in items) {
            return;
        }
        const overContainer = findContainer(overId);
        const activeContainer = findContainer(active.id);
        if (!overContainer || !activeContainer) {
            return;
        }
        if (activeContainer !== overContainer) {
            const overItems = items[overContainer];
            const overIndex = overItems.indexOf(overId);
            let newIndex;
            if (overId in items) {
                newIndex = overItems.length + 1;
            }
            else {
                const isBelowOverItem = over &&
                    active.rect.current.translated &&
                    active.rect.current.translated.top >
                        over.rect.top + over.rect.height;
                const modifier = isBelowOverItem ? 1 : 0;
                newIndex =
                    overIndex >= 0 ? overIndex + modifier : overItems.length + 1;
            }
            recentlyMovedToNewContainer.current = true;
            if (overContainer === UNSORTED_ID) {
                if (activeOriginalContainerRef.current === UNSORTED_ID) {
                    // Cards cannot be dragged back to the unsorted container,
                    // unless they were originally there
                    CardSortingResponseUtils.removeCardFromCategory(stepResponse, active.id);
                    recomputeItems();
                }
            }
            else {
                moves.current.add([active.id, overContainer, Date.now()]);
                const flipFlopping = checkForUnstablePosition(moves.current);
                if (flipFlopping) {
                    // Cancel the move to prevent getting into an infinite loop that
                    // ultimately causes react to throw an error
                    return;
                }
                transact(stepResponse, action(() => {
                    CardSortingResponseUtils.moveCard({
                        stepResponse,
                        cardUUID: active.id,
                        categoryUUID: overContainer,
                        index: newIndex,
                    });
                }));
                recomputeItems();
            }
        }
    }, [findContainer, stepResponse, recomputeItems]);
    const onDragEnd = useCallback(({ active, over }) => {
        const items = itemsRef.current;
        forceUpdate();
        const activeContainer = findContainer(active.id);
        if (!activeContainer) {
            activeIdRef.current = null;
            return;
        }
        const overId = over === null || over === void 0 ? void 0 : over.id;
        if (overId == null) {
            activeIdRef.current = null;
            return;
        }
        // When a card is dragged over the "Add new category" button,
        // create a new category and move the card to it
        if (overId === ADD_NEW_CATEGORY_ID) {
            const newCategoryUUID = addCategory();
            if (!newCategoryUUID)
                return;
            CardSortingResponseUtils.moveCard({
                stepResponse,
                cardUUID: active.id,
                categoryUUID: newCategoryUUID,
                index: 0,
            });
            return;
        }
        const overContainer = findContainer(overId);
        if (overContainer) {
            const activeIndex = items[activeContainer].indexOf(active.id);
            const overIndex = items[overContainer].indexOf(overId);
            if (overContainer === UNSORTED_ID) {
                if (activeOriginalContainerRef.current === UNSORTED_ID) {
                    // Cards cannot be dragged back to the unsorted container,
                    // unless they were originally there
                    CardSortingResponseUtils.removeCardFromCategory(stepResponse, active.id);
                    recomputeItems();
                }
            }
            else if (activeIndex !== overIndex) {
                CardSortingResponseUtils.moveCard({
                    stepResponse,
                    cardUUID: active.id,
                    categoryUUID: overContainer,
                    index: overIndex,
                });
                recomputeItems();
            }
        }
        activeIdRef.current = null;
        activeOriginalContainerRef.current = null;
    }, [addCategory, findContainer, stepResponse, recomputeItems, forceUpdate]);
    const onDragCancel = useCallback(() => {
        // TODO: Investigate when dragcancel is triggered
        console.log('drag cancelled');
        activeIdRef.current = null;
        activeOriginalContainerRef.current = null;
        forceUpdate();
    }, [forceUpdate]);
    useEffect(() => {
        requestAnimationFrame(() => {
            recentlyMovedToNewContainer.current = false;
        });
    }, []);
    return {
        collisionDetection,
        sensors,
        onDragStart,
        onDragOver,
        onDragEnd,
        onDragCancel,
        activeId: activeIdRef.current,
    };
}
function eq(move1, move2) {
    return move1[0] === move2[0] && move1[1] === move2[1];
}
/**
 * Checks for an unstable position in card movements to prevent recursive
 * rendering errors.
 *
 * It's possible to drag a card to a position where it moves from one category
 * to another, say A -> B. This causes a layout shift (as cat A gets smaller,
 * cat B gets larger) which can cause the card to move back to cat A. The layout
 * shifts back again, causing the card to move back to cat B, and so on.
 *
 * This recursive loop eventually causes React to throw an error.
 *
 * To prevent this, we check for a card flip-flopping between the same two
 * categories in the last moveCount moves.
 *
 */
function checkForUnstablePosition(moves, moveCount = CARD_CATEGORY_MOVES_HISTORY_LENGTH) {
    // We need at least moveCount moves to detect a reliable pattern
    if (moves.getBufferLength() === moveCount) {
        const currentMove = moves.get(0);
        const previousMove = moves.get(1);
        if (!currentMove || !previousMove)
            return false;
        // Check for alternating A-B-A-B pattern in the last moveCount moves
        for (let i = 2; i < moveCount; i += 2) {
            const move1 = moves.get(i);
            const move2 = moves.get(i + 1);
            if (!move1 || !move2)
                return false;
            if (!eq(currentMove, move1) || !eq(previousMove, move2)) {
                // Pattern broken, not an unstable position
                return false;
            }
        }
        // At this point we've detected a flip-flopping pattern
        return true;
    }
    // Not enough moves to detect a pattern
    return false;
}
