import { __rest } from "tslib";
import { jsx as _jsx } from "react/jsx-runtime";
import { PlateElement, PlateLeaf, useElement, } from '@udecode/plate-common';
import { useLink } from '@udecode/plate-link';
import { forwardRef } from 'react';
import * as React from 'react';
import { BtwLink } from '@marvelapp/ballpark-components';
/**
 * Paragraph element for all paragraphs
 */
const ParagraphElement = forwardRef(function ParagraphElement(props, ref) {
    const { children } = props, rest = __rest(props, ["children"]);
    return (_jsx(PlateElement, Object.assign({ asChild: true, ref: ref }, rest, { children: _jsx("p", { className: "mb-3 text-gray-600 last:mb-0", children: children }) })));
});
/**
 * Link element for styled links
 */
const LinkElement = forwardRef(function LinkElement(props, ref) {
    const { children, className } = props, rest = __rest(props, ["children", "className"]);
    const element = useElement();
    const { props: linkProps } = useLink({ element });
    return (_jsx(PlateElement, Object.assign({ asChild: true, ref: ref }, linkProps, rest, { children: _jsx(BtwLink, { children: children }) })));
});
/**
 * Ul element for unordered lists
 */
const UlElement = forwardRef(function UlElement(props, ref) {
    const { children } = props, rest = __rest(props, ["children"]);
    return (_jsx(PlateElement, Object.assign({ asChild: true, ref: ref }, rest, { children: _jsx("ul", { className: "mb-3 ml-8 list-disc last:mb-0", children: children }) })));
});
/**
 * Ol element for ordered lists
 */
const OlElement = forwardRef(function OlElement(props, ref) {
    const { children } = props, rest = __rest(props, ["children"]);
    return (_jsx(PlateElement, Object.assign({ asChild: true, ref: ref }, rest, { children: _jsx("ol", { className: "mb-3 ml-8 list-decimal last:mb-0", children: children }) })));
});
/**
 * Li element for list items
 */
const LiElement = forwardRef(function LiElement(props, ref) {
    const { children } = props, rest = __rest(props, ["children"]);
    return (_jsx(PlateElement, Object.assign({ asChild: true, ref: ref }, rest, { children: _jsx("li", { className: "my-1.5 text-gray-600 last:mb-0", children: children }) })));
});
/**
 * Strikethrough element for strikethrough text
 */
const StrikethroughElement = forwardRef(function StrikethroughElement(props, ref) {
    const { children } = props, rest = __rest(props, ["children"]);
    return (_jsx(PlateLeaf, Object.assign({ asChild: true, ref: ref }, rest, { children: _jsx("s", { className: "line-through", children: children }) })));
});
/**
 * Strong element for bold text
 */
const StrongElement = forwardRef(function StrongElement(props, ref) {
    const { children } = props, rest = __rest(props, ["children"]);
    return (_jsx(PlateLeaf, Object.assign({ asChild: true, ref: ref }, rest, { children: _jsx("strong", { className: "font-semibold text-gray-900", children: children }) })));
});
/**
 * Em element for italic text
 */
const EmElement = forwardRef(function EmElement(props, ref) {
    const { children } = props, rest = __rest(props, ["children"]);
    return (_jsx(PlateLeaf, Object.assign({ asChild: true, ref: ref }, rest, { children: _jsx("em", { className: "italic", children: children }) })));
});
export const Element = {
    Em: EmElement,
    Li: LiElement,
    Link: LinkElement,
    Ol: OlElement,
    Paragraph: ParagraphElement,
    Strikethrough: StrikethroughElement,
    Strong: StrongElement,
    Ul: UlElement,
};
