import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import * as React from 'react';
import TextareaAutosize from 'react-textarea-autosize';
import { useKey } from '@marvelapp/hooks';
import styled from '@marvelapp/styled';
import { Box, inputStyles } from '@marvelapp/ui';
import CheckboxIcon from './CheckBoxIcon';
const EditableCheckbox = React.forwardRef(({ isDisabled, isSelected, maxLength, onBlur, onChange, onCheckboxClick, onFocus, placeholder, testId = 'text-edit-checkbox', value, }, ref) => {
    const [hover, setHover] = useState(false);
    useKey(onBlur, { key: 'Escape' });
    return (_jsxs(Relative, { onMouseEnter: () => setHover(true), onMouseLeave: () => setHover(false), children: [_jsx(Textarea, { disabled: isDisabled, "data-testid": testId, isSelected: isSelected, maxLength: maxLength, onBlur: onBlur, onChange: onChange, onFocus: onFocus, placeholder: placeholder, ref: ref, value: value }), !isDisabled && (_jsxs(FakeButton, { onClick: onCheckboxClick, role: "button", tabIndex: -1, children: [isSelected && (_jsx(PinTR, { color: "primaryHighlight", "data-testid": "fake-checkbox", children: _jsx(CheckboxIcon, {}) })), !isSelected && hover && (_jsx(PinTR, { color: "primaryHighlight", children: _jsx(Checkbox, {}) }))] }))] }));
});
const FakeButton = styled.div `
  cursor: pointer;
`;
const Relative = styled.div `
  position: relative;
`;
const PinTR = styled(Box) `
  position: absolute;
  top: 50%;
  margin-top: -12px;
  right: ${(props) => props.theme.space.s}px;
`;
const Checkbox = styled(Box).attrs({
    bg: 'background',
    border: '1px solid',
    borderColor: 'slate8',
    borderRadius: 's',
}) `
  width: 24px;
  height: 24px;
`;
const Textarea = styled(TextareaAutosize).attrs((props) => ({
    fontFamily: 0,
    fontWeight: 'medium',
    fontSize: ['s', 's', 'm'],
    bg: props.isSelected ? 'blue3' : 'background',
    color: props.isSelected ? 'blue12' : 'slate12',
    hoverBg: props.isSelected ? 'blue4' : 'slate2',
    lineHeight: 'heading',
    border: '1px solid',
    borderColor: props.isSelected ? 'blue6' : 'slate6',
    focusBorderColor: 'blue6',
    focusColor: 'blue12',
    focusBg: 'blue3',
    borderRadius: 'l',
    boxShadow: 'button.secondary',
})) `
  ${inputStyles};

  display: block;
  transition: none;
  resize: none;

  padding-left: ${(props) => props.theme.space.s}px;
  padding-right: ${(props) => props.theme.space.xxl}px;

  padding-top: ${(props) => props.theme.space.s}px;
  padding-bottom: ${(props) => props.theme.space.s}px;

  min-height: 0;
  letter-spacing: -0.2px;

  width: 100%;

  &:not(:focus) {
    cursor: pointer;
  }

  &::placeholder {
    color: ${(props) => props.theme.colors.slate12};
    opacity: 1;
    letter-spacing: -0.2px;
    line-height: inherit;
  }

  &:focus {
    &::placeholder {
      color: ${(props) => props.theme.colors.blue12};
      opacity: 0.5;
    }
  }
`;
export default EditableCheckbox;
