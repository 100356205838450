import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { throttle } from 'lodash-es';
import PropTypes from 'prop-types';
import { Component, createRef } from 'react';
export default class InfiniteScroll extends Component {
    constructor(props) {
        super(props);
        this.sentinelRef = createRef();
        // We need to keep a reference to the element we are adding listeners to
        // because the ref is null by the time we try to destroy
        this.listenerElement = null;
        this.checkScroll = () => {
            if (this.props.isLoading) {
                return;
            }
            const isUsingWindow = !this.props.containerEl;
            const sentinelOffset = this.sentinelRef.current.getBoundingClientRect().bottom;
            const containerOffset = isUsingWindow
                ? // window is static so `innerHeight` will always be equivalent to its bottom value
                    window.innerHeight
                : this.props.containerEl.getBoundingClientRect().bottom;
            const { hasMore, threshold, onLoadMore } = this.props;
            // We measure how far below the container the sentinel currently is
            if (hasMore && sentinelOffset - containerOffset < threshold) {
                onLoadMore();
            }
        };
        const handler = throttle(this.checkScroll, props.throttle);
        this.scrollHandler = handler;
        this.resizeHandler = handler;
        this.sentinelPosition = { top: null, left: null };
    }
    componentDidMount() {
        const container = this.getContainer();
        // On first mount the ref we pass in might be null because the parent has
        // not mounted yet. React calls componentDidMount and applies refs upwards
        if (container) {
            this.setupListeners(container);
        }
    }
    componentDidUpdate() {
        const container = this.getContainer();
        if (container !== this.listenerElement) {
            if (this.listenerElement) {
                this.destroyListeners(this.listenerElement);
            }
            if (container) {
                this.setupListeners(container);
            }
        }
        const position = this.sentinelRef.current.getBoundingClientRect();
        const prevPosition = this.sentinelPosition;
        if (position.top !== prevPosition.top ||
            position.left !== prevPosition.left) {
            this.checkScroll();
            this.sentinelPosition = position;
        }
    }
    componentWillUnmount() {
        const container = this.getContainer();
        this.destroyListeners(container);
    }
    /* eslint-disable class-methods-use-this */
    getContainer() {
        // If we have passed in prop.containerRef, it means that we want to use a
        // custom container rather than window. So we should NEVER retun window in
        // this situation
        if (this.props.containerEl) {
            return this.props.containerEl;
        }
        return window;
    }
    setupListeners(container) {
        container.addEventListener('scroll', this.scrollHandler);
        container.addEventListener('resize', this.resizeHandler);
        this.checkScroll();
        // After setting up, store a reference to the container to both allow us
        // to destory in the future and mark the listeners as set up
        this.listenerElement = container;
    }
    destroyListeners(container) {
        if (!container)
            return;
        container.removeEventListener('scroll', this.scrollHandler);
        container.removeEventListener('resize', this.resizeHandler);
        this.listenerElement = null;
    }
    render() {
        return (_jsxs("div", { children: [this.props.children, _jsx("div", { ref: this.sentinelRef })] }));
    }
}
InfiniteScroll.propTypes = {
    // Does the resource have more entities,
    hasMore: PropTypes.bool,
    // Should show loading
    isLoading: PropTypes.bool.isRequired,
    // Callback to load more entities
    onLoadMore: PropTypes.func.isRequired,
    // Scroll threshold
    threshold: PropTypes.number.isRequired,
    // Throttle rate
    throttle: PropTypes.number.isRequired,
    // Your content
    children: PropTypes.node.isRequired,
    containerEl: PropTypes.object,
};
InfiniteScroll.defaultProps = {
    threshold: 100,
    throttle: 64,
};
