import { jsx as _jsx } from "react/jsx-runtime";
import { useQuery } from '@apollo/client';
import { get } from 'lodash-es';
import { usePermissions } from '@marvelapp/ballpark-application';
import ShareModal from './ShareModal';
import { getCollaborators, getCompanyMembers, getSharingData, queryMeta, } from './queries';
import useAddCollaboratorsMutation from './useAddCollaboratorsMutation';
import useRemoveCollaboratorsMutation from './useRemoveCollaboratorsMutation';
export default function ShareModalContainer({ itemName, itemId }) {
    const shareIdNotPk = typeof itemId === 'string';
    // project is passed as the item but you use the shareId to get it we need to use the PK for the
    // other queries
    const useItemPk = shareIdNotPk && itemName === 'project';
    const variables = {};
    if (shareIdNotPk) {
        variables.itemId = itemId;
    }
    else {
        variables.itemPk = itemId;
    }
    const { data, loading } = useQuery(getSharingData(itemName), {
        variables,
    });
    const item = get(data, itemName);
    const itemPk = get(item, 'pk');
    const permissions = usePermissions();
    const canSearchCollaborators = permissions.canSearchCollaboratorsList();
    const canEditCollaborators = permissions.canEditCollaborators();
    const canInvitePeopleToCompany = permissions.canInvitePeopleToCompany();
    const [addCollaborators] = useAddCollaboratorsMutation(itemName, useItemPk ? itemPk : itemId);
    const [removeCollaborators] = useRemoveCollaboratorsMutation(itemName, useItemPk ? itemPk : itemId);
    const { memberEdgeAnnotation, nodeIndexKey } = queryMeta[itemName];
    const collaboratorQueries = [
        {
            name: 'Members',
            query: getCollaborators(itemName),
            dataConnection: `${itemName}.collaborators`,
            variables: {
                itemId: useItemPk ? itemPk : itemId,
            },
            onRemove: (person) => {
                removeCollaborators({
                    itemPk,
                    items: [person[nodeIndexKey]],
                });
            },
        },
    ];
    const collaboratorSearchQueries = [
        {
            name: 'Members',
            query: getCompanyMembers(itemName),
            dataConnection: 'user.company.members',
            variables: {
                itemPk,
            },
            searchVariable: 'username',
            isCollaborator: (collaborator) => collaborator[memberEdgeAnnotation],
            onAdd: (collaborator) => addCollaborators({
                itemPk,
                collaborators: [collaborator],
            }),
        },
    ];
    return (_jsx(ShareModal, { canInvitePeopleToCompany: canInvitePeopleToCompany, canEditCollaborators: canEditCollaborators, canSearchCollaborators: canSearchCollaborators, collaboratorQueries: collaboratorQueries, collaboratorSearchQueries: collaboratorSearchQueries, itemId: useItemPk ? itemPk : itemId, itemName: itemName, itemToShare: item, loading: loading, name: get(item, 'name'), ownerPk: get(item, 'owner.pk') }));
}
