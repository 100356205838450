import { Combinator, } from '../../../userTest/types';
export function getRules(step) {
    if (step.conditions && step.conditions.rules) {
        return step.conditions.rules;
    }
    return [];
}
export function getRule({ mutable, ruleIndex, }) {
    var _a, _b, _c;
    if (mutable.conditions) {
        return (_c = (_b = (_a = mutable.conditions) === null || _a === void 0 ? void 0 : _a.rules) === null || _b === void 0 ? void 0 : _b[ruleIndex]) !== null && _c !== void 0 ? _c : null;
    }
    return null;
}
export function getRuleRequirements({ mutable, ruleIndex, }) {
    var _a, _b;
    if (mutable.conditions && ((_a = mutable.conditions) === null || _a === void 0 ? void 0 : _a.rules)) {
        return (_b = mutable.conditions.rules[ruleIndex].requirements) !== null && _b !== void 0 ? _b : null;
    }
    return null;
}
export function getRequirement({ mutable, ruleIndex, requirementIndex, }) {
    var _a, _b, _c;
    if (mutable.conditions && ((_b = (_a = mutable.conditions) === null || _a === void 0 ? void 0 : _a.rules) === null || _b === void 0 ? void 0 : _b[ruleIndex])) {
        return ((_c = mutable.conditions.rules[ruleIndex].requirements[requirementIndex]) !== null && _c !== void 0 ? _c : null);
    }
    return null;
}
// urgh, addNewRule is an implementation detail of the UI so maybe should just
// live in the UI code
export function addNewRule({ mutable, createRequirement, }) {
    addRule({
        mutable,
        operator: null,
        combinator: Combinator.And,
        destinationStepUUID: null,
        value: null,
        createRequirement,
    });
}
export function addRule({ mutable, operator, combinator = Combinator.And, destinationStepUUID, value, createRequirement, }) {
    var _a;
    const requirement = createRequirement(combinator, operator, value);
    const rule = {
        destinationStepUUID,
        requirements: [requirement],
    };
    if (mutable.conditions) {
        if ((_a = mutable.conditions) === null || _a === void 0 ? void 0 : _a.rules) {
            mutable.conditions.rules.push(rule);
        }
        else {
            mutable.conditions.rules = [rule];
        }
    }
    else {
        // support for documents that don't have this conditions object yet
        mutable.conditions = {
            rules: [
                {
                    destinationStepUUID,
                    requirements: [requirement],
                },
            ],
            ALWAYS: null,
        };
    }
}
export function addNewRequirement({ mutable, ruleIndex, combinator = Combinator.And, createRequirement, }) {
    var _a, _b;
    // update / create requirement
    if (mutable.conditions && ((_a = mutable.conditions) === null || _a === void 0 ? void 0 : _a.rules)) {
        (_b = mutable.conditions) === null || _b === void 0 ? void 0 : _b.rules[ruleIndex].requirements.push(createRequirement(combinator));
    }
}
export function deleteRule(mutable, index) {
    var _a;
    if (mutable.conditions && ((_a = mutable.conditions) === null || _a === void 0 ? void 0 : _a.rules)) {
        mutable.conditions.rules.splice(index, 1);
    }
}
export function deleteRequirement(mutable, ruleIndex, requirementIndex) {
    var _a;
    if (mutable.conditions && mutable.conditions.rules) {
        // if this is the last requirement, delete the entire rule
        if (mutable.conditions.rules[ruleIndex].requirements.length <= 1) {
            deleteRule(mutable, ruleIndex);
        }
        else {
            (_a = mutable.conditions) === null || _a === void 0 ? void 0 : _a.rules[ruleIndex].requirements.splice(requirementIndex, 1);
        }
    }
}
export function updateRuleDestination({ mutable, ruleIndex, destinationStepUUID, }) {
    var _a;
    if (mutable.conditions && ((_a = mutable.conditions) === null || _a === void 0 ? void 0 : _a.rules)) {
        if (mutable.conditions.rules[ruleIndex])
            mutable.conditions.rules[ruleIndex].destinationStepUUID =
                destinationStepUUID;
    }
}
export function updateRequirementCombinator({ mutable, ruleIndex, requirementIndex, combinator, }) {
    var _a;
    if (mutable.conditions && ((_a = mutable.conditions) === null || _a === void 0 ? void 0 : _a.rules))
        mutable.conditions.rules[ruleIndex].requirements[requirementIndex].combinator = combinator;
}
export function updateRequirementValue({ mutable, ruleIndex, value, requirementIndex, updateRequirement, }) {
    var _a;
    if (mutable.conditions && ((_a = mutable.conditions) === null || _a === void 0 ? void 0 : _a.rules)) {
        updateRequirement(mutable.conditions.rules[ruleIndex].requirements[requirementIndex], value);
    }
}
export function updateRequirementOperator({ mutable, ruleIndex, requirementIndex, operator, }) {
    if (mutable.conditions && mutable.conditions.rules) {
        mutable.conditions.rules[ruleIndex].requirements[requirementIndex].operator = operator;
    }
}
