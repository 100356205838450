import { defineMessage } from 'react-intl';
import { StepClass } from '../common';
import { PrototypeStepType } from '../userTest/types';
const REVEAL_IMAGE_BUTTON_COPY = defineMessage({ id: "McI9VL", defaultMessage: "Reveal image" });
// WARNING: Wherever you see warnings below these values are embedded directly into user test
// documents and cannot be changed without maintaining multiple versions of it as you will break
// localization on existing documents.
//
// If you change those values, make sure to update anywhere it is displayed and have
// them check the contents of the user test against all revisions of the copy so that user tests
// that have the older revisions in them can still be localized correctly.
const CARD_SORT_BASE_COPY = {
    // WARNING: Embedded value, see above for more details
    defaultTitle: defineMessage({ id: "M+2S6g", defaultMessage: "Sort the cards" }),
    // WARNING: Embedded value, see above for more details
    defaultDescription: defineMessage({ id: "uuZbLq", defaultMessage: "Organize the cards into categories that make sense to you. Please do whatever your intuition tells you. Remember - there is no right or wrong answer" }),
};
export const SCREENER_COPY = {
    Intro: {
        defaultTitle: defineMessage({ id: "qOLJxG", defaultMessage: "Before we get started" }),
        // WARNING: Embedded value, see above for more details
        defaultDescription: defineMessage({ id: "hvEOAX", defaultMessage: "The researcher would like to ask some questions to check if you are suitable to take part in this research." }),
        defaultButtonText: defineMessage({ id: "IqHpbz", defaultMessage: "Continue" }),
    },
    Decline: {
        defaultTitle: defineMessage({ id: "ZKTdKV", defaultMessage: "Sorry, it looks like you're not the right fit" }),
        // WARNING: Embedded value, see above for more details
        defaultDescription: defineMessage({ id: "+7ZVJJ", defaultMessage: "We're looking for a specific group of participants for this research. Thank you for your time and interest.<br/><bold>You can now close this page</bold>" }),
    },
};
export const STEPS_COPY = {
    [StepClass.CardSortingHybrid]: CARD_SORT_BASE_COPY,
    [StepClass.CardSortingClosed]: CARD_SORT_BASE_COPY,
    [StepClass.CardSortingOpen]: CARD_SORT_BASE_COPY,
    [StepClass.MediaSettings]: {
        // WARNING: Embedded value, see above for more details
        defaultTitle: defineMessage({ id: "qlXymd", defaultMessage: "The researcher would like to record this session" }),
        // WARNING: Embedded value, see above for more details
        defaultDescription: defineMessage({ id: "stc3vx", defaultMessage: "Please enable access below so the researcher can observe your session and understand your experience better." }),
    },
    [StepClass.FiveSecondTest]: {
        // WARNING: Embedded value, see above for more details
        defaultDescription: defineMessage({ id: "R5W9md", defaultMessage: "This test will display an image for a few seconds to find out how much of it you remember. Click <bold>{buttonText} </bold>when you are ready." }),
        buttonText: REVEAL_IMAGE_BUTTON_COPY,
    },
    [PrototypeStepType.FirstClick]: {
        // WARNING: Embedded value, see above for more details
        defaultDescription: defineMessage({ id: "fPqNKX", defaultMessage: "This test will track where you click to carry out the task. <bold>Note: The test will end after your first click</bold> so take your time to review the image and instructions. Click <bold>{buttonText} </bold>when you are ready." }),
        buttonText: REVEAL_IMAGE_BUTTON_COPY,
    },
    [StepClass.WebsiteTask]: {
        // WARNING: Embedded value, see above for more details
        defaultDescription: defineMessage({ id: "B6T/ho", defaultMessage: "You are about to carry out your task on a real website. A new browser window will open and your task will begin.<br/><bold>Once you are finished, please return to this window to continue to the next step.</bold>" }),
    },
};
