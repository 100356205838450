import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { gql, useMutation } from '@apollo/client';
import '@livekit/components-styles';
import '@livekit/components-styles/prefabs';
import { observer } from 'mobx-react';
import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { FullScreenLoader } from '@marvelapp/ballpark-components';
import { isDebugMode } from '@marvelapp/core';
import { SessionEnded } from '../../components/Record/SessionEnded';
import { ModeratorRoom, ObserverRoom } from './LiveRoom';
import Record from './Record';
import { Resume, clearResponseInStorage } from './Resume';
import { RoomTokenContext } from './RoomTokenContext';
const JOIN_ROOM = gql `
  mutation joinRoom($roomName: String!, $liveRoleType: LiveRoleEnum!) {
    joinRoom(roomName: $roomName, liveRoleType: $liveRoleType) {
      token
      ok
      responseUUID
      error {
        code
        message
      }
    }
  }
`;
export const JoinRoom = observer(function JoinRoom() {
    const params = useParams();
    const testUUID = `ut_${params.shareId}`; // TODO need to query for this
    const [initiated, setInitiated] = useState(false);
    const roomName = params.roomId;
    const [joinRoom, { data, loading, error }] = useMutation(JOIN_ROOM, {
        variables: {
            roomName,
            liveRoleType: params.role.toLocaleUpperCase(),
        },
    });
    useEffect(() => {
        joinRoom();
        setInitiated(true);
    }, [joinRoom]);
    const restart = useCallback(() => {
        clearResponseInStorage(testUUID);
        window.location.href = `/share/live/${params.role}/${params.shareId}`;
    }, [params.role, params.shareId, testUUID]);
    useEffect(() => {
        if (!isDebugMode()) {
            return;
        }
        window.restartSession = restart;
        return () => {
            window.restartSession = undefined;
        };
    }, [restart]);
    if (!initiated || loading) {
        return _jsx(FullScreenLoader, {});
    }
    if (error || !data) {
        return (_jsx("div", { children: _jsxs("h1", { children: ["Error joining session", ' ', _jsx("span", { role: "img", "aria-label": "sad face", children: "\uD83D\uDE25" })] }) }));
    }
    if (data.joinRoom.error) {
        if (data.joinRoom.error.code === 'ROOM_NOT_FOUND') {
            return (_jsx("div", { children: _jsx("h1", { children: "Session not found" }) }));
        }
        if (data.joinRoom.error.code === 'ROOM_CLOSED') {
            return (_jsx("div", { className: "flex min-h-screen w-full items-center justify-center", children: _jsx(SessionEnded, {}) }));
        }
        return (_jsx("div", { children: _jsxs("h1", { children: ["Error joining session", ' ', _jsx("span", { role: "img", "aria-label": "sad face", children: "\uD83D\uDE25" })] }) }));
    }
    if (params.role === 'participant') {
        return (_jsx(RoomTokenContext.Provider, { value: data.joinRoom.token, children: _jsx(Record
            // TODO implement password flow
            , { 
                // TODO implement password flow
                authToken: null, 
                // TODO implement partner data flow
                partnerData: null, responseUUID: data.joinRoom.responseUUID, testUUID: testUUID, restart: restart, children: _jsx(Resume, { isResuming: false, onRestart: restart }) }) }));
    }
    return (_jsxs("div", { className: "h-full", children: [params.role === 'researcher' && (_jsx(ModeratorRoom, { token: data.joinRoom.token })), params.role === 'observer' && (_jsx(ObserverRoom, { token: data.joinRoom.token }))] }));
});
