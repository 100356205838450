import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { observer } from 'mobx-react';
import { useEffect, useRef, useState } from 'react';
import { CopyToClipboardButton } from '../CopyToClipboadButton';
import { HoverCell } from './HoverCell';
export const QuestionTextCell = observer(function QuestionTextCell({ value, }) {
    const elementRef = useRef(null);
    const [isTruncated, setIsTruncated] = useState(false);
    useEffect(() => {
        const container = elementRef.current;
        // Checking whether text is truncated or not.
        if (container) {
            const { clientHeight, scrollHeight } = elementRef.current;
            if (scrollHeight > clientHeight)
                setIsTruncated(true);
        }
    }, []);
    return (_jsx(HoverCell, { isTruncated: isTruncated, canCopy: true, trigger: _jsxs("span", { className: "line-clamp-3", ref: elementRef, children: [value, value && !isTruncated && _jsx(CopyToClipboardButton, { text: value })] }), content: _jsxs("span", { children: [value, value && _jsx(CopyToClipboardButton, { text: value })] }) }));
});
