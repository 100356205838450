import { gql, useSuspenseQuery } from '@apollo/client';
export function useQueryCategoryAliases({ projectPk: testPk, stepUuid, }) {
    return useSuspenseQuery(GET_CARD_SORT_CATEGORY_ALIASES, {
        variables: { testPk, stepUuid },
    });
}
export const GET_CARD_SORT_CATEGORY_ALIASES = gql `
  query cardsortCategoryAliases($testPk: Int!, $stepUuid: String!) {
    cardsortCategoryAliases(usertestPk: $testPk, stepUuid: $stepUuid) {
      groupId
      groupUuid
      standardisedAs
      includedCategoryNames
    }
  }
`;
