import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { observer } from 'mobx-react';
import { useState } from 'react';
import * as React from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import { useIntl } from 'react-intl';
import { useAction } from '@marvelapp/ballpark-application';
import { Button, Heading } from '@marvelapp/ballpark-components';
import { deviceDetection } from '@marvelapp/core';
import { useWindowDimensions } from '@marvelapp/hooks';
import styled from '@marvelapp/styled';
import { Box, Flex, Floaty, PinCenter } from '@marvelapp/ui';
import { getStepTitle } from '@marvelapp/user-test-creator';
import { usePrototypeState } from '../../../components/Record/PrototypeContext';
import BackgroundOverlay from './BackgroundOverlay';
import CloseButton from './CloseButton';
import InstructionText from './InstructionText';
import OpenButton from './OpenButton';
import PauseButton from './PauseButton';
import StopButton from './StopButton';
import constants from './constants';
import HandleIcon from './icons/HandleIcon';
const POPOVER_HEIGHT_OPEN = 310;
const POPOVER_HEIGHT_CLOSED = 62;
const POPOVER_HEIGHT_CLOSED_MOBILE = 52;
const SCREEN_PADDING = 20;
const Z_INDEX = 99;
export const FloatingInstructions = observer(function FloatingInstructions({ onStop, step, }) {
    const prototypeState = usePrototypeState();
    const { width: windowWidth, height: windowHeight } = useWindowDimensions();
    const isMobile = windowWidth < 590;
    const runningStandalone = deviceDetection.isIOS && window.navigator.standalone;
    const [isOpen, setIsOpen] = useState(!isMobile);
    const height = isOpen ? POPOVER_HEIGHT_OPEN : POPOVER_HEIGHT_CLOSED;
    const heightDifference = POPOVER_HEIGHT_OPEN - POPOVER_HEIGHT_CLOSED;
    const standaloneOffset = runningStandalone ? 20 : 0;
    const defaultX = SCREEN_PADDING;
    const defaultY = windowHeight - SCREEN_PADDING - POPOVER_HEIGHT_CLOSED - standaloneOffset;
    const maxX = windowWidth - 100;
    const maxY = windowHeight - height - standaloneOffset;
    const [coordinates, setCoordinates] = useState({
        x: defaultX,
        y: defaultY,
    });
    const safeCoordinates = {
        x: Math.min(coordinates.x, maxX),
        y: Math.min(coordinates.y, maxY),
    };
    const toggleInstructions = () => {
        // This makes sure the buttons don't move up and down
        if (!isMobile) {
            setCoordinates(Object.assign(Object.assign({}, coordinates), { y: coordinates.y + (isOpen ? heightDifference : -heightDifference) }));
        }
        setIsOpen((value) => !value);
    };
    const onDragStop = useAction((_e, d) => {
        prototypeState.pointerEventsDisabled = false;
        setCoordinates(Object.assign(Object.assign({}, coordinates), { x: d.x, y: d.y }));
    }, [coordinates, prototypeState]);
    const onDragStart = useAction(() => {
        prototypeState.pointerEventsDisabled = true;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const Instructions = isMobile ? Mobile : Desktop;
    return (_jsx(Instructions, { coordinates: safeCoordinates, onClickInstructions: toggleInstructions, onDragStop: onDragStop, onDragStart: onDragStart, onStop: onStop, open: isOpen, step: step }));
});
const Desktop = observer(function Desktop({ coordinates, onDragStart, onDragStop, onStop, open, onClickInstructions, step, }) {
    return (_jsx(Draggable, { coordinates: coordinates, onDragStop: onDragStop, onDragStart: onDragStart, children: open ? (_jsx(InstructionsOpen, { onClickInstructions: onClickInstructions, onClickStop: onStop, step: step, showHandle: true })) : (_jsx(InstructionsClosedDesktop, { onClickInstructions: onClickInstructions, onClickStop: onStop })) }));
});
const Mobile = observer(function Mobile({ coordinates, onClickInstructions, onDragStart, onDragStop, onStop, open, step, }) {
    return open ? (_jsxs(_Fragment, { children: [_jsx(BackgroundOverlay, { zIndex: Z_INDEX }), _jsx(PinCenter, { zIndex: Z_INDEX, children: _jsx(InstructionsOpen, { onClickInstructions: onClickInstructions, onClickStop: onStop, step: step }) })] })) : (_jsx(Draggable, { coordinates: coordinates, onDragStop: onDragStop, onDragStart: onDragStart, children: _jsx(InstructionsClosedMobile, { onClickPause: onClickInstructions }) }));
});
const Draggable = observer(function Draggable({ children, onDragStop, coordinates, onDragStart, }) {
    return (_jsx(Floaty, { bounds: "window", enableResizing: false, onDragStop: onDragStop, position: coordinates, zIndex: Z_INDEX, onDragStart: onDragStart, children: children }));
});
const InstructionsClosedMobile = observer(function InstructionsClosedMobile({ onClickPause, }) {
    return (_jsxs(PopoverClosed, { isMobile: true, height: POPOVER_HEIGHT_CLOSED_MOBILE, children: [_jsx(HandleIconWrapper, { width: 32, children: _jsx(HandleIcon, {}) }), _jsx(PauseButton, { onClick: onClickPause })] }));
});
const InstructionsClosedDesktop = observer(function InstructionsClosedDesktop({ onClickInstructions, onClickStop, testId = 'floating-instructions', }) {
    return (_jsxs(PopoverClosed, { "data-testid": testId, height: POPOVER_HEIGHT_CLOSED, children: [_jsx(StopButton, { marginRight: "xs", onClick: onClickStop }), _jsx(OpenButton, { onClick: onClickInstructions }), _jsx(HandleButton, { children: _jsx(HandleIcon, {}) })] }));
});
export const InstructionsOpen = observer(function InstructionsOpen({ onClickInstructions, onClickStop, showHandle, step, testId = 'floating-instructions', }) {
    const intl = useIntl();
    const introTitle = getStepTitle(step);
    return (_jsxs(Popover, { "data-testid": testId, height: POPOVER_HEIGHT_OPEN, children: [_jsxs(PopoverHeader, { children: [_jsx(Heading, { size: "m", flex: 1, children: intl.formatMessage(constants.INSTRUCTIONS) }), showHandle && (_jsx(HandleButton, { children: _jsx(HandleIcon, {}) })), _jsx(CloseButton, { onClick: onClickInstructions })] }), _jsx(PopoverBody, { children: _jsx(Scrollbars, { autoHide: true, children: _jsxs(Box, { "data-testid": "floating-instructions-text", paddingX: "s", paddingY: "xs", children: [introTitle !== null && (_jsx(Heading, { flex: 1, fontSize: 17, lineHeight: "heading", paddingTop: "xs", children: introTitle })), _jsx(InstructionText, { step: step })] }) }) }), _jsx(PopoverFooter, { children: _jsx(StopButton, { onClick: onClickStop }) })] }));
});
const Popover = styled(Flex).attrs({
    bg: 'background',
    borderRadius: 'l',
    boxShadow: 'popover.default',
}) `
  flex-direction: column;
`;
const PopoverHeader = styled(Flex).attrs({
    boxShadow: 'borders.bottom',
    paddingX: 's',
}) `
  height: 50px;
  align-items: center;
  justify-content: center;
`;
const PopoverBody = styled.div `
  flex: 1;
  min-width: 300px;
  position: relative;
  width: 100%;
`;
const PopoverFooter = styled(Flex).attrs({
    boxShadow: 'borders.top',
    padding: 's',
}) `
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex;
  > * {
    margin-right: ${(props) => props.theme.space.xs}px;
    &:last-child {
      margin: 0;
    }
  }
`;
const PopoverClosed = styled(Flex).attrs((props) => ({
    bg: 'background',
    borderRadius: props.isMobile ? POPOVER_HEIGHT_CLOSED_MOBILE : 'l',
    boxShadow: 'popover.default',
    paddingLeft: props.isMobile ? 0 : 's',
    paddingRight: 's',
})) `
  align-items: center;
  flex-direction: row;
`;
const HandleIconWrapper = styled(Flex).attrs({
    color: 'slate12',
}) `
  align-items: center;
  justify-content: center;
  margin-left: 6px;
`;
const HandleButton = styled(Button).attrs({
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
    kind: 'ghost',
    size: 's',
    px: 0,
}) ``;
