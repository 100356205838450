import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { observer } from 'mobx-react';
import { Badge, Stack } from '@marvelapp/ballpark-components';
import { MultipleChoiceStepUtils, } from '@marvelapp/user-test-creator';
import { HoverCell } from './HoverCell';
export const MultipleChoiceCell = observer(function MultipleChoiceCell({ uuids, otherValue = '', step, }) {
    const visibleOptions = uuids.slice(0, 4);
    const hiddenItemsCount = uuids.length - visibleOptions.length;
    return (_jsx(HoverCell, { isTruncated: !!hiddenItemsCount, trigger: _jsxs(Stack, { direction: "row", gap: "1", wrap: "wrap", children: [_jsx(MultipleChoiceList, { uuids: visibleOptions, otherValue: otherValue, step: step }), hiddenItemsCount > 0 && (_jsx(_Fragment, { children: _jsxs(Badge, { className: "border border-gray-600/15 bg-white", children: ["+", hiddenItemsCount] }) }))] }), content: _jsx(MultipleChoiceList, { uuids: uuids, otherValue: otherValue, step: step }) }));
});
const MultipleChoiceList = observer(function MultipleChoiceList({ uuids, otherValue = '', step, }) {
    return (_jsx(_Fragment, { children: uuids.map((uuid) => {
            return (_jsx(MultipleChoiceItem, { 
                // Rendering either the regular value or the "other" option value, if present
                value: uuid === MultipleChoiceStepUtils.getOtherOptionUUID(step)
                    ? `Other: ${otherValue}`
                    : step.choiceDefinitions[uuid] }, uuid));
        }) }));
});
const MultipleChoiceItem = observer(function MultipleChoiceItem({ value, }) {
    return (_jsx(Badge, { truncate: true, className: "bg-gray-200/50 text-gray-500", "data-testid": "multiple-choice-item", children: value }));
});
