import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { observer } from 'mobx-react';
import * as React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link, Text } from '@marvelapp/ballpark-components';
import { ENABLE_SCREEN_SHARING_AND_CAMERA_ACCESS } from '@marvelapp/ballpark-help';
import ErrorBox from './ErrorBox';
import { getRecordingDevices } from './utils';
export default observer(function NoCameraError({ permissions, }) {
    const intl = useIntl();
    const devices = getRecordingDevices(intl, permissions);
    return (_jsxs(ErrorBox, { children: [_jsx(Text, { fontSize: "s", children: _jsx(FormattedMessage, { id: 'oiQsmK', defaultMessage: 'We couldn\'t set up your {devices}. Make sure you have the latest version of your browser installed.', values: { devices } }) }), _jsx(Text, { fontSize: "s", mt: "xs", children: _jsx(FormattedMessage, { id: 'rfhJjb', defaultMessage: 'Check the supported browser versions <link>here</link>.', values: {
                        link: (chunks) => (_jsx(Link, { to: ENABLE_SCREEN_SHARING_AND_CAMERA_ACCESS, children: chunks })),
                    } }) })] }));
});
