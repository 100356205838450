import { jsx as _jsx } from "react/jsx-runtime";
import { observer } from 'mobx-react';
import { useCallback, useEffect } from 'react';
import { useCreateUserTest } from '@marvelapp/ballpark-api';
import { NotFound } from '@marvelapp/ballpark-components';
import { capitalizeFirstLetter } from '@marvelapp/core';
import styled from '@marvelapp/styled';
import { Loader } from '@marvelapp/ui';
import { showErrorNotification } from '@marvelapp/ui-internal';
import { UserTestUtils } from '@marvelapp/user-test-creator';
export default observer(function ProjectCloneBounce({ match }) {
    const { testId, testName } = match.params;
    const newProjectName = capitalizeFirstLetter(testName.split('-').join(' '));
    const { createProject } = useCreateUserTest({
        projectToCloneUUID: testId,
        showError: () => {
            showErrorNotification({
                content: 'There was a problem creating your project',
                toastId: 'createProjectError',
            });
        },
    });
    const createProjectWithName = useCallback(() => {
        createProject({
            variables: {
                input: {
                    name: newProjectName,
                    uuid: UserTestUtils.generateUserTestUUID(),
                },
            },
        });
    }, [createProject, newProjectName]);
    useEffect(() => {
        if (testId && testName) {
            createProjectWithName();
        }
    }, [createProjectWithName, testId, testName]);
    if (!testId) {
        return _jsx(NotFound, {});
    }
    return (_jsx(LoaderContainer, { children: _jsx(Loader, {}) }));
});
const LoaderContainer = styled.div `
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
`;
