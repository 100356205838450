import { StepClass } from '../common';
import { generateUUID } from '../utils/generateUUID';
import { STEP_PREFIXES } from './constants';
export function create(partial = {}) {
    return Object.assign({ uuid: generateUUID(STEP_PREFIXES[StepClass.Instruction]), className: StepClass.Instruction, title: null, description: [], isRequired: false, hasButton: true, revision: 0, conditions: {
            ALWAYS: null,
        } }, partial);
}
export function setHasButton(mutable, value) {
    mutable.hasButton = value;
}
export function setButtonText(mutable, value) {
    mutable.buttonText = value;
}
export function setRedirectUrl(mutable, value) {
    mutable.redirectUrl = value;
}
