import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { observer } from 'mobx-react';
import { FormattedMessage, useIntl } from 'react-intl';
import { BtwButton, Stack } from '@marvelapp/ballpark-components';
import { deviceDetection } from '@marvelapp/core';
import { Box } from '@marvelapp/ui';
import { STEPS_COPY, StepClass, } from '@marvelapp/user-test-creator';
import { useWholeTestRecording } from '../../../WholeTestRecordingContext';
import FloatingButton from '../FloatingButton';
import { MediaAccessContainer } from './MediaAccessContainer';
import MediaAccessText from './MediaAccessText';
import { RequestWebcamMicrophonePermission } from './RequestWebcamMicrophonePermission';
import ShareScreen from './ShareScreen';
export const SetupRecording = observer(function StepupRecording({ permissions, state, send, recordEntireScreen, isConnecting, onNext, onSkip, nextButtonText, withHeading = true, isRequired = false, }) {
    const intl = useIntl();
    const recording = useWholeTestRecording();
    const commonProps = {
        permissions,
        send,
        state,
    };
    const getIsButtonEnabled = () => {
        const isUserMediaRequested = permissions.webcam || permissions.microphone;
        const hasGrantedUserPermissions = state.matches('shareUserMedia.userMediaGranted');
        const hasGrantedScreenPermissions = state.matches('shareScreen.screenGranted');
        // webcam requested, user granted but no active camera track
        if (permissions.webcam &&
            hasGrantedUserPermissions &&
            !recording.hasActiveCameraTrack) {
            return false;
        }
        // microphone requested, user granted but no active audio track
        if (permissions.microphone &&
            hasGrantedUserPermissions &&
            !recording.hasActiveAudioTrack) {
            return false;
        }
        // screen requested, user granted but no active screen track
        if (permissions.screen &&
            hasGrantedScreenPermissions &&
            !recording.hasActiveScreenTrack) {
            return false;
        }
        if (isRequired) {
            if (permissions.screen && isUserMediaRequested) {
                return hasGrantedUserPermissions && hasGrantedScreenPermissions;
            }
            if (permissions.screen) {
                return hasGrantedScreenPermissions;
            }
            if (isUserMediaRequested) {
                return hasGrantedUserPermissions;
            }
            return false;
        }
        const hasGrantedAtLeastOnePermission = hasGrantedUserPermissions || hasGrantedScreenPermissions;
        return hasGrantedAtLeastOnePermission;
    };
    const isButtonDisabled = !getIsButtonEnabled();
    return (_jsxs(_Fragment, { children: [withHeading && (_jsx(MediaAccessContainer, { heading: intl.formatMessage(STEPS_COPY[StepClass.MediaSettings].defaultTitle), children: _jsx(MediaAccessText, { children: _jsx(FormattedMessage, { id: 'G8zwhK', defaultMessage: 'Please enable access below so the researcher can observe your session and understand your experience better.' }) }) })), _jsxs(Stack, { width: "full", "data-testid": "setup-recording", gap: "6", className: "mt-2", children: [(permissions.webcam || permissions.microphone) && (_jsx(Box, { width: "100%", children: _jsx(RequestWebcamMicrophonePermission, Object.assign({}, commonProps)) })), permissions.screen && !deviceDetection.isMobile && (_jsx(Box, { width: "100%", children: _jsx(ShareScreen, Object.assign({}, commonProps, { recordEntireScreen: recordEntireScreen })) })), _jsx(FloatingButton, { children: _jsxs(Stack, { direction: "row", gap: "1.5", justify: "between", width: "full", children: [_jsx(BtwButton, { "data-testid": "start-recording", disabled: isButtonDisabled, isLoading: isConnecting, onClick: onNext, variant: "legacyBlue", children: _jsx("span", { className: "truncate", children: nextButtonText }) }), !isRequired && (_jsx(BtwButton, { onClick: onSkip, "data-testid": "skip-recording", children: _jsx("span", { className: "truncate", children: _jsx(FormattedMessage, { id: 'CugjNK', defaultMessage: 'Skip recording' }) }) }))] }) })] })] }));
});
