import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { observer } from 'mobx-react';
import { useCallback } from 'react';
import * as React from 'react';
import { Accordion, AccordionTable, CardSortThumb, Strong, cn, } from '@marvelapp/ballpark-components';
import { pluralize } from '@marvelapp/core';
import { EditStandardizedCategoryPopover } from './EditStandardizedCategoryPopover';
export const MetricsAccordion = observer(function MetricsAccordion({ items, headings, type, className, }) {
    return (_jsx(Accordion.Root, { className: cn('px-8', className), type: "multiple", children: _jsxs(AccordionTable.Root, { type: "outer", children: [_jsx(AccordionTable.Header, { children: headings }), items.map((item) => (_jsx(MetricItem, Object.assign({}, item, { type: type }), item.id)))] }) }));
});
export const MetricsAccordionHeading = AccordionTable.Heading;
const MetricItem = observer(function MetricItem({ id, title, imageSrc, metrics, averageAgreement, type, }) {
    const nestedType = type === 'card' ? 'category' : 'card';
    const hasAvgPosition = metrics.some((metric) => metric.averagePosition !== undefined);
    return (_jsxs(Accordion.Item, { "data-testid": `${type}-metrics-row`, value: id, children: [_jsx(Accordion.Trigger, { className: "py-4", "data-testid": `${type}-expand`, children: _jsxs(AccordionTable.Row, { gap: "6", className: "pr-6", children: [_jsxs(AccordionTable.ColPrimary, { className: "flex min-h-6 flex-1 items-center justify-between", children: [_jsx("div", { className: "min-w-0 flex-shrink flex-grow truncate", "data-testid": `${type}-title`, title: title, children: title }), imageSrc && (_jsx("div", { className: "flex-shrink-0", children: _jsx(MetricItemImage, { title: title, imageSrc: imageSrc, type: type }) })), type === 'category' && (_jsx("div", { className: "flex-shrink-0", children: _jsx(EditStandardizedCategoryPopover, { categoryUUID: id }) }))] }), _jsxs(AccordionTable.ColSecondary, { className: "min-h-6 w-20 leading-5", "data-testid": `${nestedType}-count`, children: [_jsx(Strong, { children: metrics.length }), ' ', pluralize(metrics.length, nestedType)] }), _jsx(AccordionTable.ColSecondary, { className: "w-20" }), _jsx(AccordionTable.ColProgress, { className: "min-h-6 flex-1 first:[&>div]:ml-4", "data-testid": `${type}-avg-agreement`, agreement: averageAgreement })] }) }), _jsx(Accordion.Content, { children: _jsxs(AccordionTable.Root, { type: "inner", children: [_jsxs(AccordionTable.Header, { children: [_jsx(AccordionTable.Heading, { className: "flex-1", children: nestedType === 'card' ? 'Cards' : 'Categories' }), _jsx(AccordionTable.Heading, { className: "w-20", children: "Frequency" }), _jsx(AccordionTable.Heading, { className: "w-20", children: hasAvgPosition ? 'Avg. Position' : '' }), _jsx(AccordionTable.Heading, { className: "flex-1" })] }), metrics.map((metric) => {
                            var _a;
                            return (_jsxs(AccordionTable.Row, { "data-testid": `${type}-metrics-${nestedType}-row`, children: [_jsxs(AccordionTable.ColSecondary, { className: "flex flex-1 items-center justify-between", "data-testid": `${nestedType}-title`, children: [_jsx("div", { className: "min-w-0 flex-shrink flex-grow truncate", children: metric.title }), metric.imageSrc && (_jsx("div", { className: "flex-shrink-0", children: _jsx(MetricItemImage, { title: metric.title, imageSrc: metric.imageSrc, type: nestedType }) }))] }), _jsx(AccordionTable.ColUsers, { className: "w-20", children: _jsx("span", { "data-testid": `${nestedType}-frequency`, children: metric.frequency }) }), metric.averagePosition ? (_jsx(AccordionTable.ColNumber, { className: "w-20", children: _jsx("span", { "data-testid": `${nestedType}-avg-position`, children: (_a = metric.averagePosition) === null || _a === void 0 ? void 0 : _a.toFixed(1) }) })) : (_jsx(AccordionTable.ColEmpty, { className: "w-20" })), _jsx(AccordionTable.ColProgress, { className: "flex-1", "data-testid": `${nestedType}-agreement`, agreement: metric.agreement })] }, metric.id));
                        })] }) })] }, id));
});
const MetricItemImage = observer(function MetricItemImage(props) {
    // Prevent the accordion to expand when zooming over the thumbnail
    const handleClick = useCallback((e) => e.stopPropagation(), []);
    return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
    _jsx("div", { onClick: handleClick, children: _jsx(CardSortThumb, Object.assign({}, props)) }));
});
