import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import h337 from 'heatmap.js';
import { observer } from 'mobx-react';
import { useLayoutEffect, useRef, useState } from 'react';
import styled from '@marvelapp/styled';
import { Box, Image as MarvelImage } from '@marvelapp/ui';
import { fadeInKeyframes } from './FadeIn';
const HeatmapImg = styled(MarvelImage) `
  animation: ${fadeInKeyframes} 1s ease-in-out 1 forwards;
`;
const HeatmapCanvas = styled(Box) `
  canvas {
    height: 100%;
    width: 100%;
  }
`;
const createHeatMapInstance = (container) => {
    return h337.create({
        container,
        radius: 120,
        maxOpacity: 0.5,
        minOpacity: 0.3,
        blur: 0.75,
    });
};
export default observer(function HeatmapImage({ sourceWidth = 300, sourceHeight = 300, data, imageUrl, onLoad, isLoading, maxWidth, }) {
    const containerRef = useRef();
    const [image, setImage] = useState('');
    useLayoutEffect(() => {
        if (!containerRef.current || !imageUrl) {
            return;
        }
        const heatmapInstance = createHeatMapInstance(containerRef.current);
        heatmapInstance.setData(data);
        // This merges the heatmap + image together as one image
        // Having it as one image also allows you to download it.
        const canvasImage = new Image();
        canvasImage.crossOrigin = '';
        canvasImage.onload = function () {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            const { canvas } = heatmapInstance._renderer; // TODO can we do this without internals?
            const ctx = canvas.getContext('2d');
            if (!ctx) {
                return;
            }
            ctx.globalCompositeOperation = 'destination-over';
            ctx.drawImage(canvasImage, 0, 0);
            ctx.globalCompositeOperation = 'source-over';
            const imageData = canvas.toDataURL();
            setImage(imageData);
        };
        canvasImage.src = imageUrl;
    }, [data, imageUrl]);
    return (_jsxs(_Fragment, { children: [_jsx(HeatmapImg, { display: isLoading ? 'none' : 'inline-block', hasTransition: false, onLoad: () => {
                    onLoad(image);
                }, src: image, maxHeight: "100%", maxWidth: maxWidth, preloadImage: false, testId: "heatmap" }), _jsx(HeatmapCanvas, { display: "none", height: sourceHeight, ref: containerRef, width: sourceWidth })] }));
});
