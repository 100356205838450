import { __awaiter } from "tslib";
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { DndContext, closestCenter } from '@dnd-kit/core';
import { SortableContext, rectSortingStrategy } from '@dnd-kit/sortable';
import { observer } from 'mobx-react';
import { useCallback, useState } from 'react';
import * as React from 'react';
import { useAction, useFeatureFlag, useSlideUIContext, } from '@marvelapp/ballpark-application';
import { Button } from '@marvelapp/ballpark-components';
import { Box } from '@marvelapp/ui';
import { StepClass, StepUtils, TaglineCopyStepUtils, TextUtils, } from '@marvelapp/user-test-creator';
import PreferenceGrid from '../../components/PreferenceGrid';
import { useDragging } from '../../hooks/useDragging';
import * as openAIApi from '../../utils/openAIApi';
import AddTaglineCTA from './AddTaglineCTA';
import TaglineCopyTestGridItem from './TaglineCopyTestGridItem';
export default observer(function TaglineCopyTestGrid() {
    const { step } = useSlideUIContext();
    const [lastAddedTagline, setLastAddedTagline] = useState(null);
    const addChoice = useAction((mutable, emptyChoceDefinition) => {
        const newChoiceUUID = TaglineCopyStepUtils.addChoice(mutable, emptyChoceDefinition);
        setLastAddedTagline(newChoiceUUID);
    }, []);
    const openAIEnabled = useFeatureFlag('ballpark-open-ai-tagline');
    const generatePrompt = useCallback((s) => {
        return `Write another tagline.

Examples:

${s.choices
            .reduce((acc, uuid) => {
            const { heading, paragraph } = s.choiceDefinitions[uuid];
            if (!heading || !paragraph) {
                return acc;
            }
            const textParagraph = TextUtils.richTextToString(paragraph).trim();
            const paragraphs = [];
            if (heading) {
                paragraphs.push(heading.replace(/\.$/, '').trim());
            }
            if (textParagraph) {
                paragraphs.push(textParagraph);
            }
            const tagLine = `- ${paragraphs.join('. ')}`;
            acc.push(tagLine);
            return acc;
        }, [])
            .join('\n\n')}

Tagline:
`;
    }, []);
    const [isGeneratingTagline, setIsGeneratingTagline] = useState(false);
    const generateTagline = useCallback(() => __awaiter(this, void 0, void 0, function* () {
        if (!StepUtils.isOfType(step, StepClass.TaglineCopyTest))
            return;
        setIsGeneratingTagline(true);
        const prompt = generatePrompt(step);
        const [result, ok] = yield openAIApi.completions({
            prompt,
            maxTokens: 180,
            temperature: 1,
            frequencyPenalty: 1,
            presencePenalty: 1,
            topP: 1,
        });
        if (!ok) {
            setIsGeneratingTagline(false);
            return;
        }
        const [firstLine = '', secondLine] = result.replace(/^-/g, '').split('.');
        const heading = firstLine.replace(/\.$/, '').replace(/['"]/g, '').trim();
        let paragraph = [];
        if (secondLine) {
            paragraph = [
                {
                    type: 'p',
                    children: [
                        {
                            text: secondLine.trim(),
                        },
                    ],
                },
            ];
        }
        addChoice(step, {
            heading,
            paragraph,
        });
        setIsGeneratingTagline(false);
    }), [step, addChoice, generatePrompt]);
    const { handleDragEnd, sensors } = useDragging();
    const onDragEnd = useAction((event) => {
        if (!StepUtils.isOfType(step, StepClass.TaglineCopyTest))
            return;
        const items = step.choices.map((uuid) => `card-${uuid}`);
        handleDragEnd({
            event,
            items,
            reorderFn: TaglineCopyStepUtils.reorderChoices,
            parentItem: step,
        });
    }, [handleDragEnd, step]);
    if (!StepUtils.isOfType(step, StepClass.TaglineCopyTest))
        return null;
    const items = step.choices.map((uuid) => `card-${uuid}`);
    const renderOpenAIButton = openAIEnabled &&
        step.choices.some((uuid) => {
            const { heading, paragraph } = step.choiceDefinitions[uuid];
            return ((heading || '').trim().length > 3 ||
                TextUtils.richTextToString(paragraph).trim().length > 3);
        }, []);
    return (_jsxs(PreferenceGrid, { columns: [
            'repeat(1, minmax(0, 1fr))',
            'repeat(1, minmax(0, 1fr))',
            'repeat(2, minmax(0, 1fr))',
            'repeat(2, minmax(0, 1fr))',
            'repeat(3, minmax(0, 1fr))',
        ], children: [_jsx(DndContext, { sensors: sensors, collisionDetection: closestCenter, onDragEnd: onDragEnd, children: _jsx(SortableContext, { items: items, strategy: rectSortingStrategy, children: step.choices.map((choiceUUID, index) => {
                        return (_jsx(TaglineCopyTestGridItem, { autofocus: choiceUUID === lastAddedTagline, "data-testid": `tagline-test-choice-${index}`, choiceUUID: choiceUUID, index: index, step: step, children: renderOpenAIButton && (_jsxs(_Fragment, { children: [_jsx(Box, { pt: 3 }), _jsx(Button, { "data-testid": "tagline-ai-generate", onClick: generateTagline, kind: "ghost", isLoading: isGeneratingTagline, children: "Generate another like this" })] })) }, choiceUUID));
                    }) }) }, "dndContext"), _jsx(AddTaglineCTA, { heading: step.choices.length > 0 ? 'Add another tagline' : 'Add tagline', isDisabled: false, addChoice: addChoice, testId: "add-tagline-cta", step: step })] }));
});
