import { __awaiter } from "tslib";
import { createMachine, send } from 'xstate';
import { deviceDetection } from '@marvelapp/core';
import { isMicrophonePermissionGranted, isWebcamPermissionGranted, supportsWebcamRecording, } from '@marvelapp/media-recording';
import { MediaUtils, StepRecordingPermission, } from '@marvelapp/user-test-creator';
export const mediaPermissionsMachine = createMachine({
    id: 'mediaPermissions',
    type: 'parallel',
    tsTypes: {},
    schema: {
        context: {},
        events: {},
    },
    states: {
        shareUserMedia: {
            initial: 'hidden',
            states: {
                hidden: {
                    entry: ['initializeUserMedia'],
                    on: {
                        '': [
                            {
                                target: 'userMediaNotSupported',
                                cond: 'userMediaNotSupported',
                            },
                            { target: 'idle', cond: 'userMediaRequested' },
                        ],
                    },
                },
                userMediaNotSupported: {
                    type: 'final',
                    entry: ['setUserMediaNotSupported'],
                },
                idle: {
                    on: {
                        REQUEST_USER_MEDIA: {
                            target: 'checkPreviouslyGranted',
                        },
                        START_RECORDING: {
                            actions: ['setUserMediaDenied'],
                        },
                    },
                },
                checkPreviouslyGranted: {
                    invoke: {
                        id: 'checkPermissions',
                        src: 'checkPermissions',
                        onDone: {
                            target: 'userMediaGranted',
                        },
                        onError: {
                            target: 'requestingUserMedia',
                        },
                    },
                },
                requestingUserMedia: {
                    on: {
                        USER_MEDIA_GRANTED: {
                            target: 'userMediaGranted',
                        },
                        USER_MEDIA_DENIED: {
                            target: 'userMediaDenied',
                        },
                        USER_MEDIA_NOT_SUPPORTED: {
                            target: 'userMediaNotSupported',
                        },
                    },
                },
                userMediaGranted: {
                    entry: ['setUserMediaGranted'],
                    on: {
                        SKIP_RECORDING: {
                            target: 'idle',
                            cond: 'isNotRequired',
                            actions: ['setSkipped'],
                        },
                        RESET_USER_MEDIA: {
                            target: 'idle',
                            actions: ['setUserMediaDenied'],
                        },
                    },
                },
                userMediaDenied: {
                    entry: ['setUserMediaDenied'],
                    on: {
                        RETRY_USER_MEDIA: {
                            target: 'requestingUserMedia',
                        },
                    },
                },
            },
        },
        shareScreen: {
            initial: 'hidden',
            states: {
                hidden: {
                    entry: ['initializeScreenMedia'],
                    on: {
                        '': [
                            {
                                target: 'screenNotSupported',
                                cond: 'screenMediaRequiredAndOnMobile',
                            },
                            { target: 'idle', cond: 'screenMediaRequestedAndNotOnMobile' },
                        ],
                    },
                },
                screenNotSupported: {
                    entry: ['setScreenNotSupported'],
                    type: 'final',
                },
                idle: {
                    on: {
                        REQUEST_SCREEN: {
                            target: 'requestingScreen',
                        },
                        START_RECORDING: {
                            actions: ['setScreenDenied'],
                        },
                        SKIP_RECORDING: {
                            actions: ['setSkipped'],
                        },
                    },
                },
                requestingScreen: {
                    on: {
                        SCREEN_GRANTED: [
                            {
                                target: 'checkEntireScreen',
                                cond: 'entireScreenRequired',
                            },
                            {
                                target: 'screenGranted',
                            },
                        ],
                        SCREEN_DENIED: {
                            target: 'screenDenied',
                        },
                    },
                },
                checkEntireScreen: {
                    on: {
                        ENTIRE_SCREEN_FOUND: {
                            target: 'screenGranted',
                        },
                        ENTIRE_SCREEN_NOT_FOUND: {
                            target: 'entireScreenNotFound',
                        },
                    },
                },
                entireScreenNotFound: {
                    entry: ['setScreenDenied'],
                    on: {
                        RETRY_SCREEN: {
                            target: 'requestingScreen',
                            actions: ['setScreenDenied'],
                        },
                        SKIP_RECORDING: {
                            target: 'idle',
                            cond: 'isNotRequired',
                            actions: ['setSkipped'],
                        },
                    },
                },
                screenGranted: {
                    entry: ['setScreenGranted', send('SCREEN_ON')],
                    on: {
                        SKIP_RECORDING: {
                            target: 'idle',
                            cond: 'isNotRequired',
                            actions: ['setSkipped'],
                        },
                        RETRY_SCREEN: {
                            target: 'screenDenied',
                            actions: ['setScreenDenied'],
                        },
                    },
                },
                screenDenied: {
                    entry: ['setScreenDenied'],
                    on: {
                        RETRY_SCREEN: {
                            target: 'requestingScreen',
                            actions: ['setScreenDenied'],
                        },
                        SKIP_RECORDING: {
                            target: 'idle',
                            cond: 'isNotRequired',
                            actions: ['setSkipped'],
                        },
                    },
                },
            },
        },
    },
}, {
    services: {
        checkPermissions,
    },
    guards: {
        isNotRequired: (context) => {
            return !context.required;
        },
        screenMediaRequiredAndOnMobile: (context) => {
            return context.required && context.screen && deviceDetection.isMobile;
        },
        userMediaNotSupported: () => {
            return !supportsWebcamRecording();
        },
        userMediaRequested: (context) => context.webcam || context.microphone,
        screenMediaRequestedAndNotOnMobile: (context) => context.screen && !deviceDetection.isMobile,
        entireScreenRequired: (context) => {
            return context.entireScreenRequired;
        },
    },
    actions: {
        // TODO the browser does let you revoke individual permissions for webcam
        // and mic
        initializeUserMedia: (context) => {
            var _a;
            if ((_a = context.response.participantRecordings) === null || _a === void 0 ? void 0 : _a.user) {
                // Don't re-initialize if we already have a recording
                return;
            }
            MediaUtils.updatePermissionAndStatus({
                mutable: context.response,
                mediaType: 'user',
                newPermissions: {
                    // Following the original implementation here and setting both to
                    // NotRequested even if both are not enabled on the step
                    webcam: StepRecordingPermission.NotRequested,
                },
            });
            updateMicrophonePermission(context, StepRecordingPermission.NotRequested, 'user');
        },
        setUserMediaNotSupported: (context) => {
            MediaUtils.updatePermissionAndStatus({
                mutable: context.response,
                mediaType: 'user',
                newPermissions: {
                    webcam: StepRecordingPermission.NotSupported,
                },
            });
            updateMicrophonePermission(context, StepRecordingPermission.NotSupported, 'user');
        },
        setUserMediaGranted: (context) => {
            if (context.webcam) {
                MediaUtils.updatePermissionAndStatus({
                    mutable: context.response,
                    mediaType: 'user',
                    newPermissions: {
                        webcam: StepRecordingPermission.Granted,
                    },
                });
            }
            if (context.microphone) {
                updateMicrophonePermission(context, StepRecordingPermission.Granted, 'user');
            }
        },
        setUserMediaDenied: (context) => {
            if (context.webcam) {
                MediaUtils.updatePermissionAndStatus({
                    mutable: context.response,
                    mediaType: 'user',
                    newPermissions: {
                        webcam: StepRecordingPermission.Denied,
                    },
                });
            }
            updateMicrophonePermission(context, StepRecordingPermission.Denied, 'user');
        },
        initializeScreenMedia: (context) => {
            var _a;
            if ((_a = context.response.participantRecordings) === null || _a === void 0 ? void 0 : _a.screen) {
                // Don't re-initialize if we already have a recording
                return;
            }
            MediaUtils.updatePermissionAndStatus({
                mutable: context.response,
                mediaType: 'screen',
                newPermissions: {
                    screen: StepRecordingPermission.NotRequested,
                },
            });
            updateMicrophonePermission(context, StepRecordingPermission.NotRequested, 'screen');
        },
        setScreenGranted: (context) => {
            MediaUtils.updatePermissionAndStatus({
                mutable: context.response,
                mediaType: 'screen',
                newPermissions: {
                    screen: StepRecordingPermission.Granted,
                },
            });
            updateMicrophonePermission(context, StepRecordingPermission.Granted, 'screen');
        },
        setScreenDenied: (context) => {
            MediaUtils.updatePermissionAndStatus({
                mutable: context.response,
                mediaType: 'screen',
                newPermissions: {
                    screen: StepRecordingPermission.Denied,
                },
            });
            updateMicrophonePermission(context, StepRecordingPermission.Denied, 'screen');
        },
        setScreenNotSupported: (context) => {
            MediaUtils.updatePermissionAndStatus({
                mutable: context.response,
                mediaType: 'screen',
                newPermissions: {
                    screen: StepRecordingPermission.NotSupported,
                },
            });
        },
        setSkipped: (context) => {
            if (context.screen) {
                MediaUtils.updatePermissionAndStatus({
                    mutable: context.response,
                    mediaType: 'screen',
                    newPermissions: {
                        screen: StepRecordingPermission.Denied,
                    },
                });
                updateMicrophonePermission(context, StepRecordingPermission.Denied, 'screen');
            }
            if (context.webcam) {
                MediaUtils.updatePermissionAndStatus({
                    mutable: context.response,
                    mediaType: 'user',
                    newPermissions: {
                        webcam: StepRecordingPermission.Denied,
                    },
                });
                updateMicrophonePermission(context, StepRecordingPermission.Denied, 'user');
            }
            else if (context.microphone) {
                updateMicrophonePermission(context, StepRecordingPermission.Denied, 'user');
            }
        },
    },
});
function updateMicrophonePermission(context, permission, mediaType) {
    // microphone permission is stored only either against user or screen recording not both, however
    // a recording may have both user and screen recording. this allows you to optmistically call this
    // function and specify which you wanted to update and it will only update if the microphone is
    // actually stored in that recording.
    if (mediaType !== getMicrophoneMediaType(context)) {
        return;
    }
    if (context.microphone) {
        MediaUtils.updatePermissionAndStatus({
            mutable: context.response,
            mediaType,
            newPermissions: {
                microphone: permission,
            },
        });
    }
}
function getMicrophoneMediaType(context) {
    if (context.microphone && !context.webcam && context.screen) {
        return 'screen';
    }
    return 'user';
}
// If the user has already granted permissions, we don't need to ask for them again.
// Can go directly to the next state, where they can select which devices they want to use.
function checkPermissions(context) {
    return __awaiter(this, void 0, void 0, function* () {
        const micPermissionGranted = yield isMicrophonePermissionGranted();
        const webcamPermissionGranted = yield isWebcamPermissionGranted();
        const isMicOnly = !context.webcam && context.microphone;
        // webcam can't be set without microphone
        if (context.webcam &&
            webcamPermissionGranted &&
            context.microphone &&
            micPermissionGranted) {
            MediaUtils.updatePermissionAndStatus({
                mutable: context.response,
                mediaType: 'user',
                newPermissions: {
                    webcam: StepRecordingPermission.Granted,
                },
            });
            updateMicrophonePermission(context, StepRecordingPermission.Granted, 'user');
        }
        else if (isMicOnly && micPermissionGranted) {
            updateMicrophonePermission(context, StepRecordingPermission.Granted, 'user');
        }
        else {
            throw new Error('Permissions not granted');
        }
    });
}
