import { __rest } from "tslib";
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { observer } from 'mobx-react';
import { useMemo } from 'react';
import { formatTime } from '@marvelapp/core';
import { Box } from '@marvelapp/ui';
import { DeviceMake, StepUtils, } from '@marvelapp/user-test-creator';
import { BallparkMediaPlayer, isRecordingProcessing, shouldShowMediaPlayer, } from '../../../components/BallparkMediaPlayer';
import StatBox from '../../../components/PrototypeStat';
import PrototypeStatsGrid from '../../../components/PrototypeStatsGrid';
import { Answer } from './Answer';
export const WebsiteAnswer = observer(function WebsiteAnswer(_a) {
    var { recordings, notAnswered, step, stepResponse, userTest, userTestResult } = _a, restProps = __rest(_a, ["recordings", "notAnswered", "step", "stepResponse", "userTest", "userTestResult"]);
    const isIncompatibleDevice = useMemo(() => {
        var _a;
        // step recording is no longer supported
        // however we will continue to support existing responses which have step recordings
        const recordingRequests = Object.values(StepUtils.getRecordingPermissions(step));
        return !!(userTestResult &&
            ((_a = userTestResult === null || userTestResult === void 0 ? void 0 : userTestResult.device) === null || _a === void 0 ? void 0 : _a.make) !== DeviceMake.Web &&
            recordingRequests.includes(true));
    }, [userTestResult, step]);
    const downloadRecording = recordings.download;
    const recordingDuration = downloadRecording === null || downloadRecording === void 0 ? void 0 : downloadRecording.duration;
    const stillLoading = isRecordingProcessing(downloadRecording === null || downloadRecording === void 0 ? void 0 : downloadRecording.uploadStatus);
    return (_jsx(Answer, Object.assign({ stepResponse: stepResponse, showIncompatibleDeviceHint: isIncompatibleDevice, notAnswered: notAnswered, step: step, stillLoading: stillLoading }, restProps, { children: !notAnswered && shouldShowMediaPlayer(downloadRecording) && (_jsxs(_Fragment, { children: [_jsx(Box, { mb: 3, children: _jsx(BallparkMediaPlayer, { recordings: recordings }) }), _jsx(PrototypeStatsGrid, { children: !!recordingDuration && (_jsx(StatBox, { kind: "slate", title: "Duration", stat: formatTime(recordingDuration) })) })] })) })));
});
