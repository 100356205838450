import { AnswerType, } from '@marvelapp/user-test-creator';
// Map answers to StepResponse
export function formatAnswers(answers) {
    const filteredAnswers = answers ? answers.filter((ans) => !!ans) : [];
    // Backend returns multiple choice answer as separate indexes
    // instead of an array of indexes. Handling this special case here
    if (filteredAnswers.length &&
        filteredAnswers[0].__typename === 'AnswerMultiPreference') {
        const multipleChoiceAnswer = {
            type: AnswerType.MultipleChoice,
            value: [],
        };
        filteredAnswers.forEach((answer) => {
            const typedAnswer = answer;
            multipleChoiceAnswer.value.push(typedAnswer.choice);
            if (typedAnswer.otherValue) {
                multipleChoiceAnswer.otherValue = typedAnswer.otherValue;
            }
        });
        return [multipleChoiceAnswer];
    }
    return filteredAnswers.map((answer) => {
        switch (answer.__typename) {
            case 'AnswerPlainText': {
                return {
                    type: AnswerType.Text,
                    value: answer.text,
                };
            }
            case 'AnswerEmail': {
                return {
                    type: AnswerType.Email,
                    value: answer.email,
                };
            }
            case 'AnswerBoolean': {
                return {
                    type: AnswerType.SingleChoice,
                    value: answer.response,
                };
            }
            case 'AnswerDateTime': {
                return {
                    type: AnswerType.DateAnswer,
                    value: answer.date,
                };
            }
            case 'AnswerRating': {
                return {
                    type: AnswerType.Rating,
                    value: answer.rating,
                };
            }
            case 'AnswerInteger': {
                return {
                    type: AnswerType.Number,
                    value: answer.value,
                };
            }
            default:
                throw Error("Couldn't get answer for this answer type");
        }
    });
}
