import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { isNil, throttle } from 'lodash-es';
import { observer } from 'mobx-react';
import * as React from 'react';
import { useEffect, useMemo, useRef, useState } from 'react';
import Scrollbars from 'react-custom-scrollbars';
import { useDebounceCallback, useResizeObserver } from 'usehooks-ts';
import { useFeatureFlag } from '@marvelapp/ballpark-application';
import { Stack, cn } from '@marvelapp/ballpark-components';
import styled from '@marvelapp/styled';
import { Box } from '@marvelapp/ui';
import { ScreenerUtils } from '@marvelapp/user-test-creator';
import { BallparkMediaPlayer, generateWebVTTChaptersURL, shouldShowMediaPlayer, } from '../../components/BallparkMediaPlayer';
import { ResponseStatus } from '../../graphql/graphqlTypes';
import stripUUIDPrefix from '../../utils/stripUUIDPrefix';
import { SkippedStepsDivider } from './SkippedStepsDivider';
import { SlideRenderer } from './SlideRenderer';
import { Transcription } from './Transcription';
export const SlidesList = observer(function SlidesList({ activeSlideUUID, bounceStepIndex, resultStatus, stepResponses, steps, userTest, userTestResult, }) {
    var _a, _b, _c, _d, _e;
    const isActiveSlideSetViaScroll = useRef(false);
    const [prototypeStepPlayingUrl, setPrototypeStepPlayingUrl] = React.useState(null);
    const isMediaChapterFeatureFlagEnabled = useFeatureFlag('media-chapters');
    const screenersCount = (_a = ScreenerUtils.getMiddleScreenerStepUUIDs(userTest).length) !== null && _a !== void 0 ? _a : 0;
    const totalStepsCount = steps.length - screenersCount;
    const getCurrentSlideNumber = (step) => {
        const currentStepNumber = steps.findIndex((currentStep) => step.uuid === currentStep.uuid) +
            1;
        // Screeners don't have slide number on them, so we want to start
        // counting from welcome step
        return currentStepNumber - screenersCount;
    };
    useEffect(() => {
        if (isActiveSlideSetViaScroll.current) {
            isActiveSlideSetViaScroll.current = false;
            return;
        }
        const elementId = stripUUIDPrefix({ uuid: activeSlideUUID });
        if (elementId) {
            const element = document.getElementById(elementId);
            element === null || element === void 0 ? void 0 : element.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
    }, [activeSlideUUID]);
    const [time, _setTime] = React.useState(0);
    const setTime = useRef(throttle(_setTime, 200)).current;
    const [currentSeekTime, setCurrentSeekTime] = React.useState();
    const handleSeekTo = React.useCallback((time) => {
        // Add epsilon to ensure seeking works even when seeking to the same timestamp.
        // Without this, seeking to the same time twice won't work even if the video
        // has advanced, since React may not recognise it as a state change
        const epsilon = Math.random() * 0.001;
        setCurrentSeekTime(time + epsilon);
    }, []);
    const visitedSteps = resultStatus !== ResponseStatus.Completed && !isNil(bounceStepIndex)
        ? // Bounce step is the last step that the user has seen
            // so we want to include it in this array
            steps.slice(0, bounceStepIndex + 1)
        : steps;
    const notSeenSteps = resultStatus !== ResponseStatus.Completed && !isNil(bounceStepIndex)
        ? steps.slice(bounceStepIndex + 1)
        : [];
    const { recordings } = userTestResult;
    const chaptersURL = useMemo(() => {
        var _a;
        if (isMediaChapterFeatureFlagEnabled &&
            ((_a = userTestResult === null || userTestResult === void 0 ? void 0 : userTestResult.response) === null || _a === void 0 ? void 0 : _a.document)) {
            return generateWebVTTChaptersURL(JSON.parse(userTestResult.response.document), recordings);
        }
    }, [
        isMediaChapterFeatureFlagEnabled,
        (_b = userTestResult === null || userTestResult === void 0 ? void 0 : userTestResult.response) === null || _b === void 0 ? void 0 : _b.document,
        recordings,
    ]);
    // Track media player size
    const mediaPlayerRef = useRef(null);
    const [size, setSize] = useState({
        width: undefined,
        height: undefined,
    });
    const onResize = useDebounceCallback(setSize, 200);
    useResizeObserver({
        ref: mediaPlayerRef,
        onResize,
    });
    const hasTranscription = useFeatureFlag('media-transcription');
    return (_jsx(Scrollbars, { autoHide: true, style: { flex: 1 }, children: _jsxs(Stack, { gap: "8", width: "full", className: "min-h-screen bg-gray-50 p-8", direction: { initial: 'colReverse', lg: 'row' }, "data-testid": "big-column", justify: "center", children: [_jsx(Box, { flex: "1", maxWidth: "700px", children: _jsxs(BodyInner, { children: [visitedSteps.map((step, index) => (_jsx(SlideRenderer, { active: activeSlideUUID === step.uuid, currentStepNumber: getCurrentSlideNumber(step), testId: `response-${step.className}-slide-${index}`, id: stripUUIDPrefix({ uuid: step.uuid }), step: step, stepResponse: stepResponses[step.uuid], totalStepsNumber: totalStepsCount, userTestResult: userTestResult, userTest: userTest, prototypeStepPlayingUrl: prototypeStepPlayingUrl, setPrototypeStepPlayingUrl: setPrototypeStepPlayingUrl }, step.uuid))), !!notSeenSteps.length && (_jsx(SkippedStepsDivider, { screeningStatus: userTestResult.status })), notSeenSteps.map((step, index) => (_jsx(SlideRenderer, { active: activeSlideUUID === step.uuid, currentStepNumber: getCurrentSlideNumber(step), testId: `response-${step.className}-slide-${index}`, id: stripUUIDPrefix({ uuid: step.uuid }), notSeenStep: true, step: step, stepResponse: stepResponses[step.uuid], totalStepsNumber: totalStepsCount, userTestResult: userTestResult, userTest: userTest, prototypeStepPlayingUrl: prototypeStepPlayingUrl, setPrototypeStepPlayingUrl: setPrototypeStepPlayingUrl }, step.uuid)))] }) }), shouldShowMediaPlayer(recordings.download) && (_jsxs("div", { className: "static top-8 flex w-full max-w-[700px] flex-1 flex-col gap-4 lg:sticky", children: [_jsx("div", { ref: mediaPlayerRef, children: _jsx(BallparkMediaPlayer, { recordings: recordings, chaptersURL: chaptersURL, onTimeUpdate: setTime, currentTime: currentSeekTime }) }), hasTranscription && ((_d = (_c = recordings.download) === null || _c === void 0 ? void 0 : _c.transcript) === null || _d === void 0 ? void 0 : _d.url) && (_jsx("div", { 
                            // Adding the correct height as a css variable, which we can then use
                            // with Tailwind for responsive layouts on the Transcription component
                            style: size.height
                                ? {
                                    '--transcription-scroll-height': `calc(100vh - ${
                                    // 32px top/bottom padding of the entire body
                                    // 32px tabs height
                                    // 16px spacing between media player and transcription block
                                    size.height + 32 * 3 + 16}px)`,
                                }
                                : undefined, children: _jsx(Transcription, { className: cn(
                                // Styling for small viewports
                                // Max height needs to be applied on the Radix ScrollArea element,
                                // or else it won't work.
                                'h-auto', '[&>[data-radix-scroll-area-viewport]]:max-h-96', 
                                // Styling for larger viewports
                                'lg:h-[--transcription-scroll-height]', 'lg:[&>[data-radix-scroll-area-viewport]]:max-h-none'), time: time, onSeekTo: handleSeekTo, transcriptionURL: (_e = recordings.download.transcript) === null || _e === void 0 ? void 0 : _e.url }) }))] }))] }) }));
});
const BodyInner = styled(Box) ``;
