import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { observer } from 'mobx-react';
import { useAction, useSlideUIContext } from '@marvelapp/ballpark-application';
import { Popover, Stepper } from '@marvelapp/ballpark-components';
import { CardSortingUtils, StepClass, } from '@marvelapp/user-test-creator';
import { StepOptions } from '../../common/Options';
export const CardSortingStepSettings = observer(function CardSortingStepSettings() {
    return (_jsxs(StepOptions, { children: [_jsx(RandomizeCardsToggle, {}), _jsx(RandomizeCategoriesToggle, {}), _jsx(ToggleCardNumbering, {}), _jsx(MaxCardsPerCategoryToggle, {})] }));
});
const RandomizeCardsToggle = observer(function RandomizeCardsToggle() {
    const { step } = useSlideUIContext();
    const onChange = useAction(() => {
        const currentValue = CardSortingUtils.getAreCardsRandomized(step);
        CardSortingUtils.setAreCardsRandomized(step, !currentValue);
    }, [step]);
    return (_jsx(Popover.Toggle, { checked: !!CardSortingUtils.getAreCardsRandomized(step), title: "Randomize cards", description: "Enabling this option will shuffle the order of cards randomly when recording a new user test", id: "randomize-cards-toggle", testId: "randomize-cards-toggle", onChange: onChange }));
});
const RandomizeCategoriesToggle = observer(function RandomizeCategoriesToggle() {
    const { step } = useSlideUIContext();
    const onChange = useAction(() => {
        const currentValue = CardSortingUtils.getAreCategoriesRandomized(step);
        CardSortingUtils.setAreCategoriesRandomized(step, !currentValue);
    }, [step]);
    if (step.className === StepClass.CardSortingOpen) {
        return null;
    }
    return (_jsx(Popover.Toggle, { checked: !!CardSortingUtils.getAreCategoriesRandomized(step), title: "Randomize categories", description: "Enabling this option will shuffle the order of categories randomly when recording a new user test", id: "randomize-categories-toggle", testId: "randomize-categories-toggle", onChange: onChange }));
});
const ToggleCardNumbering = observer(function ToggleCardNumbering() {
    const { step } = useSlideUIContext();
    const onChange = useAction(() => {
        const currentValue = CardSortingUtils.getIsNumberOrderInCategoryVisible(step);
        CardSortingUtils.setIsNumberOrderInCategoryVisible(step, !currentValue);
    }, [step]);
    return (_jsx(Popover.Toggle, { checked: !!CardSortingUtils.getIsNumberOrderInCategoryVisible(step), title: "Enable card numbering", description: "Activate this option to display a sequential number on each card within its category", id: "card-numbering-toggle", testId: "card-numbering-toggle", onChange: onChange }));
});
const MaxCardsPerCategoryToggle = observer(function MaxCardsPerCategoryToggle() {
    const { step } = useSlideUIContext();
    const onToggleChange = useAction(() => {
        const currentValue = CardSortingUtils.getMaxCardsPerCategory(step) || 0;
        CardSortingUtils.setMaxCardsPerCategory(step, currentValue > 0 ? undefined : 5);
    }, [step]);
    const onIncrease = useAction(() => {
        const currentValue = CardSortingUtils.getMaxCardsPerCategory(step) || 0;
        CardSortingUtils.setMaxCardsPerCategory(step, currentValue + 1);
    }, [step]);
    const onDecrease = useAction(() => {
        const currentValue = CardSortingUtils.getMaxCardsPerCategory(step) || 0;
        if (currentValue === 1) {
            CardSortingUtils.setMaxCardsPerCategory(step, undefined);
        }
        else if (currentValue > 0) {
            CardSortingUtils.setMaxCardsPerCategory(step, currentValue - 1);
        }
    }, [step]);
    return (_jsxs(_Fragment, { children: [_jsx(Popover.Toggle, { checked: !!CardSortingUtils.getMaxCardsPerCategory(step), title: "Max cards per category", description: "Toggle to enable setting a limit on the number of cards that can be added to a category", id: "max-cards-per-category-toggle", testId: "max-cards-per-category-toggle", onChange: onToggleChange }), typeof CardSortingUtils.getMaxCardsPerCategory(step) === 'number' && (_jsx(Stepper, { onDecrement: onDecrease, onIncrement: onIncrease, size: "s", stepperTestId: "max-cards-per-category-stepper", children: CardSortingUtils.getMaxCardsPerCategory(step) }))] }));
});
