import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { observer } from 'mobx-react';
import { useEffect, useRef } from 'react';
import * as React from 'react';
import { ScrollArea, Stack } from '@marvelapp/ballpark-components';
import { useResponsesContext } from './ResponsesContext';
import { ResponsesDataTable } from './ResponsesDataTable';
import { ResponsesHeader } from './ResponsesHeader';
import { Toolbar } from './Toolbar';
export const Responses = observer(function Responses() {
    const { table, setScrollableAreaDimensions, setScrollableAreaElement } = useResponsesContext();
    const scrollableAreaRef = useRef(null);
    // Tracking and storing the size of the scrollable area on resize
    const isTableLoaded = !!table;
    useEffect(() => {
        const scrollableArea = scrollableAreaRef.current;
        if (!scrollableArea)
            return;
        setScrollableAreaElement(scrollableArea);
        const resizeObserver = new ResizeObserver(() => {
            setScrollableAreaDimensions(scrollableArea.getBoundingClientRect());
        });
        resizeObserver.observe(scrollableArea);
        return () => {
            resizeObserver.disconnect();
        };
    }, [setScrollableAreaElement, setScrollableAreaDimensions, isTableLoaded]);
    if (!table)
        return null;
    return (
    // 100% viewport height, minus the height of the project header bar
    _jsxs(Stack, { className: "relative h-[calc(100vh_-_50px)] overflow-hidden bg-gray-50", children: [_jsx(ResponsesHeader, {}), _jsx(Stack, { width: "full", 
                // This removes the height of the ResponsesHeader above the table container
                // (where the sort & filter buttons are)
                className: "h-[calc(100%_-_60px)] p-4 pt-0", children: _jsx(Stack, { width: "full", className: "h-full overflow-scroll rounded-lg border border-gray-600/10 bg-white", children: _jsxs(ScrollArea.Root, { children: [_jsx(ScrollArea.Viewport, { ref: scrollableAreaRef, children: _jsx(ResponsesDataTable, { tableContainerRef: scrollableAreaRef }) }), _jsx(ScrollArea.Scrollbar, { orientation: "horizontal", className: "z-20", children: _jsx(ScrollArea.Thumb, {}) }), _jsx(ScrollArea.Scrollbar, { orientation: "vertical", className: "z-40", children: _jsx(ScrollArea.Thumb, {}) })] }) }) }), _jsx(Toolbar, {})] }));
});
