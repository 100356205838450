import styled from '@marvelapp/styled';
import { Box } from '@marvelapp/ui';
const TwoColumnSlide = styled(Box).attrs({
    bg: 'background',
    borderBottomLeftRadius: 'xl',
    borderBottomRightRadius: 'xl',
}) `
  width: 100%;
  height: 100%;
  flex: 1;
  display: flex;
`;
export default TwoColumnSlide;
