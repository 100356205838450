import { IdentityOperator } from '../../userTest/types';
export function getNextStepUUID(conditions, answer, isMultipleChoice) {
    const { ALWAYS: always, rules } = conditions;
    // There's only ever one rule set
    const { requirements, destinationStepUUID } = rules[0];
    if (isMultipleChoice) {
        // 'Can select' (which we can ignore), 'Required' and 'Decline'
        const mustSelect = requirements.filter((requirement) => {
            return requirement.operator === IdentityOperator.Is;
        });
        const mustNotSelect = requirements.filter((requirement) => {
            return requirement.operator === IdentityOperator.IsNot;
        });
        const mustSelectSatisfied = mustSelect.every(({ selectedChoiceUUID }) => {
            if (selectedChoiceUUID === null)
                return true; // TODO cleaner to filter partial rules first?
            return answer.includes(selectedChoiceUUID);
        });
        const mustNotSelectSatisfied = mustNotSelect.every(({ selectedChoiceUUID }) => {
            if (selectedChoiceUUID === null)
                return true; // TODO cleaner to filter partial rules first?
            return !answer.includes(selectedChoiceUUID);
        });
        if (mustSelectSatisfied && mustNotSelectSatisfied) {
            return destinationStepUUID;
        }
    }
    else {
        // 'Accept' and 'Decline' so we only need to check 'Decline'
        const mustNotSelect = requirements.filter((requirement) => {
            return requirement.operator === IdentityOperator.IsNot;
        });
        const satisfied = mustNotSelect.every(({ selectedChoiceUUID }) => {
            if (selectedChoiceUUID === null)
                return true; // TODO cleaner to filter partial rules first?
            return !answer.includes(selectedChoiceUUID);
        });
        if (satisfied) {
            return destinationStepUUID;
        }
    }
    return always;
}
