import { jsx as _jsx } from "react/jsx-runtime";
import { noop } from 'lodash-es';
import { observer } from 'mobx-react';
import { useRef } from 'react';
import * as React from 'react';
import AutosizeInput from 'react-18-input-autosize';
import { buttonKinds, buttonSizes, } from '@marvelapp/ballpark-components';
import styled from '@marvelapp/styled';
import { inputStyles } from '@marvelapp/ui';
import { useStateFromProp } from '../../hooks/useStateFromProp';
export default observer(function EditableButton({ kind, onSaveButtonText = noop, placeholder = '', readOnly = false, size = 's', testId, value, }) {
    const [internalValue, setInternalValue] = useStateFromProp(value);
    const textareaRef = useRef(null);
    const onChange = (event) => {
        setInternalValue(event.target.value);
        onSaveButtonText(event.target.value);
    };
    return (_jsx(Button, Object.assign({ "data-testid": testId }, buttonKinds[kind], buttonSizes[size], { maxLength: 128, onChange: onChange, ref: textareaRef, readOnly: readOnly, value: internalValue, placeholder: placeholder })));
});
const Button = styled(AutosizeInput).attrs({
    fontFamily: 0,
    py: 0,
    px: 's',
    hoverBoxShadow: 'editable.hover',
    focusBoxShadow: 'editable.focus',
    placeholderColor: 'background',
}) `
  input {
    ${inputStyles};

    transition: none;
    letter-spacing: -0.2px;
  }
`;
