import { jsx as _jsx } from "react/jsx-runtime";
import { observer } from 'mobx-react';
import * as React from 'react';
import { useIntl } from 'react-intl';
import { CONTINUE_BUTTON_COPY } from '@marvelapp/ballpark-copy';
import { OneColumnSlide } from '../common/OneColumnSlide';
import SlideContent from '../common/SlideContent';
export const FixedScreenerSlide = observer(function FixedScreenerSlide({ onMainCTAClick, }) {
    const intl = useIntl();
    return (_jsx(OneColumnSlide, { children: _jsx(SlideContent, { ctaOnClick: onMainCTAClick, maxHeadingLength: 512, maxParagraphLength: 512, ctaText: intl.formatMessage(CONTINUE_BUTTON_COPY), withRequiredBadge: false, withVideoGuide: false, withSlideCount: false }) }));
});
