import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { getCoreRowModel, getSortedRowModel, useReactTable, } from '@tanstack/react-table';
import { useEffect, useReducer, useState } from 'react';
import { LiveIcon, LoaderIcon, StreamingIcon } from '@marvelapp/ballpark-icons';
import { BtwAvatar } from './BtwAvatar';
import { BtwButton } from './BtwButton';
import { BtwLink } from './BtwLink';
import { BtwText } from './BtwText';
import { CtaMessage } from './CtaMessage';
import { Stack } from './Stack';
import { cn } from './utils';
const AvatarsCell = function AvatarsCell({ users }) {
    const usersCount = users.length;
    if (!usersCount) {
        return (_jsxs(Stack, { "data-testid": "no-users", direction: "row", align: "center", gap: "2", children: [_jsx("div", { className: "size-4 rounded-full border border-dashed border-gray-300 bg-gray-50" }), _jsx(BtwText, { variant: "disabled", children: "N/A" })] }));
    }
    return (_jsx(Stack, { direction: "row", children: users.map(({ avatarUrl, displayName }, index) => {
            const zIndex = (usersCount - index) * 10;
            return (_jsx(Stack, { className: `z-${zIndex} -ml-1 rounded-full border-2 border-white [&:first-child]:ml-0`, "data-testid": "avatar", children: _jsx(BtwAvatar, { name: displayName, size: "sm", src: avatarUrl }) }, `${avatarUrl}-${displayName}`));
        }) }));
};
const SessionStatus = function SessionStatus({ startTime, status, }) {
    const [_, forceUpdate] = useReducer((c) => c + 1, 0);
    // Set up an interval so we re-render at least every second (approx)
    useEffect(() => {
        const interval = setInterval(forceUpdate, 1000);
        return () => clearInterval(interval);
    }, []);
    const timeElapsed = getTimeElapsed(startTime);
    const statusIcon = status === 'Live' ? (_jsx(LiveIcon, { className: "size-4 text-green-500" })) : (_jsx(LoaderIcon, { className: "size-4 text-amber-500" }));
    return (_jsxs(Stack, { direction: "row", align: "center", gap: "1.5", children: [statusIcon, _jsxs(BtwText, { children: [status, " (", timeElapsed, ")"] })] }));
};
function getTimeElapsed(timestamp) {
    const timeNow = new Date();
    const startTime = new Date(timestamp);
    const timeElapsedInSeconds = (timeNow.getTime() - startTime.getTime()) / 1000;
    const hours = Math.floor(timeElapsedInSeconds / 3600);
    const minutes = Math.floor((timeElapsedInSeconds % 3600) / 60);
    const seconds = Math.floor(timeElapsedInSeconds % 60);
    const HH = hours > 0 ? `${addLeadingZero(hours)}:` : '';
    const MM = minutes > 0 ? `${addLeadingZero(minutes)}:` : '00:';
    const SS = addLeadingZero(seconds);
    return `${HH}${MM}${SS}`;
}
function addLeadingZero(value) {
    return value < 10 ? `0${value}` : value;
}
const NoSessionsAvailableMessage = function NoSessionsAvailableMessage({ className, description, title, }) {
    return (_jsx(Stack, { align: "center", className: cn('min-h-[calc(100vh-50px)]', className), "data-testid": "no-sessions-available-message", justify: "center", gap: "6", children: _jsx(CtaMessage.Root, { size: "sm", children: _jsxs(CtaMessage.Header, { icon: _jsx(StreamingIcon, {}), children: [_jsx(CtaMessage.Heading, { children: title }), _jsx(CtaMessage.Description, { className: "max-w-[600px]", children: description })] }) }) }));
};
export function sortSessionsByStatus({ startTime: startTimeA, status: statusA }, { startTime: startTimeB, status: statusB }) {
    if (statusA === 'Live' && statusB === 'Waiting')
        return 1;
    if (statusA === 'Waiting' && statusB === 'Live')
        return -1;
    return startTimeA > startTimeB ? -1 : 1;
}
export function useSessionsTable(sessions, columns) {
    const [sorting, setSorting] = useState([
        {
            id: 'status',
            desc: false,
        },
    ]);
    const table = useReactTable({
        data: sessions,
        columns,
        getCoreRowModel: getCoreRowModel(),
        getSortedRowModel: getSortedRowModel(),
        state: {
            sorting,
        },
        onSortingChange: (updater) => {
            let newSorting = typeof updater === 'function'
                ? updater(table.getState().sorting)
                : updater;
            // Unsorted sorting state is and empty array. We need to ensure at
            // least one sorting state is always applied
            if (newSorting.length === 0) {
                newSorting = [{ id: 'status', desc: false }];
            }
            setSorting(newSorting);
        },
    });
    return table;
}
const JoinSessionCTA = function JoinSessionCTA({ shareUrl, }) {
    if (!shareUrl)
        return null;
    return (_jsx(Stack, { width: "full", align: "end", children: _jsx(BtwLink, { "data-testid": "join-session-cta", href: shareUrl, target: "_blank", underline: false, children: _jsx(BtwButton, { size: "sm", children: "Join session" }) }) }));
};
export const LiveSessionsTable = {
    AvatarsCell,
    JoinSessionCTA,
    NoSessionsAvailableMessage,
    SessionStatus,
};
