export var MediaType;
(function (MediaType) {
    MediaType[MediaType["Audio"] = 0] = "Audio";
    MediaType[MediaType["Video"] = 1] = "Video";
    MediaType[MediaType["None"] = 2] = "None";
})(MediaType || (MediaType = {}));
export function getMediaType(media) {
    if (media.mimeType.startsWith('audio')) {
        return MediaType.Audio;
    }
    if (media.mimeType.startsWith('video')) {
        return MediaType.Video;
    }
    return MediaType.None;
}
