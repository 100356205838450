import { jsx as _jsx } from "react/jsx-runtime";
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { observer } from 'mobx-react';
import * as React from 'react';
import styled from '@marvelapp/styled';
import { ScaleUp } from './Motion';
import PreferenceItem from './PreferenceItem';
export default observer(function DraggablePreferenceItem({ children, id, index, isFocussed, onDelete, }) {
    const { attributes, isDragging, listeners, setNodeRef, transform, transition, } = useSortable({ id });
    const style = {
        transition,
        transform: CSS.Transform.toString(transform),
        zIndex: isDragging ? 100 : 'unset',
    };
    return (_jsx(ScaleUp, { testId: "choice-container", children: _jsx(DraggableContainer, Object.assign({ ref: setNodeRef, 
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            style: style }, attributes, listeners, { children: _jsx(PreferenceItem, { isDragging: isDragging, isFocussed: isFocussed, index: index, onDelete: onDelete, children: children }) })) }, id));
});
const DraggableContainer = styled.div `
  min-height: 100%;
  display: flex;
  position: relative;
`;
