import { createElement as _createElement } from "react";
import { motion, useReducedMotion } from 'framer-motion';
import { observer } from 'mobx-react';
import * as React from 'react';
import theme from '@marvelapp/ballpark-theme';
import styled from '@marvelapp/styled';
import { Box } from '@marvelapp/ui';
const animation = {
    animate: {
        y: 0,
    },
    exit: {
        y: '20%',
    },
    initial: {
        y: '20%',
    },
    transition: {
        ease: theme.easings.smooth.array,
        duration: 0.35,
    },
};
export default observer(function MobileInstructions({ recordingHasStarted, children, }) {
    const shouldReduceMotion = useReducedMotion();
    const animate = shouldReduceMotion ? {} : animation;
    if (!recordingHasStarted) {
        return (_createElement(Container, Object.assign({}, animate, { key: "guard-modal" }), children));
    }
    return null;
});
const Container = styled(motion(Box)).attrs({
    bg: 'background',
    zIndex: 'record.controls',
}) `
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: center;

  position: absolute;
  top: 0;
  left: 0;
`;
