// THIS FILE WAS AUTOGENERATED - DO NOT EDIT DIRECTLY
// RUN "yarn schema:generate" 
// 
/* eslint-disable */
// @ts-nocheck
"use strict";
var schema27 = { "$schema": "http://json-schema.org/draft-07/schema#", "$ref": "#/definitions/Descendant", "definitions": { "Descendant": { "anyOf": [{ "$ref": "#/definitions/CustomElement" }, { "$ref": "#/definitions/CustomText" }] }, "CustomElement": { "type": "object", "properties": { "type": { "enum": ["p", "a", "ul", "ol", "li", "lic"] } }, "required": ["type"], "allOf": [{ "if": { "properties": { "type": { "type": "string", "const": "p" } } }, "then": { "$ref": "#/definitions/Paragraph" } }, { "if": { "properties": { "type": { "type": "string", "const": "a" } } }, "then": { "$ref": "#/definitions/Link" } }, { "if": { "properties": { "type": { "type": "string", "enum": ["ul", "ol", "li", "lic"] } } }, "then": { "$ref": "#/definitions/List" } }] }, "Paragraph": { "type": "object", "properties": { "type": { "type": "string", "const": "p" }, "children": { "type": "array", "items": { "$ref": "#/definitions/Descendant" } } }, "required": ["type", "children"], "additionalProperties": false }, "Link": { "type": "object", "properties": { "type": { "type": "string", "const": "a" }, "url": { "type": "string" }, "target": { "type": "string" }, "children": { "type": "array", "items": { "$ref": "#/definitions/Descendant" } } }, "required": ["type", "children"], "additionalProperties": false }, "List": { "type": "object", "properties": { "type": { "type": "string", "enum": ["ul", "ol", "li", "lic"] }, "children": { "type": "array", "items": { "$ref": "#/definitions/Descendant" } } }, "required": ["type", "children"], "additionalProperties": false }, "CustomText": { "type": "object", "properties": { "text": { "type": "string" }, "bold": { "type": "boolean" }, "italic": { "type": "boolean" }, "strikethrough": { "type": "boolean" } }, "required": ["text"], "additionalProperties": false } } };
var schema28 = { "anyOf": [{ "$ref": "#/definitions/CustomElement" }, { "$ref": "#/definitions/CustomText" }] };
var schema33 = { "type": "object", "properties": { "text": { "type": "string" }, "bold": { "type": "boolean" }, "italic": { "type": "boolean" }, "strikethrough": { "type": "boolean" } }, "required": ["text"], "additionalProperties": false };
var schema29 = { "type": "object", "properties": { "type": { "enum": ["p", "a", "ul", "ol", "li", "lic"] } }, "required": ["type"], "allOf": [{ "if": { "properties": { "type": { "type": "string", "const": "p" } } }, "then": { "$ref": "#/definitions/Paragraph" } }, { "if": { "properties": { "type": { "type": "string", "const": "a" } } }, "then": { "$ref": "#/definitions/Link" } }, { "if": { "properties": { "type": { "type": "string", "enum": ["ul", "ol", "li", "lic"] } } }, "then": { "$ref": "#/definitions/List" } }] };
var schema30 = { "type": "object", "properties": { "type": { "type": "string", "const": "p" }, "children": { "type": "array", "items": { "$ref": "#/definitions/Descendant" } } }, "required": ["type", "children"], "additionalProperties": false };
var wrapper0 = { validate: validate11 };
function validate13(data, _a) {
    var _b = _a === void 0 ? {} : _a, _c = _b.instancePath, instancePath = _c === void 0 ? "" : _c, parentData = _b.parentData, parentDataProperty = _b.parentDataProperty, _d = _b.rootData, rootData = _d === void 0 ? data : _d;
    var vErrors = null;
    var errors = 0;
    if (errors === 0) {
        if (data && typeof data == "object" && !Array.isArray(data)) {
            var missing0 = void 0;
            if (((data.type === undefined) && (missing0 = "type")) || ((data.children === undefined) && (missing0 = "children"))) {
                validate13.errors = [{ instancePath: instancePath, schemaPath: "#/required", keyword: "required", params: { missingProperty: missing0 }, message: "must have required property '" + missing0 + "'" }];
                return false;
            }
            else {
                var _errs1 = errors;
                for (var key0 in data) {
                    if (!((key0 === "type") || (key0 === "children"))) {
                        validate13.errors = [{ instancePath: instancePath, schemaPath: "#/additionalProperties", keyword: "additionalProperties", params: { additionalProperty: key0 }, message: "must NOT have additional properties" }];
                        return false;
                        break;
                    }
                }
                if (_errs1 === errors) {
                    if (data.type !== undefined) {
                        var data0 = data.type;
                        var _errs2 = errors;
                        if (typeof data0 !== "string") {
                            validate13.errors = [{ instancePath: instancePath + "/type", schemaPath: "#/properties/type/type", keyword: "type", params: { type: "string" }, message: "must be string" }];
                            return false;
                        }
                        if ("p" !== data0) {
                            validate13.errors = [{ instancePath: instancePath + "/type", schemaPath: "#/properties/type/const", keyword: "const", params: { allowedValue: "p" }, message: "must be equal to constant" }];
                            return false;
                        }
                        var valid0 = _errs2 === errors;
                    }
                    else {
                        var valid0 = true;
                    }
                    if (valid0) {
                        if (data.children !== undefined) {
                            var data1 = data.children;
                            var _errs4 = errors;
                            if (errors === _errs4) {
                                if (Array.isArray(data1)) {
                                    var valid1 = true;
                                    var len0 = data1.length;
                                    for (var i0 = 0; i0 < len0; i0++) {
                                        var _errs6 = errors;
                                        if (!(wrapper0.validate(data1[i0], { instancePath: instancePath + "/children/" + i0, parentData: data1, parentDataProperty: i0, rootData: rootData }))) {
                                            vErrors = vErrors === null ? wrapper0.validate.errors : vErrors.concat(wrapper0.validate.errors);
                                            errors = vErrors.length;
                                        }
                                        var valid1 = _errs6 === errors;
                                        if (!valid1) {
                                            break;
                                        }
                                    }
                                }
                                else {
                                    validate13.errors = [{ instancePath: instancePath + "/children", schemaPath: "#/properties/children/type", keyword: "type", params: { type: "array" }, message: "must be array" }];
                                    return false;
                                }
                            }
                            var valid0 = _errs4 === errors;
                        }
                        else {
                            var valid0 = true;
                        }
                    }
                }
            }
        }
        else {
            validate13.errors = [{ instancePath: instancePath, schemaPath: "#/type", keyword: "type", params: { type: "object" }, message: "must be object" }];
            return false;
        }
    }
    validate13.errors = vErrors;
    return errors === 0;
}
var schema31 = { "type": "object", "properties": { "type": { "type": "string", "const": "a" }, "url": { "type": "string" }, "target": { "type": "string" }, "children": { "type": "array", "items": { "$ref": "#/definitions/Descendant" } } }, "required": ["type", "children"], "additionalProperties": false };
function validate15(data, _a) {
    var _b = _a === void 0 ? {} : _a, _c = _b.instancePath, instancePath = _c === void 0 ? "" : _c, parentData = _b.parentData, parentDataProperty = _b.parentDataProperty, _d = _b.rootData, rootData = _d === void 0 ? data : _d;
    var vErrors = null;
    var errors = 0;
    if (errors === 0) {
        if (data && typeof data == "object" && !Array.isArray(data)) {
            var missing0 = void 0;
            if (((data.type === undefined) && (missing0 = "type")) || ((data.children === undefined) && (missing0 = "children"))) {
                validate15.errors = [{ instancePath: instancePath, schemaPath: "#/required", keyword: "required", params: { missingProperty: missing0 }, message: "must have required property '" + missing0 + "'" }];
                return false;
            }
            else {
                var _errs1 = errors;
                for (var key0 in data) {
                    if (!((((key0 === "type") || (key0 === "url")) || (key0 === "target")) || (key0 === "children"))) {
                        validate15.errors = [{ instancePath: instancePath, schemaPath: "#/additionalProperties", keyword: "additionalProperties", params: { additionalProperty: key0 }, message: "must NOT have additional properties" }];
                        return false;
                        break;
                    }
                }
                if (_errs1 === errors) {
                    if (data.type !== undefined) {
                        var data0 = data.type;
                        var _errs2 = errors;
                        if (typeof data0 !== "string") {
                            validate15.errors = [{ instancePath: instancePath + "/type", schemaPath: "#/properties/type/type", keyword: "type", params: { type: "string" }, message: "must be string" }];
                            return false;
                        }
                        if ("a" !== data0) {
                            validate15.errors = [{ instancePath: instancePath + "/type", schemaPath: "#/properties/type/const", keyword: "const", params: { allowedValue: "a" }, message: "must be equal to constant" }];
                            return false;
                        }
                        var valid0 = _errs2 === errors;
                    }
                    else {
                        var valid0 = true;
                    }
                    if (valid0) {
                        if (data.url !== undefined) {
                            var _errs4 = errors;
                            if (typeof data.url !== "string") {
                                validate15.errors = [{ instancePath: instancePath + "/url", schemaPath: "#/properties/url/type", keyword: "type", params: { type: "string" }, message: "must be string" }];
                                return false;
                            }
                            var valid0 = _errs4 === errors;
                        }
                        else {
                            var valid0 = true;
                        }
                        if (valid0) {
                            if (data.target !== undefined) {
                                var _errs6 = errors;
                                if (typeof data.target !== "string") {
                                    validate15.errors = [{ instancePath: instancePath + "/target", schemaPath: "#/properties/target/type", keyword: "type", params: { type: "string" }, message: "must be string" }];
                                    return false;
                                }
                                var valid0 = _errs6 === errors;
                            }
                            else {
                                var valid0 = true;
                            }
                            if (valid0) {
                                if (data.children !== undefined) {
                                    var data3 = data.children;
                                    var _errs8 = errors;
                                    if (errors === _errs8) {
                                        if (Array.isArray(data3)) {
                                            var valid1 = true;
                                            var len0 = data3.length;
                                            for (var i0 = 0; i0 < len0; i0++) {
                                                var _errs10 = errors;
                                                if (!(wrapper0.validate(data3[i0], { instancePath: instancePath + "/children/" + i0, parentData: data3, parentDataProperty: i0, rootData: rootData }))) {
                                                    vErrors = vErrors === null ? wrapper0.validate.errors : vErrors.concat(wrapper0.validate.errors);
                                                    errors = vErrors.length;
                                                }
                                                var valid1 = _errs10 === errors;
                                                if (!valid1) {
                                                    break;
                                                }
                                            }
                                        }
                                        else {
                                            validate15.errors = [{ instancePath: instancePath + "/children", schemaPath: "#/properties/children/type", keyword: "type", params: { type: "array" }, message: "must be array" }];
                                            return false;
                                        }
                                    }
                                    var valid0 = _errs8 === errors;
                                }
                                else {
                                    var valid0 = true;
                                }
                            }
                        }
                    }
                }
            }
        }
        else {
            validate15.errors = [{ instancePath: instancePath, schemaPath: "#/type", keyword: "type", params: { type: "object" }, message: "must be object" }];
            return false;
        }
    }
    validate15.errors = vErrors;
    return errors === 0;
}
var schema32 = { "type": "object", "properties": { "type": { "type": "string", "enum": ["ul", "ol", "li", "lic"] }, "children": { "type": "array", "items": { "$ref": "#/definitions/Descendant" } } }, "required": ["type", "children"], "additionalProperties": false };
function validate17(data, _a) {
    var _b = _a === void 0 ? {} : _a, _c = _b.instancePath, instancePath = _c === void 0 ? "" : _c, parentData = _b.parentData, parentDataProperty = _b.parentDataProperty, _d = _b.rootData, rootData = _d === void 0 ? data : _d;
    var vErrors = null;
    var errors = 0;
    if (errors === 0) {
        if (data && typeof data == "object" && !Array.isArray(data)) {
            var missing0 = void 0;
            if (((data.type === undefined) && (missing0 = "type")) || ((data.children === undefined) && (missing0 = "children"))) {
                validate17.errors = [{ instancePath: instancePath, schemaPath: "#/required", keyword: "required", params: { missingProperty: missing0 }, message: "must have required property '" + missing0 + "'" }];
                return false;
            }
            else {
                var _errs1 = errors;
                for (var key0 in data) {
                    if (!((key0 === "type") || (key0 === "children"))) {
                        validate17.errors = [{ instancePath: instancePath, schemaPath: "#/additionalProperties", keyword: "additionalProperties", params: { additionalProperty: key0 }, message: "must NOT have additional properties" }];
                        return false;
                        break;
                    }
                }
                if (_errs1 === errors) {
                    if (data.type !== undefined) {
                        var data0 = data.type;
                        var _errs2 = errors;
                        if (typeof data0 !== "string") {
                            validate17.errors = [{ instancePath: instancePath + "/type", schemaPath: "#/properties/type/type", keyword: "type", params: { type: "string" }, message: "must be string" }];
                            return false;
                        }
                        if (!((((data0 === "ul") || (data0 === "ol")) || (data0 === "li")) || (data0 === "lic"))) {
                            validate17.errors = [{ instancePath: instancePath + "/type", schemaPath: "#/properties/type/enum", keyword: "enum", params: { allowedValues: schema32.properties.type.enum }, message: "must be equal to one of the allowed values" }];
                            return false;
                        }
                        var valid0 = _errs2 === errors;
                    }
                    else {
                        var valid0 = true;
                    }
                    if (valid0) {
                        if (data.children !== undefined) {
                            var data1 = data.children;
                            var _errs4 = errors;
                            if (errors === _errs4) {
                                if (Array.isArray(data1)) {
                                    var valid1 = true;
                                    var len0 = data1.length;
                                    for (var i0 = 0; i0 < len0; i0++) {
                                        var _errs6 = errors;
                                        if (!(wrapper0.validate(data1[i0], { instancePath: instancePath + "/children/" + i0, parentData: data1, parentDataProperty: i0, rootData: rootData }))) {
                                            vErrors = vErrors === null ? wrapper0.validate.errors : vErrors.concat(wrapper0.validate.errors);
                                            errors = vErrors.length;
                                        }
                                        var valid1 = _errs6 === errors;
                                        if (!valid1) {
                                            break;
                                        }
                                    }
                                }
                                else {
                                    validate17.errors = [{ instancePath: instancePath + "/children", schemaPath: "#/properties/children/type", keyword: "type", params: { type: "array" }, message: "must be array" }];
                                    return false;
                                }
                            }
                            var valid0 = _errs4 === errors;
                        }
                        else {
                            var valid0 = true;
                        }
                    }
                }
            }
        }
        else {
            validate17.errors = [{ instancePath: instancePath, schemaPath: "#/type", keyword: "type", params: { type: "object" }, message: "must be object" }];
            return false;
        }
    }
    validate17.errors = vErrors;
    return errors === 0;
}
function validate12(data, _a) {
    var _b = _a === void 0 ? {} : _a, _c = _b.instancePath, instancePath = _c === void 0 ? "" : _c, parentData = _b.parentData, parentDataProperty = _b.parentDataProperty, _d = _b.rootData, rootData = _d === void 0 ? data : _d;
    var vErrors = null;
    var errors = 0;
    var _errs1 = errors;
    var _errs2 = errors;
    var valid1 = true;
    var _errs3 = errors;
    if (data && typeof data == "object" && !Array.isArray(data)) {
        if (data.type !== undefined) {
            var data0 = data.type;
            if (typeof data0 !== "string") {
                var err0 = {};
                if (vErrors === null) {
                    vErrors = [err0];
                }
                else {
                    vErrors.push(err0);
                }
                errors++;
            }
            if ("p" !== data0) {
                var err1 = {};
                if (vErrors === null) {
                    vErrors = [err1];
                }
                else {
                    vErrors.push(err1);
                }
                errors++;
            }
        }
    }
    var _valid0 = _errs3 === errors;
    errors = _errs2;
    if (vErrors !== null) {
        if (_errs2) {
            vErrors.length = _errs2;
        }
        else {
            vErrors = null;
        }
    }
    if (_valid0) {
        var _errs6 = errors;
        if (!(validate13(data, { instancePath: instancePath, parentData: parentData, parentDataProperty: parentDataProperty, rootData: rootData }))) {
            vErrors = vErrors === null ? validate13.errors : vErrors.concat(validate13.errors);
            errors = vErrors.length;
        }
        var _valid0 = _errs6 === errors;
        valid1 = _valid0;
    }
    if (!valid1) {
        var err2 = { instancePath: instancePath, schemaPath: "#/allOf/0/if", keyword: "if", params: { failingKeyword: "then" }, message: "must match \"then\" schema" };
        if (vErrors === null) {
            vErrors = [err2];
        }
        else {
            vErrors.push(err2);
        }
        errors++;
        validate12.errors = vErrors;
        return false;
    }
    var valid0 = _errs1 === errors;
    if (valid0) {
        var _errs7 = errors;
        var _errs8 = errors;
        var valid3 = true;
        var _errs9 = errors;
        if (data && typeof data == "object" && !Array.isArray(data)) {
            if (data.type !== undefined) {
                var data1 = data.type;
                if (typeof data1 !== "string") {
                    var err3 = {};
                    if (vErrors === null) {
                        vErrors = [err3];
                    }
                    else {
                        vErrors.push(err3);
                    }
                    errors++;
                }
                if ("a" !== data1) {
                    var err4 = {};
                    if (vErrors === null) {
                        vErrors = [err4];
                    }
                    else {
                        vErrors.push(err4);
                    }
                    errors++;
                }
            }
        }
        var _valid1 = _errs9 === errors;
        errors = _errs8;
        if (vErrors !== null) {
            if (_errs8) {
                vErrors.length = _errs8;
            }
            else {
                vErrors = null;
            }
        }
        if (_valid1) {
            var _errs12 = errors;
            if (!(validate15(data, { instancePath: instancePath, parentData: parentData, parentDataProperty: parentDataProperty, rootData: rootData }))) {
                vErrors = vErrors === null ? validate15.errors : vErrors.concat(validate15.errors);
                errors = vErrors.length;
            }
            var _valid1 = _errs12 === errors;
            valid3 = _valid1;
        }
        if (!valid3) {
            var err5 = { instancePath: instancePath, schemaPath: "#/allOf/1/if", keyword: "if", params: { failingKeyword: "then" }, message: "must match \"then\" schema" };
            if (vErrors === null) {
                vErrors = [err5];
            }
            else {
                vErrors.push(err5);
            }
            errors++;
            validate12.errors = vErrors;
            return false;
        }
        var valid0 = _errs7 === errors;
        if (valid0) {
            var _errs13 = errors;
            var _errs14 = errors;
            var valid5 = true;
            var _errs15 = errors;
            if (data && typeof data == "object" && !Array.isArray(data)) {
                if (data.type !== undefined) {
                    var data2 = data.type;
                    if (typeof data2 !== "string") {
                        var err6 = {};
                        if (vErrors === null) {
                            vErrors = [err6];
                        }
                        else {
                            vErrors.push(err6);
                        }
                        errors++;
                    }
                    if (!((((data2 === "ul") || (data2 === "ol")) || (data2 === "li")) || (data2 === "lic"))) {
                        var err7 = {};
                        if (vErrors === null) {
                            vErrors = [err7];
                        }
                        else {
                            vErrors.push(err7);
                        }
                        errors++;
                    }
                }
            }
            var _valid2 = _errs15 === errors;
            errors = _errs14;
            if (vErrors !== null) {
                if (_errs14) {
                    vErrors.length = _errs14;
                }
                else {
                    vErrors = null;
                }
            }
            if (_valid2) {
                var _errs18 = errors;
                if (!(validate17(data, { instancePath: instancePath, parentData: parentData, parentDataProperty: parentDataProperty, rootData: rootData }))) {
                    vErrors = vErrors === null ? validate17.errors : vErrors.concat(validate17.errors);
                    errors = vErrors.length;
                }
                var _valid2 = _errs18 === errors;
                valid5 = _valid2;
            }
            if (!valid5) {
                var err8 = { instancePath: instancePath, schemaPath: "#/allOf/2/if", keyword: "if", params: { failingKeyword: "then" }, message: "must match \"then\" schema" };
                if (vErrors === null) {
                    vErrors = [err8];
                }
                else {
                    vErrors.push(err8);
                }
                errors++;
                validate12.errors = vErrors;
                return false;
            }
            var valid0 = _errs13 === errors;
        }
    }
    if (errors === 0) {
        if (data && typeof data == "object" && !Array.isArray(data)) {
            var missing0 = void 0;
            if ((data.type === undefined) && (missing0 = "type")) {
                validate12.errors = [{ instancePath: instancePath, schemaPath: "#/required", keyword: "required", params: { missingProperty: missing0 }, message: "must have required property '" + missing0 + "'" }];
                return false;
            }
            else {
                if (data.type !== undefined) {
                    var data3 = data.type;
                    if (!((((((data3 === "p") || (data3 === "a")) || (data3 === "ul")) || (data3 === "ol")) || (data3 === "li")) || (data3 === "lic"))) {
                        validate12.errors = [{ instancePath: instancePath + "/type", schemaPath: "#/properties/type/enum", keyword: "enum", params: { allowedValues: schema29.properties.type.enum }, message: "must be equal to one of the allowed values" }];
                        return false;
                    }
                }
            }
        }
        else {
            validate12.errors = [{ instancePath: instancePath, schemaPath: "#/type", keyword: "type", params: { type: "object" }, message: "must be object" }];
            return false;
        }
    }
    validate12.errors = vErrors;
    return errors === 0;
}
function validate11(data, _a) {
    var _b = _a === void 0 ? {} : _a, _c = _b.instancePath, instancePath = _c === void 0 ? "" : _c, parentData = _b.parentData, parentDataProperty = _b.parentDataProperty, _d = _b.rootData, rootData = _d === void 0 ? data : _d;
    var vErrors = null;
    var errors = 0;
    var _errs0 = errors;
    var valid0 = false;
    var _errs1 = errors;
    if (!(validate12(data, { instancePath: instancePath, parentData: parentData, parentDataProperty: parentDataProperty, rootData: rootData }))) {
        vErrors = vErrors === null ? validate12.errors : vErrors.concat(validate12.errors);
        errors = vErrors.length;
    }
    var _valid0 = _errs1 === errors;
    valid0 = valid0 || _valid0;
    if (!valid0) {
        var _errs2 = errors;
        var _errs3 = errors;
        if (errors === _errs3) {
            if (data && typeof data == "object" && !Array.isArray(data)) {
                var missing0 = void 0;
                if ((data.text === undefined) && (missing0 = "text")) {
                    var err0 = { instancePath: instancePath, schemaPath: "#/definitions/CustomText/required", keyword: "required", params: { missingProperty: missing0 }, message: "must have required property '" + missing0 + "'" };
                    if (vErrors === null) {
                        vErrors = [err0];
                    }
                    else {
                        vErrors.push(err0);
                    }
                    errors++;
                }
                else {
                    var _errs5 = errors;
                    for (var key0 in data) {
                        if (!((((key0 === "text") || (key0 === "bold")) || (key0 === "italic")) || (key0 === "strikethrough"))) {
                            var err1 = { instancePath: instancePath, schemaPath: "#/definitions/CustomText/additionalProperties", keyword: "additionalProperties", params: { additionalProperty: key0 }, message: "must NOT have additional properties" };
                            if (vErrors === null) {
                                vErrors = [err1];
                            }
                            else {
                                vErrors.push(err1);
                            }
                            errors++;
                            break;
                        }
                    }
                    if (_errs5 === errors) {
                        if (data.text !== undefined) {
                            var _errs6 = errors;
                            if (typeof data.text !== "string") {
                                var err2 = { instancePath: instancePath + "/text", schemaPath: "#/definitions/CustomText/properties/text/type", keyword: "type", params: { type: "string" }, message: "must be string" };
                                if (vErrors === null) {
                                    vErrors = [err2];
                                }
                                else {
                                    vErrors.push(err2);
                                }
                                errors++;
                            }
                            var valid2 = _errs6 === errors;
                        }
                        else {
                            var valid2 = true;
                        }
                        if (valid2) {
                            if (data.bold !== undefined) {
                                var _errs8 = errors;
                                if (typeof data.bold !== "boolean") {
                                    var err3 = { instancePath: instancePath + "/bold", schemaPath: "#/definitions/CustomText/properties/bold/type", keyword: "type", params: { type: "boolean" }, message: "must be boolean" };
                                    if (vErrors === null) {
                                        vErrors = [err3];
                                    }
                                    else {
                                        vErrors.push(err3);
                                    }
                                    errors++;
                                }
                                var valid2 = _errs8 === errors;
                            }
                            else {
                                var valid2 = true;
                            }
                            if (valid2) {
                                if (data.italic !== undefined) {
                                    var _errs10 = errors;
                                    if (typeof data.italic !== "boolean") {
                                        var err4 = { instancePath: instancePath + "/italic", schemaPath: "#/definitions/CustomText/properties/italic/type", keyword: "type", params: { type: "boolean" }, message: "must be boolean" };
                                        if (vErrors === null) {
                                            vErrors = [err4];
                                        }
                                        else {
                                            vErrors.push(err4);
                                        }
                                        errors++;
                                    }
                                    var valid2 = _errs10 === errors;
                                }
                                else {
                                    var valid2 = true;
                                }
                                if (valid2) {
                                    if (data.strikethrough !== undefined) {
                                        var _errs12 = errors;
                                        if (typeof data.strikethrough !== "boolean") {
                                            var err5 = { instancePath: instancePath + "/strikethrough", schemaPath: "#/definitions/CustomText/properties/strikethrough/type", keyword: "type", params: { type: "boolean" }, message: "must be boolean" };
                                            if (vErrors === null) {
                                                vErrors = [err5];
                                            }
                                            else {
                                                vErrors.push(err5);
                                            }
                                            errors++;
                                        }
                                        var valid2 = _errs12 === errors;
                                    }
                                    else {
                                        var valid2 = true;
                                    }
                                }
                            }
                        }
                    }
                }
            }
            else {
                var err6 = { instancePath: instancePath, schemaPath: "#/definitions/CustomText/type", keyword: "type", params: { type: "object" }, message: "must be object" };
                if (vErrors === null) {
                    vErrors = [err6];
                }
                else {
                    vErrors.push(err6);
                }
                errors++;
            }
        }
        var _valid0 = _errs2 === errors;
        valid0 = valid0 || _valid0;
    }
    if (!valid0) {
        var err7 = { instancePath: instancePath, schemaPath: "#/anyOf", keyword: "anyOf", params: {}, message: "must match a schema in anyOf" };
        if (vErrors === null) {
            vErrors = [err7];
        }
        else {
            vErrors.push(err7);
        }
        errors++;
        validate11.errors = vErrors;
        return false;
    }
    else {
        errors = _errs0;
        if (vErrors !== null) {
            if (_errs0) {
                vErrors.length = _errs0;
            }
            else {
                vErrors = null;
            }
        }
    }
    validate11.errors = vErrors;
    return errors === 0;
}
function validate10(data, _a) {
    var _b = _a === void 0 ? {} : _a, _c = _b.instancePath, instancePath = _c === void 0 ? "" : _c, parentData = _b.parentData, parentDataProperty = _b.parentDataProperty, _d = _b.rootData, rootData = _d === void 0 ? data : _d;
    var vErrors = null;
    var errors = 0;
    if (!(validate11(data, { instancePath: instancePath, parentData: parentData, parentDataProperty: parentDataProperty, rootData: rootData }))) {
        vErrors = vErrors === null ? validate11.errors : vErrors.concat(validate11.errors);
        errors = vErrors.length;
    }
    validate10.errors = vErrors;
    return errors === 0;
}
export default validate10;
