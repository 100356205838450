var _a, _URLManager_instance, _URLManager_urls;
import { __classPrivateFieldGet, __classPrivateFieldSet } from "tslib";
class URLManager {
    constructor() {
        _URLManager_urls.set(this, {});
    }
    static getInstance() {
        if (!__classPrivateFieldGet(_a, _a, "f", _URLManager_instance)) {
            __classPrivateFieldSet(_a, _a, new _a(), "f", _URLManager_instance);
        }
        return __classPrivateFieldGet(_a, _a, "f", _URLManager_instance);
    }
    getOrCreateURL(key, content, type = 'text/plain') {
        if (__classPrivateFieldGet(this, _URLManager_urls, "f")[key]) {
            return __classPrivateFieldGet(this, _URLManager_urls, "f")[key];
        }
        const url = URL.createObjectURL(new Blob([content], { type }));
        __classPrivateFieldGet(this, _URLManager_urls, "f")[key] = url;
        return url;
    }
    revokeAll() {
        Object.values(__classPrivateFieldGet(this, _URLManager_urls, "f")).forEach((url) => {
            URL.revokeObjectURL(url);
        });
        __classPrivateFieldSet(this, _URLManager_urls, {}, "f");
    }
    revokeURL(key) {
        if (__classPrivateFieldGet(this, _URLManager_urls, "f")[key]) {
            URL.revokeObjectURL(__classPrivateFieldGet(this, _URLManager_urls, "f")[key]);
            delete __classPrivateFieldGet(this, _URLManager_urls, "f")[key];
        }
    }
}
_a = URLManager, _URLManager_urls = new WeakMap();
_URLManager_instance = { value: void 0 };
export const urlManager = URLManager.getInstance();
