import { jsx as _jsx } from "react/jsx-runtime";
import { observer } from 'mobx-react';
import { useAction, useGetUserTest, useSlideUIContext, } from '@marvelapp/ballpark-application';
import { Box } from '@marvelapp/ui';
import { ScreenerUtils, StepClass, StepUtils, } from '@marvelapp/user-test-creator';
import ScreenerSelect from '../../components/Screeners';
import { MultipleChoiceContent } from '../MultipleChoice/MultipleChoiceContent';
import { OneColumnSlide } from '../common/OneColumnSlide';
const ScreenerEdit = observer(function ScreenerEdit({ onMainCTAClick, }) {
    const { step } = useSlideUIContext();
    const userTest = useGetUserTest();
    const onSetChoiceValue = useAction((value, uuid) => {
        if (!StepUtils.isOfType(step, StepClass.MultipleChoice))
            return null;
        ScreenerUtils.setChoiceValue({ step, mutable: userTest, uuid, value });
    }, [step, userTest]);
    const confirmDelete = useAction((uuid, closeModal) => {
        if (!StepUtils.isOfType(step, StepClass.MultipleChoice))
            return null;
        ScreenerUtils.removeChoice({ step, uuid });
        closeModal();
    }, [step]);
    return (_jsx(OneColumnSlide, { children: _jsx(MultipleChoiceContent, { onSetChoiceValue: onSetChoiceValue, onMainCTAClick: onMainCTAClick, renderItem: (uuid, choiceValue) => (
            // prevent the events from the Screener Select bubbling up and triggering dnd-kit listeners
            _jsx(Box, { ml: "s", flex: 1, onKeyDown: (e) => e.stopPropagation(), children: _jsx(ScreenerSelect, { isDisabled: !choiceValue || choiceValue.length === 0, choiceUUID: uuid }) })), renderOtherScreenerSelect: (uuid) => (_jsx(Box, { ml: "s", flex: 1, children: _jsx(ScreenerSelect, { isDisabled: false, choiceUUID: uuid }) })), confirmDelete: confirmDelete, withRequiredBadge: false, withSlideCount: false, withVideoGuide: false }) }));
});
export default ScreenerEdit;
