import uuidv4 from 'uuid/v4';
import { isChoiceStep } from './stepUtils';
export function addChoice(mutable, emptyChoiceDefinition) {
    const choiceUUID = uuidv4();
    mutable.choices.push(choiceUUID);
    mutable.choiceDefinitions[choiceUUID] = emptyChoiceDefinition;
    return choiceUUID;
}
export function hasAChoiceOption(mutable) {
    // some choices on multiple choice step might be null if the user
    // has not provided a value for them in the builder
    return Object.values(mutable.choiceDefinitions).some((value) => value !== null);
}
export function removeChoice(mutable, uuid) {
    delete mutable.choiceDefinitions[uuid];
    const choiceToRemoveIndex = mutable.choices.indexOf(uuid);
    mutable.choices.splice(choiceToRemoveIndex, 1);
}
export function reorderChoices(mutable, oldIndex, newIndex) {
    const [removed] = mutable.choices.splice(oldIndex, 1);
    mutable.choices.splice(newIndex, 0, removed);
}
export function getChoices(mutable) {
    return mutable.choices;
}
export function getChoicesByStepUUID(userTest, stepUUID) {
    const step = userTest.stepDefinitions[stepUUID];
    if (!step || !isChoiceStep(step))
        return [];
    return step.choices;
}
export function getSelectionRange(mutable) {
    return mutable.selectionRange || [null, null];
}
export function setSelectionRange(mutable, range) {
    mutable.selectionRange = range;
}
export function setMultipleSelectionMode(mutable, value) {
    mutable.multipleSelectionMode = value;
}
export function setMultipleSelection(mutable, value, range = [null, null]) {
    setMultipleSelectionMode(mutable, value);
    // null represents a lack of value until its set in the builder
    // this is needed to render the placeholders in the inputs
    setSelectionRange(mutable, range);
}
export function removeMultipleSelection(mutable) {
    delete mutable.multipleSelectionMode;
    setSelectionRange(mutable, [1, 1]);
}
export function getMultipleSelectionMode(mutable) {
    return mutable.multipleSelectionMode || null;
}
export function isMultipleSelectionEnabled(mutable) {
    const multipleSelectionMode = getMultipleSelectionMode(mutable);
    return multipleSelectionMode !== null;
}
export function getIsRandomizeSet(mutable) {
    return !!mutable.randomize;
}
export function setRandomizeSetting(mutable, randomize) {
    mutable.randomize = randomize;
}
