import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { gql, useMutation } from '@apollo/client';
import { observer } from 'mobx-react';
import { useState } from 'react';
import AutosizeInput from 'react-18-input-autosize';
import { Heading } from '@marvelapp/ballpark-components';
import { PENCIL_ICON } from '@marvelapp/ballpark-theme';
import styled from '@marvelapp/styled';
import { Box, ContentLoader, Icon, Tooltip, inputStyles } from '@marvelapp/ui';
import { showErrorNotification } from '@marvelapp/ui-internal';
const UPDATE_USER_TEST_NAME = gql `
  mutation updateUserTestName($pk: Int!, $name: String) {
    updateUserTest(input: { pk: $pk, name: $name }) {
      ok
      error {
        code
        message
      }
      userTest {
        pk
        prototype {
          pk
          name
        }
      }
    }
  }
`;
export const ProjectNameEditor = observer(function ProjectNameEditor({ canEdit, userTestPk, loading, projectPk, projectName, }) {
    const toastId = 'updateProject';
    const content = 'Your project couldn’t be updated, please try again.';
    const [inputValue, setInputValue] = useState(projectName);
    function showError() {
        showErrorNotification({
            toastId,
            content,
            options: { autoClose: 5000 },
        });
    }
    const [updateUserTestName] = useMutation(UPDATE_USER_TEST_NAME, {
        onCompleted: ({ updateUserTest }) => {
            if (updateUserTest.error) {
                showError();
            }
        },
        onError: showError,
    });
    if (loading) {
        return (_jsx(ContentLoader, { borderRadius: 6, "data-testid": "project-name-loader", height: 12, width: 200 }));
    }
    if (canEdit) {
        return (_jsxs(InputWrap, { children: [_jsx(Input, { "data-testid": "project-name", name: "form-field-name", value: inputValue, onChange: (event) => setInputValue(event.target.value), onBlur: () => {
                        updateUserTestName({
                            variables: { pk: userTestPk, name: inputValue },
                            optimisticResponse: {
                                updateUserTest: {
                                    __typename: 'UpdateUserTest',
                                    ok: true,
                                    error: null,
                                    userTest: {
                                        __typename: 'UserTestNode',
                                        pk: userTestPk,
                                        prototype: {
                                            __typename: 'PrototypeNode',
                                            pk: projectPk,
                                            name: inputValue,
                                        },
                                    },
                                },
                            },
                        });
                    } }), _jsx(PencilWrap, { children: _jsx(Tooltip, { content: "Edit name", "data-testid": "editable-text-edit-tooltip", position: "bottom", children: _jsx(Icon, { color: "silver", "data-testid": "editable-text-edit-icon", display: "block", height: 20, hoverColor: "slate", paths: PENCIL_ICON, width: 20, viewBox: [0, 0, 24, 24] }) }) })] }));
    }
    return _jsx(Heading, { size: "m", children: projectName });
});
const PencilWrap = styled.div `
  opacity: 0;
  display: flex;
  align-items: center;
  transition-duration: 150ms;
`;
const InputWrap = styled(Box) `
  display: flex;
  flex-direction: row;
  &:hover {
    ${PencilWrap} {
      opacity: 1;
    }
  }
`;
const Input = styled(AutosizeInput).attrs({
    fontFamily: 0,
    border: '3px solid',
    borderColor: 'transparent',
    hoverBorderColor: 'slate6',
    focusBorderColor: 'blue6',
    borderRadius: 'm',
    bg: 'background',
    fontSize: 'm',
    fontWeight: 'semibold',
    textOverflow: 'ellipsis',
    color: 'licorice',
}) `
  input {
    // This will have to be updated when we add more tabs
    max-width: calc(50vw - 230px) !important;
    ${inputStyles};
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    max-width: 100%;
    transition: none;

    padding-left: 4px;
    padding-right: 4px;

    padding-top: 6px;
    padding-bottom: 6px;

    min-height: 0;
    letter-spacing: -0.2px;
    line-height: 20px;

    &::placeholder {
      color: ${(props) => props.theme.colors.slate9};
      opacity: 1;
      letter-spacing: -0.2px;
    }
  }
`;
