import { StepClass } from '../common';
import { QuestionStepType } from '../userTest/types';
import { AnswerType } from './types';
export function create(stepUUID, stepRevision) {
    return {
        className: StepClass.Question,
        stepUUID,
        stepRevision,
        answers: [],
    };
}
export function getAnswer(response, index = 0) {
    if (!response.answers[index]) {
        return null;
    }
    return response.answers[index].value;
}
export function getAnswers(response) {
    return response.answers;
}
export function setAnswer(response, index = 0, value, type = QuestionStepType.Text) {
    if (type === QuestionStepType.Text && typeof value === 'string') {
        // eslint-disable-next-line no-param-reassign
        response.answers[index] = {
            type: AnswerType.Text,
            value,
        };
    }
    if (type === QuestionStepType.Email && typeof value === 'string') {
        // eslint-disable-next-line no-param-reassign
        response.answers[index] = {
            type: AnswerType.Email,
            value,
        };
    }
    if (type === QuestionStepType.QuestionDate && typeof value === 'number') {
        // eslint-disable-next-line no-param-reassign
        response.answers[index] = {
            type: AnswerType.DateAnswer,
            value,
        };
    }
    if (type === QuestionStepType.Numerical && typeof value === 'number') {
        if (Number.isNaN(value)) {
            response.answers.splice(index, 1);
        }
        else {
            // eslint-disable-next-line no-param-reassign
            response.answers[index] = {
                type: AnswerType.Number,
                value,
            };
        }
    }
}
