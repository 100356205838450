import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { observer } from 'mobx-react';
import { NavLink, useRouteMatch } from 'react-router-dom';
import { BtwButton, BtwLink, BtwText, Stack, } from '@marvelapp/ballpark-components';
import { InfoCircleIcon, TrashCanIcon } from '@marvelapp/ballpark-icons';
import { StudyTypeEnum } from '../../../__generated__/queryTypes';
import { useRecruitmentState } from '../RecruitmentContext';
export const DetailedTargetingFooter = observer(function DetailedTargetingFooter() {
    const match = useRouteMatch();
    const builderUrl = `/project/${match.params.id}`;
    const { studyType } = useRecruitmentState();
    const isStandardStudy = studyType === StudyTypeEnum.SURVEY;
    return (_jsxs(Stack, { align: { initial: 'stretch', sm: 'center' }, className: `min-h-14 border-t border-gray-600/10 py-4 pl-6 pr-4 sm:py-0 ${isStandardStudy ? 'justify-end' : 'justify-between'}`, direction: { initial: 'colReverse', sm: 'row' }, width: "full", gap: "4", children: [studyType !== StudyTypeEnum.SURVEY && (_jsxs(Stack, { direction: "row", gap: "3", children: [_jsx(InfoCircleIcon, { className: "size-5 shrink-0" }), _jsxs(BtwText, { variant: "secondary", size: "13", children: ["You can use screener steps in the", ' ', _jsx(BtwLink, { asChild: true, variant: "muted", children: _jsx(NavLink, { to: builderUrl, children: "Build" }) }), ' ', "section for more detailed targeting."] })] })), _jsx(ClearAllButton, {})] }));
});
const ClearAllButton = observer(function ClearAllButton() {
    const { clearAllDetailedFilters } = useRecruitmentState();
    return (_jsx(BtwButton, { "data-testid": "clear-all-attributes-cta", leadingIcon: _jsx(TrashCanIcon, {}), onClick: clearAllDetailedFilters, variant: "secondary", size: "sm", children: "Clear all attributes" }));
});
