import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { observable, runInAction } from 'mobx';
import { observer, useLocalObservable } from 'mobx-react';
import { useCallback, useEffect, useLayoutEffect, useRef, useState, } from 'react';
import * as React from 'react';
import Scrollbars from 'react-custom-scrollbars';
import { SlideUIContextProvider, hasStep, useAction, useGetUserTest, } from '@marvelapp/ballpark-application';
import { ScreenerIcon, StepIcon, getIconType, } from '@marvelapp/ballpark-components';
import { getStepName } from '@marvelapp/ballpark-copy';
import theme from '@marvelapp/ballpark-theme';
import styled from '@marvelapp/styled';
import { Box } from '@marvelapp/ui';
import { FixedStep, ScreenerUtils, getOrderedListOfAllStepUUIDs, getStepByUUID, getStepIndex, isExitStep, isWelcomeStep, } from '@marvelapp/user-test-creator';
import { SlideEdit } from '../../slides/common/SlideEdit';
import { ScreenerStep } from '../../types';
import stripUUIDPrefix from '../../utils/stripUUIDPrefix';
import { ScrollContainerContext } from './ScrollContainerContext';
import { useUIContext } from './uiContext';
const MAX_AUTO_SCROLL_START_DELAY = 300;
const SCROLLBAR_ID = 'slide-list-scrollbar';
const scrollbarStyle = {
    width: '100%',
    height: `calc(100vh - ${theme.sizes.barHeight}px)`,
};
const getSlideState = ({ step, fixedStepType }) => ({
    fixedStepType,
    step,
});
export const SlidesList = observer(() => {
    const userTest = useGetUserTest();
    const centredSlideRef = useRef('');
    const isActiveSlideSetViaScroll = useRef(false);
    const [scrollContainer, setScrollContainer] = useState();
    const UIContext = useUIContext();
    const { activeSlideUUID, setActiveSlideUUID, scrollSpyController } = UIContext;
    // We need the index here too as the active slide can be re-ordered in which case we might need to scroll
    // it into view.
    const activeStepIndex = getStepIndex(userTest, activeSlideUUID);
    useEffect(() => {
        if (!scrollSpyController)
            return;
        if (isActiveSlideSetViaScroll.current) {
            isActiveSlideSetViaScroll.current = false;
            return;
        }
        const elementId = stripUUIDPrefix({ uuid: activeSlideUUID });
        if (elementId) {
            const element = document.getElementById(elementId);
            scrollSpyController.disable();
            element === null || element === void 0 ? void 0 : element.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
    }, [activeSlideUUID, activeStepIndex, scrollSpyController]);
    useLayoutEffect(() => {
        const scrollbar = document.getElementById(SCROLLBAR_ID);
        if (!scrollbar)
            return;
        const container = scrollbar.children[0];
        if (!container)
            return;
        setScrollContainer(container);
    }, []);
    const onIntersectionChange = useCallback((entries) => {
        if (!scrollSpyController)
            return;
        entries.forEach((entry) => {
            if (entry.target instanceof HTMLElement &&
                entry.isIntersecting &&
                entry.target.dataset.uuid) {
                centredSlideRef.current = entry.target.dataset.uuid;
                if (scrollSpyController.isDisabled) {
                    return;
                }
                isActiveSlideSetViaScroll.current = true;
                setActiveSlideUUID(entry.target.dataset.uuid);
            }
        });
    }, [scrollSpyController, setActiveSlideUUID]);
    const intersectionObserver = useRef(new IntersectionObserver(onIntersectionChange, {
        // Viewport center line
        rootMargin: '-50% 0px -50% 0px',
    }));
    const screenerUUIDs = ScreenerUtils.getAllStepUUIDs(userTest);
    const stepUUIDs = getOrderedListOfAllStepUUIDs(userTest);
    const onScrollStart = useCallback(() => {
        if (!scrollSpyController)
            return;
        if (scrollSpyController.disabledSince) {
            if (scrollSpyController.disabledSince > MAX_AUTO_SCROLL_START_DELAY) {
                scrollSpyController.enable();
            }
        }
        else if (activeSlideUUID !== centredSlideRef.current) {
            setActiveSlideUUID(centredSlideRef.current);
        }
    }, [activeSlideUUID, scrollSpyController, setActiveSlideUUID]);
    const onScrollStop = useCallback(() => {
        if (UIContext.slideToFocusOnScrollStopUUID === activeSlideUUID) {
            UIContext.focusSlideUUID = activeSlideUUID;
        }
        UIContext.slideToFocusOnScrollStopUUID = '';
        if (!scrollSpyController)
            return;
        scrollSpyController.enable();
    }, [UIContext, activeSlideUUID, scrollSpyController]);
    return (_jsx(Scrollbars, { autoHide: true, id: SCROLLBAR_ID, "data-testid": "scroll-container", style: scrollbarStyle, onScrollStart: onScrollStart, onScrollStop: onScrollStop, children: _jsx(ScrollContainerContext.Provider, { value: scrollContainer, children: _jsxs(Body, { children: [screenerUUIDs.map((uuid) => (_jsx(StepSlide, { intersectionObserver: intersectionObserver, uuid: uuid, getStepName: (step, fixedStepType) => getStepName(step, fixedStepType), getStepIcon: () => (_jsx(ScreenerIcon, { size: "m", type: ScreenerUtils.getStepName(userTest, uuid) })) }, uuid))), stepUUIDs.map((uuid) => (_jsx(StepSlide, { intersectionObserver: intersectionObserver, uuid: uuid, getStepName: (step, fixedStepType) => getStepName(step, fixedStepType), getStepIcon: (step, fixedStepType) => (_jsx(StepIcon, { size: "m", type: getIconType(step, fixedStepType) })) }, uuid))), _jsx(LastSlideSpace, {})] }) }) }));
});
const StepSlide = observer(({ uuid, intersectionObserver, getStepName, getStepIcon }) => {
    const userTest = useGetUserTest();
    const { activeSlideUUID, setActiveSlideUUID } = useUIContext();
    const slideTestId = useGetSlideType(uuid);
    const isWelcomeSlide = isWelcomeStep(userTest, uuid);
    const isFixedStep = isWelcomeSlide || isExitStep(userTest, uuid);
    const fixedStepType = isWelcomeSlide ? FixedStep.Welcome : FixedStep.Exit;
    const onMainCTAClick = useAction(() => {
        if (!isFixedStep || fixedStepType !== FixedStep.Exit) {
            const allStepsUUIDs = getOrderedListOfAllStepUUIDs(userTest);
            const currentSlideIndex = getStepIndex(userTest, uuid);
            setActiveSlideUUID(allStepsUUIDs[currentSlideIndex + 1]);
        }
    }, [isFixedStep, fixedStepType, userTest, uuid, setActiveSlideUUID]);
    return (_jsx(SlideContainer, { "data-testid": `builder-${slideTestId}-slide`, fixedStepType: isFixedStep ? fixedStepType : undefined, intersectionObserver: intersectionObserver.current, isActive: activeSlideUUID === uuid, uuid: uuid, children: _jsx(SlideEdit, { onMainCTAClick: onMainCTAClick, getStepName: getStepName, getStepIcon: getStepIcon }) }, uuid));
});
function useGetSlideType(stepUUID) {
    const userTest = useGetUserTest();
    const isWelcomeSlide = isWelcomeStep(userTest, stepUUID);
    const isExitSlide = isExitStep(userTest, stepUUID);
    const isScreenerStep = ScreenerUtils.isScreenerStep(userTest, stepUUID);
    if (isWelcomeSlide || isExitSlide) {
        return isWelcomeSlide ? FixedStep.Welcome : FixedStep.Exit;
    }
    if (isScreenerStep) {
        if (ScreenerUtils.isIntroStep(userTest, stepUUID)) {
            return ScreenerStep.Intro;
        }
        if (ScreenerUtils.isDeclineStep(userTest, stepUUID)) {
            return ScreenerStep.Decline;
        }
        return ScreenerStep.Screener;
    }
    const step = getStepByUUID(userTest, stepUUID);
    if (!step)
        return '';
    return `${step.className}${'type' in step ? `-${step.type}` : ''}`;
}
const SlideContainer = observer(function SlideContainer({ uuid, fixedStepType, intersectionObserver, isActive, children, 'data-testid': testId, }) {
    const [ref, setRef] = useState(null);
    const userTest = useGetUserTest();
    const step = getStepByUUID(userTest, uuid);
    const cache = useLocalObservable(() => {
        return getSlideState({
            fixedStepType,
            step,
        });
    }, 
    // We don't need to convert step to an observable as its already observable
    { step: observable.ref });
    // Using layout effect otherwise you can sometimes briefly see the page render
    // with the stale value and, hence, in a broken state. The step changes when
    // you tab away and back and the builder doc is reconnected to RUS (TODO why
    // does step change in this case?)
    useLayoutEffect(() => runInAction(() => {
        cache.step = step;
    }), [cache, step]);
    useEffect(() => {
        if (ref && intersectionObserver) {
            intersectionObserver.observe(ref);
            return () => intersectionObserver.unobserve(ref);
        }
    }, [intersectionObserver, ref]);
    if (!hasStep(cache))
        return null;
    return (_jsx(SlideUIContextProvider, { slideProps: cache, children: _jsx(SlideContainerElement, { "data-testid": testId, id: stripUUIDPrefix({ uuid }), isActive: isActive, ref: setRef, "data-uuid": uuid, children: children }) }));
});
const SPACE = ['m', 'm', 'm', 'm', 'm', 'm', 'l'];
const Body = styled(Box).attrs({
    bg: 'slate2',
    paddingTop: SPACE,
    paddingX: SPACE,
}) `
  min-height: 100vh;
`;
const LastSlideSpace = styled(Box).attrs({
    paddingTop: 'xl',
}) ``;
const SlideContainerElement = styled(Box).attrs((props) => ({
    marginTop: SPACE,
    borderRadius: 'xl',
    border: '1px solid',
    borderColor: props.isActive ? 'blue8' : 'slate6',
    boxShadow: props.isActive ? 'typeCard.active' : 'button.secondary',
})) `
  position: relative;

  &:first-child {
    margin-top: 0;
  }
`;
SlidesList.displayName = 'SlidesList';
StepSlide.displayName = 'StepSlide';
