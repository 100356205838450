export var PlayerStatus;
(function (PlayerStatus) {
    PlayerStatus["Loading"] = "loading";
    PlayerStatus["Ready"] = "ready";
    PlayerStatus["ConnectingToVideoServer"] = "connectingToVideoServer";
    // user controlled
    PlayerStatus["Recording"] = "recording";
    PlayerStatus["Stopped"] = "stopped";
})(PlayerStatus || (PlayerStatus = {}));
// When we resuse the player between steps there is some setup that needs to be
// done before we can let the use start the task. This enum tracks the status of
// that setup.
export var CachedPlayerSetupStatus;
(function (CachedPlayerSetupStatus) {
    CachedPlayerSetupStatus["Loading"] = "loading";
    CachedPlayerSetupStatus["Ready"] = "ready";
    CachedPlayerSetupStatus["NotCached"] = "notCached";
})(CachedPlayerSetupStatus || (CachedPlayerSetupStatus = {}));
export var FigmaStatus;
(function (FigmaStatus) {
    FigmaStatus[FigmaStatus["Pending"] = 0] = "Pending";
    FigmaStatus[FigmaStatus["IframeSrcLoaded"] = 1] = "IframeSrcLoaded";
    FigmaStatus[FigmaStatus["PrototypeLoaded"] = 2] = "PrototypeLoaded";
})(FigmaStatus || (FigmaStatus = {}));
