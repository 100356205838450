import { StepClass } from '../userTest/types';
import { AnswerType, } from './types';
export function create(stepUUID, stepRevision) {
    return {
        className: StepClass.FiveSecondTest,
        stepUUID,
        stepRevision,
        answer: {
            type: AnswerType.SingleChoice,
            value: null,
        },
    };
}
export function getAnswer(response) {
    return response.answer.value;
}
export function setAnswer(response, value) {
    // eslint-disable-next-line no-param-reassign
    response.answer = {
        type: AnswerType.SingleChoice,
        value,
    };
}
