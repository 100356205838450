import { gql } from '@apollo/client';
const AnswerPlainTextFragment = gql `
  fragment AnswerPlainTextFragment on AnswerPlainText {
    __typename
    text
  }
`;
const AnswerEmailFragment = gql `
  fragment AnswerEmailFragment on AnswerEmail {
    __typename
    email
  }
`;
const AnswerBooleanFragment = gql `
  fragment AnswerBooleanFragment on AnswerBoolean {
    __typename
    response
  }
`;
const AnswerDateTimeFragment = gql `
  fragment AnswerDateTimeFragment on AnswerDateTime {
    __typename
    date
  }
`;
const AnswerRatingFragment = gql `
  fragment AnswerRatingFragment on AnswerRating {
    __typename
    rating
  }
`;
const AnswerMultiChoiceFragment = gql `
  fragment AnswerMultiChoiceFragment on AnswerMultiPreference {
    __typename
    rank
    choice
    otherValue
  }
`;
const AnswerPreferenceFragment = gql `
  fragment AnswerPreferenceFragment on AnswerMultiPreference {
    __typename
    rank
    choice
  }
`;
const AnswerTaglineCopyFragment = gql `
  fragment answerPreference on AnswerMultiPreference {
    __typename
    rank
    choice
  }
`;
const AnswerIntegerFragment = gql `
  fragment AnswerIntegerFragment on AnswerInteger {
    __typename
    value
  }
`;
/*
TODO: the following fields are repeated on every fragment.
Using string templates breaks the type generation when calling apollo codegen:generate.

codegen:generate has been deprecated in favour of https://www.graphql-code-generator.com/
Hopefully the new tool will support template strings.
*/
/*
const stepCommonFields = `
__typename
stepUuid
stepRevision
stepType
stepSkipped
duration
`;
*/
export const TestSessionRecordingFragment = gql `
  fragment TestSessionRecordingFragment on TestSessionRecording {
    __typename
    pk
    videoUrl
    waveform {
      url
    }
    subtitles {
      url
    }
    transcript {
      url
    }
    uploadStatus
    failedStatus
    failedType
    permissions {
      __typename
      webcam
      microphone
      screen
    }
    statusLog
    startTime
    missingDataWarning
    duration
    width
    height
    isAudio
  }
`;
export const StepInstructionFragment = gql `
  fragment StepInstructionFragment on StepInstructionNode {
    __typename
    stepUuid
    stepRevision
    stepType
    stepSkipped
    duration
  }
`;
export const StepQuestionFragment = gql `
  fragment StepQuestionFragment on StepQuestionNode {
    __typename
    stepUuid
    stepRevision
    stepType
    stepSkipped
    duration

    answers {
      ...AnswerPlainTextFragment
      ...AnswerEmailFragment
      ...AnswerDateTimeFragment
      ...AnswerIntegerFragment
    }
  }
  ${AnswerPlainTextFragment}
  ${AnswerEmailFragment}
  ${AnswerDateTimeFragment}
  ${AnswerIntegerFragment}
`;
export const StepBannerAdTestFragment = gql `
  fragment StepBannerAdTestFragment on StepBannerAdTestNode {
    __typename
    stepUuid
    stepRevision
    stepType
    stepSkipped
    duration
  }
`;
export const StepFirstClickFragment = gql `
  fragment StepFirstClickFragment on StepFirstClickNode {
    __typename
    stepUuid
    stepRevision
    stepType
    stepSkipped
    duration
  }
`;
export const StepFiveSecondTestFragment = gql `
  fragment StepFiveSecondTestFragment on StepFiveSecondTestNode {
    __typename
    stepUuid
    stepRevision
    stepType
    stepSkipped
    duration
    answers {
      ...AnswerBooleanFragment
    }
  }
`;
export const StepLegalQuestionFragment = gql `
  fragment StepLegalQuestionFragment on StepLegalQuestionNode {
    __typename
    stepUuid
    stepRevision
    stepType
    stepSkipped
    duration
  }
`;
export const StepMultipleChoiceFragment = gql `
  fragment StepMultipleChoiceFragment on StepMultipleChoiceNode {
    __typename
    stepUuid
    stepRevision
    stepType
    stepSkipped
    duration
    answers {
      ...AnswerMultiChoiceFragment
    }
  }
  ${AnswerMultiChoiceFragment}
`;
export const StepPreferenceTestFragment = gql `
  fragment StepPreferenceTestFragment on StepPreferenceTestNode {
    __typename
    stepUuid
    stepRevision
    stepType
    stepSkipped
    duration
    answers {
      ...AnswerPreferenceFragment
    }
  }
  ${AnswerPreferenceFragment}
`;
export const StepPrototypeTaskFragment = gql `
  fragment StepPrototypeTaskFragment on StepPrototypeTaskNode {
    __typename
    stepUuid
    stepRevision
    stepType
    stepSkipped
    duration
    stats {
      goalHit
      misclickRate
      durationSeconds
      clicksPerResult
    }
  }
`;
export const StepPrototypeTaskFragmentWithRecordings = gql `
  fragment StepPrototypeTaskFragment on StepPrototypeTaskNode {
    __typename
    stepUuid
    stepRevision
    stepType
    stepSkipped
    duration
    stats {
      goalHit
      misclickRate
      durationSeconds
      clicksPerResult
    }
    userRecording {
      ...TestSessionRecordingFragment
    }
    screenRecording {
      ...TestSessionRecordingFragment
    }
    downloadRecording {
      ...TestSessionRecordingFragment
    }
  }
  ${TestSessionRecordingFragment}
`;
export const StepPrototypeTaskFragmentWithEvents = gql `
  fragment StepPrototypeTaskFragmentWithEvents on StepPrototypeTaskNode {
    pk
    __typename
    stepUuid
    stepRevision
    stepType
    stepSkipped
    duration
    rawEvents
    stats {
      goalHit
      misclickRate
      durationSeconds
    }
    screenRecording {
      ...TestSessionRecordingFragment
    }
    userRecording {
      ...TestSessionRecordingFragment
    }
    downloadRecording {
      ...TestSessionRecordingFragment
    }
  }
  ${TestSessionRecordingFragment}
`;
export const StepRatingScaleFragment = gql `
  fragment StepRatingScaleFragment on StepRatingScaleNode {
    __typename
    stepUuid
    stepRevision
    stepType
    stepSkipped
    duration
    answers {
      ...AnswerRatingFragment
    }
  }
  ${AnswerRatingFragment}
`;
export const StepTaglineCopyTestFragment = gql `
  fragment StepTaglineCopyTestFragment on StepTaglineCopyTestNode {
    __typename
    stepUuid
    stepRevision
    stepType
    stepSkipped
    duration
    answers {
      ...answerPreference
    }
  }
  ${AnswerTaglineCopyFragment}
`;
export const StepWebsiteTaskFragment = gql `
  fragment StepWebsiteTaskFragment on StepWebsiteTaskNode {
    __typename
    stepUuid
    stepRevision
    stepType
    stepSkipped
    duration
    userRecording {
      ...TestSessionRecordingFragment
    }
    screenRecording {
      ...TestSessionRecordingFragment
    }
    downloadRecording {
      ...TestSessionRecordingFragment
    }
  }
  ${TestSessionRecordingFragment}
`;
export const StepYesOrNoFragment = gql `
  fragment StepYesOrNoFragment on StepYesOrNoNode {
    __typename
    stepUuid
    stepRevision
    stepType
    stepSkipped
    duration
    answers {
      ...AnswerBooleanFragment
    }
  }
  ${AnswerBooleanFragment}
`;
// Note: there are no StepCardsortOpenNode or StepCardsortClosedNode types in
// the schema, the backend uses StepCardsortHybridNode for every card sort step 🤷‍♂️
export const StepCardSortNodeFragment = gql `
  fragment StepCardSortNodeFragment on StepCardsortHybridNode {
    __typename
    stepUuid
    stepRevision
    stepType
    stepSkipped
    duration
    cards {
      cardUuid
      categoryUuid
      customCategoryName
    }
  }
`;
