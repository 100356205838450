import { gql } from '@apollo/client';
export default gql `
  {
    user {
      pk
      company {
        pk
        prototypeProjectCount
        userTestProjectCount
        billing {
          subscriptionPk
          projectQuantityPurchased
        }
      }
    }
  }
`;
