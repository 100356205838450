import { __rest } from "tslib";
import { jsx as _jsx } from "react/jsx-runtime";
import { observer } from 'mobx-react';
import * as React from 'react';
import { StepClass, StepUtils } from '@marvelapp/user-test-creator';
import { getScreenerChoiceStatus, getScreenerChoiceTitle, } from '../../../utils/choiceStepUtils';
import { useStepContext } from '../../../utils/stepContext';
import { ChoiceBar } from '../ChoiceBar';
import { SummaryStep } from './SummaryStep';
export const SummaryMultipleChoice = observer(function SummaryMultipleChoice(_a) {
    var { active, secondary, showAllLink, sortedChoices, stats, isScreenerStep = false } = _a, rest = __rest(_a, ["active", "secondary", "showAllLink", "sortedChoices", "stats", "isScreenerStep"]);
    const { step } = useStepContext();
    if (!StepUtils.isOfType(step, StepClass.MultipleChoice))
        return null;
    return (_jsx(SummaryStep, Object.assign({ active: active, secondary: secondary, showAllLink: showAllLink, isScreenerStep: isScreenerStep }, rest, { children: stats &&
            sortedChoices.map((uuid) => {
                var _a;
                const answer = stats.find((stat) => (stat === null || stat === void 0 ? void 0 : stat.answerValue) === uuid);
                const title = isScreenerStep
                    ? getScreenerChoiceTitle(step, uuid)
                    : (_a = step.choiceDefinitions[uuid]) !== null && _a !== void 0 ? _a : '';
                const screenerStatus = getScreenerChoiceStatus(step, uuid);
                if (!title)
                    return null;
                return (_jsx(ChoiceBar, { participants: (answer === null || answer === void 0 ? void 0 : answer.answerCount) ? answer.answerCount : 0, percent: (answer === null || answer === void 0 ? void 0 : answer.percentageChosen) ? answer.percentageChosen : 0, title: title || '', screenerStatus: screenerStatus || '' }, uuid));
            }) })));
});
