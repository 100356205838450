import { jsx as _jsx } from "react/jsx-runtime";
import { observer } from 'mobx-react';
import * as React from 'react';
import { useIntl } from 'react-intl';
import { deviceDetection } from '@marvelapp/core';
import { TextEditor } from '@marvelapp/text-editor';
import { PrototypeStepType, StepUtils, getStepDescription, } from '@marvelapp/user-test-creator';
import constants from './constants';
export default observer(function InstructionText({ step, }) {
    const intl = useIntl();
    const type = (StepUtils.isStepWithSubType(step) && step.type) ||
        PrototypeStepType.Prototype;
    const text = getStepDescription(intl, step);
    let placeholder;
    if (type === PrototypeStepType.FirstClick) {
        placeholder = constants.DEFAULT_INTO_FIRST_CLICK;
    }
    else if (deviceDetection.isMobile) {
        placeholder = constants.DEFAULT_INTRO_MOBILE;
    }
    else {
        placeholder = constants.DEFAULT_INTRO;
    }
    return (_jsx(TextEditor, { disabled: true, size: "sm", id: "instruction-text", placeholder: intl.formatMessage(placeholder), testId: "instruction-text", value: text }));
});
