import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { isNil } from 'lodash-es';
import { observer } from 'mobx-react';
import { useState } from 'react';
import * as React from 'react';
import { useSlideUIContext } from '@marvelapp/ballpark-application';
import { Icon, Popover } from '@marvelapp/ballpark-components';
import { VIDEO_GUIDES_ICON } from '@marvelapp/ballpark-theme';
import { MediaUtils, StepUtils } from '@marvelapp/user-test-creator';
import RecordVideo from './RecordVideo';
import { VideoGuideStep } from './RecordVideo/VideoGuideStep';
import SlideControlsButton from './SlideControlsButton';
export default observer(function AddVideoControlGuides() {
    const [isLocked, setIsLocked] = useState(false);
    const [addVideo, setAddVideo] = useState(false);
    const { step } = useSlideUIContext();
    const lockPopover = () => setIsLocked(true);
    const unlockPopover = () => setIsLocked(false);
    return (_jsxs(_Fragment, { children: [_jsx(VideoGuideStep, {}), _jsxs(Popover.Root, { open: addVideo, onOpenChange: () => {
                    setAddVideo(!addVideo);
                }, children: [displayAddVideoGuideButton(step) && (_jsxs(SlideControlsButton, { as: Popover.Trigger, "data-testid": "record-guidance", width: "fit-content", mt: "xs", mb: "xs", children: [_jsx(Icon, { width: 12, height: 12, marginRight: "xs", paths: VIDEO_GUIDES_ICON, viewBox: "0 0 14 14" }), "Add video guide"] })), _jsx(Popover.Content, { align: "start", borderRadius: "l", side: "bottom", sideOffset: -90, 
                        // We have to disable the automatic popover close on click outside,
                        // because when recording video you can loose it by accident and a few
                        // people have complained about it.
                        onInteractOutside: (event) => {
                            if (isLocked) {
                                event.preventDefault();
                            }
                        }, children: _jsx(RecordVideo, { onAddToStep: () => {
                                setAddVideo(false);
                            }, lockPopover: lockPopover, unlockPopover: unlockPopover }) })] })] }));
});
function displayAddVideoGuideButton(step) {
    var _a, _b;
    return (isNil((_a = StepUtils.getInstruction(step)) === null || _a === void 0 ? void 0 : _a.status) ||
        ((_b = StepUtils.getInstruction(step)) === null || _b === void 0 ? void 0 : _b.status) ===
            MediaUtils.RecordingStatus.Streaming);
}
