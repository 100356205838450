import { debounce, noop } from 'lodash-es';
import { useCallback, useEffect, useMemo, useReducer } from 'react';
const SET_LATEST = 'SET_LATEST';
const SET_DEBOUNCED = 'SET_DEBOUNCED';
export function useDebouncedState(initialState, { delay = 200, onFlush = noop } = {}) {
    const initialInternalState = {
        latest: initialState,
        debounced: initialState,
    };
    const [state, dispatch] = useReducer((prevState, action) => {
        switch (action.type) {
            case SET_LATEST:
                return Object.assign(Object.assign({}, prevState), { latest: action.state });
            case SET_DEBOUNCED:
                return Object.assign(Object.assign({}, prevState), { debounced: action.state });
            default:
                throw new Error(`Unrecognised action ${action.type} 🤷🏻‍ how we got here`);
        }
    }, initialInternalState);
    const setDebouncedState = useMemo(() => debounce((nextState) => {
        dispatch({ type: SET_DEBOUNCED, state: nextState });
    }, delay), [delay]);
    const setState = useCallback((nextState) => {
        dispatch({ type: SET_LATEST, state: nextState });
        setDebouncedState(nextState);
    }, [setDebouncedState]);
    useEffect(() => {
        if (state.latest !== state.debounced) {
            onFlush === null || onFlush === void 0 ? void 0 : onFlush(state.latest);
        }
    }, [state.latest, onFlush, state.debounced]);
    return [state.debounced, setState, state.latest];
}
