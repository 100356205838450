import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { gql, useMutation } from '@apollo/client';
import { confetti } from '@tsparticles/confetti';
import { action } from 'mobx';
import { observer } from 'mobx-react';
import { useCallback, useMemo, useRef } from 'react';
import * as React from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { BtwButton, BtwLink, BtwMessage, BtwText, OrderFilterSummary, OrderSummaryModal, Stack, getIconForFilter, } from '@marvelapp/ballpark-components';
import { CONTACT_SUPPORT } from '@marvelapp/ballpark-help';
import { BrokenLinkIcon, CelebrateIcon, LoaderIcon, WarningIcon, } from '@marvelapp/ballpark-icons';
import { mapFilterNullish } from '@marvelapp/core';
import { StudyTypeEnum } from '../../__generated__/queryTypes';
import { studyTypeLabelMap, useRecruitmentState } from './RecruitmentContext';
import { StudySummary } from './common';
import { CustomValueAgeOption, GeneralTargetingFilter } from './types';
import { GET_RECRUITMENT_ALLOWANCE, isSpecialFilterGroup, } from './useGetRecruitmentData';
const CREATE_RECRUITMENT_ORDER = gql `
  mutation createRecruitmentOrder($testUUID: String!) {
    createRecruitmentOrder(input: { testUUID: $testUUID }) {
      ok
      error {
        code
        message
        partnerMessage
      }
    }
  }
`;
export const OrderConfirmationDialog = observer(function OrderConfirmationDialog({ closeModal, dialogState, setDialogState, }) {
    const { testUUID, clear: clearState } = useRecruitmentState();
    const { pathname } = useLocation();
    const [createRecruitmentOrder] = useMutation(CREATE_RECRUITMENT_ORDER, {
        variables: { testUUID },
        refetchQueries: [GET_RECRUITMENT_ALLOWANCE],
    });
    // Creating a ref for the confetti div
    const confettiRef = useRef(null);
    const completeOrder = useCallback(() => {
        setDialogState('PUBLISHING');
        createRecruitmentOrder({
            onCompleted: action((data) => {
                if (data.createRecruitmentOrder.ok) {
                    setDialogState('SUCCESS');
                    clearState();
                    // Function to trigger confetti multiple times
                    const triggerConfetti = (iterations, maxIterations) => {
                        if (confettiRef.current && iterations < maxIterations) {
                            const rect = confettiRef.current.getBoundingClientRect();
                            // Calculate origin within viewport area
                            const confettiOrigin = {
                                // Get the center position of the element on the canvas, as a percentage
                                x: ((rect.left + rect.width / 2) / window.innerWidth) * 100,
                                y: ((rect.top + rect.height / 2) / window.innerHeight) * 100,
                            };
                            // Ensure correct rendering of confetti
                            confetti({
                                zIndex: 99999999,
                                ticks: 60,
                                // Randomizing the count
                                count: Math.floor(Math.random() * (200 - 100 + 1)) + 100,
                                shapes: ['square', 'circle', 'star', 'heart'],
                                // Adding a bit of random into the drift of each individual contetti as it falls down
                                drift: Math.random() * 10 - 10,
                                // Randomizing the spread and angle of each confetti burst
                                spread: Math.random() * (120 - 60) + 60,
                                angle: Math.random() * (135 - 45) + 45,
                                // These really should point to tailwind colors, but I don't know how to import
                                // either tailwindcss/colors or @ballpark/tailwind packages
                                colors: ['#f0abfc', '#c7d2fe', '#86efac', '#fcd34d'],
                                position: confettiOrigin,
                            });
                            // Recursive call for repeated effect
                            setTimeout(() => {
                                triggerConfetti(iterations + 1, maxIterations);
                            }, 1000); // Delay between each burst
                        }
                    };
                    // Start the confetti effect
                    setTimeout(() => {
                        triggerConfetti(0, 3); // Initialize with slight delay
                    }, 500);
                }
                else {
                    setDialogState('ERROR');
                }
            }),
        });
    }, [clearState, createRecruitmentOrder, setDialogState]);
    if (dialogState === 'SUCCESS') {
        return (_jsx(ModalMessage, { cta: _jsxs(Stack, { direction: "row", gap: "4", children: [_jsx(BtwButton, { asChild: true, "data-testid": "all-orders-cta", variant: "secondary", children: _jsx(NavLink, { to: "/user/account/recruitment-orders", children: "View all orders" }) }), _jsx(BtwButton, { asChild: true, "data-testid": "go-to-responses-cta", variant: "secondary", children: _jsx(NavLink, { to: `${pathname.replace('recruit', 'responses')}`, children: "Go to responses" }) })] }), description: _jsxs(_Fragment, { children: ["Your project is now with the participant pool. ", _jsx("br", {}), "Now sit back, relax and wait for the responses to roll in."] }), icon: _jsxs(_Fragment, { children: [_jsx("div", { ref: confettiRef, children: _jsx(CelebrateIcon, {}) }), _jsx("div", { className: "absolute z-50", id: "confetti" })] }), title: "Woohoo! Your order is live!" }));
    }
    if (dialogState === 'ERROR') {
        return (_jsx(ModalMessage, { cta: _jsx(BtwButton, { onClick: completeOrder, variant: "primary", children: "Try again" }), description: _jsxs(_Fragment, { children: ["There was an issue with the participant pool. If this issue persists, please", ' ', _jsx(BtwLink, { href: CONTACT_SUPPORT, target: "_blank", variant: "muted", children: "get in touch." })] }), icon: _jsx(BrokenLinkIcon, {}), title: "Something went wrong" }));
    }
    if (dialogState === 'PUBLISHING') {
        return (_jsx(ModalMessage, { description: "Completing your order", icon: _jsx(LoaderIcon, { className: "animate-spin" }), title: "Sending your project details to the participant pool. This shouldn\u2019t take long." }));
    }
    return (_jsx(OrderSummaryModal.Root, { asChild: true, children: _jsxs("form", { children: [_jsx(FilterSummary, {}), _jsxs(OrderSummaryModal.CreditSummary, { children: [_jsxs(Stack, { className: "p-6", width: "full", children: [_jsx(StudySummary, {}), _jsx(AvailableParticipantsWarning, {})] }), _jsx(Stack, { width: "full", children: _jsxs(Stack, { className: "p-6 sm:h-full", gap: "4", justify: "between", width: "full", children: [_jsx(BtwText, { size: "xs", children: "By confirming this order, you are agreeing to use your allowance to hire participants to take part in your research." }), _jsxs(Stack, { direction: "row", gap: "2", width: "full", children: [_jsx(BtwButton, { className: "flex-1", "data-testid": "cancel-button", onClick: closeModal, size: "intermediate", children: "Cancel" }), _jsx(CompleteOrderCTA, { completeOrder: completeOrder })] })] }) })] })] }) }));
});
const FilterSummary = observer(function FilterSummary() {
    const { duration, getSelectedOptionsByFilterAndGroupId, numberOfResponses, studyType, } = useRecruitmentState();
    const selectedAge = useGetSelectedAge();
    const [selectedCountry] = getSelectedOptionsByFilterAndGroupId(GeneralTargetingFilter.Country, 'GROUP_DEFAULT');
    const studySpecificFilters = useGetStudySpecificFilters();
    return (_jsx(OrderFilterSummary, { age: selectedAge, country: selectedCountry.name, duration: duration, numberOfResponses: numberOfResponses, studySpecificFilters: studySpecificFilters, studyType: studyTypeLabelMap[studyType] }));
});
function useGetSelectedAge() {
    var _a, _b;
    const { getSelectedOptionsByFilterAndGroupId } = useRecruitmentState();
    const selectedAge = getSelectedOptionsByFilterAndGroupId(GeneralTargetingFilter.Age, 'GROUP_DEFAULT');
    if (!selectedAge.length)
        return null;
    if (selectedAge.some(({ customValue }) => customValue)) {
        const minAge = (_a = selectedAge.find((age) => age.id === CustomValueAgeOption.Min)) === null || _a === void 0 ? void 0 : _a.customValue;
        const maxAge = (_b = selectedAge.find((age) => age.id === CustomValueAgeOption.Max)) === null || _b === void 0 ? void 0 : _b.customValue;
        if (!minAge || !maxAge)
            return null;
        return (_jsxs(BtwText, { size: "13", variant: "primary", weight: "medium", children: [minAge, " - ", maxAge] }));
    }
    return (_jsx(_Fragment, { children: selectedAge.map(({ id, name }) => (_jsx(BtwText, { size: "13", variant: "primary", weight: "medium", children: name }, id))) }));
}
function useGetStudySpecificFilters() {
    const { filters, selectedStudySpecificFilters } = useRecruitmentState();
    const selectedFilters = Object.entries(selectedStudySpecificFilters);
    const rowIcon = useCallback((groupId, filterId) => {
        const filterGroupsCount = filters.other.originalFilterGroupOrder.length;
        return getIconForFilter(filterGroupsCount > 1 ? groupId : filterId);
    }, [filters.other.originalFilterGroupOrder.length]);
    const studySpecificFilters = useMemo(() => mapFilterNullish(selectedFilters !== null && selectedFilters !== void 0 ? selectedFilters : [], ([id, filterInfo]) => {
        const { groupId, name, options } = filterInfo;
        let groupName = '';
        if (groupId) {
            groupName = isSpecialFilterGroup(groupId)
                ? filters.specialFilters[groupId].name
                : filters.other.filterGroups[groupId].name;
        }
        const filterGroupsCount = filters.other.originalFilterGroupOrder.length;
        const tooltipText = groupName && filterGroupsCount > 1 ? `${groupName} > ${name}` : name;
        if (!options.length)
            return null;
        const filter = [
            id,
            {
                rowIcon: rowIcon(groupId, id),
                name,
                options,
                tooltipText,
            },
        ];
        return filter;
    }), [filters, rowIcon, selectedFilters]);
    return studySpecificFilters;
}
const ModalMessage = observer(function ModalMessage({ cta, description, icon, title, }) {
    return (_jsxs(Stack, { align: "center", className: "h-full text-center", justify: "center", gap: "6", children: [_jsxs(Stack, { align: "center", gap: "2", children: [icon, _jsx(BtwText, { size: "base", variant: "primary", weight: "semibold", children: title }), _jsx(BtwText, { size: "sm", variant: "secondary", weight: "medium", children: description })] }), cta] }));
});
const AvailableParticipantsWarning = observer(function AvailableParticipantsWarning() {
    var _a;
    const { eligibilityDetails, hasEnoughEligibleParticipants, isUpdatingSelectedFilters, studyType, } = useRecruitmentState();
    if (studyType !== StudyTypeEnum.SURVEY)
        return null;
    const eligibilityCountError = (_a = eligibilityDetails === null || eligibilityDetails === void 0 ? void 0 : eligibilityDetails.error) !== null && _a !== void 0 ? _a : null;
    if (eligibilityCountError) {
        return (_jsx(BtwMessage, { "data-testid": "error-fetching-participants-message", leadingIcon: _jsx(WarningIcon, {}), size: "sm", variant: "red", children: "We couldn\u2019t fetch the number of available participants. Please refresh the page." }));
    }
    if (isUpdatingSelectedFilters) {
        return (_jsx(BtwMessage, { "data-testid": "loading-participants-count-message", size: "sm", leadingIcon: _jsx(LoaderIcon, { className: "animate-spin" }), children: "Hang tight, we\u2019re getting the number of available participants..." }));
    }
    if (!hasEnoughEligibleParticipants) {
        return (_jsx(BtwMessage, { "data-testid": "not-enough-participants-message", leadingIcon: _jsx(WarningIcon, {}), size: "sm", variant: "red", children: "There aren\u2019t enough participants matching your specific filters. Being less specific might increase the number of potential participants." }));
    }
    return null;
});
const CompleteOrderCTA = observer(function CompleteOrderCTA({ completeOrder, }) {
    var _a;
    const { eligibilityDetails, hasEnoughEligibleParticipants, isUpdatingSelectedFilters, studyType, } = useRecruitmentState();
    const eligibilityCountError = (_a = eligibilityDetails === null || eligibilityDetails === void 0 ? void 0 : eligibilityDetails.error) !== null && _a !== void 0 ? _a : null;
    const mayNotHaveEnoughParticipants = eligibilityCountError !== null ||
        isUpdatingSelectedFilters ||
        !hasEnoughEligibleParticipants;
    return (_jsx(BtwButton, { className: "flex-1", "data-testid": "complete-order-button", disabled: studyType === StudyTypeEnum.SURVEY && mayNotHaveEnoughParticipants, variant: "primary", onClick: completeOrder, size: "intermediate", children: "Complete order" }));
});
