import { __rest } from "tslib";
import { jsx as _jsx } from "react/jsx-runtime";
import { observer } from 'mobx-react';
import * as React from 'react';
import Scrollbars from 'react-custom-scrollbars';
import styled from '@marvelapp/styled';
import { Box } from '@marvelapp/ui';
import { BUILDER_RIGHT_HAND_SIDE_SLIDE_PADDING_X, BUILDER_RIGHT_HAND_SIDE_SLIDE_PADDING_Y, } from './config';
const HalfSlide = styled(Box).attrs((props) => ({
    borderRight: '1px solid',
    borderColor: 'slate6',
    flex: props.flex || 1,
})) `
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;

  &:last-child {
    border: none;
  }
`;
export default HalfSlide;
const ScrollableHalfSlide = observer(function ScrollableHalfSlide(_a) {
    var { children, paddingX, 'data-testid': testId = 'box' } = _a, rest = __rest(_a, ["children", "paddingX", 'data-testid']);
    return (_jsx(HalfSlide, Object.assign({}, rest, { children: _jsx(Scrollbars, { autoHide: true, hideTracksWhenNotNeeded: true, children: _jsx(Box, { paddingX: BUILDER_RIGHT_HAND_SIDE_SLIDE_PADDING_X, paddingY: BUILDER_RIGHT_HAND_SIDE_SLIDE_PADDING_Y, "data-testid": testId, minHeight: "100%", display: "flex", flexDirection: "column", justifyContent: "center", children: _jsx(Box, { children: children }) }) }) })));
});
export const LeftScrollableHalfSlide = observer(function LeftScrollableHalfSlide(props) {
    return _jsx(ScrollableHalfSlide, Object.assign({}, props, { paddingX: 88 }));
});
export const RightScrollableHalfSlide = observer(function RightScrollableHalfSlide(props) {
    return _jsx(ScrollableHalfSlide, Object.assign({}, props, { paddingX: 'xl' }));
});
