import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { observer } from 'mobx-react';
import * as React from 'react';
import { Link, Stack } from '@marvelapp/ballpark-components';
import styled from '@marvelapp/styled';
import { Box } from '@marvelapp/ui';
import { StepClass, getStepIndex, getStepsCount, } from '@marvelapp/user-test-creator';
import { StepBox, StepHeader } from '../../../components/Step';
import { useStepContext } from '../../../utils/stepContext';
import { DecorativeImage } from '../../Response/answerSlides/DecorativeImage';
import { SummaryDivider } from '../SummaryDivider';
export const SummaryStep = observer(function SummaryStep({ active, children, dividerProps, secondary, showAllLink, isScreenerStep = false, extraAction, }) {
    var _a;
    const { step, userTest } = useStepContext();
    const currentStepNumber = getStepIndex(userTest, step.uuid);
    const totalStepsNumber = getStepsCount(userTest);
    const midDot = secondary && showAllLink && _jsx(_Fragment, { children: " \u00B7 " });
    const extraActionDot = (secondary || showAllLink) && extraAction && (_jsx("span", { className: "mx-1", children: "\u00B7" }));
    const secondaryText = (_jsxs(_Fragment, { children: [secondary, midDot, showAllLink && (_jsx(Link, { underline: true, to: showAllLink, "data-testid": `show-all-responses-${step.uuid}`, children: "Show all responses" })), extraActionDot, extraAction] }));
    const decorativeImageUrl = (_a = step.decorativeImage) === null || _a === void 0 ? void 0 : _a.url;
    const testId = `${step.className}-${currentStepNumber}`;
    return (_jsxs(Stack, { width: "full", "data-testid": "step-container", children: [_jsxs(StepBox, { active: active, id: step.uuid, testId: testId, kind: "summary", children: [_jsx(StepHeader, { isScreenerStep: isScreenerStep, currentStepNumber: currentStepNumber + 1, secondary: secondaryText, totalStepsNumber: totalStepsNumber }), decorativeImageUrl && (_jsx(DecorativeImage, { url: decorativeImageUrl, testId: testId })), children && (_jsx(Box, { pb: "l", px: "l", children: children }))] }), step.className !== StepClass.MediaSettings && dividerProps && (_jsx(SummaryDivider, Object.assign({ isScreenerStep: isScreenerStep }, dividerProps)))] }));
});
export const StepContainer = styled(Box).attrs({
    width: ['100%', '100%', '100%', '100%', '100%', '700px'],
    maxWidth: ['300px', '300px', '500px', '600px', '600px', '700px'],
}) `
  display: flex;
  flex-direction: column;
`;
