import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { observer } from 'mobx-react';
import { CornerButton, Icon } from '@marvelapp/ballpark-components';
import { CLOSE_ICON } from '@marvelapp/ballpark-theme';
import styled from '@marvelapp/styled';
import { Box } from '@marvelapp/ui';
import AddImageCTA from '../../../components/AddImageCTA';
import { CroppedScreen } from './CroppedScreen';
export const PrototypeTaskScreen = observer(function PrototypeTaskScreen({ aspectRatio, isDisabled, isLoading, onRemove, screenType, selectedScreenName, selectedScreen, toggleScreenPickerModal, externalId, }) {
    const openModal = () => {
        toggleScreenPickerModal();
    };
    if (isLoading) {
        return _jsx(CroppedScreen, { aspectRatio: aspectRatio, image: selectedScreen });
    }
    if (selectedScreenName && aspectRatio) {
        return (_jsxs(Relative, { "data-testid": `${screenType}-screen`, "data-external-id": externalId, children: [_jsx(CroppedScreen, { aspectRatio: aspectRatio, ctaText: "Change", image: selectedScreen, onClick: openModal }), _jsx(CornerButton, { icon: _jsx(Icon, { paths: CLOSE_ICON }), kind: "negative", testId: `remove-${screenType}-screen`, isDisabled: isDisabled, onClick: onRemove })] }));
    }
    return (_jsx(AddImageCTA, { aspectRatio: aspectRatio, heading: screenType === 'start' ? 'Pick a starting point' : 'Pick a goal', isDisabled: isDisabled, onClick: openModal, testId: `add-${screenType}-screen` }));
});
const Relative = styled(Box) `
  position: relative;
  width: 100%;
  height: 100%;
`;
