import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import * as React from 'react';
import { Svg } from '@marvelapp/ui';
// eslint-disable-next-line mobx/missing-observer
export function ProlificLogo(props) {
    return (_jsxs(Svg, Object.assign({ xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 289.4 78.5", style: {
            enableBackground: 'new 0 0 289.4 78.5',
        }, xmlSpace: "preserve" }, props, { children: [_jsx("path", { d: "M127.5 31.6c0-9.7-7.2-16.4-22-16.4h-9.8v46.5h7.5V48.2h2.3c14.7 0 22-6.3 22-16.6zm-7.3 0c0 6.8-5.2 10.5-12.9 10.5h-4V21.3h4c7.6 0 12.9 4 12.9 10.3zm28.4 4.4c.5-2.2.7-3.9 1.1-6.1-.6-.1-1.2-.1-1.8-.1-3.5 0-6.9 2-7.9 5.2v-4.7h-6.8v31.4h6.7V48.2c0-6.6 1-12.2 8.1-12.2h.6zm36 10.2c0-10.4-7.1-16.3-16.2-16.3s-16.2 5.9-16.2 16.2 7.2 16.1 16.2 16.1 16.2-5.8 16.2-16zm-6.8 0c0 6.5-4.1 10.1-9.4 10.1-5.1 0-9.4-3.6-9.4-10.2s4.3-10.3 9.4-10.3c5.3.1 9.4 3.8 9.4 10.4zM198 61.7V14.6l-6.8 1.4v45.8h6.8zm17.1 0V30.4h-6.9v31.4h6.9zm.6-40.8c0-2.4-1.8-3.8-4.1-3.8s-4.1 1.4-4.1 3.7c0 2.1 1.6 3.8 3.7 3.8h.4c2.3 0 4.1-1.4 4.1-3.7zm27.7-5.8c-1.8-.3-3.6-.5-5.5-.5-5.9 0-10.6 2.6-10.6 11.5v4.3h-3.9l-1.5 5.6h5.5v25.8h6.8V35.9h6.9v-5.6h-6.9V26c0-4.1 1.3-5.8 4.6-5.8 1.2 0 2.4.2 3.6.5l1-5.6zm10.9 46.6V30.4h-6.8v31.4l6.8-.1zm.7-40.8c0-2.4-1.8-3.8-4.1-3.8s-4.1 1.4-4.1 3.7c0 2.1 1.6 3.8 3.7 3.8h.4c2.3 0 4.1-1.4 4.1-3.7zm22.7 41.4c4.2 0 8.3-1.3 11.8-3.6l-3.1-5.5c-2.4 2-5.5 3.2-8.7 3.2-5.6 0-9.8-3.8-9.8-10.4s4.4-10.2 9.9-10.2c3.3.1 6.6 1.3 9.2 3.3l1.4-6c-3.2-2.1-6.9-3.3-10.7-3.3-9.5 0-16.5 6.1-16.5 16.3 0 10 7 16.2 16.5 16.2z", style: {
                    fill: '#1c498a',
                } }), _jsx("path", { d: "M50.8 15.1c-.5-.7-1-1.3-1.5-1.8-2.7-2.6-6.3-3.9-10-3.8-3.8-.2-7.4 1.2-10.1 3.9-1.1 1.1-1.9 2.3-2.6 3.7h-1.1c-3.8-.1-7.6.7-11.1 2.2.9-4.7 3.3-8.9 6.7-12.2C25.9 2.4 32 0 39.3 0c7.5 0 13.5 2.4 18.2 7.1 2.8 2.8 4.9 6.2 6.1 9.9-3.4-1.3-7-2-10.6-2-.8 0-1.5 0-2.2.1", style: {
                    fill: '#85ccf1',
                } }), _jsx("path", { d: "M25.3 26.5c-3.8-.2-7.4 1.2-10.1 3.9-2.6 2.7-3.9 6.2-3.8 9.9-.1 3.7 1.3 7.2 3.8 9.9 2.4 2.4 5.6 3.7 8.9 3.8H25.9v9.5h-.5c-7.3 0-13.4-2.4-18.2-7.1C2.7 52.2.1 46.4 0 40.3c.1-6.1 2.7-12 7.2-16.1 4.8-4.7 10.9-7.1 18.2-7.1 4.1-.1 8.2.8 11.9 2.5-3 1.9-5.6 4.5-7.6 7.5-1.5-.4-2.9-.6-4.4-.6z", style: {
                    fill: '#4caae8',
                } }), _jsx("path", { d: "M27.9 40.9v37.6h11.2l.1-38.2v-.6c0-3.5 1.4-6.9 3.9-9.4 2.7-2.6 6.3-3.9 10-3.8 7.3-.4 13.5 5.1 14 12.4v1.4c.1 3.7-1.3 7.2-3.8 9.9-2.4 2.3-5.6 3.7-8.9 3.8H41.1v9.5H53c7.3 0 13.4-2.4 18.2-7.1 4.5-4.2 7.1-10 7.2-16.1-.1-6.1-2.7-12-7.2-16.1-4.8-4.7-10.9-7.1-18.2-7.1-7.5 0-13.5 2.4-18.2 7.1-4.3 4.1-6.8 9.7-7 15.6l.1 1.1z", style: {
                    fill: '#3b88ca',
                } })] })));
}
