import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { noop } from 'lodash-es';
import { observer } from 'mobx-react';
import { useCallback, useRef, useState } from 'react';
import { Button, Icon, Text } from '@marvelapp/ballpark-components';
import { MARVEL_ICON } from '@marvelapp/ballpark-theme';
import styled from '@marvelapp/styled';
import { Box } from '@marvelapp/ui';
import { FigmaIcon } from '../../icons';
import UrlInput from './UrlInput';
export default observer(function UrlForm({ buttonText, isLoading, label, onChange = noop, onSubmit, placeholder, testId, value, domainAllowList, validate, }) {
    const [hasError, setHasError] = useState(false);
    const urlInputRef = useRef(null);
    const [internalValue, setInternalValue] = useState(value);
    const handleChange = useCallback((newValue) => {
        setInternalValue(newValue);
        onChange(newValue);
    }, [onChange]);
    const handleSubmit = useCallback((e) => {
        var _a;
        e.preventDefault();
        const validLink = (_a = urlInputRef.current) === null || _a === void 0 ? void 0 : _a.validate();
        if (validLink) {
            onSubmit(validLink);
        }
    }, [onSubmit, internalValue, hasError]);
    return (_jsx(Form, { "data-testid": `choose-${testId}-source-form`, onSubmit: handleSubmit, children: _jsxs(InputWrapper, { children: [_jsx(UrlInput, { ref: urlInputRef, onChange: handleChange, testId: `create-usertest--${testId}-source`, disabled: isLoading, id: `${testId}-source`, label: label, name: `${testId}-source`, placeholder: placeholder, required: true, resize: "none", value: value, onError: setHasError, domainAllowList: domainAllowList, validate: validate }), _jsxs(Box, { display: "flex", alignItems: "center", justifyContent: "space-between", mt: "s", children: [_jsx(Button, { "data-testid": `${testId}-url-submit-button`, isDisabled: hasError, isLoading: isLoading, type: "submit", children: buttonText }), domainAllowList && (_jsxs(Icons, { "data-testid": "supported-platforms", children: [_jsx(Text, { size: "xs", mr: "s", children: "Supported platforms" }), _jsx(Icon, { paths: MARVEL_ICON, fill: "#1fb6ff", height: 32, viewBox: "0 0 32 32", width: 32 }), _jsx(FigmaIcon, {})] }))] })] }) }));
});
const Form = styled.form `
  width: 100%;
`;
const InputWrapper = styled.div `
  width: 100%;
  max-width: 420px;
  > div,
  > div > input {
    width: 100%;
  }
`;
const Icons = styled(Box).attrs({ color: 'slate12', mr: -2 }) `
  display: flex;
  align-items: center;
`;
