import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { observer } from 'mobx-react';
import { useState } from 'react';
import styled from '@marvelapp/styled';
import { Box } from '@marvelapp/ui';
import Image from '../../../components/Image';
import ZoomImageModal from '../../../components/ZoomImageModal';
import { MAX_DECORATIVE_IMAGE_DIMENSIONS_IN_REPORTING, MAX_DECORATIVE_IMAGE_DIMENSIONS_IN_REPORTING_ZOOMED, } from '../../../constants/image';
import { getOptimisedContainedImageUrl } from '../../../utils/image';
export const DecorativeImage = observer(function DecorativeImage({ testId, url, }) {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const optimisedThumbUrl = getOptimisedContainedImageUrl(url, MAX_DECORATIVE_IMAGE_DIMENSIONS_IN_REPORTING);
    const optimisedUrl = getOptimisedContainedImageUrl(url, MAX_DECORATIVE_IMAGE_DIMENSIONS_IN_REPORTING_ZOOMED);
    return (_jsxs(_Fragment, { children: [_jsx(ImageContainer, { onClick: () => setIsModalOpen(true), children: _jsx(Image, { alt: "", src: optimisedThumbUrl, srcDark: optimisedThumbUrl, testId: `upload--${testId}-image-artwork`, maxHeight: 700 }) }), _jsx(ZoomImageModal, { image: optimisedUrl, isOpen: isModalOpen, onClose: () => setIsModalOpen(false) })] }));
});
const ImageContainer = styled(Box).attrs({
    border: '1px solid',
    borderColor: 'slate6',
    borderRadius: 'xs',
    hoverBg: 'slate3',
    transition: 'smooth.fast',
    backgroundColor: 'slate2',
    padding: 'm',
}) `
  cursor: pointer;
  max-width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 32px;
  margin-right: 32px;
  margin-bottom: 32px;
`;
