import { __awaiter } from "tslib";
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useServer } from '@ballpark/realtime-plugin-server';
import { identity } from 'lodash-es';
import { observer } from 'mobx-react';
import { useCallback, useState } from 'react';
import { useConfig } from '@marvelapp/ballpark-application';
import { Button, Heading, Link, Text } from '@marvelapp/ballpark-components';
import { mapFilterNullish } from '@marvelapp/core';
import { Flex } from '@marvelapp/ui';
import { showErrorNotification } from '@marvelapp/ui-internal';
import { getStepTitle } from '@marvelapp/user-test-creator';
import StepCardContainer from './StepCardContainer';
const emptyCopy = 'Automatically generate a summary of your question responses. Works best with lots of responses.';
const SummaryQuestionOpenAI = observer(function SummaryQuestionOpenAI({ step, stats, testId, }) {
    const [isLoading, setIsLoading] = useState(false);
    const [result, setResult] = useState('');
    const config = useConfig();
    const { token } = useServer();
    const question = getStepTitle(step);
    const answers = getStepAnswers(stats);
    const id = step.uuid;
    const { host } = config.edge;
    const generateSummary = useCallback(() => __awaiter(this, void 0, void 0, function* () {
        setIsLoading(true);
        setResult('');
        try {
            const response = yield fetch(`${host}/api/summarizeResponse`, {
                method: 'POST',
                body: JSON.stringify({ question, answers, id }),
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
            });
            if (response.ok) {
                const data = yield response.json();
                setResult(data);
                return;
            }
            if (response.status === 429) {
                showErrorNotification({
                    toastId: 'summary-rate-limit',
                    content: 'It looks like there are a lot of requests right now, try again in a few minutes',
                });
            }
            else {
                showGenericErrorNotification();
            }
        }
        catch (_a) {
            showGenericErrorNotification();
        }
        finally {
            setIsLoading(false);
        }
    }), [host, id, question, answers, token]);
    if (!question || !answers.length)
        return null;
    const textProps = {
        my: 3,
        lineHeight: 1.7,
        fontSize: 2,
        color: 'slate11',
    };
    return (_jsx(StepCardContainer, { className: "order-last col-span-3 col-start-1 col-end-4 !mr-0 w-full !max-w-full flex-1 xl:order-first", header: _jsxs(Flex, { width: "100%", direction: "row", justifyContent: "space-between", alignItems: "center", mb: 2, children: [_jsx(Heading, { fontSize: "16px", children: "Summary (beta)" }), result && _jsx(FeedbackLink, {})] }), testId: testId, children: result ? (_jsx(Text, Object.assign({}, textProps, { children: result }))) : (_jsxs(_Fragment, { children: [_jsx(Text, Object.assign({}, textProps, { children: emptyCopy })), _jsxs(Flex, { direction: "row", justifyContent: "space-between", alignItems: "center", mb: 2, children: [_jsx(Button, { kind: "ghost", isLoading: isLoading, onClick: generateSummary, children: "Generate" }), _jsx(FeedbackLink, { text: "Share feedback" })] })] })) }));
});
const FeedbackLink = observer(function FeedbackLink({ text = 'Leave feedback', }) {
    return (_jsx(Link, { fontSize: "s", color: "red", underline: true, to: "https://app.ballparkhq.com/record/47b3a3c6-8b85-4827-bd78-4ff160a4b0fb", children: text }));
});
function getStepAnswers(stats) {
    const { questionAnswers } = stats;
    if (!questionAnswers)
        return [];
    // Extracting the relevant data from this nullable abomination is not easy 🤢
    const answers = mapFilterNullish(mapFilterNullish(questionAnswers, (stat) => stat === null || stat === void 0 ? void 0 : stat.answers).flatMap(identity), identity);
    return mapFilterNullish(answers, getAnswerValue);
}
function getAnswerValue(answer) {
    var _a, _b, _c;
    if (answer.__typename === 'AnswerPlainText')
        return answer.text || undefined;
    if (answer.__typename === 'AnswerInteger')
        return (_a = answer.value) === null || _a === void 0 ? void 0 : _a.toString();
    if (answer.__typename === 'AnswerDateTime')
        return (_b = answer.date) === null || _b === void 0 ? void 0 : _b.toString();
    // TODO: probably filter out email questions since there's no point in running openAI on them
    if (answer.__typename === 'AnswerEmail')
        return (_c = answer.email) === null || _c === void 0 ? void 0 : _c.toString();
    return undefined;
}
function showGenericErrorNotification() {
    showErrorNotification({
        toastId: 'summary-error',
        content: 'Something went wrong, please try again',
    });
}
export default SummaryQuestionOpenAI;
