import { __rest } from "tslib";
import { jsx as _jsx } from "react/jsx-runtime";
import { observer } from 'mobx-react';
import * as React from 'react';
import { useIntl } from 'react-intl';
import Button from '../../../components/StopButton';
import constants from './constants';
export default observer(function StopButton(_a) {
    var { onClick, children } = _a, rest = __rest(_a, ["onClick", "children"]);
    const intl = useIntl();
    return (_jsx(Button, Object.assign({ "data-testid": "stop-user-test", onClick: onClick }, rest, { children: intl.formatMessage(constants.STOP_TEST) })));
});
