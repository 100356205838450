import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { observer } from 'mobx-react';
import { useCallback, useMemo } from 'react';
import { Combobox, Stack } from '@marvelapp/ballpark-components';
import { ChoiceStepUtils, IdentityOperator, } from '@marvelapp/user-test-creator';
import { getAnswerOptions } from '../../../utils';
import { MultiConditionLogic, OperatorSelect, } from '../shared/multiConditionLogic';
const CHOICE_OPERATORS = [
    [IdentityOperator.Is, 'is equal to'],
    [IdentityOperator.IsNot, 'is not equal to'],
];
function createRequirement(combinator) {
    return {
        operator: IdentityOperator.Is,
        combinator,
        selectedChoiceUUID: null,
    };
}
function updateRequirement(requirement, value) {
    // eslint-disable-next-line no-param-reassign
    requirement.selectedChoiceUUID = value.selectedChoiceUUID;
}
export function validateRequirement({ selectedChoiceUUID, }) {
    return selectedChoiceUUID !== null;
}
export const ChoiceConditionalLogic = observer(function ChoiceConditionalLogic({ step, }) {
    const renderRequirement = ({ ruleIndex, requirementIndex, setValue: setAnswerRule, setOperator, }) => {
        var _a, _b, _c, _d;
        const requirement = (_c = (_b = (_a = step === null || step === void 0 ? void 0 : step.conditions) === null || _a === void 0 ? void 0 : _a.rules) === null || _b === void 0 ? void 0 : _b[ruleIndex]) === null || _c === void 0 ? void 0 : _c.requirements[requirementIndex];
        return (_jsx(MultipleChoiceRequirement, { step: step, setOperator: setOperator, savedValue: requirement !== null && requirement !== void 0 ? requirement : null, setRule: setAnswerRule, currentOperator: (_d = requirement === null || requirement === void 0 ? void 0 : requirement.operator) !== null && _d !== void 0 ? _d : IdentityOperator.Is }));
    };
    const isMultipleSelectionEnabled = !!ChoiceStepUtils.getMultipleSelectionMode(step);
    return (_jsx(MultiConditionLogic, { step: step, canAddRequirement: isMultipleSelectionEnabled, renderRequirement: renderRequirement, createRequirement: createRequirement, updateRequirement: updateRequirement, validateRequirement: validateRequirement }));
});
const MultipleChoiceRequirement = observer(function MultipleChoiceRequirement({ step, setRule, currentOperator, savedValue, setOperator, }) {
    return (_jsxs(_Fragment, { children: [_jsx(OperatorSelect, { operator: currentOperator, operatorOptions: CHOICE_OPERATORS, setRule: setRule, setOperator: setOperator }), _jsx(AnswerList, { savedValue: savedValue, step: step, setRule: setRule })] }));
});
const AnswerList = observer(function AnswerList({ savedValue, step, setRule, }) {
    var _a;
    const answerOptions = useMemo(() => getAnswerOptions({ step }), [step]);
    const options = answerOptions.map(({ choiceUUID, choiceValue }) => {
        return {
            label: choiceValue,
            value: choiceUUID,
            onSelect: () => {
                onSearchItemClick(choiceUUID);
            },
            testId: choiceValue,
        };
    });
    const selectedValue = (_a = savedValue === null || savedValue === void 0 ? void 0 : savedValue.selectedChoiceUUID) !== null && _a !== void 0 ? _a : null;
    const selectedItem = options.find((item) => item.value === selectedValue);
    const onSearchItemClick = useCallback((choiceUUID) => {
        setRule({
            value: { selectedChoiceUUID: choiceUUID },
        });
    }, [setRule]);
    return (_jsx(Stack, { direction: "row", className: "min-w-0 flex-1", "data-testid": "choice-answer-container", children: _jsxs(Combobox.Root, { variant: "single-select", children: [_jsx(Combobox.Trigger, { className: "w-full", "data-testid": "choice-answer-field", disabled: !options.length && true, hasPlaceholder: !selectedItem, truncate: true, children: (selectedItem === null || selectedItem === void 0 ? void 0 : selectedItem.label) || 'Select an option…' }), _jsxs(Combobox.Content, { children: [_jsx(Combobox.Empty, {}), !!options.length &&
                            options.map((item) => {
                                return (_jsx(Combobox.Item, { "data-testid": `combobox-list-item-${item.testId}`, onSelect: item.onSelect, selected: selectedValue === item.value, children: item.label }));
                            })] })] }) }));
});
