import { __awaiter } from "tslib";
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { createLocalScreenTracks } from 'livekit-client';
import { observer } from 'mobx-react';
import { useCallback, useRef, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Icon, Stack, Text, cn } from '@marvelapp/ballpark-components';
import { SCREEN_ICON } from '@marvelapp/ballpark-theme';
import { useWholeTestRecording } from '../../../WholeTestRecordingContext';
import useAnimateTabTitle from '../../../hooks/useAnimateTabTitle';
import { RequestMedia, RequestMediaContainer, } from '../../MediaSettings/RequestMedia';
import EntireScreenShareError from './EntireScreenShareError';
import NoScreenShareError from './NoScreenShareError';
const ShareScreen = observer(function ShareScreen({ send, state, recordEntireScreen, permissions }) {
    const [mediaTrack, setMediaTrack] = useState();
    const recording = useWholeTestRecording();
    const videoRef = useRef(null);
    const intl = useIntl();
    useAnimateTabTitle(state.matches('shareScreen.requestingScreen') && !recordEntireScreen);
    const onShareScreen = useCallback(() => __awaiter(this, void 0, void 0, function* () {
        let videoTrack;
        if (state.matches('shareScreen.idle')) {
            send('REQUEST_SCREEN');
        }
        else {
            send('RETRY_SCREEN');
        }
        if (!recording)
            return;
        try {
            [videoTrack] = yield createLocalScreenTracks({
                audio: false, // support for screenshare audio is limited: https://developer.mozilla.org/en-US/docs/Web/API/MediaDevices/getDisplayMedia#browser_compatibility
                selfBrowserSurface: 'include',
                video: {
                    displaySurface: recordEntireScreen ? 'monitor' : 'window',
                },
            });
            recording.setScreenTrack(videoTrack);
            if (window.Cypress) {
                window.__screenStream = videoTrack.mediaStream;
            }
            if (videoRef.current) {
                // this attaches the video stream to the html video element
                videoTrack.attach(videoRef.current);
            }
        }
        catch (err) {
            // TODO: differentiate between permission denied and other errors, such as
            // unsupported browser
            console.log("Couldn't get screen stream, error:", err);
            send('SCREEN_DENIED');
        }
        if (!videoTrack) {
            send('SCREEN_DENIED');
            return;
        }
        videoTrack.mediaStreamTrack.addEventListener('ended', () => {
            videoTrack.detach();
            send('RETRY_SCREEN');
            setMediaTrack(undefined);
        });
        if (recordEntireScreen) {
            send('SCREEN_GRANTED');
            // If the displaySurface property is available we can check to see what screen share has been enabled
            // otherwise in safari it will only allow you to share the screen and no modal appears and thus displaySurface is undefined
            if (
            // typeof displaySurface = 'browser'| 'window' | 'monitor'
            // We need to record the entire screen because website tasks are now opened
            // a new window
            // TODO: Check again when updating TS to see if the property
            // is added to lib.dom.d.ts
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            videoTrack.mediaStreamTrack.getSettings().displaySurface &&
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                videoTrack.mediaStreamTrack.getSettings().displaySurface !== 'monitor') {
                send('ENTIRE_SCREEN_NOT_FOUND');
                videoTrack.stop();
                return;
            }
            send('ENTIRE_SCREEN_FOUND');
        }
        setMediaTrack(videoTrack);
        send('SCREEN_GRANTED');
    }), [state, recording, recordEntireScreen, send]);
    let description;
    if (state.matches('shareScreen.screenGranted')) {
        description = (_jsx(FormattedMessage, { id: 'a4R4J/', defaultMessage: 'Your screen sharing is enabled' }));
    }
    else if (recordEntireScreen) {
        description = (_jsx(FormattedMessage, { id: 'WRPHD3', defaultMessage: 'Select \u2018<strong>entire screen</strong>\u2019 when you see the popup and click \u2018Share\u2019', values: {
                strong: (chunks) => _jsx("strong", { children: chunks }),
            } }));
    }
    else {
        description = (_jsx(FormattedMessage, { id: 'vXXacm', defaultMessage: 'Select \u2018<strong>window</strong>\u2019 when you see the popup and click \u2018Share\u2019', values: {
                strong: (chunks) => _jsx("strong", { children: chunks }),
            } }));
    }
    return (_jsxs(RequestMediaContainer, { children: [_jsx(RequestMedia, { state: getRequestState(state), description: description, icon: _jsx(Icon, { paths: SCREEN_ICON, width: 14, height: 14, viewBox: "0 0 14 14" }), testId: "toggle-screen-share", title: intl.formatMessage({ id: "0NAUtT", defaultMessage: "Share screen" }), onChange: onShareScreen }), state.matches('shareScreen.screenDenied') && _jsx(NoScreenShareError, {}), state.matches('shareScreen.entireScreenNotFound') && (_jsx(EntireScreenShareError, {})), permissions.screen && (_jsx("div", { "data-testid": "screen-preview", className: `relative w-full ${mediaTrack ? 'block' : 'hidden'}`, children: _jsxs(Stack, { className: "relative w-full", direction: "row", gap: "4", children: [_jsx("div", { className: cn(screenPreviewContainerClasses), children: _jsx("video", { ref: videoRef, className: "h-full min-h-full w-full object-fill", autoPlay: true, playsInline: true, muted: true }) }), _jsx(Text, { fontSize: "s", children: _jsx(FormattedMessage, { id: 'sgcSMm', defaultMessage: 'A live preview of your shared screen will appear here once successfully connected. Please ensure that the correct screen is being shared before continuing' }) })] }) }))] }));
});
const screenPreviewContainerClasses = [
    'overflow-hidden',
    'relative',
    // Setting the width to match the webcam preview
    'w-[140px]',
    'shrink-0',
    'grow-0',
    'rounded-md',
    // Setting a lil border over the video.
    // Alas, there's no better way of doing it with the way media elements behave
    'after:block',
    'after:content-[""]',
    'after:absolute',
    'after:inset-0',
    'after:ring-1',
    'after:ring-inset',
    'after:ring-gray-600/25',
    'after:rounded-md',
    // Making sure the overlay doesn't block any native behavior on it
    'after:pointer-events-none',
];
export default ShareScreen;
function getRequestState(state) {
    if (state.matches('shareScreen.requestingScreen')) {
        return 'requesting';
    }
    if (state.matches('shareScreen.screenDenied')) {
        // you can always retry a screen share request
        return 'idle';
    }
    if (state.matches('shareScreen.screenGranted')) {
        return 'granted';
    }
    return 'idle';
}
