import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import range from 'lodash-es/range';
import path from 'path';
import { useContext } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useKey } from '@marvelapp/hooks';
import { BackButton, Box, CloseButton, Flex, Position } from '@marvelapp/ui';
import { ModalIndexContext, ModalStackContext } from './ModalSwitch';
/**
 * @param {any}
 */
export default function Modal({ backButtonTestID = 'modal-back-button', children, closeButtonTestID = 'modal-close-button', closeLink, currentStep, dark, maxWidth = 520, steps, topLeftContent, }) {
    const history = useHistory();
    const location = useLocation();
    const stack = useContext(ModalStackContext);
    const index = useContext(ModalIndexContext);
    const onCloseClick = () => {
        if (stack.length) {
            history.go(-stack.length);
        }
        else {
            history.replace(path.resolve(location.pathname, closeLink));
        }
    };
    useKey(onCloseClick, { key: 'Escape' });
    const backAvailable = stack.length > 1 && index === stack.length - 1;
    const closeAvailble = stack.length === 0 || index === stack.length - 1;
    return (_jsxs(Flex, { flexDirection: "column", minHeight: "100vh", children: [_jsxs(Flex, { alignItems: "center", justifyContent: "space-between", p: 5, children: [_jsx(Flex, { alignItems: "flex-start", children: _jsxs(Position, { zIndex: "belowSidebar", children: [backAvailable && (_jsx(Box, { children: _jsx(BackButton, { as: "div", "data-testid": backButtonTestID, mr: 3, onClick: () => history.goBack() }) })), !topLeftContent && !backAvailable && (_jsx(Box, { height: 40, width: 40 })), topLeftContent && _jsx(_Fragment, { children: topLeftContent })] }) }), steps && currentStep && (_jsx(Flex, { children: range(steps).map((step) => {
                            const beforeCurrentStep = currentStep - 1 >= step;
                            const isCurrentStep = currentStep - 1 === step;
                            return (_jsxs(Flex, { alignItems: "center", mx: 2, children: [step !== 0 && (_jsx(Box, { bg: beforeCurrentStep ? 'primary' : 'slate8', height: 1, marginRight: 2, width: 50 })), isCurrentStep ? (_jsx(Box, { bg: "primary", borderRadius: "50%", color: "background", fontSize: 1, fontWeight: 600, height: 18, lineHeight: "19px", textAlign: "center", width: 18, children: currentStep })) : (_jsx(Box, { bg: beforeCurrentStep ? 'primary' : 'slate8', borderRadius: "50%", height: 8, width: 8 }))] }, step));
                        }) })), closeAvailble ? (_jsx(Position, { zIndex: "belowSidebar", children: _jsx(CloseButton, { "data-testid": closeButtonTestID, kind: dark ? 'ghostReversed' : 'ghost', onClick: onCloseClick }) })) : (_jsx(Box, { height: 40, width: 40 }))] }), _jsx(Flex, { alignItems: "center", flexDirection: "column", flexGrow: "1", justifyContent: "center", px: [5, 5, 5, 0], children: _jsx(Box, { maxWidth: maxWidth, width: "100%", children: _jsx(Flex, { flexDirection: "column", justifyContent: "flex-start", pb: 6, children: children }) }) })] }));
}
