import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { observer } from 'mobx-react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Button } from '@marvelapp/ballpark-components';
import { deviceDetection, getImage, getImage2x } from '@marvelapp/core';
import styled, { css } from '@marvelapp/styled';
import { Box, ModalPortal } from '@marvelapp/ui';
import { useMaybeRecorderState } from '../../components/Record/RecorderContext';
import ZoomImageModal from '../../components/ZoomImageModal';
export default observer(function PreferenceTestImageModal({ item, optionIndex, onClose, onNextImage, onPrevImage, onChooseImage, isChecked, maxOptionsReached, }) {
    const intl = useIntl();
    const { isMobile } = deviceDetection;
    const isOpen = !!item;
    const isDisabled = maxOptionsReached && !isChecked;
    const hideFooter = !onChooseImage;
    const maybeRecorderState = useMaybeRecorderState();
    return (_jsxs(_Fragment, { children: [_jsx(ZoomImageModal, { image: item ? getImage(item.src) : undefined, image2x: item ? getImage2x(item.src) : undefined, fitImage: true, isOpen: isOpen, name: item
                    ? item.choiceTitle ||
                        intl.formatMessage({ id: "1yAmrx", defaultMessage: "Choice {number}" }, {
                            number: optionIndex + 1,
                        })
                    : undefined, onClose: onClose, onNextImage: onNextImage, onPrevImage: onPrevImage }), !hideFooter && (_jsx(ModalPortal, { children: _jsxs(Footer, { isModalOpen: isOpen, isLive: maybeRecorderState === null || maybeRecorderState === void 0 ? void 0 : maybeRecorderState.isLive, children: [!isMobile && _jsx(InvisibleContainer, {}), _jsx(ButtonContainer, { children: _jsx(Button, { "data-testid": "choose-image-button", onClick: onChooseImage, disabled: isDisabled, kind: isDisabled ? 'disabled' : 'primary', children: isChecked ? (_jsx(Box, { textAlign: "center", children: _jsx(FormattedMessage, { id: 'xQJSPV', defaultMessage: 'Deselect this image' }) })) : (_jsx(_Fragment, { children: maxOptionsReached ? (_jsx(Box, { textAlign: "center", children: _jsx(FormattedMessage, { id: 'tJnCCY', defaultMessage: 'Maximum options already selected' }) })) : (_jsx(Box, { textAlign: "center", children: _jsx(FormattedMessage, { id: 'ZIRWxU', defaultMessage: 'Select this image' }) })) })) }) }), !isMobile && (_jsx(Box, { ml: "auto", flex: 1, textAlign: "center", fontSize: 13, children: _jsx(FormattedMessage, { id: 'U3e6nV', defaultMessage: 'Use left/right arrow keys to view each image' }) }))] }) }))] }));
});
const InvisibleContainer = styled(Box).attrs({}) `
  display: flex;
  flex: 1;
  visibility: hidden;
  margin-right: auto;
`;
const ButtonContainer = styled(Box).attrs({}) `
    display: flex;
    flex: 1
    align-items: center;
    justify-content: center;
    margin: auto;
  `;
const Footer = styled(Box).attrs({}) `
  ${({ isModalOpen }) => isModalOpen &&
    css `
      bottom: 0;
      left: 0;
      position: fixed;
      height: 60px;
      width: 100%;
      background-color: white;
      display: flex;
      align-items: center;
      z-index: 9999;
      justify-content: center;
      border: 1px solid rgb(215, 219, 223);
    `};

  ${({ isModalOpen }) => !isModalOpen &&
    css `
      display: none;
    `};

  ${({ isLive }) => isLive &&
    css `
      width: calc(100vw - (60px + 24px));
      left: 8px;
      bottom: 8px;
      border-radius: 0 0 8px 8px;
    `};
`;
