import { __rest } from "tslib";
import { jsx as _jsx } from "react/jsx-runtime";
import { useOverrideNativeUndo } from '@ballpark/realtime-plugin-history';
import { observer } from 'mobx-react';
import { forwardRef } from 'react';
import * as React from 'react';
import TextareaAutosize from 'react-textarea-autosize';
import { inputKinds as kinds, } from '@marvelapp/ballpark-components';
import styled from '@marvelapp/styled';
import { inputStyles } from '@marvelapp/ui';
export default observer(forwardRef(function TextareaGrowing(_a, ref) {
    var { kind = 'primary', onKeyDown, as } = _a, // needed to make typescript happy
    props = __rest(_a, ["kind", "onKeyDown", "as"]);
    const overrideNativeUndo = useOverrideNativeUndo(onKeyDown);
    return (_jsx(Textarea, Object.assign({}, kinds[kind], props, { onKeyDown: overrideNativeUndo, ref: ref })));
}));
const Textarea = styled(TextareaAutosize).attrs({
    borderRadius: 'm',
    fontFamily: 0,
    fontSize: [14, 14, 14, 14, 14, 'm'],
    lineHeight: 'body',
    paddingX: 's',
    paddingY: 10,
}) `
  ${inputStyles};
  max-height: 55vh;
`;
