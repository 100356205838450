import { __rest } from "tslib";
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { observer } from 'mobx-react';
import * as React from 'react';
import { formatTime } from '@marvelapp/core';
import { Box } from '@marvelapp/ui';
import StatBox from '../../../components/PrototypeStat';
import { FirstClickHeatmap } from '../Heatmaps/FirstClickHeatmap';
import { Answer } from './Answer';
export const FirstClickAnswer = observer(function FirstClickAnswer(_a) {
    var { step, resultPk, timeToFirstClick } = _a, restProps = __rest(_a, ["step", "resultPk", "timeToFirstClick"]);
    return (_jsxs(Answer, Object.assign({ step: step }, restProps, { children: [step.importedPrototypeUrl && (_jsx(_Fragment, { children: _jsx(FirstClickHeatmap, { resultPk: resultPk, stepUUID: step.uuid, prototypeUrl: step.importedPrototypeUrl }) })), !!timeToFirstClick && (_jsx(Box, { mt: "s", children: _jsx(StatBox, { testId: "time-to-first-click", kind: "slate", title: "Duration to first click", stat: formatTime(timeToFirstClick) }) }))] })));
});
