import { __rest } from "tslib";
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { observer } from 'mobx-react';
import * as React from 'react';
import { YesOrNoStepType, getStepType } from '@marvelapp/user-test-creator';
import { useStepContext } from '../../../utils/stepContext';
import { ChoiceBar } from '../ChoiceBar';
import { SummaryStep } from './SummaryStep';
export default observer(function SummaryQuestion(_a) {
    var { active, secondary, showAllLink, stats } = _a, rest = __rest(_a, ["active", "secondary", "showAllLink", "stats"]);
    const { step } = useStepContext();
    const yesStats = getAnswerStats(stats, true);
    const noStats = getAnswerStats(stats, false);
    const isLegalStep = getStepType(step) === YesOrNoStepType.Legal;
    return (_jsx(SummaryStep, Object.assign({ active: active, secondary: secondary, showAllLink: showAllLink }, rest, { children: yesStats && noStats && (_jsxs(_Fragment, { children: [_jsx(ChoiceBar, { participants: (yesStats === null || yesStats === void 0 ? void 0 : yesStats.answerCount) ? yesStats.answerCount : 0, percent: (yesStats === null || yesStats === void 0 ? void 0 : yesStats.percentageChosen) ? yesStats.percentageChosen : 0, title: `Yes${isLegalStep ? ', I accept' : ''}` }, "yes"), _jsx(ChoiceBar, { participants: (noStats === null || noStats === void 0 ? void 0 : noStats.answerCount) ? noStats.answerCount : 0, percent: (noStats === null || noStats === void 0 ? void 0 : noStats.percentageChosen) ? noStats.percentageChosen : 0, title: `No${isLegalStep ? ", I don't accept" : ''}` }, "no")] })) })));
});
function getAnswerStats(stats, answer) {
    if (!stats) {
        return {
            answerCount: 0,
            percentageChosen: 0,
        };
    }
    const answerStats = stats.find((stat) => (stat === null || stat === void 0 ? void 0 : stat.answerValue) === answer);
    if (!answerStats) {
        return {
            answerCount: 0,
            percentageChosen: 0,
        };
    }
    return answerStats;
}
