import { useMutation } from '@apollo/client';
import update from 'immutability-helper';
import { showErrorNotification } from '../ToastContainer';
import { getAddCollaborators, getCollaborators, getCompanyMembers, queryMeta, } from './queries';
const toastId = 'addCollaborators';
export default function useAddCollaboratorsMutation(itemName, itemId) {
    const { addMemberMutation, memberEdgeAnnotation, addMemberMutationResponseType, optimisticSucceededResponse, getAddedCollaboratorsFromResponse, } = queryMeta[itemName];
    const [addCollaborators, ...rest] = useMutation(getAddCollaborators(itemName));
    return [
        ({ itemPk, collaborators }) => {
            const emails = collaborators.map(({ email }) => email);
            addCollaborators({
                variables: {
                    itemPk,
                    emails,
                },
                update: (cache, response) => {
                    const addedCollaborators = getAddedCollaboratorsFromResponse(collaborators, response.data[addMemberMutation].succeeded);
                    const addedEmails = addedCollaborators.map(({ email }) => email);
                    cacheUpdateCompanyMembers(cache, {
                        itemPk,
                        emails: addedEmails,
                        itemName,
                    }, (member) => (Object.assign(Object.assign({}, member), { [memberEdgeAnnotation]: true })));
                    cacheAddCollaboratorsToProject(cache, {
                        itemId,
                        // This needs to be a list of nodes
                        collaborators: addedCollaborators,
                        itemName,
                    });
                },
                optimisticResponse: {
                    [addMemberMutation]: {
                        __typename: addMemberMutationResponseType,
                        succeeded: collaborators.map(optimisticSucceededResponse),
                        failed: [],
                    },
                },
            })
                .then(({ data }) => {
                if (data[addMemberMutation].failed.length) {
                    showErrorNotification({
                        toastId,
                        content: `There was an issue trying to add ${data[addMemberMutation].failed[0].email} as a collaborator on this project. Please try again later`,
                    });
                }
            })
                .catch(() => {
                showErrorNotification({
                    toastId,
                    content: 'Oops, something went wrong. Try again later',
                });
            });
        },
        ...rest,
    ];
}
function cacheAddCollaboratorsToProject(cache, { itemId, collaborators, itemName }) {
    const { memberEdgeAnnotation, memberEdgeType } = queryMeta[itemName];
    const queryProps = {
        query: getCollaborators(itemName),
        variables: { itemId },
    };
    const data = cache.readQuery(queryProps);
    const updatedData = update(data, {
        [itemName]: {
            collaborators: {
                edges: {
                    $push: collaborators.map((collaborator) => ({
                        [memberEdgeAnnotation]: true,
                        node: Object.assign({ addedViaTeam: false }, collaborator),
                        __typename: memberEdgeType,
                    })),
                },
            },
        },
    });
    cache.writeQuery(Object.assign(Object.assign({}, queryProps), { data: updatedData }));
}
// updates the badge in the popover list
function cacheUpdateCompanyMembers(cache, { itemPk, itemName, emails }, updateFn) {
    const queryProps = {
        query: getCompanyMembers(itemName),
        variables: {
            itemPk,
        },
    };
    const data = cache.readQuery(queryProps);
    const updatedData = emails.reduce((acc, email) => {
        // - refactor this reduce() to make it easier to read plz
        const memberEdgeIndex = data.user.company.members.edges.findIndex((edge) => edge.node.email === email);
        return memberEdgeIndex === -1
            ? acc
            : update(acc, {
                user: {
                    company: {
                        members: {
                            edges: {
                                [memberEdgeIndex]: {
                                    $apply: updateFn,
                                },
                            },
                        },
                    },
                },
            });
    }, data);
    cache.writeQuery(Object.assign(Object.assign({}, queryProps), { data: updatedData }));
}
