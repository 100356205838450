import { jsx as _jsx } from "react/jsx-runtime";
import { cloneDeep, isEmpty, isEqual, xorBy } from 'lodash-es';
import { autorun, runInAction } from 'mobx';
import { observer, useLocalObservable } from 'mobx-react';
import * as React from 'react';
import { createContext, useContext, useEffect, useRef } from 'react';
import { useFeatureFlag } from '@marvelapp/ballpark-application';
import { EligibilityCountErrorEnum, StudyTypeEnum, } from '../../__generated__/queryTypes';
import { VIRTUALIZED_LIST_OPTION_COUNT_THRESHOLD, } from './types';
import { applySavedStudyOrderFilters, getDefaultStudyOrder, getStudyDuration, isSpecialFilterGroup, } from './useGetRecruitmentData';
import { useStoreSelectedOptionsMutation } from './useStoreSelectedOptionsMutation';
const RecruitmentStateContext = createContext(undefined);
export const studyTypeLabelMap = {
    [StudyTypeEnum.SURVEY]: 'Standard',
    [StudyTypeEnum.VIDEO]: 'Enhanced',
    [StudyTypeEnum.B2B]: 'Enhanced B2B',
};
export const useRecruitmentState = () => {
    const recorderState = useContext(RecruitmentStateContext);
    if (recorderState === undefined)
        throw new Error('useRecruitmentState used outside of Provider');
    return recorderState;
};
export const RecruitmentContextProvider = observer(function RecruitmentContextProvider({ children, estimatedTimeToComplete, recruitmentData, testUUID, }) {
    const canSetDurationManually = useFeatureFlag('manual-recruitment-duration');
    /* eslint-disable react/no-this-in-sfc */
    const recruitmentState = useLocalObservable(() => {
        const state = {
            currentBalance: recruitmentData.currentBalance,
            currentlyOpenDetailedFilter: '',
            currentlyOpenDetailedFilterGroup: '',
            duration: recruitmentData.duration,
            eligibilityDetails: recruitmentData.eligibilityDetails,
            estimatedTimeToComplete,
            hasSavedDraftOrder: recruitmentData.hasSavedDraft,
            hasValidationErrors: false,
            isUpdatingSelectedFilters: false,
            numberOfResponses: recruitmentData.numberOfResponses,
            savedDraftOrder: {
                filters: recruitmentData.selectedFilters,
                numParticipants: recruitmentData.numberOfResponses,
                studyDuration: recruitmentData.duration,
                studyType: recruitmentData.studyType,
            },
            savedUnsupportedFilters: recruitmentData.savedUnsupportedFilters,
            selectedFilters: recruitmentData.selectedFilters,
            storeSelectedOptionsError: null,
            studyType: recruitmentData.studyType,
            testUUID,
            get allowedDuration() {
                return recruitmentData.allowedDuration[this.studyType];
            },
            get allowedNumberOfResponses() {
                return recruitmentData.allowedNumberOfResponses[this.studyType];
            },
            get creditsPerMinute() {
                return recruitmentData.creditsPerMinute[this.studyType];
            },
            getCreditsPerMinuteByStudyType(studyType) {
                return recruitmentData.creditsPerMinute[studyType];
            },
            get filters() {
                switch (this.studyType) {
                    case StudyTypeEnum.VIDEO:
                        return recruitmentData.videoFilters;
                    case StudyTypeEnum.B2B:
                        return recruitmentData.b2bFilters;
                    case StudyTypeEnum.SURVEY:
                        return recruitmentData.standardFilters;
                    default:
                        throw Error('Study type not recognised');
                }
            },
            get hasEnoughEligibleParticipants() {
                var _a;
                // If not implemented, always assume there are enough participants
                if (((_a = this.eligibilityDetails) === null || _a === void 0 ? void 0 : _a.error) ===
                    EligibilityCountErrorEnum.NOT_IMPLEMENTED)
                    return true;
                // If there are no eligibility details or participants count,
                // something is wrong and we should assume there are not enough
                // participants
                if (!this.eligibilityDetails ||
                    this.eligibilityDetails.countParticipants === null)
                    return false;
                return (this.eligibilityDetails.countParticipants >= this.numberOfResponses);
            },
            get requiredFilters() {
                return this.filters.requiredFilters;
            },
            get selectedFiltersIds() {
                return Object.keys(this.selectedFilters);
            },
            updateCurrentlyOpenDetailedFilterGroup(filterGroupId) {
                this.currentlyOpenDetailedFilterGroup = filterGroupId;
                [this.currentlyOpenDetailedFilter] =
                    this.filters.other.filterGroups[filterGroupId].originalFilterOrder;
            },
            get balanceAfterOrder() {
                return this.currentBalance
                    ? this.currentBalance - this.creditsRequired
                    : null;
            },
            get creditsRequired() {
                return this.duration * this.numberOfResponses * this.creditsPerMinute;
            },
            get hasEnoughCredits() {
                return (!!this.currentBalance && this.currentBalance >= this.creditsRequired);
            },
            get specialFilters() {
                return Object.entries(this.filters.specialFilters).reduce((specialFilters, [_, value]) => {
                    specialFilters.push(...value.originalFilterOrder);
                    return specialFilters;
                }, []);
            },
            getSelectedOptionsByFilterAndGroupId(filterId, groupId, ignoreSupportedOptionsCheck = false) {
                var _a, _b;
                const savedSelectedOptions = (_b = (_a = this.selectedFilters[filterId]) === null || _a === void 0 ? void 0 : _a.options) !== null && _b !== void 0 ? _b : [];
                // For virtualized lists, not all options are loaded into local state
                // due to the number of options available. In this case, we assume that
                // if the filter is supported, the options are likely supported as well.
                if (ignoreSupportedOptionsCheck) {
                    return savedSelectedOptions;
                }
                const supportedOptions = this.getFilterByGroupAndId(groupId, filterId).optionIds;
                // Local state includes all of the selected options, it doesn't take into account
                // whether the option is supported by currently selected study type, which helps us
                // easily remember the previous selections when switching study types.
                // That's why we need to filter out any unsupported options to get correct count
                return savedSelectedOptions.filter((option) => supportedOptions === null || supportedOptions === void 0 ? void 0 : supportedOptions.includes(option.id));
            },
            get selectedStudySpecificFilters() {
                const nonStudySpecificFilterIds = this.filters.specialFilters.GROUP_DEFAULT.originalFilterOrder;
                const isFilterSupported = (filterId) => {
                    const inSavedUnsupportedFilters = Object.keys(this.savedUnsupportedFilters).includes(filterId);
                    return (
                    // Filter out any unsupported filters
                    !inSavedUnsupportedFilters ||
                        // Some filters are shared across different study types, but their options
                        // may not be. We need to check if any filters in `savedUnsupportedFilters`
                        // are available for the current study type. This indicates that while some
                        // the filter's options are not supported, the filter itself is.
                        (inSavedUnsupportedFilters &&
                            this.savedUnsupportedFilters[filterId].isAvailableForStudyType));
                };
                return Object.entries(this.selectedFilters).reduce((accumulatedFilters, [filterId, filterInfo]) => {
                    if (!isFilterSupported(filterId)) {
                        return accumulatedFilters;
                    }
                    const filter = filterInfo.groupId
                        ? this.getFilterByGroupAndId(filterInfo.groupId, filterId)
                        : null;
                    // For virtualized lists, not all options are loaded into local state
                    // due to the number of options available. In this case, we assume that
                    // if the filter is supported, the options are likely supported as well.
                    if (Number(filter === null || filter === void 0 ? void 0 : filter.optionCount) >
                        VIRTUALIZED_LIST_OPTION_COUNT_THRESHOLD) {
                        // eslint-disable-next-line no-param-reassign
                        accumulatedFilters[filterId] = filterInfo;
                    }
                    else if (!nonStudySpecificFilterIds.includes(filterId) &&
                        !isEmpty(filter)) {
                        const supportedOptionsIds = filter ? filter.optionIds : [];
                        const supportedOptions = filterInfo.options.filter((option) => supportedOptionsIds.includes(option.id));
                        // eslint-disable-next-line no-param-reassign
                        accumulatedFilters[filterId] = Object.assign(Object.assign({}, filterInfo), { options: supportedOptions });
                    }
                    return accumulatedFilters;
                }, {});
            },
            clear() {
                const { duration, numberOfResponses, selectedFilters, studyType } = getDefaultStudyOrder(canSetDurationManually ? 5 : this.estimatedTimeToComplete);
                this.duration = duration;
                this.numberOfResponses = numberOfResponses;
                this.selectedFilters = selectedFilters;
                this.studyType = studyType;
                this.savedUnsupportedFilters = {};
                this.hasValidationErrors = false;
            },
            getHasValidationErrorByFilterId(filterId) {
                return (this.hasValidationErrors &&
                    Object.keys(this.requiredFilters).includes(filterId) &&
                    !this.isFilterSelected(filterId));
            },
            getFilterByGroupAndId(filterGroupId, filterId) {
                if (!this.filters)
                    return {};
                if (isSpecialFilterGroup(filterGroupId) &&
                    this.filters.specialFilters[filterGroupId]) {
                    return this.filters.specialFilters[filterGroupId].filters[filterId];
                }
                const filterGroup = this.filters.other.filterGroups[filterGroupId];
                if (!filterGroup)
                    return {};
                return filterGroup.filters[filterId];
            },
            setLocalOrderStateToLastSavedOrder() {
                const { studyDuration, numParticipants, studyType, filters } = this.savedDraftOrder;
                // Only reset the state if it differs from what is saved
                if (studyDuration !== this.duration ||
                    numParticipants !== this.numberOfResponses ||
                    studyType !== this.studyType ||
                    !isEqual(filters, this.selectedFilters)) {
                    this.duration = this.savedDraftOrder.studyDuration;
                    this.numberOfResponses = this.savedDraftOrder.numParticipants;
                    this.studyType = this.savedDraftOrder.studyType;
                    // We need to clone deep here to avoid pointing this.selectedFilters
                    // to the same object as this.savedDraftOrder.filters. We just want to
                    // save it to the value of this.savedDraftOrder.filters, not the reference.
                    this.selectedFilters = cloneDeep(this.savedDraftOrder.filters);
                }
            },
            updateCustomValueFilterSelection(filter, option) {
                this.isUpdatingSelectedFilters = true;
                if (!this.selectedFilters[filter.id]) {
                    this.selectedFilters[filter.id] = {
                        groupId: option.groupId,
                        name: filter.name,
                        options: [option],
                    };
                }
                else {
                    const existingOptionIndex = this.selectedFilters[filter.id].options.findIndex((o) => o.id === option.id);
                    if (existingOptionIndex > -1) {
                        this.selectedFilters[filter.id].options[existingOptionIndex] =
                            option;
                    }
                    else {
                        this.selectedFilters[filter.id].options.push(option);
                    }
                }
            },
            updateMultiSelectFilterSelection(filter, option) {
                if (!filter.id)
                    return;
                this.isUpdatingSelectedFilters = true;
                const { id, name, maxSelection } = filter;
                const currentSelection = this.selectedFilters[id];
                if (!currentSelection) {
                    this.selectedFilters[id] = {
                        groupId: option.groupId,
                        name,
                        options: [option],
                    };
                    return;
                }
                const currentSelectionsCount = option.groupId
                    ? this.getSelectedOptionsByFilterAndGroupId(filter.id, option.groupId).length
                    : currentSelection.options.length;
                const isOptionSelected = currentSelection.options.some((currentOption) => currentOption.id === option.id);
                if (!maxSelection ||
                    currentSelectionsCount < maxSelection ||
                    // The maxSelection is reached, but the option is already selected,
                    // meaning we want to deselect it
                    (currentSelectionsCount === maxSelection && isOptionSelected)) {
                    const updatedOptions = xorBy(currentSelection.options, [option], 'id');
                    if (updatedOptions.length) {
                        this.selectedFilters[id].options = updatedOptions;
                    }
                    else {
                        delete this.selectedFilters[id];
                    }
                }
            },
            // For some of the filters, we only allow one selection, and each selection
            // overwrites the previous one. Country is an example of this.
            updateSingleSelectFilterSelection({ filterId, filterName, option }) {
                this.isUpdatingSelectedFilters = true;
                this.selectedFilters[filterId] = {
                    groupId: option.groupId,
                    name: filterName,
                    options: [option],
                };
            },
            updateSavedDraftOrder(storedOrder) {
                const { filters, numParticipants, studyDuration, studyType, eligibilityDetails, } = storedOrder;
                if (!filters || !numParticipants || !studyDuration || !studyType)
                    return;
                // if the study type is changing, update selected filters to make
                // sure filter groups match the new study type
                if (this.savedDraftOrder.studyType !== studyType) {
                    filters.forEach((storedFilter) => {
                        var _a;
                        if (!storedFilter)
                            return;
                        const { id, options } = storedFilter;
                        if (!(options === null || options === void 0 ? void 0 : options.edges))
                            return;
                        const firstOption = options.edges.find((edge) => {
                            var _a;
                            return (_a = edge === null || edge === void 0 ? void 0 : edge.node) === null || _a === void 0 ? void 0 : _a.groupId;
                        });
                        if (!((_a = firstOption === null || firstOption === void 0 ? void 0 : firstOption.node) === null || _a === void 0 ? void 0 : _a.groupId))
                            return;
                        this.selectedFilters[id].groupId = firstOption.node.groupId;
                    });
                }
                if (!isEqual(this.eligibilityDetails, eligibilityDetails)) {
                    this.eligibilityDetails = eligibilityDetails;
                }
                const latestSavedDraftOrder = {
                    filters: applySavedStudyOrderFilters(filters),
                    numParticipants,
                    studyDuration,
                    studyType,
                };
                if (!isEqual(this.savedDraftOrder, latestSavedDraftOrder)) {
                    this.savedDraftOrder = latestSavedDraftOrder;
                }
            },
            clearFilterById(filterId) {
                if (this.selectedFilters[filterId]) {
                    this.isUpdatingSelectedFilters = true;
                    delete this.selectedFilters[filterId];
                }
            },
            clearAllOptionsOfCurrentlyOpenDetailedFilter() {
                if (!this.currentlyOpenDetailedFilter)
                    return;
                this.isUpdatingSelectedFilters = true;
                delete this.selectedFilters[this.currentlyOpenDetailedFilter];
            },
            clearAllDetailedFilters() {
                this.isUpdatingSelectedFilters = true;
                this.selectedFilters =
                    this.selectedFiltersIds.reduce((updatedFilters, filterId) => {
                        // if this is not a detailed filter, include it in updated filters
                        if (this.specialFilters.includes(filterId)) {
                            // eslint-disable-next-line no-param-reassign
                            updatedFilters[filterId] = this.selectedFilters[filterId];
                        }
                        return updatedFilters;
                    }, {});
            },
            switchStudyType(studyType, savedUnsupportedFilters) {
                this.studyType = studyType;
                this.savedUnsupportedFilters = savedUnsupportedFilters;
                this.hasValidationErrors = false;
                const [filterGroupKey] = this.filters.other.originalFilterGroupOrder;
                this.updateCurrentlyOpenDetailedFilterGroup(filterGroupKey);
                const minAllowedDuration = recruitmentData.allowedDuration[studyType].min;
                this.duration = getStudyDuration({
                    canSetDurationManually,
                    estimatedTimeToComplete: this.estimatedTimeToComplete,
                    minAllowedDuration,
                    savedDuration: this.duration,
                    studyType,
                });
            },
            isFilterSelected(filterId) {
                const selectedFilter = this.selectedFilters[filterId];
                const unsupportedFilter = this.savedUnsupportedFilters[filterId];
                if (!selectedFilter)
                    return false;
                if (!unsupportedFilter)
                    return true;
                // If the filter is selected but exists in unsupported filters,
                // it means that some options might be unsupported. We need confirm
                // if at least one selected option is supported
                const selectedOptions = selectedFilter.options;
                const unsupportedOptions = unsupportedFilter.options;
                // Return true if at least one selected option is not in the unsupported options
                return selectedOptions.some((selectedOption) => {
                    return !unsupportedOptions.some((unsupportedOption) => unsupportedOption.id === selectedOption.id);
                });
            },
            getMissingRequiredFiltersIds() {
                return Object.keys(this.requiredFilters).reduce((missingRequiredFiltersIds, filterId) => {
                    if (!this.isFilterSelected(filterId)) {
                        missingRequiredFiltersIds.push(filterId);
                    }
                    return missingRequiredFiltersIds;
                }, []);
            },
        };
        return state;
    });
    /* eslint-enable react/no-this-in-sfc */
    const storeSelectedOptions = useStoreSelectedOptionsMutation(recruitmentState, testUUID);
    useEffect(() => {
        autorun(() => {
            const firstFilterGroup = recruitmentState.filters.other.originalFilterGroupOrder[0];
            const firstFilter = recruitmentState.filters.other.filterGroups[firstFilterGroup]
                .originalFilterOrder[0];
            recruitmentState.currentlyOpenDetailedFilter = firstFilter;
            recruitmentState.currentlyOpenDetailedFilterGroup = firstFilterGroup;
        });
    }, [recruitmentState]);
    const firstRun = useRef(true);
    useEffect(() => {
        let idle = true;
        let trailingCall = false;
        autorun(() => {
            const { duration, numberOfResponses, hasSavedDraftOrder, selectedFilters, studyType, } = recruitmentState;
            const filters = mapSelectedFiltersToVariableInputFormat(selectedFilters);
            if (!firstRun.current || !hasSavedDraftOrder) {
                storeSelectedOptions({
                    testUUID,
                    studyType,
                    studyDuration: duration,
                    numParticipants: numberOfResponses,
                    filters,
                });
            }
            firstRun.current = false;
        }, {
            // This is equivalent to running lodash's throttle,
            // i.e. invoking both on the trailing and leading edge
            scheduler: (f) => {
                if (idle) {
                    trailingCall = false;
                    idle = false;
                    f();
                    setTimeout(() => {
                        idle = true;
                        if (trailingCall) {
                            f();
                        }
                    }, 500);
                }
                else {
                    trailingCall = true;
                }
            },
        });
    }, [recruitmentState, storeSelectedOptions, testUUID]);
    useEffect(() => runInAction(() => {
        if (recruitmentData.currentBalance !== recruitmentState.currentBalance) {
            recruitmentState.currentBalance = recruitmentData.currentBalance;
        }
    }), [recruitmentData.currentBalance, recruitmentState]);
    useEffect(() => runInAction(() => {
        recruitmentState.estimatedTimeToComplete = estimatedTimeToComplete;
        recruitmentState.duration = getStudyDuration({
            canSetDurationManually,
            estimatedTimeToComplete,
            minAllowedDuration: recruitmentState.allowedDuration.min,
            savedDuration: recruitmentState.duration,
            studyType: recruitmentState.studyType,
        });
    }), [canSetDurationManually, estimatedTimeToComplete, recruitmentState]);
    return (_jsx(RecruitmentStateContext.Provider, { value: recruitmentState, children: children }));
});
function mapSelectedFiltersToVariableInputFormat(selectedFilters) {
    return Object.entries(selectedFilters).map(([filterId, { options }]) => ({
        id: filterId,
        options: options.map(({ id, customValue }) => {
            const selectedOption = {
                id,
            };
            if (customValue) {
                selectedOption.customValue = customValue;
            }
            return selectedOption;
        }),
    }));
}
