import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { gql, useQuery } from '@apollo/client';
import { proxyOptions } from '@ballpark/realtime-crdt-mobx';
import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { Redirect, useParams } from 'react-router-dom';
import { Icon, NotFound } from '@marvelapp/ballpark-components';
import { CLOSE_ICON } from '@marvelapp/ballpark-theme';
import { useLocationQueryParams } from '@marvelapp/hooks';
import styled from '@marvelapp/styled';
import { Box, CircleButton, Loader, PinTR } from '@marvelapp/ui';
import { UserTestResponseUtils, getOrderedListOfAllSteps, } from '@marvelapp/user-test-creator';
import VerifyPassword, { PasswordValidationStatus, } from '../components/PasswordVerification';
import { RecordWithLoading } from '../components/Record/Record';
import { DOES_NOT_EXIST } from '../graphql/errors';
import { getPageError, getPageErrorCopy } from '../utils/getPageErrorCopy';
export const PreviewVerificationWrapper = observer(function PreviewVerificationWrapper() {
    const params = useParams();
    const testUUID = `ut_${params.testId}`;
    // Note, we used to load the builder doc from RUS so it was up to date (the
    // SQL version lags the RUS version but only by a second or so, so it was
    // maybe an over-optimisation). However we saw problems with reconnecting to
    // RUS when tabbing away and back again. Hence we changed to loading the doc
    // from graphql
    const { doc: userTestDoc, isLoading: isLoadingUserTest, error, prototype, refetch, } = useLoadUserTestPreview(testUUID);
    const [validationState, setValidationState] = useState(PasswordValidationStatus.Ready);
    const [token, setToken] = useState(null);
    if (isLoadingUserTest) {
        return (_jsx(LoaderWrapper, { children: _jsx(Loader, {}) }));
    }
    const pageError = getPageError({
        data: { userTest: { prototype } },
        loading: isLoadingUserTest,
        error,
    }, DOES_NOT_EXIST);
    if (pageError) {
        return _jsx(NotFound, Object.assign({}, getPageErrorCopy(pageError)));
    }
    if (prototype === null || (prototype === null || prototype === void 0 ? void 0 : prototype.isLocked)) {
        return _jsx(Redirect, { to: `/projects/team` });
    }
    // If a project is password protected & a user from a different workspace tries to access it via Preview, BE will throw an auth error.
    // To rectify this, we show the password protection screen.
    // On successful submit, we get the auth token and refetch the usertest doc passing this token as an arg to satisfy BE's auth requirements.
    const requiresPasswordVerification = error === null || error === void 0 ? void 0 : error.message.includes('PERMISSION_DENIED');
    if (requiresPasswordVerification && !token && prototype) {
        return (_jsx(VerifyPassword, { projectId: prototype.shareId, validationState: validationState, setValidationState: setValidationState, setToken: setToken }));
    }
    if (requiresPasswordVerification && token) {
        refetch({ token, testUuid: testUUID, revision: 0 });
    }
    return _jsx(Preview, { userTestDoc: userTestDoc });
});
const Preview = observer(function Preview({ userTestDoc, }) {
    const { step } = useLocationQueryParams();
    // In preview mode you don't want to persist the response as it'll mess up your results.
    // This hook just creates an empty response doc that's not persisted to RUS/mySQL, it
    // only exits in memory.
    const { isLoading: isLoadingResponse, doc: response } = useInMemoryResponseDoc('preview', userTestDoc, step);
    return (_jsxs(_Fragment, { children: [_jsx(RecordWithLoading, { isLoading: isLoadingResponse, isPreview: true, response: response, userTestDoc: userTestDoc, downloadRecording: null }), _jsx(CollapsiblePreviewBanner, {})] }));
});
export const GET_USER_TEST_INFO = gql `
  query getUserTestDocAndPrototype(
    $testUuid: String!
    $revision: Int!
    $token: String
  ) {
    userTestDocument(uuid: $testUuid, revision: $revision, token: $token) {
      uuid
      revision
      document
    }
    userTest(uuid: $testUuid) {
      prototype {
        isArchived
        shareId
        isLocked
      }
    }
  }
`;
function useLoadUserTestPreview(testUUID, token) {
    var _a, _b;
    const { data, loading, error, refetch } = useQuery(GET_USER_TEST_INFO, {
        variables: { testUuid: testUUID, revision: 0, token },
        errorPolicy: 'all',
    });
    return {
        isLoading: loading,
        doc: ((_a = data === null || data === void 0 ? void 0 : data.userTestDocument) === null || _a === void 0 ? void 0 : _a.document)
            ? JSON.parse(data.userTestDocument.document)
            : undefined,
        prototype: (_b = data === null || data === void 0 ? void 0 : data.userTest) === null || _b === void 0 ? void 0 : _b.prototype,
        refetch,
        error,
    };
}
function useInMemoryResponseDoc(responseUUID, userTest, startingStepUUID) {
    const [doc, setDoc] = useState(null);
    useEffect(() => {
        var _a, _b;
        if (userTest && !doc) {
            const newDoc = UserTestResponseUtils.createResponseDoc(UserTestResponseUtils.create({ uuid: responseUUID }, userTest), proxyOptions);
            // uuid in URL may not have prefix attached so find the fully qualified UUID first
            newDoc.currentStepUUID =
                (_b = (_a = getOrderedListOfAllSteps({ userTest, withScreeners: true }).find((step) => step.uuid.includes(startingStepUUID))) === null || _a === void 0 ? void 0 : _a.uuid) !== null && _b !== void 0 ? _b : newDoc.currentStepUUID;
            setDoc(newDoc);
        }
    }, [doc, responseUUID, startingStepUUID, userTest]);
    return { doc, isLoading: !doc };
}
const LoaderWrapper = styled.div `
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;
// TODO: can we make this semi transparent instead of changing page padding when its open
const CollapsiblePreviewBanner = observer(function CollapsiblePreviewBanner() {
    const [isCollapsed, setIsCollapsed] = useState(false);
    if (isCollapsed)
        return null;
    return (_jsxs(PreviewBanner, { "data-testid": "preview-mode-banner", children: ["Preview mode: No answers will be saved.", _jsx(PinTR, { top: "50%", transform: "translateY(-50%)", paddingRight: "xs", children: _jsx(CircleButton, { kind: "white", marginLeft: "xs", onClick: () => setIsCollapsed(true), size: "xxs", children: _jsx(Icon, { paths: CLOSE_ICON }) }) })] }));
});
const PreviewBanner = styled(Box).attrs({
    bg: 'primary',
    color: 'background',
    fontSize: 's',
    fontWeight: 'medium',
    paddingX: 'l',
    truncated: true,
}) `
  width: 100%;
  position: fixed;
  text-align: center;
  top: 0;
  left: 0;
  height: 40px;
  line-height: 40px;

  display: flex;
  align-items: center;
  justify-content: center;
`;
