import { pull } from 'lodash-es';
import { StepClass } from '../userTest/types';
import { isOfType } from './stepResponseUtils';
export function getAnswer(response) {
    return response.answer.value;
}
export function updateChoices(mutable, uuid, isChecked, multipleSelectionMode) {
    if (isChecked) {
        pull(mutable.answer.value, uuid);
    }
    else {
        updateAnswer(mutable, uuid, multipleSelectionMode);
    }
}
export function clearChoices(mutable) {
    mutable.answer.value = [];
}
export function updateAnswer(mutable, choiceUUID, multipleSelectionMode, otherValue) {
    if (multipleSelectionMode) {
        // if multiple selection is enabled, add other option to selected list
        if (!mutable.answer.value.includes(choiceUUID)) {
            mutable.answer.value.push(choiceUUID);
        }
    }
    else {
        mutable.answer.value = [choiceUUID];
    }
    if (isOfType(mutable, StepClass.MultipleChoice) &&
        choiceUUID.includes('-other')) {
        mutable.answer.otherValue = otherValue;
    }
}
