import { jsx as _jsx } from "react/jsx-runtime";
import { observer } from 'mobx-react';
import * as React from 'react';
import { CircleButton, Icon } from '@marvelapp/ballpark-components';
import { PAUSE_ICON, PLAY_ICON } from '@marvelapp/ballpark-theme';
export default observer(function PlayButton({ isPlaying, onPause, onPlay, videoEnabled, }) {
    const commonProps = {
        kind: videoEnabled ? 'black' : 'white',
        width: 48,
        height: 48,
    };
    if (isPlaying) {
        return (_jsx(CircleButton, Object.assign({}, commonProps, { "data-testid": "pause", onClick: onPause, children: _jsx(Icon, { paths: PAUSE_ICON }) })));
    }
    return (_jsx(CircleButton, Object.assign({}, commonProps, { "data-testid": "play", onClick: onPlay, children: _jsx(Icon, { paths: PLAY_ICON }) })));
});
