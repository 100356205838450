import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import PropTypes from 'prop-types';
import { Fragment } from 'react';
import { AspectRatio, ContentLoader } from '@marvelapp/ui';
function CardLoader({ borderRadius, ratio }) {
    return (_jsxs(Fragment, { children: [_jsx(ContentLoader, { borderRadius: borderRadius, mb: 32, "data-testid": "screen-placeholder", children: _jsx(AspectRatio, { borderRadius: borderRadius, overflow: "hidden", ratio: ratio }) }), _jsx(ContentLoader, { borderRadius: 6, height: 12, m: "0 auto", mb: 3, width: "60%" }), _jsx(ContentLoader, { borderRadius: 6, height: 12, m: "0 auto", width: "80%" })] }));
}
CardLoader.propTypes = {
    borderRadius: PropTypes.any,
    ratio: PropTypes.any,
};
CardLoader.defaultProps = {
    borderRadius: 2,
};
export default CardLoader;
