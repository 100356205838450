import { jsx as _jsx } from "react/jsx-runtime";
import { observer } from 'mobx-react';
import PrototypeViewerWrapper from '../PrototypeViewer/PrototypeViewerWrapper';
import { usePrototypeState } from './PrototypeContext';
import { useRecorderState } from './RecorderContext';
const PreloadedPrototypeViewer = observer(function PreloadedPrototypeViewer() {
    const prototypeState = usePrototypeState();
    const { nextPrototypeTaskStep } = useRecorderState();
    const { prototypeUrl, stepResponse: nextPrototypeTaskStepResponse, prototypeKey, } = prototypeState;
    if (!prototypeUrl ||
        !nextPrototypeTaskStep ||
        !nextPrototypeTaskStepResponse ||
        !prototypeKey)
        return null;
    return (_jsx(PrototypeViewerWrapper
    // Note we used to use the step uuid as the key to get a new instance of
    // the prototype viewer but we now keep the player instance if the next
    // prototype is the same as the current one (for Figma prototypes)
    , { prototypeUrl: prototypeUrl, step: nextPrototypeTaskStep, stepResponse: nextPrototypeTaskStepResponse }, prototypeKey));
});
export default PreloadedPrototypeViewer;
