import { space, typography } from 'styled-system';
import styled from '@marvelapp/styled';
export const Ordered = styled.ol `
  ${space};
  ${typography};
  list-style-type: decimal;
  list-style-position: inside;
`;
export const Unordered = styled.ul `
  ${space};
  ${typography};
  list-style-type: disc;
  list-style-position: inside;
`;
export const Unstyled = styled.ul `
  ${space};
  ${typography};
  list-style-type: none;
`;
