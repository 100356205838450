import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { observer } from 'mobx-react';
import * as React from 'react';
import { ConditionsIcon, Icon, IconWithTooltip, } from '@marvelapp/ballpark-components';
import { AsteriskIcon } from '@marvelapp/ballpark-icons';
import { VIDEO_GUIDES_ICON } from '@marvelapp/ballpark-theme';
import styled from '@marvelapp/styled';
import { Box, Loader } from '@marvelapp/ui';
import { MediaUtils } from '@marvelapp/user-test-creator';
var StepSettingsIconStatus;
(function (StepSettingsIconStatus) {
    StepSettingsIconStatus["Disabled"] = "Disabled";
    StepSettingsIconStatus["Enabled"] = "Enabled";
    StepSettingsIconStatus["Failed"] = "Failed";
})(StepSettingsIconStatus || (StepSettingsIconStatus = {}));
// This component is used in a few places, some of which don't use
// context yet. Hence why we still need to use props if `step` is not available
export const StepSettingsIcons = observer(function StepSettingsIcons({ videoGuideStatus, isStepRequired, hasConditions, }) {
    const { conditionalLogic, isLoading, guides, required } = getSlideRowIcons({
        videoGuideStatus,
        isStepRequired,
        hasConditions,
    });
    if (isLoading) {
        return (_jsx(LoaderWrapper, { children: _jsx(IconTooltip, { content: "Media processing...", children: _jsx(Loader, { speed: 1000, borderWidth: 3, size: 13 }) }) }));
    }
    return (_jsxs(Icons, { children: [_jsx(ConditionalLogic, { status: conditionalLogic }), _jsx(Guides, { status: guides }), _jsx(Required, { status: required })] }));
});
const ConditionalLogic = observer(function ConditionalLogic({ status, }) {
    switch (status) {
        case StepSettingsIconStatus.Enabled:
            return (_jsx(IconTooltip, { content: "This step has conditional logic rules defined", children: _jsx(ConditionsIcon, { height: 12, width: 12 }) }));
        // For the video guides icon we don't have use cases for these other states yet
        case StepSettingsIconStatus.Disabled:
        case StepSettingsIconStatus.Failed:
        default:
            return null;
    }
});
const Guides = observer(function Guides({ status, }) {
    switch (status) {
        case StepSettingsIconStatus.Enabled:
            return (_jsx(IconTooltip, { content: "Video guide enabled", children: _jsx(Icon, { width: 10, height: 10, paths: VIDEO_GUIDES_ICON, viewBox: "0 0 14 14" }) }));
        // For the video guides icon we don't have use cases for these other states yet
        case StepSettingsIconStatus.Disabled:
        case StepSettingsIconStatus.Failed:
        default:
            return null;
    }
});
const Required = observer(function Required({ status, }) {
    switch (status) {
        case StepSettingsIconStatus.Enabled:
            return (_jsx(IconTooltip, { content: "Required step", children: _jsx(AsteriskIcon, { "data-testid": "required-icon", className: "h-2.5 w-2.5 text-red-700" }) }));
        // For the required icon we don't have use cases for these other states yet
        case StepSettingsIconStatus.Disabled:
        case StepSettingsIconStatus.Failed:
        default:
            return null;
    }
});
const IconTooltip = observer(function IconTooltip({ children, content, }) {
    return (_jsx(IconWithTooltip, { pr: "xxs", lastChildPr: 0, content: content, children: children }));
});
function getSlideRowIcons({ hasConditions, videoGuideStatus, isStepRequired, }) {
    return {
        required: isStepRequired ? StepSettingsIconStatus.Enabled : undefined,
        guides: videoGuideStatus === MediaUtils.RecordingStatus.Ready
            ? StepSettingsIconStatus.Enabled
            : undefined,
        conditionalLogic: hasConditions
            ? StepSettingsIconStatus.Enabled
            : undefined,
    };
}
/* Styles
 */
const Icons = styled(Box) `
  display: inline;
  align-items: center;
  pointer-events: all;
`;
const LoaderWrapper = styled(Box) `
  display: inline;
  > div {
    display: inline-block;
  }
`;
