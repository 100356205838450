import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { action } from 'mobx';
import { observer } from 'mobx-react';
import { useState } from 'react';
import * as React from 'react';
import { useIntl } from 'react-intl';
import TextareaAutosize from 'react-textarea-autosize';
import { useGetUserTest, useUserTestAction, } from '@marvelapp/ballpark-application';
import { UI_STEPS_COPY } from '@marvelapp/ballpark-copy';
import styled from '@marvelapp/styled';
import { AspectRatio, Box, inputStyles } from '@marvelapp/ui';
import { ChoiceConditionalUtils, PreferenceStepUtils, StepClass, } from '@marvelapp/user-test-creator';
import { useStateFromProp } from '../hooks/useStateFromProp';
import ChoiceLogicDeleteModal from './ChoiceLogicDeleteModal';
import DraggablePreferenceItem from './DraggablePreferenceItem';
export default observer(function EditablePreferenceCard({ choiceUUID, index, src, src2x, step, }) {
    const intl = useIntl();
    const userTest = useGetUserTest();
    const value = PreferenceStepUtils.getChoiceTitle(userTest, step === null || step === void 0 ? void 0 : step.uuid, choiceUUID);
    const [internalValue, setInternalValue] = useStateFromProp(value || '');
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const onChange = useUserTestAction((event) => {
        setInternalValue(event.target.value);
        PreferenceStepUtils.setChoiceTitle(step, index, event.target.value);
    }, [index, step]);
    const checkLogicAndRemoveChoice = useUserTestAction(() => {
        const hasLogicSetOnChoice = ChoiceConditionalUtils.getIsChoiceConditional(step, choiceUUID);
        if (hasLogicSetOnChoice) {
            setShowDeleteModal(true);
        }
        else {
            PreferenceStepUtils.removeChoice(step, choiceUUID);
        }
    }, [choiceUUID, step]);
    const confirmDelete = useUserTestAction(() => {
        ChoiceConditionalUtils.deleteRulesForDeletedChoice(step, choiceUUID);
        PreferenceStepUtils.removeChoice(step, choiceUUID);
        setShowDeleteModal(false);
    }, [step, choiceUUID]);
    return (_jsxs(_Fragment, { children: [_jsxs(DraggablePreferenceItem, { id: `card-${choiceUUID}`, onDelete: checkLogicAndRemoveChoice, index: index, children: [_jsx(Ratio, { children: _jsx(RatioInner, { children: _jsx(Image, { alt: "Placeholder", src: src, srcSet: `${src2x} 2x` }) }) }), _jsx(Input, { value: internalValue, onChange: action(onChange), placeholder: intl.formatMessage(UI_STEPS_COPY[StepClass.PreferenceTest].optionPlaceholder), "data-testid": `preference-test-choice-title` })] }), showDeleteModal && (_jsx(ChoiceLogicDeleteModal, { confirmDelete: confirmDelete, trigger: () => setShowDeleteModal(false) }))] }));
});
const Ratio = styled(AspectRatio).attrs({
    ratio: 1 / 1,
    marginBottom: 's',
    width: '100%',
}) `
  pointer-events: none;
`;
const RatioInner = styled(Box) `
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const Image = styled.img `
  display: block;
  max-width: 100%;
  max-height: 100%;
  box-shadow: ${(props) => props.theme.shadows.button.secondary};
`;
const Input = styled(TextareaAutosize).attrs({
    bg: 'transparent',
    border: 'none',
    color: 'slate12',
    fontFamily: 0,
    fontWeight: 'medium',
    lineHeight: 'heading',
    placeholderColor: 'slate10',
    px: 'xs',
}) `
  ${inputStyles};
  transition: none;
  position: relative;
  text-align: center;
  resize: none;
  width: 100%;
`;
