import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { CHECKBOX_TICK_ICON } from '@marvelapp/ballpark-theme';
import { Box, Flex, Icon, Text } from '@marvelapp/ui';
import IconCircle from '../IconCircle';
export default function SuccessText({ alignItems, bg, borderColor, children, color, fontSize, iconColor, }) {
    return (_jsxs(Flex, { alignItems: alignItems, "data-testid": "success-toast", children: [_jsx(Box, { mr: 12, children: _jsx(IconCircle, { bg: bg, borderColor: borderColor, color: iconColor || color, children: _jsx(Icon, { display: "block", paths: CHECKBOX_TICK_ICON }) }) }), _jsx(Flex, { alignItems: "center", minHeight: "26px", children: _jsx(Text, { color: color, fontSize: fontSize, children: children }) })] }));
}
SuccessText.defaultProps = {
    alignItems: 'flex-start',
    color: 'greenDark',
    fontSize: 14,
};
