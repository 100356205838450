// TODO clearInvalidConditions, getNextStepUUID, and countValidRules all have
// similar logic so they could probably be tidied up somewhat.
// eg getNextStepUUID could clone the rules and call clearInvalidConditions so
// it knows it's only dealing with valid rules
// and countValidRules could call clearInvalidConditions and then count the
// remaining rules
export function clearInvalidConditions(mutable, validateRequirement) {
    if (
    // TODO can I use isMultiConditionable here?
    'conditions' in mutable &&
        mutable.conditions &&
        'rules' in mutable.conditions &&
        mutable.conditions.rules) {
        const { rules } = mutable.conditions;
        const invalidRules = [];
        rules.forEach((rule, ruleIndex) => {
            const { requirements } = rule;
            if (!isValidRule(rule)) {
                invalidRules.push(ruleIndex);
                return;
            }
            const invalidRequirements = []; // using forEach and push as TS doesn't like .filter here
            requirements.forEach((requirement, requirementIndex) => {
                if (!validateRequirement(requirement)) {
                    invalidRequirements.push(requirementIndex);
                }
            });
            deleteFromArray(requirements, invalidRequirements);
            if (requirements.length === 0) {
                invalidRules.push(ruleIndex);
            }
        });
        deleteFromArray(rules, invalidRules);
    }
}
function deleteFromArray(array, indexes) {
    indexes.forEach((indexToDelete, index) => {
        array.splice(indexToDelete - index, 1);
    });
}
export function countValidRules(rules) {
    let ruleCount = 0;
    rules.forEach((rule) => {
        if (!isValidRule(rule))
            return;
        const { requirements } = rule;
        const hasAtLeastOneValidRequirement = requirements.some((requirement) => isValidRequirement(requirement));
        if (hasAtLeastOneValidRequirement) {
            ruleCount += 1;
        }
    });
    return ruleCount;
}
export function isValidRule(rule) {
    return rule.requirements.length > 0 && rule.destinationStepUUID !== null;
}
export function isValidRequirement(requirement) {
    if ('value' in requirement) {
        if (typeof requirement.value === 'string') {
            return requirement.value !== '';
        }
        return requirement.value !== null;
    }
    return requirement.selectedChoiceUUID !== null;
}
