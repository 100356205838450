import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { FragmentUtils, MatrixUtils, PointUtils, RasterUtils, RectangleUtils, createEmptyDesign, } from '@ballpark/design-creator';
import { DotGrid } from '@ballpark/design-plugin-grid';
import { DesignProvider, useDesign } from '@ballpark/design-plugin-react';
import { Scene, ViewProvider, useView } from '@ballpark/design-plugin-view';
import { ComponentOverridesProvider, Design, } from '@ballpark/design-svg-renderer';
import { observer } from 'mobx-react';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import * as React from 'react';
import { Loader } from '@marvelapp/ui';
// eslint-disable-next-line mobx/missing-observer
function Artboard() {
    return null;
}
const imageId = 'image';
// how much padding to add to the image when fitting it to the view
const padding = 20;
const ImageViewerScene = observer(function ImageViewerScene({ imageDimensions, }) {
    const view = useView();
    const design = useDesign();
    const positionView = useCallback(() => {
        var _a, _b;
        // add padding to the view to ensure the image can open with some padding
        const paddedViewSize = RectangleUtils.expand({ point: [0, 0], size: view.size }, -2 * padding).size;
        const targetYScale = paddedViewSize[1] / imageDimensions[1];
        const isLongerThanView = targetYScale < 1;
        if (!isLongerThanView)
            return;
        const imageOffset = (_b = (_a = design.getBounds(imageId, true)) === null || _a === void 0 ? void 0 : _a.point) !== null && _b !== void 0 ? _b : [0, 0];
        const targetXScale = paddedViewSize[0] / imageDimensions[0];
        // zoom the image to fit horizontally but don't zoom greater than 100%
        const zoomFactor = Math.min(targetXScale, 1);
        const center = [
            imageOffset[0] + imageDimensions[0] / 2,
            imageOffset[1],
        ];
        // first set the zoom factor back to 1
        view.setZoomFactor(1);
        // then position the view so it is centered horizontally against the image and the top of the image aligns with the top of the image - padding
        view.panToPoint([center[0], center[1] + view.size[1] / 2 - padding]);
        // now zoom the image to fit horizontally (but not greater than 100%) maintaining the top of the image and the horizontal center point
        view.setZoomFactor(zoomFactor, {
            center,
        });
    }, [design, imageDimensions, view]);
    return (_jsxs(Scene, { dragToPan: false, onViewInitialized: positionView, constrainZoomToFit: false, forgetLastView: true, children: [_jsx(DotGrid, {}), _jsx(Design, {}), _jsx(TouchPanAndZoom, { imageWidth: imageDimensions[0], imageHeight: imageDimensions[1] })] }));
});
const ImageViewer = observer(function ImageViewer({ imageUrl, }) {
    const [imageDimensions, setImageDimensions] = useState(null);
    // preload image to get the width and height
    useEffect(() => {
        const img = new Image();
        img.onload = () => {
            setImageDimensions([img.width, img.height]);
        };
        img.src = imageUrl;
    }, [imageUrl]);
    const design = useMemo(() => imageDimensions && getDesign(imageUrl, imageDimensions), [imageDimensions, imageUrl]);
    if (!imageDimensions)
        return _jsx(Loader, {});
    if (!design)
        return null;
    return (_jsx(ComponentOverridesProvider, { components: { Artboard }, children: _jsx(DesignProvider, { design: design, children: _jsx(ViewProvider, { children: _jsx(ImageViewerScene, { imageDimensions: imageDimensions }) }) }) }));
});
export { ImageViewer };
function getDesign(imageUrl, imageDimensions) {
    const doc = createEmptyDesign();
    const image = RasterUtils.create({
        id: imageId,
        source: imageUrl,
        size: imageDimensions,
    });
    FragmentUtils.mAppendItems(doc, [image]);
    return doc;
}
const TouchPanAndZoom = observer(function TouchPanAndZoom({ imageWidth, imageHeight, }) {
    const view = useView();
    const prevDistance = useRef(0);
    const startPoint = useRef([0, 0]);
    const hasFitImage = useRef(false);
    // We need to track which gesture is currently active as Android fires _two_
    // TouchStart events when you start a pinch gesture. We need to ignore the
    // first single touch event otherwise we'll trigger a pan gesture when the
    // zoom is done.
    const gesture = useRef(null);
    useEffect(() => {
        // Fit image to view on mount
        // design-sdk does this it but it fits to one of the fixed zoom levels which
        // is not what we want. We want to fit the image exactly to the view so it
        // starts off as big as possible.
        if (hasFitImage.current)
            return;
        const [w, h] = view.bounds.size;
        const [iw, ih] = [imageWidth, imageHeight];
        if (!w || !h || !iw || !ih)
            return;
        const { zoomFactor } = view;
        const actualWidth = w * zoomFactor;
        const actualHeight = h * zoomFactor;
        const scale = Math.min(actualWidth / iw, actualHeight / ih);
        view.setZoomFactor(scale);
        hasFitImage.current = true;
    }, [imageHeight, imageWidth, view, view.bounds.size]);
    const zoom = useCallback((start, distance) => {
        const zoomDelta = distance * 0.005;
        const delta = 1 + zoomDelta;
        const offsetX = start[0]; // + sceneOffsetRef.current[0];
        const offsetY = start[1]; // + sceneOffsetRef.current[1];
        const center = PointUtils.transform([offsetX, offsetY], MatrixUtils.invert(view.matrix));
        const cappedFactor = Math.max(view.zoomFactor * delta, 0.2);
        view.setZoomFactor(cappedFactor, { center });
    }, [view]);
    const pan = useCallback((x, y) => {
        view.pan([x, y]);
    }, [view]);
    const onTouchStart = useCallback((event) => {
        event.preventDefault();
        if (event.touches.length === 1) {
            gesture.current = 'pan';
            startPoint.current = [
                event.touches[0].clientX,
                event.touches[0].clientY,
            ];
            return;
        }
        if (!event.touches || event.touches.length !== 2)
            return;
        gesture.current = 'zoom';
        // get the initial distance between the two touch points
        const touch1 = event.touches[0];
        const touch2 = event.touches[1];
        prevDistance.current = getDistance(touch1, touch2);
        // calculate midpoint between the two touch points and center the zoom at
        // that point
        startPoint.current = getMidPoint(touch1, touch2);
    }, []);
    const onTouchMove = useCallback((event) => {
        if (event.touches.length === 1 && gesture.current === 'pan') {
            // single finger drag so pan the view
            const deltaX = event.touches[0].clientX - startPoint.current[0];
            const deltaY = event.touches[0].clientY - startPoint.current[1];
            startPoint.current = [
                event.touches[0].clientX,
                event.touches[0].clientY,
            ];
            pan(deltaX, deltaY);
            return;
        }
        // calculate the distance between the two touch points on every touchmove event
        const touch1 = event.touches[0];
        const touch2 = event.touches[1];
        const currentDistance = getDistance(touch1, touch2);
        // check if the distance between the touch points has increased
        // or decreased by a certain amount to determine if a pinch gesture is happening
        if (currentDistance > prevDistance.current) {
            // a pinch-out gesture is happening
            zoom(startPoint.current, currentDistance - prevDistance.current);
        }
        if (currentDistance < prevDistance.current) {
            // a pinch-in gesture is happening
            zoom(startPoint.current, currentDistance - prevDistance.current);
        }
        prevDistance.current = currentDistance;
    }, [pan, zoom]);
    return (_jsx("div", { "data-testid": "pinch-zoom", style: {
            width: '100%',
            height: '100%',
            touchAction: 'none',
            inset: 0,
            position: 'absolute',
        }, onTouchStart: onTouchStart, onTouchMove: onTouchMove }));
});
// helper function to calculate the distance between two touch points
function getDistance(touch1, touch2) {
    const xDiff = touch1.clientX - touch2.clientX;
    const yDiff = touch1.clientY - touch2.clientY;
    return Math.sqrt(xDiff * xDiff + yDiff * yDiff);
}
// helper function to calculate the midpoint between two touch points
function getMidPoint(touch1, touch2) {
    const x = (touch1.clientX + touch2.clientX) / 2;
    const y = (touch1.clientY + touch2.clientY) / 2;
    return [x, y];
}
