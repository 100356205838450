import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import * as React from 'react';
import theme from '@marvelapp/ballpark-theme';
import { Svg } from '@marvelapp/ui';
// eslint-disable-next-line mobx/missing-observer
export default function Thumbs({ height = '42', rating, width = '42', }) {
    const gradientId = `fill-thumbs-${rating}`;
    let fillRule = `url(#${gradientId})`;
    const percentage = rating * 100;
    let percentageCutOff = 20;
    // Not filled
    if (rating === 0) {
        fillRule = 'nonzero';
    }
    // If filled
    if (rating === 1) {
        // don't substract, fill all
        percentageCutOff = 0;
    }
    let remainingPercentage = 0;
    if (percentage > percentageCutOff) {
        remainingPercentage = percentage - percentageCutOff;
    }
    return (_jsxs(Svg, { height: height, viewBox: "0 0 42 42", width: width, children: [rating !== 0 && (_jsx("defs", { children: _jsxs("linearGradient", { id: gradientId, children: [_jsx("stop", { offset: `${remainingPercentage}%`, stopColor: "currentColor" }), _jsx("stop", { offset: `${remainingPercentage}%`, stopColor: theme.colors.slate8 })] }) })), _jsx("path", { d: "m35.7 21.2125c.1562-.5136.1899-1.0567.0984-1.5857s-.3056-1.0292-.6251-1.4606c-.3196-.4313-.7358-.7819-1.2152-1.0235-.4794-.2415-1.0088-.3675-1.5456-.3677h-6.975c-.2697-.0005-.5344-.0725-.7671-.2086-.2328-.1362-.4253-.3316-.5579-.5664-.1428-.2236-.2258-.48-.2411-.7448-.0154-.2649.0375-.5292.1536-.7677l1.7875-3.75c.1612-.4868.1976-1.00623.1059-1.51073-.0917-.50451-.3087-.9779-.6309-1.37677-.2973-.34348-.6663-.61771-1.0809-.80342s-.8648-.27841-1.3191-.27158c-.486-.00497-.9662.1058-1.4009.32316-.4347.21735-.8115.53506-1.0991.92684l-6.125 8.7125c-.1408.2017-.3276.367-.545.4822s-.459.177-.705.1803c-.0766 0-.1524.0153-.223.0449-.0706.0297-.1345.0732-.1881.1279-.0535.0547-.0956.1196-.1238.1908-.0281.0712-.0418.1473-.0401.2239v14.1375c.0012.1168.0345.2311.0962.3303s.1495.1795.2538.2322c4.625 2.2375 6.1625 2.5 9.925 2.5h3.75c3.35 0 5.575-1.95 6.825-5.975 2.7125-8.975 2.3875-7.9 2.4125-8z", fill: fillRule }), _jsx("path", { d: "m10.5375 17.4c-.0746-.4414-.3046-.8415-.64846-1.1281-.34388-.2866-.77895-.4407-1.22654-.4344h-1.875c-.16576 0-.32473.0658-.44194.1831-.11721.1172-.18306.2761-.18306.4419v17.5c0 .1658.06585.3247.18306.4419s.27618.1831.44194.1831h1.875c.49728 0 .9742-.1975 1.32583-.5492.35167-.3516.54917-.8285.54917-1.3258z" })] }));
}
