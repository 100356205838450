import { __rest } from "tslib";
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { observer } from 'mobx-react';
import * as React from 'react';
import { Button } from '@marvelapp/ballpark-components';
import { Box } from '@marvelapp/ui';
export default observer(function StopButton(_a) {
    var { onClick, children } = _a, rest = __rest(_a, ["onClick", "children"]);
    return (_jsxs(Button, Object.assign({ alignItems: "center", display: "flex", kind: "negative", onClick: onClick, size: "s" }, rest, { children: [_jsx(Box, { bg: "background", borderRadius: "2px", height: 8, marginRight: "8px", width: 8 }), children] })));
});
