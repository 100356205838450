import { Frame } from './frames';
import { Orientation } from './orientation';
export function getAspectRatio(frame, orientation) {
    let ratio;
    switch (frame) {
        case Frame.AppleWatch42:
            // We don't allow landscape for Apple Watch
            return 4 / 5;
        case Frame.IPad:
            ratio = 3 / 4;
            break;
        case Frame.IPadPro11:
            ratio = 3 / 4.3;
            break;
        case Frame.IPhone4S:
            ratio = 2 / 3;
            break;
        case Frame.IPhone5:
        case Frame.IPhone5C:
        case Frame.IPhone6Plus:
        case Frame.Lumia920:
        case Frame.Nexus5:
            ratio = 9 / 16;
            break;
        case Frame.Pixel4:
            ratio = 9 / 19;
            break;
        case Frame.IPhone6:
            ratio = 375 / 667;
            break;
        case Frame.IPhoneX:
            ratio = 375 / 812;
            break;
        case Frame.Web:
            // Portrait and landscape should be same on web projects
            return 4 / 3;
        default:
            throw new Error(`No such frame: ${frame}`);
    }
    if (orientation === Orientation.Portrait)
        return ratio;
    // Flip it round for landscape setting
    return 1 / ratio;
}
