import { __rest } from "tslib";
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { capitalize } from 'lodash-es';
import { observer } from 'mobx-react';
import { BtwTooltip } from '@marvelapp/ballpark-components';
import styled from '@marvelapp/styled';
import { Flex } from '@marvelapp/ui';
import { StepRecordingPermission, } from '@marvelapp/user-test-creator';
const DeviceRecordingStatus = observer(function DeviceRecordingStatus(_a) {
    var { margin = '24px', permissions, testId } = _a, props = __rest(_a, ["margin", "permissions", "testId"]);
    if (isHidden(permissions)) {
        return null;
    }
    return (_jsxs(Flex, Object.assign({ direction: "row", "data-testid": testId }, props, { children: [_jsx(DeviceStatus, { testId: "camera", name: "camera", permission: permissions.webcam, margin: margin }), _jsx(DeviceStatus, { testId: "microphone", name: "microphone", permission: permissions.microphone, margin: margin }), _jsx(DeviceStatus, { testId: "screen", name: "screen", permission: permissions.screen, margin: margin })] })));
});
const DeviceStatus = observer(function DeviceStatus({ name, permission, margin, testId }) {
    if (permission === StepRecordingPermission.NotRequested)
        return null;
    return (_jsxs(DeviceStatusContainer, { margin: margin, children: [capitalize(name), ":", ' ', _jsx(IconTooltip, { testId: testId, icon: getIcon(permission), text: getDescription(permission, name) })] }));
});
function isHidden(permissions) {
    return Object.values(permissions).every((val) => val === StepRecordingPermission.Granted ||
        val === StepRecordingPermission.NotRequested);
}
function getIcon(permission) {
    switch (permission) {
        case StepRecordingPermission.Denied:
        case StepRecordingPermission.NotSupported:
            return '⚠️';
        case StepRecordingPermission.Granted:
            return '✅';
        default:
            return undefined;
    }
}
function getDescription(permission, device) {
    switch (permission) {
        case StepRecordingPermission.Denied:
            return `The participant denied access or blocked the request to share their ${device}`;
        case StepRecordingPermission.NotSupported:
            if (device === 'camera' || device === 'microphone') {
                return `The participant's device did not have a ${device} or is unable to support using a ${device}`;
            }
            return "The participant's device did not have screen sharing functionality or is unable to support that feature";
        case StepRecordingPermission.Granted:
            return `The participant granted access to share their ${device}`;
        default:
            return undefined;
    }
}
const Icon = styled.span `
  font-family: sans-serif !important;
`;
const DeviceStatusContainer = styled.span `
  & + & {
    margin-left: ${(props) => props.margin};
  }
`;
const IconTooltip = observer(function IconTooltip({ text, icon, testId }) {
    return (_jsxs(BtwTooltip.Root, { children: [_jsx(BtwTooltip.Trigger, { asChild: true, children: _jsx(Icon, { "data-testid": `${testId}-status-trigger`, children: icon }) }), _jsx(BtwTooltip.Portal, { children: _jsx(BtwTooltip.Content, { align: "end", className: "max-w-[260px]", "data-testid": `${testId}-status-content`, side: "bottom", children: text }) })] }));
});
export { isHidden };
export default DeviceRecordingStatus;
