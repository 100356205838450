import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { observer } from 'mobx-react';
import * as React from 'react';
import styled from '@marvelapp/styled';
import { Box } from '@marvelapp/ui';
import { ProjectBar } from './Project/ProjectBar';
export const PageWithProjectBar = observer(function PageWithProjectBar({ children, }) {
    return (_jsxs(_Fragment, { children: [_jsx(Sticky, { children: _jsx(ProjectBar, {}) }), children] }));
});
const Sticky = styled(Box).attrs({
    zIndex: 'navigationBar',
}) `
  position: sticky;
  top: 0;
`;
