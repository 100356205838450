import { __rest } from "tslib";
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useResponsiveValue } from '@theme-ui/match-media';
import { observer } from 'mobx-react';
import { useState } from 'react';
import * as React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { BtwMessage, BtwText, Button, InfoBlock, Link, Stack, Strong, Text, } from '@marvelapp/ballpark-components';
import { SUBMIT_STEP_COPY, UI_COMMON_STEPS_COPY, UI_STEPS_COPY, } from '@marvelapp/ballpark-copy';
import { deviceDetection } from '@marvelapp/core';
import { useLocationQueryParams } from '@marvelapp/hooks';
import styled from '@marvelapp/styled';
import { Box, Modal, PinBL } from '@marvelapp/ui';
import { FixedStep, StepClass, getStudyById, isExitStep, isSubmitStep, isWelcomeStep, } from '@marvelapp/user-test-creator';
import { ImageViewer } from '../../../components/ImageViewer';
import { useRecorderState } from '../../../components/Record/RecorderContext';
import ZoomImageModal from '../../../components/ZoomImageModal';
import { MAX_DECORATIVE_IMAGE_DIMENSIONS_DESKTOP, MAX_DECORATIVE_IMAGE_DIMENSIONS_IN_REPORTING_ZOOMED, MAX_DECORATIVE_IMAGE_DIMENSIONS_MOBILE, MAX_DECORATIVE_IMAGE_DIMENSIONS_MOBILE_ZOOMED, } from '../../../constants/image';
import { ProlificLogo } from '../../../icons/ProlificLogo';
import { getOptimisedContainedImageUrl } from '../../../utils/image';
import ContainedImage from '../../Instruction/ContainedImage';
import FloatingButton from '../FloatingButton';
import SimpleSlideTemplate from '../SimpleSlideTemplate';
import DecoratedSlide from '../recorder/DecoratedSlide';
import { SlideRecordContent, } from './SlideRecordContent';
export default observer(function SlideRecord(_a) {
    var _b, _c, _d, _e;
    var { isControlledStep, maxWidth, step } = _a, contentProps = __rest(_a, ["isControlledStep", "maxWidth", "step"]);
    const paddingTop = ['m', 'm', 'l']; // Preview bar height
    // Switching between the two templates as I can't see a way to have the layout
    // adapt as we want using responsive styles in the one component. On a wide enough screen
    // we have a left colukn and right column and the left column can scroll. On mobile we
    // only have a single column with the rigth 'column' content below the left 'column' content.
    const isNarrow = useResponsiveValue([true, true, true, true, false]);
    const imageUrl = (_b = step.decorativeImage) === null || _b === void 0 ? void 0 : _b.url;
    if (((_c = step.decorativeImage) === null || _c === void 0 ? void 0 : _c.url) && !isNarrow) {
        const optimisedUrl = getOptimisedContainedImageUrl((_d = step.decorativeImage) === null || _d === void 0 ? void 0 : _d.url, MAX_DECORATIVE_IMAGE_DIMENSIONS_DESKTOP);
        const optimisedZoomedUrl = getOptimisedContainedImageUrl((_e = step.decorativeImage) === null || _e === void 0 ? void 0 : _e.url, MAX_DECORATIVE_IMAGE_DIMENSIONS_IN_REPORTING_ZOOMED);
        return (_jsx(DecoratedSlide, { paddingTop: paddingTop, maxWidth: maxWidth, imageUrl: optimisedUrl, step: step, zoomedImageUrl: optimisedZoomedUrl, children: _jsx(SlideRecordContentWithImage, Object.assign({}, contentProps, { step: step })) }));
    }
    if (isControlledStep) {
        return _jsx(_Fragment, { children: contentProps.children });
    }
    return (_jsx(SimpleSlideTemplate, { paddingTop: paddingTop, maxWidth: maxWidth, children: _jsx(SlideRecordContentWithImage, Object.assign({}, contentProps, { imageUrl: imageUrl, isNarrow: isNarrow, step: step })) }));
});
const SlideRecordContentWithImage = observer(function SlideRecordContentWithImage({ button, children, imageUrl, isNarrow, step, withSlideBadges, customDescription, customTitle, }) {
    const optimisedMobileZoomedUrl = imageUrl &&
        getOptimisedContainedImageUrl(imageUrl, MAX_DECORATIVE_IMAGE_DIMENSIONS_MOBILE_ZOOMED);
    const [isZoomed, setIsZoomed] = useState(false);
    return (_jsxs(_Fragment, { children: [_jsx(Box, {}), _jsxs(Box, { width: "100%", "data-testid": "content-wrapper", children: [_jsx(SlideRecordContent, { isNarrow: isNarrow, step: step, withSlideBadges: withSlideBadges, customDescription: customDescription, customTitle: customTitle }), children, isNarrow && imageUrl && optimisedMobileZoomedUrl && (_jsxs(_Fragment, { children: [_jsx(Box, { paddingTop: "l", onClick: () => setIsZoomed(true), children: _jsx(ContainedImage, { image: imageUrl, maxWidth: MAX_DECORATIVE_IMAGE_DIMENSIONS_MOBILE.width, maxHeight: MAX_DECORATIVE_IMAGE_DIMENSIONS_MOBILE.height }) }), deviceDetection.isMobile ? (_jsx(Modal, { isOpen: isZoomed, trigger: () => setIsZoomed(false), children: _jsx(Box, { width: "100vw", height: "100vh", position: "relative", children: _jsx(ImageViewer, { imageUrl: optimisedMobileZoomedUrl }) }) })) : (_jsx(ZoomImageModal, { image: optimisedMobileZoomedUrl, isOpen: isZoomed, onClose: () => setIsZoomed(false) }))] })), _jsx(Transparency, { height: 60 }), _jsx(RecruitmentPartnerMessage, { step: step }), _jsx(SlideCTA, { button: button, step: step })] }), _jsx(Box, {})] }));
});
const SlideCTA = observer(function SlideCTA({ button, step }) {
    const { userTestDoc, canGoForward, goForward } = useRecorderState();
    const ctaCopy = getCtaCopy(userTestDoc, step);
    const intl = useIntl();
    if (button !== null && (button || ctaCopy)) {
        return (_jsx(FloatingButton, { children: button ||
                (ctaCopy && (_jsx(Button, { size: "m", marginTop: "m", "data-testid": 'submit-form', onClick: goForward, isDisabled: !canGoForward, children: intl.formatMessage(ctaCopy) }))) }));
    }
    return null;
});
const RecruitmentPartnerMessage = observer(function RecruitmentPartnerMessage({ step, }) {
    const { userTestDoc } = useRecorderState();
    const searchParams = useLocationQueryParams();
    const { studyId, responseUUID } = searchParams;
    const isNotExitStep = !isExitStep(userTestDoc, step.uuid);
    if (isNotExitStep)
        return null;
    if (responseUUID) {
        return (_jsx(BtwMessage, { className: "mt-4", "data-testid": "respondent-confirmation", variant: "green", children: _jsxs(Stack, { gap: "1", children: [_jsx(BtwText, { variant: "inherit", children: _jsx(FormattedMessage, { id: 'FH2QwQ', defaultMessage: 'Thank you for completing this study. We have now received your response and you will be contacted within 48 hours regarding payment.' }) }), ' ', _jsx(Strong, { variant: "inherit", children: _jsx(FormattedMessage, { id: '+nhdAT', defaultMessage: 'You can now close this window' }) })] }) }));
    }
    const study = getStudyById(userTestDoc, studyId || '');
    if (study === null || study === void 0 ? void 0 : study.completionCode) {
        return (_jsxs(InfoBlock, { kind: "external", hasIcon: false, maxWidth: 520, mb: "s", mt: "s", children: [_jsx(Box, { mr: "m", children: _jsx(ProlificLogo, { width: "80px", height: "45px" }) }), _jsxs(Box, { children: [_jsxs(Text, { color: "blue12", fontSize: "14px", mb: "s", children: [_jsx(FormattedMessage, { id: 'JaHGSn', defaultMessage: 'Hi {recruitmentPartner} participant! You can now head back to {recruitmentPartner} to complete your submission {icon}', values: {
                                        recruitmentPartner: 'Prolific',
                                        icon: (_jsx("span", { role: "img", "aria-label": "success", children: "\uD83C\uDF89" })),
                                    } }), ' '] }), _jsx(Text, { color: "blue12", fontSize: "14px", children: _jsx(FormattedMessage, { id: 'L/zoeA', defaultMessage: 'Click here: {link}', values: {
                                    url: `https://app.prolific.co/submissions/complete?cc=${study.completionCode}`,
                                    link: (_jsxs(Link, { href: `https://app.prolific.co/submissions/complete?cc=${study.completionCode}`, children: ["https://app.prolific.co/submissions/complete?cc=$", study.completionCode] })),
                                } }) })] })] }));
    }
    return null;
});
export function getCtaCopy(userTest, step) {
    const stepsWihtoutCta = [StepClass.FiveSecondTest, StepClass.WebsiteTask];
    if (isSubmitStep(userTest, step.uuid) &&
        !stepsWihtoutCta.includes(step.className)) {
        return SUBMIT_STEP_COPY.buttonText;
    }
    if (isWelcomeStep(userTest, step.uuid)) {
        return UI_STEPS_COPY[FixedStep.Welcome].buttonText;
    }
    if (isExitStep(userTest, step.uuid) ||
        stepsWihtoutCta.includes(step.className)) {
        return null;
    }
    return UI_COMMON_STEPS_COPY.buttonText;
}
const Transparency = styled(PinBL).attrs({
    bg: 'whiteA8',
    display: ['block', 'block', 'none'],
}) `
  width: 100%;
  position: fixed;
  backdrop-filter: blur(1px);
  pointer-events: none;
`;
