export const timezones = {
    COUNTRY_AD: ['Europe/Andorra'],
    COUNTRY_AE: ['Asia/Dubai'],
    COUNTRY_AF: ['Asia/Kabul'],
    COUNTRY_AG: ['America/Antigua'],
    COUNTRY_AI: ['America/Anguilla'],
    COUNTRY_AL: ['Europe/Tirane'],
    COUNTRY_AM: ['Asia/Yerevan'],
    COUNTRY_AO: ['Africa/Luanda'],
    COUNTRY_AQ: [
        'Antarctica/Casey',
        'Antarctica/Davis',
        'Antarctica/DumontDUrville',
        'Antarctica/Mawson',
        'Antarctica/McMurdo',
        'Antarctica/Palmer',
        'Antarctica/Rothera',
        'Antarctica/Syowa',
        'Antarctica/Troll',
        'Antarctica/Vostok',
    ],
    COUNTRY_AR: ['America/Argentina/Buenos_Aires'],
    COUNTRY_AS: ['Pacific/Pago_Pago'],
    COUNTRY_AT: ['Europe/Vienna'],
    COUNTRY_AU: [
        'Australia/Sydney',
        'Australia/Melbourne',
        'Australia/Brisbane',
        'Australia/Adelaide',
        'Australia/Perth',
        'Australia/Darwin',
        'Australia/Hobart',
    ],
    COUNTRY_AW: ['America/Aruba'],
    COUNTRY_AX: ['Europe/Mariehamn'],
    COUNTRY_AZ: ['Asia/Baku'],
    COUNTRY_BA: ['Europe/Sarajevo'],
    COUNTRY_BB: ['America/Barbados'],
    COUNTRY_BD: ['Asia/Dhaka'],
    COUNTRY_BE: ['Europe/Brussels'],
    COUNTRY_BF: ['Africa/Ouagadougou'],
    COUNTRY_BG: ['Europe/Sofia'],
    COUNTRY_BH: ['Asia/Bahrain'],
    COUNTRY_BI: ['Africa/Bujumbura'],
    COUNTRY_BJ: ['Africa/Porto-Novo'],
    COUNTRY_BL: ['America/St_Barthelemy'],
    COUNTRY_BM: ['Atlantic/Bermuda'],
    COUNTRY_BN: ['Asia/Brunei'],
    COUNTRY_BO: ['America/La_Paz'],
    COUNTRY_BQ: ['America/Kralendijk'],
    COUNTRY_BR: [
        'America/Sao_Paulo',
        'America/Manaus',
        'America/Recife',
        'America/Rio_Branco',
    ],
    COUNTRY_BS: ['America/Nassau'],
    COUNTRY_BT: ['Asia/Thimphu'],
    COUNTRY_BV: ['Europe/Rome'], // should be 'Atlantic/Bouvet' but the browser doesn't support it and its the same time as Rome
    COUNTRY_BW: ['Africa/Gaborone'],
    COUNTRY_BY: ['Europe/Minsk'],
    COUNTRY_BZ: ['America/Belize'],
    COUNTRY_CA: [
        'America/Toronto',
        'America/Vancouver',
        'America/Edmonton',
        'America/Halifax',
        'America/St_Johns',
    ],
    COUNTRY_CC: ['Indian/Cocos'],
    COUNTRY_CD: ['Africa/Kinshasa', 'Africa/Lubumbashi'],
    COUNTRY_CF: ['Africa/Bangui'],
    COUNTRY_CG: ['Africa/Brazzaville'],
    COUNTRY_CH: ['Europe/Zurich'],
    COUNTRY_CI: ['Africa/Abidjan'],
    COUNTRY_CK: ['Pacific/Rarotonga'],
    COUNTRY_CL: ['America/Santiago', 'Pacific/Easter'],
    COUNTRY_CM: ['Africa/Douala'],
    COUNTRY_CN: ['Asia/Shanghai', 'Asia/Urumqi'],
    COUNTRY_CO: ['America/Bogota'],
    COUNTRY_CR: ['America/Costa_Rica'],
    COUNTRY_CU: ['America/Havana'],
    COUNTRY_CV: ['Atlantic/Cape_Verde'],
    COUNTRY_CW: ['America/Curacao'],
    COUNTRY_CX: ['Indian/Christmas'],
    COUNTRY_CY: ['Asia/Nicosia'],
    COUNTRY_CZ: ['Europe/Prague'],
    COUNTRY_DE: ['Europe/Berlin'],
    COUNTRY_DJ: ['Africa/Djibouti'],
    COUNTRY_DK: ['Europe/Copenhagen'],
    COUNTRY_DM: ['America/Dominica'],
    COUNTRY_DO: ['America/Santo_Domingo'],
    COUNTRY_DZ: ['Africa/Algiers'],
    COUNTRY_EC: ['America/Guayaquil', 'Pacific/Galapagos'],
    COUNTRY_EE: ['Europe/Tallinn'],
    COUNTRY_EG: ['Africa/Cairo'],
    COUNTRY_EH: ['Africa/El_Aaiun'],
    COUNTRY_ER: ['Africa/Asmara'],
    COUNTRY_ES: ['Europe/Madrid', 'Atlantic/Canary'],
    COUNTRY_ET: ['Africa/Addis_Ababa'],
    COUNTRY_FI: ['Europe/Helsinki'],
    COUNTRY_FJ: ['Pacific/Fiji'],
    COUNTRY_FK: ['Atlantic/Stanley'],
    COUNTRY_FM: ['Pacific/Pohnpei', 'Pacific/Kosrae'],
    COUNTRY_FO: ['Atlantic/Faroe'],
    COUNTRY_FR: ['Europe/Paris'],
    COUNTRY_GA: ['Africa/Libreville'],
    COUNTRY_GB: ['Europe/London'],
    COUNTRY_GD: ['America/Grenada'],
    COUNTRY_GE: ['Asia/Tbilisi'],
    COUNTRY_GF: ['America/Cayenne'],
    COUNTRY_GG: ['Europe/Guernsey'],
    COUNTRY_GH: ['Africa/Accra'],
    COUNTRY_GI: ['Europe/Gibraltar'],
    COUNTRY_GL: [
        'America/Godthab',
        'America/Danmarkshavn',
        'America/Scoresbysund',
        'America/Thule',
    ],
    COUNTRY_GM: ['Africa/Banjul'],
    COUNTRY_GN: ['Africa/Conakry'],
    COUNTRY_GP: ['America/Guadeloupe'],
    COUNTRY_GQ: ['Africa/Malabo'],
    COUNTRY_GR: ['Europe/Athens'],
    COUNTRY_GS: ['Atlantic/South_Georgia'],
    COUNTRY_GT: ['America/Guatemala'],
    COUNTRY_GU: ['Pacific/Guam'],
    COUNTRY_GW: ['Africa/Bissau'],
    COUNTRY_GY: ['America/Guyana'],
    COUNTRY_HK: ['Asia/Hong_Kong'],
    COUNTRY_HN: ['America/Tegucigalpa'],
    COUNTRY_HM: ['Indian/Kerguelen'],
    COUNTRY_HR: ['Europe/Zagreb'],
    COUNTRY_HT: ['America/Port-au-Prince'],
    COUNTRY_HU: ['Europe/Budapest'],
    COUNTRY_ID: [
        'Asia/Jakarta',
        'Asia/Pontianak',
        'Asia/Makassar',
        'Asia/Jayapura',
    ],
    COUNTRY_IE: ['Europe/Dublin'],
    COUNTRY_IL: ['Asia/Jerusalem'],
    COUNTRY_IM: ['Europe/Isle_of_Man'],
    COUNTRY_IN: ['Asia/Kolkata'],
    COUNTRY_IO: ['Indian/Chagos'],
    COUNTRY_IQ: ['Asia/Baghdad'],
    COUNTRY_IR: ['Asia/Tehran'],
    COUNTRY_IS: ['Atlantic/Reykjavik'],
    COUNTRY_IT: ['Europe/Rome'],
    COUNTRY_JE: ['Europe/Jersey'],
    COUNTRY_JM: ['America/Jamaica'],
    COUNTRY_JO: ['Asia/Amman'],
    COUNTRY_JP: ['Asia/Tokyo'],
    COUNTRY_KE: ['Africa/Nairobi'],
    COUNTRY_KG: ['Asia/Bishkek'],
    COUNTRY_KH: ['Asia/Phnom_Penh'],
    COUNTRY_KI: ['Pacific/Tarawa', 'Pacific/Enderbury', 'Pacific/Kiritimati'],
    COUNTRY_KM: ['Indian/Comoro'],
    COUNTRY_KN: ['America/St_Kitts'],
    COUNTRY_KP: ['Asia/Pyongyang'],
    COUNTRY_KR: ['Asia/Seoul'],
    COUNTRY_KW: ['Asia/Kuwait'],
    COUNTRY_KY: ['America/Cayman'],
    COUNTRY_KZ: ['Asia/Almaty', 'Asia/Aqtobe'],
    COUNTRY_LA: ['Asia/Vientiane'],
    COUNTRY_LB: ['Asia/Beirut'],
    COUNTRY_LC: ['America/St_Lucia'],
    COUNTRY_LI: ['Europe/Vaduz'],
    COUNTRY_LK: ['Asia/Colombo'],
    COUNTRY_LR: ['Africa/Monrovia'],
    COUNTRY_LS: ['Africa/Maseru'],
    COUNTRY_LT: ['Europe/Vilnius'],
    COUNTRY_LU: ['Europe/Luxembourg'],
    COUNTRY_LV: ['Europe/Riga'],
    COUNTRY_LY: ['Africa/Tripoli'],
    COUNTRY_MA: ['Africa/Casablanca'],
    COUNTRY_MC: ['Europe/Monaco'],
    COUNTRY_MD: ['Europe/Chisinau'],
    COUNTRY_ME: ['Europe/Podgorica'],
    COUNTRY_MF: ['America/Marigot'],
    COUNTRY_MG: ['Indian/Antananarivo'],
    COUNTRY_MH: ['Pacific/Majuro', 'Pacific/Kwajalein'],
    COUNTRY_MK: ['Europe/Skopje'],
    COUNTRY_ML: ['Africa/Bamako'],
    COUNTRY_MM: ['Asia/Yangon'],
    COUNTRY_MN: ['Asia/Ulaanbaatar', 'Asia/Hovd', 'Asia/Choibalsan'],
    COUNTRY_MO: ['Asia/Macau'],
    COUNTRY_MP: ['Pacific/Saipan'],
    COUNTRY_MQ: ['America/Martinique'],
    COUNTRY_MR: ['Africa/Nouakchott'],
    COUNTRY_MS: ['America/Montserrat'],
    COUNTRY_MT: ['Europe/Malta'],
    COUNTRY_MU: ['Indian/Mauritius'],
    COUNTRY_MV: ['Indian/Maldives'],
    COUNTRY_MW: ['Africa/Blantyre'],
    COUNTRY_MX: [
        'America/Mexico_City',
        'America/Tijuana',
        'America/Hermosillo',
        'America/Cancun',
    ],
    COUNTRY_MY: ['Asia/Kuala_Lumpur', 'Asia/Kuching'],
    COUNTRY_MZ: ['Africa/Maputo'],
    COUNTRY_NA: ['Africa/Windhoek'],
    COUNTRY_NC: ['Pacific/Noumea'],
    COUNTRY_NE: ['Africa/Niamey'],
    COUNTRY_NF: ['Pacific/Norfolk'],
    COUNTRY_NG: ['Africa/Lagos'],
    COUNTRY_NI: ['America/Managua'],
    COUNTRY_NL: ['Europe/Amsterdam'],
    COUNTRY_NO: ['Europe/Oslo'],
    COUNTRY_NP: ['Asia/Kathmandu'],
    COUNTRY_NR: ['Pacific/Nauru'],
    COUNTRY_NU: ['Pacific/Niue'],
    COUNTRY_NZ: ['Pacific/Auckland', 'Pacific/Chatham'],
    COUNTRY_OM: ['Asia/Muscat'],
    COUNTRY_PA: ['America/Panama'],
    COUNTRY_PE: ['America/Lima'],
    COUNTRY_PF: ['Pacific/Tahiti', 'Pacific/Marquesas', 'Pacific/Gambier'],
    COUNTRY_PG: ['Pacific/Port_Moresby', 'Pacific/Bougainville'],
    COUNTRY_PH: ['Asia/Manila'],
    COUNTRY_PK: ['Asia/Karachi'],
    COUNTRY_PL: ['Europe/Warsaw'],
    COUNTRY_PM: ['America/Miquelon'],
    COUNTRY_PN: ['Pacific/Pitcairn'],
    COUNTRY_PR: ['America/Puerto_Rico'],
    COUNTRY_PS: ['Asia/Gaza', 'Asia/Hebron'],
    COUNTRY_PT: ['Europe/Lisbon', 'Atlantic/Madeira', 'Atlantic/Azores'],
    COUNTRY_PW: ['Pacific/Palau'],
    COUNTRY_PY: ['America/Asuncion'],
    COUNTRY_QA: ['Asia/Qatar'],
    COUNTRY_RE: ['Indian/Reunion'],
    COUNTRY_RO: ['Europe/Bucharest'],
    COUNTRY_RS: ['Europe/Belgrade'],
    COUNTRY_RU: [
        'Europe/Moscow',
        'Europe/Kaliningrad',
        'Europe/Samara',
        'Asia/Yekaterinburg',
        'Asia/Omsk',
        'Asia/Krasnoyarsk',
        'Asia/Irkutsk',
        'Asia/Yakutsk',
        'Asia/Vladivostok',
        'Asia/Magadan',
        'Asia/Kamchatka',
    ],
    COUNTRY_RW: ['Africa/Kigali'],
    COUNTRY_SA: ['Asia/Riyadh'],
    COUNTRY_SB: ['Pacific/Guadalcanal'],
    COUNTRY_SC: ['Indian/Mahe'],
    COUNTRY_SD: ['Africa/Khartoum'],
    COUNTRY_SE: ['Europe/Stockholm'],
    COUNTRY_SG: ['Asia/Singapore'],
    COUNTRY_SH: ['Atlantic/St_Helena'],
    COUNTRY_SI: ['Europe/Ljubljana'],
    COUNTRY_SJ: ['Arctic/Longyearbyen'],
    COUNTRY_SK: ['Europe/Bratislava'],
    COUNTRY_SL: ['Africa/Freetown'],
    COUNTRY_SM: ['Europe/San_Marino'],
    COUNTRY_SN: ['Africa/Dakar'],
    COUNTRY_SO: ['Africa/Mogadishu'],
    COUNTRY_SR: ['America/Paramaribo'],
    COUNTRY_SS: ['Africa/Juba'],
    COUNTRY_ST: ['Africa/Sao_Tome'],
    COUNTRY_SV: ['America/El_Salvador'],
    COUNTRY_SX: ['America/Lower_Princes'],
    COUNTRY_SY: ['Asia/Damascus'],
    COUNTRY_SZ: ['Africa/Mbabane'],
    COUNTRY_TC: ['America/Grand_Turk'],
    COUNTRY_TD: ['Africa/Ndjamena'],
    COUNTRY_TF: ['Indian/Kerguelen'],
    COUNTRY_TG: ['Africa/Lome'],
    COUNTRY_TH: ['Asia/Bangkok'],
    COUNTRY_TJ: ['Asia/Dushanbe'],
    COUNTRY_TK: ['Pacific/Fakaofo'],
    COUNTRY_TL: ['Asia/Dili'],
    COUNTRY_TM: ['Asia/Ashgabat'],
    COUNTRY_TN: ['Africa/Tunis'],
    COUNTRY_TO: ['Pacific/Tongatapu'],
    COUNTRY_TR: ['Europe/Istanbul'],
    COUNTRY_TT: ['America/Port_of_Spain'],
    COUNTRY_TV: ['Pacific/Funafuti'],
    COUNTRY_TW: ['Asia/Taipei'],
    COUNTRY_TZ: ['Africa/Dar_es_Salaam'],
    COUNTRY_UA: ['Europe/Kiev'],
    COUNTRY_UG: ['Africa/Kampala'],
    COUNTRY_UM: ['Pacific/Wake', 'Pacific/Midway'],
    COUNTRY_US: [
        'America/New_York',
        'America/Chicago',
        'America/Denver',
        'America/Los_Angeles',
        'America/Anchorage',
        'Pacific/Honolulu',
    ],
    COUNTRY_UY: ['America/Montevideo'],
    COUNTRY_UZ: ['Asia/Tashkent', 'Asia/Samarkand'],
    COUNTRY_VA: ['Europe/Vatican'],
    COUNTRY_VC: ['America/St_Vincent'],
    COUNTRY_VE: ['America/Caracas'],
    COUNTRY_VG: ['America/Tortola'],
    COUNTRY_VI: ['America/St_Thomas'],
    COUNTRY_VN: ['Asia/Ho_Chi_Minh'],
    COUNTRY_VU: ['Pacific/Efate'],
    COUNTRY_WF: ['Pacific/Wallis'],
    COUNTRY_WS: ['Pacific/Apia'],
    COUNTRY_YE: ['Asia/Aden'],
    COUNTRY_YT: ['Indian/Mayotte'],
    COUNTRY_ZA: ['Africa/Johannesburg'],
    COUNTRY_ZM: ['Africa/Lusaka'],
    COUNTRY_ZW: ['Africa/Harare'],
};
