import { mapFilterNullish } from '@marvelapp/core';
// eslint-disable-next-line import/no-cycle
import { isOfType, isStepWithSubType } from './stepUtils';
import { FixedStep, StepClass, } from './types';
export function getMiddleSteps(userTest) {
    return mapFilterNullish(userTest.steps, (uuid) => getStepByUUID(userTest, uuid));
}
export function getSteps(userTest) {
    return {
        welcomeStep: userTest.welcomeStep,
        mediaSettingsStep: getAnyMediaEnabled(userTest)
            ? userTest === null || userTest === void 0 ? void 0 : userTest.mediaSettingsStep
            : undefined,
        middleSteps: getMiddleSteps(userTest),
        exitStep: userTest.exitStep,
    };
}
export function getOrderedListOfAllSteps({ userTest, withScreeners = false, withMediaSettings = true, }) {
    return mapFilterNullish([
        ...(withScreeners ? getScreeningSteps(userTest) : []),
        userTest.welcomeStep,
        getAnyMediaEnabled(userTest) && withMediaSettings
            ? userTest === null || userTest === void 0 ? void 0 : userTest.mediaSettingsStep
            : null,
        ...getMiddleSteps(userTest),
        userTest.exitStep,
    ], (step) => step);
}
function getScreeningSteps(userTest) {
    if (userTest.screeners) {
        return mapFilterNullish([
            userTest.screeners.intro,
            ...getMainScreenerSteps(userTest),
            userTest.screeners.decline,
        ], (step) => step);
    }
    return [];
}
export function getMainScreenerSteps(userTest) {
    if (userTest.screeners)
        return [
            ...mapFilterNullish(userTest.screeners.steps, (uuid) => getStepByUUID(userTest, uuid)),
        ];
    return [];
}
export function getStepsCount(userTest) {
    const FIXED_STEPS_COUNT = userTest.mediaSettingsStep ? 3 : 2;
    return userTest.steps.length + FIXED_STEPS_COUNT;
}
export function getStepByUUID(userTest, uuid) {
    var _a, _b, _c;
    if (userTest.welcomeStep.uuid === uuid) {
        return userTest.welcomeStep;
    }
    // HOTFIX (BALL-998): this function sometimes receives undefined uuid we're investigating why
    if (userTest.mediaSettingsStep && userTest.mediaSettingsStep.uuid === uuid) {
        return userTest.mediaSettingsStep;
    }
    if (userTest.exitStep.uuid === uuid) {
        return userTest.exitStep;
    }
    // HOTFIX (BALL-998): this function sometimes receives undefined uuid we're investigating why
    if (userTest.screeners) {
        if (((_a = userTest.screeners.intro) === null || _a === void 0 ? void 0 : _a.uuid) === uuid) {
            return userTest.screeners.intro;
        }
        if (((_b = userTest.screeners.decline) === null || _b === void 0 ? void 0 : _b.uuid) === uuid) {
            return userTest.screeners.decline;
        }
    }
    return (_c = userTest.stepDefinitions[uuid]) !== null && _c !== void 0 ? _c : null;
}
export function getStepIndex(userTest, uuid) {
    const steps = getOrderedListOfAllSteps({ userTest });
    return steps.findIndex((step) => step.uuid === uuid);
}
export function getMiddleStepsUUIDs(userTest) {
    return userTest.steps;
}
export function getHasScreeners(userTest) {
    return !!userTest.screeners;
}
export function getOrderedListOfAllStepUUIDs(userTest) {
    return getOrderedListOfAllSteps({ userTest }).map((step) => step.uuid);
}
export function getClassName(step) {
    return step.className;
}
export function getAdjacentStepUUID({ userTest, candidateStepUUID, index, skipMediaSettingsStep, }) {
    let step = getStepByUUID(userTest, candidateStepUUID);
    if (!step)
        throw new Error(`Step with UUID ${candidateStepUUID} not found`);
    if (skipMediaSettingsStep && step.className === StepClass.MediaSettings) {
        const steps = getOrderedListOfAllSteps({ userTest });
        const stepIndex = steps.findIndex((step) => step.uuid === candidateStepUUID);
        step = steps[stepIndex + index];
    }
    return step.uuid;
}
export function getStepType(step) {
    if (isStepWithSubType(step) && step.type)
        return step.type;
    return step.className;
}
export function getStepTitle(step) {
    return step.title;
}
export function isExitStep(userTest, stepUUID) {
    if (!userTest)
        return false;
    return userTest.exitStep.uuid === stepUUID;
}
export function isWelcomeStep(userTest, stepUUID) {
    if (!userTest)
        return false;
    return userTest.welcomeStep.uuid === stepUUID;
}
export function getFixedStepType(userTest, stepUUID) {
    if (isWelcomeStep(userTest, stepUUID)) {
        return FixedStep.Welcome;
    }
    if (isExitStep(userTest, stepUUID)) {
        return FixedStep.Exit;
    }
}
export function isSubmitStep(userTest, stepUUID) {
    if (!userTest)
        return false;
    const steps = getMiddleSteps(userTest);
    if (!steps.length)
        return false;
    const lastStep = steps[steps.length - 1];
    return lastStep.uuid === stepUUID;
}
export function getRecordingPermissions(userTest) {
    return ((userTest === null || userTest === void 0 ? void 0 : userTest.recordingPermissions) || {
        webcam: false,
        microphone: false,
        screen: false,
    });
}
export function getRecordingRequired(userTest) {
    var _a;
    return !!((_a = userTest.mediaSettingsStep) === null || _a === void 0 ? void 0 : _a.isRequired);
}
export function getWholeScreenRecordingRequired(userTest) {
    const steps = getOrderedListOfAllSteps({ userTest });
    return steps.some((step) => isOfType(step, StepClass.WebsiteTask));
}
export function getAnyMediaEnabled(userTest) {
    const { webcam, microphone, screen } = getRecordingPermissions(userTest);
    return webcam || microphone || screen;
}
export function getStudyById(userTest, studyId) {
    if (!userTest.studies)
        return null;
    return userTest.studies[studyId] || null;
}
