import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { noop } from 'lodash-es';
import { observer } from 'mobx-react';
import { useCallback, useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Icon } from '@marvelapp/ballpark-components';
import { CAMERA_ICON, HEADSET_ICON } from '@marvelapp/ballpark-theme';
import { Box, Loader } from '@marvelapp/ui';
import { RequestMedia, RequestMediaContainer, } from '../../MediaSettings/RequestMedia';
import NoCameraError from './NoCameraError';
import { SelectWebcamMicrophone } from './SelectWebcamMicrophone';
import { WebcamMicPermissionsDeniedError } from './WebcamPermissionDeniedError';
export const RequestWebcamMicrophonePermission = observer(function RequestWebcamMicrophonePermission({ permissions, send, state, }) {
    const intl = useIntl();
    const onRequestAccess = useCallback(() => {
        if (state.matches('shareUserMedia.idle')) {
            send('REQUEST_USER_MEDIA');
        }
        else {
            send('RETRY_USER_MEDIA');
        }
    }, [state, send]);
    const showWebcamMicSelector = useMemo(() => state.matches('shareUserMedia.userMediaGranted') ||
        state.matches('shareUserMedia.checkPreviouslyGranted') ||
        state.matches('shareUserMedia.requestingUserMedia'), [state]);
    let description;
    if (state.matches('shareUserMedia.userMediaGranted')) {
        if (permissions.webcam) {
            description = (_jsx(FormattedMessage, { id: 'suYK9+', defaultMessage: 'Check you can see yourself before continuing' }));
        }
        else {
            description = (_jsx(FormattedMessage, { id: 'CdJ2u+', defaultMessage: 'Check you can hear yourself before continuing' }));
        }
    }
    else {
        description = (_jsx(FormattedMessage, { id: '5w079v', defaultMessage: 'Click \u2018<strong>allow</strong>\u2019 when you see the popup', values: {
                strong: (chunks) => _jsx("strong", { children: chunks }),
            } }));
    }
    return (_jsxs(RequestMediaContainer, { children: [_jsx(RequestMedia, { state: getRequestStatus(state), description: description, icon: permissions.webcam ? (_jsx(Icon, { paths: CAMERA_ICON, width: 14, height: 14 })) : (_jsx(Icon, { paths: HEADSET_ICON, height: 14, viewBox: "0 0 14 14", width: 14 })), testId: "toggle-webcam-share", title: permissions.webcam
                    ? intl.formatMessage({ id: "M7v3Xn", defaultMessage: "Share webcam" })
                    : intl.formatMessage({ id: "oXtpTl", defaultMessage: "Share microphone" }), onChange: state.matches('shareUserMedia.idle') ? onRequestAccess : noop }), showWebcamMicSelector && (_jsx(SelectWebcamMicrophone, { permissions: permissions, send: send })), state.matches('shareUserMedia.requestingUserMedia') && (_jsx(Box, { width: "100%", display: "flex", justifyContent: "center", mt: "-10px", children: _jsxs(Box, { bg: "blue5", color: "blue12", width: "100%", borderRadius: "s", display: "flex", alignItems: "center", justifyContent: "center", p: "m", gridGap: "s", fontSize: "14px", children: [_jsx(Loader, { color: "blue12" }), ' ', _jsx(FormattedMessage, { id: 'Ri84dA', defaultMessage: 'Waiting for you to allow permission' })] }) })), state.matches('shareUserMedia.userMediaNotSupported') && (_jsx(NoCameraError, { permissions: permissions })), state.matches('shareUserMedia.userMediaDenied') && (_jsx(WebcamMicPermissionsDeniedError, { permissions: permissions }))] }));
});
function getRequestStatus(state) {
    if (state.matches('shareUserMedia.userMediaGranted')) {
        return 'granted';
    }
    if (state.matches('shareUserMedia.userMediaDenied')) {
        return 'blocked';
    }
    if (state.matches('shareUserMedia.requestingUserMedia')) {
        return 'requesting';
    }
    return 'idle';
}
