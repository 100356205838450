import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { observer } from 'mobx-react';
import * as React from 'react';
import { Input, LegacySelect } from '@marvelapp/ballpark-components';
import styled from '@marvelapp/styled';
import { Box, Flex } from '@marvelapp/ui';
import { MultipleSelectionMode, } from '@marvelapp/user-test-creator';
export default observer(function MultipleChoiceCountSelect({ maxCount, onChange, value, onRangeChange, range, }) {
    var _a, _b, _c;
    const options = Object.keys(MultipleSelectionMode);
    let width = 1;
    if (value === MultipleSelectionMode.ExactNumber) {
        width = 1 / 2;
    }
    else if (value === MultipleSelectionMode.Range) {
        width = 1 / 3;
    }
    const displayValues = {
        [MultipleSelectionMode.Unlimited]: 'Unlimited',
        [MultipleSelectionMode.ExactNumber]: 'Exact number',
        [MultipleSelectionMode.Range]: 'Range',
    };
    const setRange = (lowerRangeLimit, upperRangeLimit) => {
        let upperValue = upperRangeLimit;
        let lowerValue = lowerRangeLimit;
        // don't let a user set more options than are available
        if (upperValue && upperValue > maxCount) {
            upperValue = maxCount;
        }
        if (lowerValue && lowerValue > maxCount) {
            lowerValue = maxCount;
        }
        // lower limit must always be less than upper limit
        if (lowerValue && upperValue && lowerValue >= upperValue) {
            lowerValue = upperValue - 1;
        }
        // if a user enters 0, we want to automatically increase that value to 1
        onRangeChange([
            lowerRangeLimit === 0 ? 1 : lowerValue,
            upperValue === 0 ? 1 : upperValue,
        ]);
    };
    const getParsedValue = (e) => Number.isNaN(parseInt(e.target.value)) ? null : parseInt(e.target.value);
    return (_jsxs(BorderBox, { children: [_jsx(InputWrapper, { width: width, children: _jsx(LegacySelect, { "data-testid": "select-multiple-options-count", id: "select-multiple-options-count", onChange: onChange, value: value, children: options.map((option) => (_jsx("option", { "data-testid": `${option}-options-count`, value: option, children: displayValues[option] }, option))) }) }), value === MultipleSelectionMode.ExactNumber && (_jsx(InputWrapper, { marginLeft: "xs", width: width, children: _jsx(Input, { onChange: (e) => setRange(null, getParsedValue(e)), "data-testid": "multiple-options-count-value", placeholder: `1-${maxCount}`, value: (_a = getValue('max', range)) !== null && _a !== void 0 ? _a : '' }) })), value === MultipleSelectionMode.Range && (_jsxs(_Fragment, { children: [_jsx(InputWrapper, { marginLeft: "xs", width: width, children: _jsx(Input, { "data-testid": "lower-limit-input", onChange: (e) => setRange(getParsedValue(e), range[1]), placeholder: `1-${maxCount}`, value: (_b = getValue('min', range)) !== null && _b !== void 0 ? _b : '' }) }), _jsx(InputWrapper, { marginLeft: "xs", width: width, children: _jsx(Input, { "data-testid": "upper-limit-input", onChange: (e) => setRange(range[0], getParsedValue(e)), placeholder: `1-${maxCount}`, value: (_c = getValue('max', range)) !== null && _c !== void 0 ? _c : '' }) })] }))] }));
});
const BorderBox = styled(Flex).attrs(() => ({
    borderBottom: '1px solid',
    borderColor: 'slate6',
    marginBottom: 'm',
    paddingBottom: 'm',
    width: 1,
})) ``;
function getValue(minOrMax, range) {
    const [min, max] = range;
    if (minOrMax === 'min') {
        return min && min > 0 ? min : null;
    }
    return max !== Infinity ? max : null;
}
const InputWrapper = styled(Box) `
  input {
    width: 100%;
  }
`;
