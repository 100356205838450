import { round } from 'lodash-es';
import { getFormattedImageUrl } from './getFormattedImageUrl';
import { ProjectType } from './projectType';
export const shouldCropThumbnailImage = (thumbnailImage, maxWidth) => {
    if (!thumbnailImage || !thumbnailImage.height || !thumbnailImage.width)
        return false;
    const isPortrait = thumbnailImage.height > thumbnailImage.width;
    return isPortrait && thumbnailImage.width > maxWidth;
};
export const getThumbnailImageUrl = (thumbnailImage, maxWidth, containerAspectRatio, projectType) => {
    if (!(thumbnailImage === null || thumbnailImage === void 0 ? void 0 : thumbnailImage.url) ||
        !(thumbnailImage === null || thumbnailImage === void 0 ? void 0 : thumbnailImage.height) ||
        !(thumbnailImage === null || thumbnailImage === void 0 ? void 0 : thumbnailImage.width)) {
        return null;
    }
    const shouldCrop = shouldCropThumbnailImage(thumbnailImage, maxWidth);
    const maxHeight = maxWidth / containerAspectRatio;
    const cropAspectRatio = maxHeight < thumbnailImage.height ? `${maxWidth}:${maxHeight}` : '1:1';
    const options = {
        url: thumbnailImage.url,
    };
    if (projectType === ProjectType.Whiteboard) {
        const imageAspectRatio = thumbnailImage.width / thumbnailImage.height;
        // when the image aspect ratio is very small or large we set the canvas parameter
        // https://developer.fastly.com/reference/io/canvas/
        // to generate a thumbnail closer to containerAspectRatio
        if (imageAspectRatio < 0.4 || imageAspectRatio > 2.5) {
            options.canvas = `${maxWidth},${maxHeight}`;
            // This will need to change when whiteboard projects support custom bg colors
            options.bgColor = 'FFF';
            options.crop = false;
            options.width = 0;
        }
        else {
            options.width = maxWidth;
        }
    }
    else {
        options.width = maxWidth;
        options.cropParams = shouldCrop ? ',offset-x0,offset-y0' : '';
        options.aspectRatio = shouldCrop ? cropAspectRatio : '';
    }
    return getFormattedImageUrl(options) || null;
};
// Thumbnail padding utils
const MAX_IMAGE_PADDING = 0.05;
export const getThumbnailImagePadding = (thumbnailImage, containerAspectRatio) => {
    if (!(thumbnailImage === null || thumbnailImage === void 0 ? void 0 : thumbnailImage.url) ||
        !(thumbnailImage === null || thumbnailImage === void 0 ? void 0 : thumbnailImage.height) ||
        !(thumbnailImage === null || thumbnailImage === void 0 ? void 0 : thumbnailImage.width)) {
        return 0;
    }
    const imageAspectRatio = thumbnailImage.width / thumbnailImage.height;
    const imagePaddingPercent = round(1 - containerAspectRatio / imageAspectRatio, 2);
    return imageAspectRatio > containerAspectRatio
        ? `${Math.min(MAX_IMAGE_PADDING, imagePaddingPercent) * 100}%`
        : null;
};
