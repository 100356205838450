import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { observer } from 'mobx-react';
import * as React from 'react';
import { useGetUserTest, useSlideUIContext, } from '@marvelapp/ballpark-application';
import { Icon, Link, Tooltip } from '@marvelapp/ballpark-components';
import { EYE_ICON } from '@marvelapp/ballpark-theme';
import styled from '@marvelapp/styled';
import { Box } from '@marvelapp/ui';
import stripUUIDPrefix, { stripPrefix } from '../../utils/stripUUIDPrefix';
import { ConditionalLogicSettings } from './ConditionalLogicSettings';
import SlideControlsButton from './SlideControlsButton';
import SlideControlsHeading from './SlideControlsHeading';
import SlideControlsSettings from './SlideControlsSettings';
export const SlideControls = observer(function SlideControls({ getStepIcon, getStepName, }) {
    const userTest = useGetUserTest();
    const { step } = useSlideUIContext();
    return (_jsxs(Controls, { children: [_jsx(SlideControlsHeading, { getStepName: getStepName, getStepIcon: getStepIcon }), _jsxs(Buttons, { children: [_jsx(ConditionalLogicSettings, {}), _jsx(Tooltip.Quick, { asChild: true, content: "Preview step", children: _jsx(Link, { "data-testid": "preview-step", target: "_blank", to: `/preview/${stripUUIDPrefix({
                                uuid: userTest.uuid,
                            })}/?step=${stripPrefix(step.uuid)}`, children: _jsx(SlideControlsButton, { mr: "xs", paddingLeft: "2px", paddingRight: "0", width: 30, children: _jsx(Icon, { paths: EYE_ICON }) }) }) }), _jsx(SlideControlsSettings, {})] })] }));
});
const Controls = styled(Box).attrs({
    height: 'barHeight',
    borderTopLeftRadius: 'xl',
    borderTopRightRadius: 'xl',
    borderBottom: '1px solid',
    borderColor: 'slate6',
    bg: 'slate2',
}) `
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const Buttons = styled(Box) `
  display: flex;
  align-items: center;
  > button {
    margin-right: ${(props) => props.theme.space.xs}px;
  }
`;
