import * as CardSortingResponseUtils from '../response/cardSortingStep';
import * as MultipleChoiceResponseUtils from '../response/multipleChoiceStep';
import * as PreferenceStepResponseUtils from '../response/preferenceStep';
import * as PrototypeTaskResponseUtils from '../response/prototypeTaskStep';
import * as QuestionResponseUtils from '../response/questionStep';
import * as RatingResponseUtils from '../response/ratingStep';
import * as TaglineStepResponseUtils from '../response/taglineCopyStep';
import * as YesOrNoResponseUtils from '../response/yesOrNoStep';
import { StepClass } from '../userTest/types';
export function hasAnswer(response, step) {
    switch (response.className) {
        case StepClass.Question: {
            const answer = QuestionResponseUtils.getAnswer(response);
            if (typeof answer === 'string') {
                // once you start typing into the `TextEditor` the answer
                // will never be `null` again. So we make sure here that
                // we don't consider an empty string to be an answer
                return answer.trim() !== '';
            }
            return answer !== null;
        }
        case StepClass.PrototypeTask:
            return PrototypeTaskResponseUtils.hasStartTestEvent(response);
        case StepClass.YesOrNo:
            return YesOrNoResponseUtils.getAnswer(response) !== null;
        case StepClass.MultipleChoice:
            return MultipleChoiceResponseUtils.getAnswer(response).length > 0;
        case StepClass.PreferenceTest:
            return PreferenceStepResponseUtils.getAnswer(response).length > 0;
        case StepClass.TaglineCopyTest:
            return TaglineStepResponseUtils.getAnswer(response).length > 0;
        case StepClass.RatingScale:
            return RatingResponseUtils.getAnswer(response) !== null;
        case StepClass.CardSortingClosed:
        case StepClass.CardSortingHybrid:
        case StepClass.CardSortingOpen: {
            const areAllCardsSorted = CardSortingResponseUtils.getUnsortedCardUUIDs(response, step).length === 0;
            if (response.className === StepClass.CardSortingClosed) {
                const hasCategories = CardSortingResponseUtils.getCategoryUUIDs(response, step).length > 0;
                return hasCategories ? areAllCardsSorted : true;
            }
            return areAllCardsSorted;
        }
        default:
            return false;
    }
}
