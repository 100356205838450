import { __rest } from "tslib";
import { jsx as _jsx } from "react/jsx-runtime";
import Box from '../Box';
import { getRowBg } from './color';
function Row(_a) {
    var { children, hoverBg, isDisabled, isLastChild, isLoading, isSelected, pointerEvents, borderTop, bg } = _a, props = __rest(_a, ["children", "hoverBg", "isDisabled", "isLastChild", "isLoading", "isSelected", "pointerEvents", "borderTop", "bg"]);
    const rowBg = getRowBg(isSelected, isLoading, isDisabled);
    return (_jsx(Box, Object.assign({ bg: bg || rowBg.bg, borderBottom: isLastChild ? 'none' : '1px solid', borderTop: borderTop, 
        // eslint-disable-next-line
        borderColor: "smoke", hoverBg: hoverBg || rowBg.hoverBg, pointerEvents: pointerEvents, px: 3, py: 18 }, props, { children: children })));
}
Row.defaultProps = {
    isDisabled: false,
    isLastChild: false,
    isLoading: false,
    isSelected: false,
};
export default Row;
