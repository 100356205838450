import { __awaiter } from "tslib";
import { gql, useMutation } from '@apollo/client';
import { useCallback, useEffect, useState } from 'react';
import { useWaitOnCardSortStatsUpdate } from './useWaitOnCardSortStatsUpdate';
const EDIT_CATEGORY_ALIAS = gql `
  mutation editCategoryAlias(
    $testPk: Int!
    $stepUuid: String!
    $categoryNames: [String]
    $standardisedAs: String
    $groupId: Int
  ) {
    aliasCardsortCategory(
      input: {
        testPk: $testPk
        groupId: $groupId
        stepUuid: $stepUuid
        categoryNames: $categoryNames
        standardisedAs: $standardisedAs
      }
    ) {
      ok
      group {
        groupId
        groupUuid
        standardisedAs
        includedCategoryNames
      }
      error {
        code
        message
      }
    }
  }
`;
function useEditCategoryAlias() {
    return useMutation(EDIT_CATEGORY_ALIAS);
}
export function useCreateCategoryAlias(userTestUUID) {
    const [createAlias, { loading, error }] = useEditCategoryAlias();
    const [categoryUUID, setCategoryUUID] = useState();
    const { isWaiting } = useWaitOnCardSortStatsUpdate({
        userTestUUID,
        categoryUUID,
        setCategoryUUID,
        operation: 'create',
    });
    const [status, setStatus] = useState('idle');
    const [result, setResult] = useState();
    const [promiseResolve, setPromiseResolve] = useState(null);
    const [promiseReject, setPromiseReject] = useState(null);
    const createCategoryAlias = useCallback((...args) => __awaiter(this, void 0, void 0, function* () {
        var _a, _b, _c;
        setStatus('pending');
        try {
            const aliasResult = yield createAlias(...args);
            const newCategoryGroupUUID = (_c = (_b = (_a = aliasResult.data) === null || _a === void 0 ? void 0 : _a.aliasCardsortCategory) === null || _b === void 0 ? void 0 : _b.group) === null || _c === void 0 ? void 0 : _c.groupUuid;
            if (newCategoryGroupUUID) {
                setCategoryUUID(newCategoryGroupUUID);
            }
            setResult(aliasResult);
            return new Promise((resolve, reject) => {
                setPromiseResolve(() => resolve);
                setPromiseReject(() => reject);
            });
        }
        catch (err) {
            setStatus('rejected');
            throw err;
        }
    }), [createAlias]);
    useEffect(() => {
        if (status === 'pending' && !loading && !isWaiting) {
            setStatus('resolved');
            if (promiseResolve && result) {
                promiseResolve(result);
            }
        }
    }, [status, loading, isWaiting, result, promiseResolve]);
    useEffect(() => {
        if (error) {
            setStatus('rejected');
            if (promiseReject) {
                promiseReject(error);
            }
        }
    }, [error, promiseReject]);
    return {
        createCategoryAlias,
        loading: status === 'pending',
        error,
    };
}
export function useDeleteCategoryAlias(userTestUUID, categoryUUIDToDelete) {
    const [deleteAlias, { loading, error }] = useEditCategoryAlias();
    const [categoryUUID, setCategoryUUID] = useState();
    const { isWaiting } = useWaitOnCardSortStatsUpdate({
        userTestUUID,
        categoryUUID,
        setCategoryUUID,
        operation: 'delete',
    });
    const deleteCategoryAlias = (...args) => __awaiter(this, void 0, void 0, function* () {
        var _a, _b;
        const result = yield deleteAlias(...args);
        if ((_b = (_a = result.data) === null || _a === void 0 ? void 0 : _a.aliasCardsortCategory) === null || _b === void 0 ? void 0 : _b.ok) {
            setCategoryUUID(categoryUUIDToDelete);
        }
        return result;
    });
    return {
        deleteCategoryAlias,
        loading: loading || isWaiting,
        error,
    };
}
