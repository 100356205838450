import { __rest } from "tslib";
import { jsx as _jsx } from "react/jsx-runtime";
import { observer } from 'mobx-react';
import * as React from 'react';
import { Button } from '@marvelapp/ballpark-components';
export default observer(function SlideControlsButton(_a) {
    var { isOpen, isActive } = _a, props = __rest(_a, ["isOpen", "isActive"]);
    const extraProps = !isActive && {
        bg: isOpen ? 'slate2' : 'background',
        boxShadow: isOpen ? 'none' : 'button.secondary',
    };
    return (_jsx(Button, Object.assign({ alignItems: "center", display: "flex", isActive: isActive, kind: "ghost", paddingLeft: "xs", size: "s", translateOnHover: false }, extraProps, props)));
});
