import { __rest } from "tslib";
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { observer } from 'mobx-react';
import * as React from 'react';
import { useFeatureFlag } from '@marvelapp/ballpark-application';
import { Accordion, BtwText, BtwTooltip, Stack, cn, } from '@marvelapp/ballpark-components';
import { AsteriskIcon, BuildingsIcon, ChecklistBoxIcon, WebcamIcon, } from '@marvelapp/ballpark-icons';
import { pluralize } from '@marvelapp/core';
import { StudyTypeEnum } from '../../__generated__/queryTypes';
import { useRecruitmentState } from './RecruitmentContext';
export const RecruitmentSection = observer(function RecruitmentSection(_a) {
    var { children, className } = _a, rest = __rest(_a, ["children", "className"]);
    return (_jsx(Stack, Object.assign({ className: cn('overflow-hidden rounded-xl bg-white ring-1 ring-gray-600/10', className), direction: "col", width: "full" }, rest, { children: children })));
});
export const SectionHeading = observer(function SectionHeading({ copy, icon, isRequired, maxSelection, minSelection, }) {
    const selectionCopy = getSelectionCopy(minSelection !== null && minSelection !== void 0 ? minSelection : null, maxSelection !== null && maxSelection !== void 0 ? maxSelection : null);
    return (_jsxs(Stack, { align: "center", "data-testid": "section-heading", justify: "between", direction: "row", gap: "6", children: [_jsxs(Stack, { align: "center", className: "[&>svg:first-child]:size-5", direction: "row", gap: "2", children: [icon, _jsx(BtwText, { variant: "primary", size: "13", weight: "semibold", children: copy }), isRequired && (_jsx(AsteriskIcon, { className: "size-3 text-red-500", "data-testid": "required-filter" }))] }), _jsx(Stack, { children: selectionCopy && (_jsx(BtwText, { asChild: true, size: "xs", variant: "secondary", children: _jsx("p", { children: selectionCopy }) })) })] }));
});
export function getSelectionCopy(min, max) {
    let selectionCopy = '';
    if (min === max) {
        return '';
    }
    if (min) {
        selectionCopy += `min. ${min}`;
    }
    if (min && max) {
        selectionCopy += ', ';
    }
    if (max) {
        selectionCopy += `max. ${max}`;
    }
    return selectionCopy;
}
/**
 * This component will render two columns side by side.
 * any additional items will be rendered underneath
 */
export const ColumnLayout = observer(function ColumnLayout({ items, className, }) {
    return (_jsx(Stack, { direction: "col", width: "full", align: "stretch", className: cn('overflow-hidden outline outline-1 outline-gray-200 *:bg-white *:outline *:outline-1 *:outline-gray-200', className), wrap: "wrap", children: items.map((item) => (_jsxs(Stack, { className: "flex-1 basis-1/2 p-5", gap: "3", align: "stretch", children: [_jsx(SectionHeading, { copy: item.title, isRequired: item.isRequired, maxSelection: item.maxSelection, minSelection: item.minSelection }), item.component] }, item.title))) }));
});
const StudyTypeIconMap = {
    [StudyTypeEnum.VIDEO]: {
        icon: _jsx(WebcamIcon, {}),
        color: 'red',
    },
    [StudyTypeEnum.B2B]: {
        icon: _jsx(BuildingsIcon, {}),
        color: 'violet',
    },
    [StudyTypeEnum.SURVEY]: {
        icon: _jsx(ChecklistBoxIcon, {}),
        color: 'gray',
    },
};
export const StudyFocusIcon = observer(function StudyFocusIcon({ iconSize = '4', isDisabled, studyType, withBg, }) {
    const { studyType: selectedStudyType } = useRecruitmentState();
    const studyFocus = StudyTypeIconMap[studyType || selectedStudyType];
    if (!studyFocus)
        return null;
    const { color, icon } = studyFocus;
    return (_jsx(Stack, { className: cn(`[&>svg]:size-${iconSize}`, {
            'rounded-full p-2': withBg,
            'bg-red-100': color === 'red' && withBg,
            'text-red-500': color === 'red',
            'text-red-300': color === 'red' && isDisabled,
            'bg-violet-100': color === 'violet' && withBg,
            'text-violet-500': color === 'violet',
            'text-violet-400': color === 'violet' && isDisabled,
            'bg-gray-200': color === 'gray' && withBg,
            'text-gray-500': color === 'gray',
            'text-gray-400': color === 'gray' && isDisabled,
        }), children: icon }));
});
export const StudySummary = observer(function StudySummary() {
    return (_jsxs(Stack, { "data-testid": "study-summary", direction: "col", width: "full", gap: "1", className: "rounded-lg bg-gray-50 px-4 py-3 ring-1 ring-gray-600/20", children: [_jsxs(Stack, { direction: "row", justify: "between", width: "full", align: "center", children: [_jsx(BtwText, { size: "sm", weight: "semibold", variant: "primary", children: "Total" }), _jsx(TotalCredits, {})] }), _jsx(Accordion.Root, { type: "single", collapsible: true, children: _jsxs(Accordion.Item, { value: "total", className: "data-[state=open]:pb-0", children: [_jsx(Accordion.Trigger, { className: cn('w-full p-0 focus-visible:rounded hover:data-[state=closed]:bg-transparent', 
                            // The accordion trigger has a font-medium applied by default, this is the only way to override it
                            '[&>div>*]:font-normal'), "data-testid": "credit-breakdown-trigger", children: _jsxs(Stack, { direction: "row", justify: "between", width: "full", align: "center", children: [_jsx(ResponsesAndDuration, {}), _jsx(CreditsPerMinute, {})] }) }), _jsx(Accordion.Content, { className: "bg-transparent p-0 pt-2.5", children: _jsx(CreditBreakdown, {}) })] }) })] }));
});
const ResponsesAndDuration = observer(function ResponsesAndDuration() {
    const { numberOfResponses, duration } = useRecruitmentState();
    const durationTooltipText = useGetDurationTooltipText();
    const canSetDurationManually = useFeatureFlag('manual-recruitment-duration');
    return (_jsxs(BtwText, { variant: "primary", size: "13", className: "flex w-full flex-row items-center gap-1", children: [_jsx("span", { "data-testid": "num-of-responses", children: numberOfResponses }), _jsx("span", { children: "\u00D7" }), _jsx(BtwTooltip, { className: "max-w-64", content: durationTooltipText, "data-testid": "duration-tooltip", children: _jsxs("div", { className: cn('text-nowrap', {
                        'border-b border-dashed border-b-gray-600': !canSetDurationManually,
                    }), children: [_jsx("span", { "data-testid": "minutes-per-response", children: duration }), ' ', _jsx("span", { className: "shrink-0", children: `min ${pluralize(numberOfResponses, 'response')}` })] }) })] }));
});
function useGetDurationTooltipText() {
    const canSetDurationManually = useFeatureFlag('manual-recruitment-duration');
    const { duration, estimatedTimeToComplete, studyType, allowedDuration: { min: allowedMinDuration }, } = useRecruitmentState();
    if (canSetDurationManually)
        return;
    const durationMinutesCopy = `${estimatedTimeToComplete} ${pluralize(duration, 'minute')}`;
    return studyType !== StudyTypeEnum.SURVEY &&
        estimatedTimeToComplete < allowedMinDuration
        ? `Estimated time is ${durationMinutesCopy}. Enhanced orders start at a minimum of ${allowedMinDuration} ${pluralize(allowedMinDuration, 'minute')}. You can add more questions or proceed with your order as is.`
        : `We estimate this project to take ${durationMinutesCopy}, based on the number and type of steps used.`;
}
const CreditsPerMinute = observer(function CreditsPerMinute() {
    const { creditsPerMinute } = useRecruitmentState();
    return (_jsx(BtwText, { align: "right", asChild: true, className: "w-full", "data-testid": "credits-per-minute", size: "13", variant: "primary", children: _jsxs("p", { children: [creditsPerMinute, " ", pluralize(creditsPerMinute, 'credit'), "/minute"] }) }));
});
const TotalCredits = observer(function TotalCredits() {
    const { creditsRequired, hasEnoughCredits } = useRecruitmentState();
    return (_jsx(BtwText, { align: "right", className: "w-full", "data-testid": "total-credits", variant: hasEnoughCredits ? 'primary' : 'red', size: "sm", weight: "semibold", children: _jsxs("p", { children: [creditsRequired.toLocaleString(), " credits"] }) }));
});
export const CreditBreakdown = observer(function CreditBreakdown() {
    const { balanceAfterOrder, currentBalance, creditsRequired, hasEnoughCredits, } = useRecruitmentState();
    return (_jsxs(Stack, { "data-testid": "credit-breakdown", width: "full", gap: "2", children: [_jsx(OrderInfo, { copy: "Current balance", "data-testid": "current-balance", isNegative: currentBalance !== null && !hasEnoughCredits, value: currentBalance }), _jsx(OrderInfo, { copy: "Credits required", "data-testid": "credits-required", value: creditsRequired }), hasEnoughCredits && (_jsx(OrderInfo, { copy: "Balance after order", "data-testid": "new-balance", value: balanceAfterOrder }))] }));
});
const OrderInfo = observer(function OrderInfo(_a) {
    var { copy, isNegative, value } = _a, rest = __rest(_a, ["copy", "isNegative", "value"]);
    return (_jsxs(Stack, Object.assign({ align: "center", direction: "row", gap: "0.5", justify: "between", width: "full" }, rest, { children: [_jsx(BtwText, { size: "xs", variant: isNegative ? 'red' : 'secondary', children: copy }), _jsx(BtwText, { className: "w-full max-w-20 text-right", "data-testid": "value", size: "xs", variant: isNegative ? 'red' : 'primary', children: value !== null ? (value.toLocaleString()) : (_jsx("div", { className: "h-2 animate-pulse rounded-md bg-gray-300" })) })] })));
});
