import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import LogRocket from 'logrocket';
import { observer } from 'mobx-react';
import * as React from 'react';
import { ModalPortal } from '@marvelapp/ui';
import { InvalidDocError } from '../../components/InvalidDocError';
export const TextEditorErrorBoundary = observer(class TextEditorErrorBoundary extends React.Component {
    constructor() {
        super(...arguments);
        this.state = {
            error: undefined,
        };
    }
    static getDerivedStateFromError(error) {
        return { error };
    }
    componentDidCatch(error, errorInfo) {
        var _a, _b;
        console.log(error);
        console.log(errorInfo);
        if ((_a = process.env.DEPLOY_ENV) === null || _a === void 0 ? void 0 : _a.includes('production')) {
            LogRocket.captureException(error, {
                extra: { errorInfo: (_b = errorInfo.componentStack) !== null && _b !== void 0 ? _b : '' },
            });
        }
    }
    render() {
        if (this.state.error) {
            // Render the text editor even though it's bust so that log rocket
            // captures the DOM and we can, hopefully, have a look at before/after an
            // error and see what the changes are
            return (_jsxs(_Fragment, { children: [this.props.children, _jsx(ModalPortal, { children: _jsx(InvalidDocError, {}) })] }));
        }
        return this.props.children;
    }
});
