import styled from '@marvelapp/styled';
import { Box } from '@marvelapp/ui';
const InteractionBlocker = styled(Box) `
  pointer-events: none;
  input:focus,
  input:hover,
  textarea:focus,
  textarea:hover {
    outline: none;
    border-color: ${(props) => props.theme.colors.slate7};
    box-shadow: none;
  }
`;
export default InteractionBlocker;
