import { __rest } from "tslib";
import { jsx as _jsx } from "react/jsx-runtime";
import { observer } from 'mobx-react';
import * as React from 'react';
import { cn } from '@marvelapp/ballpark-components';
export const CardSortingCategoryGrid = observer(function CardSortingCategoryGrid(props) {
    const { className } = props, rest = __rest(props, ["className"]);
    return (_jsx("div", Object.assign({ className: cn('py-4', 'pr-4', 'pb-[76px]', 'grid', 'gap-4', 'max-h-full', 'overflow-auto', 'w-[calc(50%-8px)]', 'lg:w-[66%]', 'lg:grid-cols-2', 'xl:w-[75%]', 'xl:grid-cols-3', '2xl:w-[80%]', '2xl:grid-cols-4', 
        // This is needed to override some legacy CSS injected into the DOM
        // by the prototype viewer whcih gets applied on the .grid class
        'before:!hidden', '[&>div]:!float-none', className) }, rest)));
});
