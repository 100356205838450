var _ScrollSpyController_disabledAt;
import { __classPrivateFieldGet, __classPrivateFieldSet } from "tslib";
export class ScrollSpyController {
    constructor() {
        _ScrollSpyController_disabledAt.set(this, void 0);
        __classPrivateFieldSet(this, _ScrollSpyController_disabledAt, null, "f");
    }
    get isDisabled() {
        return !!__classPrivateFieldGet(this, _ScrollSpyController_disabledAt, "f");
    }
    get disabledSince() {
        if (!__classPrivateFieldGet(this, _ScrollSpyController_disabledAt, "f"))
            return null; // or throw?
        return Date.now() - __classPrivateFieldGet(this, _ScrollSpyController_disabledAt, "f");
    }
    disable() {
        __classPrivateFieldSet(this, _ScrollSpyController_disabledAt, Date.now(), "f");
    }
    enable() {
        __classPrivateFieldSet(this, _ScrollSpyController_disabledAt, null, "f");
    }
}
_ScrollSpyController_disabledAt = new WeakMap();
