import { jsx as _jsx } from "react/jsx-runtime";
import { observer } from 'mobx-react';
import { useAction } from '@marvelapp/ballpark-application';
import { BtwSkeleton, Stack, cn } from '@marvelapp/ballpark-components';
import { useRecruitmentState } from '../RecruitmentContext';
import { FilterButton, FilterRow } from './FilterButton';
import { ListWrapper } from './ListWrapper';
export const FiltersColumn = observer(function FiltersColumn() {
    const { filters, currentlyOpenDetailedFilterGroup } = useRecruitmentState();
    const currentFilterGroup = filters.other.filterGroups[currentlyOpenDetailedFilterGroup];
    const filterGroupsCount = filters.other.originalFilterGroupOrder.length;
    return (_jsx(Stack, { className: cn(
        // Base styles
        'w-full flex-1 bg-white', 
        // Classes for hover interaction on small screens
        'peer-hover:z-20 peer-hover:translate-x-1/2 peer-hover:shadow-md', 
        // Resetting responsive classes back to normal from sm > upwards
        ' sm:peer-hover:translate-x-0 sm:peer-hover:shadow-none'), children: !filters || !currentFilterGroup ? (_jsx(ListWrapper, { "data-testid": "filters-column", children: [0, 1, 2, 3, 4].map((val) => (_jsx(FilterRow, { children: _jsx("div", { children: _jsx(BtwSkeleton, { className: "h-4 w-full" }) }) }, val))) })) : (_jsx(ListWrapper, { "data-testid": "filters-column", children: currentFilterGroup.originalFilterOrder.map((filterId) => (_jsx(FilterElement, { filterId: filterId, showIcon: filterGroupsCount === 1 }, filterId))) })) }));
});
const FilterElement = observer(function FilterElement({ filterId, showIcon, }) {
    const recruitmentState = useRecruitmentState();
    const { currentlyOpenDetailedFilter, currentlyOpenDetailedFilterGroup, getFilterByGroupAndId, getHasValidationErrorByFilterId, getSelectedOptionsByFilterAndGroupId, } = recruitmentState;
    const hasValidationError = getHasValidationErrorByFilterId(filterId);
    const selectionCount = getSelectedOptionsByFilterAndGroupId(filterId, currentlyOpenDetailedFilterGroup).length;
    const setSelectedFilter = useAction((id) => {
        recruitmentState.currentlyOpenDetailedFilter = id;
    }, [recruitmentState]);
    if (!currentlyOpenDetailedFilter.length)
        return null;
    const isSelected = filterId === currentlyOpenDetailedFilter;
    const filter = getFilterByGroupAndId(currentlyOpenDetailedFilterGroup, filterId);
    return (_jsx(FilterButton, { hasValidationError: hasValidationError, showIcon: showIcon, isRequired: !!filter.isRequired, isSelected: isSelected, itemId: filterId, itemName: filter.name, selectionCount: selectionCount, setSelectedFilter: setSelectedFilter }));
});
