import { __rest } from "tslib";
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { observer } from 'mobx-react';
import * as React from 'react';
import { useIntl } from 'react-intl';
import { Button } from '@marvelapp/ballpark-components';
import { Box } from '@marvelapp/ui';
import constants from './constants';
import InstructionsIcon from './icons/InstructionsIcon';
export default observer(function OpenButton(_a) {
    var { onClick } = _a, rest = __rest(_a, ["onClick"]);
    const intl = useIntl();
    return (_jsxs(Button, Object.assign({ alignItems: "center", "data-testid": "open-instructions", display: "flex", kind: "ghost", marginRight: "xs", onClick: onClick, size: "s" }, rest, { children: [_jsx(InstructionsIcon, {}), _jsx(Box, { marginLeft: "xs", children: intl.formatMessage(constants.OPEN_INSTRUCTIONS) })] })));
});
