import { jsx as _jsx } from "react/jsx-runtime";
import { observer } from 'mobx-react';
import { BtwSkeleton, Stack, cn } from '@marvelapp/ballpark-components';
import { useRecruitmentState } from '../RecruitmentContext';
import { FilterButton, FilterRow } from './FilterButton';
import { ListWrapper } from './ListWrapper';
export const FilterGroupsColumn = observer(function FilterGroupsColumn() {
    const recruitmentState = useRecruitmentState();
    const { filters } = recruitmentState;
    const filterGroups = Object.keys(filters.other.filterGroups);
    if (filterGroups.length < 2)
        return null;
    return (_jsx(Stack, { className: cn(
        // Base classes
        'peer flex-1', 
        // Base responsive classes
        'absolute', 'left-0', 'z-30', 'h-full', '-translate-x-48', 'bg-gray-50', 'sm:bg-green-300', 'shadow-md', 'ring-gray-600/10', 
        // Interactive styles (for smallest viewport size)
        'hover:-translate-x-0', 'hover:bg-white', 'hover:shadow-xl', 'hover:ring-1', 
        // Resetting styles for all other viewport sizes
        'sm:relative', 'sm:hover:ring-0', 'sm:hover:shadow-none', 'sm:shadow-none', 'sm:bg-white', 'sm:-translate-x-0'), children: !filters ? (_jsx(ListWrapper, { "data-testid": "filter-groups-column", children: [0, 1, 2, 3, 4].map((val) => (_jsx(FilterRow, { children: _jsx("div", { children: _jsx(BtwSkeleton, { className: "h-4 w-full" }) }) }, val))) })) : (_jsx(ListWrapper, { "data-testid": "filter-groups-column", children: filterGroups.map((filterGroupKey) => (_jsx(FilterGroupRow, { groupId: filterGroupKey }, filterGroupKey))) })) }));
});
const FilterGroupRow = observer(function FilterGroupRow({ groupId, }) {
    const recruitmentState = useRecruitmentState();
    const { currentlyOpenDetailedFilterGroup, filters, getSelectedOptionsByFilterAndGroupId, selectedFiltersIds, updateCurrentlyOpenDetailedFilterGroup, } = recruitmentState;
    const filterGroup = filters.other.filterGroups[groupId];
    const selectionCount = selectedFiltersIds.reduce((selectedCount, filterId) => {
        if (filterGroup.filters[filterId]) {
            // eslint-disable-next-line no-param-reassign
            selectedCount += getSelectedOptionsByFilterAndGroupId(filterId, groupId).length;
        }
        return selectedCount;
    }, 0);
    return (_jsx(FilterButton, { isSelected: currentlyOpenDetailedFilterGroup === groupId, itemId: groupId, itemName: filterGroup.name, selectionCount: selectionCount, setSelectedFilter: updateCurrentlyOpenDetailedFilterGroup }));
});
