import styled from '@marvelapp/styled';
import { Flex } from '@marvelapp/ui';
const PrototypeStatsGrid = styled(Flex) `
  margin-bottom: ${(props) => props.theme.space.s}px;
  > div {
    flex: 1;
    margin-right: ${(props) => props.theme.space.s}px;

    &:last-child {
      margin-right: 0;
    }
  }
`;
export default PrototypeStatsGrid;
