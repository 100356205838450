import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { observer } from 'mobx-react';
import { useAction } from '@marvelapp/ballpark-application';
import { Badge, Combobox, Stack, cn } from '@marvelapp/ballpark-components';
import { CrossedIcon } from '@marvelapp/ballpark-icons';
import { pluralize } from '@marvelapp/core';
import { useRecruitmentState } from './RecruitmentContext';
import { ComboboxFilterWithVirtualList } from './VirtualizedList';
import { VIRTUALIZED_LIST_OPTION_COUNT_THRESHOLD, } from './types';
import { useUpdateSelectedFilterOptions } from './utils';
export const ComboboxFilter = observer(function ComboboxFilter({ filterGroupId, filterId, placeholder, }) {
    var _a;
    const { clearFilterById, getHasValidationErrorByFilterId, getSelectedOptionsByFilterAndGroupId, getFilterByGroupAndId, } = useRecruitmentState();
    const filter = getFilterByGroupAndId(filterGroupId, filterId);
    const isLargeDataSet = filter.optionCount &&
        filter.optionCount > VIRTUALIZED_LIST_OPTION_COUNT_THRESHOLD;
    const selectedOptionsCount = getSelectedOptionsByFilterAndGroupId(filterId, filterGroupId, !!isLargeDataSet).length;
    const triggerPlaceholder = placeholder || `Select ${pluralize(2, filter.name).toLowerCase()}`;
    const searchPlaceHolder = (placeholder === null || placeholder === void 0 ? void 0 : placeholder.includes('Select'))
        ? placeholder.replace('Select', 'Search for')
        : placeholder;
    const handleClearFilter = useAction(() => {
        clearFilterById(filter.id);
    }, [clearFilterById, filter.id]);
    const hasValidationError = getHasValidationErrorByFilterId(filterId);
    return (_jsxs(Stack, { "data-testid": `combobox-filter-${filterId}`, direction: "col", width: "full", gap: "4", children: [_jsxs(Combobox.Root, { onClear: handleClearFilter, selected: selectedOptionsCount, total: (_a = filter.optionCount) !== null && _a !== void 0 ? _a : filter.options.length, variant: "multi-select", children: [_jsx(Combobox.Trigger, { className: cn({ 'ring-1 ring-red-500': hasValidationError }), "data-testid": "combobox-filter-trigger", hasPlaceholder: true, width: "full", size: "intermediate", children: triggerPlaceholder }), isLargeDataSet ? (_jsx(ComboboxFilterWithVirtualList, { filterGroupId: filterGroupId, filterId: filterId })) : (_jsx(ListRendererWithEmptyState, { filterGroupId: filterGroupId, filterId: filterId, placeholder: searchPlaceHolder !== null && searchPlaceHolder !== void 0 ? searchPlaceHolder : `Search for ${pluralize(2, filter.name).toLowerCase()}` }))] }), _jsx(SelectedOptions, { filterGroupId: filterGroupId, filterId: filterId, isLargeDataSet: !!isLargeDataSet })] }));
});
const ListRendererWithEmptyState = observer(function ListRendererWithEmptyState({ filterGroupId, filterId, placeholder, }) {
    const { getFilterByGroupAndId } = useRecruitmentState();
    const filter = getFilterByGroupAndId(filterGroupId, filterId);
    return (_jsxs(Combobox.Content, { modal: true, placeholder: placeholder, children: [_jsx(Combobox.Empty, {}), filter.options.map((option) => {
                if (!option)
                    return null;
                return (_jsx(FilterOption, { filterGroupId: filterGroupId, filterId: filterId, option: option }, option.id));
            })] }));
});
const FilterOption = observer(function FilterOption({ filterGroupId, filterId, option, }) {
    const { getFilterByGroupAndId, getSelectedOptionsByFilterAndGroupId } = useRecruitmentState();
    const filter = getFilterByGroupAndId(filterGroupId, filterId);
    const selectedOptions = getSelectedOptionsByFilterAndGroupId(filterId, filterGroupId);
    const selectedIds = selectedOptions.map((o) => o.id);
    const isSelected = selectedIds.includes(option.id);
    const isDisabled = !!filter.maxSelection &&
        !isSelected &&
        selectedOptions.length >= filter.maxSelection;
    const updateFilterOptions = useUpdateSelectedFilterOptions(filterGroupId, filterId);
    const handleOnSelect = useAction(() => {
        updateFilterOptions(Object.assign(Object.assign({}, option), { filterId, groupId: filterGroupId, customValue: null }));
    }, [filterGroupId, filterId, option, updateFilterOptions]);
    return (_jsx(Combobox.Item, { check: "checkbox", "data-testid": `combobox-option-${option.id}`, disabled: isDisabled, onSelect: handleOnSelect, selected: isSelected, value: option.name, children: option.name }, option.id));
});
const SelectedOptions = observer(function SelectedAgeOptions({ filterGroupId, filterId, isLargeDataSet, }) {
    const { getFilterByGroupAndId, getSelectedOptionsByFilterAndGroupId } = useRecruitmentState();
    const selectedOptions = getSelectedOptionsByFilterAndGroupId(filterId, filterGroupId, true);
    if (!selectedOptions.length)
        return null;
    const filter = getFilterByGroupAndId(filterGroupId, filterId);
    return (_jsx(Stack, { direction: "row", gap: "1", wrap: "wrap", children: selectedOptions.map((option) => {
            var _a;
            const selectedOption = (_a = filter.options) === null || _a === void 0 ? void 0 : _a.find((filterOption) => (filterOption === null || filterOption === void 0 ? void 0 : filterOption.id) === option.id);
            // Some options might not exist when switching between study types.
            // Hence why we need to check if the selected option exists on the filter.
            // However, when dealing with large data sets, the selected option might
            // not be stored in local state. In this case, we're happy to assume it
            // probably exists
            if (!isLargeDataSet && !selectedOption)
                return null;
            return (_jsx(SelectedOption, { filterGroupId: filterGroupId, filterId: filterId, isLargeDataSet: isLargeDataSet, option: option }, option.id));
        }) }));
});
const SelectedOption = observer(function SelectedOption({ filterGroupId, filterId, isLargeDataSet, option, }) {
    var _a;
    const { getFilterByGroupAndId } = useRecruitmentState();
    const filter = getFilterByGroupAndId(filterGroupId, filterId);
    const selectedOption = (_a = filter.options) === null || _a === void 0 ? void 0 : _a.find((filterOption) => (filterOption === null || filterOption === void 0 ? void 0 : filterOption.id) === option.id);
    const updateSelectedOptions = useUpdateSelectedFilterOptions(filterGroupId, filterId);
    const handleOnClick = useAction(() => {
        updateSelectedOptions(Object.assign(Object.assign({}, option), { filterId, customValue: null }));
    }, [filterId, option, updateSelectedOptions]);
    // Some options might not exist when switching between study types.
    // Hence why we need to check if the selected option exists on the filter.
    // However, when dealing with large data sets, the selected option might
    // not be stored in local state. In this case, we're happy to assume it
    // probably exists
    if (!isLargeDataSet && !selectedOption)
        return null;
    return (_jsx(Badge, { className: "max-w-md cursor-pointer", "data-testid": "selected-option", onClick: handleOnClick, trailingIcon: _jsx(CrossedIcon, {}), children: _jsx("p", { className: "truncate", children: option.name }) }));
});
