import { CollectionOperator, Combinator, IdentityOperator, OrdinalOperator, } from '../../../userTest/types';
import { isValidRule } from './clearInvalidConditions';
export function getNextStepUUID(rules, answer, always) {
    // eslint-disable-next-line no-restricted-syntax
    for (const rule of rules) {
        if (!isValidRule(rule)) {
            // eslint-disable-next-line no-continue
            continue; // TODO maybe cleaner to filter invalid rules and requirements out before this function is called
        }
        const grouped = groupByPrecedence(rule.requirements);
        // check if the expression is satisfied
        const satisfied = grouped.some((group) => {
            // if one the requirement "groups" are satisfied, return rule's destUUID
            return group.every((requirement) => {
                const value = 'value' in requirement
                    ? requirement.value
                    : requirement.selectedChoiceUUID;
                if (Array.isArray(answer)) {
                    // there's a quirk with multiple choice answers as we use the IS/ISNOT
                    // operators when we really mean CONTAINS/DOESNOTCONTAIN
                    return testAnswer(answer, requirement.operator === IdentityOperator.Is
                        ? CollectionOperator.Contains
                        : CollectionOperator.DoesNotContain, value);
                }
                return testAnswer(answer, requirement.operator, value);
            });
        });
        if (satisfied) {
            return rule.destinationStepUUID;
        }
    }
    if (always) {
        return always;
    }
    return null;
}
function groupByPrecedence(tests) {
    // TODO can I type this to say it returns only valid requirements?
    const grouped = [[]];
    tests.forEach((test) => {
        if (test.combinator === Combinator.Or) {
            grouped.push([]);
        }
        grouped[grouped.length - 1].push(test);
    });
    return grouped;
}
function testAnswer(answer, operator, value) {
    if (value === null) {
        return false; // no answer can match a null value
    }
    if (typeof value === 'string' && value === '') {
        return false; // no answer can match an empty string
    }
    if (operator === IdentityOperator.Is) {
        if (typeof value === 'string' && typeof answer === 'string') {
            return answer.toLocaleLowerCase() === value.toLocaleLowerCase();
        }
        return answer === value;
    }
    if (operator === IdentityOperator.IsNot) {
        if (typeof value === 'string' && typeof answer === 'string') {
            return answer.toLocaleLowerCase() !== value.toLocaleLowerCase();
        }
        return answer !== value;
    }
    if (operator === IdentityOperator.IsOkay) {
        return true;
        // TODO: this may not be needed, if we change the logic to not look use every requirement satisfied
    }
    if (typeof value === 'string' &&
        (typeof answer === 'string' || Array.isArray(answer))) {
        if (operator === CollectionOperator.Contains) {
            if (typeof answer === 'string' && typeof value === 'string') {
                return answer.toLocaleLowerCase().includes(value.toLocaleLowerCase());
            }
            return answer.includes(value);
        }
        if (operator === CollectionOperator.DoesNotContain) {
            if (typeof answer === 'string' && typeof value === 'string') {
                return !answer.toLocaleLowerCase().includes(value.toLocaleLowerCase());
            }
            return !answer.includes(value);
        }
    }
    if (typeof value === 'number' && typeof answer === 'number') {
        if (operator === OrdinalOperator.IsLessThan) {
            return answer < value;
        }
        if (operator === OrdinalOperator.IsGreaterThan) {
            return answer > value;
        }
        if (operator === OrdinalOperator.IsLessOrEqualTo) {
            return answer <= value;
        }
        if (operator === OrdinalOperator.IsGreaterOrEqualTo) {
            return answer >= value;
        }
    }
    return false;
}
