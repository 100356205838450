import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { noop } from 'lodash-es';
import { forwardRef, useState } from 'react';
import { CARET_ICON, SEARCH_ICON } from '@marvelapp/ballpark-theme';
import { Box, Flex, Icon, Input, PinTL, Position, SearchCloseButton, } from '@marvelapp/ui';
/** @type {any} */
const SimpleSearchInput = forwardRef(({ boxShadow = 'input.defaultTransparent', renderIcon = (color) => (_jsx(Icon, { color: color, display: "block", height: 24, paths: SEARCH_ICON, width: 24 })), id, isDisabled = false, isLoading, onBlur = noop, onChange, onClear, onFocus = noop, placeholder, hasBorder = true, showClearButton, size = 36, value, showListCaret = false, testId = 'search-input', truncated, }, ref) => {
    const [iconColor, setIconColor] = useState('slate');
    const [isFocused, setIsFocused] = useState(false);
    const onInputFocus = () => {
        onFocus();
        setIsFocused(true);
        setIconColor('marvel');
    };
    const onInputBlur = () => {
        onBlur();
        setIsFocused(false);
        setIconColor('slate');
    };
    const icon = renderIcon(iconColor);
    return (_jsxs(Position, { position: "relative", children: [icon && (_jsx(PinTL, { children: _jsx(Flex, { alignItems: "center", height: size, justifyContent: "center", width: size, children: icon }) })), _jsx(Input, { boxShadow: hasBorder ? boxShadow : 'none', hoverBoxShadow: hasBorder ? 'input.defaultHover' : 'none', "data-testid": testId, disabled: isLoading || isDisabled, height: size, id: id, onBlur: onInputBlur, onChange: onChange, onFocus: onInputFocus, paddingLeft: size, paddingRight: "l", placeholder: placeholder, ref: ref, size: 2, value: value, width: 1, truncated: truncated }), showClearButton && _jsx(SearchCloseButton, { onClick: onClear }), showListCaret && !showClearButton && (_jsx(CaretIcon, { isFocused: isFocused }))] }));
});
export default SimpleSearchInput;
function CaretIcon({ isFocused }) {
    return (_jsx(Box, { position: "absolute", px: 0, right: "6px", top: "50%", transform: "translateY(-50%)", pointerEvents: "none", display: "flex", children: _jsx(Icon, { paths: CARET_ICON, style: isFocused ? { marginRight: '2px', transform: 'rotate(180deg)' } : {} }) }));
}
