import { jsx as _jsx } from "react/jsx-runtime";
import { observer } from 'mobx-react';
import { useIntl } from 'react-intl';
import { Link, Text } from '@marvelapp/ballpark-components';
import { ENABLE_SCREEN_SHARING_AND_CAMERA_ACCESS } from '@marvelapp/ballpark-help';
import { LiNumbered, List } from '@marvelapp/ui';
const WebcamPermissionDeniedErrorList = observer(function WebcamPermissionDeniedErrorList() {
    const intl = useIntl();
    const items = [
        {
            label: intl.formatMessage({ id: "Fx+xzA", defaultMessage: "Check if your webcam is plugged in and isn't obstructed" }),
        },
    ];
    const browserSettingToEnableLink = getBrowserSettingsLink();
    if (browserSettingToEnableLink)
        items.push({
            label: intl.formatMessage({ id: "+zuT20", defaultMessage: "Check your browser settings" }),
            href: browserSettingToEnableLink,
        });
    return (_jsx(List, { size: 1, type: "ordered", children: items.map((item, index) => (_jsx(LiNumbered, { number: index + 1, size: 2, children: item.href ? (_jsx(Link, { href: item.href, kind: "primary", rel: "noopener noreferrer", target: "_blank", underline: true, lineHeight: "base", fontSize: [17, 17, 19, 19], size: "m", children: item.label })) : (_jsx(Text, { fontSize: [17, 17, 19, 19], size: "m", children: item.label })) }, item.href))) }));
});
function getBrowserSettingsLink() {
    return ENABLE_SCREEN_SHARING_AND_CAMERA_ACCESS;
}
export default WebcamPermissionDeniedErrorList;
