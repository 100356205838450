import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { observer } from 'mobx-react';
import { BtwButton, BtwModal } from '@marvelapp/ballpark-components';
import { pluralize } from '@marvelapp/core';
export const ConfirmDelete = observer(function ConfirmDelete({ onDelete, selectedResultsCount, }) {
    const conditionalArticle = selectedResultsCount > 1 ? '' : 'the';
    const pluralizedResponses = pluralize(selectedResultsCount, 'response');
    return (_jsxs(_Fragment, { children: [_jsx(BtwModal.BigHeader, { onlyChild: true, title: `
        Delete
        ${selectedResultsCount > 1 ? selectedResultsCount : ''}
        ${pluralizedResponses}
      `, description: `
        You will lose all data associated with
        ${conditionalArticle} selected ${pluralizedResponses}.
        Are you sure you want to continue?
      ` }), _jsxs(BtwModal.Footer, { children: [_jsx(BtwModal.Close, { asChild: true, children: _jsx(BtwButton, { variant: "secondary", "data-testid": "cancel-delete-test-results", children: "Cancel" }) }), _jsxs(BtwButton, { onClick: onDelete, variant: "danger", "data-testid": "confirm-delete-test-results", children: ["Delete ", selectedResultsCount > 1 ? selectedResultsCount : '', ' ', pluralize(selectedResultsCount, 'response')] })] })] }));
});
