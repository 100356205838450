import { __rest } from "tslib";
import { jsx as _jsx } from "react/jsx-runtime";
import { observer } from 'mobx-react';
import * as React from 'react';
import { StepClass, StepUtils, TextUtils } from '@marvelapp/user-test-creator';
import { useStepContext } from '../../../utils/stepContext';
import { ChoiceBar } from '../ChoiceBar';
import { SummaryStep } from './SummaryStep';
export default observer(function SummaryTagline(_a) {
    var { active, secondary, showAllLink, sortedChoices, stats } = _a, rest = __rest(_a, ["active", "secondary", "showAllLink", "sortedChoices", "stats"]);
    const { step } = useStepContext();
    if (!StepUtils.isOfType(step, StepClass.TaglineCopyTest))
        return null;
    return (_jsx(SummaryStep, Object.assign({ active: active, secondary: secondary, showAllLink: showAllLink }, rest, { children: stats &&
            sortedChoices
                .filter((uuid) => {
                const tagline = step.choiceDefinitions[uuid];
                if (!tagline)
                    return false;
                const { heading, paragraph } = tagline;
                const isParagraphEmpty = TextUtils.checkIfValueIsEmpty(paragraph);
                return !!heading || !isParagraphEmpty;
            })
                .map((uuid, index) => {
                const answer = stats.find((stat) => (stat === null || stat === void 0 ? void 0 : stat.answerValue) === uuid);
                const { heading, paragraph } = step.choiceDefinitions[uuid];
                const title = heading || TextUtils.richTextToString(paragraph);
                return (_jsx(ChoiceBar, { participants: (answer === null || answer === void 0 ? void 0 : answer.answerCount) ? answer.answerCount : 0, percent: (answer === null || answer === void 0 ? void 0 : answer.percentageChosen) ? answer.percentageChosen : 0, title: title, tagline: { heading, choiceUUID: uuid, index, paragraph } }, uuid));
            }) })));
});
