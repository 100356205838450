import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { observer } from 'mobx-react';
import * as React from 'react';
import { Button } from '@marvelapp/ballpark-components';
import styled, { css } from '@marvelapp/styled';
import { Box, Loader, PinCenter } from '@marvelapp/ui';
import { getOptimisedContainedImageUrl } from '../../utils/image';
export default observer(function ContainedImage({ image, onImageClick, maxWidth, maxHeight, cursor, }) {
    const [isImageLoaded, setIsImageLoaded] = React.useState(false);
    const optimisedUrl = image
        ? getOptimisedContainedImageUrl(image, {
            width: maxWidth,
            height: maxHeight,
        })
        : '';
    return (_jsxs(Container, { hasButton: onImageClick, cursor: cursor, "data-testid": "image-container", children: [!isImageLoaded && (_jsx(PinCenter, { children: _jsx(Loader, {}) })), _jsx("div", { style: { touchAction: 'pan-x pan-y pinch-zoom' }, children: _jsx(Image, { "data-testid": "contained-image", alt: "", src: optimisedUrl, onLoad: () => setIsImageLoaded(true), style: { display: isImageLoaded ? 'block' : 'none' } }) }), onImageClick && (_jsx(HiddenButton, { children: _jsx(PinCenter, { pointerEvents: "all", children: _jsx(Button, { size: "s", onClick: onImageClick, kind: "negative", "data-testid": "remove-decorative-image", children: "Remove" }) }) }))] }));
});
const HiddenButton = styled(Box).attrs({
    opacity: [1, 1, 1, 0],
    visibility: ['visible', 'visible', 'visible', 'hidden'],
}) ``;
// Here I am not using (one of) our standard image components -- copied this approach
// from the CheckboxImage.tsx file. Not sure why that didn't use ui/Image.
const Image = styled.img `
  display: block;
  max-width: 100%;
  max-height: 100%;
  border-radius: 2px;
  pointer-events: all;
  position: relative;
  margin: auto;
  top: 50%;
  transform: translateY(-50%);
`;
/** Copied CSS techniques from CheckboxImage.tsx
 *
 * Using max-width: 100%; max-height: 100%; on an image will fit it to the container
 * and maintain the aspect ratio (I initially tried using object-fit: contain, but
 * that leaves the image element as the size of the container -- you can't target the
 * 'inner dimensions' of the fitted image with CSS).
 *
 * Couldn't figure out if it's possible to pin a delete button to the top right of the image
 * (using onyl css) so Murat was ok with a centred delete button (revealed on hover of course).
 * Another little trick of putting 'pointer-events: none' on the container means the hover
 * style to reveal the button is only triggered when you're over the image -- sneaky!!
 */
const Container = styled(Box) `
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  position: relative;
  pointer-events: none;
  &:hover ${HiddenButton} {
    opacity: 1;
    visibility: visible;
  }
  ${(props) => props.cursor &&
    css `
      &:hover ${Image} {
        cursor: ${props.cursor};
      }
    `}
  ${(props) => props.hasButton &&
    css `
      &:hover ${Image} {
        box-shadow: ${props.theme.shadows.card.default};
        filter: brightness(40%);
      }
    `}
`;
