import { jsx as _jsx } from "react/jsx-runtime";
import { observable, runInAction } from 'mobx';
import { observer, useLocalObservable } from 'mobx-react';
import { useLayoutEffect } from 'react';
import * as React from 'react';
import { SlideUIContextProvider, hasStep, } from '@marvelapp/ballpark-application';
import { getStepByUUID, } from '@marvelapp/user-test-creator';
const getSlideState = ({ step, fixedStepType }) => ({
    fixedStepType,
    step,
});
export const CardContainer = observer(function CardContainer({ userTest, uuid, fixedStepType, children, }) {
    const step = getStepByUUID(userTest, uuid);
    const cache = useLocalObservable(() => {
        return getSlideState({
            fixedStepType,
            step,
        });
    }, 
    // We don't need to convert step to an observable as its already observable
    { step: observable.ref });
    // For some reason when we reconnect to RUS (after tabbing away and back) the
    // `step` reference is not stable. We're going to look into but, for now, a
    // quick workaround is to update the cache when step changes (using
    // useLayoutEffect otherwise you can see the page briefly render in a broken state)
    useLayoutEffect(() => runInAction(() => {
        cache.step = step;
    }), [cache, step]);
    if (!hasStep(cache))
        return null;
    return (_jsx(SlideUIContextProvider, { slideProps: cache, children: children }));
});
