import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { observer } from 'mobx-react';
import { MoreCollaborators, Player, Tooltip, } from '@marvelapp/ballpark-components';
import { useCollaborators } from '@marvelapp/ballpark-realtime';
import styled from '@marvelapp/styled';
import { Box } from '@marvelapp/ui';
export const SlidePresenceAvatars = observer(function SlidePresenceAvatars({ sidebarSlideId, }) {
    const activeCollaborators = useCollaborators();
    const collaboratorsOnStep = activeCollaborators.filter((collab, index) => {
        // remove self from slides list
        if (index === 0) {
            return false;
        }
        return collab.selection === sidebarSlideId;
    });
    const MAX_AVATARS = 5;
    const moreThanMax = collaboratorsOnStep.length > MAX_AVATARS;
    const noOfRemainingCollaborators = moreThanMax
        ? collaboratorsOnStep.length - MAX_AVATARS
        : 0;
    return (_jsxs(AvatarContainer, { children: [collaboratorsOnStep
                .slice(0, MAX_AVATARS)
                .map(({ color, id, name, avatarUrl }, index) => (_jsx(Box, { mr: "-10px", position: "relative", zIndex: MAX_AVATARS - index + 1, children: _jsx(Player, { tooltipPosition: "top", activeColor: color, name: name, avatarUrl: avatarUrl }) }, id))), moreThanMax && (_jsx(Box, { mr: "-6px", position: "relative", zIndex: 0, children: _jsx(Tooltip.Quick, { content: `And ${noOfRemainingCollaborators} more`, 
                    // cursor="pointer"
                    side: "top", children: _jsx(MoreCollaborators, { num: noOfRemainingCollaborators }) }) }))] }));
});
const AvatarContainer = styled(Box) `
  display: flex;
  align-items: center;
  justify-content: center;
  transition: transform 0.5s;
  position: absolute;
  left: calc(50% - 32px);

  &:hover {
    transform: translate(0, -50%);
  }
`;
