import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { observer } from 'mobx-react';
import * as React from 'react';
import { useIntl } from 'react-intl';
import { useSlideUIContext } from '@marvelapp/ballpark-application';
import { Heading } from '@marvelapp/ballpark-components';
import styled from '@marvelapp/styled';
import { Box } from '@marvelapp/ui';
export default observer(function SlideControlsHeading({ getStepName, getStepIcon, }) {
    const { step, fixedStepType } = useSlideUIContext();
    const intl = useIntl();
    return (_jsxs(Inline, { children: [getStepIcon(step, fixedStepType), _jsx(Heading, { paddingTop: "1px", fontSize: "14px", marginLeft: "9px", lineHeight: "base", fontWeight: "medium", children: intl.formatMessage(getStepName(step, fixedStepType)) })] }));
});
const Inline = styled(Box).attrs({
    paddingLeft: 9,
}) `
  display: flex;
  align-items: center;
`;
