import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { $$TARGET } from '@ballpark/realtime-crdt';
import { useOnHistoryReplay, } from '@ballpark/realtime-plugin-history';
import { cloneDeep } from 'lodash-es';
import { reaction } from 'mobx';
import { observer } from 'mobx-react';
import { useCallback, useEffect, useState } from 'react';
import * as React from 'react';
import { useIntl } from 'react-intl';
import { Map as YMap } from 'yjs';
import { useAction, useSlideUIContext } from '@marvelapp/ballpark-application';
import { Badge, Button, EditableText, cn, } from '@marvelapp/ballpark-components';
import { UI_COMMON_STEPS_COPY, UI_STEPS_COPY, formatStringOrMessage, } from '@marvelapp/ballpark-copy';
import { AsteriskIcon } from '@marvelapp/ballpark-icons';
import styled from '@marvelapp/styled';
import { TextEditor } from '@marvelapp/text-editor';
import { Box } from '@marvelapp/ui';
import { StepClass, StepUtils, } from '@marvelapp/user-test-creator';
import { useUIContext } from '../../pages/Project/uiContext';
import { useProjectContext } from '../../utils/ProjectContext';
import AddVideoControlGuides from './AddVideoControlGuides';
import { SlidesBadge } from './SlidesBadge';
import { TextEditorErrorBoundary } from './TextEditorErrorBoundary';
const SlideContent = observer(function SlideContent({ children, ctaText = UI_COMMON_STEPS_COPY.buttonText, ctaTestId = 'builder-main-cta', ctaOnClick, maxHeadingLength, withSlideCount = true, withRequiredBadge = true, withVideoGuide = true, }) {
    const intl = useIntl();
    const { focusSlideUUID } = useUIContext();
    const { step, fixedStepType } = useSlideUIContext();
    const { getStepKey } = useProjectContext();
    const screenerStepKey = getStepKey(step.uuid);
    const setTitle = useAction((value) => {
        StepUtils.setTitle(step, value);
    }, [step]);
    const setDescription = useAction((value) => {
        StepUtils.setDescription(step, value);
    }, [step]);
    // We need to convert the description to a plain object as Plate doesn't
    // handle proxies.
    const [descriptionValue, setDescriptionValue] = useState(() => cloneDeep((step === null || step === void 0 ? void 0 : step.description) || []));
    const isFiveSecondStep = step.className === StepClass.FiveSecondTest;
    // Update the value we pass to TextEditor if the description was edited by another user
    useEffect(() => reaction(() => step === null || step === void 0 ? void 0 : step.description, (value) => {
        var _a, _b;
        if (!value)
            return;
        // TODO: add to @ballpark/realtime-crdt methods for detecting if a value was last changed by this
        // client
        const yValue = value[$$TARGET];
        const currentId = (_a = yValue === null || yValue === void 0 ? void 0 : yValue.doc) === null || _a === void 0 ? void 0 : _a.clientID;
        const lastId = (_b = yValue === null || yValue === void 0 ? void 0 : yValue._item) === null || _b === void 0 ? void 0 : _b.lastId.client;
        // if the value was last changed by a different client, update it, this will trigger a
        // re-render of the TextEditor and show their changes
        if (currentId !== lastId) {
            setDescriptionValue(cloneDeep(value));
        }
    }), [step === null || step === void 0 ? void 0 : step.description, descriptionValue]);
    // Check if the history event targeted the description, force an update if so. This is necessary
    // because normally changes in value don't trigger a re-render due to the way plate/slate works.
    const updateIfChanged = useCallback((event) => {
        // This is a guard, TS disagrees but I've seen this fire with a step that didn't have a
        // description.
        if (step.description === undefined)
            return;
        const yValue = step.description[$$TARGET];
        const hasChanged = [...event.changedParentTypes.values()].some((value) => {
            return value.some((event) => {
                return [...event.transaction.changed.entries()].some(([target, keys]) => {
                    if (!(target instanceof YMap))
                        return false;
                    return [...keys].some((key) => {
                        if (!key)
                            return;
                        return target.get(key) === yValue;
                    });
                });
            });
        });
        if (!hasChanged)
            return;
        setDescriptionValue(cloneDeep(step.description));
    }, [step, setDescriptionValue]);
    useOnHistoryReplay(updateIfChanged);
    return (_jsxs(_Fragment, { children: [_jsxs(BadgeWrapper, { children: [withSlideCount && _jsx(SlidesBadge, {}), step.isRequired && withRequiredBadge && (_jsx(Badge, { variant: "red", "data-testid": "required-badge", leadingIcon: _jsx(AsteriskIcon, {}), size: "sm", className: "ml-2", children: "Required" }))] }), isFiveSecondStep && displayBadge(step), _jsxs(TextWrapper, { children: [_jsx(EditableText, { value: step.title || undefined, maxLength: maxHeadingLength, onSaveValue: setTitle, placeholder: intl.formatMessage(fixedStepType
                            ? UI_STEPS_COPY[fixedStepType].titlePlaceholder
                            : UI_COMMON_STEPS_COPY.titlePlaceholder), size: "2xl", "data-testid": "edit-main-text", weight: "bold", className: cn({ 'mb-3': !withVideoGuide }), 
                        // autoFocus focuses the control. This focusSlideUUID is set when scrolling finishes
                        // after adding a new step. This ensures that that the control is focused when scrolling completes
                        autoFocus: step.uuid === focusSlideUUID }, screenerStepKey), withVideoGuide && (_jsx("div", { className: "my-3", children: _jsx(AddVideoControlGuides, {}) })), _jsx(TextEditorErrorBoundary, { children: _jsx(TextEditor, { size: { initial: 'sm', lg: 'base' }, id: `${step.uuid}-text-editor`, onChange: setDescription, placeholder: intl.formatMessage(step.className === StepClass.PrototypeTask
                                ? UI_STEPS_COPY[StepClass.PrototypeTask]
                                    .descriptionPlaceholder
                                : UI_COMMON_STEPS_COPY.descriptionPlaceholder), resetValueOnChange: true, testId: 'slide-text-editor', value: descriptionValue }, screenerStepKey) })] }), children, ctaOnClick && (_jsx(Button, { "data-testid": ctaTestId, marginTop: "s", onClick: ctaOnClick, size: "m", children: formatStringOrMessage(intl, ctaText) }))] }));
});
function displayBadge(step) {
    const text = `Display image for ${step.timer} seconds`;
    return (_jsx(Badge, { variant: "gray", "data-testid": "slide-text", className: "mb-2", children: text }));
}
const BadgeWrapper = styled(Box).attrs({
    marginBottom: 's',
}) `
  display: flex;
  align-items: center;
`;
const TextWrapper = styled(Box) `
  width: 100%;
  max-width: 720px;
  display: flex;
  flex-direction: column;
`;
export default SlideContent;
