import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { observer } from 'mobx-react';
import { forwardRef } from 'react';
import * as React from 'react';
import styled, { css } from '@marvelapp/styled';
import { AspectRatio, Box } from '@marvelapp/ui';
const DottedBox = observer(forwardRef(function DottedBox({ as, aspectRatio, children, htmlFor, isActive, isDisabled, onClick, testId, role, height = '100%', }, ref) {
    return (_jsx(Ratio, { aspectRatio: aspectRatio, children: _jsx(ScreenPlaceholder, { as: as, role: role, "aria-disabled": isDisabled, bg: isDisabled ? 'slate2' : 'background', "data-testid": testId, hoverBg: isDisabled ? undefined : 'slate2', hoverBorderColor: isDisabled ? undefined : 'slate7', hoverBoxShadow: isDisabled ? undefined : 'dottedCard.hover', htmlFor: htmlFor, isActive: isActive, isDisabled: isDisabled, onClick: isDisabled ? undefined : onClick, ref: ref, height: height, children: children }) }));
}));
const Ratio = observer(function Ratio({ aspectRatio, children, }) {
    if (!aspectRatio) {
        return _jsx(_Fragment, { children: children });
    }
    return (_jsx(AspectRatio, { ratio: aspectRatio, width: "100%", children: children }));
});
const ScreenPlaceholder = styled(Box).attrs((props) => ({
    bg: props.isActive ? 'blue2' : 'background',
    border: props.isActive ? '1px solid' : '1px dashed',
    borderColor: props.isActive ? 'blue8' : 'slate6',
    borderRadius: props.isActive ? 'xl' : 'l',
    boxShadow: props.isActive ? 'typeCard.active' : 'none',
    paddingX: 's',
    paddingY: 'm',
    transform: props.isActive ? 'scale3d(1.05, 1.05, 1.05)' : 'scale3d(1, 1, 1)',
    transition: 'smooth.fast',
})) `
  width: 100%;

  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  will-change: transform;
  user-select: none;

  ${(props) => !props.isDisabled &&
    css `
      cursor: pointer;
      &:hover {
        transform: translate3d(0, -1px, 0);
      }
      &:active {
        transform: translate3d(0, 1px, 0);
      }
    `}
`;
export default DottedBox;
