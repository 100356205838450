import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { observer } from 'mobx-react';
import * as React from 'react';
import { Icon, Text } from '@marvelapp/ballpark-components';
import { ARROW_DOWN_ICON } from '@marvelapp/ballpark-theme';
import styled from '@marvelapp/styled';
import { Box, PinCenter } from '@marvelapp/ui';
import { ResponseStatus } from '../../graphql/graphqlTypes';
export const SkippedStepsDivider = observer(function SkippedStepsDivider({ screeningStatus, }) {
    const dividerCopy = screeningStatus === ResponseStatus.ScreeningDeclined
        ? 'Screened out'
        : 'Dropped off';
    return (_jsx(Container, { "data-testid": "drop-off-indicator", children: _jsx(PinCenter, { children: _jsxs(Text, { bg: "slate2", border: "1px dashed", borderColor: "slate8", borderRadius: "m", padding: "s", size: "s", lineHeight: "base", display: "flex", alignItems: "center", children: [_jsx(Icon, { marginRight: "xxs", fill: "slate8", paths: ARROW_DOWN_ICON }), dividerCopy, " before seeing these steps", _jsx(Icon, { marginLeft: "xxs", fill: "slate8", paths: ARROW_DOWN_ICON })] }) }) }));
});
const Container = styled(Box).attrs({
    bg: 'slate7',
    marginY: 'xxl',
    marginX: 'auto',
}) `
  width: 100%;
  height: 1px;
  max-width: 702px;
  position: relative;

  &:first-child {
    margin-top: ${(props) => props.theme.space.s}px;
  }
`;
