import { __rest } from "tslib";
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { observer } from 'mobx-react';
import { useCallback, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useAction } from '@marvelapp/ballpark-application';
import { Button, Stack } from '@marvelapp/ballpark-components';
import { SUBMIT_STEP_COPY, UI_COMMON_STEPS_COPY, } from '@marvelapp/ballpark-copy';
import { deviceDetection, isSafeURL } from '@marvelapp/core';
import { Box } from '@marvelapp/ui';
import { WebsiteTaskUtils, isSubmitStep, } from '@marvelapp/user-test-creator';
import { useRecorderState } from '../../components/Record/RecorderContext';
import SlideRecord from '../common/SlideRecord';
import WebsiteTaskButton from './WebsiteTaskButton';
import WebsiteTaskInfoBlock from './WebsiteTaskInfoBlock';
// https://stackoverflow.com/a/2315916/63011
const newWindowTarget = '_blank';
const newWindowFeatures = 'toolbar=0,location=0,menubar=0';
export default observer(function WebsiteTaskRecord(_a) {
    var { step, stepResponse } = _a, rest = __rest(_a, ["step", "stepResponse"]);
    const intl = useIntl();
    const recorderState = useRecorderState();
    const isFinalStep = isSubmitStep(recorderState.userTestDoc, step.uuid);
    const [hasTaskStarted, setHasTaskStarted] = useState(false);
    const markTestAsCompleted = useAction(() => {
        WebsiteTaskUtils.setSkipped(stepResponse, false);
    }, [stepResponse]);
    const closeRecordingWindow = useCallback(() => {
        const recordingWindow = recorderState.websiteTaskWindow;
        if (!recordingWindow)
            return;
        if (!recordingWindow.closed) {
            recordingWindow.close();
            recorderState.websiteTaskWindow = null;
        }
    }, [recorderState]);
    const openNewWindow = useCallback(() => {
        if (!step.websiteUrl || !isSafeURL(step.websiteUrl))
            return;
        const { innerWidth, innerHeight, screenLeft, screenTop } = window;
        const margin = 10;
        // 1024px is a good size where most websites should be functional but still leave room on most
        // screens for the user to interact with the left side of the screen in our application
        const targetWidth = 1024;
        const targetHeight = innerHeight - margin * 2;
        const left = screenLeft + innerWidth - targetWidth - margin;
        const top = screenTop + 10;
        recorderState.websiteTaskWindow = window.open(step.websiteUrl, newWindowTarget, `${newWindowFeatures}, width=${targetWidth}, height=${targetHeight}, left=${left}, top=${top}`);
    }, [step.websiteUrl, recorderState]);
    const onStopTask = useCallback(() => {
        recorderState.goForward();
        if (isFinalStep) {
            closeRecordingWindow();
        }
    }, [closeRecordingWindow, isFinalStep, recorderState]);
    const onStopTest = useCallback(() => {
        onStopTask();
        markTestAsCompleted();
    }, [onStopTask, markTestAsCompleted]);
    const startWebsiteTask = useAction(() => {
        if (!step.websiteUrl || !isSafeURL(step.websiteUrl))
            return;
        if (!deviceDetection.isIOS && // a bug in iOS causes window.focus() to not work in a lot of cases
            recorderState.websiteTaskWindow &&
            !recorderState.websiteTaskWindow.closed) {
            recorderState.websiteTaskWindow.location.href = step.websiteUrl;
            recorderState.websiteTaskWindow.focus();
        }
        else {
            openNewWindow();
        }
        setHasTaskStarted(true);
    }, [step.websiteUrl, recorderState.websiteTaskWindow, openNewWindow]);
    const reopen = useCallback(() => {
        if (!deviceDetection.isIOS && // a bug in iOS causes window.focus() to not work in a lot of cases
            recorderState.websiteTaskWindow &&
            !recorderState.websiteTaskWindow.closed) {
            recorderState.websiteTaskWindow.focus();
        }
        else {
            openNewWindow();
        }
    }, [openNewWindow, recorderState.websiteTaskWindow]);
    if (!step.websiteUrl)
        return (_jsx(SlideRecord, Object.assign({ button: _jsx(Button, { size: "m", marginTop: "m", "data-testid": "submit-form", onClick: recorderState.goForward, children: intl.formatMessage(isFinalStep
                    ? SUBMIT_STEP_COPY.buttonText
                    : UI_COMMON_STEPS_COPY.buttonText) }), step: step }, rest)));
    const button = hasTaskStarted ? (_jsxs(Stack, { direction: "row", gap: "2", children: [_jsx(Button, { "data-testid": "end-website-task", onClick: onStopTest, kind: "primary", children: _jsx("span", { className: "truncate", children: isFinalStep ? (_jsx(FormattedMessage, { id: 'SsFrB4', defaultMessage: 'End task and finish' })) : (_jsx(FormattedMessage, { id: 'YV31HM', defaultMessage: 'Go to next step' })) }) }), _jsx(Button, { size: "m", "data-testid": "reopen-website-task-link", kind: "ghost", onClick: reopen, children: _jsx(FormattedMessage, { id: 'v2ZUxa', defaultMessage: 'Reopen URL' }) })] })) : (_jsx(WebsiteTaskButton, { onClick: startWebsiteTask }));
    return (_jsxs(SlideRecord, Object.assign({ button: button, step: step }, rest, { children: [_jsx(Box, { mt: "s" }), !hasTaskStarted && (_jsx(_Fragment, { children: _jsx(Box, { mb: "m", children: _jsx(WebsiteTaskInfoBlock, { url: step.websiteUrl }) }) }))] })));
});
