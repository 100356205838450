import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useReducedMotion } from 'framer-motion';
import { observer } from 'mobx-react';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { Modal, ModalOverlay, Text } from '@marvelapp/ballpark-components';
import { deviceDetection } from '@marvelapp/core';
import styled from '@marvelapp/styled';
import { Box } from '@marvelapp/ui';
import { PrototypeTaskUtils, } from '@marvelapp/user-test-creator';
import { usePrototypeState } from '../../../components/Record/PrototypeContext';
import PlayerControls from './PlayerControls';
export default observer(function PrototypeRecord({ hasInstructions, step, }) {
    const prototypeState = usePrototypeState();
    // TODO: Wanna remove the up/down arrows for the prototype task, but this also
    // removes the face recording. Fine for now as it's not hooked in, but we might
    // want a dedicated prop for this
    const shouldReduceMotion = useReducedMotion();
    const { isPrototypeFullWidth, isInvalid, embedPermissionStatus } = prototypeState;
    const isPrototypePasswordProtected = (embedPermissionStatus === null || embedPermissionStatus === void 0 ? void 0 : embedPermissionStatus.code) === 401;
    return (_jsxs(Slide, { "data-testid": "prototype-slide", children: [_jsx(PlayerControls, { hasInstructions: hasInstructions, step: step }), deviceDetection.isDesktop && (
            // this prototype container is used to ensure there is a correct layout
            // of the prototoype task step on Desktop. Player Controls take 1/3 of "screen space"
            // and this container takes 2/3rds. The viewer is rendered on top of this container
            // by the parent. The result is that it looks like the controls and
            // the viewer are rendered next to each other when in reality they aren't.
            _jsx(PrototypeContainer, { "data-testid": "prototype-container", noPadding: true, showBorder: !isPrototypeFullWidth, children: isInvalid &&
                    !isPrototypePasswordProtected &&
                    // First click steps use the prototype step under the hood. If this is
                    // a first click step with no image uploaded then there'll be no
                    // prototype url (we create a 1-screen prototype from the uploaded
                    // image). However we *don't* need to show the error state as the
                    // prototype player is not visible on a first click step _until_ you
                    // click 'Reveal image'. The way the CSS works means that if we were
                    // to show the error state you just get a grey overlay over the whole
                    // page which, of course, looks wrong but, worse, stops you
                    // interacting with the page.
                    !PrototypeTaskUtils.isFirstClick(step) && (_jsx(ErrorState, { children: _jsx(ModalOverlay, { shouldReduceMotion: shouldReduceMotion, children: _jsx(Modal, { shouldReduceMotion: shouldReduceMotion, children: step.originalPrototypeUrl ? (_jsx(Text, { fontWeight: "semibold", size: "l", "data-testid": "prototype-importing-message", children: _jsx(FormattedMessage, { id: 'RHo4M5', defaultMessage: 'This prototype is being imported. Please check the status in the builder and re-open this window when the import is complete.' }) })) : (_jsx(Text, { fontWeight: "semibold", size: "l", "data-testid": "add-prototype-message", children: _jsx(FormattedMessage, { id: 'W231HE', defaultMessage: 'Please add a prototype to this step.' }) })) }) }) })) }))] }));
});
const ErrorState = styled(Box) `
  width: 100%;
  position: absolute;
  right: 0;
  height: 100%;
`;
const Slide = styled.div `
  display: flex;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
`;
const PrototypeContainer = styled(Box).attrs((props) => ({
    borderLeft: props.showBorder && '1px solid',
    borderColor: 'slate6',
})) `
  flex: 2;
  height: 100%;
  display: flex;
  position: relative;
  padding: ${(props) => !props.noPadding && '6vw'};
  justify-content: center;
  align-items: center;
`;
