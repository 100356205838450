import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useOnHistoryReplay } from '@ballpark/realtime-plugin-history';
import { isEqual } from 'lodash-es';
import { observer } from 'mobx-react';
import QRCode from 'qrcode.react';
import { useCallback, useState } from 'react';
import * as React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useCurrentUser, userSelectors } from '@marvelapp/ballpark-application';
import { BtwButton, BtwInput, BtwLink, BtwModal, BtwSettingsItem, CopyToClipboardButton, Stack, } from '@marvelapp/ballpark-components';
import { ShareIcon } from '@marvelapp/ballpark-icons';
import { QRButton } from '../../components/QRButton';
import { useUpdatePassword } from '../../hooks/useUpdatePassword';
import { useProjectContext } from '../../utils/ProjectContext';
const INPUT_STATES = {
    ready: {
        variant: 'legacyBlue',
        text: 'Set password',
        placeholder: 'Enter a strong password',
        isDisabled: false,
    },
    passwordSet: {
        variant: 'secondary',
        text: 'Reset password',
        placeholder: '••••••••••••••••',
        isDisabled: true,
    },
};
const ShareModal = observer(function ShareModal({ isPasswordProtected, prototypePk, shareLink, userTestPk, 
// TODO: Once we're done with the project bar refactor, we won't need to pass
// the trigger prop anymore, so the below button will just live within this component
trigger = (_jsx(BtwButton, { "data-testid": "share-test-button", leadingIcon: _jsx(ShareIcon, {}), size: "sm", variant: "legacyBlue", children: "Share" })), }) {
    const { projectBar } = useProjectContext();
    const [qrCodeOpen, setQrCodeOpen] = useState(false);
    const onOpenChange = useCallback((open) => {
        projectBar.isShareModalOpen = open;
    }, [projectBar]);
    const close = useCallback(() => {
        projectBar.isShareModalOpen = false;
    }, [projectBar]);
    useOnHistoryReplay(close);
    return (_jsxs(BtwModal.Root, { open: projectBar.isShareModalOpen, onOpenChange: onOpenChange, children: [_jsx(BtwModal.Trigger, { asChild: true, children: trigger }), _jsxs(BtwModal.Content, { "data-testid": "share-modal", children: [_jsx(BtwModal.BigHeader, { title: "Share test", onlyChild: false }), _jsxs(BtwModal.Body, { children: [_jsxs(Stack, { gap: "2", children: [_jsxs(Stack, { direction: "row", align: "end", gap: "2", width: "full", children: [_jsxs(BtwInput.Root, { className: "flex-1 [&>div>input]:text-blue-600 focus:[&>div>input]:text-blue-700", children: [_jsx(BtwInput.Label, { htmlFor: "record-test-link", children: "Send this link to participants" }), _jsx(BtwInput.Field, { "data-testid": "record-test-link", id: "record-test-link", value: shareLink })] }), _jsx(CopyToClipboardButton, { text: shareLink }), _jsx(QRButton, { isActive: qrCodeOpen, onClick: () => setQrCodeOpen(!qrCodeOpen) })] }), qrCodeOpen && (_jsx(Stack, { align: "center", className: "rounded-md border border-gray-600/20 p-4 shadow-sm", width: "full", children: _jsx(QRCode, { bgColor: "#ffffff", "data-testid": "test-qr-code", fgColor: "#000000", level: "H", renderAs: "svg", size: 180, value: shareLink }) }))] }), _jsx(PasswordProtectionSetting, { isPasswordProtected: isPasswordProtected, prototypePk: prototypePk, userTestPk: userTestPk })] })] })] }));
});
const PasswordProtectionSetting = observer(function PasswordProtectionSetting({ isPasswordProtected, prototypePk, userTestPk, }) {
    const [passwordValue, setPasswordValue] = useState('');
    const [updatePasswordFn] = useUpdatePassword(prototypePk);
    const [isInvalid, setIsInvalid] = useState(false);
    const [isPasswordSettingToggled, setIsPasswordSettingToggled] = useState(!!isPasswordProtected);
    const [inputState, setInputState] = useState(isPasswordProtected ? INPUT_STATES.passwordSet : INPUT_STATES.ready);
    const updatePassword = (password) => {
        updatePasswordFn({ variables: { itemId: prototypePk, password } });
    };
    const setPassword = (password) => {
        if (!password)
            return;
        updatePassword(password);
        setInputState(INPUT_STATES.passwordSet);
        if (isInvalid)
            setIsInvalid(false);
    };
    const clearPassword = () => {
        updatePassword('');
        setPasswordValue('');
        setInputState(INPUT_STATES.ready);
    };
    return (_jsxs(Stack, { align: "stretch", "data-testid": "password-protection-setting", gap: "8", width: "full", children: [_jsx(PasswordProtectToggle, { clearPassword: clearPassword, isInvalid: isInvalid, isPasswordSettingToggled: isPasswordSettingToggled, setIsInvalid: setIsInvalid, setIsPasswordSettingToggled: setIsPasswordSettingToggled, userTestPk: userTestPk }), isPasswordSettingToggled && (_jsx("form", { autoComplete: "new-password", "data-testid": "password-form", onSubmit: (e) => {
                    e.preventDefault();
                    if (isEqual(inputState, INPUT_STATES.passwordSet)) {
                        clearPassword();
                    }
                    else {
                        setPassword(passwordValue);
                    }
                }, children: _jsxs(Stack, { gap: "2", width: "full", children: [_jsxs(Stack, { direction: "row", align: "end", gap: "2", width: "full", children: [_jsxs(BtwInput.Root, { className: "flex-1", children: [_jsx(BtwInput.Label, { htmlFor: "builder-password-input", children: "Password" }), _jsx(BtwInput.Field, { "data-testid": "password-8-chars", disabled: inputState.isDisabled, id: "builder-password-input", minLength: 8, onChange: (e) => {
                                                setPasswordValue(e.target.value);
                                                if (isInvalid)
                                                    setIsInvalid(false);
                                            }, onInvalid: (e) => {
                                                e.preventDefault();
                                                setIsInvalid(true);
                                            }, placeholder: inputState.placeholder, type: "password", value: passwordValue })] }), _jsx(BtwButton, { type: "submit", "data-testid": "set-password-button", variant: inputState.variant, children: inputState.text })] }), isInvalid && (_jsx(BtwInput.Error, { children: "Enter 8 characters or more" }))] }) }))] }));
});
const PasswordProtectToggle = observer(function PasswordProtectToggle({ clearPassword, isInvalid, isPasswordSettingToggled, setIsInvalid, setIsPasswordSettingToggled, userTestPk, }) {
    const user = useCurrentUser();
    const isLockedFreePlan = userSelectors.isLockedFreePlan(user);
    const togglePasswordSetting = () => {
        if (isLockedFreePlan) {
            return;
        }
        if (isInvalid) {
            setIsInvalid(false);
        }
        if (isPasswordSettingToggled) {
            clearPassword();
            setIsPasswordSettingToggled(false);
        }
        else {
            setIsPasswordSettingToggled(true);
        }
    };
    const isChecked = isLockedFreePlan ? false : isPasswordSettingToggled;
    return (_jsxs(_Fragment, { children: [_jsx(BtwSettingsItem, { "data-testid": "password-protect-toggle", description: "Anyone viewing the link will be asked to enter a password", disabled: isLockedFreePlan, heading: "Password protect", id: "password-protect", onPressedChange: togglePasswordSetting, pressed: isChecked, variant: "legacyBlue" }), isLockedFreePlan && (_jsx("div", { className: "mt-1", children: _jsx(BtwLink, { asChild: true, underline: true, size: "sm", children: _jsx(RouterLink, { to: `/plans?origin=/project/${userTestPk}`, children: "Upgrade to enable" }) }) }))] }));
});
export { ShareModal };
