import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { observer } from 'mobx-react';
import { Badge, BtwButton, BtwHeading, BtwPopover, BtwTooltip, Stack, } from '@marvelapp/ballpark-components';
import { PencilIcon } from '@marvelapp/ballpark-icons';
import { useMetricsContext } from './metricsContext';
import { useDeleteCategoryAlias } from './useEditCategoryAlias';
export const EditStandardizedCategoryPopover = observer(function EditStandardizedCategoryPopover({ categoryUUID, }) {
    const { categoryAliases, projectPk, step, userTestUUID } = useMetricsContext();
    const alias = categoryAliases.find((alias) => alias.groupUuid === categoryUUID);
    const { deleteCategoryAlias, loading } = useDeleteCategoryAlias(userTestUUID, categoryUUID);
    if (!alias) {
        return null;
    }
    return (_jsxs(BtwPopover.Root, { children: [_jsx(BtwTooltip, { content: "Edit standardization", children: _jsx("div", { children: _jsx(BtwPopover.Trigger, { asChild: true, "data-testid": "edit-standardized-category-popover-trigger", onClick: (e) => e.stopPropagation(), children: _jsx(BtwButton, { variant: "ghost", className: "!size-5 [&>.inner-icon>svg]:!size-2.5", standaloneIcon: _jsx(PencilIcon, {}) }) }) }) }), _jsx(BtwPopover.Content, { children: _jsxs(Stack, { className: "w-64", children: [_jsxs(Stack, { gap: "2.5", className: "min-w-0 p-4", width: "full", children: [_jsx(BtwHeading, { children: "Combined categories" }), _jsx(Stack, { className: "min-w-0 max-w-full", direction: "row", gap: "1.5", wrap: "wrap", children: alias.includedCategoryNames.map((name) => (_jsx(Badge, { className: "max-w-full", title: name, children: _jsx("span", { className: "truncate", children: name }) }, name))) })] }), _jsx("div", { className: "w-full border-t border-gray-600/10 p-4", children: _jsx(BtwButton, { "data-testid": "revert-standardization-button", isLoading: loading, size: "intermediate", variant: "primary", width: "full", onClick: () => deleteCategoryAlias({
                                    variables: {
                                        testPk: projectPk,
                                        stepUuid: step.uuid,
                                        groupId: alias.groupId,
                                    },
                                }), children: "Reverse standardization" }) })] }) })] }));
});
