import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { observer } from 'mobx-react';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { Button, Text } from '@marvelapp/ballpark-components';
import styled, { css } from '@marvelapp/styled';
import { AspectRatio, Box, Flex, PinCenter } from '@marvelapp/ui';
import Checkbox from '../slides/common/Checkbox';
export const SelectablePreferenceCard = observer(function SelectablePreferenceCard({ id, isChecked, name, onChange, onImageClick, optionNumber, readOnly, thumbnail, thumbnail2x, }) {
    const handleImageClick = (event) => {
        if (event) {
            event.preventDefault();
            event.stopPropagation();
        }
        if (onImageClick)
            onImageClick();
    };
    return (_jsx(Container, { readOnly: readOnly, children: _jsx(Flex, { minHeight: "100%", minWidth: "100%", children: _jsxs(Checkbox, { alwaysShowCheckbox: true, checked: isChecked, hasNestedElements: !!thumbnail, hasOuterBorder: true, id: id, onChange: onChange, optionNumber: optionNumber, readOnly: readOnly, children: [_jsxs(Ratio, { children: [_jsx(RatioInner, { children: _jsx(Image, { alt: name || 'Preference test item', "data-testid": "preference-image", src: thumbnail, srcSet: `${thumbnail2x || thumbnail} 2x`, readOnly: readOnly }) }), _jsx(HiddenButton, { children: _jsx(PinCenter, { pointerEvents: "all", children: _jsx(Button, { size: "s", onClick: handleImageClick, kind: "white", children: _jsx(FormattedMessage, { id: '/q6ZHV', defaultMessage: 'View image' }) }) }) })] }), _jsx(Text, { p: "xs", children: name })] }) }) }));
});
const Image = styled.img `
  display: block;
  max-width: 100%;
  max-height: 100%;
  pointer-events: ${(props) => (props.readOnly ? 'none' : 'all')};
  border-radius: 2px;
  box-shadow: ${(props) => props.theme.shadows.button.secondary};
`;
const Container = styled(Box).attrs((props) => ({
    bg: 'background',
    borderRadius: 'l',
    hoverBoxShadow: props.readOnly ? undefined : 'button.secondary',
    cursor: 'default',
})) `
  width: 100%;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  text-align: center;
  position: relative;
  ${(props) => css `
    &:hover ${Image} {
      box-shadow: ${props.theme.shadows.card.default};
      filter: brightness(40%);
    }
  `}
`;
const HiddenButton = styled(Box).attrs({
    opacity: [1, 1, 1, 0],
    visibility: ['visible', 'visible', 'visible', 'hidden'],
}) ``;
const Ratio = styled(AspectRatio).attrs({
    ratio: 1 / 1,
    width: '100%',
    marginBottom: 'xs',
}) `
  pointer-events: none;
  &:hover ${HiddenButton} {
    opacity: 1;
    visibility: visible;
  }
`;
const RatioInner = styled(Box) `
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
