import { jsx as _jsx } from "react/jsx-runtime";
import { observer } from 'mobx-react';
import * as React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Button } from '@marvelapp/ballpark-components';
import { StepClass } from '@marvelapp/user-test-creator';
import { useWholeTestRecording } from '../../WholeTestRecordingContext';
import FloatingButton from '../common/FloatingButton';
import SlideRecord from '../common/SlideRecord';
import { SetupWholeTestRecording } from './SetupWholeTestRecording';
export const MediaSettingsRecord = observer(function MediaSettingsRecord(props) {
    const intl = useIntl();
    const recording = useWholeTestRecording();
    if (recording === null || recording === void 0 ? void 0 : recording.skipRecordingOnMobile) {
        // Horrible hack to show a custom title and description when on mobile and
        // *only* screen recording is requested. It's not supported on mobile so we
        // just show am instruction to click 'Start test'. Obviously we shouldn't
        // event show the media settings step in this case but that's a bigger job
        // as it affects navigation. Skipping will be discussed on the context of
        // the Recoder refactor. Conditional logic has laid the foundation for this
        // so it should be easy to implement. This is a quick fix as the current
        // screen looks like a bug/error.
        return (_jsx(SlideRecord, Object.assign({}, props, { button: null, step: {
                uuid: props.step.uuid,
                revision: 0,
                className: StepClass.MediaSettings,
                title: intl.formatMessage({ id: "UySpvc", defaultMessage: "We\u2019ve set up your test and you\u2019re ready to go!" }),
                description: [
                    {
                        type: 'p',
                        children: [
                            {
                                text: intl.formatMessage({ id: "FwAAw/", defaultMessage: "Go to the next step to get started." }),
                            },
                        ],
                    },
                ],
            }, children: _jsx(SkipRecordingOnMobile, { onClick: recording.skipRecordingOnMobile }) })));
    }
    return (_jsx(SlideRecord, Object.assign({}, props, { button: null, children: _jsx(SetupWholeTestRecording, {}) })));
});
const SkipRecordingOnMobile = observer(function SkipRecordingOnMobile({ onClick, }) {
    return (_jsx(FloatingButton, { children: _jsx(Button, { size: "m", marginTop: "m", "data-testid": "skip-recording-on-mobile", onClick: onClick, kind: "primary", children: _jsx(FormattedMessage, { id: 'gc3GmN', defaultMessage: 'Start test' }) }) }));
});
