import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { observer } from 'mobx-react';
import * as React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Button, Heading, Modal, ModalOverlay, Text, } from '@marvelapp/ballpark-components';
import { CONTINUE_BUTTON_COPY } from '@marvelapp/ballpark-copy';
import { deviceDetection } from '@marvelapp/core';
import { PinCenter } from '@marvelapp/ui';
export default observer(function HitGoalModal({ onContinue, }) {
    if (deviceDetection.isMobile) {
        return _jsx(MobileModalContent, { onContinue: onContinue });
    }
    return _jsx(DesktopModalContent, { onContinue: onContinue });
});
const DesktopModalContent = observer(function DesktopModalContent({ onContinue, }) {
    return (_jsx(PinCenter, { children: _jsx(InnerContent, { onContinue: onContinue }) }));
});
const MobileModalContent = observer(function MobileModalContent({ onContinue, }) {
    return (_jsx(ModalOverlay, { children: _jsx(PinCenter, { children: _jsx(Modal, { children: _jsx(InnerContent, { onContinue: onContinue }) }) }) }));
});
const InnerContent = observer(function InnerContent({ onContinue, }) {
    const intl = useIntl();
    return (_jsxs("div", { "data-testid": "goal-hit-content", children: [_jsx(Heading, { marginBottom: "xs", size: "xxl", children: _jsx("span", { role: "img", "aria-label": "Clap", children: "\uD83D\uDC4F" }) }), _jsx(Heading, { marginBottom: "xs", size: "l", children: _jsx(FormattedMessage, { id: 'AwDsl5', defaultMessage: 'Task complete' }) }), _jsx(Text, { marginBottom: "m", size: "m", children: _jsx(FormattedMessage, { id: 'YOEhin', defaultMessage: 'You can now move on to the next step.' }) }), _jsx(Button, { onClick: onContinue, size: "l", marginRight: "xs", "data-testid": "complete-prototype-recording", children: intl.formatMessage(CONTINUE_BUTTON_COPY) })] }));
});
