import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { observer } from 'mobx-react';
import * as React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useGetUserTest, useSlideUIContext, } from '@marvelapp/ballpark-application';
import { Button, Icon } from '@marvelapp/ballpark-components';
import { CAMERA_ICON, HEADSET_ICON, SCREEN_ICON, } from '@marvelapp/ballpark-theme';
import { Box } from '@marvelapp/ui';
import { getRecordingPermissions } from '@marvelapp/user-test-creator';
import { LeftScrollableHalfSlide, RightScrollableHalfSlide, } from '../common/HalfSlide';
import SlideContent from '../common/SlideContent';
import TwoColumnSlide from '../common/TwoColumnSlide';
import { RequestMedia, RequestMediaContainer } from './RequestMedia';
export default observer(function MediaSettingsEdit({ onMainCTAClick, }) {
    const intl = useIntl();
    const userTest = useGetUserTest();
    const { step } = useSlideUIContext();
    const { webcam, microphone, screen } = getRecordingPermissions(userTest);
    const AllowText = (_jsx(FormattedMessage, { id: '5w079v', defaultMessage: 'Click \u2018<strong>allow</strong>\u2019 when you see the popup', values: {
            strong: (chunks) => _jsx("strong", { children: chunks }),
        } }));
    return (_jsxs(TwoColumnSlide, { children: [_jsx(LeftScrollableHalfSlide, { children: _jsxs(SlideContent, { ctaText: "Start test", ctaOnClick: onMainCTAClick, maxHeadingLength: 512, maxParagraphLength: 512, children: [_jsxs(Box, { display: "flex", width: "100%", className: "mb-2 mt-4 flex-col gap-4", children: [webcam && (_jsx(RequestMediaContainer, { children: _jsx(RequestMedia, { state: "idle", description: AllowText, icon: _jsx(Icon, { height: 14, width: 14, paths: CAMERA_ICON }), showButtonTooltip: true, testId: "toggle-webcam-share", title: intl.formatMessage({ id: "M7v3Xn", defaultMessage: "Share webcam" }) }) })), microphone && !webcam && (_jsx(RequestMediaContainer, { children: _jsx(RequestMedia, { state: "idle", description: AllowText, icon: _jsx(Icon, { paths: HEADSET_ICON, height: 14, viewBox: "0 0 14 14", width: 14 }), showButtonTooltip: true, testId: "toggle-mic-share", title: intl.formatMessage({ id: "oXtpTl", defaultMessage: "Share microphone" }) }) })), screen && (_jsx(RequestMediaContainer, { children: _jsx(RequestMedia, { state: "idle", description: _jsx(FormattedMessage, { id: 'DIRrVa', defaultMessage: 'Click \u2018<strong>share</strong>\u2019 when you see the popup', values: {
                                                strong: (chunks) => _jsx("strong", { children: chunks }),
                                            } }), icon: _jsx(Icon, { paths: SCREEN_ICON, width: 14, height: 14, viewBox: "0 0 14 14" }), showButtonTooltip: true, testId: "toggle-screen-share", title: intl.formatMessage({ id: "0NAUtT", defaultMessage: "Share screen" }) }) }))] }), !step.isRequired && (_jsx(Box, { mt: "s", style: { float: 'right' }, children: _jsx(Button, { kind: "ghost", onClick: onMainCTAClick, children: _jsx(FormattedMessage, { id: 'uSulPg', defaultMessage: 'Skip' }) }) }))] }) }), _jsx(RightScrollableHalfSlide, { children: _jsx(Box, {}) })] }));
});
