import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { observer } from 'mobx-react';
import { HintMessage } from '@marvelapp/ballpark-components';
import { Flex } from '@marvelapp/ui';
import { MediaUtils } from '@marvelapp/user-test-creator';
import DeviceRecordingStatus, { isHidden } from './DeviceRecordingStatus';
export default observer(function AnswerRecordingStatus({ stepResponse, }) {
    var _a, _b;
    if (!stepResponse)
        return null;
    if (!stepResponse.userRecording && !stepResponse.screenRecording) {
        return null;
    }
    const permissions = MediaUtils.mergeRecordingPermissions((_a = stepResponse.userRecording) === null || _a === void 0 ? void 0 : _a.permissions, (_b = stepResponse.screenRecording) === null || _b === void 0 ? void 0 : _b.permissions);
    if (isHidden(permissions)) {
        return null;
    }
    return (_jsx(HintMessage, { kind: "warning", mx: "m", mt: "m", children: _jsxs(Flex, { direction: "row", justifyContent: "space-between", alignItems: "center", children: [_jsxs("span", { children: [_jsx("strong", { children: "Video upload status" }), " (hover for additional info)"] }), _jsx(DeviceRecordingStatus, { permissions: permissions, testId: "answer-recording-status" })] }) }));
});
