export function serializePermissions(permissions) {
    if (!permissions)
        return undefined;
    return {
        canSubscribe: permissions.canSubscribe,
        canPublish: permissions.canPublish,
        canPublishData: permissions.canPublishData,
        // TrackSource is not exported from livekit-client. Also we'd want to map it
        // to the actual Track.Source type.
        //  canPublishSources: TrackSource[];
        hidden: permissions.hidden,
        canUpdateMetadata: permissions.canUpdateMetadata,
        canSubscribeMetrics: permissions.canSubscribeMetrics,
    };
}
export function serializeJoinedParticipant(participant) {
    return {
        participant: {
            identity: participant.identity,
            sid: participant.sid,
            permissions: serializePermissions(participant.permissions),
        },
    };
}
export function serializeParticipant(participant) {
    return {
        participant: {
            identity: participant.identity,
            sid: participant.sid,
        },
    };
}
export function serializeTrackPublication(publication) {
    return {
        track: {
            sid: publication.trackSid,
            mimeType: publication.mimeType,
            source: publication.source,
            dimensions: publication.dimensions,
        },
    };
}
