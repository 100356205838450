import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { DocumentInvalidError, } from '@ballpark/realtime-core';
import { getDoc } from '@ballpark/realtime-crdt';
import { OfflineProvider } from '@ballpark/realtime-plugin-offline';
import { ServerProvider, useServer } from '@ballpark/realtime-plugin-server';
import { action, reaction } from 'mobx';
import { observer } from 'mobx-react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useConfig, useIsUnsafeProductionBackend, } from '@marvelapp/ballpark-application';
import { Maintenance } from '@marvelapp/ballpark-components';
import { InvalidDocError } from '../components/InvalidDocError';
import { Permissions, getTokenPermission, useAuthToken } from './useAuthToken';
const PersistenceInner = observer(function PersistenceInner({ children, document, parameters, id, validate, onDeleted, resetDocument, }) {
    const config = useConfig();
    const name = id !== null && id !== void 0 ? id : '';
    const { token, loading: tokenLoading, deleted } = useAuthToken(id);
    const isUnsafeProductionBackend = useIsUnsafeProductionBackend();
    useEffect(() => {
        if (deleted)
            onDeleted === null || onDeleted === void 0 ? void 0 : onDeleted();
    }, [deleted, onDeleted]);
    const permission = useMemo(() => getTokenPermission(token), [token]);
    const onValidationError = useCallback((err, doc) => {
        // eslint-disable-next-line no-console
        console.log(JSON.parse(JSON.stringify(doc)));
        if (err instanceof DocumentInvalidError) {
            // eslint-disable-next-line no-console
            console.error(validate.errors);
        }
    }, [validate]);
    const enabled = !!id && !tokenLoading;
    const yDoc = getDoc(document);
    return (_jsx(OfflineProvider, { name: name, document: yDoc, enabled: enabled && !isUnsafeProductionBackend, options: { validate, onValidationError }, children: _jsxs(ServerProvider, { url: `${config.usertest.realtime.url}/collaborate`, name: name, document: yDoc, enabled: enabled, parameters: Object.assign(Object.assign({}, parameters), { readOnly: isUnsafeProductionBackend }), token: token, options: {
                broadcast: permission === Permissions.Write,
            }, resetDocument: resetDocument, children: [window.Cypress && _jsx(CypressSetup, { document: document }), _jsxs(ErrorIfInvalid, { document: yDoc, id: name, validate: validate, children: [_jsx(ToggleConnectionOnVisibilityChange, {}), children] })] }) }));
});
// Expose a function to get the document and server on 'window' for Cypress tests. This allows us to
// wait for the doc to be synced to the backend.
export const CypressSetup = observer(function CypressSetup({ document, }) {
    const server = useServer();
    useEffect(() => {
        window.__getDoc = () => document;
        window.__getServer = () => server;
        return () => {
            delete window.__getDoc;
            delete window.__getServer;
        };
    }, [server, document]);
    return null;
});
export const Persistence = observer(function Persistence(props) {
    return (_jsx(Maintenance, { flag: "maintenance-rus", children: _jsx(PersistenceInner, Object.assign({}, props)) }));
});
const ErrorIfInvalid = observer(function ErrorIfInvalid({ document, id, validate, children, }) {
    const server = useServer();
    const [invalid, setInvalid] = useState(false);
    useEffect(() => reaction(() => server.closeEvent, (closeEvent) => {
        if (!validate || !closeEvent)
            return;
        // check if the document is empty so we don't flag it as invalid if we failed to connect
        const isEmpty = document.store.clients.size === 0;
        // TODO: send an invalid code from the server so we can check it here
        if (!isEmpty && !validate(document, id)) {
            // server closed connection and data was invalid
            server.enabled = false;
            setInvalid(true);
        }
    }, { fireImmediately: true }), [document, server, validate, id]);
    if (invalid)
        return _jsx(InvalidDocError, {});
    return _jsx(_Fragment, { children: children });
});
const ToggleConnectionOnVisibilityChange = observer(function ToggleConnectionOnVisibilityChange() {
    const server = useServer();
    const forceConnect = useCallback(action(() => {
        // There is a bug in Safari where the websocket sometimes drops
        // entirely and fails to reconnect. We force toggle the reconnection
        // to make sure it can recover
        if (document.visibilityState === 'hidden') {
            server.enabled = false;
        }
        else {
            server.enabled = true;
        }
    }), [server]);
    useEffect(() => {
        document.addEventListener('visibilitychange', forceConnect);
        return () => {
            document.removeEventListener('visibilitychange', forceConnect);
        };
    }, [forceConnect]);
    return null;
});
