import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { observer } from 'mobx-react';
import * as React from 'react';
import { HintMessage } from '@marvelapp/ballpark-components';
import styled from '@marvelapp/styled';
import { Box } from '@marvelapp/ui';
import { StepBox, StepHeader } from '../../../components/Step';
import AnswerRecordingStatus from '../AnswerRecordingStatus';
import ResponseSecondaryInfo from '../ResponseSecondaryInfo';
import { DecorativeImage } from './DecorativeImage';
export const Answer = observer(function Answer({ active, children, currentStepNumber, id, isScreenerStep, notAnswered, secondary, showIncompatibleDeviceHint = false, step, stillLoading, testId, totalStepsNumber, stepResponse, hasBeenConditionallySkipped, }) {
    var _a;
    const decorativeImageUrl = (_a = step.decorativeImage) === null || _a === void 0 ? void 0 : _a.url;
    return (_jsxs(_Fragment, { children: [_jsxs(StepBox, { active: active, id: id, notAnswered: notAnswered, testId: testId, children: [_jsx(AnswerRecordingStatus, { stepResponse: stepResponse }), showIncompatibleDeviceHint && (_jsx(HintMessage, { kind: "info", mx: "m", mt: "m", children: "Tests taken on a mobile device don't currently support screen recording" })), _jsx(StepHeader, { currentStepNumber: currentStepNumber, notAnswered: notAnswered, step: step, stillLoading: stillLoading, totalStepsNumber: totalStepsNumber, isScreenerStep: isScreenerStep }), decorativeImageUrl && (_jsx(DecorativeImage, { url: decorativeImageUrl, testId: testId })), children && _jsx(Body, { children: children })] }), _jsx(ResponseSecondaryInfo, { hasBeenConditionallySkipped: hasBeenConditionallySkipped, copy: secondary || 'Skipped this step' })] }));
});
const Body = styled(Box).attrs({
    px: 'l',
    pb: 'l',
}) ``;
