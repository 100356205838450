import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { action } from 'mobx';
import { observer } from 'mobx-react';
import { useState } from 'react';
import * as React from 'react';
import { useAction, useGetUserTest } from '@marvelapp/ballpark-application';
import { Dropdown } from '@marvelapp/ballpark-components';
import { ChoiceConditionalUtils, ChoiceStepUtils, MultipleChoiceStepUtils, MultipleSelectionMode, ScreenerUtils, StepClass, StepUtils, } from '@marvelapp/user-test-creator';
import ChoiceLogicDeleteModal from '../../../components/ChoiceLogicDeleteModal';
import { StepOptions } from '../Options';
import MultipleChoiceCountSelect from './MultipleChoiceCountSelect';
export default observer(function ChoiceSettings({ hasMultipleSelectionSetting, hasRandomizeSetting, hasOtherChoiceSetting, step, shouldShowRequiredSetting, }) {
    const userTest = useGetUserTest();
    const toggleSelection = useAction(() => {
        if (!StepUtils.isChoiceStep(step))
            return;
        const isMultipleSelectionEnabled = ChoiceStepUtils.isMultipleSelectionEnabled(step);
        if (isMultipleSelectionEnabled) {
            ChoiceStepUtils.removeMultipleSelection(step);
            if (StepUtils.isOfType(step, StepClass.MultipleChoice)) {
                // update any "Can select" options to "Accept"
                ScreenerUtils.updateEqualityOperatorOnSetRequirements(userTest);
            }
        }
        else {
            ChoiceStepUtils.setMultipleSelection(step, MultipleSelectionMode.Unlimited);
        }
    }, [step, userTest]);
    const isMultipleSelectionEnabled = ChoiceStepUtils.isMultipleSelectionEnabled(step);
    const multipleSelectionMode = ChoiceStepUtils.getMultipleSelectionMode(step);
    const isRandomized = ChoiceStepUtils.getIsRandomizeSet(step);
    return (_jsxs(StepOptions, { shouldShowRequiredSetting: shouldShowRequiredSetting, children: [hasMultipleSelectionSetting && (_jsx(Dropdown.Toggle, { checked: isMultipleSelectionEnabled, description: "Allow participants to select more than one option", id: "choice-step-edit-multiple-option", onChange: toggleSelection, testId: "choice-step-edit-multiple-option", title: "Multiple selection", hasBorder: !isMultipleSelectionEnabled })), isMultipleSelectionEnabled && multipleSelectionMode && (_jsx(MultipleChoiceCountSelect, { maxCount: step.choices.length, onChange: action((e) => ChoiceStepUtils.setMultipleSelection(step, e.target.value)), onRangeChange: action((val) => ChoiceStepUtils.setSelectionRange(step, val)), range: ChoiceStepUtils.getSelectionRange(step), value: multipleSelectionMode })), hasRandomizeSetting && (_jsx(Dropdown.Toggle, { checked: isRandomized, description: "Display the options in random order each time a test is taken", id: "choice-step-edit-randomize-option", onChange: action(() => ChoiceStepUtils.setRandomizeSetting(step, !isRandomized)), testId: "choice-step-randomize-option", title: "Randomize options", hasBorder: hasOtherChoiceSetting })), StepUtils.isOfType(step, StepClass.MultipleChoice) && (_jsx(MultipleChoiceOtherOption, { step: step, userTest: userTest }))] }));
});
const MultipleChoiceOtherOption = observer(function MultipleChoiceOtherOption({ step, userTest, }) {
    const [showConfirmRemoveOtherModal, setShowConfirmRemoveOtherModal] = useState(false);
    const addOtherOption = useAction(() => {
        MultipleChoiceStepUtils.addOtherChoiceOption(step);
        const otherChoiceUUID = MultipleChoiceStepUtils.getOtherOptionUUID(step);
        if (ScreenerUtils.isScreenerStep(userTest, step.uuid) && otherChoiceUUID) {
            // as soon as the other option is added, we add the associated requirement to the doc
            // this allows the user to update that value (via the screener select)
            ScreenerUtils.addScreenerRequirement(step, otherChoiceUUID, userTest);
        }
    }, [step, userTest]);
    const removeOtherOption = useAction(() => {
        // check if there is conditional logic set against the other option before removing it
        const otherChoiceUUID = MultipleChoiceStepUtils.getOtherOptionUUID(step);
        let hasConditionalLogicEnabled = false;
        if (otherChoiceUUID) {
            hasConditionalLogicEnabled =
                ChoiceConditionalUtils.getIsChoiceConditional(step, otherChoiceUUID);
        }
        if (hasConditionalLogicEnabled) {
            setShowConfirmRemoveOtherModal(true);
        }
        else {
            MultipleChoiceStepUtils.removeOtherChoiceOption(step);
        }
    }, [step]);
    const onConfirmRemoveOtherOption = useAction(() => {
        const otherChoiceUUID = MultipleChoiceStepUtils.getOtherOptionUUID(step);
        if (otherChoiceUUID) {
            deleteRule(userTest, step, otherChoiceUUID);
            MultipleChoiceStepUtils.removeOtherChoiceOption(step);
            setShowConfirmRemoveOtherModal(false);
        }
    }, [step, userTest]);
    const isOtherChoiceDisplayed = MultipleChoiceStepUtils.hasOtherChoiceOption(step);
    const toggleOtherChoiceOption = useAction(() => {
        if (isOtherChoiceDisplayed) {
            removeOtherOption();
        }
        else {
            addOtherOption();
        }
    }, [addOtherOption, removeOtherOption, isOtherChoiceDisplayed]);
    return (_jsxs(_Fragment, { children: [showConfirmRemoveOtherModal && (_jsx(ChoiceLogicDeleteModal, { confirmDelete: onConfirmRemoveOtherOption, trigger: () => setShowConfirmRemoveOtherModal(false) })), _jsx(Dropdown.Toggle, { checked: isOtherChoiceDisplayed, description: "Allow people to choose and type in their own answer", id: "multiple-choice-edit-other-option", onChange: toggleOtherChoiceOption, testId: "multiple-choice-edit-other-option", title: '"Other" option' })] }));
});
function deleteRule(userTest, step, otherChoiceUUID) {
    if (ScreenerUtils.isScreenerStep(userTest, step.uuid)) {
        ScreenerUtils.removeScreenerRequirement(step, otherChoiceUUID);
    }
    else {
        ChoiceConditionalUtils.deleteRulesForDeletedChoice(step, otherChoiceUUID);
    }
}
