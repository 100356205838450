import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { createContext, useCallback, useContext, useEffect, useRef, useState, } from 'react';
import * as React from 'react';
import { AiTextIcon, TranscriptionIcon } from '@marvelapp/ballpark-icons';
import { formatTime } from '@marvelapp/core';
import { BtwButton } from './BtwButton';
import { BtwHeading } from './BtwHeading';
import { BtwTabs } from './BtwTabs';
import { BtwText } from './BtwText';
import { BtwTooltip } from './BtwTooltip';
import { CopyToClipboardButton } from './CopyToClipboardButton';
import { CtaMessage } from './CtaMessage';
import { ScrollArea } from './ScrollArea';
import { Stack } from './Stack';
import { cn } from './utils';
const TAB = {
    Transcription: 'Transcription',
    Summary: 'Summary',
};
const TranscriptionContext = createContext({});
function Root({ children, actions, className, defaultTab = 'Transcription', }) {
    const childrenArray = React.Children.toArray(children);
    const hasSummary = childrenArray.some((child) => React.isValidElement(child) && child.type === SummaryTab);
    const hasTranscription = childrenArray.some((child) => React.isValidElement(child) && child.type === TranscriptionTab);
    const scrollAreaViewportRef = useRef(null);
    const lastActiveIndex = useRef(-1);
    const scrollItemIntoView = useCallback((element, index) => {
        if (!element || !scrollAreaViewportRef.current) {
            return;
        }
        const container = scrollAreaViewportRef.current;
        lastActiveIndex.current = index;
        const elementTop = element.offsetTop;
        const topPadding = 10;
        container.scrollTo({
            top: elementTop - topPadding,
            behavior: 'smooth',
        });
    }, []);
    // Tracking whether the transcription container is scrollable,
    // so we can adjust the copy button positioning for each line
    const [isScrollable, setIsScrollable] = useState(false);
    useEffect(() => {
        const container = scrollAreaViewportRef === null || scrollAreaViewportRef === void 0 ? void 0 : scrollAreaViewportRef.current;
        if (!container)
            return;
        const checkIfScrollable = () => {
            const { clientHeight, scrollHeight } = container;
            setIsScrollable(scrollHeight > clientHeight);
        };
        // Initial check
        checkIfScrollable();
        // Adding a ResizeObserver to update state when viewport size changes
        const resizeObserver = new ResizeObserver(() => {
            checkIfScrollable();
        });
        resizeObserver.observe(container);
        // Cleanup
        return () => {
            resizeObserver.disconnect();
        };
    }, [scrollAreaViewportRef, hasTranscription]);
    const contextValue = {
        onScrollIntoView: scrollItemIntoView,
        scrollAreaViewportRef,
        isScrollable,
    };
    return (_jsx(TranscriptionContext.Provider, { value: contextValue, children: _jsx(Stack, { width: "full", asChild: true, "data-testid": "transcription", children: _jsxs(BtwTabs.Root, { defaultValue: defaultTab, children: [_jsxs(BtwTabs.List, { className: "relative z-10 w-full", "aria-label": "Transcription", children: [hasSummary && (_jsx(BtwTabs.Trigger, { disabled: !hasSummary, leadingIcon: _jsx(AiTextIcon, {}), size: "intermediate", value: TAB.Summary, variant: "ghost", children: TAB.Summary })), _jsx(BtwTabs.Trigger, { leadingIcon: _jsx(TranscriptionIcon, {}), size: "intermediate", value: TAB.Transcription, variant: "ghost", children: TAB.Transcription }), actions && _jsx("div", { className: "ml-auto", children: actions })] }), hasTranscription ? (_jsxs(ScrollArea.Root, { className: className, children: [_jsxs(ScrollArea.Viewport, { ref: scrollAreaViewportRef, children: [_jsx("div", { className: "h-4" }), children, _jsx("div", { className: "h-4" }), _jsx("div", { className: "absolute left-0 right-0 top-0 h-4 bg-gradient-to-b from-gray-50 to-transparent" }), _jsx("div", { className: "absolute bottom-0 left-0 right-0 h-4 bg-gradient-to-t from-gray-50 to-transparent" })] }), _jsx(ScrollArea.Scrollbar, { orientation: "horizontal" }), _jsx(ScrollArea.Scrollbar, { orientation: "vertical" })] })) : (_jsx("div", { className: "w-full pt-4", children: children }))] }) }) }));
}
export function Item(props) {
    const { type = 'transcription-segment', isActive, name, title, text, timestamp, index, onClick, } = props;
    const { onScrollIntoView, isScrollable } = useContext(TranscriptionContext);
    const ref = React.useRef(null);
    const prevActiveRef = useRef(isActive);
    const isFirstRender = useRef(true);
    useEffect(() => {
        const wasActive = prevActiveRef.current;
        prevActiveRef.current = isActive;
        // Scroll into view if:
        // 1. Item becomes active (isActive && !wasActive), OR
        // 2. It's the first render and the item is active (isFirstRender && isActive)
        if (((isActive && !wasActive) || (isFirstRender.current && isActive)) &&
            onScrollIntoView &&
            typeof index === 'number') {
            requestAnimationFrame(() => {
                onScrollIntoView(ref.current, index);
            });
        }
        isFirstRender.current = false;
    }, [isActive, onScrollIntoView, index]);
    return (_jsx(Stack, { ref: ref, "data-testid": type, className: cn('group', 'px-2', 'py-2.5', 'group', 'relative', 'rounded-lg', 'duration-300', 'ease-smooth', 'transition-combined', 'hover:bg-gray-600/10', {
            'bg-gray-100': isActive,
        }), gap: "1", width: "full", "data-active": isActive, children: _jsxs(_Fragment, { children: [_jsx(ItemHeader, { type: type, text: text, title: title, name: name, timestamp: timestamp, onClick: onClick, isScrollable: isScrollable }), _jsx(ItemBody, { type: type, isActive: isActive, isScrollable: isScrollable, text: text })] }) }));
}
function ItemHeader(props) {
    const { title, nameVariant, timestamp, onClick, type, isScrollable, text } = props;
    if (type === 'quote' || type === 'executive-summary') {
        return (_jsx(Stack, { className: cn('absolute', isScrollable ? 'absolute right-2.5 top-1.5' : 'right-0 top-1.5'), children: _jsx(ItemActionsContainer, { isScrollable: isScrollable, children: _jsx(CopyToClipboardButton, { variant: "ghost", size: "xs", text: text, testId: "copy-segment" }) }) }));
    }
    if (type === 'chapter') {
        return (_jsxs(Stack, { direction: "row", justify: "between", width: "full", align: "center", className: "pl-1.5", children: [_jsx(BtwText, { asChild: true, leading: "normal", size: "13", variant: nameVariant, weight: "semibold", align: "left", "data-testid": "title", children: _jsxs("span", { children: [title, ":"] }) }), _jsxs(ItemActionsContainer, { isScrollable: isScrollable, children: [_jsx(TimestampButton, { timestamp: timestamp, onClick: onClick }), _jsx(CopyToClipboardButton, { variant: "ghost", size: "xs", text: text, testId: "copy-segment" })] })] }));
    }
    if (type === 'transcription-segment') {
        return (_jsxs(Stack, { direction: "row", justify: "between", width: "full", align: "center", children: [_jsx(TimestampButton, { timestamp: timestamp, onClick: onClick }), _jsx(ItemActionsContainer, { isScrollable: isScrollable, children: _jsx(CopyToClipboardButton, { variant: "ghost", size: "xs", text: text, testId: "copy-segment" }) })] }));
    }
    return null;
}
function ItemActionsContainer(props) {
    const { isScrollable, children } = props;
    return (_jsx(Stack, { direction: "row", gap: "0.5", className: cn(
        // Base styles
        'relative -right-1 opacity-0', 
        // Interactive styles
        'group-hover:right-0 group-hover:opacity-100', 
        // Transitions
        'ease-smooth transition-all duration-200 ', isScrollable ? 'mr-2.5' : 'mr-0'), children: children }));
}
function TimestampButton(props) {
    const { timestamp, onClick } = props;
    return (_jsx(BtwTooltip, { content: `Jump to ${timestamp} in the video`, children: _jsx(BtwButton, { size: "xs", variant: "ghost", onClick: onClick, "data-testid": "timestamp", children: timestamp }) }));
}
function ItemBody(props) {
    const { text, type, isActive, isScrollable } = props;
    if (type === 'quote' || type === 'executive-summary') {
        return (_jsx(BtwText, { leading: "normal", size: "13", variant: isActive ? 'primary' : 'secondary', weight: isActive ? 'medium' : 'normal', "data-testid": "text", className: cn(isScrollable ? 'pr-10' : 'pr-1.5', 
            // Quote styles
            type === 'quote'
                ? 'pl-5 italic before:absolute before:bottom-2.5 before:left-3.5 before:top-2.5 before:block before:w-0.5 before:bg-gray-600/10'
                : 'pl-1.5'), children: text }));
    }
    return (_jsx(BtwText, { leading: "normal", size: "13", variant: isActive ? 'primary' : 'secondary', weight: isActive ? 'medium' : 'normal', "data-testid": "text", className: cn('pl-1.5', isScrollable ? 'pr-3.5' : 'pr-1.5'), children: text }));
}
function TranscriptionTab(props) {
    const { children } = props;
    const isGeneratingTranscription = false;
    return (_jsx(BtwTabs.Content
    // height is 100%, minus the h-4 spacers top and bottom in the Root component.
    // Without this, the container will always have a scroll bar, because
    // it's effectively 100% + h-4 * 2
    , { 
        // height is 100%, minus the h-4 spacers top and bottom in the Root component.
        // Without this, the container will always have a scroll bar, because
        // it's effectively 100% + h-4 * 2
        className: "h-[calc(100%_-_32px)] w-full flex-1", value: TAB.Transcription, children: _jsx(Stack, { className: "h-full", width: "full", children: isGeneratingTranscription ? (_jsx(Stack, { justify: "center", align: "center", className: "h-full rounded-lg border border-dashed border-gray-600/20", width: "full", children: _jsx("div", { className: "max-w-72 p-8", children: _jsx(CtaMessage.Root, { size: "sm", children: _jsxs(CtaMessage.Header, { icon: _jsx(AiTextIcon, {}), children: [_jsx(CtaMessage.Heading, { children: "Generating transcription" }), _jsxs(CtaMessage.Description, { children: ["Transcription is currently being generated, it", "'", "ll appear here soon"] })] }) }) }) })) : (children) }) }));
}
function SummaryTab(props) {
    const { children } = props;
    return (_jsx(BtwTabs.Content, { className: "w-full [&>div]:w-full", value: TAB.Summary, children: children }));
}
function Summary({ summary, onSeekTo, activeChapterIndex }) {
    const { chapters, executiveSummary, interestingQuotes } = summary;
    return (_jsxs("div", { "data-testid": "transcription-summary", children: [_jsx(Stack, { children: chapters.map((chapter, index) => {
                    return (_jsx(Item, { type: "chapter", index: index, isActive: activeChapterIndex === index, title: chapter.title, text: chapter.content, timestamp: formatTime(chapter.timestamp), onClick: () => onSeekTo === null || onSeekTo === void 0 ? void 0 : onSeekTo(chapter.timestamp) }, chapter.timestamp));
                }) }), executiveSummary && (_jsxs(Stack, { children: [_jsx(BtwHeading, { className: "px-3.5 py-3", size: "13", variant: "primary", weight: "semibold", children: "Executive summary" }), _jsx(Item, { type: "executive-summary", isActive: false, text: executiveSummary })] })), interestingQuotes.length > 0 && (_jsx(_Fragment, { children: _jsxs("div", { className: "space-y-2", children: [_jsx(BtwHeading, { className: "px-3.5 py-3", size: "13", variant: "primary", weight: "semibold", children: "Interesting Quotes" }), _jsx("div", { className: "space-y-0.5", children: interestingQuotes.map((quote, index) => (
                            // eslint-disable-next-line react/no-array-index-key
                            _jsx(Item, { text: quote, type: "quote" }, index))) })] }) }))] }));
}
export const Transcription = {
    Item,
    Root,
    Summary,
    SummaryTab,
    TranscriptionTab,
};
