import { defineMessage } from 'react-intl';
import { FixedStep, PrototypeStepType, QuestionStepType, STEPS_COPY, StepClass, StepUtils, YesOrNoStepType, } from '@marvelapp/user-test-creator';
export const UI_COMMON_STEPS_COPY = {
    descriptionPlaceholder: defineMessage({ id: "uhG40O", defaultMessage: "Click to add a description (optional)" }),
    titlePlaceholder: defineMessage({ id: "pyLSm7", defaultMessage: "Add your question or title" }),
    buttonText: defineMessage({ id: "2BgYXN", defaultMessage: "Go to next step" }),
    inputPlaceholder: defineMessage({ id: "/qkfbk", defaultMessage: "Type your answer here" }),
};
export const SUBMIT_STEP_COPY = {
    buttonText: defineMessage({ id: "9ZV08B", defaultMessage: "Finish" }),
};
export const CONTINUE_BUTTON_COPY = defineMessage({ id: "IqHpbz", defaultMessage: "Continue" });
export const UI_STEPS_COPY = {
    [FixedStep.Exit]: {
        buttonText: defineMessage({ id: "eTN4z3", defaultMessage: "Click to add button text" }),
        stepName: defineMessage({ id: "iNc6E0", defaultMessage: "Exit" }),
        titlePlaceholder: defineMessage({ id: "ScR8z0", defaultMessage: "Add your exit title" }),
    },
    [StepClass.FiveSecondTest]: {
        stepName: defineMessage({ id: "9OjE89", defaultMessage: "Five second test" }),
        buttonText: STEPS_COPY[StepClass.FiveSecondTest].buttonText,
    },
    [StepClass.Instruction]: {
        stepName: defineMessage({ id: "ykdKSF", defaultMessage: "Instruction" }),
    },
    [StepClass.MediaSettings]: {
        buttonText: CONTINUE_BUTTON_COPY,
        stepName: defineMessage({ id: "2y/ez0", defaultMessage: "Recording permissions" }),
    },
    [StepClass.MultipleChoice]: {
        stepName: defineMessage({ id: "qPAE0g", defaultMessage: "Multiple choice" }),
        inputPlaceholder: defineMessage({ id: "pDcKX/", defaultMessage: "Enter choice" }),
    },
    [StepClass.PreferenceTest]: {
        stepName: defineMessage({ id: "a1mAkN", defaultMessage: "Image preference test" }),
        optionPlaceholder: defineMessage({ id: "O4GmXZ", defaultMessage: "Click to add label" }),
    },
    [StepClass.PrototypeTask]: {
        stepName: defineMessage({ id: "bMYXhJ", defaultMessage: "Prototype task" }),
        descriptionPlaceholder: defineMessage({ id: "82qEAa", defaultMessage: "Click to add instructions (optional)" }),
        buttonText: defineMessage({ id: "HythtA", defaultMessage: "Start" }),
    },
    [PrototypeStepType.Prototype]: {
        stepName: defineMessage({ id: "bMYXhJ", defaultMessage: "Prototype task" }),
    },
    [PrototypeStepType.FreeRoam]: {
        stepName: defineMessage({ id: "6+km4q", defaultMessage: "Prototype free roam" }),
    },
    [PrototypeStepType.FirstClick]: {
        stepName: defineMessage({ id: "haIBEq", defaultMessage: "First click" }),
        optionPlaceholder: defineMessage({ id: "O4GmXZ", defaultMessage: "Click to add label" }),
        buttonText: STEPS_COPY[PrototypeStepType.FirstClick].buttonText,
    },
    [StepClass.Question]: {
        stepName: defineMessage({ id: "fsYQ2p", defaultMessage: "Question" }),
    },
    [QuestionStepType.Text]: {
        stepName: defineMessage({ id: "fsYQ2p", defaultMessage: "Question" }),
    },
    [QuestionStepType.Email]: {
        stepName: defineMessage({ id: "Q6Ax2F", defaultMessage: "Email" }),
        inputPlaceholder: defineMessage({ id: "+OZipx", defaultMessage: "your@email-address.com" }),
    },
    [QuestionStepType.QuestionDate]: {
        stepName: defineMessage({ id: "dKZKNS", defaultMessage: "Date" }),
    },
    [QuestionStepType.Numerical]: {
        stepName: defineMessage({ id: "oBuPQZ", defaultMessage: "Numerical" }),
        inputPlaceholder: defineMessage({ id: "EEJJ8S", defaultMessage: "Enter any number" }),
    },
    [StepClass.RatingScale]: {
        stepName: defineMessage({ id: "rB481d", defaultMessage: "Rating scale" }),
        startLabelDescription: defineMessage({ id: "0AeZki", defaultMessage: "Start" }),
        middleLabelDescription: defineMessage({ id: "x7js5z", defaultMessage: "Middle" }),
        endLabelDescription: defineMessage({ id: "G4AGr2", defaultMessage: "End" }),
    },
    [StepClass.TaglineCopyTest]: {
        stepName: defineMessage({ id: "aQHbUZ", defaultMessage: "Copy test" }),
    },
    [StepClass.WebsiteTask]: {
        stepName: defineMessage({ id: "DZTNMK", defaultMessage: "Website task" }),
    },
    [FixedStep.Welcome]: {
        buttonText: defineMessage({ id: "o7UyAL", defaultMessage: "Get started" }),
        stepName: defineMessage({ id: "Rg6GzI", defaultMessage: "Welcome" }),
        titlePlaceholder: defineMessage({ id: "9axyl+", defaultMessage: "Add your welcome title" }),
    },
    [StepClass.YesOrNo]: {
        stepName: defineMessage({ id: "xN+Phm", defaultMessage: "Yes or No" }),
    },
    [YesOrNoStepType.YesNo]: {
        stepName: defineMessage({ id: "xN+Phm", defaultMessage: "Yes or No" }),
    },
    [YesOrNoStepType.Legal]: {
        stepName: defineMessage({ id: "kYyhVt", defaultMessage: "Legal" }),
    },
    [StepClass.CardSortingHybrid]: {
        stepName: defineMessage({ id: "xB22a5", defaultMessage: "Hybrid card sort" }),
    },
    [StepClass.CardSortingClosed]: {
        stepName: defineMessage({ id: "MEGW3r", defaultMessage: "Closed card sort" }),
    },
    [StepClass.CardSortingOpen]: {
        stepName: defineMessage({ id: "q+eBrn", defaultMessage: "Open card sort" }),
    },
};
export function getStepName(step, fixedStepType, isScreenerStep = false) {
    var _a, _b;
    if (fixedStepType) {
        return UI_STEPS_COPY[fixedStepType].stepName;
    }
    if (StepUtils.isStepWithSubType(step) && step.type) {
        return (_a = UI_STEPS_COPY[step.type]) === null || _a === void 0 ? void 0 : _a.stepName;
    }
    if (isScreenerStep) {
        return defineMessage({ id: "y6WDi0", defaultMessage: "Screener" });
    }
    return (((_b = UI_STEPS_COPY[step.className]) === null || _b === void 0 ? void 0 : _b.stepName) ||
        UI_COMMON_STEPS_COPY.descriptionPlaceholder);
}
export function getEnglishStepName(step, fixedStepType, isScreenerStep = false) {
    return getStepName(step, fixedStepType, isScreenerStep).defaultMessage;
}
export function getStepTitle(step, fixedStepType) {
    const placeholder = fixedStepType
        ? UI_STEPS_COPY[fixedStepType].titlePlaceholder
        : UI_COMMON_STEPS_COPY.titlePlaceholder;
    // if the step title matches the placeholder return the placeholder as it is localized
    if (step.title === placeholder.defaultMessage) {
        return placeholder;
    }
    return step.title || placeholder;
}
export function getEnglishStepTitle(step, fixedStepType) {
    const title = getStepTitle(step, fixedStepType);
    if (typeof title === 'string') {
        return title;
    }
    return title.defaultMessage;
}
export function formatStringOrMessage(intl, message, values) {
    if (typeof message === 'string') {
        return message;
    }
    return intl.formatMessage(message, values);
}
