import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { range } from 'lodash-es';
import { usePermissions } from '@marvelapp/ballpark-application';
import { getRoleDisplayLabel } from '@marvelapp/core';
import { Avatar, Button, Flex, Heading, Image, Text } from '@marvelapp/ui';
import InfiniteScroll from '../InfiniteScroll';
import SimpleListItem from '../SimpleListItem';
import emptyState from './groupsEmptyState.png';
import emptyStateRetina from './groupsEmptyState@2x.png';
export default function PeopleList({ hasNextPage, isDisabled, isLoading, isLoadingMore, itemName, loadNextPage, onAdd, onRemove, ownerPk, people = [], personIsAdded = () => true, renderPersonRowButtonComponent, scrollableContainerEl, searchTerm, shakeSearchInput, }) {
    const { canEditGroups } = usePermissions();
    const emptyStateCopy = canEditGroups()
        ? `When you add groups to the ${itemName}, they'll appear here!`
        : `It looks like this ${itemName} is still waiting for groups to be added.`;
    if (!isLoading && !people.length) {
        return (_jsxs(Flex, { alignItems: "center", border: "1px dashed", borderColor: "smokeDark", borderRadius: 2, flexDirection: "column", height: "calc(100% - 20px)", justifyContent: "center", marginTop: "20px", width: "100%", children: [_jsx(Image, { marginBottom: 4, src: emptyState, src2x: emptyStateRetina, width: 300 }), _jsx(Heading, { fontSize: 3, fontWeight: 500, marginBottom: 1, children: `Ah, no groups on this ${itemName} yet` }), _jsx(Text, { fontSize: 2, maxWidth: 230, textAlign: "center", children: emptyStateCopy }), canEditGroups() && (_jsx(Button, { kind: "ghost", mt: 3, onClick: shakeSearchInput, size: 1, children: "Add groups" }))] }));
    }
    return (_jsxs(InfiniteScroll, { containerEl: scrollableContainerEl, hasMore: hasNextPage, isLoading: isLoading, onLoadMore: loadNextPage, children: [people &&
                renderListItem({
                    isDisabled,
                    isLoading,
                    isLoadingMore,
                    itemName,
                    onAdd,
                    onRemove,
                    ownerPk,
                    people,
                    personIsAdded,
                    renderPersonRowButtonComponent,
                    searchTerm,
                }), isLoading &&
                range(4).map((dummyPerson) => (_jsx(SimpleListItem, { hasSingleAvatar: true, isLoading: true }, dummyPerson))), isLoadingMore &&
                range(4).map((dummyPerson) => (_jsx(SimpleListItem, { hasSingleAvatar: true, isLoading: true }, dummyPerson)))] }));
}
function renderListItem({ isDisabled, isLoading, isLoadingMore, itemName, onAdd, onRemove, ownerPk, people, personIsAdded, searchTerm, renderPersonRowButtonComponent, }) {
    return people.map((edge, index) => {
        const { node } = edge;
        const isCollaborator = personIsAdded(edge);
        const isLastChild = people.length - 1 === index && !isLoadingMore;
        const isOnlyChild = people.length === 1;
        const rowProps = {
            isCollaborator,
            isDisabled,
            isLastChild,
            isLoading,
            isOnlyChild,
            node,
            onAdd,
            onRemove,
            searchTerm,
            itemName,
        };
        return (_jsx(PersonRow, Object.assign({}, rowProps, { ownerPk: ownerPk, renderPersonRowButtonComponent: renderPersonRowButtonComponent })));
    });
}
function PersonRow({ isDisabled, isLastChild, node, onAdd, onRemove, ownerPk, renderPersonRowButtonComponent, searchTerm, isLoading, isCollaborator, }) {
    return (_jsx(SimpleListItem, { avatar: node.avatarUrl, badge: getRoleDisplayLabel(node.role), hasSingleAvatar: true, heading: node.username, highlightHeading: true, isLastChild: isLastChild, renderImage: (status) => (_jsx(Avatar, { display: "block", email: node.email, isOnline: status === 'online', size: 40, src: node.avatarUrl, src2x: node.avatarUrl })), searchWords: [searchTerm], subheading: node.email, children: renderPersonRowButtonComponent({
            node,
            onAdd,
            onRemove,
            ownerPk,
            isDisabled,
            isLoading,
            isCollaborator,
        }) }, node.pk));
}
