import { Frame, Orientation, getAspectRatio as baseGetAspectRatio, } from '@marvelapp/core';
export function getAspectRatio(deviceFrame, portrait) {
    const orientation = portrait ? Orientation.Portrait : Orientation.Landscape;
    const aspectRatio = deviceFrame && isFrame(deviceFrame)
        ? baseGetAspectRatio(deviceFrame, orientation)
        : 1;
    return aspectRatio;
}
// Need this to convince TS that a member of FrameEnum (graphql generated type) is the same as a member of Frame (frame enum in marvel/core)
// eslint-disable-next-line @typescript-eslint/no-explicit-any
function isFrame(value) {
    return Object.values(Frame).indexOf(value) > -1;
}
