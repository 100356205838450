import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { observer } from 'mobx-react';
import { useCallback, useState } from 'react';
import * as React from 'react';
import { ConfirmDialog, Heading, Text } from '@marvelapp/ballpark-components';
import styled, { css } from '@marvelapp/styled';
import { Box, Flex } from '@marvelapp/ui';
import { StepSettingsIcons, } from '../StepSettingsIcons';
import { CardMenu } from './CardMenu';
export const SidebarCard = observer(function SidebarCard({ active, attributes, hasConditions, hasHoverMenu = false, id, isDisabled = false, isDragging, isStepRequired, listeners, onCardClick, onStepClone, onStepDelete, setShowConfirmDeleteModal, showConfirmDeleteModal, skipped, slideAvatars, stepIcon, stepName, stepTitle, testId, videoGuideStatus, showStepSettingsIcons = true, }) {
    const [isPopoverActive, setIsPopoverActive] = useState(false);
    const preventEventBubbling = (e) => {
        // need to prevent default so that the scroll to anchor
        // behaviour doesn't get triggered
        e.preventDefault();
        // and make sure the card doesn't get selected as active
        e.stopPropagation();
    };
    const togglePopover = useCallback(() => setIsPopoverActive(!isPopoverActive), [isPopoverActive]);
    return (_jsxs(Container, Object.assign({ border: "1px solid", borderColor: getBorderColor({ active, isDragging }), boxShadow: getBoxShadow({ active, isDragging }), "data-state": active ? 'active' : 'inactive', hasHoverMenu: hasHoverMenu, id: id, onClick: onCardClick, opacity: isDisabled ? 0.5 : 1, "data-testid": testId }, attributes, listeners, { children: [showConfirmDeleteModal && (_jsx(ConfirmDialog, { onConfirmAction: onStepDelete, description: "There is another step with conditions that direct users to this step. If you delete this step, all conditions on that other step will be cleared.", heading: "Deleting this step will remove conditions from another step", onCloseDialog: () => setShowConfirmDeleteModal(false) })), _jsx(Heading, { pointerEvents: "none", lineHeight: "18px", marginBottom: "xxs", paddingRight: "s", size: "s", truncatedTwo: true, children: stepTitle }), _jsxs(IconWrapper, { filter: skipped ? 'grayscale(1)' : 'none', children: [_jsxs(Flex, { alignItems: "center", children: [stepIcon, _jsx(Text, { marginLeft: "xxs", lineHeight: "18px", size: "xs", children: stepName })] }), showStepSettingsIcons && (_jsx(Icons, { children: _jsx(StepSettingsIcons, { hasConditions: hasConditions, isStepRequired: isStepRequired, videoGuideStatus: videoGuideStatus }) }))] }), slideAvatars, hasHoverMenu && (_jsx(ButtonWrap, { isActive: isPopoverActive, onClick: preventEventBubbling, children: _jsx(CardMenu, { isActive: isPopoverActive, onClone: onStepClone, onDelete: onStepDelete, onOpenChange: togglePopover }) }))] })));
});
const Icons = styled(Box) `
  > div {
    display: flex;
    align-items: center;
    div,
    button,
    svg {
      display: block;
    }
  }
`;
const PinTR = styled(Box) `
  position: absolute;
  top: 8px;
  right: 8px;
`;
const IconWrapper = styled(Box) `
  filter: ${(props) => props.filter};
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  pointer-events: none;
`;
const ButtonWrap = styled(PinTR) `
  ${(props) => !props.isActive &&
    css `
      opacity: 0;
      visibility: hidden;
    `}

  svg {
    width: 22px;
    height: 22px;
  }
`;
const Container = styled(Box).attrs({
    bg: 'background',
    borderRadius: 'xl',
    hoverBg: 'slate2',
    padding: 14,
    transition: 'smooth.fast',
    mb: 's',
}) `
  position: relative;
  cursor: pointer;
  overflow-y: hidden;

  &:hover ${ButtonWrap} {
    opacity: 1;
    visibility: visible;
  }

  &:last-of-type {
    margin-bottom: 0;
  }
`;
const getBoxShadow = ({ isDragging, active }) => {
    if (isDragging) {
        return 'popover.default';
    }
    if (active) {
        return 'typeCard.active';
    }
    return 'button.secondary';
};
const getBorderColor = ({ isDragging, active }) => {
    if (isDragging) {
        return 'transparent';
    }
    if (active) {
        return 'blue8';
    }
    return 'slate6';
};
