import { __rest } from "tslib";
import { jsx as _jsx } from "react/jsx-runtime";
import { observer } from 'mobx-react';
import { BaseAddNewCategory } from '../BaseAddNewCategory';
import { useSortable } from '../dndUtils';
export const AddNewCategory = observer(function AddNewCategory(_a) {
    var _b;
    var { id } = _a, props = __rest(_a, ["id"]);
    const _c = useSortable({ id }), { over, active } = _c, sortable = __rest(_c, ["over", "active"]);
    const isDraggedOver = over
        ? id === over.id && ((_b = active === null || active === void 0 ? void 0 : active.data.current) === null || _b === void 0 ? void 0 : _b.type) !== 'container'
        : false;
    return (_jsx(BaseAddNewCategory, Object.assign({ isDraggedOver: isDraggedOver }, sortable, props)));
});
