import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { observer } from 'mobx-react';
import * as React from 'react';
import { Button } from '@marvelapp/ballpark-components';
import styled from '@marvelapp/styled';
import { boxStyles } from '@marvelapp/ui';
import { StartRecordingIcon, StopRecordingIcon } from '../../../icons';
export const StartRecordingButton = observer(function StartRecordingButton({ isDisabled, isLoading, onClick, }) {
    return (_jsx(Button, { "data-testid": "start-recording-video", flex: 1, isDisabled: isDisabled, isLoading: isLoading, kind: "white", onClick: onClick, size: "l", translateOnHover: false, children: _jsxs(Inner, { color: getIconFill(isDisabled || isLoading), children: [_jsx(StartRecordingIcon, {}), "Start recording", _jsx("span", {})] }) }));
});
export const StopRecordingButton = observer(function StopRecordingButton({ isDisabled, isLoading, isRecording, onClick, }) {
    return (_jsx(Button, { "data-testid": "stop-video-recording", width: '100%', isDisabled: isDisabled, isLoading: isLoading, kind: "negative", onClick: onClick, size: "l", children: _jsxs(Inner, { color: getIconFill(isDisabled, isRecording), children: [_jsx(StopRecordingIcon, {}), "Stop recording", _jsx("span", {})] }) }));
});
const Inner = styled.div `
  display: flex;
  align-items: center;
  margin-left: -${(props) => props.theme.space.xs}px;
  width: 100%;
  justify-content: space-between;

  svg {
    ${boxStyles};
    fill: currentColor;
  }
`;
function getIconFill(isDisabled, isRecording) {
    if (isDisabled) {
        return 'slate8';
    }
    if (isRecording) {
        return 'white12';
    }
    return 'tomato9';
}
