import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { gql, useQuery } from '@apollo/client';
import { useHistoryState, } from '@ballpark/realtime-plugin-history';
import LogRocket from 'logrocket';
import { observable, runInAction } from 'mobx';
import { observer, useLocalObservable } from 'mobx-react';
import { useCallback, useEffect, useRef, useState } from 'react';
import * as React from 'react';
import { Redirect, useRouteMatch } from 'react-router-dom';
import { useQueryState } from 'react-router-use-location-state';
import { useAction, usePermissions } from '@marvelapp/ballpark-application';
import { BtwButton, BtwModal, NotFound } from '@marvelapp/ballpark-components';
import { useOnClickOutside } from '@marvelapp/hooks';
import styled from '@marvelapp/styled';
import { Box, Loader } from '@marvelapp/ui';
import { showSuccessNotification } from '@marvelapp/ui-internal';
import { dismissNotification } from '@marvelapp/ui-internal/src/ToastContainer/notifications';
import { StudyTypeEnum, } from '../../__generated__/queryTypes';
import { Permissions } from '../../docUtils/useAuthToken';
import { MUST_PASS_ID } from '../../graphql/errors';
import { useProjectContext } from '../../utils/ProjectContext';
import { getPageError, getPageErrorCopy } from '../../utils/getPageErrorCopy';
import { useCancelScreenerChanges } from '../../utils/useCancelScreenerChanges';
import ActiveSlidesSidebar from './ActiveSlidesSidebar';
import { ScrollSpyController } from './ScrollSpyController';
import { SlidesList } from './SlidesList';
import { UIContext } from './uiContext';
const GET_USER_TEST = gql `
  query getUserTest($pk: Int) {
    userTest(pk: $pk) {
      pk
      uuid
      prototype {
        pk
        name
        isArchived
        passwordProtected
        isLocked
      }
    }
  }
`;
const GET_ACTIVE_RECRUITMENT_ORDERS = gql `
  query getActiveRecruitmentOrders($userTestUUID: String) {
    user {
      pk
      company {
        pk
        billing {
          subscriptionPk
          recruitmentOrders(
            first: 1
            statuses: [ACTIVE]
            testUUID: $userTestUUID
          ) @connection(key: "recruitmentOrders") {
            edges {
              node {
                pk
                studyType
              }
            }
          }
        }
      }
    }
  }
`;
const ACTIVE_SLIDE_QUERY_PARAM = 's'; // keep it short as no one reads urls
export default observer(function Project({ permission, isLoadingUserTestDoc, }) {
    var _a, _b, _c, _d, _e, _f, _g;
    const pageRef = useRef(null);
    const [isAddSidebarOpen, setIsAddSidebarOpen] = useState(false);
    const match = useRouteMatch();
    const projectPk = match.params.id;
    const permissions = usePermissions();
    const canEditProjects = permissions.canEditProjects();
    const hasPermission = permission !== Permissions.None && permission !== Permissions.Unknown;
    const { data, loading, error } = useQuery(GET_USER_TEST, {
        variables: { pk: Number(projectPk) },
    });
    const pageError = getPageError({
        data,
        loading,
        error,
    }, MUST_PASS_ID);
    const { data: recruitmentOrdersData } = useQuery(GET_ACTIVE_RECRUITMENT_ORDERS, {
        variables: { userTestUUID: (_a = data === null || data === void 0 ? void 0 : data.userTest) === null || _a === void 0 ? void 0 : _a.uuid },
        skip: !((_b = data === null || data === void 0 ? void 0 : data.userTest) === null || _b === void 0 ? void 0 : _b.uuid) || !hasPermission || !!pageError,
    });
    useOnClickOutside(pageRef, () => {
        setIsAddSidebarOpen(false);
    });
    const [activeSlideUUID, setActiveSlideUUID] = useQueryState(ACTIVE_SLIDE_QUERY_PARAM, '');
    const scrollSpyController = useRef(new ScrollSpyController());
    const cache = useLocalObservable(() => {
        return {
            focusSlideUUID: '',
            activeSlideUUID,
            setActiveSlideUUID,
            slideToFocusOnScrollStopUUID: '',
            scrollSpyController: scrollSpyController.current,
        };
    }, observable.deep);
    const storeActiveSlide = useCallback(() => {
        return cache.activeSlideUUID;
    }, [cache]);
    const restoreActiveSlide = useAction((activeSlideUUID, event) => {
        const wasUndo = event.type === 'undo';
        dismissNotification(`undo-redo`);
        // needs to be wrapped in setTimeout or the notification will be dismissed before it's shown
        setTimeout(() => {
            showSuccessNotification({
                toastId: `undo-redo`,
                content: wasUndo ? 'Undo' : 'Redo',
            });
        }, 0);
        cache.setActiveSlideUUID(activeSlideUUID !== null && activeSlideUUID !== void 0 ? activeSlideUUID : null);
    }, [cache]);
    useHistoryState('activeSlideUUID', storeActiveSlide, restoreActiveSlide);
    useEffect(() => {
        runInAction(() => {
            cache.activeSlideUUID = activeSlideUUID;
        });
    }, [activeSlideUUID, cache]);
    if (!loading && !isLoadingUserTestDoc && !hasPermission) {
        return (_jsx(NotFound, { heading: "Permission denied", subheading: "You do not have sufficient permissions to access this document. Please contact the project owner or account admin for more info." }));
    }
    if (pageError) {
        return _jsx(NotFound, Object.assign({}, getPageErrorCopy(pageError)));
    }
    const isProjectLocked = (_d = (_c = data === null || data === void 0 ? void 0 : data.userTest) === null || _c === void 0 ? void 0 : _c.prototype) === null || _d === void 0 ? void 0 : _d.isLocked;
    if (isProjectLocked) {
        return _jsx(Redirect, { to: `/projects/team` });
    }
    if (data && !isProjectLocked && !canEditProjects) {
        return _jsx(Redirect, { to: `/project/${projectPk}/responses/` });
    }
    const hasActiveRecruitmentOrders = !!((_g = (_f = (_e = recruitmentOrdersData === null || recruitmentOrdersData === void 0 ? void 0 : recruitmentOrdersData.user) === null || _e === void 0 ? void 0 : _e.company) === null || _f === void 0 ? void 0 : _f.billing) === null || _g === void 0 ? void 0 : _g.recruitmentOrders.edges.filter((order) => order.studyType !== StudyTypeEnum.SURVEY).length);
    return (_jsx(_Fragment, { children: _jsx(PageBody, { children: _jsxs(UIContext.Provider, { value: cache, children: [_jsx("div", { ref: pageRef, children: _jsx(ActiveSlidesSidebar, { isAddSidebarOpen: isAddSidebarOpen, isLoading: isLoadingUserTestDoc, onAddNewStep: () => setIsAddSidebarOpen(!isAddSidebarOpen), onCloseAddSidebar: () => setIsAddSidebarOpen(false) }) }), isLoadingUserTestDoc ? (_jsx(LoaderWrapper, { children: _jsx(Loader, {}) })) : (_jsxs(_Fragment, { children: [_jsx(SlidesList, {}), _jsx(ScreenerEditWarning, { hasActiveRecruitmentOrders: hasActiveRecruitmentOrders })] }))] }) }) }));
});
const ScreenerEditWarning = observer(function ScreenersWarning({ hasActiveRecruitmentOrders, }) {
    const [isOpen, setIsOpen] = useState(false);
    const { beforeChange } = useProjectContext();
    let hideScreenerWarning = null;
    const match = useRouteMatch();
    const projectPk = match.params.id;
    try {
        hideScreenerWarning = window.sessionStorage.getItem(`hideScreenerWarning-${projectPk}`);
    }
    catch (e) {
        // pass
    }
    const callback = useCancelScreenerChanges(setIsOpen);
    useEffect(() => {
        var _a;
        if (!hideScreenerWarning && hasActiveRecruitmentOrders) {
            if ((_a = process.env.DEPLOY_ENV) === null || _a === void 0 ? void 0 : _a.includes('production')) {
                LogRocket.track('screenerEditWarning');
            }
            beforeChange.on(callback);
            return () => {
                beforeChange.off(callback);
            };
        }
    }, [
        beforeChange,
        callback,
        hasActiveRecruitmentOrders,
        hideScreenerWarning,
        projectPk,
    ]);
    if (!hasActiveRecruitmentOrders)
        return null;
    return (_jsx(BtwModal.Root, { open: isOpen, onOpenChange: setIsOpen, children: _jsxs(BtwModal.Content, { contentClassName: "w-full flex-row", "data-testid": "active-recruitment-orders-warning", hasPortal: true, children: [_jsx(BtwModal.BigHeader, { onlyChild: true, title: "Recruitment in progress", description: "You're actively recruiting participants for this project. Making changes to screeners can lead to inaccurate participants and response data." }), _jsxs(BtwModal.Footer, { children: [_jsx(BtwModal.Close, { asChild: true, children: _jsx(BtwButton, { "data-testid": "cancel-button", variant: "secondary", children: "Cancel" }) }), _jsx(BtwButton, { "data-testid": "confirm-button", onClick: () => {
                                var _a;
                                if ((_a = process.env.DEPLOY_ENV) === null || _a === void 0 ? void 0 : _a.includes('production')) {
                                    LogRocket.track('screenerEditWarning-confirmMakeChanges');
                                }
                                window.sessionStorage.setItem(`hideScreenerWarning-${projectPk}`, 'true');
                                beforeChange.off(callback);
                                setIsOpen(false);
                            }, variant: "primary", children: "Make changes anyway" })] })] }) }));
});
const PageBody = styled(Box) `
  flex: 1;
  display: flex;
  align-items: stretch;
`;
const LoaderWrapper = styled.div `
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;
