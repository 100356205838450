import { __rest } from "tslib";
import { jsx as _jsx } from "react/jsx-runtime";
import { observer } from 'mobx-react';
import * as React from 'react';
import { StepClass, StepUtils } from '@marvelapp/user-test-creator';
import { useStepContext } from '../../../utils/stepContext';
import { ChoiceBar } from '../ChoiceBar';
import { SummaryStep } from './SummaryStep';
export default observer(function SummaryPreference(_a) {
    var { active, secondary, showAllLink, sortedChoices, stats } = _a, rest = __rest(_a, ["active", "secondary", "showAllLink", "sortedChoices", "stats"]);
    const { step } = useStepContext();
    if (!StepUtils.isOfType(step, StepClass.PreferenceTest))
        return null;
    return (_jsx(SummaryStep, Object.assign({ active: active, secondary: secondary, showAllLink: showAllLink }, rest, { children: stats &&
            sortedChoices.map((uuid, index) => {
                const answer = stats.find((stat) => (stat === null || stat === void 0 ? void 0 : stat.answerValue) === uuid);
                const choiceDefinition = step.choiceDefinitions[uuid];
                if (!choiceDefinition)
                    return null; // choice must have been deleted
                const { choiceTitle, src } = step.choiceDefinitions[uuid];
                return (_jsx(ChoiceBar, { participants: (answer === null || answer === void 0 ? void 0 : answer.answerCount) ? answer.answerCount : 0, percent: (answer === null || answer === void 0 ? void 0 : answer.percentageChosen) ? answer.percentageChosen : 0, title: choiceTitle || `Choice ${index + 1}`, image: src }, uuid));
            }) })));
});
