import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { observer } from 'mobx-react';
import { BtwText, RatingScore, Stack } from '@marvelapp/ballpark-components';
import { RatingStepUtils } from '@marvelapp/user-test-creator';
import { CopyToClipboardButton } from '../CopyToClipboadButton';
export const RatingCell = observer(function RatingCell({ step, value, }) {
    const maxRatingValue = RatingStepUtils.getMaxRating(step);
    const ratingType = RatingStepUtils.getRatingType(step);
    return (_jsxs(Stack, { direction: "row", gap: "3", className: "items-unset", children: [_jsx(RatingScore, { ratingType: ratingType, maxRating: maxRatingValue, averageRating: value, size: "sm" }), _jsxs(BtwText, { weight: "medium", children: [value, "/", maxRatingValue, _jsx(CopyToClipboardButton, { text: `${value}/${maxRatingValue}` })] })] }));
});
