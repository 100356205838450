import { __rest } from "tslib";
import { jsx as _jsx } from "react/jsx-runtime";
import { observer } from 'mobx-react';
import * as React from 'react';
import { formatTime } from '@marvelapp/core';
import { StepClass, StepUtils } from '@marvelapp/user-test-creator';
import StatBox from '../../../components/PrototypeStat';
import PrototypeStatsGrid from '../../../components/PrototypeStatsGrid';
import { useStepContext } from '../../../utils/stepContext';
import { SummaryStep } from './SummaryStep';
export default observer(function SummaryWebsite(_a) {
    var { active, secondary, showAllLink, averageDuration } = _a, rest = __rest(_a, ["active", "secondary", "showAllLink", "averageDuration"]);
    const { step } = useStepContext();
    if (!StepUtils.isOfType(step, StepClass.WebsiteTask))
        return null;
    return (_jsx(SummaryStep, Object.assign({ active: active, secondary: secondary, showAllLink: showAllLink }, rest, { children: _jsx(PrototypeStatsGrid, { children: averageDuration !== null && (_jsx(StatBox, { testId: "avg-duration", kind: "slate", title: "Avg. duration", stat: formatTime(averageDuration) })) }) })));
});
