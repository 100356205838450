import { useLazyQuery } from '@apollo/client';
import { useCallback, useEffect, useRef } from 'react';
const POLLING_TIMEOUT = 300000;
const POLL_INTERVAL = 1000;
/** @type any */
export const usePollForQueryComplete = ({ onQueryComplete, onQueryFail, onQueryPolled, query, queryName, pollTimeout = POLLING_TIMEOUT, }) => {
    var _a, _b, _c, _d;
    const [pollForStatus, { data, stopPolling }] = useLazyQuery(query, {
        pollInterval: POLL_INTERVAL,
    });
    const prevData = useRef(null);
    const pollStart = useRef(new Date());
    const isQueryComplete = (_a = data === null || data === void 0 ? void 0 : data[queryName]) === null || _a === void 0 ? void 0 : _a.complete;
    const isQueryFailed = (_b = data === null || data === void 0 ? void 0 : data[queryName]) === null || _b === void 0 ? void 0 : _b.failed;
    const failedCode = (_c = data === null || data === void 0 ? void 0 : data[queryName]) === null || _c === void 0 ? void 0 : _c.failureCode;
    const isPrevQueryComplete = (_d = prevData === null || prevData === void 0 ? void 0 : prevData[queryName]) === null || _d === void 0 ? void 0 : _d.complete;
    const onQueryCompleteCached = useRef(onQueryComplete);
    const onQueryFailCached = useRef(onQueryFail);
    const onQueryPolledCached = useRef(onQueryPolled);
    useEffect(() => {
        prevData.current = data;
    }, [data]);
    const pollWithTimeout = useCallback((...args) => {
        pollStart.current = new Date();
        prevData.current = null;
        pollForStatus(...args);
    }, [pollForStatus]);
    useEffect(() => {
        if (isQueryComplete && !isPrevQueryComplete) {
            stopPolling();
            onQueryCompleteCached.current(data);
            return;
        }
        if (new Date() - pollStart.current > pollTimeout || isQueryFailed) {
            stopPolling();
            onQueryFailCached.current(failedCode);
            return;
        }
        if (onQueryPolledCached.current) {
            onQueryPolledCached.current(data);
        }
    }, [
        data,
        failedCode,
        isPrevQueryComplete,
        isQueryComplete,
        isQueryFailed,
        onQueryCompleteCached,
        onQueryPolledCached,
        onQueryFailCached,
        prevData,
        queryName,
        stopPolling,
    ]);
    return { pollForStatus: pollWithTimeout };
};
