import { __rest } from "tslib";
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { TZDate } from '@date-fns/tz';
import { runInAction } from 'mobx';
import { observer } from 'mobx-react';
import { useEffect, useReducer } from 'react';
import * as React from 'react';
import { useAction } from '@marvelapp/ballpark-application';
import { Combobox, Stack, Tooltip, cn, } from '@marvelapp/ballpark-components';
import { MoonIcon, SleepIcon, flags } from '@marvelapp/ballpark-icons';
import { StudyTypeEnum } from '../../__generated__/queryTypes';
import { AgeSlider } from './AgeSlider';
import { ComboboxFilter } from './ComboboxFilter';
import { useRecruitmentState } from './RecruitmentContext';
import { ColumnLayout, RecruitmentSection } from './common';
import { timezones } from './timezones';
import { GeneralTargetingFilter } from './types';
export const GeneralTargeting = observer(function Targeting() {
    const { studyType } = useRecruitmentState();
    return (_jsx(RecruitmentSection, { children: _jsx(ColumnLayout, { className: cn('md:flex-row '), items: [
                {
                    title: 'Country',
                    isRequired: true,
                    component: _jsx(CountrySelect, {}),
                },
                {
                    title: 'Age',
                    component: studyType === StudyTypeEnum.SURVEY ? (_jsx(AgeSlider, {})) : (_jsx(ComboboxFilter, { filterGroupId: "GROUP_DEFAULT", filterId: GeneralTargetingFilter.Age, placeholder: "Select age groups" })),
                },
            ] }) }));
});
const CountrySelect = observer(function CountrySelect() {
    return (_jsx(Stack, { "data-testid": "country-filter", direction: "row", width: "full", children: _jsxs(Combobox.Root, { variant: "single-select", children: [_jsx(CountrySelectTrigger, {}), _jsx(CountryItems, {})] }) }));
});
function isCountryWithFlagIcon(countryName) {
    return countryName in flags;
}
const DEFAULT_GROUP_ID = 'GROUP_DEFAULT';
const CountrySelectTrigger = observer(function CountryFilterTrigger() {
    const { getFilterByGroupAndId, getSelectedOptionsByFilterAndGroupId, updateSingleSelectFilterSelection, } = useRecruitmentState();
    const [selectedCountry] = getSelectedOptionsByFilterAndGroupId(GeneralTargetingFilter.Country, DEFAULT_GROUP_ID);
    const FlagIcon = isCountryWithFlagIcon(selectedCountry === null || selectedCountry === void 0 ? void 0 : selectedCountry.id)
        ? flags[selectedCountry === null || selectedCountry === void 0 ? void 0 : selectedCountry.id]
        : undefined;
    useEffect(() => {
        runInAction(() => {
            // Some countries are only available for certain study types. If a user selects
            // such a country and then switches to a study type that doesn't support it,
            // we want to set the country filter to 'US' instead of leaving it empty.
            if (!selectedCountry) {
                const countryFilter = getFilterByGroupAndId(DEFAULT_GROUP_ID, GeneralTargetingFilter.Country);
                updateSingleSelectFilterSelection({
                    filterId: GeneralTargetingFilter.Country,
                    filterName: countryFilter.name,
                    option: Object.assign(Object.assign({}, countryFilter.options[0]), { filterId: GeneralTargetingFilter.Country, groupId: DEFAULT_GROUP_ID, customValue: null }),
                });
            }
        });
    }, [
        getFilterByGroupAndId,
        selectedCountry,
        updateSingleSelectFilterSelection,
    ]);
    return (_jsx(Combobox.Trigger, { className: "w-full pl-3", "data-testid": "country-filter-trigger", hasPlaceholder: !selectedCountry, truncate: true, size: "intermediate", children: selectedCountry ? (_jsxs(Stack, { align: "center", direction: "row", gap: "2", children: [FlagIcon && _jsx(FlagIcon, { className: "size-4" }), selectedCountry.name, _jsx(SleepIndicator, { countryId: selectedCountry.id, selected: true })] })) : ('Please select a country') }));
});
const CountryItems = observer(function CountryItems() {
    const { getFilterByGroupAndId } = useRecruitmentState();
    const countryFilter = getFilterByGroupAndId(DEFAULT_GROUP_ID, GeneralTargetingFilter.Country);
    return (_jsxs(Combobox.Content, { modal: true, placeholder: "Search for options...", children: [_jsx(Combobox.Empty, {}), countryFilter.options.map((country) => {
                if (!country)
                    return null;
                return _jsx(CountryItem, { country: country }, country.id);
            })] }));
});
const CountryItem = observer(function CountryItem({ country, }) {
    const { getFilterByGroupAndId, getSelectedOptionsByFilterAndGroupId, updateSingleSelectFilterSelection, } = useRecruitmentState();
    const countryFilter = getFilterByGroupAndId(DEFAULT_GROUP_ID, GeneralTargetingFilter.Country);
    const [selectedCountry] = getSelectedOptionsByFilterAndGroupId(GeneralTargetingFilter.Country, DEFAULT_GROUP_ID);
    const FlagIcon = isCountryWithFlagIcon(country.id)
        ? flags[country.id]
        : undefined;
    const handleOnSelect = useAction(() => {
        updateSingleSelectFilterSelection({
            filterId: GeneralTargetingFilter.Country,
            filterName: countryFilter.name,
            option: Object.assign(Object.assign({}, country), { filterId: GeneralTargetingFilter.Country, groupId: DEFAULT_GROUP_ID, customValue: null }),
        });
    }, [country, countryFilter, updateSingleSelectFilterSelection]);
    const selected = !!selectedCountry && selectedCountry.id === country.id;
    return (_jsx(Combobox.Item, { selected: selected, onSelect: handleOnSelect, "data-testid": `country-option-${country.id}`, children: _jsxs(Stack, { align: "center", direction: "row", gap: "2", children: [FlagIcon && _jsx(FlagIcon, { className: "size-4" }), country.name, _jsx(SleepIndicator, { countryId: country.id, selected: selected })] }) }, country.id));
});
const SleepIndicator = observer(function SleepIndicator({ countryId, selected, }) {
    // allow re-rendering of the component every minute
    const [_, forceUpdate] = useReducer((x) => x + 1, 0);
    // we don't care if the useEffect is recreated when this component is re-rendered
    // the component is memoized so it won't be re-rendered unless the countryId changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const date = new Date();
    useEffect(() => {
        const timeout = setTimeout(forceUpdate, (60 - date.getSeconds()) * 1000);
        return () => clearTimeout(timeout);
    }, [date]);
    const specificTimezones = timezones[countryId];
    const sleepingTimezones = specificTimezones === null || specificTimezones === void 0 ? void 0 : specificTimezones.map((timeZone) => {
        try {
            const tzDate = new TZDate(date, timeZone);
            return tzDate;
        }
        catch (e) {
            // Some timezones are not supported by the tzdb library or specific systems so ignore them
            return undefined;
        }
    }).filter((time) => time !== undefined).map((time) => {
        const hour = time.getHours();
        const minutes = time.getMinutes();
        // Return true if time is between 10:30 PM and 7:30 AM
        if ((hour === 22 && minutes >= 30) ||
            hour > 22 ||
            hour < 7 ||
            (hour === 7 && minutes <= 30)) {
            return true;
        }
        return false;
    });
    const isSleeping = sleepingTimezones.every((time) => time === true);
    if (isSleeping) {
        return _jsx(SleepingIcon, { selected: selected });
    }
    const isPartiallySleeping = sleepingTimezones.some((time) => time === true);
    if (isPartiallySleeping) {
        return _jsx(PartialSleepingIcon, { selected: selected });
    }
    return null;
});
const SleepingIcon = observer(function SleepingIcon({ selected, }) {
    return (_jsx(SleepIndicatorIcon, { selected: selected, tooltip: "Local time may delay your order's completion", "data-testid": "sleeping-icon", children: _jsx(SleepIcon, {}) }));
});
const PartialSleepingIcon = observer(function PartialSleepingIcon({ selected, }) {
    return (_jsx(SleepIndicatorIcon, { selected: selected, tooltip: "One or more timezones in this country are in evening hours, so responses may take longer", "data-testid": "partial-sleeping-icon", children: _jsx(MoonIcon, {}) }));
});
const SleepIndicatorIcon = observer(function IconWithTooltip(_a) {
    var { children, tooltip, selected } = _a, props = __rest(_a, ["children", "tooltip", "selected"]);
    return (_jsx(Stack, Object.assign({ className: cn('ml-auto', { 'mr-6': !selected }) }, props, { children: _jsxs(Tooltip.Root, { children: [_jsx(Tooltip.Portal, { children: _jsx(Tooltip.Content, { className: "!z-[2000] max-w-64 text-center text-sm", "data-testid": "sleep-tooltip", children: tooltip }) }), _jsx(Tooltip.Trigger, { asChild: true, "data-testid": "sleep-tooltip-trigger", children: _jsx("div", { children: children }) })] }) })));
});
