import { __rest } from "tslib";
import { jsx as _jsx } from "react/jsx-runtime";
import { useOverrideNativeUndo } from '@ballpark/realtime-plugin-history';
import { observer } from 'mobx-react';
import { useRef } from 'react';
import * as React from 'react';
import { Input } from '@marvelapp/ballpark-components';
import styled from '@marvelapp/styled';
import { inputStyles } from '@marvelapp/ui';
import { useStateFromProp } from '../../hooks/useStateFromProp';
export default observer(function EditableRadio(_a) {
    var { autoFocus, cursor, onBlur, onChange: parentOnChange, isDisabled = false, onKeyDown, placeholder, readOnly = false, testId, value } = _a, attributes = __rest(_a, ["autoFocus", "cursor", "onBlur", "onChange", "isDisabled", "onKeyDown", "placeholder", "readOnly", "testId", "value"]);
    const textareaRef = useRef(null);
    // In askhub state updates are async so we need to copy the value
    // to local state here so that the updates to the input's value are synchronous.
    const [internalValue, setInternalValue] = useStateFromProp(value || '');
    const overrideNativeUndo = useOverrideNativeUndo(onKeyDown);
    const onChange = (event) => {
        setInternalValue(event.target.value);
        parentOnChange(event.target.value);
    };
    return (_jsx(Radio, Object.assign({ autoFocus: autoFocus, cursor: cursor, disabled: readOnly || isDisabled, onChange: onChange, onKeyDown: overrideNativeUndo, onBlur: onBlur, ref: textareaRef, value: internalValue, placeholder: placeholder, "data-testid": testId, size: "xl", positionWidth: "100%" }, attributes)));
});
const Radio = styled(Input).attrs((props) => ({
    fontFamily: 0,
    hoverBoxShadow: props.readOnly ? 'button.secondary' : 'editable.hover',
    focusBoxShadow: props.readOnly ? 'button.secondary' : 'editable.focus',
    border: '1px solid',
    borderColor: 'slate6',
    focusBorderColor: 'slate.slate4',
    hoverBorderColor: 'slate.slate4',
    color: 'slate12',
    fontSize: 'm',
    fontWeight: 'medium',
    borderRadius: 'l',
    boxShadow: 'button.secondary',
    bg: 'background',
    px: 's',
    py: 's',
})) `
  ${inputStyles};
  transition: none;
  letter-spacing: -0.2px;
`;
