import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { range } from 'lodash-es';
import { observer } from 'mobx-react';
import { useCallback, useState } from 'react';
import { BtwButton, BtwHeading, BtwInput, BtwModal, BtwText, Stack, } from '@marvelapp/ballpark-components';
import { SearchIcon } from '@marvelapp/ballpark-icons';
import { getPrototypeShareId } from '@marvelapp/core';
import { CardLoader } from '@marvelapp/ui-internal';
import { getAspectRatio } from '../utils';
import { Grid } from './Grid';
import { GroupedScreens, ScreenPickerTab } from './GroupedScreens';
import { UngroupedScreens } from './UngroupedScreens';
import { useGetProjectScreens } from './useGetProjectInfo';
const ScreenPicker = observer(function ScreenPicker({ activeTab, isExternalPrototype, onSelectScreen, otherScreen, prototypeUrl, selectedScreen, title, }) {
    const shareId = getPrototypeShareId(prototypeUrl);
    const { error, isLoadingScreens, project, screens, sections, hotspots } = useGetProjectScreens({ shareId });
    const [searchTerm, setSearchTerm] = useState('');
    const selectScreen = useCallback((screenPk) => {
        onSelectScreen(screenPk, project !== null && project !== void 0 ? project : undefined);
    }, [onSelectScreen, project]);
    const { ungroupedScreens, sections: filteredSections, componentsSection, } = getScreens({
        screens,
        sections,
        searchTerm,
        hotspots,
    });
    const hasScreens = (activeTab === ScreenPickerTab.Screens &&
        (ungroupedScreens.length > 0 || filteredSections.length > 0)) ||
        (activeTab === ScreenPickerTab.Components && componentsSection.length > 0);
    let content;
    if (error) {
        return _jsx(_Fragment, { children: "TODO error message" });
    }
    if (isLoadingScreens || !(project === null || project === void 0 ? void 0 : project.settings)) {
        content = (_jsx(Grid, { className: "px-8", children: range(15).map((screen) => (_jsx(Stack, { className: "pb-4", width: "full", align: "stretch", children: _jsx(CardLoader, { ratio: 3 / 5 }) }, screen))) }));
    }
    else {
        const { deviceFrame, portrait } = project.settings;
        const aspectRatio = getAspectRatio(deviceFrame, portrait);
        content = (_jsxs(Stack, { width: "full", className: "px-8 pb-8", children: [activeTab === ScreenPickerTab.Screens && (_jsx(UngroupedScreens, { aspectRatio: aspectRatio, isExternalPrototype: isExternalPrototype, onSelectScreen: selectScreen, otherScreen: otherScreen, screens: ungroupedScreens, searchTerm: searchTerm, selectedScreen: selectedScreen })), _jsx(GroupedScreens, { activeTab: activeTab, aspectRatio: aspectRatio, componentsSection: componentsSection, isExternalPrototype: isExternalPrototype, onSelectScreen: selectScreen, otherScreen: otherScreen, searchTerm: searchTerm, sections: filteredSections, selectedScreen: selectedScreen })] }));
    }
    return (_jsxs(Stack, { "data-testid": "prototype-task-screen-picker", width: "full", align: "stretch", gap: "6", className: "flex-1", children: [_jsxs(Stack, { align: "center", justify: "between", direction: { initial: 'col', sm: 'row' }, gap: { initial: '4', sm: '0' }, width: "full", className: "px-8", children: [_jsx(BtwHeading, { size: "lg", children: title }), _jsx(BtwInput.Root, { leadingIcon: _jsx(SearchIcon, {}), children: _jsx(BtwInput.Field, { autoFocus: true, className: "min-w-[320px]", id: "search", onChange: (event) => setSearchTerm(event.target.value), placeholder: `Search ${activeTab}`, "data-testid": "goal-screen-search-input-box", value: searchTerm }) })] }), !hasScreens && !isLoadingScreens && (_jsx(Stack, { className: "flex-1 px-8 pb-8", width: "full", children: _jsxs(Stack, { gap: "1", align: "center", className: "flex-1 rounded-lg border border-dashed border-gray-200", justify: "center", width: "full", children: [_jsx(BtwHeading, { size: "lg", children: activeTab === ScreenPickerTab.Screens
                                ? 'Uh oh, no results!'
                                : 'No components found' }), searchTerm && (_jsxs(BtwText, { align: "center", className: "max-w-[420px]", leading: "relaxed", size: "sm", children: ["Sorry, your search for", ' ', _jsx("span", { className: "rounded bg-amber-200/50 px-1 py-0.5 text-amber-800", children: searchTerm }), ' ', "returned nothing"] })), !searchTerm && (_jsx(BtwText, { align: "center", className: "max-w-[420px]", leading: "relaxed", size: "sm", children: "This Figma file has no components available" }))] }) })), hasScreens && (_jsx(Stack, { width: "full", align: "stretch", children: content }))] }));
});
export const ScreenPickerWithModal = observer(function ScreenPickerWithModal({ isExternalPrototype, isOpen, onOpenChange, onSelectScreen, otherScreen, prototypeUrl, screenSelector, selectedScreen, trigger, }) {
    const [activeTab, setActiveTab] = useState(ScreenPickerTab.Screens);
    const screenCopy = screenSelector ? `${screenSelector} screen` : 'screen';
    const showComponents = screenSelector === 'goal';
    const screenTitle = `Pick a ${screenCopy}${showComponents ? ' or component' : ''}`;
    return (_jsxs(BtwModal.Root, { open: isOpen, onOpenChange: onOpenChange, children: [trigger && _jsx(BtwModal.Trigger, { children: trigger }), _jsxs(BtwModal.Content, { contentClassName: "h-[100vh] max-h-[90vh] max-w-[95vw]", children: [_jsxs(Stack, { align: "center", direction: "row", className: "sticky top-0 z-10 w-full border-b border-gray-200 bg-white/90 p-3 backdrop-blur-md", children: [_jsxs(Stack, { align: "center", justify: "center", className: "flex-1", direction: "row", gap: "1", children: [_jsx(BtwButton, { size: "sm", "data-state": activeTab === ScreenPickerTab.Screens ? 'active' : 'inactive', "data-testid": "screens-tab", onClick: () => setActiveTab(ScreenPickerTab.Screens), variant: "ghost", children: "Screens" }), _jsx(BtwButton, { size: "sm", "data-state": activeTab === ScreenPickerTab.Components ? 'active' : 'inactive', "data-testid": "components-tab", onClick: () => setActiveTab(ScreenPickerTab.Components), variant: "ghost", children: "Interactive components" })] }), _jsx(BtwModal.CloseButton, {})] }), _jsx(ScreenPicker, { activeTab: activeTab, isExternalPrototype: isExternalPrototype, onSelectScreen: onSelectScreen, otherScreen: otherScreen, prototypeUrl: prototypeUrl, selectedScreen: selectedScreen, title: screenTitle })] })] }));
});
function getScreens({ screens, sections, searchTerm, hotspots, }) {
    const ungroupedScreens = [];
    const sectionScreensByPk = {};
    screens.forEach((screen) => {
        const { sectionPk } = screen;
        if (!sectionPk) {
            if (matchScreen(screen, searchTerm)) {
                ungroupedScreens.push(screen);
            }
            return;
        }
        if (matchScreen(screen, searchTerm)) {
            if (!sectionScreensByPk[sectionPk]) {
                sectionScreensByPk[sectionPk] = [];
            }
            sectionScreensByPk[sectionPk].push(screen);
            return;
        }
        const section = sections.find((s) => s.pk === sectionPk);
        if (section && caseInsensitiveMatch(section.name, searchTerm)) {
            if (!sectionScreensByPk[sectionPk]) {
                sectionScreensByPk[sectionPk] = [];
            }
            sectionScreensByPk[sectionPk].push(screen);
        }
    });
    const { filteredSections, componentsSection } = getSections({
        sections,
        sectionScreensByPk,
        hotspots,
    });
    return { ungroupedScreens, sections: filteredSections, componentsSection };
}
function getSections({ sections, sectionScreensByPk, hotspots, }) {
    const filteredSections = [];
    const componentsSection = [];
    sections.forEach((section) => {
        // TODO use a section flag to determine whether its a interactive component section
        if (section.name === 'Component Sets') {
            return null;
        }
        // if there are no screens in the section, there will be no entry in `sectionScreensByPk` object
        const screens = sectionScreensByPk[section.pk];
        if (screens) {
            if ((section.name === 'Components' ||
                section.name.includes('- interactive component')) &&
                hotspots) {
                const screenElements = getLinkedScreens(screens, hotspots);
                if (screenElements.length > 0) {
                    componentsSection.push({ section, screens: screenElements });
                }
                return;
            }
            filteredSections.push({ section, screens });
        }
    });
    return { filteredSections, componentsSection };
}
function matchScreen(screen, searchTerm) {
    return caseInsensitiveMatch(screen.displayName, searchTerm);
}
function caseInsensitiveMatch(a, b) {
    return a.toLowerCase().includes(b.toLowerCase());
}
function getLinkedScreens(screens, hotspots) {
    const linkedScreenIds = new Set();
    hotspots.forEach((hotspot) => {
        // Only show components for screens that have hotspots
        // that aren't default hotspots
        if (!hotspot.external_id.startsWith('default-')) {
            linkedScreenIds.add(hotspot.dest_img_fk);
            linkedScreenIds.add(hotspot.owner_img_fk);
        }
    });
    return screens.filter((screen) => linkedScreenIds.has(screen.pk));
}
