import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import * as React from 'react';
import theme from '@marvelapp/ballpark-theme';
import { Svg } from '@marvelapp/ui';
// eslint-disable-next-line mobx/missing-observer
export default function Heart({ height = '42', rating, width = '42', }) {
    const gradientId = `fill-heart-${rating}`;
    let fillRule = `url(#${gradientId})`;
    const percentage = rating * 100;
    if (percentage === 0) {
        fillRule = 'nonzero';
    }
    return (_jsxs(Svg, { height: height, viewBox: "0 0 42 42", width: width, children: [_jsx("defs", { children: _jsxs("linearGradient", { id: gradientId, children: [_jsx("stop", { offset: `${percentage}%`, stopColor: "currentColor" }), _jsx("stop", { offset: `${percentage}%`, stopColor: theme.colors.slate8 })] }) }), _jsx("path", { d: "m37.0087 11.1154c-.6638-1.309-1.6235-2.44239-2.8017-3.30882-1.1781-.86643-2.5418-1.44164-3.981-1.67927-1.4392-.23762-2.9137-.131-4.3045.31127-1.3908.44226-2.659 1.2078-3.7025 2.23487l-1.2183 1.11386-1.1758-1.08529c-1.0442-1.04334-2.3202-1.82074-3.722-2.26776s-2.8891-.55078-4.3387-.30267c-1.4413.22557-2.80821.79571-3.98627 1.66275-1.17807.86704-2.13309 2.00579-2.78522 3.32106-.8775 1.7281-1.18445 3.6935-.87611 5.6098.30835 1.9163 1.21586 3.6832 2.59024 5.0432l13.27386 13.7946c.1322.1382.2906.2481.4659.3232.1752.0751.3637.1138.5541.1138.1905 0 .3789-.0387.5542-.1138.1752-.0751.3337-.185.4658-.3232l13.2597-13.7661c1.3791-1.3628 2.2905-3.1338 2.6013-5.0552.3109-1.9213.0051-3.8925-.873-5.6263z", fill: fillRule })] }));
}
