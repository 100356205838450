import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { observer } from 'mobx-react';
import * as React from 'react';
import styled, { css } from '@marvelapp/styled';
import { Box } from '@marvelapp/ui';
import { RatingType } from '@marvelapp/user-test-creator';
import { HeartIcon, StarIcon, ThumbsIcon } from '../icons';
export default observer(function PreferenceRateItem({ canEdit = true, index, onClick, onKeyDown, selected, size, type, }) {
    // eslint-disable-next-line @typescript-eslint/no-use-before-define, no-use-before-define
    const ItemVariant = size === 'compact' ? CompactRateItem : RateItem;
    // eslint-disable-next-line @typescript-eslint/no-use-before-define, no-use-before-define
    const LabelVariant = size === 'compact' ? CompactLabel : Label;
    return (_jsxs(ItemVariant, { canEdit: canEdit, "data-is-active": selected, "data-testid": "rate-item", onClick: onClick, onKeyDown: onKeyDown, selected: selected, size: size, type: type, children: [type !== RatingType.Numerical && (_jsx(LabelVariant, { canEdit: canEdit, type: type, selected: selected, size: size, "data-testid": `rate-item-${index}`, children: index })), _jsx(RatingIcon, { iconType: type, index: index, rating: 0, size: size })] }));
});
export const RatingIcon = observer(function RatingIcon({ iconType, index, rating, size, }) {
    const sizes = size === 'compact' ? '24' : ['32px', '32px', '42px'];
    switch (iconType) {
        case RatingType.Stars:
            return _jsx(StarIcon, { rating: rating, width: sizes, height: sizes });
        case RatingType.Thumbs:
            return _jsx(ThumbsIcon, { rating: rating, width: sizes, height: sizes });
        case RatingType.Hearts:
            return _jsx(HeartIcon, { rating: rating, width: sizes, height: sizes });
        case RatingType.Numerical:
            return (_jsx(Number, { size: size, width: sizes, height: sizes, children: index }));
        default:
            return null;
    }
});
export function getRatingTypeColor({ canEdit, selected, type, }) {
    const defaultColors = {
        bg: 'background',
        color: 'slate8',
        borderColor: 'slate6',
        hoverBg: 'slate2',
    };
    switch (type) {
        case RatingType.Stars:
            if (!canEdit) {
                return Object.assign(Object.assign({}, defaultColors), { color: 'yellow10', hoverBg: 'background' });
            }
            if (selected) {
                return {
                    bg: 'yellow2',
                    color: 'yellow10',
                    borderColor: 'yellow6',
                    hoverBg: 'yellow2',
                };
            }
            return defaultColors;
        case RatingType.Thumbs:
            if (!canEdit) {
                return Object.assign(Object.assign({}, defaultColors), { color: 'primary', hoverBg: 'background' });
            }
            if (selected) {
                return {
                    bg: 'blue3',
                    color: 'primary',
                    borderColor: 'blue6',
                    hoverBg: 'blue4',
                };
            }
            return defaultColors;
        case RatingType.Numerical:
            if (!canEdit) {
                return Object.assign(Object.assign({}, defaultColors), { color: 'slate12', hoverBg: 'background' });
            }
            if (selected) {
                return {
                    bg: 'blue3',
                    color: 'primary',
                    borderColor: 'blue6',
                    hoverBg: 'blue4',
                };
            }
            return Object.assign(Object.assign({}, defaultColors), { color: 'slate12' });
        case RatingType.Hearts:
            if (!canEdit) {
                return Object.assign(Object.assign({}, defaultColors), { color: 'tomato9', hoverBg: 'background' });
            }
            if (selected) {
                return {
                    bg: 'tomato2',
                    color: 'tomato9',
                    borderColor: 'tomato6',
                    hoverBg: 'tomato3',
                };
            }
            return defaultColors;
        default:
            return defaultColors;
    }
}
function getLabelColor(type) {
    switch (type) {
        case RatingType.Stars:
            return 'yellow12';
        case RatingType.Thumbs:
            return 'blue12';
        case RatingType.Numerical:
            return 'blue12';
        case RatingType.Hearts:
            return 'tomato12';
        default:
            return 'slate12';
    }
}
const Number = styled(Box).attrs((props) => ({
    fontWeight: 'bold',
    fontSize: props.size === 'compact'
        ? ['xl', 'xl', 'xl', 'xl', 'xl', 'xxl', 'xxl', 'xxxl']
        : 'xxxl',
})) `
  display: flex;
  align-items: center;
  justify-content: center;
`;
const RateItem = styled(Box).attrs(({ canEdit, selected, type }) => ({
    as: 'button',
    bg: getRatingTypeColor({ canEdit, selected, type }).bg,
    border: '1px solid',
    borderColor: getRatingTypeColor({ canEdit, selected, type }).borderColor,
    borderRadius: 'l',
    boxShadow: 'button.secondary',
    color: getRatingTypeColor({ canEdit, selected, type }).color,
    cursor: canEdit ? 'pointer' : 'default',
    fontFamily: 0,
    hoverBg: getRatingTypeColor({ canEdit, selected, type }).hoverBg,
    paddingX: 's',
    paddingY: 'm',
    transition: 'smooth.fast',
})) `
  width: 100%;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: 'flex-start';
  flex-direction: column;
  text-align: center;
  will-change: transform;
  transition-property: background, color, transform;

  ${(props) => props.canEdit &&
    css `
      &:hover {
        transform: translate3d(0px, -1px, 0px);
      }
      &:active {
        opacity: 0.8;
        transform: translate3d(0px, 1px, 0px);
      }
    `}
`;
const CompactRateItem = styled(RateItem).attrs((props) => {
    return {
        paddingX: 'xxs',
        paddingTop: props.type === RatingType.Numerical ? 's' : 'xxs',
        paddingBottom: 's',
    };
}) `
  justify-content: center;
  min-height: 90px;
`;
const Label = styled(Box).attrs(({ canEdit, selected, type }) => ({
    bg: getRatingTypeColor({ canEdit, selected, type }).bg,
    border: '1px solid',
    borderColor: getRatingTypeColor({ canEdit, selected, type }).borderColor,
    borderRadius: 'xs',
    boxShadow: 'button.secondary',
    color: selected ? getLabelColor(type) : 'slate12',
    margin: 'xs',
    fontSize: 'xxs',
    fontWeight: 'semibold',
})) `
  position: absolute;
  top: -2px;
  left: -2px;
  pointer-events: none;

  min-width: 20px;
  height: 20px;
  line-height: 20px;
  padding: 0 5px;
`;
const CompactLabel = styled(Label).attrs({
    marginTop: 'xs',
    marginX: '2px',
    marginBottom: 's',
}) `
  position: relative;
  top: 0;
  left: 0;
`;
