import { __rest } from "tslib";
import { jsx as _jsx } from "react/jsx-runtime";
import { observer } from 'mobx-react';
import { useCallback, useMemo } from 'react';
import * as React from 'react';
import { useAction } from '@marvelapp/ballpark-application';
import { CardSortingResponseUtils, } from '@marvelapp/user-test-creator';
import { BaseCategory } from '../BaseCategory';
import { useSortable } from '../dndUtils';
import { Card } from './Card';
export const Category = observer(function Category(_a) {
    var _b;
    var { stepResponse, category, cards, setCategoryUUIDs, showCardNumber, maxCardsPerCategory } = _a, otherProps = __rest(_a, ["stepResponse", "category", "cards", "setCategoryUUIDs", "showCardNumber", "maxCardsPerCategory"]);
    const { uuid } = category;
    const { isDragOverlay } = otherProps;
    const cardUUIDs = useMemo(() => cards.map((card) => card.uuid), [cards]);
    const _c = useSortable({
        id: uuid,
        data: {
            type: 'container',
            children: cardUUIDs,
        },
    }), { active, over, style, dragHandleProps } = _c, sortable = __rest(_c, ["active", "over", "style", "dragHandleProps"]);
    const isDraggedOver = over
        ? (uuid === over.id && ((_b = active === null || active === void 0 ? void 0 : active.data.current) === null || _b === void 0 ? void 0 : _b.type) !== 'container') ||
            cardUUIDs.includes(over.id)
        : false;
    const onUpdateTitle = useAction((newTitle) => {
        CardSortingResponseUtils.updateCategory(stepResponse, uuid, {
            title: newTitle,
        });
    }, [uuid, stepResponse]);
    const onDelete = useAction(() => {
        CardSortingResponseUtils.removeCategory(stepResponse, uuid);
        setCategoryUUIDs((prev) => prev.filter((id) => id !== uuid));
    }, [uuid, stepResponse, setCategoryUUIDs]);
    const renderCard = useCallback((card) => (_jsx(Card, { card: card, categoryUUID: category.uuid, isDragOverlay: isDragOverlay, showCardNumber: showCardNumber }, card.uuid)), [isDragOverlay, showCardNumber, category.uuid]);
    const canEditCategory = CardSortingResponseUtils.canAddNewCategories(stepResponse)
        ? category.uuid in stepResponse.answers.value.additionalCategories
        : false;
    const hasReachedMaxCategories = typeof maxCardsPerCategory === 'number' &&
        cards.length >= maxCardsPerCategory;
    // TODO: Add a visual indicator for when the user has reached the max number of cards per category
    const isCardsDragAndDropDisabled = hasReachedMaxCategories &&
        typeof (active === null || active === void 0 ? void 0 : active.id) === 'string' &&
        !cardUUIDs.includes(active.id);
    return (_jsx(BaseCategory, Object.assign({ category: category, cards: cards, renderCard: renderCard, onUpdateTitle: canEditCategory ? onUpdateTitle : undefined, onDelete: canEditCategory ? onDelete : undefined, isDraggedOver: isDraggedOver, isCardsDragAndDropDisabled: isCardsDragAndDropDisabled, maxCardsPerCategory: maxCardsPerCategory }, sortable, otherProps)));
});
