import { mean, orderBy, round } from 'lodash-es';
export const UNSORTED_CATEGORY_UUID = 'cat_unsorted';
export function getCardMetrics({ cards, cardAgreement, categoryFrequency, categoryPositionAvg, categoryUUIDToCategoryMap, }) {
    const metrics = cards.map((card) => {
        const cardAgreementForCard = cardAgreement.filter((agreement) => agreement.cardUuid === card.uuid);
        const cardFrequenciesMap = createMetricMap(card.uuid, categoryFrequency, 'cardUuid');
        const cardPositionMap = createMetricMap(card.uuid, categoryPositionAvg, 'cardUuid');
        const averageAgreement = round(mean(cardAgreementForCard.map((agreement) => Number(agreement.value))), 2);
        const nestedMetrics = cardAgreementForCard
            .map((agreement) => {
            var _a, _b;
            const category = categoryUUIDToCategoryMap[agreement.categoryUuid];
            if (!category)
                return null;
            const positionValue = (_a = cardPositionMap[agreement.categoryUuid]) === null || _a === void 0 ? void 0 : _a.value;
            return {
                id: agreement.metricId,
                title: category.title,
                agreement: Number(agreement.value),
                frequency: Number(((_b = cardFrequenciesMap[agreement.categoryUuid]) === null || _b === void 0 ? void 0 : _b.value) || 0),
                averagePosition: positionValue !== undefined ? Number(positionValue) : undefined,
                isUnsorted: agreement.categoryUuid === UNSORTED_CATEGORY_UUID,
            };
        })
            .filter(isNonNullable);
        return {
            id: card.uuid,
            title: card.title || '',
            imageSrc: card.imageUrl,
            averageAgreement,
            metrics: orderBy(nestedMetrics, ['isUnsorted', 'agreement', 'title'], ['asc', 'desc', 'asc']),
        };
    });
    return orderBy(metrics, ['averageAgreement', 'title'], ['desc', 'asc']);
}
export function getCategoryMetrics({ categoryAgreementAvg, cardAgreement, categoryFrequency, cardUUIDToCardMap, categoryUUIDToCategoryMap, categoryPositionAvg, }) {
    const metrics = categoryAgreementAvg
        .filter((metric) => metric.categoryUuid !== UNSORTED_CATEGORY_UUID)
        .map((metric) => {
        const category = categoryUUIDToCategoryMap[metric.categoryUuid];
        if (!category)
            return null;
        const cardFrequenciesMap = createMetricMap(metric.categoryUuid, categoryFrequency, 'categoryUuid');
        const cardPositionMap = createMetricMap(metric.categoryUuid, categoryPositionAvg, 'categoryUuid');
        const nestedMetrics = cardAgreement
            .filter((cardMetric) => cardMetric.categoryUuid === metric.categoryUuid)
            .map((cardMetric) => {
            var _a, _b;
            const card = cardUUIDToCardMap[cardMetric.cardUuid];
            if (!card)
                return null;
            const positionValue = (_a = cardPositionMap[cardMetric.cardUuid]) === null || _a === void 0 ? void 0 : _a.value;
            return {
                id: cardMetric.metricId,
                title: card.title,
                imageSrc: card.imageUrl,
                agreement: Number(cardMetric.value),
                frequency: Number(((_b = cardFrequenciesMap[cardMetric.cardUuid]) === null || _b === void 0 ? void 0 : _b.value) || 0),
                averagePosition: positionValue !== undefined ? Number(positionValue) : undefined,
            };
        })
            .filter(isNonNullable);
        return {
            id: metric.categoryUuid,
            title: category.title,
            averageAgreement: Number(metric.value),
            metrics: orderBy(nestedMetrics, ['agreement', 'title'], ['desc', 'asc']),
        };
    })
        .filter(isNonNullable);
    return orderBy(metrics, ['averageAgreement', 'title'], ['desc', 'asc']);
}
function isNonNullable(value) {
    return value !== null && value !== undefined;
}
function createMetricMap(uuid, frequencyData, key) {
    const inverseKey = key === 'cardUuid' ? 'categoryUuid' : 'cardUuid';
    return frequencyData
        .filter((item) => item[key] === uuid)
        .reduce((acc, item) => {
        // eslint-disable-next-line no-param-reassign
        acc[item[inverseKey]] = item;
        return acc;
    }, {});
}
export function isStandardizeCategory(categoryOrUuid) {
    if (typeof categoryOrUuid === 'string') {
        return categoryOrUuid.startsWith('vcat_');
    }
    return categoryOrUuid.uuid.startsWith('vcat_');
}
