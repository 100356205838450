import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { observer } from 'mobx-react';
import { useMemo } from 'react';
import { useGetUserTest, useUserTestAction, } from '@marvelapp/ballpark-application';
import { BtwHeading, BtwText, LanguageSelect, Stack, } from '@marvelapp/ballpark-components';
import { flags } from '@marvelapp/ballpark-icons';
const useSelectedLocale = () => {
    const userTest = useGetUserTest();
    const userLocale = userTest === null || userTest === void 0 ? void 0 : userTest.locale;
    const selectedLocale = useMemo(() => { var _a; return (_a = locales.find((locale) => locale.id === userLocale)) !== null && _a !== void 0 ? _a : locales[0]; }, [userLocale]);
    return selectedLocale;
};
export const LocaleSelect = observer(function LocaleSelect() {
    const selectedLocale = useSelectedLocale();
    return (_jsxs(Stack, { width: "full", gap: "6", "data-testid": "locale-select", children: [_jsxs(Stack, { gap: "1", align: "start", children: [_jsx(BtwHeading, { size: "sm", weight: "medium", children: "Language" }), _jsx(BtwText, { leading: "normal", size: "13", children: "Automatically translate the default text, buttons, instructions and error messages on your live test link." })] }), _jsxs(LanguageSelect.Root, { variant: "single-select", children: [_jsx(LanguageSelect.Trigger, { selectedLanguage: selectedLocale, "data-testid": "locale-select-trigger" }), _jsx(LocaleItems, { selectedLocale: selectedLocale })] }), _jsx(BtwText, { leading: "normal", size: "13", className: "italic", children: "Note: This will not translate the text you add to your questions and tasks." })] }));
});
const LocaleItems = observer(function LocaleItems(props) {
    const { selectedLocale } = props;
    const userTest = useGetUserTest();
    const updateLocale = useUserTestAction((locale) => {
        userTest.locale = locale;
    }, [userTest]);
    return (_jsx(LanguageSelect.Content, { "data-testid": "locale-select-content", children: locales.map((locale) => {
            return (_jsx(LanguageSelect.Item, { "data-testid": `locale-option-${locale.id}`, language: locale, onSelect: updateLocale, selectedLanguage: selectedLocale }, locale.id));
        }) }));
});
const locales = [
    {
        id: 'en-US',
        alternatives: ['en', 'en-GB'],
        name: 'English',
        flag: flags.COUNTRY_GB,
    },
    {
        id: 'pt-BR',
        alternatives: ['pt'],
        name: 'Brazilian Portuguese',
        flag: flags.COUNTRY_BR,
    },
    {
        id: 'zh-CN',
        alternatives: ['zh'],
        name: 'Chinese (Simplified)',
        flag: flags.COUNTRY_CN,
    },
    {
        id: 'cs-CZ',
        alternatives: ['cs'],
        name: 'Czech',
        flag: flags.COUNTRY_CZ,
    },
    {
        id: 'da-DK',
        alternatives: ['da'],
        name: 'Danish',
        flag: flags.COUNTRY_DK,
    },
    {
        id: 'nl-NL',
        alternatives: ['nl'],
        name: 'Dutch',
        flag: flags.COUNTRY_NL,
    },
    {
        id: 'et-EE',
        alternatives: ['et'],
        name: 'Estonian',
        flag: flags.COUNTRY_EE,
    },
    {
        id: 'fi-FI',
        alternatives: ['fi'],
        name: 'Finnish',
        flag: flags.COUNTRY_FI,
    },
    {
        id: 'fr-FR',
        alternatives: ['fr'],
        name: 'French',
        flag: flags.COUNTRY_FR,
    },
    {
        id: 'de-DE',
        alternatives: ['de'],
        name: 'German',
        flag: flags.COUNTRY_DE,
    },
    {
        id: 'hi-IN',
        alternatives: ['hi'],
        name: 'Hindi',
        flag: flags.COUNTRY_IN,
    },
    {
        id: 'it-IT',
        alternatives: ['it'],
        name: 'Italian',
        flag: flags.COUNTRY_IT,
    },
    {
        id: 'lv-LV',
        alternatives: ['lv'],
        name: 'Latvian',
        flag: flags.COUNTRY_LV,
    },
    {
        id: 'lt-LT',
        alternatives: ['lt'],
        name: 'Lithuanian',
        flag: flags.COUNTRY_LT,
    },
    {
        id: 'nb-NO',
        alternatives: ['no'],
        name: 'Norwegian',
        flag: flags.COUNTRY_NO,
    },
    {
        id: 'pl-PL',
        alternatives: ['pl'],
        name: 'Polish',
        flag: flags.COUNTRY_PL,
    },
    {
        id: 'pt-PT',
        alternatives: ['pt'],
        name: 'Portuguese',
        flag: flags.COUNTRY_PT,
    },
    {
        id: 'ro-RO',
        alternatives: ['ro'],
        name: 'Romanian',
        flag: flags.COUNTRY_RO,
    },
    {
        id: 'es-ES',
        alternatives: ['es'],
        name: 'Spanish',
        flag: flags.COUNTRY_ES,
    },
    {
        id: 'sv-SE',
        alternatives: ['sv'],
        name: 'Swedish',
        flag: flags.COUNTRY_SE,
    },
];
