import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { observer } from 'mobx-react';
import { useState } from 'react';
import * as React from 'react';
import { Icon, Popover, SquareButton } from '@marvelapp/ballpark-components';
import { SETTINGS_ICON } from '@marvelapp/ballpark-theme';
import styled from '@marvelapp/styled';
import { Box, Flex } from '@marvelapp/ui';
const StepCardContainer = observer(function StepCardContainer({ renderSettings, header, children, testId, className, }) {
    const [isPopoverActive, setIsPopoverActive] = useState(false);
    return (_jsxs(Container, { "data-testid": testId, className: className, children: [_jsxs(Flex, { marginTop: "m", marginBottom: "xs", justifyContent: "space-between", children: [header, _jsx(Flex, { mt: "5px", children: renderSettings && (_jsxs(Popover.Root, { open: isPopoverActive, onOpenChange: () => setIsPopoverActive((v) => !v), children: [_jsx(SquareButton, { as: Popover.Trigger, isActive: isPopoverActive, kind: "ghost", translateOnHover: false, size: "xs", children: _jsx(Icon, { paths: SETTINGS_ICON, height: 30, viewBox: "0 0 30 30", width: 30 }) }), _jsx(Popover.Content, { align: "start", children: _jsx(PopoverInner, { children: renderSettings() }) })] })) })] }), children] }));
});
const PopoverInner = styled(Popover.Inner) `
  width: 150px;
`;
export default StepCardContainer;
const Container = styled(Box).attrs({
    bg: 'background',
    border: '1px solid',
    borderColor: 'slate6',
    borderRadius: 'xl',
    boxShadow: 'button.secondary',
    paddingBottom: 's',
    paddingX: 's',
    marginRight: 's',
    maxWidth: ['220px', '220px', '220px', '220px', '250px', '275px'],
}) ``;
