import { __rest } from "tslib";
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { DndContext, DragOverlay, MeasuringStrategy, } from '@dnd-kit/core';
import { SortableContext, rectSwappingStrategy } from '@dnd-kit/sortable';
import { shuffle } from 'lodash-es';
import LogRocket from 'logrocket';
import { observer } from 'mobx-react';
import { useCallback, useEffect, useRef, useState } from 'react';
import * as React from 'react';
import { createPortal } from 'react-dom';
import { FormattedMessage, useIntl } from 'react-intl';
import { useAction } from '@marvelapp/ballpark-application';
import { BtwButton, BtwText, BtwTooltip, Stack, } from '@marvelapp/ballpark-components';
import { CircleCheckIcon, WarningIcon } from '@marvelapp/ballpark-icons';
import { CardSortingResponseUtils, CardSortingUtils, } from '@marvelapp/user-test-creator';
import { StepClass } from '@marvelapp/user-test-creator/src/common';
import { useRecorderState } from '../../../components/Record/RecorderContext';
import SlideRecord, { getCtaCopy, } from '../../common/SlideRecord';
import { RecordContent } from '../../common/SlideRecord/RecordContent';
import { CardsProgressHeading } from '../CardsProgressHeading';
import { dropAnimation } from '../dndUtils';
import { useFocusManager } from '../useFocusManager';
import { AddNewCategory } from './AddNewCategory';
import { Card } from './Card';
import { CardSortingCategoryGrid } from './CardSortingCategoryGrid';
import { CardSortingSidebar } from './CardSortingSidebar';
import { Category } from './Category';
import { ADD_NEW_CATEGORY_ID, useMultiContainerDndContextProps, } from './useMultiContainerDndContextProps';
export const CardSortingRecorder = observer(function CardSortingRecorder(_a) {
    var { stepResponse, step } = _a, rest = __rest(_a, ["stepResponse", "step"]);
    useEffect(() => {
        var _a;
        if ((_a = process.env.DEPLOY_ENV) === null || _a === void 0 ? void 0 : _a.includes('production')) {
            LogRocket.track('cardSort');
        }
    }, []);
    const [cardUUIDs] = useState(() => {
        const cardUUIDs = CardSortingUtils.getCardUUIDs(step);
        return CardSortingUtils.getAreCardsRandomized(step)
            ? shuffle(cardUUIDs)
            : cardUUIDs;
    });
    const canAddNewCategories = CardSortingUtils.canRecorderAddNewCategories(step);
    const [categoryUUIDs, setCategoryUUIDs] = useState(() => {
        const categoriesUUIDs = CardSortingResponseUtils.getCategoryUUIDs(stepResponse, step);
        return CardSortingUtils.getAreCategoriesRandomized(step)
            ? shuffle(categoriesUUIDs)
            : categoriesUUIDs;
    });
    const categoriesRef = useRef([]);
    const [pristineCategoryUUIDs, setPristineCategoryUUIDs] = useState({});
    const updatePristineCategory = useCallback((UUID, isPristine) => {
        setPristineCategoryUUIDs((prev) => (Object.assign(Object.assign({}, prev), { [UUID]: isPristine })));
    }, []);
    // Update categoriesRef when categoryUUIDs change
    useEffect(() => {
        categoriesRef.current = categoriesRef.current.slice(0, categoryUUIDs.length);
    }, [categoryUUIDs]);
    const onAddNewCategory = useAction(() => {
        const categoryUUID = CardSortingResponseUtils.addCategory(stepResponse);
        if (!categoryUUID)
            return;
        setCategoryUUIDs((prev) => [...prev, categoryUUID]);
        updatePristineCategory(categoryUUID, true);
        return categoryUUID;
    }, [stepResponse, updatePristineCategory]);
    const { handleKeyDown, addItem: addCategory } = useFocusManager(categoriesRef, onAddNewCategory);
    const unsortedCardUUIDs = CardSortingResponseUtils.getUnsortedCardUUIDs(stepResponse, step);
    const _b = useMultiContainerDndContextProps({
        addCategory: addCategory,
        stepResponse,
        step,
        categoryUUIDs,
    }), { activeId, onDragStart: originalOnDragStart } = _b, dndContextProps = __rest(_b, ["activeId", "onDragStart"]);
    const onDragStart = useCallback((event) => {
        // Trigger dirty state for all pristine categories when drag starts
        categoryUUIDs.forEach((uuid) => {
            if (pristineCategoryUUIDs[uuid]) {
                updatePristineCategory(uuid, false);
            }
        });
        originalOnDragStart === null || originalOnDragStart === void 0 ? void 0 : originalOnDragStart(event);
    }, [
        categoryUUIDs,
        pristineCategoryUUIDs,
        updatePristineCategory,
        originalOnDragStart,
    ]);
    const showCardNumber = CardSortingUtils.getIsNumberOrderInCategoryVisible(step);
    return (_jsx(CardSortErrorBoundary, { children: _jsx(SlideRecord, Object.assign({ isControlledStep: true, maxWidth: "100%", step: step }, rest, { children: _jsxs(Stack, { direction: "row", height: "full", width: "full", children: [_jsxs(DndContext, Object.assign({}, dndContextProps, { onDragStart: onDragStart, measuring: {
                            droppable: {
                                strategy: MeasuringStrategy.Always,
                            },
                        }, children: [_jsxs(SortableContext, { items: [ADD_NEW_CATEGORY_ID], strategy: rectSwappingStrategy, children: [_jsxs(CardSortingSidebar, { children: [_jsx("div", { className: "mb-6 p-3", children: _jsx(RecordContent.WithModal, { step: step }) }), _jsxs("div", { className: "relative rounded-lg bg-gray-100", children: [_jsx("div", { className: "sticky -top-4 z-10 rounded-t-lg bg-gray-100 px-4 pt-4", children: _jsx(CardsProgressHeading, { nonInteractive: false, total: cardUUIDs.length, unsorted: unsortedCardUUIDs.length }) }), _jsx(EmptyState, { totalCards: cardUUIDs.length, unsortedCards: unsortedCardUUIDs.length }), cardUUIDs.length > 0 && unsortedCardUUIDs.length > 0 && (_jsx(Stack, { align: "center", gap: "2.5", "data-testid": "card-sort-unsorted", className: "px-4 pb-4 pt-6", children: _jsx(SortableContext, { items: unsortedCardUUIDs, strategy: () => null, children: cardUUIDs
                                                                .filter((uuid) => unsortedCardUUIDs.includes(uuid))
                                                                .map((uuid) => (_jsx(Card, { card: CardSortingUtils.getCard(step, uuid) }, uuid))) }) }))] })] }), _jsxs(CardSortingCategoryGrid, { children: [categoryUUIDs.map((categoryUUID, index) => (_jsx(Category, { titleInputRef: (el) => {
                                                    categoriesRef.current[index] = el;
                                                }, onTitleKeyDown: (e) => handleKeyDown(e, index, categoryUUIDs.length), category: CardSortingResponseUtils.getCategory(stepResponse, step, categoryUUID), cards: CardSortingResponseUtils.getCardsForCategory(stepResponse, step, categoryUUID), stepResponse: stepResponse, maxCardsPerCategory: CardSortingUtils.getMaxCardsPerCategory(step), setCategoryUUIDs: setCategoryUUIDs, showCardNumber: showCardNumber, onDirty: () => updatePristineCategory(categoryUUID, false), isValid: stepResponse.className === StepClass.CardSortingClosed
                                                    ? true
                                                    : pristineCategoryUUIDs[categoryUUID] ||
                                                        CardSortingResponseUtils.isCategoryValid(step, stepResponse, categoryUUID) }, categoryUUID))), canAddNewCategories && (_jsx(AddNewCategory, { id: ADD_NEW_CATEGORY_ID, onClick: addCategory }))] })] }), createPortal(_jsx(DragOverlay, { adjustScale: false, dropAnimation: dropAnimation, children: typeof activeId === 'string' &&
                                    cardUUIDs.includes(activeId) && (_jsx(Card, { card: CardSortingUtils.getCard(step, activeId), isDragOverlay: true, showCardNumber: showCardNumber })) }), document.body)] })), _jsx("div", { className: "shadow-sm-top fixed bottom-0 left-0 right-0 bg-white/80 p-3 backdrop-blur", children: _jsx(CtaButton, { step: step }) })] }) })) }));
});
const CtaButton = observer(function Cta(props) {
    const { step } = props;
    const intl = useIntl();
    const { userTestDoc, canGoForward, validationErrors, goForward } = useRecorderState();
    const ctaCopy = getCtaCopy(userTestDoc, step);
    if (!ctaCopy)
        return null;
    return (_jsxs(Stack, { gap: "2.5", align: "center", direction: "row", children: [_jsx(BtwTooltip, { content: validationErrors || null, children: _jsx("div", { children: _jsx(BtwButton, { className: "whitespace-nowrap", "data-testid": "finish-button", disabled: !canGoForward, onClick: goForward, variant: "legacyBlue", children: intl.formatMessage(ctaCopy) }) }) }), validationErrors && (
            // Right padding to avoid clash with the up/down arrows which are absolutely positioned
            _jsx(BtwText, { align: "left", className: "pr-[102px] sm:hidden", size: "xs", variant: "red", children: validationErrors }))] }));
});
const EmptyState = observer(function EmptyState(props) {
    const { totalCards, unsortedCards } = props;
    if (totalCards === 0) {
        return (_jsxs(EmptyStateWrapper, { children: [_jsx("div", { className: "text-yellow-500", children: _jsx(WarningIcon, {}) }), _jsx(FormattedMessage, { id: 'N+wUje', defaultMessage: 'There are no cards to sort. Contact the researcher for more information.' })] }));
    }
    if (unsortedCards === 0) {
        return (_jsxs(EmptyStateWrapper, { children: [_jsx("div", { className: "text-green-500", children: _jsx(CircleCheckIcon, {}) }), _jsx(FormattedMessage, { id: 'RZ9Dj6', defaultMessage: 'Well done, you\'ve sorted all the cards!' })] }));
    }
    return null;
});
const EmptyStateWrapper = observer(function EmptyState(props) {
    const { children } = props;
    return (_jsx("div", { className: "px-4 pb-4 pt-6", children: _jsx(Stack, { align: "center", asChild: true, "data-testid": "card-sort-unsorted", gap: "2", justify: "center", className: "rounded border border-dashed border-gray-600/20 p-4", children: _jsx(BtwText, { size: "13", leading: "relaxed", children: children }) }) }));
});
// Workaround for https://linear.app/ballpark/issue/BALL-1299/
// When the dnd-kit library throws an error the error boundary
// will catch it and reload the page
const CardSortErrorBoundary = observer(class CardSortErrorBoundary extends React.Component {
    componentDidCatch(error, errorInfo) {
        LogRocket.error(error, errorInfo);
        window.location.reload();
    }
    render() {
        return this.props.children;
    }
});
