import themeGet from '@styled-system/theme-get';
import { color, layout, space, typography } from 'styled-system';
import theme from '@marvelapp/ballpark-theme';
import styled, { css } from '@marvelapp/styled';
export const DropdownLink = styled.div `
  ${space};
  ${layout};
`;
export const DropdownTriggerElement = styled.div `
  ${color};
  ${typography};
  opacity: ${(props) => props.isDisabled && '0.7'};
  cursor: pointer;
  pointer-events: ${(props) => props.isDisabled && 'none'};

  svg {
    transition: ${themeGet('transitions.smooth.fast')};
    fill: ${(props) => props.isDisabled
    ? `${themeGet('colors.silver')}`
    : `${themeGet('colors.slate')}`};
  }

  &:hover {
    svg {
      fill: ${themeGet('colors.marvel')};
    }

    ${DropdownLink} {
      color: ${themeGet('colors.marvelDark')};
    }
  }

  ${(props) => props.isActive &&
    css `
      svg {
        fill: ${themeGet('colors.marvel')};

        ${props.hideOnMobile
        ? css `
              @media (min-width: ${theme.breakpoints[4]}) {
                transform: rotate(180deg);
              }
            `
        : css `
              transform: rotate(180deg);
            `}
      }

      ${DropdownLink} {
        color: ${themeGet('colors.marvelDark')};
      }
    `};
`;
export const DropdownTriggerText = styled.div `
  display: flex;
  align-items: center;
`;
