import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { observer } from 'mobx-react';
import { Badge, BtwButton, BtwSkeleton, Image, Pin, Stack, cn, } from '@marvelapp/ballpark-components';
import { DASHBOARD_CARD_WIDTH } from '@marvelapp/ballpark-theme';
import { ProjectType, getThumbnailImagePadding, getThumbnailImageUrl, } from '@marvelapp/core';
export const CroppedScreen = observer(function CroppedScreen({ aspectRatio = 4 / 3, ctaText = 'Select', disabled, image, isActive, onClick, }) {
    const maxCardWidth = DASHBOARD_CARD_WIDTH;
    const imageUrl = image &&
        getThumbnailImageUrl(image, maxCardWidth, aspectRatio, ProjectType.UserTest);
    const imagePadding = image && getThumbnailImagePadding(image, aspectRatio);
    return (_jsxs(BtwButton, { size: "default", onClick: onClick, disabled: disabled, role: "button", className: cn('group', '!shadow-sm', 'overflow-hidden', 'rounded-lg', 'border-none', 'ring-0', 'isolate', 'w-full', 
        // Put the stroke inside to cover the image
        'before:absolute', 'before:inset-0', 'before:border', 'before:border-gray-600/10', 'before:transition-colors', 'before:duration-300', 'before:ease-smooth', 'before:rounded-lg', {
            'cursor-default': isActive,
            'hover:translate-y-0': isActive,
            'hover:!shadow': !disabled && !isActive,
            'hover:opacity-80': !disabled && !isActive,
            'hover:before:border-gray-600/20': !disabled && !isActive,
        }), style: aspectRatio ? { aspectRatio: aspectRatio.toString() } : undefined, children: [!imageUrl ? (_jsx(BtwSkeleton, { className: "h-full w-full" })) : (_jsx(Stack, { className: "w-full", align: "center", justify: "center", style: imagePadding ? { padding: imagePadding } : undefined, children: _jsx(Image, { className: "block h-full w-full object-scale-down", src: imageUrl, src2x: imageUrl, disabled: disabled }) })), isActive && (_jsx(Pin.Tr, { className: "p-3", children: _jsx(Badge, { className: "bg-gray-900/90 !text-gray-100", children: "Selected" }) })), !isActive && !disabled && (_jsx(Pin.Center, { className: "ease-smooth opacity-0 transition-opacity duration-300 group-hover:opacity-100", children: _jsx(BtwButton, { asChild: true, size: "sm", children: _jsx("span", { children: ctaText }) }) }))] }));
});
