import { jsx as _jsx } from "react/jsx-runtime";
import { proxyOptions } from '@ballpark/realtime-crdt-mobx';
import { observer } from 'mobx-react';
import { createContext, useContext } from 'react';
import * as React from 'react';
import { UserTestUtils } from '@marvelapp/user-test-creator';
const DUMMY_USER_TEST = UserTestUtils.createUserTestDoc(UserTestUtils.defaultState, proxyOptions);
const UserTestContext = createContext(null);
function useGetUserTest() {
    var _a;
    return (_a = useContext(UserTestContext)) !== null && _a !== void 0 ? _a : DUMMY_USER_TEST;
}
const UserTestContextProvider = observer(function UserTestContextProvider({ children, userTest, }) {
    return (_jsx(UserTestContext.Provider, { value: userTest, children: children }));
});
export { useGetUserTest, UserTestContextProvider };
