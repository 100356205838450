import { comparer, runInAction } from 'mobx';
import { observer, useLocalObservable } from 'mobx-react';
import { useEffect, useRef } from 'react';
import { useReaction } from '../../hooks/useReaction';
import { FigmaStatus } from '../../slides/PrototypeTask/PrototypeRecord/types';
import { usePrototypeState } from '../Record/PrototypeContext';
export const ManageFigmaPrototypeCaching = observer(function ManageFigmaPrototypeCaching() {
    const lastFigmaNode = useRef(''); // move to prototypestate?
    const prototypeState = usePrototypeState();
    const cachedPrototypeState = useLocalObservable(() => ({
        _prototypeState: prototypeState,
        get externalId() {
            var _a;
            if (!this._prototypeState.screens) {
                // TODO need to differentiate screens not loaded vs no default start
                // screen or start screen set in the screen picker in the builder
                return;
            }
            if (this.startScreenPk) {
                // Start screen is set in the screen picker in the builder
                return (_a = Object.values(this._prototypeState.screens).find((screen) => screen.id === this.startScreenPk)) === null || _a === void 0 ? void 0 : _a.external_id;
            }
            return this.defaultStartScreenExternalId;
        },
        get defaultStartScreenExternalId() {
            var _a;
            return (_a = this._prototypeState.step) === null || _a === void 0 ? void 0 : _a.defaultStartScreenExternalId;
        },
        get startScreenPk() {
            var _a, _b;
            return (_b = (_a = this._prototypeState.step) === null || _a === void 0 ? void 0 : _a.startScreen) !== null && _b !== void 0 ? _b : undefined;
        },
    }));
    useEffect(() => {
        runInAction(() => {
            cachedPrototypeState._prototypeState = prototypeState;
        });
    }, [
        cachedPrototypeState, // this keeps the linter happy but will never change
        prototypeState,
    ]);
    // Listen to Figma events so we can track the last node displayed -- we need
    // to know if the start screen on a step is the same screen as the previous
    // step finished on. If it's different we need to use Figma's API to set the
    // player to the start screen.
    useEffect(() => {
        const handler = (event) => {
            if (isFigmaNodeChangeMessageEvent(event)) {
                lastFigmaNode.current = event.data.data.presentedNodeId;
            }
        };
        window.addEventListener('message', handler);
        return () => {
            window.removeEventListener('message', handler);
        };
    }, []);
    // Set the start screen if required
    useReaction(() => {
        var _a;
        return {
            prototypeViewerEl: prototypeState.prototypeViewerEl,
            screens: prototypeState.screens,
            externalId: cachedPrototypeState.externalId,
            // We must run the reaction when the step changes as the above values could
            // all be the same for a prototype step that is a clone of the previous
            // prototype step
            nextPrototypeTaskUuid: (_a = prototypeState.step) === null || _a === void 0 ? void 0 : _a.uuid,
            isPlayerReady: prototypeState.figmaStatus === FigmaStatus.PrototypeLoaded,
        };
    }, ({ prototypeViewerEl, screens, externalId, isPlayerReady }) => {
        var _a;
        if (prototypeState.startScreenLoaded)
            return; // don't need to set the start screen if it's already set
        if (!isPlayerReady)
            return; // can't navigate to a screen until the player is ready (obvs)
        const figmaIframe = (_a = prototypeViewerEl === null || prototypeViewerEl === void 0 ? void 0 : prototypeViewerEl.getExternalPrototypeRef()) === null || _a === void 0 ? void 0 : _a.contentWindow;
        if (!figmaIframe)
            return;
        if (!screens)
            return; // need to wait until screens are loaded
        const handlePresentedNodeChanged = (event) => {
            var _a;
            if (((_a = event === null || event === void 0 ? void 0 : event.data) === null || _a === void 0 ? void 0 : _a.type) === 'PRESENTED_NODE_CHANGED') {
                if (event.data.data.presentedNodeId === externalId) {
                    prototypeState.startScreenLoaded = true;
                    window.removeEventListener('message', handlePresentedNodeChanged);
                }
            }
        };
        if (externalId) {
            if (lastFigmaNode.current !== externalId) {
                // The start screen is different to the last screen the player was on
                // set up a callback to fire once this screen is set (so we can let the
                // user start the task)
                window.addEventListener('message', handlePresentedNodeChanged);
                figmaIframe.postMessage({
                    type: 'NAVIGATE_TO_FRAME_AND_CLOSE_OVERLAYS',
                    data: {
                        nodeId: externalId,
                    },
                }, '*');
            }
            else {
                // The start screen is the same as the last screen the player was on so
                // we can just set startScreenLoaded to true immediately
                prototypeState.startScreenLoaded = true;
            }
        }
        else {
            // No start screen is set so we can just set startScreenLoaded to true immediately
            prototypeState.startScreenLoaded = true;
        }
        return () => {
            window.removeEventListener('message', handlePresentedNodeChanged);
        };
    }, { fireImmediately: true, equals: comparer.shallow }, [cachedPrototypeState, prototypeState]);
    return null;
});
function isFigmaNodeChangeMessageEvent(event) {
    return event.data.type === 'PRESENTED_NODE_CHANGED';
}
