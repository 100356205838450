import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { keyBy } from 'lodash-es';
import { observer } from 'mobx-react';
import { useCallback, useMemo } from 'react';
import * as React from 'react';
import { useAction } from '@marvelapp/ballpark-application';
import { Badge, BtwButton, BtwPopover, BtwText, CtaMessage, Select, Stack, } from '@marvelapp/ballpark-components';
import { SortIcon, TrashCanIcon } from '@marvelapp/ballpark-icons';
import { useResponsesContext } from './ResponsesContext';
const MAX_SORTS = 5;
export const SortingPopover = observer(function SortingPopover() {
    const { sorting: sortingState, clearSort, addSort, updateSort, removeSort, table, totalResults, } = useResponsesContext();
    const sortableColumns = useMemo(() => { var _a; return (_a = table === null || table === void 0 ? void 0 : table.getAllColumns().filter((column) => column.getCanSort())) !== null && _a !== void 0 ? _a : []; }, [table]);
    const sortableColumnsIds = useMemo(() => sortableColumns.map((column) => column.id), [sortableColumns]);
    const sortableColumnsMap = useMemo(() => keyBy(sortableColumns, 'id'), [sortableColumns]);
    const onAddNewSort = useAction(() => {
        addSort({ id: '', desc: false });
    }, [addSort]);
    const getSortingOptions = useCallback((currentId) => {
        const usedColumnsIds = new Set(sortingState.map((column) => column.id));
        return Object.keys(sortableColumnsMap).reduce((options, id) => {
            var _a;
            const column = sortableColumnsMap[id];
            if (!column || !((_a = column.columnDef.meta) === null || _a === void 0 ? void 0 : _a.sorting)) {
                return options;
            }
            options.push({
                name: column.columnDef.meta.sorting.name,
                value: id,
                disabled: usedColumnsIds.has(id) && id !== currentId,
            });
            return options;
        }, []);
    }, [sortableColumnsMap, sortingState]);
    return (_jsxs(BtwPopover.Root, { children: [_jsx(BtwPopover.Trigger, { asChild: true, children: _jsxs(BtwButton, { disabled: totalResults === 0, "aria-label": "Sort Responses", leadingIcon: _jsx(SortIcon, {}), variant: "ghost", "data-testid": "sort", size: "intermediate", children: ["Sort", ' ', sortingState.length > 0 && (_jsx(Badge, { size: "sm", className: "min-w-[18px]", "data-testid": "sort-count", children: sortingState.length }))] }) }), _jsx(BtwPopover.Content, { collisionPadding: 16, className: "max-h-[var(--radix-popper-available-height)]", children: _jsx(Stack, { className: "relative w-[560px]", "data-testid": "sort-popover", children: sortingState.length === 0 ? (_jsx(EmptyState, { onAddNewSort: onAddNewSort })) : (_jsxs(_Fragment, { children: [_jsx(Stack, { gap: "4", width: "full", className: "max-h-[calc(var(--radix-popper-available-height)_-_56px)] overflow-y-scroll py-4 pl-4 pr-3", children: sortingState.map(({ id, desc }, index) => {
                                    var _a, _b, _c;
                                    const getColumn = () => sortableColumnsMap[id];
                                    return (_jsxs(Stack, { direction: "row", className: "w-full", gap: "2.5", "data-testid": "sorting-select", children: [_jsx(SortingSelect, { columnId: id, desc: desc, currentSortName: (_c = (_b = (_a = getColumn()) === null || _a === void 0 ? void 0 : _a.columnDef.meta) === null || _b === void 0 ? void 0 : _b.sorting) === null || _c === void 0 ? void 0 : _c.name, onColumnIdChange: (value) => updateSort(id, { id: value }), onSortingChange: (value) => {
                                                    updateSort(id, { desc: value === 'desc' });
                                                }, options: getSortingOptions(id) }), _jsx(BtwButton, { "data-testid": "remove-sort-rule", className: "flex-shrink-0", standaloneIcon: _jsx(TrashCanIcon, {}), onClick: () => {
                                                    removeSort(id);
                                                }, size: "intermediate", variant: "ghost" })] }, id || index));
                                }) }), _jsxs(Stack, { direction: "row", justify: "between", width: "full", className: "border-t border-gray-600/10 px-4 py-3", children: [_jsx(BtwButton, { onClick: onAddNewSort, disabled: sortingState.length === sortableColumnsIds.length ||
                                            sortingState.length > MAX_SORTS, "data-testid": "add-new-sort-rule", size: "sm", children: "Add new sort" }), _jsx(BtwButton, { variant: "ghost", onClick: clearSort, disabled: sortingState.length === 0, "data-testid": "clear-sort-rules", size: "sm", children: "Clear all sorts" })] })] })) }) })] }));
});
const SortingSelect = observer(function SortingSelect({ columnId, desc, onColumnIdChange, onSortingChange, options, currentSortName, }) {
    return (_jsxs(_Fragment, { children: [_jsxs(Select.Root, { value: columnId, onValueChange: (value) => onColumnIdChange(value), children: [_jsx(Select.Trigger, { width: "full", testId: "select-property", size: "intermediate", children: currentSortName || _jsx(BtwText, { children: "Select a property" }) }), _jsx(Select.Content, { children: options.map(({ name, value, disabled }) => (_jsx(Select.Item, { value: value, disabled: disabled, children: name }, value))) })] }), _jsxs(Select.Root, { value: desc ? 'desc' : 'asc', onValueChange: onSortingChange, disabled: columnId === '', children: [_jsx(Select.Trigger, { width: "full", disabled: columnId === '', testId: "list-direction-select", size: "intermediate", children: desc ? 'Descending' : 'Ascending' }), _jsxs(Select.Content, { children: [_jsx(Select.Item, { value: "asc", children: "Ascending" }), _jsx(Select.Item, { value: "desc", children: "Descending" })] })] })] }));
});
const EmptyState = observer(function EmptyState({ onAddNewSort, }) {
    return (_jsx(Stack, { width: "full", className: "max-w-96 self-center p-8", align: "center", justify: "center", gap: "4", children: _jsxs(CtaMessage.Root, { size: "xs", children: [_jsxs(CtaMessage.Header, { icon: _jsx(SortIcon, {}), children: [_jsx(CtaMessage.Heading, { children: "No sorting applied yet\u2026" }), _jsx(CtaMessage.Description, { children: "Choose a property to sort on, or click on the header cell of the property's column in the table." })] }), _jsx(CtaMessage.Actions, { children: _jsx(BtwButton, { onClick: onAddNewSort, variant: "secondary", "data-testid": "add-new-sort-rule", size: "sm", children: "Add new sort" }) })] }) }));
});
