import { gql } from '@apollo/client';
export const CREATE_MULTISTEP_USER_TEST = gql `
  mutation CreateMultistepUserTest($input: CreateMultistepUserTestInput!) {
    createMultistepUserTest(input: $input) {
      ok
      error {
        message
        code
      }
      project: userTest {
        pk
        prototype {
          visibility
        }
      }
    }
  }
`;
