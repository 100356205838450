import { mapFilterNullish } from '@marvelapp/core';
export function getSortedAnswers(choices, stats) {
    const selectedChoices = getSortedSelectedChoices(stats);
    const remainingChoices = choices.filter((choice) => !selectedChoices.includes(choice));
    return [...selectedChoices, ...remainingChoices];
}
function getSortedSelectedChoices(stats) {
    if (!stats)
        return [];
    const selectedChoices = mapFilterNullish(stats, (stat) => stat);
    selectedChoices.sort((a, b) => a.percentageChosen && b.percentageChosen
        ? b.percentageChosen - a.percentageChosen
        : 0);
    return mapFilterNullish(selectedChoices, (choice) => choice.answerValue);
}
