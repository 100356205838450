import themeGet from '@styled-system/theme-get';
import { border, color, layout, space, typography } from 'styled-system';
import theme from '@marvelapp/ballpark-theme';
import styled, { css } from '@marvelapp/styled';
import { cursor, hoverColor } from '../customProperties';
export const PinAll = styled.div `
  ${color};
  ${layout};

  opacity: ${(props) => (props.isActive ? 1 : 0)};
  transform: scale(${(props) => (props.isActive ? 1 : 0)});
  transition: all 400ms ${theme.easings.smooth.string};
  transition-delay: 100ms;

  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none;

  position: absolute;
  top: 0;
  left: 0;
`;
PinAll.defaultProps = {
    theme,
};
export const Control = styled.label `
  ${color};
  ${cursor};
  ${layout};
  ${space};
  ${typography};
  position: relative;

  &:hover {
    ${hoverColor};
  }

  ${(props) => props.isDisabled &&
    css `
      cursor: default;
      pointer-events: none;
    `}
`;
Control.defaultProps = {
    theme,
};
export const Input = styled.input `
  z-index: ${themeGet('zIndices.negative')};
  position: absolute;
  opacity: 0;
`;
Input.defaultProps = {
    theme,
};
// The reason why this component doesn't have the box-shadows abstracted away in
// the theme object is because the shadows depend on the checkbox size
export const Indicator = styled.div `
  ${border};
  ${color};
  ${layout};

  position: absolute;
  top: 0;
  left: 0;
  display: block;
  user-select: none;
  transition: all 400ms ${themeGet('easings.smooth.string')};

  ${(props) => props.kind === 'defaultGreen' &&
    css `
      box-shadow: ${props.isChecked
        ? `inset 0 0 0 ${props.size / 2}px ${props.theme.colors.green}`
        : `inset 0 0 0 ${props.borderWidth}px ${props.theme.colors.slate}`};

      &:hover {
        box-shadow: ${props.isChecked
        ? `inset 0 0 0 ${props.size / 2}px ${props.theme.colors.green}`
        : `inset 0 0 0 ${props.borderWidth}px ${props.theme.colors.licorice}`};
      }
    `};

  ${(props) => props.kind === 'defaultGreenReversed' &&
    css `
      box-shadow: ${props.isChecked
        ? `inset 0 0 0 ${props.size / 2}px ${props.theme.colors.green}`
        : `inset 0 0 0 ${props.borderWidth}px rgba(255, 255, 255, 0.4)`};

      &:hover {
        box-shadow: ${props.isChecked
        ? `inset 0 0 0 ${props.size / 2}px ${props.theme.colors.green}`
        : `inset 0 0 0 ${props.borderWidth}px ${props.theme.colors.smokeExtraDark}`};
      }
    `};

  ${(props) => props.kind === 'disabled' &&
    css `
      pointer-events: none;
      background-color: ${props.theme.colors.snow};
      box-shadow: inset 0 0 0 ${props.borderWidth}px ${props.theme.colors.smoke};
    `};

  ${(props) => props.kind === 'defaultBlue' &&
    css `
      box-shadow: ${props.isChecked
        ? `inset 0 0 0 ${props.size / 2}px ${props.theme.colors.marvel}`
        : `inset 0 0 0 ${props.borderWidth}px ${props.theme.colors.smoke}`};

      &:hover {
        box-shadow: ${props.isChecked
        ? `inset 0 0 0 ${props.size / 2}px ${props.theme.colors.marvel}`
        : `inset 0 0 0 ${props.borderWidth}px ${props.theme.colors.smokeExtraDark}`};
      }
    `};

  ${(props) => props.kind === 'blackTransparent' &&
    css `
      background-color: ${props.isChecked
        ? 'transparent'
        : props.theme.colors.blackA2};
      box-shadow: ${props.isChecked
        ? `inset 0 0 0 ${props.size / 2}px ${props.theme.colors.green}`
        : `inset 0 0 0 ${props.borderWidth}px ${props.theme.colors.blackA2}`};
    `};

  ${(props) => props.kind === 'greyTransparent' &&
    css `
      background-color: ${props.isChecked
        ? 'transparent'
        : props.theme.colors.whiteA1};
      box-shadow: ${props.isChecked
        ? `inset 0 0 0 ${props.size / 2}px ${props.theme.colors.green}`
        : `inset 0 0 0 ${props.borderWidth}px ${props.theme.colors.whiteA2}`};
    `};

  ${(props) => props.kind === 'whiteToGreen' &&
    css `
      background-color: ${props.isChecked
        ? 'transparent'
        : props.theme.colors.white};

      box-shadow: ${props.isChecked
        ? `inset 0 0 0 ${props.size / 2}px ${props.theme.colors.green}`
        : `0 0 0 ${props.borderWidth}px rgba(0, 0, 0, 0.3)`};

      &:hover {
        background-color: ${props.isChecked
        ? 'transparent'
        : props.theme.colors.white};

        box-shadow: ${props.isChecked
        ? `inset 0 0 0 ${props.size / 2}px ${props.theme.colors.green}`
        : `0 0 0 ${props.borderWidth}px rgba(0, 0, 0, 0.5)`};
      }
    `};
`;
Indicator.defaultProps = {
    theme,
};
