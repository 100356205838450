import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { observer } from 'mobx-react';
import { useState } from 'react';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { BtwButton, BtwLink, BtwText } from '@marvelapp/ballpark-components';
import { TROUBLESHOOTING_FIGMA_ISSUES } from '@marvelapp/ballpark-help';
import { CloseIcon } from '@marvelapp/ballpark-icons';
import { useInterval } from '@marvelapp/hooks';
import { PrototypeTaskUtils, } from '@marvelapp/user-test-creator';
import { usePrototypeState } from '../../../components/Record/PrototypeContext';
import { useRecorderState } from '../../../components/Record/RecorderContext';
import { FigmaStatus } from './types';
const LONG_LOAD_TIME = 6;
const REALLY_LONG_LOAD_TIME = 11;
export const FigmaStatusMessage = observer(function FigmaStatusMessage({ step, }) {
    var _a;
    const prototypeState = usePrototypeState();
    const { isPreview } = useRecorderState();
    const [secondsPassed, setSecondsPassed] = useState(0);
    const [isStatusMessageDismissed, setIsStatusMessageDismissed] = useState(false);
    useInterval(() => setSecondsPassed((s) => s + 1), secondsPassed >= REALLY_LONG_LOAD_TIME ||
        prototypeState.figmaStatus === FigmaStatus.IframeSrcLoaded
        ? 1000
        : null);
    if (isStatusMessageDismissed ||
        !PrototypeTaskUtils.isExternalPrototype(step) ||
        prototypeState.figmaStatus === FigmaStatus.PrototypeLoaded ||
        !step.importedPrototypeUrl ||
        ((_a = prototypeState.embedPermissionStatus) === null || _a === void 0 ? void 0 : _a.code) === 401)
        return null;
    return (_jsx("div", { className: "relative", children: _jsxs(Message, { children: [_jsx(BtwButton, { className: "absolute -right-2.5 -top-2.5 z-[999] !h-5 !w-5 rounded-full bg-white [&>.inner-icon>svg]:h-2.5 [&>.inner-icon>svg]:w-2.5", onClick: () => setIsStatusMessageDismissed(true), standaloneIcon: _jsx(CloseIcon, {}), type: "button", size: "sm" }), (() => {
                    if (prototypeState.figmaStatus === FigmaStatus.Pending) {
                        return (_jsx("div", { "data-testid": "contacting-figma", children: _jsx(FormattedMessage, { id: 'eaLAcc', defaultMessage: 'Contacting {prototypeProvider} and waiting for response...', values: {
                                    prototypeProvider: 'Figma',
                                } }) }));
                    }
                    if (secondsPassed >= REALLY_LONG_LOAD_TIME) {
                        return (_jsxs("div", { "data-testid": "optimise-your-figma-file", children: [_jsx(FormattedMessage, { id: '2CmbMu', defaultMessage: 'Hang on while this finishes loading.' }), isPreview ? (
                                // not translated as this is shown in preview only
                                _jsxs(_Fragment, { children: ["Consider optimising your Figma file to make it smaller for participants to load. Read our guide", _jsx(BtwLink, { "data-testid": "figma-size-link", href: TROUBLESHOOTING_FIGMA_ISSUES, underline: true, variant: "current", children: "here" }), "."] })) : (_jsx(FormattedMessage, { id: 'MP8hso', defaultMessage: 'If the {prototypeProvider} prototype doesn\u2019t load, please contact the researcher.', values: {
                                        prototypeProvider: 'Figma',
                                    } }))] }));
                    }
                    if (secondsPassed >= LONG_LOAD_TIME) {
                        return (_jsx("div", { "data-testid": "figma-slow-to-load", children: _jsx(FormattedMessage, { id: 'Ne9ub5', defaultMessage: 'Looks like {prototypeProvider} is taking a long time to process the request, please wait...', values: {
                                    prototypeProvider: 'Figma',
                                } }) }));
                    }
                    return (_jsx("div", { "data-testid": "figma-loading", children: _jsx(FormattedMessage, { id: '5QsQOk', defaultMessage: '{prototypeProvider} is preparing the prototype and will load it when it\'s ready...', values: {
                                prototypeProvider: 'Figma',
                            } }) }));
                })()] }) }));
});
const Message = observer(function Message(props) {
    const { children } = props;
    return (_jsxs("div", { className: "absolute bottom-full left-4 right-4 mb-4 rounded-md bg-gray-900/90 p-3 backdrop-blur-md sm:left-10 sm:right-10", children: [_jsx(BtwText, { align: "center", size: "13", variant: "white", leading: "relaxed", children: children }), _jsx("div", { className: "absolute left-1/2 top-full h-0 w-0 -translate-x-1/2 rounded-[2px] border-l-[8px] border-r-[8px] border-t-[8px] border-l-transparent border-r-transparent border-t-gray-900/90" })] }));
});
