import { RatingType, StepClass } from '../common';
import { generateUUID } from '../utils/generateUUID';
import { STEP_PREFIXES } from './constants';
const ratingLabels = {
    start: '',
    middle: '',
    end: '',
};
export function create(partial = {}) {
    return Object.assign({ uuid: generateUUID(STEP_PREFIXES[StepClass.RatingScale]), className: StepClass.RatingScale, ratingType: RatingType.Stars, maxRating: 10, minRating: 1, ratingLabels, title: null, description: [], isRequired: false, conditions: {
            ALWAYS: null,
            rules: [],
        }, revision: 0 }, partial);
}
export function setStartLabel(mutable, value) {
    mutable.ratingLabels.start = value;
}
export function getStartLabel(step) {
    return step.ratingLabels.start;
}
export function setMidLabel(mutable, value) {
    mutable.ratingLabels.middle = value;
}
export function getMidLabel(step) {
    return step.ratingLabels.middle;
}
export function setEndLabel(mutable, value) {
    mutable.ratingLabels.end = value;
}
export function getEndLabel(step) {
    return step.ratingLabels.end;
}
export function setMaxRating(mutable, value) {
    mutable.maxRating = value;
}
export function setRatingType(mutable, value) {
    mutable.ratingType = value;
}
export function getMaxRating(step) {
    return step.maxRating;
}
export function setMinRating(mutable, value) {
    mutable.minRating = value;
}
export function getMinRating(step) {
    return step.minRating;
}
export function getRatingLabel(step, position) {
    return step.ratingLabels[position];
}
export function getRatingType(step) {
    return step.ratingType;
}
