import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { observer } from 'mobx-react';
import { typography } from 'styled-system';
import theme from '@marvelapp/ballpark-theme';
import styled from '@marvelapp/styled';
import { AspectRatio, Box, Card, Loader, PinCenter, Text } from '@marvelapp/ui';
import { Highlighter } from '@marvelapp/ui-internal';
export default observer(function FigmaScreenPlaceholder({ borderRadius, ratio, searchWords, title, }) {
    return (_jsxs(Box, { cursor: "pointer", children: [_jsx(Card, { children: _jsx(AspectRatio, { borderRadius: borderRadius, overflow: "hidden", ratio: ratio, children: _jsxs(PinCenter, { alignItems: "center", display: "flex", flexDirection: "column", children: [_jsx(Loader, { size: 30 }), _jsx(Text, { fontSize: 2, mt: 2, textAlign: "center", children: "Downloading image from Figma" })] }) }) }), _jsx(ReadOnlyTitle, { "data-testid": "project-screen-title", fontSize: 14, children: _jsx(Highlighter, { searchWords: searchWords || [], testId: "external-screen-name-highlight", textToHighlight: title }) })] }));
});
const ReadOnlyTitle = styled.div `
  ${typography}
  color: ${theme.colors.licorice};
  margin-top: 30px;
  display: block;
  font-weight: 500;
  line-height: 1.5;
  text-align: center;
  word-break: break-word;
`;
