import { Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { observer } from 'mobx-react';
import { useEffect, useRef } from 'react';
import * as React from 'react';
import { useFocusManager } from './useFocusManager';
export const FocusManagedList = observer(function FocusManagedList({ items, onAddItem, renderItem, renderAddItem, }) {
    const itemsRef = useRef([]);
    const { handleKeyDown, addItem } = useFocusManager(itemsRef, onAddItem);
    // Update itemsRef when items change
    useEffect(() => {
        itemsRef.current = itemsRef.current.slice(0, items.length);
    }, [items]);
    return (_jsxs(_Fragment, { children: [items.map((item, index) => {
                return renderItem({
                    key: item.id,
                    item,
                    ref: (el) => {
                        itemsRef.current[index] = el;
                    },
                    onKeyDown: (e) => handleKeyDown(e, index, items.length),
                });
            }), renderAddItem === null || renderAddItem === void 0 ? void 0 : renderAddItem({ addItem })] }));
});
