import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useQuery } from '@apollo/client';
import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import * as React from 'react';
import { useIntl } from 'react-intl';
import { Redirect, useParams } from 'react-router-dom';
import { useQueryState } from 'react-router-use-location-state';
import { EmptyState, Heading, NotFound, Stack, StepIcon, getIconType, } from '@marvelapp/ballpark-components';
import { formatStringOrMessage, getStepTitle } from '@marvelapp/ballpark-copy';
import styled from '@marvelapp/styled';
import { Box, ContentLoader, Flex } from '@marvelapp/ui';
import { FixedStep, getMainScreenerSteps, getOrderedListOfAllSteps, } from '@marvelapp/user-test-creator';
import { MUST_PASS_ID } from '../../graphql/errors';
import { GET_USER_TEST_INFO } from '../../graphql/queries';
import { getPageError, getPageErrorCopy } from '../../utils/getPageErrorCopy';
import { EmptyStateImage } from './EmptyStateImage';
import { MainTestSummary } from './MainTestSummary';
import { ScreenersSummary } from './ScreenersSummary';
import { useQueryUserTestAggregates } from './useQueryUserTestAggregates';
const ACTIVE_SLIDE_QUERY_PARAM = 's'; // keep it short as no one reads urls
export const Summary = observer(function Summary() {
    var _a, _b, _c, _d, _e;
    const { id } = useParams();
    const pk = Number(id); // TODO switch to uuid even for authenticated users
    const [activeSlideUUID, setActiveSlideUUID] = useQueryState(ACTIVE_SLIDE_QUERY_PARAM, '');
    const { data: userTestProject, loading: isUserTestProjectLoading, error, } = useQuery(GET_USER_TEST_INFO, {
        variables: { pk },
    });
    const { data, loading: isAggregateDataLoading } = useQueryUserTestAggregates((_a = userTestProject === null || userTestProject === void 0 ? void 0 : userTestProject.userTest) === null || _a === void 0 ? void 0 : _a.uuid);
    const [hasAggregateDataLoadedOnce, sethasAggregateDataLoadedOnce] = useState(false);
    useEffect(() => {
        if (!isAggregateDataLoading && data) {
            sethasAggregateDataLoadedOnce(true);
        }
    }, [isAggregateDataLoading, data]);
    const userTestDoc = (data === null || data === void 0 ? void 0 : data.userTestDocument) && JSON.parse(data.userTestDocument.document);
    const stepStats = (data === null || data === void 0 ? void 0 : data.userTestStepStats) || [];
    const welcomeStep = stepStats.find((step) => {
        return (step === null || step === void 0 ? void 0 : step.stepResponseUuid) === (userTestDoc === null || userTestDoc === void 0 ? void 0 : userTestDoc.welcomeStep.uuid);
    });
    const totalStarts = (welcomeStep && welcomeStep.totalStepVisits) || 0;
    const pageError = getPageError({
        data: userTestProject,
        loading: isAggregateDataLoading,
        error,
    }, MUST_PASS_ID);
    if (pageError) {
        return _jsx(NotFound, Object.assign({}, getPageErrorCopy(pageError)));
    }
    if ((_c = (_b = userTestProject === null || userTestProject === void 0 ? void 0 : userTestProject.userTest) === null || _b === void 0 ? void 0 : _b.prototype) === null || _c === void 0 ? void 0 : _c.isLocked) {
        return _jsx(Redirect, { to: `/projects/team` });
    }
    const isEmpty = !isAggregateDataLoading &&
        data &&
        (stepStats.length === 0 ||
            (totalStarts === 0 &&
                ((_e = (_d = userTestProject === null || userTestProject === void 0 ? void 0 : userTestProject.userTest) === null || _d === void 0 ? void 0 : _d.resultStats) === null || _e === void 0 ? void 0 : _e.screeningCount) === 0));
    if (isEmpty) {
        return (_jsx(EmptyState, { description: _jsxs(_Fragment, { children: ["Once your first responses arrive, it will take a few minutes to", ' ', _jsx("br", {}), " get the data crunched and summarised"] }), image: _jsx(EmptyStateImage, { mb: "s" }), title: "Still gathering data\u2026", testid: "summary-empty-state" }));
    }
    return (_jsx(_Fragment, { children: isUserTestProjectLoading ||
            !hasAggregateDataLoadedOnce ||
            !userTestDoc ? (_jsx(SummaryPageLoader, {})) : (_jsxs("div", { className: "grid w-full max-w-screen-2xl grid-cols-12 gap-6", children: [_jsxs("div", { className: "col-span-8 flex grid-cols-9 flex-col gap-6 xl:col-span-9 xl:grid", children: [_jsx(ScreenersSummary, { stepStats: stepStats, userTestDoc: userTestDoc, userTestProject: userTestProject, isUserTestProjectLoading: isUserTestProjectLoading, activeSlideUUID: activeSlideUUID, totalStarts: totalStarts }), _jsx(MainTestSummary, { activeSlideUUID: activeSlideUUID, totalStarts: totalStarts, stepStats: stepStats, userTestDoc: userTestDoc, userTestProject: userTestProject, isUserTestProjectLoading: isUserTestProjectLoading })] }), _jsx(Stack, { className: "col-span-4 xl:col-span-3", children: _jsx(SummarySidebar, { userTestDoc: userTestDoc, setActiveSlideUUID: setActiveSlideUUID, activeSlideUUID: activeSlideUUID }) })] })) }));
});
const SummaryPageLoader = observer(function SummaryPageLoader() {
    return (_jsxs(Flex, { justifyContent: "center", width: 1, children: [_jsxs(Box, { maxWidth: 700, flex: 1, children: [_jsx(ContentLoader, { borderRadius: "xl", height: 206, kind: "medium", marginBottom: "m", width: 1 }), _jsx(ContentLoader, { borderRadius: "xl", height: 300, kind: "medium", marginBottom: "m", width: 1 }), _jsx(ContentLoader, { borderRadius: "xl", height: 300, kind: "medium", width: 1 })] }), _jsx(Box, { marginLeft: "m", maxWidth: 300, flex: 1, children: _jsx(ContentLoader, { kind: "medium", borderRadius: "xl", height: 420, width: 1 }) })] }));
});
const SummarySidebar = observer(function SummarySidebar({ userTestDoc, setActiveSlideUUID, activeSlideUUID, }) {
    const intl = useIntl();
    const screenerSteps = getMainScreenerSteps(userTestDoc);
    const allSteps = [
        ...screenerSteps,
        ...getOrderedListOfAllSteps({ userTest: userTestDoc }),
    ];
    return (_jsx(Stack, { width: "full", "data-testid": "summary-index", className: "sticky top-[74px]", children: _jsxs(Sidebar, { children: [_jsx(Box, { paddingX: "m", children: _jsx(SidebarHeader, { children: _jsx(Heading, { lineHeight: "base", fontSize: 16, children: "Index" }) }) }), _jsx(Box, { paddingX: "s", children: allSteps.map((step, i) => {
                        let fixedStepType;
                        // welcome step is first step after screener steps
                        if (i === screenerSteps.length) {
                            fixedStepType = FixedStep.Welcome;
                        }
                        if (allSteps.length - 1 === i) {
                            fixedStepType = FixedStep.Exit;
                        }
                        return (_jsx(SidebarLink, { active: activeSlideUUID === step.uuid, icon: _jsx(StepIcon, { size: "s", type: getIconType(step, fixedStepType, i < screenerSteps.length) }), title: formatStringOrMessage(intl, getStepTitle(step, fixedStepType)), onClick: () => setActiveSlideUUID(step.uuid), testId: `summary-index-item-${step.className}-${i}` }, step.uuid));
                    }) })] }) }));
});
const Sidebar = styled(Box).attrs({
    bg: 'background',
    border: '1px solid',
    borderColor: 'slate6',
    borderRadius: 'xl',
    boxShadow: 'button.secondary',
    paddingBottom: 's',
}) `
  width: 100%;
`;
const SidebarHeader = styled(Box).attrs({
    borderBottom: '1px solid',
    borderColor: 'slate6',
    marginBottom: 's',
    paddingY: 'm',
}) ``;
const SidebarLink = observer(function SidebarLink({ active, icon, onClick, title, testId, }) {
    return (_jsxs(SidebarNavItem, { bg: active ? 'slate2' : 'background', onClick: onClick, "data-testid": testId, children: [icon, _jsx(Heading, { fontSize: 14, fontWeight: active ? 'semibold' : 'regular', paddingLeft: "xs", paddingTop: "xxs", size: "m", children: title })] }));
});
const SidebarNavItem = styled(Flex).attrs({
    borderRadius: 'm',
    hoverBg: 'slate3',
    marginBottom: 'xxs',
    paddingX: 'xs',
    paddingY: 'xs',
    transition: 'smooth.fast',
}) `
  cursor: pointer;
  &:last-item {
    margin: 0;
  }
`;
