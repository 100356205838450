import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { observer } from 'mobx-react';
import { Stack } from '@marvelapp/ballpark-components';
import { SUMMARY_METRICS } from '@marvelapp/ballpark-help';
import { formatTime } from '@marvelapp/core';
import { getOrderedListOfAllSteps, } from '@marvelapp/user-test-creator';
import { StepCard } from './StepSummary';
import { SummaryStats } from './SummaryStats';
import OpenAIStepCard from './openAI/OpenAIStepCard';
import { stepSupportsOpenAI } from './openAI/utils';
export const MainTestSummary = observer(function MainTestSummary({ stepStats, userTestDoc, userTestProject, isUserTestProjectLoading, activeSlideUUID, totalStarts, }) {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p;
    const steps = userTestDoc && getOrderedListOfAllSteps({ userTest: userTestDoc });
    const openAIEnabled = steps === null || steps === void 0 ? void 0 : steps.some(stepSupportsOpenAI);
    const stats = [
        {
            name: 'Visited',
            testId: 'total-visits',
            count: (_b = (_a = userTestProject === null || userTestProject === void 0 ? void 0 : userTestProject.userTest) === null || _a === void 0 ? void 0 : _a.resultStats) === null || _b === void 0 ? void 0 : _b.allResultsCount,
        },
        {
            name: 'Started',
            testId: 'total-started-test',
            count: (_d = (_c = userTestProject === null || userTestProject === void 0 ? void 0 : userTestProject.userTest) === null || _c === void 0 ? void 0 : _c.resultStats) === null || _d === void 0 ? void 0 : _d.totalResults,
            percentage: (_f = (_e = userTestProject === null || userTestProject === void 0 ? void 0 : userTestProject.userTest) === null || _e === void 0 ? void 0 : _e.resultStats) === null || _f === void 0 ? void 0 : _f.totalResultsPercentage,
        },
        {
            name: 'Completed',
            testId: 'total-complete-submissions',
            count: (_h = (_g = userTestProject === null || userTestProject === void 0 ? void 0 : userTestProject.userTest) === null || _g === void 0 ? void 0 : _g.resultStats) === null || _h === void 0 ? void 0 : _h.totalCompletedResults,
            percentage: (_k = (_j = userTestProject === null || userTestProject === void 0 ? void 0 : userTestProject.userTest) === null || _j === void 0 ? void 0 : _j.resultStats) === null || _k === void 0 ? void 0 : _k.completionRate,
        },
        {
            name: 'Average duration',
            testId: 'average-duration',
            count: `${((_m = (_l = userTestProject === null || userTestProject === void 0 ? void 0 : userTestProject.userTest) === null || _l === void 0 ? void 0 : _l.resultStats) === null || _m === void 0 ? void 0 : _m.averageDuration)
                ? formatTime((_p = (_o = userTestProject === null || userTestProject === void 0 ? void 0 : userTestProject.userTest) === null || _o === void 0 ? void 0 : _o.resultStats) === null || _p === void 0 ? void 0 : _p.averageDuration)
                : '0:00'}`,
        },
    ];
    return (_jsxs(_Fragment, { children: [_jsx(SummaryStats, { helpLink: SUMMARY_METRICS, areStatsLoading: isUserTestProjectLoading, stats: stats, title: "Test performance", testId: "summary-stats" }), steps.map((step) => {
                var _a, _b;
                return (_jsxs(Stack, { direction: { initial: 'col', xl: 'row' }, className: "col-span-9 col-end-10 mb-6 grid-cols-9 gap-6 last:mb-0 xl:grid", children: [openAIEnabled && (_jsx(_Fragment, { children: _jsx(OpenAIStepCard, { step: step, stepStats: stepStats }) })), _jsx(Stack, { className: "col-span-9 col-start-4 col-end-10 w-full", children: _jsx(StepCard, { hasOpenAiSummary: openAIEnabled, isActiveStep: step.uuid === activeSlideUUID, totalStarts: totalStarts, step: step, stepStats: stepStats, userTest: userTestDoc, backingProjectPk: (_b = (_a = userTestProject === null || userTestProject === void 0 ? void 0 : userTestProject.userTest) === null || _a === void 0 ? void 0 : _a.prototype) === null || _b === void 0 ? void 0 : _b.pk }) }, step.uuid)] }));
            })] }));
});
