import { getDoc } from '@ballpark/realtime-crdt';
import { action } from 'mobx';
import { useCallback } from 'react';
import { useGetUserTest } from '../providers';
export function useAction(fn, dependencies) {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    return useCallback(action(fn), dependencies);
}
export function useUserTestAction(fn, dependencies) {
    const userTest = useGetUserTest();
    const doc = getDoc(userTest);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    return useCallback(action(((...args) => doc.transact(() => fn(...args)))), dependencies);
}
