import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import * as React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import useDarkMode from 'use-dark-mode';
import { BtwMessage, ModalPortal, Pin, Text, } from '@marvelapp/ballpark-components';
import styled from '@marvelapp/styled';
import { Box, Image, Loader } from '@marvelapp/ui';
import { Scrollbars } from '../../components/Scrollbars';
export default observer(function ImageReveal({ onCloseImage, timer, src, }) {
    const intl = useIntl();
    const darkmode = useDarkMode(true);
    const isDarkMode = darkmode.value;
    const imageAlt = `${src}-reveal`;
    const [timeLeft, setTimeLeft] = useState(timer);
    const instructions = intl.formatMessage({ id: "PUJrY9", defaultMessage: "This image will display for {timeLeft, plural, one {# second} other {# seconds}}" }, {
        timeLeft,
    });
    const [imageLoaded, setImageLoaded] = useState(false);
    useEffect(() => {
        if (!src) {
            setImageLoaded(true);
        }
        if (!imageLoaded) {
            return;
        }
        setTimeout(() => {
            if (timeLeft > 0) {
                setTimeLeft(timeLeft - 1);
            }
            else {
                onCloseImage();
            }
        }, 1000);
    }, [imageLoaded, src, onCloseImage, timeLeft]);
    return (_jsx(ModalPortal, { children: _jsxs(Modal, { isDarkMode: isDarkMode, children: [_jsx(Pin.Bc, { className: "z-10 py-6", children: _jsx(BtwMessage, { className: "min-w-72 text-center", "data-testid": "five-second-step-countdown", variant: "black", children: instructions }) }), _jsx(Scrollbars, { children: _jsxs(Box, { alignItems: "center", display: "flex", justifyContent: "center", minHeight: "100vh", p: "l", children: [!imageLoaded && _jsx(Loader, {}), src ? (_jsx(PreviewImage, { "data-testid": "five-second-step-image", hasTransition: false, alt: imageAlt, src: src, onLoad: () => {
                                    setImageLoaded(true);
                                } })) : (_jsx(Text, { fontWeight: "semibold", fontSize: "xl", children: _jsx(FormattedMessage, { id: 'K3EWLV', defaultMessage: 'No image added to this step' }) }))] }) })] }) }));
});
const Modal = styled(Box).attrs({
    backgroundColor: 'background',
    zIndex: 'record.modal',
}) `
  background-image: url('data:image/svg+xml;utf8, ${(props) => encodeURIComponent(`<svg
        height="16"
        viewBox="0 0 16 16"
        width="16"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
          <rect fill="${props.isDarkMode
    ? props.theme.colors.slate3
    : props.theme.colors.slate9}" height="2" rx="1" width="2" x="0" y="0" />
        </g>
      </svg>`)}');
  background-repeat: repeat;
  bottom: 0;
  display: flex;
  flex-direction: column;
  left: 0;
  height: 100vh;
  right: 0;
  top: 0;
  position: relative;
`;
const PreviewImage = styled(Image).attrs({
    boxShadow: 'popover.default',
    maxWidth: '100%',
}) ``;
