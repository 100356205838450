import { jsx as _jsx } from "react/jsx-runtime";
import { Box, Button, ContentLoader, Tooltip } from '@marvelapp/ui';
export default function PersonButton({ badgeText, badgeTooltip, buttonKind, buttonText, isDisabled, isLoading: isRowLoading, onButtonClick, isButtonLoading, }) {
    const tooltipContent = isDisabled
        ? "You don't have the correct permissions"
        : undefined;
    if (isRowLoading) {
        return (_jsx(ContentLoader, { borderRadius: 12, height: 24, kind: "medium", width: 68 }));
    }
    if (badgeText) {
        return (_jsx(Tooltip, { content: badgeTooltip, position: "left", whiteSpace: "wrap", width: 145, children: _jsx(Box, { bg: "snowDark", borderRadius: 15, cursor: "default", "data-testid": "person-badge", fontSize: 1, fontWeight: 500, height: 24, lineHeight: "24px", minWidth: 66, textAlign: "center", children: badgeText }) }));
    }
    return (_jsx(Tooltip, { content: tooltipContent, position: "left", children: _jsx(Button, { "data-testid": "person-button", isDisabled: isDisabled, isLoading: isButtonLoading, kind: buttonKind, minWidth: 68, onClick: onButtonClick, px: 12, size: 0, children: buttonText }) }));
}
