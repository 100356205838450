import { jsx as _jsx } from "react/jsx-runtime";
import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import * as React from 'react';
import { IntlProvider } from 'react-intl';
import { FullScreenLoader } from '@marvelapp/ballpark-components';
import { decodeParams } from '@marvelapp/core';
// TODO: Our webpack doesn't support dynamic imports with variables, so we have to hardcode the path
// to the compiled locale files. This is a temporary solution until we find a better way to load them
// const LOCALE_COMPILED = '../../../../../../locale-compiled';
function loadLocaleData(locale) {
    switch (locale.toLowerCase()) {
        case 'cs':
        case 'cs-cz':
            return import('../../../../../../locale-compiled/cs-CZ.json');
        case 'da':
        case 'da-dk':
            return import(`../../../../../../locale-compiled/da-DK.json`);
        case 'de':
        case 'de-de':
            return import(`../../../../../../locale-compiled/de-DE.json`);
        case 'en':
        case 'en-gb':
        case 'en-us':
            return import(`../../../../../../locale-compiled/en.json`);
        case 'es':
        case 'es-es':
            return import(`../../../../../../locale-compiled/es-ES.json`);
        case 'et':
        case 'et-ee':
            return import(`../../../../../../locale-compiled/et-EE.json`);
        case 'fi':
        case 'fi-fi':
            return import(`../../../../../../locale-compiled/fi-FI.json`);
        case 'fr':
        case 'fr-fr':
            return import(`../../../../../../locale-compiled/fr-FR.json`);
        case 'hi':
        case 'hi-in':
            return import(`../../../../../../locale-compiled/hi-IN.json`);
        case 'it':
        case 'it-it':
            return import(`../../../../../../locale-compiled/it-IT.json`);
        case 'lv':
        case 'lv-lv':
            return import(`../../../../../../locale-compiled/lv-LV.json`);
        case 'lt':
        case 'lt-lt':
            return import(`../../../../../../locale-compiled/lt-LT.json`);
        case 'nb':
        case 'nb-no':
            return import(`../../../../../../locale-compiled/nb-NO.json`);
        case 'nl':
        case 'nl-nl':
            return import('../../../../../../locale-compiled/nl-NL.json');
        case 'pl':
        case 'pl-pl':
            return import(`../../../../../../locale-compiled/pl-PL.json`);
        case 'pt':
        case 'pt-pt':
            return import(`../../../../../../locale-compiled/pt-PT.json`);
        case 'pt-br':
            return import(`../../../../../../locale-compiled/pt-BR.json`);
        case 'ro':
        case 'ro-ro':
            return import('../../../../../../locale-compiled/ro-RO.json');
        case 'sv':
        case 'sv-se':
            return import(`../../../../../../locale-compiled/sv-SE.json`);
        case 'zh':
        case 'zh-cn':
            return import('../../../../../../locale-compiled/zh-CN.json');
        default:
            return import(`../../../../../../locale-compiled/en.json`);
    }
}
export const Localize = observer(function Localize({ locale, children, }) {
    const searchParams = decodeParams(window.location.search);
    const overridenLocale = searchParams.locale || locale || 'en-US';
    const [messages, setMessages] = useState(null);
    useEffect(() => {
        setMessages(null);
        let cancelled = false;
        loadLocaleData(overridenLocale).then((data) => {
            if (cancelled)
                return;
            setMessages(data.default);
        });
        return () => {
            cancelled = true;
        };
    }, [overridenLocale]);
    if (!messages) {
        return _jsx(FullScreenLoader, {});
    }
    return (_jsx(IntlProvider, { locale: overridenLocale, defaultLocale: "en", messages: messages, children: children }));
});
