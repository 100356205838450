import { __rest } from "tslib";
import { jsx as _jsx } from "react/jsx-runtime";
import PropTypes from 'prop-types';
import { Children, cloneElement, isValidElement } from 'react';
import { Ordered, Unordered, Unstyled } from './styles';
function List(_a) {
    var { children, type, size } = _a, rest = __rest(_a, ["children", "type", "size"]);
    const items = Children.map(children, (element) => isValidElement(element) ? cloneElement(element, { size }) : element);
    if (type === 'ordered') {
        return _jsx(Ordered, Object.assign({}, rest, { children: items }));
    }
    if (type === 'unordered') {
        return _jsx(Unordered, Object.assign({}, rest, { children: items }));
    }
    return _jsx(Unstyled, Object.assign({}, rest, { children: items }));
}
List.propTypes = {
    children: PropTypes.any,
    type: PropTypes.oneOf(['ordered', 'unordered']),
};
List.defaultProps = {
    type: 'unordered',
    size: 2,
};
export default List;
