import { __awaiter } from "tslib";
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { observer } from 'mobx-react';
import { useCallback, useEffect, useRef, useState } from 'react';
import * as React from 'react';
import { useAction, useSlideUIContext } from '@marvelapp/ballpark-application';
import { CornerButton, Icon, Text } from '@marvelapp/ballpark-components';
import { CLOSE_ICON } from '@marvelapp/ballpark-theme';
import styled from '@marvelapp/styled';
import { Box, Flex, Loader, PinCenter, PinTL } from '@marvelapp/ui';
import { MediaUtils, StepUtils } from '@marvelapp/user-test-creator';
import VideoGuideHolder from '../../../components/VideoGuideHolder';
import FadeIn from '../../../pages/Response/Heatmaps/FadeIn';
import UploadingArrow from '../UploadingArrow';
import PlayButton from './PlayButton';
import { useGetVideoGuideUrl } from './useGetVideoGuideUrl';
export const VideoGuideStep = observer(function VideoGuideStep() {
    const { step } = useSlideUIContext();
    const [isPlaying, setIsPlaying] = useState(false);
    const mediaRef = useRef(null);
    const [showControl, setShowControl] = useState(false);
    const videoGuideUrl = useGetVideoGuideUrl(step);
    const instruction = StepUtils.getInstruction(step);
    useEffect(() => {
        const media = mediaRef.current;
        if (!media)
            return;
        if (videoGuideUrl) {
            media.addEventListener('play', () => {
                setIsPlaying(true);
            }, false);
            media.addEventListener('pause', () => {
                setIsPlaying(false);
            }, false);
        }
    }, [videoGuideUrl]);
    const onPlay = useCallback(() => __awaiter(this, void 0, void 0, function* () {
        const media = mediaRef.current;
        if (!media)
            return;
        if (media.paused || media.ended) {
            yield media.play();
            setIsPlaying(true);
            setShowControl(true);
        }
    }), []);
    const onPause = useCallback(() => {
        const media = mediaRef.current;
        if (!media)
            return;
        if (!media.paused && !media.ended) {
            media.pause();
        }
    }, []);
    const onDelete = useAction((event) => {
        event.stopPropagation();
        StepUtils.removeInstruction(step);
    }, [step]);
    if (!instruction ||
        instruction.status === MediaUtils.RecordingStatus.Streaming) {
        return null;
    }
    const isReady = instruction.status === MediaUtils.RecordingStatus.Ready;
    if (!videoGuideUrl) {
        return (_jsx(NoMediaContainer, { "data-testid": "view-step-recording", children: _jsxs(VideoGuideHolder, { children: [_jsx(PinCenter, { color: "slate8", children: _jsx(UploadingArrow, {}) }), _jsx(CornerButton, { icon: _jsx(Icon, { paths: CLOSE_ICON }), kind: "negative", onClick: onDelete, testId: "delete-step-recording" })] }) }));
    }
    // Safari does not display the video thumbnail with preload="metadata"
    // As a workaround, we slightly advance the video time by 1ms
    const mediaUrl = `${videoGuideUrl}#t=1`;
    return (_jsxs(Container, { "data-testid": "view-step-recording", mt: "xs", mb: "xs", children: [_jsx(Hover, { children: _jsxs(VideoGuideHolder, { children: [_jsxs(_Fragment, { children: [_jsx(Video, { ref: mediaRef, controls: showControl, disablePictureInPicture: true, children: _jsx("source", { src: mediaUrl }) }, `${mediaUrl}`), !showControl && (_jsx(PinCenter, { children: _jsx(PlayButton, { isPlaying: isPlaying, onPause: onPause, onPlay: onPlay }) }))] }), !isReady && (_jsx(PinTL, { children: _jsx(FadeIn, { animate: true, children: _jsx(TextHolder, { "data-testid": "uploading-video", children: _jsxs(Flex, { alignItems: "center", children: [_jsx(Box, { marginTop: "4px", marginRight: "6px", children: _jsx(Loader, { speed: 1000, borderWidth: 3, size: 13 }) }), _jsx(Box, { marginTop: "4px", children: _jsx(Text, { size: "xs", children: "Uploading..." }) })] }) }) }) }))] }) }), _jsx(CornerButton, { icon: _jsx(Icon, { paths: CLOSE_ICON }), kind: "negative", onClick: onDelete, testId: "delete-step-recording" })] }));
});
const Hover = styled(Box).attrs({
    transition: 'smooth.fast',
    hoverBoxShadow: 'button.ghost',
    borderRadius: ['s', 's', 'm', 'l'],
}) `
  &:hover {
    transform: translate3d(0px, -1px, 0px);
  }
  &:active {
    transform: translate3d(0px, 1px, 0px);
  }
`;
const Container = styled(Box) `
  cursor: pointer;
  position: relative;
  width: fit-content;
`;
const NoMediaContainer = styled(Box) `
  cursor: pointer;
  position: relative;
`;
const Video = styled(Box).attrs({
    as: 'video',
    preload: 'metadata',
    borderRadius: ['s', 's', 'm', 'l'],
}) `
  width: 100%;
  display: block;
  object-fit: cover;
}
`;
const TextHolder = styled(Box).attrs({
    bg: 'blackA10',
    borderRadius: 's',
    marginLeft: 'xs',
    marginTop: 'xs',
    color: 'whiteA10',
    fontSize: 'xs',
    fontWeight: 'medium',
}) `
  padding: 0 12px;
  height: 28px;
  line-height: 28px;
`;
