export function richTextV1ToV2(input) {
    if (input.every(isElement))
        return input;
    const result = input.map(toElement);
    return result;
}
export function isElement(value) {
    return 'children' in value;
}
export function toElement(node) {
    return isElement(node)
        ? node
        : {
            type: 'p',
            children: [{ text: node.text }],
        };
}
