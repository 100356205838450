import { StepClass } from '../common';
import { getCategory as getStepCategory } from '../userTest/cardSortingUtils';
import { generateUUID } from '../utils/generateUUID';
export function createCardSortingOpenResponse(step) {
    return {
        stepUUID: step.uuid,
        stepRevision: step.revision,
        className: StepClass.CardSortingOpen,
        answers: {
            type: 'CARDSORT_OPEN',
            value: {
                additionalCategories: {},
                cardCategoryMapping: [],
            },
        },
    };
}
export function createCardSortingClosedResponse(step) {
    return {
        stepUUID: step.uuid,
        stepRevision: step.revision,
        className: StepClass.CardSortingClosed,
        answers: {
            type: 'CARDSORT_CLOSED',
            value: {
                cardCategoryMapping: [],
            },
        },
    };
}
export function createCardSortingHybridResponse(step) {
    return {
        stepUUID: step.uuid,
        stepRevision: step.revision,
        className: StepClass.CardSortingHybrid,
        answers: {
            type: 'CARDSORT_HYBRID',
            value: {
                additionalCategories: {},
                cardCategoryMapping: [],
            },
        },
    };
}
export function getUnsortedCardUUIDs(stepResponse, step) {
    const sortedCardUUIDs = new Set(stepResponse.answers.value.cardCategoryMapping.map(([cardUUID]) => cardUUID));
    return step.cards.filter((uuid) => !sortedCardUUIDs.has(uuid));
}
export function getCategoryUUIDs(stepResponse, step) {
    if ('additionalCategories' in stepResponse.answers.value) {
        return [
            ...step.categories,
            ...Object.keys(stepResponse.answers.value.additionalCategories),
        ];
    }
    return step.categories;
}
export function getCardUUIDsForCategory(stepResponse, categoryUUID) {
    return stepResponse.answers.value.cardCategoryMapping
        .filter(([, catUUID]) => catUUID === categoryUUID)
        .map(([cardUUID]) => cardUUID);
}
export function getCardsForCategory(stepResponse, step, categoryUUID) {
    return stepResponse.answers.value.cardCategoryMapping
        .filter(([, catUUID]) => catUUID === categoryUUID)
        .map(([cardUUID]) => (Object.assign({ id: cardUUID, uuid: cardUUID }, step.cardDefinitions[cardUUID])));
}
export function getCardCategoryMapping(stepResponse) {
    return stepResponse.answers.value.cardCategoryMapping;
}
export function moveCard({ stepResponse: mutable, cardUUID, categoryUUID, index, }) {
    // Remove any existing association of the card with any other category
    removeCardFromCategory(mutable, cardUUID);
    const { cardCategoryMapping } = mutable.answers.value;
    // Find the index to insert the card into the new category
    const insertIndex = cardCategoryMapping.findIndex(([_, category]) => category === categoryUUID);
    if (insertIndex !== -1) {
        cardCategoryMapping.splice(insertIndex + index, 0, [
            cardUUID,
            categoryUUID,
        ]);
    }
    else {
        cardCategoryMapping.push([cardUUID, categoryUUID]);
    }
}
export function removeCardFromCategory(mutable, cardUUIDToRemove) {
    const { cardCategoryMapping } = mutable.answers.value;
    const index = cardCategoryMapping.findIndex(([cardUUID]) => cardUUID === cardUUIDToRemove);
    if (index === -1) {
        return;
    }
    cardCategoryMapping.splice(index, 1);
}
export function canAddNewCategories(stepResponse) {
    return 'additionalCategories' in stepResponse.answers.value;
}
export function getCategory(stepResponse, step, uuid) {
    if (canAddNewCategories(stepResponse) &&
        uuid in stepResponse.answers.value.additionalCategories) {
        return Object.assign({ uuid, id: uuid }, stepResponse.answers.value.additionalCategories[uuid]);
    }
    return getStepCategory(step, uuid);
}
export function addCategory(mutable) {
    if (!canAddNewCategories(mutable)) {
        return;
    }
    const newUUID = generateUUID('cat_');
    mutable.answers.value.additionalCategories[newUUID] = {};
    return newUUID;
}
export function updateCategory(mutable, uuid, newCategory) {
    if (!canAddNewCategories(mutable)) {
        return;
    }
    const existingCategory = mutable.answers.value.additionalCategories[uuid];
    if (!existingCategory)
        return;
    Object.assign(existingCategory, newCategory);
}
export function removeCategory(mutable, uuid) {
    if (!canAddNewCategories(mutable)) {
        return;
    }
    delete mutable.answers.value.additionalCategories[uuid];
    mutable.answers.value.cardCategoryMapping =
        mutable.answers.value.cardCategoryMapping.filter(([, category]) => category !== uuid);
}
export function hasValidCategoryNames(step, stepResponse) {
    // Use Set here so we ignore duplicates in the initial categories as the
    // participant can't change them
    const initialCategoryNames = new Set(step.categories.map((categoryUUID) => normaliseCategoryTitle(getStepCategory(step, categoryUUID).title)));
    const additionalCategoryNames = Object.values(stepResponse.answers.value.additionalCategories).map((category) => normaliseCategoryTitle(category.title));
    const allCategoryNames = [
        ...initialCategoryNames,
        ...additionalCategoryNames,
    ];
    const uniqueCategoryNames = new Set(allCategoryNames);
    if (uniqueCategoryNames.has('')) {
        return false;
    }
    return uniqueCategoryNames.size === allCategoryNames.length;
}
export function isCategoryValid(step, stepResponse, categoryUUID) {
    if (step.categories.includes(categoryUUID)) {
        // Don't show validation errors for initial categories
        return true;
    }
    const category = stepResponse.answers.value.additionalCategories[categoryUUID];
    const title = normaliseCategoryTitle(category.title);
    if (title === '') {
        return false;
    }
    // Check if the category title is unique
    const categoryTitles = step.categories.map((uuid) => normaliseCategoryTitle(step.categoryDefinitions[uuid].title));
    const additionalCategoryTitles = Object.entries(stepResponse.answers.value.additionalCategories)
        .filter(([uuid]) => uuid !== categoryUUID)
        .map(([_, category]) => normaliseCategoryTitle(category.title));
    const allOtherCategoryTitles = [
        ...categoryTitles,
        ...additionalCategoryTitles,
    ];
    return !allOtherCategoryTitles.includes(normaliseCategoryTitle(category.title));
}
function normaliseCategoryTitle(title) {
    if (!title)
        return '';
    return title.trim().toLocaleLowerCase();
}
