import { gql, useQuery } from '@apollo/client';
export const STEP_AGGREGATE_CARD_SORT_NODE_FRAGMENT = gql `
  fragment stepAggregateCardsortNode on StepAggregateCardsortNode {
    __typename
    pk
    stepResponseUuid
    updatedAt
    totalStepVisits
    totalVisitsCompleted
    bounceCount
    stepBounceRate

    cardAgreement {
      pk
      categoryUuid
      cardUuid
      value
      metricId
    }
    cardAgreementAvg {
      pk
      cardUuid
      value
      metricId
    }
    categoryAgreementAvg {
      pk
      categoryUuid
      value
      metricId
    }
    categoryFrequency {
      pk
      categoryUuid
      cardUuid
      value
      metricId
    }
    categoryTotals {
      pk
      categoryUuid
      value
      metricId
    }
    cardSimilarity {
      cardUuids
      value
      metricId
      pk
    }
    categoryPositionAvg {
      cardUuid
      categoryUuid
      value
      metricId
    }
  }
`;
// Note we can't use a fragment here, for the common fields,
// as the different nodes do not share a common interface in graphene
export const GET_USER_TEST_AGGREGATES = gql `
  fragment textAnswerNode on AnswerPlainText {
    text
  }
  fragment emailAnswerNode on AnswerEmail {
    email
  }
  fragment dateTimeAnswerNode on AnswerDateTime {
    date
  }
  fragment integerAnswerNode on AnswerInteger {
    value
  }
  fragment stepAggregateInstructionNode on StepAggregateInstructionNode {
    __typename
    pk
    stepResponseUuid
    totalStepVisits
    percentageTotalStepVisits
    totalVisitsCompleted
    totalVisitsIncompleted
    percentageVisitsCompleted
    percentageVisitsIncompleted
    averageStepDuration
    minimumStepDuration
    maximumStepDuration
    averageAnswer
    aggregatedOn
    stepRevision
    updatedAt
    stepBounceRate
    bounceCount
  }
  fragment stepAggregateBannerAdTestNode on StepAggregateBannerAdTestNode {
    __typename
    pk
    stepResponseUuid
    totalStepVisits
    percentageTotalStepVisits
    totalVisitsCompleted
    totalVisitsIncompleted
    percentageVisitsCompleted
    percentageVisitsIncompleted
    averageStepDuration
    minimumStepDuration
    maximumStepDuration
    averageAnswer
    aggregatedOn
    stepRevision
    updatedAt
    stepBounceRate
    bounceCount
  }
  fragment stepAggregateFirstClickNode on StepAggregateFirstClickNode {
    __typename
    pk
    stepResponseUuid
    totalStepVisits
    percentageTotalStepVisits
    totalVisitsCompleted
    totalVisitsIncompleted
    percentageVisitsCompleted
    percentageVisitsIncompleted
    averageStepDuration
    minimumStepDuration
    maximumStepDuration
    averageAnswer
    aggregatedOn
    stepRevision
    updatedAt
    stepBounceRate
    bounceCount
  }
  fragment stepAggregateFiveSecondTestNode on StepAggregateFiveSecondTestNode {
    __typename
    pk
    stepResponseUuid
    totalStepVisits
    percentageTotalStepVisits
    totalVisitsCompleted
    totalVisitsIncompleted
    percentageVisitsCompleted
    percentageVisitsIncompleted
    averageStepDuration
    minimumStepDuration
    maximumStepDuration
    averageAnswer
    aggregatedOn
    stepRevision
    updatedAt
    stepBounceRate
    bounceCount
  }
  fragment stepAggregateLegalQuestionNode on StepAggregateLegalQuestionNode {
    __typename
    pk
    stepResponseUuid
    totalStepVisits
    percentageTotalStepVisits
    totalVisitsCompleted
    totalVisitsIncompleted
    percentageVisitsCompleted
    percentageVisitsIncompleted
    averageStepDuration
    minimumStepDuration
    maximumStepDuration
    averageAnswer
    aggregatedOn
    stepRevision
    updatedAt
    stepBounceRate
    bounceCount
  }
  fragment stepAggregateMultipleChoiceNode on StepAggregateMultipleChoiceNode {
    __typename
    pk
    stepResponseUuid
    totalStepVisits
    percentageTotalStepVisits
    totalVisitsCompleted
    totalVisitsIncompleted
    percentageVisitsCompleted
    percentageVisitsIncompleted
    averageStepDuration
    minimumStepDuration
    maximumStepDuration
    averageAnswer
    aggregatedOn
    stepRevision
    updatedAt
    stepBounceRate
    bounceCount
    disqualifiedCount
    disqualifiedPercentage
    choiceStats {
      answerValue
      answerCount
      percentageChosen
    }
  }
  fragment stepAggregatePreferenceTestNode on StepAggregatePreferenceTestNode {
    __typename
    pk
    stepResponseUuid
    totalStepVisits
    percentageTotalStepVisits
    totalVisitsCompleted
    totalVisitsIncompleted
    percentageVisitsCompleted
    percentageVisitsIncompleted
    averageStepDuration
    minimumStepDuration
    maximumStepDuration
    averageAnswer
    aggregatedOn
    stepRevision
    updatedAt
    stepBounceRate
    bounceCount
    preferenceStats {
      answerCount
      answerValue
      percentageChosen
    }
  }
  fragment stepAggregatePrototypeTaskNode on StepAggregatePrototypeTaskNode {
    __typename
    pk
    stepResponseUuid
    totalStepVisits
    percentageTotalStepVisits
    totalVisitsCompleted
    totalVisitsIncompleted
    percentageVisitsCompleted
    percentageVisitsIncompleted
    averageStepDuration
    minimumStepDuration
    maximumStepDuration
    averageAnswer
    aggregatedOn
    stepRevision
    updatedAt
    stepBounceRate
    bounceCount
    prototypeStats {
      goalHitTotal
      goalHitRate
      misclickRate
      userRecordingCount
      screenRecordingCount
      percentageUserRecording
      percentageScreenRecording
      averageDuration
    }
  }
  fragment stepAggregateQuestionNode on StepAggregateQuestionNode {
    __typename
    pk
    stepResponseUuid
    totalStepVisits
    percentageTotalStepVisits
    totalVisitsCompleted
    totalVisitsIncompleted
    percentageVisitsCompleted
    percentageVisitsIncompleted
    averageStepDuration
    minimumStepDuration
    maximumStepDuration
    averageAnswer
    aggregatedOn
    stepRevision
    updatedAt
    stepBounceRate
    bounceCount
    questionAnswers {
      stepType
      participantName
      createdAt
      responsePk
      answers {
        ...textAnswerNode
        ...emailAnswerNode
        ...dateTimeAnswerNode
        ...integerAnswerNode
      }
    }
  }
  fragment stepAggregateRatingScaleNode on StepAggregateRatingScaleNode {
    __typename
    pk
    stepResponseUuid
    totalStepVisits
    percentageTotalStepVisits
    totalVisitsCompleted
    totalVisitsIncompleted
    percentageVisitsCompleted
    percentageVisitsIncompleted
    averageStepDuration
    minimumStepDuration
    maximumStepDuration
    averageAnswer
    aggregatedOn
    stepRevision
    updatedAt
    stepBounceRate
    bounceCount
    ratingStats {
      answerValue
      percentageChosen
    }
  }
  fragment stepAggregateTaglineCopyTestNode on StepAggregateTaglineCopyTestNode {
    __typename
    pk
    stepResponseUuid
    totalStepVisits
    percentageTotalStepVisits
    totalVisitsCompleted
    totalVisitsIncompleted
    percentageVisitsCompleted
    percentageVisitsIncompleted
    averageStepDuration
    minimumStepDuration
    maximumStepDuration
    averageAnswer
    aggregatedOn
    stepRevision
    updatedAt
    stepBounceRate
    bounceCount
    taglineStats {
      answerCount
      answerValue
      percentageChosen
    }
  }
  fragment stepAggregateWebsiteTaskNode on StepAggregateWebsiteTaskNode {
    __typename
    pk
    stepResponseUuid
    totalStepVisits
    percentageTotalStepVisits
    totalVisitsCompleted
    totalVisitsIncompleted
    percentageVisitsCompleted
    percentageVisitsIncompleted
    averageStepDuration
    minimumStepDuration
    maximumStepDuration
    averageAnswer
    aggregatedOn
    stepRevision
    updatedAt
    stepBounceRate
    bounceCount
    mediaStats {
      averageRecordingDuration
    }
  }
  fragment stepAggregateYesOrNoNode on StepAggregateYesOrNoNode {
    __typename
    pk
    stepResponseUuid
    totalStepVisits
    percentageTotalStepVisits
    totalVisitsCompleted
    totalVisitsIncompleted
    percentageVisitsCompleted
    percentageVisitsIncompleted
    averageStepDuration
    minimumStepDuration
    maximumStepDuration
    averageAnswer
    aggregatedOn
    stepRevision
    updatedAt
    stepBounceRate
    bounceCount
    radioButtonStats {
      answerValue
      answerCount
      percentageChosen
    }
  }
  ${STEP_AGGREGATE_CARD_SORT_NODE_FRAGMENT}

  query getStepStats($uuid: String!) {
    userTestStepStats(uuid: $uuid, aggregatedOn: COMPLETE_AND_INCOMPLETE) {
      ...stepAggregateInstructionNode
      ...stepAggregateBannerAdTestNode
      ...stepAggregateFirstClickNode
      ...stepAggregateFiveSecondTestNode
      ...stepAggregateLegalQuestionNode
      ...stepAggregateMultipleChoiceNode
      ...stepAggregatePreferenceTestNode
      ...stepAggregatePrototypeTaskNode
      ...stepAggregateQuestionNode
      ...stepAggregateRatingScaleNode
      ...stepAggregateTaglineCopyTestNode
      ...stepAggregateWebsiteTaskNode
      ...stepAggregateYesOrNoNode
      ...stepAggregateCardsortNode
    }
    userTestDocument(uuid: $uuid, revision: 0) {
      uuid
      revision
      document
    }
  }
`;
export function useQueryUserTestAggregates(testUUID) {
    return useQuery(GET_USER_TEST_AGGREGATES, {
        variables: { uuid: testUUID || 'keep TS happy' },
        skip: !testUUID,
        nextFetchPolicy: 'cache-first',
    });
}
