import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { observer } from 'mobx-react';
import { Button, Heading, Link, Text } from '@marvelapp/ballpark-components';
import { CONTACT_SUPPORT } from '@marvelapp/ballpark-help';
import styled from '@marvelapp/styled';
import { Box } from '@marvelapp/ui';
export const InvalidDocError = observer(function InvalidDocError() {
    return (_jsx(ContentContainer, { "data-testid": "invalid-doc-error-popup", children: _jsxs(Box, { bg: "white", padding: "xl", maxWidth: 420, children: [_jsx(Heading, { children: "We have reverted your most recent change due to an issue" }), _jsxs(Text, { paddingY: "m", children: ["This can happen when using browser plugins such as spell checkers like Grammerly or translation tools.", ' ', _jsx(Link, { to: CONTACT_SUPPORT, children: "Contact support" }), " and let us know what you\u2019re using or disable the plugin and try again."] }), _jsx(Button, { onClick: () => window.location.reload(), children: "Reload page" })] }) }));
});
const ContentContainer = styled.div `
  background-color: rgba(0, 0, 0, 0.5);
  background-opacity: 0.5;
  z-index: ${(props) => props.theme.zIndices.modal};
  box-shadow: 0 0 12px 4px rgba(0, 0, 0, 0.1);
  position: fixed !important;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow-y: scroll;
`;
