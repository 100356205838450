import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { DndContext, closestCenter } from '@dnd-kit/core';
import { SortableContext, rectSortingStrategy } from '@dnd-kit/sortable';
import { observer } from 'mobx-react';
import * as React from 'react';
import { useAction, useGetUserTest, useSlideUIContext, } from '@marvelapp/ballpark-application';
import { getThumbnail, getThumbnail2x } from '@marvelapp/core';
import { PreferenceStepUtils, StepClass, StepUtils, } from '@marvelapp/user-test-creator';
import AddImageCTA from '../../components/AddImageCTA';
import EditablePreferenceCard from '../../components/EditablePreferenceCard';
import PreferenceGrid from '../../components/PreferenceGrid';
import { useDragging } from '../../hooks/useDragging';
import { OneColumnSlide } from '../common/OneColumnSlide';
import SlideContent from '../common/SlideContent';
export default observer(function PreferenceTest({ onMainCTAClick, }) {
    const userTest = useGetUserTest();
    const { step } = useSlideUIContext();
    const { handleDragEnd, sensors } = useDragging();
    const onImageUploaded = useAction((src) => {
        if (!StepUtils.isOfType(step, StepClass.PreferenceTest))
            return;
        PreferenceStepUtils.addChoice(step, { src, choiceTitle: '' });
    }, [step]);
    const onDragEnd = useAction((event) => {
        if (!StepUtils.isOfType(step, StepClass.PreferenceTest))
            return;
        const items = step.choices.map((uuid) => `card-${uuid}`);
        handleDragEnd({
            event,
            items,
            reorderFn: PreferenceStepUtils.reorderChoices,
            parentItem: step,
        });
    }, [handleDragEnd, step]);
    const stepChoices = PreferenceStepUtils.getChoicesByStepUUID(userTest, step.uuid);
    const items = stepChoices.map((uuid) => `card-${uuid}`);
    return (_jsx(OneColumnSlide, { children: _jsx(SlideContent, { ctaOnClick: onMainCTAClick, children: _jsxs(PreferenceGrid, { children: [_jsx(DndContext, { sensors: sensors, collisionDetection: closestCenter, onDragEnd: onDragEnd, children: _jsx(SortableContext, { items: items, strategy: rectSortingStrategy, children: stepChoices.map((choiceUUID, index) => (_jsx(PreferenceChoice, { choiceUUID: choiceUUID, choiceIndex: index }, choiceUUID))) }) }, "dndContext"), _jsx(AddImageCTA, { onImageUploaded: onImageUploaded, aspectRatio: 1 / 1, heading: "Add an image", isDisabled: false, subheading: "PNG, JPG, GIF or WebP up to 6MB. Click or drag and drop.", testId: "add-preference-test-image-cta" }, "addImageCta")] }) }) }));
});
const PreferenceChoice = observer(function PreferenceChoice({ choiceIndex, choiceUUID, }) {
    const { step } = useSlideUIContext();
    if (!StepUtils.isOfType(step, StepClass.PreferenceTest))
        return null;
    const choice = step.choiceDefinitions[choiceUUID];
    if (!choice)
        return null;
    return (_jsx(EditablePreferenceCard, { index: choiceIndex, choiceUUID: choiceUUID, src: getThumbnail(choice.src, 400), src2x: getThumbnail2x(choice.src, 400), step: step }, choiceUUID));
});
