import { pull } from 'lodash-es';
export function getAnswer(response) {
    return response.answer.value;
}
export function updateChoices(mutable, uuid, isChecked, multipleSelectionMode) {
    if (isChecked) {
        pull(mutable.answer.value, uuid);
    }
    else {
        updateAnswer(mutable, uuid, multipleSelectionMode);
    }
}
export function updateAnswer(mutable, choiceUUID, multipleSelectionMode) {
    if (multipleSelectionMode) {
        // if multiple selection is enabled, add other option to selected list
        if (!mutable.answer.value.includes(choiceUUID)) {
            mutable.answer.value.push(choiceUUID);
        }
    }
    else {
        mutable.answer.value = [choiceUUID];
    }
}
