import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { isNil } from 'lodash-es';
import { observer } from 'mobx-react';
import { useCallback } from 'react';
import * as React from 'react';
import { v4 as uuid } from 'uuid';
import { useAction } from '@marvelapp/ballpark-application';
import { Badge, BtwButton, BtwPopover, CtaMessage, Stack, } from '@marvelapp/ballpark-components';
import { FilterFunnelIcon } from '@marvelapp/ballpark-icons';
import { Filter } from './Filters';
import { useResponsesContext } from './ResponsesContext';
import { useGetFilterDefinitions } from './useGetFilterDefinitions';
const MAX_FILTERS = 10;
export const FilteringPopover = observer(function FilteringPopover() {
    const filterDefinitions = useGetFilterDefinitions();
    const { filters, addFilter, clearFilters, updateFilter, removeFilter, filterDefinitionsMap, totalResults, } = useResponsesContext();
    const getValues = useCallback((filterId, filterDef) => {
        const usedValues = filters
            .filter((filter) => filter.id !== filterId &&
            filter.definitionId === filterDef.id &&
            (filter.operator === 'equals' || filter.operator === 'goal_hit'))
            .map((filter) => filter.value);
        const unusedValues = (filterDef.values || []).filter((value) => !usedValues.includes(value.value));
        return { usedValues, unusedValues };
    }, [filters]);
    const onAddNewFilter = useCallback(() => {
        const newFilter = {
            id: uuid(),
            operand: undefined,
            definitionId: undefined,
            operator: undefined,
            value: undefined,
        };
        addFilter(newFilter);
    }, [addFilter]);
    const getFilterNames = useCallback((filterId) => {
        return filterDefinitions.map((filterDef) => {
            const { id } = filterDef;
            const { unusedValues } = getValues(filterId, filterDef);
            const disabled = unusedValues.length === 0 &&
                id !== filterId &&
                filterDef.type.includes('enum');
            return {
                filterDef,
                value: id,
                disabled,
            };
        });
    }, [filterDefinitions, getValues]);
    const getFilterValues = useCallback((id, filterDef) => {
        const { usedValues } = getValues(id, filterDef);
        if (!filterDef.values)
            return [];
        return filterDef.values.map((filterValue) => ({
            name: filterValue.name,
            value: filterValue.value,
            disabled: usedValues.includes(filterValue.value),
        }));
    }, [getValues]);
    return (_jsxs(BtwPopover.Root, { children: [_jsx(BtwPopover.Trigger, { asChild: true, children: _jsxs(BtwButton, { disabled: totalResults === 0, "aria-label": "Filter Responses", leadingIcon: _jsx(FilterFunnelIcon, {}), variant: "ghost", "data-testid": "filters", size: "intermediate", children: ["Filters", ' ', filters.length > 0 && (_jsx(Badge, { size: "sm", className: "min-w-[18px]", "data-testid": "filter-count", children: filters.length }))] }) }), _jsx(BtwPopover.Content, { collisionPadding: 16, className: "max-h-[var(--radix-popper-available-height)]", "data-testid": "filtering-popover", children: _jsx(Stack, { className: "relative w-[560px]", children: filters.length === 0 ? (_jsx(EmptyState, { onAddNewFilter: onAddNewFilter })) : (_jsxs(_Fragment, { children: [_jsx(Stack, { gap: "4", width: "full", className: "max-h-[calc(var(--radix-popper-available-height)_-_56px)] overflow-y-scroll py-4 pl-4 pr-3", children: filters.map((filterSelection, index) => (_jsx(FilterSelectionDetails, { filterSelection: filterSelection, index: index, getFilterNames: getFilterNames, updateFilter: updateFilter, removeFilter: removeFilter, filterDefinitionsMap: filterDefinitionsMap, getFilterValues: getFilterValues, getValues: getValues }, filterSelection.id))) }), _jsxs(Stack, { direction: "row", justify: "between", width: "full", className: "z-10 px-4 py-3 ring-1 ring-gray-600/10", children: [_jsx(BtwButton, { onClick: onAddNewFilter, disabled: filters.length >= MAX_FILTERS, "data-testid": "add-new-filter", size: "sm", children: "Add new filter" }), _jsx(BtwButton, { variant: "ghost", onClick: clearFilters, "data-testid": "clear-filters", size: "sm", children: "Clear all filters" })] })] })) }) })] }));
});
const EmptyState = observer(function EmptyState({ onAddNewFilter, }) {
    return (_jsx(Stack, { width: "full", className: "max-w-96 self-center p-8", align: "center", justify: "center", gap: "4", children: _jsxs(CtaMessage.Root, { size: "xs", children: [_jsxs(CtaMessage.Header, { icon: _jsx(FilterFunnelIcon, {}), children: [_jsx(CtaMessage.Heading, { children: "No filters applied yet\u2026" }), _jsx(CtaMessage.Description, { children: "Narrow down your responses by filtering on the available properties. All filters are additive." })] }), _jsx(CtaMessage.Actions, { children: _jsx(BtwButton, { onClick: onAddNewFilter, variant: "secondary", "data-testid": "add-new-filter", size: "sm", children: "Add new filter" }) })] }) }));
});
export const FilterSelectionDetails = observer(function FilterSelectionDetails({ filterSelection, index, getFilterNames, updateFilter, removeFilter, filterDefinitionsMap, getFilterValues, }) {
    const { id, definitionId, operator, value } = filterSelection;
    const onFilterDefinitionIdChange = (definitionId) => {
        const newFilter = filterDefinitionsMap[definitionId];
        updateFilter(id, {
            id: uuid(),
            definitionId,
            operand: newFilter.operand,
            operator: newFilter.operators[0].value,
            value: undefined,
        });
    };
    const onDelete = () => removeFilter(id);
    // Note this _must_ be stable for value debouncing to work correctly
    const onFilterValueChange = useAction((value) => {
        updateFilter(id, { value });
    }, [updateFilter, id]);
    if (isNil(definitionId) || isNil(operator)) {
        return (_jsx(Filter, { index: index, availableFilters: getFilterNames(''), onFilterDefinitionIdChange: onFilterDefinitionIdChange, availableOperators: [], onDelete: onDelete, definitionId: "" }, id));
    }
    const filterDefinition = filterDefinitionsMap[definitionId];
    if (!filterDefinition)
        return null;
    return (_jsx(Filter, { availableFilters: getFilterNames(definitionId), availableOperators: filterDefinition.operators, definitionId: definitionId, index: index, inputPlaceholder: filterDefinition.inputPlaceholder, onDelete: () => removeFilter(id), onFilterDefinitionIdChange: onFilterDefinitionIdChange, onOperatorChange: (operator) => updateFilter(id, { operator }), onValueChange: onFilterValueChange, operator: operator, type: filterDefinition.type, value: value, values: getFilterValues(id, filterDefinition) }, id));
});
