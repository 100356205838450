import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import capitalize from 'lodash-es/capitalize';
import { useRouteMatch } from 'react-router-dom';
import { Box, Text } from '@marvelapp/ui';
import { NotFound } from '../Error';
import FullScreenLoader from '../FullScreenLoader';
import { ModalLink } from '../Modal';
import { ManagePeople, PersonButton } from '../People';
import ShareHeading from './ShareHeading';
function ShareModal({ canInvitePeopleToCompany, canEditCollaborators, canSearchCollaborators, collaboratorQueries, collaboratorSearchQueries, itemId, itemName, itemToShare, loading, ownerPk, }) {
    const match = useRouteMatch();
    if (loading) {
        return _jsx(FullScreenLoader, {});
    }
    if (!loading && !itemToShare) {
        return (_jsx(NotFound, { heading: `Oh no! ${capitalize(itemName)} with id ${itemId} does not exist.` }));
    }
    const renderPersonRowButtonComponent = ({ node, onRemove, isLoading, isDisabled, }) => {
        const { addedViaTeam, pk } = node;
        const isOwner = pk === ownerPk;
        return (_jsx(PersonButton, { badgeText: isOwner ? 'Owner' : addedViaTeam && 'Remove', badgeTooltip: isOwner
                ? "The owner can't be removed"
                : addedViaTeam &&
                    `This member can not be removed because they are part of a group added to this ${itemName}`, buttonKind: "ghostDanger", buttonText: "Remove", isDisabled: isDisabled, isLoading: isLoading, onButtonClick: () => onRemove(node) }));
    };
    const renderSearchButton = ({ isCollaborator, node, onAdd, isLoading, isDisabled, }) => {
        return (_jsx(PersonButton, { badgeText: isCollaborator && 'Added', buttonKind: "green", buttonText: "Add", isDisabled: isDisabled, isLoading: isLoading, onButtonClick: () => onAdd(node) }));
    };
    const projectPk = match.params.id;
    const inviteLink = {
        pathname: `/project/${itemId}/invite`,
        search: `next=/project/${projectPk}`,
    };
    return (_jsxs(_Fragment, { children: [_jsxs(ShareHeading, { children: [canEditCollaborators
                        ? 'Add people from your workspace to collaborate'
                        : `Collaborators on this ${itemName}`, canInvitePeopleToCompany && (_jsx(ShareSubHeading, { inviteLink: inviteLink }))] }), _jsx(ManagePeople, { canEdit: canEditCollaborators, canSearch: canSearchCollaborators, getOwnerPk: () => ownerPk, inviteUsersUrl: inviteLink, itemName: itemName, queries: collaboratorQueries, renderPersonRowButtonComponent: renderPersonRowButtonComponent, renderSearchButton: renderSearchButton, searchQueries: collaboratorSearchQueries, showTabs: collaboratorQueries.length > 1 })] }));
}
function ShareSubHeading({ inviteLink }) {
    return (_jsxs(Box, { paddingTop: "s", children: [_jsx(Text, { display: "inline-block", fontWeight: 400, fontSize: 2, children: "Search people in your workspace or," }), ' ', _jsx(ModalLink, { color: "marvelDark", "data-testid": "invite-new-person", display: "inline", fontSize: 3, paddingRight: 2, to: {
                    pathname: inviteLink.pathname,
                    search: inviteLink.search,
                }, children: _jsx(Text, { display: "inline-block", fontWeight: 400, fontSize: 2, children: "invite a new person to your workspace" }) })] }));
}
export default ShareModal;
