import { jsx as _jsx } from "react/jsx-runtime";
import * as React from 'react';
import theme from '@marvelapp/ballpark-theme';
import styled from '@marvelapp/styled';
import { Box } from '@marvelapp/ui';
// eslint-disable-next-line mobx/missing-observer
export default function FloatingButton({ children, }) {
    return _jsx(ButtonContainer, { children: children });
}
// useResponsiveValue causes flickering on desktop
const ButtonContainer = styled(Box) `
  @media (max-width: ${theme.breakpoints[2]}) {
    z-index: 1;
    position: fixed;
    bottom: 12px;
    left: 12px;
    right: 96px;
    button {
      width: 100%;
    }
  }
`;
