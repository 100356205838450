import { __rest } from "tslib";
import { jsx as _jsx } from "react/jsx-runtime";
import { observer } from 'mobx-react';
import { useAction, useSlideUIContext } from '@marvelapp/ballpark-application';
import { CardSortingUtils, StepUtils, } from '@marvelapp/user-test-creator';
import { BaseCard } from '../BaseCard';
import { useSortable } from '../dndUtils';
export const Card = observer(function Card(_a) {
    var { card } = _a, otherProps = __rest(_a, ["card"]);
    const { step } = useSlideUIContext();
    const { uuid } = card;
    const sortable = useSortable({ id: uuid });
    const onUpdateTitle = useAction((newTitle) => {
        if (!StepUtils.isCardSortingStep(step))
            return;
        CardSortingUtils.updateCard(step, uuid, {
            title: newTitle,
        });
    }, [uuid, step]);
    const onUpdateDescription = useAction((newDescription) => {
        if (!newDescription || !StepUtils.isCardSortingStep(step))
            return;
        CardSortingUtils.updateCard(step, uuid, {
            description: newDescription,
        });
    }, [uuid, step]);
    const onUpdateImageUrl = useAction((newImageUrl) => {
        if (!newImageUrl || !StepUtils.isCardSortingStep(step))
            return;
        CardSortingUtils.updateCard(step, uuid, {
            imageUrl: newImageUrl,
        });
    }, [uuid, step]);
    const onRemoveImageUrl = useAction(() => {
        if (!StepUtils.isCardSortingStep(step))
            return;
        CardSortingUtils.updateCard(step, uuid, {
            imageUrl: undefined,
        });
    }, [uuid, step]);
    const onDelete = useAction(() => {
        if (!StepUtils.isCardSortingStep(step))
            return;
        CardSortingUtils.removeCard(step, uuid);
    }, [uuid, step]);
    return (_jsx(BaseCard, Object.assign({ card: card, onDelete: onDelete, onUpdateDescription: onUpdateDescription, onUpdateImage: onUpdateImageUrl, onRemoveImage: onRemoveImageUrl, onUpdateTitle: onUpdateTitle }, sortable, otherProps)));
});
