import { __rest } from "tslib";
import { jsx as _jsx } from "react/jsx-runtime";
import { useMarkToolbarButton, useMarkToolbarButtonState, } from '@udecode/plate-common';
import { useLinkToolbarButton, useLinkToolbarButtonState, } from '@udecode/plate-link';
import { useListToolbarButton, useListToolbarButtonState, } from '@udecode/plate-list';
import { forwardRef } from 'react';
import * as React from 'react';
import { Toolbar } from '@marvelapp/ballpark-components';
/**
 * Toolbar button
 */
const ToolbarButtonBase = forwardRef(function ToolbarButtonBase(props, ref) {
    const { children, className, icon: Icon, pressed, tooltip } = props, rest = __rest(props, ["children", "className", "icon", "pressed", "tooltip"]);
    return (_jsx(Toolbar.Button, Object.assign({ content: tooltip, "data-state": pressed ? 'active' : 'inactive', ref: ref, size: "intermediate", standaloneIcon: _jsx(Icon, {}), variant: "ghost" }, rest)));
});
/**
 * Link toolbar button
 */
const LinkToolbarButton = forwardRef(function LinkToolbarButton(props, ref) {
    const state = useLinkToolbarButtonState();
    const linkToolbarButton = useLinkToolbarButton(state);
    return (_jsx(ToolbarButtonBase, Object.assign({ ref: ref, tooltip: "Link" }, linkToolbarButton.props, props)));
});
/**
 * List toolbar button
 */
const ListToolbarButton = forwardRef(function ListToolbarButton(props, ref) {
    const { clear, nodeType } = props, rest = __rest(props, ["clear", "nodeType"]);
    const state = useListToolbarButtonState({ nodeType });
    const listToolbarButton = useListToolbarButton(state);
    return _jsx(ToolbarButton, Object.assign({ ref: ref }, listToolbarButton.props, rest));
});
/**
 * Mark toolbar button
 */
const MarkToolbarButton = forwardRef(function MarkToolbarButton(props, ref) {
    const { clear, nodeType } = props, rest = __rest(props, ["clear", "nodeType"]);
    const state = useMarkToolbarButtonState({ clear, nodeType });
    const toolbarButton = useMarkToolbarButton(state);
    return _jsx(ToolbarButton, Object.assign({ ref: ref }, toolbarButton.props, rest));
});
export const ToolbarButton = Object.assign(ToolbarButtonBase, {
    Link: LinkToolbarButton,
    List: ListToolbarButton,
    Mark: MarkToolbarButton,
});
