import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { observer } from 'mobx-react';
import * as React from 'react';
import { ConditionsIcon, IconWithTooltip, Text, } from '@marvelapp/ballpark-components';
import styled from '@marvelapp/styled';
import { Box } from '@marvelapp/ui';
export default observer(function ResponseSecondaryInfo({ copy, hasBeenConditionallySkipped, }) {
    return (_jsxs(InfoBox, { "data-testid": "under-step-stats", children: [hasBeenConditionallySkipped && (_jsx(IconWithTooltip, { content: "Steps in this project have conditional logic rules, which determine whether users see this step", display: "inline-flex", children: _jsx(ConditionsIcon, { fill: "slate10", mr: "xxs" }) })), _jsx(Text, { size: "s", lineHeight: "base", color: "slate10", children: copy })] }));
});
const InfoBox = styled(Box).attrs({
    bg: 'snowDark',
    borderBottomLeftRadius: 'xl',
    borderBottomRightRadius: 'xl',
    padding: 's',
    paddingTop: '21px',
    marginBottom: 'm',
    marginX: 'auto',
}) `
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 700px;
  margin-top: -5px;
  &:last-child {
    margin-bottom: 0;
  }
`;
