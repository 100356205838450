import { pull } from 'lodash-es';
import { StepClass } from '../common';
import { updateAnswer } from './choiceStepResponseUtils';
import { AnswerType } from './types';
export function create(stepUUID, stepRevision) {
    return {
        className: StepClass.MultipleChoice,
        stepUUID,
        stepRevision,
        answer: {
            type: AnswerType.MultipleChoice,
            value: [],
        },
    };
}
export * from './choiceStepResponseUtils';
export function addOtherChoice(mutable, choiceUUID, otherValue, multipleSelectionMode) {
    updateAnswer(mutable, choiceUUID, multipleSelectionMode);
    mutable.answer.otherValue = otherValue;
}
export function removeOtherChoice(mutable, otherChoiceUUID) {
    pull(mutable.answer.value, otherChoiceUUID);
    delete mutable.answer.otherValue;
}
export function getOtherChoiceValue(mutable) {
    return mutable.answer.otherValue || null;
}
