import { deviceDetection } from '@marvelapp/core';
import { DeviceMake } from './response/types';
// TODO we have this info on TestResult so do we need it here (also this won't work if we have multiplayer)
export function getDeviceInfo() {
    return {
        make: getDeviceType(),
        model: deviceDetection.isMobile
            ? `${deviceDetection.mobileModel}`
            : `${deviceDetection.browserName}`,
        operatingSystem: `${deviceDetection.osName} (${deviceDetection.osVersion})`,
        screenWidth: window.innerWidth,
        screenHeight: window.innerHeight,
    };
}
function getDeviceType() {
    if (deviceDetection.isDesktop) {
        return DeviceMake.Web;
    }
    if (deviceDetection.isTablet) {
        return DeviceMake.Tablet;
    }
    return DeviceMake.Mobile;
}
