import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { motion } from 'framer-motion';
import { observer } from 'mobx-react';
import * as React from 'react';
export default observer(function UploadingArrow() {
    const pathProps = {
        stroke: 'currentColor',
        strokeWidth: '2.5',
        initial: { pathLength: 0 },
        animate: { pathLength: 1 },
        transition: {
            duration: 1.8,
            repeat: Infinity,
            ease: [0.23, 1, 0.32, 1],
        },
    };
    return (_jsxs("svg", { "data-testid": "uploading-arrow", fill: "none", height: "42", viewBox: "0 0 30 30", width: "42", children: [_jsx(motion.path, Object.assign({ d: "m15 21v-12l-4 4", strokeLinecap: "round", strokeLinejoin: "round" }, pathProps)), _jsx(motion.path, Object.assign({ d: "m15 21v-12l4 4", strokeLinecap: "round", strokeLinejoin: "round" }, pathProps))] }));
});
