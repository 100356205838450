import { jsx as _jsx } from "react/jsx-runtime";
import { NetworkStatus } from '@apollo/client';
import { observer, useLocalObservable } from 'mobx-react';
import { createContext, useContext } from 'react';
import * as React from 'react';
import { useGetUserTest } from '@marvelapp/ballpark-application';
import { getFilterDefinitions } from './filterUtils';
import { getDefaultColumnVisibility } from './stepUtils';
import { usePersistContext } from './usePersistContext';
const DEFAULT_LOGICAL_OPERATOR = 'AND';
export const ResponsesContext = createContext({});
export const useResponsesContext = () => {
    const responsesContext = useContext(ResponsesContext);
    if (responsesContext === undefined)
        throw new Error('useResponsesContext used outside of Provider');
    return responsesContext;
};
export const ResponsesContextProvider = observer(function RecorderContextProvider({ children, }) {
    const userTest = useGetUserTest();
    const defaultColumnVisibility = getDefaultColumnVisibility(userTest);
    /* eslint-disable react/no-this-in-sfc */
    const responsesContextState = useLocalObservable(() => ({
        rowSelection: {},
        setRowSelection(updaterOrValue) {
            if (typeof updaterOrValue === 'function') {
                this.rowSelection = updaterOrValue(this.rowSelection);
            }
            else {
                this.rowSelection = updaterOrValue;
            }
        },
        clearSelection() {
            this.rowSelection = {};
        },
        columnVisibility: defaultColumnVisibility,
        setColumnVisibility(updaterOrValue) {
            if (typeof updaterOrValue === 'function') {
                this.columnVisibility = updaterOrValue(this.columnVisibility);
            }
            else {
                this.columnVisibility = updaterOrValue;
            }
        },
        setScreenersVisibility(screenersUUIDs, visible) {
            var _a, _b;
            const columns = (_b = (_a = this.table) === null || _a === void 0 ? void 0 : _a.getAllColumns()) !== null && _b !== void 0 ? _b : [];
            screenersUUIDs.forEach((uuid) => {
                var _a;
                (_a = columns
                    .find((column) => column.id === uuid)) === null || _a === void 0 ? void 0 : _a.toggleVisibility(visible);
            });
        },
        areScreenersVisible(screenersUUIDs) {
            return screenersUUIDs.every((uuid) => this.columnVisibility[uuid]);
        },
        sorting: [],
        setSorting(updaterOrValue) {
            if (typeof updaterOrValue === 'function') {
                this.sorting = updaterOrValue(this.sorting);
            }
            else {
                this.sorting = updaterOrValue;
            }
        },
        clearSort() {
            this.sorting = [];
        },
        addSort({ id, desc = false }) {
            this.sorting.push({ id, desc });
        },
        removeSort(id) {
            const index = this.sorting.findIndex((column) => column.id === id);
            if (index !== -1) {
                this.sorting.splice(index, 1);
            }
        },
        updateSort(id, column) {
            const sort = this.sorting.find((sorting) => sorting.id === id);
            if (sort) {
                Object.assign(sort, column);
            }
        },
        filters: [],
        addFilter(filter) {
            this.filters.push(filter);
        },
        clearFilters() {
            this.filters = [];
            this.setLogicalOperator(DEFAULT_LOGICAL_OPERATOR);
        },
        updateFilter(id, newFilterSelection) {
            const filterSelection = this.filters.find((filter) => filter.id === id);
            if (filterSelection) {
                Object.assign(filterSelection, newFilterSelection);
            }
        },
        removeFilter(id) {
            const index = this.filters.findIndex((filter) => filter.id === id);
            if (index !== -1) {
                this.filters.splice(index, 1);
            }
        },
        logicalOperator: DEFAULT_LOGICAL_OPERATOR,
        setLogicalOperator(logicalOperator) {
            this.logicalOperator = logicalOperator;
        },
        projectData: null,
        userTestResults: [],
        networkStatus: NetworkStatus.loading,
        projectPk: '',
        columns: [],
        table: undefined,
        scrollableAreaElement: undefined,
        setScrollableAreaElement(el) {
            this.scrollableAreaElement = el;
        },
        scrollableAreaDimensions: undefined,
        setScrollableAreaDimensions(dimensions) {
            this.scrollableAreaDimensions = dimensions;
        },
        get columnDefinitionsMap() {
            return this.columns.reduce((acc, column) => {
                if (!column.id) {
                    return acc;
                }
                // eslint-disable-next-line no-param-reassign
                acc[column.id] = column;
                return acc;
            }, {});
        },
        get filterDefinitionsMap() {
            return getFilterDefinitions(this.columns).reduce((acc, filterDef) => {
                // eslint-disable-next-line no-param-reassign
                acc[filterDef.id] = filterDef;
                return acc;
            }, {});
        },
    }));
    usePersistContext({
        responsesContextState,
        defaultColumnVisibility,
        userTest,
    });
    return (_jsx(ResponsesContext.Provider, { value: responsesContextState, children: children }));
});
