import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { observer } from 'mobx-react';
import { useState } from 'react';
import * as React from 'react';
import { Heading, Icon, Text } from '@marvelapp/ballpark-components';
import { EYE_ICON } from '@marvelapp/ballpark-theme';
import { pluralize } from '@marvelapp/core';
import styled from '@marvelapp/styled';
import { TextEditor } from '@marvelapp/text-editor';
import { Box, Flex } from '@marvelapp/ui';
import { TextUtils } from '@marvelapp/user-test-creator';
import ZoomImageModal from '../../components/ZoomImageModal';
import ZoomModal from '../../components/ZoomModal';
import Bar from './Bar';
export const ChoiceBar = observer(function ChoiceBar({ image, participants, percent, tagline, title, screenerStatus, }) {
    const [isImageModalOpen, setIsImageModalOpen] = useState(false);
    const [isTaglineModalOpen, setIsTaglineModalOpen] = useState(false);
    const percentageNum = Number(percent);
    return (_jsxs(Choice, { "data-testid": `choice-summary-option-${title.replace(/ /g, '')}`, children: [image && (_jsx(ImageContainer, { onClick: () => setIsImageModalOpen(true), children: _jsx(Image, { alt: title, src: image }) })), tagline && (_jsx(ImageContainer, { onClick: () => setIsTaglineModalOpen(true), children: _jsx(Icon, { paths: EYE_ICON }) })), _jsxs(Box, { flex: 1, children: [_jsxs(ChoiceHeader, { children: [_jsxs(Flex, { alignItems: "center", children: [_jsx(Heading, { fontWeight: "medium", size: "m", maxWidth: "450px", children: title }), screenerStatus && (_jsxs(Text, { marginLeft: "xxs", size: "s", children: ["\u00B7 ", screenerStatus] })), _jsxs(Text, { marginLeft: "xxs", size: "s", children: ["\u00B7", ' ', Number.isInteger(percentageNum)
                                                ? percentageNum
                                                : percentageNum.toFixed(1), "%"] })] }), _jsxs(Text, { size: "s", children: [participants, " ", pluralize(participants, 'participant')] })] }), _jsx(Bar, { width: `${percent}%` })] }), image && (_jsx(ZoomImageModal, { image: image, image2x: image, name: title, isOpen: isImageModalOpen, onClose: () => setIsImageModalOpen(false) })), tagline && (_jsx(ZoomModal, { closeButtonTestID: "tagline-zoom-close-button", isOpen: isTaglineModalOpen, onClose: () => setIsTaglineModalOpen(false), children: _jsx(TaglineOptionContainer, { children: _jsxs("div", { children: [tagline.heading && (_jsx(Text, { testId: `${tagline.choiceUUID}-heading`, fontWeight: "600", fontSize: "xl", children: tagline.heading })), !TextUtils.checkIfValueIsEmpty(tagline.paragraph) && (_jsx(TextEditor, { disabled: true, id: `${tagline.choiceUUID}-paragraph`, size: "base", value: tagline.paragraph }))] }) }) }))] }));
});
const Choice = styled(Flex).attrs({
    marginBottom: 'm',
}) `
  align-items: center;
  &:last-child {
    margin-bottom: 0px;
  }
`;
const ChoiceHeader = styled(Flex).attrs({
    marginBottom: 'xs',
    alignItems: 'center',
    justifyContent: 'space-between',
}) ``;
const ImageContainer = styled(Box).attrs({
    border: '1px solid',
    borderColor: 'slate6',
    borderRadius: 's',
    boxShadow: 'button.secondary',
    hoverBg: 'slate3',
    marginRight: 's',
    transition: 'smooth.fast',
}) `
  cursor: pointer;
  max-width: 76px;
  min-width: 40px;
  aspect-ratio: 4 / 3;
  position: relative;

  display: flex;
  align-items: center;
  justify-content: center;

  @supports not (aspect-ratio: 4 / 3) {
    &::before {
      float: left;
      padding-top: 75%; /* 4:3 ratio */
      content: '';
    }

    &::after {
      display: block;
      content: '';
      clear: both;
    }
  }
`;
const Image = styled.img `
  display: block;
  max-width: 80%;
  max-height: 80%;
`;
const TaglineOptionContainer = styled(Box).attrs({
    bg: 'background',
    borderRadius: 'l',
    border: '1px solid',
    borderColor: 'slate6',
    paddingX: 'xl',
    paddingTop: 'xl',
    paddingBottom: 'xl',
    boxShadow: 'button.secondary',
}) `
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  text-align: left;
  max-width: 50%;
  min-width: 300px;
`;
