import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { MARK_BOLD, MARK_ITALIC, MARK_STRIKETHROUGH, } from '@udecode/plate-basic-marks';
import { useComposedRef, useEventEditorSelectors, usePlateSelectors, withRef, } from '@udecode/plate-common';
import { autoUpdate, flip, offset, useFloatingToolbar, useFloatingToolbarState, } from '@udecode/plate-floating';
import { ELEMENT_OL, ELEMENT_UL } from '@udecode/plate-list';
import { Stack, Toolbar } from '@marvelapp/ballpark-components';
import { BoldIcon, BulletListIcon, ItalicIcon, LinkIcon, NumberedListIcon, StrikethroughIcon, } from '@marvelapp/ballpark-icons';
import { ToolbarButton } from './ToolbarButton';
// Multiple text editors share the same toolbar, see
// https://github.com/udecode/plate/blob/main/docs/docs/guides/multiple-editors.mdx
export const TextEditorToolbar = withRef((_props, componentRef) => {
    const editorId = usePlateSelectors().id();
    const focusedEditorId = useEventEditorSelectors.focus();
    const floatingToolbarState = useFloatingToolbarState({
        editorId,
        focusedEditorId,
        floatingOptions: {
            whileElementsMounted: autoUpdate,
            middleware: [
                offset(12),
                flip({
                    fallbackPlacements: [
                        'top-start',
                        'top-end',
                        'bottom-start',
                        'bottom-end',
                    ],
                    padding: 12,
                }),
            ],
            placement: 'top',
        },
    });
    const { hidden, props: rootProps, ref: floatingRef, } = useFloatingToolbar(floatingToolbarState);
    const ref = useComposedRef(componentRef, floatingRef);
    if (hidden)
        return null;
    if (!focusedEditorId)
        return null;
    return (_jsx(Toolbar.Root, Object.assign({ className: "absolute z-50" }, rootProps, { "data-testid": "text-editor-toolbar", ref: ref, children: _jsxs(Stack, { direction: "row", gap: "1", children: [_jsx(ToolbarButton.Mark, { nodeType: MARK_BOLD, tooltip: "Bold (\u2318 + B)", "aria-label": "bold", icon: BoldIcon }), _jsx(ToolbarButton.Mark, { nodeType: MARK_ITALIC, tooltip: "Italic (\u2318 + I)", "aria-label": "Italic", icon: ItalicIcon }), _jsx(ToolbarButton.Mark, { nodeType: MARK_STRIKETHROUGH, tooltip: "Strikethrough  (\u2318 + SHIFT + S)", "aria-label": "Strikethrough", icon: StrikethroughIcon }), _jsx(ToolbarButton.List, { nodeType: ELEMENT_UL, tooltip: "Bulleted list", "data-testid": "unordered-list-button", icon: BulletListIcon }), _jsx(ToolbarButton.List, { nodeType: ELEMENT_OL, tooltip: "Numbered list", "data-testid": "ordered-list-button", icon: NumberedListIcon }), _jsx(ToolbarButton.Link, { tooltip: "Link", icon: LinkIcon, "data-testid": "link-button" })] }) })));
});
