import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { action } from 'mobx';
import { observer } from 'mobx-react';
import { useState } from 'react';
import * as React from 'react';
import { Icon, Popover } from '@marvelapp/ballpark-components';
import theme, { CAMERA_ICON, HEADSET_ICON, SCREEN_ICON, } from '@marvelapp/ballpark-theme';
import SlideControlButton from '../slides/common/SlideControlsButton';
const MediaSettings = observer(function MediaSettings({ buttonProps = {}, buttonText = 'Record video', icon = _jsx(Icon, { paths: CAMERA_ICON, width: 14, height: 14, marginRight: "xs" }), item, microphone, popoverPosition, screen, setMicrophone, setScreen, setWebcam, webcam, }) {
    const [isOpen, setIsOpen] = useState(false);
    const isActive = webcam || microphone || screen;
    return (_jsxs(Popover.Root, { open: isOpen, onOpenChange: () => setIsOpen(!isOpen), children: [_jsxs(SlideControlButton, Object.assign({ as: Popover.Trigger, "data-testid": "participant-media", isActive: isActive, isOpen: isOpen, position: "relative", size: "s" }, buttonProps, { children: [icon, buttonText] })), _jsx(Popover.Portal, { children: _jsx(Popover.Content, { "data-testid": "participant-media-settings", align: popoverPosition, zIndex: theme.zIndices.popover, children: _jsxs(Popover.Inner, { children: [_jsx(Popover.Toggle, { checked: webcam, description: `Ask the participant to enable their webcam whilst completing the ${item}`, icon: _jsx(Icon, { paths: CAMERA_ICON, width: 14, height: 14 }), id: "webcam-toggle", testId: "webcam-toggle", onChange: action(() => {
                                    setWebcam(!webcam);
                                    setMicrophone(!webcam);
                                }), title: "Webcam" }), _jsx(Popover.Toggle, { disabled: webcam, checked: microphone, description: `Ask the participant to enable their microphone whilst completing the ${item}`, icon: _jsx(Icon, { paths: HEADSET_ICON, height: 14, viewBox: "0 0 14 14", width: 14 }), id: "microphone-toggle", testId: "microphone-toggle", onChange: action(() => {
                                    if (!webcam) {
                                        setMicrophone(!microphone);
                                    }
                                }), title: "Microphone" }), _jsx(Popover.Toggle, { checked: screen, description: "Request permission to record the screen during the test (desktop only)", icon: _jsx(Icon, { paths: SCREEN_ICON, width: 14, height: 14, viewBox: "0 0 14 14" }), id: "screen-toggle", testId: "screen-toggle", onChange: action(() => setScreen(!screen)), title: "Screen recording" })] }) }) })] }));
});
export { MediaSettings };
