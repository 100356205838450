import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { observer } from 'mobx-react';
import { useCallback } from 'react';
import { useAction, useGetUserTest, useSlideUIContext, useUserTestAction, } from '@marvelapp/ballpark-application';
import { ScreenerIcon, Select, Stack, StepIcon, } from '@marvelapp/ballpark-components';
import { Box } from '@marvelapp/ui';
import { showErrorNotification } from '@marvelapp/ui-internal';
import { IdentityOperator, ScreenerType, ScreenerUtils, StepClass, StepUtils, getHasScreeners, } from '@marvelapp/user-test-creator';
import { useUIContext } from '../../pages/Project/uiContext';
import { ScreenerStep } from '../../types';
import { DraggableCards } from '../DraggableCards';
import { FixedCard } from '../FixedCard';
import { SectionDivider } from '../SectionDividerSidebar';
var SingleSelectLabels;
(function (SingleSelectLabels) {
    SingleSelectLabels["Accept"] = "Accept";
    SingleSelectLabels["Decline"] = "Decline";
})(SingleSelectLabels || (SingleSelectLabels = {}));
var MultipleSelectLabels;
(function (MultipleSelectLabels) {
    MultipleSelectLabels["Required"] = "Required";
    MultipleSelectLabels["CanSelect"] = "Can select";
    MultipleSelectLabels["Decline"] = "Decline";
})(MultipleSelectLabels || (MultipleSelectLabels = {}));
const ScreenerSelect = observer(function ScreenerSelect({ choiceUUID, isDisabled, }) {
    const { step } = useSlideUIContext();
    const handleRequirementChange = useAction((value) => {
        if (ScreenerUtils.isEqualityOperator(value) &&
            StepUtils.isOfType(step, StepClass.MultipleChoice)) {
            ScreenerUtils.updateEqualityOperator({
                mutable: step,
                equalityOperatorValue: value,
                choiceUUID,
            });
        }
    }, [choiceUUID, step]);
    if (!StepUtils.isOfType(step, StepClass.MultipleChoice))
        return null;
    const options = step.multipleSelectionMode
        ? [
            {
                value: IdentityOperator.IsOkay,
                label: `${MultipleSelectLabels.CanSelect}`,
                prefix: '✅',
            },
            {
                value: IdentityOperator.Is,
                label: `${MultipleSelectLabels.Required}`,
                prefix: '✅',
            },
            {
                value: IdentityOperator.IsNot,
                label: `${MultipleSelectLabels.Decline}`,
                prefix: '⛔️',
            },
        ]
        : [
            {
                value: IdentityOperator.Is,
                label: `${SingleSelectLabels.Accept}`,
                prefix: '✅',
            },
            {
                value: IdentityOperator.IsNot,
                label: `${SingleSelectLabels.Decline}`,
                prefix: '⛔️',
            },
        ];
    const requirement = ScreenerUtils.getRequirement({
        mutable: step,
        choiceUUID,
    });
    const value = options.find((option) => (requirement === null || requirement === void 0 ? void 0 : requirement.operator) === option.value);
    return (_jsxs(Select.Root, { disabled: isDisabled, value: value === null || value === void 0 ? void 0 : value.value, onValueChange: handleRequirementChange, children: [_jsx(Select.Trigger, { variant: "ghost", className: "focus-visible:ring-gray-600/20s h-[46px] w-full justify-between text-[15px] leading-5 shadow-sm outline-[3px] outline-offset-[5px] outline-transparent ring-1 ring-gray-600/20 transition-none hover:bg-white hover:outline hover:outline-gray-200 focus-visible:outline focus-visible:outline-[hsl(209,81.2%,84.5%)] disabled:bg-white disabled:ring-1 disabled:ring-gray-600/20", testId: "screener-select", children: _jsxs(Stack, { className: "flex flex-row gap-1.5", children: [_jsx("span", { children: value === null || value === void 0 ? void 0 : value.prefix }), _jsx("span", { children: (value === null || value === void 0 ? void 0 : value.label) || 'Select value…' })] }) }), _jsx(Select.Content, { children: options.map(({ label, value, prefix }) => (_jsx(Select.Item, { value: value, className: "glex-row flex gap-1.5", children: _jsxs(Stack, { className: "flex flex-row gap-1.5", children: [_jsx("span", { children: prefix }), _jsx("span", { children: label })] }) }, label))) })] }));
});
export default ScreenerSelect;
const ScreenersIntroStep = observer(function ScreenersIntroStep() {
    var _a, _b;
    const userTest = useGetUserTest();
    const { activeSlideUUID, setActiveSlideUUID } = useUIContext();
    const stepUUID = (_b = (_a = userTest.screeners) === null || _a === void 0 ? void 0 : _a.intro) === null || _b === void 0 ? void 0 : _b.uuid;
    if (!stepUUID)
        return null;
    return (_jsx(FixedCard, { activeSlideUUID: activeSlideUUID, getStepText: () => 'Screener welcome', getCardTypeIcon: () => (_jsx(ScreenerIcon, { size: "xs", type: ScreenerType.Introduction })), setActiveSlideUUID: setActiveSlideUUID, stepUUID: stepUUID, testId: `sidebar-step-${ScreenerStep.Intro}`, userTest: userTest }));
});
export const ScreenersSection = observer(function ScreenersSection() {
    const hasScreenerQuestions = getHasScreeners(useGetUserTest());
    if (!hasScreenerQuestions) {
        return null;
    }
    return (_jsxs(_Fragment, { children: [_jsx(SectionDivider, { copy: "Screeners" }), _jsxs(Box, { children: [_jsx(ScreenersIntroStep, {}), _jsx(ScreenerCards, {}), _jsx(ScreenersDeclineStep, {})] })] }));
});
const ScreenersDeclineStep = observer(function ScreenersDeclineStep() {
    var _a, _b;
    const userTest = useGetUserTest();
    const { activeSlideUUID, setActiveSlideUUID } = useUIContext();
    const stepUUID = (_b = (_a = userTest.screeners) === null || _a === void 0 ? void 0 : _a.decline) === null || _b === void 0 ? void 0 : _b.uuid;
    if (!stepUUID)
        return null;
    return (_jsx(FixedCard, { activeSlideUUID: activeSlideUUID, getStepText: () => 'Screener exit', getCardTypeIcon: () => (_jsx(ScreenerIcon, { size: "xs", type: ScreenerType.Declined })), setActiveSlideUUID: setActiveSlideUUID, stepUUID: stepUUID, testId: `sidebar-step-${ScreenerStep.Decline}`, userTest: userTest }));
});
const ScreenerCards = observer(function ScreenerCards() {
    const userTest = useGetUserTest();
    const cloneStep = useUserTestAction((step) => {
        var _a;
        const index = ScreenerUtils.getIndexFromAllScreenerSteps(userTest, step.uuid);
        if (((_a = userTest.screeners) === null || _a === void 0 ? void 0 : _a.steps) && userTest.screeners.steps.length >= 5) {
            showErrorNotification({
                content: 'You can only add up to 5 screener steps',
                toastId: 'maxScreenerWarning',
            });
        }
        ScreenerUtils.cloneScreenerStep(userTest, step, index);
    }, [userTest]);
    const deleteStep = useAction((step) => {
        ScreenerUtils.deleteScreenerStep(userTest, step.uuid);
    }, [userTest]);
    const getStepText = useCallback((userTest, step) => ScreenerUtils.getStepName(userTest, step.uuid), []);
    const stepsUUIDs = ScreenerUtils.getMiddleScreenerStepUUIDs(userTest);
    const getCardTypeIcon = () => (_jsx(StepIcon, { size: "xs", type: ScreenerType.Screener }));
    return (_jsx(DraggableCards, { stepsUUIDs: stepsUUIDs, onCloneStep: cloneStep, onDeleteStep: deleteStep, getStepText: getStepText, reorderFn: ScreenerUtils.reorderScreenerStep, getCardTypeIcon: getCardTypeIcon, testId: `sidebar-step-${ScreenerStep.Screener}`, showStepSettingsIcons: false }));
});
