import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { observer } from 'mobx-react';
import * as React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Badge, Heading } from '@marvelapp/ballpark-components';
import { formatStringOrMessage } from '@marvelapp/ballpark-copy';
import { AsteriskIcon } from '@marvelapp/ballpark-icons';
import styled from '@marvelapp/styled';
import { TextEditor } from '@marvelapp/text-editor';
import { Box, Flex } from '@marvelapp/ui';
import { StepUtils, TextUtils, getStepDescription, isExitStep, } from '@marvelapp/user-test-creator';
import { useRecorderState } from '../../../components/Record/RecorderContext';
import SlideInstructions from '../SlideInstructions';
import { getStepTitleCopy } from './utils';
/**
 * @deprecated use <RecordContent /> instead
 */
export const SlideRecordContent = observer(function SlideRecordContent({ isNarrow = false, withSlideBadges = true, step, customTitle, customDescription, hasError = false, }) {
    return (_jsxs(_Fragment, { children: [withSlideBadges && (_jsx(RequiredAndTallyBadge, { step: step, hasError: hasError })), _jsx(ContentTitle, { step: step, customTitle: customTitle }), _jsx("div", { children: _jsx(SlideInstructions, { step: step }) }), _jsx(ContentDescription, { isNarrow: isNarrow, step: step, customDescription: customDescription })] }));
});
const ContentTitle = observer(function ContentTitle({ step, customTitle, }) {
    const { userTestDoc } = useRecorderState();
    const intl = useIntl();
    return (_jsx(Flex, { marginTop: "s", marginBottom: "6px", maxWidth: 680, children: _jsx(Heading, { as: "h1", "data-testid": "recorder-slide-title", fontSize: [24, 24, 24, 28], lineHeight: "heading", wordBreak: "break-word", 
            // Preserves line breaks in the text, if there are any
            whiteSpace: "pre-line", children: customTitle ||
                formatStringOrMessage(intl, getStepTitleCopy(userTestDoc, step)) }) }));
});
const RequiredAndTallyBadge = observer(function RequiredAndTallyBadge({ step, hasError = false, }) {
    const { currentSlideNumber, userTestDoc, steps } = useRecorderState();
    const isRequired = StepUtils.getIsRequired(step);
    const totalSlidesNumber = steps.length;
    return (_jsxs(BadgeWrapper, { children: [!isExitStep(userTestDoc, step.uuid) && (_jsxs(Badge, { "data-testid": "slide-count", variant: hasError ? 'red' : 'sky', children: [currentSlideNumber, " / ", totalSlidesNumber - 1] })), isRequired && (_jsx(Badge, { variant: "red", "data-testid": "required-badge", className: "ml-2", leadingIcon: _jsx(AsteriskIcon, {}), size: "sm", children: _jsx(FormattedMessage, { id: 'Gjzr/5', defaultMessage: 'Required' }) }))] }));
});
const ContentDescription = observer(function ContentDescription({ isNarrow, step, customDescription, }) {
    const intl = useIntl();
    const description = getStepDescription(intl, step);
    const isDescriptionEmpty = TextUtils.checkIfValueIsEmpty(description);
    if (isDescriptionEmpty && !customDescription) {
        return null;
    }
    return (_jsx(Box, { marginBottom: "s", maxWidth: 680, children: _jsx(TextEditor, { size: "lg", id: `${step.uuid}-${isNarrow ? 'narrow' : 'wide'}-description`, testId: "recorder-slide-description", value: customDescription || description, disabled: true }) }));
});
const BadgeWrapper = styled(Box).attrs({
    marginBottom: 's',
}) `
  display: flex;
  align-items: center;
`;
