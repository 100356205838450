import { jsx as _jsx } from "react/jsx-runtime";
/* eslint-disable no-param-reassign */
import { useQuery } from '@apollo/client';
import { proxyOptions } from '@ballpark/realtime-crdt-mobx';
import { useOffline } from '@ballpark/realtime-plugin-offline';
import { useServer } from '@ballpark/realtime-plugin-server';
import { observer } from 'mobx-react';
import { useReducer } from 'react';
import { useMemoOne } from 'use-memo-one';
import { UserTestResponseUtils, validateResponseDoc, } from '@marvelapp/user-test-creator';
import { RecordWithLoading } from '../../components/Record/Record';
import { Persistence } from '../../docUtils/Persistence';
import { useTokenPermission } from '../../docUtils/useAuthToken';
import { GET_RECORDING_DOWNLOAD, GET_USER_TEST_DOC, } from '../../graphql/queries';
function useLoadUserTestFromGraphQL(testUUID, authToken, response) {
    var _a;
    const responseIsLoading = response === null;
    const responseHasUserTest = (response === null || response === void 0 ? void 0 : response.userTest) !== undefined;
    const { data, loading } = useQuery(GET_USER_TEST_DOC, {
        variables: { testUuid: testUUID, revision: 0, token: authToken },
        // skip if response has not yet loaded or if the response has loaded and it contains a UserTest
        // historically we loaded userTests separately to responses which is why it may be undefined but
        // recent responses contain a snapshot of the UserTest embedded for replayability.
        skip: responseIsLoading || responseHasUserTest,
    });
    const userTestData = (_a = data === null || data === void 0 ? void 0 : data.userTestDocument) === null || _a === void 0 ? void 0 : _a.document;
    // useMemoOne used as semantic guarantee required, otherwise UserTestDocument will be an entirely
    // new object everytime this runs and can cause re-renders of entire recording page as all step
    // definitions won't be referentially equal
    const userTestDoc = useMemoOne(() => (userTestData ? JSON.parse(userTestData) : undefined), [userTestData]);
    if (responseIsLoading) {
        return { isLoading: true, userTestDoc: undefined };
    }
    if (responseHasUserTest) {
        return { isLoading: false, userTestDoc: response.userTest };
    }
    return {
        isLoading: loading,
        userTestDoc,
    };
}
const RecordWithUserTest = observer(function RecordWithUserTest({ authToken, testUUID, response, children, partnerData, }) {
    var _a, _b;
    const permission = useTokenPermission();
    const server = useServer();
    const offline = useOffline();
    // check if offline has synced with DB and whether it had data (loaded an existing test)
    const offlineLoaded = offline.synced && ((_a = offline.document) === null || _a === void 0 ? void 0 : _a.store.clients.size) !== 0;
    // Important, we must wait for offline to sync before considering it loaded as the user may have
    // been offline when they completed the test, and we don't want to ask them to resume if the server
    // thinks we are in-progress but locally they have completed it.
    const isLoadingResponse = (offline.enabled && !offline.synced) || (!offlineLoaded && !server.synced);
    // We load a snapshot, stored in mySQL, of the user test document here.
    // This anticipates versioning of the user test document which we haven't
    // implemented yet. When we do, the RUS version of the document can be considered
    // a permanent draft document and versions will be snapshotted and saved to mySQL.
    const { userTestDoc, isLoading: isLoadingUserTest } = useLoadUserTestFromGraphQL(testUUID, authToken, isLoadingResponse ? null : response);
    const { data, loading: isFetchingRecording } = useQuery(GET_RECORDING_DOWNLOAD, {
        skip: !(response === null || response === void 0 ? void 0 : response.uuid),
        variables: { uuid: response === null || response === void 0 ? void 0 : response.uuid },
    });
    const downloadRecording = (_b = data === null || data === void 0 ? void 0 : data.userTestResult) === null || _b === void 0 ? void 0 : _b.recordings.download;
    return (_jsx(RecordWithLoading, { userTestDoc: userTestDoc !== null && userTestDoc !== void 0 ? userTestDoc : null, response: isLoadingResponse ? null : response, isLoading: isLoadingResponse || isLoadingUserTest || isFetchingRecording, permission: permission, partnerData: partnerData, downloadRecording: downloadRecording !== null && downloadRecording !== void 0 ? downloadRecording : null, children: children }));
});
export default observer(function RecordPage({ authToken, testUUID, responseUUID, children, restart, partnerData, }) {
    const [documentNumber, incrementDocumentNumber] = useReducer((x) => x + 1, 0);
    const response = useMemoOne(() => UserTestResponseUtils.createResponseDoc(undefined, proxyOptions), [responseUUID, documentNumber]);
    return (_jsx(Persistence, { document: response, id: responseUUID, parameters: { userTestUUID: testUUID }, validate: validateResponseDoc, onDeleted: restart, resetDocument: incrementDocumentNumber, children: _jsx(RecordWithUserTest, { testUUID: testUUID, response: response, authToken: authToken, partnerData: partnerData, children: children }) }));
});
