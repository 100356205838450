import { useAction } from '@marvelapp/ballpark-application';
import { useRecruitmentState } from './RecruitmentContext';
export function useUpdateSelectedFilterOptions(filterGroupId, filterId) {
    const { getFilterByGroupAndId, updateMultiSelectFilterSelection } = useRecruitmentState();
    return useAction((selectedOption) => {
        const filter = getFilterByGroupAndId(filterGroupId, filterId);
        if (!selectedOption)
            return null;
        updateMultiSelectFilterSelection({
            name: filter.name,
            id: filter.id,
            maxSelection: filter.maxSelection,
        }, selectedOption);
    }, [
        filterGroupId,
        filterId,
        getFilterByGroupAndId,
        updateMultiSelectFilterSelection,
    ]);
}
