import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
/* eslint-disable no-param-reassign */
import { AnimatePresence, motion, useReducedMotion } from 'framer-motion';
import LogRocket from 'logrocket';
import { when } from 'mobx';
import { observer } from 'mobx-react';
import * as React from 'react';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useAction, useFeatureFlag } from '@marvelapp/ballpark-application';
import { NotFound, Stack, cn } from '@marvelapp/ballpark-components';
import styled from '@marvelapp/styled';
import { Box, Loader } from '@marvelapp/ui';
import { ConditionalLogicUtils, ScreenerUtils, UserTestResponseUtils, getClassName, } from '@marvelapp/user-test-creator';
import { WholeTestRecordingProvider } from '../../WholeTestRecordingContext';
import { Permissions } from '../../docUtils/useAuthToken';
import { Localize } from '../../pages/Record/Localize';
import { PlayerStatus } from '../../slides/PrototypeTask/PrototypeRecord/types';
import SlideFooter from '../../slides/common/SlideFooter';
import { EnsureBrowserSupported } from '../BrowserCheck';
import { ParticipantRoom } from './ParticipantRoom';
import PreloadedPrototypeViewer from './PreloadedPrototypeViewer';
import { PrototypeStateProvider, usePrototypeState } from './PrototypeContext';
import { RecorderContextProvider, useRecorderState } from './RecorderContext';
import { SlideRenderer } from './SlideRenderer';
import { LiveSessionStatus, useLiveSessionStatus, } from './useLiveSessionStatus';
const variants = {
    initial: (direction) => {
        return {
            y: direction > 0 ? 1000 : -1000,
            opacity: 0,
        };
    },
    animate: {
        y: 0,
        opacity: 1,
    },
    exit: (direction) => {
        return {
            y: direction < 0 ? 1000 : -1000,
            opacity: 0,
        };
    },
};
const transition = {
    y: {
        type: 'spring',
        stiffness: 300,
        damping: 30,
    },
    opacity: {
        duration: 0.5,
    },
};
const Record = observer(function Record({ userTestDoc, response, isPreview = false, children, downloadRecording, }) {
    const isLiveSession = useLocation().pathname.includes('live');
    return (_jsx(Localize, { locale: userTestDoc.locale, children: _jsx(EnsureBrowserSupported, { children: _jsx(RecorderContextProvider, { response: response, userTestDoc: userTestDoc, isPreview: isPreview, downloadRecording: downloadRecording, children: _jsx(PrototypeStateProvider, { children: _jsx(WholeTestRecordingProvider, { children: isLiveSession ? (_jsxs(RecorderWrapper, { children: [_jsxs("div", { className: "relative h-full w-full overflow-hidden bg-white", children: [_jsxs(LiveSessionAnimationViewport, { className: "*:!w-full", children: [_jsx(SlideWithAnimation, {}), _jsx(LogrocketTrackEvents, { response: response }), _jsx(StartUserTest, {}), _jsx(PreloadedPrototypeViewer, {}), children] }), _jsx(SlideFooter, {})] }), _jsx(ParticipantRoom, {})] })) : (_jsxs(_Fragment, { children: [_jsxs(AnimationViewport, { children: [_jsx(SlideWithAnimation, {}), _jsx(LogrocketTrackEvents, { response: response }), _jsx(StartUserTest, {}), _jsx(PreloadedPrototypeViewer, {}), children] }), _jsx(SlideFooter, {})] })) }) }) }) }) }));
});
const RecorderWrapper = observer(function RecorderWrapper(props) {
    const { children } = props;
    const recorderState = useRecorderState();
    const setLiveSessionStatus = useAction((status) => {
        if (recorderState.isLive) {
            recorderState.liveSessionStatus = status;
        }
    }, [recorderState]);
    useLiveSessionStatus(setLiveSessionStatus);
    return (_jsx(Stack, { direction: "row", className: cn('h-screen w-screen ', {
            'gap-2 bg-gray-950 p-2 *:rounded-md': recorderState.liveSessionStatus === LiveSessionStatus.Recording,
        }), children: children }));
});
export const RecordWithLoading = observer(function RecordWithLoading({ children, userTestDoc, response, isLoading, isPreview, startingStep, permission, // permission passed in because it requires token and therefore Persistence to determine
partnerData, downloadRecording, }) {
    if (isLoading || !response || !userTestDoc) {
        return (_jsx(LoaderWrapper, { children: _jsx(Loader, {}) }));
    }
    const hasPermission = permission !== Permissions.None && permission !== Permissions.Unknown;
    if (!hasPermission) {
        return (_jsx(NotFound, { heading: "Permission denied", subheading: "You do not have sufficient permissions to access this document. Please contact the project owner or account admin for more info." }));
    }
    if (partnerData) {
        UserTestResponseUtils.addPartnerData(response, partnerData);
    }
    return (_jsx(Record, { isPreview: isPreview, startingStep: startingStep, userTestDoc: userTestDoc, response: response, downloadRecording: downloadRecording, children: children }));
});
const AnimationViewport = styled(Box) `
  height: 100%;
  width: 100vw;
  min-height: -webkit-fill-available;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
`;
const LiveSessionAnimationViewport = styled(Box) `
  height: 100%;
  width: 100%;
  min-height: -webkit-fill-available;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
`;
const MotionSlide = styled(motion(Box)).attrs((props) => ({
    bg: props.showDarkBg ? 'blackA12' : 'background',
    width: props.hasLiveSessions ? '100%' : '100vw',
})) `
  height: 100%;
  position: absolute;
`;
const LoaderWrapper = styled.div `
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;
const LogrocketTrackEvents = observer(function LogrocketTrackEvents({ response, }) {
    var _a;
    if ((_a = process.env.DEPLOY_ENV) === null || _a === void 0 ? void 0 : _a.includes('production'))
        return (_jsxs(_Fragment, { children: [_jsx(LogrocketTrackResponse, { response: response }), _jsx(LogrocketTrackConditionalLogic, {}), _jsx(LogrocketTrackScreeners, {})] }));
    return null;
});
const LogrocketTrackResponse = observer(function LogrocketTrackConditionalLogic({ response, }) {
    useEffect(() => {
        LogRocket.track('responseCreated', { responseUUID: response.uuid });
    }, [response.uuid]);
    return null;
});
const LogrocketTrackConditionalLogic = observer(function LogrocketTrackConditionalLogic() {
    const { steps } = useRecorderState();
    const hasConditionalLogicSet = ConditionalLogicUtils.getHasConditionalLogicSet(steps);
    useEffect(() => {
        if (hasConditionalLogicSet) {
            LogRocket.track('conditionalLogic');
        }
    }, [hasConditionalLogicSet]);
    return null;
});
const LogrocketTrackScreeners = observer(function LogrocketTrackScreeners() {
    const { userTestDoc } = useRecorderState();
    const hasScreenersSet = ScreenerUtils.isScreenersSet(userTestDoc);
    useEffect(() => {
        if (hasScreenersSet) {
            LogRocket.track('Screeners');
        }
    }, [hasScreenersSet]);
    return null;
});
const StartUserTest = observer(function StartUserTest() {
    const recorderState = useRecorderState();
    useEffect(() => when(() => !recorderState.response.startTimestamp, () => {
        var _a, _b;
        UserTestResponseUtils.setStartTimestamp(recorderState.response);
        if (recorderState.response.timeline.length === 0) {
            UserTestResponseUtils.addEventToTimeline(recorderState.response, (_b = (_a = recorderState.userTestDoc.screeners) === null || _a === void 0 ? void 0 : _a.intro.uuid) !== null && _b !== void 0 ? _b : recorderState.userTestDoc.welcomeStep.uuid);
        }
    }), [recorderState]);
    return null;
});
const SlideWithAnimation = observer(function SlideAnimation() {
    const recorderState = useRecorderState();
    const prototypeState = usePrototypeState();
    const shouldReduceMotion = useReducedMotion();
    const hasLiveSessions = useFeatureFlag('live-video');
    // AnimatePresence keeps the old slide around until the animation finishes.
    // We use `key` on MotionSlide to force create a new component. React when
    // using props does not send new props, to the component which is being unmounted,
    // because of the key change.
    // This doesn't play well with MobX which immediately re-renders the slide to look
    // like the new content and then slides in the replacement (which also looks like the new content).
    // Because the MobX state is mutable, we can't (easily) keep the old state around, hence
    // why we send `step` and `stepResponse` as props to SlideRenderer so that its static for
    // each instance of a slide
    return (_jsx(AnimatePresence, { custom: recorderState.paginationDirection, initial: false, children: _jsx(MotionSlide, { hasLiveSessions: hasLiveSessions, animate: !shouldReduceMotion ? 'animate' : undefined, custom: recorderState.paginationDirection, "data-testid": `${getClassName(recorderState.currentStep)}-slide-record`, "data-step-type": 'type' in recorderState.currentStep
                ? recorderState.currentStep.type
                : '', showDarkBg: prototypeState.isViewingMobileProtoOnIpad &&
                prototypeState.playerStatus === PlayerStatus.Recording, exit: "exit", initial: "initial", transition: !shouldReduceMotion ? transition : undefined, variants: !shouldReduceMotion ? variants : undefined, children: _jsx(SlideRenderer, { step: recorderState.currentStep, stepResponse: recorderState.currentStepResponse }) }, recorderState.currentStep.uuid) }));
});
