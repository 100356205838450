import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { Button } from '@marvelapp/ballpark-components';
import styled from '@marvelapp/styled';
import { AspectRatio, Box, Flex, Loader, PinBL, PinBR, PinCenter, } from '@marvelapp/ui';
import ArrowButton from '../../../components/ArrowButton';
import { DownloadHeatmap } from './DownloadHeatmap';
import FadeIn from './FadeIn';
import HeatmapImage from './HeatmapImage';
const PADDING = 20;
const CIRCLE_BUTTON_WIDTH = 30;
const SPACE_BUTTON_AND_CONTENT = 20;
export default observer(function HeatmapPlayer({ isLoading, hasNextPage, hasPreviousPage, setNextScreenCursor, screensSelected, openScreenPicker, setPreviousScreenCursor, screenWidth, screenHeight, screenDisplayName, imageUrl, coordinates, }) {
    const [heatmapImageData, setHeatmapImageData] = useState('');
    const isLoadingHeatmap = !heatmapImageData;
    const isLoadingAny = isLoading || isLoadingHeatmap;
    useEffect(() => {
        setHeatmapImageData('');
    }, [imageUrl, screenWidth, screenHeight, coordinates]);
    return (_jsx(Box, { width: "100%", children: _jsx(AspectRatio, { ratio: 16 / 9, children: _jsxs(Body, { children: [_jsxs(ImageHolder, { children: [isLoading ? (_jsx(Loader, { borderWidth: 4, size: 24 })) : (_jsx(HeatmapImage, { data: {
                                    max: 100,
                                    min: 0,
                                    data: coordinates,
                                }, isLoading: isLoadingAny, imageUrl: imageUrl, onLoad: (data) => setHeatmapImageData(data), sourceHeight: screenHeight, sourceWidth: screenWidth })), !isLoadingAny && coordinates.length === 0 && (_jsx(PinCenter, { children: _jsx(TextHolder, { "data-testid": "no-interactions", children: screensSelected
                                        ? 'This screen has no interactions yet.'
                                        : 'No responses selected.' }) }))] }), _jsx(ArrowButtonHolder, { left: PADDING, children: _jsx(ArrowButton, { id: "previous-heatmap", isDisabled: !hasPreviousPage, onClick: setPreviousScreenCursor, direction: "left" }) }), _jsx(ArrowButtonHolder, { right: PADDING, children: _jsx(ArrowButton, { id: "next-heatmap", isDisabled: !hasNextPage, onClick: setNextScreenCursor, direction: "right" }) }), _jsx(PinBR, { padding: PADDING, children: _jsx(Button, { "data-testid": "select-screen-heatmaps", kind: "ghost", onClick: openScreenPicker, size: "s", type: "button", children: "Select a screen" }) }), _jsxs(PinBL, { display: "flex", alignItems: "center", padding: PADDING, children: [_jsx(DownloadHeatmap, { screenDisplayName: screenDisplayName, heatmapDataUri: heatmapImageData }), !isLoadingAny && (_jsx(FadeIn, { animate: true, children: _jsx(TextHolder, { "data-testid": "heatmap-screen-name", children: screenDisplayName }) }))] })] }) }) }));
});
const ArrowButtonHolder = styled(Box) `
  margin-top: -15px;
  position: absolute;
  top: 50%;
`;
const ImageHolder = styled.div `
  display: flex;
  align-items: center;
  justify-content: center;

  position: absolute;
  top: ${PADDING}px;
  bottom: ${PADDING}px;
  left: ${PADDING + SPACE_BUTTON_AND_CONTENT + CIRCLE_BUTTON_WIDTH}px;
  right: ${PADDING + SPACE_BUTTON_AND_CONTENT + CIRCLE_BUTTON_WIDTH}px;
`;
const Body = styled(Flex).attrs({
    bg: 'slate2',
    border: '1px solid',
    borderBottomLeftRadius: 'm',
    borderBottomRightRadius: 'm',
    borderColor: 'slate6',
}) `
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100%;
  height: 100%;
`;
const TextHolder = styled(Box).attrs({
    bg: 'blackA10',
    borderRadius: 's',
    marginLeft: 'xs',
    color: 'whiteA10',
    fontSize: 'xs',
    fontWeight: 'medium',
}) `
  padding: 0 12px;
  height: 28px;
  line-height: 28px;
`;
