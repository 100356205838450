import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { observer } from 'mobx-react';
import { Badge, BtwText, BtwTooltip, Stack, } from '@marvelapp/ballpark-components';
import { TextUtils } from '@marvelapp/user-test-creator';
export const TaglineCopyCell = observer(function TaglineCopyCell({ values, step, }) {
    return (_jsx(Stack, { direction: "row", wrap: "wrap", gap: "2", children: values.map((value) => {
            const choice = step.choiceDefinitions[value];
            // the choice may have been deleted out of the user test
            if (!choice)
                return null;
            const { heading, paragraph } = choice;
            const index = step.choices.indexOf(value) + 1;
            return (_jsxs(BtwTooltip.Root, { children: [_jsx(BtwTooltip.Trigger, { className: `${!TextUtils.checkIfValueIsEmpty(paragraph) && 'cursor-default'}`, children: _jsx(Badge, { className: "max-w-80 bg-gray-200/50 text-gray-500", children: _jsx("span", { className: "truncate", "data-testid": "tagline-cell-heading", children: heading || `Option ${index}` }) }) }), _jsx(BtwTooltip.Content, { className: "max-w-96 overflow-hidden bg-white px-4 py-3 shadow-md ring-1 ring-gray-600/10", side: "bottom", align: "start", asChild: true, collisionPadding: 24, children: _jsxs(Stack, { gap: "0.5", children: [_jsx(BtwText, { variant: "primary", size: "sm", weight: "medium", children: heading || `Option ${index}` }), !TextUtils.checkIfValueIsEmpty(paragraph) && (_jsx(BtwText, { size: "sm", weight: "normal", children: TextUtils.richTextToString(paragraph) }))] }) })] }, value));
        }) }));
});
