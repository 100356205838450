import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { observer } from 'mobx-react';
import { forwardRef, useState } from 'react';
import { FooterReady, FooterRecording } from './Footer';
import RecordVideoHeader from './RecordVideoHeader';
import Video from './Video';
const RecordingPreview = observer(forwardRef(function RecordingPreview(props, ref) {
    const { isAcquiringMedia, isRecording, isRecordingStarting, isStopping, onRecord, onStopRecording, onUpdateVideoDeviceId, onUpdateAudioDeviceId, mediaStream, 
    // We're disabling audio only for now, as it's not stable enough
    // TODO: To be re-enabled soon
    // /cc https://marvelapp.slack.com/archives/C03PSMNST/p1637759353370400
    // onToggleVideoClick,
    videoEnabled, } = props;
    // We don't care about supporting legacy callbacks-bases refs
    const mediaRef = ref;
    const [isLoading, setIsLoading] = useState(true);
    return (_jsxs(_Fragment, { children: [!isLoading && _jsx(RecordVideoHeader, {}), _jsx(Video, { isLoading: isLoading, isRecording: isRecording, onCanPlay: () => setIsLoading(false), onLimitReached: onStopRecording, ref: mediaRef, videoEnabled: videoEnabled }), !isRecording && !isStopping && (_jsx(FooterReady, { isAcquiringMedia: isAcquiringMedia, isLoading: isLoading, isRecordingStarting: isRecordingStarting, onRecord: onRecord, onUpdateVideoDeviceId: onUpdateVideoDeviceId, onUpdateAudioDeviceId: onUpdateAudioDeviceId, mediaStream: mediaStream })), isRecording && (_jsx(FooterRecording, { isRecording: isRecording, isLoading: isLoading, onStopRecording: onStopRecording }))] }));
}));
export default RecordingPreview;
