import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { AnimatePresence } from 'framer-motion';
import { observer } from 'mobx-react';
import { Suspense, useEffect } from 'react';
import { useAction } from '@marvelapp/ballpark-application';
import { Badge, ModalOverlay, Text } from '@marvelapp/ballpark-components';
import { EventOutcomeType, deviceDetection } from '@marvelapp/core';
import styled from '@marvelapp/styled';
import { Box, Loader, PinAll, PinCenter } from '@marvelapp/ui';
import { PrototypeStepType, PrototypeTaskResponseUtils, PrototypeTaskUtils, StepUtils, } from '@marvelapp/user-test-creator';
import { FigmaStatus, PlayerStatus, } from '../../slides/PrototypeTask/PrototypeRecord/types';
import { usePrototypeState } from '../Record/PrototypeContext';
import { useRecorderState } from '../Record/RecorderContext';
import CachedPrototypeViewer from './CachedPrototypeViewer';
export default observer(function PrototypeViewerWrapper({ step, stepResponse, }) {
    var _a;
    const recorderState = useRecorderState();
    const prototypeState = usePrototypeState();
    useEffect(() => {
        prototypeState.checkEmbedPermissions();
    }, [prototypeState]);
    const onFigmaLoading = useAction(() => {
        prototypeState.figmaStatus = FigmaStatus.IframeSrcLoaded;
    }, [prototypeState]);
    const setReady = useAction(() => {
        var _a, _b;
        const isMarvelPrototype = ((_a = prototypeState.externalPrototypeUrl) === null || _a === void 0 ? void 0 : _a.indexOf('marvelapp.com')) !== -1;
        if (isMarvelPrototype && step.startScreen) {
            (_b = prototypeState.prototypeViewerEl) === null || _b === void 0 ? void 0 : _b.showScreen(step.startScreen);
        }
        prototypeState.playerStatus = PlayerStatus.Ready;
        prototypeState.figmaStatus = FigmaStatus.PrototypeLoaded;
    }, [prototypeState, step]);
    const onReachedGoalScreen = useAction(() => {
        prototypeState.hasReachedGoalScreenOrDoneFirstClick = true;
        if (!deviceDetection.isMobile)
            prototypeState.isPrototypeFullWidth = false;
    }, [prototypeState]);
    const onMadeFirstClick = useAction(() => {
        prototypeState.playerStatus = PlayerStatus.Stopped;
        prototypeState.hasReachedGoalScreenOrDoneFirstClick = true;
        setTimeout(recorderState.goForward, 500);
    }, [prototypeState, recorderState]);
    const handleEvent = useAction((event) => {
        PrototypeTaskResponseUtils.addEvent(stepResponse, event);
        const hitGoalScreen = event.outcome.type === EventOutcomeType.GoalReached;
        const madeFirstClick = StepUtils.isOfPrototypeSubType(step, PrototypeStepType.FirstClick) &&
            event.outcome.type === EventOutcomeType.Miss &&
            event.trigger.type === 'tap';
        if (madeFirstClick) {
            onMadeFirstClick();
        }
        else if (hitGoalScreen) {
            onReachedGoalScreen();
        }
    }, [stepResponse, step, onMadeFirstClick, onReachedGoalScreen]);
    const widthWhenFullScreen = prototypeState.isViewingMobileProtoOnIpad
        ? '50%'
        : '100%';
    const twoThirds = 1.5;
    const widthOfViewer = prototypeState.isPrototypeFullWidth
        ? widthWhenFullScreen
        : `calc(100% / ${twoThirds})`;
    const showOverlay = deviceDetection.isDesktop &&
        (shouldBlockPlayerInteraction(prototypeState.playerStatus) ||
            prototypeState.hasReachedGoalScreenOrDoneFirstClick);
    const isViewerHidden = recorderState.currentStep.uuid !==
        ((_a = recorderState.nextPrototypeTaskStep) === null || _a === void 0 ? void 0 : _a.uuid) ||
        // On mobile the player should never be visible unless you're recording This
        // fixes
        // https://linear.app/ballpark/issue/BALL-784/the-figma-warning-blocks-the-ui-on-mobile-single-step
        // (I think the issue was that the player was still layered over the
        // instructions on mobile and had pointerEvents: none applied while the
        // Figma iframe was loading -- this blocked clicks on, eg, the button to
        // enable camera share. I don't quite get why the issue didn't persist after
        // Figma started loading but this fixes it so that the instructions are
        // never blocked by the Figma's iframe. At some point we really need to
        // refactor this step to remove all the hacks for mobile and use responsive
        // design instead)
        (deviceDetection.isMobile &&
            prototypeState.playerStatus !== PlayerStatus.Recording);
    const setPrototypeViewerEl = useAction((node) => {
        prototypeState.prototypeViewerEl = node;
    }, [prototypeState]);
    return (_jsxs(PrototypeVisibilityContainer, { isFirstClick: PrototypeTaskUtils.isFirstClick(step), isViewingMobileProtoOnIpad: prototypeState.isViewingMobileProtoOnIpad, widthOfViewer: widthOfViewer, "data-testid": "viewer", isViewerHidden: isViewerHidden, 
        // this controls the size of the viewer. If the sidebar/controls are open
        // it will take up 2/3rds of the screen space. Otherwise, it will be 100% wide.
        isPrototypeFullWidth: prototypeState.isPrototypeFullWidth, arePointerEventsDisabled: prototypeState.pointerEventsDisabled, playerStatus: prototypeState.playerStatus, figmaStatus: prototypeState.figmaStatus, onClickCapture: showOverlay
            ? (e) => {
                e.preventDefault();
                e.stopPropagation();
            }
            : undefined, children: [_jsx(Suspense, { fallback: _jsx(PinCenter, { children: _jsx(Loader, {}) }), children: _jsx(CachedPrototypeViewer, { handleEvent: handleEvent, onReady: setReady, onLoading: onFigmaLoading, ref: setPrototypeViewerEl, isFirstClick: PrototypeTaskUtils.isFirstClick(step) }) }), _jsx(AnimatePresence, { children: showOverlay && (_jsx(ModalOverlay, { children: prototypeState.playerStatus ===
                        PlayerStatus.ConnectingToVideoServer && (_jsxs(PinAll, { children: [_jsx(PinAll, { bg: "snow", opacity: "0.8" }), _jsx(PinCenter, { display: "flex", alignItems: "center", children: _jsx(Badge, { leadingIcon: _jsx(Loader, { size: "15px" }), children: _jsx(Text, { kind: "primary", ml: "xs", children: "Preparing task" }) }) })] })) })) })] }));
});
const PrototypeVisibilityContainer = styled(Box) `
  ${(props) => props.isViewerHidden && 'visibility: hidden; opacity: 0;'}
  ${(props) => !props.isPrototypeFullWidth &&
    (deviceDetection.isMobile || props.isFirstClick) &&
    'opacity: 0;'}
  height: ${(props) => (props.isViewingMobileProtoOnIpad ? '75%' : '100%')};
  width: ${(props) => props.widthOfViewer};
  position: absolute;
  ${(props) => !props.isViewingMobileProtoOnIpad && 'right: 0;'}
  pointer-events: ${(props) => 
// on mobile, viewer clicks still register, even with the instructions screen layered over it
// this disables those pointer events until the user clicks 'start'
// we also disable pointer events when user is dragging the instructions modal
props.arePointerEventsDisabled ||
    shouldBlockPlayerInteraction(props.playerStatus)
    ? 'none'
    : 'auto'};
`;
function shouldBlockPlayerInteraction(playerStatus) {
    const allowInteraction = 
    // allow interaction when the player is _loading_ so that the user can log
    // in to figma where prototypes require authentication
    playerStatus === PlayerStatus.Loading ||
        playerStatus === PlayerStatus.Recording;
    return !allowInteraction;
}
