import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { observer } from 'mobx-react';
import { NavLink } from 'react-router-dom';
import { BtwButton, BtwText, Stack } from '@marvelapp/ballpark-components';
import { StudyTypeEnum } from '../../../__generated__/queryTypes';
import { ProceedToCheckout } from '../ProceedToCheckout';
import { useRecruitmentState } from '../RecruitmentContext';
export const OrderSummaryFooter = observer(function OrderSummaryFooter() {
    var _a;
    const { currentBalance, hasEnoughEligibleParticipants, eligibilityDetails, hasEnoughCredits, studyType, } = useRecruitmentState();
    const eligibilityCountError = (_a = eligibilityDetails === null || eligibilityDetails === void 0 ? void 0 : eligibilityDetails.error) !== null && _a !== void 0 ? _a : null;
    if (!hasEnoughCredits) {
        const message = currentBalance === 0
            ? 'You don’t have any credits. You need to top up your balance before submitting an order. Don’t worry, we’ll save your order details.'
            : 'You don’t have enough credits for this recruitment order. You can reduce the number of responses or buy more credits. Don’t worry, we’ll save your order details.';
        return (_jsxs(_Fragment, { children: [_jsx(BtwText, { "data-testid": "not-enough-credits-message", size: "xs", variant: "primary", children: message }), _jsx(BtwButton, { asChild: true, "data-testid": "recruitment-bundles-link", type: "submit", variant: "secondary", width: "full", size: "intermediate", children: _jsx(NavLink, { to: {
                            pathname: '/recruitment-bundles',
                            state: { origin: window.location.pathname },
                        }, children: "Buy more credits" }) })] }));
    }
    return (_jsxs(Stack, { width: "full", gap: "3", children: [_jsx(ProceedToCheckout, { disabled: studyType === StudyTypeEnum.SURVEY &&
                    // If there is eligibility count error, it means we don't know what the count is
                    // and therefore can't determine if there are enough participants. We'd rather allow
                    // the user to proceed to checkout than block, since the create order mutation will fail
                    // if there are not enough participants
                    !hasEnoughEligibleParticipants &&
                    !eligibilityCountError }), _jsx(BtwButton, { asChild: true, "data-testid": "recruitment-bundles-link", type: "submit", variant: "secondary", width: "full", size: "intermediate", children: _jsx(NavLink, { to: {
                        pathname: '/recruitment-bundles',
                        state: { origin: window.location.pathname },
                    }, children: "Buy more credits" }) })] }));
});
