import { jsx as _jsx } from "react/jsx-runtime";
import { observer } from 'mobx-react';
import * as React from 'react';
import { useGetUserTest, useSlideUIContext, } from '@marvelapp/ballpark-application';
import { ScreenerUtils, StepClass, StepUtils, isExitStep, } from '@marvelapp/user-test-creator';
import { CardSortingStepSettings } from '../CardSorting/builder/CardSortingStepSettings';
import ExitDotMenu from '../Exit/ExitDotMenu';
import { FiveSecondStepSettings } from '../FiveSecond/FiveSecondEdit';
import { RatingStepSettings } from '../Rate/RateEdit';
import ChoiceSettings from './ChoiceSettings';
import { StepOptions } from './Options';
export default observer(function SlideControlSettings() {
    const userTest = useGetUserTest();
    const { step } = useSlideUIContext();
    const isExitSlide = isExitStep(userTest, step.uuid);
    // TODO really these settings components should be passed in from a parent
    // but we can't do that due to how the components are currently structured.
    // Ideally SlideEdit would take an 'options' prop so you'd have something
    // like:
    //
    //  --- MultipleChoiceEdit.tsx ---
    //  function MultipleChoiceEdit() {
    //    /* ... */
    //    return (
    //      <SlideEdit
    //        options={
    //          <ChoiceSettings />
    //        }
    //      />
    //    )
    //  }
    //
    // but currently SlideEdit renders MultipleChoiceEdit as a child which is
    // the wrong way around if we wanted to do the above. (It's tricky because
    // if SlideEdit initially takes no props then it's natural to want to hoist
    // it into the parent)
    // Also note that each settings component renders the `Options` wrapper
    // component to allow components to pass a callback to be called when the
    // popover state changes (currently only used by the ExitDotMenu component)
    if (StepUtils.isOneOfType(step, [
        StepClass.PreferenceTest,
        StepClass.TaglineCopyTest,
        StepClass.MultipleChoice,
    ])) {
        return (_jsx(ChoiceSettings, { step: step, hasMultipleSelectionSetting: true, hasRandomizeSetting: true, hasOtherChoiceSetting: StepUtils.isOfType(step, StepClass.MultipleChoice), shouldShowRequiredSetting: !ScreenerUtils.isScreenerStep(userTest, step.uuid) }));
    }
    if (StepUtils.isOfType(step, StepClass.RatingScale)) {
        return _jsx(RatingStepSettings, {});
    }
    if (StepUtils.isOfType(step, StepClass.FiveSecondTest)) {
        return _jsx(FiveSecondStepSettings, {});
    }
    if (StepUtils.isCardSortingStep(step)) {
        return _jsx(CardSortingStepSettings, {});
    }
    if (isExitSlide) {
        return _jsx(ExitDotMenu, {});
    }
    return _jsx(StepOptions, {});
});
