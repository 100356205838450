import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { observer } from 'mobx-react';
import { useCallback, useMemo } from 'react';
import { CardSortingSimilarityTable } from '@marvelapp/ballpark-components';
import { useMetricsContext } from './metricsContext';
function sortString(a, b) {
    return a.localeCompare(b);
}
/**
 * To generate the similarity matrix, we start with the card pair with the
 * highest similarity and place them at the top left. Let's say [A, B] is the
 * most similar pair, with a similarity value of 100 (meaning they were always
 * paired)
 *
 * Then the matrix starts with:
 *
 *   | A   |
 *   | 100 | B
 *
 * We then find the card that is most similar to B, but not A. Let's say it's C
 * with a similarity value of 90. We then add C to the matrix:
 *
 *   | A   |
 *   | 100 | B   |
 *   |  40 |  90 | C
 *
 *  40 is the similarity value between A and C.
 *
 *  We continue this process until all cards are in the matrix.
 */
export const SimilarityMatrix = observer(function SimilarityMatrix() {
    const { cardSimilarity: cardSimilarityFormatted, cardUUIDToCardMap } = useMetricsContext();
    const sortCardUuids = useCallback((a, b) => {
        var _a, _b;
        return sortString((_a = cardUUIDToCardMap[a].title) !== null && _a !== void 0 ? _a : '', (_b = cardUUIDToCardMap[b].title) !== null && _b !== void 0 ? _b : '');
    }, [cardUUIDToCardMap]);
    const cardSimilarity = useMemo(() => {
        return (cardSimilarityFormatted
            // Filter out similarity pairs where either UUID doesn't exist in the map
            .filter((similarity) => similarity.cardUuids.every((uuid) => uuid in cardUUIDToCardMap && cardUUIDToCardMap[uuid] !== null))
            .map((similarity) => {
            return {
                cardUuids: [...similarity.cardUuids].sort(sortCardUuids),
                value: Number(similarity.value),
            };
        }));
    }, [cardSimilarityFormatted, sortCardUuids, cardUUIDToCardMap]);
    const cardSimilarityMap = useMemo(() => {
        const similarityMap = {};
        cardSimilarity.forEach((similarity) => {
            const cardA = similarity.cardUuids[0];
            const cardB = similarity.cardUuids[1];
            if (!similarityMap[cardA]) {
                similarityMap[cardA] = {};
            }
            similarityMap[cardA][cardB] = similarity.value;
            if (!similarityMap[cardB]) {
                similarityMap[cardB] = {};
            }
            similarityMap[cardB][cardA] = similarity.value;
        });
        return similarityMap;
    }, [cardSimilarity]);
    const sortedCardSimilarity = useMemo(() => {
        return [...cardSimilarity].sort((a, b) => {
            // sort by similarity value
            if (a.value !== b.value) {
                return b.value - a.value;
            }
            // if similarity value is the same sort by card titles
            return sortCardUuids(a.cardUuids[0], b.cardUuids[0]);
        });
    }, [cardSimilarity, sortCardUuids]);
    const findHighestSimilarity = useCallback((previousCards) => {
        const best = cardSimilarity.reduce((acc, curr) => {
            const countOfPreviousCards = curr.cardUuids.filter((card) => {
                return previousCards.includes(card);
            }).length;
            if (countOfPreviousCards !== 1) {
                return acc;
            }
            const otherCard = previousCards.includes(curr.cardUuids[0])
                ? curr.cardUuids[1]
                : curr.cardUuids[0];
            if (!acc.card && curr.value === 0) {
                return { card: otherCard, value: curr.value };
            }
            if (curr.value === acc.value) {
                // sort matching similarity values by card title
                const sortedSameValue = [acc.card, otherCard].sort(sortCardUuids)[0];
                return { card: sortedSameValue, value: acc.value };
            }
            return curr.value > acc.value
                ? { card: otherCard, value: curr.value }
                : acc;
        }, { card: '', value: 0 });
        return best.card;
    }, [cardSimilarity, sortCardUuids]);
    const similarityMatrix = useMemo(() => {
        if (sortedCardSimilarity.length === 0) {
            return [];
        }
        const topPair = sortedCardSimilarity[0].cardUuids;
        const topCard = [...topPair].sort(sortCardUuids)[0];
        const matrix = [
            {
                similarity: [],
                card: topCard,
            },
        ];
        const numberOfCards = Object.keys(cardUUIDToCardMap).length;
        while (matrix.length < numberOfCards) {
            const previousCards = matrix.map((item) => item.card);
            // find most similar card to previous row card
            const bestCard = findHighestSimilarity(previousCards);
            // fill in the current row
            const similarities = previousCards.map((previousCard) => {
                return cardSimilarityMap[bestCard][previousCard];
            });
            matrix.push({
                similarity: similarities,
                card: bestCard,
            });
        }
        return matrix;
    }, [
        sortedCardSimilarity,
        sortCardUuids,
        cardUUIDToCardMap,
        findHighestSimilarity,
        cardSimilarityMap,
    ]);
    return (_jsx(_Fragment, { children: similarityMatrix.map((item, index) => {
            var _a;
            const currentCard = cardUUIDToCardMap[item.card];
            return (_jsx(CardSortingSimilarityTable.Row
            // eslint-disable-next-line react/no-array-index-key
            , { row: index, similarity: item.similarity, title: (_a = currentCard.title) !== null && _a !== void 0 ? _a : '', imageSrc: currentCard.imageUrl }, index));
        }) }));
});
