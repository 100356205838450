import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { groupBy } from 'lodash-es';
import { observer } from 'mobx-react';
import { useMemo } from 'react';
import * as React from 'react';
import { useIntl } from 'react-intl';
import { useGetUserTest } from '@marvelapp/ballpark-application';
import { Badge, BtwButton, BtwPopover, BtwText, BtwToggle, Stack, baseButtonStyles, cn, ghostButtonStyles, } from '@marvelapp/ballpark-components';
import { formatStringOrMessage, getStepName, getStepTitle, } from '@marvelapp/ballpark-copy';
import { ChevronRightIcon, EyeOffIcon, LayoutColumnIcon, } from '@marvelapp/ballpark-icons';
import { ScreenerUtils, getStepByUUID, } from '@marvelapp/user-test-creator';
import { useResponsesContext } from './ResponsesContext';
import { TruncatedTextContainer } from './TruncatedTextContainer';
import { groupColumn } from './columnUtils';
import { getDefaultColumnVisibility } from './stepUtils';
export const ColumnVisibilityPopover = observer(function ColumnVisibilityPopover() {
    var _a, _b;
    const { table, totalResults } = useResponsesContext();
    const userTest = useGetUserTest();
    const allColumns = useMemo(() => {
        var _a;
        return (_a = table === null || table === void 0 ? void 0 : table.getAllColumns()) !== null && _a !== void 0 ? _a : [];
    }, [table]);
    const allHidableColumns = useMemo(() => {
        var _a;
        return (_a = allColumns === null || allColumns === void 0 ? void 0 : allColumns.filter((column) => column.getCanHide())) !== null && _a !== void 0 ? _a : [];
    }, [allColumns]);
    let hiddenColumnsCount;
    // screeners & recruitment IDs should not be included in the hidden column count
    const allColsExcludingDefaultHidden = allColumns.filter((column) => {
        var _a;
        return (!((_a = userTest.screeners) === null || _a === void 0 ? void 0 : _a.steps.includes(column.id)) &&
            column.id !== 'recruitment_studyId' &&
            column.id !== 'recruitment_participantId');
    });
    if (allColsExcludingDefaultHidden) {
        hiddenColumnsCount =
            (allColsExcludingDefaultHidden === null || allColsExcludingDefaultHidden === void 0 ? void 0 : allColsExcludingDefaultHidden.length) -
                ((_b = (_a = table === null || table === void 0 ? void 0 : table.getVisibleFlatColumns().filter((column) => {
                    var _a;
                    // exclude default hidden columns from count
                    return (!((_a = userTest.screeners) === null || _a === void 0 ? void 0 : _a.steps.includes(column.id)) &&
                        column.id !== 'recruitment_studyId' &&
                        column.id !== 'recruitment_participantId');
                })) === null || _a === void 0 ? void 0 : _a.length) !== null && _b !== void 0 ? _b : 0);
    }
    const groupedColumns = useMemo(() => {
        return groupBy(allHidableColumns, (column) => {
            var _a;
            return groupColumn(((_a = column.columnDef.meta) === null || _a === void 0 ? void 0 : _a.stepUUID) || column.columnDef.id, userTest);
        });
    }, [allHidableColumns, userTest]);
    if (!groupedColumns)
        return null;
    return (_jsxs(BtwPopover.Root, { children: [_jsx(BtwPopover.Trigger, { asChild: true, children: _jsxs(BtwButton, { "aria-label": "Column Visibility", leadingIcon: _jsx(LayoutColumnIcon, {}), variant: "ghost", disabled: totalResults === 0, "data-testid": "column-visibility", size: "intermediate", children: ["Columns", !!hiddenColumnsCount && (_jsx(Badge, { size: "sm", className: "min-w-[18px]", leadingIcon: _jsx(EyeOffIcon, {}), "data-testid": "hidden-column-count", children: hiddenColumnsCount }))] }) }), _jsx(BtwPopover.Content, { collisionPadding: 16, "data-testid": "column-vis-popover", children: _jsxs(Stack, { className: "max-h-[--radix-popover-content-available-height] w-96 ", align: "stretch", children: [_jsx(Stack, { width: "full", className: "max-h-full divide-y divide-gray-600/10 overflow-y-scroll p-2.5", children: _jsx(GroupsContainer, { groupedColumns: groupedColumns }) }), _jsx(ColumnVisibilityPopoverFooter, {})] }) })] }));
});
const GroupsContainer = observer(function GroupsContainer(props) {
    const { groupedColumns } = props;
    return (_jsx(_Fragment, { children: Object.entries(groupedColumns).map(([groupName, columns]) => (_jsxs("div", { className: "w-full pt-2 [&:not(:first-child)]:pt-4 [&:not(:last-child)]:pb-2.5", "data-testid": `group-${groupName}`, children: [_jsx(BtwText, { size: "xxs", weight: "bold", className: "px-3 pb-1 uppercase", children: groupName }), columns.map((column) => {
                    var _a;
                    return (_jsx(ColumnItem, { column: column }, ((_a = column.columnDef.meta) === null || _a === void 0 ? void 0 : _a.stepUUID) || column.columnDef.id));
                })] }, groupName))) }));
});
const ColumnItem = observer(function ColumnItem(props) {
    var _a, _b, _c;
    const { column } = props;
    const intl = useIntl();
    const userTest = useGetUserTest();
    const stepUUID = (_a = column.columnDef.meta) === null || _a === void 0 ? void 0 : _a.stepUUID;
    const { title, name } = getStepData(stepUUID, userTest);
    const subType = (_c = (_b = column.columnDef.meta) === null || _b === void 0 ? void 0 : _b.visibility) === null || _c === void 0 ? void 0 : _c.name;
    const columnName = column.columnDef.header;
    const handleClick = () => {
        column.toggleVisibility();
    };
    return (_jsxs(Stack, { direction: "row", justify: "between", gap: "4", className: cn(baseButtonStyles, ghostButtonStyles, 'group rounded-md p-2 pl-3'), align: "center", onClick: handleClick, "data-testid": `column-vis-${stepUUID || column.columnDef.id}`, children: [_jsxs(Stack, { width: "full", className: "truncate", children: [_jsxs(Stack, { direction: "row", gap: "1", align: "center", children: [name && (_jsx(BtwText, { size: "xs", weight: "semibold", children: formatStringOrMessage(intl, name) })), subType && (_jsxs(_Fragment, { children: [_jsx(ChevronRightIcon, { className: "size-2.5 text-gray-500" }), _jsx(BtwText, { size: "xs", weight: "semibold", children: subType })] }))] }), _jsx(BtwText, { truncate: true, className: "relative h-5 w-full", size: "13", variant: "primary", weight: "normal", children: title ? (_jsx(TruncatedTextContainer, { title: formatStringOrMessage(intl, title) })) : (`${columnName}`) })] }), _jsx("div", { className: "flex-0 flex items-center", children: _jsx(BtwToggle, { pressed: column.getIsVisible(), onPressedChange: (pressed) => {
                        column.toggleVisibility(pressed);
                    }, size: "sm", "data-testid": "visibility-toggle" }) })] }, column.id));
});
const ColumnVisibilityPopoverFooter = observer(function ColumnVisibilityPopoverFooter() {
    const { table } = useResponsesContext();
    const userTest = useGetUserTest();
    return (_jsx(Stack, { direction: "row", justify: "end", width: "full", className: "py-3 pl-4 pr-3 ring-1 ring-gray-600/10", children: _jsx(BtwButton, { variant: "ghost", onClick: () => table === null || table === void 0 ? void 0 : table.setColumnVisibility(getDefaultColumnVisibility(userTest)), "data-testid": "clear-sort-rules", size: "sm", children: "Reset all columns" }) }));
});
function getStepData(stepUUID, userTest) {
    if (!stepUUID) {
        return {};
    }
    const step = getStepByUUID(userTest, stepUUID);
    if (!step) {
        return {};
    }
    const isScreenerStep = ScreenerUtils.isScreenerStep(userTest, stepUUID);
    return {
        title: getStepTitle(step),
        name: getStepName(step, undefined, isScreenerStep),
        isScreenerStep,
    };
}
