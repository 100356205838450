import { gql, useMutation } from '@apollo/client';
import { useCallback } from 'react';
const UPDATE_USER_TEST_STATUS = gql `
  mutation updateUserTestPaused($input: UpdateUserTestInput!) {
    updateUserTest(input: $input) {
      ok
      error {
        message
        code
      }
      userTest {
        pk
        pausedAt
        status
      }
    }
  }
`;
export function useUpdateUserTestStatus() {
    const [mutate, mutationResult] = useMutation(UPDATE_USER_TEST_STATUS);
    const updateUserTestStatus = useCallback((newTestStatus, userTestPk) => {
        return mutate({
            variables: {
                input: {
                    pk: userTestPk,
                    // Enums in TypeScript are broken by design and cannot be cast to
                    // other enums, even if they have the same values
                    status: newTestStatus,
                },
            },
            optimisticResponse: {
                updateUserTest: {
                    __typename: 'UpdateUserTest',
                    ok: true,
                    error: null,
                    userTest: {
                        __typename: 'UserTestNode',
                        pk: userTestPk,
                        status: newTestStatus,
                        pausedAt: Date.now(),
                    },
                },
            },
        });
    }, [mutate]);
    return [updateUserTestStatus, mutationResult];
}
