import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { SortableContext, verticalListSortingStrategy, } from '@dnd-kit/sortable';
import { observer } from 'mobx-react';
import { forwardRef, useMemo } from 'react';
import * as React from 'react';
import { FormattedMessage, defineMessage, useIntl } from 'react-intl';
import { BtwHeading, BtwText, BtwTooltip, EditableText, Stack, cn, } from '@marvelapp/ballpark-components';
import { DropCardsIcon, WarningIcon } from '@marvelapp/ballpark-icons';
import { DeleteCornerButton } from './DeleteCornerButton';
import { CATEGORY_TITLE_MAX_LENGTH } from './constants';
export const BaseCategory = observer(
// eslint-disable-next-line mobx/missing-observer
forwardRef(function BaseCategory({ cards = [], category, dragHandleProps = {}, isCardsDragAndDropDisabled, isDraggedOver, isDragging, isDragOverlay, isValid = true, maxCardsPerCategory, onDelete, onDirty, onTitleKeyDown, onUpdateTitle, renderCard, style, titleInputRef, }, ref) {
    const intl = useIntl();
    const cardUUIDs = useMemo(() => cards.map((card) => card.uuid), [cards]);
    return (_jsxs(Stack, { className: cn('p-4', 'min-w-0', 'group', 'bg-gray-100', 'relative', 'rounded-lg', 'duration-300', 'ease-smooth', 'transition-combined', 'will-change-transform', 'ring-2', 'ring-inset', 'ring-transparent', {
            'opacity-25': isDragging && !isDragOverlay,
            'bg-gray-200': isDraggedOver,
            'shadow-lg ring-1 ring-gray-600/10': isDragOverlay,
            'bg-gray-50': cards.length === maxCardsPerCategory,
            'ring-red-600': !isValid,
        }), ref: ref, style: style, width: "full", "data-testid": "card-sort-category", children: [_jsxs(Stack, Object.assign({ align: { initial: 'start', sm: 'center' }, className: cn('pb-4', {
                    'cursor-grab': Object.keys(dragHandleProps).length > 0,
                }), direction: { initial: 'col', sm: 'row' }, gap: { initial: '1', sm: '4' }, justify: "between", width: "full" }, dragHandleProps, { children: [_jsx("div", { className: "min-w-0 flex-1 text-left", children: onUpdateTitle && !isDragging && !isDraggedOver ? (_jsx(EditableText, { autoFocus: false, "data-testid": "category-title", onBlur: onDirty
                                ? () => {
                                    onDirty(true);
                                }
                                : undefined, onSaveValue: (value) => {
                                onDirty === null || onDirty === void 0 ? void 0 : onDirty(true);
                                onUpdateTitle(value);
                            }, placeholder: intl.formatMessage({ id: "l4IPhY", defaultMessage: "Add your category title" }), value: category.title, 
                            // Tiny hack to prevent the card from being dragged when editing
                            // the title.
                            // We should probabaly have a drag handle for d&d instead.
                            onMouseDown: (e) => e.stopPropagation(), size: "sm", weight: "semibold", ref: titleInputRef, onKeyDown: onTitleKeyDown, className: "!border-gray-100", maxLength: CATEGORY_TITLE_MAX_LENGTH })) : (_jsx(BtwHeading
                        // The border here + the padding are needed to prevent a layout
                        // shift when a card is dragged in/our of a custom category
                        , { 
                            // The border here + the padding are needed to prevent a layout
                            // shift when a card is dragged in/our of a custom category
                            className: "break-words border border-transparent pl-0.5", size: "sm", "data-testid": "category-title", children: category.title })) }), _jsxs(Stack, { align: "center", className: "flex-shrink-0 whitespace-nowrap max-sm:whitespace-normal max-sm:text-left", direction: "row", gap: "2", children: [!isValid && (_jsx(BtwTooltip, { content: intl.formatMessage(UNIQUE_CATEGORY_TITLE_ERROR), side: "bottom", children: _jsx("div", { className: "text-red-600", children: _jsx(WarningIcon, { className: "size-5", "data-testid": "invalid-title" }) }) })), _jsx(BtwText, { className: cn(
                                // Transition
                                'duration-300', 'ease-smooth', 'transition-combined', 'will-change-transform', {
                                    'scale-105': isCardsDragAndDropDisabled,
                                }), size: "xs", "data-testid": "category-card-count", variant: isCardsDragAndDropDisabled ? 'red' : 'secondary', children: getCardsCount(cards.length, maxCardsPerCategory) })] })] })), _jsx(Stack, { align: "center", 
                // Note: A min-height is set for this element to ensure that the empty
                // state has the same height as the state with cards.
                // This is necessary to prevent an infinite loop of re-renders
                // on smaller screens (and mobile) where categories are stacked
                // vertically when a card is moved between categories.
                className: cn('min-h-32', 'flex-1', {
                    'items-center justify-center rounded border border-dashed border-gray-600/20': cards.length === 0 || !renderCard,
                }), width: "full", children: cards.length === 0 || !renderCard ? (_jsxs(Stack, { gap: "2.5", align: "center", className: "p-6", children: [_jsx(DropCardsIcon, { className: "size-9" }), _jsx(BtwText, { size: "13", align: "center", "data-testid": "category-empty-text", children: _jsx(FormattedMessage, { id: 'nnw08G', defaultMessage: 'Drop cards here' }) })] })) : (_jsx(Stack, { align: "center", gap: "2.5", width: "full", children: _jsx(SortableContext, { items: cardUUIDs, strategy: verticalListSortingStrategy, disabled: isCardsDragAndDropDisabled, children: cards.map((card) => renderCard(card)) }) })) }), onDelete && (_jsx(DeleteCornerButton, { onClick: onDelete, 
                // See above
                onMouseDown: (e) => e.preventDefault(), "data-testid": "remove-category" }))] }, category.uuid));
}));
const getCardsCount = (count, maxCardsPerCategory) => {
    if (maxCardsPerCategory) {
        return maxCardsPerCategory === count ? (_jsx(FormattedMessage, { id: 'uzgVVE', defaultMessage: 'Max cards reached' })) : (_jsx(FormattedMessage, { id: 'XldZrO', defaultMessage: '{maxCardsPerCategory, plural, one {# card} other {# cards}} max', values: { maxCardsPerCategory } }));
    }
    return (_jsx(FormattedMessage, { id: 'E5AnCe', defaultMessage: '{count, plural, one {# card} other {# cards}}', values: { count } }));
};
export const UNIQUE_CATEGORY_TITLE_ERROR = defineMessage({ id: "mJTUjR", defaultMessage: "Please give all categories a unique title" });
