import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect } from 'react';
import * as React from 'react';
import uuid from 'uuid/v4';
import { useConfig, useCurrentUser } from '@marvelapp/ballpark-application';
import RealtimeProvider from './RealtimeProvider';
const MULTIPLAYER_COLORS = [
    'purpleLight',
    'tealLight',
    'marvelLight',
    'greenLight',
    'orangeLight',
    'pink8',
    'red8',
];
export const MultiplayerRealtimeProvider = ({ children, awareness }) => {
    useMultiplayer(awareness);
    return _jsx(RealtimeProvider, { awareness: awareness, children: children });
};
const localId = uuid();
export const useMyLocalId = () => localId;
function useMultiplayer(awareness) {
    const user = useCurrentUser();
    const { pie: { host }, } = useConfig();
    useEffect(() => {
        if (!awareness)
            return;
        const color = getLeastUsedColor(awareness);
        if (!user) {
            awareness.setLocalState({
                id: localId, // don't use user pk because user may have multiple tabs open
                avatarUrl: `${host}/avatar/A.svg`,
                name: 'Anon',
                color,
            });
        }
        else {
            awareness.setLocalState({
                id: user === null || user === void 0 ? void 0 : user.pk,
                avatarUrl: user === null || user === void 0 ? void 0 : user.avatarUrl,
                name: user === null || user === void 0 ? void 0 : user.firstName,
                color,
            });
        }
        return () => awareness.setLocalState(null);
    }, [awareness, user, host]);
}
function getLeastUsedColor(awareness) {
    // in an attempt to get asfew duplicates as possible in avatar colour
    const colorFrequency = {};
    MULTIPLAYER_COLORS.forEach((color) => {
        colorFrequency[color] = 0;
    });
    const colorsInUse = [];
    [...awareness.getStates().values()].forEach((player) => {
        if (player.color)
            colorsInUse.push(player.color);
    });
    // tally the colors already in use
    colorsInUse.forEach((color) => {
        colorFrequency[color]++;
    });
    const [color] = Object.entries(colorFrequency).reduce((a, b) => {
        const [, frequencyA] = a;
        const [, frequencyB] = b;
        return frequencyB < frequencyA ? b : a;
    });
    return color;
}
