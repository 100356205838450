import { gql, useApolloClient, useMutation } from '@apollo/client';
import { UserRole, heapTrackEvent } from '@marvelapp/core';
const INVITE_USERS_TO_WORKSPACE = gql `
  mutation INVITE_USERS_TO_WORKSPACE($emails: [String]!, $role: RoleEnum) {
    inviteUsersToWorkspace(
      input: { emails: $emails, role: $role, isBallpark: true }
    ) {
      succeeded
      failed {
        email
        code
      }
      error {
        message
        code
      }
    }
  }
`;
export function useInviteUsers({ setIsSendingInvite, onError, planLabel }) {
    const client = useApolloClient();
    const [inviteUsersFn] = useMutation(INVITE_USERS_TO_WORKSPACE);
    const inviteUsers = ({ onInviteSuccess, setEmails, setShowFormError, usersToInvite, }) => {
        const retry = () => {
            setIsSendingInvite(true);
            setShowFormError(false);
            inviteUsers({
                setEmails,
                setIsSendingInvite,
                setShowFormError,
                usersToInvite,
            });
        };
        inviteUsersFn({ variables: Object.assign({}, usersToInvite) })
            .then(({ data: { inviteUsersToWorkspace } }) => {
            const invitedUsersCount = inviteUsersToWorkspace.succeeded.length;
            if (usersToInvite.role !== UserRole.ExternalReviewer) {
                updateSeatsUsedStoredInCache(client, invitedUsersCount);
            }
            heapTrackEvent('Invited users', {
                planLabel,
                invitedUsersCount,
                role: usersToInvite.role,
                totalExpansion: 0,
            });
            onInviteSuccess({
                inviteUsersToWorkspace,
                retry,
                setEmails,
                setIsSendingInvite,
                setShowFormError,
            });
        })
            .catch(() => {
            setIsSendingInvite(false);
            if (onError)
                onError(retry);
        });
    };
    return inviteUsers;
}
function updateSeatsUsedStoredInCache(client, increment) {
    const cachedFragment = client.readFragment({
        id: 'Seats',
        fragment: gql `
      fragment seats on Seats {
        used
      }
    `,
    });
    if (!cachedFragment)
        return;
    client.writeFragment({
        id: 'Seats',
        fragment: gql `
      fragment seats on Seats {
        used
      }
    `,
        data: {
            used: cachedFragment.used + increment,
        },
    });
}
