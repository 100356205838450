import { __rest } from "tslib";
import { jsx as _jsx } from "react/jsx-runtime";
import PropTypes from 'prop-types';
import { Box } from '@marvelapp/ui';
export default function IconCircle(_a) {
    var { children } = _a, props = __rest(_a, ["children"]);
    return (_jsx(Box, Object.assign({ alignItems: "center", border: "1px solid", borderColor: "rgba(255,255,255,0.4)", borderRadius: 12, display: "flex", height: 24, justifyContent: "center", width: 24 }, props, { children: children })));
}
IconCircle.propTypes = {
    children: PropTypes.any,
};
