import { isValidEmail } from '@marvelapp/core';
import { CardSortingResponseUtils, ChoiceStepResponseUtils, ChoiceStepUtils, QuestionResponseUtils, StepClass, StepUtils, YesOrNoResponseUtils, hasAnswer, } from '@marvelapp/user-test-creator';
import { UNIQUE_CATEGORY_TITLE_ERROR } from '../../slides/CardSorting/BaseCategory';
import { getIsButtonDisabled as getIsChoiceButtonDisabled } from '../../utils/choiceStepUtils';
export function canMoveToNextSlide(step, stepResponse) {
    if (!step)
        return true;
    const isUnansweredRequiredStep = !!(StepUtils.getIsRequired(step) && !hasAnswer(stepResponse, step));
    if (StepUtils.isLegalStep(step)) {
        return !!YesOrNoResponseUtils.getAnswer(stepResponse);
    }
    if (step.className === StepClass.MultipleChoice ||
        step.className === StepClass.PreferenceTest ||
        step.className === StepClass.TaglineCopyTest) {
        return !getIsChoiceStepDisabled(step, stepResponse, isUnansweredRequiredStep);
    }
    if (step.className === StepClass.FiveSecondTest) {
        return !step.imageUrl || !isUnansweredRequiredStep;
    }
    if (step.className === StepClass.PrototypeTask) {
        const hasImageOrPrototype = step.imageUrl || step.originalPrototypeUrl;
        return !hasImageOrPrototype || !isUnansweredRequiredStep;
    }
    if (step.className === StepClass.WebsiteTask) {
        return !step.websiteUrl || !isUnansweredRequiredStep;
    }
    if (step.className === StepClass.CardSortingHybrid ||
        step.className === StepClass.CardSortingOpen) {
        const canGoForward = CardSortingResponseUtils.hasValidCategoryNames(step, stepResponse) && !isUnansweredRequiredStep;
        return canGoForward;
    }
    return !isUnansweredRequiredStep;
}
function getIsChoiceStepDisabled(step, stepResponse, isUnansweredRequiredStep) {
    const multipleSelectionMode = ChoiceStepUtils.getMultipleSelectionMode(step);
    const selectionRange = ChoiceStepUtils.getSelectionRange(step);
    const numberOfSelectedAnswers = ChoiceStepResponseUtils.getAnswer(stepResponse).length;
    const hasAtLeastOneChoice = ChoiceStepUtils.hasAChoiceOption(step);
    return getIsChoiceButtonDisabled(multipleSelectionMode, selectionRange, numberOfSelectedAnswers, !!(isUnansweredRequiredStep && hasAtLeastOneChoice));
}
export function getStepError(intl, step, stepResponse) {
    if (!step)
        return '';
    const isEmailStep = StepUtils.isEmailStep(step);
    if (isEmailStep) {
        const answer = QuestionResponseUtils.getAnswer(stepResponse);
        const isAnswerEmpty = answer === null || answer === '';
        const isAnswerValid = typeof answer === 'string' && isValidEmail(answer);
        // not letting you move forward on an empty required step is handled elsewhere
        // so it's safe to check empty answer here like so
        return isAnswerEmpty || isAnswerValid
            ? ''
            : intl.formatMessage({ id: "4Vq0iT", defaultMessage: "Please enter a valid email address" });
    }
    return '';
}
export function validationErrors(intl, step, stepResponse) {
    if (!step)
        return '';
    if (step.className === StepClass.CardSortingHybrid ||
        step.className === StepClass.CardSortingOpen) {
        if (!CardSortingResponseUtils.hasValidCategoryNames(step, stepResponse)) {
            return intl.formatMessage(UNIQUE_CATEGORY_TITLE_ERROR);
        }
    }
    return '';
}
