import { defineMessage } from 'react-intl';
const constants = {
    STOP_TEST: defineMessage({ id: "OO5XWi", defaultMessage: "End task" }),
    DEFAULT_INTRO: defineMessage({ id: "3uQKQW", defaultMessage: "The test has started! Interact with the prototype and if you have enabled the camera and audio, give us your feedback!" }),
    DEFAULT_INTRO_MOBILE: defineMessage({ id: "dKQolD", defaultMessage: "The test has started! Interact with the prototype and give us your feedback!" }),
    DEFAULT_INTO_FIRST_CLICK: defineMessage({ id: "ipNCVB", defaultMessage: "Read the question, then carry out the task by clicking on an area of the image." }),
    INSTRUCTIONS: defineMessage({ id: "npeDaU", defaultMessage: "Instructions" }),
    CLOSE_INSTRUCTIONS: defineMessage({ id: "pxQC1d", defaultMessage: "Close" }),
    OPEN_INSTRUCTIONS: defineMessage({ id: "KrQpVO", defaultMessage: "Open instructions" }),
};
export default constants;
