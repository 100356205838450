import { __rest } from "tslib";
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useOverrideNativeUndo } from '@ballpark/realtime-plugin-history';
import { Plate, PlateContent, focusEditor, isEditorFocused, } from '@udecode/plate-common';
import { isEqual } from 'lodash-es';
import { useCallback, useEffect, useMemo, useRef, useState, } from 'react';
import * as React from 'react';
import { cn, textBaseVariants, } from '@marvelapp/ballpark-components';
import { showErrorNotification, showWarningNotification, } from '@marvelapp/ui-internal';
import { richTextV1ToV2, sanitize, } from '@marvelapp/user-test-creator';
import { LinkFloatingToolbar } from './LinkToolbar';
import { TextEditorToolbar } from './TextEditorToolbar';
import { plugins } from './plugins';
const defaultValue = [{ type: 'p', children: [{ text: '' }] }];
export const TextEditor = React.memo((_a) => {
    var { disabled = false, id, onChange, placeholder, resetValueOnChange = false, testId, value, className } = _a, rest = __rest(_a, ["disabled", "id", "onChange", "placeholder", "resetValueOnChange", "testId", "value", "className"]);
    const containerRef = useRef(null);
    // perform on the fly conversion from v1 to v2, this is a no-op if the value is already in
    // v2 format this is necessary because we can't do schema migrations and therefore we must
    // support our historical format that is no longer compatible with the current slate
    // schema
    //
    // TODO: migrate the schema and remove this BALL-336
    const valueV2 = useMemo(() => value && richTextV1ToV2(value), [value]);
    return (_jsx("div", { "data-testid": testId, translate: "no", className: cn('notranslate', className), ref: containerRef, children: _jsx(PlateWrapper, Object.assign({ disabled: disabled, id: id, onChange: onChange, placeholder: placeholder, resetValueOnChange: resetValueOnChange, containerRef: containerRef, value: valueV2 }, rest)) }));
});
export const PlateWrapper = React.memo(function PlateWrapper({ align, containerRef, disabled = false, font, id, leading = 'relaxed', onChange, placeholder, resetValueOnChange = false, size = 'base', truncate, value, weight, maxLength, }) {
    const initialized = useRef(false);
    const [slateValue, setSlateValue] = useState((value === null || value === void 0 ? void 0 : value.length) ? value : defaultValue);
    const editorRef = useRef(null);
    const version = useValueVersion(slateValue);
    const overrideNativeUndo = useOverrideNativeUndo();
    // store the last value, this is different to the value prop that may not change as frequently
    // as the actual internal value of the editor
    const previousValueRef = useRef(value);
    // if the value changes externally update previousValueRef so the next change comparison isn't
    // falsey
    useEffect(() => {
        previousValueRef.current = value;
    }, [value]);
    const onPlateChange = useCallback((newValue) => {
        if (onChange) {
            // this method fires for all sorts of reasons including user changing their editor selection
            // or focus, we only want to update the value if the user changed it otherwise if two users
            // are in same text editor they'll enter an infinite loop of resetting it, blurring the
            // other user, the other user focusing, changing, blurring this user...
            if (!isEqual(newValue, previousValueRef.current)) {
                const { deletions, result: sanitizedValue, formatting, } = sanitize(newValue);
                if (deletions) {
                    console.warn('sanitized clipboard value with deletions', sanitizedValue, newValue);
                    showErrorNotification({
                        toastId: 'invalidInputError',
                        content: `The inserted text contains elements we didn't recognise and some of it has been removed. Please try pasting it again as plain-text.`,
                    });
                }
                else if (formatting) {
                    console.warn('sanitized clipboard value with formatting changes', sanitizedValue, newValue);
                    showWarningNotification({
                        toastId: 'invalidInputError',
                        content: `The inserted text contained formatting we didn't recognise and may look different to the original.`,
                    });
                }
                // plate holds its own state that so we need to update it to reflect the sanitized value
                // to clear any bad data
                if (!isEqual(sanitizedValue, newValue)) {
                    // Editor will lose focus, it's quite a bit more work to try and reconcile the original
                    // cursor location in relation to the sanitized value so just let it happen.
                    setSlateValue(sanitizedValue);
                }
                onChange(sanitizedValue);
            }
            previousValueRef.current = newValue;
        }
    }, [onChange]);
    useEffect(() => {
        if (!initialized.current)
            return;
        if (resetValueOnChange) {
            setSlateValue((value === null || value === void 0 ? void 0 : value.length) ? value : defaultValue);
        }
    }, [value, resetValueOnChange]);
    useEffect(() => {
        if (resetValueOnChange) {
            const editor = editorRef.current;
            if (!editor)
                return;
            const isFocused = isEditorFocused(editor);
            // resetting the editor takes away focus, reapply it (this won't remember the user's
            // selection) but reapplying the users selection wouldn't work as it is offset based and the
            // text changed externally so they could end up selecting different text even if their current
            // selection still exists in the document
            //
            // TODO: possibly diff these changes into slate operations for a more complete collaborative
            // experience
            if (isFocused && !editor.isFallback) {
                focusEditor(editor);
            }
        }
    }, [slateValue, id, resetValueOnChange]);
    useEffect(() => {
        initialized.current = true;
    }, []);
    return (_jsxs(Plate, { disableCorePlugins: { deserializeHtml: true }, editorRef: editorRef, id: id, initialValue: slateValue, onChange: disabled ? undefined : onPlateChange, plugins: plugins, readOnly: disabled, maxLength: maxLength, children: [_jsx(PlateContent, { onKeyDown: overrideNativeUndo, placeholder: placeholder, readOnly: disabled, renderPlaceholder: renderPlaceholder, "data-testid": "text-editor-content", className: textBaseVariants({
                    align,
                    size,
                    leading,
                    weight,
                    truncate,
                    font,
                    className: cn('cursor-text', 'rounded', 'outline-none', 'border-y', 'border-x-[3px]', 'border-transparent', 'ring-2', 'ring-transparent', 'duration-300', 'ease-smooth', 'transition-combined', 
                    // For some reason, Tailwind doesn't apply the opacity class when used
                    // with the object syntax from cn (cn({ property: condition })), so we
                    // have to conditionally apply it like below. I'm assuming it has something
                    // to do with the arbitrary class that confuses the compiler
                    disabled
                        ? '[&_[data-testid="placeholder"]]:!opacity-100'
                        : 'border-white hover:ring-gray-600/20 focus:ring-gray-600/30 [&_[data-testid="placeholder"]]:!opacity-50'),
                }) }), _jsx(FloatingToolbars, { disabled: disabled, containerRef: containerRef })] }, version));
});
function FloatingToolbars({ disabled, containerRef, }) {
    const [hasDeepFocus, setHasDeepFocus] = useState(false);
    // This is a workaround to determine whether the editor or any of its children are focused. This
    // is because we close the toolbar when the editor loses focus, but we want to keep it open when
    // inputs within the toolbar are focused.
    //
    // It is extremely important that toolbar buttons manage focus well and do not uneccesarily blur
    // the editor before applying focus to inputs.
    useEffect(() => {
        if (disabled) {
            setHasDeepFocus(false);
            return;
        }
        const updateHasDeepFocus = () => {
            var _a, _b;
            setHasDeepFocus((_b = (_a = containerRef.current) === null || _a === void 0 ? void 0 : _a.contains(document.activeElement)) !== null && _b !== void 0 ? _b : false);
        };
        document.addEventListener('focusin', updateHasDeepFocus);
        document.addEventListener('focusout', updateHasDeepFocus);
        return () => {
            document.removeEventListener('focusin', updateHasDeepFocus);
            document.removeEventListener('focusout', updateHasDeepFocus);
        };
    }, [containerRef, disabled]);
    if (disabled || !hasDeepFocus)
        return null;
    return (_jsxs(_Fragment, { children: [_jsx(TextEditorToolbar, {}), _jsx(LinkFloatingToolbar, {})] }));
}
// TODO: Integrate real CRDT text into the proxy so this is no longer necessary and we can transform
// the text in the editor instead of resetting the editor.
/**
 * Monitors changes to the passed in value and returns a key that is used to remount the text-editor
 * when it changes.
 *
 * This is the safest way of completely replacing the editor's content when the value changes as it
 * does not listen to the value prop directly.
 *
 * @param value - external value to be passed to the text editor
 * @param editor - the editor so that it can be refocused after remounting if it was previously
 * @returns
 */
function useValueVersion(value, editor) {
    const wasEditorFocused = useRef(false);
    const [version, setVersion] = useState(1);
    useEffect(() => {
        if (editor)
            wasEditorFocused.current = isEditorFocused(editor);
    }, [value, editor]);
    useEffect(() => {
        setVersion((v) => v + 1);
    }, [value]);
    useEffect(() => {
        if (!editor)
            return;
        if (wasEditorFocused.current) {
            focusEditor(editor);
            wasEditorFocused.current = false;
        }
    }, [version, editor]);
    return version;
}
function renderPlaceholder({ children, attributes }) {
    return (_jsx("span", Object.assign({ "data-testid": "placeholder" }, attributes, { children: children })));
}
