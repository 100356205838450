import { RecordingPipeline, RecordingStatus, StepRecordingPermission, } from './common';
export function getMedia(args) {
    var _a;
    let media = null;
    if (!('mediaType' in args)) {
        media = args.mutable.instruction;
    }
    else if (!((_a = args.mutable) === null || _a === void 0 ? void 0 : _a.participantRecordings)) {
        return null;
    }
    else {
        media = args.mutable.participantRecordings[args.mediaType];
    }
    if (!media) {
        return null;
    }
    return media;
}
export function getMediaStatus(args) {
    const media = getMedia(args);
    if (!media) {
        return null;
    }
    return media.status;
}
export function setMediaStatus(args) {
    if (!('mediaType' in args)) {
        const { mutable } = args;
        mutable.instruction = getUpdatedRecording(mutable.instruction, args);
    }
    else {
        const { mutable } = args;
        if (!mutable.participantRecordings) {
            mutable.participantRecordings = { user: null, screen: null };
        }
        mutable.participantRecordings[args.mediaType] = getUpdatedRecording(mutable.participantRecordings[args.mediaType], args);
    }
}
function getUpdatedStatus(newPermissions, currentStatus) {
    // the status will be set to AcquiringMedia if the user is still on the media settings step and hasn't started streaming to Janus yet
    if (Object.values(newPermissions).includes(StepRecordingPermission.Denied) &&
        (currentStatus === RecordingStatus.AcquiringMedia ||
            currentStatus === RecordingStatus.Failed)) {
        return RecordingStatus.Failed;
    }
    return RecordingStatus.AcquiringMedia;
}
export function updatePermissionAndStatus({ mutable, mediaType, newPermissions, }) {
    var _a, _b, _c;
    // Do not reset permissions if there is already a status log.
    //
    // This is an extra guard to prevent resetting participantRecordings when a user reloads the
    // page as they are already streaming to Janus. really the state machine itself should be better
    // guarded against this.
    if ((_c = (_b = (_a = mutable.participantRecordings) === null || _a === void 0 ? void 0 : _a[mediaType]) === null || _b === void 0 ? void 0 : _b.statusLog) === null || _c === void 0 ? void 0 : _c.length) {
        return;
    }
    if (!mutable.participantRecordings) {
        mutable.participantRecordings = {
            user: null,
            screen: null,
        };
    }
    const currentRecording = mutable.participantRecordings[mediaType];
    const permissions = mergeRecordingPermissions(currentRecording === null || currentRecording === void 0 ? void 0 : currentRecording.permissions, newPermissions);
    const requestedPermissions = Object.values(permissions).filter((permission) => permission !== StepRecordingPermission.NotRequested);
    const newRecording = requestedPermissions.length
        ? {
            status: getUpdatedStatus(newPermissions, currentRecording === null || currentRecording === void 0 ? void 0 : currentRecording.status),
            permissions,
        }
        : null;
    mutable.participantRecordings[mediaType] = newRecording;
}
export function setInstructionStatus(args) {
    setMediaStatus(args);
}
export function getUpdatedRecording(recording, args) {
    var _a;
    const { statusLog = [] } = recording !== null && recording !== void 0 ? recording : {};
    // this is a no-op if the same status and timestamp already exists in the log
    if (recording && recording.statusLog) {
        const isDuplicate = recording.statusLog.some((entry) => entry.status === args.stage && entry.timestamp === args.timestamp);
        if (isDuplicate)
            return recording;
    }
    const { timestamp = Date.now() } = args;
    // This status is set client-side.
    // When using the navigation arrows to move back/forward on the recording view
    // a test can generate more than multiple recordings (with different IDs)
    if (args.stage === RecordingPipeline.StreamingFromClient) {
        statusLog.push({
            timestamp,
            status: args.stage,
        });
        return {
            status: RecordingStatus.Streaming,
            statusLog,
            recordingId: args.recordingId,
            permissions: recording === null || recording === void 0 ? void 0 : recording.permissions,
        };
    }
    if (!recording) {
        statusLog.push({
            status: RecordingPipeline.Error,
            timestamp,
        });
        return {
            status: RecordingStatus.Failed,
            statusLog,
            error: `Recording not found when trying to set status to ${args.stage}`,
        };
    }
    const { permissions } = recording;
    const prefixedRecordingId = recording.recordingId;
    const unprefixedRecordingId = (_a = recording.recordingId) === null || _a === void 0 ? void 0 : _a.split('/')[1];
    const isRecordingIdArgNonEmpty = !!args.recordingId;
    let { recordingId } = recording;
    if (recording.status === RecordingStatus.AcquiringMedia) {
        // The recording id is not defined at this point
        ({ recordingId } = args);
    }
    else if (isRecordingIdArgNonEmpty &&
        !(
        // Historical accident means that the recordingId in the document has an
        // '<env>/' prefix. Here we allow the recordingId arg to match either the
        // prefixed or unprefixed id as status updates from the video pipeline use
        // the unprefixed value
        (args.recordingId === prefixedRecordingId ||
            args.recordingId === unprefixedRecordingId))) {
        throw new Error(`Recording id '${args.recordingId}' does not match existing recording with recording id '${recording === null || recording === void 0 ? void 0 : recording.recordingId}'`);
    }
    statusLog.push({
        timestamp,
        status: args.stage,
    });
    if (args.stage === RecordingPipeline.Ready) {
        let duration;
        if (typeof args.duration === 'string') {
            try {
                duration = parseFloat(args.duration);
            }
            catch (e) {
                duration = undefined;
            }
        }
        else if (typeof args.duration === 'number') {
            ({ duration } = args);
        }
        return {
            statusLog,
            status: RecordingStatus.Ready,
            mimeType: args.mimeType,
            url: args.url,
            duration,
            permissions,
            recordingId,
        };
    }
    if (args.stage === RecordingPipeline.Error) {
        return {
            statusLog,
            status: RecordingStatus.Failed,
            error: args.error,
            permissions,
            recordingId,
        };
    }
    if (args.stage < RecordingPipeline.StreamingFromClientComplete) {
        return {
            statusLog,
            status: RecordingStatus.Streaming,
            permissions,
            recordingId,
        };
    }
    return {
        statusLog,
        status: RecordingStatus.Processing,
        permissions,
        recordingId,
    };
}
export function mergeRecordingPermissions(permissions1, permissions2) {
    return {
        webcam: mergePermission(permissions1 === null || permissions1 === void 0 ? void 0 : permissions1.webcam, permissions2 === null || permissions2 === void 0 ? void 0 : permissions2.webcam),
        microphone: mergePermission(permissions1 === null || permissions1 === void 0 ? void 0 : permissions1.microphone, permissions2 === null || permissions2 === void 0 ? void 0 : permissions2.microphone),
        screen: mergePermission(permissions1 === null || permissions1 === void 0 ? void 0 : permissions1.screen, permissions2 === null || permissions2 === void 0 ? void 0 : permissions2.screen),
    };
}
function mergePermission(permission1, permission2) {
    const p1 = permission1 === StepRecordingPermission.NotRequested
        ? undefined
        : permission1;
    const p2 = permission2 === StepRecordingPermission.NotRequested
        ? undefined
        : permission2;
    return p2 || p1 || StepRecordingPermission.NotRequested;
}
export function getRecordingMedia(permission) {
    const media = new Set();
    if (permission.screen) {
        media.add('screen');
    }
    if (permission.webcam) {
        media.add('user');
    }
    // When screen sharing is enabled with audio only we merge the two streams
    // together and send the combined video/audio stream to the server as screen recording
    if (permission.microphone) {
        if (permission.screen) {
            media.add('screen');
        }
        else if (permission.webcam) {
            media.add('user');
        }
        else {
            // audio only recording
            media.add('user');
        }
    }
    return Array.from(media.values());
}
// following the pattern of userTest here which exports StepClass locally.
// Not sure why we don't export from the type file where it's defined.
export { RecordingPipeline, RecordingStatus };
