import { jsx as _jsx } from "react/jsx-runtime";
import { gql, useMutation } from '@apollo/client';
import { observer } from 'mobx-react';
import * as React from 'react';
import { InviteOnlySetting as InviteOnlySettingComponent } from '@marvelapp/ballpark-components';
const TOGGLE_VISIBILITY = gql `
  mutation SetVisibility($pk: Int!, $visibility: ProjectVisibilityEnum) {
    updateProject(input: { pk: $pk, visibility: $visibility }) {
      ok
      project {
        pk
        visibility
      }
    }
  }
`;
export const InviteOnlySetting = observer(function InviteOnlySetting({ visibility, prototypePk, }) {
    const [setProjectVisibility] = useMutation(TOGGLE_VISIBILITY, {});
    function toggleVisibility() {
        const newVisibility = visibility === 'PRIVATE' ? 'WORKSPACE' : 'PRIVATE';
        setProjectVisibility({
            variables: { pk: prototypePk, visibility: newVisibility },
            optimisticResponse: {
                updateProject: {
                    __typename: 'UpdateProject',
                    ok: true,
                    project: {
                        pk: prototypePk,
                        visibility: newVisibility,
                        __typename: 'ProjectNode',
                    },
                },
            },
            update(cache) {
                cache.writeFragment({
                    id: `PrototypeNode:${prototypePk}`,
                    fragment: gql `
            fragment prototype on PrototypeNode {
              visibility
            }
          `,
                    data: {
                        visibility: newVisibility,
                    },
                });
            },
        });
    }
    return (_jsx(InviteOnlySettingComponent, { onToggle: toggleVisibility, visibility: visibility }));
});
