import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { observer } from 'mobx-react';
import { useState } from 'react';
import * as React from 'react';
import { useAction } from '@marvelapp/ballpark-application';
import styled from '@marvelapp/styled';
import { TextEditor } from '@marvelapp/text-editor';
import { Box } from '@marvelapp/ui';
import { ChoiceConditionalUtils, TaglineCopyStepUtils, } from '@marvelapp/user-test-creator';
import ChoiceLogicDeleteModal from '../../components/ChoiceLogicDeleteModal';
import DraggablePreferenceItem from '../../components/DraggablePreferenceItem';
import EditableText from '../../components/Editable/EditableText';
export default observer(function TaglineCopyTestGridItem({ autofocus, choiceUUID, index, step, children, }) {
    var _a, _b;
    const headingValue = (_a = step.choiceDefinitions[choiceUUID]) === null || _a === void 0 ? void 0 : _a.heading;
    const paragraphValue = (_b = step.choiceDefinitions[choiceUUID]) === null || _b === void 0 ? void 0 : _b.paragraph;
    const id = `card-${choiceUUID}`;
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const deleteChoice = useAction(() => {
        const hasLogicSetOnChoice = ChoiceConditionalUtils.getIsChoiceConditional(step, choiceUUID);
        if (hasLogicSetOnChoice) {
            setShowDeleteModal(true);
        }
        else {
            TaglineCopyStepUtils.removeChoice(step, choiceUUID);
        }
    }, [choiceUUID, step]);
    const confirmDelete = useAction(() => {
        ChoiceConditionalUtils.deleteRulesForDeletedChoice(step, choiceUUID);
        TaglineCopyStepUtils.removeChoice(step, choiceUUID);
        setShowDeleteModal(false);
    }, [choiceUUID, step]);
    const onHeadingChange = useAction((value) => TaglineCopyStepUtils.setChoiceHeading(step, index, value), [index, step]);
    const onParagraphChange = useAction((value) => TaglineCopyStepUtils.setChoiceParagraph(step, index, value), [index, step]);
    return (_jsxs(_Fragment, { children: [_jsx(DraggablePreferenceItem, { id: id, onDelete: deleteChoice, index: index, children: _jsx(Body, { children: _jsxs("div", { children: [_jsx(EditableText, { as: "h3", autoFocus: autofocus, value: headingValue, onSaveValue: onHeadingChange, placeholder: "Add your tagline", kind: "heading", fontSize: "xl", lineHeight: ['28px', '28px', '28px', '28px', '28px', '32px'], testId: `tagline-choice-heading` }), _jsx(TextEditor, { size: "base", id: `${id}-paragraph`, onChange: onParagraphChange, placeholder: "Add your sub-heading", testId: `tagline-choice-paragraph`, value: paragraphValue }), children] }) }) }), showDeleteModal && (_jsx(ChoiceLogicDeleteModal, { confirmDelete: confirmDelete, trigger: () => setShowDeleteModal(false) }))] }));
});
const Body = styled(Box).attrs({
    marginY: 's',
}) `
  margin-left: 10px;
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  text-align: left;

  > div {
    width: 100%;
  }
`;
