import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { gql, useMutation, useQuery } from '@apollo/client';
import { observer } from 'mobx-react';
import { useCallback, useState } from 'react';
import { useAction, useConfig, useGetUserTest, useSlideUIContext, useUserTestAction, } from '@marvelapp/ballpark-application';
import { Link, Text } from '@marvelapp/ballpark-components';
import { HOW_TO_ADD_FIGMA_PROTOTYPE } from '@marvelapp/ballpark-help';
import { usePollForQueryComplete } from '@marvelapp/hooks';
import styled from '@marvelapp/styled';
import { Box } from '@marvelapp/ui';
import { showErrorNotification } from '@marvelapp/ui-internal';
import { PrototypeTaskUtils, StepClass, StepUtils, } from '@marvelapp/user-test-creator';
import { FailureCodeEnum, FileSizeTypeEnum, } from '../../../__generated__/queryTypes';
import { parseUrl } from '../../../utils';
import { RightScrollableHalfSlide } from '../../common/HalfSlide';
import UrlForm from '../../common/UrlForm';
import { FigmaProTip } from './FigmaProTip';
import ImportFailureModal from './ImportFailureModal';
import { CTA_CONNECT_WITH_FIGMA, CTA_I_UNDERSTAND, CTA_TRY_AGAIN, failureCopy, } from './ModalCopy';
import { useCheckFigmaPermissions } from './useCheckFigmaPermissions';
const INTEGRATIONS_QUERY = gql `
  query getIntegrations {
    user {
      pk
      company {
        pk
        integrations {
          edges {
            node {
              pk
              name
              isExpired
            }
          }
        }
      }
    }
  }
`;
const VALID_DOMAINS = ['figma.com', 'marvelapp.com'];
export const PrototypeUrlInput = observer(function PrototypeUrlInput({ fileSize, setFileSize, }) {
    const userTest = useGetUserTest();
    const { step } = useSlideUIContext();
    const [showFailureModal, setShowFailureModal] = useState(false);
    const [failureCode, _setFailureCode] = useState(null);
    const config = useConfig();
    const FIGMA_CONNECT_URL = `${config.pie.host}/integration/figma/`;
    const { data, loading } = useQuery(INTEGRATIONS_QUERY);
    const { resetPermissionsError } = useCheckFigmaPermissions();
    let hasFigmaIntegration = false;
    if (!loading) {
        hasFigmaIntegration = !!data.user.company.integrations.edges.filter((integration) => integration.node.name === 'figma' &&
            integration.node.hasAccessToken &&
            !integration.node.isExpired);
    }
    const setFailureCode = useCallback((code) => {
        if (code === FailureCodeEnum.NOT_FOUND && hasFigmaIntegration) {
            _setFailureCode('NOT_FOUND_TOKEN');
        }
        _setFailureCode(code);
    }, [hasFigmaIntegration]);
    const { createPrototypeTask, isLoading } = useCreatePrototypeTask({
        fileSize,
        setFileSize,
        setShowFailureModal,
        setFailureCode,
    });
    const setOriginalPrototypeUrl = useUserTestAction((value) => {
        if (!StepUtils.isOfType(step, StepClass.PrototypeTask))
            return;
        PrototypeTaskUtils.setOriginalPrototypeUrl(step, value);
    }, [step]);
    const onSubmit = useAction(() => {
        if (!StepUtils.isOfType(step, StepClass.PrototypeTask))
            return;
        resetPermissionsError();
        createPrototypeTask({
            variables: {
                input: {
                    testUuid: userTest.uuid,
                    stepUuid: step.uuid,
                    version: step.revision,
                    url: step.originalPrototypeUrl,
                },
            },
        });
    }, [createPrototypeTask, resetPermissionsError, step, userTest.uuid]);
    const onClick = useCallback(() => {
        // TS is stating that failureCode might not be defined yet so return if this is the case
        if (!failureCode)
            return;
        const modalCopy = failureCopy[failureCode];
        if (modalCopy.ctaType === CTA_TRY_AGAIN) {
            // allow user to retry if there is a figma server error
            onSubmit();
        }
        else if (modalCopy.ctaType === CTA_I_UNDERSTAND) {
            setShowFailureModal(false);
        }
        else if (modalCopy.ctaType === CTA_CONNECT_WITH_FIGMA) {
            if (hasFigmaIntegration) {
                window.open(FIGMA_CONNECT_URL, '_blank', 'noreferrer');
            }
            setShowFailureModal(false);
        }
    }, [failureCode, hasFigmaIntegration, onSubmit, FIGMA_CONNECT_URL]);
    const validate = useCallback((url) => {
        const parsedUrl = parseUrl(url);
        if (!VALID_DOMAINS.includes(parsedUrl.domainWithoutSubdomains)) {
            return "We don't currently support URLs from this domain";
        }
        if (parsedUrl.domainWithoutSubdomains === 'figma.com') {
            const fileType = parsedUrl.pathname.split('/')[1];
            if (fileType !== 'proto') {
                return 'It looks like you are pasting a share link to a Figma file, please add the share link to the prototype instead';
            }
        }
    }, []);
    if (!StepUtils.isOfType(step, StepClass.PrototypeTask))
        return null;
    return (_jsx(RightScrollableHalfSlide, { height: "100%", children: _jsxs(Box, { width: "100%", id: "prototype-selector", children: [showFailureModal && (_jsx(ImportFailureModal, { onClick: onClick, isLoading: isLoading, failureCode: failureCode })), _jsxs(UrlFormWrapper, { flex: 1, children: [_jsx(Text, { color: "licorice", mt: "xs", fontSize: 2, fontWeight: 600, children: "Add your Marvel or Figma prototype." }), _jsx(Text, { paddingLeft: "3px", mt: "xs", fontSize: 2, fontWeight: 400, children: _jsx(Link, { "data-testid": "prototype-help-link", fontSize: "s", color: "red", underline: true, to: HOW_TO_ADD_FIGMA_PROTOTYPE, children: "Learn more" }) })] }), _jsx(UrlForm, { buttonText: "Embed prototype", isLoading: isLoading, onChange: setOriginalPrototypeUrl, onSubmit: onSubmit, domainAllowList: VALID_DOMAINS, validate: validate, placeholder: "Paste your share link here", testId: "prototype", value: step.originalPrototypeUrl }), _jsx(FigmaProTip, {})] }) }));
});
const CREATE_PROTOTYPE_FOR_STEP = gql `
  mutation createPrototypeForUserTestStep($input: CreatePrototypeStepInput!) {
    createPrototypeForUserTestStep(input: $input) {
      ok
      project {
        pk
        prototypeUrl
        name
        externalPrototype {
          pk
          defaultStartScreenId
          defaultStartScreenExternalId
          embedUrl
        }
      }
      status {
        pollId
        complete
        failed
      }
      error {
        code
        message
      }
    }
  }
`;
const GET_EXTERNAL_PROTOTYPE_CLONE_STATUS = gql `
  query pollExternalProjectClone($pollId: Int!) {
    pollExternalProjectClone(pollId: $pollId) {
      complete
      prototypeUrl
      failed
      failureCode
      fileSize
      fileSizeType
      externalPrototype {
        pk
        defaultStartScreenId
        defaultStartScreenExternalId
        embedUrl
      }
    }
  }
`;
function useCreatePrototypeTask({ fileSize, setFileSize, setShowFailureModal, setFailureCode, }) {
    const { step } = useSlideUIContext();
    const [isPolling, setIsPolling] = useState(false);
    const handleFileSize = useCallback((data) => {
        var _a, _b;
        if (fileSize) {
            return;
        }
        if (((_a = data === null || data === void 0 ? void 0 : data.pollExternalProjectClone) === null || _a === void 0 ? void 0 : _a.fileSizeType) &&
            ((_b = data === null || data === void 0 ? void 0 : data.pollExternalProjectClone) === null || _b === void 0 ? void 0 : _b.fileSize)) {
            const { fileSizeType, fileSize: newFileSize } = data.pollExternalProjectClone;
            if (fileSizeType === FileSizeTypeEnum.LARGE) {
                setFileSize(newFileSize);
            }
        }
    }, [fileSize, setFileSize]);
    const onQueryComplete = useUserTestAction((data) => {
        var _a, _b, _c, _d, _e, _f, _g;
        if (!step || !StepUtils.isOfType(step, StepClass.PrototypeTask))
            return;
        handleFileSize(data);
        if (data &&
            ((_a = data.pollExternalProjectClone) === null || _a === void 0 ? void 0 : _a.externalPrototype) &&
            ((_b = data.pollExternalProjectClone) === null || _b === void 0 ? void 0 : _b.prototypeUrl)) {
            setIsPolling(false);
            setImportedPrototype({
                step,
                url: (_c = data.pollExternalProjectClone) === null || _c === void 0 ? void 0 : _c.prototypeUrl,
                startScreenId: (_e = (_d = data.pollExternalProjectClone) === null || _d === void 0 ? void 0 : _d.externalPrototype) === null || _e === void 0 ? void 0 : _e.defaultStartScreenId,
                embedUrl: (_g = (_f = data.pollExternalProjectClone) === null || _f === void 0 ? void 0 : _f.externalPrototype) === null || _g === void 0 ? void 0 : _g.embedUrl,
            });
        }
    }, [handleFileSize, step]);
    const { pollForStatus } = usePollForQueryComplete({
        query: GET_EXTERNAL_PROTOTYPE_CLONE_STATUS,
        queryName: 'pollExternalProjectClone',
        onQueryComplete,
        onQueryFail: (errorCode) => {
            setShowFailureModal(true);
            setFailureCode(errorCode);
            setIsPolling(false);
        },
        // the file size data can become available before the import completes so we
        // pass onQueryPolled to handle that as soon as possible
        onQueryPolled: handleFileSize,
    });
    const onMutationCompleted = useUserTestAction((data) => {
        var _a, _b, _c, _d;
        if (!step || !StepUtils.isOfType(step, StepClass.PrototypeTask))
            return;
        if ((_a = data.createPrototypeForUserTestStep) === null || _a === void 0 ? void 0 : _a.error) {
            const setError = (error) => {
                showErrorNotification({
                    toastId: 'prototype-url',
                    content: error,
                });
            };
            handleMutationErrors(data.createPrototypeForUserTestStep.error.code, setError);
        }
        else if (data.createPrototypeForUserTestStep) {
            const { status } = data.createPrototypeForUserTestStep;
            if (status === null || status === void 0 ? void 0 : status.failed) {
                showErrorNotification({
                    toastId: 'prototype-url',
                    content: 'There was an issue importing your prototype',
                });
            }
            else if (status === null || status === void 0 ? void 0 : status.pollId) {
                setIsPolling(true);
                pollForStatus({
                    variables: {
                        pollId: status.pollId,
                    },
                });
            }
            else if ((_b = data === null || data === void 0 ? void 0 : data.createPrototypeForUserTestStep) === null || _b === void 0 ? void 0 : _b.project) {
                setImportedPrototype({
                    step,
                    url: data.createPrototypeForUserTestStep.project.prototypeUrl,
                    startScreenId: (_c = data.createPrototypeForUserTestStep.project.externalPrototype) === null || _c === void 0 ? void 0 : _c.defaultStartScreenId,
                    embedUrl: (_d = data.createPrototypeForUserTestStep.project.externalPrototype) === null || _d === void 0 ? void 0 : _d.embedUrl,
                });
            }
            else {
                showErrorNotification({
                    toastId: 'prototype-url',
                    content: 'There was an unknown error importing the prototype',
                });
            }
        }
    }, [pollForStatus, step]);
    const [createPrototypeTask, { loading: isLoading }] = useMutation(CREATE_PROTOTYPE_FOR_STEP, {
        onCompleted: onMutationCompleted,
        onError() {
            showErrorNotification({
                toastId: 'prototype-url',
                content: 'There was an issue importing your prototype',
            });
        },
    });
    return {
        createPrototypeTask,
        isLoading: isLoading || isPolling,
    };
}
function handleMutationErrors(errorCode, setErrorMessage) {
    switch (errorCode) {
        case 'EXTERNAL_PROTOTYPE_IMPORT_FAILED':
            setErrorMessage(_jsxs(_Fragment, { children: ["There was an issue importing your prototype,", ' ', _jsx(Link, { underline: true, kind: "white", to: "mailto:help@marvelapp.com", children: "contact our support team" }), ' ', "for help."] }));
            break;
        case 'EXTERNAL_PROTOTYPE_UNAUTHORISED_ACCESSS':
            setErrorMessage(_jsxs(_Fragment, { children: ["You don\u2019t have the user permissions to access this prototype. Make sure the share link is set to", ' ', _jsx(Text, { display: "inline", fontSize: 2, fontWeight: "500", children: "'Anyone with the link'" }), ' ', "can access."] }));
            break;
        case 'EXTERNAL_PROTOTYPE_NOT_FOUND':
            setErrorMessage(_jsxs(_Fragment, { children: ["There was a problem accessing this prototype. Check the share link is set to", ' ', _jsx(Text, { color: "background", display: "inline", fontSize: 2, fontWeight: "500", children: "'Anyone with the link'" }), ' ', "can access. If the issue persists,", ' ', _jsx(Link, { underline: true, kind: "white", to: "mailto:help@marvelapp.com", children: "contact support" }), "."] }));
            break;
        default:
            setErrorMessage('Oops, something went wrong.');
            break;
    }
}
function setImportedPrototype({ step, url, startScreenId, startScreenExternalId, embedUrl, }) {
    PrototypeTaskUtils.setImportedPrototypeUrl(step, url);
    if (startScreenId) {
        PrototypeTaskUtils.setStartScreen(step, startScreenId);
    }
    if (startScreenExternalId) {
        PrototypeTaskUtils.setStartScreenExternalId(step, startScreenExternalId);
    }
    if (embedUrl) {
        PrototypeTaskUtils.setEmbedUrl(step, embedUrl);
    }
}
const UrlFormWrapper = styled(Box) `
  display: flex;
  flex-direction: row;
`;
