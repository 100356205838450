import { createAutoformatPlugin } from '@udecode/plate-autoformat';
import { MARK_BOLD, MARK_ITALIC, MARK_STRIKETHROUGH, createBoldPlugin, createItalicPlugin, createStrikethroughPlugin, } from '@udecode/plate-basic-marks';
import { ELEMENT_BLOCKQUOTE } from '@udecode/plate-block-quote';
import { createExitBreakPlugin, createSoftBreakPlugin, } from '@udecode/plate-break';
import { createPlugins, isBlockAboveEmpty, isSelectionAtBlockStart, } from '@udecode/plate-common';
import { KEYS_HEADING } from '@udecode/plate-heading';
import { ELEMENT_LINK, createLinkPlugin } from '@udecode/plate-link';
import { ELEMENT_LI, ELEMENT_OL, ELEMENT_UL, createListPlugin, } from '@udecode/plate-list';
import { ELEMENT_PARAGRAPH, createParagraphPlugin, } from '@udecode/plate-paragraph';
import { createResetNodePlugin } from '@udecode/plate-reset-node';
import { makeLinkValid } from '@marvelapp/core';
import { Element } from './Element';
const resetBlockTypesCommonRule = {
    types: [ELEMENT_BLOCKQUOTE],
    defaultType: ELEMENT_PARAGRAPH,
};
export const plugins = createPlugins([
    createParagraphPlugin(),
    createLinkPlugin({
        options: {
            transformInput: makeLinkValid,
            defaultLinkAttributes: {
                target: '_blank',
                rel: 'noopener noreferrer',
            },
        },
    }),
    createBoldPlugin(),
    createItalicPlugin(),
    createStrikethroughPlugin(),
    createAutoformatPlugin({
        options: {
            rules: [
            // Usage: https://platejs.org/docs/autoformat
            ],
            enableUndoOnDelete: true,
        },
    }),
    createExitBreakPlugin({
        options: {
            rules: [
                {
                    hotkey: 'mod+enter',
                },
                {
                    hotkey: 'mod+shift+enter',
                    before: true,
                },
                {
                    hotkey: 'enter',
                    query: {
                        start: true,
                        end: true,
                        allow: KEYS_HEADING,
                    },
                    relative: true,
                    level: 1,
                },
            ],
        },
    }),
    createResetNodePlugin({
        options: {
            rules: [
                Object.assign(Object.assign({}, resetBlockTypesCommonRule), { hotkey: 'Enter', predicate: isBlockAboveEmpty }),
                Object.assign(Object.assign({}, resetBlockTypesCommonRule), { hotkey: 'Backspace', predicate: isSelectionAtBlockStart }),
            ],
        },
    }),
    createSoftBreakPlugin({
        options: {
            rules: [
                { hotkey: 'shift+enter' },
                {
                    hotkey: 'enter',
                    query: {
                        allow: [ELEMENT_BLOCKQUOTE],
                    },
                },
            ],
        },
    }),
    createListPlugin(),
], {
    components: {
        [ELEMENT_LI]: Element.Li,
        [ELEMENT_LINK]: Element.Link,
        [ELEMENT_OL]: Element.Ol,
        [ELEMENT_PARAGRAPH]: Element.Paragraph,
        [ELEMENT_UL]: Element.Ul,
        [MARK_BOLD]: Element.Strong,
        [MARK_ITALIC]: Element.Em,
        [MARK_STRIKETHROUGH]: Element.Strikethrough,
    },
});
