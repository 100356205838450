import { jsx as _jsx } from "react/jsx-runtime";
import { getDoc } from '@ballpark/realtime-crdt';
import { action, observable } from 'mobx';
import { observer, useLocalObservable } from 'mobx-react';
import { createContext, useContext, useEffect, } from 'react';
import { useIntl } from 'react-intl';
import { useLocation } from 'react-router-dom';
import { deviceDetection } from '@marvelapp/core';
import { ConditionalLogicUtils, RESPONSE_DOC_KEY, ResponseStatus, ScreenerUtils, StepClass, StepUtils, UserTestResponseUtils, getAdjacentStepUUID, getOrderedListOfAllSteps, getResponses, isExitStep, isSubmitStep, } from '@marvelapp/user-test-creator';
import { canMoveToNextSlide, getStepError, validationErrors } from './helpers';
export var PaginationDirection;
(function (PaginationDirection) {
    PaginationDirection[PaginationDirection["Forward"] = 1] = "Forward";
    PaginationDirection[PaginationDirection["Backward"] = -1] = "Backward";
    PaginationDirection[PaginationDirection["None"] = 0] = "None";
})(PaginationDirection || (PaginationDirection = {}));
const RecorderStateContext = createContext(undefined);
export const useRecorderState = () => {
    const recorderState = useContext(RecorderStateContext);
    if (recorderState === undefined)
        throw new Error('useRecorderState used outside of Provider');
    return recorderState;
};
export const useMaybeRecorderState = () => {
    return useContext(RecorderStateContext);
};
export const RecorderContextProvider = observer(function RecorderContextProvider({ response: _response, userTestDoc: _userTestDoc, isPreview, children, downloadRecording, }) {
    const intl = useIntl();
    const isLive = useLocation().pathname.includes('live');
    /* eslint-disable react/no-this-in-sfc */
    const recorderState = useLocalObservable(() => {
        const state = {
            isLive,
            liveSessionStatus: undefined,
            requiresMedia: false,
            // store YJS docs that are also observable so
            userTestDoc: _userTestDoc,
            response: responseWithHistory(_response, _userTestDoc),
            paginationDirection: PaginationDirection.None,
            isPreview,
            isRecordingMedia: false,
            /**
             * stepError is only shown after attempting to navigate out of a slide
             */
            showStepError: false,
            websiteTaskWindow: null,
            get currentStep() {
                var _a;
                return (_a = this.allSteps[this.currentStepIndex]) !== null && _a !== void 0 ? _a : this.allSteps[0];
            },
            get isComplete() {
                return this.response.status === ResponseStatus.Complete;
            },
            get isScreening() {
                return this.response.status === ResponseStatus.Screening;
            },
            get stepError() {
                return getStepError(intl, this.currentStep, this.currentStepResponse);
            },
            get currentStepResponse() {
                const stepResponses = getResponses(this.response);
                return stepResponses[this.currentStepIndex];
            },
            /**
             * All steps including mediaStep, but without screeners
             */
            get steps() {
                return getOrderedListOfAllSteps({
                    withMediaSettings: true,
                    withScreeners: false,
                    userTest: this.userTestDoc,
                });
            },
            /**
             * All steps including mediaStep & screeners
             */
            get allSteps() {
                return getOrderedListOfAllSteps({
                    withMediaSettings: true,
                    withScreeners: true,
                    userTest: this.userTestDoc,
                });
            },
            get currentStepIndex() {
                var _a;
                return ((_a = this.allSteps.findIndex((step) => step.uuid === this.response.currentStepUUID)) !== null && _a !== void 0 ? _a : 0);
            },
            /**
             * current slide number in relation to all test steps (excludes screener steps)
             */
            get currentSlideNumber() {
                return (this.steps.findIndex((step) => step.uuid === this.response.currentStepUUID) + 1);
            },
            get canGoForward() {
                return canMoveToNextSlide(recorderState.currentStep, recorderState.currentStepResponse);
            },
            get validationErrors() {
                return validationErrors(intl, recorderState.currentStep, recorderState.currentStepResponse);
            },
            get isPrototypeTask() {
                return StepUtils.isOfType(this.currentStep, StepClass.PrototypeTask);
            },
            get nextPrototypeTaskStep() {
                // on safari, we are disabling preloading of the prototype viewer
                // in a bid to prevent the browser from crashing/refreshing spontaneously
                if (deviceDetection.isSafari && !this.isPrototypeTask) {
                    return;
                }
                return recorderState.paginationDirection ===
                    PaginationDirection.Backward
                    ? getPreviousPrototypeStep(recorderState.currentStep, recorderState.allSteps)
                    : getNextPrototypeStep(recorderState.currentStep, recorderState.allSteps);
            },
            get skipMediaSettingsStep() {
                return this.isRecordingMedia || !!(downloadRecording === null || downloadRecording === void 0 ? void 0 : downloadRecording.videoUrl);
            },
            goForward() {
                var _a;
                // We only want to fail validation when going forward. The user should
                // still be able to go back a step
                if (this.stepError) {
                    this.showStepError = true;
                    return;
                }
                logResponseToTheConsole(this.response);
                this.showStepError = false;
                const { currentStepUUID, status } = this.response;
                const isScreenerStep = ScreenerUtils.isScreenerStep(this.userTestDoc, currentStepUUID);
                const hasBeenPreScreened = currentStepUUID === this.userTestDoc.welcomeStep.uuid &&
                    status === ResponseStatus.Screening;
                const nextStepUUID = (_a = ConditionalLogicUtils.getConditionalNextStepUUID(this.currentStep, this.currentStepResponse, isScreenerStep)) !== null && _a !== void 0 ? _a : getAdjacentStepUUID({
                    userTest: this.userTestDoc,
                    candidateStepUUID: this.allSteps[this.currentStepIndex + 1].uuid,
                    index: +1,
                    skipMediaSettingsStep: this.skipMediaSettingsStep,
                });
                UserTestResponseUtils.addEventToTimeline(this.response, nextStepUUID);
                UserTestResponseUtils.addUUIDToHistory(this.response, currentStepUUID);
                if (isScreenerStep || hasBeenPreScreened) {
                    UserTestResponseUtils.setStatus({
                        userTestDoc: this.userTestDoc,
                        response: this.response,
                        currentStepUUID,
                        nextStepUUID,
                    });
                }
                if (isSubmitStep(this.userTestDoc, currentStepUUID) ||
                    isExitStep(this.userTestDoc, nextStepUUID)) {
                    UserTestResponseUtils.markResponseAsComplete(this.response);
                }
                this.response.currentStepUUID = nextStepUUID;
                this.paginationDirection = PaginationDirection.Forward;
            },
            goBackward() {
                var _a;
                logResponseToTheConsole(this.response);
                this.showStepError = false;
                const lastStepUUID = getAdjacentStepUUID({
                    userTest: this.userTestDoc,
                    candidateStepUUID: (_a = this.response.history.pop()) !== null && _a !== void 0 ? _a : this.allSteps[this.currentStepIndex - 1].uuid,
                    index: -1,
                    skipMediaSettingsStep: this.skipMediaSettingsStep,
                });
                UserTestResponseUtils.addEventToTimeline(this.response, lastStepUUID);
                this.response.currentStepUUID = lastStepUUID;
                this.paginationDirection = PaginationDirection.Backward;
            },
            resetParticipantRecordings() {
                this.response.participantRecordings = {
                    screen: null,
                    user: null,
                };
            },
        };
        // if we're not starting at the start for a live session, we need to
        // require media as we must be resuming from a previous step
        if (state.currentStepIndex !== 0 && state.isLive) {
            state.requiresMedia = true;
        }
        return state;
    }, {
        response: observable.ref,
        // userTest should never change, even if it isn't already observable we don't need to listen
        // to it now
        userTestDoc: observable.ref,
    });
    /* eslint-enable react/no-this-in-sfc */
    useEffect(action(() => {
        recorderState.userTestDoc = _userTestDoc;
        recorderState.response = responseWithHistory(_response, _userTestDoc);
    }), [_userTestDoc, _response]);
    return (_jsx(RecorderStateContext.Provider, { value: recorderState, children: children }));
});
function logResponseToTheConsole(response) {
    if (response) {
        try {
            // eslint-disable-next-line no-console
            console.log('changing step', getDoc(response)
                .get(RESPONSE_DOC_KEY)
                .toJSON());
        }
        catch (e) {
            // do nothing
        }
    }
}
function getNextPrototypeStep(currentStep, steps) {
    const currentStepIndex = steps.findIndex((step) => step.uuid === currentStep.uuid);
    for (let i = currentStepIndex; i < steps.length; i++) {
        const step = steps[i];
        if (StepUtils.isOfType(step, StepClass.PrototypeTask))
            return step;
    }
}
function getPreviousPrototypeStep(currentStep, steps) {
    const currentStepIndex = steps.findIndex((step) => step.uuid === currentStep.uuid);
    for (let i = currentStepIndex; i >= 0; i--) {
        const step = steps[i];
        if (StepUtils.isOfType(step, StepClass.PrototypeTask))
            return step;
    }
}
// old responses didn't have history, this ensures they do
function responseWithHistory(response, userTestDoc) {
    var _a;
    if (!response.history) {
        // eslint-disable-next-line no-param-reassign
        response.history = [];
    }
    if (!response.currentStepUUID) {
        // eslint-disable-next-line no-param-reassign
        response.currentStepUUID =
            (_a = response.timeline[response.timeline.length - 1].stepUUID) !== null && _a !== void 0 ? _a : userTestDoc.welcomeStep.uuid;
    }
    return response;
}
