import { jsx as _jsx } from "react/jsx-runtime";
import { observer } from 'mobx-react';
import * as React from 'react';
import { useIntl } from 'react-intl';
import { Input } from '@marvelapp/ballpark-components';
import { UI_STEPS_COPY } from '@marvelapp/ballpark-copy';
import { QuestionStepType } from '@marvelapp/user-test-creator';
import BaseQuestionEdit from '../Question/BaseQuestionEdit';
export default observer(function NumericalEdit(props) {
    const intl = useIntl();
    return (_jsx(BaseQuestionEdit, Object.assign({}, props, { children: _jsx(Input, { "data-testid": "numerical-editable-input", placeholder: intl.formatMessage(UI_STEPS_COPY[QuestionStepType.Numerical].inputPlaceholder), readOnly: true, size: "xl", type: "number", width: "100%" }) })));
});
