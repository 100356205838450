import { __rest } from "tslib";
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { toast } from 'react-toastify';
import { Link, ProgressBar } from '@marvelapp/ui';
import FailedText from '../FailedText';
import LoadingText from '../LoadingText';
import SuccessText from '../SuccessText';
/** @param {any} */
function showNotification({ toastId, component, options }) {
    if (toastId && toast.isActive(toastId)) {
        return toast.update(toastId, Object.assign({ render: component }, options));
    }
    return toast(component, Object.assign(Object.assign({}, options), { toastId }));
}
export function dismissNotification(toastId) {
    if (toast.isActive(toastId)) {
        toast.dismiss(toastId);
    }
}
/** @param {any} */
export function showLoadingNotification({ toastId, content, options }) {
    return showNotification({
        toastId,
        component: (_jsx(LoadingText, { color: "white", loaderColor: "white", children: content })),
        options: Object.assign({ type: 'info' }, options),
    });
}
/** @param {any} */
export function showProgressNotification({ toastId, content, options, progress, }) {
    return showNotification({
        toastId,
        component: (_jsxs(LoadingText, { alignItems: "center", color: "white", loaderColor: "white", children: [content, _jsx(ProgressBar, { mb: 1, mt: 2, progress: progress })] })),
        options: Object.assign({ type: 'info' }, options),
    });
}
/** @param {any} */
export function showSuccessNotification({ toastId, content, options }) {
    return showNotification({
        toastId,
        component: (_jsx(SuccessText, { bg: "white", borderColor: "white", color: "white", iconColor: "silver", children: content })),
        options: Object.assign({ type: 'success' }, options),
    });
}
/** @param {any} */
export function showErrorNotification({ toastId, content, retry, options }) {
    return showNotification({
        toastId,
        component: (_jsxs(FailedText, { color: "white", children: [content, retry && (_jsxs(_Fragment, { children: [' ', _jsx(Link, { fontSize: "inherit", hasUnderline: true, kind: "white", onClick: retry, children: "Retry?" })] }))] })),
        options: Object.assign({ type: 'error' }, options),
    });
}
export function showWarningNotification(_a) {
    var { options = {} } = _a, args = __rest(_a, ["options"]);
    return showErrorNotification(Object.assign(Object.assign({}, args), { options: Object.assign({ type: 'warning' }, options) }));
}
function getErrorMessage(statusCode, optionalMessage) {
    let message = optionalMessage || 'Oops, something went wrong';
    if (statusCode) {
        message += ` (Error code: ${statusCode})`;
    }
    return `${message}.`;
}
/** @param {any} */
export function showGenericServerError({ statusCode, toastId, closeOnClick = false, retry, content, } = {}) {
    return showErrorNotification({
        toastId,
        content: getErrorMessage(statusCode, content),
        options: {
            // setting autoClose to 5000 in case the original toast
            // disabled autoClose (will be true for progress notifications)
            autoClose: 5000,
            // Prevent close when clicking the retry link
            closeOnClick,
        },
        retry,
    });
}
