import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { observer } from 'mobx-react';
import { useAction } from '@marvelapp/ballpark-application';
import { BtwText, BtwTooltip, Slider, Stack, } from '@marvelapp/ballpark-components';
import { useRecruitmentState } from './RecruitmentContext';
import { CustomValueAgeOption, GeneralTargetingFilter } from './types';
const AGE_RANGE_FALLBACKS = [18, 100];
export const AgeSlider = observer(function AgeSlider() {
    return (_jsxs(Stack, { "data-testid": "age-slider", direction: "row", align: "center", gap: "4", children: [_jsx(Value, { ageRange: "min" }), _jsxs(Stack, { gap: "1.5", className: "", width: "full", children: [_jsx(AgeSliderThumbs, {}), _jsxs(Stack, { justify: "between", width: "full", direction: "row", children: [_jsx(RangeLabel, { ageRange: "min" }), _jsx(RangeLabel, { ageRange: "max" })] })] }), _jsx(Value, { ageRange: "max" })] }));
});
const AgeSliderThumbs = observer(function AgeSliderThumbs() {
    const { getFilterByGroupAndId, updateCustomValueFilterSelection } = useRecruitmentState();
    const { allowedMinAge, allowedMaxAge, selectedMinAge, selectedMaxAge } = useGetSliderValue();
    const setAge = useAction((value) => {
        const [min, max] = value;
        const filter = getFilterByGroupAndId('GROUP_DEFAULT', GeneralTargetingFilter.Age);
        const selectedFilter = {
            name: filter.name,
            id: filter.id,
        };
        const minAgeOption = filter.options.find((o) => o.id === CustomValueAgeOption.Min);
        const maxAgeOption = filter.options.find((o) => o.id === CustomValueAgeOption.Max);
        if (minAgeOption && maxAgeOption) {
            updateCustomValueFilterSelection(selectedFilter, Object.assign(Object.assign({}, minAgeOption), { filterId: GeneralTargetingFilter.Age, customValue: `${min}`, groupId: 'GROUP_DEFAULT' }));
            updateCustomValueFilterSelection(selectedFilter, Object.assign(Object.assign({}, maxAgeOption), { filterId: GeneralTargetingFilter.Age, customValue: `${max}`, groupId: 'GROUP_DEFAULT' }));
        }
    }, [getFilterByGroupAndId, updateCustomValueFilterSelection]);
    return (_jsxs(Slider.Root, { value: [selectedMinAge, selectedMaxAge], min: allowedMinAge, max: allowedMaxAge, step: 1, onValueChange: setAge, minStepsBetweenThumbs: 1, children: [_jsx(Slider.Track, { children: _jsx(Slider.Range, {}) }), _jsx(Slider.Thumb, { "data-testid": "min-age-thumb" }), _jsx(Slider.Thumb, { "data-testid": "max-age-thumb" })] }));
});
const Value = observer(function Value({ ageRange }) {
    const { selectedMinAge, selectedMaxAge } = useGetSliderValue();
    return (_jsx(BtwTooltip, { content: "Use the slider to adjust age", children: _jsx(Stack, { align: "center", className: "h-8 w-12 cursor-pointer rounded-md bg-gray-100 p-2", "data-testid": `selected-${ageRange}-age`, justify: "center", children: _jsx(BtwText, { className: "opacity-75", size: "13", variant: "primary", weight: "medium", children: ageRange === 'min' ? selectedMinAge : selectedMaxAge }) }) }));
});
const RangeLabel = observer(function RangeLabel({ ageRange }) {
    const { allowedMinAge, allowedMaxAge } = useGetSliderValue();
    return (_jsx(BtwText, { align: "center", size: "xxs", variant: "secondary", weight: "medium", children: ageRange === 'min' ? allowedMinAge : allowedMaxAge }));
});
function useGetSliderValue() {
    var _a, _b;
    const { getFilterByGroupAndId, selectedFilters } = useRecruitmentState();
    const filter = getFilterByGroupAndId('GROUP_DEFAULT', GeneralTargetingFilter.Age);
    const { min, max } = filter.options[0];
    const allowedMinAge = min || AGE_RANGE_FALLBACKS[0];
    const allowedMaxAge = max || AGE_RANGE_FALLBACKS[1];
    const selectedMinAgeOption = (_a = selectedFilters[GeneralTargetingFilter.Age]) === null || _a === void 0 ? void 0 : _a.options.find((o) => o.id === CustomValueAgeOption.Min);
    const selectedMaxAgeOption = (_b = selectedFilters[GeneralTargetingFilter.Age]) === null || _b === void 0 ? void 0 : _b.options.find((o) => o.id === CustomValueAgeOption.Max);
    const selectedMinAge = (selectedMinAgeOption === null || selectedMinAgeOption === void 0 ? void 0 : selectedMinAgeOption.customValue)
        ? Number(selectedMinAgeOption.customValue)
        : allowedMinAge;
    const selectedMaxAge = (selectedMaxAgeOption === null || selectedMaxAgeOption === void 0 ? void 0 : selectedMaxAgeOption.customValue)
        ? Number(selectedMaxAgeOption.customValue)
        : allowedMaxAge;
    return {
        allowedMinAge,
        allowedMaxAge,
        selectedMinAge,
        selectedMaxAge,
    };
}
