import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { observer } from 'mobx-react';
import { FormattedMessage, useIntl } from 'react-intl';
import { BtwHeading, BtwText, Progress, Stack, } from '@marvelapp/ballpark-components';
export const CardsProgressHeading = observer(function CardsProgressHeading(props) {
    const { unsorted, total, nonInteractive } = props;
    const intl = useIntl();
    return (_jsxs(Stack, { gap: "4", width: "full", children: [_jsxs(Stack, { align: { initial: 'start', sm: 'center' }, direction: { initial: 'col', sm: 'row' }, gap: "2", justify: "between", width: "full", children: [_jsx(BtwHeading, { className: "min-h-[22px] break-words border border-transparent pl-0.5", size: "sm", children: _jsx(FormattedMessage, { id: 'K5dLcw', defaultMessage: 'Cards' }) }), _jsx(BtwText, { className: "whitespace-nowrap", "data-testid": "card-remainder-text", size: "xs", children: _jsx(FormattedMessage, { id: 'T2QM61', defaultMessage: '{unsortedCount} out of {totalCount} remaining', values: {
                                unsortedCount: intl.formatNumber(unsorted),
                                totalCount: intl.formatNumber(total),
                            } }) })] }), _jsx(Progress, { className: "bg-gray-300", size: "sm", value: nonInteractive ? 0 : ((total - unsorted) / total) * 100, variant: "legacyBlue" })] }));
});
