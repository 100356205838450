import { jsx as _jsx } from "react/jsx-runtime";
import { observer } from 'mobx-react';
import * as React from 'react';
import { useIntl } from 'react-intl';
import { UI_COMMON_STEPS_COPY } from '@marvelapp/ballpark-copy';
import TextareaGrowing from '../../components/TextareaGrowing';
import BaseQuestionEdit from './BaseQuestionEdit';
export default observer(function QuestionEdit(props) {
    const intl = useIntl();
    return (_jsx(BaseQuestionEdit, Object.assign({}, props, { children: _jsx(TextareaGrowing, { id: "question-editable-textarea", "data-testid": "question-editable-textarea", placeholder: intl.formatMessage(UI_COMMON_STEPS_COPY.inputPlaceholder), readOnly: true, size: "xl", width: "100%" }) })));
});
