import { gql, useMutation } from '@apollo/client';
import { showGenericServerError } from '@marvelapp/ui-internal';
const UPDATE_PASSWORD = gql `
  mutation passwordProtectProject($itemId: Int!, $password: String!) {
    passwordProtectProject(input: { projectPk: $itemId, password: $password }) {
      ok
      project {
        passwordProtected
      }
      error {
        code
        message
      }
    }
  }
`;
export function useUpdatePassword(pk) {
    return useMutation(UPDATE_PASSWORD, {
        onError({ networkError }) {
            const toastId = 'setPassword';
            const content = 'Your password could not be updated, please try again.';
            // It seems that you're not able to get the status code from the network error
            // without TypeScript complaining about it. Had to use a type guard as suggested here:
            // https://github.com/apollographql/apollo-link/issues/300
            const statusCode = networkError && 'statusCode' in networkError && networkError.statusCode;
            showGenericServerError({
                toastId,
                content,
                options: { autoClose: 5000 },
                statusCode,
            });
        },
        update(cache) {
            const cachedData = cache.readFragment({
                id: `PrototypeNode:${pk}`,
                fragment: gql `
          fragment prototype on PrototypeNode {
            pk
            passwordProtected
          }
        `,
            });
            cache.writeFragment({
                id: `PrototypeNode:${pk}`,
                fragment: gql `
          fragment prototype on PrototypeNode {
            passwordProtected
          }
        `,
                data: {
                    passwordProtected: !(cachedData === null || cachedData === void 0 ? void 0 : cachedData.passwordProtected),
                },
            });
        },
        optimisticResponse: {
            passwordProtectProject: {
                error: null,
                project: {
                    passwordProtected: true,
                    __typename: 'ProjectNode',
                },
                ok: true,
                __typename: 'PasswordProtectProject',
            },
        },
    });
}
