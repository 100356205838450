import { __rest } from "tslib";
import { jsx as _jsx } from "react/jsx-runtime";
import { observer } from 'mobx-react';
import * as React from 'react';
import { RatingStepUtils, RatingType, StepClass, StepUtils, } from '@marvelapp/user-test-creator';
import { useStepContext } from '../../../utils/stepContext';
import { RatingStatic } from '../../common/RatingStatic';
import { SummaryStep } from './SummaryStep';
export const SummaryRating = observer(function SummaryRating(_a) {
    var { active, secondary, showAllLink, averageRating } = _a, rest = __rest(_a, ["active", "secondary", "showAllLink", "averageRating"]);
    const { step } = useStepContext();
    if (!StepUtils.isOfType(step, StepClass.RatingScale))
        return null;
    const minRating = RatingStepUtils.getMinRating(step);
    return (_jsx(SummaryStep, Object.assign({ active: active, secondary: secondary, showAllLink: showAllLink }, rest, { children: averageRating && (
        // TODO: (refactor) Use context when available in all places
        // using `RatingStatic` component
        _jsx(RatingStatic, { iconType: step.ratingType, selectedRatingNumber: averageRating, totalRatingNumber: step.maxRating, showFullScaleRating: step.ratingType !== RatingType.Numerical, ratingBadgeCopy: step.ratingType === RatingType.Numerical
                ? `${averageRating.toFixed(1)} out of ${step.maxRating}`
                : averageRating.toFixed(1), minRating: minRating })) })));
});
