import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { createColumnHelper, } from '@tanstack/react-table';
import { isArray, isEmpty, isNil } from 'lodash-es';
import { observer } from 'mobx-react';
import React from 'react';
import { Badge, BtwText, BtwCheckbox as Checkbox, Stack, BtwText as Text, } from '@marvelapp/ballpark-components';
import { CheckCircleIcon, CloseCircleIcon } from '@marvelapp/ballpark-icons';
import { formatDateAndTime } from '@marvelapp/core';
import { FlatStepClass, ScreenerUtils, StepClass, StepUtils, getRecordingPermissions, getStepByUUID, } from '@marvelapp/user-test-creator';
import { ResultStatusEnum } from '../../__generated__/queryTypes';
import ResultLoading from '../../components/ResultLoading';
import { CopyToClipboardButton } from './CopyToClipboadButton';
import { IndividualResultActions } from './IndividualResultActions';
import { BooleanCell, DateCell, DeviceDataCell, DurationCell, EmailCell, HeaderCell, MisclickRateCell, MultipleChoiceCell, NumericCell, PreferenceTestCell, QuestionTextCell, RatingCell, TaglineCopyCell, } from './TableCells';
import { ParticipantSourceCell } from './TableCells/ParticipantSourceCell';
import { getFiltersForStep } from './filterUtils';
import { getFlatStep, getNonNullResponseValue, getResponseTableStepUUIDs, getResponseValue, getResultStatusDisplayName, getResultStatusIcon, } from './stepUtils';
// Setting a min width variable for columns (TanStack requires a width and 20 is the min)
// We need to set this if we want to conditionally set custom widths to specific columns
const CELL_WIDTH = 20;
export function getColumns({ userTest, projectPk, isSelectionEnabled, }) {
    const columnHelper = createColumnHelper();
    const columns = [
        columnHelper.display(getRowSelection({ projectPk, isSelectionEnabled })),
        columnHelper.accessor('submittedAt', {
            size: 170,
            header: 'Created at',
            cell: ({ row }) => {
                const submittedAt = row.getValue('submittedAt');
                const { date, time } = formatDateAndTime(new Date(submittedAt), 'd MMM y');
                return (_jsxs(BtwText, { size: "13", children: [submittedAt && date, row.original.status === 'IN_PROGRESS' ? (_jsx(ResultLoading, { size: "s" })) : (submittedAt && _jsxs(_Fragment, { children: [", ", time] }))] }));
            },
            id: 'submittedAt',
            meta: {
                sorting: {
                    name: 'Created at',
                    id: 'CreatedAt',
                    type: 'date',
                },
                visibility: {
                    id: 'CreatedAt',
                },
            },
            enableSorting: true,
            enableHiding: true,
            enableColumnFilter: true,
        }),
        columnHelper.accessor('status', {
            id: 'status',
            size: 140,
            header: 'Status',
            cell: ({ cell }) => {
                const status = cell.getValue();
                if (status === ResultStatusEnum.COMPLETED ||
                    status === ResultStatusEnum.INCOMPLETE) {
                    return (_jsxs(Stack, { direction: "row", gap: "2", align: "center", children: [getResultStatusIcon(status, 'size-4'), _jsx(BtwText, { children: getResultStatusDisplayName(status) })] }));
                }
            },
            meta: {
                visibility: {
                    id: 'Status',
                },
                sorting: {
                    name: 'Status',
                    id: 'Status',
                    type: 'string',
                },
            },
            enableSorting: true,
            enableHiding: true,
            enableColumnFilter: false,
        }),
        columnHelper.accessor('participant.name', {
            size: 80,
            header: 'ID',
            cell: ({ cell }) => {
                const strippedID = cell.getValue().replace('User #', '');
                return _jsx(BtwText, { weight: "medium", children: strippedID });
            },
            id: 'participant_name',
            meta: {
                sorting: {
                    name: 'ID',
                    id: 'UserID',
                    type: 'string',
                },
                visibility: {
                    id: 'ID',
                },
            },
            enableSorting: true,
            enableHiding: true,
            enableColumnFilter: true,
        }),
        columnHelper.accessor('device', {
            id: 'device',
            size: 160,
            header: 'Device',
            cell: ({ cell }) => {
                const device = cell.getValue();
                return _jsx(DeviceDataCell, { device: device });
            },
            meta: {
                visibility: {
                    id: 'Device',
                },
            },
            enableSorting: false,
            enableHiding: true,
            enableColumnFilter: true,
        }),
        columnHelper.accessor('recruitment.participantSource', {
            id: 'recruitment_participantSource',
            size: 160,
            header: 'Source',
            cell: ({ cell }) => {
                const source = cell.getValue();
                return _jsx(ParticipantSourceCell, { source: source });
            },
            meta: {
                visibility: {
                    id: 'Source',
                },
                sorting: {
                    name: 'Source',
                    id: 'ParticipantSource',
                    type: 'string',
                },
            },
            enableSorting: true,
            enableHiding: true,
            enableColumnFilter: true,
        }),
        columnHelper.accessor('recruitment.participantId', {
            id: 'recruitment_participantId',
            size: 140,
            header: 'Participant ID',
            cell: ({ cell }) => {
                const participantId = cell.getValue();
                return (_jsxs(_Fragment, { children: [_jsx(BtwText, { className: "w-full max-w-60", truncate: true, children: participantId }), participantId && _jsx(CopyToClipboardButton, { text: participantId })] }));
            },
            meta: {
                visibility: {
                    id: 'Participant ID',
                },
            },
            enableSorting: false,
            enableHiding: true,
            enableColumnFilter: true,
        }),
        columnHelper.accessor('recruitment.studyId', {
            id: 'recruitment_studyId',
            size: 140,
            header: 'Study ID',
            cell: ({ cell }) => {
                const studyId = cell.getValue();
                return (_jsxs(_Fragment, { children: [_jsx(BtwText, { className: "w-full max-w-60", truncate: true, children: studyId }), studyId && _jsx(CopyToClipboardButton, { text: studyId })] }));
            },
            meta: {
                visibility: {
                    id: 'Study ID',
                },
            },
            enableSorting: false,
            enableHiding: true,
            enableColumnFilter: true,
        }),
    ];
    const hasMediaEnabled = getHasMediaEnabled(userTest);
    if (hasMediaEnabled) {
        columns.push(columnHelper.accessor('hasRecording', {
            header: 'Recording',
            size: 100,
            cell: ({ cell }) => {
                const hasPlayableRecording = cell.getValue();
                return (_jsx(Badge, { variant: hasPlayableRecording ? 'green' : 'gray', children: hasPlayableRecording ? 'Yes' : 'No' }));
            },
            id: 'hasRecording',
            meta: {
                visibility: {
                    id: 'RecordingSubmitted',
                },
                sorting: {
                    name: 'Recording',
                    id: 'RecordingSubmitted',
                    type: 'boolean',
                },
            },
            enableSorting: true,
            enableHiding: true,
            enableColumnFilter: true,
        }));
    }
    const stepsUUIDs = getResponseTableStepUUIDs(userTest);
    stepsUUIDs.forEach((stepUUID) => {
        const step = getStepByUUID(userTest, stepUUID);
        if (!step)
            return;
        if (step.className === StepClass.Instruction)
            return;
        const newCols = getColumnDefinitionsForStep(columnHelper, step);
        if (Array.isArray(newCols)) {
            columns.push(...newCols);
        }
    });
    return columns;
}
function getRowSelection({ projectPk, isSelectionEnabled, }) {
    let lastSelectedID = '';
    return {
        // This is the width of the checkbox and the "Actions" text
        size: 130,
        id: 'select',
        header({ table }) {
            return (_jsxs(Stack, { direction: "row", align: "center", gap: "4", className: "relative top-0.5", children: [_jsx(Checkbox, { disabled: !isSelectionEnabled, checked: table.getIsAllPageRowsSelected(), onCheckedChange: (value) => table.toggleAllPageRowsSelected(!!value), "aria-label": "Select all", "data-testid": "select-all", size: "sm" }), _jsx(Text, { weight: "medium", variant: "primary", leading: "tight", children: "Actions" })] }));
        },
        cell({ row, table }) {
            const result = row.original;
            return (_jsxs(Stack, { direction: "row", align: "center", gap: "4", children: [_jsx(Checkbox, { checked: row.getIsSelected(), onCheckedChange: (value) => row.toggleSelected(!!value), onClick: (e) => {
                            if (e.shiftKey) {
                                const { rows, rowsById } = table.getRowModel();
                                const lastSelectedRow = rowsById[lastSelectedID];
                                const rowsToToggle = getRowRange(rows, row.index, lastSelectedRow.index);
                                const isLastSelected = !rowsById[row.id].getIsSelected();
                                rowsToToggle.forEach((row) => {
                                    row.toggleSelected(isLastSelected);
                                });
                            }
                            lastSelectedID = row.id;
                        }, "aria-label": "Select row", size: "sm" }), _jsx(IndividualResultActions, { projectPk: projectPk, resultPk: result.pk, resultUUID: result.uuid })] }));
        },
        enableSorting: false,
        enableHiding: false,
        enableColumnFilter: false,
    };
}
function getColumnDefinitionsForStep(columnHelper, step) {
    const flatStepClass = StepUtils.getFlatStepClass(step);
    const createColumn = ({ step, id = step.uuid, sorting, filters = getFiltersForStep(step), visibility, renderValue, renderEmpty = (_, value) => {
        if (isArray(value)) {
            return isEmpty(value);
        }
        return isNil(value);
    }, renderHeader = () => _jsx(HeaderCell, { step: step }), size = CELL_WIDTH, }) => {
        return columnHelper.accessor('stepResponses', {
            id,
            header: renderHeader,
            size,
            cell({ row }) {
                return (_jsx(Row, { row: row, step: step, renderValue: renderValue, renderEmpty: renderEmpty }));
            },
            meta: {
                filters,
                sorting,
                visibility,
                stepUUID: step.uuid,
            },
            enableSorting: !!sorting,
            enableHiding: true,
            enableColumnFilter: filters.length > 0,
        });
    };
    switch (flatStepClass) {
        case FlatStepClass.Instruction:
        case FlatStepClass.MediaSettings:
            return [];
        case FlatStepClass.FiveSecondTest: {
            return [
                createColumn({
                    step,
                    size: 220,
                    renderValue: (stepResponse) => {
                        const value = getNonNullResponseValue(stepResponse, flatStepClass);
                        return (_jsx(Badge, { leadingIcon: value ? _jsx(CheckCircleIcon, {}) : _jsx(CloseCircleIcon, {}), className: "bg-gray-200/50 text-gray-500", children: value ? 'Viewed' : 'Not viewed' }));
                    },
                }),
            ];
        }
        case FlatStepClass.MultipleChoice: {
            return [
                createColumn({
                    step,
                    renderValue: (stepResponse) => {
                        const { uuids, otherValue } = getNonNullResponseValue(stepResponse, flatStepClass);
                        const multipleChoiceStep = getFlatStep(step, flatStepClass);
                        return (_jsx(MultipleChoiceCell, { uuids: uuids, otherValue: otherValue, step: multipleChoiceStep }));
                    },
                }),
            ];
        }
        case FlatStepClass.YesOrNo: {
            return [
                createColumn({
                    step,
                    size: 220,
                    renderValue: (stepResponse) => {
                        const value = getNonNullResponseValue(stepResponse, flatStepClass);
                        return (_jsx(Badge, { leadingIcon: value ? _jsx(CheckCircleIcon, {}) : _jsx(CloseCircleIcon, {}), className: "bg-gray-200/50 text-gray-500", children: value ? 'Yes' : 'No' }));
                    },
                }),
            ];
        }
        case FlatStepClass.PreferenceTest: {
            return [
                createColumn({
                    step,
                    renderValue: (stepResponse) => {
                        const preferenceStep = getFlatStep(step, flatStepClass);
                        const values = getNonNullResponseValue(stepResponse, flatStepClass);
                        return _jsx(PreferenceTestCell, { values: values, step: preferenceStep });
                    },
                }),
            ];
        }
        case FlatStepClass.TaglineCopyTest:
            return [
                createColumn({
                    step,
                    renderValue: (stepResponse) => {
                        const taglineStep = getFlatStep(step, flatStepClass);
                        const values = getNonNullResponseValue(stepResponse, flatStepClass);
                        return _jsx(TaglineCopyCell, { values: values, step: taglineStep });
                    },
                }),
            ];
        case FlatStepClass.LegalStep:
            return [
                createColumn({
                    step,
                    size: 260,
                    renderValue: (stepResponse) => {
                        const value = getNonNullResponseValue(stepResponse, flatStepClass);
                        return (_jsx(Badge, { leadingIcon: value ? _jsx(CheckCircleIcon, {}) : _jsx(CloseCircleIcon, {}), className: "bg-gray-200/50 text-gray-500", children: value ? 'Yes, I accept' : "No, I don't accept" }));
                    },
                }),
            ];
        case FlatStepClass.QuestionDate:
            return [
                createColumn({
                    step,
                    size: 220,
                    renderValue: (stepResponse) => {
                        const value = getNonNullResponseValue(stepResponse, flatStepClass);
                        return _jsx(DateCell, { value: value });
                    },
                }),
            ];
        case FlatStepClass.QuestionNumerical:
            return [
                createColumn({
                    step,
                    size: 220,
                    renderValue: (stepResponse) => {
                        const value = getNonNullResponseValue(stepResponse, flatStepClass);
                        return _jsx(NumericCell, { value: value });
                    },
                }),
            ];
        case FlatStepClass.CardSortingClosed:
        case FlatStepClass.CardSortingHybrid:
        case FlatStepClass.CardSortingOpen:
        case FlatStepClass.QuestionText: {
            return [
                createColumn({
                    step,
                    renderValue: (stepResponse) => {
                        return (_jsx(QuestionTextCell, { value: getResponseValue(stepResponse, flatStepClass) }));
                    },
                }),
            ];
        }
        case FlatStepClass.QuestionEmail: {
            return [
                createColumn({
                    step,
                    renderValue: (stepResponse) => {
                        return (_jsx(EmailCell, { value: getNonNullResponseValue(stepResponse, flatStepClass) }));
                    },
                }),
            ];
        }
        case FlatStepClass.RatingScale: {
            return [
                createColumn({
                    step,
                    size: 280,
                    renderValue: (stepResponse) => {
                        const value = getNonNullResponseValue(stepResponse, flatStepClass);
                        const ratingStep = getFlatStep(step, flatStepClass);
                        return _jsx(RatingCell, { step: ratingStep, value: value });
                    },
                }),
            ];
        }
        case FlatStepClass.GoalBasedStep:
        case FlatStepClass.FreeRoamStep: {
            const columns = [
                createColumn({
                    id: `${step.uuid}-misclick-rate`,
                    step,
                    visibility: {
                        name: 'Misclicks',
                        id: `${step.uuid}=misclick-rate`,
                    },
                    size: 200,
                    renderHeader: () => (_jsx(HeaderCell, { step: step, subType: "Misclicks", isFirst: flatStepClass !== FlatStepClass.GoalBasedStep })),
                    renderEmpty(stepResponse) {
                        var _a;
                        return isNil((_a = stepResponse.stats) === null || _a === void 0 ? void 0 : _a.misclickRate);
                    },
                    renderValue(stepResponse) {
                        var _a;
                        if (isNil((_a = stepResponse.stats) === null || _a === void 0 ? void 0 : _a.misclickRate)) {
                            return null;
                        }
                        return (_jsx(MisclickRateCell, { value: stepResponse.stats.misclickRate }));
                    },
                }),
                createColumn({
                    id: `${step.uuid}-duration`,
                    step,
                    visibility: {
                        name: 'Duration',
                        id: `${step.uuid}=duration`,
                    },
                    size: 200,
                    renderHeader: () => _jsx(HeaderCell, { step: step, subType: "Duration" }),
                    renderEmpty(stepResponse) {
                        var _a;
                        return isNil((_a = stepResponse.stats) === null || _a === void 0 ? void 0 : _a.durationSeconds);
                    },
                    renderValue(stepResponse) {
                        var _a;
                        if (isNil((_a = stepResponse.stats) === null || _a === void 0 ? void 0 : _a.durationSeconds)) {
                            return null;
                        }
                        return _jsx(DurationCell, { value: stepResponse.stats.durationSeconds });
                    },
                    filters: [],
                }),
            ];
            if (flatStepClass !== FlatStepClass.FreeRoamStep) {
                columns.unshift(createColumn({
                    id: `${step.uuid}-hit-goal`,
                    step,
                    visibility: {
                        name: 'Goal Hit',
                        id: `${step.uuid}=hit-goal`,
                    },
                    size: 200,
                    renderHeader: () => (_jsx(HeaderCell, { step: step, subType: "Goal Hit", isFirst: true })),
                    renderEmpty(stepResponse) {
                        var _a;
                        return isNil((_a = stepResponse.stats) === null || _a === void 0 ? void 0 : _a.goalHit);
                    },
                    renderValue(stepResponse) {
                        var _a;
                        if (isNil((_a = stepResponse.stats) === null || _a === void 0 ? void 0 : _a.goalHit)) {
                            return null;
                        }
                        return (_jsx(BooleanCell, { value: stepResponse.stats.goalHit, trueText: "Yes", falseText: "No" }));
                    },
                    filters: [],
                }));
            }
            return columns;
        }
        case FlatStepClass.FirstClickStep: {
            return [
                createColumn({
                    id: `${step.uuid}-duration`,
                    step,
                    size: 200,
                    renderEmpty(stepResponse) {
                        var _a;
                        return isNil((_a = stepResponse.stats) === null || _a === void 0 ? void 0 : _a.durationSeconds);
                    },
                    renderValue(stepResponse) {
                        var _a;
                        if (isNil((_a = stepResponse.stats) === null || _a === void 0 ? void 0 : _a.durationSeconds)) {
                            return null;
                        }
                        return _jsx(DurationCell, { value: stepResponse.stats.durationSeconds });
                    },
                }),
            ];
        }
        case FlatStepClass.WebsiteTask: {
            return [
                createColumn({
                    id: `${step.uuid}-duration`,
                    step,
                    size: 220,
                    renderEmpty(stepResponse) {
                        return isNil(stepResponse.duration);
                    },
                    renderValue(stepResponse) {
                        return _jsx(DurationCell, { value: stepResponse.duration });
                    },
                }),
            ];
        }
        default:
            // eslint-disable-next-line no-console
            console.error('Unknown step type', flatStepClass);
            return [];
    }
}
const Row = observer(function Row({ row, step, renderValue, renderEmpty = () => false, EmptyRow = () => _jsx(MDash, {}), }) {
    const stepResponse = getStepResponseByUUID(row.original.stepResponses, step.uuid);
    if (!stepResponse ||
        renderEmpty(stepResponse, getResponseValue(stepResponse, StepUtils.getFlatStepClass(step)))) {
        return _jsx(EmptyRow, {});
    }
    return renderValue(stepResponse);
});
function getStepResponseByUUID(stepResponses, uuid) {
    return stepResponses.find((stepResponse) => stepResponse.stepUuid === uuid);
}
const MDash = observer(function MDash() {
    return _jsx(_Fragment, { children: "\u00A0" });
});
export function groupColumn(ID, userTest) {
    if (!ID) {
        return 'general';
    }
    if (ID.includes('participantId') || ID.includes('studyId')) {
        return 'Rally';
    }
    if (ScreenerUtils.isScreenerStep(userTest, ID)) {
        return 'screeners';
    }
    if (ID === null || ID === void 0 ? void 0 : ID.includes('step_')) {
        return 'steps';
    }
    return 'general';
}
function getRowRange(rows, currentID, selectedID) {
    const rangeStart = selectedID > currentID ? currentID : selectedID;
    const rangeEnd = rangeStart === currentID ? selectedID : currentID;
    return rows.slice(rangeStart, rangeEnd + 1);
}
export function getHasMediaEnabled(userTest) {
    const docPerms = getRecordingPermissions(userTest);
    if (docPerms.microphone || docPerms.webcam || docPerms.screen) {
        return true;
    }
    return userTest === null || userTest === void 0 ? void 0 : userTest.steps.some((uuid) => {
        const step = getStepByUUID(userTest, uuid);
        // step recording is no longer supported
        // however we will continue to support existing responses which have step recordings
        if (step &&
            (StepUtils.isOfType(step, StepClass.PrototypeTask) ||
                StepUtils.isOfType(step, StepClass.WebsiteTask))) {
            const { microphone, webcam, screen } = StepUtils.getRecordingPermissions(step);
            return microphone || webcam || screen;
        }
        return false;
    });
}
