import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { observer } from 'mobx-react';
import { useCallback } from 'react';
import * as React from 'react';
import { Badge, BtwSettingsItem, Stack } from '@marvelapp/ballpark-components';
import { UserTestStatus, formatDateAndTime } from '@marvelapp/core';
import { useUpdateUserTestStatus } from '../../graphql/mutations';
export const PauseProjectSetting = observer(function PauseProjectSetting({ projectStatus, userTestPk, pausedAt, }) {
    const [updateTestStatus] = useUpdateUserTestStatus();
    const toggleTestStatus = useCallback(() => {
        const newTestStatus = projectStatus === UserTestStatus.Paused
            ? UserTestStatus.Active
            : UserTestStatus.Paused;
        updateTestStatus(newTestStatus, userTestPk);
    }, [projectStatus, updateTestStatus, userTestPk]);
    return (_jsx(BtwSettingsItem, { description: "Prevent any further participants from taking the test", heading: _jsxs(Stack, { direction: "row", align: "center", gap: "2", children: ["Pause project", ' ', projectStatus === UserTestStatus.Paused && (_jsx(Badge, { size: "sm", variant: "amber", children: getFormattedPausedDate(pausedAt) }))] }), "data-testid": "pause-test-toggle", onPressedChange: toggleTestStatus, pressed: projectStatus === UserTestStatus.Paused, variant: "legacyBlue" }));
});
function getFormattedPausedDate(dateTimestamp) {
    if (!dateTimestamp)
        return '';
    const { date, time } = formatDateAndTime(new Date(dateTimestamp), 'dd/MM/yy');
    return `Paused on ${date} @ ${time}`;
}
