import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { observer } from 'mobx-react';
import * as React from 'react';
import { CornerButton, Icon } from '@marvelapp/ballpark-components';
import { CLOSE_ICON } from '@marvelapp/ballpark-theme';
import styled from '@marvelapp/styled';
import { Box } from '@marvelapp/ui';
export const INPUT_STATE = {
    Focussed: 'Focussed',
    Blurred: 'Blurred',
};
export default observer(function PreferenceItem({ canEdit = true, children, index, isDragging, isFocussed, onDelete, }) {
    return (_jsxs(_Fragment, { children: [_jsx(PreferenceBox, { canEdit: canEdit, isDragging: isDragging, isFocussed: isFocussed, children: children }), onDelete && (_jsx(CornerButton, { icon: _jsx(Icon, { paths: CLOSE_ICON }), kind: "negative", onClick: onDelete, testId: `delete-button-${index}` }))] }));
});
export const PreferenceBox = observer(function PreferenceBox({ canEdit, children, isDragging, isFocussed, }) {
    return (_jsx(Container, { canEdit: canEdit, isDragging: isDragging, isFocussed: isFocussed, "data-testid": "choice", children: children }));
});
const getContainerBoxShadow = ({ isFocussed, isDragging }) => {
    if (isFocussed) {
        return 'editable.focus';
    }
    if (isDragging) {
        return 'popover.default';
    }
    return 'button.secondary';
};
const getContainerHoverBoxShadow = ({ canEdit, isDragging, isFocussed, }) => {
    if (!canEdit) {
        return undefined;
    }
    if (isFocussed) {
        return 'editable.focus';
    }
    if (isDragging) {
        return 'popover.default';
    }
    return 'editable.hover';
};
const Container = styled(Box).attrs((props) => ({
    bg: 'background',
    borderRadius: 'l',
    border: '1px solid',
    borderColor: props.isDragging ? 'transparent' : 'slate6',
    boxShadow: getContainerBoxShadow({
        isDragging: props.isDragging,
        isFocussed: props.isFocussed,
    }),
    hoverBoxShadow: getContainerHoverBoxShadow({
        canEdit: props.canEdit,
        isDragging: props.isDragging,
        isFocussed: props.isFocussed,
    }),
    cursor: props.isDragging ? 'grab' : 'default',
    paddingX: 'xs',
    paddingY: 'xs',
})) `
  width: 100%;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  text-align: center;
`;
