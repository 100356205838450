import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { observer } from 'mobx-react';
import { forwardRef, lazy, useCallback } from 'react';
import * as React from 'react';
import { useLocation } from 'react-router-dom';
import { useAction, useFeatureFlag } from '@marvelapp/ballpark-application';
import { usePrototypeState } from '../Record/PrototypeContext';
import { ManageFigmaPrototypeCaching } from './ManageFigmaPrototypeCaching';
const PrototypeViewer = lazy(() => import(
/* webpackChunkName: "PrototypeViewer" */ '@marvelapp/prototype-viewer').then((module) => ({ default: module.PrototypeViewer })));
const CachedPrototypeViewer = ({ handleEvent, onLoading, onReady: _onReady, isFirstClick }, ref) => {
    const prototypeState = usePrototypeState();
    const location = useLocation();
    const debugPrototypes = useFeatureFlag('debug-figma-prototypes') ||
        // support the old way too
        new URLSearchParams(location.search).get('mode') === 'debug';
    const onLoadScreensStart = useAction(() => {
        prototypeState.screens = null;
    }, [prototypeState]);
    const onLoadScreensEnd = useAction((screens) => {
        prototypeState.screens = screens;
    }, [prototypeState]);
    const onReady = useCallback(() => {
        var _a, _b, _c;
        if (debugPrototypes) {
            if ((_a = prototypeState.step) === null || _a === void 0 ? void 0 : _a.startScreen) {
                (_b = prototypeState.prototypeViewerEl) === null || _b === void 0 ? void 0 : _b.showScreen((_c = prototypeState.step) === null || _c === void 0 ? void 0 : _c.startScreen);
            }
        }
        _onReady();
    }, [debugPrototypes, prototypeState, _onReady]);
    if (!prototypeState.prototypeUrl) {
        // shouldn't happen because this component is only rendered when the parent
        // has a prototypeUrl
        return null;
    }
    return (_jsxs(_Fragment, { children: [_jsx(PrototypeViewer, { ref: ref, handleEvent: handleEvent, onReady: onReady, onLoading: onLoading, isFirstClick: isFirstClick, onLoadScreensStart: onLoadScreensStart, onLoadScreensEnd: onLoadScreensEnd, prototypeUrl: prototypeState.prototypeUrl, overrideEmbedUrl: prototypeState.embedUrl, renderExternalPrototypes: !debugPrototypes }), _jsx(ManageFigmaPrototypeCaching, {})] }));
};
export default observer(forwardRef(CachedPrototypeViewer));
