import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { has } from 'lodash-es';
import { observer } from 'mobx-react';
import { useMemo, useState } from 'react';
import { CardSortingAgreementTable, FullscreenModal, StepButtons, SummaryTabs, Table, } from '@marvelapp/ballpark-components';
import { UNSORTED_CATEGORY_UUID } from './metricUtils';
import { useMetricsContext } from './metricsContext';
function sortString(a, b) {
    return a.localeCompare(b);
}
export const AgreementMatrix = observer(function AgreementMatrix() {
    const { cardAgreement, categoryAgreementAvg, cardUUIDToCardMap, categoryUUIDToCategoryMap, } = useMetricsContext();
    const [isBreakdownModalOpen, setIsBreakdownModalOpen] = useState(false);
    // filter out any cards/categories that don't exist anymore (may have been deleted since the metric was created)
    const filteredCardAgreement = useMemo(() => {
        return cardAgreement.filter((agreement) => has(cardUUIDToCardMap, agreement.cardUuid) &&
            has(categoryUUIDToCategoryMap, agreement.categoryUuid));
    }, [cardAgreement, cardUUIDToCardMap, categoryUUIDToCategoryMap]);
    const categoryAgreementAvgByUuid = useMemo(() => {
        return categoryAgreementAvg.reduce((acc, metric) => {
            // eslint-disable-next-line no-param-reassign
            acc[metric.categoryUuid] = parseFloat(metric.value);
            return acc;
        }, {});
    }, [categoryAgreementAvg]);
    const categories = useMemo(() => Object.values(categoryUUIDToCategoryMap).sort((a, b) => {
        var _a, _b, _c, _d;
        // ensure unsorted is always last
        if (a.uuid === UNSORTED_CATEGORY_UUID)
            return 1;
        if (b.uuid === UNSORTED_CATEGORY_UUID)
            return -1;
        // otherwise first sort categories by average agreement
        const aAvgAgreement = (_a = categoryAgreementAvgByUuid[a.uuid]) !== null && _a !== void 0 ? _a : 0;
        const bAvgAgreement = (_b = categoryAgreementAvgByUuid[b.uuid]) !== null && _b !== void 0 ? _b : 0;
        if (aAvgAgreement !== bAvgAgreement) {
            return bAvgAgreement - aAvgAgreement;
        }
        // if average agreement is the same, sort by count of cards sorted into the category
        const aCardCount = filteredCardAgreement.filter((agreement) => agreement.categoryUuid === a.uuid).length;
        const bCardCount = filteredCardAgreement.filter((agreement) => agreement.categoryUuid === b.uuid).length;
        if (aCardCount !== bCardCount) {
            return bCardCount - aCardCount;
        }
        // if all else is equal sort by title alphabetically
        return sortString((_c = a.title) !== null && _c !== void 0 ? _c : '', (_d = b.title) !== null && _d !== void 0 ? _d : '');
    }), [
        categoryAgreementAvgByUuid,
        categoryUUIDToCategoryMap,
        filteredCardAgreement,
    ]);
    const filteredCategories = useMemo(() => {
        const hasUnsorted = filteredCardAgreement.some((agreement) => agreement.categoryUuid === UNSORTED_CATEGORY_UUID);
        if (hasUnsorted)
            return categories;
        return categories.filter((category) => category.uuid !== UNSORTED_CATEGORY_UUID);
    }, [categories, filteredCardAgreement]);
    const categoryAgreementByCard = useMemo(() => {
        return filteredCardAgreement.reduce((acc, metric) => {
            if (!acc[metric.cardUuid]) {
                // eslint-disable-next-line no-param-reassign
                acc[metric.cardUuid] = {};
            }
            // eslint-disable-next-line no-param-reassign
            acc[metric.cardUuid][metric.categoryUuid] = parseFloat(metric.value);
            return acc;
        }, {});
    }, [filteredCardAgreement]);
    const cards = useMemo(() => Object.values(cardUUIDToCardMap).sort((a, b) => {
        var _a, _b, _c, _d, _e, _f;
        for (let i = 0; i < filteredCategories.length; i += 1) {
            const category = filteredCategories[i];
            const aAgreement = (_b = (_a = categoryAgreementByCard[a.uuid]) === null || _a === void 0 ? void 0 : _a[category.uuid]) !== null && _b !== void 0 ? _b : 0;
            const bAgreement = (_d = (_c = categoryAgreementByCard[b.uuid]) === null || _c === void 0 ? void 0 : _c[category.uuid]) !== null && _d !== void 0 ? _d : 0;
            if (aAgreement !== bAgreement) {
                return bAgreement - aAgreement;
            }
        }
        // if all else fails sort them alphabetically
        return sortString((_e = a.title) !== null && _e !== void 0 ? _e : '', (_f = b.title) !== null && _f !== void 0 ? _f : '');
    }), [cardUUIDToCardMap, filteredCategories, categoryAgreementByCard]);
    const categoryUUIDtoColumn = useMemo(() => {
        return filteredCategories.reduce((acc, category, index) => {
            // eslint-disable-next-line no-param-reassign
            acc[category.uuid] = index;
            return acc;
        }, {});
    }, [filteredCategories]);
    const cardUUIDToRow = useMemo(() => {
        return cards.reduce((acc, card, index) => {
            // eslint-disable-next-line no-param-reassign
            acc[card.uuid] = index;
            return acc;
        }, {});
    }, [cards]);
    const agreementMatrix = useMemo(() => {
        const matrix = Array.from({ length: cards.length }, () => Array.from({ length: filteredCategories.length }, () => '0'));
        filteredCardAgreement.forEach((agreement) => {
            const row = cardUUIDToRow[agreement.cardUuid];
            const column = categoryUUIDtoColumn[agreement.categoryUuid];
            matrix[row][column] = agreement.value;
        });
        return matrix;
    }, [
        filteredCardAgreement,
        cardUUIDToRow,
        cards.length,
        filteredCategories.length,
        categoryUUIDtoColumn,
    ]);
    const stickyCellClass = 'sticky left-0 top-0 z-10 rounded-none bg-white pl-8 pr-4';
    return (_jsxs(_Fragment, { children: [_jsxs(CardSortingAgreementTable.Root, { categoriesCount: filteredCategories.length, "data-testid": "agreement-matrix", children: [_jsx(Table.Thead, { children: _jsxs(Table.Tr, { children: [_jsx(CardSortingAgreementTable.Cell, { className: stickyCellClass, row: -1, column: -1 }), filteredCategories.map((category, i) => (_jsx(CardSortingAgreementTable.Cell, { align: "center", title: category.title, "data-testid": "agreement-matrix-category", className: "w-auto", row: -1, column: i }, category.uuid)))] }) }), _jsx(Table.Tbody, { children: cards.map((card, i) => (_jsxs(Table.Tr, { className: "relative", children: [_jsx(CardSortingAgreementTable.Cell
                                // TODO: description is richText, need to convert to string / html
                                // description={card.description}
                                , { 
                                    // TODO: description is richText, need to convert to string / html
                                    // description={card.description}
                                    title: card.title, imageSrc: card.imageUrl, className: stickyCellClass, "data-testid": "agreement-matrix-card", row: i, column: -1 }), agreementMatrix[i].map((value, j) => (_jsx(CardSortingAgreementTable.Cell, { agreement: parseFloat(value), align: "center", className: "h-full w-auto", "data-testid": "agreement-matrix-value", row: i, column: j }, `${card.uuid}-${filteredCategories[j].uuid}`)))] }, card.uuid))) })] }), _jsx(SummaryTabs.FooterCta, { children: _jsx(FullscreenModal, { onOpenChange: setIsBreakdownModalOpen, open: isBreakdownModalOpen, trigger: _jsx(StepButtons.ViewBreakdown, {}), children: isBreakdownModalOpen && (_jsx("div", { className: "h-full w-full", children: _jsxs(Table, { className: "border-separate border-spacing-1 overflow-auto p-2 pr-8 pt-0", minWidth: filteredCategories.length * 150, "data-testid": "agreement-matrix-fullscreen", children: [_jsx(Table.Thead, { children: _jsxs(Table.Tr, { children: [_jsx(Table.Td, { className: "w-48" }), filteredCategories.map((category, i) => (_jsx(CardSortingAgreementTable.Cell, { align: "center", title: category.title, className: "w-auto", "data-testid": "agreement-matrix-category", row: -1, column: i }, category.uuid)))] }) }), _jsx(Table.Tbody, { children: cards.map((card, i) => (_jsxs(Table.Tr, { children: [_jsx(CardSortingAgreementTable.Cell
                                            // TODO: description is richText, need to convert to string / html
                                            // description={card.description}
                                            , { 
                                                // TODO: description is richText, need to convert to string / html
                                                // description={card.description}
                                                title: card.title, className: "sticky left-0 top-0 z-10 w-48 rounded-none bg-white pl-8 pr-4", "data-testid": "agreement-matrix-card", row: i, column: -1 }), agreementMatrix[i].map((value, j) => (_jsx(CardSortingAgreementTable.Cell, { agreement: parseFloat(value), align: "center", "data-testid": "agreement-matrix-value", className: "w-auto", row: i, column: j }, `${card.uuid}-${filteredCategories[j].uuid}`)))] }, card.uuid))) })] }) })) }) })] }));
});
