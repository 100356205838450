import { __rest } from "tslib";
import { jsx as _jsx } from "react/jsx-runtime";
import { forwardRef } from 'react';
import Position from './Position';
/** @type {any} */
const PinCenter = forwardRef((_a, ref) => {
    var { children } = _a, props = __rest(_a, ["children"]);
    return (_jsx(Position, Object.assign({ left: "50%", position: "absolute", ref: ref, top: "50%", transform: "translate(-50%, -50%)" }, props, { children: children })));
});
export default PinCenter;
