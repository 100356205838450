/**
 * Translates seconds into human readable format of hh:mm:ss
 *
 * @param seconds The number of seconds to be processed
 * @returns A string describing the amount of time (e.g. "1:01" or "4:03:59" or "123:03:59")
 */
export function formatTime(seconds, withMilliseconds = true) {
    // If this is sub 1 second and greater than 0 then we show it as milliseconds
    // Note that we don't want to format the time in milliseconds in certain cases
    // (e.g. video player scrubber), so I had to add another paramater to disable it
    if (withMilliseconds && seconds < 1 && seconds > 0) {
        const millis = seconds.toFixed(2);
        return `${millis}ms`;
    }
    const hours = Math.floor(seconds / 3600);
    const mins = Math.floor((seconds % 3600) / 60);
    const secs = Math.floor(seconds % 60);
    let ret = '';
    if (hours > 0) {
        ret += `${hours}:${mins < 10 ? '0' : ''}`;
    }
    ret += `${mins}:${secs < 10 ? '0' : ''}`;
    ret += `${secs}`;
    return ret;
}
