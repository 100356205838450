import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { observer } from 'mobx-react';
import * as React from 'react';
import styled from '@marvelapp/styled';
import { Box } from '@marvelapp/ui';
import EditableRadio from '../../components/Editable/EditableRadio';
import InteractionBlocker from '../../components/InteractionBlocker';
import { DecoratedSlide } from '../common/DecoratedSlide';
import SlideContent from '../common/SlideContent';
export default observer(function YesNoEdit({ onMainCTAClick, }) {
    return (_jsx(DecoratedSlide, { children: _jsx(SlideContent, { ctaOnClick: onMainCTAClick, children: _jsx(InputsWrapper, { children: _jsxs(InteractionBlocker, { children: [_jsx(RadioWrapper, { children: _jsx(EditableRadio, { readOnly: true, value: "Yes", onChange: () => null }) }), _jsx(RadioWrapper, { children: _jsx(EditableRadio, { readOnly: true, value: "No", onChange: () => null }) })] }) }) }) }));
});
const InputsWrapper = styled(Box).attrs({
    marginTop: 's',
}) `
  width: 100%;
  display: flex;
  flex-direction: column;
  max-width: 480px;
`;
const RadioWrapper = styled(Box).attrs({
    marginBottom: 's',
}) `
  > input {
    width: 100%;
    max-width: 300px;
  }
  &:last-child {
    margin: 0;
  }
`;
