import { jsx as _jsx } from "react/jsx-runtime";
import { observer } from 'mobx-react';
import * as React from 'react';
import styled from '@marvelapp/styled';
import { Modal as BadModal, Box } from '@marvelapp/ui';
import { useMaybeRecorderState } from './Record/RecorderContext';
const ZoomModal = observer(function ZoomModal({ children, closeButtonTestID, isOpen, onClose, header, withBackground = true, }) {
    const maybeRecorderState = useMaybeRecorderState();
    return (_jsx(BadModal, { header: header, backgroundImage: withBackground &&
            'url(data:image/gif;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABAAQMAAACQp+OdAAAABlBMVEUAAADf4+YfiCygAAAAAXRSTlMAQObYZgAAABVJREFUeAFjGGDAfACE8TEGMxgNHwCzGwwxfM2OzgAAAABJRU5ErkJggg==)', backgroundSize: "32px 32px", closeButtonTestID: closeButtonTestID, isOpen: isOpen, isLive: maybeRecorderState === null || maybeRecorderState === void 0 ? void 0 : maybeRecorderState.isLive, trigger: onClose, width: "100%", children: _jsx(Container, { children: children }) }));
});
export default ZoomModal;
const Container = styled(Box).attrs({
    padding: 'xl',
}) `
  min-height: 100%;
  min-width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
