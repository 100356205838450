import { __rest } from "tslib";
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { observer } from 'mobx-react';
import * as React from 'react';
import { formatTime } from '@marvelapp/core';
import { StepClass, StepUtils } from '@marvelapp/user-test-creator';
import StatBox from '../../../components/PrototypeStat';
import PrototypeStatsGrid from '../../../components/PrototypeStatsGrid';
import { useStepContext } from '../../../utils/stepContext';
import { FirstClickHeatmap } from '../../Response/Heatmaps/FirstClickHeatmap';
import { SummaryStep } from './SummaryStep';
export default observer(function SummaryFirstClick(_a) {
    var { active, secondary, showAllLink, stats } = _a, rest = __rest(_a, ["active", "secondary", "showAllLink", "stats"]);
    const { step } = useStepContext();
    if (!StepUtils.isOfType(step, StepClass.PrototypeTask))
        return null;
    return (_jsx(SummaryStep, Object.assign({ active: active, secondary: secondary, showAllLink: showAllLink }, rest, { children: step.importedPrototypeUrl && (
        // TODO: (refactor) Use context to get step in `FirstClickHeatmap`
        // when it's available in all places
        _jsxs(_Fragment, { children: [_jsx(FirstClickHeatmap, { stepUUID: step.uuid, prototypeUrl: step.importedPrototypeUrl }), stats && (_jsx(_Fragment, { children: stats.averageDuration !== null && (_jsx(PrototypeStatsGrid, { mt: "xl", children: _jsx(StatBox, { kind: "slate", title: "Avg. duration to first click", stat: formatTime(stats.averageDuration), testId: "duration" }) })) }))] })) })));
});
