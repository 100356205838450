import { __rest } from "tslib";
import { jsx as _jsx } from "react/jsx-runtime";
import { observer } from 'mobx-react';
import * as React from 'react';
import { Grid } from '@marvelapp/ui';
import { TextUtils } from '@marvelapp/user-test-creator';
import { TaglineTestGridItem } from '../../../slides/TaglineCopyTest/TaglineTestRecordGridItem';
import { Answer } from './Answer';
export const TaglineCopyAnswer = observer(function TaglineCopyAnswer(_a) {
    var { answers, step } = _a, restProps = __rest(_a, ["answers", "step"]);
    const hasAnswer = !!(answers === null || answers === void 0 ? void 0 : answers.length);
    return (_jsx(Answer, Object.assign({ step: step }, restProps, { children: hasAnswer && (_jsx(Grid, { gridGap: "s", gridTemplateColumns: "1fr 1fr", children: step.choices
                .filter((uuid) => {
                const { heading, paragraph } = step.choiceDefinitions[uuid];
                const isParagraphEmpty = TextUtils.checkIfValueIsEmpty(paragraph);
                return !!heading || !isParagraphEmpty;
            })
                .map((choiceUUID, index) => {
                const { heading, paragraph } = step.choiceDefinitions[choiceUUID];
                if (!hasAnswer || !Array.isArray(answers[0].value))
                    return null;
                const isSelected = answers[0].value.includes(choiceUUID);
                return (_jsx(TaglineTestGridItem, { headingValue: heading, id: choiceUUID, index: index, isChecked: isSelected, paragraphValue: paragraph, optionNumber: index, readOnly: true }, choiceUUID));
            }) })) })));
});
