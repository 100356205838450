import { __rest } from "tslib";
import { jsx as _jsx } from "react/jsx-runtime";
import { observer } from 'mobx-react';
import * as React from 'react';
import { formatDuration } from '@marvelapp/core';
import { PrototypeTaskUtils, ScreenerUtils, StepClass, isExitStep as _isExitStep, } from '@marvelapp/user-test-creator';
import { formatAnswers } from '../../utils/formatAnswers';
import { Answer } from './answerSlides/Answer';
import { CardSortingAnswer } from './answerSlides/CardSortingAnswer';
import { FirstClickAnswer } from './answerSlides/FirstClickAnswer';
import { FiveSecondAnswer } from './answerSlides/FiveSecondAnswer';
import { MultipleChoiceAnswer } from './answerSlides/MultipleChoiceAnswer';
import { PreferenceAnswer } from './answerSlides/PreferenceAnswer';
import PrototypeAnswer from './answerSlides/PrototypeAnswer';
import { QuestionAnswer } from './answerSlides/QuestionAnswer';
import { RatingAnswer } from './answerSlides/RatingAnswer';
import { ScreenerAnswer } from './answerSlides/ScreenerAnswer';
import { TaglineCopyAnswer } from './answerSlides/TaglineCopyAnswer';
import { WebsiteAnswer } from './answerSlides/WebsiteAnswer';
import { YesNoAnswer } from './answerSlides/YesNoAnswer';
export const SlideRenderer = observer(function SlideRenderer(_a) {
    var _b, _c, _d, _e, _f, _g;
    var { notSeenStep, step, stepResponse, userTestResult, prototypeStepPlayingUrl, setPrototypeStepPlayingUrl, userTest } = _a, restProps = __rest(_a, ["notSeenStep", "step", "stepResponse", "userTestResult", "prototypeStepPlayingUrl", "setPrototypeStepPlayingUrl", "userTest"]);
    const skipped = stepResponse === null || stepResponse === void 0 ? void 0 : stepResponse.stepSkipped;
    const answers = (stepResponse === null || stepResponse === void 0 ? void 0 : stepResponse.answers)
        ? formatAnswers(stepResponse === null || stepResponse === void 0 ? void 0 : stepResponse.answers)
        : [];
    const duration = (_b = stepResponse === null || stepResponse === void 0 ? void 0 : stepResponse.duration) !== null && _b !== void 0 ? _b : null;
    // a "notSeenStep" denotes steps that follow a bounce step
    // here I check that the step isn't a post-bounce step, and that the
    // duration is null, to determine it has not been seen due to conditional logic routing
    const hasBeenConditionallySkipped = !notSeenStep && duration === null;
    const secondaryCopy = useGetSecondaryCopy({
        userTest,
        notSeenStep,
        step,
        stepResponse,
    });
    const commonProps = Object.assign({ notAnswered: skipped || !!notSeenStep, hasBeenConditionallySkipped }, restProps);
    switch (step.className) {
        case StepClass.Instruction:
            return _jsx(Answer, Object.assign({ secondary: secondaryCopy, step: step }, commonProps));
        case StepClass.MultipleChoice:
            if (ScreenerUtils.isScreenerStep(userTest, step.uuid)) {
                return (_jsx(ScreenerAnswer, Object.assign({ answers: answers, secondary: secondaryCopy, step: step }, commonProps)));
            }
            return (_jsx(MultipleChoiceAnswer, Object.assign({ answers: answers, secondary: secondaryCopy, step: step }, commonProps)));
        case StepClass.Question:
            return (_jsx(QuestionAnswer, Object.assign({ answers: answers, secondary: secondaryCopy, step: step }, commonProps)));
        case StepClass.YesOrNo:
            return (_jsx(YesNoAnswer, Object.assign({ answers: answers, secondary: secondaryCopy, step: step }, commonProps)));
        case StepClass.RatingScale:
            return (_jsx(RatingAnswer, Object.assign({ answers: answers, secondary: secondaryCopy, step: step }, commonProps)));
        case StepClass.PrototypeTask: {
            if (PrototypeTaskUtils.isFirstClick(step)) {
                return (_jsx(FirstClickAnswer, Object.assign({ step: step, resultPk: userTestResult.pk, timeToFirstClick: (_c = stepResponse === null || stepResponse === void 0 ? void 0 : stepResponse.stats) === null || _c === void 0 ? void 0 : _c.durationSeconds, secondary: secondaryCopy }, commonProps)));
            }
            return (_jsx(PrototypeAnswer, Object.assign({ duration: (_d = stepResponse === null || stepResponse === void 0 ? void 0 : stepResponse.stats) === null || _d === void 0 ? void 0 : _d.durationSeconds, goalHit: (_e = stepResponse === null || stepResponse === void 0 ? void 0 : stepResponse.stats) === null || _e === void 0 ? void 0 : _e.goalHit, misclicks: (_f = stepResponse === null || stepResponse === void 0 ? void 0 : stepResponse.stats) === null || _f === void 0 ? void 0 : _f.misclickRate, recordings: {
                    screen: stepResponse === null || stepResponse === void 0 ? void 0 : stepResponse.screenRecording,
                    user: stepResponse === null || stepResponse === void 0 ? void 0 : stepResponse.userRecording,
                    download: stepResponse === null || stepResponse === void 0 ? void 0 : stepResponse.downloadRecording,
                }, secondary: secondaryCopy, step: step, stepResponse: stepResponse, userTest: userTest, userTestResult: userTestResult, playingUrl: prototypeStepPlayingUrl }, commonProps)));
        }
        case StepClass.PreferenceTest:
            return (_jsx(PreferenceAnswer, Object.assign({ answers: answers, secondary: secondaryCopy, step: step }, commonProps)));
        case StepClass.TaglineCopyTest:
            return (_jsx(TaglineCopyAnswer, Object.assign({ answers: answers, secondary: secondaryCopy, step: step }, commonProps)));
        case StepClass.FiveSecondTest:
            return (_jsx(FiveSecondAnswer, Object.assign({ imageUrl: step.imageUrl, secondary: secondaryCopy, step: step }, commonProps)));
        case StepClass.WebsiteTask: {
            return (_jsx(WebsiteAnswer, Object.assign({ duration: (_g = stepResponse === null || stepResponse === void 0 ? void 0 : stepResponse.stats) === null || _g === void 0 ? void 0 : _g.durationSeconds, recordings: {
                    screen: stepResponse === null || stepResponse === void 0 ? void 0 : stepResponse.screenRecording,
                    user: stepResponse === null || stepResponse === void 0 ? void 0 : stepResponse.userRecording,
                    download: stepResponse === null || stepResponse === void 0 ? void 0 : stepResponse.downloadRecording,
                }, secondary: secondaryCopy, step: step, stepResponse: stepResponse, userTest: userTest, userTestResult: userTestResult }, commonProps)));
        }
        case StepClass.CardSortingClosed:
        case StepClass.CardSortingOpen:
        case StepClass.CardSortingHybrid:
            return (_jsx(CardSortingAnswer, Object.assign({ step: step, stepResponse: stepResponse, secondary: secondaryCopy }, commonProps)));
        default:
            return null;
    }
});
function useGetSecondaryCopy({ userTest, notSeenStep, step, stepResponse, }) {
    var _a, _b;
    if (notSeenStep) {
        return 'The participant did not reach this step';
    }
    if (!stepResponse && !notSeenStep) {
        return 'This step was added after this participant had submitted this response';
    }
    const isExitStep = _isExitStep(userTest, step.uuid);
    const skipped = (_a = stepResponse === null || stepResponse === void 0 ? void 0 : stepResponse.stepSkipped) !== null && _a !== void 0 ? _a : null;
    if (isExitStep && !skipped)
        return 'Completed the test';
    const duration = (_b = stepResponse === null || stepResponse === void 0 ? void 0 : stepResponse.duration) !== null && _b !== void 0 ? _b : null;
    // a "notSeenStep" denotes steps that follow a bounce step
    // here I check that the step isn't a post-bounce step, and that the
    // duration is null, to determine it has not been seen due to conditional logic routing
    const hasBeenConditionallySkipped = !notSeenStep && duration === null;
    if (hasBeenConditionallySkipped) {
        return 'Based on a previous answer the participant did not see this step';
    }
    const text = step.className === StepClass.FiveSecondTest
        ? 'Viewed step for'
        : 'Completed the step in';
    const action = skipped ? 'Skipped after' : text;
    return duration !== null ? `${action} ${formatDuration(duration)}` : '';
}
