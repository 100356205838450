import { __rest } from "tslib";
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { action } from 'mobx';
import { observer } from 'mobx-react';
import * as React from 'react';
import { useIntl } from 'react-intl';
import styled from '@marvelapp/styled';
import { Grid } from '@marvelapp/ui';
import { YesOrNoResponseUtils, } from '@marvelapp/user-test-creator';
import Checkbox from '../common/Checkbox';
import SlideRecord from '../common/SlideRecord';
export default observer(function YesNoRecord(_a) {
    var { stepResponse, step } = _a, rest = __rest(_a, ["stepResponse", "step"]);
    const intl = useIntl();
    const responseAnswer = YesOrNoResponseUtils.getAnswer(stepResponse);
    return (_jsx(SlideRecord, Object.assign({ step: step }, rest, { children: _jsxs(SelectionContainer, { children: [_jsx(Checkbox, { checked: responseAnswer === true, testId: "Yes-answer", groupName: "question", id: "question-yes", name: intl.formatMessage({ id: "0atImS", defaultMessage: "Yes" }), onChange: action(() => YesOrNoResponseUtils.setAnswer(stepResponse, true)), type: "radio", value: "yes" }), _jsx(Checkbox, { checked: responseAnswer === false, testId: "No-answer", groupName: "question", id: "question-no", name: intl.formatMessage({ id: "52lZI8", defaultMessage: "No" }), onChange: action(() => YesOrNoResponseUtils.setAnswer(stepResponse, false)), type: "radio", value: "no" })] }) })));
});
const SelectionContainer = styled(Grid).attrs({
    mt: 'l',
    mb: 'xxs',
    gridGap: 's',
}) `
  width: 100%;
  max-width: 300px;
`;
