import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useServer } from '@ballpark/realtime-plugin-server';
import { useReducedMotion } from 'framer-motion';
import { autorun, reaction, when } from 'mobx';
import { observer } from 'mobx-react';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { FormattedMessage, defineMessage, useIntl } from 'react-intl';
import { Button, Heading, Link, Modal, ModalOverlay, ModalPortal, Text, } from '@marvelapp/ballpark-components';
import { Box, Flex, Loader } from '@marvelapp/ui';
import { useRecorderState } from '../../components/Record/RecorderContext';
import { useReaction } from '../../hooks/useReaction';
function getResponseIdStorageKey(testUUID) {
    // contains a version number so we can invalidate old storage keys if we change the format
    return `${testUUID}_responseUUID_v1`;
}
export function clearResponseInStorage(userTestUUID) {
    const storageKey = getResponseIdStorageKey(userTestUUID);
    window.localStorage.removeItem(storageKey);
}
const TrackCompletion = observer(function TrackCompletion() {
    const server = useServer();
    const recorderState = useRecorderState();
    useEffect(() => 
    // use a reaction so that it only fires when the storage key changes (and therefore the
    // userTestDoc.uuid changes)
    reaction(() => {
        // don't store a reference to continue the response if there is no userTest snapshot in it
        // (there always should be but this is declared an optional field and this safety check must
        // be applied)
        if (recorderState.response.userTest === undefined)
            return null;
        // don't store a reference to continue the response if the user has not progressed past
        // the first step
        if (recorderState.response.timeline.length < 2)
            return null;
        return getResponseIdStorageKey(recorderState.userTestDoc.uuid);
    }, (storageKey) => {
        if (storageKey === null)
            return;
        // store the current responseUUID in local storage against the testUUID so that we can retrieve
        // it if the user does not complete the test
        try {
            window.localStorage.setItem(storageKey, recorderState.response.uuid);
        }
        catch (e) {
            // eslint-disable-next-line no-console
            console.error('Failed to store responseUUID in local storage', e);
        }
    }, { fireImmediately: true }), 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []);
    useEffect(() => reaction(() => {
        return recorderState.isComplete && !server.hasUnsyncedChanges;
    }, (isCompleteAndSynced) => {
        if (!isCompleteAndSynced)
            return;
        clearResponseInStorage(recorderState.userTestDoc.uuid);
    }, { fireImmediately: true }), 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []);
    return null;
});
const ResumeOrSync = observer(function ResumeOrSync({ isResuming = false, onRestart, }) {
    const recorderState = useRecorderState();
    // we only show the sync modal if the test was complete when it was loaded. Otherwise a user
    // may resume a test and on completion it not be synced and then they would see the sync modal
    const [wasCompleteWhenLoaded] = useState(() => recorderState.isComplete);
    // if user was in the screening section when the test was loaded, we don't want to show a resume modal
    // This would also be true for loading a pre-seeded document for recruitment tests (if not resuming)
    const [wasBeingScreenedWhenLoaded] = useState(() => recorderState.isScreening);
    if (wasCompleteWhenLoaded) {
        // previously completed tests that are still referenced in localStorage are unsynced, sync them
        return _jsx(SyncPreviousModal, { onRestart: onRestart });
    }
    if (recorderState.isComplete) {
        // user completed the test, potentially show the sync modal (it handles when it is visible)
        return _jsx(SyncModal, {});
    }
    // if user was in the screening section when the test was loaded, we don't want to show a resume modal
    if (wasBeingScreenedWhenLoaded)
        return null;
    // if this is a live session we don't want to show the resume modal as we'll
    // resume the session automatically
    if (recorderState.isLive)
        return null;
    if (isResuming) {
        // user is resuming and has not completed the test show the resume modal
        return _jsx(ResumeModal, { onRestart: onRestart });
    }
    // not resuming and not complete, user is just taking the test, don't show any resume or sync modals
    return null;
});
const ModalBase = observer(function ModalBase({ heading, description, children, testId, }) {
    const shouldReduceMotion = useReducedMotion();
    return (_jsx(ModalPortal, { children: _jsx(ModalOverlay, { shouldReduceMotion: shouldReduceMotion, children: _jsx(Modal, { testId: testId, children: _jsxs(Box, { textAlign: "left", children: [_jsx(Heading, { size: "xxl", marginBottom: "s", children: heading }), _jsx(Box, { children: _jsx(Text, { size: "l", children: description }) }), children && _jsx(Box, { marginTop: "m", children: children })] }) }) }) }));
});
const ResumeModal = observer(function ResumeModal({ onRestart }) {
    const intl = useIntl();
    const [showModal, setShowModal] = useState(true);
    if (!showModal)
        return null;
    return (_jsxs(ModalBase, { testId: "resume-modal", heading: _jsx(FormattedMessage, { id: 'IhOX6f', defaultMessage: 'Welcome back {waveIcon}', values: {
                waveIcon: (_jsx("span", { role: "img", "aria-label": "wave", children: "\uD83D\uDC4B" })),
            } }), description: intl.formatMessage({ id: "BdVa+P", defaultMessage: "We noticed that you have already started this {brand} session. Would you like to continue the existing session?" }, { brand: 'Ballpark' }), children: [_jsx(Button, { size: "l", marginRight: "s", onClick: () => setShowModal(false), "data-testid": "continue-button", children: _jsx(FormattedMessage, { id: '+3BMyf', defaultMessage: 'Continue session' }) }), _jsx(Button, { size: "l", kind: "ghost", onClick: onRestart, "data-testid": "restart-button", children: _jsx(FormattedMessage, { id: 'A93PXc', defaultMessage: 'Restart' }) })] }));
});
/**
 * Modal that is shown when completing a test that fails or is pending to sync
 */
const SyncModal = observer(function SyncModal() {
    const intl = useIntl();
    const server = useServer();
    const [visible, setVisible] = useState(false);
    // using state prevents a continouous sync loop incase something erronously updates the document
    // again after it finishes syncing
    const [hasSynced, setHasSynced] = useState(() => !server.hasUnsyncedChanges);
    // set `hasSynced` to true once, when the document is synced
    useEffect(() => when(() => !server.hasUnsyncedChanges, () => setHasSynced(true)), [server]);
    // while not synced and not fully errored block the user from leaving the page
    // we allow the user to leave the page when they error because refreshing is part of the error flow
    useReaction(() => {
        if (!server.open)
            return false;
        if (hasSynced)
            return false;
        return true;
    }, (shouldBlockUnload) => {
        if (!shouldBlockUnload)
            return;
        const blockUnload = (e) => {
            e.preventDefault();
            // eslint-disable-next-line no-param-reassign
            e.returnValue = intl.formatMessage({ id: "LDKDVS", defaultMessage: "Your session hasn't finished syncing, please do not leave the page" }); // Chrome requires this to be set to show the dialog
            setVisible(true);
        };
        window.addEventListener('beforeunload', blockUnload, { capture: true });
        return () => {
            window.removeEventListener('beforeunload', blockUnload, {
                capture: true,
            });
        };
    }, {
        fireImmediately: true,
    }, [hasSynced, server, intl]);
    useEffect(() => {
        if (hasSynced)
            return;
        return autorun(() => {
            // immediately show the modal if the server closes the connection
            if (!server.open)
                setVisible(true);
        });
    }, [server, hasSynced]);
    useEffect(() => {
        if (visible || hasSynced)
            return;
        // show the sync modal after 2 seconds if the document still hasn't synced
        const timeout = setTimeout(() => setVisible(true), 2000);
        return () => clearTimeout(timeout);
    }, [visible, hasSynced]);
    if (!visible)
        return null;
    if (hasSynced)
        return _jsx(SyncSuccessModal, {});
    if (!server.open)
        return _jsx(SyncFailedModal, {});
    return _jsx(SyncPendingModal, {});
});
const SUCCESS_MESSAGE = defineMessage({ id: "OHrLmQ", defaultMessage: "Success" });
const ERROR_MESSAGE = defineMessage({ id: "BmqXDK", defaultMessage: "Something went wrong" });
const SyncSuccessModal = observer(function SyncSuccessModal() {
    const intl = useIntl();
    return (_jsx(ModalBase, { testId: "sync-success-modal", heading: intl.formatMessage(SUCCESS_MESSAGE), description: _jsx(FormattedMessage, { id: 'nbwARs', defaultMessage: '<text>Your session has been fully synced to our servers</text><subtext>You can now close this page</subtext>', values: {
                text: (text) => _jsx(Text, { size: "l", children: text }),
                subtext: (text) => (_jsx(Text, { size: "l", mt: "s", children: text })),
            } }) }));
});
const SyncPendingModal = observer(function SyncPendingModal() {
    return (_jsx(ModalBase, { testId: "sync-pending-modal", heading: _jsx(LoadingMessage, {}), description: _jsx(Text, { size: "l", children: _jsx(FormattedMessage, { id: 'c2JHqa', defaultMessage: 'We are syncing your session with our servers, this should only take a moment' }) }) }));
});
const SyncFailedModal = observer(function SyncFailedModal() {
    const intl = useIntl();
    return (_jsx(ModalBase, { testId: "sync-failed-modal", heading: intl.formatMessage(ERROR_MESSAGE), description: _jsx(SyncFailedMessage, {}), children: _jsx(RetryButton, {}) }));
});
/**
 * Modal that is shown when returning to a test that was completed but not synced
 */
const SyncPreviousModal = observer(function SyncModal({ onRestart, }) {
    const urlParams = new URLSearchParams(window.location.search);
    const urlResponseUUID = urlParams.get('responseUUID');
    const server = useServer();
    if (server.synced)
        return (_jsx(SyncPreviousSuccessModal, { onRestart: onRestart, hasBeenPreScreened: !!urlResponseUUID }));
    if (server.closeEvent)
        return _jsx(SyncPreviousFailedModal, { onRestart: onRestart });
    return (_jsx(SyncPreviousPendingModal, { onRestart: onRestart, hasBeenPreScreened: !!urlResponseUUID }));
});
const SyncPreviousSuccessModal = observer(function SyncSuccessModal({ onRestart, hasBeenPreScreened, }) {
    const intl = useIntl();
    return (_jsx(ModalBase, { testId: "sync-previous-success-modal", heading: intl.formatMessage(SUCCESS_MESSAGE), description: _jsxs(_Fragment, { children: [_jsx(Text, { size: "l", children: _jsx(FormattedMessage, { id: 'PoPsp7', defaultMessage: 'Your previous session has been fully synced to our servers.' }) }), _jsx(Text, { size: "l", mt: "s", children: hasBeenPreScreened ? (_jsx(FormattedMessage, { id: 'V7JQGD', defaultMessage: 'You can now close this page' })) : (_jsx(FormattedMessage, { id: 'EdtSSq', defaultMessage: 'You can now close this page or start a new session' })) })] }), children: !hasBeenPreScreened && (_jsx(RestartButton, { onRestart: onRestart, kind: "ghost" })) }));
});
const SyncPreviousPendingModal = observer(function SyncPendingModal({ onRestart, hasBeenPreScreened, }) {
    return (_jsx(ModalBase, { testId: "sync-previous-pending-modal", heading: _jsx(LoadingMessage, {}), description: _jsx(FormattedMessage, { id: 'gg8hEE', defaultMessage: '<text>We are syncing your previous session with our servers, this should only take a moment.</text><subtext>Please note that starting a new session will overwrite your previous session.</subtext>', values: {
                text: (text) => _jsx(Text, { size: "l", children: text }),
                subtext: (text) => (_jsx(Text, { size: "l", mt: "s", children: text })),
            } }), children: !hasBeenPreScreened && _jsx(RestartButton, { onRestart: onRestart }) }));
});
const SyncPreviousFailedModal = observer(function SyncFailedModal({ onRestart, }) {
    const intl = useIntl();
    return (_jsxs(ModalBase, { testId: "sync-previous-failed-modal", heading: intl.formatMessage(ERROR_MESSAGE), description: _jsxs(_Fragment, { children: [_jsx(SyncFailedMessage, {}), _jsx(Text, { kind: "primary", size: "l", mt: "s", children: _jsx(FormattedMessage, { id: 'hVbVgB', defaultMessage: 'Please note that starting a new session will overwrite your previous session.' }) })] }), children: [_jsx(RetryButton, {}), _jsx(RestartButton, { onRestart: onRestart })] }));
});
export const Resume = observer(function Resume(props) {
    return (_jsxs(_Fragment, { children: [_jsx(TrackCompletion, {}), _jsx(ResumeOrSync, Object.assign({}, props))] }));
});
/**
 * retrieve existing test from local storage, this value only exists if the previous test was not
 * completed or synced
 */
export function getResumableResponseUUID(testUUID) {
    return window.localStorage.getItem(getResponseIdStorageKey(testUUID));
}
const LoadingMessage = observer(function LoadingMessage() {
    return (_jsxs(Flex, { alignItems: "center", children: [_jsx(FormattedMessage, { id: 'L5KV6f', defaultMessage: 'Please wait' }), _jsx(Box, { ml: "s", children: _jsx(Loader, {}) })] }));
});
const SyncFailedMessage = observer(function SyncFailedMessage() {
    return (_jsx(Text, { size: "l", children: _jsx(FormattedMessage, { id: 'DhwRol', defaultMessage: 'We were unable to sync your session to our servers. Please try again or <link>contact support</link>', values: {
                link: (text) => (_jsx(Link, { fontSize: "inherit", kind: "primary", to: "support@ballparkhq.com", children: text })),
            } }) }));
});
const RetryButton = observer(function RetryButton() {
    return (_jsx(Button, { size: "l", marginRight: "s", onClick: () => window.location.reload(), "data-testid": "retry-button", children: _jsx(FormattedMessage, { id: 'FC76oH', defaultMessage: 'Retry' }) }));
});
const RestartButton = observer(function RestartButton({ onRestart, kind = 'negative', }) {
    return (_jsx(Button, { size: "l", kind: kind, onClick: onRestart, "data-testid": "restart-button", children: _jsx(FormattedMessage, { id: 'QGsiwV', defaultMessage: 'Start a new session' }) }));
});
