import { Fragment as _Fragment, jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import { observer } from 'mobx-react';
import { BtwText, Stack } from '@marvelapp/ballpark-components';
import { pluralize } from '@marvelapp/core';
import { ColumnVisibilityPopover } from './ColumnVisibilityPopover';
import { FilteringPopover } from './FilteringPopover';
import { MoreActionsDropdown } from './MoreActionsDropdown/DownloadCSV';
import { useResponsesContext } from './ResponsesContext';
import { SortingPopover } from './SortingPopover';
export const ResponsesHeader = observer(function ResponsesHeader() {
    const { totalFilteredResults } = useResponsesContext();
    return (_jsxs(Stack, { direction: "row", gap: "5", className: "py-3 pl-4 pr-3", width: "full", justify: "between", align: "center", children: [_jsx(Stack, { children: _jsx(BtwText, { size: "13", weight: "medium", "data-testid": "response-count", children: totalFilteredResults !== undefined && (_jsxs(_Fragment, { children: [totalFilteredResults || 'No', ' ', pluralize(totalFilteredResults || 0, 'response')] })) }) }), _jsxs(Stack, { direction: "row", gap: "0.5", children: [_jsx(FilteringPopover, {}), _jsx(SortingPopover, {}), _jsx(ColumnVisibilityPopover, {}), _jsx(MoreActionsDropdown, {})] })] }));
});
