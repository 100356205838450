import { lazy } from 'react';
const mediaPlayerImport = () => import('./MediaPlayer');
const MediaPlayer = {
    Root: lazy(() => mediaPlayerImport().then((mod) => ({
        default: mod.Root,
    }))),
    Poster: lazy(() => mediaPlayerImport().then((mod) => ({
        default: mod.Poster,
    }))),
    Track: lazy(() => mediaPlayerImport().then((mod) => ({
        default: mod.Track,
    }))),
    MessageBadge: lazy(() => mediaPlayerImport().then((mod) => ({
        default: mod.MessageBadge,
    }))),
};
export { MediaPlayer };
