import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { observer } from 'mobx-react';
import * as React from 'react';
import { useCurrentUser, userSelectors } from '@marvelapp/ballpark-application';
import { Badge, Heading, Link, Text, Tooltip, } from '@marvelapp/ballpark-components';
import styled from '@marvelapp/styled';
import { Box, PinTR } from '@marvelapp/ui';
export const SlideRowSimple = observer(function SlideRowSimple({ cursor, description, isPremiumStep, isDisabled, onClick, icon, testId, title, }) {
    const user = useCurrentUser();
    const isOnEnterprisePlan = userSelectors.isEnterprise(user);
    const isOnTeamPlan = userSelectors.isTeam(user);
    const planSupportsPremiumSteps = isOnEnterprisePlan || isOnTeamPlan;
    const isOnFreeTrial = userSelectors.isOnFreeTrial(user);
    const shouldSeeUpgradeMessaging = isPremiumStep && !planSupportsPremiumSteps && !isOnFreeTrial;
    return (_jsx(Container, { isDisabled: isDisabled, children: _jsxs(PaywallLinkWrapper, { cursor: cursor, onClick: onClick, shouldLinkToPaywall: shouldSeeUpgradeMessaging, testId: testId, isDisabled: isDisabled, children: [_jsx(IconWrapper, { filter: isDisabled ? 'grayscale(1)' : 'none', children: icon }), _jsxs(Meta, { children: [_jsx(Heading, { lineHeight: "18px", marginBottom: "2px", paddingRight: "l", size: "s", color: isDisabled ? 'slate11' : 'licorice', children: title }), _jsx(Text, { lineHeight: "18px", size: "xs", children: description }), shouldSeeUpgradeMessaging && (_jsx(PinTR, { right: "5px", top: 10, children: _jsx(Badge, { "data-testid": "add-step--upgrade-badge", variant: "outline", size: "sm", children: "UPGRADE" }) }))] })] }) }));
});
const PaywallLinkWrapper = observer(function PaywallLinkWrapper({ children, onClick, shouldLinkToPaywall, isDisabled, testId, }) {
    if (shouldLinkToPaywall) {
        return (_jsx(Tooltip.Quick, { triggerHasFullWidth: true, content: "Available on Business and Enterprise plans", children: _jsx(Link, { display: "block", href: "/plans", tag: "a", children: _jsx(Body, { "data-testid": testId, children: children }) }) }));
    }
    if (isDisabled) {
        return (_jsx(Tooltip.Quick, { triggerHasFullWidth: true, content: "You can only add 5 screener questions", children: _jsx(Body, { "data-testid": testId, children: children }) }));
    }
    return (_jsx(Body, { "data-testid": testId, onClick: onClick, children: children }));
});
const Container = styled(Box).attrs((props) => ({
    bg: props.isDisabled ? 'slate2' : 'background',
})) `
  position: relative;
  cursor: pointer;
`;
const Body = styled(Box).attrs({
    borderRadius: 'xl',
    hoverBg: 'slate2',
    padding: 14,
    transition: 'smooth.fast',
}) `
  display: flex;
  position: relative;
  text-align: left;
`;
const IconWrapper = styled(Box).attrs({
    maxWidth: 'typeIcon',
}) `
  flex: 1;
  position: relative;
  filter: ${(props) => props.filter};
`;
const Meta = styled(Box).attrs({
    paddingLeft: 14,
    minHeight: 'typeIcon',
}) `
  flex: 1;
  min-width: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;
