import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { observer } from 'mobx-react';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { Button, Label, Stack, Text, Tooltip, cn, } from '@marvelapp/ballpark-components';
import { Box } from '@marvelapp/ui';
export const RequestMedia = observer(function RequestMedia({ description, state, icon, onChange, showButtonTooltip = false, testId, title, }) {
    return (_jsxs(Stack, { direction: "row", align: "center", width: "full", className: "min-h-[52px]" // to match the same height as the old markup
        , gap: "4", children: [_jsx(Box, { display: "flex", justifyContent: "space-around", alignItems: "center", width: 36, height: 36, border: "1px solid", borderColor: "slate6", borderRadius: "m", children: icon }), _jsxs(Box, { flex: 1, display: "flex", gridGap: "xs", justifyContent: "center", alignItems: "start", height: "100%", flexDirection: "column", children: [_jsx(Label, { fontSize: "3", size: "m", lineHeight: "base", children: title }), _jsx(Text, { fontSize: "2", className: "text-left", children: description })] }), _jsx(Box, { flex: 0, children: _jsx(RequestMediaCTA, { onChange: onChange, showButtonTooltip: showButtonTooltip, testId: testId, state: state }) })] }));
});
export const RequestMediaContainer = observer(function RequestMediaContainer({ children, }) {
    return (_jsx(Stack, { "data-testid": "toggle-container", width: "full", gap: "4", className: cn('rounded-[10px]', // To match the old style radius
        'ring-1', 'ring-gray-600/10', 'shadow', 'p-4'), children: children }));
});
const RequestMediaCTA = observer(function RequestMediaCTA({ onChange, showButtonTooltip, state, testId, }) {
    const button = (_jsxs(Button, { onClick: onChange, "data-testid": testId, isDisabled: state === 'requesting' || state === 'blocked', hasSucceeded: state === 'granted', kind: state === 'granted' ? 'primary' : 'ghost', overrideProps: {
            hasSucceeded: {
                bg: 'grass8',
                hoverBg: 'grass8',
                fill: 'grass8',
                borderColor: 'grass8',
            },
        }, children: [state === 'idle' && (_jsx(FormattedMessage, { id: '6IdIL3', defaultMessage: 'Enable' })), state === 'requesting' && (_jsx(FormattedMessage, { id: 'tzM0iB', defaultMessage: 'Enabling...' })), state === 'blocked' && (_jsx(FormattedMessage, { id: '49evhq', defaultMessage: 'Blocked' })), state === 'granted' && (_jsx(FormattedMessage, { id: 'Cq+6gR', defaultMessage: 'Enabled' }))] }));
    if (showButtonTooltip) {
        return (_jsxs(Tooltip.Root, { children: [_jsx(Tooltip.Trigger, { children: button }), _jsx(Tooltip.Content, { maxWidth: "260px", side: "bottom", size: "s", children: "This is a preview of how the button will look for participants" })] }));
    }
    return button;
});
