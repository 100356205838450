import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import * as ScrollArea from '@radix-ui/react-scroll-area';
import { observer } from 'mobx-react';
import { forwardRef } from 'react';
import * as React from 'react';
import theme from '@marvelapp/ballpark-theme';
import styled from '@marvelapp/styled';
const SCROLLBAR_SIZE = 12;
const StyledScrollArea = styled(ScrollArea.Root)({
    width: '100%',
    height: '100%',
    position: 'relative',
    overflow: 'hidden',
});
const StyledViewport = styled(ScrollArea.Viewport)({
    width: '100%',
    height: '100%',
    position: 'absolute',
    inset: 0,
    borderRadius: 'inherit',
    // Radix ScrollArea wraps content in a div which was causing the height of the
    // content to collapse. I tried asChild but that completely broke scrolling.
    // This ensures the content height doesn't collapse and keeps the empty state
    // centred.
    '> div': {
        height: '100%',
    },
});
const StyledScrollbar = styled(ScrollArea.Scrollbar)({
    display: 'flex',
    // ensures no selection
    userSelect: 'none',
    // disable browser handling of all panning and zooming gestures on touch devices
    touchAction: 'none',
    padding: 2,
    background: theme.colors.slate2,
    transition: 'background 160ms ease-out',
    '&:hover': { background: theme.colors.slate4 },
    '&[data-orientation="vertical"]': { width: SCROLLBAR_SIZE },
    '&[data-orientation="horizontal"]': {
        flexDirection: 'column',
        height: SCROLLBAR_SIZE,
    },
});
const StyledThumb = styled(ScrollArea.Thumb)({
    flex: 1,
    background: theme.colors.slate10,
    borderRadius: SCROLLBAR_SIZE,
    // increase target size for touch devices https://www.w3.org/WAI/WCAG21/Understanding/target-size.html
    position: 'relative',
    '&::before': {
        content: '""',
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '100%',
        height: '100%',
        minWidth: 44,
        minHeight: 44,
    },
});
const StyledCorner = styled(ScrollArea.Corner)({
    background: theme.colors.slate2,
});
const Scrollbars = observer(forwardRef(function Scrollbars({ children }, ref) {
    return (_jsxs(StyledScrollArea, { children: [_jsx(StyledViewport, { ref: ref, children: children }), _jsx(StyledScrollbar, { orientation: "horizontal", children: _jsx(StyledThumb, {}) }), _jsx(StyledScrollbar, { orientation: "vertical", children: _jsx(StyledThumb, {}) }), _jsx(StyledCorner, {})] }));
}));
export { Scrollbars };
