import styled from '@marvelapp/styled';
import { Box } from '@marvelapp/ui';
export const SidebarPane = styled(Box).attrs({
    width: 'wideSidebar',
    bg: 'background',
    boxShadow: 'sidebar.left',
    borderRight: '1px solid',
    borderColor: 'slate6',
}) `
  min-height: 100%;
  position: relative;
  z-index: 1; /* TODO: Add to scale */
`;
export const BuilderSidebarPane = styled(SidebarPane) `
  position: relative;
  z-index: 2;
  flex: 1;
  display: flex;
  flex-direction: column;
`;
