import { __rest } from "tslib";
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Heading, Text } from '@marvelapp/ui';
/**
 * @param {any}
 */
function ModalTitle(_a) {
    var { title, fontSize = 6, subtitle } = _a, props = __rest(_a, ["title", "fontSize", "subtitle"]);
    return (_jsxs(Box, Object.assign({ borderBottom: "1px solid", borderColor: "smoke", mb: 5, pb: 4 }, props, { children: [_jsx(Heading, { display: "block", fontSize: fontSize, lineHeight: 1.2, tag: "h1", children: title }), subtitle && (_jsx(Text, { fontSize: 3, mt: 12, children: subtitle }))] })));
}
export default ModalTitle;
