import { useCallback } from 'react';
import { createPaginationUpdateQuery } from './apolloUtils';
export function useInfiniteQuery({ data, fetchMore, getDataConnection, isFetchingMore, }) {
    var _a;
    const connection = getDataConnection(data);
    const fetchNextPage = useCallback(() => {
        if (!connection)
            return;
        const { pageInfo } = connection;
        const { endCursor } = pageInfo;
        if (isFetchingMore) {
            return;
        }
        fetchMore({
            variables: {
                cursor: endCursor,
            },
            updateQuery: createPaginationUpdateQuery(getDataConnection),
        });
    }, [connection, fetchMore, getDataConnection, isFetchingMore]);
    return {
        fetchNextPage,
        hasNextPage: (_a = connection === null || connection === void 0 ? void 0 : connection.pageInfo.hasNextPage) !== null && _a !== void 0 ? _a : false,
    };
}
