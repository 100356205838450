import { RoomEvent as RoomEventName } from 'livekit-client';
import LogRocket from 'logrocket';
import { useEffect } from 'react';
import { serializeJoinedParticipant, serializeParticipant, serializePermissions, serializeTrackPublication, validateRoomEvent, } from '@marvelapp/user-test-creator';
export function useSetupEventLogging(room, response) {
    useEffect(() => {
        if (!room || !response)
            return;
        if (!response.recordingLog) {
            // eslint-disable-next-line no-param-reassign
            response.recordingLog = {
                version: 1,
                events: [],
            };
        }
        const _addEntry = (entry) => {
            var _a;
            const entryWithTimestamp = Object.assign({ timestamp: Date.now() }, entry);
            const isValid = validateRoomEvent(entryWithTimestamp);
            if (isValid) {
                (_a = response.recordingLog) === null || _a === void 0 ? void 0 : _a.events.push(entryWithTimestamp);
            }
            else {
                console.error('Invalid room event', entryWithTimestamp, validateRoomEvent.errors);
                LogRocket.error('Invalid room event', entryWithTimestamp, validateRoomEvent.errors);
            }
        };
        room.on('connected', () => {
            _addEntry({ name: RoomEventName.Connected });
        });
        room.on('reconnecting', () => {
            _addEntry({ name: RoomEventName.Reconnecting });
        });
        room.on('signalReconnecting', () => {
            _addEntry({ name: RoomEventName.SignalReconnecting });
        });
        room.on('reconnected', () => {
            _addEntry({ name: RoomEventName.Reconnected });
        });
        room.on('disconnected', (reason) => {
            _addEntry({ name: RoomEventName.Disconnected, reason });
        });
        room.on('connectionStateChanged', (state) => {
            _addEntry({ name: RoomEventName.ConnectionStateChanged, state });
        });
        room.on('mediaDevicesChanged', () => {
            _addEntry({ name: RoomEventName.MediaDevicesChanged });
        });
        room.on('participantConnected', (participant) => {
            _addEntry(Object.assign({ name: RoomEventName.ParticipantConnected }, serializeJoinedParticipant(participant)));
        });
        room.on('participantDisconnected', (participant) => {
            _addEntry(Object.assign({ name: RoomEventName.ParticipantDisconnected }, serializeParticipant(participant)));
        });
        room.on('trackPublished', (publication, participant) => {
            _addEntry(Object.assign(Object.assign({ name: RoomEventName.TrackPublished }, serializeTrackPublication(publication)), serializeParticipant(participant)));
        });
        room.on('trackSubscribed', (_, publication, participant) => {
            _addEntry(Object.assign(Object.assign({ name: RoomEventName.TrackSubscribed }, serializeTrackPublication(publication)), serializeParticipant(participant)));
        });
        room.on('trackSubscriptionFailed', (trackSid, participant, reason) => {
            _addEntry(Object.assign({ name: RoomEventName.TrackSubscriptionFailed, reason, track: {
                    sid: trackSid,
                } }, serializeParticipant(participant)));
        });
        room.on('trackUnpublished', (publication, participant) => {
            _addEntry(Object.assign(Object.assign({ name: RoomEventName.TrackUnpublished }, serializeTrackPublication(publication)), serializeParticipant(participant)));
        });
        room.on('trackUnsubscribed', (_, publication, participant) => {
            _addEntry(Object.assign(Object.assign({ name: RoomEventName.TrackUnsubscribed }, serializeTrackPublication(publication)), serializeParticipant(participant)));
        });
        room.on('trackMuted', (publication, participant) => {
            _addEntry(Object.assign(Object.assign({ name: RoomEventName.TrackMuted }, serializeTrackPublication(publication)), serializeParticipant(participant)));
        });
        room.on('trackUnmuted', (publication, participant) => {
            _addEntry(Object.assign(Object.assign({ name: RoomEventName.TrackUnmuted }, serializeTrackPublication(publication)), serializeParticipant(participant)));
        });
        room.on('localTrackPublished', (publication, participant) => {
            _addEntry(Object.assign(Object.assign({ name: RoomEventName.LocalTrackPublished }, serializeTrackPublication(publication)), serializeParticipant(participant)));
        });
        room.on('localTrackUnpublished', (publication, participant) => {
            _addEntry(Object.assign(Object.assign({ name: RoomEventName.LocalTrackUnpublished }, serializeTrackPublication(publication)), serializeParticipant(participant)));
        });
        room.on('localAudioSilenceDetected', (publication) => {
            _addEntry(Object.assign({ name: RoomEventName.LocalAudioSilenceDetected }, serializeTrackPublication(publication)));
        });
        room.on('participantPermissionsChanged', (prevPermissions, participant) => {
            _addEntry(Object.assign(Object.assign({ name: RoomEventName.ParticipantPermissionsChanged }, serializeParticipant(participant)), { prevPermissions: serializePermissions(prevPermissions) }));
        });
        room.on('connectionQualityChanged', (quality, participant) => {
            _addEntry(Object.assign(Object.assign({ name: RoomEventName.ConnectionQualityChanged }, serializeParticipant(participant)), { quality }));
        });
        room.on('mediaDevicesError', (error) => {
            _addEntry({
                name: RoomEventName.MediaDevicesError,
                error: error.message,
            });
        });
        room.on('trackStreamStateChanged', (track, state, participant) => {
            _addEntry(Object.assign(Object.assign(Object.assign({ name: RoomEventName.TrackStreamStateChanged }, serializeTrackPublication(track)), serializeParticipant(participant)), { state }));
        });
        room.on('trackSubscriptionPermissionChanged', (publication, status, participant) => {
            _addEntry(Object.assign(Object.assign(Object.assign({ name: RoomEventName.TrackSubscriptionPermissionChanged }, serializeTrackPublication(publication)), serializeParticipant(participant)), { status }));
        });
        room.on('trackSubscriptionStatusChanged', (publication, status, participant) => {
            _addEntry(Object.assign(Object.assign(Object.assign({ name: RoomEventName.TrackSubscriptionStatusChanged }, serializeTrackPublication(publication)), serializeParticipant(participant)), { status }));
        });
        room.on('audioPlaybackChanged', (enabled) => {
            _addEntry({
                name: RoomEventName.AudioPlaybackStatusChanged,
                playing: enabled,
            });
        });
        room.on('videoPlaybackChanged', (enabled) => {
            _addEntry({
                name: RoomEventName.VideoPlaybackStatusChanged,
                playing: enabled,
            });
        });
        room.on('signalConnected', () => {
            _addEntry({ name: RoomEventName.SignalConnected });
        });
        room.on('recordingStatusChanged', (recording) => {
            _addEntry({
                name: RoomEventName.RecordingStatusChanged,
                recording,
            });
        });
        room.on('activeDeviceChanged', (deviceKind, deviceId) => {
            _addEntry({
                name: RoomEventName.ActiveDeviceChanged,
                kind: deviceKind,
                deviceId,
            });
        });
        room.on('localTrackSubscribed', (publication, participant) => {
            _addEntry(Object.assign(Object.assign({ name: RoomEventName.LocalTrackSubscribed }, serializeTrackPublication(publication)), serializeParticipant(participant)));
        });
        return () => {
            room.removeAllListeners();
        };
    }, [response, room]);
}
