export var Frame;
(function (Frame) {
    Frame["AppleWatch42"] = "APPL_WATCH_42";
    Frame["IPad"] = "IPAD";
    Frame["IPadPro11"] = "IPAD_PRO_11";
    Frame["IPhone4S"] = "IPHONE4S";
    Frame["IPhone5"] = "IPHONE5";
    Frame["IPhone5C"] = "IPHONE5C";
    Frame["IPhone6"] = "IPHONE6";
    Frame["IPhone6Plus"] = "IPHONE6PLUS";
    Frame["IPhoneX"] = "IPHONEX";
    Frame["Lumia920"] = "LUMIA920";
    Frame["Pixel4"] = "PIXEL_4";
    Frame["Nexus5"] = "NEXUS_5";
    Frame["Web"] = "WEB";
})(Frame || (Frame = {}));
export function isMobileFrame(frame) {
    return frame !== Frame.AppleWatch42 && frame !== Frame.Web;
}
