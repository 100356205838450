import { StepClass } from '../userTest/types';
import { AnswerType } from './types';
export function create(stepUUID, stepRevision) {
    return {
        className: StepClass.RatingScale,
        stepUUID,
        stepRevision,
        answer: {
            type: AnswerType.Rating,
            value: null,
        },
    };
}
export function getAnswer(response) {
    return response.answer.value;
}
export function setAnswer(mutable, rating) {
    mutable.answer.value = rating;
}
