import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { observer } from 'mobx-react';
import { Scrollbars } from 'react-custom-scrollbars';
import { useAction, useFeatureFlag, useGetUserTest, } from '@marvelapp/ballpark-application';
import { Button, Icon, StepIcon, getIconType, } from '@marvelapp/ballpark-components';
import { getEnglishStepName } from '@marvelapp/ballpark-copy';
import { PLUS_ICON } from '@marvelapp/ballpark-theme';
import styled from '@marvelapp/styled';
import { Box, Flex } from '@marvelapp/ui';
import { FixedStep, UserTestUtils, getAnyMediaEnabled, getMiddleStepsUUIDs, getStepIndex, } from '@marvelapp/user-test-creator';
import { DraggableCards } from '../../components/DraggableCards';
import { FixedCard } from '../../components/FixedCard';
import { ScreenersSection } from '../../components/Screeners';
import { SectionDivider } from '../../components/SectionDividerSidebar';
import { BuilderSidebarPane } from '../../components/SidebarPane';
import RecordUserTestToggle from './RecordUserTestToggle';
import { useUIContext } from './uiContext';
const ActiveSlides = observer(({ onAddNewStep }) => {
    const canRecordWholeTest = useFeatureFlag('ballpark-whole-test-recording');
    return (_jsxs(BuilderSidebarPane, { children: [_jsx(Container, { "data-testid": "divider", children: _jsxs(Flex, { flexDirection: "row", justifyContent: "space-between", p: "s", children: [_jsxs(Button, { color: "white", "data-testid": "add-new-step", width: canRecordWholeTest ? '55%' : '100%', onClick: onAddNewStep, children: [_jsx(IconWrapper, { children: _jsx(Icon, { paths: PLUS_ICON }) }), _jsx(Box, { textAlign: "center", ml: "17px", children: "Add step" })] }), _jsx(RecordUserTestToggle, { width: "42.5%" })] }) }), _jsx(Scrollbars, { autoHide: true, children: _jsxs(Box, { px: "s", children: [_jsx(ScreenersSection, {}), _jsx(SectionDivider, { copy: "Start", mt: "s" }), _jsx(WelcomeCard, {}), _jsx(MediaSettingsCard, {}), _jsx(SectionDivider, { copy: "Main", mt: "s" }), _jsx(MainCards, {})] }) }), _jsxs(Box, { px: "s", pt: "xs", pb: "s", children: [_jsx(SectionDivider, { copy: "Exit" }), _jsx(FixedLastCardInner, { children: _jsx(ExitCard, {}) })] })] }));
});
const getStepText = (userTest, step, fixedStepType) => {
    return `${getStepIndex(userTest, step.uuid) + 1}:${` `}${getEnglishStepName(step, fixedStepType)}`;
};
const MainCards = observer(function MainCards() {
    const userTest = useGetUserTest();
    const stepUUIDs = getMiddleStepsUUIDs(userTest);
    const cloneStep = useAction((step) => {
        UserTestUtils.cloneAndAddStep(userTest, step);
    }, [userTest]);
    const deleteStep = useAction((step) => {
        UserTestUtils.deleteStep(userTest, step.uuid);
    }, [userTest]);
    const getCardTypeIcon = (step, fixedStepType) => (_jsx(StepIcon, { size: "xs", type: getIconType(step, fixedStepType) }));
    return (_jsx(DraggableCards, { stepsUUIDs: stepUUIDs, reorderFn: UserTestUtils.reorderStep, onCloneStep: cloneStep, onDeleteStep: deleteStep, getStepText: getStepText, getCardTypeIcon: getCardTypeIcon }));
});
const WelcomeCard = observer(() => {
    const userTest = useGetUserTest();
    const stepUUID = userTest.welcomeStep.uuid;
    const { activeSlideUUID, setActiveSlideUUID } = useUIContext();
    return (_jsx(FixedCard, { activeSlideUUID: activeSlideUUID, getStepText: getStepText, fixedStepType: FixedStep.Welcome, setActiveSlideUUID: setActiveSlideUUID, stepUUID: stepUUID, getCardTypeIcon: () => _jsx(StepIcon, { size: "xs", type: FixedStep.Welcome }), userTest: userTest }));
});
const MediaSettingsCard = observer(function MediaSettingsCard() {
    const userTest = useGetUserTest();
    const { mediaSettingsStep } = userTest;
    const stepUUID = mediaSettingsStep === null || mediaSettingsStep === void 0 ? void 0 : mediaSettingsStep.uuid;
    const { activeSlideUUID, setActiveSlideUUID } = useUIContext();
    if (!stepUUID || !getAnyMediaEnabled(userTest))
        return null;
    return (_jsx(FixedCard, { activeSlideUUID: activeSlideUUID, getStepText: getStepText, getCardTypeIcon: (step, fixedStepType) => (_jsx(StepIcon, { size: "xs", type: getIconType(step, fixedStepType) })), setActiveSlideUUID: setActiveSlideUUID, stepUUID: stepUUID, testId: `sidebar-step-${mediaSettingsStep.className}`, userTest: userTest }));
});
const ExitCard = observer(() => {
    const userTest = useGetUserTest();
    const stepUUID = userTest.exitStep.uuid;
    const { activeSlideUUID, setActiveSlideUUID } = useUIContext();
    return (_jsx(FixedCard, { activeSlideUUID: activeSlideUUID, getStepText: getStepText, fixedStepType: FixedStep.Exit, setActiveSlideUUID: setActiveSlideUUID, stepUUID: stepUUID, getCardTypeIcon: () => _jsx(StepIcon, { size: "xs", type: FixedStep.Exit }), userTest: userTest }));
});
const FixedLastCardInner = styled(Box).attrs({
    zIndex: 'record.controls',
}) `
  position: relative;
`;
const Container = styled(Box).attrs({
    zIndex: 'record.controls',
}) `
  position: relative;
`;
const IconWrapper = styled(Box).attrs({
    color: 'white',
}) `
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 34px; /* button height - 1px on the sides */
  height: 34px; /* button height - 1px on the sides */

  svg {
    display: block;
    width: 24px;
    height: 24px;
  }
`;
export default ActiveSlides;
ActiveSlides.displayName = 'ActiveSlides';
ExitCard.displayName = 'ExitCard';
WelcomeCard.displayName = 'WelcomeCard';
