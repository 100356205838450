import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { observer } from 'mobx-react';
import * as React from 'react';
import { useIntl } from 'react-intl';
import { useAction, useSlideUIContext } from '@marvelapp/ballpark-application';
import { Dropdown, Stepper } from '@marvelapp/ballpark-components';
import { UI_STEPS_COPY } from '@marvelapp/ballpark-copy';
import { FiveSecondStepUtils, StepClass, StepUtils, } from '@marvelapp/user-test-creator';
import HalfSlide, { LeftScrollableHalfSlide } from '../common/HalfSlide';
import ImageUpload from '../common/ImageUpload';
import { StepOptions } from '../common/Options';
import SlideContent from '../common/SlideContent';
import TwoColumnSlide from '../common/TwoColumnSlide';
export default observer(function FiveSecondEdit({ onMainCTAClick, }) {
    const intl = useIntl();
    const { step } = useSlideUIContext();
    const buttonText = intl.formatMessage(UI_STEPS_COPY[StepClass.FiveSecondTest].buttonText);
    const onImageUploaded = useAction((fileURL) => {
        if (!StepUtils.isOfType(step, StepClass.FiveSecondTest))
            return;
        FiveSecondStepUtils.setImageUrl(step, fileURL);
    }, [step]);
    const onDelete = useAction(() => {
        if (!StepUtils.isOfType(step, StepClass.FiveSecondTest))
            return;
        FiveSecondStepUtils.removeImage(step);
    }, [step]);
    if (!StepUtils.isOfType(step, StepClass.FiveSecondTest))
        return null;
    return (_jsx(_Fragment, { children: _jsxs(TwoColumnSlide, { children: [_jsx(LeftScrollableHalfSlide, { children: _jsx(SlideContent, { ctaOnClick: onMainCTAClick, maxHeadingLength: 512, maxParagraphLength: 512, ctaText: buttonText }) }), _jsx(HalfSlide, { children: _jsx(ImageUpload, { onImageUploaded: onImageUploaded, onDelete: onDelete, testId: 'instruction', url: step.imageUrl }) })] }) }));
});
export const FiveSecondStepSettings = observer(function FiveSecondStepSettings() {
    const { step } = useSlideUIContext();
    const increase = useAction(() => {
        if (!StepUtils.isOfType(step, StepClass.FiveSecondTest))
            return;
        let rating = FiveSecondStepUtils.getTimer(step);
        if (rating < 20) {
            rating += 1;
            FiveSecondStepUtils.setTimer(step, rating);
        }
    }, [step]);
    const decrease = useAction(() => {
        if (!StepUtils.isOfType(step, StepClass.FiveSecondTest))
            return;
        let rating = FiveSecondStepUtils.getTimer(step);
        if (rating > 5) {
            rating -= 1;
            FiveSecondStepUtils.setTimer(step, rating);
        }
    }, [step]);
    if (!StepUtils.isOfType(step, StepClass.FiveSecondTest))
        return null;
    return (_jsx(StepOptions, { children: _jsx(Dropdown.Label, { description: "Select how long to display your image", id: "timer-count-stepper", title: "Set timer", children: _jsx(Stepper, { onDecrement: decrease, onIncrement: increase, size: "s", stepperTestId: "timer-count-stepper", children: `${FiveSecondStepUtils.getTimer(step)} seconds` }) }) }));
});
