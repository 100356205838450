import { generateUUID } from '../utils/generateUUID';
import { generateUniqueName } from '../utils/generateUniqueName';
import { STEP_PREFIXES } from './constants';
import { StepClass, } from './types';
export function create(partial) {
    const step = Object.assign({ uuid: generateUUID(STEP_PREFIXES[partial.className]), title: null, description: [], revision: 0, isRequired: false, cards: [], cardDefinitions: {}, categories: [], categoryDefinitions: {}, areCardsRandomized: true, areCategoriesRandomized: true }, partial);
    return step;
}
export function canRecorderAddNewCategories(mutable) {
    return (mutable.className === StepClass.CardSortingHybrid ||
        mutable.className === StepClass.CardSortingOpen);
}
export function canBuilderAddNewCategories(mutable) {
    return (mutable.className === StepClass.CardSortingHybrid ||
        mutable.className === StepClass.CardSortingClosed);
}
export function getCard(mutable, uuid) {
    return Object.assign({ uuid, id: uuid }, mutable.cardDefinitions[uuid]);
}
export function getCards(mutable) {
    return mutable.cards.map((uuid) => (Object.assign({ uuid, id: uuid }, mutable.cardDefinitions[uuid])));
}
export function getCategory(mutable, uuid) {
    return Object.assign({ uuid, id: uuid }, mutable.categoryDefinitions[uuid]);
}
export function getCategories(mutable) {
    return mutable.categories.map((uuid) => (Object.assign({ uuid, id: uuid }, mutable.categoryDefinitions[uuid])));
}
export function getCardUUIDs(mutable) {
    return mutable.cards;
}
export function reorderCards(mutable, oldIndex, newIndex) {
    const [removed] = mutable.cards.splice(oldIndex, 1);
    mutable.cards.splice(newIndex, 0, removed);
}
export function reorderCategories(mutable, oldIndex, newIndex) {
    const [removed] = mutable.categories.splice(oldIndex, 1);
    mutable.categories.splice(newIndex, 0, removed);
}
export function addCard(mutable) {
    const cardNames = Object.values(mutable.cardDefinitions).map((card) => card.title || '');
    const title = generateUniqueName('Card', cardNames);
    const newUUID = generateUUID('card_');
    mutable.cards.push(newUUID);
    mutable.cardDefinitions[newUUID] = {
        title,
    };
    return newUUID;
}
export function addCategory(mutable) {
    const categoryNames = Object.values(mutable.categoryDefinitions).map((category) => category.title || '');
    const title = generateUniqueName('Add category title', categoryNames);
    const newUUID = generateUUID('cat_');
    mutable.categories.push(newUUID);
    mutable.categoryDefinitions[newUUID] = {
        title,
    };
    return newUUID;
}
export function removeCard(mutable, uuid) {
    delete mutable.cardDefinitions[uuid];
    const cardToRemoveIndex = mutable.cards.indexOf(uuid);
    mutable.cards.splice(cardToRemoveIndex, 1);
}
export function removeCategory(mutable, uuid) {
    delete mutable.categoryDefinitions[uuid];
    const categoryToRemoveIndex = mutable.categories.indexOf(uuid);
    mutable.categories.splice(categoryToRemoveIndex, 1);
}
export function updateCard(mutable, uuid, newCard) {
    const existingCard = mutable.cardDefinitions[uuid];
    if (!existingCard)
        return;
    Object.assign(existingCard, newCard);
}
export function updateCategory(mutable, uuid, newCategory) {
    const existingCategory = mutable.categoryDefinitions[uuid];
    if (!existingCategory)
        return;
    Object.assign(existingCategory, newCategory);
}
export function getAreCardsRandomized(mutable) {
    return mutable.areCardsRandomized;
}
export function setAreCardsRandomized(mutable, value) {
    mutable.areCardsRandomized = value;
}
export function getAreCategoriesRandomized(mutable) {
    return mutable.areCategoriesRandomized;
}
export function setAreCategoriesRandomized(mutable, value) {
    mutable.areCategoriesRandomized = value;
}
export function getIsNumberOrderInCategoryVisible(mutable) {
    return mutable.isNumberOrderInCategoryVisible;
}
export function setIsNumberOrderInCategoryVisible(mutable, value) {
    mutable.isNumberOrderInCategoryVisible = value;
}
export function getMaxCardsPerCategory(mutable) {
    return mutable.maxCardsPerCategory;
}
export function setMaxCardsPerCategory(mutable, value) {
    mutable.maxCardsPerCategory = value;
}
