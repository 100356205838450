import { __rest } from "tslib";
import { jsx as _jsx } from "react/jsx-runtime";
import { observer } from 'mobx-react';
import { BaseCard } from '../BaseCard';
import { useSortable } from '../dndUtils';
export const Card = observer(function Card(_a) {
    var { card } = _a, otherProps = __rest(_a, ["card"]);
    const { uuid } = card;
    const sortable = useSortable({ id: uuid });
    return _jsx(BaseCard, Object.assign({ card: card }, sortable, otherProps));
});
