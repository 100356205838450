import { jsx as _jsx } from "react/jsx-runtime";
import { observer } from 'mobx-react';
import { useAction } from '@marvelapp/ballpark-application';
import { IdentityOperator, YesNoConditionalUtils, } from '@marvelapp/user-test-creator';
import BooleanConditionalLogic from '../shared/booleanConditionalLogic';
const BOOLEAN_OPERATORS = [
    [IdentityOperator.Is, 'Yes'],
    [IdentityOperator.IsNot, 'No'],
];
export const YesNoConditionalLogic = observer(function YesNoConditionalLogic({ step, }) {
    const deleteRule = useAction((savedAnswer) => {
        YesNoConditionalUtils.deleteRule(step, savedAnswer);
    }, [step]);
    const updateRule = useAction(({ selectedChoice, selectedDestStepUuid, currentChoice, }) => {
        YesNoConditionalUtils.updateRule({
            mutable: step,
            selectedChoice,
            selectedDestStepUuid,
            currentChoice,
        });
    }, [step]);
    const setRule = useAction((selectedChoice, selectedDestStepUuid) => {
        YesNoConditionalUtils.setRule(step, selectedChoice, selectedDestStepUuid);
    }, [step]);
    // filter out any rules that don't have a destination step set
    const savedRules = YesNoConditionalUtils.getSavedRules(step);
    return (_jsx(BooleanConditionalLogic, { savedRules: savedRules, setRule: setRule, updateRule: updateRule, deleteRule: deleteRule, operatorOptions: BOOLEAN_OPERATORS }, step.uuid));
});
