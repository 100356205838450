import { StepClass } from '../common';
import { generateUUID } from '../utils/generateUUID';
import { STEP_PREFIXES } from './constants';
export function create(partial = {}) {
    return Object.assign({ uuid: generateUUID(STEP_PREFIXES.WebsiteTask), className: StepClass.WebsiteTask, title: null, description: [], revision: 0, isRequired: false, websiteUrl: null, conditions: {
            ALWAYS: null,
        } }, partial);
}
export function setWebsiteUrl(mutable, url) {
    if (!isSafeURL(url))
        return;
    mutable.websiteUrl = url;
}
export function setSkipped(mutable, skipped) {
    mutable.skipped = skipped;
}
// TODO: this function should be imported from:
// import { isSafeURL } from '@marvelapp/core';
// But for some unknown reasons it crashes RUS with the following message when starting on CI:
// Error: Cannot find module '/root/project/node_modules/@marvelapp/core/dist-cjs'. Please verify that the package.json has a valid "main" entry
function isSafeURL(url) {
    try {
        const { protocol } = new URL(url);
        // eslint-disable-next-line no-script-url
        return protocol !== 'javascript:';
    }
    catch (_a) {
        return true;
    }
}
