import { __awaiter } from "tslib";
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Track, createLocalTracks } from 'livekit-client';
import { observer } from 'mobx-react';
import { useCallback, useEffect, useRef, useState } from 'react';
import * as React from 'react';
import { useGetUserTest, useSlideUIContext, } from '@marvelapp/ballpark-application';
import { useMediaRecorder } from '@marvelapp/media-recording';
import { Box, Heading, Text } from '@marvelapp/ui';
import { MediaUtils } from '@marvelapp/user-test-creator';
import VideoGuideHolder from '../../../components/VideoGuideHolder';
import WebcamPermissionDeniedErrorList from '../MediaAccess/WebcamPermissionDeniedErrorList';
import RecordingPreview from './RecordingPreview';
export const RecordVideo = observer(function RecordVideo({ lockPopover, onAddToStep, unlockPopover, }) {
    const userTest = useGetUserTest();
    const { step } = useSlideUIContext();
    const mediaRef = useRef(null);
    // No audio support yet
    const videoEnabled = true;
    const [mediaConstraints, setMediaConstraints] = useState(() => {
        const savedAudioId = localStorage.getItem('videoGuidesAudioDeviceId');
        const savedVideoId = localStorage.getItem('videoGuidesVideoDeviceId');
        return {
            audio: savedAudioId ? { deviceId: { exact: savedAudioId } } : true,
            video: savedVideoId ? { deviceId: { exact: savedVideoId } } : true,
        };
    });
    const [tracks, setTracks] = useState('pending');
    const [isRecording, setIsRecording] = useState(false);
    const updateVideoDeviceId = useCallback((id) => setMediaConstraints((constraints) => (Object.assign(Object.assign({}, constraints), { video: { deviceId: { exact: id } } }))), []);
    const updateAudioDeviceId = useCallback((id) => setMediaConstraints((constraints) => (Object.assign(Object.assign({}, constraints), { audio: { deviceId: { exact: id } } }))), []);
    function setupVideoPreview() {
        if (tracks === 'pending' || tracks === 'failed')
            return;
        const media = mediaRef.current;
        if (!media)
            return;
        const stream = tracks[0].mediaStream;
        if (!stream)
            return;
        media.srcObject = stream;
        return () => {
            stopTracks(tracks);
        };
    }
    useEffect(setupVideoPreview, [tracks]);
    // Note using webcamMediaStream.status isn't sufficient as there's no state change
    // when the recording is _starting_ or _stopping_. Also the the functionality is split
    // between the useMediaStream hook and the useJanusRecorder hook so the state can't
    // live in either. Could possibly create a parent hook that manages both.
    const [recordingStatus, setRecordingStatus] = useState('stopped');
    const onStartRecording = useCallback((roomName) => {
        setIsRecording(true);
        MediaUtils.setMediaStatus({
            mutable: step,
            stage: MediaUtils.RecordingPipeline.StreamingFromClient,
            recordingId: roomName,
        });
        setTimeout(() => {
            setRecordingStatus('recording');
        }, 1000);
    }, [step, setRecordingStatus]);
    const { startRecordingMedia, stopRecordingMedia } = useMediaRecorder({
        testUUID: userTest.uuid,
        stepUUID: step.uuid,
        mock: false,
        isRecordingEnabled: true,
        onStartRecording,
        cameraTrack: Array.isArray(tracks)
            ? tracks.find((track) => track.source === Track.Source.Camera) || null
            : null,
        audioTrack: Array.isArray(tracks)
            ? tracks.find((track) => track.source === Track.Source.Microphone) || null
            : null,
        screenTrack: null,
        response: null,
    });
    useEffect(() => {
        let tracks;
        function getMedia() {
            return __awaiter(this, void 0, void 0, function* () {
                var _a;
                try {
                    tracks = yield createLocalTracks({
                        audio: mediaConstraints.audio,
                        video: typeof mediaConstraints.video === 'boolean'
                            ? mediaConstraints.video
                            : {
                                deviceId: (_a = mediaConstraints.video) === null || _a === void 0 ? void 0 : _a.deviceId,
                            },
                    });
                    if (tracks.length !== 2) {
                        setTracks('failed');
                        return;
                    }
                    setTracks(tracks);
                }
                catch (e) {
                    setTracks('failed');
                }
            });
        }
        getMedia();
        return () => {
            if (tracks) {
                // TODO instead of killing the whole stream we could just swap out the tracks?
                stopTracks(tracks);
            }
        };
    }, [mediaConstraints.audio, mediaConstraints.video]);
    useEffect(() => {
        // We want to disable clicking outside of the popover to close it, because it
        // can get annoying if you're mid recording and accidentally close the popover
        if (isRecording) {
            lockPopover();
        }
        else {
            unlockPopover();
        }
    }, [isRecording, lockPopover, unlockPopover]);
    if (tracks === 'failed') {
        return (_jsx(Box, { width: 300, children: _jsx(WebcamPermissionDenied, {}) }));
    }
    const onRecord = () => __awaiter(this, void 0, void 0, function* () {
        if (tracks === 'pending')
            return;
        setRecordingStatus('starting');
        startRecordingMedia();
    });
    const onStopRecording = () => __awaiter(this, void 0, void 0, function* () {
        setRecordingStatus('stopping'); // stopping or 'generating-preview'?
        // TODO maybe stop recording should be async (to mirror startRecording)
        stopRecordingMedia();
        // Need to set this status to show the uploading arrow while the backend is processing
        MediaUtils.setMediaStatus({
            mutable: step,
            stage: MediaUtils.RecordingPipeline.StreamingFromClientComplete,
        });
        setRecordingStatus('stopped');
        onAddToStep();
    });
    return (_jsx(VideoGuideHolder, { children: _jsx(RecordingPreview, { isAcquiringMedia: tracks === 'pending', isRecording: recordingStatus === 'recording', isRecordingStarting: recordingStatus === 'starting', isStopping: recordingStatus === 'stopping', onRecord: onRecord, onStopRecording: onStopRecording, onUpdateVideoDeviceId: updateVideoDeviceId, onUpdateAudioDeviceId: updateAudioDeviceId, ref: mediaRef, videoEnabled: videoEnabled, mediaStream: tracks instanceof MediaStream ? tracks : undefined }, step.uuid) }));
});
const WebcamPermissionDenied = observer(function WebcamPermissionDenied() {
    return (_jsxs(Box, { padding: "m", children: [_jsxs(Heading, { size: "l", marginBottom: "xs", children: ["We couldn", "'", "t set up your camera and microphone"] }), _jsx(Text, { size: "s", marginBottom: "s", children: "We access to your camera and microphone, so that you can record your video/audio guides." }), _jsx(WebcamPermissionDeniedErrorList, {})] }));
});
function stopTracks(tracks) {
    tracks.forEach((track) => {
        track.stop();
    });
}
