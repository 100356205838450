import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { observer } from 'mobx-react';
import { useState } from 'react';
import { Badge, BtwText, BtwTooltip, Stack, } from '@marvelapp/ballpark-components';
import { ImagesIcon } from '@marvelapp/ballpark-icons';
import { getImage } from '@marvelapp/core';
export const PreferenceTestCell = observer(function PreferenceTestCell({ values, step, }) {
    const [imageWidth, setImageWidth] = useState(0);
    return (_jsx(Stack, { direction: "row", gap: "2", "data-testid": "preference-test-cell", wrap: "wrap", children: values.map((value) => {
            const choice = step.choiceDefinitions[value];
            // the choice may have been deleted out of the user test
            if (!choice)
                return null;
            const { choiceTitle, src } = choice;
            const index = step.choices.indexOf(value) + 1;
            const imageThumb = getImage(src, 400);
            const handleImageLoad = (event) => {
                const target = event.target;
                // Manually setting the width of text in the tooltip to match the image's width.
                // Note: handleImageLoad fires every time the tooltip is opened, so it's
                // okay to have a single state for all images.
                setImageWidth(target.offsetWidth);
            };
            return (_jsxs(BtwTooltip.Root, { children: [_jsx(BtwTooltip.Trigger, { "data-testid": "preference-test-cell-badge", children: _jsx(Badge, { className: "min-w-0 max-w-40 bg-gray-200/50 text-gray-500", leadingIcon: _jsx(ImagesIcon, {}), children: _jsx("div", { className: "truncate", children: choiceTitle || `Image ${index}` }) }, "value") }), _jsx(BtwTooltip.Content, { className: "overflow-hidden bg-white p-2 shadow-md ring-1 ring-gray-600/10", side: "bottom", align: "start", children: _jsxs(Stack, { gap: "2", align: "center", className: "max-w-sm", "data-testid": "preference-preview", children: [_jsx("img", { onLoad: handleImageLoad, className: "max-h-80 max-w-sm flex-1 rounded object-contain", src: imageThumb, alt: choiceTitle || `Image ${index}` }), _jsx(BtwText, { weight: "medium", className: "self-center", style: { maxWidth: imageWidth }, children: choiceTitle || `Image ${index}` })] }) })] }, value));
        }) }));
});
