import { __rest } from "tslib";
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { observer } from 'mobx-react';
import { forwardRef, useCallback, useEffect, useRef, useState, } from 'react';
import * as React from 'react';
import { Stack, cn } from '@marvelapp/ballpark-components';
import { useResponsesContext } from '../ResponsesContext';
const Container = observer(function Container(props) {
    const { trigger, content, isTruncated, canCopy } = props;
    const { scrollableAreaDimensions, scrollableAreaElement } = useResponsesContext();
    // Setting up refs for the "trigger" and the "popper" (expanded cell when hovering)
    const triggerRef = useRef(null);
    const contentRef = useRef(null);
    // Setting up some hover-related things
    const [isHovered, setIsHovered] = useState(false);
    const [hoverTimeout, setHoverTimeout] = useState(null);
    // This is to determine correct spacing & positioning for the popper
    const [spacing, setSpacing] = useState();
    // Adding some special adjustments to table header cells
    const [isTableHead, setIsTableHead] = useState(false);
    // This is used to determine which side the popper is clipping outside the
    // viewable area (radix scrollarea)
    const [clipping, setClipping] = useState({
        right: false,
        bottom: false,
    });
    // Handling hover, so we only render the hover cell on hover… duh
    const handleMouseEnter = useCallback(() => {
        const timeout = setTimeout(() => {
            setIsHovered(true);
        }, 500);
        setHoverTimeout(timeout);
    }, []);
    const handleMouseLeave = useCallback(() => {
        if (hoverTimeout) {
            clearTimeout(hoverTimeout);
        }
        setClipping({ right: false, bottom: false });
        setIsHovered(false);
    }, [hoverTimeout]);
    useEffect(() => {
        // Hovering this will show the popper.
        const trigger = triggerRef.current;
        if (!trigger) {
            return;
        }
        // Calculating the space between the trigger and the cell for
        // precise hover item position and spacing.
        // This is needed because the height of the row is dynamic and the cell centers
        // content vertically, which means the row height can be greater than the
        // height of the trigger plus the vertical cell padding
        const tableCell = triggerRef.current.closest('td') || triggerRef.current.closest('th');
        if ((tableCell === null || tableCell === void 0 ? void 0 : tableCell.tagName) === 'TH') {
            setIsTableHead(true);
        }
        if (!tableCell) {
            return;
        }
        // We take the height of the cell and the trigger
        const tableCellHeight = tableCell.offsetHeight;
        const triggerHeight = triggerRef.current.offsetHeight;
        // Then subtract the height of the trigger from the height of the cell
        // and divide it by 2. This is how much padding top/bottom and negative
        // top positioning the popper will need to overlay the cell perfectly.
        // Also rounding the odd values down to replicate how the browser
        // rounds values.
        setSpacing(Math.floor((tableCellHeight - triggerHeight) / 2));
        // A function to clear the hover status if the user scrolls.
        // The default mouseOut event only triggers after scroll is complete,
        // which would leave the popper present until the scroll motion is complete
        const handleScroll = (event) => {
            const target = event.target;
            if (target === null || target === void 0 ? void 0 : target.contains(trigger))
                setIsHovered(false);
        };
        // Adding and removing an event listener to the scroll area
        if (scrollableAreaElement) {
            if (isHovered) {
                scrollableAreaElement.addEventListener('scroll', handleScroll);
                return () => scrollableAreaElement.removeEventListener('scroll', handleScroll);
            }
        }
    }, [isHovered, scrollableAreaElement]);
    useEffect(() => {
        // This is the popper, or the element that will be rendered on hover
        const content = contentRef.current;
        if (!isHovered || !content || !scrollableAreaDimensions) {
            return;
        }
        // Getting the position of the popper
        const contentPosition = content.getBoundingClientRect();
        // If the popper's right edge is outside the vieport, mark it as clipped on the right
        if (contentPosition.right > scrollableAreaDimensions.width) {
            setClipping((prevClipping) => (Object.assign(Object.assign({}, prevClipping), { right: true })));
        }
        // If the popper's bottom edge is outside the vieport, mark it as clipped on the bottom
        if (contentPosition.bottom > scrollableAreaDimensions.height) {
            setClipping((prevClipping) => (Object.assign(Object.assign({}, prevClipping), { bottom: true })));
        }
    }, [isHovered, scrollableAreaDimensions]);
    return (_jsxs(Stack, { onMouseEnter: handleMouseEnter, onMouseLeave: handleMouseLeave, className: "h-full", width: "full", direction: "row", align: "center", children: [_jsx("div", { className: cn('font-body text-13 w-full text-left', {
                    'h-full': isTableHead,
                }), ref: triggerRef, children: trigger }), isTruncated && isHovered && (_jsx(Content, { ref: contentRef, spacing: spacing, clipping: clipping, canCopy: canCopy, children: content }))] }));
});
const Content = observer(
// eslint-disable-next-line mobx/missing-observer
forwardRef(function Content(_a, ref) {
    var { spacing = 0, children, className, clipping, canCopy } = _a, rest = __rest(_a, ["spacing", "children", "className", "clipping", "canCopy"]);
    return (_jsx(Stack, Object.assign({ direction: "row", gap: "1", wrap: "wrap", className: cn(className, 'absolute z-[50]', '-top-2.5', 'bg-white', 'shadow-lg', 'rounded-md', 'py-2.5 ', `${canCopy ? 'pl-5 pr-10' : 'px-5'}`, 'w-[480px]', 'ring-1 ring-gray-600/10', 'font-body', 'text-13', getBorderRadius(clipping)), 
        // Setting the vertical padding to the popper according to the calculations earlier
        // and also fixing its positioning based on where it clips
        style: {
            paddingTop: spacing,
            paddingBottom: spacing,
            top: !(clipping === null || clipping === void 0 ? void 0 : clipping.bottom) ? 0 : 'unset',
            right: (clipping === null || clipping === void 0 ? void 0 : clipping.right) ? 0 : 'unset',
            bottom: (clipping === null || clipping === void 0 ? void 0 : clipping.bottom) ? 0 : 'unset',
            left: !(clipping === null || clipping === void 0 ? void 0 : clipping.right) ? 0 : 'unset',
        } }, rest, { ref: ref, children: children })));
}));
// Removing border radius from the corner that touches the table cell
// Remember that by default, it renders in the top left.
// Purely an aesthetic thing.
function getBorderRadius(clipping) {
    if (!clipping)
        return '';
    if (clipping.right && clipping.bottom) {
        return 'rounded-br-none';
    }
    if (clipping.right) {
        return 'rounded-tr-none';
    }
    if (clipping.bottom) {
        return 'rounded-bl-none';
    }
    return 'rounded-tl-none';
}
export { Container as HoverCell };
