import { __rest } from "tslib";
import { jsx as _jsx } from "react/jsx-runtime";
import { observer } from 'mobx-react';
import { forwardRef } from 'react';
import { BtwButton, cn } from '@marvelapp/ballpark-components';
export const DroppableButtonContainer = observer(forwardRef(
// eslint-disable-next-line mobx/missing-observer
function DroppableButtonContainer(props, ref) {
    const { className } = props, rest = __rest(props, ["className"]);
    return (_jsx(BtwButton, Object.assign({ variant: "ghost", width: "full", className: cn('py-6', 'h-auto', 'gap-4', 'flex-col', 'ring-0', 'border', 'border-dashed', 'border-gray-200', 'hover:bg-gray-50', 'hover:border-gray-300', 'active:bg-gray-100', className), ref: ref }, rest)));
}));
