import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { AlignRightIcon, BlockIcon } from '@marvelapp/ballpark-icons';
import { ConditionalLogicUtils, isExitStep, } from '@marvelapp/user-test-creator';
export const emptyStateCopy = {
    isExitStep: {
        icon: _jsx(AlignRightIcon, { className: "text-gray-500" }),
        title: 'That’s it, folks!',
        description: 'You’ve reached the end of the survey; there’s nowhere else to route to.',
    },
    isConditionable: {
        icon: _jsx(AlignRightIcon, { className: "text-gray-500" }),
        title: 'Users can only be routed to a single step…',
        description: (_jsxs(_Fragment, { children: ["This step type does not support routing based on a user's response. You can use ", _jsx("strong", { children: "\u201CAlways go to\u201D" }), " rule to route all users to a specific step."] })),
    },
    isNotConditionable: {
        icon: _jsx(BlockIcon, { className: "text-gray-500" }),
        title: 'Custom routing not supported',
        description: 'This step type does not support custom routing and will always go to the next step.',
    },
};
export function getLogicEmptyStateCopy(userTest, step) {
    if (isExitStep(userTest, step.uuid)) {
        return emptyStateCopy.isExitStep;
    }
    if (ConditionalLogicUtils.isConditionable(step, userTest)) {
        return emptyStateCopy.isConditionable;
    }
    return emptyStateCopy.isNotConditionable;
}
