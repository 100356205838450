import { color, layout, space } from 'styled-system';
import styled from '@marvelapp/styled';
import { fill, hoverFill } from '../customProperties';
export const Svg = styled.svg `
  ${color};
  ${layout};
  ${space};
  ${fill};

  &:hover {
    ${hoverFill};
  }
`;
