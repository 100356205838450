import { makePersistable, stopPersisting } from 'mobx-persist-store';
import { useEffect } from 'react';
import { validateFilter } from './filterUtils';
export function usePersistContext({ responsesContextState, defaultColumnVisibility, userTest, }) {
    useEffect(() => {
        if (!responsesContextState.projectPk) {
            return;
        }
        makePersistable(responsesContextState, {
            name: `project/${responsesContextState.projectPk}/responses`,
            properties: [
                {
                    key: 'sorting',
                    serialize: serializeToJSON,
                    deserialize(value) {
                        const sorting = parseFromJSON(value, []);
                        if (!Array.isArray(sorting)) {
                            return [];
                        }
                        return sorting.filter((sort) => {
                            var _a;
                            const column = responsesContextState.columnDefinitionsMap[sort.id];
                            const sortDefinition = (_a = column === null || column === void 0 ? void 0 : column.meta) === null || _a === void 0 ? void 0 : _a.sorting;
                            return validateSort(sort, sortDefinition);
                        });
                    },
                },
                {
                    key: 'filters',
                    serialize: serializeToJSON,
                    deserialize(value) {
                        const filters = parseFromJSON(value, []);
                        if (!Array.isArray(filters)) {
                            return [];
                        }
                        return filters.filter((filter) => filter.definitionId &&
                            validateFilter(filter, responsesContextState.filterDefinitionsMap[filter.definitionId]));
                    },
                },
                {
                    key: 'logicalOperator',
                    serialize: serializeToJSON,
                    deserialize(json) {
                        let logicalOperator = parseFromJSON(json, 'AND');
                        if (!['AND', 'OR'].includes(logicalOperator)) {
                            logicalOperator = 'AND';
                        }
                        return logicalOperator;
                    },
                },
                {
                    key: 'columnVisibility',
                    serialize: serializeToJSON,
                    deserialize(json) {
                        var _a, _b;
                        const columnVisibility = parseFromJSON(json, defaultColumnVisibility);
                        const columnUUIDs = (_b = (_a = responsesContextState.table) === null || _a === void 0 ? void 0 : _a.getAllColumns().map((column) => column.id)) !== null && _b !== void 0 ? _b : [];
                        // Validate column IDs against the latest version of the user test
                        // (in case the user test has been updated since persisting the
                        // column visibility state)
                        if (Object.keys(columnVisibility).some((uuid) => !columnUUIDs.includes(uuid))) {
                            return defaultColumnVisibility;
                        }
                        return columnVisibility;
                    },
                },
            ],
            storage: window.localStorage,
            // debugMode: process.env.NODE_ENV === 'development',
        });
        return () => stopPersisting(responsesContextState);
    }, [responsesContextState, defaultColumnVisibility, userTest]);
}
function serializeToJSON(value) {
    return JSON.stringify(value);
}
function parseFromJSON(value, defaultValue) {
    try {
        return JSON.parse(value);
    }
    catch (e) {
        return defaultValue;
    }
}
function validateSort(sort, sortDefinition) {
    if (!sortDefinition) {
        return false;
    }
    return typeof sort.desc === 'boolean';
}
