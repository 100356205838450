import { __rest } from "tslib";
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { AnimatePresence } from 'framer-motion';
import { useState } from 'react';
import theme from '@marvelapp/ballpark-theme';
import Position from '../Position';
import { Container, Cover, PopoverElement } from './styles';
export default function Popover(props) {
    const { align = 'center', animateOnMount = true, borderRadius = 2, boxShadow = 'popover.default', children, distance = 15, isActive, position = 'top', showOn = 'click', zIndex } = props, rest = __rest(props, ["align", "animateOnMount", "borderRadius", "boxShadow", "children", "distance", "isActive", "position", "showOn", "zIndex"]);
    const [isPopoverVisible, setIsPopoverVisible] = useState(false);
    const toggleVisibility = () => {
        setIsPopoverVisible(!isPopoverVisible);
    };
    const onMouseEnter = () => {
        setIsPopoverVisible(true);
    };
    const onMouseLeave = () => {
        setIsPopoverVisible(false);
    };
    const isVisible = showOn === 'custom' ? isActive : isPopoverVisible;
    const hoverProps = showOn === 'hover' && {
        onMouseLeave,
        onMouseEnter,
    };
    const clickProps = showOn === 'click' && {
        onClick: toggleVisibility,
    };
    const animation = animateOnMount
        ? {
            animate: {
                scale: 1,
                opacity: 1,
                y: '0%',
                transition: {
                    duration: 0.35,
                    ease: theme.easings.smooth.array,
                },
            },
            initial: {
                scale: 0.95,
                opacity: 1,
                y: position === 'top' ? '10%' : '-10%',
            },
            exit: {
                scale: 0.95,
                opacity: 0,
                y: position === 'top' ? '10%' : '-10%',
                transition: {
                    duration: 0.35,
                    ease: theme.easings.smooth.array,
                },
            },
            transition: {
                duration: 0.35,
                ease: theme.easings.smooth.array,
            },
        }
        : {
            animate: {},
            initial: {},
            exit: {},
            transition: {},
        };
    return (_jsxs(Position, Object.assign({ position: showOn === 'custom' ? 'unset' : 'relative', width: 1, zIndex: zIndex }, hoverProps, { children: [showOn !== 'custom' && _jsx("span", Object.assign({}, clickProps, { children: children[0] })), _jsx(AnimatePresence, { children: isVisible && (_jsx(Container, { animate: animation.animate, exit: animation.exit, initial: animation.initial, transition: animation.transition, children: _jsxs(PopoverElement, Object.assign({ align: align, bg: "white", borderRadius: borderRadius, boxShadow: boxShadow, distance: distance, position: position }, rest, { children: [showOn !== 'custom' ? children[1] : children, _jsx(Cover, { distance: distance, position: position })] })) }, "popover")) })] })));
}
