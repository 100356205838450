import { jsx as _jsx } from "react/jsx-runtime";
import { observer } from 'mobx-react';
import { useState } from 'react';
import { Redirect, useParams } from 'react-router-dom';
import { UserTestResponseUtils } from '@marvelapp/user-test-creator';
import CreateTestResult from './CreateTestResult';
import { getResumableResponseUUID } from './Resume';
export const GetOrCreateLiveSession = observer(function GetOrCreateLiveSession() {
    const { shareId } = useParams();
    // TODO need to query to get the testUUID from the shareUUID
    const testUUID = `ut_${shareId}`;
    const resumableResponseUUID = getResumableResponseUUID(testUUID);
    const [roomName, setRoomName] = useState();
    const [_token, setToken] = useState(null);
    const [_partnerData, setPartnerData] = useState(null);
    if (roomName) {
        // TODO set password token and partnerData to location state
        return _jsx(Redirect, { to: `/share/live/participant/${shareId}/${roomName}` });
    }
    return (_jsx(CreateTestResult, { onTestCreate: (_, roomName) => setRoomName(roomName), setToken: setToken, setPartnerData: setPartnerData, testUUID: testUUID, 
        // TODO once all the share link stuff is working properly we can pass the shareUUID
        // shareUUID={shareId}
        isLive: true, resumableLiveSessionResponseUUID: resumableResponseUUID !== null && resumableResponseUUID !== void 0 ? resumableResponseUUID : UserTestResponseUtils.generateResponseUUID() }));
});
