import { gql } from '@apollo/client';
export const queryMeta = {
    project: {
        addMemberMutation: 'addCollaboratorsToProject',
        removeMemberMutation: 'removeCollaboratorsFromProject',
        addMemberMutationResponseType: 'AddCollaboratorsToProject',
        removeMemberMutationResponseType: 'RemoveCollaboratorsFromProject',
        itemIdField: 'projectPk',
        itemNodeType: 'ProjectNode',
        idField: 'pk',
        idType: 'Int',
        pkField: 'projectPk',
        optimisticSucceededResponse: (member) => (Object.assign(Object.assign({}, member), { __typename: 'CollaboratorNode' })),
        memberEdgeAnnotation: 'isProjectCollaborator',
        memberEdgeType: 'CollaboratorEdge',
        memberNodeType: 'CollaboratorNode',
        nodeIndexKey: 'email',
        getAddedCollaboratorsFromResponse(attemptedMembers, succeeded) {
            return succeeded;
        },
        teamEdgeConnection: 'isAddedToProject',
        updatePasswordMutation: 'passwordProtectProject',
        updatePasswordMutationResponseType: 'PasswordProtectProject',
    },
};
export function getSharingData(itemName) {
    if (itemName === 'project') {
        return gql `
      query GET_SHARING_DATA($itemPk: Int, $itemId: String) {
        project(pk: $itemPk, shareId: $itemId) {
          pk
          name
          companyPk
          passwordProtected
          owner {
            pk
          }
          visibility
          prototypeUrl
        }
      }
    `;
    }
}
export function getCompanyMembers(itemName) {
    const { memberEdgeAnnotation, pkField } = queryMeta[itemName];
    return gql `
    query GET_COMPANY_USERS(
      $username: String
      $cursor: String
      $itemPk: Int!
    ) {
      user {
        pk
        company {
          pk
          members(username: $username, first: 6, after: $cursor)
            @connection(key: "members") {
            pageInfo {
              hasNextPage
              endCursor
            }
            edges {
              ${memberEdgeAnnotation}(${pkField}: $itemPk)
              node {
                pk
                avatarUrl
                email
                username
                role
              }
            }
          }
        }
      }
    }
  `;
}
export function getCollaborators(itemName) {
    const { idType, idField } = queryMeta[itemName];
    return gql `
    query GET_COLLABORATORS($itemId: ${idType}!, $cursor: String) {
      ${itemName}(${idField}: $itemId) {
        pk
        owner {
          pk
        }
        collaborators(first: 6, after: $cursor)
          @connection(key: "collaborators") {
          pageInfo {
            hasNextPage
            endCursor
          }
          edges {
            node {
              addedViaTeam
              pk
              avatarUrl
              email
              username
              role
            }
          }
        }
      }
    }
  `;
}
export function getAddCollaborators(itemName) {
    if (itemName === 'project') {
        return gql `
      mutation ADD_COLLABORATORS($itemPk: Int!, $emails: [String]!) {
        addCollaboratorsToProject(
          input: { projectPk: $itemPk, emails: $emails }
        ) {
          succeeded {
            email
            pk
            username
            avatarUrl
            role
          }
          failed {
            code
            email
          }
        }
      }
    `;
    }
}
export function onRemoveCollaborators(itemName) {
    if (itemName === 'project') {
        return gql `
      mutation REMOVE_COLLABORATORS($itemPk: Int!, $items: [String]!) {
        removeCollaboratorsFromProject(
          input: { projectPk: $itemPk, emails: $items }
        ) {
          succeeded
          failed {
            message
            email
          }
        }
      }
    `;
    }
}
export function updatePassword(itemName) {
    const { updatePasswordMutation, itemIdField, idType } = queryMeta[itemName];
    return gql `
    mutation ${updatePasswordMutation}($itemId: ${idType}!, $password: String!) {
      ${updatePasswordMutation}(
        input: { ${itemIdField}: $itemId, password: $password }
      ) {
        ok
        ${itemName} {
          passwordProtected
        }
        error {
          code
          message
        }
      }
    }
  `;
}
