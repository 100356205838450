import { __rest } from "tslib";
import { jsx as _jsx } from "react/jsx-runtime";
import { observer } from 'mobx-react';
import * as React from 'react';
import { DateDropdowns, Stack } from '@marvelapp/ballpark-components';
import { DateFormat, QuestionStepType, QuestionStepUtils, } from '@marvelapp/user-test-creator';
import BaseQuestionRecord from '../Question/BaseQuestionRecord';
export default observer(function DateRecord(_a) {
    var { step } = _a, rest = __rest(_a, ["step"]);
    const dateFormat = QuestionStepUtils.getDateFormat(step) || DateFormat.DDMMYYYY;
    return (_jsx(BaseQuestionRecord, Object.assign({ renderInput: ({ value, onChange }) => {
            return (_jsx(Stack, { className: "sm:max-w-xs", children: _jsx(DateDropdowns, { value: value, dateFormat: dateFormat, onChange: onChange }) }));
        }, step: step, type: QuestionStepType.QuestionDate }, rest)));
});
