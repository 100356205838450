import { __rest } from "tslib";
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { observer } from 'mobx-react';
import * as React from 'react';
import styled from '@marvelapp/styled';
import { Box, Grid } from '@marvelapp/ui';
import { YesOrNoStepType, getStepType } from '@marvelapp/user-test-creator';
import Checkbox from '../../../slides/common/Checkbox';
import { Answer } from './Answer';
export const YesNoAnswer = observer(function YesNoAnswer(_a) {
    var { answers, step } = _a, restProps = __rest(_a, ["answers", "step"]);
    const yesOrNoAnswer = (answers === null || answers === void 0 ? void 0 : answers.length) ? answers[0].value : null;
    const isLegalStep = getStepType(step) === YesOrNoStepType.Legal;
    return (_jsx(Answer, Object.assign({ step: step }, restProps, { children: _jsx(CheckboxWrapper, { children: _jsxs(Grid, { gridGap: "s", marginTop: "-4px", children: [_jsx(Checkbox, { name: `Yes${isLegalStep ? ', I accept' : ''}`, checked: yesOrNoAnswer === true, testId: "Yes-answer", readOnly: true }), _jsx(Checkbox, { name: `No${isLegalStep ? ", I don't accept" : ''}`, checked: yesOrNoAnswer === false, testId: "No-answer", readOnly: true })] }) }) })));
});
const CheckboxWrapper = styled(Box).attrs({
    maxWidth: ['100%', '100%', '50%'],
}) `
  display: inline-block;
  width: 100%;
`;
