import Highlighter from 'react-highlight-words';
import styled from '@marvelapp/styled';
export const HighlighterElement = styled(Highlighter) `
  .highlight {
    background: rgb(255, 235, 179);
    border-radius: 2px;
    padding: 3px 0;
  }
`;
HighlighterElement.defaultProps = {
    highlightClassName: 'highlight',
};
