import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useRef, useState } from 'react';
import { UserRole } from '@marvelapp/core';
import { useOnClickOutside } from '@marvelapp/hooks';
import { Popover, Position, Text } from '@marvelapp/ui';
import DropdownTrigger from '../DropdownTrigger';
import UserRoleOption from './UserRoleOption';
const rolesDisplayLabels = {
    [UserRole.Owner]: 'Owner',
    [UserRole.Admin]: 'Admin',
    [UserRole.Editor]: 'Editor',
    [UserRole.InternalReviewer]: 'Viewer',
    [UserRole.ExternalReviewer]: 'Viewer',
    [UserRole.ExternalEditor]: 'Editor',
};
const UserRoleSelect = ({ onSelection, options, selectedRole, disabled }) => {
    const [showOptions, setShowOptions] = useState(false);
    const selectedRoleDropdown = useRef(null);
    useOnClickOutside(selectedRoleDropdown, () => setShowOptions(false));
    const toggleOptions = () => setShowOptions(!showOptions);
    const createOnClickHandler = (option) => () => {
        if (option.url) {
            setShowOptions(false);
        }
        onSelection(option.value);
        toggleOptions();
    };
    const createOptions = () => {
        return options.map((option, index) => {
            if (option.hide)
                return null;
            return (_jsx(UserRoleOption, { description: option.description, isDisabled: option.disabled, label: option.label, lastChild: options.length - 1 === index, onClick: createOnClickHandler(option), selected: option.value === selectedRole, url: option.url, value: option.value }, option.value));
        });
    };
    const isActive = !disabled && showOptions;
    if (disabled || options.length === 1) {
        return (_jsx(Text, { color: disabled && 'silver', "data-testid": "user-role-display-label", fontSize: 2, textAlign: "right", mr: 2, children: rolesDisplayLabels[selectedRole] }));
    }
    return (_jsxs(Position, { marginRight: disabled ? 2 : 0, ref: selectedRoleDropdown, zIndex: isActive ? 'popover' : 'inherit', children: [_jsx(DropdownTrigger, { "data-testid": "user-role-select-trigger", isActive: isActive, isDisabled: disabled, onClick: toggleOptions, children: _jsx(Text, { "data-testid": "user-role-display-label", fontSize: 2, children: rolesDisplayLabels[selectedRole] }) }), _jsx(Popover, { align: "right", boxShadow: "popover.raised", disabled: disabled, isActive: isActive, maxWidth: 260, overflow: "hidden", position: "bottom", showOn: "custom", width: "100vw", children: createOptions() })] }));
};
export default UserRoleSelect;
