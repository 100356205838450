import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { observer } from 'mobx-react';
import { SummaryTabs } from '@marvelapp/ballpark-components';
import { StepClass } from '@marvelapp/user-test-creator';
import { MetricsAccordion, MetricsAccordionHeading } from './MetricsAccordion';
import { StandardizeCategories } from './StandardizeCategories';
import { getCategoryMetrics } from './metricUtils';
import { useMetricsContext } from './metricsContext';
export const CategoryMetrics = observer(function CategoryMetrics() {
    const { step, categoryUUIDToCategoryMap, cardUUIDToCardMap, cardAgreement, categoryFrequency, categoryAgreementAvg, categoryPositionAvg, } = useMetricsContext();
    const categoryMetrics = getCategoryMetrics({
        categoryAgreementAvg,
        cardAgreement,
        categoryFrequency,
        cardUUIDToCardMap,
        categoryUUIDToCategoryMap,
        categoryPositionAvg,
    });
    return (_jsxs(_Fragment, { children: [_jsx(MetricsAccordion, { items: categoryMetrics, headings: _jsxs(_Fragment, { children: [_jsx(MetricsAccordionHeading, { className: "flex-1", children: "Categories" }), _jsx(MetricsAccordionHeading, { className: "w-20", children: "Cards" }), _jsx(MetricsAccordionHeading, { className: "w-20" }), _jsx(MetricsAccordionHeading, { className: "flex-1", children: "Agreement" })] }), type: "category" }), _jsx(SummaryTabs.FooterCta, { children: step.className !== StepClass.CardSortingClosed && (_jsx(StandardizeCategories, {})) })] }));
});
