import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { observer } from 'mobx-react';
import { BtwMessage, BtwText, Stack, Strong, } from '@marvelapp/ballpark-components';
import { useRecruitmentState } from '../RecruitmentContext';
import { RecruitmentSection, StudySummary, getSelectionCopy } from '../common';
import { EligibilityCount } from './EligibilityCount';
import { LastOrderBlock } from './LastOrderBlock';
import { OrderSummaryFooter } from './OrderSummaryFooter';
export const OrderSummary = observer(function OrderSummary() {
    return (_jsxs(Stack, { gap: "6", children: [_jsxs(RecruitmentSection, { "data-testid": "order-summary", children: [_jsxs(Stack, { gap: "4", className: "border-b border-gray-600/10 p-4", width: "full", children: [_jsx(StudySummary, {}), _jsx(ValidationError, {}), _jsx(OrderSummaryFooter, {})] }), _jsx(EligibilityCount, {})] }), _jsx(LastOrderBlock, {})] }));
});
const ValidationError = observer(function ValidationError() {
    const { hasValidationErrors, requiredFilters, getMissingRequiredFiltersIds } = useRecruitmentState();
    const missingFilters = getMissingRequiredFiltersIds();
    if (!hasValidationErrors || !missingFilters.length)
        return null;
    return (_jsx(BtwMessage, { className: "w-full", "data-testid": "validation-error-message", variant: "red", children: _jsxs(Stack, { gap: "4", children: [_jsx(BtwText, { asChild: true, size: "13", variant: "inherit", children: _jsx("p", { children: "We need a few more details before you can submit the order:" }) }), _jsx(BtwText, { asChild: true, size: "13", variant: "inherit", children: _jsx("ul", { className: "ml-3 list-disc pl-2", children: missingFilters.map((filterId) => {
                            const { name, maxSelection, minSelection } = requiredFilters[filterId];
                            const selectionCopy = getSelectionCopy(minSelection, maxSelection);
                            return (_jsxs("li", { children: [_jsx(Strong, { variant: "inherit", children: name }), ' ', selectionCopy && `(${selectionCopy})`] }, name));
                        }) }) })] }) }));
});
