export default function stripUUIDPrefix(step) {
    if (!step.uuid)
        return null;
    const parts = step.uuid.split('_');
    return parts[parts.length - 1];
}
export function stripPrefix(uuid) {
    if (!uuid)
        return '';
    const parts = uuid.split('_');
    return parts[parts.length - 1];
}
