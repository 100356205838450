import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { observer } from 'mobx-react';
import { useEffect, useRef } from 'react';
import * as React from 'react';
import { useGetUserTest } from '@marvelapp/ballpark-application';
import { Stack } from '@marvelapp/ballpark-components';
import { getLogicEmptyStateCopy } from '@marvelapp/ballpark-copy';
import { ConditionalLogicUtils, getStepByUUID, } from '@marvelapp/user-test-creator';
import { useConditionalLogicState } from '../../ConditionalLogicContext';
import { AddRuleButton, AlwaysSelect, ClearAllRulesButton, ConditionalEmptyState, } from './shared/sharedComponents';
export default observer(function BaseLogic({ alwaysPrefixText, canAddRule = false, addRule, children, isAddRuleButtonDisabled = false, }) {
    var _a;
    const buttonBoxRef = useRef(null);
    useConditionalScrollIntoView(buttonBoxRef);
    const { activeStepUUID } = useConditionalLogicState();
    const userTest = useGetUserTest();
    const step = getStepByUUID(userTest, activeStepUUID);
    if (!step)
        return null;
    // TODO: simplify this check to use the 'in' operator ('conditions' in step), when BALL-438 is completed
    const alwaysRuleUUID = ConditionalLogicUtils.isConditionable(step, userTest)
        ? (_a = step.conditions) === null || _a === void 0 ? void 0 : _a.ALWAYS
        : null;
    const emptyStateCopy = getLogicEmptyStateCopy(userTest, step);
    return (_jsxs(Stack, { "data-testid": "conditional-logic-container", height: "full", width: "full", className: "bg-gray-50", children: [_jsxs(Stack, { className: "animate-in fade-in overflow-y-scroll p-6 duration-200", height: "full", width: "full", children: [children || (_jsx(ConditionalEmptyState, { icon: emptyStateCopy.icon, title: emptyStateCopy.title, children: emptyStateCopy.description })), canAddRule && (_jsxs(Stack, { "data-testid": "action-buttons-block", direction: "row", gap: "4", ref: buttonBoxRef, className: "self-center", children: [_jsx(ClearAllRulesButton, {}), _jsx(AddRuleButton, { addRule: addRule, isDisabled: isAddRuleButtonDisabled })] }))] }), _jsx(AlwaysSelect
            // this component is never unmounted and therefore retains stale data
            // this key ensures that a new instance is rendered when the saved rule is deleted
            , { prefixText: alwaysPrefixText, savedAlwaysStepUuid: alwaysRuleUUID || null }, `${`${alwaysRuleUUID}-always-rule`}`)] }));
});
function useConditionalScrollIntoView(ref) {
    const { activeStepUUID } = useConditionalLogicState();
    const userTest = useGetUserTest();
    const step = getStepByUUID(userTest, activeStepUUID);
    const multiConditionRulesCount = step
        ? ConditionalLogicUtils.getMultiConditionRulesCount(step, userTest)
        : 0;
    const { isAddingNewRule } = useConditionalLogicState();
    // two handlers to handle boolean & multi condition components
    useEffect(() => {
        if (isAddingNewRule && ref.current) {
            ref.current.scrollIntoView({
                behavior: 'smooth',
                block: 'start',
            });
        }
    }, [isAddingNewRule, ref]);
    useEffect(() => {
        if (isAddingNewRule && multiConditionRulesCount && ref.current) {
            ref.current.scrollIntoView({
                behavior: 'smooth',
                block: 'start',
            });
        }
    }, [isAddingNewRule, multiConditionRulesCount, ref]);
}
