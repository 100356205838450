import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useResponsiveValue } from '@theme-ui/match-media';
import { observer } from 'mobx-react';
import * as React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { BtwButton } from '@marvelapp/ballpark-components';
import { SUBMIT_STEP_COPY, UI_COMMON_STEPS_COPY, UI_STEPS_COPY, } from '@marvelapp/ballpark-copy';
import styled from '@marvelapp/styled';
import { Box } from '@marvelapp/ui';
import { LocaleUtils, PrototypeStepType, PrototypeTaskUtils, StepClass, StepUtils, isSubmitStep, } from '@marvelapp/user-test-creator';
import { usePrototypeState } from '../../../components/Record/PrototypeContext';
import { useRecorderState } from '../../../components/Record/RecorderContext';
import { SlideRecordContent } from '../../common/SlideRecord/SlideRecordContent';
import { FigmaStatusMessage } from './FigmaStatus';
import { CachedPlayerSetupStatus, PlayerStatus } from './types';
export default observer(function PrototypeInstructions({ hasInstructions, startTest, step, }) {
    const { playerStatus, isInvalid, cachedPlayerSetupStatus, embedPermissionStatus, } = usePrototypeState();
    const intl = useIntl();
    const { userTestDoc, goForward } = useRecorderState();
    const isPrototype = StepUtils.isOfType(step, StepClass.PrototypeTask) &&
        !PrototypeTaskUtils.isFirstClick(step);
    const isReady = cachedPlayerSetupStatus === CachedPlayerSetupStatus.NotCached
        ? playerStatus === PlayerStatus.Ready
        : cachedPlayerSetupStatus === CachedPlayerSetupStatus.Ready;
    const isPrototypePasswordProtected = (embedPermissionStatus === null || embedPermissionStatus === void 0 ? void 0 : embedPermissionStatus.code) === 401;
    return (_jsxs(_Fragment, { children: [_jsx(Container, { "data-testid": "prototype-instructions-container", children: _jsx(SlideRecordContent, { step: step, hasError: isPrototypePasswordProtected, customTitle: isPrototypePasswordProtected
                        ? intl.formatMessage({ id: "h5LFdF", defaultMessage: "Unable to load prototype" })
                        : undefined, customDescription: isPrototypePasswordProtected
                        ? LocaleUtils.localeStringToRichText(intl.formatMessage({ id: "tIFLmj", defaultMessage: "There was a problem loading the prototype as it is password protected" }))
                        : undefined }) }), _jsxs(ButtonsWrap, { "data-testid": "buttons-wrap", hasInstructions: hasInstructions, isPrototype: isPrototype, children: [_jsx(FigmaStatusMessage, { step: step }), isReady && playerStatus !== PlayerStatus.ConnectingToVideoServer && (_jsx(BtwButton, { "data-testid": "start-prototype-recording", onClick: startTest, variant: "legacyBlue", width: "full", children: _jsx("span", { className: "truncate", children: PrototypeTaskUtils.isFirstClick(step)
                                ? intl.formatMessage(UI_STEPS_COPY[PrototypeStepType.FirstClick].buttonText)
                                : intl.formatMessage(UI_STEPS_COPY[StepClass.PrototypeTask].buttonText) }) })), (playerStatus === PlayerStatus.Loading ||
                        cachedPlayerSetupStatus === CachedPlayerSetupStatus.Loading) &&
                        !isInvalid && (_jsx(BtwButton, { "data-testid": "prototype-loading", isLoading: true, width: "full", children: _jsx("span", { className: "truncate", children: PrototypeTaskUtils.isFirstClick(step) ? (_jsx(FormattedMessage, { id: 'LO+9Wm', defaultMessage: 'Loading image' })) : (_jsx(FormattedMessage, { id: '0DDPnP', defaultMessage: 'Loading interactive prototype' })) }) })), playerStatus === PlayerStatus.ConnectingToVideoServer && (_jsx(BtwButton, { "data-testid": "connecting-to-video-server", isLoading: true, width: "full", children: _jsx("span", { className: "truncate", children: _jsx(FormattedMessage, { id: 'lqh4tG', defaultMessage: 'Preparing task' }) }) })), isInvalid && (_jsx(BtwButton, { "data-testid": "skip-invalid-prototype-step", onClick: goForward, variant: "legacyBlue", width: "full", children: _jsx("span", { className: "truncate", children: intl.formatMessage(isSubmitStep(userTestDoc, step.uuid)
                                ? SUBMIT_STEP_COPY.buttonText
                                : UI_COMMON_STEPS_COPY.buttonText) }) }))] })] }));
});
const Container = styled(Box).attrs({ pb: 'xl' }) `
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
`;
const ButtonsWrap = observer(function ButtonsWrap({ children, hasInstructions, isPrototype, }) {
    const isMobile = useResponsiveValue([true, true, false]);
    if (isMobile) {
        return (_jsx(MobileContainer, { hasInstructions: hasInstructions, children: children }));
    }
    if (isPrototype) {
        return _jsx(StickyContainer, { children: children });
    }
    return _jsx(Buttons, { children: children });
});
const Buttons = styled(Box).attrs({
    marginTop: 's',
}) `
  display: flex;
  align-items: center;
`;
const MobileContainer = styled(Box) `
  position: fixed;
  bottom: 12px;
  left: 12px;
  right: 96px;
  background: white;
  opacity: 0.9;
  button {
    width: 100%;
  }
  z-index: 1;
`;
const StickyContainer = styled(Box).attrs({
    padding: 'm',
}) `
  z-index: 10;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  backdrop-filter: blur(10px);
  background: rgba(255, 255, 255, 0.8);
  box-shadow: 0 -1px 0 0 rgba(0, 0, 0, 0.08);
`;
