export function makeLinkValid(link) {
    let url;
    try {
        // Check if the link is valid -- the URL constructor will throw an error if it's not
        url = new URL(link);
    }
    catch (e) {
        try {
            // The link as entered by the user didn't validate so prepend 'https://' to it
            // and see if it validates. This lets people enter links like 'marvelapp.com'
            // without having to type the protocol.
            url = new URL(`https://${link}`);
        }
        catch (ee) {
            return null;
        }
    }
    return url.toString();
}
