import { useMaybeRoomContext } from '@livekit/components-react';
import { DisconnectReason } from 'livekit-client';
import { useEffect } from 'react';
export var LiveSessionStatus;
(function (LiveSessionStatus) {
    LiveSessionStatus[LiveSessionStatus["Pending"] = 0] = "Pending";
    LiveSessionStatus[LiveSessionStatus["Lobby"] = 1] = "Lobby";
    LiveSessionStatus[LiveSessionStatus["Recording"] = 2] = "Recording";
    LiveSessionStatus[LiveSessionStatus["Ended"] = 3] = "Ended";
})(LiveSessionStatus || (LiveSessionStatus = {}));
export function useLiveSessionStatus(setStatus) {
    // TODO: can we only render the participant room once iniitalized? Then we
    // don't need useMaybeRoomContext
    const room = useMaybeRoomContext();
    useEffect(() => {
        if (!room || !(room === null || room === void 0 ? void 0 : room.getSid())) {
            setStatus(LiveSessionStatus.Pending);
            return;
        }
        if (room.isRecording) {
            setStatus(LiveSessionStatus.Recording);
        }
        const connected = () => {
            if (room.isRecording) {
                setStatus(LiveSessionStatus.Recording);
            }
            else {
                setStatus(LiveSessionStatus.Lobby);
            }
        };
        const recordingStatusChanged = (isRecording) => {
            if (isRecording) {
                setStatus(LiveSessionStatus.Recording);
            }
        };
        const disconnected = (reason) => {
            if (reason === DisconnectReason.ROOM_DELETED ||
                reason === DisconnectReason.ROOM_CLOSED)
                setStatus(LiveSessionStatus.Ended);
        };
        room.on('connected', connected);
        room.on('recordingStatusChanged', recordingStatusChanged);
        room.on('disconnected', disconnected);
        return () => {
            room.off('connected', connected);
            room.off('recordingStatusChanged', recordingStatusChanged);
            room.off('disconnected', disconnected);
        };
    }, [room, setStatus]);
}
