import { __rest } from "tslib";
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useResponsiveValue } from '@theme-ui/match-media';
import { isNil } from 'lodash-es';
import { observer } from 'mobx-react';
import { useState } from 'react';
import * as React from 'react';
import { Text } from '@marvelapp/ballpark-components';
import styled from '@marvelapp/styled';
import { Box } from '@marvelapp/ui';
import { RatingResponseUtils, RatingStepUtils, } from '@marvelapp/user-test-creator';
import PreferenceRateItem from '../../components/PreferenceRateItem';
import { useRecorderState } from '../../components/Record/RecorderContext';
import SlideRecord from '../common/SlideRecord';
export const RateRecordSlide = observer(function RateRecordSlide(_a) {
    var { stepResponse, step } = _a, rest = __rest(_a, ["stepResponse", "step"]);
    const [hover, setHover] = useState(undefined);
    const { minRating, maxRating, decorativeImage } = step;
    // For new tests, number of items count goes up by 1 if min rating is 0. i.e min rating 0 but max was 10,
    // Now numberOfItemsCount is 11 items.
    let numberOfItemsCount = step.minRating === 0 ? maxRating + 1 : maxRating;
    const { userTestDoc } = useRecorderState();
    if (isNil(userTestDoc.createdAt)) {
        // Old test, starts with min rating 0 by default (was set to this in the schema),
        // So the number of items count is whatever the max maxrating is.
        numberOfItemsCount = maxRating;
    }
    const midRating = Math.floor((minRating + numberOfItemsCount) / 2);
    const hasSomeLabel = RatingStepUtils.getRatingLabel(step, 'start') !== '' ||
        RatingStepUtils.getRatingLabel(step, 'middle') !== '' ||
        RatingStepUtils.getRatingLabel(step, 'end') !== '';
    const itemsLength = [...Array(numberOfItemsCount)].length;
    const isMobileForAllItems = useResponsiveValue(decorativeImage
        ? [true, true, true, true, true, true, true, false]
        : [true, true, true, true, true, false]);
    const isMobileForLessItems = useResponsiveValue([true, true, true, false]);
    const isMobile = itemsLength > 5 ? isMobileForAllItems : isMobileForLessItems;
    const startLabel = RatingStepUtils.getRatingLabel(step, 'start');
    const middleLabel = RatingStepUtils.getRatingLabel(step, 'middle');
    const endLabel = RatingStepUtils.getRatingLabel(step, 'end');
    const RatingStepContainerVariant = decorativeImage
        ? // eslint-disable-next-line @typescript-eslint/no-use-before-define, no-use-before-define
            CompactRatingStepContainer
        : // eslint-disable-next-line @typescript-eslint/no-use-before-define, no-use-before-define
            RatingStepContainer;
    const PreferenceBoxContainerVariant = decorativeImage
        ? // eslint-disable-next-line @typescript-eslint/no-use-before-define, no-use-before-define
            CompactPreferenceBoxContainer
        : // eslint-disable-next-line @typescript-eslint/no-use-before-define, no-use-before-define
            PreferenceBoxContainer;
    return (_jsxs(SlideRecord, Object.assign({ maxWidth: 1380, step: step }, rest, { children: [hasSomeLabel && isMobile && (_jsxs(LabelsMobileContainer, { children: [_jsx(Text, { size: "m", "data-testid": "rating-label-start", children: startLabel && (_jsxs(_Fragment, { children: [_jsx(Strong, { children: minRating }), " \u2013 ", startLabel] })) }), _jsx(Text, { size: "m", "data-testid": "rating-label-middle", children: middleLabel && (_jsxs(_Fragment, { children: [_jsx(Strong, { children: midRating }), " \u2013 ", middleLabel] })) }), _jsx(Text, { size: "m", "data-testid": "rating-label-end", children: endLabel && (_jsxs(_Fragment, { children: [_jsx(Strong, { children: maxRating }), " \u2013 ", endLabel] })) })] })), _jsx(RatingStepContainerVariant, { items: [...Array(numberOfItemsCount)].length, hasDecorativeImage: !!decorativeImage, marginBottom: 'xs', children: [...Array(numberOfItemsCount)].map((_, index) => {
                    // If the test is old, just use index + 1 like before
                    // so that the test doesn't break
                    var _a, _b;
                    // If the test is new, then use index which is 0 + min rating 0|1
                    const i = isNil(userTestDoc.createdAt)
                        ? index + 1
                        : index + step.minRating;
                    return (_jsx(PreferenceBoxContainerVariant, { "data-testid": `rating-${index}`, items: itemsLength, onMouseEnter: () => setHover(i), onMouseLeave: () => setHover(undefined), children: _jsx(PreferenceRateItem, { index: i, type: step.ratingType, onClick: () => RatingResponseUtils.setAnswer(stepResponse, i), onKeyDown: (e) => {
                                if (e.key === 'Enter') {
                                    RatingResponseUtils.setAnswer(stepResponse, i);
                                }
                            }, selected: i <=
                                ((_a = (hover || RatingResponseUtils.getAnswer(stepResponse))) !== null && _a !== void 0 ? _a : -1), size: ((_b = step.decorativeImage) === null || _b === void 0 ? void 0 : _b.url) ? 'compact' : 'default' }) }, i));
                }) }), hasSomeLabel && !isMobile && (_jsxs(LabelsContainer, { children: [_jsx(Text, { size: "m", justifySelf: "start", wordBreak: "break-word", "data-testid": "rating-label-start", children: startLabel }), _jsx(Text, { size: "m", wordBreak: "break-word", justifySelf: "center", textAlign: "center", "data-testid": "rating-label-middle", children: middleLabel }), _jsx(Text, { size: "m", justifySelf: "end", textAlign: "right", wordBreak: "break-word", "data-testid": "rating-label-end", children: endLabel })] })), _jsx(Box, { width: 1 })] })));
});
const Strong = styled(Box).attrs({
    fontWeight: 'semibold',
    color: 'slate12',
}) `
  display: inline;
`;
const LabelsContainer = styled(Box) `
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  grid-gap: 10px;
`;
const LabelsMobileContainer = styled(Box).attrs({
    marginTop: 'm',
    marginBottom: 'l',
}) ``;
const PreferenceBoxContainer = styled(Box).attrs(({ items }) => ({
    paddingRight: items > 5 ? [0, 0, 0, 0, 0, 's'] : [0, 0, 0, 's'],
})) `
  flex: 1;
  position: relative;
  &:last-child {
    padding-right: 0;
  }
`;
const CompactPreferenceBoxContainer = styled(PreferenceBoxContainer).attrs(({ items }) => ({
    paddingRight: 0,
    marginRight: items > 5 ? [0, 0, 0, 0, 0, 'xs'] : [0, 0, 0, 'xs'],
})) ``;
const RatingStepContainer = styled(Box).attrs(({ items }) => ({
    display: items > 5
        ? ['grid', 'grid', 'grid', 'grid', 'grid', 'flex']
        : ['grid', 'grid', 'grid', 'flex'],
    gridGap: items > 5 ? ['s', 's', 's', 's', 's', 0] : ['s', 's', 's', 0],
    gridTemplateColumns: items > 5
        ? [
            'repeat(4, 1fr)',
            'repeat(4, 1fr)',
            'repeat(4, 1fr)',
            'repeat(4, 1fr)',
            'repeat(4, 1fr)',
            0,
        ]
        : ['repeat(4, 1fr)', 'repeat(4, 1fr)', 'repeat(4, 1fr)', 0],
    marginTop: 'l',
})) `
  max-width: 100%;
  position: relative;
`;
const CompactRatingStepContainer = styled(Box).attrs(({ items }) => ({
    display: items > 5
        ? ['grid', 'grid', 'grid', 'grid', 'grid', 'grid', 'grid', 'flex']
        : ['grid', 'grid', 'grid', 'flex'],
    gridGap: items > 5 ? ['s', 's', 's', 's', 's', 's', 's', 0] : ['s', 's', 's', 0],
    gridTemplateColumns: items > 5
        ? [
            'repeat(4, 1fr)',
            'repeat(4, 1fr)',
            'repeat(4, 1fr)',
            'repeat(4, 1fr)',
            'repeat(4, 1fr)',
            'repeat(5, 1fr)',
            'repeat(5, 1fr)',
            0,
        ]
        : ['repeat(4, 1fr)', 'repeat(4, 1fr)', 'repeat(4, 1fr)', 0],
    marginTop: 'l',
})) `
  max-width: 100%;
  position: relative;
`;
