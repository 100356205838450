import { jsx as _jsx } from "react/jsx-runtime";
import { observer } from 'mobx-react';
import { useGetUserTest } from '@marvelapp/ballpark-application';
import { ScreenerUtils } from '@marvelapp/user-test-creator';
import { FixedScreenerSlide } from './FixedSlides';
import ScreenerEdit from './ScreenerEdit';
const ScreenerSlide = observer(function ScreenerSlide({ stepUUID, onMainCTAClick, }) {
    const userTest = useGetUserTest();
    if (ScreenerUtils.isIntroStep(userTest, stepUUID)) {
        return _jsx(FixedScreenerSlide, { onMainCTAClick: onMainCTAClick });
    }
    if (ScreenerUtils.isDeclineStep(userTest, stepUUID)) {
        return _jsx(FixedScreenerSlide, {});
    }
    return _jsx(ScreenerEdit, { onMainCTAClick: onMainCTAClick });
});
export default ScreenerSlide;
