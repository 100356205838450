import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { WARNING_ICON } from '@marvelapp/ballpark-theme';
import { Box, Flex, Icon, Text } from '@marvelapp/ui';
import IconCircle from '../IconCircle';
export default function FailedText({ alignItems, borderColor, children, color, fontSize, }) {
    return (_jsxs(Flex, { alignItems: alignItems, children: [_jsx(Box, { mr: 12, children: _jsx(IconCircle, { borderColor: borderColor, color: color, children: _jsx(Icon, { display: "block", paths: WARNING_ICON }) }) }), _jsx(Flex, { alignItems: "center", minHeight: "26px", children: _jsx(Text, { color: color, fontSize: fontSize, children: children }) })] }));
}
FailedText.defaultProps = {
    alignItems: 'flex-start',
    color: 'red',
    fontSize: 14,
};
