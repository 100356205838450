import { jsx as _jsx } from "react/jsx-runtime";
import { gql, useMutation } from '@apollo/client';
import { observer } from 'mobx-react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { Badge } from '@marvelapp/ballpark-components';
import { decodeParams } from '@marvelapp/core';
import { InviteUsers, showAddPeopleNotifications, showGenericServerError, sortFailedInvitesByReason, } from '@marvelapp/ui-internal';
const ADD_COLLABORATORS_TO_PROJECT = gql `
  mutation ADD_COLLABORATORS($itemPk: Int!, $emails: [String]!) {
    addCollaboratorsToProject(input: { projectPk: $itemPk, emails: $emails }) {
      succeeded {
        email
        pk
        username
        avatarUrl
        role
      }
      failed {
        code
        email
      }
    }
  }
`;
export const InviteToProjectAndWorkspace = observer(function InviteToProjectAndWorkspace() {
    const match = useRouteMatch();
    const history = useHistory();
    const [addCollaboratorsMutation] = useMutation(ADD_COLLABORATORS_TO_PROJECT);
    const addCollaborators = (usersToAdd) => addCollaboratorsMutation({
        variables: {
            itemPk: Number(match.params.pk),
            emails: usersToAdd,
        },
    });
    const searchParams = decodeParams(window.location.search);
    const redirect = () => history.replace(searchParams.next);
    const handleInviteSuccess = ({ inviteUsersToWorkspace: { succeeded, failed }, setEmails, setIsSendingInvite, setShowFormError, }) => {
        const { domainFailures, alreadyOnAnotherPaidAccountFailures, alreadyOnThisAccountFailures, otherFailures, } = sortFailedInvitesByReason(failed);
        // if some of users fail to be added to company at this point
        // for reasons other than specified, we still want to
        // continue onto adding them to the item
        const usersToAdd = succeeded.concat(otherFailures.map((user) => user.email));
        const triggerFormError = () => {
            setShowFormError(true);
            setIsSendingInvite(false);
        };
        const retry = () => {
            triggerFormError();
            addCollaborators(usersToAdd);
        };
        addCollaborators(usersToAdd)
            .then(({ data }) => {
            showAddPeopleNotifications({
                alreadyOnAnotherPaidAccountFailures,
                alreadyOnThisAccountFailures,
                data: data.addCollaboratorsToProject,
                domainFailures,
                entity: 'project',
                otherFailures: data.addCollaboratorsToProject.failed,
                redirect,
                retry,
                setEmails,
                triggerFormError,
            });
        })
            .catch(() => {
            triggerFormError();
            showGenericServerError({
                retry,
                closeOnClick: true,
            });
        });
    };
    return (_jsx(InviteUsers, { onInviteSuccess: handleInviteSuccess, upgradeBadge: _jsx(Badge, { "data-testid": "upgrade-badge", size: "sm", variant: "outline", children: "Upgrade" }) }));
});
