import { gql, useMutation } from '@apollo/client';
import { useCallback, useMemo, useState } from 'react';
import { DeviceUtils, UserTestResponseUtils, } from '@marvelapp/user-test-creator';
import { RecordTestSessionErrorEnum } from '../../__generated__/queryTypes';
const RECORD_TEST_RESULT = gql `
  mutation RECORD_RESULT($input: RecordTestSessionInput!) {
    recordTestResult(input: $input) {
      ok
      error {
        message
        code
      }
      result {
        pk
      }
      partnerResultMapper {
        source
        bounceUrl
      }
      prototype {
        shareId
        isArchived
        settings {
          isAvailableOnDesktopDevice
          isAvailableOnMobileDevice
          isAvailableOnTabletDevice
        }
      }
      locale
      roomName
    }
  }
`;
export var MutationStatus;
(function (MutationStatus) {
    MutationStatus["Pending"] = "Pending";
    MutationStatus["Error"] = "Error";
    MutationStatus["Success"] = "Success";
})(MutationStatus || (MutationStatus = {}));
export function useRecordTestResult({ testUUID, shareUUID, resumableLiveSessionResponseUUID, isLive = false, }) {
    var _a;
    // If there is a responseUUID in the URL, it means the document was pre-seeded
    const urlParams = useMemo(() => new URLSearchParams(window.location.search), []);
    const urlResponseUUID = urlParams.get('responseUUID');
    // once set, we don't want the `responseUUID` to change
    const [responseUUID] = useState(urlResponseUUID || UserTestResponseUtils.generateResponseUUID());
    // using `mutationStatus` instead of `loading` value from the mutation,
    // because it is set to `false` even before the mutation is called
    const [mutationStatus, setMutationStatus] = useState(MutationStatus.Pending);
    const [_recordTestResult, { data, error }] = useMutation(RECORD_TEST_RESULT, {
        onCompleted(data) {
            // ignore any errors related to partner data as they should not block a user from taking a test
            if (data.recordTestResult.error &&
                data.recordTestResult.error.code !==
                    RecordTestSessionErrorEnum.UNEXPECTED_URL_PARAMS) {
                setMutationStatus(MutationStatus.Error);
            }
            else {
                setMutationStatus(MutationStatus.Success);
                if (window.Cypress) {
                    window.__resultPk = data.recordTestResult.result.pk;
                }
            }
        },
        onError() {
            setMutationStatus(MutationStatus.Error);
        },
    });
    // we send the whole query param string through and backend processes it accordingly
    const customReferralParams = urlParams.size > 0 ? urlParams.toString() : undefined;
    const recordTestResult = useCallback((passwordToken) => {
        _recordTestResult({
            variables: {
                input: {
                    testUUID,
                    shareUUID,
                    responseUUID: isLive
                        ? resumableLiveSessionResponseUUID
                        : responseUUID,
                    device: DeviceUtils.getDeviceInfo(),
                    // TODO: when we support revisions, this should come from the user test doc
                    testVersion: 0,
                    passwordToken,
                    customReferralParams,
                    isLive,
                },
            },
        });
    }, [
        _recordTestResult,
        customReferralParams,
        isLive,
        responseUUID,
        resumableLiveSessionResponseUUID,
        shareUUID,
        testUUID,
    ]);
    return {
        recordTestResult,
        error: ((_a = data === null || data === void 0 ? void 0 : data.recordTestResult.error) === null || _a === void 0 ? void 0 : _a.code) || error,
        mutationStatus,
        prototype: data === null || data === void 0 ? void 0 : data.recordTestResult.prototype,
        responseUUID,
        partnerData: data === null || data === void 0 ? void 0 : data.recordTestResult.partnerResultMapper,
        locale: data === null || data === void 0 ? void 0 : data.recordTestResult.locale,
        roomName: data === null || data === void 0 ? void 0 : data.recordTestResult.roomName,
    };
}
