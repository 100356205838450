import { __rest } from "tslib";
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { shuffle } from 'lodash-es';
import { observer } from 'mobx-react';
import { useMemo } from 'react';
import * as React from 'react';
import { useIntl } from 'react-intl';
import { Text } from '@marvelapp/ballpark-components';
import { TaglineCopyStepUtils, TextUtils, } from '@marvelapp/user-test-creator';
import PreferenceGrid from '../../components/PreferenceGrid';
import { getHelpText } from '../../utils/choiceStepUtils';
import SlideRecord from '../common/SlideRecord';
import TaglineTestRecordGridItem from './TaglineTestRecordGridItem';
export default observer(function TaglineRecord(_a) {
    var { stepResponse, step } = _a, rest = __rest(_a, ["stepResponse", "step"]);
    const intl = useIntl();
    const multipleSelectionMode = TaglineCopyStepUtils.getMultipleSelectionMode(step);
    const selectionRange = TaglineCopyStepUtils.getSelectionRange(step);
    TaglineCopyStepUtils.getMultipleSelectionMode(step);
    const choices = useMemo(() => TaglineCopyStepUtils.getIsRandomizeSet(step)
        ? shuffle(step.choices)
        : step.choices, [step]);
    const helpText = getHelpText(intl, multipleSelectionMode, selectionRange);
    return (_jsxs(SlideRecord, Object.assign({ maxWidth: "100%", step: step }, rest, { children: [helpText && _jsx(Text, { children: helpText }), !!step.choices.length && (_jsx(PreferenceGrid, { columns: [
                    'repeat(1, minmax(0, 1fr))',
                    'repeat(1, minmax(0, 1fr))',
                    'repeat(2, minmax(0, 1fr))',
                    'repeat(2, minmax(0, 1fr))',
                    'repeat(3, minmax(0, 1fr))',
                ], children: choices
                    .filter((uuid) => {
                    const { heading, paragraph } = step.choiceDefinitions[uuid];
                    const isParagraphEmpty = TextUtils.checkIfValueIsEmpty(paragraph);
                    return !!heading || !isParagraphEmpty;
                })
                    .map((uuid, index) => {
                    return (_jsx(TaglineTestRecordGridItem, { "data-testid": `tagline-choice-${index}`, index: index, optionNumber: index, step: step, stepResponse: stepResponse, uuid: uuid }, uuid));
                }) }))] })));
});
