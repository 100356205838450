import { mapFilterNullish } from '@marvelapp/core';
import { validateDescendant } from '../validate';
import { toElement } from './migrate';
import { textProperties, } from './types';
export function sanitize(descendants) {
    // if the input is not an array of descendants then it's invalid
    // hopefully not possible but we should handle it anyway
    if (!Array.isArray(descendants))
        return {
            deletions: true,
            result: [{ type: 'p', children: [{ text: '' }] }],
            formatting: false,
        };
    let deletions = false;
    let formatting = false;
    const richText = mapFilterNullish(descendants.map(sanitizeFuzzyDescendant), ({ deletions: descendantDeleted, descendant, formatting: descendantFormatted, }) => {
        if (descendantDeleted)
            deletions = true;
        if (descendantFormatted)
            formatting = true;
        if (!descendant)
            return null;
        return toElement(descendant);
    });
    if (!richText.length) {
        return {
            deletions,
            result: [{ type: 'p', children: [{ text: '' }] }],
            formatting,
        };
    }
    return { deletions, result: richText, formatting };
}
const knownTypes = ['p', 'a', 'ul', 'ol', 'li', 'lic'];
function sanitizeFuzzyDescendant(descendant) {
    let formatting = false;
    if (!('type' in descendant)) {
        const newDescendant = Object.assign({}, descendant);
        if ('strong' in newDescendant) {
            newDescendant.bold = newDescendant.strong;
            delete newDescendant.strong;
        }
        if ('emphasis' in newDescendant) {
            newDescendant.italic = newDescendant.emphasis;
            delete newDescendant.emphasis;
        }
        if ('delete' in newDescendant) {
            newDescendant.strikethrough = newDescendant.delete;
            delete newDescendant.delete;
        }
        // delete any unrecognised properties
        Object.keys(newDescendant)
            .filter((key) => !textProperties.includes(key))
            .forEach((key) => {
            formatting = true;
            delete newDescendant[key];
        });
        if (validateDescendant(newDescendant)) {
            return { deletions: false, descendant: newDescendant, formatting };
        }
        return { deletions: true, descendant: null, formatting };
    }
    if (isKnownType(descendant.type)) {
        return sanitizeElement(descendant);
    }
    if (!('children' in descendant)) {
        return { deletions: true, descendant: null, formatting };
    }
    const type = mapType(descendant.type);
    if (!type) {
        formatting = true;
    }
    const result = sanitizeElement(Object.assign(Object.assign({}, descendant), { type: type !== null && type !== void 0 ? type : 'p' }));
    return Object.assign(Object.assign({}, result), { formatting: result.formatting || formatting });
}
function sanitizeElement(element) {
    let deletions = false;
    let formatting = false;
    const sanitizedChildren = mapFilterNullish(element.children, (fuzzyDescendant) => {
        const { deletions: descendantDeleted, descendant: sanitizedDescendant, formatting: descendantFormatted, } = sanitizeFuzzyDescendant(fuzzyDescendant);
        if (descendantDeleted)
            deletions = true;
        if (descendantFormatted)
            formatting = true;
        return sanitizedDescendant;
    });
    // can't have an empty element
    if (sanitizedChildren.length === 0)
        return { deletions: true, descendant: null, formatting: false };
    const sanitizedElement = Object.assign(Object.assign({}, element), { children: sanitizedChildren });
    const valid = validateDescendant(sanitizedElement);
    if (valid) {
        return { deletions, descendant: sanitizedElement, formatting };
    }
    return { deletions: true, descendant: null, formatting: false };
}
function isKnownType(type) {
    return knownTypes.includes(type);
}
function mapType(type) {
    if (type === 'paragraph')
        return 'p';
    if (type === 'anchor')
        return 'a';
    if (type === 'link')
        return 'a';
    return null;
}
