import { __rest } from "tslib";
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { shuffle } from 'lodash-es';
import { action } from 'mobx';
import { observer } from 'mobx-react';
import { useEffect, useMemo, useRef, useState } from 'react';
import * as React from 'react';
import { useIntl } from 'react-intl';
import { useAction } from '@marvelapp/ballpark-application';
import { Text } from '@marvelapp/ballpark-components';
import styled from '@marvelapp/styled';
import { Grid } from '@marvelapp/ui';
import { MultipleChoiceResponseUtils, MultipleChoiceStepUtils, } from '@marvelapp/user-test-creator';
import { getHelpText } from '../../utils/choiceStepUtils';
import Checkbox from '../common/Checkbox';
import EditableCheckbox from '../common/EditableCheckbox';
import SlideRecord from '../common/SlideRecord';
function useOtherOptionResponse(step, stepResponse, multipleSelectionMode) {
    const [isOtherOptionSelected, setIsOtherOptionSelected] = useState(!!stepResponse.answer.otherValue);
    const [otherValue, setOtherValue] = useState(stepResponse.answer.otherValue || '');
    const otherOptionUUID = MultipleChoiceStepUtils.getOtherOptionUUID(step);
    const setOtherOptionValue = useAction(() => {
        if (otherOptionUUID) {
            MultipleChoiceResponseUtils.addOtherChoice(stepResponse, otherOptionUUID, otherValue, multipleSelectionMode);
        }
    }, [multipleSelectionMode, otherOptionUUID, otherValue, stepResponse]);
    const removeOtherOptionValue = useAction(() => {
        if (otherOptionUUID) {
            MultipleChoiceResponseUtils.removeOtherChoice(stepResponse, otherOptionUUID);
        }
    }, [otherOptionUUID, stepResponse]);
    useEffect(() => {
        if (isOtherOptionSelected && otherValue) {
            setOtherOptionValue();
        }
        else {
            removeOtherOptionValue();
        }
    }, [
        isOtherOptionSelected,
        otherValue,
        otherOptionUUID,
        setOtherOptionValue,
        removeOtherOptionValue,
    ]);
    return {
        isOtherOptionSelected,
        setOtherValue,
        setIsOtherOptionSelected,
        otherOptionUUID,
        otherValue,
    };
}
export default observer(function MultipleChoiceRecord(_a) {
    var { stepResponse, step } = _a, rest = __rest(_a, ["stepResponse", "step"]);
    const intl = useIntl();
    const otherTextRef = useRef(null);
    const otherPlaceholderText = intl.formatMessage({ id: "+7XLkf", defaultMessage: "Other (type your own answer)" });
    const [placeholder, setPlaceholder] = useState(otherPlaceholderText);
    // If the user hasn't supplied any options in the builder,
    // we don't want to disable the button even if isRequired is true.
    // This will ensure that the participant doesn't get stuck when taking the test
    const hasAtLeastOneChoice = MultipleChoiceStepUtils.hasAChoiceOption(step);
    const multipleSelectionMode = MultipleChoiceStepUtils.getMultipleSelectionMode(step);
    const selectionRange = MultipleChoiceStepUtils.getSelectionRange(step);
    const [_, upperRangeLimit] = selectionRange;
    const { isOtherOptionSelected, otherOptionUUID, otherValue, setOtherValue, setIsOtherOptionSelected, } = useOtherOptionResponse(step, stepResponse, multipleSelectionMode);
    // prevent choices from jumping around when user makes selection
    const choices = useMemo(() => MultipleChoiceStepUtils.getIsRandomizeSet(step)
        ? shuffle(step.choices)
        : step.choices, [step]);
    const numberOfSelectedAnswers = MultipleChoiceResponseUtils.getAnswer(stepResponse).length;
    const helpText = getHelpText(intl, multipleSelectionMode, selectionRange);
    const hasSelectedMaxOptions = !!(multipleSelectionMode &&
        upperRangeLimit &&
        numberOfSelectedAnswers >= upperRangeLimit);
    const clearChoices = useAction(() => MultipleChoiceResponseUtils.clearChoices(stepResponse), [stepResponse]);
    return (_jsx(SlideRecord, Object.assign({ step: step }, rest, { children: hasAtLeastOneChoice && (_jsxs(SelectionContainer, { children: [helpText && _jsx(Text, { "data-testid": "help-text", children: helpText }), MultipleChoiceStepUtils.mapChoices(step, (choiceValue, choiceUUID, index) => {
                    if (choiceUUID === otherOptionUUID) {
                        return null;
                    }
                    const isChecked = MultipleChoiceResponseUtils.getAnswer(stepResponse).includes(choiceUUID);
                    return (step.choiceDefinitions[choiceUUID] && (_jsx(Checkbox, { readOnly: hasSelectedMaxOptions && !isChecked, checked: isChecked, id: choiceUUID, name: choiceValue, onChange: action(() => {
                            MultipleChoiceResponseUtils.updateChoices(stepResponse, choiceUUID, isChecked, multipleSelectionMode);
                            if (isOtherOptionSelected && !multipleSelectionMode)
                                // if single selection is enabled,
                                // remove selection of Other option if already selected
                                setIsOtherOptionSelected(false);
                        }), optionNumber: index }, choiceUUID)));
                }, choices), otherOptionUUID && (_jsx(EditableCheckbox
                // disable this option once max options have been selected
                , { 
                    // disable this option once max options have been selected
                    isDisabled: hasSelectedMaxOptions && !isOtherOptionSelected, isSelected: isOtherOptionSelected, placeholder: placeholder, value: otherValue, onChange: (e) => setOtherValue(e.target.value), onCheckboxClick: () => {
                        // focus the text area when selecting 'other' option
                        // to make it clear to the user that this is an option
                        // they can edit
                        if (!isOtherOptionSelected && otherTextRef.current) {
                            otherTextRef.current.focus();
                        }
                        setIsOtherOptionSelected(!isOtherOptionSelected);
                        if (!multipleSelectionMode)
                            clearChoices();
                    }, onFocus: () => {
                        setIsOtherOptionSelected(true);
                        if (!multipleSelectionMode)
                            clearChoices();
                        if (!otherValue)
                            setPlaceholder(intl.formatMessage({ id: "g6Z+pr", defaultMessage: "Please Enter Text" }));
                    }, onBlur: () => {
                        if (!otherValue) {
                            setIsOtherOptionSelected(false);
                            setPlaceholder(otherPlaceholderText);
                        }
                    }, ref: otherTextRef }))] })) })));
});
const SelectionContainer = styled(Grid).attrs({
    mt: 's',
    mb: 'xxs',
    gridGap: 's',
    'data-testid': 'selection-container',
}) `
  width: 100%;
  max-width: 400px;
`;
