import { jsx as _jsx } from "react/jsx-runtime";
import { motion, useReducedMotion } from 'framer-motion';
import { observer } from 'mobx-react';
import * as React from 'react';
import { useInView } from 'react-intersection-observer';
import theme from '@marvelapp/ballpark-theme';
import styled from '@marvelapp/styled';
import { Box, boxStyles } from '@marvelapp/ui';
const sizes = {
    s: 20,
    m: 30,
};
export default observer(function Bar({ size = 's', width, }) {
    const shouldReduceMotion = useReducedMotion();
    const { ref, inView } = useInView({
        triggerOnce: true,
    });
    const animation = shouldReduceMotion
        ? {}
        : {
            animate: inView
                ? {
                    x: 0,
                }
                : {
                    x: '-100%',
                },
            exit: {
                x: '-100%',
            },
            initial: {
                x: '-100%',
            },
            transition: {
                ease: theme.easings.smooth.array,
                duration: 0.8,
                delay: 0.35,
            },
        };
    return (_jsx(BarBg, { ref: ref, height: sizes[size], children: _jsx(BarFg, Object.assign({ height: sizes[size], width: width }, animation)) }));
});
const BarBg = styled(Box).attrs({
    borderRadius: 's',
    bg: 'slate3',
}) `
  position: relative;
  overflow: hidden;
`;
const BarFg = styled(motion.div).attrs({
    borderRadius: 's',
    bg: 'primary',
}) `
  ${boxStyles};
  position: absolute;
  top: 0;
  left: 0;
`;
