import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { observer } from 'mobx-react';
import * as React from 'react';
import styled from '@marvelapp/styled';
import { Box, PinAll } from '@marvelapp/ui';
import papers from '../../assets/images/papers.png';
import papers2x from '../../assets/images/papers@2x.png';
import AddImageCTA from '../../components/AddImageCTA';
import Image from '../../components/Image';
import { MAX_DECORATIVE_IMAGE_DIMENSIONS_DESKTOP } from '../../constants/image';
import ContainedImage from '../Instruction/ContainedImage';
export default observer(function ImageUpload({ onImageUploaded, customiseUppy, onDelete, testId, url, }) {
    return (_jsxs(ImageBox, { children: [url && (_jsx(Box, { padding: "xl", width: "100%", height: "100%", children: _jsx(ContainedImage, { image: url, onImageClick: onDelete, maxWidth: MAX_DECORATIVE_IMAGE_DIMENSIONS_DESKTOP.width, maxHeight: MAX_DECORATIVE_IMAGE_DIMENSIONS_DESKTOP.height }) })), !url && (_jsx(Box, { position: "relative", width: "100%", height: "100%", children: _jsx(PinAll, { padding: "xl", children: _jsx(AddImageCTA, { heading: "Click to add image or drop one in", subheading: "PNG, JPG, GIF or WebP up to 6MB.", decoration: _jsx(Image, { alt: "Upload image", src: papers, src2x: papers2x, 
                            // Using same images for dark mode as some users (me included) can't get
                            // rid of the setting in local storage (delete it and it comes back) but
                            // we no longer have a dark mode. Things look very odd if bits and pieces
                            // of the UI adapt to dark mode but not the whole thing.
                            src2xDark: papers2x, srcDark: papers }), testId: `upload--${testId}-image-artwork`, onImageUploaded: onImageUploaded, customiseUppy: customiseUppy }) }) }))] }));
});
const ImageBox = styled(Box) `
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
`;
