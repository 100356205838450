import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import { useResponsiveValue } from '@theme-ui/match-media';
import { observer } from 'mobx-react';
import { Button, CircleButton, Icon, Tooltip, } from '@marvelapp/ballpark-components';
import { PAUSE_ICON, PLAY_ICON } from '@marvelapp/ballpark-theme';
import { formatTime } from '@marvelapp/core';
export const PlaybackButton = observer(function PlaybackButton({ currentTime, duration, isVideo, onClick, testId, }) {
    return (_jsxs(Tooltip.Root, { children: [_jsx(Tooltip.Trigger, { children: _jsxs(Button, { "data-testid": testId, size: "xs", kind: isVideo ? 'black' : 'white', onClick: onClick, children: [formatTime(currentTime), " / ", formatTime(duration)] }) }), _jsxs(Tooltip.Content, { side: "bottom", children: [_jsx(Tooltip.Arrow, { offset: 8 }), "Reset playback"] })] }));
});
export const PlayButton = observer(function PlayButton({ isOpen, isPlaying, isVideo, onPause, onPlay, }) {
    const buttonSize = useResponsiveValue(['m', 'l', 'l', 'l']);
    const buttonProps = {
        size: isOpen ? 'l' : buttonSize,
        kind: isVideo ? 'black' : 'white',
    };
    if (isPlaying) {
        return (_jsx(CircleButton, Object.assign({ onClick: onPause }, buttonProps, { children: _jsx(Icon, { paths: PAUSE_ICON, "data-testid": "pause-video" }) })));
    }
    return (_jsx(CircleButton, Object.assign({ onClick: onPlay }, buttonProps, { children: _jsx(Icon, { paths: PLAY_ICON, "data-testid": "play-video", marginLeft: "2px" }) })));
});
