import { jsx as _jsx } from "react/jsx-runtime";
import { motion, useReducedMotion } from 'framer-motion';
import { observer } from 'mobx-react';
import * as React from 'react';
import theme from '@marvelapp/ballpark-theme';
export const scaleUpAnimation = {
    animate: {
        opacity: 1,
        scale: 1,
    },
    exit: {
        opacity: 0,
        scale: 0,
    },
    initial: {
        opacity: 0,
        scale: 0,
    },
    transition: {
        ease: theme.easings.smooth.array,
        duration: 0.35,
    },
};
export const ScaleUp = observer(function ScaleUp({ children, testId, }) {
    const shouldReduceMotion = useReducedMotion();
    const animation = !shouldReduceMotion ? scaleUpAnimation : {};
    return (_jsx(motion.div, Object.assign({ "data-testid": testId }, animation, { children: children })));
});
