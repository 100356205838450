import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { observer } from 'mobx-react';
import * as React from 'react';
import { Dropdown, Icon, SquareButton } from '@marvelapp/ballpark-components';
import { DOTS_ICON } from '@marvelapp/ballpark-theme';
import styled from '@marvelapp/styled';
import { Box } from '@marvelapp/ui';
export const CardMenu = observer(function CardMenu({ isActive, onClone, onDelete, onOpenChange, }) {
    return (_jsxs(Dropdown.Root, { onOpenChange: onOpenChange, children: [_jsx(Dropdown.Trigger, { "data-testid": "builder-step-dot-menu", children: _jsx(SquareButton, { as: "div", isActive: isActive, kind: "ghost", translateOnHover: false, size: "xs", children: _jsx(Icon, { paths: DOTS_ICON }) }) }), _jsx(Dropdown.Content, { align: "end", children: _jsxs(DropdownInner, { children: [_jsx(Dropdown.Item, { "data-testid": "builder-clone-task", onClick: onClone, children: "Duplicate" }), _jsx(Dropdown.Item, { "data-testid": "builder-delete-task", hoverColor: "tomato10", onClick: onDelete, children: "Delete" })] }) })] }));
});
const DropdownInner = styled(Box).attrs({
    paddingY: 'xs',
}) `
  min-width: 120px;
`;
