import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { observer } from 'mobx-react';
import * as React from 'react';
import { useGetUserTest, useSlideUIContext, } from '@marvelapp/ballpark-application';
import { AspectRatio } from '@marvelapp/ui';
import { FixedStep, PrototypeStepType, QuestionStepType, ScreenerUtils, StepClass, StepUtils, } from '@marvelapp/user-test-creator';
import { CardSortingBuilder } from '../CardSorting/builder/CardSortingBuilder';
import DateEdit from '../Date/DateEdit';
import EmailEdit from '../Email/EmailEdit';
import ExitEdit from '../Exit/ExitEdit';
import FirstClickEdit from '../FirstClick/FirstClickEdit';
import FiveSecondEdit from '../FiveSecond/FiveSecondEdit';
import InstructionEdit from '../Instruction/InstructionEdit';
import LegalEdit from '../Legal/LegalEdit';
import MediaSettingsEdit from '../MediaSettings/MediaSettingsEdit';
import MultipleChoiceEdit from '../MultipleChoice/MultipleChoiceEdit';
import NumericalEdit from '../Numerical/NumericalEdit';
import PreferenceTest from '../PreferenceTest/PreferenceTestEdit';
import { PrototypeTaskEdit } from '../PrototypeTask/PrototypeTaskEdit/PrototypeTaskEdit';
import QuestionEdit from '../Question/QuestionEdit';
import { RateEdit } from '../Rate/RateEdit';
import ScreenerSlide from '../Screeners';
import TaglineCopyTestEdit from '../TaglineCopyTest/TaglineCopyTestEdit';
import WebsiteTaskEdit from '../WebsiteTask/WebsiteTaskEdit';
import WelcomeEdit from '../Welcome/WelcomeEdit';
import YesNoEdit from '../YesNo/YesNoEdit';
import { SlideControls } from './SlideControls';
export const SlideEdit = observer(({ onMainCTAClick, getStepName, getStepIcon }) => {
    return (_jsxs(_Fragment, { children: [_jsx(SlideControls, { getStepName: getStepName, getStepIcon: getStepIcon }), _jsx(AspectRatio, { ratio: 16 / 9, width: "100%", children: _jsx(SlideRenderer, { onMainCTAClick: onMainCTAClick }) })] }));
});
export const SlideRenderer = observer(function SlideRenderer({ onMainCTAClick }) {
    const { fixedStepType, step } = useSlideUIContext();
    const userTest = useGetUserTest();
    if (fixedStepType) {
        switch (fixedStepType) {
            case FixedStep.Welcome:
                return _jsx(WelcomeEdit, { onMainCTAClick: onMainCTAClick });
            case FixedStep.Exit:
                return _jsx(ExitEdit, {});
            default:
                throw Error(`step ${fixedStepType} not recognised`);
        }
    }
    if (ScreenerUtils.isScreenerStep(userTest, step.uuid)) {
        return (_jsx(ScreenerSlide, { onMainCTAClick: onMainCTAClick, stepUUID: step.uuid }));
    }
    switch (step.className) {
        case StepClass.Instruction:
            return _jsx(InstructionEdit, { onMainCTAClick: onMainCTAClick });
        case StepClass.PreferenceTest:
            return _jsx(PreferenceTest, { onMainCTAClick: onMainCTAClick });
        case StepClass.MultipleChoice:
            return _jsx(MultipleChoiceEdit, { onMainCTAClick: onMainCTAClick });
        case StepClass.TaglineCopyTest:
            return _jsx(TaglineCopyTestEdit, { onMainCTAClick: onMainCTAClick });
        case StepClass.PrototypeTask:
            if (StepUtils.isOfPrototypeSubType(step, PrototypeStepType.FirstClick)) {
                return _jsx(FirstClickEdit, { onMainCTAClick: onMainCTAClick });
            }
            return _jsx(PrototypeTaskEdit, { onMainCTAClick: onMainCTAClick });
        case StepClass.FiveSecondTest:
            return _jsx(FiveSecondEdit, { onMainCTAClick: onMainCTAClick });
        case StepClass.RatingScale:
            return _jsx(RateEdit, { onMainCTAClick: onMainCTAClick });
        case StepClass.Question:
            if ('type' in step) {
                if (step.type === QuestionStepType.Email) {
                    return _jsx(EmailEdit, { onMainCTAClick: onMainCTAClick });
                }
                if (step.type === QuestionStepType.QuestionDate) {
                    return _jsx(DateEdit, { onMainCTAClick: onMainCTAClick });
                }
                if (step.type === QuestionStepType.Numerical) {
                    return _jsx(NumericalEdit, { onMainCTAClick: onMainCTAClick });
                }
            }
            return _jsx(QuestionEdit, { onMainCTAClick: onMainCTAClick });
        case StepClass.YesOrNo:
            if (StepUtils.isLegalStep(step)) {
                return _jsx(LegalEdit, { onMainCTAClick: onMainCTAClick });
            }
            return _jsx(YesNoEdit, { onMainCTAClick: onMainCTAClick });
        case StepClass.MediaSettings:
            return _jsx(MediaSettingsEdit, { onMainCTAClick: onMainCTAClick });
        case StepClass.WebsiteTask: {
            return _jsx(WebsiteTaskEdit, {});
        }
        case StepClass.CardSortingClosed:
        case StepClass.CardSortingOpen:
        case StepClass.CardSortingHybrid: {
            return _jsx(CardSortingBuilder, {});
        }
        default:
            throw Error(`step not recognised`);
    }
});
SlideEdit.displayName = 'SlideEdit';
