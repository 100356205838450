import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { observer } from 'mobx-react';
import * as React from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { BtwButton, BtwModal } from '@marvelapp/ballpark-components';
import { TrashCanIcon } from '@marvelapp/ballpark-icons';
import { ConfirmDelete } from '../NewResponses/Toolbar/ConfirmDelete';
import { useDeleteResults } from '../NewResponses/Toolbar/DeleteResultsAction';
export const DeleteResult = observer(function DeleteResult() {
    const match = useRouteMatch();
    const { testId, responseId } = match.params;
    const history = useHistory();
    const onDelete = () => history.replace(`/project/${testId}/responses`);
    const { deleteResults, loading: isDeletingResult } = useDeleteResults({
        onSuccess: onDelete,
        selectedResultsPks: [Number(responseId)],
        testId,
    });
    return (_jsxs(BtwModal.Root, { children: [_jsx(BtwModal.Trigger, { asChild: true, children: _jsx(BtwButton, { "data-testid": "delete-result-button", isLoading: isDeletingResult, leadingIcon: _jsx(TrashCanIcon, {}), size: "sm", children: "Delete" }) }), _jsx(BtwModal.Content, { children: _jsx(ConfirmDelete, { onDelete: deleteResults, selectedResultsCount: 1 }) })] }));
});
