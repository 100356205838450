import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { observer } from 'mobx-react';
import * as React from 'react';
import { useFeatureFlag } from '@marvelapp/ballpark-application';
import { Stepper } from '@marvelapp/ballpark-components';
import theme from '@marvelapp/ballpark-theme';
import { useKey } from '@marvelapp/hooks';
import styled from '@marvelapp/styled';
import { Box, Loader, PinCenter, PinTL } from '@marvelapp/ui';
import ArrowButton from './ArrowButton';
import ZoomModal from './ZoomModal';
export default observer(function ZoomImageModal({ image, image2x, name, isOpen, onClose, onNextImage, onPrevImage, fitImage, }) {
    const hasStaticUi = useFeatureFlag('marvel-3-static-ui');
    useKey(onPrevImage, { key: 'ArrowLeft' });
    useKey(onNextImage, { key: 'ArrowRight' });
    return (_jsx(_Fragment, { children: _jsxs(ZoomModal, { closeButtonTestID: "zoom-close-button", isOpen: isOpen, onClose: onClose, header: _jsxs(_Fragment, { children: [name && _jsx(Name, { children: name }), onPrevImage && (_jsx(ArrowButtonHolder, { left: "7px", children: _jsx(ArrowButton, { id: "previous-image", onClick: onPrevImage, direction: "left", size: "m" }) })), onNextImage && (_jsx(ArrowButtonHolder, { right: "7px", children: _jsx(ArrowButton, { id: "next-image", onClick: onNextImage, direction: "right", size: "m" }) }))] }), children: [_jsxs(Container, { children: [_jsx(PinCenter, { children: _jsx(Loader, {}) }), image && (_jsx(Image, { "data-testid": "expanded-modal-image", src: image, srcSet: image2x ? `${image2x} 2x` : undefined, style: fitImage
                                ? {
                                    width: '100%',
                                    height: '100%',
                                }
                                : {} }, `${image}-${image2x}`))] }), hasStaticUi && (_jsx(PinTL, { padding: "l", children: _jsx(Stepper, { onDecrement: () => null, onIncrement: () => null, size: "m", stepperTestId: "image-modal-zoom-level", children: _jsx(Box, { paddingX: "s", children: "100%" }) }) }))] }) }));
});
const Name = styled(Box).attrs({
    bg: 'slate11',
    color: 'white',
    marginTop: 'l',
}) `
  border-radius: 20px;
  padding: 8px 20px;
  position: fixed;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  z-index: ${theme.zIndices.modal + 1};
`;
const Container = styled(Box).attrs({
    bg: 'slate3',
    boxShadow: 'button.secondary',
    marginTop: 'l',
}) `
  position: relative;
`;
const ArrowButtonHolder = styled(Box) `
  margin-top: -15px;
  position: fixed;
  top: 50%;
  transform: translateY(-50%);
  z-index: ${theme.zIndices.modal + 1};
`;
const Image = styled.img `
  position: relative;
  display: block;
`;
