import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { round } from 'lodash-es';
import { observer } from 'mobx-react';
import { BtwText, Stack } from '@marvelapp/ballpark-components';
import { CursorClickIcon } from '@marvelapp/ballpark-icons';
export const MisclickRateCell = observer(function MisclickRateCell({ value, }) {
    const getRangeColor = () => {
        if (value >= 30) {
            return 'red';
        }
        if (value >= 10) {
            return 'yellow';
        }
        return 'green';
    };
    return (_jsx(BtwText, { variant: getRangeColor(), weight: "medium", children: _jsxs(Stack, { direction: "row", align: "center", gap: "2", children: [_jsx(CursorClickIcon, { className: "size-4" }), " ", `${round(value, 1)}%`] }) }));
});
