const LOCAL_STORAGE_KEY = 'marvel3';
const canUseDOM = !!(typeof window !== 'undefined' &&
    window.document &&
    window.document.createElement);
export function reset(storageKey = LOCAL_STORAGE_KEY) {
    if (canUseDOM) {
        window.localStorage.removeItem(storageKey);
    }
}
function readCache(storageKey) {
    if (canUseDOM) {
        try {
            const ls = window.localStorage;
            const rawCache = ls.getItem(storageKey) || '{}';
            return JSON.parse(rawCache);
        }
        catch (e) {
            // This means the JSON is corrupt, let's clear it and start again
            reset();
            return {};
        }
    }
}
export function get(key, storageKey = LOCAL_STORAGE_KEY) {
    if (canUseDOM) {
        return readCache(storageKey)[key];
    }
}
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function set(key, value, storageKey = LOCAL_STORAGE_KEY) {
    if (canUseDOM) {
        setCache(Object.assign(Object.assign({}, readCache(storageKey)), { [key]: value }), storageKey);
    }
}
export function remove(key, storageKey = LOCAL_STORAGE_KEY) {
    if (canUseDOM) {
        const currentCache = readCache(storageKey);
        // delete specific property off the marvel3 object in cache
        delete currentCache[key];
        setCache(currentCache, storageKey);
    }
}
function setCache(value, storageKey) {
    window.localStorage.setItem(storageKey, JSON.stringify(value));
}
export function getMatchingValueFromCache(cacheKey, options) {
    if (!cacheKey)
        return null;
    const keyFromCache = get(cacheKey);
    return options.find(({ key }) => key === keyFromCache) ? keyFromCache : null;
}
