import { StepClass } from '../common';
import { generateUUID } from '../utils/generateUUID';
import { STEP_PREFIXES } from './constants';
export function create(partial = {}) {
    return Object.assign({ uuid: generateUUID(STEP_PREFIXES.FiveSecondTest), className: StepClass.FiveSecondTest, title: null, description: [], timer: 5, revision: 0, isRequired: false }, partial);
}
export function setImageUrl(mutable, url) {
    mutable.imageUrl = url;
}
export function getImageUrl(step) {
    return step.imageUrl;
}
export function getTimer(step) {
    return step.timer;
}
export function setTimer(mutable, timer) {
    mutable.timer = timer;
}
export function removeImage(mutable) {
    delete mutable.imageUrl;
}
