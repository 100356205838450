import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { observer } from 'mobx-react';
import * as React from 'react';
import { SquareButton, Tooltip } from '@marvelapp/ballpark-components';
import { DOWNLOAD_ICON } from '@marvelapp/ballpark-theme';
import styled from '@marvelapp/styled';
import { Icon, Text } from '@marvelapp/ui';
export const DownloadHeatmap = observer(function DownloadHeatmap({ screenDisplayName, heatmapDataUri, }) {
    const downloadHeatmap = (e) => {
        e.stopPropagation();
        const filename = screenDisplayName
            ? screenDisplayName.replace(/[^a-z0-9]/gi, '_').toLowerCase()
            : 'heatmap';
        const a = document.createElement('a');
        a.href = heatmapDataUri;
        a.download = `${filename}.png`;
        a.click();
    };
    return (_jsx(Tooltip.Quick, { content: "Download Heatmap", children: _jsxs(IconButton, { "data-testid": "download-heatmap", isDisabled: !heatmapDataUri, kind: "ghost", onClick: downloadHeatmap, type: "button", isSquare: false, children: [_jsx(Icon, { display: "block", height: 8, paths: DOWNLOAD_ICON, width: 8 }), _jsx(Text, { marginLeft: 2, fontSize: 2, children: "Download" })] }) }));
});
const IconButton = styled(SquareButton) `
  padding-left: 10px;
  padding-right: 10px;
`;
