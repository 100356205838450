import { createProxiedDoc } from '@ballpark/realtime-crdt';
import { cloneDeep } from 'lodash-es';
import uuidv4 from 'uuid/v4';
import { USERTEST_DOC_KEY } from '../constants';
import { STEPS_COPY } from '../locale/steps';
import { stringToRichText } from '../text/utils';
import { hasMultiConditions } from '../utils/conditionalLogicUtils/hasMultiConditions';
import { deleteRule } from '../utils/conditionalLogicUtils/multiConditionStep/multiConditionStep';
import { generateUUID } from '../utils/generateUUID';
import { STEP_PREFIXES } from './constants';
import { create as createInstructionStep } from './instructionStep';
import { create as createMediaSettingsStep } from './mediaSettingsStep';
import { getAnyMediaEnabled, getOrderedListOfAllSteps, getRecordingPermissions, } from './selectors';
import * as StepUtils from './stepUtils';
import { StepClass, } from './types';
export function createUserTestDoc(data, options) {
    const userTest = createProxiedDoc(data, USERTEST_DOC_KEY, options);
    return userTest;
}
export function generateUserTestUUID() {
    return generateUUID('ut_');
}
export function stripUuidPrefix(uuid) {
    return uuid.replace(/^ut_/, '');
}
export const defaultState = {
    uuid: generateUserTestUUID(),
    schemaVersion: 0,
    revision: 0,
    steps: [],
    stepDefinitions: {},
    welcomeStep: createInstructionStep(),
    createdAt: Date.now(),
    exitStep: createInstructionStep({ hasButton: false }),
};
export function create(partial = {}) {
    return Object.assign({ uuid: generateUserTestUUID(), schemaVersion: 0, revision: 0, steps: [], stepDefinitions: {}, studies: {}, studyIDs: [], welcomeStep: createInstructionStep(), createdAt: Date.now(), exitStep: createInstructionStep({ hasButton: false }), screeners: null }, partial);
}
export function insertStep(mutable, step, activeStepUUID) {
    mutable.stepDefinitions[step.uuid] = step;
    const insertIndex = getInsertIndex(mutable, activeStepUUID);
    mutable.steps.splice(insertIndex, 0, step.uuid);
}
export function deleteStep(mutable, stepUUID) {
    const index = getStepIndex(mutable, stepUUID);
    mutable.steps.splice(index, 1);
    delete mutable.stepDefinitions[stepUUID];
    deleteAssociatedStepRules(mutable, stepUUID);
}
// ideally this would live in Conditional Logic Utils, but it causes a cyclical dependancy error
// as its only used in this file, its probably fine to live here
function deleteAssociatedStepRules(mutable, deletedStepUUID) {
    const steps = getOrderedListOfAllSteps({ userTest: mutable });
    steps.forEach((step) => {
        var _a, _b;
        if (StepUtils.isOfType(step, StepClass.MediaSettings) ||
            StepUtils.isLegalStep(step))
            return false;
        if (step.conditions) {
            if (step.conditions.ALWAYS === deletedStepUUID) {
                // eslint-disable-next-line no-param-reassign
                step.conditions.ALWAYS = null;
            }
            if (hasMultiConditions(step)) {
                step.conditions.rules.forEach(({ destinationStepUUID }, index) => {
                    if (destinationStepUUID === deletedStepUUID) {
                        deleteRule(step, index);
                    }
                });
            }
            if (StepUtils.isOfType(step, StepClass.YesOrNo)) {
                if (step.conditions.answer.true === deletedStepUUID) {
                    // eslint-disable-next-line no-param-reassign
                    step.conditions.answer.true = null;
                }
                if (step.conditions.answer.false === deletedStepUUID) {
                    // eslint-disable-next-line no-param-reassign
                    step.conditions.answer.false = null;
                }
            }
            if (StepUtils.isGoalBasedPrototype(step)) {
                if (((_a = step.conditions.hitGoal) === null || _a === void 0 ? void 0 : _a.true) === deletedStepUUID) {
                    // eslint-disable-next-line no-param-reassign
                    step.conditions.hitGoal.true = null;
                }
                if (((_b = step.conditions.hitGoal) === null || _b === void 0 ? void 0 : _b.false) === deletedStepUUID) {
                    // eslint-disable-next-line no-param-reassign
                    step.conditions.hitGoal.false = null;
                }
            }
        }
    });
}
function getInsertIndex(userTest, activeStepUUID) {
    if (activeStepUUID === null) {
        return userTest.steps.length;
    }
    if (activeStepUUID === userTest.welcomeStep.uuid) {
        return 0;
    }
    if (activeStepUUID === userTest.exitStep.uuid) {
        return userTest.steps.length;
    }
    return getStepIndex(userTest, activeStepUUID) + 1;
}
function getStepIndex(userTest, stepUUID) {
    return userTest.steps.findIndex((uuid) => uuid === stepUUID);
}
export function cloneAndAddStep(mutable, step) {
    const newStep = cloneStep(step, false);
    const insertionIndex = mutable.steps.findIndex((uuid) => uuid === step.uuid) + 1;
    mutable.steps.splice(insertionIndex, 0, newStep.uuid);
    mutable.stepDefinitions[newStep.uuid] = newStep;
}
export function reorderStep(userTest, startIndex, endIndex) {
    const [removed] = userTest.steps.splice(startIndex, 1);
    userTest.steps.splice(endIndex, 0, removed);
    return removed;
}
export function clone(userTest, newTestUUID = generateUserTestUUID()) {
    var _a, _b;
    const { createdAt } = userTest;
    const clonedTest = create({
        uuid: newTestUUID,
        schemaVersion: userTest.schemaVersion,
        createdAt,
    });
    // create a mapping of original step uuids to the cloned step uuids
    const stepUUIDMap = {};
    clonedTest.welcomeStep = cloneStep(userTest.welcomeStep);
    stepUUIDMap[userTest.welcomeStep.uuid] = clonedTest.welcomeStep.uuid;
    clonedTest.exitStep = cloneStep(userTest.exitStep);
    stepUUIDMap[userTest.exitStep.uuid] = clonedTest.exitStep.uuid;
    cloneScreenerSteps(userTest, clonedTest, stepUUIDMap);
    if (userTest.mediaSettingsStep) {
        clonedTest.mediaSettingsStep = cloneStep(userTest.mediaSettingsStep);
    }
    if (userTest.recordingPermissions) {
        clonedTest.recordingPermissions = userTest.recordingPermissions;
    }
    userTest.steps.forEach((uuid) => {
        const step = userTest.stepDefinitions[uuid];
        const clonedStep = cloneStep(step);
        stepUUIDMap[step.uuid] = clonedStep.uuid;
        clonedTest.stepDefinitions[clonedStep.uuid] = clonedStep;
        clonedTest.steps.push(clonedStep.uuid);
    });
    // clone logic
    [...((_b = (_a = clonedTest.screeners) === null || _a === void 0 ? void 0 : _a.steps) !== null && _b !== void 0 ? _b : []), ...clonedTest.steps].forEach((uuid) => {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j;
        const step = clonedTest.stepDefinitions[uuid];
        if ('conditions' in step) {
            if (StepUtils.isOfType(step, StepClass.YesOrNo)) {
                if ((_a = step.conditions) === null || _a === void 0 ? void 0 : _a.answer.true) {
                    step.conditions.answer.true =
                        stepUUIDMap[step.conditions.answer.true];
                }
                if ((_b = step.conditions) === null || _b === void 0 ? void 0 : _b.answer.false) {
                    step.conditions.answer.false =
                        stepUUIDMap[step.conditions.answer.false];
                }
            }
            if (StepUtils.isGoalBasedPrototype(step)) {
                if ((_d = (_c = step.conditions) === null || _c === void 0 ? void 0 : _c.hitGoal) === null || _d === void 0 ? void 0 : _d.true) {
                    step.conditions.hitGoal.true =
                        stepUUIDMap[step.conditions.hitGoal.true];
                }
                if ((_f = (_e = step.conditions) === null || _e === void 0 ? void 0 : _e.hitGoal) === null || _f === void 0 ? void 0 : _f.false) {
                    step.conditions.hitGoal.false =
                        stepUUIDMap[step.conditions.hitGoal.false];
                }
            }
            if (hasMultiConditions(step)) {
                if ((_g = step.conditions) === null || _g === void 0 ? void 0 : _g.rules.length) {
                    step.conditions.rules.forEach(({ destinationStepUUID }, index) => {
                        if (destinationStepUUID) {
                            step.conditions.rules[index].destinationStepUUID =
                                stepUUIDMap[destinationStepUUID];
                        }
                    });
                }
            }
            if ((_h = step.conditions) === null || _h === void 0 ? void 0 : _h.ALWAYS) {
                step.conditions.ALWAYS = stepUUIDMap[(_j = step.conditions) === null || _j === void 0 ? void 0 : _j.ALWAYS];
            }
        }
    });
    return clonedTest;
}
export function cloneStep(step, shouldCloneLogic = true) {
    const newUUID = generateUUID(STEP_PREFIXES[step.className]);
    if (StepUtils.isOfType(step, StepClass.MediaSettings) ||
        StepUtils.isLegalStep(step)) {
        const clonedStep = cloneDeep(step);
        clonedStep.uuid = newUUID;
        clonedStep.revision = 0;
        return clonedStep;
    }
    if (StepUtils.isOneOfType(step, [
        StepClass.Question,
        StepClass.Instruction,
        StepClass.WebsiteTask,
        StepClass.RatingScale,
        StepClass.FiveSecondTest,
        StepClass.PrototypeTask,
    ]) &&
        !StepUtils.isGoalBasedPrototype(step)) {
        const clonedStep = cloneDeep(step);
        clonedStep.uuid = newUUID;
        clonedStep.revision = 0;
        if (!shouldCloneLogic && (clonedStep === null || clonedStep === void 0 ? void 0 : clonedStep.conditions)) {
            clonedStep.conditions.ALWAYS = null;
            if ('rules' in clonedStep.conditions) {
                clonedStep.conditions.rules = [];
            }
        }
        return clonedStep;
    }
    if (StepUtils.isYesNoStep(step)) {
        const clonedStep = cloneDeep(step);
        clonedStep.uuid = newUUID;
        clonedStep.revision = 0;
        if (!shouldCloneLogic && clonedStep.conditions) {
            clonedStep.conditions.answer.true = null;
            clonedStep.conditions.answer.false = null;
            clonedStep.conditions.ALWAYS = null;
        }
        return clonedStep;
    }
    if (StepUtils.isGoalBasedPrototype(step)) {
        const clonedStep = cloneDeep(step);
        clonedStep.uuid = newUUID;
        clonedStep.revision = 0;
        if (!shouldCloneLogic && clonedStep.conditions) {
            if (clonedStep.conditions.hitGoal) {
                clonedStep.conditions.hitGoal.true = null;
                clonedStep.conditions.hitGoal.false = null;
            }
            clonedStep.conditions.ALWAYS = null;
        }
        return clonedStep;
    }
    if (StepUtils.isChoiceStep(step)) {
        const clonedStep = cloneChoiceStep(step, newUUID, shouldCloneLogic);
        return clonedStep;
    }
    if (StepUtils.isCardSortingStep(step)) {
        const clonedStep = cloneDeep(step);
        clonedStep.uuid = newUUID;
        clonedStep.revision = 0;
        const [cards, cardDefinitions] = generateNewEntityUUIDs(clonedStep.cards, clonedStep.cardDefinitions, 'card_');
        clonedStep.cards = cards;
        clonedStep.cardDefinitions = cardDefinitions;
        const [categories, categoryDefinitions] = generateNewEntityUUIDs(clonedStep.categories, clonedStep.categoryDefinitions, 'cat_');
        clonedStep.categories = categories;
        clonedStep.categoryDefinitions = categoryDefinitions;
        if (!shouldCloneLogic && (clonedStep === null || clonedStep === void 0 ? void 0 : clonedStep.conditions)) {
            clonedStep.conditions.ALWAYS = null;
            if ('rules' in clonedStep.conditions) {
                clonedStep.conditions.rules = [];
            }
        }
        return clonedStep;
    }
    return assertUnreachable(step);
}
function cloneChoiceStep(step, uuid, shouldCloneLogic) {
    const clonedStep = cloneDeep(step);
    clonedStep.uuid = uuid;
    clonedStep.revision = 0;
    const choicesMap = {};
    step.choices.forEach((choiceUUID, i) => {
        const choice = step.choiceDefinitions[choiceUUID];
        const newKey = getNewChoiceUUID(choiceUUID);
        choicesMap[choiceUUID] = newKey;
        clonedStep.choiceDefinitions[newKey] = choice;
        delete clonedStep.choiceDefinitions[choiceUUID];
        clonedStep.choices[i] = newKey;
    });
    if (!shouldCloneLogic && clonedStep.conditions) {
        // when we clone an individual step, we don't want to clone the conditional logic
        // this resets the logic on the step to be a blank slate
        clonedStep.conditions.rules = [];
        clonedStep.conditions.ALWAYS = null;
    }
    else if (clonedStep.conditions && clonedStep.conditions.rules.length) {
        // the cloned step's conditions will have the original step's choice uuid's set against the rules
        // here we update those rules to have the cloned step's choice uuids
        clonedStep.conditions.rules.forEach((rule) => rule.requirements.forEach((requirement) => {
            if (requirement.selectedChoiceUUID === null)
                return; // TODO make sure we're not copying partial rules
            // eslint-disable-next-line no-param-reassign
            requirement.selectedChoiceUUID =
                choicesMap[requirement.selectedChoiceUUID];
        }));
    }
    return clonedStep;
}
function assertUnreachable(_) {
    throw new Error("Didn't expect to get here");
}
function getNewChoiceUUID(uuid) {
    // 'Other' choice includes '-other' postfix, so when cloning
    // the choice, we need to make sure the new UUID also contains
    // this postfix
    return uuid.includes('-other') ? `${uuidv4()}-other` : uuidv4();
}
export function setMicrophoneRecordingPermission(mutable, microphone) {
    setRecordingPermission(mutable, 'microphone', microphone);
}
export function setWebcamRecordingPermission(mutable, webcam) {
    setRecordingPermission(mutable, 'webcam', webcam);
}
export function setScreenRecordingPermission(mutable, screen) {
    setRecordingPermission(mutable, 'screen', screen);
}
function setRecordingPermission(mutable, permission, enabled) {
    if (enabled) {
        unsetStepRecordingSettings(mutable);
    }
    mutable.recordingPermissions = Object.assign(Object.assign({}, getRecordingPermissions(mutable)), { [permission]: enabled });
    if (getAnyMediaEnabled(mutable)) {
        if (!mutable.mediaSettingsStep) {
            addMediaSettingsStep(mutable);
        }
    }
    else {
        removeMediaSettingsStep(mutable);
    }
}
function addMediaSettingsStep(mutable) {
    const step = createMediaSettingsStep({
        title: STEPS_COPY[StepClass.MediaSettings].defaultTitle.defaultMessage,
        isRequired: true,
        description: stringToRichText(STEPS_COPY[StepClass.MediaSettings].defaultDescription.defaultMessage),
    });
    mutable.mediaSettingsStep = step;
}
function removeMediaSettingsStep(mutable) {
    delete mutable.mediaSettingsStep;
}
// When whole test recording is enabled we disable recording on any prototype or
// website task so we don't generate multiple recordings
function unsetStepRecordingSettings(mutable) {
    getOrderedListOfAllSteps({ userTest: mutable }).forEach((step) => {
        if ('recordingPermissions' in step) {
            StepUtils.setMicrophoneRecordingPermission(step, false);
            StepUtils.setWebcamRecordingPermission(step, false);
            StepUtils.setScreenRecordingPermission(step, false);
        }
    });
}
function cloneScreenerSteps(originalTest, clonedTest, stepUUIDMap) {
    if (originalTest.screeners) {
        const introStep = cloneStep(originalTest.screeners.intro);
        const declineStep = cloneStep(originalTest.screeners.decline);
        const screenerStepsUUIDs = [];
        originalTest.screeners.steps.forEach((uuid) => {
            const step = originalTest.stepDefinitions[uuid];
            const clonedStep = cloneStep(step);
            // store old step uuids and new uuids in mapping
            stepUUIDMap[step.uuid] = clonedStep.uuid;
            clonedTest.stepDefinitions[clonedStep.uuid] = clonedStep;
            screenerStepsUUIDs.push(clonedStep.uuid);
        });
        clonedTest.screeners = {
            intro: introStep,
            steps: screenerStepsUUIDs,
            decline: declineStep,
        };
        stepUUIDMap[originalTest.screeners.intro.uuid] =
            clonedTest.screeners.intro.uuid;
        stepUUIDMap[originalTest.screeners.decline.uuid] =
            clonedTest.screeners.decline.uuid;
    }
}
export function generateNewEntityUUIDs(entityUUIDs, entityData, UUIDPrefix = '') {
    const updatedEntityUUIDs = [];
    const updatedEntityData = {};
    for (let i = 0; i < entityUUIDs.length; i++) {
        const UUID = entityUUIDs[i];
        const newUUID = generateUUID(UUIDPrefix);
        updatedEntityUUIDs.push(newUUID);
        updatedEntityData[newUUID] = entityData[UUID];
    }
    return [updatedEntityUUIDs, updatedEntityData];
}
