import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { observer } from 'mobx-react';
import { AuthedRoute, PublicRoute, useCurrentUser, usePermissions, } from '@marvelapp/ballpark-application';
import { PrivateRoute, SwitchWithNotFound, } from '@marvelapp/ballpark-components';
import { ANONYMOUS } from '@marvelapp/core';
import { MediaSyncProvider } from '@marvelapp/media-player';
import ProjectCloneBounce from './components/ProjectCloneBounce';
import { ContactCX } from './pages/ContactCX';
import { InviteToProjectAndWorkspace } from './pages/InviteToProjectAndWorkspace';
import { PreviewVerificationWrapper as Preview } from './pages/Preview';
import ProjectViews from './pages/ProjectViewsWithAwareness';
import { RecordFlow } from './pages/Record';
import { GetOrCreateLiveSession } from './pages/Record/GetOrCreateLiveSession';
import { JoinRoom } from './pages/Record/JoinRoom';
import { JoinRoomSplash } from './pages/Record/JoinRoomSplash';
import { RecordStudyBounce } from './pages/RecordStudyBounce';
import { Response } from './pages/Response';
import WebsiteRecord from './pages/WebsiteRecord';
export const App = observer(function App() {
    const user = useCurrentUser();
    const permissions = usePermissions();
    const canInvitePeopleToCompany = permissions.canInvitePeopleToCompany();
    const isUserAuthenticated = user.role !== ANONYMOUS;
    return (_jsx(MediaSyncProvider, { children: _jsxs(SwitchWithNotFound, { children: [_jsx(AuthedRoute, { component: Response, path: "/project/:testId/response/:responseId" }), _jsx(AuthedRoute, { component: ContactCX, path: "/contact-cx" }), _jsx(AuthedRoute, { component: ProjectCloneBounce, exact: true, path: "/project/clone-bounce/:testName/:testId", redirectToSignUp: true }), _jsx(PrivateRoute, { exact: true, hasPermission: canInvitePeopleToCompany, path: "/project/:pk/invite", component: InviteToProjectAndWorkspace }), _jsx(AuthedRoute, { path: "/project/:id", component: ProjectViews }), _jsx(PrivateRoute, { component: Preview, hasPermission: isUserAuthenticated, exact: true, path: "/preview/:testId" }), _jsx(PublicRoute, { component: RecordStudyBounce, exact: true, path: "/record/study/:studyId" }), _jsx(PublicRoute, { component: RecordFlow, exact: true, path: "/record/:testId" }), _jsx(PublicRoute, { component: WebsiteRecord, exact: true, path: "/website-record" }), _jsx(AuthedRoute, { component: JoinRoomSplash, exact: true, path: "/share/live/panelists/:testId/:roomId" }), _jsx(AuthedRoute, { component: JoinRoom, exact: true, path: "/share/live/:role(researcher|observer)/:shareId/:roomId" }), _jsx(PublicRoute, { component: GetOrCreateLiveSession, exact: true, path: "/share/live/participant/:shareId" }), _jsx(PublicRoute, { component: JoinRoom, exact: true, path: "/share/live/:role(participant)/:shareId/:roomId" })] }) }));
});
