import { jsx as _jsx } from "react/jsx-runtime";
import { observer } from 'mobx-react';
import { useFeatureFlag, useGetUserTest, } from '@marvelapp/ballpark-application';
import { Icon } from '@marvelapp/ballpark-components';
import { CAMERA_ICON } from '@marvelapp/ballpark-theme';
import styled from '@marvelapp/styled';
import { Box } from '@marvelapp/ui';
import { UserTestUtils, getRecordingPermissions, } from '@marvelapp/user-test-creator';
import { MediaSettings } from '../../components/MediaSettings';
import { RecordingIcon } from '../../icons';
const buttonProps = {
    width: '100%',
    size: 'm',
    bg: 'background',
    boxShadow: 'button.secondary',
    'data-testid': 'whole-test-recording-toggle',
    pl: '10px',
    pr: 0,
};
const RecordUserTestToggle = observer(({ width }) => {
    const userTest = useGetUserTest();
    const permissions = getRecordingPermissions(userTest);
    const canRecordWholeTest = useFeatureFlag('ballpark-whole-test-recording');
    if (!canRecordWholeTest)
        return null;
    const isRecording = permissions.webcam || permissions.microphone || permissions.screen;
    return (_jsx(Box, { width: width, children: _jsx(MediaSettings, Object.assign({}, permissions, { item: "test", icon: _jsx(IconWrapper, { children: isRecording ? (_jsx(RecordingIcon, { color: "red" })) : (_jsx(Icon, { paths: CAMERA_ICON, width: 14, height: 14 })) }), buttonText: _jsx(Box, { flex: 1, textAlign: "center", children: isRecording ? 'Recording' : 'Record' }), buttonProps: buttonProps, popoverPosition: "start", setWebcam: (enabled) => UserTestUtils.setWebcamRecordingPermission(userTest, enabled), setMicrophone: (enabled) => UserTestUtils.setMicrophoneRecordingPermission(userTest, enabled), setScreen: (enabled) => UserTestUtils.setScreenRecordingPermission(userTest, enabled) })) }));
});
const IconWrapper = styled(Box) `
  display: flex;
  align-items: center;
  svg {
    display: block;
  }
`;
export default RecordUserTestToggle;
