import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { observer } from 'mobx-react';
import * as React from 'react';
import { ConditionsIcon, Icon, IconWithTooltip, Text, } from '@marvelapp/ballpark-components';
import { DROP_OFF_ICON, EXIT_COUNT_ICON } from '@marvelapp/ballpark-theme';
import styled from '@marvelapp/styled';
import { Box } from '@marvelapp/ui';
import { ConditionalLogicUtils, getOrderedListOfAllSteps, } from '@marvelapp/user-test-creator';
import { EnterIcon } from '../../icons';
import { useStepContext } from '../../utils/stepContext';
export const SummaryDivider = observer(function SummaryDivider({ totalStarts, isExitStep, isScreenerStep, }) {
    const { summary: stepStats, userTest } = useStepContext();
    if (!stepStats) {
        return (_jsx(StepDivider, { "data-testid": "step-divider", children: _jsx(StatText, { "data-testid": "noResponses-stat", textAlign: "center", children: "This step was added after participants had already started to respond and has no responses yet" }) }));
    }
    const hasConditionalLogicSet = ConditionalLogicUtils.getHasConditionalLogicSet(getOrderedListOfAllSteps({ userTest }));
    return (_jsx(StepDivider, { "data-testid": "step-divider", children: getDividerCopy({
            stepStats,
            isExitStep,
            totalStarts,
            isScreenerStep,
            showLogicInfo: hasConditionalLogicSet,
        }) }));
});
function getDividerCopy({ isExitStep, isScreenerStep = false, stepStats, totalStarts, showLogicInfo, }) {
    if (isExitStep) {
        const percentageCompleted = stepStats.totalStepVisits
            ? (stepStats.totalStepVisits / totalStarts) * 100
            : 0;
        return (_jsx(StatItem, { copy: ` (${stepStats.totalStepVisits} of ${totalStarts} people who started)`, stat: `${percentageCompleted % 1 > 0
                ? percentageCompleted.toFixed(2)
                : percentageCompleted}% of participants completed the test`, testId: "exitStep-stats" }));
    }
    const { bounceCount, stepBounceRate, totalStepVisits } = stepStats;
    if (isScreenerStep &&
        stepStats.__typename === 'StepAggregateMultipleChoiceNode') {
        const { disqualifiedCount, disqualifiedPercentage } = stepStats;
        return (_jsxs(_Fragment, { children: [_jsx(StatItem, { copy: " entered the step", icon: _jsx(ConditionsIcon, { fill: "slate" }), stat: totalStepVisits, testId: "enter-stat" }), _jsx(StatItem, { copy: ' screened out', icon: _jsx(Icon, { paths: EXIT_COUNT_ICON, width: 13, height: 13, viewBox: "0 0 13 13" }), stat: disqualifiedCount, testId: "leave-stat" }), _jsx(StatItem, { copy: '% screened out rate', icon: _jsx(Icon, { paths: DROP_OFF_ICON, width: 9, height: 13, viewBox: "0 0 10 14" }), mr: 0, stat: disqualifiedPercentage, testId: "dropOff-stat" })] }));
    }
    return (_jsxs(_Fragment, { children: [showLogicInfo ? (_jsx(StatItem, { copy: " shown the step", icon: _jsx(IconWithTooltip, { content: "Steps in this project have conditional logic rules, which determine whether users see this step", children: _jsx(ConditionsIcon, { fill: "slate" }) }), stat: totalStepVisits, testId: "logic-stat" })) : (_jsx(StatItem, { copy: " entered the step", 
                // This is a more complex svg image, couldn't just
                // use a path saved in ballpark-theme
                icon: _jsx(EnterIcon, {}), stat: totalStepVisits, testId: "enter-stat" })), _jsx(StatItem, { copy: ' left the test at this step ', icon: _jsx(Icon, { paths: EXIT_COUNT_ICON, width: 13, height: 13, viewBox: "0 0 13 13" }), stat: bounceCount, testId: "leave-stat" }), _jsx(StatItem, { copy: '% drop off rate', icon: _jsx(Icon, { paths: DROP_OFF_ICON, width: 9, height: 13, viewBox: "0 0 10 14" }), mr: 0, stat: stepBounceRate, testId: "dropOff-stat" })] }));
}
const StatItem = observer(function StatItem({ stat, copy, icon, mr = 'l', testId, }) {
    return (_jsxs(Box, { "data-testid": testId, display: "flex", alignItems: "center", mr: mr, children: [icon, _jsxs(StatText, { ml: "xs", children: [_jsx(StatValue, { children: stat }), copy] })] }));
});
const StatText = styled(Text).attrs({
    lineHeight: 'base',
    color: 'slate10',
}) `
  font-size: 14px;
`;
const StatValue = styled.span `
  font-weight: 500;
`;
const StepDivider = styled(Box).attrs({
    bg: 'snowDark',
    borderBottomLeftRadius: 'xl',
    borderBottomRightRadius: 'xl',
    padding: 's',
    paddingTop: '21px',
    marginBottom: 'm',
}) `
  margin-top: -5px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  &:last-child {
    margin-bottom: 0;
  }
`;
