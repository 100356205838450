import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { observer } from 'mobx-react';
import { Button, Heading, Text } from '@marvelapp/ballpark-components';
import styled from '@marvelapp/styled';
import { Box } from '@marvelapp/ui';
export const ErrorLoadingDoc = observer(function ErrorLoadingDoc() {
    return (_jsx(ContentContainer, { "data-testid": "error-loading-doc", children: _jsxs(Box, { bg: "white", padding: "xl", maxWidth: 420, children: [_jsx(Heading, { children: "There has been an issue detected with your connection to the server" }), _jsx(Text, { paddingY: "m", children: "As a precaution, no further updates can be made until you reload the page and reconnect." }), _jsx(Button, { onClick: () => window.location.reload(), children: "Reload page" })] }) }));
});
const ContentContainer = styled.div `
  background-color: rgba(0, 0, 0, 0.5);
  background-opacity: 0.5;
  z-index: ${(props) => props.theme.zIndices.modal};
  box-shadow: 0 0 12px 4px rgba(0, 0, 0, 0.1);
  position: fixed !important;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow-y: scroll;
`;
