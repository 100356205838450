import { __rest } from "tslib";
import { jsx as _jsx } from "react/jsx-runtime";
import LogRocket from 'logrocket';
import { observer } from 'mobx-react';
import { useEffect } from 'react';
import * as React from 'react';
import { useIntl } from 'react-intl';
import { Button, Link } from '@marvelapp/ballpark-components';
import { UI_STEPS_COPY, formatStringOrMessage } from '@marvelapp/ballpark-copy';
import { FixedStep } from '@marvelapp/user-test-creator';
import { useWholeTestRecording } from '../../WholeTestRecordingContext';
import { useRecorderState } from '../../components/Record/RecorderContext';
import SlideRecord from '../common/SlideRecord';
export const ExitRecord = observer(function ExitRecord(_a) {
    var { step } = _a, rest = __rest(_a, ["step"]);
    const recording = useWholeTestRecording();
    const { websiteTaskWindow, isLive } = useRecorderState();
    const stopRecording = recording === null || recording === void 0 ? void 0 : recording.stopRecording;
    useEffect(() => {
        if (isLive)
            return; // Live sessions don't auto-stop recording when the user reaches the end
        setTimeout(() => stopRecording === null || stopRecording === void 0 ? void 0 : stopRecording(), 1000);
    }, [isLive, stopRecording]);
    useEffect(() => {
        websiteTaskWindow === null || websiteTaskWindow === void 0 ? void 0 : websiteTaskWindow.close();
    }, [websiteTaskWindow]);
    return (_jsx(SlideRecord, Object.assign({ step: step }, rest, { button: _jsx(ExitStepCTA, { step: step }) })));
});
const ExitStepCTA = observer(function ExitStepCTA({ step, }) {
    var _a;
    const intl = useIntl();
    const { response } = useRecorderState();
    const partnerRedirectUrl = (_a = response.partnerData) === null || _a === void 0 ? void 0 : _a.redirectUrl;
    if ((!step.hasButton || !step.redirectUrl) && !partnerRedirectUrl)
        return null;
    if (partnerRedirectUrl)
        LogRocket.track('partner-redirect');
    const redirectUrl = partnerRedirectUrl || step.redirectUrl;
    const buttonText = partnerRedirectUrl
        ? intl.formatMessage({ id: "bvmHT5", defaultMessage: "Click here to complete your test" })
        : step.buttonText || UI_STEPS_COPY[FixedStep.Exit].buttonText;
    return (_jsx(Button, { as: Link, size: "l", mt: "s", "data-testid": "submit-form", to: redirectUrl, 
        // I need to brute force this with style, because the kind="primary" styles from Link override
        // Button styles and none of the button color props can fight that.
        // I also don't know how to pass a prop (kind="white") to the Link tag as part of the "as" prop.
        // So, I'm left with the only option below 👇
        style: { color: '#fff' }, children: formatStringOrMessage(intl, buttonText) }));
});
