import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { observer } from 'mobx-react';
import { useEffect } from 'react';
import * as React from 'react';
import { Link } from '@marvelapp/ballpark-components';
import { useTextCollapse } from '@marvelapp/hooks';
import styled, { css } from '@marvelapp/styled';
import { Box } from '@marvelapp/ui';
export const ReadMoreWrapper = observer(function ReadMoreWrapper({ children, forceCollapse, onClick: parentOnClick, truncatedLinesCount = 2, }) {
    const { setTextRef, isTextCollapsed, overflowActive, expandText, collapseText, } = useTextCollapse();
    useEffect(() => {
        if (forceCollapse)
            collapseText();
    }, [collapseText, forceCollapse]);
    return (_jsxs(_Fragment, { children: [_jsx(TruncatedText, { ref: setTextRef, truncate: isTextCollapsed, truncatedLinesCount: truncatedLinesCount, children: children }), overflowActive && (_jsx(ReadMore, { children: _jsx(Link, { onClick: () => {
                        expandText();
                        if (parentOnClick)
                            parentOnClick();
                    }, underline: true, children: "Read more" }) }))] }));
});
const TruncatedText = styled(Box) `
  ${(props) => props.truncate &&
    css `
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: ${props.truncatedLinesCount};
      -webkit-box-orient: vertical;
    `};
`;
const ReadMore = styled(Box).attrs({
    marginTop: 'xs',
    marginBottom: 's',
    fontSize: 's',
}) ``;
