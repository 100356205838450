import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { range } from 'lodash-es';
import { useState } from 'react';
import { heapTrackEvent } from '@marvelapp/core';
import { Box, Button, Flex, Heading, Text } from '@marvelapp/ui';
import ModalLink from '../Modal/ModalLink';
import SimpleListItem from '../SimpleListItem';
import PeopleList from './PeopleList';
export default function SearchPeopleList({ renderPersonRowButtonComponent, hasNextPage, isLoading, isLoadingMore, inviteUrl, loadNextPage, onAdd, onRemove, ownerPk, people, personIsAdded, searchTerm, }) {
    const [scrollableContainerEl, setScrollableContainerEl] = useState(null);
    if (isLoading && !people) {
        return (_jsx(Box, { px: 3, children: range(3).map((dummyPerson) => (_jsx(SimpleListItem, { isLoading: true }, dummyPerson))) }));
    }
    const noSearchResults = searchTerm && people && people.length === 0;
    if (noSearchResults) {
        return _jsx(PeopleSearchEmptyState, { inviteUrl: inviteUrl });
    }
    return (_jsx(Box, { "data-testid": "search-people-list", maxHeight: 345, overflow: "auto", px: 3, ref: setScrollableContainerEl, children: _jsx(PeopleList, { hasNextPage: hasNextPage, isLoading: isLoading, isLoadingMore: isLoadingMore, loadNextPage: loadNextPage, onAdd: onAdd, onRemove: onRemove, ownerPk: ownerPk, people: people, personIsAdded: personIsAdded, renderPersonRowButtonComponent: renderPersonRowButtonComponent, scrollableContainerEl: scrollableContainerEl, searchTerm: searchTerm }) }));
}
function PeopleSearchEmptyState({ inviteUrl }) {
    return (_jsx(SearchEmptyState, { buttonText: "Invite people", description: "If you want to give access to a new member, you should invite them to the workspace first.", heading: "No people match your search term", heapId: "No collaborators found", inviteUrl: inviteUrl }));
}
function SearchEmptyState({ heading, heapId, description, buttonText, inviteUrl, }) {
    heapTrackEvent(heapId);
    return (_jsxs(Flex, { alignItems: "center", "data-testid": "search-list-empty", flexDirection: "column", justifyContent: "center", padding: 4, textAlign: "center", children: [_jsx(Heading, { fontSize: 3, fontWeight: 500, mb: 1, children: heading }), _jsx(Text, { fontSize: 2, maxWidth: 340, children: description }), inviteUrl && (_jsx(Button, { as: ModalLink, "data-testid": "invite-users-button", kind: "ghost", marginTop: 2, to: inviteUrl, children: buttonText }))] }));
}
