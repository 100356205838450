import { jsx as _jsx } from "react/jsx-runtime";
import { RoomContext } from '@livekit/components-react';
import { observer } from 'mobx-react';
import * as React from 'react';
import { createContext, useContext, useEffect, useMemo } from 'react';
import { getRecordingPermissions, getRecordingRequired, } from '@marvelapp/user-test-creator';
import { useRecorderState } from './components/Record/RecorderContext';
import useSetupParticipantRecording from './hooks/useSetupParticipantRecording';
import { removeScreenPermissionIfOnMobile } from './utils';
const WholeTestRecordingContext = createContext(null);
export const useWholeTestRecording = () => {
    const wholeTestRecording = useContext(WholeTestRecordingContext);
    if (!wholeTestRecording) {
        throw new Error('useWholeTestRecording must be used within WholeTestRecordingProvider');
    }
    return wholeTestRecording;
};
export const WholeTestRecordingProvider = observer(function WholeTestRecordingProvider({ children, }) {
    var _a;
    const recorderState = useRecorderState();
    const originalPermissions = getRecordingPermissions(recorderState.userTestDoc);
    const permissions = removeScreenPermissionIfOnMobile(originalPermissions);
    const somePermissionRequested = Object.values(permissions).some(Boolean);
    const isRequired = getRecordingRequired(recorderState.userTestDoc) &&
        somePermissionRequested;
    const recording = useSetupParticipantRecording({
        response: recorderState.response,
        permissions,
        isRecordingEnabled: somePermissionRequested,
        isRecordingRequired: isRequired,
        responseUUID: recorderState.response.uuid,
    });
    useEffect(() => {
        switch (recording.connectionStatus) {
            case 'connected':
            case 'connecting':
                recorderState.isRecordingMedia = true;
                break;
            case 'disconnected':
            case 'idle':
                recorderState.isRecordingMedia = false;
                break;
            default:
                throw new Error(`Unknown connection status: ${recording.connectionStatus}`);
        }
    }, [recording, recorderState]);
    // This covers the case where we're on mobile and only screen recording is
    // requested. In this case no permission will have been requested (as screen
    // recording is not supported) so when 'Go to next' step is clicked we need
    // to call the callback directly that's normally called when the recording
    // starts.
    const skipRecordingOnMobile = !somePermissionRequested
        ? recorderState.goForward
        : undefined;
    const value = useMemo(() => (Object.assign(Object.assign({}, recording), { isRequired,
        skipRecordingOnMobile })), [recording, isRequired, skipRecordingOnMobile]);
    return (_jsx(RoomContext.Provider, { value: (_a = recording.room) !== null && _a !== void 0 ? _a : undefined, children: _jsx(WholeTestRecordingContext.Provider, { value: value, children: children }) }));
});
