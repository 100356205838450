import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { observer } from 'mobx-react';
import * as React from 'react';
import { Icon, LegacySelect } from '@marvelapp/ballpark-components';
import { CAMERA_ICON, MICROPHONE_ICON } from '@marvelapp/ballpark-theme';
import { VolumeMeter } from '@marvelapp/media-recording';
import styled from '@marvelapp/styled';
import { Box, Flex } from '@marvelapp/ui';
export const SelectWebcamMicrophoneSettings = observer(function SelectWebcamMicrophoneSettings({ audioDevices, audioDeviceId, onAudioDeviceChange, videoDeviceId, onVideoDeviceChange, videoDevices, mediaStream, }) {
    return (_jsxs(Box, { "data-testid": "select-webcam-mic-settings", width: "100%", children: [!!videoDevices.length && (_jsxs(Flex, { children: [_jsx(IconWrapper, { children: _jsx(Icon, { paths: CAMERA_ICON, width: 12, height: 12 }) }), _jsx(Box, { flex: 1, children: _jsx(LegacySelect, { onChange: onVideoDeviceChange, size: "m", width: 1, value: videoDeviceId, children: videoDevices.map(({ deviceId, label }) => (_jsx("option", { value: deviceId, children: label }, deviceId))) }) })] })), !!audioDevices.length && (_jsxs(Flex, { marginTop: "xs", children: [!!videoDevices.length && (_jsx(IconWrapper, { children: _jsx(Icon, { fill: "slate11", height: 12, paths: MICROPHONE_ICON, width: 12 }) })), _jsxs(Box, { flex: 1, children: [_jsx(LegacySelect, { onChange: onAudioDeviceChange, value: audioDeviceId, size: "m", width: 1, "data-testid": "audio-devices-select", children: audioDevices.map(({ deviceId, label }) => (_jsx("option", { value: deviceId, children: label }, deviceId))) }), _jsx(Box, { mt: 3, "data-testid": "mic-preview", children: _jsx(VolumeMeter, { stream: mediaStream }) })] })] }))] }));
});
const IconWrapper = styled(Box).attrs({
    alignItems: 'center',
    border: '1px solid',
    borderColor: 'slate6',
    borderRadius: 'm',
    display: 'flex',
    height: 36,
    justifyContent: 'center',
    marginRight: 'xs',
    width: 36,
}) ``;
