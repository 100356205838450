export function getRowBg(isSelected, isLoading, isDisabled) {
    if (isSelected && !isLoading && !isDisabled) {
        return {
            bg: 'green005',
            hoverBg: 'green008',
        };
    }
    if (isLoading || isDisabled) {
        return {
            bg: 'snow',
            hoverBg: 'snow',
        };
    }
    return {
        bg: 'white',
        hoverBg: 'snow',
    };
}
