import { noop } from 'lodash-es';
import { createContext, useContext } from 'react';
const DUMMY_SLIDE_UI_CONTEXT = {
    activeSlideUUID: '',
    focusSlideUUID: '',
    setActiveSlideUUID: noop,
    slideToFocusOnScrollStopUUID: '',
    scrollSpyController: null,
};
export const UIContext = createContext(null);
export const useUIContext = () => useContext(UIContext) || DUMMY_SLIDE_UI_CONTEXT;
