import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { observer } from 'mobx-react';
import { forwardRef } from 'react';
import { FormattedMessage } from 'react-intl';
import { BtwButton, BtwLink, BtwText, cn, } from '@marvelapp/ballpark-components';
import { AddCardIcon } from '@marvelapp/ballpark-icons';
import { DroppableButtonContainer } from './DroppableButtonContainer';
export const BaseAddNewCategory = observer(forwardRef(function BaseAddNewCategory({ onClick, onBulkAddClick, isDraggedOver }, ref) {
    return (_jsxs(DroppableButtonContainer, { className: cn('min-h-[198px]', { 'bg-gray-50': isDraggedOver }), ref: ref, children: [_jsx(BtwButton, { "data-testid": "add-new-category", asChild: true, leadingIcon: _jsx(AddCardIcon, {}), onClick: onClick, children: _jsx("span", { className: "truncate", children: _jsx(FormattedMessage, { id: 'pxP3Rt', defaultMessage: 'Add new category' }) }) }), onBulkAddClick ? (_jsx(BtwLink, { onClick: onBulkAddClick, "data-testid": "bulk-add-categories", children: _jsx(FormattedMessage, { id: '6NMJOC', defaultMessage: '(or bulk add categories)' }) })) : (_jsx(BtwText, { size: "xs", weight: "normal", children: _jsx(FormattedMessage, { id: 'p+e/r0', defaultMessage: 'or drop cards here' }) }))] }));
}));
