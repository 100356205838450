import { useCallback, useEffect, useRef } from 'react';
import { deviceDetection } from '@marvelapp/core';
export function useShowKeyboard() {
    const proxyInput = useRef(null);
    useEffect(() => {
        if (!deviceDetection.isSafari)
            return;
        // Hack for Safari Mobile
        // Create an invisible proxy input in the top-left corner
        // See https://stackoverflow.com/questions/4609765/
        if (!proxyInput.current) {
            const input = document.createElement('input');
            Object.assign(input.style, {
                opacity: '0',
                position: 'fixed',
                top: '0',
                left: '0',
                pointerEvents: 'none',
                // Prevent iOS page zoom on focus
                fontSize: '16px',
            });
            document.body.appendChild(input);
            proxyInput.current = input;
        }
        return () => {
            if (proxyInput.current) {
                document.body.removeChild(proxyInput.current);
                proxyInput.current = null;
            }
        };
    }, []);
    const showKeyboard = useCallback(() => {
        if ('virtualKeyboard' in navigator) {
            navigator.virtualKeyboard.show();
        }
        else if (proxyInput.current) {
            // Focus the proxy input to ensure the keyboard slides up
            proxyInput.current.focus();
        }
    }, []);
    return showKeyboard;
}
