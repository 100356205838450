import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { observer } from 'mobx-react';
import * as React from 'react';
import { Button, Heading, Link, Modal, ModalOverlay, Text, } from '@marvelapp/ballpark-components';
import { failureCopy } from './ModalCopy';
export default observer(function ImportFailureModal({ onClick, failureCode, isLoading, }) {
    if (!failureCode)
        return null;
    const copy = failureCopy[failureCode];
    return (_jsx(ModalOverlay, { children: _jsxs(Modal, { testId: "import-failure-message", children: [_jsx(Heading, { marginTop: "xl", marginBottom: "xs", size: "l", children: copy.heading }), _jsxs(Text, { marginBottom: "m", size: "m", whiteSpace: "pre-line", children: [copy.description, ' ', _jsx(Link, { "data-testid": "figma-size-link", color: "marvelDark", fontSize: 3, to: copy.helpLink, children: "Learn More" })] }), _jsx(Button, { onClick: onClick, isLoading: isLoading, size: "l", marginRight: "xs", "data-testid": "close-import-failure-warning", children: copy.buttonText })] }) }));
});
