import { __rest } from "tslib";
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useQuery } from '@apollo/client';
import { getDoc, transact, } from '@ballpark/realtime-crdt';
import { proxyOptions } from '@ballpark/realtime-crdt-mobx';
import { HistoryProvider } from '@ballpark/realtime-plugin-history';
import { useOffline } from '@ballpark/realtime-plugin-offline';
import { useServer } from '@ballpark/realtime-plugin-server';
import { action } from 'mobx';
import { observer } from 'mobx-react';
import * as React from 'react';
import { useCallback, useEffect, useReducer, useState } from 'react';
import { useParams, useRouteMatch } from 'react-router-dom';
import { useQueryState } from 'react-router-use-location-state';
import { useMemoOne } from 'use-memo-one';
import { AuthedRoute, UserTestContextProvider, useFeatureFlag, useGetUserTest, } from '@marvelapp/ballpark-application';
import { NotFound, UnauthorizedAccess } from '@marvelapp/ballpark-components';
import { MultiplayerRealtimeProvider } from '@marvelapp/ballpark-realtime';
import { isDebugMode } from '@marvelapp/core';
import styled from '@marvelapp/styled';
import { Box } from '@marvelapp/ui';
import { USERTEST_DOC_KEY, UserTestUtils, validateUserTest, validateUserTestDoc, } from '@marvelapp/user-test-creator';
import { ErrorLoadingDoc } from '../components/ErrorLoadingDoc';
import { Persistence } from '../docUtils/Persistence';
import { useTokenPermission } from '../docUtils/useAuthToken';
import { DOES_NOT_EXIST, DOES_NOT_EXIST_VARIATION, MUST_PASS_ID, PERMISSION_ERROR, } from '../graphql/errors';
import { GET_USER_TEST_UUID } from '../graphql/queries';
import { ProjectContextProvider, } from '../utils/ProjectContext';
import { getPageError, getPageErrorCopy } from '../utils/getPageErrorCopy';
import { ResponsesPage as NewResponses } from './NewResponses/ResponsesPage';
import { PageWithProjectBar } from './PageWithProjectBar';
import Builder from './Project/Project';
import { Recruitment } from './Respondent/Recruitment';
import { ProjectSessions } from './Sessions/Sessions';
import Summary from './Summary';
const ProjectViewsWithAwareness = observer(function ProjectViewsWithAwareness({ beforeChangeCallbacks, userTest, }) {
    const server = useServer();
    const offline = useOffline();
    // offline may sync and be empty (sync means a connection with IDB was established)
    const offlineLoaded = offline.synced && userTest.uuid !== undefined;
    const isLoading = !server.synced && !offlineLoaded;
    const tokenPermission = useTokenPermission();
    const responses = _jsx(NewResponses, { isLoadingUserTestDoc: isLoading });
    const hasLiveSessions = useFeatureFlag('live-video');
    const isError = server.forbidden || server.unauthorized;
    if (isError)
        return _jsx(ErrorLoadingDoc, {});
    return (_jsx(UserTestContextProvider, { userTest: isLoading ? null : userTest, children: _jsx(WithHistory, { children: _jsx(MultiplayerProviderWrapper, { children: _jsx(ProjectContextProvider, { beforeChangeCallbacks: beforeChangeCallbacks, children: _jsxs(PageWithProjectBar, { children: [_jsx(AuthedRoute, { exact: true, path: "/project/:id", render: () => (_jsx(Builder, { isLoadingUserTestDoc: isLoading, permission: tokenPermission })) }), _jsx(AuthedRoute, { path: "/project/:id/summary", render: () => (_jsx(PositionWithPadding, { "data-testid": "summary-page", children: _jsx(Summary, {}) })) }), _jsx(AuthedRoute, { path: "/project/:id/responses", render: () => responses }), _jsx(AuthedRoute, { render: () => _jsx(Recruitment, { isLoadingUserTestDoc: isLoading }), exact: true, path: "/project/:id/recruit" }), hasLiveSessions && (_jsx(AuthedRoute, { component: ProjectSessions, exact: true, path: "/project/:id/sessions" }))] }) }) }) }) }));
});
const WithHistory = observer(function WithHistory({ children, }) {
    // only enable history for the builder page, we can't put this in the builder page itself because
    // the project bar needs access to it so it can close the share modal when the user performs
    // undo/redo.
    const match = useRouteMatch({ path: '/project/:id', exact: true });
    const userTest = useGetUserTest();
    if (!match)
        return _jsx(_Fragment, { children: children });
    const doc = getDoc(userTest);
    return (_jsx(HistoryProvider, { root: doc.getMap(USERTEST_DOC_KEY), children: children }));
});
const MultiplayerProviderWrapper = observer(function MultiplayerProviderWrapper({ children, }) {
    var _a;
    const server = useServer();
    return (_jsx(MultiplayerRealtimeProvider, { awareness: (_a = server.awareness) !== null && _a !== void 0 ? _a : undefined, children: children }));
});
export default observer(function ProjectViewsWithAwarenessContainer(_a) {
    var _b, _c;
    var args = __rest(_a, []);
    const { id } = useParams();
    const [beforeChangeCallbacks] = useState(() => new Set());
    const [documentNumber, incrementDocumentNumber] = useReducer((x) => x + 1, 0);
    const { data: userTestData, error, loading, } = useQuery(GET_USER_TEST_UUID, {
        variables: { pk: id },
    });
    const userTestUUID = (_b = userTestData === null || userTestData === void 0 ? void 0 : userTestData.userTest) === null || _b === void 0 ? void 0 : _b.uuid;
    const [cloneFrom] = (_c = useQueryState('from', '')) !== null && _c !== void 0 ? _c : '';
    const onBeforeChange = useCallback((data) => [...beforeChangeCallbacks].every((callback) => callback(data)), [beforeChangeCallbacks]);
    const userTest = useMemoOne(() => UserTestUtils.createUserTestDoc(undefined, Object.assign(Object.assign({}, proxyOptions), { onBeforeChange })), [userTestUUID, documentNumber]);
    useDebugUserTest(userTest, userTestUUID);
    if ((error === null || error === void 0 ? void 0 : error.message) === PERMISSION_ERROR) {
        return _jsx(UnauthorizedAccess, {});
    }
    const pageError = getPageError({
        data: { userTest: userTestData },
        loading,
        error,
    }, MUST_PASS_ID);
    if (pageError) {
        return _jsx(NotFound, Object.assign({}, getPageErrorCopy(pageError)));
    }
    if ((error === null || error === void 0 ? void 0 : error.message) === DOES_NOT_EXIST ||
        (error === null || error === void 0 ? void 0 : error.message) === DOES_NOT_EXIST_VARIATION ||
        (
        // if the url includes a pk that is too long, we get a response not successful message
        error === null || error === void 0 ? void 0 : error.message.includes('Response not successful: Received status code 400'))) {
        return _jsx(NotFound, Object.assign({}, getPageErrorCopy('doesNotExist')));
    }
    return (_jsx(Persistence, { id: userTestUUID, parameters: { cloneFrom }, document: userTest, validate: validateUserTestDoc, resetDocument: incrementDocumentNumber, children: _jsx(ProjectViewsWithAwareness, Object.assign({ beforeChangeCallbacks: beforeChangeCallbacks, userTest: userTest }, args)) }));
});
const PositionWithPadding = styled(Box).attrs({
    bg: 'slate2',
    padding: ['m', 'm', 'm', 'm', 'm', 'm', 'l'],
}) `
  display: flex;
  align-items: stretch;
  justify-content: center;
  min-height: calc(100vh - 50px);
`;
function useDebugUserTest(userTest, userTestUUID) {
    useEffect(() => {
        if (!isDebugMode()) {
            return;
        }
        window.importDocument = (data) => {
            if (!userTestUUID) {
                throw new Error('Tried to import UserTest but no UUID was set');
            }
            const dataWithCorrectUUID = Object.assign(Object.assign({}, data), { uuid: userTestUUID });
            if (!validateUserTest(dataWithCorrectUUID, userTestUUID)) {
                // eslint-disable-next-line no-console
                console.error(validateUserTest.errors, dataWithCorrectUUID);
                throw new Error('Tried to import invalid UserTest');
            }
            const deletedKeys = Object.keys(userTest).filter((key) => !Object.hasOwnProperty.call(dataWithCorrectUUID, key));
            transact(userTest, action(() => {
                Object.assign(userTest, dataWithCorrectUUID);
                deletedKeys.forEach((key) => {
                    // eslint-disable-next-line no-param-reassign
                    delete userTest[key];
                });
            }));
            // eslint-disable-next-line no-console
            console.log('imported', JSON.parse(JSON.stringify(userTest)));
        };
        window.exportDocument = () => {
            return JSON.parse(JSON.stringify(userTest));
        };
        return () => {
            window.importDocument = undefined;
            window.exportDocument = undefined;
        };
    }, [userTest, userTestUUID]);
}
