import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Fragment, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Webinar } from '@marvelapp/askhub-upsells';
import { useFeatureFlag, usePermissions, } from '@marvelapp/ballpark-application';
import { Link, Stack } from '@marvelapp/ballpark-components';
import { HELP, ROADMAP } from '@marvelapp/ballpark-help';
import { useOnClickOutside } from '@marvelapp/hooks';
import { TrialCountdown } from './TrialCountdown';
import { UpgradeButton } from './UpgradeButton';
import { getMainLinks } from './links';
export const NavLinks = function NavLinks() {
    const { pathname } = useLocation();
    const permissions = usePermissions();
    const hasLiveSessions = useFeatureFlag('live-video');
    const mainLinks = getMainLinks({ permissions, hasLiveSessions });
    const mainLinksCanView = mainLinks.filter((link) => link.canView);
    const ref = useRef();
    const [openSubmenu, setOpenSubmenu] = useState(null);
    useOnClickOutside(ref, () => {
        setOpenSubmenu(null);
    });
    return (_jsxs(Stack, { width: "full", gap: "6", align: "stretch", children: [_jsx(TrialCountdown, {}), _jsx(UpgradeButton, {}), _jsx(Stack, { gap: "2", align: "stretch", children: mainLinksCanView.map((link) => (_jsx(Fragment, { children: _jsx(NavLink, { active: openSubmenu === null && pathname.includes(link.to), to: link.to, children: link.name }) }, link.name))) }), _jsxs(Stack, { gap: "2", align: "stretch", className: "mt-2", children: [_jsx(NavLink, { to: HELP, children: "Help" }, "help"), _jsx(IntercomLauncher, {}), _jsx(NavLink, { to: ROADMAP, children: "Roadmap" }, "roadmap")] }), _jsx(Webinar, {})] }));
};
function NavLink({ active, children, to }) {
    return (_jsx(Link, { kind: "licorice", bg: active && 'slate4', borderRadius: 2, fontSize: 14, fontWeight: active ? 500 : 400, height: "32px", hoverBg: active ? 'slate5' : 'slate4', lineHeight: "32px", to: to, px: "s", children: children }));
}
function IntercomLauncher() {
    return (_jsx(Link, { kind: "licorice", borderRadius: 2, fontSize: 14, fontWeight: 400, height: "32px", hoverBg: 'slate4', lineHeight: "32px", px: "s", id: "intercom-launcher", textAlign: "left", children: "Contact us" }));
}
