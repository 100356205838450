import { jsx as _jsx } from "react/jsx-runtime";
import { observer, useLocalObservable } from 'mobx-react';
import { createContext, useContext } from 'react';
import * as React from 'react';
const ProjectContext = createContext(undefined);
export const useProjectContext = () => {
    const projectState = useContext(ProjectContext);
    if (!projectState) {
        throw new Error('useProjectContext used outside of the provider');
    }
    return projectState;
};
export const ProjectContextProvider = observer(function ProjectContextProvider({ beforeChangeCallbacks, children, }) {
    /* eslint-disable react/no-this-in-sfc */
    const cache = useLocalObservable(() => {
        const state = {
            beforeChange: {
                on: (callback) => {
                    beforeChangeCallbacks.add(callback);
                },
                off: (callback) => {
                    beforeChangeCallbacks.delete(callback);
                },
            },
            cancelledOperationStepUUID: null,
            // When an operation on the realtime doc is cancelled, the current timesteamp
            // and the UUID of the affected step are saved to local state.
            // This unique combination is used as a key on the slide elements of the step,
            // which triggers a re-render.
            // Since these elements maintain their own local state, re-rendering them upon
            // cancellation is the simplest way to keep them in sync with the realtime doc
            getStepKey(stepUUID) {
                var _a;
                return ((_a = this.cancelledOperationStepUUID) === null || _a === void 0 ? void 0 : _a.includes(stepUUID))
                    ? this.cancelledOperationStepUUID
                    : stepUUID;
            },
            projectBar: {
                isSettingsModalOpen: false,
                isShareModalOpen: false,
            },
        };
        return state;
    });
    /* eslint-enable react/no-this-in-sfc */
    return (_jsx(ProjectContext.Provider, { value: cache, children: children }));
});
