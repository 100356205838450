import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useResponsiveValue } from '@theme-ui/match-media';
import { isNil } from 'lodash-es';
import { observer } from 'mobx-react';
import * as React from 'react';
import styled, { css } from '@marvelapp/styled';
import { Box, Flex } from '@marvelapp/ui';
export default observer(function Checkbox({ alwaysShowCheckbox, checked, children, groupName, hasNestedElements, hasOuterBorder = false, id, name, onChange, optionNumber, readOnly, testId, type = 'checkbox', value, }) {
    const showBasedOnBreakpoints = useResponsiveValue([true, true, true, false]);
    const shouldShowEmptyCheckbox = alwaysShowCheckbox || showBasedOnBreakpoints;
    let inputTestId = '';
    let labelTestId = '';
    if (!isNil(optionNumber)) {
        inputTestId = `choice-input-${optionNumber}`;
        labelTestId = `choice-label-${optionNumber}`;
    }
    return (_jsxs(LabelCheckboxWrapper, { "data-testid": `input-label-wrapper-${name}`, children: [_jsx(CheckboxInput, { checked: checked, "data-testid": testId || inputTestId, hasNestedElements: hasNestedElements, hasOuterBorder: hasOuterBorder, id: id, name: groupName, onChange: onChange && ((e) => onChange(e.target.checked)), readOnly: readOnly, type: type, value: value }), _jsxs(CheckboxLabel, { checked: checked, cursor: onChange ? 'pointer' : 'default', "data-testid": testId || labelTestId, hasNestedElements: hasNestedElements, hasOuterBorder: hasOuterBorder, htmlFor: id, readOnly: readOnly, alwaysShowCheckbox: shouldShowEmptyCheckbox, children: [children, name] })] }));
});
const HiddenButton = styled(Box).attrs({
    opacity: [1, 1, 1, 0],
    visibility: ['visible', 'visible', 'visible', 'hidden'],
}) ``;
const LabelCheckboxWrapper = styled(Flex) `
  height: 100%;
  width: 100%;
`;
const CheckboxInput = styled.input `
  opacity: 0;
  position: fixed;
  width: 0;

  &:checked:hover + label {
    background: ${(props) => props.theme.colors.blue4};
  }
  &:checked + label {
    background: ${(props) => props.theme.colors.blue3};
    border-color: ${(props) => props.theme.colors.blue6};
  }
  &:checked + label::after {
    content: ${(props) => `url('data:image/svg+xml,${encodeURIComponent(`<svg
          height="24"
          viewBox="0 0 24 24"
          width="24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g fill="${props.theme.colors.primaryHighlight}">
            <path d="M20 0a4 4 0 014 4v16a4 4 0 01-4 4H4a4 4 0 01-4-4V4a4 4 0 014-4h16zm-1.395 6.3a1.137 1.137 0 00-1.609.07l-7.334 8.667-2.666-2.708a1.142 1.142 0 00-1.654 0 1.238 1.238 0 000 1.71l3.493 3.61c.223.22.518.345.827.35.354.017.697-.134.931-.41l8.15-9.63c.39-.502.33-1.233-.138-1.659z" />
          </g>
        </svg>`)}')`};
    background-color: white;
    position: absolute;
    box-shadow: ${(props) => props.hasOuterBorder ? `0 0 0 3px white` : 'none'};
    border-radius: ${(props) => props.theme.radii.s}px;
    width: 24px;
    height: 24px;

    ${(props) => props.hasNestedElements
    ? css `
            top: ${props.theme.space.xs}px;
            right: ${props.theme.space.xs}px;
          `
    : css `
            top: 50%;
            margin-top: -12px;
            right: ${props.theme.space.s}px;
          `};
  }
  &:focus + label {
    box-shadow: ${(props) => props.theme.shadows.button.secondaryFocus};
  }
`;
const CheckboxLabel = styled(Box).attrs((props) => ({
    as: 'label',
    bg: 'background',
    border: '1px solid',
    borderColor: 'slate6',
    borderRadius: 'l',
    boxShadow: 'button.secondary',
    color: props.checked ? 'blue12' : 'slate12',
    fontSize: props.hasNestedElements ? ['s', 's', 14] : ['s', 's', 'm'],
    fontWeight: 'medium',
    hoverBg: props.readOnly ? 'background' : 'slate2',
    hoverShadow: 'button.secondaryHover',
    lineHeight: 'heading',
    paddingLeft: props.hasNestedElements ? 'xs' : 's',
    paddingRight: props.hasNestedElements ? 'xs' : 'xxl',
    paddingY: props.hasNestedElements ? 'xs' : 's',
    position: 'relative',
    textAlign: props.hasNestedElements ? 'center' : 'left',
})) `
  display: block;
  width: 100%;
  ${(props) => props.readOnly &&
    css `
      pointer-events: none;
    `};

  &:hover ${HiddenButton} {
    opacity: 1;
    visibility: visible;
  }

  ${(props) => !props.checked &&
    !props.readOnly &&
    css `
      &:after {
        top: ${props.hasNestedElements ? `${props.theme.space.xs}px` : '50%'};
        margin-top: ${props.hasNestedElements ? '0px' : '-12px'};
        right: ${props.hasNestedElements
        ? props.theme.space.xs
        : props.theme.space.s}px;
        position: absolute;
        content: '';
        display: block;
        width: 24px;
        height: 24px;
        background-color: ${props.theme.colors.background};
        border: 1px solid ${props.theme.colors.slate8};
        border-radius: ${props.theme.radii.s}px;
        opacity: ${props.alwaysShowCheckbox ? 1 : 0};
        box-shadow: ${props.hasOuterBorder ? `0 0 0 3px white` : 'none'};
        &:hover {
          background-color: ${props.theme.colors.blue2};
        }
      }
      &:hover:after {
        opacity: 1;
        visibility: visible;
      }
    `}
`;
