import { __rest } from "tslib";
import { jsx as _jsx } from "react/jsx-runtime";
import { useResponsiveValue } from '@theme-ui/match-media';
import { observer } from 'mobx-react';
import { useState } from 'react';
import * as React from 'react';
import { useIntl } from 'react-intl';
import { useAction } from '@marvelapp/ballpark-application';
import { Button } from '@marvelapp/ballpark-components';
import { UI_STEPS_COPY } from '@marvelapp/ballpark-copy';
import styled, { css } from '@marvelapp/styled';
import { Box } from '@marvelapp/ui';
import { FiveSecondResponseUtils, StepClass, } from '@marvelapp/user-test-creator';
import { useRecorderState } from '../../components/Record/RecorderContext';
import ImageReveal from '../FirstClick/ImageReveal';
import SlideRecord from '../common/SlideRecord';
export default observer(function FiveSecondRecord(_a) {
    var { step, stepResponse } = _a, restProps = __rest(_a, ["step", "stepResponse"]);
    const intl = useIntl();
    const [showImage, setShowImage] = useState(false);
    const recorderState = useRecorderState();
    const isMobile = useResponsiveValue([true, true, false]);
    const closeImage = useAction(() => {
        setShowImage(false);
        recorderState.goForward();
    }, [recorderState]);
    const revealImage = useAction(() => {
        FiveSecondResponseUtils.setAnswer(stepResponse, true);
        setShowImage(true);
    }, [stepResponse]);
    if (showImage) {
        return (_jsx(ImageReveal, { timer: step.timer, onCloseImage: closeImage, src: step.imageUrl }));
    }
    return (_jsx(SlideRecord, Object.assign({ step: step }, restProps, { children: _jsx(ButtonContainer, { isMobile: isMobile, children: _jsx(Button, { "data-testid": "reveal-image-button", flex: 1, mt: "s", onClick: revealImage, children: intl.formatMessage(UI_STEPS_COPY[StepClass.FiveSecondTest].buttonText) }) }) })));
});
const ButtonContainer = styled(Box) `
  ${({ isMobile }) => isMobile &&
    css `
      display: flex;
      flex-direction: row;
      position: fixed;
      bottom: 12px;
      left: 12px;
      right: 96px;
      z-index: 10;
    `}
`;
