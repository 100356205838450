import { isEqual } from 'lodash-es';
export class RequestsQueue {
    constructor() {
        this.requestInProgress = false;
        this.requestsQueue = [];
    }
    queueRequest(requestPayload) {
        const requestsCount = this.requestsQueue.length;
        if (!requestsCount) {
            this.requestsQueue.push(requestPayload);
        }
        else if ((requestsCount === 1 &&
            !isEqual(this.requestsQueue[0], requestPayload)) ||
            (requestsCount === 2 && !isEqual(this.requestsQueue[1], requestPayload))) {
            this.requestsQueue[1] = requestPayload;
        }
    }
    dequeueRequest(cb) {
        this.requestsQueue.shift();
        this.requestInProgress = false;
        if (this.requestsQueue.length) {
            cb(this.requestsQueue[0]);
        }
    }
    clearQueue() {
        this.requestInProgress = false;
        this.requestsQueue = [];
    }
}
