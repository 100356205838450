import { Combinator, } from '../../userTest/types';
export function getIsChoiceConditional(step, uuid) {
    var _a, _b;
    // check if step has conditional logic set
    const hasConditionalLogicSet = !!((_a = step.conditions) === null || _a === void 0 ? void 0 : _a.rules.length);
    if (hasConditionalLogicSet) {
        // check each rule for deletedUUID
        return !!((_b = step.conditions) === null || _b === void 0 ? void 0 : _b.rules.find((rule) => {
            return rule.requirements.find((requirement) => {
                return requirement.selectedChoiceUUID === uuid;
            });
        }));
    }
    return false;
}
export function deleteRulesForDeletedChoice(mutable, deletedChoiceUUID) {
    var _a;
    if (!mutable.conditions) {
        return;
    }
    // filter out any rules that are associated with the deleted choice
    const filteredRules = (_a = mutable.conditions) === null || _a === void 0 ? void 0 : _a.rules.filter((rule) => {
        return !rule.requirements.find((req) => req.selectedChoiceUUID === deletedChoiceUUID);
    });
    mutable.conditions.rules = filteredRules;
}
// TODO use the generic versions of these functions from multiConditionStep.ts
export function getRules(step) {
    if (step.conditions && step.conditions.rules) {
        return step.conditions.rules.filter((rule) => rule.destinationStepUUID !== null);
    }
    return [];
}
export function getRule({ mutable, ruleIndex, }) {
    var _a, _b, _c;
    if (mutable.conditions) {
        return (_c = (_b = (_a = mutable.conditions) === null || _a === void 0 ? void 0 : _a.rules) === null || _b === void 0 ? void 0 : _b[ruleIndex]) !== null && _c !== void 0 ? _c : null;
    }
    return null;
}
export function getRuleRequirements({ mutable, ruleIndex, }) {
    var _a, _b;
    if (mutable.conditions && ((_a = mutable.conditions) === null || _a === void 0 ? void 0 : _a.rules)) {
        return (_b = mutable.conditions.rules[ruleIndex].requirements) !== null && _b !== void 0 ? _b : null;
    }
    return null;
}
export function getRequirement({ mutable, ruleIndex, requirementIndex, }) {
    var _a, _b, _c;
    if (mutable.conditions && ((_b = (_a = mutable.conditions) === null || _a === void 0 ? void 0 : _a.rules) === null || _b === void 0 ? void 0 : _b[ruleIndex])) {
        return ((_c = mutable.conditions.rules[ruleIndex].requirements[requirementIndex]) !== null && _c !== void 0 ? _c : null);
    }
    return null;
}
export function setRule(mutable, selectedChoiceUUID, selectedStepUUID, selectedOperator, selectedCombinator) {
    var _a;
    const rule = {
        destinationStepUUID: selectedStepUUID,
        requirements: [
            {
                // operator: selectedOperator ?? IsOperator.Is, TODO pass in default?
                operator: selectedOperator,
                selectedChoiceUUID,
                combinator: selectedCombinator,
            },
        ],
    };
    if (mutable.conditions) {
        if ((_a = mutable.conditions) === null || _a === void 0 ? void 0 : _a.rules) {
            mutable.conditions.rules.push(rule);
        }
        else {
            mutable.conditions.rules = [rule];
        }
    }
    else {
        // support for documents that don't have this conditions object yet
        mutable.conditions = {
            rules: [
                {
                    destinationStepUUID: selectedStepUUID,
                    requirements: [
                        {
                            operator: selectedOperator,
                            selectedChoiceUUID,
                            combinator: Combinator.And,
                        },
                    ],
                },
            ],
            ALWAYS: null,
        };
    }
}
export function setRequirement({ mutable, ruleIndex, selectedChoiceUUID, 
// operator = IsOperator.Is, // TODO does this need to be optional?
operator, combinator = Combinator.And, }) {
    var _a, _b;
    if (mutable.conditions && ((_a = mutable.conditions) === null || _a === void 0 ? void 0 : _a.rules)) {
        (_b = mutable.conditions) === null || _b === void 0 ? void 0 : _b.rules[ruleIndex].requirements.push({
            operator,
            selectedChoiceUUID,
            combinator,
        });
    }
}
export function deleteRule(mutable, index) {
    var _a;
    if (mutable.conditions && ((_a = mutable.conditions) === null || _a === void 0 ? void 0 : _a.rules)) {
        mutable.conditions.rules.splice(index, 1);
    }
}
export function deleteRequirement(mutable, ruleIndex, requirementIndex) {
    var _a;
    if (mutable.conditions && mutable.conditions.rules) {
        // if this is the last requirement, delete the entire rule
        if (mutable.conditions.rules[ruleIndex].requirements.length <= 1) {
            deleteRule(mutable, ruleIndex);
        }
        else {
            (_a = mutable.conditions) === null || _a === void 0 ? void 0 : _a.rules[ruleIndex].requirements.splice(requirementIndex, 1);
        }
    }
}
export function updateRuleDestination({ mutable, ruleIndex, destinationStepUUID, }) {
    var _a;
    if (mutable.conditions && ((_a = mutable.conditions) === null || _a === void 0 ? void 0 : _a.rules)) {
        if (mutable.conditions.rules[ruleIndex])
            mutable.conditions.rules[ruleIndex].destinationStepUUID =
                destinationStepUUID;
    }
}
export function updateRequirementCombinator({ mutable, ruleIndex, requirementIndex, combinator, }) {
    var _a;
    if (mutable.conditions && ((_a = mutable.conditions) === null || _a === void 0 ? void 0 : _a.rules))
        mutable.conditions.rules[ruleIndex].requirements[requirementIndex].combinator = combinator;
}
export function updateRequirementValue({ mutable, ruleIndex, selectedChoiceUUID, requirementIndex, }) {
    var _a;
    if (mutable.conditions && ((_a = mutable.conditions) === null || _a === void 0 ? void 0 : _a.rules)) {
        mutable.conditions.rules[ruleIndex].requirements[requirementIndex].selectedChoiceUUID = selectedChoiceUUID;
    }
}
export function updateRequirementEqualityOperator({ mutable, ruleIndex, requirementIndex, equalityOperatorValue, }) {
    if (mutable.conditions && mutable.conditions.rules) {
        mutable.conditions.rules[ruleIndex].requirements[requirementIndex].operator = equalityOperatorValue;
    }
}
export function initializeConditions(mutable) {
    if (!mutable.conditions) {
        mutable.conditions = {
            rules: [],
            ALWAYS: null,
        };
    }
}
