import { reaction } from 'mobx';
import { useEffect, useRef } from 'react';
export function useReaction(depFn, reactionFn, options = {}, deps = []) {
    const teardown = useRef(undefined);
    useEffect(() => {
        const disposer = reaction(depFn, (input) => {
            var _a;
            (_a = teardown.current) === null || _a === void 0 ? void 0 : _a.call(teardown);
            teardown.current = reactionFn(input);
        }, options);
        return () => {
            var _a;
            (_a = teardown.current) === null || _a === void 0 ? void 0 : _a.call(teardown);
            teardown.current = undefined;
            disposer();
        };
    }, deps);
}
