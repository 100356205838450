import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useHistoryState } from '@ballpark/realtime-plugin-history';
import { observer } from 'mobx-react';
import { useCallback, useState } from 'react';
import * as React from 'react';
import { useGetUserTest, useSlideUIContext, } from '@marvelapp/ballpark-application';
import { Badge, BtwModal, ConditionsIcon, Stack, } from '@marvelapp/ballpark-components';
import { ConditionalLogicProvider, LogicStepSidebar, RulesContent, useConditionalLogicState, } from '@marvelapp/conditional-logic';
import { ConditionalLogicUtils } from '@marvelapp/user-test-creator';
import { useUIContext } from '../../pages/Project/uiContext';
import SlideControlsButton from './SlideControlsButton';
export const ConditionalLogicSettings = observer(function ConditionalLogicSettings() {
    const { activeSlideUUID } = useUIContext();
    return (_jsx(ConditionalLogicProvider, { activeStepUUID: activeSlideUUID, children: _jsx(ConditionalLogic, {}) }));
});
const ConditionalLogic = observer(function ConditionalLogic() {
    const canHaveConditions = useCanHaveConditions();
    const { step } = useSlideUIContext();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const { setActiveStepUUID, activeStepUUID } = useConditionalLogicState();
    const storeState = useCallback(() => {
        return {
            modalOpen: isModalOpen,
            activeStepUUID,
        };
    }, [activeStepUUID, isModalOpen]);
    const replayState = useCallback((state) => {
        const { modalOpen, activeStepUUID } = state !== null && state !== void 0 ? state : {};
        setIsModalOpen(!!modalOpen);
        if (activeStepUUID)
            setActiveStepUUID(activeStepUUID);
    }, [setActiveStepUUID]);
    // there are multiple modals therefore we need to store unique state for each modal
    useHistoryState(`conditionalLogic-${step.uuid}`, storeState, replayState);
    if (!canHaveConditions) {
        return null;
    }
    return (_jsxs(BtwModal.Root, { open: isModalOpen, onOpenChange: () => {
            if (!isModalOpen) {
                setActiveStepUUID(step.uuid);
            }
            setIsModalOpen(!isModalOpen);
        }, children: [_jsx(BtwModal.Trigger, { asChild: true, children: _jsxs(SlideControlsButton, { "data-testid": "conditions-button", children: [_jsx(ConditionsIcon, { mr: "6px", ml: "xxs" }), "Conditions", _jsx(ConditionsCount, {})] }) }), _jsx(BtwModal.Content
            // 130px for height = 40px padding top + 40px padding bottom + 50px nav bar height
            // 80px for width = 40px padding on both sides
            , { 
                // 130px for height = 40px padding top + 40px padding bottom + 50px nav bar height
                // 80px for width = 40px padding on both sides
                contentClassName: "relative top-[25px] h-[calc(100vh_-_130px)] max-h-[calc(100vh_-_130px)] w-[calc(100vw_-_80px)] max-w-screen-2xl overflow-hidden rounded-xl ring-1 ring-gray-600/10", position: "center", "data-testid": 'conditional-logic-modal', children: _jsxs(Stack, { direction: "row", className: "divide-x", width: "full", height: "full", children: [_jsx(LogicStepSidebar, {}), _jsx(RulesContent, {})] }) })] }));
});
const ConditionsCount = observer(function ConditionsCount() {
    const { step } = useSlideUIContext();
    const userTest = useGetUserTest();
    const count = ConditionalLogicUtils.getConditionsCount(step, userTest);
    if (!count)
        return null;
    return (_jsx(Badge, { size: "sm", "data-testid": "conditions-count", className: "ml-2", children: count }));
});
function useCanHaveConditions() {
    const userTest = useGetUserTest();
    const { step } = useSlideUIContext();
    return ConditionalLogicUtils.isConditionable(step, userTest);
}
