import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { observer } from 'mobx-react';
import { Flex } from '@marvelapp/ui';
import { MediaUtils } from '@marvelapp/user-test-creator';
import { RecordingFailureType } from '../../types';
import DeviceRecordingStatus, { isHidden } from './DeviceRecordingStatus';
import Divider from './Divider';
export default observer(function SideBarRecordingStatus({ userTestResult, }) {
    var _a, _b;
    const { recordings } = userTestResult;
    if (!recordings.download) {
        return null;
    }
    if (recordings.download.failedType === RecordingFailureType.HardwarePermissions) {
        const permissions = MediaUtils.mergeRecordingPermissions((_a = recordings === null || recordings === void 0 ? void 0 : recordings.user) === null || _a === void 0 ? void 0 : _a.permissions, (_b = recordings === null || recordings === void 0 ? void 0 : recordings.screen) === null || _b === void 0 ? void 0 : _b.permissions);
        if (isHidden(permissions)) {
            return null;
        }
        return (_jsxs(_Fragment, { children: [_jsx(Divider, {}), _jsx(Flex, { fontSize: "s", paddingX: "xs", children: _jsx(Flex, { direction: "row", alignItems: "center", width: "100%", children: _jsx(DeviceRecordingStatus, { testId: "whole-test-recording-status", permissions: permissions, width: "100%", justifyContent: "space-between", margin: 0 }) }) })] }));
    }
    return null;
});
