import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { observer } from 'mobx-react';
import { forwardRef, useEffect, useState } from 'react';
import { Icon } from '@marvelapp/ballpark-components';
import { NO_VIDEO_ICON } from '@marvelapp/ballpark-theme';
import { useInterval } from '@marvelapp/hooks';
import styled from '@marvelapp/styled';
import { Box, Loader, PinAll, PinTR, Relative } from '@marvelapp/ui';
import { PlaybackButton } from '../SlideInstructions/Buttons';
const Video = observer(forwardRef(function Video(props, ref) {
    const { isLoading, onCanPlay, onLimitReached, videoEnabled, isRecording, } = props;
    const LIMIT = 180; // 180 seconds
    const [currentTime, setCurrentTime] = useState(0);
    const limitReached = currentTime === LIMIT;
    useInterval(() => {
        if (!isLoading && isRecording) {
            setCurrentTime((c) => c + 1);
        }
    }, limitReached ? null : 1000);
    useEffect(() => {
        if (limitReached) {
            onLimitReached();
        }
    }, [limitReached]);
    if (videoEnabled) {
        return (_jsxs(Relative, { children: [isLoading && (_jsx(PinAll, { children: _jsx(AudioContainer, { "data-testid": "no-video", children: _jsx(Loader, {}) }) })), !isLoading && isRecording && (_jsx(PinTR, { marginTop: "10px", marginRight: "10px", children: _jsx(PlaybackButton, { testId: "duration-time-stamp", currentTime: currentTime, duration: LIMIT, isVideo: videoEnabled }) })), _jsx(VideoContainer, { autoPlay: true, "data-testid": "video-player", muted: true, onCanPlay: onCanPlay, playsInline: true, preload: "auto", ref: ref })] }));
    }
    return (_jsxs(AudioContainer, { "data-testid": "no-video", children: [_jsx("audio", { ref: ref }), _jsx(Icon, { paths: NO_VIDEO_ICON, width: 192, height: 192, viewBox: "0 0 30 30" })] }));
}));
const VideoContainer = styled.video.attrs({
    borderRadius: 'l',
}) `
  display: flex;
  align-items: center;
  justify-content: center;
  object-fit: cover;
`;
const AudioContainer = styled(Box).attrs({
    bg: 'slate4',
    color: 'slate8',
    width: 'videoGuide',
    height: 'videoGuide',
    borderRadius: 'l',
}) `
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
export default Video;
