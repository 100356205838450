import { useResponsesContext } from './ResponsesContext';
import { isFilterDefined } from './filterUtils';
const GENERAL_FILTER_IDS = [
    'Device',
    'CreatedAt',
    'Status',
    'RecordingSubmitted',
    'ParticipantId',
    'StudyId',
    'ParticipantSource',
];
export function useCreateDataQueryFromState() {
    const { columnDefinitionsMap, filterDefinitionsMap, sorting, filters, logicalOperator, columnVisibility, } = useResponsesContext();
    if (Object.keys(columnDefinitionsMap).length === 0)
        return;
    return {
        operatorType: logicalOperator,
        generalFilters: getGeneralFilters(filters).map((filter) => {
            const { value, operator, operand } = filter;
            switch (operand) {
                case 'Device':
                    return {
                        filterType: operand,
                        filter: operator,
                        value: value,
                    };
                case 'CreatedAt':
                    return {
                        filterType: operand,
                        filter: operator,
                        value: getCreatedAtValue(value, operator),
                    };
                case 'Status':
                    return {
                        filterType: operand,
                        filter: operator,
                        value: [value],
                    };
                case 'RecordingSubmitted':
                    return {
                        filterType: operand,
                        filter: operator,
                        value: value,
                    };
                case 'ParticipantId':
                case 'StudyId':
                    return {
                        filterType: operand,
                        filter: operator,
                        value: value,
                    };
                case 'ParticipantSource':
                    return {
                        filterType: operand,
                        filter: operator,
                        value: [value],
                    };
                default:
                    throw new Error(`Unknown filter type: ${filter.operand}`);
            }
        }),
        stepFilters: getStepFilters(filters).map((filter) => {
            return {
                stepUUID: filter.operand,
                filter: filter.operator,
                value: filterDefinitionsMap[filter.definitionId].allowsMultiple
                    ? [filter.value]
                    : filter.value,
            };
        }),
        sorting: sorting
            .filter((sort) => sort.id !== '')
            .map((sort) => {
            const column = columnDefinitionsMap[sort.id];
            if (!column || !column.meta || !column.meta.sorting) {
                throw new Error(`Unknown sorting for column: ${sort.id}`);
            }
            const { type, id } = column.meta.sorting;
            switch (type) {
                case 'string':
                case 'date':
                    return {
                        sortingType: id,
                        value: sort.desc ? 'DESC' : 'ASC',
                    };
                case 'boolean':
                    return {
                        sortingType: id,
                        value: sort.desc ? 'DESC' : 'ASC',
                    };
                default:
                    throw new Error(`Unknown sorting type: ${type}`);
            }
        }),
        // This is only needed for CSV export
        hiddenColumns: Object.entries(columnVisibility)
            .filter(([, visible]) => !visible)
            .map(([colId]) => {
            var _a, _b;
            const column = columnDefinitionsMap[colId];
            const { id: backendColId } = (_b = (_a = column === null || column === void 0 ? void 0 : column.meta) === null || _a === void 0 ? void 0 : _a.visibility) !== null && _b !== void 0 ? _b : {};
            return backendColId !== null && backendColId !== void 0 ? backendColId : colId;
        }),
    };
}
function getGeneralFilters(filters) {
    const generalFilters = filters.filter((filter) => isFilterDefined(filter) && GENERAL_FILTER_IDS.includes(filter.operand));
    return generalFilters;
}
function getStepFilters(filters) {
    return filters.filter((filter) => isFilterDefined(filter) && !GENERAL_FILTER_IDS.includes(filter.operand));
}
function getCreatedAtValue(value, operator) {
    const date = new Date(value);
    if (operator === 'after' && date) {
        date.setUTCHours(23, 59, 59, 999);
    }
    return date.valueOf();
}
