import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { observer } from 'mobx-react';
import * as React from 'react';
import { Text } from '@marvelapp/ballpark-components';
import styled from '@marvelapp/styled';
import { Box } from '@marvelapp/ui';
const kinds = {
    primary: {
        bg: 'primary',
    },
};
export default observer(function Message({ kind = 'primary', children, size = 'm', testId, }) {
    return (_jsxs(Container, { "data-testid": testId, children: [_jsx(Border, Object.assign({}, kinds[kind])), _jsx(Text, { py: "xxs", size: size, children: children })] }));
});
const Container = styled(Box) `
  display: flex;
  align-items: stretch;
`;
const Border = styled(Box).attrs({
    borderRadius: 'xl',
    marginRight: 's',
}) `
  min-width: 0;
  width: 4px;
  flex-shrink: 0;
`;
