import { jsx as _jsx } from "react/jsx-runtime";
import LogRocket from 'logrocket';
import { observer } from 'mobx-react';
import { useConfig } from '@marvelapp/ballpark-application';
import { BaseError, BtwButton } from '@marvelapp/ballpark-components';
import { isSafeURL } from '@marvelapp/core';
export const RecordStudyBounce = observer(function RecordStudyBounce() {
    var _a;
    const config = useConfig();
    let heading = 'There has been a problem';
    let subheading = 'It looks like we’re unable to continue due to an error loading the study.';
    let hasCta = false;
    let bounceUrl = null;
    let errorCode = null;
    try {
        const url = new URL(window.location.href);
        const params = url.searchParams;
        bounceUrl = params.get('bounce_url');
        errorCode = params.get('code');
    }
    catch (error) {
        if (((_a = process.env.DEPLOY_ENV) === null || _a === void 0 ? void 0 : _a.includes('production')) &&
            error instanceof Error) {
            LogRocket.captureException(error, {
                extra: {
                    errorInfo: `Failed to parse the URL for the study bounce page: ${error.message}`,
                },
            });
        }
    }
    const ctaUrl = !!bounceUrl && isSafeURL(bounceUrl) ? bounceUrl : '';
    switch (errorCode) {
        case 'WARN_QUEUED':
            heading = 'Study full (for now). Check back soon.';
            subheading =
                'It looks like all spots for this study are currently filled. Please click the button below to check if a spot becomes available as participants may drop out.';
            hasCta = true;
            break;
        case 'WARN_FULFILLED':
            heading = 'Study now closed';
            subheading =
                'The study is now closed to new participants—thank you for your interest!';
            break;
        case 'WARN_UNAVAILABLE':
            heading = 'Study unavailable';
            subheading = 'The study is no longer available for participants.';
            break;
        case 'WARN_TEST_ALREADY_TAKEN':
            heading = 'We’ve already received your answers';
            subheading =
                "It looks like you've already submitted a response for this study — thanks for participating!";
            break;
        case 'ERROR_REJECTED':
            heading = 'You’re not eligible for this study';
            subheading =
                'Unfortunately, this study is no longer available to you. Thank you for your interest!';
            break;
        case 'ERROR_INVALID_STUDY_ID':
        case 'ERROR_STUDY_ID_NOT_MATCHING':
        case 'ERROR_INVALID_PARTNER_PARAMS':
            heading = 'Study link not recognized';
            subheading =
                'The study link you’ve used is invalid — please check and try again';
            break;
        case 'ERROR_INTERNAL_SEED_ERROR':
        default:
            subheading += ' Please try again.';
            hasCta = true;
            break;
    }
    return (_jsx(BaseError, { heading: heading, subheading: subheading, testId: "study-bounce-error-message", children: hasCta && ctaUrl && (_jsx("a", { "data-testid": "bounce-link", href: `${config.pie.host}${bounceUrl}`, children: _jsx(BtwButton, { variant: "primary", children: "Try again" }) })) }));
});
