import { StepClass } from '../common';
export function create(stepUUID, stepRevision) {
    return {
        className: StepClass.WebsiteTask,
        stepUUID,
        stepRevision,
        participantRecordings: {
            user: null,
            screen: null,
        },
        skipped: true,
    };
}
