import { createProxiedDoc } from '@ballpark/realtime-crdt';
import { cloneDeep } from 'lodash-es';
import { mapFilterNullish } from '@marvelapp/core';
import { RESPONSE_DOC_KEY } from '../constants';
import { getAnyMediaEnabled, isWelcomeStep } from '../userTest/selectors';
import { StepClass } from '../userTest/types';
import { generateUUID } from '../utils/generateUUID';
import { isIntroStep } from '../utils/screenerUtils';
import { create as createFiveSecondResponse } from './FiveSecondStep';
import { createCardSortingClosedResponse, createCardSortingHybridResponse, createCardSortingOpenResponse, } from './cardSortingStep';
import { create as createInstructionResponse } from './instructionStep';
import { create as createMultipleChoiceResponse } from './multipleChoiceStep';
import { create as createPreferenceTestResponse } from './preferenceStep';
import { create as createPrototypeTaskResponse } from './prototypeTaskStep';
import { create as createQuestionResponse } from './questionStep';
import { create as createRatingStepResponse } from './ratingStep';
import { create as createTaglineTestResponse } from './taglineCopyStep';
import { ResponseStatus } from './types';
import { create as createWebsiteTaskResponse } from './websiteTaskStep';
import { create as createYesOrNoResponse } from './yesOrNoStep';
export function createResponseDoc(data, options) {
    const userTestResponse = createProxiedDoc(data, RESPONSE_DOC_KEY, options);
    return userTestResponse;
}
export function generateResponseUUID() {
    return generateUUID('ut_response_');
}
export function stripUuidPrefix(uuid) {
    return uuid.replace(/^ut_response_/, '');
}
export function create(partial = {}, userTestDoc) {
    return Object.assign({ startTimestamp: null, uuid: generateResponseUUID(), schemaVersion: 0, status: userTestDoc.screeners
            ? ResponseStatus.Screening
            : ResponseStatus.InProgress, endTimestamp: null, utRevision: 0, timeline: [], history: [], currentStepUUID: userTestDoc.screeners
            ? userTestDoc.screeners.intro.uuid
            : userTestDoc.welcomeStep.uuid, responses: createStepResponses(userTestDoc), screenerResponses: createScreenerStepResponses(userTestDoc), userTest: cloneDeep(userTestDoc) }, partial);
}
function createStepResponses(userTestDoc) {
    const welcomeStepResponse = {
        className: userTestDoc.welcomeStep.className,
        stepRevision: userTestDoc.welcomeStep.revision,
        stepUUID: userTestDoc.welcomeStep.uuid,
    };
    const recordingPermissionsStepResponse = getAnyMediaEnabled(userTestDoc)
        ? {
            className: userTestDoc.welcomeStep.className,
            stepRevision: userTestDoc.welcomeStep.revision,
            stepUUID: userTestDoc.welcomeStep.uuid,
        }
        : null;
    const middleStepResponses = userTestDoc.steps.map((uuid) => {
        const step = userTestDoc.stepDefinitions[uuid];
        switch (step.className) {
            case StepClass.Question:
                return createQuestionResponse(step.uuid, step.revision);
            case StepClass.Instruction:
                return createInstructionResponse(step.uuid, step.revision);
            case StepClass.YesOrNo:
                return createYesOrNoResponse(step.uuid, step.revision);
            case StepClass.RatingScale:
                return createRatingStepResponse(step.uuid, step.revision);
            case StepClass.PrototypeTask:
                return createPrototypeTaskResponse(step.uuid, step.revision);
            case StepClass.MultipleChoice:
                return createMultipleChoiceResponse(step.uuid, step.revision);
            case StepClass.PreferenceTest:
                return createPreferenceTestResponse(step.uuid, step.revision);
            case StepClass.TaglineCopyTest:
                return createTaglineTestResponse(step.uuid, step.revision);
            case StepClass.FiveSecondTest:
                return createFiveSecondResponse(step.uuid, step.revision);
            case StepClass.WebsiteTask:
                return createWebsiteTaskResponse(step.uuid, step.revision);
            case StepClass.CardSortingClosed:
                return createCardSortingClosedResponse(step);
            case StepClass.CardSortingOpen:
                return createCardSortingOpenResponse(step);
            case StepClass.CardSortingHybrid:
                return createCardSortingHybridResponse(step);
            default:
                throw new Error(`Shouldn't get here`);
        }
    });
    const exitStepResponse = {
        className: userTestDoc.exitStep.className,
        stepRevision: userTestDoc.exitStep.revision,
        stepUUID: userTestDoc.exitStep.uuid,
    };
    return mapFilterNullish([
        welcomeStepResponse,
        recordingPermissionsStepResponse,
        ...middleStepResponses,
        exitStepResponse,
    ], (s) => s);
}
export function addEventToTimeline(response, stepUUID, timestamp = Date.now()) {
    response.timeline.push({
        startTimestamp: timestamp,
        stepUUID,
    });
}
export function addUUIDToHistory(response, stepUUID) {
    if (response.history)
        response.history.push(stepUUID);
}
export function setStartTimestamp(mutable, timestamp = Date.now()) {
    mutable.startTimestamp = timestamp;
}
export function changeResponseStatus(mutable, status) {
    mutable.status = status;
}
export function markResponseAsComplete(mutable) {
    mutable.status = ResponseStatus.Complete;
    mutable.endTimestamp = Date.now();
}
export function importResponseDoc(mutable, overwriteDoc) {
    // eslint-disable-next-line no-param-reassign
    mutable = Object.assign(mutable, overwriteDoc);
}
function createScreenerStepResponses(userTestDoc) {
    if (!userTestDoc.screeners)
        return [];
    const introStepResponse = userTestDoc.screeners.intro
        ? {
            className: userTestDoc.screeners.intro.className,
            stepRevision: userTestDoc.screeners.intro.revision,
            stepUUID: userTestDoc.screeners.intro.uuid,
        }
        : null;
    const screenerStepResponses = userTestDoc.screeners.steps.map((uuid) => {
        const step = userTestDoc.stepDefinitions[uuid];
        return createMultipleChoiceResponse(step.uuid, step.revision);
    });
    const declineStepResponse = userTestDoc.screeners.decline
        ? {
            className: userTestDoc.screeners.decline.className,
            stepRevision: userTestDoc.screeners.decline.revision,
            stepUUID: userTestDoc.screeners.decline.uuid,
        }
        : null;
    return mapFilterNullish([introStepResponse, ...screenerStepResponses, declineStepResponse], (response) => response);
}
export function setStatus({ userTestDoc, response, currentStepUUID, nextStepUUID, }) {
    // if current step is Screener Intro step, mark test status as Screening
    if (isIntroStep(userTestDoc, currentStepUUID)) {
        changeResponseStatus(response, ResponseStatus.Screening);
    }
    else if (isWelcomeStep(userTestDoc, currentStepUUID) &&
        response.status === ResponseStatus.Screening) {
        // if current step is welcome step and status is Screening, this response has been prescreened, set the status to InProgress
        changeResponseStatus(response, ResponseStatus.InProgress);
    }
    else if (isWelcomeStep(userTestDoc, nextStepUUID)) {
        // if next step is welcome step, the user has passed screeners
        // mark test status as InProgress
        changeResponseStatus(response, ResponseStatus.InProgress);
    }
}
export function addPartnerData(mutable, { redirectUrl, source, }) {
    mutable.partnerData = {
        redirectUrl,
        source,
    };
}
