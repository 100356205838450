import { useCallback } from 'react';
import { useShowKeyboard } from './useShowKeyboard';
export function useFocusManager(itemsRef, onAddItem) {
    const showKeyboard = useShowKeyboard();
    const focusItem = useCallback((index) => {
        let attempts = 0;
        const maxAttempts = 10;
        const retryDelay = 50;
        const tryFocus = () => {
            var _a;
            if (attempts >= maxAttempts) {
                return;
            }
            const currentItem = (_a = itemsRef.current) === null || _a === void 0 ? void 0 : _a[index];
            if (currentItem) {
                currentItem.focus();
                currentItem.scrollIntoView({
                    behavior: 'smooth',
                    block: 'nearest',
                });
                // There's a problem when dragging cards into the category button
                // causing the new category title not to be selected, even if the DOM
                // element is defined. This issue happens more frequently on Safari
                // Mobile.
                //
                // Adding a timeout mitigates this issue, however, it breaks Cypress tests
                // (see https://github.com/cypress-io/cypress/issues/3817) as they fill
                // the input really fast, causing some letters to be excluded when typing,
                // typically the first one. As a second workaround, the timeout is set to 0
                // when running E2E tests.
                const TEXT_SELECTION_TIMEOUT = 'Cypress' in window ? 0 : 200;
                setTimeout(() => {
                    currentItem.select();
                }, TEXT_SELECTION_TIMEOUT);
            }
            else {
                attempts++;
                setTimeout(() => {
                    requestAnimationFrame(tryFocus);
                }, retryDelay);
            }
        };
        tryFocus();
    }, [itemsRef]);
    const addItem = useCallback(() => {
        showKeyboard();
        // We need a 60ms timeout here to workaround dnd-kit doing some cleanup
        // _after_ onDragEnd is called which causes the keyboard to be hidden on
        // Android. To avoid changing test code we just set it to 0 when running
        // in Cypress.
        const FOCUS_TIMEOUT = 'Cypress' in window ? 0 : 60;
        setTimeout(() => {
            if (itemsRef.current) {
                focusItem(itemsRef.current.length - 1);
            }
        }, FOCUS_TIMEOUT);
        return onAddItem();
    }, [onAddItem, focusItem, itemsRef, showKeyboard]);
    const handleKeyDown = (event, index, totalItems) => {
        switch (event.key) {
            case 'ArrowUp':
                event.preventDefault();
                focusItem((index - 1 + totalItems) % totalItems);
                break;
            case 'ArrowDown':
                event.preventDefault();
                focusItem((index + 1) % totalItems);
                break;
            case 'Enter':
                event.preventDefault();
                if (index === totalItems - 1) {
                    addItem();
                }
                else {
                    focusItem((index + 1) % totalItems);
                }
                break;
            default:
                break;
        }
    };
    return { handleKeyDown, addItem, focusItem };
}
