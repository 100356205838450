import { __rest } from "tslib";
import { jsx as _jsx } from "react/jsx-runtime";
import { observer } from 'mobx-react';
import * as React from 'react';
import { RatingStepUtils, } from '@marvelapp/user-test-creator';
import { RatingStatic } from '../../common/RatingStatic';
import { Answer } from './Answer';
export const RatingAnswer = observer(function RatingAnswer(_a) {
    var { step, answers } = _a, restProps = __rest(_a, ["step", "answers"]);
    let selectedRatingNumber = 0;
    if (answers !== undefined &&
        answers.length > 0 &&
        answers[0].value !== null) {
        selectedRatingNumber = answers[0].value;
    }
    const type = RatingStepUtils.getRatingType(step);
    const minRating = RatingStepUtils.getMinRating(step);
    const totalRatingNumber = RatingStepUtils.getMaxRating(step);
    return (_jsx(Answer, Object.assign({ step: step }, restProps, { children: _jsx(RatingStatic, { iconType: type, showFullScaleRating: true, selectedRatingNumber: selectedRatingNumber, totalRatingNumber: totalRatingNumber, ratingBadgeCopy: `${selectedRatingNumber}/${totalRatingNumber}`, minRating: minRating }) })));
});
