import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Checkbox, Flex, Heading, Row, Text } from '@marvelapp/ui';
const UserRoleOption = (props) => {
    const { description, label, lastChild, selected, value, onClick, isDisabled, url, } = props;
    const onClickHandler = (e) => {
        if (!isDisabled) {
            onClick(e);
        }
    };
    const linkProps = url
        ? {
            as: 'a',
            target: '_blank',
            display: 'flex',
            color: 'currentColor',
            href: url,
        }
        : {};
    return (_jsx(Row, Object.assign({ cursor: "pointer", "data-testid": "user-role-option", "data-value": value, isDisabled: isDisabled, isLastChild: lastChild, onClick: onClickHandler, padding: 3 }, linkProps, { children: _jsxs(Flex, { alignItems: "center", children: [_jsx(Checkbox, { isChecked: selected, isDisabled: isDisabled, mr: 3, size: 18, type: "hidden" }), _jsxs(Box, { children: [_jsx(Heading, { "data-testid": "user-role-option-label", fontSize: 2, fontWeight: 500, lineHeight: "20px", mb: "2px", children: label }), _jsx(Text, { color: "slate", "data-testid": "user-role-option-description", fontSize: 12, children: description })] })] }) })));
};
export default UserRoleOption;
