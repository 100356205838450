import { __awaiter } from "tslib";
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Track, createLocalAudioTrack, createLocalTracks, createLocalVideoTrack, } from 'livekit-client';
import { observer } from 'mobx-react';
import { useCallback, useEffect, useRef, useState, } from 'react';
import * as React from 'react';
import { Stack, cn } from '@marvelapp/ballpark-components';
import { deviceDetection } from '@marvelapp/core';
import { getVideoAndAudioDevices } from '@marvelapp/media-recording';
import { useWholeTestRecording } from '../../../WholeTestRecordingContext';
import { SelectWebcamMicrophoneSettings } from './SelectWebcamMicrophoneSettings';
export const videoSize = {
    width: 335,
    height: 250,
};
export const SelectWebcamMicrophone = observer(function SelectWebcamMicrophone({ permissions, send }) {
    const videoRef = useRef(null);
    const [audioStream, setAudioStream] = useState();
    const [videoDeviceId, setVideoDeviceId] = useState();
    const [audioDeviceId, setAudioDeviceId] = useState();
    const recording = useWholeTestRecording();
    const [videoDevices, setVideoDevices] = useState([]);
    const [audioDevices, setAudioDevices] = useState([]);
    const [hasGrantedPermissions, setHasGrantedPermissions] = useState(false);
    const hasBeenCalled = useRef(false);
    const videoTrackRef = useRef(null);
    const audioTrackRef = useRef(null);
    // kill stream and set up new stream when UI or permissions change
    const createOrUpdateTracks = useCallback((...args_1) => __awaiter(this, [...args_1], void 0, function* ({ newAudioDeviceId, newVideoDeviceId, } = {}) {
        try {
            // if device input has changed and there is an old track,
            // stop old track before creating new track
            if (newAudioDeviceId || newVideoDeviceId) {
                yield onDeviceChange({
                    videoTrackRef,
                    audioTrackRef,
                    audioDeviceId: newAudioDeviceId,
                    videoDeviceId: newVideoDeviceId,
                });
            }
            else {
                yield createInitialTracks({
                    videoTrackRef,
                    permissions,
                    audioTrackRef,
                });
            }
            if (videoTrackRef.current) {
                recording === null || recording === void 0 ? void 0 : recording.setCameraTrack(videoTrackRef.current);
                if (window.Cypress) {
                    window.__cameraStream = videoTrackRef.current.mediaStream;
                }
                setVideoDeviceId(videoTrackRef.current.mediaStreamTrack.getSettings().deviceId);
                // attach the video stream to the html video element
                if (videoRef.current)
                    videoTrackRef.current.attach(videoRef.current);
                videoTrackRef.current.mediaStreamTrack.addEventListener('ended', () => __awaiter(this, void 0, void 0, function* () {
                    send('RESET_USER_MEDIA');
                }));
            }
            if (audioTrackRef.current) {
                setAudioDeviceId(audioTrackRef.current.mediaStreamTrack.getSettings().deviceId);
                recording === null || recording === void 0 ? void 0 : recording.setAudioTrack(audioTrackRef.current);
                if (window.Cypress) {
                    window.__micStream = audioTrackRef.current.mediaStream;
                }
                // create stream from audio track for the volume indicator
                const audioStream = new MediaStream([
                    audioTrackRef.current.mediaStreamTrack,
                ]);
                if (audioStream) {
                    setAudioStream(audioStream);
                }
                audioTrackRef.current.mediaStreamTrack.addEventListener('ended', () => {
                    send('RESET_USER_MEDIA');
                });
            }
            setHasGrantedPermissions(true);
            send('USER_MEDIA_GRANTED');
        }
        catch (err) {
            // TODO: Use err.message to detect when the permissions are denied by the user
            // or the system (not granted on "System Preferences -> "Security & Privacy"):
            // message = "Permission denied by system" | "Permission denied"
            // https://marvelapp.atlassian.net/browse/UT-3744
            if (err instanceof Error && err.name === 'NotAllowedError') {
                send('USER_MEDIA_DENIED');
            }
            else {
                send('USER_MEDIA_NOT_SUPPORTED');
            }
        }
    }), [send, permissions, recording]);
    const onVideoDeviceChange = useCallback((event) => {
        const newVideoDeviceId = permissions.webcam
            ? event.target.value
            : undefined;
        createOrUpdateTracks({
            newVideoDeviceId,
        });
    }, [createOrUpdateTracks, permissions.webcam]);
    const onAudioDeviceChange = useCallback((event) => {
        const newAudioDeviceId = permissions.microphone
            ? event.target.value
            : undefined;
        createOrUpdateTracks({
            newAudioDeviceId,
        });
    }, [createOrUpdateTracks, permissions.microphone]);
    useEffect(() => {
        if (hasBeenCalled.current)
            return;
        createOrUpdateTracks();
        hasBeenCalled.current = true;
    }, [createOrUpdateTracks]);
    useEffect(() => {
        if (!hasGrantedPermissions)
            return;
        getVideoAndAudioDevices().then(({ video, audio }) => {
            if (permissions.webcam) {
                setVideoDevices(video);
            }
            if (permissions.microphone) {
                setAudioDevices(audio);
            }
        });
    }, [hasGrantedPermissions, permissions]);
    return (_jsxs(Stack, { width: "full", gap: "4", direction: "row", children: [permissions.webcam && (_jsx("div", { className: cn(cameraPreviewContainerClasses, deviceDetection.isMobile && 'w-full'), "data-testid": "camera-preview", children: _jsx("video", { ref: videoRef, className: cn('aspect-video h-full min-h-full object-cover', deviceDetection.isMobile ? 'w-full' : 'w-[140px]', !videoTrackRef.current ? 'hidden' : 'inline'), autoPlay: true, playsInline: true, muted: true }) })), deviceDetection.isDesktop && (_jsx(SelectWebcamMicrophoneSettings, { audioDevices: audioDevices, audioDeviceId: audioDeviceId, onAudioDeviceChange: onAudioDeviceChange, videoDeviceId: videoDeviceId, onVideoDeviceChange: onVideoDeviceChange, videoDevices: videoDevices, mediaStream: audioStream }))] }));
});
const cameraPreviewContainerClasses = [
    'overflow-hidden',
    'relative',
    // Setting the width to match the webcam preview
    'shrink-0',
    'grow-0',
    'rounded-md',
    // Setting a lil border over the video.
    // Alas, there's no better way of doing it with the way media elements behave
    'after:block',
    'after:content-[""]',
    'after:absolute',
    'after:inset-0',
    'after:ring-1',
    'after:ring-inset',
    'after:ring-gray-600/25',
    'after:rounded-md',
    // Making sure the overlay doesn't block any native behavior on it
    'after:pointer-events-none',
];
function onDeviceChange(_a) {
    return __awaiter(this, arguments, void 0, function* ({ videoTrackRef, audioDeviceId, videoDeviceId, audioTrackRef, }) {
        if (videoTrackRef.current && videoDeviceId) {
            const newTrack = yield createLocalVideoTrack({
                facingMode: 'user',
                deviceId: videoDeviceId,
            });
            // stop old track
            videoTrackRef.current.stop();
            // assign new track to ref
            videoTrackRef.current = newTrack;
        }
        if (audioTrackRef.current && audioDeviceId) {
            const newTrack = yield createLocalAudioTrack({
                echoCancellation: true,
                noiseSuppression: true,
                deviceId: audioDeviceId,
            });
            // stop old track
            audioTrackRef.current.stop();
            // assign new track to ref
            audioTrackRef.current = newTrack;
        }
    });
}
function createInitialTracks(_a) {
    return __awaiter(this, arguments, void 0, function* ({ permissions, videoTrackRef, audioTrackRef, }) {
        if (permissions.webcam && permissions.microphone) {
            const tracks = yield createLocalTracks({
                audio: {
                    echoCancellation: true,
                    noiseSuppression: true,
                },
                video: {
                    facingMode: 'user',
                },
            });
            [videoTrackRef.current] = tracks.filter((track) => track.kind === Track.Kind.Video);
            [audioTrackRef.current] = tracks.filter((track) => track.kind === Track.Kind.Audio);
        }
        else if (permissions.microphone) {
            audioTrackRef.current = yield createLocalAudioTrack({
                echoCancellation: true,
                noiseSuppression: true,
            });
        }
    });
}
