import { jsx as _jsx } from "react/jsx-runtime";
import { observer } from 'mobx-react';
import * as React from 'react';
import { useIntl } from 'react-intl';
import { InputWithLabel } from '@marvelapp/ballpark-components';
import { UI_STEPS_COPY } from '@marvelapp/ballpark-copy';
import { QuestionStepType } from '@marvelapp/user-test-creator';
import BaseQuestionEdit from '../Question/BaseQuestionEdit';
export default observer(function EmailEdit(props) {
    const intl = useIntl();
    return (_jsx(BaseQuestionEdit, Object.assign({}, props, { children: _jsx(InputWithLabel, { "data-testid": "email-editable-input", label: "Email address", placeholder: intl.formatMessage(UI_STEPS_COPY[QuestionStepType.Email].inputPlaceholder), readOnly: true, size: "xl", type: "email", width: "100%" }) })));
});
