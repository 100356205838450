import { jsxs as _jsxs, jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { round } from 'lodash-es';
import { observer } from 'mobx-react';
import * as React from 'react';
import { BtwText, Heading, Stack, Text } from '@marvelapp/ballpark-components';
import { ArrowRightIcon, QuestionMarkIcon } from '@marvelapp/ballpark-icons';
import { ContentLoader } from '@marvelapp/ui';
export const SummaryStats = observer(function StatsBox({ areStatsLoading, stats, title, helpLink, testId, }) {
    return (_jsxs(Stack, { className: "col-span-6 col-end-10 min-h-[44px] w-full rounded-[10px] border border-gray-200 bg-white shadow-sm", direction: "col", "data-testid": testId, children: [_jsxs(Stack, { direction: "row", justify: "between", className: "h-14 w-full items-center border-b border-solid border-gray-200 px-8", children: [_jsxs(BtwText, { weight: "medium", variant: "primary", size: "sm", children: [title, ' '] }), _jsx("a", { href: helpLink, target: "_blank", rel: "noreferrer", className: "inline-flex h-7 w-7 items-center justify-center rounded-md text-gray-900 hover:bg-gray-100", children: _jsx(QuestionMarkIcon, { className: "h-4 w-4" }) })] }), _jsx(Stack, { direction: "row", className: "w-full", align: "stretch", children: stats.map(({ name, testId, percentage, count }, index) => {
                    // last two items don't have connecting arrow icons
                    const showArrow = index < stats.length - 2;
                    return (_jsxs(_Fragment, { children: [_jsx(Stat, { areStatsLoading: areStatsLoading, statName: name, stat: { count, percentage }, testId: testId }), showArrow && (_jsx(Stack, { className: " flex-1", justify: "center", align: "center", children: _jsx(ArrowRightIcon, { className: "h-5 w-5" }) }))] }));
                }) })] }));
});
const Stat = observer(function Stat({ areStatsLoading = true, stat, statName, testId, }) {
    return (_jsx(Stack, { direction: "row", className: "flex-1 last:border-l last:border-solid last:border-gray-200", align: "stretch", children: _jsxs(Stack, { "data-testid": testId, direction: "col", justify: "center", className: "px-8 py-4", width: "full", children: [_jsx(Text, { fontSize: "s", marginBottom: "xs", className: "whitespace-nowrap", children: statName }), _jsxs(Stack, { direction: "row", align: "baseline", children: [_jsx(Heading, { fontSize: "xl", "data-testid": "stat-count", children: areStatsLoading ? (_jsx(ContentLoader, { borderRadius: "s", height: 11, width: 85, my: "m" })) : (stat === null || stat === void 0 ? void 0 : stat.count) }), (stat === null || stat === void 0 ? void 0 : stat.percentage) ? (_jsxs(Text, { className: "pl-2 text-base font-medium text-gray-500", "data-testid": "stat-percentage", children: [round(Number(stat.percentage), 1), "%"] })) : null] })] }) }));
});
