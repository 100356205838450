import { createProxyFromDoc } from '@ballpark/realtime-crdt';
import { groupBy, pickBy } from 'lodash-es';
import { RESPONSE_DOC_KEY, USERTEST_DOC_KEY } from '../constants';
import { getAnyMediaEnabled } from '../userTest/selectors';
import untypedValidateDescendant from './generated/descendant/validate';
import untypedValidateResponse from './generated/response/validate';
import untypedValidateRoomEvent from './generated/roomEvent/validate';
import untypedValidateUserTest from './generated/userTest/validate';
// call is used to force `this` as the AJV validation functions put data directly on the function as
// if it is an object too
const validateUserTest = ((userTest, id) => {
    if (userTest.uuid !== id) {
        return false;
    }
    const result = untypedValidateUserTest(userTest, undefined);
    validateUserTest.errors = untypedValidateUserTest.errors;
    return result;
});
const customResponseValidators = [validateBALL857];
const validateResponse = ((response, id) => {
    if (response.uuid !== id) {
        return false;
    }
    let validation = untypedValidateResponse(response, undefined);
    validateResponse.errors = untypedValidateResponse.errors;
    // eslint-disable-next-line no-restricted-syntax
    for (const validator of customResponseValidators) {
        const { isValid, errors } = validator(response);
        if (!isValid) {
            validation = validation && isValid;
            if (errors.length) {
                validateResponse.errors = validateResponse.errors || [];
                validateResponse.errors.push(...errors);
            }
        }
    }
    return validation;
});
const validateResponseDoc = ((doc, id) => {
    const response = createProxyFromDoc(doc, RESPONSE_DOC_KEY);
    const result = validateResponse(response, id);
    validateResponseDoc.errors = validateResponse.errors;
    return result;
});
const validateUserTestDoc = ((doc, id) => {
    const userTest = createProxyFromDoc(doc, USERTEST_DOC_KEY);
    const result = validateUserTest(userTest, id);
    validateUserTestDoc.errors = validateUserTest.errors;
    return result;
});
export { validateResponse, validateResponseDoc, validateUserTest, validateUserTestDoc, };
// https://linear.app/ballpark/issue/BALL-857
function validateBALL857(userTestResponse) {
    const { userTest, responses } = userTestResponse;
    // Responses with mediaSettingsStep defined and recording permissions disabled
    // should not have duplicate stepUUIDs
    if ((userTest === null || userTest === void 0 ? void 0 : userTest.mediaSettingsStep) && !getAnyMediaEnabled(userTest)) {
        const groupedResponses = groupBy(responses, 'stepUUID');
        const duplicatedUUIDs = Object.keys(pickBy(groupedResponses, (group) => group.length > 1));
        if (duplicatedUUIDs.length > 0) {
            return {
                isValid: false,
                errors: [
                    {
                        keyword: 'duplicateUUIDs',
                        instancePath: '',
                        schemaPath: '',
                        params: { uuid: duplicatedUUIDs },
                        message: `Duplicate stepUUIDs found: ${duplicatedUUIDs.join(', ')}`,
                    },
                ],
            };
        }
    }
    return { isValid: true, errors: [] };
}
const validateDescendant = ((text) => {
    const result = untypedValidateDescendant(text, undefined);
    validateDescendant.errors = untypedValidateDescendant.errors;
    return result;
});
const validateRichText = ((text) => {
    const result = text.every(validateDescendant);
    validateRichText.errors = validateDescendant.errors;
    return result;
});
const validateRoomEvent = ((event) => {
    const result = untypedValidateRoomEvent(event, undefined);
    validateRoomEvent.errors = untypedValidateRoomEvent.errors;
    return result;
});
export { validateDescendant, validateRoomEvent };
