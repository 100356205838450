import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { observer } from 'mobx-react';
import { FormattedMessage } from 'react-intl';
import { InfoBlock } from '@marvelapp/ballpark-components';
import styled from '@marvelapp/styled';
import Strong from '../../components/Strong';
const WebsiteTaskInfoBlock = observer(function WebsiteTaskInfoBlock({ url, children, }) {
    let link;
    // extract the hostname if the URL contains one otherwise fallback to whatever was passed
    try {
        ({ hostname: link } = new URL(url));
    }
    catch (e) {
        link = url;
    }
    return (_jsx(InfoBlock, { "data-testid": "website-task-link", kind: "external", hasIcon: false, maxWidth: 420, children: _jsxs(Message, { children: [_jsx(FormattedMessage, { id: 'EsVadw', defaultMessage: 'The link goes to {link}', values: {
                        link: _jsx(Strong, { children: link }),
                    } }), children] }) }));
});
export default WebsiteTaskInfoBlock;
const Message = styled.div `
  overflow: hidden;
  word-wrap: break-word;
`;
