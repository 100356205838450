export function generateWebVTT(chapters) {
    let webVTTContent = 'WEBVTT\n\n';
    chapters.forEach((chapter) => {
        const startTimeFormatted = formatTime(chapter.start);
        const endTimeFormatted = formatTime(chapter.end);
        webVTTContent += `${startTimeFormatted} --> ${endTimeFormatted}\n${chapter.title}\n\n`;
    });
    return webVTTContent;
}
export function formatTime(milliseconds) {
    const totalSeconds = Math.floor(milliseconds / 1000);
    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const seconds = totalSeconds % 60;
    const ms = milliseconds % 1000;
    return `${hours.toString().padStart(2, '0')}:${minutes
        .toString()
        .padStart(2, '0')}:${seconds.toString().padStart(2, '0')}.${ms
        .toString()
        .padStart(3, '0')}`;
}
// We check if there's a 't' param in the url to start the video
// at a specific time. And also if there's a stepUUID.
export function useGetStartTime(stepUUID) {
    try {
        const { location } = window;
        const query = new URLSearchParams(location.search);
        const isStepLinkedTo = !!stepUUID && query.get('s') === stepUUID;
        const isWholeTestPlayer = !stepUUID;
        return isStepLinkedTo || isWholeTestPlayer
            ? Number(query.get('t') || 0)
            : 0;
    }
    catch (e) {
        // This is a fallback in case the hook is used outside of a component
        // e.g. in Storybook
        // eslint-disable-next-line no-console
        console.error(e);
        return 0;
    }
}
