import { jsx as _jsx } from "react/jsx-runtime";
import { observer } from 'mobx-react';
import * as React from 'react';
import { useIntl } from 'react-intl';
import { UI_STEPS_COPY } from '@marvelapp/ballpark-copy';
import { FixedStep } from '@marvelapp/user-test-creator';
import { DecoratedSlide } from '../common/DecoratedSlide';
import SlideContent from '../common/SlideContent';
export default observer(function WelcomeEdit({ onMainCTAClick, }) {
    const intl = useIntl();
    return (_jsx(DecoratedSlide, { children: _jsx(SlideContent, { ctaOnClick: onMainCTAClick, ctaText: intl.formatMessage(UI_STEPS_COPY[FixedStep.Welcome].buttonText), maxHeadingLength: 512, maxParagraphLength: 512 }) }));
});
