import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { findIndex } from 'lodash-es';
import { useRef, useState } from 'react';
import { usePaginatedQuery } from '@marvelapp/ballpark-application';
import { useDebouncedState } from '@marvelapp/hooks';
import styled from '@marvelapp/styled';
import { Box } from '@marvelapp/ui';
import CollaboratorsTabs from './CollaboratorsTabs';
import PeopleList from './PeopleList';
import SearchPeople from './SearchPeople';
export default function ManagePeople({ canEdit, canSearch, inviteUsersUrl, isSearchDisabled, itemName, onAdd, renderPersonRowButtonComponent, renderSearchButton, getOwnerPk, queries, renderSearchCollaborators, searchQueries, showTabs = false, }) {
    const itemTypes = queries.map((item) => item.name);
    const [activeTab, setActiveTab] = useState(itemTypes[0]);
    const indexOfCurrentQuery = findIndex(queries, (query) => query.name === activeTab);
    const { dataConnection, variables, query, onRemove } = queries[indexOfCurrentQuery];
    const { data, edges: people, loadNextPage, hasNextPage, isLoading, isLoadingMore, } = usePaginatedQuery(query, {
        dataConnection,
        variables,
    });
    const [debouncedSearchTerm, setSearchTerm, latestSearchTerm] = useDebouncedState('');
    const ownerPk = getOwnerPk(data);
    const [scrollableContainerEl, setScrollableContainerEl] = useState(null);
    const inputRef = useRef();
    const inviteUrl = inviteUsersUrl;
    return (_jsxs(_Fragment, { children: [_jsx(Box, { marginBottom: 11, marginTop: 5, children: showTabs && (_jsx(CollaboratorsTabs, { activeTab: activeTab, onClick: setActiveTab, tabNames: itemTypes })) }), canSearch ? (_jsx(SearchPeople, { debouncedSearchTerm: debouncedSearchTerm, inputRef: inputRef, inviteUrl: inviteUrl, isSearchDisabled: isSearchDisabled, latestSearchTerm: latestSearchTerm, queryData: searchQueries[indexOfCurrentQuery], renderPersonRowButtonComponent: renderSearchButton, renderSearchCollaborators: renderSearchCollaborators, setSearchTerm: setSearchTerm })) : (_jsx(Box, { bg: "smoke", height: "1px", width: "100%" })), _jsx(ListWrap, { height: showTabs ? 473 : 420, opacity: debouncedSearchTerm ? 0.35 : 1, showBorder: people && people.length > 5, children: _jsx(Box, { "data-testid": "group-collaborator-list", height: "100%", overflow: "auto", ref: setScrollableContainerEl, children: _jsx(PeopleList, { hasNextPage: hasNextPage, inviteUsersUrl: inviteUsersUrl, isDisabled: !canEdit, isLoading: isLoading, isLoadingMore: isLoadingMore, itemName: itemName, loadNextPage: loadNextPage, onAdd: onAdd, onRemove: onRemove, ownerPk: ownerPk, people: people, renderPersonRowButtonComponent: renderPersonRowButtonComponent, scrollableContainerEl: scrollableContainerEl }) }) })] }));
}
const ListWrap = styled(Box) `
  transition: 300ms ease-in-out;
  position: relative;

  &::after {
    left: 0;
    bottom: 0;
    right: 0;
    display: block;
    position: absolute;
    content: '';
    height: 1px;
    background-color: ${(props) => props.showBorder ? 'rgba(34, 45, 57, 0.18)' : 'transparent'};
  }
`;
