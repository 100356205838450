import { jsx as _jsx } from "react/jsx-runtime";
import { useState } from 'react';
import { useCurrentSubscription } from '@marvelapp/ballpark-application';
import { useInviteUsers } from '@marvelapp/hooks';
import { Modal } from '../Modal';
import Page from '../Page';
import { showGenericServerError } from '../ToastContainer';
import InviteUsers from './InviteUsers';
export default function InviteUsersModal({ closeLink = '..', onInviteSuccess, upgradeBadge, }) {
    const [subscription] = useCurrentSubscription();
    const planLabel = subscription === null || subscription === void 0 ? void 0 : subscription.plan.label;
    const [isSendingInvite, setIsSendingInvite] = useState(false);
    const inviteUsers = useInviteUsers({
        setIsSendingInvite,
        onError: (retry) => showGenericServerError({ retry, closeOnClick: true }),
        planLabel,
    });
    return (_jsx(Page, { title: "Invite people", children: _jsx(Modal, { closeLink: closeLink, maxWidth: 600, children: _jsx(InviteUsers, { buttonIsLoading: isSendingInvite, onInviteUsers: ({ emails, role, setEmails, setShowFormError }) => {
                    setIsSendingInvite(true);
                    inviteUsers({
                        setEmails,
                        setShowFormError,
                        usersToInvite: { emails, role },
                        onInviteSuccess,
                    });
                }, upgradeBadge: upgradeBadge }) }) }));
}
export { showAddPeopleNotifications, sortFailedInvitesByReason, } from './inviteUsersNotifications';
