import { StepClass } from '../common';
import { generateUUID } from '../utils/generateUUID';
import { STEP_PREFIXES } from './constants';
import { isOfType } from './stepUtils';
export function create(partial = {}) {
    return Object.assign({ uuid: generateUUID(STEP_PREFIXES[StepClass.TaglineCopyTest]), className: StepClass.TaglineCopyTest, title: null, description: [], choices: [], choiceDefinitions: {}, conditions: {
            rules: [],
            ALWAYS: null,
        }, isRequired: false, revision: 0, randomize: false }, partial);
}
export function setChoiceHeading(mutable, index, value) {
    const choiceUUID = mutable.choices[index];
    mutable.choiceDefinitions[choiceUUID].heading = value;
}
export function setChoiceParagraph(mutable, index, value) {
    const choiceUUID = mutable.choices[index];
    mutable.choiceDefinitions[choiceUUID].paragraph = value;
}
export function getChoiceUUID(userTest, stepUUID, choiceValue) {
    const step = userTest.stepDefinitions[stepUUID];
    if (!isOfType(step, StepClass.TaglineCopyTest))
        return null;
    const choiceUUID = Object.keys(step.choiceDefinitions).find((key) => {
        var _a;
        return ((_a = step.choiceDefinitions[key]) === null || _a === void 0 ? void 0 : _a.heading) === choiceValue;
    });
    return choiceUUID || null;
}
export * from './choiceStepUtils';
