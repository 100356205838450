import { __rest } from "tslib";
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { runInAction } from 'mobx';
import { observer } from 'mobx-react';
import { useEffect } from 'react';
import { useAction, useFeatureFlag } from '@marvelapp/ballpark-application';
import { BtwText, BtwTooltip, Slider, Stack, } from '@marvelapp/ballpark-components';
import { pluralize } from '@marvelapp/core';
import { showErrorNotification } from '@marvelapp/ui-internal';
import { StudyTypeEnum } from '../../__generated__/queryTypes';
import { useRecruitmentState } from './RecruitmentContext';
import { StudyFocusOptions } from './StudyFocusOptions';
import { ColumnLayout, RecruitmentSection } from './common';
export const StudySetup = observer(function StudySetup() {
    const canSetDurationManually = useFeatureFlag('manual-recruitment-duration');
    const columns = [
        {
            title: 'Number of responses',
            component: _jsx(NumberOfResponsesSlider, {}),
        },
    ];
    if (canSetDurationManually) {
        columns.push({
            title: 'Study duration in minutes',
            component: _jsx(DurationSlider, {}),
        });
    }
    return (_jsxs(RecruitmentSection, { children: [_jsx(Stack, { className: "border-b border-gray-600/10 p-5", gap: "4", width: "full", children: _jsx(StudyFocusOptions, {}) }), _jsx(ColumnLayout, { className: "sm:flex-row", items: columns })] }));
});
const NumberOfResponsesSlider = observer(function NumberOfResponsesSlider() {
    const recruitmentState = useRecruitmentState();
    const { numberOfResponses, studyType } = recruitmentState;
    const isStandardStudy = studyType === StudyTypeEnum.SURVEY;
    // TODO: this is currently hardcoded on the frontend, but needs updating
    // on the backend
    const allowedMaxNoOfResponses = isStandardStudy ? 750 : 150;
    const labels = isStandardStudy
        ? [1, 75, 150, 225, 300, 375, 450, 525, 600, 675, 750]
        : [1, 25, 50, 75, 100, 125, 150];
    const handleNumberOfResponsesChange = useAction((value) => {
        [recruitmentState.numberOfResponses] = value;
    }, [recruitmentState]);
    useEffect(() => {
        runInAction(() => {
            // Maximum number of responses varies between providers. If we switch to a study type
            // with a lower allowed maximum number of responses than the currently selected number,
            // we need to update the number of responses to the new maximum allowed value
            if (numberOfResponses > allowedMaxNoOfResponses) {
                handleNumberOfResponsesChange([allowedMaxNoOfResponses]);
                showErrorNotification({
                    toastId: 'responses-updated',
                    content: `We've changed the number of responses to ${allowedMaxNoOfResponses}, which is the maximum allowed number for this panel`,
                });
            }
        });
    }, [
        recruitmentState,
        handleNumberOfResponsesChange,
        numberOfResponses,
        allowedMaxNoOfResponses,
    ]);
    return (_jsx(StudySetupSlider, { "data-testid": "numberOfResponses-slider", 
        // defaultValue={defaultValue}
        labels: labels, onValueChange: handleNumberOfResponsesChange, step: 1, tooltipText: "Use the slider to adjust the number of responses", value: numberOfResponses }));
});
const DurationSlider = observer(function DurationSlider() {
    const recruitmentState = useRecruitmentState();
    const { allowedDuration: { min: allowedMinDuration }, duration, studyType, } = recruitmentState;
    const step = studyType === StudyTypeEnum.SURVEY ? 1 : 5;
    const labels = [5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60];
    if (studyType === StudyTypeEnum.SURVEY) {
        labels.unshift(1);
    }
    const handleDurationChange = useAction((value) => {
        [recruitmentState.duration] = value;
    }, [recruitmentState]);
    useEffect(() => {
        runInAction(() => {
            // Minimum duration varies between providers. If we switch to a study type
            // with a higher minimum allowed duration than the currently selected one,
            // we need to update the duration to the new minimum allowed value
            if (duration < allowedMinDuration) {
                handleDurationChange([allowedMinDuration]);
                showErrorNotification({
                    toastId: 'duration-updated',
                    content: `We've changed your study duration to ${allowedMinDuration} ${pluralize(allowedMinDuration, 'min')}, which is the minimum required for this panel`,
                });
            }
        });
    }, [recruitmentState, duration, allowedMinDuration, handleDurationChange]);
    return (_jsx(StudySetupSlider, { "data-testid": "duration-slider", labels: labels, onValueChange: handleDurationChange, step: step, tooltipText: "Use the slider to adjust the duration", value: duration }));
});
const StudySetupSlider = observer(function StudySetupSlider(_a) {
    var { labels, onValueChange, step, tooltipText, value } = _a, rest = __rest(_a, ["labels", "onValueChange", "step", "tooltipText", "value"]);
    const minValue = labels[0];
    const maxValue = labels[labels.length - 1];
    return (_jsxs(Stack, Object.assign({ direction: "row", gap: "4" }, rest, { children: [_jsxs(Stack, { gap: "0.5", width: "full", className: "relative -top-1", children: [_jsx(Slider, { "data-testid": "slider", value: [value], min: minValue, max: maxValue, step: step, onValueChange: onValueChange }), _jsx(Stack, { justify: "between", width: "full", direction: "row", 
                        // Full width minus the width of the thumb for positioning the labels correctly
                        className: "relative w-[calc(100%_-_20px)]", children: labels.map((label) => {
                            // A bit of monkey brain js trickery to position labels properly for the sliders. :thub:
                            // We're using numberOfResponses array [1, 25, 50, 75, 100, 125, 150] for the examples below.
                            // Getting the range from the smallest number to the biggest
                            const fullRange = maxValue - minValue; // e.g., 150 - 1 = 149
                            // Getting the fractional increment of each step
                            const stepSize = 100 / fullRange; // e.g., 100 / 149 = 0.671140939597315 or 0.67%
                            // Getting the current step in the full range. So if the starting step was 1 and current step was 25,
                            // it would be 24 steps after the first one.
                            const currentStepInRange = label - minValue; // e.g., 25 - 1 = 24
                            // Calculating the number for absolute positioning from the left (relative to its container)
                            const position = currentStepInRange * stepSize; // 24 * 0.671140939597315 = 16.10738255033556 or 16.1074%
                            return (_jsx(BtwText, { align: "center", 
                                // The size of the thumb
                                className: "absolute top-0 w-5", size: "xxs", variant: "secondary", weight: "medium", style: {
                                    // And here we calculate the position of each item by moving it left, minus the offset. :thub:
                                    // The offset is basically a proportionally stepped increment of w-5, from 0.00 to 1.00,
                                    // based on the number of steps
                                    left: `${position}%`,
                                }, children: label }, label));
                        }) })] }), _jsx(BtwTooltip, { content: tooltipText, children: _jsx(Stack, { align: "center", className: "h-8 w-12 cursor-pointer rounded-md bg-gray-100 p-2", "data-testid": "selected-value", justify: "center", children: _jsx(BtwText, { className: "opacity-75", size: "13", variant: "primary", weight: "medium", children: value }) }) })] })));
});
