import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import * as React from 'react';
import theme from '@marvelapp/ballpark-theme';
import { Svg } from '@marvelapp/ui';
// eslint-disable-next-line mobx/missing-observer
export default function Star({ height = '42', rating, width = '42', }) {
    const gradientId = `fill-star-${rating}`;
    let fillRule = `url(#${gradientId})`;
    const percentage = rating * 100;
    if (percentage === 0) {
        fillRule = 'nonzero';
    }
    return (_jsxs(Svg, { height: height, viewBox: "0 0 42 42", width: width, children: [_jsx("defs", { children: _jsxs("linearGradient", { id: gradientId, children: [_jsx("stop", { offset: `${percentage}%`, stopColor: "currentColor" }), _jsx("stop", { offset: `${percentage}%`, stopColor: theme.colors.slate8 })] }) }), _jsx("path", { d: "m38.868 16.723c-.1528-.4753-.4386-.8983-.8244-1.2201s-.8558-.5292-1.3559-.5984l-9.1984-1.3716-4.0715-8.15881c-.2458-.41799-.5984-.76489-1.0226-1.00603-.4241-.24114-.9049-.36806-1.3943-.36806s-.9702.12692-1.3943.36806c-.4242.24114-.7768.58804-1.0226 1.00603l-4.1699 8.22781-9.09999 1.3026c-.4987.0708-.96737.2781-1.35293.5984s-.6726.7409-.8286 1.2141-.17473.9801-.05407 1.4632c.12067.4832.3759.9233.7368 1.2705l6.64229 6.4628-1.544 8.9719c-.08566.4899-.03068.9938.15871 1.4544.18939.4607.50561.8597.91285 1.1519s.88914.466 1.39124.5015 1.0042-.0685 1.4494-.3004l8.2317-4.2451 8.144 4.2353c.4453.2317.9473.3355 1.4492.2999.5019-.0357.9836-.2095 1.3907-.5016.4071-.2922.7232-.6912.9125-1.1517.1894-.4605.2444-.9641.1589-1.4539l-1.5165-9.0817 6.5902-6.3501c.3599-.3449.6145-.7827.735-1.2636.1205-.4808.102-.9854-.0535-1.4563z", fill: fillRule })] }));
}
