import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { gql, useMutation } from '@apollo/client';
import { observer } from 'mobx-react';
import { useCallback, useState } from 'react';
import * as React from 'react';
import { useRouteMatch } from 'react-router-dom';
import { BtwButton, BtwModal } from '@marvelapp/ballpark-components';
import { pluralize } from '@marvelapp/core';
import { showErrorNotification, showSuccessNotification, } from '@marvelapp/ui-internal';
import { GET_USER_TEST_BY_ID } from '../useQueryUserTest';
import { ConfirmDelete } from './ConfirmDelete';
const DELETE_RESULT = gql `
  mutation newDeleteTestResults($testId: Int!, $resultPks: [Int!]!) {
    deleteTestResults(input: { testPk: $testId, resultPks: $resultPks }) {
      deleted
      errors {
        code
        message
        resultPk
      }
    }
  }
`;
const toastId = 'deleteResult';
export function useDeleteResults({ onSuccess, selectedResultsPks, testId, }) {
    const match = useRouteMatch();
    const { id } = match.params;
    const [deleteResults, { loading }] = useMutation(DELETE_RESULT, {
        variables: { testId: testId || id, resultPks: selectedResultsPks },
        onError: () => {
            showErrorMessage(selectedResultsPks.length);
        },
        optimisticResponse: {
            deleteTestResults: {
                deleted: selectedResultsPks,
                errors: [],
                __typename: 'DeleteTestResults',
            },
        },
        onCompleted: (data) => {
            if (!(data === null || data === void 0 ? void 0 : data.deleteTestResults))
                return;
            const { errors, deleted } = data.deleteTestResults;
            if (errors.length) {
                showErrorMessage(errors.length);
            }
            if (deleted.length) {
                if (onSuccess)
                    onSuccess();
                showSuccessMessage(deleted.length);
            }
        },
    });
    return { deleteResults, loading };
}
export const DeleteResultsAction = observer(function DeleteResultsAction({ clearSelectedItems, selectedPks, }) {
    const [isOpen, setModalOpen] = useState(false);
    const match = useRouteMatch();
    const { id } = match.params;
    const selectedResultsPks = selectedPks.map((pk) => Number(pk));
    const { deleteResults } = useDeleteResults({ selectedResultsPks });
    const onDelete = useCallback(() => {
        setModalOpen(false);
        // The selection is cleared immediately, rather than on completed, because
        // for some unknown reason the backend takes up to 3 seconds to respond.
        // The action bar has no loading state, so the user would not get any feedback
        // that the action is being processed, and would probably click the button again.
        clearSelectedItems();
        const variables = {
            pk: Number(id),
        };
        deleteResults({
            update(cache, { data }) {
                updateCache({ cache, data, variables });
            },
        });
    }, [clearSelectedItems, deleteResults, id]);
    return (_jsxs(BtwModal.Root, { open: isOpen, onOpenChange: setModalOpen, children: [_jsx(BtwModal.Trigger, { asChild: true, children: _jsx(BtwButton, { "data-testid": "delete-test-results", variant: "danger", children: "Delete" }) }), _jsx(BtwModal.Content, { children: _jsx(ConfirmDelete, { onDelete: onDelete, selectedResultsCount: selectedPks.length }) })] }));
});
export function updateCache({ cache, data, variables }) {
    if (!(data === null || data === void 0 ? void 0 : data.deleteTestResults))
        return;
    const { errors, deleted } = data.deleteTestResults;
    if (errors.length) {
        showErrorNotification({
            autoClose: 5000,
            content: 'There was a problem removing these results',
            toastId: 'removeResultsError',
        });
        return;
    }
    removeResultFromCache(cache, variables, deleted);
}
export function removeResultFromCache(cache, variables, deleted) {
    var _a, _b, _c, _d, _e, _f;
    const cachedData = cache.readQuery({
        query: GET_USER_TEST_BY_ID,
        variables,
    });
    if (!cachedData)
        return;
    // TODO use a field policy here to automate the json parsing and stringifying
    // (and validation?)?
    const userTest = JSON.parse((_a = cachedData.userTest) === null || _a === void 0 ? void 0 : _a.resultsSortFilter);
    const remainingResponses = userTest.responses.filter((response) => !!(response === null || response === void 0 ? void 0 : response.pk) && !deleted.includes(response === null || response === void 0 ? void 0 : response.pk));
    const newData = {
        userTest: Object.assign(Object.assign({}, cachedData.userTest), { resultStats: Object.assign(Object.assign({}, (_b = cachedData.userTest) === null || _b === void 0 ? void 0 : _b.resultStats), { totalResults: ((_d = (_c = cachedData.userTest) === null || _c === void 0 ? void 0 : _c.resultStats) === null || _d === void 0 ? void 0 : _d.totalResults)
                    ? ((_f = (_e = cachedData.userTest) === null || _e === void 0 ? void 0 : _e.resultStats) === null || _f === void 0 ? void 0 : _f.totalResults) - deleted.length
                    : 0 }), resultsSortFilter: JSON.stringify(Object.assign(Object.assign({}, userTest), { responses: remainingResponses })) }),
    };
    cache.writeQuery({
        query: GET_USER_TEST_BY_ID,
        variables,
        data: newData,
    });
}
function showErrorMessage(errorsCount) {
    const errorMessage = errorsCount > 1
        ? `${errorsCount} results could not be deleted`
        : 'The result couldn’t be deleted';
    showErrorNotification({
        toastId,
        content: `${errorMessage}, please refresh and try again.`,
        options: { autoClose: 5000 },
    });
}
function showSuccessMessage(deletedResultsCount) {
    showSuccessNotification({
        content: `Successfully deleted ${deletedResultsCount} ${pluralize(deletedResultsCount, 'result')}`,
        toastId,
        options: { autoClose: 5000 },
    });
}
