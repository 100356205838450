import { __rest } from "tslib";
import { jsx as _jsx } from "react/jsx-runtime";
import { observer } from 'mobx-react';
import * as React from 'react';
import { formatDateAndTime } from '@marvelapp/core';
import { AnswerType, } from '@marvelapp/user-test-creator';
import Message from '../../../components/Message';
import { Answer } from './Answer';
export const QuestionAnswer = observer(function QuestionAnswer(_a) {
    var { answers } = _a, restProps = __rest(_a, ["answers"]);
    return (_jsx(Answer, Object.assign({}, restProps, { children: !!(answers === null || answers === void 0 ? void 0 : answers.length) && (_jsx(Message, { testId: "answer-value", children: answers[0].type === AnswerType.DateAnswer
                ? formatDateAndTime(new Date(answers[0].value)).date
                : answers[0].value })) })));
});
