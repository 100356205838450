import { __rest } from "tslib";
import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect, useRef, useState } from 'react';
import AutosizeInput from 'react-18-input-autosize';
import TagsInput from 'react-tagsinput';
import { TagsInputWrapper } from './styles';
export default function EmailsInput({ disabled, emails, placeholder, setEmails, setShowFormError, showFormError, }) {
    const inputRef = useRef(null);
    const [boxShadow, setBoxShadow] = useState('input.default');
    useEffect(() => {
        inputRef.current.focus();
    }, []);
    const setHoverShadow = () => {
        if (boxShadow === 'input.defaultFocus')
            return;
        if (boxShadow === 'input.default')
            setBoxShadow('input.defaultHover');
        if (boxShadow === 'input.defaultHover')
            setBoxShadow('input.default');
    };
    function autosizingRenderInput(props) {
        const { onChange, value } = props, other = __rest(props, ["onChange", "value"]);
        return (_jsx(AutosizeInput, Object.assign({ onChange: onChange, style: { minHeight: 60 }, type: "text", value: value }, other)));
    }
    /* eslint-disable jsx-a11y/mouse-events-have-key-events */
    return (_jsx("form", { children: _jsx(TagsInputWrapper, { bg: disabled ? 'snow' : 'white', boxShadow: disabled ? 'input.default' : boxShadow, onClick: (e) => {
                if (e.target.className !== 'react-tagsinput-tag') {
                    inputRef.current.focus();
                }
            }, onMouseOut: setHoverShadow, onMouseOver: setHoverShadow, children: _jsx(TagsInput, { addKeys: [9, 13, 32, 188], addOnBlur: true, addOnFocus: true, addOnPaste: true, inputProps: {
                    disabled,
                    onBlur: () => setBoxShadow('input.default'),
                    onChange: () => {
                        if (showFormError) {
                            setShowFormError(false);
                        }
                    },
                    onFocus: () => setBoxShadow('input.defaultFocus'),
                    placeholder: emails.length ? '' : placeholder,
                    'data-testid': 'invite-email-input',
                }, 
                // two onChange handlers: the one below is activated only when tags change and the one above is activated on each keystroke on input element
                onChange: (updatedEmails) => {
                    setEmails(updatedEmails);
                }, pasteSplit: (data) => pasteSplit(data), ref: inputRef, renderInput: autosizingRenderInput, tagProps: {
                    'data-testid': 'invite-email-tag',
                    className: 'react-tagsinput-tag',
                    classNameRemove: 'react-tagsinput-remove',
                }, value: emails }) }) }));
}
export function pasteSplit(data) {
    const separator = /,|\s+/;
    return data.split(separator).map((d) => d.trim());
}
