import { gql, useLazyQuery } from '@apollo/client';
const GET_ORDER_FOR_RESPONSE = gql `
  query getRecruitmentOrderForResponse($uuid: String) {
    recruitmentOrder(responseUUID: $uuid) {
      pk
      status
      reconciliation {
        reconciledAt
      }
    }
  }
`;
export function useGetOrderForResponse(responseUUID) {
    const [getOrderForResponse, { loading, data }] = useLazyQuery(GET_ORDER_FOR_RESPONSE, { variables: { uuid: responseUUID } });
    return {
        getOrderForResponse,
        isLoadingOrder: loading,
        order: data === null || data === void 0 ? void 0 : data.recruitmentOrder,
    };
}
