import { getDoc } from '@ballpark/realtime-crdt';
import { useAction, useGetUserTest } from '@marvelapp/ballpark-application';
import { useProjectContext } from './ProjectContext';
export function useCancelScreenerChanges(setIsOpen) {
    var _a;
    const userTest = useGetUserTest();
    const screenerSteps = (_a = userTest.screeners) === null || _a === void 0 ? void 0 : _a.steps;
    const projectContext = useProjectContext();
    return useAction((data) => {
        var _a;
        const doc = getDoc(userTest);
        const { path, key } = data;
        // TODO: This is a temporary workaround that will ultimately be implemented
        // in the proxy itself (cancelling part of a YJS transaction cancels all
        // of a transaction), however making the generic form of this that works
        // for any transaction is much harder than this band-aid.
        const isPartOfCancelledTransaction = !!((_a = doc._transaction) === null || _a === void 0 ? void 0 : _a.cancelled);
        const pathString = path.join('.');
        const pathIncludesScreenerStepUUID = screenerSteps === null || screenerSteps === void 0 ? void 0 : screenerSteps.some((stepUUID) => pathString.includes(stepUUID));
        // The data we get through the `onBeforeChange` callback gives us information
        // about the operation being performed on the proxy. In order to cancel a specific
        // operation, we need to check the path (and in some cases the key) of the operation
        // to confirm it is related to the screeners. If it is, we want to cancel it and show
        // the warning modal.
        if (isPartOfCancelledTransaction ||
            pathString.includes('screeners') ||
            (pathString === 'stepDefinitions' &&
                typeof key === 'string' &&
                (screenerSteps === null || screenerSteps === void 0 ? void 0 : screenerSteps.includes(key))) ||
            key === 'screeners' ||
            pathIncludesScreenerStepUUID) {
            const stepUUID = getScreenerStepUUIDForCancelledAction(path, userTest);
            if (stepUUID) {
                projectContext.cancelledOperationStepUUID = `${stepUUID}-${Date.now()}`;
            }
            else if (typeof key === 'string') {
                projectContext.cancelledOperationStepUUID = `${key}-${Date.now()}`;
            }
            data.cancel();
            // Adding a new screener to `stepDefinitions` (e.g. when cloning) is indistinguishable
            // from adding a multiple-choice step. As a result, we have no reliable way to
            // determine whether a change specifically targets a screener step.
            // As a hacky solution, we set `cancelled` to `true` on the transaction object to
            // indicate when a change is part of a transaction. Note that this approach relies
            // on the order of operations in the transactions.
            if (doc === null || doc === void 0 ? void 0 : doc._transaction) {
                doc._transaction.cancelled = true;
            }
            setIsOpen(true);
        }
    }, [projectContext, screenerSteps, setIsOpen, userTest]);
}
function getScreenerStepUUIDForCancelledAction(path, userTest) {
    var _a, _b;
    const pathString = path.join('.');
    if (pathString === 'screeners.decline') {
        return (_a = userTest.screeners) === null || _a === void 0 ? void 0 : _a.decline.uuid;
    }
    if (pathString === 'screeners.intro') {
        return (_b = userTest.screeners) === null || _b === void 0 ? void 0 : _b.intro.uuid;
    }
    return path.find((item) => item.includes('step_multipleChoice'));
}
