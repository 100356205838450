import { __awaiter } from "tslib";
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { observer } from 'mobx-react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import * as React from 'react';
import { Badge, BtwButton, BtwInput, Combobox, Stack, TinyButton, } from '@marvelapp/ballpark-components';
import { ArrowLeftIcon, ArrowRightIcon, PencilIcon, QuestionMarkIcon, WarningIcon, } from '@marvelapp/ballpark-icons';
import { UserTestStatus } from '@marvelapp/core';
import { useCachedState } from '@marvelapp/hooks';
import { StandardizationPausePromptModal } from './StandardizationPausePromptModal';
import { UNSORTED_CATEGORY_UUID, isStandardizeCategory } from './metricUtils';
import { useMetricsContext } from './metricsContext';
import { useCreateCategoryAlias } from './useEditCategoryAlias';
export const StandardizeCategories = observer(function StandardizeCategories() {
    const { userTestUUID, projectPk, step, categoryUUIDToCategoryMap, categoryAliases, userTestStatus, } = useMetricsContext();
    const categories = getNonStandardizedCategories({
        categoryUUIDToCategoryMap,
        categoryAliases,
    });
    const [isComboboxOpen, setIsComboboxOpen] = useState(false);
    const allCategoryNames = useMemo(() => new Set([
        ...Object.values(categoryUUIDToCategoryMap).map((cat) => cat.title),
        ...categoryAliases.map((alias) => alias.standardisedAs),
    ]), [categoryUUIDToCategoryMap, categoryAliases]);
    const [progressStep, setProgressStep] = useState('pick-categories');
    const [selectedCategories, setSelectedCategories] = useState([]);
    const [newCategoryName, setNewCategoryName] = useState('');
    const [showPromptOnUnpause, setShowPromptOnUnpause] = useCachedState(true, `categoryStandardizationPromptUserTest${projectPk}`);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const onModalOpenChange = useCallback((open) => {
        if (!open) {
            setIsComboboxOpen(true);
        }
        setIsModalOpen(open);
    }, []);
    const [errorMessage, setErrorMessage] = useState();
    const resetDefaultState = useCallback(() => {
        setSelectedCategories([]);
        setProgressStep('pick-categories');
        setNewCategoryName('');
        setIsComboboxOpen(false);
    }, []);
    const onPopoverOpenChange = useCallback((open) => {
        if (!open) {
            setTimeout(() => {
                resetDefaultState();
            }, 100);
        }
        else if (userTestStatus === UserTestStatus.Active &&
            showPromptOnUnpause) {
            setIsModalOpen(open);
        }
        else {
            setIsComboboxOpen(open);
        }
    }, [resetDefaultState, userTestStatus, showPromptOnUnpause]);
    const { createCategoryAlias, loading } = useCreateCategoryAlias(userTestUUID);
    const handleSaveCategory = useCallback(() => __awaiter(this, void 0, void 0, function* () {
        const categoryNames = selectedCategories.map((item) => item.title);
        if (allCategoryNames.has(newCategoryName) &&
            // A duplicate category name is allowed if the new category name is the
            // same as the selected categories, e.g. merge Animal and Pet into Animal
            !categoryNames.includes(newCategoryName)) {
            setErrorMessage('Category name already exists');
            return;
        }
        setErrorMessage(undefined);
        try {
            yield createCategoryAlias({
                variables: {
                    testPk: projectPk,
                    stepUuid: step.uuid,
                    categoryNames,
                    standardisedAs: newCategoryName,
                },
            });
        }
        finally {
            onPopoverOpenChange(false);
        }
    }), [
        onPopoverOpenChange,
        allCategoryNames,
        selectedCategories,
        createCategoryAlias,
        newCategoryName,
        projectPk,
        step.uuid,
    ]);
    const clearComboboxItems = useCallback(() => {
        setSelectedCategories([]);
    }, []);
    const updateProgressStep = useMemo(() => (step) => () => {
        setProgressStep(step);
    }, [setProgressStep]);
    const onPauseProject = useCallback(() => __awaiter(this, void 0, void 0, function* () {
        setIsComboboxOpen(true);
    }), []);
    // Because of a cmdk issue (https://github.com/pacocoursey/cmdk/issues/199),
    // multiple Combobox.Items with the same title can be highlighted at once.
    // Since we're using UUIDs as values but filtering on titles, we need to look up
    // the category title from the UUID to ensure correct filtering behavior.
    const filter = useCallback((value, search) => {
        const category = categoryUUIDToCategoryMap[value];
        if (!category)
            return 0;
        const normalizedTitle = category.title.toLowerCase().trim();
        const normalizedSearch = search.toLowerCase().trim();
        return normalizedTitle.includes(normalizedSearch) ? 1 : 0;
    }, [categoryUUIDToCategoryMap]);
    if (categories.length === 0) {
        return null;
    }
    return (_jsxs(_Fragment, { children: [_jsx(StandardizationPausePromptModal, { isOpen: isModalOpen, onOpenChange: onModalOpenChange, onPauseProject: onPauseProject, showPromptOnUnpause: showPromptOnUnpause, setShowPromptOnUnpause: setShowPromptOnUnpause }), _jsx("span", { "data-testid": "standardize-categories-combobox", children: _jsxs(Combobox.Root, { onClear: clearComboboxItems, onOpenChange: onPopoverOpenChange, open: isComboboxOpen, selected: selectedCategories.length, total: categories.length, variant: progressStep === 'pick-categories'
                        ? 'multi-select-with-cta'
                        : 'popover', children: [_jsx(Combobox.Trigger, { "data-testid": "standardize-categories-button", hasPlaceholder: false, leadingIcon: _jsx(PencilIcon, {}), size: "intermediate", trailingIcon: null, disabled: categories.length === 0, children: "Standardize categories" }), _jsxs(Combobox.Content, { filter: filter, helpButton: _jsx(TinyButton, { asChild: true, className: "pointer-events-auto", rounded: "full", standaloneIcon: _jsx(QuestionMarkIcon, {}), children: _jsx("a", { href: "https://help.ballparkhq.com/en/articles/9833680-analyzing-card-sort-studies#h_6c60510c2f", target: "_blank", rel: "noreferrer" }) }), className: "w-screen max-w-80", "data-testid": "standardize-categories-content", cta: progressStep === 'pick-categories' ? (_jsx(BtwButton, { "data-testid": "choose-name-button", size: "intermediate", trailingIcon: _jsx(ArrowRightIcon, {}), variant: "primary", width: "full", onClick: updateProgressStep('choose-name'), disabled: selectedCategories.length === 0, children: "Choose a name" })) : (_jsxs(Stack, { direction: "row", width: "full", justify: "between", children: [_jsx(BtwButton, { size: "intermediate", variant: "secondary", leadingIcon: _jsx(ArrowLeftIcon, {}), onClick: updateProgressStep('pick-categories'), children: "Go back" }), _jsx(BtwButton, { "data-testid": "save-category-button", size: "intermediate", variant: "primary", onClick: handleSaveCategory, isLoading: loading, disabled: !newCategoryName.trim(), children: "Save category" })] })), modal: true, placeholder: "Search items...", children: [progressStep === 'pick-categories' && (_jsx(PickCategoriesStep, { categories: categories, selectedCategories: selectedCategories, setSelectedCategories: setSelectedCategories })), progressStep === 'choose-name' && (_jsx(ChooseNameStep, { errorMessage: errorMessage, setErrorMessage: setErrorMessage, selectedCategories: selectedCategories, newCategoryName: newCategoryName, setNewCategoryName: setNewCategoryName }))] })] }) })] }));
});
const PickCategoriesStep = observer(function PickCategoriesStep({ categories, selectedCategories, setSelectedCategories, }) {
    const toggleSelection = useMemo(() => {
        return (item, isSelected) => {
            if (isSelected) {
                setSelectedCategories(selectedCategories.filter((selectedItem) => selectedItem.uuid !== item.uuid));
            }
            else {
                setSelectedCategories([...selectedCategories, item]);
            }
        };
    }, [selectedCategories, setSelectedCategories]);
    return (_jsxs(_Fragment, { children: [_jsx(Combobox.Empty, { icon: _jsx(WarningIcon, {}), children: "Nothing could be found..." }), categories.map((item) => {
                const isSelected = selectedCategories.some((selectedItem) => selectedItem.uuid === item.uuid);
                return (_jsx(Combobox.Item, { "data-testid": "standardize-categories-item", check: "checkbox", onSelect: () => toggleSelection(item, isSelected), selected: isSelected, value: item.uuid, children: _jsx("span", { "data-testid": "standardize-categories-item-title", children: item.title }) }, item.uuid));
            })] }));
});
const ChooseNameStep = observer(function ChooseNameStep({ selectedCategories, newCategoryName, setErrorMessage, errorMessage, setNewCategoryName, }) {
    const handleChange = useCallback((e) => {
        setNewCategoryName(e.target.value);
        setErrorMessage(undefined);
    }, [setNewCategoryName, setErrorMessage]);
    useEffect(() => {
        return () => {
            setErrorMessage(undefined);
        };
    }, [setErrorMessage]);
    return (_jsxs(Stack, { gap: "4", width: "full", className: "p-2.5", children: [_jsxs(BtwInput.Root, { width: "full", children: [_jsx(BtwInput.Label, { htmlFor: "name", children: "New category name" }), _jsx(BtwInput.Field, { autoComplete: "off", "data-1p-ignore": true, "data-testid": "new-category-name-input", value: newCategoryName, placeholder: "Enter category name", minLength: 1, id: "name", onChange: handleChange }), errorMessage && _jsx(BtwInput.Error, { children: errorMessage })] }), _jsxs(BtwInput.Root, { width: "full", children: [_jsx(BtwInput.Label, { htmlFor: "combining", children: "Combining" }), _jsx(Stack, { className: "min-w-0", direction: "row", gap: "1.5", id: "combining", wrap: "wrap", children: selectedCategories.map((item) => (_jsx(Badge, { className: "max-w-full", title: item.title, children: _jsx("span", { className: "truncate", children: item.title }) }, item.title))) })] })] }));
});
function getNonStandardizedCategories({ categoryUUIDToCategoryMap, categoryAliases, }) {
    const aliasedCategories = new Set(categoryAliases.flatMap((alias) => alias.includedCategoryNames));
    return Object.values(categoryUUIDToCategoryMap)
        .filter((cat) => cat.uuid !== UNSORTED_CATEGORY_UUID &&
        !aliasedCategories.has(cat.title) &&
        !isStandardizeCategory(cat))
        .sort((cat1, cat2) => cat1.title.localeCompare(cat2.title));
}
