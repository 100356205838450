import { useSensor, useSensors, } from '@dnd-kit/core';
import { arraySwap, sortableKeyboardCoordinates } from '@dnd-kit/sortable';
import { useCallback, useState } from 'react';
import { CustomKeyboardSensor, CustomPointerSensor, isDraggableElement, } from '../utils/dndUtils';
export function useDragging({ pointerSensorOptions, } = {}) {
    const [draggingItem, setDraggingItem] = useState(null);
    const handleDragStart = useCallback((event) => {
        const { active } = event;
        setDraggingItem(active.id);
    }, []);
    const handleDragEnd = useCallback(({ event, items, parentItem, onDragEnd: parentOnDragEnd, reorderFn, }) => {
        const { active, over } = event;
        if (active.id === (over === null || over === void 0 ? void 0 : over.id))
            return null;
        const oldIndex = items.indexOf(active.id);
        const newIndex = over ? items.indexOf(over.id) : 0;
        const moved = reorderFn(parentItem, oldIndex, newIndex);
        if (parentOnDragEnd && moved) {
            parentOnDragEnd(moved);
        }
        setDraggingItem(null);
        return arraySwap(items, oldIndex, newIndex);
    }, []);
    const sensors = useSensors(useSensor(CustomPointerSensor, Object.assign({ isDraggableElement }, pointerSensorOptions)), useSensor(CustomKeyboardSensor, {
        isDraggableElement,
        coordinateGetter: sortableKeyboardCoordinates,
    }));
    return {
        draggingItem,
        handleDragEnd,
        handleDragStart,
        sensors,
    };
}
