import { __rest } from "tslib";
import { jsx as _jsx } from "react/jsx-runtime";
import { observer } from 'mobx-react';
import * as React from 'react';
import { CardSortingResponse } from '../../../slides/CardSorting/response/CardSortingResponse';
import { Answer } from './Answer';
export const CardSortingAnswer = observer(function CardSortingAnswer(_a) {
    var { step, stepResponse } = _a, restProps = __rest(_a, ["step", "stepResponse"]);
    return (_jsx(Answer, Object.assign({ step: step }, restProps, { children: stepResponse && (_jsx(CardSortingResponse, { step: step, stepResponse: stepResponse })) })));
});
