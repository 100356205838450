import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { range } from 'lodash-es';
import { observer } from 'mobx-react';
import * as React from 'react';
import { Badge } from '@marvelapp/ballpark-components';
import styled from '@marvelapp/styled';
import { Box } from '@marvelapp/ui';
import { RatingType } from '@marvelapp/user-test-creator';
import { RatingIcon, getRatingTypeColor, } from '../../components/PreferenceRateItem';
import { getIconFillInfo } from '../../utils/getIconFillInfo';
export const RatingStatic = observer(function RatingStatic({ iconType, ratingBadgeCopy, selectedRatingNumber, showFullScaleRating, totalRatingNumber, minRating, }) {
    const isNumerical = iconType === RatingType.Numerical;
    const selectedColors = getRatingTypeColor({
        type: iconType,
        canEdit: false,
        selected: true,
    });
    const roundedSelectedRating = Math.floor(selectedRatingNumber);
    const { decimalPart, emptyIcons, filledIcons, hasDecimals } = getIconFillInfo(selectedRatingNumber, totalRatingNumber);
    // This draws the scale by flooring a rating (i.e 4.2 -> 4), paint the first full digits, draw the extra
    // decimal ( 0.2 ) which is retrieved from the rating and then drawing the remainder (i.e 6 if max is 10)
    return (_jsxs(Body, { children: [showFullScaleRating && (_jsxs(_Fragment, { children: [range(minRating, filledIcons + 1).map((icon) => (_jsx(Box, { color: selectedColors.color, "data-testid": "rating-item", "data-is-filled": "true", children: _jsx(RatingIcon, { index: icon, iconType: iconType, rating: 1 }) }, icon))), hasDecimals && (_jsx(Box, { color: selectedColors.color, "data-testid": "rating-item", "data-is-filled": "true", children: _jsx(RatingIcon, { iconType: iconType, index: roundedSelectedRating, rating: decimalPart }) })), range(totalRatingNumber - (emptyIcons - 1), totalRatingNumber + 1).map((icon) => (_jsx(Box, { color: "slate8", "data-testid": "rating-item", "data-is-filled": "false", children: _jsx(RatingIcon, { index: icon, iconType: iconType, rating: 0 }) }, icon)))] })), _jsx(Badge, { "data-testid": "rating-value", className: `${!isNumerical && 'ml-2'}`, children: ratingBadgeCopy })] }));
});
const Body = styled(Box).attrs({
    bg: 'slate2',
    borderRadius: 'l',
    padding: 'l',
    transition: 'smooth.fast',
}) `
  display: flex;
  align-items: center;
  justify-content: center;
  user-select: none;
`;
