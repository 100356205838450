import { jsx as _jsx } from "react/jsx-runtime";
import { createContext, useContext } from 'react';
const MediaSyncContext = createContext({
    mediaRemotes: new Set(),
});
export const MediaSyncProvider = ({ children }) => {
    return (_jsx(MediaSyncContext.Provider, { value: { mediaRemotes: new Set() }, children: children }));
};
export function useMediaSyncContext() {
    return useContext(MediaSyncContext);
}
