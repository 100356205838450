import { __rest } from "tslib";
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { CarouselLayout, FocusLayout, FocusLayoutContainer, LiveKitRoom, MediaDeviceSelect, ParticipantTile, RoomAudioRenderer, useRoomContext, useTrackToggle, useTracks, } from '@livekit/components-react';
import { Track } from 'livekit-client';
import { observer } from 'mobx-react';
import { useCallback, useState } from 'react';
import { useConfig } from '@marvelapp/ballpark-application';
import { BtwButton, BtwModal, BtwPopover, BtwTooltip, Stack, cn, } from '@marvelapp/ballpark-components';
import { ChevronBottomSmallIcon, MicrophoneDisabledIcon, MicrophoneIcon, WebcamDisabledIcon, WebcamIcon, } from '@marvelapp/ballpark-icons';
// Using a plain css file to be able to select and override livekit things better
import { LiveKitEgressTypeEnum } from '@marvelapp/media-recording/src/__generated__/queryTypes';
import { useStartEgress, useStopEgress, } from '@marvelapp/media-recording/src/hooks/useMediaRecorder';
import { SessionEnded as SessionEndedModal } from '../../components/Record/SessionEnded';
import { LiveSessionStatus, useLiveSessionStatus, } from '../../components/Record/useLiveSessionStatus';
import './LiveRoom.css';
export const ModeratorRoom = observer(function ModeratorRoom({ token, }) {
    const config = useConfig();
    const [status, setStatus] = useState();
    return (_jsxs(LiveKitRoom, { video: true, audio: true, token: token, serverUrl: config.livekit.url, className: "flex !h-screen flex-col gap-3 !bg-gray-700 p-3 font-sans", children: [_jsx(TrackSessionStatus, { setStatus: setStatus }), _jsx(MyVideoConference, {}), _jsx(RoomAudioRenderer, {}), _jsxs(Stack, { direction: "row", gap: "2", align: "center", justify: "center", children: [_jsx(CameraButton, {}), _jsx(MicButton, {}), status && _jsx(SessionControlButton, { status: status })] }), status === LiveSessionStatus.Ended && _jsx(SessionEnded, {})] }));
});
export const ObserverRoom = observer(function ObserverRoom({ token, }) {
    const config = useConfig();
    const [status, setStatus] = useState();
    return (_jsxs(LiveKitRoom, { video: false, audio: false, token: token, serverUrl: config.livekit.url, className: "flex !h-screen flex-col gap-3 !bg-gray-700 p-3 font-sans", children: [_jsx(TrackSessionStatus, { setStatus: setStatus }), _jsx(MyVideoConference, {}), _jsx(RoomAudioRenderer, {}), status === LiveSessionStatus.Ended && _jsx(SessionEnded, {})] }));
});
const MyVideoConference = observer(function MyVideoConference() {
    // `useTracks` returns all camera and screen share tracks. If a user
    // joins without a published camera track, a placeholder track is returned.
    const cameraTracks = useTracks([{ source: Track.Source.Camera, withPlaceholder: true }], { onlySubscribed: false });
    const screenTracks = useTracks([{ source: Track.Source.ScreenShare, withPlaceholder: true }], { onlySubscribed: false });
    return (
    // 100vh - controls bar (32px) + top and bottom padding (12px*2) + 12px gap between the video and controls
    _jsxs(FocusLayoutContainer, { className: "!flex h-[calc(100vh_-_68px)] !gap-3", children: [screenTracks.length && (_jsx(FocusLayout, { trackRef: screenTracks[0], className: "w-full flex-1 bg-gray-950 [&>video]:!bg-gray-950" })), _jsx(CarouselLayout, { tracks: cameraTracks, orientation: "vertical", className: "flex w-full max-w-fit flex-1 flex-col-reverse justify-end gap-2", children: _jsx(ParticipantTile, { className: "w-48" }) })] }));
});
const TrackSessionStatus = observer(function TrackSessionStatus({ setStatus, }) {
    useLiveSessionStatus(setStatus);
    return null;
});
const SessionControlButton = observer(function SessionControlButton({ status, }) {
    const room = useRoomContext();
    const [startEgress, { loading: isEgressStarting }] = useStartEgress();
    const startSession = useCallback(() => {
        startEgress({
            variables: {
                input: {
                    roomName: room.name,
                    egressType: LiveKitEgressTypeEnum.ROOM_COMPOSITE_EGRESS,
                },
            },
        });
    }, [room.name, startEgress]);
    const [stopEgress, { loading: isEgressEnding }] = useStopEgress(room, (data) => {
        if (data === null || data === void 0 ? void 0 : data.stopVideoEgress.error) {
            console.error(data === null || data === void 0 ? void 0 : data.stopVideoEgress.error);
        }
    });
    const endSession = useCallback(() => {
        stopEgress();
    }, [stopEgress]);
    if (status === LiveSessionStatus.Ended) {
        return null;
    }
    return (_jsxs(_Fragment, { children: [status !== LiveSessionStatus.Recording && (_jsx(BtwButton, { variant: "secondary", size: "intermediate", onClick: startSession, isLoading: isEgressStarting, children: "Start session" })), status === LiveSessionStatus.Recording && (_jsx(BtwButton, { variant: "secondary", size: "intermediate", onClick: endSession, isLoading: isEgressEnding, children: "End session" }))] }));
});
const CameraButton = observer(function CameraButton() {
    const { buttonProps, enabled } = useTrackToggle({
        source: Track.Source.Camera,
    });
    // Omit the className from buttonProps
    const { className } = buttonProps, otherButtonProps = __rest(buttonProps, ["className"]);
    return (_jsxs(Stack, { direction: "row", className: "ease-smooth transition-transform duration-300 hover:-translate-y-px has-[:active]:translate-y-px", children: [_jsx(BtwTooltip, { content: enabled ? 'Turn off camera' : 'Turn on camera', children: _jsx(BtwButton, Object.assign({}, otherButtonProps, { standaloneIcon: enabled ? _jsx(WebcamIcon, {}) : _jsx(WebcamDisabledIcon, {}), variant: "secondary", size: "intermediate", className: cn('peer rounded-br-none rounded-tr-none hover:translate-y-0 active:translate-y-0 data-[lk-enabled=false]:bg-white/50') })) }), _jsxs(BtwPopover.Root, { children: [_jsx(BtwPopover.Trigger, { asChild: true, children: _jsx(BtwButton, { standaloneIcon: _jsx(ChevronBottomSmallIcon, {}), variant: "secondary", size: "intermediate", className: cn('w-7 rounded-bl-none rounded-tl-none hover:translate-y-0 active:translate-y-0 peer-data-[lk-enabled=false]:bg-white/50') }) }), _jsx(BtwPopover.Content, { children: _jsx(MediaDeviceSelect, { kind: "videoinput" }) })] })] }));
});
const MicButton = observer(function MicButton() {
    const { buttonProps, enabled } = useTrackToggle({
        source: Track.Source.Microphone,
    });
    // Omit the className from buttonProps
    const { className } = buttonProps, otherButtonProps = __rest(buttonProps, ["className"]);
    return (_jsxs(Stack, { direction: "row", className: "ease-smooth transition-transform duration-300 hover:-translate-y-px has-[:active]:translate-y-px", children: [_jsx(BtwTooltip, { content: enabled ? 'Mute microphone' : 'Unmute microphone', children: _jsx(BtwButton, Object.assign({}, otherButtonProps, { standaloneIcon: enabled ? _jsx(MicrophoneIcon, {}) : _jsx(MicrophoneDisabledIcon, {}), variant: "secondary", size: "intermediate", className: cn('peer rounded-br-none rounded-tr-none hover:translate-y-0 active:translate-y-0 data-[lk-enabled=false]:bg-white/50') })) }), _jsxs(BtwPopover.Root, { children: [_jsx(BtwPopover.Trigger, { asChild: true, children: _jsx(BtwButton, { standaloneIcon: _jsx(ChevronBottomSmallIcon, {}), variant: "secondary", size: "intermediate", className: "w-7 rounded-bl-none rounded-tl-none hover:translate-y-0 active:translate-y-0 peer-data-[lk-enabled=false]:bg-white/50" }) }), _jsx(BtwPopover.Content, { children: _jsx(MediaDeviceSelect, { kind: "audioinput" }) })] })] }));
});
const SessionEnded = observer(function SessionEnded() {
    return (_jsx(BtwModal.Root, { open: true, children: _jsx(BtwModal.Content, { position: "full", size: "full", children: _jsx(BtwModal.Body, { className: "lk-waiting-room flex h-screen w-screen flex-col items-center justify-center gap-14 p-10", children: _jsx(SessionEndedModal, {}) }) }) }));
});
