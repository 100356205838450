export var PusherEvent;
(function (PusherEvent) {
    PusherEvent["ContentCreated"] = "event_content_created";
    PusherEvent["ContentUpdated"] = "event_content_updated";
    PusherEvent["Sequence"] = "event_sequence";
    PusherEvent["ContentReplaceFail"] = "event_content_replace_fail";
    PusherEvent["Hotspot"] = "event_hotspot";
    PusherEvent["Comment"] = "event_comment";
    PusherEvent["UserTestAggregated"] = "event_usertest_aggregated";
})(PusherEvent || (PusherEvent = {}));
