import { useMemo } from 'react';
import { mapFilterNullish } from '@marvelapp/core';
export function useEventQueryDataToHeatmapData(eventQueryData, pointValue = 30) {
    var _a, _b, _c, _d, _e, _f, _g;
    const screen = (_c = (_b = (_a = eventQueryData === null || eventQueryData === void 0 ? void 0 : eventQueryData.project) === null || _a === void 0 ? void 0 : _a.screens) === null || _b === void 0 ? void 0 : _b.edges[0]) === null || _c === void 0 ? void 0 : _c.node;
    const imageUrl = (_d = screen === null || screen === void 0 ? void 0 : screen.content) === null || _d === void 0 ? void 0 : _d.url;
    const screenDisplayName = screen === null || screen === void 0 ? void 0 : screen.displayName;
    const eventNodes = (_e = screen === null || screen === void 0 ? void 0 : screen.events) === null || _e === void 0 ? void 0 : _e.edges;
    const originalWidth = ((_f = screen === null || screen === void 0 ? void 0 : screen.content) === null || _f === void 0 ? void 0 : _f.width) || 0;
    const originalHeight = ((_g = screen === null || screen === void 0 ? void 0 : screen.content) === null || _g === void 0 ? void 0 : _g.height) || 0;
    const coordinates = useMemo(() => {
        return eventNodes
            ? mapFilterNullish(Object.values(eventNodes), (edge) => {
                var _a, _b, _c, _d;
                const x = (_b = (_a = edge === null || edge === void 0 ? void 0 : edge.node) === null || _a === void 0 ? void 0 : _a.trigger) === null || _b === void 0 ? void 0 : _b.coordX;
                const y = (_d = (_c = edge === null || edge === void 0 ? void 0 : edge.node) === null || _c === void 0 ? void 0 : _c.trigger) === null || _d === void 0 ? void 0 : _d.coordY;
                if (x === null || x === undefined || y === null || y === undefined) {
                    return undefined;
                }
                return {
                    x,
                    y,
                    value: pointValue,
                };
            })
            : [];
    }, [eventNodes, pointValue]);
    return {
        imageUrl: imageUrl || undefined,
        screenDisplayName,
        coordinates,
        originalWidth,
        originalHeight,
    };
}
