export function getMainLinks({ permissions, hasLiveSessions, }) {
    return [
        {
            name: 'Projects',
            to: '/projects',
            canView: true,
        },
        {
            name: 'Archive',
            to: '/archive',
            canView: true,
        },
        {
            name: 'Live sessions',
            to: '/sessions',
            canView: hasLiveSessions,
        },
        {
            name: 'Team',
            to: '/company/people',
            canView: permissions.canViewPeople(),
        },
        {
            name: 'Team',
            to: '/company/guest',
            canView: !permissions.canViewPeople(),
        },
        {
            name: 'Templates',
            to: '/templates',
            canView: true,
        },
    ];
}
export const getUserBlockLinks = () => {
    return [
        {
            name: 'My account',
            to: '/user/account/personal',
        },
    ];
};
