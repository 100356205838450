import { useMutation, } from '@apollo/client';
import { useHistory } from 'react-router-dom';
import { heapTrackEvent } from '@marvelapp/core';
import { CREATE_MULTISTEP_USER_TEST } from './graphql/createMultistepUserTest';
import GET_PROJECT_COUNT from './graphql/getProjectCount';
export default function useCreateUserTest({ projectToCloneUUID, showError, }) {
    const history = useHistory();
    const [createProject, { data, loading }] = useMutation(CREATE_MULTISTEP_USER_TEST, {
        onCompleted: (mutationResponse) => {
            if (!mutationResponse.createMultistepUserTest.ok) {
                showError();
                return;
            }
            const { pk: projectPk } = mutationResponse.createMultistepUserTest.project;
            const cloneFromParam = projectToCloneUUID
                ? `?from=${projectToCloneUUID}`
                : '';
            const projectURL = `/project/${projectPk}${cloneFromParam}`;
            heapTrackEvent('Prototype Project Create', {
                createdInFolder: false,
            });
            history.replace(projectURL);
        },
        onError: (error) => {
            if (error.message === 'GraphQL error: User has hit their project quota') {
                history.replace('/plans?intent=reached-project-limit');
            }
            showError();
        },
        refetchQueries: [{ query: GET_PROJECT_COUNT }],
    });
    return {
        createProject,
        data,
        loading,
        hasSucceeded: data === null || data === void 0 ? void 0 : data.createMultistepUserTest.ok,
    };
}
