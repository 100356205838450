import { jsx as _jsx } from "react/jsx-runtime";
import { observer } from 'mobx-react';
import { useFeatureFlag } from '@marvelapp/ballpark-application';
import { StepClass } from '@marvelapp/user-test-creator';
import SummaryQuestion from './SummaryQuestion';
import { stepSupportsOpenAI } from './utils';
const OpenAIStepCard = observer(function OpenAIStepCard({ step, stepStats, }) {
    const isOpenAIEnabled = useFeatureFlag('ballpark-open-ai-summary');
    if (!isOpenAIEnabled || !stepStats)
        return null;
    const stats = stepStats.find((stepStat) => (stepStat === null || stepStat === void 0 ? void 0 : stepStat.stepResponseUuid) === step.uuid);
    if (!stats)
        return null;
    if (!stepSupportsOpenAI(step))
        return null;
    switch (step.className) {
        case StepClass.Question: {
            return (_jsx(SummaryQuestion, { testId: "openAI-box", step: step, stats: stats }));
        }
        default:
            return null;
    }
});
export default OpenAIStepCard;
