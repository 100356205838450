import { gql, useQuery } from '@apollo/client';
import { isEmpty } from 'lodash-es';
import { runInAction } from 'mobx';
import { useEffect, useMemo } from 'react';
import { validateUserTestResponses, } from '@marvelapp/result-sql-mapper-schema';
import { StepClass } from '@marvelapp/user-test-creator';
import { useResponsesContext } from './ResponsesContext';
import { formatAnswers } from './formatAnswers';
export const GET_USER_TEST_BY_ID = gql `
  query newGetUserTestById($pk: Int, $queryData: JSONString) {
    userTest(pk: $pk) {
      pk
      uuid
      resultsSortFilter(filters: { queryData: $queryData })
        @connection(key: "testResults")
      resultStats {
        allResultsCount
        averageAnsweredRate
        completionRate
        totalResults
        totalCompletedResults
        testPk
      }
      prototype {
        pk
        name
        isArchived
        isLocked
        passwordProtected
      }
    }
  }
`;
export function useQueryUserTest(pk, dataQuery) {
    var _a, _b;
    const queryResult = useQuery(GET_USER_TEST_BY_ID, {
        variables: {
            pk,
            queryData: JSON.stringify(dataQuery),
        },
        skip: !dataQuery,
        errorPolicy: 'all',
    });
    const context = useResponsesContext();
    const projectData = (_b = (_a = queryResult.data) === null || _a === void 0 ? void 0 : _a.userTest) !== null && _b !== void 0 ? _b : null;
    const userTestResults = useMemo(() => {
        if (isEmpty(projectData) || !(projectData === null || projectData === void 0 ? void 0 : projectData.resultsSortFilter)) {
            return [];
        }
        const resultSQLMapper = JSON.parse(projectData.resultsSortFilter);
        if (!validateUserTestResponses(resultSQLMapper)) {
            console.error('Invalid response data', validateUserTestResponses.errors);
            throw new Error('Invalid response data');
        }
        return resultSQLMapper.responses.map(formatResult);
    }, [projectData]);
    // if we re-introduce pagination, we need to get this from the backend
    const totalFilteredResults = userTestResults.length;
    useEffect(() => {
        runInAction(() => {
            context.totalFilteredResults = totalFilteredResults;
        });
    }, [context, totalFilteredResults]);
    return Object.assign({ projectData,
        userTestResults }, queryResult);
}
function formatDeviceData(result) {
    if (!(result === null || result === void 0 ? void 0 : result.device)) {
        return {
            platform: 'UNKNOWN',
            device: 'UNKNOWN',
        };
    }
    const { make, model, operatingSystem } = result.device;
    let platform = '';
    let device = '';
    if (make === 'Web') {
        platform = 'Desktop';
    }
    else if (make === 'Tablet') {
        platform = 'Tablet';
    }
    else {
        platform = 'Mobile';
    }
    if (operatingSystem && operatingSystem.includes('Android')) {
        device = 'Android';
    }
    else {
        device = model || 'Unknown';
    }
    return {
        platform,
        device,
    };
}
function formatResult(result) {
    return {
        pk: result.pk,
        uuid: result.responseUUID,
        status: result.status,
        submittedAt: result.submittedAt,
        device: formatDeviceData(result),
        hasRecording: result.hasRecording,
        participant: result.participant,
        recruitment: result.recruitment,
        stepResponses: result.stepResponses.map((response) => {
            return {
                duration: response.duration,
                stepUuid: response.stepUuid,
                stepRevision: response.stepRevision,
                stepType: stepTypeToStepClass(response.type),
                answers: 'answers' in response && (response === null || response === void 0 ? void 0 : response.answers)
                    ? formatAnswers(response.answers)
                    : [],
                stats: 'stats' in response ? getStats(response.stats) : null,
            };
        }),
    };
}
// Map to StepClass
function stepTypeToStepClass(type) {
    switch (type) {
        case 'STEP_FIVE_SECOND_TEST':
            return StepClass.FiveSecondTest;
        case 'STEP_INSTRUCTION':
            return StepClass.Instruction;
        case 'STEP_MULTIPLE_CHOICE':
            return StepClass.MultipleChoice;
        case 'STEP_PREFERENCE_TEST':
            return StepClass.PreferenceTest;
        case 'STEP_PROTOTYPE_TASK':
            return StepClass.PrototypeTask;
        case 'STEP_QUESTION':
            return StepClass.Question;
        case 'STEP_RATING_SCALE':
            return StepClass.RatingScale;
        case 'STEP_TAGLINE_COPY_TEST':
            return StepClass.TaglineCopyTest;
        case 'STEP_WEBSITE_TASK':
            return StepClass.WebsiteTask;
        case 'STEP_YES_OR_NO':
            return StepClass.YesOrNo;
        case 'STEP_CARDSORT_HYBRID':
            return StepClass.CardSortingHybrid;
        case 'STEP_CARDSORT_OPEN':
            return StepClass.CardSortingOpen;
        case 'STEP_CARDSORT_CLOSED':
            return StepClass.CardSortingClosed;
        default:
            assertExhaustive(type);
    }
}
function assertExhaustive(value) {
    throw new Error(`Unhandled value: ${value}`);
}
function getStats(stats) {
    if (!stats)
        return null;
    return {
        goalHit: stats.goalHit,
        durationSeconds: stats.durationSeconds,
        misclickRate: stats.misclickRate,
        clicksPerResult: stats.clicksPerResult,
    };
}
