import { __rest } from "tslib";
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { observer } from 'mobx-react';
import * as React from 'react';
import { useParams } from 'react-router-dom';
import { Badge, Link, Text } from '@marvelapp/ballpark-components';
import { formatDate, formatDateAndTime } from '@marvelapp/core';
import styled from '@marvelapp/styled';
import { Box } from '@marvelapp/ui';
import { useStepContext } from '../../../utils/stepContext';
import { SummaryStep } from './SummaryStep';
export default observer(function SummaryQuestion(_a) {
    var { active, secondary, showAllLink, stats } = _a, rest = __rest(_a, ["active", "secondary", "showAllLink", "stats"]);
    const { id } = useParams();
    const { step } = useStepContext();
    return (_jsx(SummaryStep, Object.assign({ active: active, secondary: secondary, showAllLink: showAllLink }, rest, { children: stats && (_jsxs(_Fragment, { children: [_jsx(Divider, {}), _jsx(Questions, { children: stats.map((stat) => stat &&
                        stat.answers &&
                        stat.answers.map((answer) => (_jsx(Question, { children: _jsx(Border, { children: _jsxs(Link, { "data-testid": "view-response", display: "block", target: "_blank", to: `/project/${id}/response/${stat.responsePk}?s=${step.uuid}`, children: [_jsxs("div", { "data-testid": "response-answer", children: [(answer === null || answer === void 0 ? void 0 : answer.__typename) === 'AnswerPlainText' && (_jsx(Text, { children: answer.text })), (answer === null || answer === void 0 ? void 0 : answer.__typename) === 'AnswerInteger' && (_jsx(Text, { children: answer.value })), (answer === null || answer === void 0 ? void 0 : answer.__typename) === 'AnswerDateTime' && (_jsx(Text, { children: formatDateAndTime(new Date(answer.date)).date })), (answer === null || answer === void 0 ? void 0 : answer.__typename) === 'AnswerEmail' && (_jsx(Box, { display: "inline-block", children: _jsx(Badge, { variant: "sky", children: answer.email }) }))] }), _jsxs(Text, { marginTop: "xs", kind: "additional", size: "s", children: [_jsx(Box, { display: "inline-block", "data-testid": "user-email", children: stat.participantName }), ' ', "\u00B7", ' ', _jsx(Box, { display: "inline-block", "data-testid": "response-timestamp", children: formatDate(new Date(stat.createdAt)) })] })] }) }) })))) })] })) })));
});
const Divider = styled(Box).attrs({
    bg: 'slate6',
}) `
  width: 100%;
  height: 1px;
`;
const Questions = styled(Box) `
  overflow: auto;
  max-height: 300px;
  margin-bottom: -${(props) => props.theme.space.l}px;
  margin-left: -${(props) => props.theme.space.l}px;
  width: calc(100% + ${(props) => props.theme.space.l * 2}px);
`;
const Question = styled(Box).attrs({
    hoverBg: 'slate2',
    paddingX: 'l',
    transition: 'smooth.fast',
}) `
  cursor: pointer;
  &:last-child > div {
    border-bottom: none;
  }
`;
const Border = styled(Box).attrs({
    paddingY: 'm',
    borderBottom: '1px solid',
    borderColor: 'slate6',
}) ``;
