import { jsx as _jsx } from "react/jsx-runtime";
import { observer } from 'mobx-react';
import * as React from 'react';
import { useIntl } from 'react-intl';
import { LocaleUtils, SCREENER_COPY, ScreenerUtils, StepClass, StepUtils, getStepByUUID, isExitStep, } from '@marvelapp/user-test-creator';
import { CardSortingRecorder } from '../../slides/CardSorting/recorder/CardSortingRecorder';
import DateSlide from '../../slides/Date/DateRecord';
import EmailSlide from '../../slides/Email/EmailRecord';
import { ExitRecord as ExitSlide } from '../../slides/Exit/ExitRecord';
import FiveSecondSlide from '../../slides/FiveSecond/FiveSecondRecord';
import InstructionSlide from '../../slides/Instruction/InstructionRecord';
import LegalSlide from '../../slides/Legal/LegalRecord';
import { MediaSettingsRecord } from '../../slides/MediaSettings/MediaSettingsRecord';
import MultipleChoiceSlide from '../../slides/MultipleChoice/MultipleChoiceRecord';
import NumericalSlide from '../../slides/Numerical/NumericalRecord';
import PreferenceTestSlide from '../../slides/PreferenceTest/PreferenceTestRecord';
import PrototypeTaskSlide from '../../slides/PrototypeTask/PrototypeRecord';
import QuestionSlide from '../../slides/Question/QuestionRecord';
import { RateRecordSlide } from '../../slides/Rate/RateRecord';
import TaglineCopyTestSlide from '../../slides/TaglineCopyTest/TaglineCopyTestRecord';
import WebsiteTaskSlide from '../../slides/WebsiteTask/WebsiteTaskRecord';
import YesOrNoSlide from '../../slides/YesNo/YesNoRecord';
import { useRecorderState } from './RecorderContext';
import { SessionEnded } from './SessionEnded';
import { LiveSessionStatus } from './useLiveSessionStatus';
export const SlideRenderer = observer(function SlideRenderer({ step, stepResponse, }) {
    var _a;
    const recorderState = useRecorderState();
    if (recorderState.liveSessionStatus === LiveSessionStatus.Ended) {
        return (_jsx("div", { className: "flex min-h-screen w-full items-center justify-center", children: _jsx(SessionEnded, {}) }));
    }
    if (ScreenerUtils.isScreenerStep(recorderState.userTestDoc, step.uuid)) {
        return _jsx(ScreenerStep, { step: step, stepResponse: stepResponse });
    }
    if (recorderState.requiresMedia) {
        const mediaSettingsStepUUID = (_a = recorderState.userTestDoc.mediaSettingsStep) === null || _a === void 0 ? void 0 : _a.uuid;
        if (mediaSettingsStepUUID) {
            const mediaSettingsStep = getStepByUUID(recorderState.userTestDoc, mediaSettingsStepUUID);
            if (mediaSettingsStep) {
                return _jsx(MediaSettingsRecord, { step: mediaSettingsStep });
            }
        }
    }
    switch (step.className) {
        case StepClass.Question:
            if (StepUtils.isEmailStep(step)) {
                return (_jsx(EmailSlide, { step: step, stepResponse: stepResponse }));
            }
            if (StepUtils.isDateStep(step)) {
                return (_jsx(DateSlide, { step: step, stepResponse: stepResponse }));
            }
            if (StepUtils.isNumericalStep(step)) {
                return (_jsx(NumericalSlide, { step: step, stepResponse: stepResponse }));
            }
            return (_jsx(QuestionSlide, { step: step, stepResponse: stepResponse }));
        case StepClass.Instruction: {
            if (isExitStep(recorderState.userTestDoc, step.uuid)) {
                return _jsx(ExitSlide, { step: step });
            }
            return _jsx(InstructionSlide, { step: step });
        }
        case StepClass.PrototypeTask:
            return _jsx(PrototypeTaskSlide, { step: step });
        case StepClass.YesOrNo:
            if (StepUtils.isLegalStep(step)) {
                return (_jsx(LegalSlide, { step: step, stepResponse: stepResponse }));
            }
            return (_jsx(YesOrNoSlide, { step: step, stepResponse: stepResponse }));
        case StepClass.MultipleChoice:
            return (_jsx(MultipleChoiceSlide, { step: step, stepResponse: stepResponse }));
        case StepClass.RatingScale:
            return (_jsx(RateRecordSlide, { step: step, stepResponse: stepResponse }));
        case StepClass.PreferenceTest:
            return (_jsx(PreferenceTestSlide, { step: step, stepResponse: stepResponse }));
        case StepClass.TaglineCopyTest:
            return (_jsx(TaglineCopyTestSlide, { step: step, stepResponse: stepResponse }));
        case StepClass.FiveSecondTest:
            return (_jsx(FiveSecondSlide, { step: step, stepResponse: stepResponse }));
        case StepClass.MediaSettings:
            return _jsx(MediaSettingsRecord, { step: step });
        case StepClass.WebsiteTask:
            return (_jsx(WebsiteTaskSlide, { step: step, stepResponse: stepResponse }));
        case StepClass.CardSortingClosed:
        case StepClass.CardSortingOpen:
        case StepClass.CardSortingHybrid:
            return (_jsx(CardSortingRecorder, { step: step, stepResponse: stepResponse }));
        default:
            return null;
    }
});
const ScreenerStep = observer(function ScreenerStep({ step, stepResponse, }) {
    const intl = useIntl();
    const recorderState = useRecorderState();
    if (ScreenerUtils.isIntroStep(recorderState.userTestDoc, step.uuid)) {
        const title = step.title
            ? LocaleUtils.localizeIfDefault(intl, step.title, SCREENER_COPY.Intro.defaultTitle)
            : step.title;
        const description = LocaleUtils.localizeIfDefault(intl, step.description, SCREENER_COPY.Intro.defaultDescription);
        return (_jsx(InstructionSlide, { step: step, withSlideBadges: false, customTitle: title !== null && title !== void 0 ? title : undefined, customDescription: description }));
    }
    if (ScreenerUtils.isDeclineStep(recorderState.userTestDoc, step.uuid) &&
        step.className === StepClass.Instruction) {
        const title = step.title
            ? LocaleUtils.localizeIfDefault(intl, step.title, SCREENER_COPY.Decline.defaultTitle)
            : step.title;
        const description = LocaleUtils.localizeIfDefault(intl, step.description, SCREENER_COPY.Decline.defaultDescription);
        return (_jsx(ExitSlide, { step: step, withSlideBadges: false, customTitle: title !== null && title !== void 0 ? title : undefined, customDescription: description }));
    }
    return (_jsx(MultipleChoiceSlide, { step: step, stepResponse: stepResponse, withSlideBadges: false }));
});
