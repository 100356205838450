import { __rest } from "tslib";
import { jsx as _jsx } from "react/jsx-runtime";
import PropTypes from 'prop-types';
import { Child, Ratio } from './styles';
import { convertRatioToNumber } from './utils';
export default function AspectRatio(_a) {
    var { ratio, children } = _a, props = __rest(_a, ["ratio", "children"]);
    const numericRatio = convertRatioToNumber(ratio);
    const paddingTop = numericRatio === 0 ? '100%' : `${100 / numericRatio}%`;
    return (_jsx(Ratio, Object.assign({ pt: paddingTop }, props, { children: _jsx(Child, { children: children }) })));
}
AspectRatio.propTypes = {
    children: PropTypes.node,
    ratio: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
};
AspectRatio.defaultProps = {
    ratio: 4 / 3,
};
