import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { AnimatePresence, motion } from 'framer-motion';
import { observer } from 'mobx-react';
import { useMemo } from 'react';
import { BtwButton, BtwCheckbox, BtwText, Stack, TableToolbar, } from '@marvelapp/ballpark-components';
import theme from '@marvelapp/ballpark-theme';
import { pluralize } from '@marvelapp/core';
import { useResponsesContext } from '../ResponsesContext';
import { DeleteResultsAction } from './DeleteResultsAction';
export const Toolbar = observer(function Toolbar() {
    const { rowSelection, clearSelection } = useResponsesContext();
    const selectedPks = useMemo(() => Object.keys(rowSelection).filter((key) => rowSelection[key]), [rowSelection]);
    return (_jsx(_Fragment, { children: _jsx(AnimatePresence, { children: selectedPks.length > 0 && (_jsx(motion.div, { initial: { opacity: 0, y: 8, scale: 0.9 }, animate: { opacity: 1, y: 0, scale: 1 }, exit: {
                    opacity: 0,
                    y: 8,
                    scale: 0.9,
                    transition: { duration: 0.2 },
                }, className: "absolute inset-x-0 bottom-0 z-[400] w-full", transition: {
                    ease: theme.easings.smooth.array,
                    duration: 0.2,
                }, children: _jsx(TableToolbar.Container, { isOpen: selectedPks.length > 0, children: _jsxs(Stack, { direction: "row", justify: "between", width: "full", align: "center", children: [_jsxs(Stack, { className: "pl-2", direction: "row", gap: "3", children: [_jsx(BtwCheckbox, { checked: "indeterminate", onCheckedChange: clearSelection }), _jsxs(BtwText, { weight: "medium", variant: "primary", size: "sm", children: [selectedPks.length, ' ', pluralize(selectedPks.length, 'response'), " selected"] })] }), _jsxs(Stack, { direction: "row", gap: "2", children: [_jsx(TableToolbar.Button, { children: _jsx(BtwButton, { onClick: clearSelection, children: "Cancel" }) }), _jsx(TableToolbar.Button, { children: _jsx(DeleteResultsAction, { clearSelectedItems: clearSelection, selectedPks: selectedPks }) })] })] }) }) }, "tableToolbar")) }) }));
});
