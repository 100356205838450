import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { observer } from 'mobx-react';
import { useState } from 'react';
import * as React from 'react';
import { useSlideUIContext } from '@marvelapp/ballpark-application';
import { Button, Heading, Link, Modal, ModalOverlay, Text, } from '@marvelapp/ballpark-components';
import { TROUBLESHOOTING_FIGMA_ISSUES } from '@marvelapp/ballpark-help';
import styled from '@marvelapp/styled';
import { Box } from '@marvelapp/ui';
import { StepClass, StepUtils } from '@marvelapp/user-test-creator';
import { LeftScrollableHalfSlide } from '../../common/HalfSlide';
import SlideContent from '../../common/SlideContent';
import TwoColumnSlide from '../../common/TwoColumnSlide';
import { PrototypeSelector } from './PrototypeSelector';
export const PrototypeTaskEdit = observer(function PrototypeTaskEdit({ onMainCTAClick, }) {
    const { step } = useSlideUIContext();
    const [showWarningModal, setShowWarningModal] = useState(true);
    const [fileSize, setFileSize] = useState(null);
    if (!StepUtils.isOfType(step, StepClass.PrototypeTask))
        return null;
    return (_jsxs(TwoColumnSlide, { children: [_jsx(LeftScrollableHalfSlide, { flex: 1, children: _jsx(SlideContent, { ctaText: "Start", ctaOnClick: onMainCTAClick, maxHeadingLength: 512, maxParagraphLength: 512 }) }), _jsxs(Box, { display: "flex", flexDirection: "column", flex: [2, 2, 2, 2, 2, 2, 1], children: [_jsx(PrototypeSelector, { fileSize: fileSize, setFileSize: setFileSize }), fileSize && showWarningModal && (_jsx(FileSizeWarningModal, { fileSize: fileSize, onClick: () => setShowWarningModal(false) }, "file-size-warning-modal"))] })] }));
});
const FileSizeWarningModal = observer(function FileSizeWarningModal({ onClick, fileSize, }) {
    const fileSizeInMb = fileSize / 1000000;
    const displayFileSize = fileSizeInMb < 1 ? fileSizeInMb.toFixed(2) : Math.round(fileSizeInMb);
    return (_jsx(ModalOverlay, { children: _jsx(ModalContainer, { children: _jsxs(Modal, { testId: "import-file-size-warning", children: [_jsxs(Heading, { marginTop: "xl", marginBottom: "xs", size: "l", children: ["Your Figma file is large (", displayFileSize, "MB) and will increase load time"] }), _jsxs(Text, { marginBottom: "m", size: "m", children: ["We recommend copying only the frames you need to a new Figma file.", ' ', '', _jsx(Link, { "data-testid": "figma-size-link", color: "marvelDark", fontSize: 3, to: TROUBLESHOOTING_FIGMA_ISSUES, children: "Learn More" })] }), _jsx(Button, { onClick: onClick, size: "l", marginRight: "xs", "data-testid": "close-file-size-warning", children: "I understand" })] }) }) }));
});
const ModalContainer = styled(Box) `
  top: 25%;
  left: 25%;
  position: absolute;
  max-width: 350px;
`;
