export const TEXT_EDITOR_DESCRIPTION_FONT_SIZES = [
    's',
    's',
    'm',
    'm',
    'l',
    'l',
];
export const TEXT_EDITOR_TITLE_FONT_SIZES = ['l', 'l', 'l', 'xl', 'xxl', 'xxl'];
// Need to manually set the line height here, because something like
// 1.3 returns half pixel values, which work fine for h2s but
// render different results in a textarea, causing a jump on focus
export const TEXT_EDITOR_TITLE_LINE_HEIGHTS = [
    '32px',
    '32px',
    '32px',
    '32px',
    '32px',
    '36px',
];
export const BUILDER_RIGHT_HAND_SIDE_SLIDE_PADDING_Y = [
    'm',
    'm',
    'm',
    'm',
    'l',
    'l',
    'xl',
    'xxl',
];
export const BUILDER_RIGHT_HAND_SIDE_SLIDE_PADDING_X = [
    'm',
    'm',
    'm',
    'm',
    'l',
    'l',
    'xxl',
];
