import { __rest } from "tslib";
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { observer } from 'mobx-react';
import * as React from 'react';
import styled from '@marvelapp/styled';
import { Box, Grid } from '@marvelapp/ui';
import { MultipleChoiceStepUtils, } from '@marvelapp/user-test-creator';
import Checkbox from '../../../slides/common/Checkbox';
import { Answer } from './Answer';
export const MultipleChoiceAnswer = observer(function MultipleChoiceAnswer(_a) {
    var { step, answers } = _a, restProps = __rest(_a, ["step", "answers"]);
    const selectedOptions = answers ? answers.map((a) => a.value).flat() : [];
    const multiChoiceStep = step;
    const otherOptionUUID = MultipleChoiceStepUtils.getOtherOptionUUID(multiChoiceStep);
    const choices = multiChoiceStep.choices.filter((uuid) => uuid !== otherOptionUUID);
    const otherValue = (answers === null || answers === void 0 ? void 0 : answers.length)
        ? answers[0].otherValue
        : undefined;
    return (_jsx(Answer, Object.assign({ step: step }, restProps, { children: _jsx(CheckboxWrapper, { children: _jsxs(Grid, { gridGap: "s", marginTop: "-4px", children: [choices.map((choiceUUID) => {
                        const optionName = multiChoiceStep.choiceDefinitions[choiceUUID];
                        return (optionName && (_jsx(Checkbox, { checked: selectedOptions.includes(choiceUUID), name: optionName, readOnly: true }, choiceUUID)));
                    }), otherOptionUUID && (_jsx(Checkbox, { readOnly: true, checked: selectedOptions.includes(otherOptionUUID), name: otherValue ? `Other: ${otherValue}` : 'Other' }))] }) }) })));
});
const CheckboxWrapper = styled(Box).attrs({
    maxWidth: ['100%', '100%', '50%'],
}) `
  display: inline-block;
  width: 100%;
`;
