import { __rest } from "tslib";
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { observer } from 'mobx-react';
import { forwardRef, useMemo } from 'react';
import * as React from 'react';
import { AddImageCta, BtwButton, BtwHeading, EditableText, Stack, cn, } from '@marvelapp/ballpark-components';
import { DragIcon } from '@marvelapp/ballpark-icons';
import { getImage, getImage2x, getThumbnail, getThumbnail2x, } from '@marvelapp/core';
import { TextEditor } from '@marvelapp/text-editor';
import { TextUtils, } from '@marvelapp/user-test-creator';
import { useUppy } from '../../components/useUppy';
import { DeleteCornerButton } from './DeleteCornerButton';
import { CARD_DESCRIPTION_MAX_LENGTH, CARD_TITLE_MAX_LENGTH, } from './constants';
const CARD_IMAGE_WIDTH = 340;
export const BaseCard = observer(
// eslint-disable-next-line mobx/missing-observer
forwardRef(function BaseCard(_a, ref) {
    var { card, categoryUUID, dragHandleProps = {}, isDragging, isDragOverlay, onDelete, onUpdateDescription, onUpdateImage, onRemoveImage, onUpdateTitle, onAddNewCard, showCardNumber, style, isFocused, titleInputRef, onTitleKeyDown } = _a, sortableProps = __rest(_a, ["card", "categoryUUID", "dragHandleProps", "isDragging", "isDragOverlay", "onDelete", "onUpdateDescription", "onUpdateImage", "onRemoveImage", "onUpdateTitle", "onAddNewCard", "showCardNumber", "style", "isFocused", "titleInputRef", "onTitleKeyDown"]);
    const isDragPlaceholder = isDragging && !isDragOverlay;
    const { dndRef: addImageCtaRef, isLoading: isImageCtaLoading, draggedOver: isImageCtaDraggedOver, } = useUppy({
        onImageUploaded: onUpdateImage,
    });
    const isDraggable = Object.keys(dragHandleProps).length > 0;
    const onReplaceImage = useMemo(() => {
        if (!onRemoveImage)
            return undefined;
        return () => {
            if (!addImageCtaRef.current)
                return;
            // Find the file input rendered by Uppy to replace the current image
            const fileInput = addImageCtaRef.current.querySelector('input[type="file"]');
            if (!fileInput)
                return;
            // After a new image is selected, remove the current image. This will
            // show a loading state while the new image is being uploaded
            fileInput.addEventListener('change', onRemoveImage, { once: true });
            fileInput.click();
        };
    }, [addImageCtaRef, onRemoveImage]);
    const index = getIndex({
        showCardNumber: !!showCardNumber,
        isDragPlaceholder,
        isDragOverlay,
        isSorting: sortableProps.isSorting,
        newIndex: sortableProps.newIndex,
        index: sortableProps.index,
    });
    const testId = useMemo(() => {
        if (isDragPlaceholder)
            return 'card-sort-card-placeholder';
        if (isDragOverlay)
            return 'card-sort-card-overlay';
        return 'card-sort-card';
    }, [isDragOverlay, isDragPlaceholder]);
    return (_jsxs(Stack, Object.assign({ className: cn('p-[14px]', 'w-full', 'relative', 'bg-white', 'rounded-lg', 'group', 'ring-1', 'ring-gray-600/20', 
        // Transition
        'duration-300', 'ease-smooth', 'transition-combined', 
        // Focus state
        'focus-visible:outline-none', 'focus-visible:ring-gray-600', 'focus-visible:outline-gray-300', {
            'cursor-grab': isDraggable,
            'opacity-25': isDragPlaceholder,
            'shadow-lg': isDragOverlay,
            'shadow-sm-longer': !isDragOverlay,
        }), gap: "3", ref: ref, style: style, width: "full", "data-testid": testId, "data-uuid": card.uuid }, dragHandleProps, { children: [_jsx(AddImageCta.EmptyState, { className: cn({ hidden: !onUpdateImage || card.imageUrl }), "data-testid": "add-card-image-cta", draggedOver: isImageCtaDraggedOver, isLoading: isImageCtaLoading, ref: addImageCtaRef }), card.imageUrl && (_jsx(AddImageCta.Image, { alt: card.title || 'Card image', "data-testid": "card-image", imageSrc: getImage(card.imageUrl), imageSrcSet: `${getImage2x(card.imageUrl)} 2x`, onRemove: onRemoveImage, onReplace: onReplaceImage, thumbnail: getThumbnail(card.imageUrl, CARD_IMAGE_WIDTH), thumbnailSet: `${getThumbnail2x(card.imageUrl, CARD_IMAGE_WIDTH)} 2x` })), _jsxs(Stack, { gap: "0.5", width: "full", children: [_jsxs(Stack, { align: "start", direction: "row", width: "full", children: [_jsx(Stack, { justify: "center", className: "min-h-[22px] min-w-0 flex-1 !text-left", children: onUpdateTitle ? (_jsx(EditableText, { onKeyDown: onTitleKeyDown, ref: titleInputRef, autoFocus: false, "data-testid": "card-title", onSaveValue: onUpdateTitle, placeholder: "Add your card title", size: "13", value: card.title, weight: "semibold", maxLength: CARD_TITLE_MAX_LENGTH })) : (_jsx(BtwHeading, { asChild: true, size: "13", className: "w-full break-words px-0.5", children: _jsxs("h2", { "data-testId": "card-title", children: [showCardNumber && typeof index === 'number' && (_jsxs("span", { "data-testId": "card-number", className: "mr-2", children: [index + 1, "."] })), card.title] }) })) }), isDraggable && (_jsx(BtwButton, { className: "!size-[22px] cursor-grab rounded", size: "sm", standaloneIcon: _jsx(DragIcon, { className: "!size-4" }), variant: "ghost", "data-dndkit-drag": true }))] }), _jsx(CardDescription, { description: card.description, disabled: !onUpdateDescription || isDragging, id: getCardTextEditorId({
                            cardUUID: card.uuid,
                            isDragOverlay,
                            categoryUUID,
                        }), onChange: onUpdateDescription, placeholder: "Click to add description (optional)", testId: "card-description", maxLength: CARD_DESCRIPTION_MAX_LENGTH })] }), onDelete && !isDragOverlay && (_jsx(DeleteCornerButton, { onClick: onDelete, "data-testid": "remove-card" }))] }), card.uuid));
}));
function getCardTextEditorId({ cardUUID, isDragOverlay, categoryUUID, }) {
    return `${cardUUID}-card-description${isDragOverlay ? '-dragOverlay' : ''}${categoryUUID ? `-${categoryUUID}` : ''}`;
}
const CardDescription = observer(function CardDescription(_a) {
    var { description, disabled } = _a, rest = __rest(_a, ["description", "disabled"]);
    const noDescription = description
        ? TextUtils.checkIfValueIsEmpty(description)
        : true;
    if (disabled && noDescription) {
        return null;
    }
    return (_jsx("div", { className: "w-full", children: _jsx(TextEditor, Object.assign({ size: "13", disabled: disabled, value: description }, rest)) }));
});
function getIndex({ showCardNumber, isDragPlaceholder, isDragOverlay, isSorting, newIndex, index, }) {
    if (!showCardNumber || isDragOverlay) {
        return undefined;
    }
    if (isDragPlaceholder || isSorting) {
        return newIndex;
    }
    return index;
}
