import { isNil } from 'lodash-es';
import { hasHitGoalScreen, hasStartTestEvent, } from '../../response/prototypeTaskStep';
import * as StepUtils from '../../userTest/stepUtils';
export function setGoalRule(mutable, answerOption, destinationStepUUID) {
    var _a, _b;
    if (StepUtils.isGoalBasedPrototype(mutable)) {
        if (!((_a = mutable.conditions) === null || _a === void 0 ? void 0 : _a.hitGoal)) {
            initializeConditions(mutable);
        }
        if ((_b = mutable.conditions) === null || _b === void 0 ? void 0 : _b.hitGoal) {
            if (answerOption) {
                mutable.conditions.hitGoal.true = destinationStepUUID;
            }
            else {
                mutable.conditions.hitGoal.false = destinationStepUUID;
            }
        }
    }
}
export function updateGoalRule({ mutable, selectedChoice, selectedDestStepUuid, currentChoice, }) {
    if (!isNil(selectedChoice)) {
        updateGoalRuleCheck({ mutable, selectedChoice, currentChoice });
    }
    if (selectedDestStepUuid) {
        updateGoalRuleDestination({ mutable, currentChoice, selectedDestStepUuid });
    }
}
function updateGoalRuleCheck({ mutable, selectedChoice, currentChoice, }) {
    if (mutable.conditions && mutable.conditions.hitGoal) {
        if (currentChoice) {
            const destinationUUID = mutable.conditions.hitGoal.true;
            if (!selectedChoice) {
                mutable.conditions.hitGoal.true = null;
                mutable.conditions.hitGoal.false = destinationUUID;
            }
        }
        else {
            const destinationUUID = mutable.conditions.hitGoal.false;
            if (selectedChoice) {
                mutable.conditions.hitGoal.false = null;
                mutable.conditions.hitGoal.true = destinationUUID;
            }
        }
    }
    else {
        initializeConditions(mutable);
    }
}
function updateGoalRuleDestination({ mutable, currentChoice, selectedDestStepUuid, }) {
    var _a;
    if ((_a = mutable.conditions) === null || _a === void 0 ? void 0 : _a.hitGoal) {
        if (currentChoice) {
            mutable.conditions.hitGoal.true = selectedDestStepUuid;
        }
        else {
            mutable.conditions.hitGoal.false = selectedDestStepUuid;
        }
    }
    else {
        initializeConditions(mutable);
    }
}
function initializeConditionsData(mutable) {
    mutable.conditions = {
        hitGoal: {
            true: null,
            false: null,
        },
        ALWAYS: null,
    };
}
function initializeHitGoalConditions(mutable) {
    if (mutable.conditions) {
        mutable.conditions.hitGoal = {
            true: null,
            false: null,
        };
    }
    else {
        initializeConditionsData(mutable);
    }
}
function initializeConditions(mutable) {
    if (!mutable.conditions) {
        initializeConditionsData(mutable);
    }
    else if (!mutable.conditions.hitGoal) {
        initializeHitGoalConditions(mutable);
    }
}
export function deleteGoalRule(mutable, currentRule) {
    var _a;
    if ((_a = mutable.conditions) === null || _a === void 0 ? void 0 : _a.hitGoal) {
        if (currentRule) {
            mutable.conditions.hitGoal.true = null;
        }
        else {
            mutable.conditions.hitGoal.false = null;
        }
    }
}
export function getNextStepUUID(mutable, response) {
    var _a, _b, _c, _d, _e, _f;
    const hitGoalScreen = hasHitGoalScreen(response);
    const startedTest = hasStartTestEvent(response);
    if (hitGoalScreen && ((_b = (_a = mutable.conditions) === null || _a === void 0 ? void 0 : _a.hitGoal) === null || _b === void 0 ? void 0 : _b.true)) {
        return mutable.conditions.hitGoal.true;
    }
    if (!hitGoalScreen && startedTest && ((_d = (_c = mutable.conditions) === null || _c === void 0 ? void 0 : _c.hitGoal) === null || _d === void 0 ? void 0 : _d.false)) {
        return mutable.conditions.hitGoal.false;
    }
    // if user skips the step, they should fall into the ALWAYS field
    return (_f = (_e = mutable.conditions) === null || _e === void 0 ? void 0 : _e.ALWAYS) !== null && _f !== void 0 ? _f : null;
}
export function getSavedRules(step) {
    var _a;
    const savedRules = [];
    if (StepUtils.isGoalBasedPrototype(step) && ((_a = step.conditions) === null || _a === void 0 ? void 0 : _a.hitGoal)) {
        if (step.conditions.hitGoal) {
            if (!isNil(step.conditions.hitGoal.true)) {
                savedRules.push(['true', step.conditions.hitGoal.true]);
            }
            if (!isNil(step.conditions.hitGoal.false)) {
                savedRules.push(['false', step.conditions.hitGoal.false]);
            }
        }
    }
    return savedRules;
}
