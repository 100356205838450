import { AnswerType, } from '@marvelapp/user-test-creator';
// Map answers to StepResponse
export function formatAnswers(answers) {
    const filteredAnswers = answers ? answers.filter((ans) => !!ans) : [];
    // Backend returns multiple choice answer as separate indexes
    // instead of an array of indexes. Handling this special case here
    if (filteredAnswers.length && filteredAnswers[0].type === 'MULTIPLE_CHOICE') {
        const multipleChoiceAnswer = {
            type: AnswerType.MultipleChoice,
            value: [],
        };
        filteredAnswers.forEach((answer) => {
            const typedAnswer = answer;
            if (typedAnswer.choiceUuid) {
                multipleChoiceAnswer.value.push(typedAnswer.choiceUuid);
            }
            if (typedAnswer.otherValue) {
                multipleChoiceAnswer.otherValue = typedAnswer.otherValue;
            }
        });
        return [multipleChoiceAnswer];
    }
    return filteredAnswers.map((answer) => {
        switch (answer.type) {
            case 'TEXT': {
                return {
                    type: AnswerType.Text,
                    value: answer.value,
                };
            }
            case 'EMAIL': {
                return {
                    type: AnswerType.Email,
                    value: answer.value,
                };
            }
            case 'BOOLEAN': {
                return {
                    type: AnswerType.SingleChoice,
                    value: answer.value,
                };
            }
            case 'DATETIME': {
                return {
                    type: AnswerType.DateAnswer,
                    value: new Date(answer.value).getTime(), // TODO
                };
            }
            case 'INTEGER': {
                return {
                    type: AnswerType.Number,
                    value: answer.value,
                };
            }
            default:
                throw Error(`Couldn't get answer for this answer type: ${answer.type}`);
        }
    });
}
