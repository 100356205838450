import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { observer } from 'mobx-react';
import { Fragment } from 'react';
import { Stack } from '@marvelapp/ballpark-components';
import { useStore } from '../../../../store';
import { Grid } from './Grid';
import { Screen } from './Screen';
import { SectionDivider } from './SectionDivider';
export var ScreenPickerTab;
(function (ScreenPickerTab) {
    ScreenPickerTab["Screens"] = "screens";
    ScreenPickerTab["Components"] = "components";
})(ScreenPickerTab || (ScreenPickerTab = {}));
export const GroupedScreens = observer(function GroupedScreens({ activeTab, aspectRatio, componentsSection, isExternalPrototype, onSelectScreen, otherScreen, searchTerm, sections, selectedScreen, }) {
    const { screenPicker: { collapsedSections }, } = useStore();
    const sectionsToRender = activeTab === ScreenPickerTab.Components ? componentsSection : sections;
    return (_jsx(Stack, { width: "full", gap: "6", children: sectionsToRender.map(({ section, screens }) => {
            const { name, pk } = section;
            return (_jsxs(Fragment, { children: [_jsx(SectionDivider, { name: name, isHidden: collapsedSections[pk], toggleSection: () => {
                            collapsedSections[pk] = !collapsedSections[pk];
                        }, screenCount: screens.length, searchTerm: searchTerm }), !collapsedSections[pk] && (_jsx(Grid, { children: screens.map((screen) => (_jsx(Screen, { aspectRatio: aspectRatio, disabled: screen.pk === otherScreen, isActive: screen.pk === selectedScreen, isWaitingOnFigma: isExternalPrototype && screen.content === null, onSelectScreen: onSelectScreen, screenData: screen, searchTerm: searchTerm }, screen.pk))) }))] }, pk));
        }) }));
});
