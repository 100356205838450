import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { observer } from 'mobx-react';
import * as React from 'react';
import { useSlideUIContext } from '@marvelapp/ballpark-application';
import { StepClass, StepUtils } from '@marvelapp/user-test-creator';
import { PrototypeTaskSettings } from './PrototypeTaskSettings';
import { PrototypeUrlInput } from './PrototypeUrlInput';
export const PrototypeSelector = observer(function PrototypeSelector({ setFileSize, fileSize, }) {
    const { step } = useSlideUIContext();
    if (!StepUtils.isOfType(step, StepClass.PrototypeTask)) {
        return null;
    }
    return (_jsx(_Fragment, { children: !step.importedPrototypeUrl ? (_jsx(PrototypeUrlInput, { fileSize: fileSize, setFileSize: setFileSize })) : (_jsx(PrototypeTaskSettings, {})) }));
});
