import { __rest } from "tslib";
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { observer } from 'mobx-react';
import { forwardRef, useEffect, useRef, useState } from 'react';
import * as React from 'react';
import { useIntl } from 'react-intl';
import { useGetUserTest } from '@marvelapp/ballpark-application';
import { BtwText, Stack, StepIcon, cn, getIconType, } from '@marvelapp/ballpark-components';
import { formatStringOrMessage, getStepName, getStepTitle, } from '@marvelapp/ballpark-copy';
import { ChevronRightIcon } from '@marvelapp/ballpark-icons';
import { FlatStepClass, ScreenerUtils, StepUtils, getStepType, } from '@marvelapp/user-test-creator';
import { HoverCell } from './HoverCell';
export const HeaderCell = observer(function HeaderCell(props) {
    const { step, subType, isFirst } = props;
    const elementRef = useRef(null);
    const [isTruncated, setIsTruncated] = useState(false);
    useEffect(() => {
        const container = elementRef.current;
        // Checking whether text is truncated or not.
        if (container) {
            const { clientHeight, scrollHeight } = elementRef.current.getElementsByClassName('cellTitle')[0];
            if (scrollHeight > clientHeight)
                setIsTruncated(true);
        }
    }, []);
    return (_jsx(HoverCell, { isTruncated: isTruncated, trigger: _jsx(HeaderCellBase, { step: step, subType: subType, isFirst: isFirst, ref: elementRef, className: "line-clamp-2" }), content: _jsx(HeaderCellBase, { step: step, subType: subType, isFirst: isFirst, className: "line-clamp-[20]" }) }));
});
const HeaderCellBase = observer(
// eslint-disable-next-line
forwardRef(function HeaderCellBase(props, ref) {
    const { step, subType, isFirst, className } = props, rest = __rest(props, ["step", "subType", "isFirst", "className"]);
    const intl = useIntl();
    const userTest = useGetUserTest();
    const isScreenerStep = ScreenerUtils.isScreenerStep(userTest, step.uuid);
    const ribbonGradientStyles = `
  before:absolute before:left-0 before:top-0 before:block before:h-1 before:w-8 before:bg-gradient-to-r before:content-[""]
    ${getRibbonGradientColor(getStepType(step))}
  `;
    const ribbonBorderStyles = `
  after:absolute after:left-0 after:top-0 after:block after:h-1 after:w-px
    ${getRibbonBorderColor(getStepType(step))}
  `;
    function renderStepName(step, isScreenerStep) {
        const flatStepClass = StepUtils.getFlatStepClass(step);
        if (flatStepClass === FlatStepClass.FreeRoamStep) {
            return 'Free roam';
        }
        if (flatStepClass === FlatStepClass.GoalBasedStep) {
            return 'Task';
        }
        return intl.formatMessage(getStepName(step, undefined, isScreenerStep));
    }
    return (_jsxs(Stack, Object.assign({ gap: "1.5", justify: "between", className: "h-full" }, rest, { ref: ref, children: [subType && (_jsx(_Fragment, { children: _jsx("div", { className: cn(
                    // Base styles for the full-width ribbon
                    'absolute inset-x-0 -left-px top-0 z-50 h-1 w-[calc(100%+2px)]', `${getRibbonBackgroundColor(getStepType(step))}`, 
                    // If the column is the first in the group (has isFirst prop),
                    // add a very slight gradient to the left of the ribbon
                    `${isFirst && ribbonGradientStyles}`, 
                    // Also add a 1px border to the left
                    `${isFirst && ribbonBorderStyles}`) }) })), _jsxs(Stack, { direction: "row", gap: "2", align: "center", children: [_jsx(StepIcon, { size: "xs", type: getIconType(step, undefined, isScreenerStep) }), _jsxs(BtwText, { size: "xs", weight: "medium", className: "flex flex-row items-center gap-1", children: [renderStepName(step, isScreenerStep), ' ', subType && (_jsxs(_Fragment, { children: [_jsx(ChevronRightIcon, { className: "size-3" }), " ", subType] }))] })] }), _jsx(BtwText, { weight: "medium", variant: "primary", leading: "tight", className: cn('cellTitle max-w-full hyphens-auto', className), children: formatStringOrMessage(intl, getStepTitle(step)) })] })));
}));
function getRibbonBackgroundColor(stepType) {
    if (stepType === 'Prototype') {
        return 'bg-red-300';
    }
    if (stepType === 'FreeRoam') {
        return 'bg-purple-300';
    }
}
function getRibbonGradientColor(stepType) {
    if (stepType === 'Prototype') {
        return 'before:from-red-400/40';
    }
    if (stepType === 'FreeRoam') {
        return 'before:from-purple-400/60';
    }
}
function getRibbonBorderColor(stepType) {
    if (stepType === 'Prototype') {
        return 'after:bg-red-400/60';
    }
    if (stepType === 'FreeRoam') {
        return 'after:bg-purple-400/70';
    }
}
