import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { observer } from 'mobx-react';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { Badge, Heading } from '@marvelapp/ballpark-components';
import styled from '@marvelapp/styled';
export const MediaAccessContainer = observer(function MediaAccessContainer({ children, heading, }) {
    return (_jsxs(Container, { children: [_jsx(Badge, { children: _jsx(FormattedMessage, { id: '5rEf9v', defaultMessage: 'Before we start' }) }), _jsx(Heading, { fontSize: [21, 21, 28, 28], fontWeight: "semibold", marginTop: "s", marginBottom: "xs", children: heading }), children] }));
});
const Container = styled.div `
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-width: 420px;
`;
