import { FIGMA_PERMISSION_HELP, HOW_TO_ADD_FIGMA_PROTOTYPE, TROUBLESHOOTING_FIGMA_ISSUES, } from '@marvelapp/ballpark-help';
import { FailureCodeEnum } from '../../../__generated__/queryTypes';
export const CTA_TRY_AGAIN = 'TRY_AGAIN';
export const CTA_CONNECT_WITH_FIGMA = 'CONNECT_WITH_FIGMA';
export const CTA_I_UNDERSTAND = 'I_UNDERSTAND';
export const failureCopy = {
    NOT_FOUND_TOKEN: {
        heading: "We couldn't find your prototype",
        description: "Please ensure that your prototype share settings in Figma are set to 'Anyone can view'.",
        buttonText: 'Learn More',
        helpLink: TROUBLESHOOTING_FIGMA_ISSUES,
        ctaType: CTA_I_UNDERSTAND,
    },
    [FailureCodeEnum.NOT_FOUND]: {
        heading: "We couldn't find your prototype",
        description: `Please ensure that your prototype share settings in Figma are set to 'Anyone can view'.

      Connect with Figma to allow us to access prototypes with Team and Organization share settings`,
        buttonText: 'Connect with Figma',
        helpLink: FIGMA_PERMISSION_HELP,
        ctaType: CTA_CONNECT_WITH_FIGMA,
    },
    [FailureCodeEnum.PROTOTYPE_NOT_FOUND]: {
        heading: "Your prototype doesn't have any connections",
        // template literal used here on purpose to force the text into separate lines
        description: `It looks like the screens in your prototype link aren't connected meaning there's no goal to reach.

      Fix the issue or try using a Free roam step instead which works great for testing single screens or components.`,
        buttonText: 'I understand',
        helpLink: HOW_TO_ADD_FIGMA_PROTOTYPE,
        ctaType: CTA_I_UNDERSTAND,
    },
    [FailureCodeEnum.SERVER_ERROR]: {
        heading: 'We could not contact Figma',
        description: 'Check your connection or the Figma status page before trying again.',
        buttonText: 'Try again',
        helpLink: TROUBLESHOOTING_FIGMA_ISSUES,
        ctaType: CTA_TRY_AGAIN,
    },
    [FailureCodeEnum.FORBIDDEN_NOT_ALLOWED]: {
        heading: 'We could not download your file due to sharing permissions',
        description: "Change your permissions to 'Anyone with the link' can view.",
        buttonText: 'I understand',
        helpLink: TROUBLESHOOTING_FIGMA_ISSUES,
        ctaType: CTA_CONNECT_WITH_FIGMA,
    },
    [FailureCodeEnum.FILE_TOO_LARGE]: {
        heading: 'Warning: Very large Figma file',
        description: 'Your Figma prototype (and file) size is too large for the majority of participant mobile and desktop devices and will likely cause issues loading. Please copy only the frames you need to a new file.',
        buttonText: 'I understand',
        helpLink: TROUBLESHOOTING_FIGMA_ISSUES,
        ctaType: CTA_I_UNDERSTAND,
    },
};
