import { NetworkStatus, gql, useQuery } from '@apollo/client';
import { throttle } from 'lodash-es';
import { useEffect, useRef, useState } from 'react';
import { useConfig, usePusher, useSlideUIContext, } from '@marvelapp/ballpark-application';
import { PusherEvent, getPrototypeShareId, mapFilterNullish, } from '@marvelapp/core';
import { StepClass, StepUtils } from '@marvelapp/user-test-creator';
import { getAspectRatio } from '../utils';
const ScreenFragment = gql `
  fragment ScreenFragment on ScreenNode {
    displayName
    pk
    sectionPk
    externalId
    content {
      ... on ImageScreen {
        url
        width
        height
        __typename
      }
      __typename
    }
    __typename
  }
`;
const GET_USER_TEST_SCREENS_BY_PROJECT_ID = gql `
  query Project($shareId: String!, $pageSize: Int) {
    project(shareId: $shareId) {
      pk
      prototypeUrl
      screens(first: $pageSize) {
        edges {
          cursor
          node {
            ...ScreenFragment
          }
        }
        pageInfo {
          endCursor
          hasNextPage
        }
      }
      sections(first: 9999) {
        edges {
          node {
            pk
            name
          }
        }
        pageInfo {
          endCursor
          hasNextPage
        }
      }
      settings {
        deviceFrame
        portrait
      }
    }
  }
  ${ScreenFragment}
`;
function getHotspots(shareId) {
    return fetch(`/api/project-hotspots/${shareId}`, {
        method: 'GET',
        headers: { 'content-type': 'application/json' },
    }).then((r) => r.json());
}
export function useGetProjectScreens({ shareId, }) {
    var _a, _b, _c, _d, _e;
    const { data, error, networkStatus, refetch } = useQuery(GET_USER_TEST_SCREENS_BY_PROJECT_ID, {
        // fetchPolicy needs to be cache-and-network because sometimes the screen content is null after the first request
        fetchPolicy: 'cache-and-network',
        variables: {
            shareId,
            // we are fetching all of the screens in one request because currently we wait for both
            // requests to complete before we show any screens. Meaning that two requests currently aren't optimising the UX, better to just send the one.
            pageSize: 9999,
        },
    });
    const [hotspots, setHotspots] = useState([]);
    useEffect(() => {
        let cancelled = false;
        getHotspots(shareId).then((projectHotspots) => {
            if (!cancelled)
                setHotspots(projectHotspots);
        });
        return () => {
            cancelled = true;
        };
    }, [shareId]);
    const isLoading = networkStatus === NetworkStatus.loading;
    const isRefetching = networkStatus === NetworkStatus.refetch;
    const projectPk = (_a = data === null || data === void 0 ? void 0 : data.project) === null || _a === void 0 ? void 0 : _a.pk;
    usePusherContentUpdated(projectPk, () => refetch({ pageSize: 9999, shareId }));
    return {
        hotspots,
        project: data === null || data === void 0 ? void 0 : data.project,
        screens: ((_c = (_b = data === null || data === void 0 ? void 0 : data.project) === null || _b === void 0 ? void 0 : _b.screens) === null || _c === void 0 ? void 0 : _c.edges)
            ? mapFilterNullish(data.project.screens.edges, (n) => n === null || n === void 0 ? void 0 : n.node)
            : [],
        sections: ((_e = (_d = data === null || data === void 0 ? void 0 : data.project) === null || _d === void 0 ? void 0 : _d.sections) === null || _e === void 0 ? void 0 : _e.edges)
            ? mapFilterNullish(data.project.sections.edges, (n) => n === null || n === void 0 ? void 0 : n.node)
            : [],
        error,
        isLoadingScreens: isLoading || hotspots === null,
        isRefetching,
    };
}
const GET_PROJECT_INFO = gql `
  query GetProjectInfo(
    $shareId: String!
    $startScreenPk: Int!
    $goalScreenPk: Int!
    $hasStartScreen: Boolean!
    $hasGoalScreen: Boolean!
  ) {
    project(shareId: $shareId) {
      pk
      name
      settings {
        deviceFrame
        portrait
      }
      screens(first: 1) {
        edges {
          node {
            pk
          }
        }
      }
      externalPrototype {
        pk
        fileSize
        fileSizeType
      }
    }
    startScreen: screen(pk: $startScreenPk, shareId: $shareId)
      @include(if: $hasStartScreen) {
      ...ScreenFragment
    }
    goalScreen: screen(pk: $goalScreenPk, shareId: $shareId)
      @include(if: $hasGoalScreen) {
      ...ScreenFragment
    }
  }
  ${ScreenFragment}
`;
// Note the hack to work around limitations of graphql @include directive. We
// _have_ to supply an int otherwise the query is rejected but the value 100 is
// never actually used.
const FAKE_SCREEN_ID_TO_WORK_AROUND_INCLUDE_DIRECTIVE = 100;
export function useGetProjectInfo() {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t;
    const { shareId, startScreenPk, goalScreenPk } = useGoalScreenInfo();
    const { data, loading, refetch, startPolling, stopPolling } = useQuery(GET_PROJECT_INFO, {
        variables: {
            shareId: shareId || '',
            startScreenPk: startScreenPk !== null && startScreenPk !== void 0 ? startScreenPk : FAKE_SCREEN_ID_TO_WORK_AROUND_INCLUDE_DIRECTIVE,
            goalScreenPk: goalScreenPk !== null && goalScreenPk !== void 0 ? goalScreenPk : FAKE_SCREEN_ID_TO_WORK_AROUND_INCLUDE_DIRECTIVE,
            hasStartScreen: !!startScreenPk,
            hasGoalScreen: !!goalScreenPk,
        },
        skip: !shareId,
    });
    // TODO: remove this poller when Pusher is correctly providing updates on content updates (ticket: UT-3349)
    const pollerStartTime = useRef(null);
    // if the start screen content is null, we will poll for 60 seconds to see if backend processes it
    useEffect(() => {
        var _a;
        if (startScreenPk &&
            !((_a = data === null || data === void 0 ? void 0 : data.startScreen) === null || _a === void 0 ? void 0 : _a.content) &&
            !pollerStartTime.current) {
            pollerStartTime.current = Date.now();
            startPolling(1000);
        }
    }, [(_a = data === null || data === void 0 ? void 0 : data.startScreen) === null || _a === void 0 ? void 0 : _a.content, startPolling, startScreenPk]);
    useEffect(() => {
        var _a;
        if (pollerStartTime.current &&
            ((Date.now() - pollerStartTime.current) / 1000 >= 60 ||
                (startScreenPk && ((_a = data === null || data === void 0 ? void 0 : data.startScreen) === null || _a === void 0 ? void 0 : _a.content)))) {
            stopPolling();
            pollerStartTime.current = null;
        }
    }, [(_b = data === null || data === void 0 ? void 0 : data.startScreen) === null || _b === void 0 ? void 0 : _b.content, startScreenPk, stopPolling]);
    const projectPk = (_c = data === null || data === void 0 ? void 0 : data.project) === null || _c === void 0 ? void 0 : _c.pk;
    usePusherContentUpdated(projectPk, refetch);
    const deviceFrame = (_e = (_d = data === null || data === void 0 ? void 0 : data.project) === null || _d === void 0 ? void 0 : _d.settings) === null || _e === void 0 ? void 0 : _e.deviceFrame;
    const portrait = (_g = (_f = data === null || data === void 0 ? void 0 : data.project) === null || _f === void 0 ? void 0 : _f.settings) === null || _g === void 0 ? void 0 : _g.portrait;
    const aspectRatio = deviceFrame && portrait !== undefined
        ? getAspectRatio(deviceFrame, portrait)
        : undefined;
    return {
        startScreenName: (_h = data === null || data === void 0 ? void 0 : data.startScreen) === null || _h === void 0 ? void 0 : _h.displayName,
        goalScreenName: (_j = data === null || data === void 0 ? void 0 : data.goalScreen) === null || _j === void 0 ? void 0 : _j.displayName,
        startScreenExternalId: (_k = data === null || data === void 0 ? void 0 : data.startScreen) === null || _k === void 0 ? void 0 : _k.externalId,
        goalScreenExternalId: (_l = data === null || data === void 0 ? void 0 : data.goalScreen) === null || _l === void 0 ? void 0 : _l.externalId,
        startScreen: (_m = data === null || data === void 0 ? void 0 : data.startScreen) === null || _m === void 0 ? void 0 : _m.content,
        goalScreen: (_o = data === null || data === void 0 ? void 0 : data.goalScreen) === null || _o === void 0 ? void 0 : _o.content,
        projectName: (_p = data === null || data === void 0 ? void 0 : data.project) === null || _p === void 0 ? void 0 : _p.name,
        isLoading: loading,
        hasScreens: !!((_s = (_r = (_q = data === null || data === void 0 ? void 0 : data.project) === null || _q === void 0 ? void 0 : _q.screens) === null || _r === void 0 ? void 0 : _r.edges) === null || _s === void 0 ? void 0 : _s.length),
        aspectRatio,
        externalPrototype: (_t = data === null || data === void 0 ? void 0 : data.project) === null || _t === void 0 ? void 0 : _t.externalPrototype,
    };
}
/** Figma screen images are import asynchronously so we need to listen to content updates
 * so we can update the screen images, in the picker or start/goal screen when they are ready.
 */
function usePusherContentUpdated(projectPk, callback) {
    const config = useConfig();
    const { pusher, transactionalKey } = usePusher();
    const updateProjectThrottled = throttle((pusherTransactionalKey) => {
        if (pusherTransactionalKey === transactionalKey) {
            return;
        }
        callback();
    }, 3000, { trailing: true });
    const updateProjectThrottledRef = useRef(updateProjectThrottled);
    useEffect(() => {
        if (!projectPk)
            return;
        const channelName = `${config.pusher.publicPrototypePrefix}${projectPk}`;
        const { channel, unsubscribe } = pusher.subscribe(channelName);
        // We only want to call channel.bind once when refetch is available
        // In order to have access to it from the other useEffect, we need to save it to a ref
        channel.bind(PusherEvent.ContentUpdated, ({ content: { trans_key: pusherTransactionalKey }, }) => {
            if (updateProjectThrottledRef.current) {
                updateProjectThrottledRef.current(pusherTransactionalKey);
            }
        });
        return () => unsubscribe();
    }, [config.pusher.publicPrototypePrefix, projectPk, pusher]);
}
function useGoalScreenInfo() {
    const { step } = useSlideUIContext();
    if (!StepUtils.isOfType(step, StepClass.PrototypeTask))
        return {};
    const startScreenPk = step.startScreen;
    const goalScreenPk = step.goalScreen;
    const shareId = step.importedPrototypeUrl
        ? getPrototypeShareId(step.importedPrototypeUrl)
        : '';
    return { shareId, startScreenPk, goalScreenPk };
}
