import { SCREENER_COPY } from '../locale/steps';
import { localeStringToRichText } from '../locale/utils';
import { cloneStep } from '../userTest';
import * as InstructionStepUtils from '../userTest/instructionStep';
import * as MultipleChoiceStepUtils from '../userTest/multipleChoiceStep';
import { Combinator, IdentityOperator, ScreenerType, StepClass, } from '../userTest/types';
// https://linear.app/ballpark/issue/BALL-961
// eslint-disable-next-line import/no-cycle
import { getRule, initializeConditions, setRequirement, setRule, updateRequirementEqualityOperator, updateRuleDestination, } from './conditionalLogicUtils/choiceStep';
export function removeScreenerRequirement(mutable, deletedChoiceUUID) {
    var _a, _b;
    if (!mutable.conditions) {
        initializeConditions(mutable);
    }
    else if ((_a = mutable.conditions) === null || _a === void 0 ? void 0 : _a.rules[0]) {
        // remove the deleted requirement
        const filteredRules = (_b = mutable.conditions) === null || _b === void 0 ? void 0 : _b.rules[0].requirements.filter((requirement) => {
            return requirement.selectedChoiceUUID !== deletedChoiceUUID;
        });
        mutable.conditions.rules[0].requirements = filteredRules;
    }
}
export function getScreenerNextStep(userTest, currentStepUUID) {
    if (userTest.screeners) {
        const nextStepIndex = userTest.screeners.steps.findIndex((stepUUID) => stepUUID === currentStepUUID) + 1;
        const nextStepUUID = userTest.screeners.steps[nextStepIndex];
        // the destination step will either be the next screener step
        // or the welcome step at the start of the test
        if (nextStepUUID) {
            return nextStepUUID;
        }
    }
    return userTest.welcomeStep.uuid;
}
function getRequirementIndex({ mutable, choiceUUID, }) {
    if (mutable.conditions && mutable.conditions.rules[0]) {
        return mutable.conditions.rules[0].requirements.findIndex((req) => req.selectedChoiceUUID === choiceUUID);
    }
    return -1;
}
export function addScreenerRequirement(step, currentChoiceUUID, userTest) {
    const rule = getRule({
        mutable: step,
        ruleIndex: 0,
    });
    // if theres already a requirement for this choice, early return
    const hasRequirement = getRequirementIndex({
        mutable: step,
        choiceUUID: currentChoiceUUID,
    }) !== -1;
    if (hasRequirement) {
        return;
    }
    const destinationStepUUID = getScreenerNextStep(userTest, step.uuid);
    if (!rule) {
        setRule(step, currentChoiceUUID, destinationStepUUID, IdentityOperator.Is, Combinator.And);
    }
    else {
        setRequirement({
            mutable: step,
            ruleIndex: 0,
            selectedChoiceUUID: currentChoiceUUID,
            operator: IdentityOperator.Is,
            combinator: Combinator.And,
        });
    }
}
export function updateEqualityOperatorOnSetRequirements(userTest) {
    var _a;
    (_a = userTest.screeners) === null || _a === void 0 ? void 0 : _a.steps.forEach((stepUUID) => {
        var _a, _b;
        const step = userTest.stepDefinitions[stepUUID];
        if (step.className === StepClass.MultipleChoice) {
            (_b = (_a = step.conditions) === null || _a === void 0 ? void 0 : _a.rules[0]) === null || _b === void 0 ? void 0 : _b.requirements.forEach((req, index) => {
                if (req.operator === IdentityOperator.IsOkay) {
                    updateRequirementEqualityOperator({
                        mutable: step,
                        ruleIndex: 0,
                        equalityOperatorValue: IdentityOperator.Is,
                        requirementIndex: index,
                    });
                }
            });
        }
    });
}
export function reorderScreenerStep(userTest, startIndex, endIndex) {
    if (userTest.screeners) {
        // update the destination uuid for all screener steps
        const [removed] = userTest.screeners.steps.splice(startIndex, 1);
        // removed may not exist if there is no step to remove
        if (!removed)
            return;
        userTest.screeners.steps.splice(endIndex, 0, removed);
        updateAllDestinationSteps(userTest);
        return removed;
    }
}
export function updateAllDestinationSteps(userTest) {
    if (userTest.screeners) {
        userTest.screeners.steps.forEach((stepUUID) => {
            const step = userTest.stepDefinitions[stepUUID];
            const nextStepUUID = getScreenerNextStep(userTest, step.uuid);
            updateScreenerDestinationStep(userTest, step, nextStepUUID);
        });
    }
}
export function updateScreenerDestinationStep(mutable, stepToUpdate, destinationStepUUID) {
    if (mutable.screeners &&
        (stepToUpdate === null || stepToUpdate === void 0 ? void 0 : stepToUpdate.className) === StepClass.MultipleChoice) {
        updateRuleDestination({
            mutable: stepToUpdate,
            ruleIndex: 0,
            destinationStepUUID,
        });
    }
}
export function addScreenerStep(mutable) {
    let screenerStep = null;
    if (!mutable.screeners) {
        const declineStep = InstructionStepUtils.create({
            title: SCREENER_COPY.Decline.defaultTitle.defaultMessage,
            description: localeStringToRichText(SCREENER_COPY.Decline.defaultDescription.defaultMessage),
            hasButton: false,
        });
        screenerStep = MultipleChoiceStepUtils.create({
            isRequired: true,
            conditions: { ALWAYS: declineStep.uuid, rules: [] },
        });
        mutable.screeners = {
            intro: InstructionStepUtils.create({
                title: SCREENER_COPY.Intro.defaultTitle.defaultMessage,
                description: localeStringToRichText(SCREENER_COPY.Intro.defaultDescription.defaultMessage),
                buttonText: SCREENER_COPY.Intro.defaultButtonText.defaultMessage,
            }),
            steps: [screenerStep.uuid],
            decline: declineStep,
        };
        mutable.stepDefinitions[screenerStep.uuid] = screenerStep;
        // the user can only add up to 5 screener steps
    }
    else if (mutable.screeners && mutable.screeners.steps.length < 5) {
        const declineStepUUID = mutable.screeners.decline.uuid;
        const partialScreenerData = {
            isRequired: true,
        };
        if (declineStepUUID)
            partialScreenerData.conditions = { ALWAYS: declineStepUUID, rules: [] };
        screenerStep = MultipleChoiceStepUtils.create(partialScreenerData);
        mutable.screeners.steps.push(screenerStep.uuid);
        mutable.stepDefinitions[screenerStep.uuid] = screenerStep;
        // a new screener step is added to the end of the screener list
        // we want to update destination step of the one before it to go to this new step
        // because otherwise, it will be pointing to the welcome step (as it was previously the last step in the list)
        const stepBeforeCurrent = mutable.stepDefinitions[mutable.screeners.steps[mutable.screeners.steps.length - 2]];
        updateScreenerDestinationStep(mutable, stepBeforeCurrent, screenerStep.uuid);
    }
    return screenerStep !== null && screenerStep !== void 0 ? screenerStep : null;
}
export function cloneScreenerStep(mutable, step, insertionIndex) {
    // there can only be up to 5 screener steps
    if (mutable.screeners && mutable.screeners.steps.length < 5) {
        const newStep = cloneStep(step, true);
        mutable.screeners.steps.splice(insertionIndex, 0, newStep.uuid);
        mutable.stepDefinitions[newStep.uuid] = newStep;
        updateAllDestinationSteps(mutable);
    }
}
export function deleteScreenerStep(mutable, stepUUID) {
    const index = getIndex(mutable, stepUUID);
    if (mutable.screeners) {
        mutable.screeners.steps.splice(index, 1);
        if (mutable.screeners.steps.length === 0) {
            mutable.screeners = null;
        }
    }
    delete mutable.stepDefinitions[stepUUID];
    updateAllDestinationSteps(mutable);
}
export function getIndex(mutable, stepUUID) {
    if (mutable.screeners) {
        return mutable.screeners.steps.findIndex((screenerStepUUID) => screenerStepUUID === stepUUID);
    }
    return -1;
}
export function getIndexFromAllScreenerSteps(mutable, stepUUID) {
    if (mutable.screeners) {
        return [
            mutable.screeners.intro.uuid,
            ...mutable.screeners.steps,
            mutable.screeners.decline.uuid,
        ].findIndex((screenerStepUUID) => screenerStepUUID === stepUUID);
    }
    return -1;
}
export function isIntroStep(mutable, stepUUID) {
    if (!mutable.screeners) {
        return false;
    }
    return mutable.screeners.intro.uuid === stepUUID;
}
export function isDeclineStep(mutable, stepUUID) {
    if (!mutable.screeners) {
        return false;
    }
    return mutable.screeners.decline.uuid === stepUUID;
}
export function isScreenerStep(mutable, stepUUID) {
    if (!mutable.screeners) {
        return false;
    }
    if (isIntroStep(mutable, stepUUID) || isDeclineStep(mutable, stepUUID)) {
        return true;
    }
    return !!mutable.screeners.steps.find((screenerStepUUID) => screenerStepUUID === stepUUID);
}
export function getStepName(mutable, stepUUID) {
    var _a, _b;
    if (((_a = mutable.screeners) === null || _a === void 0 ? void 0 : _a.intro.uuid) === stepUUID) {
        return ScreenerType.Introduction;
    }
    if (((_b = mutable.screeners) === null || _b === void 0 ? void 0 : _b.decline.uuid) === stepUUID) {
        return ScreenerType.Declined;
    }
    return ScreenerType.Screener;
}
export function getAllStepUUIDs(mutable) {
    if (mutable.screeners) {
        return [
            mutable.screeners.intro.uuid,
            ...mutable.screeners.steps,
            mutable.screeners.decline.uuid,
        ];
    }
    return [];
}
export function getMiddleScreenerStepUUIDs(userTest) {
    if (userTest.screeners) {
        return userTest.screeners.steps;
    }
    return [];
}
export function setChoiceValue({ step, uuid, value, mutable, }) {
    MultipleChoiceStepUtils.setChoiceValue(step, uuid, value);
    if (value && value.length > 0) {
        addScreenerRequirement(step, uuid, mutable);
    }
    else {
        removeScreenerRequirement(step, uuid);
    }
}
export function removeChoice({ step, uuid, }) {
    removeScreenerRequirement(step, uuid);
    MultipleChoiceStepUtils.removeChoice(step, uuid);
}
export function updateEqualityOperator({ mutable, equalityOperatorValue, choiceUUID, }) {
    if (mutable.conditions) {
        const requirementIndex = getRequirementIndex({
            mutable,
            choiceUUID,
        });
        if (requirementIndex !== -1 && mutable.conditions.rules[0]) {
            mutable.conditions.rules[0].requirements[requirementIndex].operator =
                equalityOperatorValue;
        }
    }
    else {
        initializeConditions(mutable);
    }
}
export function getRequirement({ mutable, choiceUUID, }) {
    var _a;
    const requirementIndex = getRequirementIndex({
        mutable,
        choiceUUID,
    });
    if (mutable.conditions && mutable.conditions.rules[0]) {
        return (_a = mutable.conditions.rules[0].requirements[requirementIndex]) !== null && _a !== void 0 ? _a : null;
    }
    return null;
}
export function isScreenersSet(userTest) {
    return !!userTest.screeners;
}
export function hasMaxNoOfScreenerSteps(userTest) {
    var _a, _b;
    return ((_b = (_a = userTest.screeners) === null || _a === void 0 ? void 0 : _a.steps.length) !== null && _b !== void 0 ? _b : 0) >= 5;
}
export function isEqualityOperator(value) {
    return Object.values(IdentityOperator).includes(value);
}
export function getChoiceUUIDByOperator(step, v) {
    var _a, _b, _c;
    // Screener steps will only ever have one rule, with multiple requirements
    const requirement = (_b = (_a = step.conditions) === null || _a === void 0 ? void 0 : _a.rules[0]) === null || _b === void 0 ? void 0 : _b.requirements.find((requirement) => requirement.operator === v);
    return (_c = requirement === null || requirement === void 0 ? void 0 : requirement.selectedChoiceUUID) !== null && _c !== void 0 ? _c : null;
}
