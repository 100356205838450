import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import LogRocket from 'logrocket';
import { observer } from 'mobx-react';
import { useEffect } from 'react';
import { Stack } from '@marvelapp/ballpark-components';
import { SCREENER_METRICS } from '@marvelapp/ballpark-help';
import { getMainScreenerSteps } from '@marvelapp/user-test-creator';
import { StepCard } from './StepSummary';
import { SummaryStats } from './SummaryStats';
export const ScreenersSummary = observer(function ScreenersSummary({ userTestProject, isUserTestProjectLoading, activeSlideUUID, stepStats, totalStarts, userTestDoc, }) {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k;
    const screenerSteps = getMainScreenerSteps(userTestDoc);
    if (!screenerSteps.length) {
        return null;
    }
    const stats = [
        {
            name: 'Screened',
            testId: 'total-screened',
            count: (_b = (_a = userTestProject === null || userTestProject === void 0 ? void 0 : userTestProject.userTest) === null || _a === void 0 ? void 0 : _a.resultStats) === null || _b === void 0 ? void 0 : _b.screeningCount,
        },
        {
            name: 'Qualified',
            testId: 'total-qualified',
            count: (_d = (_c = userTestProject === null || userTestProject === void 0 ? void 0 : userTestProject.userTest) === null || _c === void 0 ? void 0 : _c.resultStats) === null || _d === void 0 ? void 0 : _d.qualifiedCount,
            percentage: (_f = (_e = userTestProject === null || userTestProject === void 0 ? void 0 : userTestProject.userTest) === null || _e === void 0 ? void 0 : _e.resultStats) === null || _f === void 0 ? void 0 : _f.qualifiedPercentage,
        },
        {
            name: 'Disqualified',
            testId: 'total-disqualified',
            count: (_h = (_g = userTestProject === null || userTestProject === void 0 ? void 0 : userTestProject.userTest) === null || _g === void 0 ? void 0 : _g.resultStats) === null || _h === void 0 ? void 0 : _h.disqualifiedCount,
            percentage: (_k = (_j = userTestProject === null || userTestProject === void 0 ? void 0 : userTestProject.userTest) === null || _j === void 0 ? void 0 : _j.resultStats) === null || _k === void 0 ? void 0 : _k.disqualifiedPercentage,
        },
    ];
    return (_jsxs(_Fragment, { children: [_jsx(LogrocketTrackScreenersSummary, {}), _jsx(SummaryStats, { areStatsLoading: isUserTestProjectLoading, stats: stats, helpLink: SCREENER_METRICS, title: "Screener performance", testId: "screener-summary-stats" }), screenerSteps.map((step) => {
                var _a, _b;
                return (_jsx(Stack, { className: "col-span-6 col-end-10", children: _jsx(StepCard, { hasOpenAiSummary: false, isActiveStep: step.uuid === activeSlideUUID, totalStarts: totalStarts, step: step, stepStats: stepStats, userTest: userTestDoc, isScreenerStep: true, backingProjectPk: (_b = (_a = userTestProject === null || userTestProject === void 0 ? void 0 : userTestProject.userTest) === null || _a === void 0 ? void 0 : _a.prototype) === null || _b === void 0 ? void 0 : _b.pk }, step.uuid) }));
            })] }));
});
const LogrocketTrackScreenersSummary = observer(function LogrocketTrackConditionalLogic() {
    useEffect(() => {
        var _a;
        if ((_a = process.env.DEPLOY_ENV) === null || _a === void 0 ? void 0 : _a.includes('production')) {
            LogRocket.track('screeners-summary');
        }
    }, []);
    return null;
});
