import { useMutation } from '@apollo/client';
import update from 'immutability-helper';
import { showErrorNotification } from '../ToastContainer';
import { getCollaborators, onRemoveCollaborators, queryMeta } from './queries';
const toastId = 'removeCollaborators';
export default function useRemoveCollaboratorsMutation(itemName, itemId) {
    const { removeMemberMutation, removeMemberMutationResponseType } = queryMeta[itemName];
    const [removeCollaborators, ...rest] = useMutation(onRemoveCollaborators(itemName));
    return [
        ({ itemPk, items }) => {
            removeCollaborators({
                variables: {
                    itemPk,
                    items,
                },
                update: (cache, response) => {
                    const removedCollaborators = response.data[removeMemberMutation].succeeded;
                    cacheRemoveCollaborators(cache, {
                        itemId,
                        removedCollaborators,
                        itemName,
                    });
                },
                optimisticResponse: {
                    [removeMemberMutation]: {
                        __typename: removeMemberMutationResponseType,
                        succeeded: items,
                        failed: [],
                    },
                },
            })
                .then(({ data }) => {
                if (data[removeMemberMutation].failed.length) {
                    showErrorNotification({
                        toastId,
                        content: `There was an issue trying to remove user from collaborators list on this ${itemName}. Please try again later`,
                    });
                }
            })
                .catch(() => {
                showErrorNotification({
                    toastId,
                    content: 'Oops, something went wrong. Try again later',
                });
            });
        },
        ...rest,
    ];
}
export function cacheRemoveCollaborators(cache, { itemId, removedCollaborators, itemName }) {
    const { nodeIndexKey } = queryMeta[itemName];
    const queryProps = {
        query: getCollaborators(itemName),
        variables: { itemId },
    };
    const data = cache.readQuery(queryProps);
    const updatedData = update(data, {
        [itemName]: {
            collaborators: {
                edges: (edges) => edges.filter((memberEdge) => removedCollaborators.indexOf(memberEdge.node[nodeIndexKey]) ===
                    -1),
            },
        },
    });
    cache.writeQuery(Object.assign(Object.assign({}, queryProps), { data: updatedData }));
}
