import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { observer } from 'mobx-react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import * as React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useAction, useSlideUIContext } from '@marvelapp/ballpark-application';
import { BtwButton, BtwInput, BtwLink, BtwModal, } from '@marvelapp/ballpark-components';
import { CardSortingUtils, } from '@marvelapp/user-test-creator';
import { CARD_DESCRIPTION_MAX_LENGTH, CARD_TITLE_MAX_LENGTH, CATEGORY_TITLE_MAX_LENGTH, } from '../constants';
import { urlManager } from './URLManager';
export const BulkAddItemsModal = observer(function BulkAddItemsModal({ entity, isOpen, onOpenChange, }) {
    const intl = useIntl();
    const { step } = useSlideUIContext();
    const [input, setInput] = useState('');
    const handleInputChange = useCallback((e) => setInput(e.target.value), [setInput]);
    const [inputErrorMessage, setInputErrorMessage] = useState('');
    const translations = useMemo(() => getTranslations(intl)[entity], [entity, intl]);
    useEffect(() => {
        if (!isOpen) {
            setInput('');
            setInputErrorMessage('');
        }
    }, [isOpen]);
    const handleImport = useAction(() => {
        if (!input.trim()) {
            setInputErrorMessage(translations.errorMessage);
            return;
        }
        setInputErrorMessage('');
        processBulkImport({
            input,
            entity,
            step,
        });
        onOpenChange(false);
    }, [input, entity, onOpenChange, step, translations]);
    const textAreaId = `bulk-add-${entity}-textarea`;
    return (_jsx(BtwModal.Root, { open: isOpen, onOpenChange: onOpenChange, children: _jsxs(BtwModal.Content, { "data-testid": `bulk-add-${entity}`, children: [_jsx(BtwModal.BigHeader, { onlyChild: false, title: translations.title, titleTestId: `bulk-add-${entity}-title`, description: translations.description }), _jsx(BtwModal.Body, { children: _jsxs(BtwInput.Root, { children: [_jsx(BtwInput.Label, { htmlFor: textAreaId, children: translations.textareaLabel }), _jsx(BtwInput.Textarea, { className: "min-h-28 !font-mono", value: input, onChange: handleInputChange, id: textAreaId, "data-testid": textAreaId, placeholder: intl.formatMessage({ id: "tXx4nZ", defaultMessage: "Paste your list here" }) }), inputErrorMessage ? (_jsx(BtwInput.Error, { "data-testid": `bulk-add-${entity}-error-message`, children: inputErrorMessage })) : (_jsx(BtwInput.Info, { "data-testid": `bulk-add-${entity}-instructions`, children: translations.instructions }))] }) }), _jsxs(BtwModal.Footer, { children: [_jsx(BtwModal.Close, { asChild: true, children: _jsx(BtwButton, { variant: "secondary", "data-testid": `bulk-add-${entity}-cancel-button`, children: _jsx(FormattedMessage, { id: 'cuuad5', defaultMessage: 'Cancel' }) }) }), _jsx(BtwButton, { variant: "primary", onClick: handleImport, "data-testid": `bulk-add-${entity}-import-button`, children: translations.importButton })] })] }) }));
});
function getTranslations(intl) {
    return {
        card: {
            title: (_jsx(FormattedMessage, { id: '+ox9X/', defaultMessage: 'Bulk add cards' })),
            description: (_jsx(FormattedMessage, { id: 'Qsvpfc', defaultMessage: 'Paste a pre-defined list of cards to quickly add them' })),
            textareaLabel: (_jsx(FormattedMessage, { id: 'Ixvsg+', defaultMessage: 'Cards' })),
            errorMessage: intl.formatMessage({ id: "mVqS4N", defaultMessage: "Please enter at least one card to import" }),
            instructions: (_jsx(FormattedMessage, { id: 'UYrtBj', defaultMessage: 'Separate titles/descriptions with a tab or comma, place each card on a new line. If you need help, you can <link>download an example file here</link>', values: {
                    link: (chunks) => (_jsx(BtwLink, { variant: "secondary", href: generateExampleCardURL(), target: "_blank", underline: true, children: chunks })),
                } })),
            importButton: (_jsx(FormattedMessage, { id: 'SUdwEH', defaultMessage: 'Import cards' })),
        },
        category: {
            title: (_jsx(FormattedMessage, { id: '1PySts', defaultMessage: 'Bulk add categories' })),
            description: (_jsx(FormattedMessage, { id: 'GgXb0d', defaultMessage: 'Paste a pre-defined list of categories to quickly add them' })),
            textareaLabel: (_jsx(FormattedMessage, { id: '7nKNAa', defaultMessage: 'Categories' })),
            errorMessage: intl.formatMessage({ id: "eEgA3L", defaultMessage: "Please enter at least one category to import" }),
            instructions: (_jsx(FormattedMessage, { id: 'X6vrqb', defaultMessage: 'Each category can either be separated by a tab or a new line. If you need help, you can <link>download an example file here</link>', values: {
                    link: (chunks) => (_jsx(BtwLink, { variant: "secondary", href: generateExampleCategoryURL(), target: "_blank", underline: true, children: chunks })),
                } })),
            importButton: (_jsx(FormattedMessage, { id: 'v0Q7Jt', defaultMessage: 'Import categories' })),
        },
    };
}
function generateExampleCardURL() {
    const content = `Card Title 1\tThis is a description for Card 1
Card Title 2
Card Title 3\tThis card has a longer description that cannot spans multiple lines.
Card Title 4\tAnother card with a description
Card Title 5,This card uses a comma as separator`;
    return urlManager.getOrCreateURL('example-cards', content);
}
function generateExampleCategoryURL() {
    const content = `Category 1
Category 2
Category 3
Category 4`;
    return urlManager.getOrCreateURL('example-categories', content);
}
export function processBulkImport({ input, entity, step, }) {
    if (entity === 'category') {
        const existingCategories = new Map(CardSortingUtils.getCategories(step).map((category) => [
            getTitleKey(category.title),
            category,
        ]));
        // Create a map to deduplicate new categories while preserving first occurrence's case
        const newCategoriesMap = new Map();
        input
            .trim()
            .split(/[\n\t]+/)
            .map((line) => trimTitle(line))
            .filter((line) => line !== '')
            .forEach((category) => {
            const key = getTitleKey(category);
            if (!newCategoriesMap.has(key) && !existingCategories.has(key)) {
                newCategoriesMap.set(key, category);
            }
        });
        Array.from(newCategoriesMap.values()).forEach((category) => {
            const categoryUUID = CardSortingUtils.addCategory(step);
            const categoryTitle = category.slice(0, CATEGORY_TITLE_MAX_LENGTH);
            CardSortingUtils.updateCategory(step, categoryUUID, {
                title: categoryTitle,
            });
            existingCategories.set(getTitleKey(categoryTitle), CardSortingUtils.getCategory(step, categoryUUID));
        });
    }
    else {
        const existingCards = new Map(CardSortingUtils.getCards(step).map((card) => [
            getTitleKey(card.title),
            card,
        ]));
        const parseLine = (line) => {
            let title;
            let descriptionParts;
            const delimiter = line.includes('\t') ? '\t' : ',';
            // eslint-disable-next-line prefer-const
            [title, ...descriptionParts] = line.split(delimiter);
            title = trimTitle(title);
            if (!title)
                return null;
            return {
                title,
                description: descriptionParts.length > 0
                    ? descriptionParts.join(delimiter).trim()
                    : undefined,
            };
        };
        // Create a map to deduplicate new cards while preserving first occurrence
        const newCardsMap = new Map();
        input
            .trim()
            .split('\n')
            .map(parseLine)
            .filter((parsed) => parsed !== null && getTitleKey(parsed.title) !== '')
            .forEach((parsed) => {
            const key = getTitleKey(parsed.title);
            if (!newCardsMap.has(key) && !existingCards.has(key)) {
                newCardsMap.set(key, parsed);
            }
        });
        Array.from(newCardsMap.values()).forEach(({ title, description }) => {
            const cardUUID = CardSortingUtils.addCard(step);
            const cardTitle = title.slice(0, CARD_TITLE_MAX_LENGTH);
            CardSortingUtils.updateCard(step, cardUUID, {
                title: cardTitle,
                description: description
                    ? [
                        {
                            type: 'p',
                            children: [
                                {
                                    text: description.slice(0, CARD_DESCRIPTION_MAX_LENGTH),
                                },
                            ],
                        },
                    ]
                    : undefined,
            });
            existingCards.set(getTitleKey(cardTitle), CardSortingUtils.getCard(step, cardUUID));
        });
    }
}
function trimTitle(title) {
    return (title !== null && title !== void 0 ? title : '').trim();
}
function getTitleKey(title) {
    return trimTitle(title).toLowerCase();
}
