import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { useFeatureFlag } from '@marvelapp/ballpark-application';
import { Transcription as TranscriptionPrimitive } from '@marvelapp/ballpark-components';
import { formatTime } from '@marvelapp/core';
export const Transcription = observer(function Transcription({ transcriptionURL, time, className, onSeekTo, }) {
    const { transcription, summary } = useLoadTranscription(transcriptionURL);
    const isSummaryEnabled = useFeatureFlag('media-transcription-summary');
    const hasSummary = summary && isSummaryEnabled;
    if (!transcription.length)
        return null;
    const activeIndex = getActiveTranscriptionIndex(transcription, time);
    const activeChapterIndex = hasSummary
        ? getActiveChapterIndex(summary, time)
        : -1;
    return (_jsxs(TranscriptionPrimitive.Root, { className: className, defaultTab: hasSummary ? 'Summary' : 'Transcription', children: [summary && isSummaryEnabled && (_jsx(TranscriptionPrimitive.SummaryTab, { children: _jsx(TranscriptionPrimitive.Summary, { activeChapterIndex: activeChapterIndex, summary: summary.content, onSeekTo: onSeekTo }) })), transcription.length > 0 && (_jsx(TranscriptionPrimitive.TranscriptionTab, { children: transcription.map((segment, index) => (_jsx(TranscriptionPrimitive.Item, { index: index, isActive: index === activeIndex, text: segment.text, timestamp: formatTime(segment.start, false), onClick: () => onSeekTo === null || onSeekTo === void 0 ? void 0 : onSeekTo(segment.start) }, segment.id))) }))] }));
});
function useLoadTranscription(transcriptionUrl) {
    const [transcription, setTranscription] = useState([]);
    const [summary, setSummary] = useState();
    useEffect(() => {
        if (!transcriptionUrl)
            return;
        fetch(transcriptionUrl)
            .then((response) => response.json())
            .then((data) => {
            const { transcript, summary } = parseTranscription(data) || {};
            if (transcript)
                setTranscription(formatTranscription(transcript));
            if (summary)
                setSummary(summary);
        })
            .catch((error) => {
            console.error('Error fetching transcription', error);
        });
    }, [transcriptionUrl]);
    return { transcription, summary };
}
function formatTranscription(transcription) {
    var _a, _b, _c, _d, _e, _f;
    const paragraphs = (_f = (_e = (_d = (_c = (_b = (_a = transcription.results) === null || _a === void 0 ? void 0 : _a.channels) === null || _b === void 0 ? void 0 : _b[0]) === null || _c === void 0 ? void 0 : _c.alternatives) === null || _d === void 0 ? void 0 : _d[0]) === null || _e === void 0 ? void 0 : _e.paragraphs) === null || _f === void 0 ? void 0 : _f.paragraphs;
    if (!paragraphs)
        return [];
    return paragraphs.map((paragraph) => ({
        id: paragraph.start.toString(),
        isActive: false,
        start: paragraph.start,
        end: paragraph.end,
        text: paragraph.sentences.map((sentence) => sentence.text).join('\n'),
    }));
}
function getActiveTranscriptionIndex(transcription, time) {
    return binarySearch(transcription, (segment) => segment.start <= time && segment.end >= time, (segment) => segment.end < time, false);
}
function getActiveChapterIndex(summary, time) {
    const { chapters } = summary.content;
    if (!chapters.length || time < chapters[0].timestamp)
        return -1;
    return binarySearch(chapters, (chapter) => chapter.timestamp <= time, (chapter) => chapter.timestamp < time, true);
}
/**
 * Binary search that finds an element based on a predicate and navigation function
 * @param array The array to search in
 * @param predicate Function that returns true for matching elements
 * @param navigate Function that determines search direction when no match is found
 * @param findLast If true, continues searching after finding a match
 */
function binarySearch(array, predicate, navigate, findLast = false) {
    if (!array.length)
        return -1;
    let left = 0;
    let right = array.length - 1;
    let result = -1;
    while (left <= right) {
        const mid = Math.floor((left + right) / 2);
        const item = array[mid];
        if (predicate(item)) {
            result = mid;
            if (findLast) {
                left = mid + 1;
            }
            else {
                // Return immediately if we're not looking for last match
                return mid;
            }
        }
        else if (navigate(item)) {
            left = mid + 1;
        }
        else {
            right = mid - 1;
        }
    }
    return result;
}
function parseTranscription(data) {
    if (!data)
        return null;
    if (data && 'version' in data) {
        return data;
    }
    return { transcript: data };
}
