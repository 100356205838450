import { StepClass } from '../userTest/types';
import { AnswerType } from './types';
export function create(stepUUID, stepRevision) {
    return {
        className: StepClass.PreferenceTest,
        stepUUID,
        stepRevision,
        answer: {
            type: AnswerType.MultipleChoice,
            value: [],
        },
    };
}
export function setAnswer(mutable, value) {
    mutable.answer = {
        type: AnswerType.MultipleChoice,
        value,
    };
}
export * from './choiceStepResponseUtils';
