import { __awaiter } from "tslib";
import { gql, useMutation } from '@apollo/client';
import { Room as LivekitRoom } from 'livekit-client';
import { useCallback, useEffect, useMemo, useRef } from 'react';
import { useAction, useConfig } from '@marvelapp/ballpark-application';
import { showErrorNotification, showGenericServerError, } from '@marvelapp/ui-internal';
import { LiveKitEgressTypeEnum, } from '../__generated__/queryTypes';
import { useSetupEventLogging } from './useSetupEventLogging';
// I couldn't figure out a way of doing this with cy.stub ¯\_(ツ)_/¯
const Room = (window === null || window === void 0 ? void 0 : window.Cypress) ? window.Room : LivekitRoom;
const CREATE_ROOM = gql `
  mutation createRoom(
    $testUUID: String
    $stepUUID: String
    $resultUUID: String
  ) {
    createRoom(
      testUUID: $testUUID
      stepUUID: $stepUUID
      resultUUID: $resultUUID
    ) {
      room {
        token
        name
      }
      ok
      error {
        code
        message
      }
    }
  }
`;
const startEgressService = gql `
  mutation startVideoEgress($input: StartVideoEgressInput!) {
    startVideoEgress(input: $input) {
      error {
        code
        message
      }
      egressId
    }
  }
`;
const stopEgressService = gql `
  mutation stopVideoEgress($roomName: String!) {
    stopVideoEgress(roomName: $roomName) {
      ok
      error {
        message
        code
      }
    }
  }
`;
// TODO split out the live session logic into a separate hook
export function useMediaRecorder({ resultUUID, testUUID, stepUUID, onStartRecording, cameraTrack, audioTrack, screenTrack, mock, roomToken, response, isRecordingEnabled, }) {
    var _a;
    const isLiveSession = !!roomToken;
    const config = useConfig();
    const hasBeenCalled = useRef(false);
    const room = useMemo(() => (isRecordingEnabled && Room ? new Room() : undefined), [isRecordingEnabled]);
    useSetupEventLogging(room, response);
    const setupRoom = useCallback((token) => __awaiter(this, void 0, void 0, function* () {
        if (typeof room === 'undefined') {
            throw new Error('room is not available');
        }
        if (window.Cypress) {
            window.__room = room;
        }
        const wsURL = config.livekit.url;
        try {
            yield room.connect(wsURL, token);
            if (!(room === null || room === void 0 ? void 0 : room.name)) {
                // We require a room name to look up the TestRecording object in the database
                throw new Error('Room name is not available');
            }
            return room;
        }
        catch (error) {
            showErrorNotification({
                toastId: 'roomConnection',
                content: 'We were unable to set up your recording. Please refresh the page and try again.',
            });
            throw error;
        }
    }), [config.livekit.url, room]);
    const [createRoom, { error }] = useMutation(CREATE_ROOM, {
        variables: {
            testUUID,
            stepUUID,
            resultUUID,
        },
    });
    if (error) {
        showGenericServerError({
            toastId: 'CreateRoomError',
        });
    }
    const [startEgress, { loading: isEgressLoading }] = useStartEgress();
    const [stopEgress, { data, error: egressError }] = useStopEgress(room !== null && room !== void 0 ? room : null);
    if (egressError)
        console.error(egressError);
    if (data === null || data === void 0 ? void 0 : data.stopVideoEgress.error)
        console.error(data === null || data === void 0 ? void 0 : data.stopVideoEgress.error);
    const stopRecording = useAction(() => {
        if (hasBeenCalled.current)
            return;
        if (room === null || room === void 0 ? void 0 : room.isRecording) {
            stopEgress();
        }
        hasBeenCalled.current = true;
    }, [room, stopEgress]);
    useEffect(() => {
        const handleBeforeUnload = (event) => {
            const isRecordingMedia = room && room.localParticipant.trackPublications.size > 0;
            if (isRecordingMedia && !isLiveSession) {
                stopRecording();
                event.preventDefault();
            }
        };
        window.addEventListener('beforeunload', handleBeforeUnload);
        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, [isLiveSession, room, stopRecording]);
    const startRecording = useAction(() => __awaiter(this, void 0, void 0, function* () {
        var _a, _b;
        const tracks = [];
        if (screenTrack)
            tracks.push(screenTrack);
        if (audioTrack)
            tracks.push(audioTrack);
        if (cameraTrack)
            tracks.push(cameraTrack);
        let room;
        if (isLiveSession) {
            // room already exists if we have a roomToken
            room = yield setupRoom(roomToken);
        }
        else {
            const response = yield createRoom();
            room = yield setupRoom(response.data.createRoom.room.token);
        }
        if (!room) {
            throw new Error('room wasnt initialized in time to start Egress');
        }
        yield Promise.all(tracks.map((track) => {
            if (!isLiveSession) {
                track.mediaStreamTrack.addEventListener('ended', () => __awaiter(this, void 0, void 0, function* () {
                    yield room.localParticipant.unpublishTrack(track);
                    if (room.localParticipant.trackPublications.size === 0) {
                        // can't call stopRecording() in here because the room object isn't populated
                        // at the time of setting up this event listener
                        stopEgress();
                    }
                }));
            }
            return room.localParticipant.publishTrack(track);
        }));
        if (isLiveSession) {
            // In live sessions recording does not automatically start when the room
            // is created by the participant. Egress is started by the moderator.
            onStartRecording(room.name);
            return;
        }
        const variables = getVariablesPerEgressType({
            roomName: room.name,
            ids: {
                cameraId: cameraTrack === null || cameraTrack === void 0 ? void 0 : cameraTrack.sid,
                screenId: screenTrack === null || screenTrack === void 0 ? void 0 : screenTrack.sid,
                audioId: audioTrack === null || audioTrack === void 0 ? void 0 : audioTrack.sid,
            },
        });
        const { data } = yield startEgress({ variables });
        if (!isEgressLoading &&
            data &&
            !((_a = data.startVideoEgress) === null || _a === void 0 ? void 0 : _a.error) &&
            ((_b = data.startVideoEgress) === null || _b === void 0 ? void 0 : _b.egressId)) {
            onStartRecording(room.name);
        }
        if (error) {
            showErrorNotification({
                toastId: 'egressError',
                content: 'We are unable to start the recording. Please refresh the page and try again.',
            });
        }
    }), [
        screenTrack,
        audioTrack,
        cameraTrack,
        roomToken,
        isLiveSession,
        startEgress,
        isEgressLoading,
        error,
        setupRoom,
        createRoom,
        stopEgress,
        onStartRecording,
    ]);
    const returnValues = {
        startRecordingMedia: startRecording,
        stopRecordingMedia: stopRecording,
        // TODO don't need to return localParticipant if returning room
        localParticipant: (_a = room === null || room === void 0 ? void 0 : room.localParticipant) !== null && _a !== void 0 ? _a : null,
        room,
    };
    const mockReturnValues = {
        startRecordingMedia: () => __awaiter(this, void 0, void 0, function* () {
            onStartRecording('mock-room-name');
        }),
        stopRecordingMedia: useAction(() => {
            cameraTrack === null || cameraTrack === void 0 ? void 0 : cameraTrack.stop();
            audioTrack === null || audioTrack === void 0 ? void 0 : audioTrack.stop();
            screenTrack === null || screenTrack === void 0 ? void 0 : screenTrack.stop();
        }, [audioTrack, cameraTrack, screenTrack]),
        localParticipant: null,
        room: null,
    };
    return mock ? mockReturnValues : returnValues;
}
export function useStartEgress() {
    return useMutation(startEgressService);
}
export function useStopEgress(room, onCompleted) {
    var _a;
    return useMutation(stopEgressService, {
        variables: { roomName: (_a = room === null || room === void 0 ? void 0 : room.name) !== null && _a !== void 0 ? _a : '' },
        onCompleted,
    });
}
function getVariablesPerEgressType({ roomName, ids, }) {
    const { screenId, audioId, cameraId } = ids;
    if (screenId && audioId && cameraId) {
        return {
            input: {
                egressType: LiveKitEgressTypeEnum.ROOM_COMPOSITE_EGRESS,
                roomName,
            },
        };
    }
    if (screenId || cameraId || audioId) {
        return {
            input: {
                videoId: screenId || cameraId,
                audioId,
                egressType: LiveKitEgressTypeEnum.TRACK_COMPOSITE_EGRESS,
                roomName,
            },
        };
    }
}
