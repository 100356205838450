import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { observer } from 'mobx-react';
import * as React from 'react';
import { Icon, Label } from '@marvelapp/ballpark-components';
import { PLUS_ICON } from '@marvelapp/ballpark-theme';
import styled from '@marvelapp/styled';
import { Box } from '@marvelapp/ui';
import DottedBox from '../../components/DottedBox';
export default observer(function AddTaglineCTA({ addChoice, heading, isDisabled, step, subheading, testId, }) {
    return (_jsx(_Fragment, { children: _jsxs(DottedBox, { role: "button", isDisabled: isDisabled, onClick: () => addChoice(step, {
                heading: null,
                paragraph: [],
            }), testId: testId, height: "auto", children: [_jsx(PlusIconWrapper, { children: _jsx(Icon, { paths: PLUS_ICON }) }), heading && _jsx(Label, { marginTop: "s", children: heading }), subheading && (_jsx(Label, { size: "s", marginTop: "xs", kind: "secondary", children: subheading }))] }) }));
});
const PlusIconWrapper = styled(Box).attrs({
    bg: 'slate7',
    color: 'background',
    borderRadius: 'circle',
    marginBottom: 'xs',
}) `
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
