import { __rest } from "tslib";
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import theme, { CHECKBOX_TICK_ICON, MINUS_ICON, } from '@marvelapp/ballpark-theme';
import Box from '../Box';
import Icon from '../Icon';
import { Control, Indicator, Input, PinAll } from './styles';
/** @param {any} */
export default function Checkbox(_a) {
    var { bg, border, borderColor, borderRadius, borderWidth, boxShadow, children, color, display, fontSize, hoverColor, iconColor, isChecked, isDisabled, kind, mr, onChange, size, testId, type = 'checkbox' } = _a, rest = __rest(_a, ["bg", "border", "borderColor", "borderRadius", "borderWidth", "boxShadow", "children", "color", "display", "fontSize", "hoverColor", "iconColor", "isChecked", "isDisabled", "kind", "mr", "onChange", "size", "testId", "type"]);
    const isRadio = type === 'radio';
    const isIndeterminate = isChecked === null;
    return (_jsxs(Control, { color: color, cursor: isDisabled ? 'default' : 'pointer', "data-testid": testId, display: display, fontSize: fontSize, height: size, hoverColor: hoverColor, isDisabled: isDisabled, lineHeight: `${size}px`, mr: mr, pl: children ? size + size / 2 : size, children: [_jsx(Input, Object.assign({ checked: isChecked, onChange: onChange, readOnly: !onChange, type: type, disabled: isDisabled }, rest)), _jsx(Indicator, { borderRadius: isRadio ? '50%' : borderRadius, borderWidth: borderWidth, height: size, isChecked: isChecked, isIndeterminate: isIndeterminate, kind: isDisabled ? 'disabled' : kind, size: size, width: size }), _jsx(PinAll, { height: size, isActive: isChecked, opacity: isChecked ? 1 : 0, width: size, children: isRadio ? (_jsx(Box, { bg: iconColor, borderRadius: "50%", boxShadow: "popover.default", height: size / 2 - size / 10, width: size / 2 - size / 10 })) : (_jsx(Icon, { color: iconColor, height: size, paths: CHECKBOX_TICK_ICON, width: size })) }), _jsx(PinAll, { color: kind === 'defaultGreenReversed' ? 'white' : 'silver', height: size, isActive: isIndeterminate, width: size, children: _jsx(Icon, { height: size, paths: MINUS_ICON, width: size }) }), children] }));
}
Checkbox.defaultProps = {
    theme,
    borderRadius: 1,
    borderWidth: 1,
    color: 'slate',
    display: 'inline-block',
    hoverColor: 'licorice',
    iconColor: 'white',
    isChecked: false,
    isDisabled: false,
    kind: 'defaultGreen',
    size: 24,
};
