import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { observer } from 'mobx-react';
import { useCallback, useEffect, useRef, useState } from 'react';
import * as React from 'react';
import { Icon, SquareButton } from '@marvelapp/ballpark-components';
import { SETTINGS_ICON } from '@marvelapp/ballpark-theme';
import { useOnClickOutside } from '@marvelapp/hooks';
import { getVideoAndAudioDeviceIds, getVideoAndAudioDevices, } from '@marvelapp/media-recording';
import styled from '@marvelapp/styled';
import { Box } from '@marvelapp/ui';
import { SelectWebcamMicrophoneSettings } from '../MediaAccess/SelectWebcamMicrophoneSettings';
import { StartRecordingButton, StopRecordingButton } from './RecordingButtons';
// We're disablind audio only for now, as it's not stable enough
// TODO: To be re-enabled soon
// /cc https://marvelapp.slack.com/archives/C03PSMNST/p1637759353370400
export const FooterReady = observer(function FooterReady({ isAcquiringMedia, isLoading, isRecordingStarting, onRecord, onUpdateVideoDeviceId, onUpdateAudioDeviceId, mediaStream, }) {
    const ref = useRef();
    const [settingsActive, setSettingsActive] = useState(false);
    const [videoDevices, setVideoDevices] = useState([]);
    const [audioDevices, setAudioDevices] = useState([]);
    const [videoDeviceId, setVideoDeviceId] = useState();
    const [audioDeviceId, setAudioDeviceId] = useState();
    useEffect(() => {
        if (!mediaStream)
            return;
        const { video, audio } = getVideoAndAudioDeviceIds(mediaStream);
        setVideoDeviceId(video);
        setAudioDeviceId(audio);
    }, [mediaStream]);
    const onAudioDeviceChange = useCallback((event) => {
        const newAudioDeviceId = event.target.value;
        setAudioDeviceId(newAudioDeviceId);
        onUpdateAudioDeviceId(newAudioDeviceId);
        try {
            localStorage.setItem('videoGuidesAudioDeviceId', newAudioDeviceId);
        }
        catch (e) {
            // Silent fail
        }
    }, [onUpdateAudioDeviceId]);
    const onVideoDeviceChange = useCallback((event) => {
        const newVideoDeviceId = event.target.value;
        setVideoDeviceId(newVideoDeviceId);
        onUpdateVideoDeviceId(newVideoDeviceId);
        try {
            localStorage.setItem('videoGuidesVideoDeviceId', newVideoDeviceId);
        }
        catch (e) {
            // Silent fail
        }
    }, [onUpdateVideoDeviceId]);
    useEffect(() => {
        getVideoAndAudioDevices().then(({ audio, video }) => {
            // TODO: when re-enabling support for audio-only make sure that we don't allow
            // to select the camera if it is disabled
            setVideoDevices(video);
            setAudioDevices(audio);
        });
    }, [isAcquiringMedia]);
    useOnClickOutside(ref, () => {
        setSettingsActive(false);
    });
    return (_jsxs(Footer, { children: [_jsxs(RecordingSettingsHolder, { mb: settingsActive ? 's' : 0, children: [_jsx(StartRecordingButton, { isDisabled: isLoading, isLoading: isRecordingStarting || isAcquiringMedia, onClick: onRecord }), _jsx("div", { style: { pointerEvents: settingsActive ? 'none' : 'auto' }, children: _jsx(SquareButton, { isActive: settingsActive, kind: "white", marginLeft: "xs", onClick: () => setSettingsActive((value) => !value), size: "l", translateOnHover: false, children: _jsx(Icon, { paths: SETTINGS_ICON, height: 30, viewBox: "0 0 30 30", width: 30 }) }) })] }), settingsActive && (_jsx(FooterSettingsHolder, { ref: ref, children: _jsx(SelectWebcamMicrophoneSettings, { audioDeviceId: audioDeviceId, videoDeviceId: videoDeviceId, audioDevices: audioDevices, videoDevices: videoDevices, onAudioDeviceChange: onAudioDeviceChange, onVideoDeviceChange: onVideoDeviceChange }) }))] }));
});
export const FooterRecording = observer(function FooterRecording({ isRecording, isLoading, onStopRecording, }) {
    return (_jsx(Footer, { children: _jsx(StopRecordingButton, { isRecording: isRecording, isLoading: isLoading, onClick: onStopRecording }) }));
});
const RecordingSettingsHolder = styled(Box) `
  display: flex;
`;
const FooterSettingsHolder = styled(Box).attrs({
    bg: 'background',
    borderRadius: 'l',
}) `
  bottom: 100%;
  position: relative;
  padding: 12px;
`;
const Footer = styled(Box).attrs({
    padding: 's',
}) `
  position: relative;
  z-index: 1;
  bottom: 0;
  left: 0;

  width: 100%;

  button {
    transition: none;
  }
`;
export default Footer;
