import { jsx as _jsx } from "react/jsx-runtime";
import themeGet from '@styled-system/theme-get';
import { useEffect, useState } from 'react';
import styled from '@marvelapp/styled';
const VolumeMeter = ({ stream, pidCount = 10, }) => {
    const [volume, setVolume] = useState(0);
    useEffect(() => {
        if (!stream)
            return;
        const audioContext = new AudioContext();
        const audioSource = audioContext.createMediaStreamSource(stream);
        const analyser = audioContext.createAnalyser();
        analyser.fftSize = 256;
        analyser.minDecibels = -100;
        analyser.maxDecibels = -30;
        analyser.smoothingTimeConstant = 0.4;
        audioSource.connect(analyser);
        function calculateVolume() {
            const array = new Uint8Array(analyser.frequencyBinCount);
            analyser.getByteFrequencyData(array);
            const volumeSum = array.reduce((a, value) => a + value, 0);
            const averageVolume = volumeSum / array.length;
            const volumeRange = Math.abs(analyser.maxDecibels - analyser.minDecibels);
            const normalizedVolume = Math.round((averageVolume * pidCount) / volumeRange);
            setVolume(normalizedVolume);
        }
        const volumeInterval = setInterval(calculateVolume, 100);
        return () => clearInterval(volumeInterval);
    }, [stream, pidCount]);
    if (!stream)
        return null;
    return (_jsx(Pids, { children: Array.from({ length: pidCount }).map((_, index) => (
        // eslint-disable-next-line react/no-array-index-key
        _jsx(Pid, { color: getPidColor(volume, index) }, index))) }));
};
const Pids = styled.div `
  width: 100%;
`;
const Pid = styled.div `
  width: calc(10% - 10px);
  height: 10px;
  display: inline-block;
  margin: 5px;
  border-radius: ${themeGet('radii.m')}px;
  background-color: ${(props) => props.theme.colors[props.color]};
`;
function getPidColor(volume, index) {
    if (volume < index)
        return 'slate4';
    if (volume < 2)
        return 'tomato9';
    if (volume < 3)
        return 'amber9';
    return 'grass8';
}
export default VolumeMeter;
