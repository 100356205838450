import { PrototypeStepType, QuestionStepType, StepClass, YesOrNoStepType, } from '@marvelapp/user-test-creator';
export const STEP_TYPES = [
    {
        id: 0,
        title: 'Prompt',
        steps: [
            {
                id: 0,
                title: 'Instruction',
                description: 'Add additional context',
                config: { stepClass: StepClass.Instruction },
            },
        ],
    },
    {
        id: 1,
        title: 'Screener',
        steps: [
            {
                id: 0,
                title: 'Screener',
                description: 'Find the right participants',
                config: { stepClass: StepClass.MultipleChoice },
                isPremiumStep: true,
                isScreenerStep: true,
                testId: 'ScreenerStep',
            },
        ],
    },
    {
        id: 2,
        title: 'Survey',
        steps: [
            {
                id: 0,
                title: 'Yes or No',
                description: 'A simple two-choice question',
                config: {
                    stepClass: StepClass.YesOrNo,
                    stepType: YesOrNoStepType.YesNo,
                },
            },
            {
                id: 1,
                title: 'Question',
                description: 'Collect free text answers',
                config: {
                    stepClass: StepClass.Question,
                    stepType: QuestionStepType.Text,
                },
            },
            {
                id: 2,
                title: 'Multiple choice',
                description: 'Provide two or more choices',
                config: { stepClass: StepClass.MultipleChoice },
            },
            {
                id: 3,
                title: 'Rating scale',
                description: 'Get feedback on a numbered scale',
                config: { stepClass: StepClass.RatingScale },
            },
            {
                id: 4,
                title: 'Image preference test',
                description: 'Multiple choice with images',
                config: { stepClass: StepClass.PreferenceTest },
            },
            {
                id: 7,
                title: 'Email',
                description: 'Collect email answers',
                config: {
                    stepClass: StepClass.Question,
                    stepType: QuestionStepType.Email,
                },
            },
            {
                id: 8,
                title: 'Number',
                description: 'Collect numerical answers',
                config: {
                    stepClass: StepClass.Question,
                    stepType: QuestionStepType.Numerical,
                },
            },
            {
                id: 9,
                title: 'Date',
                description: 'Collect date answers',
                config: {
                    stepClass: StepClass.Question,
                    stepType: QuestionStepType.QuestionDate,
                },
            },
        ],
    },
    {
        id: 3,
        title: 'Usability',
        steps: [
            {
                id: 0,
                title: 'Website task',
                description: 'Ask the participant to test a website',
                config: { stepClass: StepClass.WebsiteTask },
            },
            {
                id: 1,
                title: 'Five second test',
                description: 'Test memory recall',
                config: {
                    stepClass: StepClass.FiveSecondTest,
                },
            },
            {
                id: 2,
                title: 'First click',
                description: 'Test first impressions',
                config: {
                    stepClass: StepClass.PrototypeTask,
                    stepType: PrototypeStepType.FirstClick,
                },
            },
            {
                id: 3,
                title: 'Prototype task',
                description: 'Set a goal to complete',
                config: {
                    stepClass: StepClass.PrototypeTask,
                    stepType: PrototypeStepType.Prototype,
                },
            },
            {
                id: 4,
                title: 'Prototype free roam',
                description: 'Test a prototype without a goal',
                config: {
                    stepClass: StepClass.PrototypeTask,
                    stepType: PrototypeStepType.FreeRoam,
                },
            },
            {
                id: 5,
                title: 'Open card sort',
                description: 'Participants create the categories they sort into',
                config: {
                    stepClass: StepClass.CardSortingOpen,
                },
            },
            {
                id: 6,
                title: 'Closed card sort',
                description: 'Participants sort into predefined categories',
                config: {
                    stepClass: StepClass.CardSortingClosed,
                },
            },
            {
                id: 7,
                title: 'Hybrid card sort',
                description: 'Participants sort into predefined and new categories',
                config: {
                    stepClass: StepClass.CardSortingHybrid,
                },
            },
        ],
    },
    {
        id: 4,
        title: 'Marketing',
        steps: [
            {
                id: 0,
                title: 'Banner ad test',
                description: 'A test to perform a task with 1 click',
                config: {
                    stepClass: StepClass.BannerAdTest,
                },
            },
            {
                id: 1,
                title: 'Copy test',
                description: 'Test and validate marketing copy',
                config: { stepClass: StepClass.TaglineCopyTest },
            },
            {
                id: 2,
                title: 'Legal',
                description: 'Ask participants to accept terms',
                config: {
                    stepClass: StepClass.YesOrNo,
                    stepType: YesOrNoStepType.Legal,
                },
                isPremiumStep: true,
            },
        ],
    },
];
