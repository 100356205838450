import { isNil } from 'lodash-es';
import * as StepUtils from '../../userTest/stepUtils';
import { StepClass } from '../../userTest/types';
export function setRule(mutable, answer, selectedStepUUID) {
    if (mutable.conditions) {
        if (answer) {
            mutable.conditions.answer.true = selectedStepUUID;
        }
        else {
            mutable.conditions.answer.false = selectedStepUUID;
        }
    }
    // support for documents that don't have this conditions object yet
    else {
        mutable.conditions = {
            answer: {
                true: answer ? selectedStepUUID : null,
                false: answer ? null : selectedStepUUID,
            },
            ALWAYS: null,
        };
    }
}
export function deleteRule(mutable, answer) {
    if (mutable.conditions) {
        if (answer) {
            mutable.conditions.answer.true = null;
        }
        else {
            mutable.conditions.answer.false = null;
        }
    }
}
export function getConditionalNextStepUUID(step, answer) {
    let destinationStep = null;
    if (step.conditions) {
        // lookup any saved rules in the document
        if (answer === true) {
            destinationStep = step.conditions.answer.true;
        }
        else if (answer === false) {
            destinationStep = step.conditions.answer.false;
        }
        // if no set rules, check the always rule
        if (!destinationStep) {
            destinationStep = step.conditions.ALWAYS;
        }
    }
    return destinationStep;
}
export function updateRule({ mutable, selectedChoice, selectedDestStepUuid, currentChoice, }) {
    if (!isNil(selectedChoice)) {
        updateAnswerValue({ mutable, selectedChoice, currentChoice });
    }
    if (selectedDestStepUuid) {
        updateRuleDestination({
            mutable,
            currentChoice,
            selectedDestStepUuid,
        });
    }
}
function updateAnswerValue({ mutable, selectedChoice, currentChoice, }) {
    if (!mutable.conditions) {
        initializeConditions(mutable);
    }
    if (mutable.conditions) {
        if (currentChoice) {
            const destinationUUID = mutable.conditions.answer.true;
            if (!selectedChoice) {
                mutable.conditions.answer.true = null;
                mutable.conditions.answer.false = destinationUUID;
            }
        }
        else {
            const destinationUUID = mutable.conditions.answer.false;
            if (selectedChoice) {
                mutable.conditions.answer.false = null;
                mutable.conditions.answer.true = destinationUUID;
            }
        }
    }
}
function updateRuleDestination({ mutable, currentChoice, selectedDestStepUuid, }) {
    if (!mutable.conditions) {
        initializeConditions(mutable);
    }
    if (mutable.conditions) {
        if (currentChoice) {
            mutable.conditions.answer.true = selectedDestStepUuid;
        }
        else {
            mutable.conditions.answer.false = selectedDestStepUuid;
        }
    }
}
function initializeConditions(mutable) {
    if (!mutable.conditions) {
        mutable.conditions = {
            answer: {
                true: null,
                false: null,
            },
            ALWAYS: null,
        };
    }
}
export function getSavedRules(step) {
    const savedRules = [];
    if (StepUtils.isOfType(step, StepClass.YesOrNo) && step.conditions) {
        if (step.conditions.answer) {
            if (!isNil(step.conditions.answer.true)) {
                savedRules.push(['true', step.conditions.answer.true]);
            }
            if (!isNil(step.conditions.answer.false)) {
                savedRules.push(['false', step.conditions.answer.false]);
            }
        }
    }
    return savedRules;
}
