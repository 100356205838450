import { jsxs as _jsxs } from "react/jsx-runtime";
import { observer } from 'mobx-react';
import { useGetUserTest, useSlideUIContext, } from '@marvelapp/ballpark-application';
import { Badge } from '@marvelapp/ballpark-components';
import { getStepIndex, getStepsCount } from '@marvelapp/user-test-creator';
const SlidesBadge = observer(() => {
    const userTest = useGetUserTest();
    const { step } = useSlideUIContext();
    const stepIndex = getStepIndex(userTest, step.uuid);
    const totalStepsCount = getStepsCount(userTest);
    return (_jsxs(Badge, { "data-testid": "slide-tally", variant: "sky", size: "sm", children: [stepIndex + 1, " / ", totalStepsCount] }));
});
export { SlidesBadge };
