import { __rest } from "tslib";
import { gql, useQuery } from '@apollo/client';
import { omit } from 'lodash-es';
import { useMemo } from 'react';
import { useFeatureFlag } from '@marvelapp/ballpark-application';
import { mapFilterNullish } from '@marvelapp/core';
import { StudyTypeEnum, } from '../../__generated__/queryTypes';
import { GeneralTargetingFilter, } from './types';
export const filterOption = gql `
  fragment option on OptionNode {
    name
    id
    min
    max
  }
`;
export const selectedOptionFragment = gql `
  fragment selectedOption on SelectedOptionNode {
    name
    id
    max
    min
    filterId
    groupId
    customValue
  }
`;
const filterGroup = gql `
  fragment filterGroup on FilterGroupNode {
    name
    id
    filters {
      id
      name
      description
      isRequired
      minSelection
      maxSelection
      optionCount
      options(first: 250) {
        edges {
          node {
            ...option
          }
        }
      }
    }
  }
  ${filterOption}
`;
const GET_FILTER_DATA = gql `
  query getFilterData {
    videoStudy: studyType(studyType: VIDEO) {
      creditPerMinute
      minimumDuration
      maximumDuration
      minimumParticipants
      maximumParticipants
      filterGroups {
        ...filterGroup
      }
    }
    b2bStudy: studyType(studyType: B2B) {
      creditPerMinute
      minimumDuration
      maximumDuration
      minimumParticipants
      maximumParticipants
      filterGroups {
        ...filterGroup
      }
    }
    standardStudy: studyType(studyType: SURVEY) {
      creditPerMinute
      minimumDuration
      maximumDuration
      minimumParticipants
      maximumParticipants
      filterGroups {
        ...filterGroup
      }
    }
  }
  ${filterGroup}
`;
export const GET_RECRUITMENT_ALLOWANCE = gql `
  query getRecruitmentAllowance {
    user {
      pk
      company {
        pk
        billing {
          subscriptionPk
          participantMinutesPurchased
          participantMinutesUsed
        }
      }
    }
  }
`;
export const GET_SAVED_STUDY_ORDER = gql `
  query getSavedStudyOrder($testUUID: String!) {
    studyOrderDetails(testUUID: $testUUID) {
      studyId
      numParticipants
      studyDuration
      studyType
      filters {
        id
        name
        options(first: 250) {
          edges {
            node {
              ...selectedOption
            }
          }
        }
      }
      eligibilityDetails {
        countParticipants
        error
      }
      unsupportedFilters {
        id
        name
        isAvailableForStudyType
        options(first: 250) {
          edges {
            node {
              ...selectedOption
            }
          }
        }
      }
    }
  }
  ${selectedOptionFragment}
`;
export function useGetRecruitmentData({ testUUID, estimatedTimeToComplete, }) {
    const canSetDurationManually = useFeatureFlag('manual-recruitment-duration');
    const { data, loading, error } = useQuery(GET_FILTER_DATA);
    const { data: allowanceData, error: allowanceError } = useQuery(GET_RECRUITMENT_ALLOWANCE);
    const { data: savedStudyOrder, error: savedStudyError } = useQuery(GET_SAVED_STUDY_ORDER, {
        variables: { testUUID },
        skip: !testUUID,
    });
    const allowedDuration = useMemo(() => {
        var _a, _b, _c, _d, _e, _f;
        if (!((_a = data === null || data === void 0 ? void 0 : data.videoStudy) === null || _a === void 0 ? void 0 : _a.minimumDuration) ||
            !((_b = data === null || data === void 0 ? void 0 : data.videoStudy) === null || _b === void 0 ? void 0 : _b.maximumDuration) ||
            !((_c = data === null || data === void 0 ? void 0 : data.b2bStudy) === null || _c === void 0 ? void 0 : _c.minimumDuration) ||
            !((_d = data === null || data === void 0 ? void 0 : data.b2bStudy) === null || _d === void 0 ? void 0 : _d.maximumDuration) ||
            !((_e = data === null || data === void 0 ? void 0 : data.standardStudy) === null || _e === void 0 ? void 0 : _e.minimumDuration) ||
            !((_f = data === null || data === void 0 ? void 0 : data.standardStudy) === null || _f === void 0 ? void 0 : _f.maximumDuration))
            return;
        return {
            [StudyTypeEnum.VIDEO]: {
                min: data.videoStudy.minimumDuration,
                max: data.videoStudy.maximumDuration,
            },
            [StudyTypeEnum.B2B]: {
                min: data.b2bStudy.minimumDuration,
                max: data.b2bStudy.maximumDuration,
            },
            [StudyTypeEnum.SURVEY]: {
                min: data.standardStudy.minimumDuration,
                max: data.standardStudy.maximumDuration,
            },
        };
    }, [data]);
    const savedData = useMemo(() => {
        if (!savedStudyOrder || !allowedDuration)
            return;
        return getSavedData({
            allowedDuration,
            canSetDurationManually,
            estimatedTimeToComplete,
            orderDetails: savedStudyOrder.studyOrderDetails,
        });
    }, [
        allowedDuration,
        canSetDurationManually,
        estimatedTimeToComplete,
        savedStudyOrder,
    ]);
    const videoFilters = useMemo(() => {
        var _a;
        if (!((_a = data === null || data === void 0 ? void 0 : data.videoStudy) === null || _a === void 0 ? void 0 : _a.filterGroups))
            return;
        return normalizeFilters(data.videoStudy.filterGroups);
    }, [data]);
    const b2bFilters = useMemo(() => {
        var _a;
        if (!((_a = data === null || data === void 0 ? void 0 : data.b2bStudy) === null || _a === void 0 ? void 0 : _a.filterGroups))
            return;
        return normalizeFilters(data.b2bStudy.filterGroups);
    }, [data]);
    const standardFilters = useMemo(() => {
        var _a;
        if (!((_a = data === null || data === void 0 ? void 0 : data.standardStudy) === null || _a === void 0 ? void 0 : _a.filterGroups))
            return;
        return normalizeFilters(data.standardStudy.filterGroups);
    }, [data]);
    const savedUnsupportedFilters = useMemo(() => {
        var _a;
        const unsupportedFilters = (_a = savedStudyOrder === null || savedStudyOrder === void 0 ? void 0 : savedStudyOrder.studyOrderDetails) === null || _a === void 0 ? void 0 : _a.unsupportedFilters;
        if (!unsupportedFilters)
            return {};
        return normalizeUnsupportedFilters(unsupportedFilters);
    }, [savedStudyOrder]);
    const currentBalance = useMemo(() => {
        var _a, _b;
        const billingInfo = (_b = (_a = allowanceData === null || allowanceData === void 0 ? void 0 : allowanceData.user) === null || _a === void 0 ? void 0 : _a.company) === null || _b === void 0 ? void 0 : _b.billing;
        if (!billingInfo)
            return null;
        const { participantMinutesPurchased, participantMinutesUsed } = billingInfo;
        if (participantMinutesPurchased === null || participantMinutesUsed === null)
            return null;
        return participantMinutesPurchased - participantMinutesUsed;
    }, [allowanceData]);
    const creditsPerMinute = useMemo(() => {
        var _a, _b, _c;
        if (!((_a = data === null || data === void 0 ? void 0 : data.videoStudy) === null || _a === void 0 ? void 0 : _a.creditPerMinute) ||
            !((_b = data === null || data === void 0 ? void 0 : data.b2bStudy) === null || _b === void 0 ? void 0 : _b.creditPerMinute) ||
            !((_c = data === null || data === void 0 ? void 0 : data.standardStudy) === null || _c === void 0 ? void 0 : _c.creditPerMinute))
            return;
        return {
            [StudyTypeEnum.VIDEO]: data.videoStudy.creditPerMinute,
            [StudyTypeEnum.B2B]: data.b2bStudy.creditPerMinute,
            [StudyTypeEnum.SURVEY]: data.standardStudy.creditPerMinute,
        };
    }, [data]);
    const allowedNumberOfResponses = useMemo(() => {
        var _a, _b, _c, _d, _e, _f;
        if (!((_a = data === null || data === void 0 ? void 0 : data.videoStudy) === null || _a === void 0 ? void 0 : _a.minimumParticipants) ||
            !((_b = data === null || data === void 0 ? void 0 : data.videoStudy) === null || _b === void 0 ? void 0 : _b.maximumParticipants) ||
            !((_c = data === null || data === void 0 ? void 0 : data.b2bStudy) === null || _c === void 0 ? void 0 : _c.minimumParticipants) ||
            !((_d = data === null || data === void 0 ? void 0 : data.b2bStudy) === null || _d === void 0 ? void 0 : _d.maximumParticipants) ||
            !((_e = data === null || data === void 0 ? void 0 : data.standardStudy) === null || _e === void 0 ? void 0 : _e.minimumParticipants) ||
            !((_f = data === null || data === void 0 ? void 0 : data.standardStudy) === null || _f === void 0 ? void 0 : _f.maximumParticipants))
            return;
        return {
            [StudyTypeEnum.VIDEO]: {
                min: data.videoStudy.minimumParticipants,
                max: data.videoStudy.maximumParticipants,
            },
            [StudyTypeEnum.B2B]: {
                min: data.b2bStudy.minimumParticipants,
                max: data.b2bStudy.maximumParticipants,
            },
            [StudyTypeEnum.SURVEY]: {
                min: data.standardStudy.minimumParticipants,
                max: data.standardStudy.maximumParticipants,
            },
        };
    }, [data]);
    if (!data ||
        loading ||
        !allowanceData ||
        !savedData ||
        !videoFilters ||
        !b2bFilters ||
        !standardFilters ||
        !creditsPerMinute ||
        currentBalance === null ||
        !allowedDuration ||
        !allowedNumberOfResponses) {
        return {
            isRecruitmentDataLoading: true,
            errorFetchingRecruitmentData: !!error || !!allowanceError || !!savedStudyError,
        };
    }
    return {
        isRecruitmentDataLoading: loading,
        recruitmentData: Object.assign(Object.assign({}, savedData.savedDraft), { hasSavedDraft: savedData.hasSavedDraft, videoFilters,
            b2bFilters,
            standardFilters,
            currentBalance,
            creditsPerMinute,
            savedUnsupportedFilters,
            allowedDuration,
            allowedNumberOfResponses }),
    };
}
// These defaults are hardcoded and match the defaults specified
// in the feature file
export const getDefaultStudyOrder = function getDefaultStudyOrder(duration) {
    return {
        duration,
        eligibilityDetails: {
            countParticipants: null,
            error: null,
        },
        numberOfResponses: 1,
        selectedFilters: {
            [GeneralTargetingFilter.Country]: {
                groupId: 'GROUP_DEFAULT',
                name: 'Country',
                options: [
                    {
                        name: 'United States',
                        id: 'COUNTRY_US',
                        max: null,
                        min: null,
                        filterId: GeneralTargetingFilter.Country,
                        groupId: 'GROUP_DEFAULT',
                        customValue: null,
                    },
                ],
            },
        },
        studyType: StudyTypeEnum.SURVEY,
    };
};
function getSavedData({ allowedDuration, canSetDurationManually, estimatedTimeToComplete, orderDetails, }) {
    if (!orderDetails ||
        orderDetails.filters === null ||
        orderDetails.studyDuration === null ||
        orderDetails.numParticipants === null) {
        const duration = getStudyDuration({
            canSetDurationManually,
            estimatedTimeToComplete,
            minAllowedDuration: allowedDuration[StudyTypeEnum.SURVEY].min,
            savedDuration: 5,
            studyType: StudyTypeEnum.SURVEY,
        });
        return {
            hasSavedDraft: false,
            savedDraft: getDefaultStudyOrder(duration),
        };
    }
    const minAllowedDuration = allowedDuration[orderDetails.studyType].min;
    return {
        hasSavedDraft: true,
        savedDraft: {
            duration: getStudyDuration({
                canSetDurationManually,
                estimatedTimeToComplete,
                minAllowedDuration,
                savedDuration: orderDetails.studyDuration,
                studyType: orderDetails.studyType,
            }),
            numberOfResponses: orderDetails.numParticipants,
            selectedFilters: applySavedStudyOrderFilters(orderDetails.filters),
            studyType: orderDetails.studyType,
            eligibilityDetails: omit(orderDetails.eligibilityDetails, '__typename'),
        },
    };
}
export function getStudyDuration({ canSetDurationManually, estimatedTimeToComplete, minAllowedDuration, savedDuration, studyType, }) {
    if (!canSetDurationManually) {
        return studyType !== StudyTypeEnum.SURVEY &&
            estimatedTimeToComplete < minAllowedDuration
            ? minAllowedDuration
            : estimatedTimeToComplete;
    }
    return savedDuration;
}
export function flattenOptions(options) {
    if (!(options === null || options === void 0 ? void 0 : options.edges))
        return [];
    return mapFilterNullish(options.edges, (edge) => {
        if (!(edge === null || edge === void 0 ? void 0 : edge.node))
            return null;
        const _a = edge.node, { __typename } = _a, option = __rest(_a, ["__typename"]);
        return option;
    });
}
export function applySavedStudyOrderFilters(filters) {
    return filters.reduce((acc, filter) => {
        if (!(filter === null || filter === void 0 ? void 0 : filter.options))
            return acc;
        const { id, name, options } = filter;
        const nonNullableOptions = mapFilterNullish(flattenOptions(options), (option) => option);
        // eslint-disable-next-line no-param-reassign
        acc[id] = {
            groupId: nonNullableOptions[0].groupId,
            name,
            options: nonNullableOptions,
        };
        return acc;
    }, {});
}
const SPECIAL_FILTER_GROUPS = ['GROUP_DEFAULT', 'GROUP_B2B'];
export function isSpecialFilterGroup(groupName) {
    return SPECIAL_FILTER_GROUPS.some((group) => group === groupName);
}
function normalizeFilters(filterGroups) {
    const other = [];
    const normalizedSpecialFilters = filterGroups.reduce((acc, filterGroup) => {
        if (isSpecialFilterGroup(filterGroup.id)) {
            const normalizedFilterGroup = normalizeFilterGroup(filterGroup);
            // eslint-disable-next-line no-param-reassign
            acc[filterGroup.id] = normalizedFilterGroup;
        }
        else {
            other.push(filterGroup);
        }
        return acc;
    }, {});
    const otherFilterGroups = normalizeRemainingFilters(other);
    const requiredFilters = Object.assign(Object.assign({}, getRequiredFilters(normalizedSpecialFilters)), getRequiredFilters(otherFilterGroups.filterGroups));
    return {
        specialFilters: normalizedSpecialFilters,
        other: otherFilterGroups,
        requiredFilters,
    };
}
function normalizeRemainingFilters(filterGroups) {
    const originalFilterGroupOrder = [];
    const indexedFilterGroups = filterGroups.reduce((acc, filterGroup) => {
        originalFilterGroupOrder.push(filterGroup.id);
        const normalizedFilterGroup = normalizeFilterGroup(filterGroup);
        // eslint-disable-next-line no-param-reassign
        acc[filterGroup.id] = normalizedFilterGroup;
        return acc;
    }, {});
    return {
        filterGroups: indexedFilterGroups,
        originalFilterGroupOrder,
    };
}
function normalizeFilterGroup(filterGroup) {
    const { originalFilterOrder, filters: filtersIndexedById } = getFiltersIndexedById(filterGroup.filters || []);
    return Object.assign(Object.assign({}, filterGroup), { filters: filtersIndexedById, originalFilterOrder });
}
function getFiltersIndexedById(filters) {
    const originalFilterOrder = [];
    const indexedFilters = filters.reduce((acc, filter) => {
        originalFilterOrder.push(filter.id);
        const { options } = filter, rest = __rest(filter, ["options"]);
        const flattenedOptions = flattenOptions(options);
        const optionIds = flattenedOptions.map((option) => option.id);
        // eslint-disable-next-line no-param-reassign
        acc[filter.id] = Object.assign({ options: flattenedOptions, optionIds }, rest);
        return acc;
    }, {});
    return { filters: indexedFilters, originalFilterOrder };
}
function getRequiredFilters(filterGroups) {
    const requiredFilters = {};
    Object.values(filterGroups).forEach((filterGroup) => {
        Object.values(filterGroup.filters).forEach((filter) => {
            if (filter.isRequired) {
                requiredFilters[filter.id] = {
                    maxSelection: filter.maxSelection,
                    minSelection: filter.minSelection,
                    name: filter.name,
                };
            }
        });
    });
    return requiredFilters;
}
export function normalizeUnsupportedFilters(unsupportedFilters) {
    if (!unsupportedFilters || !unsupportedFilters.length)
        return {};
    return unsupportedFilters.reduce((normalizedFilters, filter) => {
        if (filter) {
            // eslint-disable-next-line no-param-reassign
            normalizedFilters[filter.id] = Object.assign(Object.assign({}, filter), { options: flattenOptions(filter.options) });
        }
        return normalizedFilters;
    }, {});
}
