export var ResponseStatus;
(function (ResponseStatus) {
    ResponseStatus["InProgress"] = "IN_PROGRESS";
    ResponseStatus["Complete"] = "COMPLETE";
    ResponseStatus["Screening"] = "SCREENING";
    ResponseStatus["Incomplete"] = "INCOMPLETE";
    ResponseStatus["Failed"] = "FAILED";
    ResponseStatus["Viewed"] = "VIEWED";
})(ResponseStatus || (ResponseStatus = {}));
export var AnswerType;
(function (AnswerType) {
    AnswerType["Email"] = "EMAIL";
    AnswerType["SingleChoice"] = "RADIO_BUTTON";
    // Can't call this Date because schema generator considers Date
    // a format when it sees it (which breaks everything)
    AnswerType["DateAnswer"] = "DATE_TIME";
    AnswerType["Text"] = "TEXT";
    AnswerType["Rating"] = "RATING";
    AnswerType["MultipleChoice"] = "MULTIPLE_CHOICE";
    AnswerType["Number"] = "NUMBER";
})(AnswerType || (AnswerType = {}));
export var DeviceMake;
(function (DeviceMake) {
    DeviceMake["Web"] = "WEB";
    DeviceMake["Mobile"] = "MOBILE";
    DeviceMake["Tablet"] = "TABLET";
})(DeviceMake || (DeviceMake = {}));
