import { jsx as _jsx } from "react/jsx-runtime";
import { observer } from 'mobx-react';
import * as React from 'react';
import { useSlideUIContext, useUserTestAction, } from '@marvelapp/ballpark-application';
import { ChoiceConditionalUtils, MultipleChoiceStepUtils, StepClass, StepUtils, } from '@marvelapp/user-test-creator';
import { DecoratedSlide } from '../common/DecoratedSlide';
import { MultipleChoiceContent, } from './MultipleChoiceContent';
const MultipleChoiceEdit = observer(function MultipleChoiceEdit({ onMainCTAClick, }) {
    const { step } = useSlideUIContext();
    const confirmDelete = useUserTestAction((uuid, closeModal) => {
        if (!StepUtils.isOfType(step, StepClass.MultipleChoice))
            return null;
        ChoiceConditionalUtils.deleteRulesForDeletedChoice(step, uuid);
        MultipleChoiceStepUtils.removeChoice(step, uuid);
        closeModal();
    }, [step]);
    const onSetChoiceValue = useUserTestAction((value, uuid) => MultipleChoiceStepUtils.setChoiceValue(step, uuid, value), [step]);
    if (!StepUtils.isOfType(step, StepClass.MultipleChoice))
        return null;
    return (_jsx(DecoratedSlide, { children: _jsx(MultipleChoiceContent, { onSetChoiceValue: onSetChoiceValue, onMainCTAClick: onMainCTAClick, confirmDelete: confirmDelete }) }));
});
export default MultipleChoiceEdit;
