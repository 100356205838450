/* eslint-disable no-restricted-syntax */
import { useCallback, useContext, useEffect, useReducer } from 'react';
import RealtimeContext from '../context/RealtimeContext';
export function useCollaborators() {
    const awareness = useContext(RealtimeContext);
    const [, forceUpdate] = useReducer((c) => c + 1, 0);
    useEffect(() => {
        if (!awareness)
            return;
        awareness.on('change', forceUpdate);
    }, [awareness]);
    if (!awareness)
        return [];
    const collaborators = [];
    for (const [id, state] of awareness.getStates().entries()) {
        const isDuplicate = !!collaborators.find((c) => c.id === state.id);
        if (!isDuplicate && id === awareness.clientID && state.avatarUrl) {
            collaborators.unshift(state);
        }
        else if (!isDuplicate && state.avatarUrl) {
            collaborators.push(state);
        }
    }
    return collaborators;
}
export function usePublishSelection() {
    const awareness = useContext(RealtimeContext);
    const publishSelection = useCallback((value) => {
        if (awareness && awareness.getLocalState() !== null) {
            awareness.setLocalStateField('selection', value);
        }
    }, [awareness]);
    return publishSelection;
}
