import { jsx as _jsx } from "react/jsx-runtime";
// TODO this can be removed when yes/no and prototype step no longer have draft rules
import { observer, useLocalObservable } from 'mobx-react';
import { createContext, useContext } from 'react';
const ConditionalLogicStateContext = createContext(undefined);
export const useConditionalLogicState = () => {
    const conditionalLogicState = useContext(ConditionalLogicStateContext);
    if (conditionalLogicState === undefined)
        throw new Error('useConditionalLogicState used outside of Provider');
    return conditionalLogicState;
};
export const ConditionalLogicProvider = observer(function ConditionalLogicProvider({ children, activeStepUUID, }) {
    const conditionalLogicState = useLocalObservable(() => {
        const state = {
            isAddingNewRule: false,
            activeStepUUID,
            addNewRule() {
                this.isAddingNewRule = true;
            },
            removeRule() {
                this.isAddingNewRule = false;
            },
            setActiveStepUUID(stepUUID) {
                this.activeStepUUID = stepUUID;
            },
        };
        return state;
    });
    return (_jsx(ConditionalLogicStateContext.Provider, { value: conditionalLogicState, children: children }));
});
