import { __awaiter } from "tslib";
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { observer } from 'mobx-react';
import { useCallback } from 'react';
import { BtwButton, BtwCheckbox, BtwLabel, BtwModal, BtwText, } from '@marvelapp/ballpark-components';
import { UserTestStatus } from '@marvelapp/core';
import { useUpdateUserTestStatus } from '../../../../graphql/mutations';
import { useMetricsContext } from './metricsContext';
export const StandardizationPausePromptModal = observer(function StandardizationPausePromptModal({ isOpen, onOpenChange, onPauseProject, showPromptOnUnpause, setShowPromptOnUnpause, }) {
    const { projectPk } = useMetricsContext();
    const [updateUserTestStatus, { loading: isUpdatingUserTestStatus }] = useUpdateUserTestStatus();
    const toggleCheckboxPrompt = useCallback((value) => {
        setShowPromptOnUnpause(!value);
    }, [setShowPromptOnUnpause]);
    const handlePauseProject = useCallback(() => __awaiter(this, void 0, void 0, function* () {
        try {
            yield updateUserTestStatus(UserTestStatus.Paused, projectPk);
            onPauseProject();
        }
        finally {
            onOpenChange(false);
        }
    }), [updateUserTestStatus, projectPk, onPauseProject, onOpenChange]);
    return (_jsx(BtwModal.Root, { open: isOpen, onOpenChange: onOpenChange, children: _jsxs(BtwModal.Content, { "data-testid": "standardization-pause-prompt-modal", children: [_jsx(BtwModal.BigHeader, { onlyChild: false, title: "Have you finished collecting responses?", description: "We recommend that you pause your project before standardizing\n          categories for reliable analysis." }), _jsx(BtwModal.Body, { children: _jsxs(BtwLabel, { htmlFor: "show-prompt-on-unpause-checkbox", size: "sm", weight: "normal", className: "direction-row flex", children: [_jsx(BtwCheckbox, { id: "show-prompt-on-unpause-checkbox", "data-testid": "show-prompt-on-unpause-checkbox", checked: !showPromptOnUnpause, onCheckedChange: toggleCheckboxPrompt }), _jsxs(BtwText, { weight: "normal", leading: "relaxed", size: "sm", className: "ml-3", children: ["Don", "'", "t show this prompt again"] })] }) }), _jsxs(BtwModal.Footer, { children: [_jsx(BtwModal.Close, { asChild: true, "data-testid": "dismiss-button", children: _jsx(BtwButton, { variant: "secondary", children: "Dismiss" }) }), _jsx(BtwButton, { variant: "primary", isLoading: isUpdatingUserTestStatus, onClick: handlePauseProject, "data-testid": "pause-project-button", children: "Pause Project" })] })] }) }));
});
