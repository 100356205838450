import styled from '@marvelapp/styled';
import { Box } from '@marvelapp/ui';
const StickyHeader = styled(Box).attrs({
    px: 22,
    bg: 'background',
    zIndex: 'record.controls',
}) `
  position: sticky;
  top: 0;
`;
export default StickyHeader;
