import { motion } from 'framer-motion';
import styled from '@marvelapp/styled';
import { Box } from '@marvelapp/ui';
const VideoGuideHolder = styled(motion(Box)).attrs({
    bg: 'slate4',
    borderRadius: ['s', 's', 'm', 'l'],
    width: 'videoGuide.edit',
}) `
  aspect-ratio: 16 / 9;
  position: relative;

  @supports not (aspect-ratio: 16 / 9) {
    &::before {
      float: left;
      padding-top: 177.77777777777777%; /* 16:9 ratio */
      content: '';
    }

    &::after {
      display: block;
      content: '';
      clear: both;
    }
  }
`;
export default VideoGuideHolder;
