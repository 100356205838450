export function getIconFillInfo(selectedRatingNumber, totalRatingNumber) {
    const decimalPart = parseFloat((selectedRatingNumber % 1).toFixed(1));
    const hasDecimals = decimalPart > 0;
    const filledIcons = hasDecimals
        ? Math.ceil(selectedRatingNumber) - 1
        : selectedRatingNumber;
    const emptyIcons = hasDecimals
        ? totalRatingNumber - Math.ceil(selectedRatingNumber)
        : totalRatingNumber - selectedRatingNumber;
    return {
        decimalPart,
        emptyIcons,
        filledIcons,
        hasDecimals,
    };
}
