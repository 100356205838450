import { richTextV1ToV2 } from './migrate';
// TODO: remove richTextV1ToV2 just-in-time migration when we can do schema migration
export function getValueLength(value) {
    return richTextToString(richTextV1ToV2(value)).length || 0;
}
// TODO: remove richTextV1ToV2 just-in-time migration when we can do schema migration
export function checkIfValueIsEmpty(value) {
    return !value.length || richTextToString(richTextV1ToV2(value)).trim() === '';
}
/**
 * Converts a RichText object to a string.
 * works on basic formatting only (bold, italic, strikethrough)
 * @param nodes the RichText object to convert
 * @param newlineSymbol the symbol to use for newlines, defaults to '\n'
 * @returns
 */
export function richTextToString(nodes, newlineSymbol = '\n') {
    const appliedMarks = new Set();
    // TODO: remove richTextV1ToV2 just-in-time migration when we can do schema migration
    let result = richTextV1ToV2(nodes)
        .map((n) => customElementToString(n, appliedMarks))
        .join(newlineSymbol);
    appliedMarks.forEach((mark) => {
        result += `</${mark}>`;
    });
    return result;
}
function customElementToString(element, appliedMarks = new Set(), newlineSymbol = '\n') {
    let result = '';
    let newline = false;
    element.children.forEach((child) => {
        if ('text' in child) {
            if (newline) {
                result += newlineSymbol;
            }
            // need to remove marks in reverse order to their application
            let removeMarks = '';
            appliedMarks.forEach((mark) => {
                if (!child[mark]) {
                    removeMarks = `</${mark}>${removeMarks}`;
                    appliedMarks.delete(mark);
                }
            });
            result += removeMarks;
            marks.forEach((mark) => {
                if (child[mark]) {
                    if (!appliedMarks.has(mark)) {
                        result += `<${mark}>`;
                        appliedMarks.add(mark);
                    }
                }
            });
            result += child.text;
            newline = false;
        }
        else {
            if (newline || result !== '') {
                result += newlineSymbol;
            }
            result += customElementToString(child, appliedMarks);
            newline = true;
        }
    });
    return result;
}
/**
 * Creates a function that replaces placeholders in a string with values.
 * @param placeholders placeholder values to replace, e.g. { 'placeholder': 'value' } would replace '{placeholder}' with 'value' in the source text
 * @returns
 */
export function createReplacePlaceholders(placeholders) {
    const keys = Object.keys(placeholders);
    if (keys.length === 0) {
        return (text) => text;
    }
    const placeholderRegex = new RegExp(`{(${keys.join('|')})}`, 'g');
    return (text) => {
        return text.replace(placeholderRegex, (_, key) => placeholders[key]);
    };
}
/**
 * Converts a string to our RichText format.
 * @param value the string to convert
 * @param newlineSymbol the symbol to use for newlines, defaults to '\n'
 * @param placeholders placeholder values to replace, e.g. { 'placeholder': 'value' } would replace '{placeholder}' with 'value' in the source text
 * @returns
 */
export function stringToRichText(value, newlineSymbol = '\n', placeholders = {}) {
    const marksToApply = new Set();
    const replacePlaceholders = createReplacePlaceholders(placeholders);
    return value.split(newlineSymbol).map((node) => ({
        type: 'p',
        children: stringToCustomTexts(node, replacePlaceholders, marksToApply),
    }));
}
const marks = ['bold', 'italic', 'strikethrough'];
const markRegex = new RegExp(`(<)(${marks.join('|')})(>)(.*?)(</)(\\2)(>)`, 'g');
function stringToCustomTexts(value, replacePlaceholders, marksToApply = new Set()) {
    const pieces = value.split(markRegex).filter((piece) => piece !== '');
    const texts = [];
    for (let i = 0; i < pieces.length; i++) {
        const piece = pieces[i];
        if (piece === '<' &&
            marks.includes(pieces[i + 1]) &&
            pieces[i + 2] === '>') {
            marksToApply.add(pieces[i + 1]);
            i += 2;
            // eslint-disable-next-line no-continue
            continue;
        }
        if (piece === '</' &&
            marks.includes(pieces[i + 1]) &&
            pieces[i + 2] === '>') {
            marksToApply.delete(pieces[i + 1]);
            i += 2;
            // eslint-disable-next-line no-continue
            continue;
        }
        if (markRegex.test(piece)) {
            texts.push(...stringToCustomTexts(piece, replacePlaceholders, marksToApply));
        }
        else {
            const text = { text: replacePlaceholders(piece) };
            marksToApply.forEach((mark) => {
                text[mark] = true;
            });
            texts.push(text);
        }
    }
    return texts;
}
