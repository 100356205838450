import { __awaiter } from "tslib";
import { useMachine } from '@xstate/react';
import { useState } from 'react';
import { useAction } from '@marvelapp/ballpark-application';
import { useMediaRecorder } from '@marvelapp/media-recording';
import { StepClass, StepUtils, getWholeScreenRecordingRequired, } from '@marvelapp/user-test-creator';
import { useRecorderState } from '../components/Record/RecorderContext';
import { useRoomToken } from '../pages/Record/RoomTokenContext';
import { mediaPermissionsMachine } from '../slides/common/MediaAccess/machine';
export default function useSetupParticipantRecording({ response, permissions, isRecordingEnabled = false, isRecordingRequired = false, responseUUID, }) {
    var _a, _b, _c;
    const [cameraTrack, setCameraTrack] = useState(null);
    const [audioTrack, setAudioTrack] = useState(null);
    const [screenTrack, setScreenTrack] = useState(null);
    const [connectionStatus, setConnectionStatus] = useState('idle');
    const recorderState = useRecorderState();
    const hasActiveCameraTrack = !!((_a = cameraTrack === null || cameraTrack === void 0 ? void 0 : cameraTrack.mediaStream) === null || _a === void 0 ? void 0 : _a.active);
    const hasActiveAudioTrack = !!((_b = audioTrack === null || audioTrack === void 0 ? void 0 : audioTrack.mediaStream) === null || _b === void 0 ? void 0 : _b.active);
    const hasActiveScreenTrack = !!((_c = screenTrack === null || screenTrack === void 0 ? void 0 : screenTrack.mediaStream) === null || _c === void 0 ? void 0 : _c.active);
    const hasActiveTrack = hasActiveCameraTrack || hasActiveAudioTrack || hasActiveScreenTrack;
    const onStartRecording = useAction(() => {
        if (hasActiveTrack) {
            setConnectionStatus('connected');
            if (recorderState.requiresMedia) {
                // We are re-acquiring media when a participant has resumed a live
                // session. In that case we show the media settings in place of the
                // current step until we have the media.
                if (StepUtils.isOfType(recorderState.currentStep, StepClass.MediaSettings)) {
                    // If the current step is the media settings step, we need to go forward
                    recorderState.goForward();
                }
                // If the current step is not the media settings step, we just show the
                // current step once we have the media
                recorderState.requiresMedia = false;
            }
            else {
                recorderState.goForward();
            }
        }
    }, [hasActiveTrack, recorderState]);
    const { isPreview } = useRecorderState();
    const roomToken = useRoomToken();
    const { startRecordingMedia, stopRecordingMedia, room } = useMediaRecorder({
        resultUUID: responseUUID,
        onStartRecording,
        mock: isPreview,
        cameraTrack,
        audioTrack,
        screenTrack,
        roomToken,
        response,
        isRecordingEnabled,
    });
    const isWholeScreenRequired = getWholeScreenRecordingRequired(recorderState.userTestDoc);
    const [recordingPermissionState, send] = useMachine(mediaPermissionsMachine, {
        context: Object.assign(Object.assign({}, permissions), { response, entireScreenRequired: isWholeScreenRequired, required: isRecordingRequired }),
    });
    const endTracks = useAction(() => {
        [cameraTrack, audioTrack, screenTrack].forEach((track) => {
            track === null || track === void 0 ? void 0 : track.stop();
        });
    }, [audioTrack, cameraTrack, screenTrack]);
    const skipRecording = useAction(() => {
        if (!recorderState.currentStep.isRequired) {
            endTracks();
            setConnectionStatus('idle');
            send('SKIP_RECORDING');
            recorderState.goForward();
        }
        else {
            console.error('skipRecording was called on a required step');
        }
    }, [endTracks, recorderState, send]);
    const userRecordingRequested = permissions.webcam ||
        (permissions.microphone &&
            !(!permissions.webcam && permissions.microphone && permissions.screen));
    const screenRecordingRequested = permissions.screen;
    const startRecording = useAction(() => __awaiter(this, void 0, void 0, function* () {
        if (!userRecordingRequested && !screenRecordingRequested) {
            return;
        }
        setConnectionStatus('connecting');
        send('START_RECORDING');
        yield startRecordingMedia();
    }), [
        userRecordingRequested,
        screenRecordingRequested,
        send,
        startRecordingMedia,
    ]);
    // the down arrow on the media step doesn't know whether the tracks are available or not
    // so we compute it here and pass down a simple goforward function
    const onGoForward = () => {
        var _a, _b, _c;
        const hasActiveTrack = ((_a = cameraTrack === null || cameraTrack === void 0 ? void 0 : cameraTrack.mediaStream) === null || _a === void 0 ? void 0 : _a.active) ||
            ((_b = audioTrack === null || audioTrack === void 0 ? void 0 : audioTrack.mediaStream) === null || _b === void 0 ? void 0 : _b.active) ||
            ((_c = screenTrack === null || screenTrack === void 0 ? void 0 : screenTrack.mediaStream) === null || _c === void 0 ? void 0 : _c.active);
        if (hasActiveTrack) {
            startRecording();
        }
        else {
            skipRecording();
        }
    };
    const stopRecording = useAction(() => {
        stopRecordingMedia();
        setConnectionStatus('disconnected');
    }, [stopRecordingMedia]);
    return {
        hasActiveTrack,
        hasActiveCameraTrack,
        hasActiveAudioTrack,
        hasActiveScreenTrack,
        startRecording,
        connectionStatus,
        stopRecording,
        recordingPermissionState,
        send,
        setCameraTrack,
        setScreenTrack,
        setAudioTrack,
        skipRecording,
        onGoForward,
        room: room !== null && room !== void 0 ? room : null,
    };
}
