import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { observer } from 'mobx-react';
import * as React from 'react';
import { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Button, Heading, InputWithLabel, } from '@marvelapp/ballpark-components';
import { Box, Flex } from '@marvelapp/ui';
import { showGenericServerError } from '@marvelapp/ui-internal';
export var PasswordValidationStatus;
(function (PasswordValidationStatus) {
    PasswordValidationStatus["Ready"] = "READY";
    PasswordValidationStatus["InProgress"] = "IN_PROGRESS";
    PasswordValidationStatus["Complete"] = "COMPLETE";
    PasswordValidationStatus["Error"] = "ERROR";
})(PasswordValidationStatus || (PasswordValidationStatus = {}));
export function verifyPassword(projectID, password, setValidationState, setToken, recordTestResult) {
    const url = `${CONFIG.pie.host}/api/project/verify/${projectID}/`;
    const data = new FormData();
    data.append('password', password);
    setValidationState(PasswordValidationStatus.InProgress);
    return fetch(url, {
        method: 'POST',
        body: data,
        credentials: 'same-origin',
    })
        .then((response) => {
        if (response.ok) {
            const responseJson = response.json();
            responseJson.then((res) => {
                setToken(res.token);
                if (recordTestResult)
                    recordTestResult(res.token);
            });
            setValidationState(PasswordValidationStatus.Complete);
            return responseJson;
        }
        if (response.status === 403) {
            setValidationState(PasswordValidationStatus.Error);
            return;
        }
        throw new Error(`Password verification error: ${response.statusText}`);
    })
        .catch(() => {
        const toastId = 'passwordVerification';
        showGenericServerError({
            toastId,
            options: { autoClose: 5000 },
        });
    });
}
export default observer(function VerifyPassword({ projectId, validationState, setValidationState, setToken, recordTestResult, }) {
    const intl = useIntl();
    const [password, setPassword] = useState('');
    const isInputErroring = validationState === PasswordValidationStatus.Error;
    return (_jsx(Flex, { alignItems: "center", height: "100vh", justifyContent: "center", width: "100vw", children: _jsx(Box, { width: 340, "data-testid": "password-verification", children: _jsxs("form", { onSubmit: (e) => {
                    e.preventDefault();
                    verifyPassword(projectId, password, setValidationState, setToken, recordTestResult);
                }, children: [_jsx(Heading, { size: "xl", marginBottom: "m", children: _jsx(FormattedMessage, { id: 'qQoE8/', defaultMessage: 'Restricted access' }) }), _jsx(InputWithLabel, { error: isInputErroring &&
                            intl.formatMessage({ id: "yHEV5w", defaultMessage: "Incorrect Password" }), id: "passwordInput", kind: isInputErroring ? 'error' : 'default', err: true, label: intl.formatMessage({ id: "mhBGtF", defaultMessage: "Password" }), mb: 3, type: "password", placeholder: intl.formatMessage({ id: "FaJ9Xu", defaultMessage: "Enter password" }), onChange: (e) => {
                            if (isInputErroring)
                                setValidationState(PasswordValidationStatus.Ready);
                            setPassword(e.target.value);
                        }, inputSize: "l", width: "100%" }), _jsx(Button, { hasSucceeded: validationState === PasswordValidationStatus.Complete, isLoading: validationState === PasswordValidationStatus.InProgress, isDisabled: !password, kind: "primary", size: "s", children: _jsx(FormattedMessage, { id: 'k7i2CM', defaultMessage: 'Submit Password' }) })] }) }) }));
});
