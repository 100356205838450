export function getStatus(response) {
    return response.status;
}
export function getResponses(response) {
    var _a;
    return [...((_a = response.screenerResponses) !== null && _a !== void 0 ? _a : []), ...response.responses];
}
export function getLastTimelineEvent(response) {
    return response.timeline[response.timeline.length - 1];
}
export function getResponsebyUUID(response, uuid) {
    return response.responses.find((r) => r.stepUUID === uuid);
}
export function getScreenerResponsebyUUID(response, uuid) {
    var _a;
    return (_a = response.screenerResponses) === null || _a === void 0 ? void 0 : _a.find((r) => r.stepUUID === uuid);
}
