import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { observer } from 'mobx-react';
import { CardSortingUtils } from '@marvelapp/user-test-creator';
import { MetricsAccordion, MetricsAccordionHeading } from './MetricsAccordion';
import { getCardMetrics } from './metricUtils';
import { useMetricsContext } from './metricsContext';
export const CardMetrics = observer(function CardMetrics() {
    const { step, cardAgreement, categoryFrequency, categoryUUIDToCategoryMap, categoryPositionAvg, } = useMetricsContext();
    const cards = CardSortingUtils.getCards(step);
    const cardMetrics = getCardMetrics({
        cards,
        cardAgreement,
        categoryFrequency,
        categoryPositionAvg,
        categoryUUIDToCategoryMap,
    });
    return (_jsx(MetricsAccordion, { className: "pb-8", items: cardMetrics, headings: _jsxs(_Fragment, { children: [_jsx(MetricsAccordionHeading, { className: "flex-1", children: "Card" }), _jsx(MetricsAccordionHeading, { className: "w-20", children: "Categories" }), _jsx(MetricsAccordionHeading, { className: "w-20" }), _jsx(MetricsAccordionHeading, { className: "flex-1", children: "Agreement" })] }), type: "card" }));
});
