import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { isNil, range } from 'lodash-es';
import { action } from 'mobx';
import { observer } from 'mobx-react';
import * as React from 'react';
import { useAction, useGetUserTest, useSlideUIContext, } from '@marvelapp/ballpark-application';
import { Dropdown, LegacySelect, Stepper, } from '@marvelapp/ballpark-components';
import styled from '@marvelapp/styled';
import { Box, Flex } from '@marvelapp/ui';
import { RatingStepUtils, RatingType, StepClass, StepUtils, } from '@marvelapp/user-test-creator';
import EditableText from '../../components/Editable/EditableText';
import PreferenceRateItem from '../../components/PreferenceRateItem';
import { DecoratedSlide } from '../common/DecoratedSlide';
import { StepOptions } from '../common/Options';
import SlideContent from '../common/SlideContent';
export const RateEdit = observer(function RateEdit({ onMainCTAClick, }) {
    const { step } = useSlideUIContext();
    const userTest = useGetUserTest();
    if (!StepUtils.isOfType(step, StepClass.RatingScale))
        return null;
    // This ensures that the old tests still uses index + 1 to draw
    function drawFromOffset(index) {
        // If there is no create date
        if (isNil(userTest.createdAt)) {
            return index + 1;
        }
        // If there is a create date
        return index + RatingStepUtils.getMinRating(step);
    }
    return (_jsx(DecoratedSlide, { children: _jsxs(SlideContent, { ctaOnClick: onMainCTAClick, children: [_jsx(RatingsWrapper, { children: range(getSettingsCount(userTest, step)).map((rating, index) => (_jsx(RatingWrapper, { children: _jsx(PreferenceRateItem, { canEdit: false, index: drawFromOffset(index), selected: true, type: RatingStepUtils.getRatingType(step), size: "compact" }) }, `rating-${rating}`))) }), _jsxs(Flex, { children: [_jsx(Label, { id: "rating-label-start", onSaveValue: action((value) => {
                                RatingStepUtils.setStartLabel(step, value);
                            }), value: RatingStepUtils.getRatingLabel(step, 'start'), textAlign: "left" }), _jsx(Label, { id: "rating-label-middle", onSaveValue: action((value) => {
                                RatingStepUtils.setMidLabel(step, value);
                            }), value: RatingStepUtils.getRatingLabel(step, 'middle'), textAlign: "center" }), _jsx(Label, { id: "rating-label-end", onSaveValue: action((value) => {
                                RatingStepUtils.setEndLabel(step, value);
                            }), value: RatingStepUtils.getRatingLabel(step, 'end'), textAlign: "right" })] })] }) }));
});
export const RatingStepSettings = observer(function RatingStepSettings() {
    const { step } = useSlideUIContext();
    const userTest = useGetUserTest();
    const increase = useAction(() => {
        if (!StepUtils.isOfType(step, StepClass.RatingScale))
            return;
        let upperLimit = 10;
        // If there is a createdAt , that means it is a new test created with the start from 0 | 1 option
        if (userTest.createdAt &&
            RatingStepUtils.getRatingType(step) === RatingType.Numerical) {
            // Change the upper limit based on the min, if 0 , then 11, if 1 then 10
            upperLimit = RatingStepUtils.getMinRating(step) === 0 ? 11 : 10;
        }
        if (getSettingsCount(userTest, step) < upperLimit) {
            const rating = RatingStepUtils.getMaxRating(step) + 1;
            RatingStepUtils.setMaxRating(step, rating);
        }
    }, [step, userTest]);
    const decrease = useAction(() => {
        if (!StepUtils.isOfType(step, StepClass.RatingScale))
            return;
        const minCount = 5;
        if (RatingStepUtils.getMaxRating(step) > minCount) {
            const rating = RatingStepUtils.getMaxRating(step) - 1;
            RatingStepUtils.setMaxRating(step, rating);
        }
    }, [step]);
    const onSelectRatingType = useAction((event) => {
        if (!StepUtils.isOfType(step, StepClass.RatingScale))
            return;
        RatingStepUtils.setRatingType(step, event.target.value);
        if (RatingStepUtils.getRatingType(step) !== RatingType.Numerical) {
            // Set rating minimum to 1
            RatingStepUtils.setMinRating(step, 1);
        }
    }, [step]);
    const onSelectStartType = useAction((event) => {
        if (!StepUtils.isOfType(step, StepClass.RatingScale))
            return;
        const startType = Number(event.target.value);
        if (startType !== 0 && startType !== 1)
            return;
        RatingStepUtils.setMinRating(step, startType);
    }, [step]);
    if (!StepUtils.isOfType(step, StepClass.RatingScale))
        return null;
    const iconTypes = Object.keys(RatingType);
    return (_jsxs(StepOptions, { children: [_jsx(Dropdown.Label, { description: "Choose the shape of your rating scale", hasBorder: true, id: "shape-type", title: "Shape", children: _jsx(LegacySelect, { size: "s", "data-testid": "shape-type", value: RatingStepUtils.getRatingType(step), onChange: onSelectRatingType, children: iconTypes.map((type) => (_jsx("option", { value: type, children: type }, type))) }) }), !isNil(userTest.createdAt) &&
                RatingStepUtils.getRatingType(step) === RatingType.Numerical && (_jsx(Dropdown.Label, { description: "Choose whether to start at 0 or 1", hasBorder: true, id: "star-start-setting", title: "Start scale at", children: _jsxs(LegacySelect, { size: "s", "data-testid": "start-type", value: RatingStepUtils.getMinRating(step), onChange: onSelectStartType, children: [_jsx("option", { value: 0, children: 0 }, "zero"), _jsx("option", { value: 1, children: 1 }, "one")] }) })), _jsx(Dropdown.Label, { description: "Choose the number of options", id: "star-count-stepper", title: "Count", children: _jsxs(Stepper, { onDecrement: decrease, onIncrement: increase, size: "s", stepperTestId: "star-count-stepper", children: [getSettingsCount(userTest, step), ' ', RatingStepUtils.getRatingType(step)] }) })] }));
});
function getSettingsCount(userTest, step) {
    if (userTest.createdAt &&
        RatingStepUtils.getRatingType(step) === RatingType.Numerical) {
        // Test can start from 0 or 1
        // If 0, the count is max + 1
        return RatingStepUtils.getMinRating(step) === 0
            ? RatingStepUtils.getMaxRating(step) + 1
            : RatingStepUtils.getMaxRating(step);
    }
    return RatingStepUtils.getMaxRating(step);
}
const Label = observer(function Label({ id, onSaveValue, textAlign, value, }) {
    return (_jsx(LabelWrapper, { children: _jsx(EditableText, { testId: id, fontSize: "m", id: id, kind: "paragraph", lineHeight: "1", onSaveValue: onSaveValue, placeholder: "Label (optional)", tabIndex: -1, textAlign: textAlign, value: value }) }));
});
const RatingsWrapper = styled(Box).attrs({
    marginY: 's',
}) `
  width: 100%;
  display: flex;
  align-items: center;
  position: relative;
`;
const RatingWrapper = styled(Box) `
  flex: 1;
  position: relative;
  margin-right: 2px;
  &:last-child {
    margin-right: 0;
  }
`;
const LabelWrapper = styled(Box) `
  flex: 1;
  padding-left: 5px;
  padding-right: 5px;
  textarea {
    display: block;
  }
`;
