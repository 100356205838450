import { __rest } from "tslib";
import { jsx as _jsx } from "react/jsx-runtime";
import { observer } from 'mobx-react';
import { forwardRef, useState } from 'react';
import * as React from 'react';
import { useIntl } from 'react-intl';
import { InputWithLabel } from '@marvelapp/ballpark-components';
import { QuestionStepType } from '@marvelapp/user-test-creator';
import { useRecorderState } from '../../components/Record/RecorderContext';
import { useAutoFocus } from '../../hooks/useAutoFocus';
import BaseQuestionRecord from '../Question/BaseQuestionRecord';
const EmailRecord = observer(function EmailRecord(_a) {
    var rest = __rest(_a, []);
    const [inputRef, setInputRef] = useState(null);
    useAutoFocus(inputRef);
    return (_jsx(BaseQuestionRecord, Object.assign({ renderInput: ({ value, onChange }) => (_jsx(EmailInput, { value: value, onChange: onChange, ref: setInputRef })), type: QuestionStepType.Email }, rest)));
});
export default EmailRecord;
const EmailInput = observer(forwardRef(function EmailInput({ value, onChange }, ref) {
    const intl = useIntl();
    const state = useRecorderState();
    const stepError = state.showStepError ? state.stepError : '';
    return (_jsx(InputWithLabel, { "data-testid": "answer-step", error: stepError, kind: stepError ? 'error' : 'primary', label: intl.formatMessage({ id: "Im27cF", defaultMessage: "Email address" }), maxLength: 255, onChange: (event) => onChange(event.target.value), placeholder: "your@email.com", size: "xl", type: "email", value: value, width: "100%", ref: ref }));
}));
