import { jsx as _jsx } from "react/jsx-runtime";
import { usePropReaction } from '@ballpark/core';
import { getCoreRowModel, useReactTable } from '@tanstack/react-table';
import { runInAction } from 'mobx';
import { observer } from 'mobx-react';
import { useEffect, useMemo } from 'react';
import * as React from 'react';
import { Redirect, useRouteMatch } from 'react-router-dom';
import { useGetUserTest } from '@marvelapp/ballpark-application';
import { NotFound } from '@marvelapp/ballpark-components';
import { MUST_PASS_ID } from '../../graphql/errors';
import { getPageError, getPageErrorCopy } from '../../utils/getPageErrorCopy';
import { NoAccess } from '../NoAccess';
import { Responses } from './Responses';
import { ResponsesContextProvider, useResponsesContext, } from './ResponsesContext';
import { getColumns } from './columnUtils';
import { useCreateDataQueryFromState } from './responseQueryUtils';
import { useQueryUserTest } from './useQueryUserTest';
const ResponsePageWithContext = observer(function ResponsesPage() {
    var _a, _b, _c;
    const match = useRouteMatch();
    const projectPk = match.params.id;
    const context = useResponsesContext();
    const { rowSelection, setRowSelection, sorting, setSorting, columnVisibility, setColumnVisibility, } = context;
    const userTest = useGetUserTest();
    const dataQuery = useCreateDataQueryFromState();
    const { data, projectData, userTestResults, networkStatus, error, loading, } = useQueryUserTest(Number(projectPk), dataQuery);
    const isSelectionEnabled = userTestResults.length > 0 && !loading;
    const columns = useMemo(() => getColumns({
        userTest,
        projectPk,
        isSelectionEnabled,
    }), [userTest, projectPk, isSelectionEnabled]);
    const table = useReactTable({
        data: userTestResults,
        columns,
        getCoreRowModel: getCoreRowModel(),
        onRowSelectionChange: setRowSelection,
        onSortingChange: setSorting,
        onColumnVisibilityChange: setColumnVisibility,
        getRowId: (originalRow) => originalRow.pk.toString(),
        state: {
            rowSelection,
            sorting,
            columnVisibility,
        },
        manualSorting: true,
        enableMultiSort: true,
        isMultiSortEvent: (event) => event === null || event === void 0 ? void 0 : event.shiftKey,
    });
    const stateProps = {
        // We're using the `usePropReaction` hook from MobX to combine some
        // stateProps with MobX props. This hook performs a referential equality
        // check to determine if the state has changed. However, with
        // `useReactTable`, the table reference does not update when its state
        // is updated.
        // This means that the referential equality check in `usePropReaction`
        // will not detect changes in the table's state.
        // As a result, children components that access the table object via the
        // `useResponsesContext` will not update properly when the table state
        // is updated.
        // To work around this, we're creating a new object every time the state
        // changes: this ensures that the table object has a new reference each
        // time, which will cause the children components to update correctly.
        table: Object.assign({}, table),
        projectData,
        userTestResults,
        networkStatus,
        totalResults: (_b = (_a = data === null || data === void 0 ? void 0 : data.userTest) === null || _a === void 0 ? void 0 : _a.resultStats) === null || _b === void 0 ? void 0 : _b.totalResults,
        projectPk,
        columns,
    };
    useEffect(() => {
        runInAction(() => Object.assign(context, stateProps));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [context]);
    usePropReaction((changedProps) => {
        Object.assign(context, changedProps);
    }, stateProps);
    if ((error === null || error === void 0 ? void 0 : error.message) ===
        "GraphQL error: You don't have permission to access that user test") {
        return _jsx(NoAccess, {});
    }
    const pageError = getPageError({
        data: { userTest: projectData },
        loading,
        error,
    }, MUST_PASS_ID);
    if (pageError) {
        return _jsx(NotFound, Object.assign({}, getPageErrorCopy(pageError)));
    }
    if ((_c = projectData === null || projectData === void 0 ? void 0 : projectData.prototype) === null || _c === void 0 ? void 0 : _c.isLocked) {
        return _jsx(Redirect, { to: `/projects/team` });
    }
    return _jsx(Responses, {});
});
export const ResponsesPage = observer(function ResponsesPage({ isLoadingUserTestDoc, }) {
    if (isLoadingUserTestDoc)
        return null;
    return (_jsx(ResponsesContextProvider, { children: _jsx(ResponsePageWithContext, {}) }));
});
