import { KeyboardCode, KeyboardSensor, PointerSensor, } from '@dnd-kit/core';
// Adapted from https://github.com/clauderic/dnd-kit/issues/863
// These custom sensors define an extra callback which can be used to
// automatically prevents drag on interactive child elements such as inputs,
// textareas. A default implementation of isDraggableElement is provided.
const always = () => true;
const defaultKeyboardCodes = {
    start: [KeyboardCode.Space, KeyboardCode.Enter],
    cancel: [KeyboardCode.Esc],
    end: [KeyboardCode.Space, KeyboardCode.Enter],
};
export class CustomKeyboardSensor extends KeyboardSensor {
}
CustomKeyboardSensor.activators = [
    {
        eventName: 'onKeyDown',
        handler: (event, { onActivation, keyboardCodes = defaultKeyboardCodes, isDraggableElement = always, }, { active }) => {
            const { code } = event.nativeEvent;
            if (keyboardCodes.start.includes(code)) {
                const activator = active.activatorNode.current;
                if (activator && event.target !== activator) {
                    return false;
                }
                if (!(event.target instanceof HTMLElement)) {
                    return false;
                }
                if (!isDraggableElement(event.target)) {
                    return false;
                }
                event.preventDefault();
                onActivation === null || onActivation === void 0 ? void 0 : onActivation({ event: event.nativeEvent });
                return true;
            }
            return false;
        },
    },
];
export class CustomPointerSensor extends PointerSensor {
}
CustomPointerSensor.activators = [
    {
        eventName: 'onPointerDown',
        handler: (syntheticEvent, { onActivation, isDraggableElement = always }) => {
            const { nativeEvent: event } = syntheticEvent;
            // TODO: remove as part of BALL-1861
            // Check if the event target or any of its parents has the data-dndkit-drag attribute
            const dragElement = event.target.closest('[data-dndkit-drag]');
            if (dragElement) {
                onActivation === null || onActivation === void 0 ? void 0 : onActivation({ event });
                return true;
            }
            if (!event.isPrimary || event.button !== 0) {
                return false;
            }
            if (!(event.target instanceof HTMLElement)) {
                return false;
            }
            if (!isDraggableElement(event.target)) {
                return false;
            }
            onActivation === null || onActivation === void 0 ? void 0 : onActivation({ event });
            return true;
        },
    },
];
const interactiveTags = [
    'textarea',
    'input',
    'button',
    'select',
    'option',
    'optgroup',
    'video',
    'audio',
];
const getPathToRoot = (el) => {
    const path = [el];
    let current = el;
    while (current.parentElement) {
        path.push(current.parentElement);
        current = current.parentElement;
    }
    return path;
};
const isInteractive = (el) => {
    if (el.isContentEditable)
        return true;
    return interactiveTags.includes(el.tagName.toLowerCase());
};
export const isDraggableElement = (el) => {
    const path = getPathToRoot(el);
    return !path.some((pathEl) => {
        if (isInteractive(pathEl))
            return true;
        if (pathEl.dataset.dndkitNoDrag)
            return true;
        return false;
    });
};
