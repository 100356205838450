import { useEffect, useState } from 'react';
import useBoolean from './useBoolean';
// This hook let's us determine if an element that we are truncating using
// this CSS solution: https://css-tricks.com/line-clampin/#aa-the-standardized-way
// is currently collapsed
// Solution adapted from: https://stackoverflow.com/questions/42012130/how-to-detect-overflow-of-react-component-without-reactdom/70947064#70947064
export function useTextCollapse() {
    const [overflowActive, setOverflowActive] = useState(false);
    const { value: isTextCollapsed, setFalse: expandText, setTrue: collapseText, } = useBoolean(true);
    const [textRef, setTextRef] = useState(null);
    const [height, setHeight] = useState(null);
    const checkOverflow = (textContainer) => {
        if (textContainer) {
            return (textContainer.offsetHeight < textContainer.scrollHeight ||
                textContainer.offsetWidth < textContainer.scrollWidth);
        }
        return false;
    };
    useEffect(() => {
        if (checkOverflow(textRef)) {
            setOverflowActive(true);
            return;
        }
        setOverflowActive(false);
    }, [overflowActive, textRef, height]);
    useEffect(() => {
        if (textRef) {
            const observer = new ResizeObserver((entries) => {
                setHeight(entries[0].contentRect.height);
            });
            observer.observe(textRef);
            return () => observer.unobserve(textRef);
        }
    }, [textRef]);
    return {
        setTextRef,
        isTextCollapsed,
        overflowActive,
        expandText,
        collapseText,
    };
}
