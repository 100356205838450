import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { observer } from 'mobx-react';
import { Link, Loader } from '@marvelapp/ballpark-components';
import { useDownloadCSV } from '../../../NewResponses/MoreActionsDropdown/useDownloadCSV';
export const DownloadCSV = observer(function DownloadCSV({ projectPk, stepUUID, }) {
    const { triggerDownload, isDownloadCSVAvailable, isLoading } = useDownloadCSV({
        projectPk,
        stepUuid: stepUUID,
    });
    if (isLoading) {
        return (_jsxs(_Fragment, { children: [_jsx(Loader, { className: "mr-0.5 inline-block h-4 w-4" }), "Generating CSV..."] }));
    }
    return (_jsx(Link, { "data-testid": "download-csv", onClick: isDownloadCSVAvailable ? triggerDownload : null, to: isDownloadCSVAvailable ? null : '/plans?intent=csv-download"', underline: true, children: "Download CSV" }));
});
