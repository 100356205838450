import { isNil } from 'lodash-es';
import * as YesOrNoResponseUtils from '../../response/yesOrNoStep';
import { getOrderedListOfAllSteps, isExitStep, isWelcomeStep, } from '../../userTest/selectors';
import * as StepUtils from '../../userTest/stepUtils';
import { StepClass } from '../../userTest/types';
// https://linear.app/ballpark/issue/BALL-961
// eslint-disable-next-line import/no-cycle
import * as ScreenerUtils from '../screenerUtils';
import { hasMultiConditions } from './hasMultiConditions';
import { countValidRules } from './multiConditionStep/clearInvalidConditions';
import { getNextStepUUID as getMultiConditionNextStepUUID } from './multiConditionStep/getNextStepUUID';
import { getNextStepUUID as getPrototypeGoalNextStepUUID } from './prototypeTask';
import { getNextStepUUID as getScreenerNextStepUUID } from './screener';
import { getConditionalNextStepUUID as getYesNoConditionalNextStepUUID } from './yesno';
export function getConditionalNextStepUUID(step, response, isScreenerStep) {
    var _a, _b, _c, _d;
    if (StepUtils.isYesNoStep(step) && response.className === StepClass.YesOrNo) {
        const answer = YesOrNoResponseUtils.getAnswer(response);
        return getYesNoConditionalNextStepUUID(step, answer);
    }
    if (StepUtils.isGoalBasedPrototype(step) &&
        response.className === StepClass.PrototypeTask) {
        return getPrototypeGoalNextStepUUID(step, response);
    }
    // get answer from response
    const answer = getAnswer(response);
    // TODO I want to move this horrible conditional to a function but the type
    // narrowing is lost when I do that
    if ('conditions' in step &&
        step.conditions &&
        'rules' in step.conditions &&
        ((_a = step.conditions.rules) === null || _a === void 0 ? void 0 : _a.length) &&
        // TODO if we normalized yes/no and choice steps we could handle yes/no here
        typeof answer !== 'boolean' &&
        !isNil(answer)) {
        // Screener steps are implemented as multiple choice steps but the logic
        // comparing answers to rules/requirements is different
        if (isScreenerStep &&
            StepUtils.isChoiceStep(step) &&
            Array.isArray(answer)) {
            return getScreenerNextStepUUID(step.conditions, answer, !!step.multipleSelectionMode);
        }
        const { rules } = step.conditions;
        return getMultiConditionNextStepUUID(rules, answer, (_b = step.conditions) === null || _b === void 0 ? void 0 : _b.ALWAYS);
    }
    if (!('conditions' in step))
        return null;
    return (_d = (_c = step.conditions) === null || _c === void 0 ? void 0 : _c.ALWAYS) !== null && _d !== void 0 ? _d : null;
}
export const getHasConditionalLogicSet = (steps) => {
    const stepWithConditionalLogic = steps.find((step) => {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k;
        if (StepUtils.isOfType(step, StepClass.MediaSettings) ||
            StepUtils.isLegalStep(step) ||
            !step.conditions) {
            return false;
        }
        if (step.conditions.ALWAYS) {
            return true;
        }
        if (StepUtils.isYesNoStep(step)) {
            return !!(((_a = step.conditions) === null || _a === void 0 ? void 0 : _a.ALWAYS) ||
                ((_b = step.conditions) === null || _b === void 0 ? void 0 : _b.answer.true) ||
                ((_c = step.conditions) === null || _c === void 0 ? void 0 : _c.answer.false));
        }
        if (StepUtils.isGoalBasedPrototype(step)) {
            return !!(((_d = step.conditions) === null || _d === void 0 ? void 0 : _d.ALWAYS) ||
                ((_f = (_e = step.conditions) === null || _e === void 0 ? void 0 : _e.hitGoal) === null || _f === void 0 ? void 0 : _f.true) ||
                ((_h = (_g = step.conditions) === null || _g === void 0 ? void 0 : _g.hitGoal) === null || _h === void 0 ? void 0 : _h.false));
        }
        if ('rules' in step.conditions) {
            return !!((_k = (_j = step.conditions) === null || _j === void 0 ? void 0 : _j.rules) === null || _k === void 0 ? void 0 : _k.length);
        }
        return false;
    });
    return !!stepWithConditionalLogic;
};
function getAnswer(response) {
    if ('answer' in response && 'value' in response.answer) {
        return response.answer.value;
    }
    if ('answers' in response &&
        Array.isArray(response.answers) &&
        response.answers.length) {
        // we only allow one answer to question steps for now
        return response.answers[0].value;
    }
}
export function getIsDeletedStepConditional(mutable, deletedStepUUID) {
    const steps = getOrderedListOfAllSteps({ userTest: mutable });
    return !!steps.find((step) => {
        var _a, _b;
        if (StepUtils.isOfType(step, StepClass.MediaSettings) ||
            StepUtils.isLegalStep(step))
            return false;
        if (step.conditions) {
            if (step.conditions.ALWAYS === deletedStepUUID) {
                return true;
            }
            if (hasMultiConditions(step)) {
                return !!step.conditions.rules.find(({ destinationStepUUID }) => destinationStepUUID === deletedStepUUID);
            }
            if (StepUtils.isOfType(step, StepClass.YesOrNo)) {
                if (step.conditions.answer.true === deletedStepUUID) {
                    return true;
                }
                if (step.conditions.answer.false === deletedStepUUID) {
                    return true;
                }
            }
            if (StepUtils.isGoalBasedPrototype(step)) {
                if (((_a = step.conditions.hitGoal) === null || _a === void 0 ? void 0 : _a.true) === deletedStepUUID) {
                    return true;
                }
                if (((_b = step.conditions.hitGoal) === null || _b === void 0 ? void 0 : _b.false) === deletedStepUUID) {
                    return true;
                }
            }
        }
        return false;
    });
}
export function setAlwaysConditionalRule(mutable, selectedStepUUID) {
    if (mutable.conditions) {
        mutable.conditions.ALWAYS = selectedStepUUID;
    }
    else {
        mutable.conditions = {
            ALWAYS: selectedStepUUID,
        };
    }
}
export function deleteAlwaysConditionalRule(mutable) {
    if (mutable.conditions) {
        mutable.conditions.ALWAYS = null;
    }
    else {
        mutable.conditions = {
            ALWAYS: null,
        };
    }
}
export function isConditionable(step, userTest) {
    // step is conditionable if its not one of the below steps
    const isScreenerStep = ScreenerUtils.isScreenerStep(userTest, step.uuid);
    return !(isWelcomeStep(userTest, step.uuid) ||
        isExitStep(userTest, step.uuid) ||
        StepUtils.isOfType(step, StepClass.MediaSettings) ||
        StepUtils.isLegalStep(step) ||
        isScreenerStep);
}
/** returns multi conditions rule count including partial rules & excluding Always */
export function getMultiConditionRulesCount(mutable, userTest) {
    var _a;
    if (!isConditionable(mutable, userTest))
        return 0;
    let ruleCount = 0;
    if (StepUtils.isOneOfType(mutable, [
        StepClass.Question,
        StepClass.RatingScale,
    ]) ||
        StepUtils.isChoiceStep(mutable)) {
        if ((_a = mutable.conditions) === null || _a === void 0 ? void 0 : _a.rules) {
            ruleCount += mutable.conditions.rules.length;
        }
    }
    return ruleCount;
}
export function getConditionsCount(mutable, userTest) {
    var _a, _b, _c, _d, _e, _f, _g;
    if (!isConditionable(mutable, userTest))
        return 0;
    const hasAlwaysRule = !!((_a = mutable.conditions) === null || _a === void 0 ? void 0 : _a.ALWAYS);
    let ruleCount = hasAlwaysRule ? 1 : 0;
    if (StepUtils.isYesNoStep(mutable)) {
        if ((_b = mutable.conditions) === null || _b === void 0 ? void 0 : _b.answer.true)
            ruleCount += 1;
        if ((_c = mutable.conditions) === null || _c === void 0 ? void 0 : _c.answer.false)
            ruleCount += 1;
    }
    if (StepUtils.isGoalBasedPrototype(mutable)) {
        if ((_e = (_d = mutable.conditions) === null || _d === void 0 ? void 0 : _d.hitGoal) === null || _e === void 0 ? void 0 : _e.true)
            ruleCount += 1;
        if ((_g = (_f = mutable.conditions) === null || _f === void 0 ? void 0 : _f.hitGoal) === null || _g === void 0 ? void 0 : _g.false)
            ruleCount += 1;
    }
    if ('conditions' in mutable &&
        mutable.conditions &&
        'rules' in mutable.conditions &&
        mutable.conditions.rules) {
        ruleCount += countValidRules(mutable.conditions.rules);
    }
    return ruleCount;
}
export function clearAllConditions(mutable, userTest) {
    if (!isConditionable(mutable, userTest) || !mutable.conditions)
        return;
    mutable.conditions.ALWAYS = null;
    if ('rules' in mutable.conditions) {
        mutable.conditions.rules = [];
    }
    if (StepUtils.isOfType(mutable, StepClass.YesOrNo)) {
        mutable.conditions.answer.true = null;
        mutable.conditions.answer.false = null;
    }
    if (StepUtils.isGoalBasedPrototype(mutable)) {
        if (mutable.conditions.hitGoal) {
            mutable.conditions.hitGoal.true = null;
            mutable.conditions.hitGoal.false = null;
        }
    }
}
export function hasAnswerBasedRulesSet(mutable, userTest) {
    var _a;
    const ruleCount = getConditionsCount(mutable, userTest);
    if ('conditions' in mutable && ((_a = mutable === null || mutable === void 0 ? void 0 : mutable.conditions) === null || _a === void 0 ? void 0 : _a.ALWAYS))
        return ruleCount > 1;
    return ruleCount > 0;
}
export { clearInvalidConditions } from './multiConditionStep/clearInvalidConditions';
