import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { observer } from 'mobx-react';
import * as React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link, Text } from '@marvelapp/ballpark-components';
import { ENABLE_SCREEN_SHARING_AND_CAMERA_ACCESS } from '@marvelapp/ballpark-help';
import ErrorBox from './ErrorBox';
import { getRecordingDevices } from './utils';
export const WebcamMicPermissionsDeniedError = observer(function WebcamMicPermissionsDeniedError({ permissions, }) {
    const intl = useIntl();
    const devices = getRecordingDevices(intl, permissions);
    return (_jsxs(ErrorBox, { children: [_jsxs(Text, { fontSize: "s", children: [_jsx(FormattedMessage, { id: 'rYglgr', defaultMessage: 'It looks like this browser doesn\'t have permission to access your {recordingDevices}', values: {
                            recordingDevices: devices,
                        } }), "."] }), _jsx(Text, { fontSize: "s", mt: "xs", children: _jsx(FormattedMessage, { id: 'Latink', defaultMessage: '<link>Follow our instructions</link> to change the setting, then refresh and try again.', values: {
                        link: (chunks) => (_jsx(Link, { to: ENABLE_SCREEN_SHARING_AND_CAMERA_ACCESS, children: chunks })),
                    } }) })] }));
});
