import { gql, useSuspenseQuery } from '@apollo/client';
const GET_PROJECT_STATUS = gql `
  query getProjectStatus($pk: Int) {
    userTest(pk: $pk) {
      pk
      pausedAt
      status
    }
  }
`;
export function useGetProjectStatus(pk) {
    return useSuspenseQuery(GET_PROJECT_STATUS, {
        variables: { pk },
    });
}
