import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { gql, useQuery } from '@apollo/client';
import { observer } from 'mobx-react';
import { useState } from 'react';
import * as React from 'react';
import { getPrototypeShareId } from '@marvelapp/core';
import styled from '@marvelapp/styled';
import { Box, PinTR } from '@marvelapp/ui';
import ZoomModal from '../../../components/ZoomModal';
import { DownloadHeatmap } from './DownloadHeatmap';
import HeatmapImage from './HeatmapImage';
import { useEventQueryDataToHeatmapData } from './utils';
const GET_FIRST_CLICK_EVENTS = gql `
  query getFirstClickEvents(
    $shareId: String!
    $maxEvents: Int!
    $eventsResultsPk: [Int!]
    $stepUuid: String!
    $skipEvents: Boolean!
  ) {
    project(shareId: $shareId) {
      pk
      screens(first: 1) {
        edges {
          node {
            pk
            content {
              __typename
              ... on ImageScreen {
                url
                height
                width
              }
            }
            events(
              first: $maxEvents
              triggerTypes: CLICK_OR_TAP
              resultsPk: $eventsResultsPk
              stepUuid: $stepUuid
            ) @skip(if: $skipEvents) {
              edges {
                node {
                  __typename
                  trigger {
                    coordX
                    coordY
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
export const FirstClickHeatmap = observer(function FirstClickHeatmap({ prototypeUrl, resultPk, stepUUID, children, }) {
    const [isZoomed, setIsZoomed] = useState(false);
    const resultPks = resultPk ? [resultPk] : [];
    const { data, loading: eventsLoading } = useQuery(GET_FIRST_CLICK_EVENTS, {
        variables: {
            shareId: getPrototypeShareId(prototypeUrl),
            eventsResultsPk: resultPks,
            maxEvents: 600,
            skipEvents: false,
            stepUuid: stepUUID,
        },
    });
    const { imageUrl, originalWidth, originalHeight, coordinates } = useEventQueryDataToHeatmapData(data, 1);
    const [heatmapDataUri, setHeatmapDataUri] = useState('');
    const heatmap = (maxWidth) => {
        return (_jsx(_Fragment, { children: _jsx(Box, { display: "flex", flexDirection: "row", justifyContent: "center", children: _jsx(HeatmapImage, { data: {
                        max: 1,
                        min: 1,
                        data: coordinates,
                    }, imageUrl: imageUrl || undefined, onLoad: (dataUri) => setHeatmapDataUri(dataUri), isLoading: eventsLoading, sourceHeight: originalHeight, sourceWidth: originalWidth, maxWidth: maxWidth }) }) }));
    };
    return (_jsxs(_Fragment, { children: [children ? (_jsx(HeatmapContainer, { onClick: () => setIsZoomed(true), children: children })) : (_jsxs(Body, { children: [_jsx(PinTR, { top: "xs", right: "xs", children: _jsx(DownloadHeatmap, { heatmapDataUri: heatmapDataUri }) }), _jsx(HeatmapContainer, { onClick: () => setIsZoomed(true), children: heatmap('170px') })] })), _jsx(ZoomModal, { closeButtonTestID: "first-click-zoom-close-button", isOpen: isZoomed, onClose: () => setIsZoomed(false), children: _jsx(Box, { "data-testid": "enlarged-heatmap-image-modal", padding: "l", children: heatmap('100%') }) })] }));
});
const HeatmapContainer = styled(Box) `
  cursor: pointer;
`;
const Body = styled(Box).attrs({
    bg: 'slate2',
    borderRadius: 'l',
    hoverBg: 'slate3',
    padding: 'l',
    transition: 'smooth.fast',
}) `
  display: flex;
  align-items: center;
  justify-content: center;
  user-select: none;
  cursor: pointer;
  transform: translate3d(0, 0, 0);
`;
