import { __rest } from "tslib";
import { createElement as _createElement } from "react";
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { DndContext, DragOverlay, closestCenter } from '@dnd-kit/core';
import { SortableContext, useSortable, verticalListSortingStrategy, } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { AnimatePresence, motion } from 'framer-motion';
import { observer } from 'mobx-react';
import { useCallback, useState } from 'react';
import * as React from 'react';
import { useIntl } from 'react-intl';
import { useAction, useGetUserTest, useSlideUIContext, useUserTestAction, } from '@marvelapp/ballpark-application';
import { Icon, SquareButton, Text } from '@marvelapp/ballpark-components';
import { UI_STEPS_COPY } from '@marvelapp/ballpark-copy';
import theme, { CLOSE_ICON, PLUS_ICON } from '@marvelapp/ballpark-theme';
import styled from '@marvelapp/styled';
import { Box } from '@marvelapp/ui';
import { ChoiceConditionalUtils, MultipleChoiceStepUtils, ScreenerUtils, StepClass, StepUtils, } from '@marvelapp/user-test-creator';
import ChoiceLogicDeleteModal from '../../components/ChoiceLogicDeleteModal';
import EditableRadio from '../../components/Editable/EditableRadio';
import InteractionBlocker from '../../components/InteractionBlocker';
import { useDragging } from '../../hooks/useDragging';
import { useProjectContext } from '../../utils/ProjectContext';
import { getHelpText } from '../../utils/choiceStepUtils';
import SlideContent from '../common/SlideContent';
const animation = {
    animate: {
        opacity: 1,
        x: 0,
    },
    initial: {
        opacity: 0,
        x: '-10%',
    },
    exit: {
        opacity: 0,
        x: '-10%',
    },
    transition: {
        ease: theme.easings.smooth.array,
        duration: 0.35,
    },
};
export const MultipleChoiceContent = observer(function MultipleChoiceContent({ onMainCTAClick, onSetChoiceValue, renderItem, confirmDelete, withSlideCount = true, withVideoGuide = true, withRequiredBadge = true, renderOtherScreenerSelect, }) {
    const intl = useIntl();
    const userTest = useGetUserTest();
    const { step } = useSlideUIContext();
    const [autoFocus, setAutofocus] = useState(false);
    const addNewChoice = useUserTestAction(() => {
        if (!StepUtils.isOfType(step, StepClass.MultipleChoice))
            return;
        if (!autoFocus)
            setAutofocus(true);
        MultipleChoiceStepUtils.addChoice(step, null);
    }, [autoFocus, step]);
    const onKeyDown = useCallback((event) => {
        // Pressing space was causing the drag event to get activated
        // so we want to stop that from happening
        if (event.key === ' ') {
            event.stopPropagation();
        }
        if (event.key === 'Enter') {
            const target = event.target;
            const { form } = target;
            if (!form)
                return;
            const elements = [...form.elements].filter((el) => el.type === 'text');
            const index = elements.indexOf(target);
            const nextElement = elements[index + 1];
            if (nextElement && nextElement.value !== 'Other') {
                nextElement.focus();
            }
            else {
                addNewChoice();
            }
        }
    }, [addNewChoice]);
    const onSubmit = useCallback((event) => event.preventDefault(), []);
    const { draggingItem, handleDragEnd, handleDragStart, sensors } = useDragging({
        pointerSensorOptions: {
            activationConstraint: {
                distance: 5,
            },
        },
    });
    const onDragEnd = useAction((event) => {
        if (!StepUtils.isOfType(step, StepClass.MultipleChoice))
            return;
        const items = step.choices.map((uuid) => `choice-${uuid}`);
        handleDragEnd({
            event,
            items,
            reorderFn: MultipleChoiceStepUtils.reorderChoices,
            parentItem: step,
        });
    }, [handleDragEnd, step]);
    if (!StepUtils.isOfType(step, StepClass.MultipleChoice))
        return null;
    const multipleSelectionMode = MultipleChoiceStepUtils.getMultipleSelectionMode(step);
    const selectionRange = MultipleChoiceStepUtils.getSelectionRange(step);
    const helpText = getHelpText(intl, multipleSelectionMode, selectionRange);
    const choices = MultipleChoiceStepUtils.getChoicesByStepUUID(userTest, step.uuid);
    const items = choices.map((choiceUUID) => `choice-${choiceUUID}`);
    const otherOptionUUID = MultipleChoiceStepUtils.getOtherOptionUUID(step);
    return (_jsx(SlideContent, { ctaOnClick: onMainCTAClick, withSlideCount: withSlideCount, withRequiredBadge: withRequiredBadge, withVideoGuide: withVideoGuide, children: _jsxs(InputsWrapper, { children: [helpText && _jsx(Text, { mb: "s", children: helpText }), _jsxs("form", { onSubmit: onSubmit, children: [_jsxs(AnimatePresence, { children: [_jsx(DndContext, { sensors: sensors, collisionDetection: closestCenter, onDragEnd: onDragEnd, onDragStart: handleDragStart, children: _jsxs(SortableContext, { items: items, strategy: verticalListSortingStrategy, children: [MultipleChoiceStepUtils.mapChoices(step, (choiceValue, uuid) => {
                                                if (uuid === MultipleChoiceStepUtils.getOtherOptionUUID(step)) {
                                                    return null;
                                                }
                                                return (_jsx(DraggableChoice, { step: step, autoFocus: autoFocus, choice: { uuid, value: choiceValue }, uuid: uuid, onKeyDown: onKeyDown, onSetChoiceValue: onSetChoiceValue, renderItem: renderItem, confirmDelete: confirmDelete }, uuid));
                                            }), _jsx(DragOverlay, { children: typeof draggingItem === 'string' ? (_jsx(ChoiceInput, { isOverlay: true, choice: { uuid: draggingItem.replace('choice-', '') }, onKeyDown: onKeyDown, autoFocus: autoFocus, onSetChoiceValue: onSetChoiceValue, renderItem: renderItem, confirmDelete: confirmDelete })) : null })] }) }, "dndContext"), otherOptionUUID && (_jsxs(AnimationWrapper, { animate: animation.animate, exit: animation.exit, initial: animation.exit, transition: animation.transition, children: [_jsx(InteractionBlocker, { flex: 2, children: _jsx(OtherInputRow, { children: _jsx(EditableRadio, { isDisabled: true, testId: "multiple-choice-edit-input-other", placeholder: "Other (type your own answer)", value: "Other (type your own answer)", onChange: () => null, onBlur: () => null, width: "100%" }) }) }), renderOtherScreenerSelect &&
                                            renderOtherScreenerSelect(otherOptionUUID)] }))] }), _jsxs(InputPlaceholder, { cursor: "pointer", "data-testid": "add-new-choice", onClick: addNewChoice, testId: "multiple-choice-edit-add-choice-button", children: [_jsx(IconWrapper, { children: _jsx(Icon, { paths: PLUS_ICON }) }), "Add new choice"] })] })] }) }));
});
const ChoiceInput = observer(function ChoiceInput(_a) {
    var { autoFocus, choice, isDragging = false, isOverlay = false, onKeyDown, removeChoice, onSetChoiceValue, renderItem } = _a, attributes = __rest(_a, ["autoFocus", "choice", "isDragging", "isOverlay", "onKeyDown", "removeChoice", "onSetChoiceValue", "renderItem"]);
    const { uuid } = choice;
    const intl = useIntl();
    const { step } = useSlideUIContext();
    const { getStepKey } = useProjectContext();
    const screenerStepKey = getStepKey(step.uuid);
    if (!StepUtils.isOfType(step, StepClass.MultipleChoice))
        return null;
    let choiceValue = choice.value;
    if (!choiceValue) {
        choiceValue = step.choiceDefinitions[uuid];
    }
    const choiceCount = MultipleChoiceStepUtils.hasOtherChoiceOption(step)
        ? step.choices.length - 1
        : step.choices.length;
    return (_jsx(Box, { opacity: isDragging ? 0.6 : 1, width: "100%", cursor: "pointer", children: _createElement(InputRow, Object.assign({}, animation, { key: uuid }),
            _jsx(Box, { flex: 2, children: _createElement(EditableRadio, Object.assign({}, attributes, { key: screenerStepKey, cursor: "pointer", onKeyDown: onKeyDown, autoFocus: autoFocus, testId: `multiple-choice-edit-input`, placeholder: intl.formatMessage(UI_STEPS_COPY[StepClass.MultipleChoice].inputPlaceholder), value: choiceValue, onChange: (value) => onSetChoiceValue(value, uuid) })) }),
            renderItem && renderItem(uuid, choiceValue),
            choiceCount > 2 && !isOverlay && (_jsx(RemoveChoiceButton, { tabIndex: -1, "data-testid": "multiple-choice-edit-remove-choice-button", onClick: removeChoice, type: "button", size: "l", children: _jsx(Icon, { paths: CLOSE_ICON }) }))) }));
});
const AnimationWrapper = styled(motion.div) `
  display: flex;
  width: calc(100% - 48px);
`;
const DraggableChoice = observer(function DraggableChoice({ uuid, autoFocus, onKeyDown, choice, step, onSetChoiceValue, renderItem, confirmDelete, }) {
    const id = `choice-${uuid}`;
    const { attributes, isDragging, listeners, setNodeRef, transform, transition, } = useSortable({ id });
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const userTest = useGetUserTest();
    const checkLogicAndRemoveChoice = useAction(() => {
        const hasLogicSetOnChoice = ChoiceConditionalUtils.getIsChoiceConditional(step, uuid);
        if (hasLogicSetOnChoice) {
            setShowDeleteModal(true);
        }
        else {
            MultipleChoiceStepUtils.removeChoice(step, uuid);
        }
    }, [uuid, step]);
    const style = {
        transition,
        transform: CSS.Transform.toString(transform),
        zIndex: isDragging ? 100 : 'unset',
    };
    const onConfirmDelete = useAction(() => {
        confirmDelete(uuid, () => setShowDeleteModal(false));
    }, [confirmDelete, uuid]);
    return (_jsxs(_Fragment, { children: [_jsx(DraggableContainer, Object.assign({ "data-testid": "multiple-choice-edit-container", ref: setNodeRef, 
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                style: style }, listeners, { children: _jsx(ChoiceInput, Object.assign({ autoFocus: autoFocus, choice: choice, isDragging: isDragging, onKeyDown: onKeyDown, removeChoice: checkLogicAndRemoveChoice, onSetChoiceValue: onSetChoiceValue, renderItem: renderItem, confirmDelete: confirmDelete }, attributes)) })), showDeleteModal && (_jsx(ChoiceLogicDeleteModal, { isScreenerStep: ScreenerUtils.isScreenerStep(userTest, step.uuid), confirmDelete: onConfirmDelete, trigger: () => setShowDeleteModal(false) }))] }));
});
const DraggableContainer = styled.div `
  min-height: 100%;
  display: flex;
  position: relative;
`;
const RemoveChoiceButton = styled(SquareButton) `
  position: absolute;
  right: 0px;
`;
const InputRow = styled(motion.div) `
  width: 100%;
  display: flex;
  height: 46px;
  align-items: center;
  margin-bottom: ${(props) => props.theme.space.s}px;
  position: relative;
  padding-right: 48px;

  input {
    width: 100%;
  }

  ${RemoveChoiceButton} {
    opacity: 0;
    margin-left: ${(props) => props.theme.space.xs}px;
  }

  &:hover {
    button {
      opacity: 1;
    }
  }
`;
const OtherInputRow = styled.div `
  width: 100%;
  display: flex;
  height: 46px;
  align-items: center;
  margin-bottom: ${(props) => props.theme.space.s}px;
  position: relative;
`;
const InputsWrapper = styled(Box).attrs({
    marginTop: 's',
}) `
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  max-width: 550px;
`;
const InputPlaceholder = styled(Box).attrs({
    bg: 'sky2',
    borderRadius: 'm',
    color: 'sky12',
    fontSize: 's',
    fontWeight: 'medium',
    hoverBg: 'sky3',
    transition: 'smooth.fast',
}) `
  width: calc(100% - 48px);
  height: 40px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
`;
const IconWrapper = styled(Box).attrs({
    color: 'sky12',
}) `
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 40px;
  height: 40px;

  svg {
    display: block;
    width: 24px;
    height: 24px;
  }
`;
