import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { observer } from 'mobx-react';
import { useCallback, useRef } from 'react';
import { useAction, useGetUserTest } from '@marvelapp/ballpark-application';
import { Popover } from '@marvelapp/ballpark-components';
import styled from '@marvelapp/styled';
import { Box } from '@marvelapp/ui';
import { InstructionStepUtils, getSteps } from '@marvelapp/user-test-creator';
import { StepOptions } from '../common/Options';
import UrlInput from '../common/UrlInput';
export default observer(function ExitDotMenu() {
    const userTest = useGetUserTest();
    const { exitStep } = getSteps(userTest);
    const setRedirectUrl = useAction((value) => InstructionStepUtils.setRedirectUrl(exitStep, value), [exitStep]);
    const setHasButton = useAction(() => InstructionStepUtils.setHasButton(exitStep, !exitStep.hasButton), [exitStep]);
    const ref = useRef(null);
    const onOpenChange = useCallback((isOpen) => {
        var _a;
        if (!isOpen) {
            // Popover is being closed, so call 'validate' which has the side-effect
            // of making the link valid by, say, adding a protocol if it's missing,
            // and saving to the document. We have to do this as onBlur doesn't fire
            // when the popover is closed.
            (_a = ref.current) === null || _a === void 0 ? void 0 : _a.validate();
        }
    }, []);
    return (_jsxs(StepOptions, { onOpenChange: onOpenChange, children: [_jsx(Popover.Toggle, { checked: exitStep.hasButton, id: "button-toggle", description: "The main CTA on the exit step is optional. It defaults to closing the tab, but you can redirect to a url.", testId: "exit-toggle-has-button", onChange: setHasButton, title: "Button" }), exitStep.hasButton && (_jsx(PopoverInputRow, { children: _jsx(UrlInput, { value: exitStep.redirectUrl || '', onChange: setRedirectUrl, id: "button-input", testId: "exit-edit-button-link", placeholder: "https://your-website.com/", label: "Redirect url", ref: ref }) }))] }));
});
const PopoverInputRow = styled(Box) `
  label,
  > div > div {
    font-size: 14px;
  }
  input {
    width: 100%;
    margin-top: 12px;
  }
`;
