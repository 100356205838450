import { __rest } from "tslib";
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { observer } from 'mobx-react';
import { useCallback, useMemo } from 'react';
import * as React from 'react';
import { useAction, useGetUserTest } from '@marvelapp/ballpark-application';
import { BtwButton, BtwModal, BtwText, BtwButton as Button, Combobox, Select, Stack, } from '@marvelapp/ballpark-components';
import { UI_STEPS_COPY } from '@marvelapp/ballpark-copy';
import { AlignBottomIcon, TrashCanIcon } from '@marvelapp/ballpark-icons';
import styled from '@marvelapp/styled';
import { Box } from '@marvelapp/ui';
import { ConditionalLogicUtils, StepUtils, getFixedStepType, getOrderedListOfAllSteps, getStepByUUID, getStepIndex, } from '@marvelapp/user-test-creator';
import { useConditionalLogicState } from '../../../ConditionalLogicContext';
export const DestinationStepSelect = observer(function DestinationStepSelect({ prefixText = 'Then jump to', placeholder = 'Select an option…', setDestinationStepUuid, savedDestinationStepUuid, }) {
    var _a;
    const userTest = useGetUserTest();
    const { activeStepUUID } = useConditionalLogicState();
    const step = getStepByUUID(userTest, activeStepUUID);
    const filteredSteps = useMemo(() => {
        const fullStepsList = getOrderedListOfAllSteps({ userTest });
        // only show the steps _after_ the current step in the destination select dropdown
        const currentStepIndex = fullStepsList.findIndex((s) => step && s.uuid === step.uuid);
        const stepsAfterCurrentStep = fullStepsList.slice(currentStepIndex + 1);
        return stepsAfterCurrentStep;
    }, [step, userTest]);
    if (!step)
        return null;
    const selectedStep = savedDestinationStepUuid
        ? getStepByUUID(userTest, savedDestinationStepUuid)
        : null;
    const options = filteredSteps.map((item) => {
        return {
            label: getStepText(userTest, item),
            value: item.uuid,
            testId: 'type' in item && item.type ? item.type : item.className,
            onSelect: () => {
                setDestinationStepUuid(item.uuid);
            },
        };
    });
    const selectedValue = (_a = selectedStep === null || selectedStep === void 0 ? void 0 : selectedStep.uuid) !== null && _a !== void 0 ? _a : null;
    const selectedItem = options.find((item) => item.value === selectedValue);
    const isConditionable = ConditionalLogicUtils.isConditionable(step, userTest);
    // is not disabled if its conditionable && there are options
    const isDisabled = !isConditionable || !options.length;
    return (_jsxs(Stack, { width: "full", direction: "row", gap: "2", className: "min-w-0 flex-1", children: [_jsx(PrefixText, { text: prefixText }), _jsx(Stack, { className: "min-w-0 flex-1", direction: "row", children: _jsxs(Combobox.Root, { variant: "single-select", children: [_jsx(Combobox.Trigger, { width: "full", truncate: true, hasPlaceholder: !selectedItem, disabled: isDisabled, "data-testid": "destination-dropdown-button", children: (selectedItem === null || selectedItem === void 0 ? void 0 : selectedItem.label) || placeholder }), _jsxs(Combobox.Content, { children: [_jsx(Combobox.Empty, {}), options.map((item) => {
                                    return (_jsx(Combobox.Item, { "data-testid": `combobox-list-item-${item.testId}`, onSelect: item.onSelect, selected: selectedValue === item.value, children: item.label }));
                                })] })] }) })] }));
});
export const DeleteRule = observer(function DeleteRule(_a) {
    var { deleteRule, testId = 'delete-rule' } = _a, props = __rest(_a, ["deleteRule", "testId"]);
    return (_jsx(Button, Object.assign({ variant: "ghost", standaloneIcon: _jsx(TrashCanIcon, { className: "text-gray-500" }), "data-testid": testId }, props, { onClick: () => {
            deleteRule();
        }, className: "-mr-2" })));
});
export const RequirementBox = ({ answerComponent, deleteRequirement, hasNestedRequirements = false, }) => {
    return (_jsxs(Box, { display: "flex", position: "relative", alignItems: "flex-end", gridGap: "xs", children: [_jsx(Box, { flex: "1", minWidth: "0", children: answerComponent }), deleteRequirement && hasNestedRequirements && (_jsx(Box, { children: _jsx(DeleteRule, { testId: "delete-condition", deleteRule: deleteRequirement }) }))] }));
};
function getStepText(userTest, item) {
    if (!item)
        return '';
    return `${getStepIndex(userTest, item.uuid) + 1}. ${getStepTitleOrName(item, getFixedStepType(userTest, item.uuid))}`;
}
function getStepTitleOrName(step, fixedStepType) {
    if (fixedStepType) {
        return step.title || UI_STEPS_COPY[fixedStepType].stepName.defaultMessage;
    }
    if (step.title) {
        return step.title;
    }
    if (StepUtils.isStepWithSubType(step) && step.type) {
        return UI_STEPS_COPY[step.type].stepName.defaultMessage;
    }
    return UI_STEPS_COPY[step.className].stepName.defaultMessage;
}
export const SelectWithPrefix = observer(function SelectWithPrefix({ prefixText, selectOptions, onChange, value, }) {
    const onOptionClick = useCallback((val) => onChange(val), [onChange]);
    const formattedOptions = selectOptions.map(([_, value]) => ({
        label: value,
        value,
    }));
    return (_jsxs(_Fragment, { children: [_jsx(PrefixText, { text: prefixText }), _jsx(Stack, { className: "min-w-0 flex-1", direction: "row", children: _jsxs(Select.Root, { value: value === null || value === void 0 ? void 0 : value.value, onValueChange: (v) => onOptionClick(v), children: [_jsx(Select.Trigger, { className: "min-w-0 flex-1", isTruncated: true, hasPlaceholder: !value && true, width: "full", children: (value === null || value === void 0 ? void 0 : value.label) || 'Select an option…' }), _jsx(Select.Content, { children: formattedOptions.map(({ label, value }) => (_jsx(Select.Item, { value: value, children: _jsx(_Fragment, { children: value }) }, label))) })] }) })] }));
});
export const InputWithList = styled(Box) `
  height: 32px;

  input {
    color: ${(props) => props.theme.colors.licorice};
    padding-left: ${(props) => props.pL}px;
  }
`;
export function PrefixText({ text }) {
    return (_jsx(Stack, { className: "h-[18px] h-auto min-w-[100px] items-center justify-center self-stretch rounded-md bg-gray-100 px-3 text-[10px] font-semibold uppercase leading-[18px] tracking-normal tracking-normal text-gray-600", children: text }));
}
export function RuleHeader({ ruleIndex, deleteRule, }) {
    return (_jsxs(Stack, { direction: "row", justify: "between", align: "center", className: "border-b border-gray-600/20 px-4 py-1.5", children: [_jsxs(BtwText, { weight: "medium", size: "sm", className: "text-gray-900", children: ["Rule #", ruleIndex + 1, ' '] }), deleteRule && _jsx(DeleteRule, { deleteRule: deleteRule })] }));
}
export function RuleBox(props) {
    const { children, variant = 'default' } = props, rest = __rest(props, ["children", "variant"]);
    return (_jsx(Stack, Object.assign({ direction: "col", align: "stretch", width: "full", className: `mb-4 rounded-lg border-gray-600/20  ${variant === 'default' && 'border bg-white'} ${variant === 'placeholder' && 'border-2 border-dashed'}` }, rest, { children: children })));
}
export const RuleBoxContentContainer = styled(Box).attrs({
    padding: 's',
    display: 'flex',
    flexDirection: 'column',
    gridGap: 's',
}) `
  position: relative;
  &::before {
    content: '';
    display: block;
    position: absolute;
    width: 2px;
    height: calc(100% - 32px); // Full height - padding Y.
    background-color: hsl(
      209,
      13.3%,
      95.3%
    ); // I've got no idea how to get the Radix variables in here. Forgive my sins.
    top: 16px;
    left: calc(49px + 16px); // Where it needs to be + padding.
    bottom: 16px;
  }
`;
export const ConditionalEmptyState = observer(function ConditionalEmptyState(props) {
    const { icon, title, children } = props;
    return (_jsx(RuleBox, { variant: "placeholder", children: _jsxs(Stack, { "data-testid": "empty-state", gap: "2", align: "center", justify: "center", 
            // 211px is the height of the default state of a multiConditionLogic rule box. Excluding the padding.
            className: "h-[211px] p-8", children: [icon, _jsx(BtwText, { asChild: true, size: "base", weight: "semibold", variant: "primary", children: _jsx("span", { children: title }) }), _jsx(BtwText, { size: "sm", leading: "normal", children: children })] }) }));
});
export const ClearAllRulesButton = observer(function ClearAllRulesButton() {
    const { activeStepUUID } = useConditionalLogicState();
    const userTest = useGetUserTest();
    const step = getStepByUUID(userTest, activeStepUUID);
    if (!step)
        return null;
    const isClearAllDisabled = !ConditionalLogicUtils.hasAnswerBasedRulesSet(step, userTest);
    return (_jsxs(BtwModal.Root, { children: [_jsx(BtwModal.Trigger, { asChild: true, children: _jsx(Button, { "data-testid": "clear-all-rules-cta", disabled: isClearAllDisabled, className: "flex-none", children: "Clear all rules" }) }), _jsx(DeleteAllRulesModalContent, { step: step, userTest: userTest })] }));
});
export const DeleteAllRulesModalContent = observer(function DeleteAllRulesModalContent({ step, userTest, }) {
    const { removeRule } = useConditionalLogicState();
    const clearAll = useAction(() => {
        ConditionalLogicUtils.clearAllConditions(step, userTest);
        removeRule();
    }, [removeRule, step, userTest]);
    return (_jsxs(BtwModal.Content, { overlay: "none", contentClassName: "max-w-md overflow-visible", "data-testid": "confirm-dialog", children: [_jsx(BtwModal.BigHeader, { description: "You will lose all data associated with the selected results. Are you sure you want to continue?", onlyChild: true, title: "Are you sure you want to clear all rules?" }), _jsxs(BtwModal.Footer, { children: [_jsx(BtwModal.Close, { asChild: true, children: _jsx(BtwButton, { variant: "secondary", children: "Cancel" }) }), _jsx(BtwModal.Close, { asChild: true, children: _jsx(BtwButton, { "data-testid": "confirm-cta", onClick: clearAll, variant: "danger", children: "Confirm" }) })] })] }));
});
export const AddRuleButton = observer(function AddRuleButton({ addRule, isDisabled = false, }) {
    const { addNewRule } = useConditionalLogicState();
    return (_jsx(Button, { testId: "add-new-rule-btn", variant: "primary", className: "flex-1 bg-[#0a4481] ring-[#0a4481] hover:bg-[#0954a5] hover:ring-[#0954a5]", onClick: addRule || addNewRule, disabled: isDisabled, children: "Add a new rule" }));
});
export const AlwaysSelect = observer(function AlwaysSelect({ prefixText = 'Always go to', savedAlwaysStepUuid, }) {
    const userTest = useGetUserTest();
    const { activeStepUUID } = useConditionalLogicState();
    const step = getStepByUUID(userTest, activeStepUUID);
    const addAlwaysRule = useAction((selectedDestStepUuid) => {
        if (step)
            ConditionalLogicUtils.setAlwaysConditionalRule(step, selectedDestStepUuid);
    }, [step]);
    const deleteRule = useAction(() => {
        if (step)
            ConditionalLogicUtils.deleteAlwaysConditionalRule(step);
    }, [step]);
    return (_jsxs(Stack, { "data-testid": "always-block", direction: "row", width: "full", className: "border-t border-gray-600/20 bg-white px-6 py-4", gap: "2", children: [_jsx(DestinationStepSelect, { prefixText: prefixText, placeholder: _jsxs(Stack, { direction: "row", gap: "1.5", align: "center", children: [_jsx(AlignBottomIcon, { className: "inline-block" }), " Next step"] }), savedDestinationStepUuid: savedAlwaysStepUuid, setDestinationStepUuid: addAlwaysRule }), savedAlwaysStepUuid && (_jsx(Button, { variant: "ghost", trailingIcon: _jsx(TrashCanIcon, { className: "text-gray-500" }), "data-testid": "delete-rule", onClick: () => {
                    deleteRule();
                }, className: "-mr-2" }))] }));
});
