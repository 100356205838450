import { __rest } from "tslib";
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { observer } from 'mobx-react';
import { useCallback, useState } from 'react';
import * as React from 'react';
import { getThumbnail, getThumbnail2x } from '@marvelapp/core';
import { Grid } from '@marvelapp/ui';
import { SelectablePreferenceCard } from '../../../components/SelectablePreferenceCard';
import PreferenceTestImageModal from '../../../slides/PreferenceTest/PreferenceTestImageModal';
import { Answer } from './Answer';
export const PreferenceAnswer = observer(function PreferenceAnswer(_a) {
    var { answers, step } = _a, restProps = __rest(_a, ["answers", "step"]);
    const [imageModalUuid, setImageModalUuid] = useState(null);
    const hasAnswer = !!(answers === null || answers === void 0 ? void 0 : answers.length);
    const onCloseModal = useCallback(() => {
        setImageModalUuid(null);
    }, []);
    function getImageUuidByOffset(offset) {
        if (!imageModalUuid || step.choices.length <= 1)
            return null;
        const currentIndex = step.choices.indexOf(imageModalUuid);
        if (currentIndex < 0)
            return null;
        // Offset -1 is for previous + 1 is for next and % ensures that the index rotates
        return step.choices[(currentIndex + offset + step.choices.length) % step.choices.length];
    }
    const onNextImage = () => setImageModalUuid(getImageUuidByOffset(+1));
    const onPrevImage = () => setImageModalUuid(getImageUuidByOffset(-1));
    return (_jsx(Answer, Object.assign({ step: step }, restProps, { children: hasAnswer && (_jsxs(Grid, { gridGap: "s", gridTemplateColumns: "1fr 1fr 1fr", children: [step.choices.map((choiceUUID, index) => {
                    if (!hasAnswer || !Array.isArray(answers[0].value))
                        return null;
                    const { choiceTitle, src } = step.choiceDefinitions[choiceUUID];
                    const isSelected = answers[0].value.includes(choiceUUID);
                    return (_jsx(SelectablePreferenceCard, { id: choiceUUID, isChecked: isSelected, onImageClick: () => {
                            setImageModalUuid(choiceUUID);
                        }, name: choiceTitle || `Choice ${index + 1}`, optionNumber: index, readOnly: true, thumbnail: getThumbnail(src, 200), thumbnail2x: getThumbnail2x(src, 200) }, choiceUUID));
                }), _jsx(PreferenceTestImageModal, { item: imageModalUuid
                        ? step.choiceDefinitions[imageModalUuid]
                        : undefined, optionIndex: imageModalUuid ? step.choices.indexOf(imageModalUuid) : 0, onClose: onCloseModal, onNextImage: onNextImage, onPrevImage: onPrevImage })] })) })));
});
