export function getRecordingDevices(intl, permissions) {
    const camera = intl.formatMessage({ id: "tL68dI", defaultMessage: "camera" });
    const microphone = intl.formatMessage({ id: "I/MFLL", defaultMessage: "microphone" });
    const devices = [];
    if (permissions.webcam)
        devices.push(camera);
    if (permissions.microphone)
        devices.push(microphone);
    return intl.formatList(devices, {
        type: 'conjunction',
    });
}
export function getStepCount(permissions) {
    return permissions.screen && (permissions.webcam || permissions.microphone)
        ? 2
        : 1;
}
