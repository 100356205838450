import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { AnimatePresence, motion } from 'framer-motion';
import { observer } from 'mobx-react';
import { useEffect, useRef, useState } from 'react';
import { BtwSkeleton, BtwText, BtwTooltip, Stack, Strong, } from '@marvelapp/ballpark-components';
import { TargetIcon } from '@marvelapp/ballpark-icons';
import { pluralize } from '@marvelapp/core';
import { StudyTypeEnum } from '../../../__generated__/queryTypes';
import { useRecruitmentState } from '../RecruitmentContext';
export const EligibilityCount = observer(function EligibilityCount() {
    var _a, _b;
    const { eligibilityDetails, hasEnoughEligibleParticipants, studyType, isUpdatingSelectedFilters, } = useRecruitmentState();
    const eligibleParticipantsCount = (_a = eligibilityDetails === null || eligibilityDetails === void 0 ? void 0 : eligibilityDetails.countParticipants) !== null && _a !== void 0 ? _a : null;
    const eligibilityCountError = (_b = eligibilityDetails === null || eligibilityDetails === void 0 ? void 0 : eligibilityDetails.error) !== null && _b !== void 0 ? _b : null;
    const eligibilityCounterRef = useRef(null);
    const [counterWidth, setCounterWidth] = useState(undefined);
    // Getting the width of the counter
    useEffect(() => {
        const container = eligibilityCounterRef.current;
        if (container) {
            const currentCounterWidth = container.getBoundingClientRect().width;
            if (currentCounterWidth > 0) {
                setCounterWidth(currentCounterWidth);
            }
        }
    }, [eligibleParticipantsCount]);
    // Framer motion animate variants
    const variants = {
        initial: { opacity: 0, translateY: '8px' },
        animate: { opacity: 1, translateY: 0 },
        exit: { opacity: 0, translateY: '-8px' },
    };
    return (_jsxs(Stack, { align: "center", className: "p-4", direction: "row", gap: "3", width: "full", children: [_jsx(Stack, { children: _jsx(TargetIcon, { className: "size-5" }) }), _jsx(BtwText, { align: "left", "data-testid": "eligible-participants-message", size: "xs", children: studyType === StudyTypeEnum.SURVEY ? (_jsxs(_Fragment, { children: ["It looks like", ' ', _jsx("span", { ref: eligibilityCounterRef, children: _jsx(Strong, { "data-testid": "eligible-participants-count", variant: hasEnoughEligibleParticipants ? 'inherit' : 'red', weight: "bold", children: eligibleParticipantsCount !== null &&
                                    !isUpdatingSelectedFilters &&
                                    eligibilityCountError === null ? (_jsx(AnimatePresence, { children: _jsx(motion.span, { variants: variants, initial: "initial", animate: "animate", exit: "exit", className: "inline-block", children: eligibleParticipantsCount.toLocaleString() }, eligibleParticipantsCount) })) : (_jsx(BtwTooltip, { className: "max-w-96", content: eligibilityCountError &&
                                        'We’re having a bit of trouble fetching the participant count. If this persists, please reload the page', "data-testid": "eligible-participants-error-tooltip", children: _jsx("div", { className: "inline-block", children: _jsx(BtwSkeleton, { className: "inline-block h-2 w-11", "data-testid": "loader", style: { width: counterWidth } }) }) })) }) }), ' ', "active ", pluralize(eligibleParticipantsCount !== null && eligibleParticipantsCount !== void 0 ? eligibleParticipantsCount : 0, 'participant'), ' ', "match your targeting requirements", hasEnoughEligibleParticipants || eligibilityCountError
                            ? ''
                            : '. There’s not enough participants to take this test, change your criteria.'] })) : ('Participants are sourced via our pool and on-demand recruiting to find the best possible match') })] }));
});
