import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { createElement as _createElement } from "react";
import { AnimatePresence, motion, useReducedMotion } from 'framer-motion';
import { forwardRef } from 'react';
import { position, space } from 'styled-system';
import theme from '@marvelapp/ballpark-theme';
import styled from '@marvelapp/styled';
import Box from './Box';
import CloseButton from './CloseButton';
import EscKeyHandler from './EscKeyHandler';
import ModalPortal from './ModalPortal';
/** @type any */
const Modal = forwardRef((props, ref) => {
    const { backgroundImage, backgroundSize, children, closeButtonTestID, hasTopBar = false, header, isOpen, trigger, zIndex = 'modal', 
    // See https://linear.app/ballpark/issue/BALL-164/ for reasoning behind this.
    // When dismissing the full screen modal, the animation on the sheet is
    // faster than the animation on the close button - meaning it just sticks
    // around for longer than the rest of the modal
    reduceMotion = true, isLive, } = props;
    const animation = {
        content: {
            animate: {
                y: 0,
            },
            exit: {
                y: '100%',
            },
            initial: {
                y: '100%',
            },
            transition: {
                ease: theme.easings.smooth.array,
                duration: 0.8,
            },
        },
        icon: {
            animate: {
                opacity: 1,
            },
            exit: {
                opacity: 0,
            },
            initial: {
                opacity: 0,
            },
            transition: {
                ease: theme.easings.smooth.array,
                duration: 0.6,
                delay: 0.15,
            },
        },
        header: {
            animate: {
                opacity: 1,
            },
            exit: {
                opacity: 0,
                transition: {
                    ease: theme.easings.smooth.array,
                    duration: 0.3,
                    delay: 0,
                },
            },
            initial: {
                opacity: 0,
            },
            transition: {
                ease: theme.easings.smooth.array,
                duration: 0.3,
                delay: 0.5,
            },
        },
    };
    const hasReduceMotion = useReducedMotion() || reduceMotion;
    return (_jsx(ModalPortal, { children: _jsx(AnimatePresence, { children: isOpen && (_jsxs(_Fragment, { children: [_jsxs(Box, { position: "fixed", 
                        // width="100%"
                        top: "0", left: "0", zIndex: theme.zIndices.modal + 1, className: `${isLive &&
                            '!absolute !left-2 !top-2 !w-[calc(100vw-(60px+24px))]'}`, children: [trigger && (_createElement(CloseIcon, Object.assign({}, (!hasReduceMotion && animation.icon), { "data-testid": "close-modal", hasTopBar: hasTopBar, key: "icon", zIndex: zIndex, isLive: isLive }),
                                _jsx(CloseButton, { "data-testid": closeButtonTestID, onClick: trigger }))), _createElement(HeaderContainer, Object.assign({}, (!hasReduceMotion && animation.header), { key: "header" }), header && header)] }), _createElement(ContentContainer, Object.assign({}, (!hasReduceMotion && animation.content), { backgroundSize: backgroundSize, backgroundImage: backgroundImage, hasTopBar: hasTopBar, key: "content", ref: ref, zIndex: zIndex, "data-testid": "modal-inner-scrollable", isLive: isLive }),
                        trigger && _jsx(EscKeyHandler, { onEscapeKey: trigger }),
                        _jsx(Content, { children: children }))] })) }) }));
});
export const ContentContainer = styled(motion.div) `
  ${position};
  background-color: ${(props) => props.theme.colors.white};
  background-image: ${(props) => props.backgroundImage};
  background-size: ${(props) => props.backgroundSize};
  box-shadow: 0 0 12px 4px rgba(0, 0, 0, 0.1);
  position: fixed !important;

  top: 0px;
  padding-top: ${(props) => props.hasTopBar && '50px'};
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow-y: scroll;
  ${(props) => props.isLive
    ? `
      width: calc(100vw - (60px + 24px));
      height: calc(100vh - 16px);
      margin: 8px;
      border-radius: 6px;
    `
    : `
      width: 100%;
      height: 100%;
    `}
`;
export const HeaderContainer = styled(motion.div) ``;
export const CloseIcon = styled(motion.div) `
  ${space};
  ${position};
  position: ${(props) => (props.isLive ? 'absolute' : 'fixed')};
  padding: ${(props) => (props.hasTopBar ? '6px 26px' : '16px')};
  top: 0;
  right: 0;
`;
export const Content = styled.div `
  margin: auto;
`;
export default Modal;
