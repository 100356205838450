import { gql, useQuery } from '@apollo/client';
import { StepCardSortNodeFragment, StepFiveSecondTestFragment, StepInstructionFragment, StepMultipleChoiceFragment, StepPreferenceTestFragment, StepPrototypeTaskFragmentWithEvents, StepQuestionFragment, StepRatingScaleFragment, StepTaglineCopyTestFragment, StepWebsiteTaskFragment, StepYesOrNoFragment, TestSessionRecordingFragment, } from '../../graphql/fragments';
const GET_USER_TEST_RESULT = gql `
  query getUserTestResultInfo($pk: Int, $uuid: String!, $revision: Int!) {
    userTestResult(pk: $pk) {
      pk
      responseUUID
      bounceStep
      status
      submittedAt
      participant {
        email
        name
      }
      response {
        document
      }
      recordings {
        user {
          ...TestSessionRecordingFragment
        }
        screen {
          ...TestSessionRecordingFragment
        }
        download {
          ...TestSessionRecordingFragment
        }
      }
      device {
        make
        model
        operatingSystem
        screenWidth
        screenHeight
      }
      stepResponses {
        ...StepFiveSecondTestFragment
        ...StepInstructionFragment
        ...StepMultipleChoiceFragment
        ...StepPreferenceTestFragment
        ...StepPrototypeTaskFragmentWithEvents
        ...StepQuestionFragment
        ...StepRatingScaleFragment
        ...StepTaglineCopyTestFragment
        ...StepWebsiteTaskFragment
        ...StepYesOrNoFragment
        ...StepCardSortNodeFragment
      }
    }
    userTestDocument(uuid: $uuid, revision: $revision) {
      uuid
      revision
      document
    }
  }
  ${StepFiveSecondTestFragment}
  ${StepInstructionFragment}
  ${StepMultipleChoiceFragment}
  ${StepPreferenceTestFragment}
  ${StepPrototypeTaskFragmentWithEvents}
  ${StepQuestionFragment}
  ${StepRatingScaleFragment}
  ${StepTaglineCopyTestFragment}
  ${StepWebsiteTaskFragment}
  ${StepYesOrNoFragment}
  ${TestSessionRecordingFragment}
  ${StepCardSortNodeFragment}
`;
export function useQueryUserTestResult({ skip, variables, }) {
    const { data, loading, startPolling, stopPolling, networkStatus } = useQuery(GET_USER_TEST_RESULT, {
        variables,
        skip,
    });
    return {
        isResultLoading: loading,
        resultData: data,
        startPolling,
        stopPolling,
        networkStatus,
    };
}
export function updateResultStatus(cache, status, variables) {
    const cachedData = cache.readQuery({
        query: GET_USER_TEST_RESULT,
        variables,
    });
    if (!cachedData)
        return;
    const newData = Object.assign(Object.assign({}, cachedData), { userTestResult: Object.assign(Object.assign({}, cachedData.userTestResult), { status }) });
    cache.writeQuery({
        query: GET_USER_TEST_RESULT,
        variables,
        data: newData,
    });
}
