import { __rest } from "tslib";
import { jsx as _jsx } from "react/jsx-runtime";
import { observer } from 'mobx-react';
import * as React from 'react';
import useDarkMode from 'use-dark-mode';
import { Image as MarvelImage } from '@marvelapp/ui';
export default observer(function Image(_a) {
    var { alt = '', disabled, finishedLoading, hasTransition = true, preloadImage = true, src, src2x, src2xDark, srcDark, testId } = _a, rest = __rest(_a, ["alt", "disabled", "finishedLoading", "hasTransition", "preloadImage", "src", "src2x", "src2xDark", "srcDark", "testId"]);
    const darkmode = useDarkMode(true);
    return (_jsx(MarvelImage, Object.assign({ alt: alt, disabled: disabled, finishedLoading: finishedLoading, hasTransition: hasTransition, preloadImage: preloadImage, src: darkmode.value ? srcDark : src, src2x: darkmode.value ? src2xDark : src2x, testId: testId }, rest)));
});
