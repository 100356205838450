import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { AnimatePresence, motion } from 'framer-motion';
import { useRef, useState } from 'react';
import * as React from 'react';
import { tv } from 'tailwind-variants';
import { ArchiveIcon, ArrowLeftIcon, BagIcon, BillIcon, CloseIcon, CreditCardIcon, DashboardFastIcon, DotsIcon, FolderIcon, FolderUserIcon, HamburgerIcon, LayoutGridIcon, LogoutIcon, MapIcon, MegaphoneIcon, PeopleBehindIcon, PeopleCircleIcon, RescueRingIcon, SettingsIcon, StoreIcon, } from '@marvelapp/ballpark-icons';
import theme from '@marvelapp/ballpark-theme';
import { CATEGORIES, CATEGORYGROUPS, TEMPLATES, } from '@marvelapp/templates/src/constants';
import { getCategoryCount } from '@marvelapp/templates/src/utils';
import { Badge } from './Badge';
import { BtwAvatar } from './BtwAvatar';
import { BtwButton, baseButtonStyles, ghostButtonStyles } from './BtwButton';
import { BtwDropdown } from './BtwDropdown';
import { BtwHeading } from './BtwHeading';
import { BtwText } from './BtwText';
import { BtwTooltip } from './BtwTooltip';
import { SidebarBanner } from './SidebarBanner';
import { Stack } from './Stack';
import { cn } from './utils';
const sidebarClasses = tv({
    slots: {
        sidebarBackground: [
            // Small screen stylign
            'absolute',
            'data-[collapsed=false]:w-full',
            'data-[collapsed=false]:h-full',
            'block',
            'z-50',
            // Big screen styling
            'md:hidden',
        ],
        sidebarPanel: [
            // Base styling
            'h-full',
            'shrink-0',
            'overflow-hidden',
            'bg-gray-50',
            'ring-1',
            'ring-gray-600/10',
            'transition-all',
            'ease-smooth',
            'duration-300',
            // Small screen styling
            'absolute',
            'w-full',
            'max-w-80',
            '-left-80',
            'data-[collapsed=false]:left-0',
            'z-50',
            // Big screen styling
            'md:relative',
            'md:w-64',
            'md:max-w-64',
            'md:left-0',
        ],
    },
});
const { sidebarPanel, sidebarBackground } = sidebarClasses();
var SidebarPanel;
(function (SidebarPanel) {
    SidebarPanel["Main"] = "main";
    SidebarPanel["Settings"] = "settings";
    SidebarPanel["Templates"] = "Templates";
})(SidebarPanel || (SidebarPanel = {}));
var SidebarNavItem;
(function (SidebarNavItem) {
    // Main sidebar menu
    SidebarNavItem["Main_AllProjects"] = "All projects";
    SidebarNavItem["Main_MyProjects"] = "My projects";
    SidebarNavItem["Main_Archive"] = "Archive";
    SidebarNavItem["Main_Sessions"] = "Sessions";
    SidebarNavItem["Main_People"] = "People";
    SidebarNavItem["Main_Templates"] = "Templates";
    SidebarNavItem["Main_Help"] = "Help";
    SidebarNavItem["Main_Roadmap"] = "Roadmap";
    // Settings menu
    SidebarNavItem["Settings_Personal"] = "Personal";
    SidebarNavItem["Settings_Workspace"] = "Workspace";
    SidebarNavItem["Settings_PlanUsage"] = "Plan & Usage";
    SidebarNavItem["Settings_Billing"] = "Billing";
    SidebarNavItem["Settings_RecruitmentOrders"] = "Recruitment orders";
    SidebarNavItem["Settings_ConnectedApps"] = "Connected apps";
    SidebarNavItem["Templates_All"] = "All";
})(SidebarNavItem || (SidebarNavItem = {}));
function AppSidebar(props) {
    const { initialMenuItem, initialActivePanel } = props;
    const [activePanel, setActivePanel] = useState(initialActivePanel || SidebarPanel.Main);
    const [activeMenuItem, setActiveMenuItem] = useState(initialMenuItem || SidebarNavItem.Main_AllProjects);
    // Setting the sidebar as collapsed by default, so that when the viewport
    // gets small, the sidebar is closed by default. This prop only controls the sidebar
    // in smaller viewports.
    const [isSidebarCollapsed, setSidebarCollapsed] = useState(true);
    const sidebarRef = useRef(null);
    const sidebarBackgroundRef = useRef(null);
    // Closing the sidebar and making sure we reset the active panel
    // in case the user closed it while the settings was open.
    // This logic should be further improved to only apply IF the current
    // page is not a settings page.
    const toggleSidebar = () => {
        setSidebarCollapsed(!isSidebarCollapsed);
        if (activePanel !== initialActivePanel) {
            setActivePanel(initialActivePanel);
        }
    };
    return (_jsxs(_Fragment, { children: [_jsx(BtwTooltip, { content: "Open menu", children: _jsx(BtwButton, { size: "sm", standaloneIcon: _jsx(HamburgerIcon, {}), className: "absolute left-8 top-3 z-20 flex md:hidden", onClick: toggleSidebar }) }), _jsx("div", { className: cn(sidebarBackground()), "data-collapsed": isSidebarCollapsed, ref: sidebarBackgroundRef, onClick: toggleSidebar, role: "none" }), _jsx(Stack, { direction: "row", className: cn(sidebarPanel()), ref: sidebarRef, "data-collapsed": isSidebarCollapsed, children: _jsxs(AnimatePresence, { initial: false, children: [activePanel === SidebarPanel.Main && (_jsx(motion.div, { className: "absolute h-full w-full", initial: { x: '-100%' }, animate: { x: 0 }, exit: {
                                x: '-100%',
                            }, transition: { ease: theme.easings.smooth.array, duration: 0.2 }, children: _jsx(MainSidebarPanel, { activeMenuItem: activeMenuItem, setActiveMenuItem: setActiveMenuItem, setActivePanel: setActivePanel, isSidebarCollapsed: isSidebarCollapsed, setSidebarCollapsed: setSidebarCollapsed }) }, "sidebar-main")), activePanel === SidebarPanel.Settings && (_jsx(motion.div, { className: "absolute h-full w-full", initial: { x: '100%' }, animate: { x: 0 }, exit: {
                                x: '100%',
                            }, transition: { ease: theme.easings.smooth.array, duration: 0.2 }, children: _jsx(SettingsSidebarPanel, { activeMenuItem: activeMenuItem, setActiveMenuItem: setActiveMenuItem, setActivePanel: setActivePanel, isSidebarCollapsed: isSidebarCollapsed, setSidebarCollapsed: setSidebarCollapsed }) }, "sidebar-settings")), activePanel === SidebarPanel.Templates && (_jsx(motion.div, { className: "absolute h-full w-full", initial: { x: '100%' }, animate: { x: 0 }, exit: {
                                x: '100%',
                            }, transition: { ease: theme.easings.smooth.array, duration: 0.2 }, children: _jsx(TemplatesSidebarPanel, { activeMenuItem: activeMenuItem, setActiveMenuItem: setActiveMenuItem, setActivePanel: setActivePanel, isSidebarCollapsed: isSidebarCollapsed, setSidebarCollapsed: setSidebarCollapsed }) }, "sidebar-settings"))] }) })] }));
}
function MainSidebarPanel(props) {
    const { activeMenuItem, setActiveMenuItem, setActivePanel, setSidebarCollapsed, isSidebarCollapsed, } = props;
    return (_jsxs(Stack, { className: "h-full min-w-64", children: [_jsx(UserSection, { setActivePanel: setActivePanel, setSidebarCollapsed: setSidebarCollapsed, isSidebarCollapsed: isSidebarCollapsed }), _jsx(Stack, { width: "full", children: _jsx(Stack, { className: "px-3 pt-3", width: "full", children: _jsx(BtwButton, { variant: "secondary", width: "full", children: "Create new project" }) }) }), _jsx(Stack, { width: "full", className: "h-full divide-y divide-gray-600/10", children: _jsx(SidebarMenu, { activeMenuItem: activeMenuItem, setActiveMenuItem: setActiveMenuItem, setActivePanel: setActivePanel }) })] }));
}
function SettingsSidebarPanel(props) {
    const { activeMenuItem, setActiveMenuItem, setActivePanel, isSidebarCollapsed, setSidebarCollapsed, } = props;
    return (_jsx(SecondarySidebarPanel, { title: "Settings", setActivePanel: setActivePanel, isSidebarCollapsed: isSidebarCollapsed, setSidebarCollapsed: setSidebarCollapsed, children: settingsMenu.map((item) => {
            const { id, title, icon, url } = item;
            return (_jsx(MenuItem, { isActive: title === activeMenuItem, title: title, icon: icon, url: url, onClick: () => setActiveMenuItem(title) }, id));
        }) }));
}
function TemplatesSidebarPanel(props) {
    const { activeMenuItem, setActiveMenuItem, setActivePanel, isSidebarCollapsed, setSidebarCollapsed, } = props;
    // Dunno if this is the right way of doing this, but it feels ok
    const templateCategoryGroups = CATEGORYGROUPS;
    const templateCategories = CATEGORIES;
    const templates = TEMPLATES;
    return (_jsx(SecondarySidebarPanel, { title: "Templates", setActivePanel: setActivePanel, isSidebarCollapsed: isSidebarCollapsed, setSidebarCollapsed: setSidebarCollapsed, children: _jsx(Stack, { className: "divide-y-gray-600/10 divide-y", width: "full", gap: "3", children: templateCategoryGroups.map((group) => {
                const categories = templateCategories.filter((sidebarCategory) => sidebarCategory.group === group.slug);
                const templatesCount = templates.length;
                return (_jsxs(Stack, { gap: "3", width: "full", className: "[&:not(:first-child)]:pt-3", children: [_jsx(BtwHeading, { variant: "secondary", size: "xxs", className: "px-3 pt-2 uppercase", children: group.name }), _jsxs(Stack, { gap: "0.5", width: "full", children: [group.slug === 'featured' && (_jsx(MenuItem, { isActive: SidebarNavItem.Templates_All === activeMenuItem, title: SidebarNavItem.Templates_All, url: "/", onClick: () => setActiveMenuItem(SidebarNavItem.Templates_All), badgeCount: templatesCount }, "All")), categories.map((category) => {
                                    if (getCategoryCount(category) > 0) {
                                        return (_jsx(MenuItem, { isActive: category.name === activeMenuItem, title: category.name, url: `#`, 
                                            // Not really sure yet how to get this working, but I need to get deploy previews up
                                            // so I'm just leaving this for now.
                                            // Getting it to accept the categoryNameKey is just impossible.
                                            onClick: () => setActiveMenuItem(SidebarNavItem.Main_AllProjects), badgeCount: getCategoryCount(category) }, category.slug));
                                    }
                                    return null;
                                })] })] }, group.slug));
            }) }) }));
}
function SecondarySidebarPanel(props) {
    const { setActivePanel, isSidebarCollapsed, setSidebarCollapsed, title, children, } = props;
    return (_jsxs(Stack, { className: "h-full min-w-64", children: [_jsxs(Stack, { direction: "row", align: "center", width: "full", gap: "2", className: "h-[52px] border-b border-gray-600/10 px-5", justify: "between", children: [_jsxs(Stack, { direction: "row", align: "center", gap: "2", children: [_jsx(BtwTooltip, { content: "Back to main menu", children: _jsx(BtwButton, { variant: "ghost", size: "sm", standaloneIcon: _jsx(ArrowLeftIcon, {}), onClick: () => {
                                        setActivePanel(SidebarPanel.Main);
                                    } }) }), _jsx(BtwText, { size: "sm", variant: "primary", weight: "medium", children: title })] }), _jsx(Stack, { className: "block md:hidden", children: _jsx(BtwTooltip, { content: "Close menu", children: _jsx(BtwButton, { standaloneIcon: _jsx(CloseIcon, {}), variant: "ghost", size: "sm", onClick: () => setSidebarCollapsed(!isSidebarCollapsed), className: "flex md:hidden" }) }) })] }), _jsx(Stack, { width: "full", className: "p-3", gap: "0.5", children: children })] }));
}
function UserSection(props) {
    const { setActivePanel, setSidebarCollapsed, isSidebarCollapsed } = props;
    return (_jsxs(Stack, { direction: "row", gap: "3", align: "center", width: "full", className: "h-[52px] shrink-0 border-b border-gray-600/10 px-3", children: [_jsxs(Stack, { className: "relative", children: [_jsx(BtwAvatar, { name: user.name, size: "lg", src: user.avatarUrl }), _jsx("img", { src: user.companyImageUrl, className: "absolute -bottom-px -right-1 size-4 rounded-sm ring-2 ring-gray-50", alt: user.companyName })] }), _jsxs(Stack, { width: "full", children: [_jsx(BtwText, { variant: "primary", size: "13", weight: "medium", className: "leading-5", children: user.name }), _jsx(BtwText, { size: "xs", weight: "medium", variant: "secondary", className: "-mt-0.5", children: user.companyName })] }), _jsx(Stack, { children: _jsxs(BtwDropdown.Root, { children: [_jsxs(Stack, { direction: "row", gap: "1", children: [_jsxs(BtwTooltip.Root, { children: [_jsx(BtwTooltip.Trigger, { children: _jsx(BtwDropdown.Trigger, { "data-testid": "sidebar-more-actions", size: "sm", standaloneIcon: _jsx(DotsIcon, {}), variant: "ghost" }) }), _jsx(BtwTooltip.Portal, { children: _jsx(BtwTooltip.Content, { children: "More actions" }) })] }), _jsx(BtwTooltip, { content: "Close menu", children: _jsx(BtwButton, { standaloneIcon: _jsx(CloseIcon, {}), variant: "ghost", size: "sm", onClick: () => setSidebarCollapsed(!isSidebarCollapsed), className: "flex md:hidden" }) })] }), _jsxs(BtwDropdown.Content, { className: "w-48", children: [_jsxs(BtwDropdown.Stack, { children: [_jsx(BtwDropdown.Item, { leadingIcon: _jsx(SettingsIcon, {}), onSelect: () => {
                                                setActivePanel(SidebarPanel.Settings);
                                            }, children: "Settings" }), _jsx(BtwDropdown.Item, { leadingIcon: _jsx(MegaphoneIcon, {}), children: "Release notes" })] }), _jsx(BtwDropdown.Separator, {}), _jsx(BtwDropdown.Stack, { children: _jsx(BtwDropdown.Item, { leadingIcon: _jsx(LogoutIcon, {}), children: "Log out" }) })] })] }) })] }));
}
function SidebarMenu(props) {
    const { activeMenuItem, setActiveMenuItem, setActivePanel } = props;
    return (_jsxs(Stack, { width: "full", justify: "between", className: "h-full", children: [_jsxs(Stack, { width: "full", className: "divide-y divide-gray-600/10", children: [_jsx(Stack, { width: "full", className: "p-3", gap: "0.5", children: sidebarProjectsMenu.map((item) => {
                            const { id, title, icon, url } = item;
                            return (_jsx(MenuItem, { isActive: title === activeMenuItem, title: title, icon: icon, url: url, onClick: () => setActiveMenuItem(title) }, id));
                        }) }), _jsx(Stack, { width: "full", className: " p-3", gap: "0.5", children: otherItemsMenu.map((item) => {
                            const { id, title, icon, url } = item;
                            return (_jsx(MenuItem, { isActive: title === activeMenuItem, title: title, icon: icon, url: url, onClick: () => {
                                    // I am sure there are is a right way of doing this, but for the sake of an example:
                                    if (title === SidebarNavItem.Main_Templates) {
                                        setActivePanel(SidebarPanel.Templates);
                                    }
                                    setActiveMenuItem(title);
                                } }, id));
                        }) })] }), _jsxs(Stack, { width: "full", className: "divide-y divide-gray-600/10", children: [_jsx(Stack, { className: "p-3", children: _jsx(SidebarBanner, { title: "9 days left in your trial", description: "Upgrade early or book a personalised demo for your team.", progressValue: 9, progressMax: 14, actions: _jsx(BtwButton, { size: "sm", variant: "primary", children: "Upgrade now" }) }) }), _jsx(Stack, { width: "full", className: " p-3", gap: "0.5", children: helpMenu.map((item) => {
                            const { id, title, icon, url } = item;
                            return (_jsx(MenuItem, { isActive: title === activeMenuItem, title: title, icon: icon, url: url, onClick: () => setActiveMenuItem(title) }, id));
                        }) })] })] }));
}
// I opted to use custom components here instead of buttons, because I had to override
// too many things to make the buttons fit the sidebar nav.
function MenuItem(props) {
    const { icon, title, key, url, isActive, onClick, badgeCount } = props;
    const menuItemClasses = [
        // Base styles
        'h-9',
        'w-full',
        'gap-2',
        'justify-start',
        'px-3',
        'items-center',
        'rounded-md',
        'text-gray-700',
        // Similar to Yav's findingings (BtwButton.tsx:223), the text-13 class breaks styles
        // hence the arbitrary value
        'text-[13px]',
        'font-medium',
        // Hover styles
        'hover:bg-gray-200/50',
        'hover:ring-gray-200/50',
        // Active styles
        'data-[state=active]:text-gray-900',
        'data-[state=active]:bg-gray-200/50',
        'data-[state=active]:ring-gray-200/50',
    ];
    return (_jsxs("a", { href: url, className: cn(...baseButtonStyles, ...ghostButtonStyles, menuItemClasses), onClick: onClick, "data-state": isActive ? 'active' : '', children: [_jsxs("span", { className: "inline-flex w-full gap-2", children: [icon && _jsx("span", { className: "[&>svg]:size-5", children: icon }), _jsx("span", { children: title })] }), badgeCount && (_jsx(Badge, { size: "sm", className: "justify-self-end", children: badgeCount }))] }, key));
}
export { AppSidebar, SidebarNavItem, SidebarPanel };
const sidebarProjectsMenu = [
    {
        id: 0,
        title: SidebarNavItem.Main_AllProjects,
        icon: _jsx(FolderIcon, {}),
        url: '#',
    },
    {
        id: 1,
        title: SidebarNavItem.Main_MyProjects,
        icon: _jsx(FolderUserIcon, {}),
        url: '#',
    },
    {
        id: 2,
        title: SidebarNavItem.Main_Archive,
        icon: _jsx(ArchiveIcon, {}),
        url: '#',
    },
    {
        id: 3,
        title: SidebarNavItem.Main_Sessions,
        icon: '',
        url: '#',
    },
];
const otherItemsMenu = [
    {
        id: 0,
        title: SidebarNavItem.Main_People,
        icon: _jsx(PeopleBehindIcon, {}),
        url: '#',
    },
    {
        id: 1,
        title: SidebarNavItem.Main_Templates,
        icon: _jsx(StoreIcon, {}),
        url: '#',
    },
];
const helpMenu = [
    {
        id: 0,
        title: SidebarNavItem.Main_Help,
        icon: _jsx(RescueRingIcon, {}),
        url: '#',
    },
    {
        id: 1,
        title: SidebarNavItem.Main_Roadmap,
        icon: _jsx(MapIcon, {}),
        url: '#',
    },
];
const settingsMenu = [
    {
        id: 0,
        title: SidebarNavItem.Settings_Personal,
        icon: _jsx(PeopleCircleIcon, {}),
        url: '#',
    },
    {
        id: 1,
        title: SidebarNavItem.Settings_Workspace,
        icon: _jsx(BagIcon, {}),
        url: '#',
    },
    {
        id: 2,
        title: SidebarNavItem.Settings_PlanUsage,
        icon: _jsx(DashboardFastIcon, {}),
        url: '#',
    },
    {
        id: 3,
        title: SidebarNavItem.Settings_Billing,
        icon: _jsx(CreditCardIcon, {}),
        url: '#',
    },
    {
        id: 4,
        title: SidebarNavItem.Settings_RecruitmentOrders,
        icon: _jsx(BillIcon, {}),
        url: '#',
    },
    {
        id: 5,
        title: SidebarNavItem.Settings_ConnectedApps,
        icon: _jsx(LayoutGridIcon, {}),
        url: '#',
    },
];
const user = {
    name: 'Alfred Bottleneck',
    avatarUrl: 'https://images.unsplash.com/photo-1507003211169-0a1dd7228f2d?&w=128&h=128&dpr=2&q=80',
    companyName: 'Acme Inc',
    companyImageUrl: 'https://images.unsplash.com/photo-1574169208507-84376144848b?&w=128&h=128&dpr=2&q=80',
};
