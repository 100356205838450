import { jsx as _jsx } from "react/jsx-runtime";
import { cn } from '@marvelapp/ballpark-components';
import { CheckCircleIcon, LoaderIcon, PhoneIcon, RemoveUserIcon, StudioDisplayIcon, TabletIcon, } from '@marvelapp/ballpark-icons';
import { AnswerType, FlatStepClass, ScreenerUtils, } from '@marvelapp/user-test-creator';
import { getMiddleScreenerStepUUIDs } from '@marvelapp/user-test-creator/src/utils/screenerUtils';
import { DeviceMakeEnum } from '../../__generated__/queryTypes';
export function getFlatStep(step, _flatStepClass) {
    return step;
}
export function getResponseValue(stepResponse, flatStepClass) {
    var _a, _b;
    switch (flatStepClass) {
        case FlatStepClass.Instruction:
        case FlatStepClass.FirstClickStep:
        case FlatStepClass.FreeRoamStep:
        case FlatStepClass.GoalBasedStep:
        case FlatStepClass.WebsiteTask:
        case FlatStepClass.MediaSettings:
            return null;
        case FlatStepClass.PreferenceTest:
        case FlatStepClass.TaglineCopyTest: {
            return stepResponse.answers.flatMap((answer) => answer.value);
        }
        case FlatStepClass.QuestionDate:
        case FlatStepClass.QuestionEmail:
        case FlatStepClass.QuestionNumerical:
        case FlatStepClass.QuestionText:
        case FlatStepClass.RatingScale:
        case FlatStepClass.LegalStep:
        case FlatStepClass.YesOrNo:
        case FlatStepClass.FiveSecondTest:
        case FlatStepClass.CardSortingHybrid:
        case FlatStepClass.CardSortingOpen:
        case FlatStepClass.CardSortingClosed:
            return ((_b = (_a = stepResponse.answers[0]) === null || _a === void 0 ? void 0 : _a.value) !== null && _b !== void 0 ? _b : null);
        case FlatStepClass.MultipleChoice: {
            return stepResponse.answers.reduce((acc, answer) => {
                if (answer.type === AnswerType.MultipleChoice) {
                    acc.uuids.push(...answer.value);
                    if (answer.otherValue) {
                        // eslint-disable-next-line no-param-reassign
                        acc.otherValue = answer.otherValue;
                    }
                }
                return acc;
            }, {
                uuids: [],
                otherValue: undefined,
            });
        }
        default:
            // Ensure the switch is exhaustive by marking the step class as never
            throw new Error(`Unknown step class: ${flatStepClass}`);
    }
}
export function getNonNullResponseValue(stepResponse, stepClass) {
    const value = getResponseValue(stepResponse, stepClass);
    if (value === null) {
        throw new Error(`Null value for stepResponse ${stepResponse}`);
    }
    return value;
}
export function getDeviceMakeIcon(value, className) {
    switch (value) {
        case DeviceMakeEnum.WEB:
            return (_jsx(StudioDisplayIcon, { className: cn('size-4 text-gray-500', className) }));
        case DeviceMakeEnum.MOBILE:
            return _jsx(PhoneIcon, { className: cn('size-4 text-gray-500', className) });
        case DeviceMakeEnum.TABLET:
            return _jsx(TabletIcon, { className: cn('size-4 text-gray-500', className) });
        default:
            console.error('Unknown device status:', value);
    }
}
export function getResultStatusIcon(value, className) {
    switch (value) {
        case 'COMPLETED':
            return _jsx(CheckCircleIcon, { className: cn('text-green-500', className) });
        case 'IN_PROGRESS':
            return _jsx(LoaderIcon, { className: cn('text-gray-500', className) });
        case 'INCOMPLETE':
            return _jsx(LoaderIcon, { className: cn('text-gray-500', className) });
        case 'SCREENING_ABANDONED':
            return _jsx(RemoveUserIcon, { className: cn('text-red-500', className) });
        case 'FAILED':
        case 'QUALIFIED_VIEWED':
        case 'SCREENING_DECLINED':
        case 'SCREENING_STARTED':
            return null;
        default:
            console.error('Unknown result status:', value);
    }
}
export function getResultStatusDisplayName(value) {
    switch (value) {
        case 'COMPLETED':
            return 'Completed';
        case 'IN_PROGRESS':
            return 'In progress';
        case 'INCOMPLETE':
            return 'Incomplete';
        case 'SCREENING_ABANDONED':
            return 'Screening abandoned';
        case 'FAILED':
            return 'Failed';
        case 'QUALIFIED_VIEWED':
            return 'Qualified viewed';
        case 'SCREENING_DECLINED':
            return 'Screening declined';
        case 'SCREENING_STARTED':
            return 'Screening started';
        default:
            console.error('Unknown result status:', value);
            return '';
    }
}
export function getResponseTableStepUUIDs(userTest) {
    return [...getMiddleScreenerStepUUIDs(userTest), ...userTest.steps];
}
export function getDefaultColumnVisibility(userTest) {
    // Screener steps are hidden by default
    const initialState = ScreenerUtils.getMiddleScreenerStepUUIDs(userTest).reduce((acc, uuid) => {
        // eslint-disable-next-line no-param-reassign
        acc[uuid] = false;
        return acc;
    }, {});
    initialState.recruitment_participantId = false;
    initialState.recruitment_studyId = false;
    return initialState;
}
