import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { isNil } from 'lodash-es';
import { observer } from 'mobx-react';
import { useCallback, useEffect, useRef, useState } from 'react';
import * as React from 'react';
import { useAction } from '@marvelapp/ballpark-application';
import { CursorClickIcon } from '@marvelapp/ballpark-icons';
import { Box } from '@marvelapp/ui';
import { useConditionalLogicState } from '../../../ConditionalLogicContext';
import BaseLogic from '../BaseLogic';
import { ConditionalEmptyState, DestinationStepSelect, RequirementBox, RuleBox, RuleBoxContentContainer, RuleHeader, SelectWithPrefix, } from './sharedComponents';
const BooleanConditionalLogic = observer(function BooleanConditionalLogic({ operatorOptions, prefixText, deleteRule, updateRule, setRule, savedRules, }) {
    const { isAddingNewRule, removeRule } = useConditionalLogicState();
    useEffect(() => {
        // reset global isAddingNewRule value on unmount
        return () => {
            if (isAddingNewRule)
                removeRule();
        };
    }, [isAddingNewRule, removeRule]);
    return (_jsxs(BaseLogic, { alwaysPrefixText: savedRules.length ? 'Otherwise go to' : 'Always go to', canAddRule: true, isAddRuleButtonDisabled: isAddingNewRule, children: [savedRules.map(([savedAnswer, destinationStepUUID], index) => {
                return (_jsx(BooleanSavedRuleSet, { savedAnswer: savedAnswer === 'true', destinationStepUUID: destinationStepUUID, operatorOptions: operatorOptions, prefixText: prefixText, deleteRule: deleteRule, updateRule: updateRule, ruleIndex: index }, `${destinationStepUUID + index + savedAnswer}`));
            }), !savedRules.length && !isAddingNewRule && (_jsxs(ConditionalEmptyState, { icon: _jsx(CursorClickIcon, { className: "text-gray-500" }), title: "No rules set up yet\u2026", children: ["Add rules with conditional logic or use the", ' ', _jsx("strong", { children: "\u201CAlways go to\u201D" }), " rule to route all users to a specific step."] })), isAddingNewRule && (_jsx(BooleanDraftRuleInputSet, { operatorOptions: operatorOptions, prefixText: prefixText, setRule: setRule, ruleIndex: savedRules.length }))] }));
});
function BooleanDraftRuleInputSet({ operatorOptions, prefixText, setRule: _setRule, ruleIndex, }) {
    const { removeRule, isAddingNewRule } = useConditionalLogicState();
    const booleanAnswerRef = useRef(null);
    const destStepUuid = useRef(null);
    const setRule = useCallback(({ selectedChoice, selectedDestStepUuid, }) => {
        if (!isNil(selectedChoice)) {
            booleanAnswerRef.current = selectedChoice;
        }
        if (selectedDestStepUuid) {
            destStepUuid.current = selectedDestStepUuid;
        }
        // if both the answer choice and destination step has been selected
        // set these values to the document
        if (booleanAnswerRef.current !== null && destStepUuid.current) {
            _setRule(booleanAnswerRef.current, destStepUuid.current);
            if (isAddingNewRule)
                removeRule();
        }
    }, [_setRule, isAddingNewRule, removeRule]);
    return (_jsx(BooleanConditionalRule, { ruleIndex: ruleIndex, destinationStepUUID: null, testId: "draft-rule", setRule: setRule, deleteRule: () => removeRule(), operatorOptions: operatorOptions, prefixText: prefixText }));
}
function BooleanSavedRuleSet({ ruleIndex, savedAnswer, destinationStepUUID, operatorOptions, prefixText, deleteRule: _deleteRule, updateRule: _updateRule, }) {
    const deleteRule = useAction(() => {
        _deleteRule(savedAnswer);
    }, [_deleteRule, savedAnswer]);
    const updateRule = useAction(({ selectedChoice, selectedDestStepUuid, }) => {
        _updateRule({
            selectedChoice,
            selectedDestStepUuid,
            currentChoice: savedAnswer,
        });
    }, [_updateRule, savedAnswer]);
    return (_jsx(BooleanConditionalRule, { ruleIndex: ruleIndex, destinationStepUUID: destinationStepUUID, deleteRule: deleteRule, setRule: updateRule, operatorOptions: operatorOptions, prefixText: prefixText, testId: "rule-box", savedValue: savedAnswer }, `${savedAnswer}`));
}
const BooleanConditionalRule = observer(function BooleanConditionalRule({ deleteRule, destinationStepUUID, setRule, testId, operatorOptions, prefixText, ruleIndex, savedValue, }) {
    const [, truthyText] = operatorOptions[0];
    const [, falsyText] = operatorOptions[1];
    // TODO: rip out this internal state when support for partial data on boolean steps is implemented
    const [selectedStepUuid, setSelectedStepUuid] = useState(destinationStepUUID);
    const setRuleValue = useCallback((val) => {
        setRule({
            selectedChoice: val === truthyText,
        });
    }, [truthyText, setRule]);
    const setDestinationStepUuid = useCallback((val) => {
        setSelectedStepUuid(val);
        setRule({
            selectedDestStepUuid: val,
        });
    }, [setRule]);
    const convertBooleanToString = useCallback(() => {
        if (savedValue === undefined) {
            return savedValue;
        }
        return savedValue ? truthyText : falsyText;
    }, [falsyText, savedValue, truthyText]);
    return (_jsxs(RuleBox, { "data-testid": testId, children: [_jsx(RuleHeader, { ruleIndex: ruleIndex, deleteRule: deleteRule }), _jsx(RuleBoxContentContainer, { children: _jsxs(Box, { display: "flex", flexDirection: "column", gridGap: "m", position: "relative", children: [_jsx(RequirementBox, { answerComponent: _jsx(_Fragment, { children: _jsx(Box, { display: "flex", gridGap: "8px", children: _jsx(BooleanAnswerSelect, { operatorOptions: operatorOptions, onChange: setRuleValue, prefixText: prefixText, savedValue: convertBooleanToString() }) }) }) }), _jsx(DestinationStepSelect, { setDestinationStepUuid: setDestinationStepUuid, savedDestinationStepUuid: selectedStepUuid })] }) })] }));
});
export function BooleanAnswerSelect({ onChange: _onChange, operatorOptions, prefixText, savedValue, }) {
    // this internal state is temporary, while we support a "draft" state
    // remove as part of https://linear.app/ballpark/issue/BALL-378/remove-draft-states-from-yesno-and-prototype-conditional-logic
    const [value, setValue] = useState(savedValue);
    const onChange = (v) => {
        setValue(v);
        _onChange(v);
    };
    return (_jsx(SelectWithPrefix, { onChange: onChange, prefixText: prefixText !== null && prefixText !== void 0 ? prefixText : 'If answer is', selectOptions: operatorOptions, value: value ? { label: value, value } : undefined }));
}
export default BooleanConditionalLogic;
