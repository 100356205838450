import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { observer } from 'mobx-react';
import * as React from 'react';
import { Heading, Text } from '@marvelapp/ballpark-components';
import styled from '@marvelapp/styled';
import { Box } from '@marvelapp/ui';
export default observer(function RecordVideoHeader() {
    return (_jsx(Container, { children: _jsxs(Header, { children: [_jsx(Heading, { color: "blackA12", marginBottom: "xxs", fontSize: 14, size: "s", children: "Add video guide" }), _jsx(Text, { color: "blackA12", size: "s", children: "Record a video instruction for this step" })] }) }));
});
const Container = styled(Box).attrs({}) `
  width: 100%;
  position: relative;
  z-index: 1;
  top: 0;
  left: 0;
`;
const Header = styled(Box).attrs({
    bg: 'whiteA9',
    paddingX: 's',
    paddingTop: 's',
    paddingBottom: 14,
    borderTopLeftRadius: 'm',
    borderTopRightRadius: 'm',
    boxShadow: 'borders.black',
}) ``;
