import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
/**
 *
 * The terminology used in this file is based on the following diagram:
 *
 *   A rule is made up of one or more requirements. Each requirement is made up of:
 *     - A combinator (and/or)
 *     - An operator (is equal to, is greater than, etc)
 *     - A value (the answer to the question)
 *
 *                    Operator
 *                       │                  Value
 *                       │                    │
 *                       │                    │
 *    ┌──────────────────┼────────────────────┼─────────────────┐
 *    │  Rule            │                    │                 │
 *    │                  │                    │                 │
 *    │   ┌──────────────┼────────────────────┼──────────────┐  │
 *    │   │  Requirement │                    │              │  │
 *    │   │              │                    │              │  │
 *    │   │              ▼                    ▼              │  │
 *    │   │     ┌─────────────┐  ┌───────────────────────┐   │  │
 *    │   │  If │ is equal to │  │                       │   │  │
 *    │   │     └─────────────┘  └───────────────────────┘   │  │
 *    │   └──────────────────────────────────────────────────┘  │
 *    │   ┌──────────────────────────────────────────────────┐  │
 *    │   │ Requirement                                      │  │
 *    │   │                                                  │  │
 *    │   │     ┌───┐                                        │  │
 *    │   │     │ or│◄───────────────────────────────────────┼──┼─── Combinator
 *    │   │     └───┘                                        │  │
 *    │   │                                                  │  │
 *    │   │     ┌─────────────┐  ┌───────────────────────┐   │  │
 *    │   │     │ is equal to │  │                       │   │  │
 *    │   │     └─────────────┘  └───────────────────────┘   │  │
 *    │   └──────────────────────────────────────────────────┘  │
 *    │                                                         │
 *    │                  ┌───────────────────────────────┐      │
 *    │      Then go to  │<destination step>             │      │
 *    │                  └───────────────────────────────┘      │
 *    │                                                         │
 *    └─────────────────────────────────────────────────────────┘
 *
 */
import { observer } from 'mobx-react';
import { useCallback, useEffect, useState } from 'react';
import * as React from 'react';
import { useAction } from '@marvelapp/ballpark-application';
import { BtwButton as Button, Select, Stack, } from '@marvelapp/ballpark-components';
import { CursorClickIcon, PlusIcon } from '@marvelapp/ballpark-icons';
import { Box } from '@marvelapp/ui';
import { Combinator, ConditionalLogicUtils, MultiConditionUtils, generateUUID, } from '@marvelapp/user-test-creator';
import { useConditionalLogicState } from '../../../ConditionalLogicContext';
import BaseLogic from '../BaseLogic';
import { ConditionalEmptyState, DestinationStepSelect, RequirementBox, RuleBox, RuleBoxContentContainer, RuleHeader, SelectWithPrefix, } from './sharedComponents';
export const MultiConditionLogic = observer(function MultiConditionLogic({ step, canAddRequirement, renderRequirement, createRequirement, updateRequirement, validateRequirement, }) {
    const { addNewRule } = useConditionalLogicState();
    const rules = MultiConditionUtils.getRules(step);
    const addRule = useAction(() => {
        MultiConditionUtils.addNewRule({ mutable: step, createRequirement });
        addNewRule();
    }, [addNewRule, createRequirement, step]);
    // Clean up partial rules when the conditions modal is closed
    // TODO this effect seems a convoluted way of doing this -- is there a better way?
    useEffect(() => {
        return () => ConditionalLogicUtils.clearInvalidConditions(step, validateRequirement);
    }, [step, validateRequirement]);
    return (_jsx(BaseLogic, { alwaysPrefixText: rules.length ? 'Otherwise go to' : 'Always go to', canAddRule: true, addRule: addRule, children: rules.length ? (rules.map((rule, ruleIndex) => {
            return (_jsx(RuleView, { step: step, rule: rule, ruleIndex: ruleIndex, createNewRequirement: createRequirement, updateRequirementValue: updateRequirement, canAddRequirement: canAddRequirement, renderRequirement: renderRequirement }, generateUUID()));
        })) : (_jsxs(ConditionalEmptyState, { icon: _jsx(CursorClickIcon, { className: "text-gray-500" }), title: "No rules set up yet\u2026", children: ["Add rules with conditional logic or use the", ' ', _jsx("strong", { children: "\u201CAlways go to\u201D" }), " rule to route all users to a specific step."] })) }));
});
const RuleView = observer(function RuleView({ step, rule, ruleIndex, canAddRequirement, renderRequirement, createNewRequirement, updateRequirementValue, }) {
    const setDestinationStepUuid = useAction((stepUuid) => {
        MultiConditionUtils.updateRuleDestination({
            mutable: step,
            ruleIndex,
            destinationStepUUID: stepUuid,
        });
    }, [ruleIndex, step]);
    const addNewRequirement = useAction(() => MultiConditionUtils.addNewRequirement({
        mutable: step,
        ruleIndex,
        createRequirement: createNewRequirement,
    }), [createNewRequirement, ruleIndex, step]);
    const deleteRequirement = useAction((requirementIndex) => {
        MultiConditionUtils.deleteRequirement(step, ruleIndex, requirementIndex);
    }, [ruleIndex, step]);
    const deleteRule = useAction(() => {
        MultiConditionUtils.deleteRule(step, ruleIndex);
    }, [ruleIndex, step]);
    const { isAddingNewRule, removeRule } = useConditionalLogicState();
    useEffect(() => {
        // reset global isAddingNewRule value on unmount
        return () => {
            if (isAddingNewRule)
                removeRule();
        };
    }, [isAddingNewRule, removeRule]);
    return (_jsxs(RuleBox, { "data-testid": "rule-box", children: [_jsx(RuleHeader, { ruleIndex: ruleIndex, deleteRule: deleteRule }), _jsxs(RuleBoxContentContainer, { children: [rule === null || rule === void 0 ? void 0 : rule.requirements.map((requirement, requirementIndex) => (_jsx(RequirementBox, { answerComponent: _jsx(RequirementView, { step: step, ruleIndex: ruleIndex, requirementIndex: requirementIndex, requirement: requirement, renderRequirement: renderRequirement, updateRequirement: updateRequirementValue }), hasNestedRequirements: rule.requirements.length > 1, 
                        // TODO push this down into RequirementBox so it can be memoized
                        deleteRequirement: () => deleteRequirement(requirementIndex) }, generateUUID()))), canAddRequirement && (_jsx(Stack, { className: "pl-[68px]", children: _jsx(Button, { "data-testid": "add-condition", variant: "ghost", size: "sm", leadingIcon: _jsx(PlusIcon, {}), className: "text-sky-600 hover:bg-sky-100 hover:ring-sky-100 active:bg-sky-200 active:ring-sky-200", onClick: addNewRequirement, children: "Add a condition" }) })), _jsx(Box, { position: "relative", children: _jsx(DestinationStepSelect, { savedDestinationStepUuid: (rule === null || rule === void 0 ? void 0 : rule.destinationStepUUID) || null, setDestinationStepUuid: setDestinationStepUuid }) })] })] }));
});
const RequirementView = observer(function Requirement({ step, ruleIndex, requirementIndex, updateRequirement, requirement, renderRequirement, }) {
    // TODO could this just be internal to the component rendered by renderRequirement?
    const updateRequirementValue = useAction(({ value }) => {
        MultiConditionUtils.updateRequirementValue({
            mutable: step,
            value,
            ruleIndex,
            requirementIndex,
            updateRequirement,
        });
    }, [requirementIndex, ruleIndex, step, updateRequirement]);
    const updateCombinator = useAction((combinator) => {
        MultiConditionUtils.updateRequirementCombinator({
            mutable: step,
            ruleIndex,
            combinator,
            requirementIndex,
        });
    }, [requirementIndex, ruleIndex, step]);
    const updateOperator = useAction((operator) => {
        MultiConditionUtils.updateRequirementOperator({
            mutable: step,
            requirementIndex,
            ruleIndex,
            operator,
        });
    }, [requirementIndex, ruleIndex, step]);
    return (_jsxs(Box, { "data-testid": `requirement-${requirementIndex}`, display: "flex", flexDirection: "row", gridColumnGap: "xs", gridRowGap: "s", flexWrap: "wrap", children: [requirementIndex > 0 && (_jsx(_Fragment, { children: _jsx(Box, { "data-testid": "combinator", flexBasis: "100%", children: _jsx(AndOrInput, { savedCombinator: requirement === null || requirement === void 0 ? void 0 : requirement.combinator, setCombinator: updateCombinator }) }) })), renderRequirement({
                ruleIndex,
                requirementIndex,
                setOperator: updateOperator,
                setValue: updateRequirementValue,
            })] }));
});
// TODO should be able to use SelectWithPrefix directly
export const OperatorSelect = observer(function OperatorSelect({ operator, setOperator, setRule: _setRule, operatorOptions, }) {
    var _a, _b;
    const onChange = (val) => {
        var _a, _b;
        // convert string to identity operator match
        const selectedIdentityOperator = (_b = (_a = operatorOptions.find(([_, selectText]) => selectText === val)) === null || _a === void 0 ? void 0 : _a[0]) !== null && _b !== void 0 ? _b : '';
        if (selectedIdentityOperator)
            setOperator(selectedIdentityOperator);
    };
    const selectedAnswerText = (_b = (_a = operatorOptions.find(([key, _]) => key === operator)) === null || _a === void 0 ? void 0 : _a[1]) !== null && _b !== void 0 ? _b : '';
    return (_jsx(SelectWithPrefix, { value: { label: selectedAnswerText, value: selectedAnswerText }, onChange: onChange, prefixText: "If answer", selectOptions: operatorOptions }));
});
// TODO this should be a simple select not a combo box!
const AndOrInput = observer(function AndOrInput({ savedCombinator = Combinator.And, setCombinator, }) {
    const [selectedAnswer, setSelectedAnswer] = useState(savedCombinator
        ? savedCombinator.toLowerCase()
        : Combinator.And.toLowerCase());
    const value = selectedAnswer !== null && selectedAnswer !== void 0 ? selectedAnswer : '';
    const onOptionClick = useCallback((option) => {
        setCombinator(option.toUpperCase()); // FIXME?
        setSelectedAnswer(option);
    }, [setCombinator]);
    return (_jsx(Stack, { className: "w-[100px] bg-white", direction: "row", children: _jsxs(Select.Root, { value: value, onValueChange: (option) => onOptionClick(option), children: [_jsx(Select.Trigger, { variant: "secondary", width: "full", isTruncated: true, hasPlaceholder: !value && true, children: value }), _jsx(Select.Content, { testId: "combinator-list", children: ['and', 'or'].map((option) => (_jsx(Select.Item, { value: option, children: option }, option))) })] }) }));
});
