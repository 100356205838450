import { gql, useQuery } from '@apollo/client';
import { MediaUtils, StepUtils } from '@marvelapp/user-test-creator';
const GET_SIGNED_URL = gql `
  query videoGuideUrl($recordingId: String!) {
    videoGuideUrl(recordingId: $recordingId)
  }
`;
export function useGetVideoGuideUrl(step) {
    var _a;
    const instruction = StepUtils.getInstruction(step);
    const isLegacyRecording = (instruction === null || instruction === void 0 ? void 0 : instruction.status) === MediaUtils.RecordingStatus.Ready &&
        instruction.url.includes('ballpark-media');
    const { data } = useQuery(GET_SIGNED_URL, {
        variables: { recordingId: instruction === null || instruction === void 0 ? void 0 : instruction.recordingId },
        skip: isLegacyRecording ||
            (instruction === null || instruction === void 0 ? void 0 : instruction.status) !== MediaUtils.RecordingStatus.Ready,
    });
    if (isLegacyRecording) {
        // Legacy recordings are hosted on ballpark-media-XXX and are publicly accessible
        return instruction.url;
    }
    return (_a = data === null || data === void 0 ? void 0 : data.videoGuideUrl) !== null && _a !== void 0 ? _a : null;
}
