import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { gql, useQuery } from '@apollo/client';
import { observer } from 'mobx-react';
import { useCallback } from 'react';
import * as React from 'react';
import { NavLink, Redirect, useRouteMatch } from 'react-router-dom';
import { useAction, usePermissions } from '@marvelapp/ballpark-application';
import { BtwButton, BtwModal, BtwText, CtaMessage, NotFound, Stack, Strong, cn, } from '@marvelapp/ballpark-components';
import { CONTACT_SUPPORT } from '@marvelapp/ballpark-help';
import { BuildingsIcon, EarthIcon, FileLockedIcon, LockIcon, PeopleIcon, RemovePeopleIcon, TargetIcon, UfoIcon, } from '@marvelapp/ballpark-icons';
import styled from '@marvelapp/styled';
import { Loader } from '@marvelapp/ui';
import { StoreSelectedErrorEnum, UserTestStatusEnum, } from '../../__generated__/queryTypes';
import { useProjectContext } from '../../utils/ProjectContext';
import { getPageError, getPageErrorCopy } from '../../utils/getPageErrorCopy';
import { ComboboxFilter } from './ComboboxFilter';
import { DetailedTargeting } from './DetailedTargeting';
import { GeneralTargeting } from './GeneralTargeting';
import { OrderSummary } from './OrderSummary';
import { RecruitmentContextProvider, useRecruitmentState, } from './RecruitmentContext';
import { RecruitmentUnavailableMessage } from './RecruitmentUnavailableMessage';
import { StudySetup } from './StudySetup';
import { ColumnLayout, RecruitmentSection } from './common';
import { useGetRecruitmentData, } from './useGetRecruitmentData';
const GET_USER_TEST = gql `
  query getUserTestForRecruitmentPage($pk: Int) {
    userTest(pk: $pk) {
      estimatedTimeToCompleteMinutes
      pk
      prototype {
        pk
        name
        isLocked
        isArchived
        passwordProtected
      }
      status
      uuid
    }
  }
`;
const sectionTitlesByFilterGroup = {
    GROUP_DEFAULT: 'General targeting',
    GROUP_B2B: 'B2B-specific targeting',
};
const sectionIconsByFilterGroup = {
    GROUP_DEFAULT: _jsx(EarthIcon, {}),
    GROUP_B2B: _jsx(BuildingsIcon, {}),
};
export const Recruitment = observer(function Recruitment({ isLoadingUserTestDoc, }) {
    var _a, _b, _c, _d, _e, _f;
    const match = useRouteMatch();
    const projectPk = match.params.id;
    const permissions = usePermissions();
    const canRecruitParticipants = permissions.canRecruitParticipants();
    const { data, loading: isLoadingUserTest, error, } = useQuery(GET_USER_TEST, {
        variables: { pk: Number(projectPk) },
    });
    const estimatedTimeToComplete = !((_a = data === null || data === void 0 ? void 0 : data.userTest) === null || _a === void 0 ? void 0 : _a.estimatedTimeToCompleteMinutes)
        ? 1
        : (_b = data === null || data === void 0 ? void 0 : data.userTest) === null || _b === void 0 ? void 0 : _b.estimatedTimeToCompleteMinutes;
    const { isRecruitmentDataLoading, recruitmentData, errorFetchingRecruitmentData, } = useGetRecruitmentData({
        testUUID: (_c = data === null || data === void 0 ? void 0 : data.userTest) === null || _c === void 0 ? void 0 : _c.uuid,
        estimatedTimeToComplete,
    });
    const pageError = getPageError({
        data,
        loading: isLoadingUserTest,
        error,
    });
    if (!canRecruitParticipants) {
        return _jsx(Redirect, { to: `/project/${projectPk}/responses/` });
    }
    if (errorFetchingRecruitmentData) {
        return (_jsx(RecruitmentUnavailableMessage, { copy: _jsx("p", { children: "We couldn\u2019t fetch the recruitment information. Please get in touch with our support team." }), icon: _jsx(UfoIcon, {}), title: "Houston, we have a problem!", children: _jsx(BtwButton, { asChild: true, variant: "primary", children: _jsx("a", { "data-testid": "message-cta", href: CONTACT_SUPPORT, children: "Contact support" }) }) }));
    }
    if (pageError) {
        return _jsx(NotFound, Object.assign({}, getPageErrorCopy(pageError)));
    }
    if ((_e = (_d = data === null || data === void 0 ? void 0 : data.userTest) === null || _d === void 0 ? void 0 : _d.prototype) === null || _e === void 0 ? void 0 : _e.isLocked) {
        return _jsx(Redirect, { to: `/projects/team` });
    }
    if (isLoadingUserTestDoc ||
        isLoadingUserTest ||
        !(data === null || data === void 0 ? void 0 : data.userTest) ||
        isRecruitmentDataLoading ||
        !recruitmentData) {
        return (_jsx(LoaderWrapper, { children: _jsx(Loader, {}) }));
    }
    return (_jsx(RecruitmentPage, { estimatedTimeToComplete: estimatedTimeToComplete, isPasswordProtected: !!((_f = data.userTest.prototype) === null || _f === void 0 ? void 0 : _f.passwordProtected), isProjectPaused: data.userTest.status === UserTestStatusEnum.PAUSED, recruitmentData: recruitmentData, testUUID: data.userTest.uuid }));
});
const RecruitmentPage = observer(function RecruitmentPage({ estimatedTimeToComplete, isPasswordProtected, isProjectPaused, recruitmentData, testUUID, }) {
    const { projectBar } = useProjectContext();
    const openSettingsModal = useAction(() => {
        projectBar.isSettingsModalOpen = true;
    }, [projectBar]);
    const openShareModal = useAction(() => {
        projectBar.isShareModalOpen = true;
    }, [projectBar]);
    const PausedProjectIcon = useCallback(() => {
        return _jsx(RemovePeopleIcon, {});
    }, []);
    const PasswordProtectedProjectIcon = useCallback(() => {
        return _jsx(LockIcon, {});
    }, []);
    if (isProjectPaused) {
        return (_jsx(RecruitmentUnavailableMessage, { copy: _jsxs("p", { children: ["This project is ", _jsx(Strong, { children: "paused" }), ", so it can\u2019t receive new responses from participants. To enable recruitment again, un-pause the project first."] }), icon: _jsx(PausedProjectIcon, {}), children: _jsx(BtwButton, { "data-testid": "message-cta", onClick: openSettingsModal, children: "Open project settings" }) }));
    }
    if (isPasswordProtected) {
        return (_jsx(RecruitmentUnavailableMessage, { copy: _jsxs("p", { children: ["This project is ", _jsx(Strong, { children: "password protected" }), ", so it can\u2019t receive new responses from participants. To enable recruitment again, remove the password first."] }), icon: _jsx(PasswordProtectedProjectIcon, {}), children: _jsx(BtwButton, { "data-testid": "message-cta", onClick: openShareModal, children: "Open share settings" }) }));
    }
    return (_jsxs(RecruitmentContextProvider, { estimatedTimeToComplete: estimatedTimeToComplete, recruitmentData: recruitmentData, testUUID: testUUID, children: [_jsx(ErrorStoringSelections, {}), _jsx("form", { className: "min-h-[calc(100vh-50px)]", "data-testid": "recruitment-wrapper", onSubmit: (e) => e.preventDefault(), children: _jsx(Stack, { className: "min-h-[calc(100vh-50px)] bg-gray-50 px-8 py-6", direction: "col", gap: "4", align: "center", children: _jsxs(Stack, { direction: { initial: 'col', lg: 'row' }, align: { initial: 'stretch', lg: 'start' }, gap: "6", width: "full", className: "max-w-screen-2xl", children: [_jsxs(Stack, { className: "flex-1", gap: "8", children: [_jsxs(Stack, { gap: "1", width: "full", children: [_jsx(SectionHeading, { icon: _jsx(PeopleIcon, {}), copy: "Select panel type and number of responses required" }), _jsx(StudySetup, {})] }), _jsxs(Stack, { "data-testid": "general-targeting", gap: "1", width: "full", children: [_jsx(SectionHeading, { icon: sectionIconsByFilterGroup.GROUP_DEFAULT, copy: sectionTitlesByFilterGroup.GROUP_DEFAULT }), _jsx(GeneralTargeting, {})] }), _jsx(StudySpecificFilters, {})] }), _jsx(Stack, { className: "lg:sticky lg:top-[82px] lg:mt-8 lg:w-[360px]", children: _jsx(OrderSummary, {}) })] }) }) })] }));
});
const ErrorStoringSelections = observer(function ErrorStoringSelections() {
    const { storeSelectedOptionsError } = useRecruitmentState();
    if (!storeSelectedOptionsError)
        return null;
    if (storeSelectedOptionsError === StoreSelectedErrorEnum.PROJECT_NOT_FOUND) {
        return (_jsxs(ErrorModal, { description: "It has been archived or deleted. Please check your archives or contact support.", icon: _jsx(UfoIcon, {}), title: "This project no longer exists", children: [_jsx(BtwButton, { asChild: true, "data-testid": "secondary-cta", variant: "secondary", children: _jsx(NavLink, { to: "/projects", children: "View all projects" }) }), _jsx(BtwButton, { asChild: true, "data-testid": "primary-cta", variant: "primary", children: _jsx("a", { href: CONTACT_SUPPORT, children: "Contact support" }) })] }));
    }
    if (storeSelectedOptionsError === StoreSelectedErrorEnum.PERMISSION_DENIED) {
        return (_jsx(ErrorModal, { description: "Please contact the workspace owner to change the permissions.", icon: _jsx(FileLockedIcon, {}), title: "You no longer have the permission to edit this project", children: _jsx(BtwButton, { asChild: true, "data-testid": "primary-cta", variant: "primary", children: _jsx(NavLink, { to: "/projects", children: "View all projects" }) }) }));
    }
    return null;
});
const ErrorModal = observer(function ErrorModal({ children, description, icon, title, }) {
    const preventInteractOutside = useCallback((e) => e.preventDefault(), []);
    return (_jsx(BtwModal.Root, { "data-testid": "error-dialog", open: true, children: _jsx(BtwModal.Content, { contentClassName: "max-h-[800px] sm:max-h-[640px] min-h-[500px] w-full max-w-[800px] flex-row", "data-testid": "error-dialog", onInteractOutside: preventInteractOutside, children: _jsx(Stack, { align: "center", className: "h-full text-center", justify: "center", gap: "6", children: _jsxs(CtaMessage.Root, { size: "sm", children: [_jsxs(CtaMessage.Header, { icon: icon, children: [_jsx(CtaMessage.Heading, { children: title }), _jsx(CtaMessage.Description, { children: description })] }), _jsx(CtaMessage.Actions, { children: children })] }) }) }) }));
});
const LoaderWrapper = styled.div `
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;
const SectionHeading = observer(function SectionHeading({ copy, icon, }) {
    return (_jsxs(Stack, { align: "center", "data-testid": "section-heading", direction: "row", gap: "2", width: "full", className: cn('h-7 px-2 [&>svg]:size-3.5 [&>svg]:text-gray-900'), children: [icon, _jsx(BtwText, { size: "13", variant: "primary", weight: "semibold", children: copy })] }));
});
const StudySpecificFilters = observer(function StudySpecificFilters() {
    const { filters } = useRecruitmentState();
    // specialFilters will always include GROUP_DEFAULT, which we don't
    // want included in study specific filters section
    const studySpecificSpecialFilters = Object.keys(filters.specialFilters).filter((group) => group !== 'GROUP_DEFAULT');
    return (_jsxs(_Fragment, { children: [studySpecificSpecialFilters.map((group) => {
                const filterGroupId = group;
                return (_jsxs(Stack, { "data-testid": "spotlight-filters", gap: "1", width: "full", children: [_jsx(SectionHeading, { icon: sectionIconsByFilterGroup[filterGroupId], copy: sectionTitlesByFilterGroup[filterGroupId] }), _jsx(SpotlightFilters, { filterGroupId: filterGroupId })] }, filterGroupId));
            }), _jsxs(Stack, { "data-testid": "detailed-targeting", gap: "1", width: "full", children: [_jsx(SectionHeading, { icon: _jsx(TargetIcon, {}), copy: "Audience targeting" }), _jsx(DetailedTargeting, {})] })] }));
});
const SpotlightFilters = observer(function SpotlightFilters({ filterGroupId, }) {
    const { filters } = useRecruitmentState();
    const filterGroup = filters.specialFilters[filterGroupId];
    return (_jsx(RecruitmentSection, { children: _jsx(ColumnLayout
        // Switching to a 3-column layout on 2xl size (min-width: 1536px)
        , { 
            // Switching to a 3-column layout on 2xl size (min-width: 1536px)
            className: cn('md:flex-row 2xl:*:basis-1'), items: filterGroup.originalFilterOrder.map((filterKey) => ({
                title: filterGroup.filters[filterKey].name,
                isRequired: !!filterGroup.filters[filterKey].isRequired,
                minSelection: filterGroup.filters[filterKey].minSelection,
                maxSelection: filterGroup.filters[filterKey].maxSelection,
                component: (_jsx(ComboboxFilter, { filterId: filterKey, filterGroupId: filterGroupId })),
            })) }) }));
});
