import { __rest } from "tslib";
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { observer } from 'mobx-react';
import { Heading, Text } from '@marvelapp/ballpark-components';
import { Box, Grid } from '@marvelapp/ui';
import Divider from './Divider';
export default observer(function ResponseInfo({ device, participant, }) {
    return (_jsxs(Box, { paddingX: "xs", children: [participant.email && (_jsxs(_Fragment, { children: [_jsx(Heading, { marginBottom: "m", size: "s", children: "Basics" }), _jsx(InfoPair, { mb: "m", subtitle: "Email", value: participant.email }), _jsx(Divider, { mb: "l" })] })), device && (_jsxs(_Fragment, { children: [_jsx(Heading, { marginBottom: "m", size: "s", children: "Hardware" }), _jsxs(Grid, { gridTemplateColumns: "1fr 1fr", children: [_jsx(InfoPair, { mb: "m", subtitle: "Model", value: device.model }), _jsx(InfoPair, { subtitle: "Resolution", value: `${device.screenWidth}x${device.screenHeight}` }), _jsx(InfoPair, { subtitle: "OS", value: device.operatingSystem })] })] }))] }));
});
const InfoPair = observer(function InfoPair(_a) {
    var { subtitle, value } = _a, otherProps = __rest(_a, ["subtitle", "value"]);
    if (!value) {
        return null;
    }
    return (_jsxs(Box, Object.assign({}, otherProps, { children: [_jsx(Text, { fontSize: 12, kind: "primary", children: subtitle }), _jsx(Text, { fontSize: 12, kind: "secondary", children: value })] })));
});
