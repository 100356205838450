import { gql } from '@apollo/client';
export const GET_USER_TEST_DOC = gql `
  query getUserTestDoc($testUuid: String!, $revision: Int!, $token: String) {
    userTestDocument(uuid: $testUuid, revision: $revision, token: $token) {
      uuid
      revision
      document
    }
  }
`;
export const GET_USER_TEST_UUID = gql `
  query getUserTestUuid($pk: Int!) {
    userTest(pk: $pk) {
      uuid
      pk
    }
  }
`;
export const GET_USER_TEST_INFO = gql `
  query getUserTestInfo($pk: Int) {
    userTest(pk: $pk) {
      pk
      uuid
      prototype {
        pk
        name
        isArchived
        isLocked
      }
      resultStats {
        allResultsCount
        completionRate
        totalCompletedResults
        totalResultsPercentage
        totalResults
        screeningCount
        qualifiedCount
        disqualifiedCount
        qualifiedPercentage
        disqualifiedPercentage
        averageDuration
        testPk
      }
    }
  }
`;
export const GET_RECORDING_DOWNLOAD = gql `
  query getTestRecordingObject($uuid: String) {
    userTestResult(resultUuid: $uuid) {
      recordings {
        download {
          pk
          videoUrl
          statusLog
        }
      }
    }
  }
`;
