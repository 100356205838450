import { __rest } from "tslib";
import { jsx as _jsx } from "react/jsx-runtime";
import { ARROW_LEFT_ICON } from '@marvelapp/ballpark-theme';
import Button from '../Button';
import Icon from '../Icon';
function BackButton(_a) {
    var props = __rest(_a, []);
    return (_jsx(Button, Object.assign({ alignItems: "center", display: "flex", justifyContent: "center", px: 0 }, props, { children: _jsx(Icon, { display: "block", paths: ARROW_LEFT_ICON }) })));
}
BackButton.defaultProps = {
    borderRadius: 20,
    height: 40,
    kind: 'ghost',
    width: 40,
};
export default BackButton;
