import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { createColumnHelper } from '@tanstack/react-table';
import { observer } from 'mobx-react';
import { Redirect } from 'react-router-dom';
import { BtwDataTable, BtwText, LiveSessionsTable, Loader, ScrollArea, Stack, cn, sortSessionsByStatus, useSessionsTable, } from '@marvelapp/ballpark-components';
import { LiveIcon } from '@marvelapp/ballpark-icons';
import { useLiveProjectSessions } from './useLiveProjectSessions';
const tableClassNames = [
    'rounded-lg border',
    // adjust cell padding
    '[&_th]:py-3.5',
    '[&_td]:py-2.5',
    // Removing horizontal borders from cells
    '[&_td]:border-x-0',
    'first:[&_td]:border-x-0',
    '[&_th]:border-x-0',
    'first:[&_th]:border-x-0',
    // Other
    'first:[&_tbody>tr]:hidden',
    'last:[&_tbody>tr]:hidden',
];
export const ProjectSessions = observer(function Sessions({ isLoadingUserTestDoc, }) {
    var _a;
    const { isLoadingLiveSessions, liveSessions, userTest } = useLiveProjectSessions();
    if ((_a = userTest === null || userTest === void 0 ? void 0 : userTest.prototype) === null || _a === void 0 ? void 0 : _a.isLocked) {
        return _jsx(Redirect, { to: `/projects/team` });
    }
    if (isLoadingUserTestDoc ||
        isLoadingLiveSessions ||
        !liveSessions ||
        !userTest) {
        return (_jsx(Stack, { align: "center", className: "min-h-screen", justify: "center", width: "full", children: _jsx(Loader, {}) }));
    }
    return _jsx(ProjectSessionsTable, { sessions: liveSessions });
});
const projColumnHelper = createColumnHelper();
const columns = [
    projColumnHelper.accessor('roomName', {
        size: 300,
        header: 'Participant',
        enableSorting: false,
        cell: (data) => {
            const roomName = data.getValue();
            return (_jsx(BtwText, { variant: "primary", weight: "medium", children: roomName }));
        },
    }),
    projColumnHelper.accessor('status', {
        size: 200,
        header: 'Status',
        cell: (data) => {
            const status = data.getValue();
            const { startTime } = data.row.original;
            return (_jsx(LiveSessionsTable.SessionStatus, { status: status, startTime: startTime }));
        },
        sortingFn: (rowA, rowB) => sortSessionsByStatus(rowA.original, rowB.original),
    }),
    projColumnHelper.accessor('moderators', {
        size: 200,
        header: 'Moderators',
        enableSorting: false,
        cell: (data) => {
            const moderators = data.getValue();
            return _jsx(LiveSessionsTable.AvatarsCell, { users: moderators });
        },
    }),
    projColumnHelper.accessor('observers', {
        size: 200,
        header: 'Observers',
        enableSorting: false,
        cell: (data) => {
            const observers = data.getValue();
            return _jsx(LiveSessionsTable.AvatarsCell, { users: observers });
        },
    }),
    projColumnHelper.display({
        size: 140,
        id: 'joinSessionCTA',
        cell: (data) => {
            const { shareUrl } = data.row.original;
            return _jsx(LiveSessionsTable.JoinSessionCTA, { shareUrl: shareUrl });
        },
    }),
];
const ProjectSessionsTable = observer(function ProjectSessionsTable({ sessions, }) {
    const table = useSessionsTable(sessions, columns);
    if (sessions.length === 0) {
        return (_jsx(LiveSessionsTable.NoSessionsAvailableMessage, { className: "bg-gray-50 px-8 py-6", description: "Once a participant joins a live session, we will show it here", title: "No live sessions currently" }));
    }
    return (
    // 100% viewport height, minus the height of the project header bar
    _jsxs(Stack, { className: "relative h-[calc(100vh_-_50px)] overflow-hidden bg-gray-50 p-6", gap: "2", align: "center", children: [_jsxs(Stack, { align: "center", direction: "row", gap: "2", justify: "start", className: "max-w-screen-2xl", width: "full", children: [_jsx(LiveIcon, { className: "size-3.5 text-gray-900" }), _jsx(BtwText, { size: "13", variant: "primary", weight: "semibold", children: "All live sessions" })] }), _jsx(Stack, { width: "full", 
                // This removes the height of the header above the table container
                className: "max-h-[calc(100%_-_32px)] max-w-screen-2xl", children: _jsx(Stack, { width: "full", className: cn(tableClassNames, 'h-full overflow-scroll border-gray-600/10 bg-white'), children: _jsxs(ScrollArea.Root, { children: [_jsx(ScrollArea.Viewport, { children: _jsx(BtwDataTable, { rowTestIdPrefix: "session", table: table, withStickyHeader: true }) }), _jsx(ScrollArea.Scrollbar, { orientation: "horizontal", className: "z-20", children: _jsx(ScrollArea.Thumb, {}) }), _jsx(ScrollArea.Scrollbar, { orientation: "vertical", className: "z-40", children: _jsx(ScrollArea.Thumb, {}) })] }) }) })] }));
});
