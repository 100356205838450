import { jsx as _jsx } from "react/jsx-runtime";
import { observer } from 'mobx-react';
import { useAction } from '@marvelapp/ballpark-application';
import { IdentityOperator, PrototypeGoalConditionalUtils, } from '@marvelapp/user-test-creator';
import BooleanConditionalLogic from '../shared/booleanConditionalLogic';
const PROTOTYPE_BOOLEAN_OPERATORS = [
    [IdentityOperator.Is, 'user hit the goal successfully'],
    [IdentityOperator.IsNot, 'user did not hit the goal'],
];
export const PrototypeGoalConditionalLogic = observer(function PrototypeGoalConditionalLogic({ step }) {
    const deleteRule = useAction((savedAnswer) => {
        PrototypeGoalConditionalUtils.deleteGoalRule(step, savedAnswer);
    }, [step]);
    const updateRule = useAction(({ selectedChoice, selectedDestStepUuid, currentChoice, }) => {
        PrototypeGoalConditionalUtils.updateGoalRule({
            mutable: step,
            selectedChoice,
            selectedDestStepUuid,
            currentChoice,
        });
    }, [step]);
    const setRule = useAction((selectedChoice, selectedDestStepUuid) => {
        PrototypeGoalConditionalUtils.setGoalRule(step, selectedChoice, selectedDestStepUuid);
    }, [step]);
    // filter out any rules that don't have a destination step set
    const savedRules = PrototypeGoalConditionalUtils.getSavedRules(step);
    return (_jsx(BooleanConditionalLogic, { savedRules: savedRules, deleteRule: deleteRule, setRule: setRule, updateRule: updateRule, prefixText: "If", operatorOptions: PROTOTYPE_BOOLEAN_OPERATORS }, step.uuid));
});
