import { jsx as _jsx } from "react/jsx-runtime";
import { useOverrideNativeUndo } from '@ballpark/realtime-plugin-history';
import { noop } from 'lodash-es';
import { observer } from 'mobx-react';
import { useLayoutEffect, useRef } from 'react';
import * as React from 'react';
import TextareaAutosize from 'react-textarea-autosize';
import { useKey, useOnClickOutside } from '@marvelapp/hooks';
import styled from '@marvelapp/styled';
import { inputStyles } from '@marvelapp/ui';
import { useStateFromProp } from '../../hooks/useStateFromProp';
const sizes = {
    m: {
        fontSize: 'm',
    },
    l: {
        fontSize: 'l',
    },
    xl: {
        fontSize: 'xl',
    },
    xxl: {
        fontSize: [20, 20, 'xl', 'xl', 'xl', 'xxl'],
    },
};
const kinds = {
    heading: {
        color: 'slate12',
        fontWeight: 'semibold',
        lineHeight: 'heading',
    },
    label: {
        color: 'slate12',
        fontWeight: 'medium',
        lineHeight: 'heading',
    },
    paragraph: {
        color: 'slate11',
        fontWeight: 'regular',
        lineHeight: 'body',
    },
};
const EditableText = observer(function EditableText({ fontSize, kind, lineHeight, maxLength, onBlur: parentOnBlur, onMouseDown, onSaveValue = noop, placeholder, size, testId, textAlign, value = '', autoFocus = false, }) {
    const textareaRef = useRef(null);
    const overrideNativeUndo = useOverrideNativeUndo();
    useLayoutEffect(() => {
        // We don't want to auto focus the textarea when another element is already focused,
        // or we might steal it while the user is typing on another input
        if (!autoFocus || !textareaRef.current || document.activeElement) {
            return;
        }
        textareaRef.current.focus();
    }, [autoFocus]);
    // Using internal state here as the onChange handler to an input cannot be async.
    // Updates to state in askhub are async so we can't use the value prop directly
    const [internalValue, setInternalValue] = useStateFromProp(value || '');
    const onBlur = (event) => {
        parentOnBlur === null || parentOnBlur === void 0 ? void 0 : parentOnBlur(event);
    };
    const onChange = (event) => {
        setInternalValue(event.target.value);
        onSaveValue(event.target.value);
    };
    useOnClickOutside(textareaRef, onBlur);
    return (_jsx(TextEdit, { fontSize: fontSize, kind: kind, lineHeight: lineHeight, maxLength: maxLength, onBlur: onBlur, onMouseDown: onMouseDown, onChange: onChange, onKeyDown: overrideNativeUndo, placeholder: placeholder, size: size, testId: testId, textAlign: textAlign, textareaRef: textareaRef, value: internalValue }));
});
const TextEdit = observer(function TextEdit({ fontSize, kind, lineHeight, maxLength, onBlur, onChange, onFocus, onMouseDown, onKeyDown, placeholder, size, testId = 'text-edit', textAlign, textareaRef, value, }) {
    useKey(onBlur, { key: 'Escape' });
    const overrideNativeUndo = useOverrideNativeUndo(onKeyDown);
    return (_jsx(Textarea, Object.assign({}, kinds[kind], (size && sizes[size]), { "data-testid": testId, fontSize: fontSize, lineHeight: lineHeight, maxLength: maxLength, onBlur: onBlur, onChange: onChange, onFocus: onFocus, onMouseDown: onMouseDown, onKeyDown: overrideNativeUndo, placeholder: placeholder, ref: textareaRef, textAlign: textAlign, value: value })));
});
const Textarea = styled(TextareaAutosize).attrs({
    fontFamily: 0,
    border: '3px solid',
    borderRadius: 'xl',
    borderColor: 'transparent',
    hoverBorderColor: 'slate5',
    bg: 'background',
}) `
  ${inputStyles};
  transition: none;

  padding-left: 8px;
  padding-right: 8px;

  padding-top: 4px;
  padding-bottom: 3px;

  margin-left: -10px;
  min-height: 0;
  letter-spacing: -0.2px;

  width: calc(100% + 10px);

  border-color: transparent;

  resize: none;

  &:focus {
    resize: both;
    border-color: ${(props) => props.theme.colors.slate7};
  }

  &::placeholder {
    color: ${(props) => props.theme.colors.slate9};
    opacity: 1;
    letter-spacing: -0.2px;
    line-height: inherit;
  }

  &:focus&::placeholder {
    color: ${(props) => props.theme.colors.slate9};
    opacity: 1;
    letter-spacing: -0.2px;
    line-height: inherit;
  }
`;
export default EditableText;
