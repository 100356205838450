import { __rest } from "tslib";
import { jsx as _jsx } from "react/jsx-runtime";
import { noop } from 'lodash-es';
import { observer } from 'mobx-react';
import { forwardRef, useCallback, useImperativeHandle, useState, } from 'react';
import { InputWithLabel } from '@marvelapp/ballpark-components';
import { makeLinkValid } from '@marvelapp/core';
import { useStateFromProp } from '../../hooks/useStateFromProp';
import { parseUrl } from '../../utils';
const UrlInput = observer(forwardRef(function UrlInput(props, ref) {
    const { value, domainAllowList, onError = noop, onChange: parentOnChange, testId, validate } = props, rest = __rest(props, ["value", "domainAllowList", "onError", "onChange", "testId", "validate"]);
    // In askhub state updates are async so we need to copy the value
    // to local state here so that the updates to the input's value are synchronous.
    // The effect is needed as the value prop might change as a result of a realtime update.
    const [internalValue, setInternalValue] = useStateFromProp(value);
    const [error, setError] = useState('');
    const handleChange = useCallback((newValue, isValid) => {
        setInternalValue(newValue);
        if (isValid) {
            parentOnChange(newValue);
        }
    }, [parentOnChange, setInternalValue]);
    const onChange = useCallback((e) => {
        const withoutBreaks = e.target.value.replace(/\n/g, '');
        const validLink = makeLinkValid(withoutBreaks);
        setError(validLink ? '' : 'Please enter a valid url');
        onError(!validLink);
        handleChange(withoutBreaks, !!validLink);
    }, [handleChange, onError]);
    const onBlur = useCallback(() => {
        const validLink = makeLinkValid(internalValue);
        if (!validLink) {
            return;
        }
        if (validate) {
            const customError = validate(validLink);
            if (customError) {
                setError(customError);
                onError(true);
                return;
            }
        }
        if (domainAllowList && domainAllowList.length) {
            const { domainWithoutSubdomains } = parseUrl(validLink);
            if (!domainAllowList.includes(domainWithoutSubdomains)) {
                setError("We don't currently support URLs from this domain");
                onError(true);
                return;
            }
        }
        else if (validLink.includes('file/')) {
            setError("We don't currently support file URLs");
            onError(true);
            return;
        }
        handleChange(validLink, true);
        return validLink;
    }, [internalValue, handleChange, domainAllowList, onError]);
    useImperativeHandle(ref, () => ({
        validate: onBlur,
    }));
    return (_jsx(InputWithLabel, Object.assign({ "data-testid": testId, error: error, kind: error ? 'error' : 'primary', type: "text", value: internalValue, onChange: onChange, onBlur: onBlur }, rest)));
}));
export default UrlInput;
