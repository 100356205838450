export const URL = 'https://help.marvelapp.com/hc/en-us';
const link = (id) => `${URL}/articles/${id}`;
export const NEW_TICKET = `${URL}/requests/new`;
export const BILLING_REMOVING_MEMBERS = link(360002743757);
export const HOW_PLAY_PROTOTYPE = link(360002730717);
export const CREATE_FIRST_PROTOTYPE = link(360002536038);
export const DETACHED_PROJECTS = link(36000485047);
export const PROTOTYPING_LAYERS = link(209299205);
export const USER_TESTING_INTRODUCTION = link(360002558237);
export const USER_TESTING_TROUBLESHOOTING = link(360001834078);
export const HOW_TO_INVITE_USERS = link(360002561038);
export const MANAGING_MEMBERS_AND_GUESTS = link(360003166018);
export const SWITCH_MEMBERS_GUESTS = link(360004864397);
export const CHANGE_TEAM_MEMBERS_PERMISSION = link(360009863757);
export const REMOVE_MEMBERS = link(360004864457);
export const SWITCH_OWNERS = link(360003212158);
export const GROUPS = link(360008844537);
export const SIGNIN_PROTECTED_PROTOTYPES = link(360003212218);
export const TEAM_MEMBERS_INVITING = link(360009942478);
export const HOW_TO_CHANGE_TEAM_NAME = link(360003229437);
export const HOW_TO_CHANGE_TEAM_ICON = link(360003229197);
export const SUBDOMAINS = link(360000626869);
export const ARCHIVING_PROJECTS = link(360010395997);
export const COPY_PROJECTS = link(360007955278);
export const DELETE_PROJECTS = link(360010395357);
export const RENAME_PROJECTS = link(360002855617);
export const PHOTOSHOP = link(360018729478);
export const CHANGE_DEVICE = link(360002748358);
export const CHANGE_BACKGROUND = link(360002747217);
export const DISABLE_RESIZING = link(360002746878);
export const HIDE_HOTSPOT_HINTS = link(360002746798);
export const HIDE_RETINA = link(360002729958);
export const SHARE_PROTOTYPE = link(360002646138);
export const ADD_COLLABORATORS = link(360002646178);
export const INVITE_ONLY = link(360007914597);
export const PASSWORD_PROTECT = link(360002851757);
export const STUDENTS = link(360003458197);
export const PROTOTYPING_CONTAINERS = link(360014699118);
export const USER_TESTING_STATUS = link(360016632057);
export const ENABLE_CAMERA_CHROME = link(4403383831185);
export const ENABLE_CAMERA_SAFARI_MAC = link(4403383377041);
export const ENABLE_CAMERA_FIREFOX = link(4403567877649);
export const ENABLE_SCREEN_SHARING_SAFARI_MAC = link(4403548711441);
export const ENABLE_SCREEN_ACCESS_MAC = link(4403376006673);
export const ENABLE_CAMERA_ACCESS_MAC = link(4403375731089);
export const ENABLE_CAMERA_OPERA = link(4403384249617);
export const ENABLE_CAMERA_EDGE = link(4403545692177);
export const CREATE_UT_FIGMA = link(4403727902481);
