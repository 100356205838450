import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { AnimatePresence, motion } from 'framer-motion';
import LogRocket from 'logrocket';
import { action } from 'mobx';
import { observer } from 'mobx-react';
import { Scrollbars } from 'react-custom-scrollbars';
import { useFeatureFlag, useGetUserTest, } from '@marvelapp/ballpark-application';
import { CircleButton, Heading, Icon, ScreenerIcon, StepIcon, } from '@marvelapp/ballpark-components';
import { UI_STEPS_COPY } from '@marvelapp/ballpark-copy';
import theme, { CLOSE_ICON } from '@marvelapp/ballpark-theme';
import styled from '@marvelapp/styled';
import { Box } from '@marvelapp/ui';
import { CardSortingUtils, FiveSecondStepUtils, InstructionStepUtils, LocaleUtils, MultipleChoiceStepUtils, PreferenceStepUtils, PrototypeStepType, PrototypeTaskUtils, QuestionStepType, QuestionStepUtils, RatingStepUtils, STEPS_COPY, ScreenerUtils, StepClass, TaglineCopyStepUtils, UserTestUtils, WebsiteTaskUtils, YesOrNoStepType, YesOrNoStepUtils, } from '@marvelapp/user-test-creator';
import { SidebarPane } from '../../components/SidebarPane';
import { SlideRowSimple } from '../../components/SlideRowSimple';
import StickyHeader from './StickyHeader';
import { STEP_TYPES } from './slideTypes';
import { useUIContext } from './uiContext';
const animation = {
    animate: {
        x: 0,
    },
    exit: {
        x: '-100%',
    },
    initial: {
        x: '-100%',
    },
    transition: {
        ease: theme.easings.smooth.array,
        duration: 0.42,
    },
};
export const AddStepSidebar = observer(function AddStepSidebar({ isOpen, onClose, }) {
    const hiddenSteps = [];
    const hasStaticUi = useFeatureFlag('marvel-3-static-ui');
    const isCardSortingEnabled = useFeatureFlag('card-sorting');
    if (!hasStaticUi) {
        hiddenSteps.push(StepClass.BannerAdTest);
    }
    if (!isCardSortingEnabled) {
        hiddenSteps.push(StepClass.CardSortingClosed);
        hiddenSteps.push(StepClass.CardSortingHybrid);
        hiddenSteps.push(StepClass.CardSortingOpen);
    }
    const userTestDoc = useGetUserTest();
    const UIContext = useUIContext();
    return (_jsx(AnimatePresence, { children: isOpen && (_jsx(Container, { animate: animation.animate, exit: animation.exit, initial: animation.initial, "data-testid": "add-step-sidebar", transition: animation.transition, children: _jsxs(Scrollbars, { autoHide: true, children: [_jsx(StickyHeader, { children: _jsxs(Header, { children: [_jsx(Heading, { as: "h4", lineHeight: 1, size: "s", children: "Select a step" }), _jsx(CircleButton, { "data-testid": "select-step-close", kind: "ghost", onClick: onClose, children: _jsx(Icon, { paths: CLOSE_ICON }) })] }) }), _jsx(Body, { "data-testid": "select-step-list", children: STEP_TYPES.map((category) => {
                            const shouldShowCategory = category.steps.some((step) => !hiddenSteps.includes(step.config.stepClass));
                            if (!shouldShowCategory) {
                                return null;
                            }
                            return (_jsxs(StepCard, { children: [category.title && (_jsx(Heading, { as: "h4", size: "s", children: category.title })), category.steps.map((stepDefn) => {
                                        var _a;
                                        if (hiddenSteps.includes(stepDefn.config.stepClass)) {
                                            return null;
                                        }
                                        return (_jsx(SlideRowSimple, { testId: `select-step-${(_a = stepDefn.testId) !== null && _a !== void 0 ? _a : stepDefn.config.stepClass}${'stepType' in stepDefn.config
                                                ? `-${stepDefn.config.stepType}`
                                                : ''}`, description: stepDefn.description, isPremiumStep: !!stepDefn.isPremiumStep, isDisabled: !!(stepDefn.isScreenerStep &&
                                                ScreenerUtils.hasMaxNoOfScreenerSteps(userTestDoc)), onClick: action(() => {
                                                const stepUUID = handleOnClick(userTestDoc, UIContext.activeSlideUUID, stepDefn);
                                                if (stepUUID) {
                                                    UIContext.setActiveSlideUUID(stepUUID);
                                                    UIContext.slideToFocusOnScrollStopUUID = stepUUID;
                                                }
                                                onClose();
                                            }), title: stepDefn.title, icon: stepDefn.title === 'Screener' ? (_jsx(ScreenerIcon, {})) : (_jsx(StepIcon, { type: 'stepType' in stepDefn.config
                                                    ? stepDefn.config.stepType
                                                    : stepDefn.config.stepClass })) }, stepDefn.id));
                                    })] }, category.id));
                        }) })] }) }, "sidebar-pane")) }));
});
function handleOnClick(userTestDoc, activeSlideUUID, stepDefn) {
    var _a, _b;
    if (stepDefn.isScreenerStep) {
        const screenerStep = ScreenerUtils.addScreenerStep(userTestDoc);
        if ((_a = process.env.DEPLOY_ENV) === null || _a === void 0 ? void 0 : _a.includes('production')) {
            LogRocket.track('add-screener-step');
        }
        return (_b = screenerStep === null || screenerStep === void 0 ? void 0 : screenerStep.uuid) !== null && _b !== void 0 ? _b : null;
    }
    let step;
    switch (stepDefn.config.stepClass) {
        case StepClass.Instruction:
            step = InstructionStepUtils.create();
            break;
        case StepClass.PrototypeTask: {
            const description = stepDefn.config.stepType === PrototypeStepType.FirstClick
                ? LocaleUtils.localeStringToRichText(STEPS_COPY[PrototypeStepType.FirstClick].defaultDescription
                    .defaultMessage, {
                    buttonText: UI_STEPS_COPY[PrototypeStepType.FirstClick].buttonText
                        .defaultMessage,
                })
                : [];
            step = PrototypeTaskUtils.create({
                type: stepDefn.config.stepType,
                description,
            });
            break;
        }
        case StepClass.Question: {
            if (stepDefn.config.stepType === QuestionStepType.QuestionDate) {
                step = QuestionStepUtils.createDate({
                    type: QuestionStepType.QuestionDate,
                });
            }
            else if (stepDefn.config.stepType === QuestionStepType.Text) {
                step = QuestionStepUtils.createText({
                    type: QuestionStepType.Text,
                });
            }
            else if (stepDefn.config.stepType === QuestionStepType.Email) {
                step = QuestionStepUtils.createEmail({
                    type: QuestionStepType.Email,
                });
            }
            else if (stepDefn.config.stepType === QuestionStepType.Numerical) {
                step = QuestionStepUtils.createNumerical({
                    type: QuestionStepType.Numerical,
                });
            }
            break;
        }
        case StepClass.YesOrNo:
            if (stepDefn.config.stepType === YesOrNoStepType.Legal) {
                step = YesOrNoStepUtils.createLegalStep();
            }
            else {
                step = YesOrNoStepUtils.createYesNoStep();
            }
            break;
        case StepClass.MultipleChoice:
            step = MultipleChoiceStepUtils.create();
            break;
        case StepClass.PreferenceTest:
            step = PreferenceStepUtils.create();
            break;
        case StepClass.TaglineCopyTest:
            step = TaglineCopyStepUtils.create();
            break;
        case StepClass.RatingScale:
            step = RatingStepUtils.create();
            break;
        case StepClass.FiveSecondTest: {
            const description = LocaleUtils.localeStringToRichText(STEPS_COPY[StepClass.FiveSecondTest].defaultDescription.defaultMessage, {
                buttonText: UI_STEPS_COPY[StepClass.FiveSecondTest].buttonText.defaultMessage,
            });
            step = FiveSecondStepUtils.create({
                description,
            });
            break;
        }
        case StepClass.WebsiteTask: {
            const description = LocaleUtils.localeStringToRichText(STEPS_COPY[StepClass.WebsiteTask].defaultDescription.defaultMessage);
            step = WebsiteTaskUtils.create({ description });
            break;
        }
        case StepClass.CardSortingClosed:
        case StepClass.CardSortingHybrid:
        case StepClass.CardSortingOpen: {
            const title = 'Sort the cards';
            const description = LocaleUtils.localeStringToRichText(STEPS_COPY[StepClass.CardSortingHybrid].defaultDescription
                .defaultMessage);
            step = CardSortingUtils.create({
                title,
                description,
                className: stepDefn.config.stepClass,
            });
            break;
        }
        default:
            step = null;
    }
    if (step) {
        UserTestUtils.insertStep(userTestDoc, step, activeSlideUUID);
    }
    return step ? step.uuid : null;
}
const Container = styled(motion(SidebarPane)).attrs({ left: 'wideSidebar' }) `
  position: absolute;
  top: 0;
  bottom: 0;
  height: 100%;
  z-index: 1;
`;
const Header = styled(Box).attrs({
    boxShadow: 'borders.bottom',
    pb: 's',
    pt: 17, // need to offset because of the shadow, which sits outside of the box
}) `
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const Body = styled(Box).attrs({
    px: 'xs',
    pt: 's',
    pb: 'xs',
}) ``;
const StepCard = styled(Box).attrs({
    mb: 's',
}) `
  > h4 {
    margin-bottom: ${(props) => props.theme.space.xs}px;
    padding-left: ${(props) => props.theme.space.s}px;
    padding-right: ${(props) => props.theme.space.s}px;
  }

  &:last-child {
    margin-bottom: 0;
  }
`;
