import theme from '@marvelapp/ballpark-theme';
export const transition = {
    ease: theme.easings.smooth.array,
    duration: 0.4,
};
export const videoGuideHolderAnimationMobile = {
    initial: 'inactive',
    variants: {
        active: {
            width: theme.sizes.videoGuide.expanded,
            height: 390,
        },
        inactive: {
            width: 36,
            height: 36,
        },
    },
};
export const videoGuideHolderAnimationTablet = {
    initial: 'inactive',
    variants: {
        active: {
            width: theme.sizes.videoGuide.expanded,
            height: 'auto',
        },
        inactive: {
            width: theme.sizes.videoGuide.builder,
            height: 'auto',
        },
    },
};
export const videoGuideHolderAnimation = {
    initial: 'inactive',
    variants: {
        active: {
            width: theme.sizes.videoGuide.expanded,
            height: 'auto',
        },
        inactive: {
            width: theme.sizes.videoGuide.collapsed,
            height: 'auto',
        },
    },
};
export const rangeSliderAnimation = {
    animate: 'active',
    initial: 'inactive',
    transition: Object.assign(Object.assign({}, transition), { delay: 0.35 }),
    variants: {
        inactive: {
            y: '-100%',
        },
        active: {
            y: 0,
        },
    },
};
export const additionalControlsAnimation = {
    animate: 'active',
    initial: 'inactive',
    transition: Object.assign(Object.assign({}, transition), { delay: 0.55 }),
    variants: {
        inactive: {
            opacity: 0,
            x: 10,
        },
        active: {
            opacity: 1,
            x: 0,
        },
    },
};
export const subtitlesAnimation = {
    animate: 'active',
    initial: 'inactive',
    exit: 'inactive',
    transition,
    variants: {
        inactive: {
            y: '100%',
        },
        active: {
            y: 0,
        },
    },
};
