import { __rest } from "tslib";
import { isBoolean, isFinite, isNil, isString } from 'lodash-es';
import { FlatStepClass, StepUtils, TextUtils, } from '@marvelapp/user-test-creator';
import { ResultStatusEnum } from '../../__generated__/queryTypes';
import { getFlatStep, getResultStatusDisplayName } from './stepUtils';
const generalFilters = [
    createFilterDefinition({
        type: 'enum<string>',
        name: 'Status',
        operand: 'Status',
        operators: [{ name: 'is', value: 'equals' }],
        values: [
            {
                name: getResultStatusDisplayName(ResultStatusEnum.COMPLETED),
                value: ResultStatusEnum.COMPLETED,
            },
            {
                name: getResultStatusDisplayName(ResultStatusEnum.INCOMPLETE),
                value: ResultStatusEnum.INCOMPLETE,
            },
        ],
    }),
    createFilterDefinition({
        type: 'enum<string>',
        operand: 'Device',
        name: 'Device',
        operators: [{ name: 'is', value: 'equals' }],
        values: [
            {
                name: 'Desktop',
                value: 'Web',
            },
            {
                name: 'Mobile',
                value: 'Mobile',
            },
            {
                name: 'Tablet',
                value: 'Tablet',
            },
        ],
    }),
    createFilterDefinition({
        type: 'enum<boolean>',
        operand: 'RecordingSubmitted',
        name: 'Recording',
        operators: [{ name: 'is', value: 'equals' }],
        values: [
            {
                name: 'Yes',
                value: true,
            },
            {
                name: 'No',
                value: false,
            },
        ],
    }),
    createFilterDefinition({
        type: 'date',
        name: 'Created Date',
        operand: 'CreatedAt',
        operators: [
            { name: 'before', value: 'before' },
            { name: 'after', value: 'after' },
        ],
    }),
    createFilterDefinition({
        type: 'enum<string>',
        operand: 'ParticipantSource',
        name: 'Source',
        operators: [
            { name: 'is', value: 'equals' },
            { name: 'is not', value: 'not_equals' },
        ],
        values: [
            {
                name: 'Rally',
                value: 'Rally',
            },
            {
                name: 'Enhanced',
                value: 'Enhanced',
            },
            {
                name: 'Enhanced B2B',
                value: 'Enhanced B2B',
            },
            {
                name: 'Standard',
                value: 'Standard',
            },
            {
                name: 'Share link',
                value: 'Share',
            },
        ],
        allowsMultiple: true,
    }),
    createFilterDefinition({
        type: 'string',
        operand: 'ParticipantId',
        name: 'Participant ID',
        operators: [
            {
                name: 'contains',
                value: 'contains',
            },
        ],
    }),
    createFilterDefinition({
        type: 'string',
        operand: 'StudyId',
        name: 'Study ID',
        operators: [
            {
                name: 'contains',
                value: 'contains',
            },
        ],
    }),
];
export function getGeneralFilters() {
    return generalFilters;
}
// TODO: general filters could be defined in the filter definition, once
// there is a 1:1 mapping between filter definitions and columns.
// For example, the `Status` filter doesn't have a corresponding column.
export function getFilterDefinitions(columns) {
    return [
        ...generalFilters,
        ...columns.flatMap((column) => { var _a, _b; return (_b = (_a = column.meta) === null || _a === void 0 ? void 0 : _a.filters) !== null && _b !== void 0 ? _b : []; }),
    ];
}
export function getFiltersForStep(step) {
    const flatStepClass = StepUtils.getFlatStepClass(step);
    switch (flatStepClass) {
        case FlatStepClass.YesOrNo:
        case FlatStepClass.LegalStep:
            return [
                createBooleanFilterDefinition({
                    stepUUID: step.uuid,
                    operatorValue: 'equals',
                }),
            ];
        case FlatStepClass.MultipleChoice:
        case FlatStepClass.PreferenceTest: {
            let values = [];
            if (flatStepClass === FlatStepClass.MultipleChoice) {
                const { choiceDefinitions, choices } = getFlatStep(step, flatStepClass);
                values = choices
                    .map((value) => ({
                    name: choiceDefinitions[value] || '',
                    value,
                }))
                    .filter(isFilterNameDefined);
            }
            if (flatStepClass === FlatStepClass.PreferenceTest) {
                const { choiceDefinitions, choices } = getFlatStep(step, flatStepClass);
                values = choices.map((value, index) => ({
                    name: choiceDefinitions[value].choiceTitle || `Image ${index + 1}`,
                    value,
                }));
            }
            return [
                createFilterDefinition({
                    type: 'enum<string>',
                    operand: step.uuid,
                    stepUUID: step.uuid,
                    operators: [
                        { name: 'is', value: 'equals' },
                        { name: 'is not', value: 'not_equals' },
                    ],
                    values,
                    allowsMultiple: true,
                }),
            ];
        }
        case FlatStepClass.FirstClickStep:
            return [
                createDurationFilter({
                    stepUUID: step.uuid,
                    name: 'Time to click',
                }),
            ];
        case FlatStepClass.FreeRoamStep:
        case FlatStepClass.GoalBasedStep: {
            const filters = [
                createDurationFilter({
                    stepUUID: step.uuid,
                }),
            ];
            if (flatStepClass === FlatStepClass.GoalBasedStep) {
                filters.push(createBooleanFilterDefinition({
                    stepUUID: step.uuid,
                    name: 'Goal Hit',
                    operatorValue: 'goal_hit',
                }));
            }
            return filters;
        }
        case FlatStepClass.QuestionDate:
            return [
                createDateFilterDefinition({
                    stepUUID: step.uuid,
                }),
            ];
        case FlatStepClass.CardSortingClosed:
        case FlatStepClass.CardSortingHybrid:
        case FlatStepClass.CardSortingOpen:
        case FlatStepClass.QuestionNumerical:
            return [
                createNumericFilterDefinition({
                    stepUUID: step.uuid,
                }),
            ];
        case FlatStepClass.QuestionEmail:
        case FlatStepClass.QuestionText:
            return [
                createStringFilterDefinition({
                    stepUUID: step.uuid,
                }),
            ];
        case FlatStepClass.RatingScale:
            return [
                createNumericFilterDefinition({
                    stepUUID: step.uuid,
                    withEquals: true,
                    inputPlaceholder: 'Enter a value (e.g., 5)',
                }),
            ];
        case FlatStepClass.TaglineCopyTest: {
            const { choiceDefinitions, choices } = getFlatStep(step, flatStepClass);
            return [
                createFilterDefinition({
                    type: 'enum<string>',
                    stepUUID: step.uuid,
                    operand: step.uuid,
                    operators: [
                        { name: 'is', value: 'equals' },
                        { name: 'is not', value: 'not_equals' },
                    ],
                    values: choices
                        .map((value) => ({
                        name: choiceDefinitions[value].heading ||
                            TextUtils.richTextToString(choiceDefinitions[value].paragraph) ||
                            '',
                        value,
                    }))
                        .filter(isFilterNameDefined),
                    allowsMultiple: true,
                }),
            ];
        }
        case FlatStepClass.WebsiteTask:
            return [
                createDurationFilter({
                    stepUUID: step.uuid,
                }),
            ];
        case FlatStepClass.FiveSecondTest:
        case FlatStepClass.MediaSettings:
        case FlatStepClass.Instruction:
            return [];
        default: {
            // eslint-disable-next-line no-console
            console.error('Unknown step type', flatStepClass);
            return [];
        }
    }
}
function createBooleanFilterDefinition({ stepUUID, name, labels = ['Yes', 'No'], operatorValue, }) {
    return createFilterDefinition({
        type: 'enum<boolean>',
        name,
        operand: stepUUID,
        stepUUID,
        operators: [{ name: 'is', value: operatorValue }],
        values: [
            {
                name: labels[0],
                value: true,
            },
            {
                name: labels[1],
                value: false,
            },
        ],
    });
}
function createNumericFilterDefinition(_a) {
    var { stepUUID, name, prefix = '', withEquals = true, type = 'number' } = _a, otherArgs = __rest(_a, ["stepUUID", "name", "prefix", "withEquals", "type"]);
    if (prefix) {
        // eslint-disable-next-line no-param-reassign
        prefix = `${prefix}_`;
    }
    const filterDefinition = Object.assign({ type,
        name, operand: stepUUID, stepUUID, operators: [
            {
                name: 'less than or equals',
                value: `${prefix}less_than_or_equals`,
            },
            {
                name: 'greater than or equals',
                value: `${prefix}greater_than_or_equals`,
            },
            {
                name: 'less than',
                value: `${prefix}less_than`,
            },
            {
                name: 'greater than',
                value: `${prefix}greater_than`,
            },
        ] }, otherArgs);
    if (withEquals) {
        filterDefinition.operators.unshift({
            name: 'equals',
            value: `${prefix}equals`,
        });
        filterDefinition.operators.unshift({
            name: 'does not equal',
            value: `${prefix}not_equals`,
        });
    }
    return createFilterDefinition(filterDefinition);
}
function createDateFilterDefinition({ stepUUID, name, prefix = '', }) {
    if (prefix) {
        // eslint-disable-next-line no-param-reassign
        prefix = `${prefix}_`;
    }
    return createFilterDefinition({
        type: 'date',
        name,
        operand: stepUUID,
        stepUUID,
        operators: [
            {
                name: 'equals',
                value: `${prefix}equals`,
            },
            {
                name: 'does not equal',
                value: `${prefix}not_equals`,
            },
            {
                name: 'less than or equals',
                value: `${prefix}less_than_or_equals`,
            },
            {
                name: 'greater than or equals',
                value: `${prefix}greater_than_or_equals`,
            },
            {
                name: 'less than',
                value: `${prefix}less_than`,
            },
            {
                name: 'greater than',
                value: `${prefix}greater_than`,
            },
        ],
    });
}
function createStringFilterDefinition(_a) {
    var { stepUUID, name, prefix = '' } = _a, otherArgs = __rest(_a, ["stepUUID", "name", "prefix"]);
    if (prefix) {
        // eslint-disable-next-line no-param-reassign
        prefix = `${prefix}_`;
    }
    return createFilterDefinition(Object.assign({ type: 'string', stepUUID,
        name, operand: stepUUID, operators: [
            {
                name: 'contains',
                value: `${prefix}contains`,
            },
            {
                name: 'does not contain',
                value: `${prefix}not_contains`,
            },
        ] }, otherArgs));
}
function createDurationFilter({ stepUUID, name = 'Duration', }) {
    return createNumericFilterDefinition({
        stepUUID,
        name,
        prefix: 'duration',
        type: 'duration',
        inputPlaceholder: 'Enter a value as seconds (e.g., 10)',
        withEquals: false,
    });
}
function createFilterDefinition(filterDef) {
    const id = hashCode(JSON.stringify(filterDef));
    // The ID generation has to be deterministic, since it's used to identify
    // filters that persist in local storage and the URL. The ID is generated
    // by creating a hash code from the JSON string representation of the filter.
    // This ensures that the same filter will always produce the same ID.
    return Object.assign({ id: id.toString() }, filterDef);
}
/**
 * Returns a hash code from a string
 * @param  {String} str The string to hash.
 * @return {Number}    A 32bit integer
 * @see https://stackoverflow.com/a/8831937/63011
 */
function hashCode(str) {
    /* eslint-disable no-bitwise */
    let hash = 0;
    for (let i = 0, len = str.length; i < len; i++) {
        const chr = str.charCodeAt(i);
        hash = (hash << 5) - hash + chr;
        hash |= 0; // Convert to 32bit integer
    }
    return hash;
    /* eslint-enable no-bitwise */
}
export function isFilterDefined(filter) {
    return (!isNil(filter.definitionId) &&
        !isNil(filter.value) &&
        !isNil(filter.operator) &&
        !isNil(filter.operand));
}
export function validateFilter(filter, filterDefinition) {
    if (!filterDefinition) {
        return false;
    }
    if (!isFilterDefined(filter)) {
        return false;
    }
    if (filterDefinition.values &&
        !filterDefinition.values
            .map((val) => val.value)
            .includes(filter.value)) {
        return false;
    }
    return (filter.definitionId === filterDefinition.id &&
        filter.operand === filterDefinition.operand &&
        validateFilterValue(filter.value, filterDefinition.type) &&
        filterDefinition.operators.map((op) => op.value).includes(filter.operator));
}
function validateFilterValue(value, type) {
    switch (type) {
        case 'date':
        case 'datetime':
        case 'number':
        case 'duration':
        case 'enum<number>':
            return isFinite(value);
        case 'enum<string>':
        case 'string':
            return isString(value);
        case 'enum<boolean>':
            return isBoolean(value);
        default:
            // eslint-disable-next-line no-console
            console.error('Unknown filter type', type);
            return false;
    }
}
function isFilterNameDefined(filterValue) {
    return filterValue.name.trim() !== '';
}
