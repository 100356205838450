import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { observer } from 'mobx-react';
import { BtwLink, BtwMessage } from '@marvelapp/ballpark-components';
import { TROUBLESHOOTING_FIGMA_ISSUES } from '@marvelapp/ballpark-help';
import { WarningIcon } from '@marvelapp/ballpark-icons';
export const FigmaSizeWarning = observer(function FigmaSizeWarning({ fileSize, }) {
    const fileSizeInMb = fileSize / 1000000;
    const displayFileSize = fileSizeInMb < 1 ? fileSizeInMb.toFixed(2) : Math.round(fileSizeInMb);
    return (_jsxs(BtwMessage, { className: "w-[480px]", "data-testid": "figma-permissions-error", leadingIcon: _jsx(WarningIcon, {}), variant: "red", children: ["Your Figma file is large (", displayFileSize, "MB) and will increase load time.", ' ', _jsx(BtwLink.External, { "data-testid": "figma-size-link", href: TROUBLESHOOTING_FIGMA_ISSUES, target: "_blank", variant: "current", children: "Learn how to optimise your file here" })] }));
});
