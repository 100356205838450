import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import { observer } from 'mobx-react';
import * as React from 'react';
import { Heading, Text } from '@marvelapp/ballpark-components';
import styled from '@marvelapp/styled';
import { Box } from '@marvelapp/ui';
// Compact stat box (for responses table view)
const compactStatBoxKinds = {
    grass: {
        color: 'grass11',
    },
    tomato: {
        color: 'tomato11',
    },
    slate: {
        color: 'slate12',
    },
};
export const CompactStatBox = observer(function CompactStatBox({ stat, title, kind, testId, }) {
    return (_jsxs("div", { children: [_jsxs(Text, { size: "s", display: "block", children: [title, ":"] }), _jsx(Heading, { "data-testid": testId, size: "s", color: compactStatBoxKinds[kind].color, lineHeight: "body", children: stat })] }));
});
// Stat box (default)
const statBoxKinds = {
    grass: {
        bg: 'grass2',
        color: 'grass12',
        borderColor: 'grass6',
    },
    tomato: {
        bg: 'tomato2',
        color: 'tomato12',
        borderColor: 'tomato6',
    },
    amber: {
        bg: 'amber2',
        color: 'amber12',
        borderColor: 'amber6',
    },
    slate: {
        bg: 'slate2',
        color: 'slate12',
        borderColor: 'slate6',
    },
};
export default observer(function StatBox({ kind, stat, subtitle, title, testId, }) {
    return (_jsxs(BigStat, Object.assign({ border: "1px solid" }, statBoxKinds[kind], { "data-testid": testId, children: [_jsx(Text, { minHeight: "20px", size: "s", color: "currentColor", textAlign: "left", children: title }), _jsx(Heading, { size: "xxl", color: "currentColor", my: "s", textAlign: "center", testId: "stat", children: stat }), _jsx(Text, { minHeight: "20px", size: "s", color: "currentColor", textAlign: "center", "data-testid": "subtitle", children: subtitle })] })));
});
const BigStat = styled(Box).attrs({
    borderRadius: 'm',
    padding: 'xs',
}) ``;
