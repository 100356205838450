const DESKTOP_DISPLAY = {
    width: 2560,
    height: 1440,
};
export const MAX_DECORATIVE_IMAGE_DIMENSIONS_DESKTOP = {
    width: DESKTOP_DISPLAY.width / 2,
    height: DESKTOP_DISPLAY.height,
};
// TODO not sure about this -- eg pixel 6 is 1080p (but then dpi handles that?)
export const MAX_DECORATIVE_IMAGE_DIMENSIONS_MOBILE = {
    width: 600,
    height: 600,
};
export const MAX_DECORATIVE_IMAGE_DIMENSIONS_MOBILE_ZOOMED = {
    width: 1200,
    height: 1200,
};
export const MAX_DECORATIVE_IMAGE_DIMENSIONS_IN_REPORTING = {
    width: 600,
    height: 700,
};
export const MAX_DECORATIVE_IMAGE_DIMENSIONS_IN_REPORTING_ZOOMED = {
    width: DESKTOP_DISPLAY.width,
};
