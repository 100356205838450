import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { observer } from 'mobx-react';
import * as React from 'react';
import { useAction } from '@marvelapp/ballpark-application';
import { Text } from '@marvelapp/ballpark-components';
import styled from '@marvelapp/styled';
import { TextEditor } from '@marvelapp/text-editor';
import { Box } from '@marvelapp/ui';
import { TaglineCopyStepUtils, TaglineStepResponseUtils, TextUtils, } from '@marvelapp/user-test-creator';
import InteractionBlocker from '../../components/InteractionBlocker';
import Checkbox from '../common/Checkbox';
export default observer(function TaglineTestRecordGridItem({ index, optionNumber, step, stepResponse, uuid, }) {
    const isChecked = TaglineStepResponseUtils.getAnswer(stepResponse).includes(uuid);
    const multipleSelectionMode = TaglineCopyStepUtils.getMultipleSelectionMode(step);
    const numberOfSelectedAnswers = TaglineStepResponseUtils.getAnswer(stepResponse).length;
    const [_, upperRangeLimit] = TaglineCopyStepUtils.getSelectionRange(step);
    const hasSelectedMaxOptions = !!(multipleSelectionMode &&
        upperRangeLimit &&
        numberOfSelectedAnswers >= upperRangeLimit);
    const toggleCheckbox = useAction(() => TaglineStepResponseUtils.updateChoices(stepResponse, uuid, isChecked, multipleSelectionMode), [stepResponse, uuid, isChecked, multipleSelectionMode]);
    const headingValue = step.choiceDefinitions[uuid].heading;
    const paragraphValue = step.choiceDefinitions[uuid].paragraph;
    return (_jsx(TaglineTestGridItem, { headingValue: headingValue, index: index, id: uuid, isChecked: isChecked, onChange: toggleCheckbox, optionNumber: optionNumber, paragraphValue: paragraphValue, readOnly: hasSelectedMaxOptions && !isChecked }));
});
export const TaglineTestGridItem = observer(function TaglineTestGridItem({ headingValue, id, index, isChecked, onChange, optionNumber, paragraphValue, readOnly, }) {
    const hasParagraph = !TextUtils.checkIfValueIsEmpty(paragraphValue);
    return (_jsx(Checkbox, { checked: isChecked, id: id, onChange: onChange, optionNumber: optionNumber, readOnly: readOnly, hasNestedElements: true, children: _jsx(InteractionBlocker, { children: _jsxs(Body, { children: [headingValue && (_jsx(Text, { color: "slate12", fontWeight: "600", size: "xl", lineHeight: 1.2, as: "p", children: headingValue })), headingValue && hasParagraph && _jsx(Box, {}), hasParagraph && (_jsx(TextEditor, { "data-testid": `tagline-choice-paragraph-${index}`, size: "base", id: `${id}-paragraph`, placeholder: "Some paragraph text", value: paragraphValue, disabled: true }))] }) }) }));
});
const Body = styled(Box).attrs({
    marginTop: 0,
    marginY: 's',
    px: 'm',
    py: 's',
}) `
  font-weight: initial;
  text-align: left;
  > div {
    width: 100%;
  }
`;
