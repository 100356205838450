import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { observer } from 'mobx-react';
import { useCallback, useState } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { usePermissions } from '@marvelapp/ballpark-application';
import { BtwButton, BtwDropdown, BtwLink, BtwModal, Stack, cn, } from '@marvelapp/ballpark-components';
import { ChevronBottomIcon, FlagIcon, TrashCanIcon, } from '@marvelapp/ballpark-icons';
import { ConfirmDelete } from '../Toolbar/ConfirmDelete';
import { updateCache, useDeleteResults } from '../Toolbar/DeleteResultsAction';
import { ReportResponse } from './ReportResponse';
import { useGetOrderForResponse } from './useGetOrderForResponse';
export const IndividualResultActions = observer(function IndividualResultActions({ projectPk, resultPk, resultUUID, }) {
    const [modalView, setModalView] = useState(null);
    const permissions = usePermissions();
    const canEditResults = permissions.canEditUserTestResults();
    const { getOrderForResponse, isLoadingOrder, order } = useGetOrderForResponse(resultUUID);
    const openReportResponses = useCallback(() => {
        getOrderForResponse();
        setModalView('REPORT_RESPONSE');
    }, [getOrderForResponse]);
    const openConfirmDeleteResults = useCallback(() => {
        setModalView('CONFIRM_DELETE_RESPONSE');
    }, []);
    const closeModal = useCallback((shouldOpen) => {
        if (!shouldOpen)
            setModalView(null);
    }, []);
    return (_jsxs(BtwModal.Root, { open: modalView !== null, onOpenChange: closeModal, children: [_jsxs(Stack, { direction: "row", className: cn({
                    // Adding the hover and pressed effects to the parent when it's a split button.
                    // This should probably be componentized in the future.
                    'ease-smooth transition-transform duration-300 hover:-translate-y-px has-[:active]:translate-y-px': canEditResults,
                }), children: [_jsx(BtwLink, { "data-testid": "view-response", target: "_blank", href: `/project/${projectPk}/response/${resultPk}`, underline: false, className: "has-[:focus]:z-10", children: _jsx(BtwButton, { className: cn({
                                // Removing rounded corners on the right and hover/pressed transition
                                'rounded-br-none rounded-tr-none hover:translate-y-0 active:translate-y-0': canEditResults,
                            }), size: "sm", "data-testid": "external-button", children: "View" }) }), canEditResults && (_jsxs(BtwDropdown.Root, { children: [_jsx(BtwDropdown.Trigger
                            // Removing rounded corners on the left and hover/pressed transition
                            , { 
                                // Removing rounded corners on the left and hover/pressed transition
                                className: "w-7 rounded-bl-none rounded-tl-none hover:translate-y-0 active:translate-y-0", "data-testid": "individual-result-actions-trigger", size: "sm", standaloneIcon: _jsx(ChevronBottomIcon, {}) }), _jsx(BtwDropdown.Content, { children: _jsxs(BtwDropdown.Stack, { children: [_jsx(BtwModal.Trigger, { asChild: true, children: _jsx(BtwDropdown.Item, { "data-testid": "report-response", leadingIcon: _jsx(FlagIcon, {}), onSelect: openReportResponses, size: "intermediate", children: "Report response" }) }), _jsx(BtwModal.Trigger, { asChild: true, children: _jsx(BtwDropdown.Item, { "data-testid": "delete-response", leadingIcon: _jsx(TrashCanIcon, {}), onSelect: openConfirmDeleteResults, size: "intermediate", children: "Delete response" }) })] }) })] }))] }), _jsx(ActionModal, { isLoadingOrder: isLoadingOrder, modalView: modalView, order: order, resultPk: resultPk, resultUUID: resultUUID, setModalView: setModalView })] }));
});
const ActionModal = observer(function ActionModal({ isLoadingOrder, modalView, order, resultPk, resultUUID, setModalView, }) {
    const match = useRouteMatch();
    const { id } = match.params;
    const { deleteResults } = useDeleteResults({
        selectedResultsPks: [resultPk],
    });
    const onDelete = useCallback(() => {
        setModalView(null);
        deleteResults({
            update(cache, { data }) {
                updateCache({
                    cache,
                    data,
                    variables: {
                        pk: Number(id),
                    },
                });
            },
        });
    }, [deleteResults, id, setModalView]);
    const showConfirmDeleteModal = modalView === 'CONFIRM_DELETE_RESPONSE';
    return (_jsx(BtwModal.Content, { "data-testid": showConfirmDeleteModal
            ? 'confirm-delete-response-modal'
            : 'report-response-modal', hasPortal: true, children: _jsx(Stack, { align: "stretch", gap: "4", width: "full", children: showConfirmDeleteModal ? (_jsx(ConfirmDelete, { onDelete: onDelete, selectedResultsCount: 1 })) : (_jsx(ReportResponse, { isLoadingOrder: isLoadingOrder, modalView: modalView, order: order, resultPk: resultPk, resultUUID: resultUUID, setModalView: setModalView })) }) }));
});
