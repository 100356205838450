import { __rest } from "tslib";
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { observer } from 'mobx-react';
import { useState } from 'react';
import * as React from 'react';
import styled from '@marvelapp/styled';
import { Box } from '@marvelapp/ui';
import { StepClass, StepUtils } from '@marvelapp/user-test-creator';
import ZoomImageModal from '../../../components/ZoomImageModal';
import { useStepContext } from '../../../utils/stepContext';
import { SummaryStep } from './SummaryStep';
export default observer(function SummaryFiveSecond(_a) {
    var { active } = _a, rest = __rest(_a, ["active"]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const { step } = useStepContext();
    if (!StepUtils.isOfType(step, StepClass.FiveSecondTest))
        return null;
    const { imageUrl } = step;
    return (_jsx(SummaryStep, Object.assign({ active: active }, rest, { children: imageUrl && (_jsxs(_Fragment, { children: [_jsx(Body, { onClick: () => setIsModalOpen(true), children: _jsx(Image, { as: "img", src: imageUrl }) }), _jsx(ZoomImageModal, { image: imageUrl, image2x: imageUrl, isOpen: isModalOpen, onClose: () => setIsModalOpen(false) })] })) })));
});
const Body = styled(Box).attrs({
    bg: 'slate2',
    borderRadius: 'l',
    hoverBg: 'slate3',
    padding: 'l',
    transition: 'smooth.fast',
}) `
  display: flex;
  align-items: center;
  justify-content: center;
  user-select: none;
  cursor: pointer;

  &:hover {
    transform: translate3d(0, -1px, 0);
  }

  &:active {
    transform: translate3d(0, 1px, 0);
  }
`;
const Image = styled(Box).attrs({
    borderRadius: 'm',
    boxShadow: 'button.secondary',
}) `
  display: block;
  max-width: 170px;
`;
