import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { get } from 'lodash-es';
import { observer } from 'mobx-react';
import { BtwHeading, Stack, cn } from '@marvelapp/ballpark-components';
import { Highlighter } from '@marvelapp/ui-internal';
import { CroppedScreen } from '../CroppedScreen';
import FigmaScreenPlaceholder from './FigmaScreenPlaceholder';
export const Screen = observer(function Screen({ aspectRatio, disabled = false, isActive, isWaitingOnFigma, onSelectScreen, screenData, searchTerm, }) {
    const { pk, displayName, externalId } = screenData;
    const image = get(screenData, 'content');
    return (_jsx(Stack, { align: "stretch", className: cn({ 'opacity-50': disabled }), "data-testid": `screen-${externalId || pk}`, onClick: () => !disabled && onSelectScreen(pk), width: "full", children: isWaitingOnFigma ? (_jsx(FigmaScreenPlaceholder, { borderRadius: 2, ratio: aspectRatio, searchWords: [searchTerm], title: displayName })) : (_jsxs(Stack, { width: "full", gap: "3", children: [_jsx(CroppedScreen, { aspectRatio: aspectRatio, disabled: disabled, image: image, isActive: isActive }), _jsx(BtwHeading, { size: "xs", children: _jsx(Highlighter, { searchWords: [searchTerm], testId: "prototype-task-screen-picker-screen", textToHighlight: displayName }) })] })) }));
});
