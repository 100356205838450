import { UI_COMMON_STEPS_COPY, UI_STEPS_COPY } from '@marvelapp/ballpark-copy';
import { FixedStep, STEPS_COPY, StepClass, getStepType, isExitStep, isMediaSettingsStepCreatedBeforeTheEditableVersionWasReleased, isWelcomeStep, } from '@marvelapp/user-test-creator';
export function getStepTitleCopy(userTest, step) {
    if (isMediaSettingsStepCreatedBeforeTheEditableVersionWasReleased(step)) {
        return STEPS_COPY[StepClass.MediaSettings].defaultTitle;
    }
    let { titlePlaceholder } = UI_COMMON_STEPS_COPY;
    if (isWelcomeStep(userTest, step.uuid)) {
        // eslint-disable-next-line
        titlePlaceholder = UI_STEPS_COPY[FixedStep.Welcome].titlePlaceholder;
    }
    else if (isExitStep(userTest, step.uuid)) {
        // eslint-disable-next-line
        titlePlaceholder = UI_STEPS_COPY[FixedStep.Exit].titlePlaceholder;
    }
    const stepType = getStepType(step);
    const stepCopy = STEPS_COPY[stepType];
    if (stepCopy && 'defaultTitle' in stepCopy) {
        if (step.title === stepCopy.defaultTitle.defaultMessage)
            return stepCopy.defaultTitle;
    }
    // If the title is the default placeholder, return the default placeholder as it is localized
    if (step.title === titlePlaceholder.defaultMessage) {
        return titlePlaceholder;
    }
    return step.title || titlePlaceholder;
}
