import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { NetworkStatus, gql, useQuery } from '@apollo/client';
import { useVirtualizer } from '@tanstack/react-virtual';
import { action } from 'mobx';
import { observer } from 'mobx-react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import * as React from 'react';
import { useInfiniteQuery } from '@marvelapp/ballpark-application';
import { Badge, BtwInput, BtwText, Combobox, Stack, cn, } from '@marvelapp/ballpark-components';
import { ListSearchIcon } from '@marvelapp/ballpark-icons';
import { pluralize } from '@marvelapp/core';
import { useDebouncedState } from '@marvelapp/hooks';
import { showGenericServerError } from '@marvelapp/ui-internal';
import { useRecruitmentState } from './RecruitmentContext';
import { filterOption, flattenOptions, } from './useGetRecruitmentData';
import { useUpdateSelectedFilterOptions } from './utils';
const getPaginatedFilter = gql `
  query getPaginatedFilter(
    $filterId: String!
    $searchTerm: String
    $studyType: StudyTypeEnum!
    $cursor: String
  ) {
    paginatedFilter(
      filterId: $filterId
      studyType: $studyType
      searchTerm: $searchTerm
    ) {
      optionCount
      id
      name
      options(first: 25, after: $cursor) {
        pageInfo {
          hasNextPage
          endCursor
        }
        edges {
          node {
            ...option
          }
        }
      }
    }
  }
  ${filterOption}
`;
const VirtualizedList = observer(function VirtualizedList({ listRef, hasNextPage, items, isFetchingMore, onLoadMore, itemHeight = 36, renderItem, loader = _jsx("div", { children: "Loading..." }), totalItemsCount, }) {
    const virtualizer = useVirtualizer({
        count: totalItemsCount,
        // estimated size of each row in px
        estimateSize: () => 36,
        getScrollElement: () => listRef,
        overscan: 5,
    });
    const virtualItems = virtualizer.getVirtualItems();
    useEffect(() => {
        const [lastItem] = [...virtualItems].reverse();
        if (!lastItem) {
            return;
        }
        if (lastItem.index >= items.length - 1 && hasNextPage && !isFetchingMore) {
            if (typeof onLoadMore === 'function')
                onLoadMore();
        }
    }, [
        hasNextPage,
        onLoadMore,
        isFetchingMore,
        virtualizer,
        items,
        virtualItems,
    ]);
    return (_jsx("div", { style: {
            height: `${virtualizer.getTotalSize()}px`,
            width: '100%',
            position: 'relative',
        }, children: virtualItems.map((virtualItem) => {
            const item = items[virtualItem.index];
            return (_jsx("div", { className: "absolute left-0 top-0 w-full", style: {
                    height: `${itemHeight}px`,
                    transform: `translateY(${virtualItem.start}px)`,
                }, children: item ? renderItem(item, virtualItem.index) : loader }, virtualItem.key));
        }) }));
});
const VirtualizedComboboxList = observer(function VirtualizedComboboxList({ filterGroupId, filterId, listRef, data, fetchMore, networkStatus, }) {
    var _a, _b, _c, _d;
    const { getFilterByGroupAndId, getSelectedOptionsByFilterAndGroupId } = useRecruitmentState();
    const paginatedListItems = ((_a = data.paginatedFilter) === null || _a === void 0 ? void 0 : _a.options)
        ? flattenOptions((_b = data.paginatedFilter) === null || _b === void 0 ? void 0 : _b.options)
        : [];
    const { fetchNextPage, hasNextPage } = useInfiniteQuery({
        data,
        fetchMore,
        getDataConnection: (data) => { var _a; return (_a = data === null || data === void 0 ? void 0 : data.paginatedFilter) === null || _a === void 0 ? void 0 : _a.options; },
        isFetchingMore: networkStatus === NetworkStatus.fetchMore,
    });
    const selectedOptions = getSelectedOptionsByFilterAndGroupId(filterId, filterGroupId, true);
    const selectedIds = useMemo(() => selectedOptions.map((option) => option.id), [selectedOptions]);
    const updateFilterOptions = useUpdateSelectedFilterOptions(filterGroupId, filterId);
    const filter = getFilterByGroupAndId(filterGroupId, filterId);
    const renderItem = useCallback((item) => {
        const isSelected = selectedIds.includes(item.id);
        const isDisabled = !!filter.maxSelection &&
            !isSelected &&
            selectedOptions.length >= filter.maxSelection;
        return (_jsx(Combobox.Item, { check: "checkbox", "data-testid": `combobox-option-${item.id}`, disabled: isDisabled, onSelect: action(() => updateFilterOptions(Object.assign(Object.assign({ filterId: null }, item), { groupId: filterGroupId, customValue: null }))), selected: isSelected, value: item.name, className: cn(`h-9 w-full`), children: item.name }));
    }, [
        filter,
        filterGroupId,
        selectedIds,
        selectedOptions.length,
        updateFilterOptions,
    ]);
    return (_jsx(VirtualizedList, { loader: _jsx(Combobox.LoadingState, {}), totalItemsCount: (_d = (_c = data.paginatedFilter) === null || _c === void 0 ? void 0 : _c.optionCount) !== null && _d !== void 0 ? _d : 0, items: paginatedListItems, listRef: listRef, renderItem: renderItem, onLoadMore: fetchNextPage, hasNextPage: hasNextPage, isFetchingMore: networkStatus === NetworkStatus.fetchMore, filterGroupId: filterGroupId, filterId: filterId, selectedOptions: selectedOptions }));
});
export const ComboboxFilterWithVirtualList = observer(function ComboboxFilterWithVirtualList({ filterGroupId, filterId, }) {
    const { studyType, getFilterByGroupAndId } = useRecruitmentState();
    const [listRef, setListRef] = useState(null);
    const [debouncedValue, setValue, latestValue] = useDebouncedState('', {
        delay: 500,
    });
    const filter = getFilterByGroupAndId(filterGroupId, filterId);
    const { data, loading: isLoading, networkStatus, fetchMore, } = useQuery(getPaginatedFilter, {
        variables: {
            filterId,
            searchTerm: debouncedValue,
            studyType,
        },
        onError() {
            showGenericServerError();
        },
        skip: !debouncedValue,
    });
    const handleSearchChange = useCallback((e) => {
        setValue(e.target.value || '');
    }, [setValue]);
    const hasPendingValue = isLoading || latestValue !== debouncedValue;
    return (_jsxs(Combobox.Content, { modal: true, listRef: setListRef, shouldFilter: false, headerInput: _jsx(BtwInput.Field, { id: "search", value: latestValue, onChange: handleSearchChange, placeholder: `Search for ${pluralize(2, filter.name).toLowerCase()}` }), onCloseAutoFocus: () => setValue(''), children: [_jsx(Combobox.Empty, { children: hasPendingValue ? (_jsx(Combobox.LoadingState, {})) : (_jsx(EmptyState, { filterGroupId: filterGroupId, filterId: filterId, searchTerm: latestValue })) }), data && (_jsx(VirtualizedComboboxList, { data: data, fetchMore: fetchMore, listRef: listRef, filterGroupId: filterGroupId, filterId: filterId, networkStatus: networkStatus }))] }));
});
const EmptyState = observer(function EmptyState({ searchTerm, filterGroupId, filterId, }) {
    const { getFilterByGroupAndId } = useRecruitmentState();
    const filter = getFilterByGroupAndId(filterGroupId, filterId);
    return (_jsx(_Fragment, { children: !searchTerm ? (_jsx(_Fragment, { children: _jsxs(Stack, { gap: "1", align: "center", children: [_jsx(ListSearchIcon, { className: "inline-block" }), _jsxs(BtwText, { weight: "semibold", size: "13", children: ["Search for ", pluralize(2, filter.name).toLowerCase()] }), _jsxs(Stack, { gap: "2", align: "center", children: [_jsxs(BtwText, { size: "xs", children: ["Search across our database of ", filter.optionCount, ' ', pluralize(2, filter.name).toLowerCase(), ". Here are some examples:"] }), _jsx(Stack, { direction: "row", gap: "1", wrap: "wrap", justify: "center", children: examples[filter.id].map((exampleText) => (_jsx(Badge, { "data-testid": "selected-option", children: exampleText }, exampleText))) })] })] }) })) : (_jsxs(BtwText, { className: "pt-2", children: ["No results matching ", searchTerm] })) }));
});
const examples = {
    FILTER_SKILLS: ['Figma', 'Excel', 'Adobe', 'Leadership'],
    FILTER_JOB_TITLES: ['Accountant', 'Marketing Admin', 'UX designer'],
};
