import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { observer } from 'mobx-react';
import { useState } from 'react';
import * as React from 'react';
import { BtwInput as Input } from '@marvelapp/ballpark-components';
import { CollectionOperator, IdentityOperator, } from '@marvelapp/user-test-creator';
import { updateRequirement } from '../../../utils';
import { MultiConditionLogic, OperatorSelect, } from '../shared/multiConditionLogic';
const TEXT_OPERATORS = [
    [IdentityOperator.Is, 'is equal to'],
    [IdentityOperator.IsNot, 'is not equal to'],
    [CollectionOperator.Contains, 'contains'],
    [CollectionOperator.DoesNotContain, 'does not contain'],
];
export function createDefaultRequirement(combinator) {
    return {
        operator: IdentityOperator.Is,
        combinator,
        value: '',
    };
}
export function validateRequirement({ value }) {
    return value !== '';
}
export const TextConditionalLogic = observer(function TextConditionalLogic({ step, }) {
    const renderAnswerComponent = ({ ruleIndex, requirementIndex, setValue: setAnswerRule, setOperator, }) => {
        var _a, _b, _c, _d;
        const requirement = (_c = (_b = (_a = step === null || step === void 0 ? void 0 : step.conditions) === null || _a === void 0 ? void 0 : _a.rules) === null || _b === void 0 ? void 0 : _b[ruleIndex]) === null || _c === void 0 ? void 0 : _c.requirements[requirementIndex];
        return (_jsx(TextAnswerSelect, { setOperator: setOperator, savedValue: requirement !== null && requirement !== void 0 ? requirement : null, setRule: setAnswerRule, currentOperator: (_d = requirement === null || requirement === void 0 ? void 0 : requirement.operator) !== null && _d !== void 0 ? _d : IdentityOperator.Is }));
    };
    return (_jsx(MultiConditionLogic, { step: step, createRequirement: createDefaultRequirement, updateRequirement: updateRequirement, validateRequirement: validateRequirement, canAddRequirement: true, renderRequirement: renderAnswerComponent }));
});
const TextAnswerSelect = observer(function TextAnswerSelect({ savedValue, setRule, currentOperator, setOperator, }) {
    var _a;
    const [value, setValue] = useState((_a = savedValue === null || savedValue === void 0 ? void 0 : savedValue.value) !== null && _a !== void 0 ? _a : '');
    return (_jsxs(_Fragment, { children: [_jsx(OperatorSelect, { operator: currentOperator, operatorOptions: TEXT_OPERATORS, setRule: setRule, setOperator: setOperator }), _jsx(Input.Root, { className: "flex-1", children: _jsx(Input.Field, { value: value, onChange: (e) => {
                        const { value } = e.target; // TODO trim?
                        setValue(value !== null && value !== void 0 ? value : '');
                        setRule({
                            value: { value },
                        });
                    }, "data-testid": "text-logic-input", placeholder: "Enter a value\u2026", id: "text-logic-input" }) })] }));
});
