import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { BtwTooltip, Stack } from '@marvelapp/ballpark-components';
export const TruncatedTextContainer = observer(function TruncatedTextContainer({ title, }) {
    const [truncatedItemRef, setTruncatedItemRef] = useState(null);
    const [isTruncated, setIsTruncated] = useState(false);
    useEffect(() => {
        if (!truncatedItemRef)
            return;
        const resizeObserver = new ResizeObserver(() => {
            const { clientWidth, scrollWidth } = truncatedItemRef;
            if (scrollWidth > clientWidth) {
                setIsTruncated(true);
            }
        });
        resizeObserver.observe(truncatedItemRef);
        return () => {
            resizeObserver.disconnect();
        };
    }, [truncatedItemRef]);
    return (_jsx(Stack, { children: _jsxs(BtwTooltip.Root, { delayDuration: 1500, children: [_jsx(BtwTooltip.Trigger, { className: "w-full text-left", children: _jsx("div", { ref: setTruncatedItemRef, className: "min-w-0 truncate leading-4", children: title }) }), isTruncated && (_jsx(BtwTooltip.Portal, { children: _jsx(BtwTooltip.Content, { className: "max-w-[--radix-tooltip-trigger-width] text-center", children: title }) }))] }) }));
});
