import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { observer } from 'mobx-react';
import { useCallback, useState } from 'react';
import { useParams } from 'react-router-dom';
import { createGlobalStyle } from '@marvelapp/styled';
import CreateTestResult from './CreateTestResult';
import Record from './Record';
import { Resume, clearResponseInStorage, getResumableResponseUUID, } from './Resume';
const RecordStyle = createGlobalStyle `
  html, body {
    overscroll-behavior: none;
    height: 100%;
    width: 100%;
    overflow: hidden;
  }
`;
export const RecordFlow = observer(function RecordFlow() {
    const { testId } = useParams();
    const testUUID = `ut_${testId}`;
    const resumableResponseUUID = getResumableResponseUUID(testUUID);
    const [isResuming, setIsResuming] = useState(!!resumableResponseUUID);
    // The initial value for `responseUUID` should only be set if the response is resumable.
    // We could get the responseUUID for pre-seeded documents from the url, but we want them
    // to go through the `CreateTestResult` flow to update the test result with device information.
    const [responseUUID, setResponseUUID] = useState(() => resumableResponseUUID);
    const restart = useCallback(() => {
        clearResponseInStorage(testUUID);
        setResponseUUID(null);
        setIsResuming(false);
    }, [testUUID]);
    const [token, setToken] = useState(null);
    const [partnerData, setPartnerData] = useState(null);
    return (_jsxs(_Fragment, { children: [_jsx(RecordStyle, {}), responseUUID ? (_jsx(Record, { authToken: token, responseUUID: responseUUID, testUUID: testUUID, restart: restart, partnerData: partnerData, children: _jsx(Resume, { isResuming: isResuming, onRestart: restart }) })) : (_jsx(CreateTestResult, { onTestCreate: setResponseUUID, setToken: setToken, testUUID: testUUID, setPartnerData: setPartnerData }))] }));
});
