import { checkIfValueIsEmpty } from '../text/utils';
import { getStepType } from '../userTest/selectors';
import { isOfType } from '../userTest/stepUtils';
import { PrototypeStepType, StepClass } from '../userTest/types';
import { STEPS_COPY } from './steps';
import { localeStringToRichText, localizeIfDefault } from './utils';
// Check if the Media settings step was created before we deployed the new
// editable version. The old title doesn't make sense to show in the recorder
// so we show the default title that's now used when enabling recording. Old
// steps don't have a description so we show the new default description too.
export function isMediaSettingsStepCreatedBeforeTheEditableVersionWasReleased(step) {
    return (isOfType(step, StepClass.MediaSettings) &&
        (step.title === 'Request access to record video' ||
            step.title === 'Request access to record audio') &&
        checkIfValueIsEmpty(step.description));
}
// the localized versions of the placeholders to replace when localizing embedded source texts
// that match the default values
export function getStepLocaleValues(intl, step) {
    const stepType = getStepType(step);
    if (stepType === StepClass.FiveSecondTest) {
        return {
            buttonText: intl.formatMessage(STEPS_COPY[StepClass.FiveSecondTest].buttonText),
        };
    }
    if (stepType === PrototypeStepType.FirstClick) {
        return {
            buttonText: intl.formatMessage(STEPS_COPY[PrototypeStepType.FirstClick].buttonText),
        };
    }
    return undefined;
}
// placeholder values in embedded source texts, e.g., {buttonText} that need to be replaced before
// comparing
export const STEP_LOCALE_PLACEHOLDERS = {
    [StepClass.FiveSecondTest]: {
        buttonText: STEPS_COPY[StepClass.FiveSecondTest].buttonText.defaultMessage,
    },
    [PrototypeStepType.FirstClick]: {
        buttonText: STEPS_COPY[PrototypeStepType.FirstClick].buttonText.defaultMessage,
    },
};
export function getStepDescription(intl, step) {
    var _a;
    if (isMediaSettingsStepCreatedBeforeTheEditableVersionWasReleased(step)) {
        return localeStringToRichText(intl.formatMessage(STEPS_COPY[StepClass.MediaSettings].defaultDescription));
    }
    const stepType = getStepType(step);
    const stepCopy = STEPS_COPY[stepType];
    if (stepCopy && 'defaultDescription' in stepCopy) {
        return localizeIfDefault(intl, step.description, stepCopy.defaultDescription, (_a = STEP_LOCALE_PLACEHOLDERS[stepType]) !== null && _a !== void 0 ? _a : undefined, getStepLocaleValues(intl, step));
    }
    return step.description;
}
