export var EventOutcomeType;
(function (EventOutcomeType) {
    EventOutcomeType["ScreenTransition"] = "screenTransition";
    EventOutcomeType["OverlayTransition"] = "overlayTransition";
    EventOutcomeType["GoalReached"] = "goalReached";
    EventOutcomeType["RemoveOverlay"] = "removeOverlay";
    EventOutcomeType["Scroll"] = "scroll";
    EventOutcomeType["StartRecording"] = "startRecording";
    EventOutcomeType["StopRecording"] = "stopRecording";
    EventOutcomeType["Miss"] = "miss";
    EventOutcomeType["Resize"] = "resize";
})(EventOutcomeType || (EventOutcomeType = {}));
export var EventTriggerType;
(function (EventTriggerType) {
    EventTriggerType["Tap"] = "tap";
    EventTriggerType["Mousemove"] = "mousemove";
})(EventTriggerType || (EventTriggerType = {}));
