import { showErrorNotification, showGenericServerError, showSuccessNotification, } from '../ToastContainer';
const inviteErrorCodes = {
    EMAIL_DOMAIN_NOT_WHITELISTED: 'EMAIL_DOMAIN_NOT_WHITELISTED',
    USER_ALREADY_ON_PAID_SUBSCRIPTION: 'USER_ALREADY_ON_PAID_SUBSCRIPTION',
    USER_BELONGS_ANOTHER_WORKSPACE: 'USER_BELONGS_ANOTHER_WORKSPACE',
    USER_COULD_NOT_BE_ADDED_AGAIN: 'USER_COULD_NOT_BE_ADDED_AGAIN',
    INTEGRITY_ERROR: 'INTEGRITY_ERROR',
};
export function showAddPeopleNotifications({ alreadyOnThisAccountFailures, alreadyOnAnotherPaidAccountFailures, data: { succeeded, error }, domainFailures, entity = 'company', otherFailures, redirect, retry, setEmails, triggerFormError, }) {
    const allFailed = [
        ...domainFailures,
        ...alreadyOnThisAccountFailures,
        ...alreadyOnAnotherPaidAccountFailures,
        ...otherFailures,
    ];
    setEmails(allFailed.map((user) => user.email));
    if (allFailed.length && succeeded.length) {
        showSuccessNotification({
            content: getSuccessMessage(succeeded, entity),
        });
        showErrorNotification({
            autoClose: 8000,
            content: getErrorMessage({
                alreadyOnThisAccountFailures,
                alreadyOnAnotherPaidAccountFailures,
                domainFailures,
                entity,
                otherFailures,
            }),
        });
        triggerFormError();
    }
    else if (allFailed.length) {
        showErrorNotification({
            autoClose: 8000,
            content: getErrorMessage({
                alreadyOnThisAccountFailures,
                alreadyOnAnotherPaidAccountFailures,
                domainFailures,
                entity,
                otherFailures,
            }),
        });
        triggerFormError();
    }
    else if (error) {
        showGenericServerError({
            closeOnClick: true,
            retry,
        });
        triggerFormError();
    }
    else {
        showSuccessNotification({
            content: getSuccessMessage(succeeded, entity),
        });
        redirect();
    }
}
function getSuccessMessage(successfulUsers, entity) {
    const remainderUsersCount = successfulUsers.length - 1;
    return `${successfulUsers[0].email ? successfulUsers[0].email : successfulUsers[0]} ${successfulUsers.length > 1
        ? `and ${remainderUsersCount} other${remainderUsersCount > 1 ? 's' : ' person'} were`
        : 'was'} added to the ${entity}.`;
}
function getErrorMessage({ domainFailures = [], alreadyOnAnotherPaidAccountFailures = [], alreadyOnThisAccountFailures = [], otherFailures = [], entity, }) {
    const getUsersCount = (users) => {
        const othersCount = users.length - 1;
        const othersText = ` and ${othersCount} other${othersCount > 1 ? 's' : ' person'}`;
        return `${users[0].email}${users.length > 1 ? othersText : ''}`;
    };
    if (domainFailures.length) {
        const pluraliseErrorMessage = domainFailures.length > 1 ? "s don't" : " doesn't";
        return `${getUsersCount(domainFailures)} could not be added to your ${entity}. The email domain${pluraliseErrorMessage} match your whitelisted domains.`;
    }
    if (alreadyOnAnotherPaidAccountFailures.length) {
        const pluraliseErrorMessage = alreadyOnAnotherPaidAccountFailures.length > 1 ? 's are' : ' is';
        return `${getUsersCount(alreadyOnAnotherPaidAccountFailures)} could not be added to your ${entity}. The user${pluraliseErrorMessage} already part of another workspace.`;
    }
    if (alreadyOnThisAccountFailures.length) {
        return `${getUsersCount(alreadyOnThisAccountFailures)} already exist on your ${entity} and there was a problem adding them again.`;
    }
    if (otherFailures.length) {
        return `There was an issue adding ${getUsersCount(otherFailures)} to your ${entity}.`;
    }
}
export function sortFailedInvitesByReason(failedUsers) {
    const domainFailures = [];
    const alreadyOnAnotherPaidAccountFailures = [];
    const alreadyOnThisAccountFailures = [];
    const otherFailures = [];
    failedUsers.forEach((failed) => {
        switch (failed.code) {
            case inviteErrorCodes.EMAIL_DOMAIN_NOT_WHITELISTED:
                domainFailures.push(failed);
                break;
            case inviteErrorCodes.USER_BELONGS_ANOTHER_WORKSPACE:
            case inviteErrorCodes.USER_ALREADY_ON_PAID_SUBSCRIPTION:
            case inviteErrorCodes.INTEGRITY_ERROR:
                alreadyOnAnotherPaidAccountFailures.push(failed);
                break;
            case inviteErrorCodes.USER_COULD_NOT_BE_ADDED_AGAIN:
                alreadyOnThisAccountFailures.push(failed);
                break;
            default:
                otherFailures.push(failed);
                break;
        }
    });
    return {
        domainFailures,
        alreadyOnAnotherPaidAccountFailures,
        alreadyOnThisAccountFailures,
        otherFailures,
    };
}
