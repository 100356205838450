import { StepClass } from '../common';
// TODO do we really need a non-response response? I suppose it keeps things
// simple in that the steps array and responses array have corresponding entries
export function create(stepUUID, stepRevision) {
    return {
        className: StepClass.Instruction,
        stepUUID,
        stepRevision,
    };
}
