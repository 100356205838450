import { useState } from 'react';
import { cache } from '@marvelapp/core';
export default function useCachedState(defaultValue, cacheKey) {
    var _a;
    if (typeof cacheKey !== 'string') {
        throw new TypeError('cacheKey must be a string');
    }
    const [value, setValue] = useState((_a = cache.get(cacheKey)) !== null && _a !== void 0 ? _a : defaultValue);
    const setValueAndUpdateCache = (newValue) => {
        setValue(newValue);
        cache.set(cacheKey, newValue);
    };
    return [value, setValueAndUpdateCache];
}
