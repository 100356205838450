/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.
/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.
//==============================================================
// START Enums and Input Objects
//==============================================================
export var AliasCardsortCategoryErrorEnum;
(function (AliasCardsortCategoryErrorEnum) {
    AliasCardsortCategoryErrorEnum["GROUP_DOES_NOT_EXIST"] = "GROUP_DOES_NOT_EXIST";
    AliasCardsortCategoryErrorEnum["PERMISSION_DENIED"] = "PERMISSION_DENIED";
    AliasCardsortCategoryErrorEnum["TEST_DOES_NOT_EXIST"] = "TEST_DOES_NOT_EXIST";
})(AliasCardsortCategoryErrorEnum || (AliasCardsortCategoryErrorEnum = {}));
export var CollaboratorAddErrorEnum;
(function (CollaboratorAddErrorEnum) {
    CollaboratorAddErrorEnum["PERMISSION_DENIED"] = "PERMISSION_DENIED";
    CollaboratorAddErrorEnum["UNHANDLED_EXCEPTION"] = "UNHANDLED_EXCEPTION";
    CollaboratorAddErrorEnum["USER_ALREADY_COLLABORATOR"] = "USER_ALREADY_COLLABORATOR";
    CollaboratorAddErrorEnum["USER_DOES_NOT_EXIST"] = "USER_DOES_NOT_EXIST";
    CollaboratorAddErrorEnum["USER_NOT_COMPANY_MEMBER"] = "USER_NOT_COMPANY_MEMBER";
})(CollaboratorAddErrorEnum || (CollaboratorAddErrorEnum = {}));
export var CreateRecruitmentOrderErrorEnum;
(function (CreateRecruitmentOrderErrorEnum) {
    CreateRecruitmentOrderErrorEnum["ALLOWANCE_EXCEEDED"] = "ALLOWANCE_EXCEEDED";
    CreateRecruitmentOrderErrorEnum["MAXIMUM_DURATION_EXCEEDED"] = "MAXIMUM_DURATION_EXCEEDED";
    CreateRecruitmentOrderErrorEnum["MAXIMUM_PARTICIPANTS_EXCEEDED"] = "MAXIMUM_PARTICIPANTS_EXCEEDED";
    CreateRecruitmentOrderErrorEnum["MINIMUM_DURATION_NOT_MET"] = "MINIMUM_DURATION_NOT_MET";
    CreateRecruitmentOrderErrorEnum["MINIMUM_PARTICIPANTS_NOT_MET"] = "MINIMUM_PARTICIPANTS_NOT_MET";
    CreateRecruitmentOrderErrorEnum["NOT_ENOUGH_PARTICIPANTS"] = "NOT_ENOUGH_PARTICIPANTS";
    CreateRecruitmentOrderErrorEnum["NO_SCREENER_TEXT_FOUND"] = "NO_SCREENER_TEXT_FOUND";
    CreateRecruitmentOrderErrorEnum["PARTNER_400_BAD_REQUEST"] = "PARTNER_400_BAD_REQUEST";
    CreateRecruitmentOrderErrorEnum["PARTNER_401_UNAUTHORIZED"] = "PARTNER_401_UNAUTHORIZED";
    CreateRecruitmentOrderErrorEnum["PARTNER_403_FORBIDDEN"] = "PARTNER_403_FORBIDDEN";
    CreateRecruitmentOrderErrorEnum["PARTNER_404_NOT_FOUND"] = "PARTNER_404_NOT_FOUND";
    CreateRecruitmentOrderErrorEnum["PARTNER_500_INTERNAL_SERVER_ERROR"] = "PARTNER_500_INTERNAL_SERVER_ERROR";
    CreateRecruitmentOrderErrorEnum["PARTNER_NOT_FOUND"] = "PARTNER_NOT_FOUND";
    CreateRecruitmentOrderErrorEnum["PERMISSION_DENIED"] = "PERMISSION_DENIED";
    CreateRecruitmentOrderErrorEnum["PROJECT_ARCHIVED"] = "PROJECT_ARCHIVED";
    CreateRecruitmentOrderErrorEnum["PROJECT_DELETED"] = "PROJECT_DELETED";
    CreateRecruitmentOrderErrorEnum["PROJECT_NOT_FOUND"] = "PROJECT_NOT_FOUND";
    CreateRecruitmentOrderErrorEnum["PROJECT_PASSWORD_PROTECTED"] = "PROJECT_PASSWORD_PROTECTED";
    CreateRecruitmentOrderErrorEnum["PROJECT_PAUSED"] = "PROJECT_PAUSED";
    CreateRecruitmentOrderErrorEnum["STUDY_ACTIVATION_ERROR"] = "STUDY_ACTIVATION_ERROR";
    CreateRecruitmentOrderErrorEnum["STUDY_NOT_FOUND"] = "STUDY_NOT_FOUND";
})(CreateRecruitmentOrderErrorEnum || (CreateRecruitmentOrderErrorEnum = {}));
export var CreateUserTestErrorEnum;
(function (CreateUserTestErrorEnum) {
    CreateUserTestErrorEnum["BROKEN_SCREENS"] = "BROKEN_SCREENS";
    CreateUserTestErrorEnum["EXTERNAL_PROTOTYPE_IMPORT_FAILED"] = "EXTERNAL_PROTOTYPE_IMPORT_FAILED";
    CreateUserTestErrorEnum["EXTERNAL_PROTOTYPE_NOT_FOUND"] = "EXTERNAL_PROTOTYPE_NOT_FOUND";
    CreateUserTestErrorEnum["EXTERNAL_PROTOTYPE_UNAUTHORISED_ACCESSS"] = "EXTERNAL_PROTOTYPE_UNAUTHORISED_ACCESSS";
    CreateUserTestErrorEnum["INVALID_GOAL_SCREEN"] = "INVALID_GOAL_SCREEN";
    CreateUserTestErrorEnum["INVALID_TARGET_FOLDER"] = "INVALID_TARGET_FOLDER";
    CreateUserTestErrorEnum["MISSING_ARGUMENTS"] = "MISSING_ARGUMENTS";
    CreateUserTestErrorEnum["PERMISSION_DENIED"] = "PERMISSION_DENIED";
    CreateUserTestErrorEnum["PROJECT_DOES_NOT_EXIST"] = "PROJECT_DOES_NOT_EXIST";
    CreateUserTestErrorEnum["PROJECT_IS_ARCHIVED"] = "PROJECT_IS_ARCHIVED";
    CreateUserTestErrorEnum["QUOTA_REACHED"] = "QUOTA_REACHED";
    CreateUserTestErrorEnum["UNAUTHORIZED_TARGET_FOLDER"] = "UNAUTHORIZED_TARGET_FOLDER";
})(CreateUserTestErrorEnum || (CreateUserTestErrorEnum = {}));
export var DeleteTestResultsErrorEnum;
(function (DeleteTestResultsErrorEnum) {
    DeleteTestResultsErrorEnum["PERMISSION_DENIED"] = "PERMISSION_DENIED";
    DeleteTestResultsErrorEnum["RESULT_DOES_NOT_EXIST"] = "RESULT_DOES_NOT_EXIST";
    DeleteTestResultsErrorEnum["TEST_DOES_NOT_EXIST"] = "TEST_DOES_NOT_EXIST";
})(DeleteTestResultsErrorEnum || (DeleteTestResultsErrorEnum = {}));
export var DeviceMakeEnum;
(function (DeviceMakeEnum) {
    DeviceMakeEnum["MOBILE"] = "MOBILE";
    DeviceMakeEnum["TABLET"] = "TABLET";
    DeviceMakeEnum["WEB"] = "WEB";
})(DeviceMakeEnum || (DeviceMakeEnum = {}));
export var EligibilityCountErrorEnum;
(function (EligibilityCountErrorEnum) {
    EligibilityCountErrorEnum["NOT_IMPLEMENTED"] = "NOT_IMPLEMENTED";
    EligibilityCountErrorEnum["PARTNER_NOT_FOUND"] = "PARTNER_NOT_FOUND";
    EligibilityCountErrorEnum["PARTNER_REQUEST_ERROR"] = "PARTNER_REQUEST_ERROR";
    EligibilityCountErrorEnum["PERMISSION_DENIED"] = "PERMISSION_DENIED";
    EligibilityCountErrorEnum["PROJECT_NOT_FOUND"] = "PROJECT_NOT_FOUND";
    EligibilityCountErrorEnum["REMOTE_COUNT_ERROR"] = "REMOTE_COUNT_ERROR";
    EligibilityCountErrorEnum["STUDY_NOT_FOUND"] = "STUDY_NOT_FOUND";
})(EligibilityCountErrorEnum || (EligibilityCountErrorEnum = {}));
export var FailureCodeEnum;
(function (FailureCodeEnum) {
    FailureCodeEnum["FILE_TOO_LARGE"] = "FILE_TOO_LARGE";
    FailureCodeEnum["FORBIDDEN_NOT_ALLOWED"] = "FORBIDDEN_NOT_ALLOWED";
    FailureCodeEnum["NOT_FOUND"] = "NOT_FOUND";
    FailureCodeEnum["PROTOTYPE_NOT_FOUND"] = "PROTOTYPE_NOT_FOUND";
    FailureCodeEnum["SERVER_ERROR"] = "SERVER_ERROR";
})(FailureCodeEnum || (FailureCodeEnum = {}));
export var FailureType;
(function (FailureType) {
    FailureType["CLIENT_STREAMING"] = "CLIENT_STREAMING";
    FailureType["HARDWARE_PERMISSIONS"] = "HARDWARE_PERMISSIONS";
    FailureType["SERVER"] = "SERVER";
})(FailureType || (FailureType = {}));
export var FileSizeTypeEnum;
(function (FileSizeTypeEnum) {
    FileSizeTypeEnum["LARGE"] = "LARGE";
    FileSizeTypeEnum["MEDIUM"] = "MEDIUM";
    FileSizeTypeEnum["SMALL"] = "SMALL";
    FileSizeTypeEnum["UNKNOWN"] = "UNKNOWN";
})(FileSizeTypeEnum || (FileSizeTypeEnum = {}));
export var FrameEnum;
(function (FrameEnum) {
    FrameEnum["APPL_WATCH_42"] = "APPL_WATCH_42";
    FrameEnum["IPAD"] = "IPAD";
    FrameEnum["IPAD_PRO_11"] = "IPAD_PRO_11";
    FrameEnum["IPHONE4S"] = "IPHONE4S";
    FrameEnum["IPHONE5"] = "IPHONE5";
    FrameEnum["IPHONE5C"] = "IPHONE5C";
    FrameEnum["IPHONE6"] = "IPHONE6";
    FrameEnum["IPHONE6PLUS"] = "IPHONE6PLUS";
    FrameEnum["IPHONEX"] = "IPHONEX";
    FrameEnum["LUMIA920"] = "LUMIA920";
    FrameEnum["NEXUS_5"] = "NEXUS_5";
    FrameEnum["PIXEL_4"] = "PIXEL_4";
    FrameEnum["WEB"] = "WEB";
})(FrameEnum || (FrameEnum = {}));
export var JoinRoomErrorEnum;
(function (JoinRoomErrorEnum) {
    JoinRoomErrorEnum["MISSING_PAYLOAD"] = "MISSING_PAYLOAD";
    JoinRoomErrorEnum["PERMISSION_DENIED"] = "PERMISSION_DENIED";
    JoinRoomErrorEnum["REQUEST_FAILED"] = "REQUEST_FAILED";
    JoinRoomErrorEnum["ROOM_NOT_FOUND"] = "ROOM_NOT_FOUND";
})(JoinRoomErrorEnum || (JoinRoomErrorEnum = {}));
export var LiveRoleEnum;
(function (LiveRoleEnum) {
    LiveRoleEnum["OBSERVER"] = "OBSERVER";
    LiveRoleEnum["PARTICIPANT"] = "PARTICIPANT";
    LiveRoleEnum["RESEARCHER"] = "RESEARCHER";
})(LiveRoleEnum || (LiveRoleEnum = {}));
export var MimeTypeEnum;
(function (MimeTypeEnum) {
    MimeTypeEnum["MP4"] = "MP4";
    MimeTypeEnum["WEBM"] = "WEBM";
})(MimeTypeEnum || (MimeTypeEnum = {}));
export var PasswordProtectProjectErrorEnum;
(function (PasswordProtectProjectErrorEnum) {
    PasswordProtectProjectErrorEnum["PASSWORD_TOO_SHORT"] = "PASSWORD_TOO_SHORT";
    PasswordProtectProjectErrorEnum["PERMISSION_DENIED"] = "PERMISSION_DENIED";
    PasswordProtectProjectErrorEnum["PROJECT_NOT_FOUND"] = "PROJECT_NOT_FOUND";
})(PasswordProtectProjectErrorEnum || (PasswordProtectProjectErrorEnum = {}));
export var ProjectVisibilityEnum;
(function (ProjectVisibilityEnum) {
    ProjectVisibilityEnum["PRIVATE"] = "PRIVATE";
    ProjectVisibilityEnum["WORKSPACE"] = "WORKSPACE";
})(ProjectVisibilityEnum || (ProjectVisibilityEnum = {}));
export var ReasonEnum;
(function (ReasonEnum) {
    ReasonEnum["ANSWERS_IMPROPER"] = "ANSWERS_IMPROPER";
    ReasonEnum["ANSWERS_TOO_SHORT"] = "ANSWERS_TOO_SHORT";
    ReasonEnum["DUPLICATE_PARTICIPANT"] = "DUPLICATE_PARTICIPANT";
    ReasonEnum["INCORRECT_SCREEN"] = "INCORRECT_SCREEN";
    ReasonEnum["NO_AUDIO"] = "NO_AUDIO";
    ReasonEnum["NO_SCREEN_RECORDING"] = "NO_SCREEN_RECORDING";
    ReasonEnum["NO_WEBCAM"] = "NO_WEBCAM";
    ReasonEnum["OTHER"] = "OTHER";
    ReasonEnum["WRONG_DEMOGRAPHICS"] = "WRONG_DEMOGRAPHICS";
})(ReasonEnum || (ReasonEnum = {}));
export var RecordTestSessionErrorEnum;
(function (RecordTestSessionErrorEnum) {
    RecordTestSessionErrorEnum["DEVICE_NOT_PROVIDED"] = "DEVICE_NOT_PROVIDED";
    RecordTestSessionErrorEnum["DEVICE_NOT_SUPPORTED"] = "DEVICE_NOT_SUPPORTED";
    RecordTestSessionErrorEnum["INVALID_ARG"] = "INVALID_ARG";
    RecordTestSessionErrorEnum["INVALID_EVENTS"] = "INVALID_EVENTS";
    RecordTestSessionErrorEnum["PASSWORD_TOKEN_DOES_NOT_EXIST"] = "PASSWORD_TOKEN_DOES_NOT_EXIST";
    RecordTestSessionErrorEnum["PASSWORD_TOKEN_INVALID"] = "PASSWORD_TOKEN_INVALID";
    RecordTestSessionErrorEnum["PASSWORD_TOKEN_NOT_PROVIDED"] = "PASSWORD_TOKEN_NOT_PROVIDED";
    RecordTestSessionErrorEnum["RECORDING_TYPE_ALREADY_EXISTS"] = "RECORDING_TYPE_ALREADY_EXISTS";
    RecordTestSessionErrorEnum["SHARE_LINK_DEACTIVATED"] = "SHARE_LINK_DEACTIVATED";
    RecordTestSessionErrorEnum["SHARE_LINK_DOES_NOT_EXIST"] = "SHARE_LINK_DOES_NOT_EXIST";
    RecordTestSessionErrorEnum["SHARE_LINK_EXPIRED"] = "SHARE_LINK_EXPIRED";
    RecordTestSessionErrorEnum["TEST_DOES_NOT_EXIST"] = "TEST_DOES_NOT_EXIST";
    RecordTestSessionErrorEnum["TEST_NOT_ACTIVE"] = "TEST_NOT_ACTIVE";
    RecordTestSessionErrorEnum["TEST_RESULT_DUPLICATE"] = "TEST_RESULT_DUPLICATE";
    RecordTestSessionErrorEnum["TEST_RESULT_NOT_MATCHED"] = "TEST_RESULT_NOT_MATCHED";
    RecordTestSessionErrorEnum["UNEXPECTED_URL_PARAMS"] = "UNEXPECTED_URL_PARAMS";
    RecordTestSessionErrorEnum["USERTEST_PAUSED"] = "USERTEST_PAUSED";
    RecordTestSessionErrorEnum["USERTEST_PROJECT_UNAVAILABLE"] = "USERTEST_PROJECT_UNAVAILABLE";
})(RecordTestSessionErrorEnum || (RecordTestSessionErrorEnum = {}));
export var ReportResultErrorEnum;
(function (ReportResultErrorEnum) {
    ReportResultErrorEnum["INVALID_REASON"] = "INVALID_REASON";
    ReportResultErrorEnum["OTHER_REASON_NOT_PROVIDED"] = "OTHER_REASON_NOT_PROVIDED";
    ReportResultErrorEnum["PARTNER_REQUEST_ERROR"] = "PARTNER_REQUEST_ERROR";
    ReportResultErrorEnum["PERMISSION_DENIED"] = "PERMISSION_DENIED";
    ReportResultErrorEnum["RESULT_ALREADY_REPORTED"] = "RESULT_ALREADY_REPORTED";
    ReportResultErrorEnum["RESULT_NOT_FOUND"] = "RESULT_NOT_FOUND";
})(ReportResultErrorEnum || (ReportResultErrorEnum = {}));
export var ResultStatusEnum;
(function (ResultStatusEnum) {
    ResultStatusEnum["COMPLETED"] = "COMPLETED";
    ResultStatusEnum["FAILED"] = "FAILED";
    ResultStatusEnum["INCOMPLETE"] = "INCOMPLETE";
    ResultStatusEnum["IN_PROGRESS"] = "IN_PROGRESS";
    ResultStatusEnum["QUALIFIED_VIEWED"] = "QUALIFIED_VIEWED";
    ResultStatusEnum["REJECTED"] = "REJECTED";
    ResultStatusEnum["SCREENING_ABANDONED"] = "SCREENING_ABANDONED";
    ResultStatusEnum["SCREENING_DECLINED"] = "SCREENING_DECLINED";
    ResultStatusEnum["SCREENING_STARTED"] = "SCREENING_STARTED";
    ResultStatusEnum["SCREENING_VIEWED"] = "SCREENING_VIEWED";
    ResultStatusEnum["VIEWED"] = "VIEWED";
})(ResultStatusEnum || (ResultStatusEnum = {}));
export var RoleEnum;
(function (RoleEnum) {
    RoleEnum["ADMIN"] = "ADMIN";
    RoleEnum["EDITOR"] = "EDITOR";
    RoleEnum["EXTERNAL_EDITOR"] = "EXTERNAL_EDITOR";
    RoleEnum["EXTERNAL_REVIEWER"] = "EXTERNAL_REVIEWER";
    RoleEnum["INTERNAL_REVIEWER"] = "INTERNAL_REVIEWER";
    RoleEnum["MANAGER"] = "MANAGER";
    RoleEnum["OWNER"] = "OWNER";
})(RoleEnum || (RoleEnum = {}));
export var SourcePartnerEnum;
(function (SourcePartnerEnum) {
    SourcePartnerEnum["RALLY"] = "RALLY";
})(SourcePartnerEnum || (SourcePartnerEnum = {}));
export var StepTypeEnum;
(function (StepTypeEnum) {
    StepTypeEnum["STEP_BANNER_AD_TEST"] = "STEP_BANNER_AD_TEST";
    StepTypeEnum["STEP_CARDSORT_CLOSED"] = "STEP_CARDSORT_CLOSED";
    StepTypeEnum["STEP_CARDSORT_HYBRID"] = "STEP_CARDSORT_HYBRID";
    StepTypeEnum["STEP_CARDSORT_OPEN"] = "STEP_CARDSORT_OPEN";
    StepTypeEnum["STEP_FIRST_CLICK"] = "STEP_FIRST_CLICK";
    StepTypeEnum["STEP_FIVE_SECOND_TEST"] = "STEP_FIVE_SECOND_TEST";
    StepTypeEnum["STEP_INSTRUCTION"] = "STEP_INSTRUCTION";
    StepTypeEnum["STEP_LEGAL_QUESTION"] = "STEP_LEGAL_QUESTION";
    StepTypeEnum["STEP_MULTIPLE_CHOICE"] = "STEP_MULTIPLE_CHOICE";
    StepTypeEnum["STEP_PREFERENCE_TEST"] = "STEP_PREFERENCE_TEST";
    StepTypeEnum["STEP_PROTOTYPE_TASK"] = "STEP_PROTOTYPE_TASK";
    StepTypeEnum["STEP_QUESTION"] = "STEP_QUESTION";
    StepTypeEnum["STEP_RATING_SCALE"] = "STEP_RATING_SCALE";
    StepTypeEnum["STEP_TAGLINE_COPY_TEST"] = "STEP_TAGLINE_COPY_TEST";
    StepTypeEnum["STEP_WEBSITE_TASK"] = "STEP_WEBSITE_TASK";
    StepTypeEnum["STEP_YES_OR_NO"] = "STEP_YES_OR_NO";
})(StepTypeEnum || (StepTypeEnum = {}));
export var StoreSelectedErrorEnum;
(function (StoreSelectedErrorEnum) {
    StoreSelectedErrorEnum["INVALID_NUMBER_PARTICIPANTS"] = "INVALID_NUMBER_PARTICIPANTS";
    StoreSelectedErrorEnum["INVALID_STUDY_DURATION"] = "INVALID_STUDY_DURATION";
    StoreSelectedErrorEnum["PERMISSION_DENIED"] = "PERMISSION_DENIED";
    StoreSelectedErrorEnum["PROJECT_NOT_FOUND"] = "PROJECT_NOT_FOUND";
})(StoreSelectedErrorEnum || (StoreSelectedErrorEnum = {}));
export var StoreSelectedFailureEnum;
(function (StoreSelectedFailureEnum) {
    StoreSelectedFailureEnum["FILTER_NOT_FOUND"] = "FILTER_NOT_FOUND";
    StoreSelectedFailureEnum["OPTION_DOES_NOT_MATCH_FILTER"] = "OPTION_DOES_NOT_MATCH_FILTER";
    StoreSelectedFailureEnum["OPTION_NOT_FOUND"] = "OPTION_NOT_FOUND";
})(StoreSelectedFailureEnum || (StoreSelectedFailureEnum = {}));
export var StudyStatusEnum;
(function (StudyStatusEnum) {
    StudyStatusEnum["ACTIVE"] = "ACTIVE";
    StudyStatusEnum["CANCELLED"] = "CANCELLED";
    StudyStatusEnum["COMPLETED"] = "COMPLETED";
    StudyStatusEnum["DRAFT"] = "DRAFT";
    StudyStatusEnum["PAUSED"] = "PAUSED";
})(StudyStatusEnum || (StudyStatusEnum = {}));
export var StudyTypeEnum;
(function (StudyTypeEnum) {
    StudyTypeEnum["B2B"] = "B2B";
    StudyTypeEnum["SURVEY"] = "SURVEY";
    StudyTypeEnum["VIDEO"] = "VIDEO";
})(StudyTypeEnum || (StudyTypeEnum = {}));
export var TestRecordingPermissionsEnum;
(function (TestRecordingPermissionsEnum) {
    TestRecordingPermissionsEnum["DENIED"] = "DENIED";
    TestRecordingPermissionsEnum["GRANTED"] = "GRANTED";
    TestRecordingPermissionsEnum["NOT_REQUESTED"] = "NOT_REQUESTED";
    TestRecordingPermissionsEnum["NOT_SUPPORTED"] = "NOT_SUPPORTED";
})(TestRecordingPermissionsEnum || (TestRecordingPermissionsEnum = {}));
export var TestRecordingUploadStatusEnum;
(function (TestRecordingUploadStatusEnum) {
    TestRecordingUploadStatusEnum["UPLOAD_ACQUIRING_MEDIA"] = "UPLOAD_ACQUIRING_MEDIA";
    TestRecordingUploadStatusEnum["UPLOAD_FAILED"] = "UPLOAD_FAILED";
    TestRecordingUploadStatusEnum["UPLOAD_IN_PROGRESS"] = "UPLOAD_IN_PROGRESS";
    TestRecordingUploadStatusEnum["UPLOAD_MEDIA_ACQUIRED"] = "UPLOAD_MEDIA_ACQUIRED";
    TestRecordingUploadStatusEnum["UPLOAD_PENDING"] = "UPLOAD_PENDING";
    TestRecordingUploadStatusEnum["UPLOAD_STARTING"] = "UPLOAD_STARTING";
    TestRecordingUploadStatusEnum["UPLOAD_SUCCESS"] = "UPLOAD_SUCCESS";
})(TestRecordingUploadStatusEnum || (TestRecordingUploadStatusEnum = {}));
export var TestStatusInputEnum;
(function (TestStatusInputEnum) {
    TestStatusInputEnum["ACTIVE"] = "ACTIVE";
    TestStatusInputEnum["PAUSED"] = "PAUSED";
})(TestStatusInputEnum || (TestStatusInputEnum = {}));
export var TriggerTypeEnum;
(function (TriggerTypeEnum) {
    TriggerTypeEnum["CLICK_OR_TAP"] = "CLICK_OR_TAP";
    TriggerTypeEnum["DOUBLE_CLICK_OR_TAP"] = "DOUBLE_CLICK_OR_TAP";
    TriggerTypeEnum["NAVIGATION"] = "NAVIGATION";
    TriggerTypeEnum["PINCH"] = "PINCH";
    TriggerTypeEnum["PLAYER"] = "PLAYER";
    TriggerTypeEnum["SWIPE"] = "SWIPE";
    TriggerTypeEnum["TIMER"] = "TIMER";
    TriggerTypeEnum["TOUCH_OR_MOUSE_MOVE"] = "TOUCH_OR_MOUSE_MOVE";
    TriggerTypeEnum["USER"] = "USER";
})(TriggerTypeEnum || (TriggerTypeEnum = {}));
export var UpdateUserTestErrorEnum;
(function (UpdateUserTestErrorEnum) {
    UpdateUserTestErrorEnum["DOES_NOT_EXIST"] = "DOES_NOT_EXIST";
    UpdateUserTestErrorEnum["INVALID_GOAL_SCREEN"] = "INVALID_GOAL_SCREEN";
    UpdateUserTestErrorEnum["MESSAGE_TOO_LONG"] = "MESSAGE_TOO_LONG";
    UpdateUserTestErrorEnum["MISSING_ARGUMENTS"] = "MISSING_ARGUMENTS";
    UpdateUserTestErrorEnum["PERMISSION_DENIED"] = "PERMISSION_DENIED";
    UpdateUserTestErrorEnum["TEST_PENDING"] = "TEST_PENDING";
})(UpdateUserTestErrorEnum || (UpdateUserTestErrorEnum = {}));
export var UserTestStatusEnum;
(function (UserTestStatusEnum) {
    UserTestStatusEnum["ACTIVE"] = "ACTIVE";
    UserTestStatusEnum["PAUSED"] = "PAUSED";
    UserTestStatusEnum["PENDING"] = "PENDING";
})(UserTestStatusEnum || (UserTestStatusEnum = {}));
export var UserTestTypeEnum;
(function (UserTestTypeEnum) {
    UserTestTypeEnum["FREEROAM"] = "FREEROAM";
    UserTestTypeEnum["GOAL"] = "GOAL";
    UserTestTypeEnum["MULTISTEP"] = "MULTISTEP";
})(UserTestTypeEnum || (UserTestTypeEnum = {}));
export var VideoRecordingRequirementEnum;
(function (VideoRecordingRequirementEnum) {
    VideoRecordingRequirementEnum["OFF"] = "OFF";
    VideoRecordingRequirementEnum["OPTIONAL"] = "OPTIONAL";
    VideoRecordingRequirementEnum["REQUIRED"] = "REQUIRED";
})(VideoRecordingRequirementEnum || (VideoRecordingRequirementEnum = {}));
//==============================================================
// END Enums and Input Objects
//==============================================================
