import { createReplacePlaceholders, richTextToString, stringToRichText, } from '../text/utils';
export const RICH_TEXT_LOCALE_STRING_VALUES = {
    bold: (chunks) => `<bold>${chunks}</bold>`,
    italic: (chunks) => `<italic>${chunks}</italic>`,
    strikethrough: (chunks) => `<s>${chunks}</s>`,
};
export function richTextToLocaleString(richText) {
    return richTextToString(richText, '<br/>');
}
export function localeStringToRichText(value, placeholders) {
    return stringToRichText(value, '<br/>', placeholders);
}
export function localizeIfDefault(intl, text, defaultValue, placeholders = {}, values = {}) {
    const isRichText = typeof text !== 'string';
    const string = isRichText ? richTextToLocaleString(text) : text;
    const replacePlaceholders = createReplacePlaceholders(placeholders);
    if (typeof defaultValue.defaultMessage === 'string' &&
        string === replacePlaceholders(defaultValue.defaultMessage)) {
        const localizedString = intl.formatMessage(defaultValue, Object.assign(Object.assign({}, RICH_TEXT_LOCALE_STRING_VALUES), values));
        return isRichText
            ? localeStringToRichText(localizedString)
            : localizedString;
    }
    return text;
}
