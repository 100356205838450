import { __rest } from "tslib";
import { jsx as _jsx } from "react/jsx-runtime";
import { observer } from 'mobx-react';
import { Text } from '@marvelapp/ballpark-components';
import styled from '@marvelapp/styled';
import { Box } from '@marvelapp/ui';
export const SectionDivider = observer(function SectionDivider(_a) {
    var { copy } = _a, props = __rest(_a, ["copy"]);
    return (_jsx(SectionDividerContainer, Object.assign({ mb: "s" }, props, { children: _jsx(SectionText, { fontSize: "s", fontWeight: "medium", color: "slate12", alignSelf: "center", children: copy }) })));
});
const SectionDividerContainer = styled(Box) `
  position: relative;
  &:before {
    content: '';
    position: absolute;
    top: 50%;
    height: 1px;
    background: ${(props) => props.theme.colors.slate6};
    width: 100%;
    z-index: -1;
  }
`;
const SectionText = styled(Text).attrs({ bg: 'background', pr: 'xxs' }) `
  display: inline-block;
`;
