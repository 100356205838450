import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useOnHistoryReplay } from '@ballpark/realtime-plugin-history';
import { noop } from 'lodash-es';
import { observer } from 'mobx-react';
import { useCallback, useState } from 'react';
import { useAction, useSlideUIContext } from '@marvelapp/ballpark-application';
import { Text } from '@marvelapp/ballpark-components';
import { Box } from '@marvelapp/ui';
import { StepClass, StepUtils, WebsiteTaskUtils, } from '@marvelapp/user-test-creator';
import { DecoratedSlide } from '../common/DecoratedSlide';
import SlideContent from '../common/SlideContent';
import UrlForm from '../common/UrlForm';
import WebsiteTaskButton from './WebsiteTaskButton';
import WebsiteTaskInfoBlock from './WebsiteTaskInfoBlock';
export default observer(function WebsiteTaskEdit() {
    const { step } = useSlideUIContext();
    const [isInEditMode, setIsInEditMode] = useState(false);
    const enableEditMode = useCallback(() => {
        setIsInEditMode(true);
    }, []);
    const disableEditMode = useCallback(() => {
        // TODO: this is necessary otherwise the children of this component do not update. I believe
        // this is MobX/React Scheduling error where this causes it to cancel the immediate render
        // but still records the websiteUrl last property and then when it re-renders it no longer
        // recognized websiteUrl as having changed so doesn't re-render the children then either.
        //
        // Needs further investigation
        setTimeout(() => setIsInEditMode(false), 0);
    }, []);
    useOnHistoryReplay(disableEditMode);
    const onSubmit = useAction((url) => {
        if (!StepUtils.isOfType(step, StepClass.WebsiteTask))
            return;
        WebsiteTaskUtils.setWebsiteUrl(step, url);
        setIsInEditMode(false);
    }, [step]);
    if (!StepUtils.isOfType(step, StepClass.WebsiteTask))
        return null;
    const websiteUrl = step.websiteUrl || '';
    return (_jsxs(DecoratedSlide, { children: [_jsx(SlideContent, {}), _jsx(Box, { width: "100%", marginTop: "s", minHeight: "120px", children: isInEditMode || !websiteUrl ? (_jsx(UrlForm, { label: "Website url", testId: "website", buttonText: "Update website url", value: websiteUrl || '', placeholder: "https://website-to-test.com", onChange: noop, onSubmit: onSubmit, isLoading: false })) : (_jsxs(_Fragment, { children: [_jsx(WebsiteTaskButton, { href: websiteUrl }), _jsx(Box, { marginTop: "m", children: _jsx(WebsiteTaskInfoBlock, { url: websiteUrl, children: _jsx(Text, { as: "a", "data-testid": "change-url", cursor: "pointer", fontSize: "14px", textDecoration: "underline", display: "inline", paddingLeft: "xs", color: "red", onClick: enableEditMode, children: "Change URL" }) }) })] })) })] }));
});
