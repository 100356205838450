import { __rest } from "tslib";
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from '@marvelapp/styled';
import Box from '../Box';
import Flex from '../Flex';
import Text from '../Text';
import sizes from './sizes';
export default function LiNumbered(_a) {
    var { size, children, number } = _a, rest = __rest(_a, ["size", "children", "number"]);
    return (_jsx(Li, Object.assign({ as: "li" }, rest, { children: _jsxs(Flex, { alignItems: "flex-stretch", mb: sizes[size].spacing, children: [_jsx(Flex, { alignItems: "center", border: "1px solid", borderColor: "smoke", borderRadius: sizes[size].iconBorderRadius, fontSize: sizes[size].iconFontSize, fontWeight: 600, height: sizes[size].iconSize, justifyContent: "center", minHeight: sizes[size].iconSize, minWidth: sizes[size].iconSize, textAlign: "center", width: sizes[size].iconSize, children: number }), _jsx(Flex, { alignItems: "center", children: _jsx(Text, { fontSize: sizes[size].fontSize, pl: sizes[size].iconMarginRight, children: children }) })] }) })));
}
const Li = styled(Box) `
  list-style: none;
`;
LiNumbered.defaultProps = {
    size: 2,
    number: 1,
};
