import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { observer } from 'mobx-react';
import { useState } from 'react';
import * as React from 'react';
import { BtwInput as Input, Select, Stack, } from '@marvelapp/ballpark-components';
import { IdentityOperator, OrdinalOperator, RatingStepUtils, StepClass, StepUtils, } from '@marvelapp/user-test-creator';
import { updateRequirement } from '../../../utils';
import { MultiConditionLogic, OperatorSelect, } from '../shared/multiConditionLogic';
function validateRequirement({ value }) {
    return value !== null;
}
const NUMERIC_OPERATORS = [
    [IdentityOperator.Is, 'is equal to'],
    [IdentityOperator.IsNot, 'is not equal to'],
    [OrdinalOperator.IsLessThan, 'is less than'],
    [OrdinalOperator.IsGreaterThan, 'is greater than'],
    [OrdinalOperator.IsLessOrEqualTo, 'is less or equal to'],
    [OrdinalOperator.IsGreaterOrEqualTo, 'is greater or equal to'],
];
export function createDefaultRequirement(combinator) {
    return {
        operator: IdentityOperator.Is,
        combinator,
        value: null,
    };
}
export const NumericalConditionalLogic = observer(function NumericalConditionalLogic({ step, }) {
    const renderAnswerComponent = ({ ruleIndex, requirementIndex, setValue: setAnswerRule, setOperator, }) => {
        var _a, _b, _c, _d, _e;
        const requirement = (_c = (_b = (_a = step === null || step === void 0 ? void 0 : step.conditions) === null || _a === void 0 ? void 0 : _a.rules) === null || _b === void 0 ? void 0 : _b[ruleIndex]) === null || _c === void 0 ? void 0 : _c.requirements[requirementIndex];
        return StepUtils.isOfType(step, StepClass.RatingScale) ? (_jsx(RatingAnswerSelect, { setOperator: setOperator, savedValue: requirement !== null && requirement !== void 0 ? requirement : null, setRule: setAnswerRule, currentOperator: (_d = requirement === null || requirement === void 0 ? void 0 : requirement.operator) !== null && _d !== void 0 ? _d : IdentityOperator.Is, step: step })) : (_jsx(NumericalAnswerSelect, { setOperator: setOperator, savedValue: requirement !== null && requirement !== void 0 ? requirement : null, setRule: setAnswerRule, currentOperator: (_e = requirement === null || requirement === void 0 ? void 0 : requirement.operator) !== null && _e !== void 0 ? _e : IdentityOperator.Is }));
    };
    return (_jsx(MultiConditionLogic, { step: step, canAddRequirement: true, renderRequirement: renderAnswerComponent, createRequirement: createDefaultRequirement, updateRequirement: updateRequirement, validateRequirement: validateRequirement }));
});
const getParsedValue = (e) => {
    if (!Number.isNaN(parseInt(e.target.value))) {
        return parseInt(e.target.value);
    }
    return 0;
};
const NumericalAnswerSelect = observer(function NumericalAnswerSelect({ savedValue, setRule, currentOperator, setOperator, }) {
    var _a;
    const [value, setValue] = useState((_a = savedValue === null || savedValue === void 0 ? void 0 : savedValue.value) !== null && _a !== void 0 ? _a : '');
    // do we need the state here?
    return (_jsxs(_Fragment, { children: [_jsx(OperatorSelect, { operator: currentOperator, operatorOptions: NUMERIC_OPERATORS, setRule: setRule, setOperator: setOperator }), _jsx(Input.Root, { className: "flex-1", children: _jsx(Input.Field, { onChange: (e) => {
                        const value = getParsedValue(e);
                        setValue(value !== null && value !== void 0 ? value : '');
                        setRule({
                            value: { value },
                        });
                    }, "data-testid": "numerical-logic-input", value: value, id: "number-field", placeholder: "Enter a number\u2026" }) })] }));
});
const RatingAnswerSelect = observer(function NumericalAnswerSelect({ savedValue, setRule, currentOperator, setOperator, step, }) {
    var _a;
    const formattedValue = `${(_a = savedValue === null || savedValue === void 0 ? void 0 : savedValue.value) !== null && _a !== void 0 ? _a : ''}`;
    const minRating = RatingStepUtils.getMinRating(step);
    const maxRating = RatingStepUtils.getMaxRating(step);
    const formattedOptions = Array.from({ length: maxRating - minRating + 1 }, (_, i) => `${minRating + i}`);
    return (_jsxs(_Fragment, { children: [_jsx(OperatorSelect, { operator: currentOperator, operatorOptions: NUMERIC_OPERATORS, setRule: setRule, setOperator: setOperator }), _jsx(Stack, { direction: "row", className: "flex-1", children: _jsxs(Select.Root, { value: formattedValue, onValueChange: (v) => {
                        setRule({
                            value: { value: parseInt(v) },
                        });
                    }, children: [_jsx(Select.Trigger, { className: "w-full min-w-0 flex-1", testId: "rating-logic-select", isTruncated: true, hasPlaceholder: !formattedValue, children: formattedValue || 'Select an option…' }), _jsx(Select.Content, { children: formattedOptions.map((value) => (_jsx(Select.Item, { value: value, children: _jsx(_Fragment, { children: value }) }, value))) })] }) })] }));
});
