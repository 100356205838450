import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { DndContext, DragOverlay, closestCenter, } from '@dnd-kit/core';
import { SortableContext, verticalListSortingStrategy, } from '@dnd-kit/sortable';
import { observer } from 'mobx-react';
import * as React from 'react';
import { useAction, useGetUserTest } from '@marvelapp/ballpark-application';
import { useDragging } from '../hooks/useDragging';
import { useUIContext } from '../pages/Project/uiContext';
import { CardContainer } from './CardContainer';
import SlideRowCard, { DraggableSlideRowCard } from './SlideRowCard';
export const DraggableCards = observer(function DraggableCards({ stepsUUIDs, reorderFn, onCloneStep, onDeleteStep, getStepText, getCardTypeIcon, testId, showStepSettingsIcons, }) {
    const userTest = useGetUserTest();
    const { setActiveSlideUUID, scrollSpyController } = useUIContext();
    const items = stepsUUIDs.map((item) => `sidebar-${item}`);
    const { draggingItem, handleDragEnd, handleDragStart, sensors } = useDragging({
        pointerSensorOptions: {
            activationConstraint: {
                distance: 5,
            },
        },
    });
    const onDragEnd = useAction((event) => {
        if (!scrollSpyController)
            return;
        handleDragEnd({
            event,
            items,
            reorderFn,
            parentItem: userTest,
            onDragEnd: (moved) => {
                scrollSpyController.disable();
                setActiveSlideUUID(moved);
            },
        });
    }, [
        handleDragEnd,
        items,
        reorderFn,
        scrollSpyController,
        setActiveSlideUUID,
        userTest,
    ]);
    return (_jsxs(DndContext, { sensors: sensors, collisionDetection: closestCenter, onDragStart: handleDragStart, onDragEnd: onDragEnd, children: [_jsx(SortableContext, { items: items, strategy: verticalListSortingStrategy, children: stepsUUIDs.map((uuid) => (_jsx(DraggableCard, { onCloneStep: onCloneStep, getStepText: getStepText, onDeleteStep: onDeleteStep, getCardTypeIcon: getCardTypeIcon, showStepSettingsIcons: showStepSettingsIcons, uuid: uuid, testId: testId }, uuid))) }), _jsx(DragOverlay, { children: typeof draggingItem === 'string' ? (_jsx(DraggingItem, { uuid: draggingItem.replace('sidebar-', ''), getCardTypeIcon: getCardTypeIcon, getStepText: getStepText, showStepSettingsIcons: showStepSettingsIcons })) : null })] }));
});
const DraggingItem = observer(function DraggingItem({ uuid, getStepText, getCardTypeIcon, showStepSettingsIcons, }) {
    const userTest = useGetUserTest();
    return (_jsx(CardContainer, { userTest: userTest, uuid: uuid, children: _jsx(SlideRowCard, { getCardTypeIcon: getCardTypeIcon, getStepText: getStepText, hasHoverMenu: true, id: uuid, showStepSettingsIcons: showStepSettingsIcons, userTest: userTest }) }));
});
const DraggableCard = observer(function DraggableCard({ uuid, onCloneStep, getStepText, onDeleteStep, getCardTypeIcon, testId, showStepSettingsIcons, }) {
    const userTest = useGetUserTest();
    const { activeSlideUUID, setActiveSlideUUID } = useUIContext();
    return (_jsx(CardContainer, { userTest: userTest, uuid: uuid, children: _jsx(DraggableSlideRowCard, { active: activeSlideUUID === uuid, onCloneStep: onCloneStep, onDeleteStep: onDeleteStep, getCardTypeIcon: getCardTypeIcon, getStepText: getStepText, hasHoverMenu: true, id: `sidebar-${uuid}`, setActiveSlideUUID: setActiveSlideUUID, showStepSettingsIcons: showStepSettingsIcons, testId: testId, userTest: userTest }) }));
});
