import { jsx as _jsx } from "react/jsx-runtime";
import { observer } from 'mobx-react';
import * as React from 'react';
import styled from '@marvelapp/styled';
import { Box } from '@marvelapp/ui';
import InteractionBlocker from '../../components/InteractionBlocker';
import { DecoratedSlide } from '../common/DecoratedSlide';
import SlideContent from '../common/SlideContent';
export default observer(function BaseQuestionEdit({ onMainCTAClick, children, }) {
    return (_jsx(DecoratedSlide, { children: _jsx(SlideContent, { ctaOnClick: onMainCTAClick, children: _jsx(InteractionBlocker, { children: _jsx(InputsWrapper, { children: children }) }) }) }));
});
const InputsWrapper = styled(Box).attrs({
    marginTop: 's',
}) `
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
`;
