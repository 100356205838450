import { EventOutcomeType } from '@marvelapp/core';
import { StepClass } from '../common';
import { RecordingStatus } from '../media';
import { getStepByUUID } from '../userTest/selectors';
import { getRecordingPermissions } from '../userTest/stepUtils';
export function create(stepUUID, stepRevision) {
    return {
        className: StepClass.PrototypeTask,
        stepUUID,
        stepRevision,
        events: [],
        participantRecordings: {
            user: null,
            screen: null,
        },
    };
}
export function addEvent(response, event) {
    response.events.push(event);
}
export function clearEvents(response) {
    // eslint-disable-next-line no-param-reassign
    response.events = [];
}
export function hasEvents(response) {
    return response.events.length > 0;
}
export function hasStartTestEvent(response) {
    return response.events.some((event) => event.outcome.type === EventOutcomeType.StartRecording);
}
export function hasHitGoalScreen(response) {
    return response.events.some((event) => event.outcome.type === EventOutcomeType.GoalReached);
}
function getReadyRecording(response, mediaType) {
    const media = response.participantRecordings[mediaType];
    if (!media) {
        return;
    }
    if (media.status === RecordingStatus.Ready) {
        return media;
    }
}
export function getUserMedia(response) {
    return response.participantRecordings.user;
}
export function getScreenMedia(response) {
    return response.participantRecordings.screen;
}
export function getUserReadyRecording(response) {
    return getReadyRecording(response, 'user');
}
export function getScreenReadyRecording(response) {
    return getReadyRecording(response, 'screen');
}
export function getUserRecordingStatus(userTest, stepResponse) {
    const userRecording = getUserMedia(stepResponse);
    if (userRecording) {
        return userRecording.status;
    }
    const step = getStepByUUID(userTest, stepResponse.stepUUID);
    if (step) {
        const permissions = getRecordingPermissions(step);
        if (permissions.webcam || permissions.microphone) {
            return 'declined';
        }
    }
    return null;
}
export function getScreenRecordingStatus(userTest, stepResponse) {
    const screenRecording = getScreenMedia(stepResponse);
    if (screenRecording) {
        return screenRecording.status;
    }
    const step = getStepByUUID(userTest, stepResponse.stepUUID);
    if (step) {
        const permissions = getRecordingPermissions(step);
        if (permissions.screen) {
            return 'declined';
        }
    }
    return null;
}
