import { jsx as _jsx } from "react/jsx-runtime";
import { observer } from 'mobx-react';
import { useEffect } from 'react';
import { isSafeURL } from '@marvelapp/core';
import { useLocationQueryParams } from '@marvelapp/hooks';
import styled from '@marvelapp/styled';
import { Flex, Loader } from '@marvelapp/ui';
export default observer(function WebsiteRecord() {
    const { url } = useLocationQueryParams();
    useEffect(() => {
        if (url && isSafeURL(url)) {
            window.location.href = url;
        }
    }, [url]);
    return (_jsx(Centered, { children: _jsx(Loader, {}) }));
});
const Centered = styled(Flex).attrs({
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh',
}) ``;
