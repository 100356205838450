import uuidv4 from 'uuid/v4';
import { StepClass } from '../common';
import { generateUUID } from '../utils/generateUUID';
import { STEP_PREFIXES } from './constants';
import { isChoiceStep } from './stepUtils';
export function create(partial = {}) {
    const choices = [uuidv4(), uuidv4()];
    const choiceDefinitions = choices.reduce((definitions, choiceUUID) => {
        // eslint-disable-next-line no-param-reassign
        definitions[choiceUUID] = null;
        return definitions;
    }, {});
    return Object.assign({ uuid: generateUUID(STEP_PREFIXES[StepClass.MultipleChoice]), className: StepClass.MultipleChoice, title: null, description: [], choices,
        choiceDefinitions, conditions: {
            rules: [],
            ALWAYS: null,
        }, isRequired: false, randomize: false, revision: 0, selectionRange: [null, null] }, partial);
}
export function mapChoices(mutable, cb, choices = mutable.choices) {
    return choices.map((choiceUUID, index) => {
        const choiceValue = mutable.choiceDefinitions[choiceUUID];
        return cb(choiceValue, choiceUUID, index);
    });
}
export function setChoiceValue(mutable, uuid, value) {
    mutable.choiceDefinitions[uuid] = value;
}
export function getChoiceValue(mutable, uuid) {
    return mutable.choiceDefinitions[uuid];
}
export function getOtherOptionUUID(mutable) {
    const otherOptionUUID = Object.keys(mutable.choiceDefinitions).find((uuid) => uuid.includes('-other'));
    return otherOptionUUID || null;
}
export function addOtherChoiceOption(mutable) {
    const choiceUUID = `${uuidv4()}-other`;
    mutable.choices.push(choiceUUID);
    mutable.choiceDefinitions[choiceUUID] = 'Other';
}
export function removeOtherChoiceOption(mutable) {
    const otherChoiceUUID = getOtherOptionUUID(mutable);
    if (otherChoiceUUID) {
        delete mutable.choiceDefinitions[otherChoiceUUID];
        const choiceToRemoveIndex = mutable.choices.indexOf(otherChoiceUUID);
        mutable.choices.splice(choiceToRemoveIndex, 1);
    }
}
export function hasOtherChoiceOption(mutable) {
    return !!getOtherOptionUUID(mutable);
}
export function getMultipleChoiceUUID(userTest, stepUUID, choiceValue) {
    const step = userTest.stepDefinitions[stepUUID];
    if (!step || !isChoiceStep(step))
        return null;
    const choiceUUID = Object.keys(step.choiceDefinitions).find((key) => {
        return step.choiceDefinitions[key] === choiceValue;
    });
    return choiceUUID || null;
}
export * from './choiceStepUtils';
