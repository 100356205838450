import { StepClass } from '../userTest/types';
import { AnswerType } from './types';
export function create(stepUUID, stepRevision) {
    return {
        className: StepClass.TaglineCopyTest,
        stepUUID,
        stepRevision,
        answer: {
            type: AnswerType.MultipleChoice,
            value: [],
        },
    };
}
export * from './choiceStepResponseUtils';
