import { createProxiedDoc } from '@ballpark/realtime-crdt';
import { proxyOptions } from '@ballpark/realtime-crdt-mobx';
import { createContext, useContext } from 'react';
export const defaultStore = createProxiedDoc({
    screenPicker: {
        collapsedSections: {},
    },
}, 'store', proxyOptions);
export const StoreContext = createContext(defaultStore);
export function useStore() {
    return useContext(StoreContext);
}
