// THIS FILE WAS AUTOGENERATED - DO NOT EDIT DIRECTLY
// RUN "yarn schema:generate"
//
/* eslint-disable */
// @ts-nocheck
'use strict';
var schema27 = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    $ref: '#/definitions/UserTestWithResponses',
    definitions: {
        UserTestWithResponses: {
            type: 'object',
            properties: {
                pk: { type: 'number' },
                uuid: { type: 'string' },
                responses: {
                    type: 'array',
                    items: { $ref: '#/definitions/UserTestResponse' },
                },
            },
            required: ['pk', 'uuid', 'responses'],
            additionalProperties: false,
        },
        UserTestResponse: {
            type: 'object',
            properties: {
                pk: { type: 'number' },
                participant: {
                    type: 'object',
                    properties: { name: { type: 'string' } },
                    required: ['name'],
                    additionalProperties: false,
                },
                submittedAt: { type: 'string' },
                device: {
                    type: 'object',
                    properties: {
                        model: { type: ['string', 'null'] },
                        operatingSystem: { type: ['string', 'null'] },
                        make: {
                            type: ['string', 'null'],
                            enum: ['Web', 'Tablet', 'Mobile', null],
                        },
                    },
                    required: ['model', 'operatingSystem', 'make'],
                    additionalProperties: false,
                },
                hasRecording: { type: 'boolean' },
                status: { $ref: '#/definitions/UserTestResponseStatus' },
                stepResponses: {
                    type: 'array',
                    items: { $ref: '#/definitions/StepResponse' },
                },
                responseUUID: { type: 'string' },
                recruitment: {
                    type: 'object',
                    properties: {
                        participantSource: { $ref: '#/definitions/ParticipantSource' },
                        participantId: { type: ['string', 'null'] },
                        studyId: { type: ['string', 'null'] },
                    },
                    required: ['participantSource', 'participantId', 'studyId'],
                    additionalProperties: false,
                },
            },
            required: [
                'pk',
                'participant',
                'submittedAt',
                'device',
                'hasRecording',
                'status',
                'stepResponses',
                'responseUUID',
                'recruitment',
            ],
            additionalProperties: false,
        },
        UserTestResponseStatus: {
            type: 'string',
            enum: [
                'COMPLETED',
                'FAILED',
                'INCOMPLETE',
                'IN_PROGRESS',
                'QUALIFIED_VIEWED',
                'SCREENING_ABANDONED',
                'SCREENING_DECLINED',
                'SCREENING_STARTED',
            ],
        },
        StepResponse: {
            anyOf: [
                { $ref: '#/definitions/InstructionResponse' },
                { $ref: '#/definitions/FiveSecondsResponse' },
                { $ref: '#/definitions/YesOrNoResponse' },
                { $ref: '#/definitions/MultipleChoiceResponse' },
                { $ref: '#/definitions/PreferenceResponse' },
                { $ref: '#/definitions/TaglineCopyTestResponse' },
                { $ref: '#/definitions/PrototypeTaskResponse' },
                { $ref: '#/definitions/StepQuestionResponse' },
                { $ref: '#/definitions/RatingResponse' },
                { $ref: '#/definitions/WebsiteTaskResponse' },
                { $ref: '#/definitions/CardSortResponse' },
            ],
        },
        InstructionResponse: {
            type: 'object',
            additionalProperties: false,
            properties: {
                type: { type: 'string', const: 'STEP_INSTRUCTION' },
                stepUuid: { type: 'string' },
                stepRevision: { type: 'number' },
                skipped: { type: 'boolean' },
                duration: { type: ['number', 'null'] },
            },
            required: ['duration', 'skipped', 'stepRevision', 'stepUuid', 'type'],
        },
        FiveSecondsResponse: {
            type: 'object',
            additionalProperties: false,
            properties: {
                type: { type: 'string', const: 'STEP_FIVE_SECOND_TEST' },
                answers: {
                    type: 'array',
                    items: { $ref: '#/definitions/BooleanAnswer' },
                },
                stepUuid: { type: 'string' },
                stepRevision: { type: 'number' },
                skipped: { type: 'boolean' },
                duration: { type: ['number', 'null'] },
            },
            required: [
                'answers',
                'duration',
                'skipped',
                'stepRevision',
                'stepUuid',
                'type',
            ],
        },
        BooleanAnswer: {
            type: 'object',
            properties: {
                type: { type: 'string', const: 'BOOLEAN' },
                value: { type: 'boolean' },
            },
            required: ['type', 'value'],
            additionalProperties: false,
        },
        YesOrNoResponse: {
            type: 'object',
            additionalProperties: false,
            properties: {
                type: { type: 'string', const: 'STEP_YES_OR_NO' },
                answers: {
                    type: 'array',
                    items: { $ref: '#/definitions/BooleanAnswer' },
                },
                stepUuid: { type: 'string' },
                stepRevision: { type: 'number' },
                skipped: { type: 'boolean' },
                duration: { type: ['number', 'null'] },
            },
            required: [
                'answers',
                'duration',
                'skipped',
                'stepRevision',
                'stepUuid',
                'type',
            ],
        },
        MultipleChoiceResponse: {
            type: 'object',
            additionalProperties: false,
            properties: {
                type: { type: 'string', const: 'STEP_MULTIPLE_CHOICE' },
                answers: {
                    type: 'array',
                    items: { $ref: '#/definitions/MultipleChoiceAnswer' },
                },
                stepUuid: { type: 'string' },
                stepRevision: { type: 'number' },
                skipped: { type: 'boolean' },
                duration: { type: ['number', 'null'] },
            },
            required: [
                'answers',
                'duration',
                'skipped',
                'stepRevision',
                'stepUuid',
                'type',
            ],
        },
        MultipleChoiceAnswer: {
            type: 'object',
            properties: {
                type: { type: 'string', const: 'MULTIPLE_CHOICE' },
                choiceUuid: { type: 'string' },
                otherValue: { type: ['string', 'null'] },
                rank: { type: ['number', 'null'] },
            },
            required: ['type', 'choiceUuid', 'otherValue', 'rank'],
            additionalProperties: false,
        },
        PreferenceResponse: {
            type: 'object',
            additionalProperties: false,
            properties: {
                type: { type: 'string', const: 'STEP_PREFERENCE_TEST' },
                answers: {
                    type: 'array',
                    items: { $ref: '#/definitions/MultipleChoiceAnswer' },
                },
                stepUuid: { type: 'string' },
                stepRevision: { type: 'number' },
                skipped: { type: 'boolean' },
                duration: { type: ['number', 'null'] },
            },
            required: [
                'answers',
                'duration',
                'skipped',
                'stepRevision',
                'stepUuid',
                'type',
            ],
        },
        TaglineCopyTestResponse: {
            type: 'object',
            additionalProperties: false,
            properties: {
                type: { type: 'string', const: 'STEP_TAGLINE_COPY_TEST' },
                answers: {
                    type: 'array',
                    items: { $ref: '#/definitions/MultipleChoiceAnswer' },
                },
                stepUuid: { type: 'string' },
                stepRevision: { type: 'number' },
                skipped: { type: 'boolean' },
                duration: { type: ['number', 'null'] },
            },
            required: [
                'answers',
                'duration',
                'skipped',
                'stepRevision',
                'stepUuid',
                'type',
            ],
        },
        PrototypeTaskResponse: {
            type: 'object',
            additionalProperties: false,
            properties: {
                type: { type: 'string', const: 'STEP_PROTOTYPE_TASK' },
                stats: {
                    anyOf: [
                        {
                            type: 'object',
                            properties: {
                                goalHit: { type: ['boolean', 'null'] },
                                misclickRate: { type: ['number', 'null'] },
                                durationSeconds: { type: ['number', 'null'] },
                                clicksPerResult: { type: ['number', 'null'] },
                            },
                            required: [
                                'goalHit',
                                'misclickRate',
                                'durationSeconds',
                                'clicksPerResult',
                            ],
                            additionalProperties: false,
                        },
                        { type: 'null' },
                    ],
                },
                stepUuid: { type: 'string' },
                stepRevision: { type: 'number' },
                skipped: { type: 'boolean' },
                duration: { type: ['number', 'null'] },
            },
            required: [
                'duration',
                'skipped',
                'stats',
                'stepRevision',
                'stepUuid',
                'type',
            ],
        },
        StepQuestionResponse: {
            type: 'object',
            additionalProperties: false,
            properties: {
                type: { type: 'string', const: 'STEP_QUESTION' },
                answers: {
                    type: 'array',
                    items: {
                        anyOf: [
                            { $ref: '#/definitions/EmailAnswer' },
                            { $ref: '#/definitions/TextAnswer' },
                            { $ref: '#/definitions/IntegerAnswer' },
                            { $ref: '#/definitions/DateTimeAnswer' },
                        ],
                    },
                },
                stepUuid: { type: 'string' },
                stepRevision: { type: 'number' },
                skipped: { type: 'boolean' },
                duration: { type: ['number', 'null'] },
            },
            required: [
                'answers',
                'duration',
                'skipped',
                'stepRevision',
                'stepUuid',
                'type',
            ],
        },
        EmailAnswer: {
            type: 'object',
            properties: {
                type: { type: 'string', const: 'EMAIL' },
                value: { type: 'string' },
            },
            required: ['type', 'value'],
            additionalProperties: false,
        },
        TextAnswer: {
            type: 'object',
            properties: {
                type: { type: 'string', const: 'TEXT' },
                value: { type: 'string' },
            },
            required: ['type', 'value'],
            additionalProperties: false,
        },
        IntegerAnswer: {
            type: 'object',
            properties: {
                type: { type: 'string', const: 'INTEGER' },
                value: { type: 'number' },
            },
            required: ['type', 'value'],
            additionalProperties: false,
        },
        DateTimeAnswer: {
            type: 'object',
            properties: {
                type: { type: 'string', const: 'DATETIME' },
                value: { type: 'string' },
            },
            required: ['type', 'value'],
            additionalProperties: false,
        },
        RatingResponse: {
            type: 'object',
            additionalProperties: false,
            properties: {
                type: { type: 'string', const: 'STEP_RATING_SCALE' },
                answers: {
                    type: 'array',
                    items: { $ref: '#/definitions/IntegerAnswer' },
                },
                stepUuid: { type: 'string' },
                stepRevision: { type: 'number' },
                skipped: { type: 'boolean' },
                duration: { type: ['number', 'null'] },
            },
            required: [
                'answers',
                'duration',
                'skipped',
                'stepRevision',
                'stepUuid',
                'type',
            ],
        },
        WebsiteTaskResponse: {
            type: 'object',
            additionalProperties: false,
            properties: {
                type: { type: 'string', const: 'STEP_WEBSITE_TASK' },
                downloadRecording: {
                    anyOf: [
                        {
                            type: 'object',
                            properties: {
                                pk: { type: 'number' },
                                videoUrl: { type: ['string', 'null'] },
                                uploadStatus: {
                                    type: 'string',
                                    enum: [
                                        'UPLOAD_FAILED',
                                        'UPLOAD_PENDING',
                                        'UPLOAD_SUCCESS',
                                        'UPLOAD_IN_PROGRESS',
                                        'UPLOAD_ACQUIRING_MEDIA',
                                    ],
                                },
                                failedStatus: { type: ['string', 'null'] },
                                failedtype: { type: ['string', 'null'] },
                                permissions: {
                                    anyOf: [
                                        { $ref: '#/definitions/RecordingPermissions' },
                                        { type: 'null' },
                                    ],
                                },
                                startTime: { type: ['string', 'null'] },
                                missingDataWarning: { type: 'boolean' },
                                duration: { type: ['number', 'null'] },
                                width: { type: ['number', 'null'] },
                                height: { type: ['number', 'null'] },
                            },
                            required: [
                                'pk',
                                'videoUrl',
                                'uploadStatus',
                                'failedStatus',
                                'failedtype',
                                'permissions',
                                'startTime',
                                'missingDataWarning',
                                'duration',
                                'width',
                                'height',
                            ],
                            additionalProperties: false,
                        },
                        { type: 'null' },
                    ],
                },
                stepUuid: { type: 'string' },
                stepRevision: { type: 'number' },
                skipped: { type: 'boolean' },
                duration: { type: ['number', 'null'] },
            },
            required: ['duration', 'skipped', 'stepRevision', 'stepUuid', 'type'],
        },
        RecordingPermissions: {
            type: 'object',
            properties: {
                microphone: { type: 'boolean' },
                webcam: { type: 'boolean' },
                screen: { type: 'boolean' },
            },
            required: ['microphone', 'webcam', 'screen'],
            additionalProperties: false,
        },
        CardSortResponse: {
            type: 'object',
            additionalProperties: false,
            properties: {
                type: {
                    type: 'string',
                    enum: [
                        'STEP_CARDSORT_HYBRID',
                        'STEP_CARDSORT_OPEN',
                        'STEP_CARDSORT_CLOSED',
                    ],
                },
                answers: { type: 'array', items: { $ref: '#/definitions/TextAnswer' } },
                duration: { type: ['number', 'null'] },
                stepUuid: { type: 'string' },
                stepRevision: { type: 'number' },
                skipped: { type: 'boolean' },
            },
            required: [
                'answers',
                'duration',
                'skipped',
                'stepRevision',
                'stepUuid',
                'type',
            ],
        },
        ParticipantSource: {
            type: 'string',
            enum: ['Rally', 'Share', 'Enhanced', 'Enhanced B2B', 'Standard'],
        },
    },
};
var schema28 = {
    type: 'object',
    properties: {
        pk: { type: 'number' },
        uuid: { type: 'string' },
        responses: {
            type: 'array',
            items: { $ref: '#/definitions/UserTestResponse' },
        },
    },
    required: ['pk', 'uuid', 'responses'],
    additionalProperties: false,
};
var schema29 = {
    type: 'object',
    properties: {
        pk: { type: 'number' },
        participant: {
            type: 'object',
            properties: { name: { type: 'string' } },
            required: ['name'],
            additionalProperties: false,
        },
        submittedAt: { type: 'string' },
        device: {
            type: 'object',
            properties: {
                model: { type: ['string', 'null'] },
                operatingSystem: { type: ['string', 'null'] },
                make: {
                    type: ['string', 'null'],
                    enum: ['Web', 'Tablet', 'Mobile', null],
                },
            },
            required: ['model', 'operatingSystem', 'make'],
            additionalProperties: false,
        },
        hasRecording: { type: 'boolean' },
        status: { $ref: '#/definitions/UserTestResponseStatus' },
        stepResponses: {
            type: 'array',
            items: { $ref: '#/definitions/StepResponse' },
        },
        responseUUID: { type: 'string' },
        recruitment: {
            type: 'object',
            properties: {
                participantSource: { $ref: '#/definitions/ParticipantSource' },
                participantId: { type: ['string', 'null'] },
                studyId: { type: ['string', 'null'] },
            },
            required: ['participantSource', 'participantId', 'studyId'],
            additionalProperties: false,
        },
    },
    required: [
        'pk',
        'participant',
        'submittedAt',
        'device',
        'hasRecording',
        'status',
        'stepResponses',
        'responseUUID',
        'recruitment',
    ],
    additionalProperties: false,
};
var schema30 = {
    type: 'string',
    enum: [
        'COMPLETED',
        'FAILED',
        'INCOMPLETE',
        'IN_PROGRESS',
        'QUALIFIED_VIEWED',
        'SCREENING_ABANDONED',
        'SCREENING_DECLINED',
        'SCREENING_STARTED',
    ],
};
var schema55 = {
    type: 'string',
    enum: ['Rally', 'Share', 'Enhanced', 'Enhanced B2B', 'Standard'],
};
var func2 = Object.prototype.hasOwnProperty;
var schema31 = {
    anyOf: [
        { $ref: '#/definitions/InstructionResponse' },
        { $ref: '#/definitions/FiveSecondsResponse' },
        { $ref: '#/definitions/YesOrNoResponse' },
        { $ref: '#/definitions/MultipleChoiceResponse' },
        { $ref: '#/definitions/PreferenceResponse' },
        { $ref: '#/definitions/TaglineCopyTestResponse' },
        { $ref: '#/definitions/PrototypeTaskResponse' },
        { $ref: '#/definitions/StepQuestionResponse' },
        { $ref: '#/definitions/RatingResponse' },
        { $ref: '#/definitions/WebsiteTaskResponse' },
        { $ref: '#/definitions/CardSortResponse' },
    ],
};
var schema32 = {
    type: 'object',
    additionalProperties: false,
    properties: {
        type: { type: 'string', const: 'STEP_INSTRUCTION' },
        stepUuid: { type: 'string' },
        stepRevision: { type: 'number' },
        skipped: { type: 'boolean' },
        duration: { type: ['number', 'null'] },
    },
    required: ['duration', 'skipped', 'stepRevision', 'stepUuid', 'type'],
};
var schema43 = {
    type: 'object',
    additionalProperties: false,
    properties: {
        type: { type: 'string', const: 'STEP_PROTOTYPE_TASK' },
        stats: {
            anyOf: [
                {
                    type: 'object',
                    properties: {
                        goalHit: { type: ['boolean', 'null'] },
                        misclickRate: { type: ['number', 'null'] },
                        durationSeconds: { type: ['number', 'null'] },
                        clicksPerResult: { type: ['number', 'null'] },
                    },
                    required: [
                        'goalHit',
                        'misclickRate',
                        'durationSeconds',
                        'clicksPerResult',
                    ],
                    additionalProperties: false,
                },
                { type: 'null' },
            ],
        },
        stepUuid: { type: 'string' },
        stepRevision: { type: 'number' },
        skipped: { type: 'boolean' },
        duration: { type: ['number', 'null'] },
    },
    required: [
        'duration',
        'skipped',
        'stats',
        'stepRevision',
        'stepUuid',
        'type',
    ],
};
var schema33 = {
    type: 'object',
    additionalProperties: false,
    properties: {
        type: { type: 'string', const: 'STEP_FIVE_SECOND_TEST' },
        answers: { type: 'array', items: { $ref: '#/definitions/BooleanAnswer' } },
        stepUuid: { type: 'string' },
        stepRevision: { type: 'number' },
        skipped: { type: 'boolean' },
        duration: { type: ['number', 'null'] },
    },
    required: [
        'answers',
        'duration',
        'skipped',
        'stepRevision',
        'stepUuid',
        'type',
    ],
};
var schema34 = {
    type: 'object',
    properties: {
        type: { type: 'string', const: 'BOOLEAN' },
        value: { type: 'boolean' },
    },
    required: ['type', 'value'],
    additionalProperties: false,
};
function validate14(data, _a) {
    var _b = _a === void 0 ? {} : _a, _c = _b.instancePath, instancePath = _c === void 0 ? '' : _c, parentData = _b.parentData, parentDataProperty = _b.parentDataProperty, _d = _b.rootData, rootData = _d === void 0 ? data : _d;
    var vErrors = null;
    var errors = 0;
    if (errors === 0) {
        if (data && typeof data == 'object' && !Array.isArray(data)) {
            var missing0 = void 0;
            if ((data.answers === undefined && (missing0 = 'answers')) ||
                (data.duration === undefined && (missing0 = 'duration')) ||
                (data.skipped === undefined && (missing0 = 'skipped')) ||
                (data.stepRevision === undefined && (missing0 = 'stepRevision')) ||
                (data.stepUuid === undefined && (missing0 = 'stepUuid')) ||
                (data.type === undefined && (missing0 = 'type'))) {
                validate14.errors = [
                    {
                        instancePath: instancePath,
                        schemaPath: '#/required',
                        keyword: 'required',
                        params: { missingProperty: missing0 },
                        message: "must have required property '" + missing0 + "'",
                    },
                ];
                return false;
            }
            else {
                var _errs1 = errors;
                for (var key0 in data) {
                    if (!(key0 === 'type' ||
                        key0 === 'answers' ||
                        key0 === 'stepUuid' ||
                        key0 === 'stepRevision' ||
                        key0 === 'skipped' ||
                        key0 === 'duration')) {
                        validate14.errors = [
                            {
                                instancePath: instancePath,
                                schemaPath: '#/additionalProperties',
                                keyword: 'additionalProperties',
                                params: { additionalProperty: key0 },
                                message: 'must NOT have additional properties',
                            },
                        ];
                        return false;
                        break;
                    }
                }
                if (_errs1 === errors) {
                    if (data.type !== undefined) {
                        var data0 = data.type;
                        var _errs2 = errors;
                        if (typeof data0 !== 'string') {
                            validate14.errors = [
                                {
                                    instancePath: instancePath + '/type',
                                    schemaPath: '#/properties/type/type',
                                    keyword: 'type',
                                    params: { type: 'string' },
                                    message: 'must be string',
                                },
                            ];
                            return false;
                        }
                        if ('STEP_FIVE_SECOND_TEST' !== data0) {
                            validate14.errors = [
                                {
                                    instancePath: instancePath + '/type',
                                    schemaPath: '#/properties/type/const',
                                    keyword: 'const',
                                    params: { allowedValue: 'STEP_FIVE_SECOND_TEST' },
                                    message: 'must be equal to constant',
                                },
                            ];
                            return false;
                        }
                        var valid0 = _errs2 === errors;
                    }
                    else {
                        var valid0 = true;
                    }
                    if (valid0) {
                        if (data.answers !== undefined) {
                            var data1 = data.answers;
                            var _errs4 = errors;
                            if (errors === _errs4) {
                                if (Array.isArray(data1)) {
                                    var valid1 = true;
                                    var len0 = data1.length;
                                    for (var i0 = 0; i0 < len0; i0++) {
                                        var data2 = data1[i0];
                                        var _errs6 = errors;
                                        var _errs7 = errors;
                                        if (errors === _errs7) {
                                            if (data2 &&
                                                typeof data2 == 'object' &&
                                                !Array.isArray(data2)) {
                                                var missing1 = void 0;
                                                if ((data2.type === undefined && (missing1 = 'type')) ||
                                                    (data2.value === undefined && (missing1 = 'value'))) {
                                                    validate14.errors = [
                                                        {
                                                            instancePath: instancePath + '/answers/' + i0,
                                                            schemaPath: '#/definitions/BooleanAnswer/required',
                                                            keyword: 'required',
                                                            params: { missingProperty: missing1 },
                                                            message: "must have required property '" +
                                                                missing1 +
                                                                "'",
                                                        },
                                                    ];
                                                    return false;
                                                }
                                                else {
                                                    var _errs9 = errors;
                                                    for (var key1 in data2) {
                                                        if (!(key1 === 'type' || key1 === 'value')) {
                                                            validate14.errors = [
                                                                {
                                                                    instancePath: instancePath + '/answers/' + i0,
                                                                    schemaPath: '#/definitions/BooleanAnswer/additionalProperties',
                                                                    keyword: 'additionalProperties',
                                                                    params: { additionalProperty: key1 },
                                                                    message: 'must NOT have additional properties',
                                                                },
                                                            ];
                                                            return false;
                                                            break;
                                                        }
                                                    }
                                                    if (_errs9 === errors) {
                                                        if (data2.type !== undefined) {
                                                            var data3 = data2.type;
                                                            var _errs10 = errors;
                                                            if (typeof data3 !== 'string') {
                                                                validate14.errors = [
                                                                    {
                                                                        instancePath: instancePath + '/answers/' + i0 + '/type',
                                                                        schemaPath: '#/definitions/BooleanAnswer/properties/type/type',
                                                                        keyword: 'type',
                                                                        params: { type: 'string' },
                                                                        message: 'must be string',
                                                                    },
                                                                ];
                                                                return false;
                                                            }
                                                            if ('BOOLEAN' !== data3) {
                                                                validate14.errors = [
                                                                    {
                                                                        instancePath: instancePath + '/answers/' + i0 + '/type',
                                                                        schemaPath: '#/definitions/BooleanAnswer/properties/type/const',
                                                                        keyword: 'const',
                                                                        params: { allowedValue: 'BOOLEAN' },
                                                                        message: 'must be equal to constant',
                                                                    },
                                                                ];
                                                                return false;
                                                            }
                                                            var valid3 = _errs10 === errors;
                                                        }
                                                        else {
                                                            var valid3 = true;
                                                        }
                                                        if (valid3) {
                                                            if (data2.value !== undefined) {
                                                                var _errs12 = errors;
                                                                if (typeof data2.value !== 'boolean') {
                                                                    validate14.errors = [
                                                                        {
                                                                            instancePath: instancePath +
                                                                                '/answers/' +
                                                                                i0 +
                                                                                '/value',
                                                                            schemaPath: '#/definitions/BooleanAnswer/properties/value/type',
                                                                            keyword: 'type',
                                                                            params: { type: 'boolean' },
                                                                            message: 'must be boolean',
                                                                        },
                                                                    ];
                                                                    return false;
                                                                }
                                                                var valid3 = _errs12 === errors;
                                                            }
                                                            else {
                                                                var valid3 = true;
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                            else {
                                                validate14.errors = [
                                                    {
                                                        instancePath: instancePath + '/answers/' + i0,
                                                        schemaPath: '#/definitions/BooleanAnswer/type',
                                                        keyword: 'type',
                                                        params: { type: 'object' },
                                                        message: 'must be object',
                                                    },
                                                ];
                                                return false;
                                            }
                                        }
                                        var valid1 = _errs6 === errors;
                                        if (!valid1) {
                                            break;
                                        }
                                    }
                                }
                                else {
                                    validate14.errors = [
                                        {
                                            instancePath: instancePath + '/answers',
                                            schemaPath: '#/properties/answers/type',
                                            keyword: 'type',
                                            params: { type: 'array' },
                                            message: 'must be array',
                                        },
                                    ];
                                    return false;
                                }
                            }
                            var valid0 = _errs4 === errors;
                        }
                        else {
                            var valid0 = true;
                        }
                        if (valid0) {
                            if (data.stepUuid !== undefined) {
                                var _errs14 = errors;
                                if (typeof data.stepUuid !== 'string') {
                                    validate14.errors = [
                                        {
                                            instancePath: instancePath + '/stepUuid',
                                            schemaPath: '#/properties/stepUuid/type',
                                            keyword: 'type',
                                            params: { type: 'string' },
                                            message: 'must be string',
                                        },
                                    ];
                                    return false;
                                }
                                var valid0 = _errs14 === errors;
                            }
                            else {
                                var valid0 = true;
                            }
                            if (valid0) {
                                if (data.stepRevision !== undefined) {
                                    var data6 = data.stepRevision;
                                    var _errs16 = errors;
                                    if (!(typeof data6 == 'number' && isFinite(data6))) {
                                        validate14.errors = [
                                            {
                                                instancePath: instancePath + '/stepRevision',
                                                schemaPath: '#/properties/stepRevision/type',
                                                keyword: 'type',
                                                params: { type: 'number' },
                                                message: 'must be number',
                                            },
                                        ];
                                        return false;
                                    }
                                    var valid0 = _errs16 === errors;
                                }
                                else {
                                    var valid0 = true;
                                }
                                if (valid0) {
                                    if (data.skipped !== undefined) {
                                        var _errs18 = errors;
                                        if (typeof data.skipped !== 'boolean') {
                                            validate14.errors = [
                                                {
                                                    instancePath: instancePath + '/skipped',
                                                    schemaPath: '#/properties/skipped/type',
                                                    keyword: 'type',
                                                    params: { type: 'boolean' },
                                                    message: 'must be boolean',
                                                },
                                            ];
                                            return false;
                                        }
                                        var valid0 = _errs18 === errors;
                                    }
                                    else {
                                        var valid0 = true;
                                    }
                                    if (valid0) {
                                        if (data.duration !== undefined) {
                                            var data8 = data.duration;
                                            var _errs20 = errors;
                                            if (!(typeof data8 == 'number' && isFinite(data8)) &&
                                                data8 !== null) {
                                                validate14.errors = [
                                                    {
                                                        instancePath: instancePath + '/duration',
                                                        schemaPath: '#/properties/duration/type',
                                                        keyword: 'type',
                                                        params: { type: schema33.properties.duration.type },
                                                        message: 'must be number,null',
                                                    },
                                                ];
                                                return false;
                                            }
                                            var valid0 = _errs20 === errors;
                                        }
                                        else {
                                            var valid0 = true;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        else {
            validate14.errors = [
                {
                    instancePath: instancePath,
                    schemaPath: '#/type',
                    keyword: 'type',
                    params: { type: 'object' },
                    message: 'must be object',
                },
            ];
            return false;
        }
    }
    validate14.errors = vErrors;
    return errors === 0;
}
var schema35 = {
    type: 'object',
    additionalProperties: false,
    properties: {
        type: { type: 'string', const: 'STEP_YES_OR_NO' },
        answers: { type: 'array', items: { $ref: '#/definitions/BooleanAnswer' } },
        stepUuid: { type: 'string' },
        stepRevision: { type: 'number' },
        skipped: { type: 'boolean' },
        duration: { type: ['number', 'null'] },
    },
    required: [
        'answers',
        'duration',
        'skipped',
        'stepRevision',
        'stepUuid',
        'type',
    ],
};
function validate16(data, _a) {
    var _b = _a === void 0 ? {} : _a, _c = _b.instancePath, instancePath = _c === void 0 ? '' : _c, parentData = _b.parentData, parentDataProperty = _b.parentDataProperty, _d = _b.rootData, rootData = _d === void 0 ? data : _d;
    var vErrors = null;
    var errors = 0;
    if (errors === 0) {
        if (data && typeof data == 'object' && !Array.isArray(data)) {
            var missing0 = void 0;
            if ((data.answers === undefined && (missing0 = 'answers')) ||
                (data.duration === undefined && (missing0 = 'duration')) ||
                (data.skipped === undefined && (missing0 = 'skipped')) ||
                (data.stepRevision === undefined && (missing0 = 'stepRevision')) ||
                (data.stepUuid === undefined && (missing0 = 'stepUuid')) ||
                (data.type === undefined && (missing0 = 'type'))) {
                validate16.errors = [
                    {
                        instancePath: instancePath,
                        schemaPath: '#/required',
                        keyword: 'required',
                        params: { missingProperty: missing0 },
                        message: "must have required property '" + missing0 + "'",
                    },
                ];
                return false;
            }
            else {
                var _errs1 = errors;
                for (var key0 in data) {
                    if (!(key0 === 'type' ||
                        key0 === 'answers' ||
                        key0 === 'stepUuid' ||
                        key0 === 'stepRevision' ||
                        key0 === 'skipped' ||
                        key0 === 'duration')) {
                        validate16.errors = [
                            {
                                instancePath: instancePath,
                                schemaPath: '#/additionalProperties',
                                keyword: 'additionalProperties',
                                params: { additionalProperty: key0 },
                                message: 'must NOT have additional properties',
                            },
                        ];
                        return false;
                        break;
                    }
                }
                if (_errs1 === errors) {
                    if (data.type !== undefined) {
                        var data0 = data.type;
                        var _errs2 = errors;
                        if (typeof data0 !== 'string') {
                            validate16.errors = [
                                {
                                    instancePath: instancePath + '/type',
                                    schemaPath: '#/properties/type/type',
                                    keyword: 'type',
                                    params: { type: 'string' },
                                    message: 'must be string',
                                },
                            ];
                            return false;
                        }
                        if ('STEP_YES_OR_NO' !== data0) {
                            validate16.errors = [
                                {
                                    instancePath: instancePath + '/type',
                                    schemaPath: '#/properties/type/const',
                                    keyword: 'const',
                                    params: { allowedValue: 'STEP_YES_OR_NO' },
                                    message: 'must be equal to constant',
                                },
                            ];
                            return false;
                        }
                        var valid0 = _errs2 === errors;
                    }
                    else {
                        var valid0 = true;
                    }
                    if (valid0) {
                        if (data.answers !== undefined) {
                            var data1 = data.answers;
                            var _errs4 = errors;
                            if (errors === _errs4) {
                                if (Array.isArray(data1)) {
                                    var valid1 = true;
                                    var len0 = data1.length;
                                    for (var i0 = 0; i0 < len0; i0++) {
                                        var data2 = data1[i0];
                                        var _errs6 = errors;
                                        var _errs7 = errors;
                                        if (errors === _errs7) {
                                            if (data2 &&
                                                typeof data2 == 'object' &&
                                                !Array.isArray(data2)) {
                                                var missing1 = void 0;
                                                if ((data2.type === undefined && (missing1 = 'type')) ||
                                                    (data2.value === undefined && (missing1 = 'value'))) {
                                                    validate16.errors = [
                                                        {
                                                            instancePath: instancePath + '/answers/' + i0,
                                                            schemaPath: '#/definitions/BooleanAnswer/required',
                                                            keyword: 'required',
                                                            params: { missingProperty: missing1 },
                                                            message: "must have required property '" +
                                                                missing1 +
                                                                "'",
                                                        },
                                                    ];
                                                    return false;
                                                }
                                                else {
                                                    var _errs9 = errors;
                                                    for (var key1 in data2) {
                                                        if (!(key1 === 'type' || key1 === 'value')) {
                                                            validate16.errors = [
                                                                {
                                                                    instancePath: instancePath + '/answers/' + i0,
                                                                    schemaPath: '#/definitions/BooleanAnswer/additionalProperties',
                                                                    keyword: 'additionalProperties',
                                                                    params: { additionalProperty: key1 },
                                                                    message: 'must NOT have additional properties',
                                                                },
                                                            ];
                                                            return false;
                                                            break;
                                                        }
                                                    }
                                                    if (_errs9 === errors) {
                                                        if (data2.type !== undefined) {
                                                            var data3 = data2.type;
                                                            var _errs10 = errors;
                                                            if (typeof data3 !== 'string') {
                                                                validate16.errors = [
                                                                    {
                                                                        instancePath: instancePath + '/answers/' + i0 + '/type',
                                                                        schemaPath: '#/definitions/BooleanAnswer/properties/type/type',
                                                                        keyword: 'type',
                                                                        params: { type: 'string' },
                                                                        message: 'must be string',
                                                                    },
                                                                ];
                                                                return false;
                                                            }
                                                            if ('BOOLEAN' !== data3) {
                                                                validate16.errors = [
                                                                    {
                                                                        instancePath: instancePath + '/answers/' + i0 + '/type',
                                                                        schemaPath: '#/definitions/BooleanAnswer/properties/type/const',
                                                                        keyword: 'const',
                                                                        params: { allowedValue: 'BOOLEAN' },
                                                                        message: 'must be equal to constant',
                                                                    },
                                                                ];
                                                                return false;
                                                            }
                                                            var valid3 = _errs10 === errors;
                                                        }
                                                        else {
                                                            var valid3 = true;
                                                        }
                                                        if (valid3) {
                                                            if (data2.value !== undefined) {
                                                                var _errs12 = errors;
                                                                if (typeof data2.value !== 'boolean') {
                                                                    validate16.errors = [
                                                                        {
                                                                            instancePath: instancePath +
                                                                                '/answers/' +
                                                                                i0 +
                                                                                '/value',
                                                                            schemaPath: '#/definitions/BooleanAnswer/properties/value/type',
                                                                            keyword: 'type',
                                                                            params: { type: 'boolean' },
                                                                            message: 'must be boolean',
                                                                        },
                                                                    ];
                                                                    return false;
                                                                }
                                                                var valid3 = _errs12 === errors;
                                                            }
                                                            else {
                                                                var valid3 = true;
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                            else {
                                                validate16.errors = [
                                                    {
                                                        instancePath: instancePath + '/answers/' + i0,
                                                        schemaPath: '#/definitions/BooleanAnswer/type',
                                                        keyword: 'type',
                                                        params: { type: 'object' },
                                                        message: 'must be object',
                                                    },
                                                ];
                                                return false;
                                            }
                                        }
                                        var valid1 = _errs6 === errors;
                                        if (!valid1) {
                                            break;
                                        }
                                    }
                                }
                                else {
                                    validate16.errors = [
                                        {
                                            instancePath: instancePath + '/answers',
                                            schemaPath: '#/properties/answers/type',
                                            keyword: 'type',
                                            params: { type: 'array' },
                                            message: 'must be array',
                                        },
                                    ];
                                    return false;
                                }
                            }
                            var valid0 = _errs4 === errors;
                        }
                        else {
                            var valid0 = true;
                        }
                        if (valid0) {
                            if (data.stepUuid !== undefined) {
                                var _errs14 = errors;
                                if (typeof data.stepUuid !== 'string') {
                                    validate16.errors = [
                                        {
                                            instancePath: instancePath + '/stepUuid',
                                            schemaPath: '#/properties/stepUuid/type',
                                            keyword: 'type',
                                            params: { type: 'string' },
                                            message: 'must be string',
                                        },
                                    ];
                                    return false;
                                }
                                var valid0 = _errs14 === errors;
                            }
                            else {
                                var valid0 = true;
                            }
                            if (valid0) {
                                if (data.stepRevision !== undefined) {
                                    var data6 = data.stepRevision;
                                    var _errs16 = errors;
                                    if (!(typeof data6 == 'number' && isFinite(data6))) {
                                        validate16.errors = [
                                            {
                                                instancePath: instancePath + '/stepRevision',
                                                schemaPath: '#/properties/stepRevision/type',
                                                keyword: 'type',
                                                params: { type: 'number' },
                                                message: 'must be number',
                                            },
                                        ];
                                        return false;
                                    }
                                    var valid0 = _errs16 === errors;
                                }
                                else {
                                    var valid0 = true;
                                }
                                if (valid0) {
                                    if (data.skipped !== undefined) {
                                        var _errs18 = errors;
                                        if (typeof data.skipped !== 'boolean') {
                                            validate16.errors = [
                                                {
                                                    instancePath: instancePath + '/skipped',
                                                    schemaPath: '#/properties/skipped/type',
                                                    keyword: 'type',
                                                    params: { type: 'boolean' },
                                                    message: 'must be boolean',
                                                },
                                            ];
                                            return false;
                                        }
                                        var valid0 = _errs18 === errors;
                                    }
                                    else {
                                        var valid0 = true;
                                    }
                                    if (valid0) {
                                        if (data.duration !== undefined) {
                                            var data8 = data.duration;
                                            var _errs20 = errors;
                                            if (!(typeof data8 == 'number' && isFinite(data8)) &&
                                                data8 !== null) {
                                                validate16.errors = [
                                                    {
                                                        instancePath: instancePath + '/duration',
                                                        schemaPath: '#/properties/duration/type',
                                                        keyword: 'type',
                                                        params: { type: schema35.properties.duration.type },
                                                        message: 'must be number,null',
                                                    },
                                                ];
                                                return false;
                                            }
                                            var valid0 = _errs20 === errors;
                                        }
                                        else {
                                            var valid0 = true;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        else {
            validate16.errors = [
                {
                    instancePath: instancePath,
                    schemaPath: '#/type',
                    keyword: 'type',
                    params: { type: 'object' },
                    message: 'must be object',
                },
            ];
            return false;
        }
    }
    validate16.errors = vErrors;
    return errors === 0;
}
var schema37 = {
    type: 'object',
    additionalProperties: false,
    properties: {
        type: { type: 'string', const: 'STEP_MULTIPLE_CHOICE' },
        answers: {
            type: 'array',
            items: { $ref: '#/definitions/MultipleChoiceAnswer' },
        },
        stepUuid: { type: 'string' },
        stepRevision: { type: 'number' },
        skipped: { type: 'boolean' },
        duration: { type: ['number', 'null'] },
    },
    required: [
        'answers',
        'duration',
        'skipped',
        'stepRevision',
        'stepUuid',
        'type',
    ],
};
var schema38 = {
    type: 'object',
    properties: {
        type: { type: 'string', const: 'MULTIPLE_CHOICE' },
        choiceUuid: { type: 'string' },
        otherValue: { type: ['string', 'null'] },
        rank: { type: ['number', 'null'] },
    },
    required: ['type', 'choiceUuid', 'otherValue', 'rank'],
    additionalProperties: false,
};
function validate18(data, _a) {
    var _b = _a === void 0 ? {} : _a, _c = _b.instancePath, instancePath = _c === void 0 ? '' : _c, parentData = _b.parentData, parentDataProperty = _b.parentDataProperty, _d = _b.rootData, rootData = _d === void 0 ? data : _d;
    var vErrors = null;
    var errors = 0;
    if (errors === 0) {
        if (data && typeof data == 'object' && !Array.isArray(data)) {
            var missing0 = void 0;
            if ((data.answers === undefined && (missing0 = 'answers')) ||
                (data.duration === undefined && (missing0 = 'duration')) ||
                (data.skipped === undefined && (missing0 = 'skipped')) ||
                (data.stepRevision === undefined && (missing0 = 'stepRevision')) ||
                (data.stepUuid === undefined && (missing0 = 'stepUuid')) ||
                (data.type === undefined && (missing0 = 'type'))) {
                validate18.errors = [
                    {
                        instancePath: instancePath,
                        schemaPath: '#/required',
                        keyword: 'required',
                        params: { missingProperty: missing0 },
                        message: "must have required property '" + missing0 + "'",
                    },
                ];
                return false;
            }
            else {
                var _errs1 = errors;
                for (var key0 in data) {
                    if (!(key0 === 'type' ||
                        key0 === 'answers' ||
                        key0 === 'stepUuid' ||
                        key0 === 'stepRevision' ||
                        key0 === 'skipped' ||
                        key0 === 'duration')) {
                        validate18.errors = [
                            {
                                instancePath: instancePath,
                                schemaPath: '#/additionalProperties',
                                keyword: 'additionalProperties',
                                params: { additionalProperty: key0 },
                                message: 'must NOT have additional properties',
                            },
                        ];
                        return false;
                        break;
                    }
                }
                if (_errs1 === errors) {
                    if (data.type !== undefined) {
                        var data0 = data.type;
                        var _errs2 = errors;
                        if (typeof data0 !== 'string') {
                            validate18.errors = [
                                {
                                    instancePath: instancePath + '/type',
                                    schemaPath: '#/properties/type/type',
                                    keyword: 'type',
                                    params: { type: 'string' },
                                    message: 'must be string',
                                },
                            ];
                            return false;
                        }
                        if ('STEP_MULTIPLE_CHOICE' !== data0) {
                            validate18.errors = [
                                {
                                    instancePath: instancePath + '/type',
                                    schemaPath: '#/properties/type/const',
                                    keyword: 'const',
                                    params: { allowedValue: 'STEP_MULTIPLE_CHOICE' },
                                    message: 'must be equal to constant',
                                },
                            ];
                            return false;
                        }
                        var valid0 = _errs2 === errors;
                    }
                    else {
                        var valid0 = true;
                    }
                    if (valid0) {
                        if (data.answers !== undefined) {
                            var data1 = data.answers;
                            var _errs4 = errors;
                            if (errors === _errs4) {
                                if (Array.isArray(data1)) {
                                    var valid1 = true;
                                    var len0 = data1.length;
                                    for (var i0 = 0; i0 < len0; i0++) {
                                        var data2 = data1[i0];
                                        var _errs6 = errors;
                                        var _errs7 = errors;
                                        if (errors === _errs7) {
                                            if (data2 &&
                                                typeof data2 == 'object' &&
                                                !Array.isArray(data2)) {
                                                var missing1 = void 0;
                                                if ((data2.type === undefined && (missing1 = 'type')) ||
                                                    (data2.choiceUuid === undefined &&
                                                        (missing1 = 'choiceUuid')) ||
                                                    (data2.otherValue === undefined &&
                                                        (missing1 = 'otherValue')) ||
                                                    (data2.rank === undefined && (missing1 = 'rank'))) {
                                                    validate18.errors = [
                                                        {
                                                            instancePath: instancePath + '/answers/' + i0,
                                                            schemaPath: '#/definitions/MultipleChoiceAnswer/required',
                                                            keyword: 'required',
                                                            params: { missingProperty: missing1 },
                                                            message: "must have required property '" +
                                                                missing1 +
                                                                "'",
                                                        },
                                                    ];
                                                    return false;
                                                }
                                                else {
                                                    var _errs9 = errors;
                                                    for (var key1 in data2) {
                                                        if (!(key1 === 'type' ||
                                                            key1 === 'choiceUuid' ||
                                                            key1 === 'otherValue' ||
                                                            key1 === 'rank')) {
                                                            validate18.errors = [
                                                                {
                                                                    instancePath: instancePath + '/answers/' + i0,
                                                                    schemaPath: '#/definitions/MultipleChoiceAnswer/additionalProperties',
                                                                    keyword: 'additionalProperties',
                                                                    params: { additionalProperty: key1 },
                                                                    message: 'must NOT have additional properties',
                                                                },
                                                            ];
                                                            return false;
                                                            break;
                                                        }
                                                    }
                                                    if (_errs9 === errors) {
                                                        if (data2.type !== undefined) {
                                                            var data3 = data2.type;
                                                            var _errs10 = errors;
                                                            if (typeof data3 !== 'string') {
                                                                validate18.errors = [
                                                                    {
                                                                        instancePath: instancePath + '/answers/' + i0 + '/type',
                                                                        schemaPath: '#/definitions/MultipleChoiceAnswer/properties/type/type',
                                                                        keyword: 'type',
                                                                        params: { type: 'string' },
                                                                        message: 'must be string',
                                                                    },
                                                                ];
                                                                return false;
                                                            }
                                                            if ('MULTIPLE_CHOICE' !== data3) {
                                                                validate18.errors = [
                                                                    {
                                                                        instancePath: instancePath + '/answers/' + i0 + '/type',
                                                                        schemaPath: '#/definitions/MultipleChoiceAnswer/properties/type/const',
                                                                        keyword: 'const',
                                                                        params: { allowedValue: 'MULTIPLE_CHOICE' },
                                                                        message: 'must be equal to constant',
                                                                    },
                                                                ];
                                                                return false;
                                                            }
                                                            var valid3 = _errs10 === errors;
                                                        }
                                                        else {
                                                            var valid3 = true;
                                                        }
                                                        if (valid3) {
                                                            if (data2.choiceUuid !== undefined) {
                                                                var _errs12 = errors;
                                                                if (typeof data2.choiceUuid !== 'string') {
                                                                    validate18.errors = [
                                                                        {
                                                                            instancePath: instancePath +
                                                                                '/answers/' +
                                                                                i0 +
                                                                                '/choiceUuid',
                                                                            schemaPath: '#/definitions/MultipleChoiceAnswer/properties/choiceUuid/type',
                                                                            keyword: 'type',
                                                                            params: { type: 'string' },
                                                                            message: 'must be string',
                                                                        },
                                                                    ];
                                                                    return false;
                                                                }
                                                                var valid3 = _errs12 === errors;
                                                            }
                                                            else {
                                                                var valid3 = true;
                                                            }
                                                            if (valid3) {
                                                                if (data2.otherValue !== undefined) {
                                                                    var data5 = data2.otherValue;
                                                                    var _errs14 = errors;
                                                                    if (typeof data5 !== 'string' &&
                                                                        data5 !== null) {
                                                                        validate18.errors = [
                                                                            {
                                                                                instancePath: instancePath +
                                                                                    '/answers/' +
                                                                                    i0 +
                                                                                    '/otherValue',
                                                                                schemaPath: '#/definitions/MultipleChoiceAnswer/properties/otherValue/type',
                                                                                keyword: 'type',
                                                                                params: {
                                                                                    type: schema38.properties.otherValue
                                                                                        .type,
                                                                                },
                                                                                message: 'must be string,null',
                                                                            },
                                                                        ];
                                                                        return false;
                                                                    }
                                                                    var valid3 = _errs14 === errors;
                                                                }
                                                                else {
                                                                    var valid3 = true;
                                                                }
                                                                if (valid3) {
                                                                    if (data2.rank !== undefined) {
                                                                        var data6 = data2.rank;
                                                                        var _errs16 = errors;
                                                                        if (!(typeof data6 == 'number' &&
                                                                            isFinite(data6)) &&
                                                                            data6 !== null) {
                                                                            validate18.errors = [
                                                                                {
                                                                                    instancePath: instancePath +
                                                                                        '/answers/' +
                                                                                        i0 +
                                                                                        '/rank',
                                                                                    schemaPath: '#/definitions/MultipleChoiceAnswer/properties/rank/type',
                                                                                    keyword: 'type',
                                                                                    params: {
                                                                                        type: schema38.properties.rank.type,
                                                                                    },
                                                                                    message: 'must be number,null',
                                                                                },
                                                                            ];
                                                                            return false;
                                                                        }
                                                                        var valid3 = _errs16 === errors;
                                                                    }
                                                                    else {
                                                                        var valid3 = true;
                                                                    }
                                                                }
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                            else {
                                                validate18.errors = [
                                                    {
                                                        instancePath: instancePath + '/answers/' + i0,
                                                        schemaPath: '#/definitions/MultipleChoiceAnswer/type',
                                                        keyword: 'type',
                                                        params: { type: 'object' },
                                                        message: 'must be object',
                                                    },
                                                ];
                                                return false;
                                            }
                                        }
                                        var valid1 = _errs6 === errors;
                                        if (!valid1) {
                                            break;
                                        }
                                    }
                                }
                                else {
                                    validate18.errors = [
                                        {
                                            instancePath: instancePath + '/answers',
                                            schemaPath: '#/properties/answers/type',
                                            keyword: 'type',
                                            params: { type: 'array' },
                                            message: 'must be array',
                                        },
                                    ];
                                    return false;
                                }
                            }
                            var valid0 = _errs4 === errors;
                        }
                        else {
                            var valid0 = true;
                        }
                        if (valid0) {
                            if (data.stepUuid !== undefined) {
                                var _errs18 = errors;
                                if (typeof data.stepUuid !== 'string') {
                                    validate18.errors = [
                                        {
                                            instancePath: instancePath + '/stepUuid',
                                            schemaPath: '#/properties/stepUuid/type',
                                            keyword: 'type',
                                            params: { type: 'string' },
                                            message: 'must be string',
                                        },
                                    ];
                                    return false;
                                }
                                var valid0 = _errs18 === errors;
                            }
                            else {
                                var valid0 = true;
                            }
                            if (valid0) {
                                if (data.stepRevision !== undefined) {
                                    var data8 = data.stepRevision;
                                    var _errs20 = errors;
                                    if (!(typeof data8 == 'number' && isFinite(data8))) {
                                        validate18.errors = [
                                            {
                                                instancePath: instancePath + '/stepRevision',
                                                schemaPath: '#/properties/stepRevision/type',
                                                keyword: 'type',
                                                params: { type: 'number' },
                                                message: 'must be number',
                                            },
                                        ];
                                        return false;
                                    }
                                    var valid0 = _errs20 === errors;
                                }
                                else {
                                    var valid0 = true;
                                }
                                if (valid0) {
                                    if (data.skipped !== undefined) {
                                        var _errs22 = errors;
                                        if (typeof data.skipped !== 'boolean') {
                                            validate18.errors = [
                                                {
                                                    instancePath: instancePath + '/skipped',
                                                    schemaPath: '#/properties/skipped/type',
                                                    keyword: 'type',
                                                    params: { type: 'boolean' },
                                                    message: 'must be boolean',
                                                },
                                            ];
                                            return false;
                                        }
                                        var valid0 = _errs22 === errors;
                                    }
                                    else {
                                        var valid0 = true;
                                    }
                                    if (valid0) {
                                        if (data.duration !== undefined) {
                                            var data10 = data.duration;
                                            var _errs24 = errors;
                                            if (!(typeof data10 == 'number' && isFinite(data10)) &&
                                                data10 !== null) {
                                                validate18.errors = [
                                                    {
                                                        instancePath: instancePath + '/duration',
                                                        schemaPath: '#/properties/duration/type',
                                                        keyword: 'type',
                                                        params: { type: schema37.properties.duration.type },
                                                        message: 'must be number,null',
                                                    },
                                                ];
                                                return false;
                                            }
                                            var valid0 = _errs24 === errors;
                                        }
                                        else {
                                            var valid0 = true;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        else {
            validate18.errors = [
                {
                    instancePath: instancePath,
                    schemaPath: '#/type',
                    keyword: 'type',
                    params: { type: 'object' },
                    message: 'must be object',
                },
            ];
            return false;
        }
    }
    validate18.errors = vErrors;
    return errors === 0;
}
var schema39 = {
    type: 'object',
    additionalProperties: false,
    properties: {
        type: { type: 'string', const: 'STEP_PREFERENCE_TEST' },
        answers: {
            type: 'array',
            items: { $ref: '#/definitions/MultipleChoiceAnswer' },
        },
        stepUuid: { type: 'string' },
        stepRevision: { type: 'number' },
        skipped: { type: 'boolean' },
        duration: { type: ['number', 'null'] },
    },
    required: [
        'answers',
        'duration',
        'skipped',
        'stepRevision',
        'stepUuid',
        'type',
    ],
};
function validate20(data, _a) {
    var _b = _a === void 0 ? {} : _a, _c = _b.instancePath, instancePath = _c === void 0 ? '' : _c, parentData = _b.parentData, parentDataProperty = _b.parentDataProperty, _d = _b.rootData, rootData = _d === void 0 ? data : _d;
    var vErrors = null;
    var errors = 0;
    if (errors === 0) {
        if (data && typeof data == 'object' && !Array.isArray(data)) {
            var missing0 = void 0;
            if ((data.answers === undefined && (missing0 = 'answers')) ||
                (data.duration === undefined && (missing0 = 'duration')) ||
                (data.skipped === undefined && (missing0 = 'skipped')) ||
                (data.stepRevision === undefined && (missing0 = 'stepRevision')) ||
                (data.stepUuid === undefined && (missing0 = 'stepUuid')) ||
                (data.type === undefined && (missing0 = 'type'))) {
                validate20.errors = [
                    {
                        instancePath: instancePath,
                        schemaPath: '#/required',
                        keyword: 'required',
                        params: { missingProperty: missing0 },
                        message: "must have required property '" + missing0 + "'",
                    },
                ];
                return false;
            }
            else {
                var _errs1 = errors;
                for (var key0 in data) {
                    if (!(key0 === 'type' ||
                        key0 === 'answers' ||
                        key0 === 'stepUuid' ||
                        key0 === 'stepRevision' ||
                        key0 === 'skipped' ||
                        key0 === 'duration')) {
                        validate20.errors = [
                            {
                                instancePath: instancePath,
                                schemaPath: '#/additionalProperties',
                                keyword: 'additionalProperties',
                                params: { additionalProperty: key0 },
                                message: 'must NOT have additional properties',
                            },
                        ];
                        return false;
                        break;
                    }
                }
                if (_errs1 === errors) {
                    if (data.type !== undefined) {
                        var data0 = data.type;
                        var _errs2 = errors;
                        if (typeof data0 !== 'string') {
                            validate20.errors = [
                                {
                                    instancePath: instancePath + '/type',
                                    schemaPath: '#/properties/type/type',
                                    keyword: 'type',
                                    params: { type: 'string' },
                                    message: 'must be string',
                                },
                            ];
                            return false;
                        }
                        if ('STEP_PREFERENCE_TEST' !== data0) {
                            validate20.errors = [
                                {
                                    instancePath: instancePath + '/type',
                                    schemaPath: '#/properties/type/const',
                                    keyword: 'const',
                                    params: { allowedValue: 'STEP_PREFERENCE_TEST' },
                                    message: 'must be equal to constant',
                                },
                            ];
                            return false;
                        }
                        var valid0 = _errs2 === errors;
                    }
                    else {
                        var valid0 = true;
                    }
                    if (valid0) {
                        if (data.answers !== undefined) {
                            var data1 = data.answers;
                            var _errs4 = errors;
                            if (errors === _errs4) {
                                if (Array.isArray(data1)) {
                                    var valid1 = true;
                                    var len0 = data1.length;
                                    for (var i0 = 0; i0 < len0; i0++) {
                                        var data2 = data1[i0];
                                        var _errs6 = errors;
                                        var _errs7 = errors;
                                        if (errors === _errs7) {
                                            if (data2 &&
                                                typeof data2 == 'object' &&
                                                !Array.isArray(data2)) {
                                                var missing1 = void 0;
                                                if ((data2.type === undefined && (missing1 = 'type')) ||
                                                    (data2.choiceUuid === undefined &&
                                                        (missing1 = 'choiceUuid')) ||
                                                    (data2.otherValue === undefined &&
                                                        (missing1 = 'otherValue')) ||
                                                    (data2.rank === undefined && (missing1 = 'rank'))) {
                                                    validate20.errors = [
                                                        {
                                                            instancePath: instancePath + '/answers/' + i0,
                                                            schemaPath: '#/definitions/MultipleChoiceAnswer/required',
                                                            keyword: 'required',
                                                            params: { missingProperty: missing1 },
                                                            message: "must have required property '" +
                                                                missing1 +
                                                                "'",
                                                        },
                                                    ];
                                                    return false;
                                                }
                                                else {
                                                    var _errs9 = errors;
                                                    for (var key1 in data2) {
                                                        if (!(key1 === 'type' ||
                                                            key1 === 'choiceUuid' ||
                                                            key1 === 'otherValue' ||
                                                            key1 === 'rank')) {
                                                            validate20.errors = [
                                                                {
                                                                    instancePath: instancePath + '/answers/' + i0,
                                                                    schemaPath: '#/definitions/MultipleChoiceAnswer/additionalProperties',
                                                                    keyword: 'additionalProperties',
                                                                    params: { additionalProperty: key1 },
                                                                    message: 'must NOT have additional properties',
                                                                },
                                                            ];
                                                            return false;
                                                            break;
                                                        }
                                                    }
                                                    if (_errs9 === errors) {
                                                        if (data2.type !== undefined) {
                                                            var data3 = data2.type;
                                                            var _errs10 = errors;
                                                            if (typeof data3 !== 'string') {
                                                                validate20.errors = [
                                                                    {
                                                                        instancePath: instancePath + '/answers/' + i0 + '/type',
                                                                        schemaPath: '#/definitions/MultipleChoiceAnswer/properties/type/type',
                                                                        keyword: 'type',
                                                                        params: { type: 'string' },
                                                                        message: 'must be string',
                                                                    },
                                                                ];
                                                                return false;
                                                            }
                                                            if ('MULTIPLE_CHOICE' !== data3) {
                                                                validate20.errors = [
                                                                    {
                                                                        instancePath: instancePath + '/answers/' + i0 + '/type',
                                                                        schemaPath: '#/definitions/MultipleChoiceAnswer/properties/type/const',
                                                                        keyword: 'const',
                                                                        params: { allowedValue: 'MULTIPLE_CHOICE' },
                                                                        message: 'must be equal to constant',
                                                                    },
                                                                ];
                                                                return false;
                                                            }
                                                            var valid3 = _errs10 === errors;
                                                        }
                                                        else {
                                                            var valid3 = true;
                                                        }
                                                        if (valid3) {
                                                            if (data2.choiceUuid !== undefined) {
                                                                var _errs12 = errors;
                                                                if (typeof data2.choiceUuid !== 'string') {
                                                                    validate20.errors = [
                                                                        {
                                                                            instancePath: instancePath +
                                                                                '/answers/' +
                                                                                i0 +
                                                                                '/choiceUuid',
                                                                            schemaPath: '#/definitions/MultipleChoiceAnswer/properties/choiceUuid/type',
                                                                            keyword: 'type',
                                                                            params: { type: 'string' },
                                                                            message: 'must be string',
                                                                        },
                                                                    ];
                                                                    return false;
                                                                }
                                                                var valid3 = _errs12 === errors;
                                                            }
                                                            else {
                                                                var valid3 = true;
                                                            }
                                                            if (valid3) {
                                                                if (data2.otherValue !== undefined) {
                                                                    var data5 = data2.otherValue;
                                                                    var _errs14 = errors;
                                                                    if (typeof data5 !== 'string' &&
                                                                        data5 !== null) {
                                                                        validate20.errors = [
                                                                            {
                                                                                instancePath: instancePath +
                                                                                    '/answers/' +
                                                                                    i0 +
                                                                                    '/otherValue',
                                                                                schemaPath: '#/definitions/MultipleChoiceAnswer/properties/otherValue/type',
                                                                                keyword: 'type',
                                                                                params: {
                                                                                    type: schema38.properties.otherValue
                                                                                        .type,
                                                                                },
                                                                                message: 'must be string,null',
                                                                            },
                                                                        ];
                                                                        return false;
                                                                    }
                                                                    var valid3 = _errs14 === errors;
                                                                }
                                                                else {
                                                                    var valid3 = true;
                                                                }
                                                                if (valid3) {
                                                                    if (data2.rank !== undefined) {
                                                                        var data6 = data2.rank;
                                                                        var _errs16 = errors;
                                                                        if (!(typeof data6 == 'number' &&
                                                                            isFinite(data6)) &&
                                                                            data6 !== null) {
                                                                            validate20.errors = [
                                                                                {
                                                                                    instancePath: instancePath +
                                                                                        '/answers/' +
                                                                                        i0 +
                                                                                        '/rank',
                                                                                    schemaPath: '#/definitions/MultipleChoiceAnswer/properties/rank/type',
                                                                                    keyword: 'type',
                                                                                    params: {
                                                                                        type: schema38.properties.rank.type,
                                                                                    },
                                                                                    message: 'must be number,null',
                                                                                },
                                                                            ];
                                                                            return false;
                                                                        }
                                                                        var valid3 = _errs16 === errors;
                                                                    }
                                                                    else {
                                                                        var valid3 = true;
                                                                    }
                                                                }
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                            else {
                                                validate20.errors = [
                                                    {
                                                        instancePath: instancePath + '/answers/' + i0,
                                                        schemaPath: '#/definitions/MultipleChoiceAnswer/type',
                                                        keyword: 'type',
                                                        params: { type: 'object' },
                                                        message: 'must be object',
                                                    },
                                                ];
                                                return false;
                                            }
                                        }
                                        var valid1 = _errs6 === errors;
                                        if (!valid1) {
                                            break;
                                        }
                                    }
                                }
                                else {
                                    validate20.errors = [
                                        {
                                            instancePath: instancePath + '/answers',
                                            schemaPath: '#/properties/answers/type',
                                            keyword: 'type',
                                            params: { type: 'array' },
                                            message: 'must be array',
                                        },
                                    ];
                                    return false;
                                }
                            }
                            var valid0 = _errs4 === errors;
                        }
                        else {
                            var valid0 = true;
                        }
                        if (valid0) {
                            if (data.stepUuid !== undefined) {
                                var _errs18 = errors;
                                if (typeof data.stepUuid !== 'string') {
                                    validate20.errors = [
                                        {
                                            instancePath: instancePath + '/stepUuid',
                                            schemaPath: '#/properties/stepUuid/type',
                                            keyword: 'type',
                                            params: { type: 'string' },
                                            message: 'must be string',
                                        },
                                    ];
                                    return false;
                                }
                                var valid0 = _errs18 === errors;
                            }
                            else {
                                var valid0 = true;
                            }
                            if (valid0) {
                                if (data.stepRevision !== undefined) {
                                    var data8 = data.stepRevision;
                                    var _errs20 = errors;
                                    if (!(typeof data8 == 'number' && isFinite(data8))) {
                                        validate20.errors = [
                                            {
                                                instancePath: instancePath + '/stepRevision',
                                                schemaPath: '#/properties/stepRevision/type',
                                                keyword: 'type',
                                                params: { type: 'number' },
                                                message: 'must be number',
                                            },
                                        ];
                                        return false;
                                    }
                                    var valid0 = _errs20 === errors;
                                }
                                else {
                                    var valid0 = true;
                                }
                                if (valid0) {
                                    if (data.skipped !== undefined) {
                                        var _errs22 = errors;
                                        if (typeof data.skipped !== 'boolean') {
                                            validate20.errors = [
                                                {
                                                    instancePath: instancePath + '/skipped',
                                                    schemaPath: '#/properties/skipped/type',
                                                    keyword: 'type',
                                                    params: { type: 'boolean' },
                                                    message: 'must be boolean',
                                                },
                                            ];
                                            return false;
                                        }
                                        var valid0 = _errs22 === errors;
                                    }
                                    else {
                                        var valid0 = true;
                                    }
                                    if (valid0) {
                                        if (data.duration !== undefined) {
                                            var data10 = data.duration;
                                            var _errs24 = errors;
                                            if (!(typeof data10 == 'number' && isFinite(data10)) &&
                                                data10 !== null) {
                                                validate20.errors = [
                                                    {
                                                        instancePath: instancePath + '/duration',
                                                        schemaPath: '#/properties/duration/type',
                                                        keyword: 'type',
                                                        params: { type: schema39.properties.duration.type },
                                                        message: 'must be number,null',
                                                    },
                                                ];
                                                return false;
                                            }
                                            var valid0 = _errs24 === errors;
                                        }
                                        else {
                                            var valid0 = true;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        else {
            validate20.errors = [
                {
                    instancePath: instancePath,
                    schemaPath: '#/type',
                    keyword: 'type',
                    params: { type: 'object' },
                    message: 'must be object',
                },
            ];
            return false;
        }
    }
    validate20.errors = vErrors;
    return errors === 0;
}
var schema41 = {
    type: 'object',
    additionalProperties: false,
    properties: {
        type: { type: 'string', const: 'STEP_TAGLINE_COPY_TEST' },
        answers: {
            type: 'array',
            items: { $ref: '#/definitions/MultipleChoiceAnswer' },
        },
        stepUuid: { type: 'string' },
        stepRevision: { type: 'number' },
        skipped: { type: 'boolean' },
        duration: { type: ['number', 'null'] },
    },
    required: [
        'answers',
        'duration',
        'skipped',
        'stepRevision',
        'stepUuid',
        'type',
    ],
};
function validate22(data, _a) {
    var _b = _a === void 0 ? {} : _a, _c = _b.instancePath, instancePath = _c === void 0 ? '' : _c, parentData = _b.parentData, parentDataProperty = _b.parentDataProperty, _d = _b.rootData, rootData = _d === void 0 ? data : _d;
    var vErrors = null;
    var errors = 0;
    if (errors === 0) {
        if (data && typeof data == 'object' && !Array.isArray(data)) {
            var missing0 = void 0;
            if ((data.answers === undefined && (missing0 = 'answers')) ||
                (data.duration === undefined && (missing0 = 'duration')) ||
                (data.skipped === undefined && (missing0 = 'skipped')) ||
                (data.stepRevision === undefined && (missing0 = 'stepRevision')) ||
                (data.stepUuid === undefined && (missing0 = 'stepUuid')) ||
                (data.type === undefined && (missing0 = 'type'))) {
                validate22.errors = [
                    {
                        instancePath: instancePath,
                        schemaPath: '#/required',
                        keyword: 'required',
                        params: { missingProperty: missing0 },
                        message: "must have required property '" + missing0 + "'",
                    },
                ];
                return false;
            }
            else {
                var _errs1 = errors;
                for (var key0 in data) {
                    if (!(key0 === 'type' ||
                        key0 === 'answers' ||
                        key0 === 'stepUuid' ||
                        key0 === 'stepRevision' ||
                        key0 === 'skipped' ||
                        key0 === 'duration')) {
                        validate22.errors = [
                            {
                                instancePath: instancePath,
                                schemaPath: '#/additionalProperties',
                                keyword: 'additionalProperties',
                                params: { additionalProperty: key0 },
                                message: 'must NOT have additional properties',
                            },
                        ];
                        return false;
                        break;
                    }
                }
                if (_errs1 === errors) {
                    if (data.type !== undefined) {
                        var data0 = data.type;
                        var _errs2 = errors;
                        if (typeof data0 !== 'string') {
                            validate22.errors = [
                                {
                                    instancePath: instancePath + '/type',
                                    schemaPath: '#/properties/type/type',
                                    keyword: 'type',
                                    params: { type: 'string' },
                                    message: 'must be string',
                                },
                            ];
                            return false;
                        }
                        if ('STEP_TAGLINE_COPY_TEST' !== data0) {
                            validate22.errors = [
                                {
                                    instancePath: instancePath + '/type',
                                    schemaPath: '#/properties/type/const',
                                    keyword: 'const',
                                    params: { allowedValue: 'STEP_TAGLINE_COPY_TEST' },
                                    message: 'must be equal to constant',
                                },
                            ];
                            return false;
                        }
                        var valid0 = _errs2 === errors;
                    }
                    else {
                        var valid0 = true;
                    }
                    if (valid0) {
                        if (data.answers !== undefined) {
                            var data1 = data.answers;
                            var _errs4 = errors;
                            if (errors === _errs4) {
                                if (Array.isArray(data1)) {
                                    var valid1 = true;
                                    var len0 = data1.length;
                                    for (var i0 = 0; i0 < len0; i0++) {
                                        var data2 = data1[i0];
                                        var _errs6 = errors;
                                        var _errs7 = errors;
                                        if (errors === _errs7) {
                                            if (data2 &&
                                                typeof data2 == 'object' &&
                                                !Array.isArray(data2)) {
                                                var missing1 = void 0;
                                                if ((data2.type === undefined && (missing1 = 'type')) ||
                                                    (data2.choiceUuid === undefined &&
                                                        (missing1 = 'choiceUuid')) ||
                                                    (data2.otherValue === undefined &&
                                                        (missing1 = 'otherValue')) ||
                                                    (data2.rank === undefined && (missing1 = 'rank'))) {
                                                    validate22.errors = [
                                                        {
                                                            instancePath: instancePath + '/answers/' + i0,
                                                            schemaPath: '#/definitions/MultipleChoiceAnswer/required',
                                                            keyword: 'required',
                                                            params: { missingProperty: missing1 },
                                                            message: "must have required property '" +
                                                                missing1 +
                                                                "'",
                                                        },
                                                    ];
                                                    return false;
                                                }
                                                else {
                                                    var _errs9 = errors;
                                                    for (var key1 in data2) {
                                                        if (!(key1 === 'type' ||
                                                            key1 === 'choiceUuid' ||
                                                            key1 === 'otherValue' ||
                                                            key1 === 'rank')) {
                                                            validate22.errors = [
                                                                {
                                                                    instancePath: instancePath + '/answers/' + i0,
                                                                    schemaPath: '#/definitions/MultipleChoiceAnswer/additionalProperties',
                                                                    keyword: 'additionalProperties',
                                                                    params: { additionalProperty: key1 },
                                                                    message: 'must NOT have additional properties',
                                                                },
                                                            ];
                                                            return false;
                                                            break;
                                                        }
                                                    }
                                                    if (_errs9 === errors) {
                                                        if (data2.type !== undefined) {
                                                            var data3 = data2.type;
                                                            var _errs10 = errors;
                                                            if (typeof data3 !== 'string') {
                                                                validate22.errors = [
                                                                    {
                                                                        instancePath: instancePath + '/answers/' + i0 + '/type',
                                                                        schemaPath: '#/definitions/MultipleChoiceAnswer/properties/type/type',
                                                                        keyword: 'type',
                                                                        params: { type: 'string' },
                                                                        message: 'must be string',
                                                                    },
                                                                ];
                                                                return false;
                                                            }
                                                            if ('MULTIPLE_CHOICE' !== data3) {
                                                                validate22.errors = [
                                                                    {
                                                                        instancePath: instancePath + '/answers/' + i0 + '/type',
                                                                        schemaPath: '#/definitions/MultipleChoiceAnswer/properties/type/const',
                                                                        keyword: 'const',
                                                                        params: { allowedValue: 'MULTIPLE_CHOICE' },
                                                                        message: 'must be equal to constant',
                                                                    },
                                                                ];
                                                                return false;
                                                            }
                                                            var valid3 = _errs10 === errors;
                                                        }
                                                        else {
                                                            var valid3 = true;
                                                        }
                                                        if (valid3) {
                                                            if (data2.choiceUuid !== undefined) {
                                                                var _errs12 = errors;
                                                                if (typeof data2.choiceUuid !== 'string') {
                                                                    validate22.errors = [
                                                                        {
                                                                            instancePath: instancePath +
                                                                                '/answers/' +
                                                                                i0 +
                                                                                '/choiceUuid',
                                                                            schemaPath: '#/definitions/MultipleChoiceAnswer/properties/choiceUuid/type',
                                                                            keyword: 'type',
                                                                            params: { type: 'string' },
                                                                            message: 'must be string',
                                                                        },
                                                                    ];
                                                                    return false;
                                                                }
                                                                var valid3 = _errs12 === errors;
                                                            }
                                                            else {
                                                                var valid3 = true;
                                                            }
                                                            if (valid3) {
                                                                if (data2.otherValue !== undefined) {
                                                                    var data5 = data2.otherValue;
                                                                    var _errs14 = errors;
                                                                    if (typeof data5 !== 'string' &&
                                                                        data5 !== null) {
                                                                        validate22.errors = [
                                                                            {
                                                                                instancePath: instancePath +
                                                                                    '/answers/' +
                                                                                    i0 +
                                                                                    '/otherValue',
                                                                                schemaPath: '#/definitions/MultipleChoiceAnswer/properties/otherValue/type',
                                                                                keyword: 'type',
                                                                                params: {
                                                                                    type: schema38.properties.otherValue
                                                                                        .type,
                                                                                },
                                                                                message: 'must be string,null',
                                                                            },
                                                                        ];
                                                                        return false;
                                                                    }
                                                                    var valid3 = _errs14 === errors;
                                                                }
                                                                else {
                                                                    var valid3 = true;
                                                                }
                                                                if (valid3) {
                                                                    if (data2.rank !== undefined) {
                                                                        var data6 = data2.rank;
                                                                        var _errs16 = errors;
                                                                        if (!(typeof data6 == 'number' &&
                                                                            isFinite(data6)) &&
                                                                            data6 !== null) {
                                                                            validate22.errors = [
                                                                                {
                                                                                    instancePath: instancePath +
                                                                                        '/answers/' +
                                                                                        i0 +
                                                                                        '/rank',
                                                                                    schemaPath: '#/definitions/MultipleChoiceAnswer/properties/rank/type',
                                                                                    keyword: 'type',
                                                                                    params: {
                                                                                        type: schema38.properties.rank.type,
                                                                                    },
                                                                                    message: 'must be number,null',
                                                                                },
                                                                            ];
                                                                            return false;
                                                                        }
                                                                        var valid3 = _errs16 === errors;
                                                                    }
                                                                    else {
                                                                        var valid3 = true;
                                                                    }
                                                                }
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                            else {
                                                validate22.errors = [
                                                    {
                                                        instancePath: instancePath + '/answers/' + i0,
                                                        schemaPath: '#/definitions/MultipleChoiceAnswer/type',
                                                        keyword: 'type',
                                                        params: { type: 'object' },
                                                        message: 'must be object',
                                                    },
                                                ];
                                                return false;
                                            }
                                        }
                                        var valid1 = _errs6 === errors;
                                        if (!valid1) {
                                            break;
                                        }
                                    }
                                }
                                else {
                                    validate22.errors = [
                                        {
                                            instancePath: instancePath + '/answers',
                                            schemaPath: '#/properties/answers/type',
                                            keyword: 'type',
                                            params: { type: 'array' },
                                            message: 'must be array',
                                        },
                                    ];
                                    return false;
                                }
                            }
                            var valid0 = _errs4 === errors;
                        }
                        else {
                            var valid0 = true;
                        }
                        if (valid0) {
                            if (data.stepUuid !== undefined) {
                                var _errs18 = errors;
                                if (typeof data.stepUuid !== 'string') {
                                    validate22.errors = [
                                        {
                                            instancePath: instancePath + '/stepUuid',
                                            schemaPath: '#/properties/stepUuid/type',
                                            keyword: 'type',
                                            params: { type: 'string' },
                                            message: 'must be string',
                                        },
                                    ];
                                    return false;
                                }
                                var valid0 = _errs18 === errors;
                            }
                            else {
                                var valid0 = true;
                            }
                            if (valid0) {
                                if (data.stepRevision !== undefined) {
                                    var data8 = data.stepRevision;
                                    var _errs20 = errors;
                                    if (!(typeof data8 == 'number' && isFinite(data8))) {
                                        validate22.errors = [
                                            {
                                                instancePath: instancePath + '/stepRevision',
                                                schemaPath: '#/properties/stepRevision/type',
                                                keyword: 'type',
                                                params: { type: 'number' },
                                                message: 'must be number',
                                            },
                                        ];
                                        return false;
                                    }
                                    var valid0 = _errs20 === errors;
                                }
                                else {
                                    var valid0 = true;
                                }
                                if (valid0) {
                                    if (data.skipped !== undefined) {
                                        var _errs22 = errors;
                                        if (typeof data.skipped !== 'boolean') {
                                            validate22.errors = [
                                                {
                                                    instancePath: instancePath + '/skipped',
                                                    schemaPath: '#/properties/skipped/type',
                                                    keyword: 'type',
                                                    params: { type: 'boolean' },
                                                    message: 'must be boolean',
                                                },
                                            ];
                                            return false;
                                        }
                                        var valid0 = _errs22 === errors;
                                    }
                                    else {
                                        var valid0 = true;
                                    }
                                    if (valid0) {
                                        if (data.duration !== undefined) {
                                            var data10 = data.duration;
                                            var _errs24 = errors;
                                            if (!(typeof data10 == 'number' && isFinite(data10)) &&
                                                data10 !== null) {
                                                validate22.errors = [
                                                    {
                                                        instancePath: instancePath + '/duration',
                                                        schemaPath: '#/properties/duration/type',
                                                        keyword: 'type',
                                                        params: { type: schema41.properties.duration.type },
                                                        message: 'must be number,null',
                                                    },
                                                ];
                                                return false;
                                            }
                                            var valid0 = _errs24 === errors;
                                        }
                                        else {
                                            var valid0 = true;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        else {
            validate22.errors = [
                {
                    instancePath: instancePath,
                    schemaPath: '#/type',
                    keyword: 'type',
                    params: { type: 'object' },
                    message: 'must be object',
                },
            ];
            return false;
        }
    }
    validate22.errors = vErrors;
    return errors === 0;
}
var schema44 = {
    type: 'object',
    additionalProperties: false,
    properties: {
        type: { type: 'string', const: 'STEP_QUESTION' },
        answers: {
            type: 'array',
            items: {
                anyOf: [
                    { $ref: '#/definitions/EmailAnswer' },
                    { $ref: '#/definitions/TextAnswer' },
                    { $ref: '#/definitions/IntegerAnswer' },
                    { $ref: '#/definitions/DateTimeAnswer' },
                ],
            },
        },
        stepUuid: { type: 'string' },
        stepRevision: { type: 'number' },
        skipped: { type: 'boolean' },
        duration: { type: ['number', 'null'] },
    },
    required: [
        'answers',
        'duration',
        'skipped',
        'stepRevision',
        'stepUuid',
        'type',
    ],
};
var schema45 = {
    type: 'object',
    properties: {
        type: { type: 'string', const: 'EMAIL' },
        value: { type: 'string' },
    },
    required: ['type', 'value'],
    additionalProperties: false,
};
var schema46 = {
    type: 'object',
    properties: {
        type: { type: 'string', const: 'TEXT' },
        value: { type: 'string' },
    },
    required: ['type', 'value'],
    additionalProperties: false,
};
var schema47 = {
    type: 'object',
    properties: {
        type: { type: 'string', const: 'INTEGER' },
        value: { type: 'number' },
    },
    required: ['type', 'value'],
    additionalProperties: false,
};
var schema48 = {
    type: 'object',
    properties: {
        type: { type: 'string', const: 'DATETIME' },
        value: { type: 'string' },
    },
    required: ['type', 'value'],
    additionalProperties: false,
};
function validate24(data, _a) {
    var _b = _a === void 0 ? {} : _a, _c = _b.instancePath, instancePath = _c === void 0 ? '' : _c, parentData = _b.parentData, parentDataProperty = _b.parentDataProperty, _d = _b.rootData, rootData = _d === void 0 ? data : _d;
    var vErrors = null;
    var errors = 0;
    if (errors === 0) {
        if (data && typeof data == 'object' && !Array.isArray(data)) {
            var missing0 = void 0;
            if ((data.answers === undefined && (missing0 = 'answers')) ||
                (data.duration === undefined && (missing0 = 'duration')) ||
                (data.skipped === undefined && (missing0 = 'skipped')) ||
                (data.stepRevision === undefined && (missing0 = 'stepRevision')) ||
                (data.stepUuid === undefined && (missing0 = 'stepUuid')) ||
                (data.type === undefined && (missing0 = 'type'))) {
                validate24.errors = [
                    {
                        instancePath: instancePath,
                        schemaPath: '#/required',
                        keyword: 'required',
                        params: { missingProperty: missing0 },
                        message: "must have required property '" + missing0 + "'",
                    },
                ];
                return false;
            }
            else {
                var _errs1 = errors;
                for (var key0 in data) {
                    if (!(key0 === 'type' ||
                        key0 === 'answers' ||
                        key0 === 'stepUuid' ||
                        key0 === 'stepRevision' ||
                        key0 === 'skipped' ||
                        key0 === 'duration')) {
                        validate24.errors = [
                            {
                                instancePath: instancePath,
                                schemaPath: '#/additionalProperties',
                                keyword: 'additionalProperties',
                                params: { additionalProperty: key0 },
                                message: 'must NOT have additional properties',
                            },
                        ];
                        return false;
                        break;
                    }
                }
                if (_errs1 === errors) {
                    if (data.type !== undefined) {
                        var data0 = data.type;
                        var _errs2 = errors;
                        if (typeof data0 !== 'string') {
                            validate24.errors = [
                                {
                                    instancePath: instancePath + '/type',
                                    schemaPath: '#/properties/type/type',
                                    keyword: 'type',
                                    params: { type: 'string' },
                                    message: 'must be string',
                                },
                            ];
                            return false;
                        }
                        if ('STEP_QUESTION' !== data0) {
                            validate24.errors = [
                                {
                                    instancePath: instancePath + '/type',
                                    schemaPath: '#/properties/type/const',
                                    keyword: 'const',
                                    params: { allowedValue: 'STEP_QUESTION' },
                                    message: 'must be equal to constant',
                                },
                            ];
                            return false;
                        }
                        var valid0 = _errs2 === errors;
                    }
                    else {
                        var valid0 = true;
                    }
                    if (valid0) {
                        if (data.answers !== undefined) {
                            var data1 = data.answers;
                            var _errs4 = errors;
                            if (errors === _errs4) {
                                if (Array.isArray(data1)) {
                                    var valid1 = true;
                                    var len0 = data1.length;
                                    for (var i0 = 0; i0 < len0; i0++) {
                                        var data2 = data1[i0];
                                        var _errs6 = errors;
                                        var _errs7 = errors;
                                        var valid2 = false;
                                        var _errs8 = errors;
                                        var _errs9 = errors;
                                        if (errors === _errs9) {
                                            if (data2 &&
                                                typeof data2 == 'object' &&
                                                !Array.isArray(data2)) {
                                                var missing1 = void 0;
                                                if ((data2.type === undefined && (missing1 = 'type')) ||
                                                    (data2.value === undefined && (missing1 = 'value'))) {
                                                    var err0 = {
                                                        instancePath: instancePath + '/answers/' + i0,
                                                        schemaPath: '#/definitions/EmailAnswer/required',
                                                        keyword: 'required',
                                                        params: { missingProperty: missing1 },
                                                        message: "must have required property '" + missing1 + "'",
                                                    };
                                                    if (vErrors === null) {
                                                        vErrors = [err0];
                                                    }
                                                    else {
                                                        vErrors.push(err0);
                                                    }
                                                    errors++;
                                                }
                                                else {
                                                    var _errs11 = errors;
                                                    for (var key1 in data2) {
                                                        if (!(key1 === 'type' || key1 === 'value')) {
                                                            var err1 = {
                                                                instancePath: instancePath + '/answers/' + i0,
                                                                schemaPath: '#/definitions/EmailAnswer/additionalProperties',
                                                                keyword: 'additionalProperties',
                                                                params: { additionalProperty: key1 },
                                                                message: 'must NOT have additional properties',
                                                            };
                                                            if (vErrors === null) {
                                                                vErrors = [err1];
                                                            }
                                                            else {
                                                                vErrors.push(err1);
                                                            }
                                                            errors++;
                                                            break;
                                                        }
                                                    }
                                                    if (_errs11 === errors) {
                                                        if (data2.type !== undefined) {
                                                            var data3 = data2.type;
                                                            var _errs12 = errors;
                                                            if (typeof data3 !== 'string') {
                                                                var err2 = {
                                                                    instancePath: instancePath + '/answers/' + i0 + '/type',
                                                                    schemaPath: '#/definitions/EmailAnswer/properties/type/type',
                                                                    keyword: 'type',
                                                                    params: { type: 'string' },
                                                                    message: 'must be string',
                                                                };
                                                                if (vErrors === null) {
                                                                    vErrors = [err2];
                                                                }
                                                                else {
                                                                    vErrors.push(err2);
                                                                }
                                                                errors++;
                                                            }
                                                            if ('EMAIL' !== data3) {
                                                                var err3 = {
                                                                    instancePath: instancePath + '/answers/' + i0 + '/type',
                                                                    schemaPath: '#/definitions/EmailAnswer/properties/type/const',
                                                                    keyword: 'const',
                                                                    params: { allowedValue: 'EMAIL' },
                                                                    message: 'must be equal to constant',
                                                                };
                                                                if (vErrors === null) {
                                                                    vErrors = [err3];
                                                                }
                                                                else {
                                                                    vErrors.push(err3);
                                                                }
                                                                errors++;
                                                            }
                                                            var valid4 = _errs12 === errors;
                                                        }
                                                        else {
                                                            var valid4 = true;
                                                        }
                                                        if (valid4) {
                                                            if (data2.value !== undefined) {
                                                                var _errs14 = errors;
                                                                if (typeof data2.value !== 'string') {
                                                                    var err4 = {
                                                                        instancePath: instancePath +
                                                                            '/answers/' +
                                                                            i0 +
                                                                            '/value',
                                                                        schemaPath: '#/definitions/EmailAnswer/properties/value/type',
                                                                        keyword: 'type',
                                                                        params: { type: 'string' },
                                                                        message: 'must be string',
                                                                    };
                                                                    if (vErrors === null) {
                                                                        vErrors = [err4];
                                                                    }
                                                                    else {
                                                                        vErrors.push(err4);
                                                                    }
                                                                    errors++;
                                                                }
                                                                var valid4 = _errs14 === errors;
                                                            }
                                                            else {
                                                                var valid4 = true;
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                            else {
                                                var err5 = {
                                                    instancePath: instancePath + '/answers/' + i0,
                                                    schemaPath: '#/definitions/EmailAnswer/type',
                                                    keyword: 'type',
                                                    params: { type: 'object' },
                                                    message: 'must be object',
                                                };
                                                if (vErrors === null) {
                                                    vErrors = [err5];
                                                }
                                                else {
                                                    vErrors.push(err5);
                                                }
                                                errors++;
                                            }
                                        }
                                        var _valid0 = _errs8 === errors;
                                        valid2 = valid2 || _valid0;
                                        if (!valid2) {
                                            var _errs16 = errors;
                                            var _errs17 = errors;
                                            if (errors === _errs17) {
                                                if (data2 &&
                                                    typeof data2 == 'object' &&
                                                    !Array.isArray(data2)) {
                                                    var missing2 = void 0;
                                                    if ((data2.type === undefined && (missing2 = 'type')) ||
                                                        (data2.value === undefined && (missing2 = 'value'))) {
                                                        var err6 = {
                                                            instancePath: instancePath + '/answers/' + i0,
                                                            schemaPath: '#/definitions/TextAnswer/required',
                                                            keyword: 'required',
                                                            params: { missingProperty: missing2 },
                                                            message: "must have required property '" +
                                                                missing2 +
                                                                "'",
                                                        };
                                                        if (vErrors === null) {
                                                            vErrors = [err6];
                                                        }
                                                        else {
                                                            vErrors.push(err6);
                                                        }
                                                        errors++;
                                                    }
                                                    else {
                                                        var _errs19 = errors;
                                                        for (var key2 in data2) {
                                                            if (!(key2 === 'type' || key2 === 'value')) {
                                                                var err7 = {
                                                                    instancePath: instancePath + '/answers/' + i0,
                                                                    schemaPath: '#/definitions/TextAnswer/additionalProperties',
                                                                    keyword: 'additionalProperties',
                                                                    params: { additionalProperty: key2 },
                                                                    message: 'must NOT have additional properties',
                                                                };
                                                                if (vErrors === null) {
                                                                    vErrors = [err7];
                                                                }
                                                                else {
                                                                    vErrors.push(err7);
                                                                }
                                                                errors++;
                                                                break;
                                                            }
                                                        }
                                                        if (_errs19 === errors) {
                                                            if (data2.type !== undefined) {
                                                                var data5 = data2.type;
                                                                var _errs20 = errors;
                                                                if (typeof data5 !== 'string') {
                                                                    var err8 = {
                                                                        instancePath: instancePath + '/answers/' + i0 + '/type',
                                                                        schemaPath: '#/definitions/TextAnswer/properties/type/type',
                                                                        keyword: 'type',
                                                                        params: { type: 'string' },
                                                                        message: 'must be string',
                                                                    };
                                                                    if (vErrors === null) {
                                                                        vErrors = [err8];
                                                                    }
                                                                    else {
                                                                        vErrors.push(err8);
                                                                    }
                                                                    errors++;
                                                                }
                                                                if ('TEXT' !== data5) {
                                                                    var err9 = {
                                                                        instancePath: instancePath + '/answers/' + i0 + '/type',
                                                                        schemaPath: '#/definitions/TextAnswer/properties/type/const',
                                                                        keyword: 'const',
                                                                        params: { allowedValue: 'TEXT' },
                                                                        message: 'must be equal to constant',
                                                                    };
                                                                    if (vErrors === null) {
                                                                        vErrors = [err9];
                                                                    }
                                                                    else {
                                                                        vErrors.push(err9);
                                                                    }
                                                                    errors++;
                                                                }
                                                                var valid6 = _errs20 === errors;
                                                            }
                                                            else {
                                                                var valid6 = true;
                                                            }
                                                            if (valid6) {
                                                                if (data2.value !== undefined) {
                                                                    var _errs22 = errors;
                                                                    if (typeof data2.value !== 'string') {
                                                                        var err10 = {
                                                                            instancePath: instancePath +
                                                                                '/answers/' +
                                                                                i0 +
                                                                                '/value',
                                                                            schemaPath: '#/definitions/TextAnswer/properties/value/type',
                                                                            keyword: 'type',
                                                                            params: { type: 'string' },
                                                                            message: 'must be string',
                                                                        };
                                                                        if (vErrors === null) {
                                                                            vErrors = [err10];
                                                                        }
                                                                        else {
                                                                            vErrors.push(err10);
                                                                        }
                                                                        errors++;
                                                                    }
                                                                    var valid6 = _errs22 === errors;
                                                                }
                                                                else {
                                                                    var valid6 = true;
                                                                }
                                                            }
                                                        }
                                                    }
                                                }
                                                else {
                                                    var err11 = {
                                                        instancePath: instancePath + '/answers/' + i0,
                                                        schemaPath: '#/definitions/TextAnswer/type',
                                                        keyword: 'type',
                                                        params: { type: 'object' },
                                                        message: 'must be object',
                                                    };
                                                    if (vErrors === null) {
                                                        vErrors = [err11];
                                                    }
                                                    else {
                                                        vErrors.push(err11);
                                                    }
                                                    errors++;
                                                }
                                            }
                                            var _valid0 = _errs16 === errors;
                                            valid2 = valid2 || _valid0;
                                            if (!valid2) {
                                                var _errs24 = errors;
                                                var _errs25 = errors;
                                                if (errors === _errs25) {
                                                    if (data2 &&
                                                        typeof data2 == 'object' &&
                                                        !Array.isArray(data2)) {
                                                        var missing3 = void 0;
                                                        if ((data2.type === undefined &&
                                                            (missing3 = 'type')) ||
                                                            (data2.value === undefined &&
                                                                (missing3 = 'value'))) {
                                                            var err12 = {
                                                                instancePath: instancePath + '/answers/' + i0,
                                                                schemaPath: '#/definitions/IntegerAnswer/required',
                                                                keyword: 'required',
                                                                params: { missingProperty: missing3 },
                                                                message: "must have required property '" +
                                                                    missing3 +
                                                                    "'",
                                                            };
                                                            if (vErrors === null) {
                                                                vErrors = [err12];
                                                            }
                                                            else {
                                                                vErrors.push(err12);
                                                            }
                                                            errors++;
                                                        }
                                                        else {
                                                            var _errs27 = errors;
                                                            for (var key3 in data2) {
                                                                if (!(key3 === 'type' || key3 === 'value')) {
                                                                    var err13 = {
                                                                        instancePath: instancePath + '/answers/' + i0,
                                                                        schemaPath: '#/definitions/IntegerAnswer/additionalProperties',
                                                                        keyword: 'additionalProperties',
                                                                        params: { additionalProperty: key3 },
                                                                        message: 'must NOT have additional properties',
                                                                    };
                                                                    if (vErrors === null) {
                                                                        vErrors = [err13];
                                                                    }
                                                                    else {
                                                                        vErrors.push(err13);
                                                                    }
                                                                    errors++;
                                                                    break;
                                                                }
                                                            }
                                                            if (_errs27 === errors) {
                                                                if (data2.type !== undefined) {
                                                                    var data7 = data2.type;
                                                                    var _errs28 = errors;
                                                                    if (typeof data7 !== 'string') {
                                                                        var err14 = {
                                                                            instancePath: instancePath +
                                                                                '/answers/' +
                                                                                i0 +
                                                                                '/type',
                                                                            schemaPath: '#/definitions/IntegerAnswer/properties/type/type',
                                                                            keyword: 'type',
                                                                            params: { type: 'string' },
                                                                            message: 'must be string',
                                                                        };
                                                                        if (vErrors === null) {
                                                                            vErrors = [err14];
                                                                        }
                                                                        else {
                                                                            vErrors.push(err14);
                                                                        }
                                                                        errors++;
                                                                    }
                                                                    if ('INTEGER' !== data7) {
                                                                        var err15 = {
                                                                            instancePath: instancePath +
                                                                                '/answers/' +
                                                                                i0 +
                                                                                '/type',
                                                                            schemaPath: '#/definitions/IntegerAnswer/properties/type/const',
                                                                            keyword: 'const',
                                                                            params: { allowedValue: 'INTEGER' },
                                                                            message: 'must be equal to constant',
                                                                        };
                                                                        if (vErrors === null) {
                                                                            vErrors = [err15];
                                                                        }
                                                                        else {
                                                                            vErrors.push(err15);
                                                                        }
                                                                        errors++;
                                                                    }
                                                                    var valid8 = _errs28 === errors;
                                                                }
                                                                else {
                                                                    var valid8 = true;
                                                                }
                                                                if (valid8) {
                                                                    if (data2.value !== undefined) {
                                                                        var data8 = data2.value;
                                                                        var _errs30 = errors;
                                                                        if (!(typeof data8 == 'number' &&
                                                                            isFinite(data8))) {
                                                                            var err16 = {
                                                                                instancePath: instancePath +
                                                                                    '/answers/' +
                                                                                    i0 +
                                                                                    '/value',
                                                                                schemaPath: '#/definitions/IntegerAnswer/properties/value/type',
                                                                                keyword: 'type',
                                                                                params: { type: 'number' },
                                                                                message: 'must be number',
                                                                            };
                                                                            if (vErrors === null) {
                                                                                vErrors = [err16];
                                                                            }
                                                                            else {
                                                                                vErrors.push(err16);
                                                                            }
                                                                            errors++;
                                                                        }
                                                                        var valid8 = _errs30 === errors;
                                                                    }
                                                                    else {
                                                                        var valid8 = true;
                                                                    }
                                                                }
                                                            }
                                                        }
                                                    }
                                                    else {
                                                        var err17 = {
                                                            instancePath: instancePath + '/answers/' + i0,
                                                            schemaPath: '#/definitions/IntegerAnswer/type',
                                                            keyword: 'type',
                                                            params: { type: 'object' },
                                                            message: 'must be object',
                                                        };
                                                        if (vErrors === null) {
                                                            vErrors = [err17];
                                                        }
                                                        else {
                                                            vErrors.push(err17);
                                                        }
                                                        errors++;
                                                    }
                                                }
                                                var _valid0 = _errs24 === errors;
                                                valid2 = valid2 || _valid0;
                                                if (!valid2) {
                                                    var _errs32 = errors;
                                                    var _errs33 = errors;
                                                    if (errors === _errs33) {
                                                        if (data2 &&
                                                            typeof data2 == 'object' &&
                                                            !Array.isArray(data2)) {
                                                            var missing4 = void 0;
                                                            if ((data2.type === undefined &&
                                                                (missing4 = 'type')) ||
                                                                (data2.value === undefined &&
                                                                    (missing4 = 'value'))) {
                                                                var err18 = {
                                                                    instancePath: instancePath + '/answers/' + i0,
                                                                    schemaPath: '#/definitions/DateTimeAnswer/required',
                                                                    keyword: 'required',
                                                                    params: { missingProperty: missing4 },
                                                                    message: "must have required property '" +
                                                                        missing4 +
                                                                        "'",
                                                                };
                                                                if (vErrors === null) {
                                                                    vErrors = [err18];
                                                                }
                                                                else {
                                                                    vErrors.push(err18);
                                                                }
                                                                errors++;
                                                            }
                                                            else {
                                                                var _errs35 = errors;
                                                                for (var key4 in data2) {
                                                                    if (!(key4 === 'type' || key4 === 'value')) {
                                                                        var err19 = {
                                                                            instancePath: instancePath + '/answers/' + i0,
                                                                            schemaPath: '#/definitions/DateTimeAnswer/additionalProperties',
                                                                            keyword: 'additionalProperties',
                                                                            params: { additionalProperty: key4 },
                                                                            message: 'must NOT have additional properties',
                                                                        };
                                                                        if (vErrors === null) {
                                                                            vErrors = [err19];
                                                                        }
                                                                        else {
                                                                            vErrors.push(err19);
                                                                        }
                                                                        errors++;
                                                                        break;
                                                                    }
                                                                }
                                                                if (_errs35 === errors) {
                                                                    if (data2.type !== undefined) {
                                                                        var data9 = data2.type;
                                                                        var _errs36 = errors;
                                                                        if (typeof data9 !== 'string') {
                                                                            var err20 = {
                                                                                instancePath: instancePath +
                                                                                    '/answers/' +
                                                                                    i0 +
                                                                                    '/type',
                                                                                schemaPath: '#/definitions/DateTimeAnswer/properties/type/type',
                                                                                keyword: 'type',
                                                                                params: { type: 'string' },
                                                                                message: 'must be string',
                                                                            };
                                                                            if (vErrors === null) {
                                                                                vErrors = [err20];
                                                                            }
                                                                            else {
                                                                                vErrors.push(err20);
                                                                            }
                                                                            errors++;
                                                                        }
                                                                        if ('DATETIME' !== data9) {
                                                                            var err21 = {
                                                                                instancePath: instancePath +
                                                                                    '/answers/' +
                                                                                    i0 +
                                                                                    '/type',
                                                                                schemaPath: '#/definitions/DateTimeAnswer/properties/type/const',
                                                                                keyword: 'const',
                                                                                params: { allowedValue: 'DATETIME' },
                                                                                message: 'must be equal to constant',
                                                                            };
                                                                            if (vErrors === null) {
                                                                                vErrors = [err21];
                                                                            }
                                                                            else {
                                                                                vErrors.push(err21);
                                                                            }
                                                                            errors++;
                                                                        }
                                                                        var valid10 = _errs36 === errors;
                                                                    }
                                                                    else {
                                                                        var valid10 = true;
                                                                    }
                                                                    if (valid10) {
                                                                        if (data2.value !== undefined) {
                                                                            var _errs38 = errors;
                                                                            if (typeof data2.value !== 'string') {
                                                                                var err22 = {
                                                                                    instancePath: instancePath +
                                                                                        '/answers/' +
                                                                                        i0 +
                                                                                        '/value',
                                                                                    schemaPath: '#/definitions/DateTimeAnswer/properties/value/type',
                                                                                    keyword: 'type',
                                                                                    params: { type: 'string' },
                                                                                    message: 'must be string',
                                                                                };
                                                                                if (vErrors === null) {
                                                                                    vErrors = [err22];
                                                                                }
                                                                                else {
                                                                                    vErrors.push(err22);
                                                                                }
                                                                                errors++;
                                                                            }
                                                                            var valid10 = _errs38 === errors;
                                                                        }
                                                                        else {
                                                                            var valid10 = true;
                                                                        }
                                                                    }
                                                                }
                                                            }
                                                        }
                                                        else {
                                                            var err23 = {
                                                                instancePath: instancePath + '/answers/' + i0,
                                                                schemaPath: '#/definitions/DateTimeAnswer/type',
                                                                keyword: 'type',
                                                                params: { type: 'object' },
                                                                message: 'must be object',
                                                            };
                                                            if (vErrors === null) {
                                                                vErrors = [err23];
                                                            }
                                                            else {
                                                                vErrors.push(err23);
                                                            }
                                                            errors++;
                                                        }
                                                    }
                                                    var _valid0 = _errs32 === errors;
                                                    valid2 = valid2 || _valid0;
                                                }
                                            }
                                        }
                                        if (!valid2) {
                                            var err24 = {
                                                instancePath: instancePath + '/answers/' + i0,
                                                schemaPath: '#/properties/answers/items/anyOf',
                                                keyword: 'anyOf',
                                                params: {},
                                                message: 'must match a schema in anyOf',
                                            };
                                            if (vErrors === null) {
                                                vErrors = [err24];
                                            }
                                            else {
                                                vErrors.push(err24);
                                            }
                                            errors++;
                                            validate24.errors = vErrors;
                                            return false;
                                        }
                                        else {
                                            errors = _errs7;
                                            if (vErrors !== null) {
                                                if (_errs7) {
                                                    vErrors.length = _errs7;
                                                }
                                                else {
                                                    vErrors = null;
                                                }
                                            }
                                        }
                                        var valid1 = _errs6 === errors;
                                        if (!valid1) {
                                            break;
                                        }
                                    }
                                }
                                else {
                                    validate24.errors = [
                                        {
                                            instancePath: instancePath + '/answers',
                                            schemaPath: '#/properties/answers/type',
                                            keyword: 'type',
                                            params: { type: 'array' },
                                            message: 'must be array',
                                        },
                                    ];
                                    return false;
                                }
                            }
                            var valid0 = _errs4 === errors;
                        }
                        else {
                            var valid0 = true;
                        }
                        if (valid0) {
                            if (data.stepUuid !== undefined) {
                                var _errs40 = errors;
                                if (typeof data.stepUuid !== 'string') {
                                    validate24.errors = [
                                        {
                                            instancePath: instancePath + '/stepUuid',
                                            schemaPath: '#/properties/stepUuid/type',
                                            keyword: 'type',
                                            params: { type: 'string' },
                                            message: 'must be string',
                                        },
                                    ];
                                    return false;
                                }
                                var valid0 = _errs40 === errors;
                            }
                            else {
                                var valid0 = true;
                            }
                            if (valid0) {
                                if (data.stepRevision !== undefined) {
                                    var data12 = data.stepRevision;
                                    var _errs42 = errors;
                                    if (!(typeof data12 == 'number' && isFinite(data12))) {
                                        validate24.errors = [
                                            {
                                                instancePath: instancePath + '/stepRevision',
                                                schemaPath: '#/properties/stepRevision/type',
                                                keyword: 'type',
                                                params: { type: 'number' },
                                                message: 'must be number',
                                            },
                                        ];
                                        return false;
                                    }
                                    var valid0 = _errs42 === errors;
                                }
                                else {
                                    var valid0 = true;
                                }
                                if (valid0) {
                                    if (data.skipped !== undefined) {
                                        var _errs44 = errors;
                                        if (typeof data.skipped !== 'boolean') {
                                            validate24.errors = [
                                                {
                                                    instancePath: instancePath + '/skipped',
                                                    schemaPath: '#/properties/skipped/type',
                                                    keyword: 'type',
                                                    params: { type: 'boolean' },
                                                    message: 'must be boolean',
                                                },
                                            ];
                                            return false;
                                        }
                                        var valid0 = _errs44 === errors;
                                    }
                                    else {
                                        var valid0 = true;
                                    }
                                    if (valid0) {
                                        if (data.duration !== undefined) {
                                            var data14 = data.duration;
                                            var _errs46 = errors;
                                            if (!(typeof data14 == 'number' && isFinite(data14)) &&
                                                data14 !== null) {
                                                validate24.errors = [
                                                    {
                                                        instancePath: instancePath + '/duration',
                                                        schemaPath: '#/properties/duration/type',
                                                        keyword: 'type',
                                                        params: { type: schema44.properties.duration.type },
                                                        message: 'must be number,null',
                                                    },
                                                ];
                                                return false;
                                            }
                                            var valid0 = _errs46 === errors;
                                        }
                                        else {
                                            var valid0 = true;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        else {
            validate24.errors = [
                {
                    instancePath: instancePath,
                    schemaPath: '#/type',
                    keyword: 'type',
                    params: { type: 'object' },
                    message: 'must be object',
                },
            ];
            return false;
        }
    }
    validate24.errors = vErrors;
    return errors === 0;
}
var schema49 = {
    type: 'object',
    additionalProperties: false,
    properties: {
        type: { type: 'string', const: 'STEP_RATING_SCALE' },
        answers: { type: 'array', items: { $ref: '#/definitions/IntegerAnswer' } },
        stepUuid: { type: 'string' },
        stepRevision: { type: 'number' },
        skipped: { type: 'boolean' },
        duration: { type: ['number', 'null'] },
    },
    required: [
        'answers',
        'duration',
        'skipped',
        'stepRevision',
        'stepUuid',
        'type',
    ],
};
function validate26(data, _a) {
    var _b = _a === void 0 ? {} : _a, _c = _b.instancePath, instancePath = _c === void 0 ? '' : _c, parentData = _b.parentData, parentDataProperty = _b.parentDataProperty, _d = _b.rootData, rootData = _d === void 0 ? data : _d;
    var vErrors = null;
    var errors = 0;
    if (errors === 0) {
        if (data && typeof data == 'object' && !Array.isArray(data)) {
            var missing0 = void 0;
            if ((data.answers === undefined && (missing0 = 'answers')) ||
                (data.duration === undefined && (missing0 = 'duration')) ||
                (data.skipped === undefined && (missing0 = 'skipped')) ||
                (data.stepRevision === undefined && (missing0 = 'stepRevision')) ||
                (data.stepUuid === undefined && (missing0 = 'stepUuid')) ||
                (data.type === undefined && (missing0 = 'type'))) {
                validate26.errors = [
                    {
                        instancePath: instancePath,
                        schemaPath: '#/required',
                        keyword: 'required',
                        params: { missingProperty: missing0 },
                        message: "must have required property '" + missing0 + "'",
                    },
                ];
                return false;
            }
            else {
                var _errs1 = errors;
                for (var key0 in data) {
                    if (!(key0 === 'type' ||
                        key0 === 'answers' ||
                        key0 === 'stepUuid' ||
                        key0 === 'stepRevision' ||
                        key0 === 'skipped' ||
                        key0 === 'duration')) {
                        validate26.errors = [
                            {
                                instancePath: instancePath,
                                schemaPath: '#/additionalProperties',
                                keyword: 'additionalProperties',
                                params: { additionalProperty: key0 },
                                message: 'must NOT have additional properties',
                            },
                        ];
                        return false;
                        break;
                    }
                }
                if (_errs1 === errors) {
                    if (data.type !== undefined) {
                        var data0 = data.type;
                        var _errs2 = errors;
                        if (typeof data0 !== 'string') {
                            validate26.errors = [
                                {
                                    instancePath: instancePath + '/type',
                                    schemaPath: '#/properties/type/type',
                                    keyword: 'type',
                                    params: { type: 'string' },
                                    message: 'must be string',
                                },
                            ];
                            return false;
                        }
                        if ('STEP_RATING_SCALE' !== data0) {
                            validate26.errors = [
                                {
                                    instancePath: instancePath + '/type',
                                    schemaPath: '#/properties/type/const',
                                    keyword: 'const',
                                    params: { allowedValue: 'STEP_RATING_SCALE' },
                                    message: 'must be equal to constant',
                                },
                            ];
                            return false;
                        }
                        var valid0 = _errs2 === errors;
                    }
                    else {
                        var valid0 = true;
                    }
                    if (valid0) {
                        if (data.answers !== undefined) {
                            var data1 = data.answers;
                            var _errs4 = errors;
                            if (errors === _errs4) {
                                if (Array.isArray(data1)) {
                                    var valid1 = true;
                                    var len0 = data1.length;
                                    for (var i0 = 0; i0 < len0; i0++) {
                                        var data2 = data1[i0];
                                        var _errs6 = errors;
                                        var _errs7 = errors;
                                        if (errors === _errs7) {
                                            if (data2 &&
                                                typeof data2 == 'object' &&
                                                !Array.isArray(data2)) {
                                                var missing1 = void 0;
                                                if ((data2.type === undefined && (missing1 = 'type')) ||
                                                    (data2.value === undefined && (missing1 = 'value'))) {
                                                    validate26.errors = [
                                                        {
                                                            instancePath: instancePath + '/answers/' + i0,
                                                            schemaPath: '#/definitions/IntegerAnswer/required',
                                                            keyword: 'required',
                                                            params: { missingProperty: missing1 },
                                                            message: "must have required property '" +
                                                                missing1 +
                                                                "'",
                                                        },
                                                    ];
                                                    return false;
                                                }
                                                else {
                                                    var _errs9 = errors;
                                                    for (var key1 in data2) {
                                                        if (!(key1 === 'type' || key1 === 'value')) {
                                                            validate26.errors = [
                                                                {
                                                                    instancePath: instancePath + '/answers/' + i0,
                                                                    schemaPath: '#/definitions/IntegerAnswer/additionalProperties',
                                                                    keyword: 'additionalProperties',
                                                                    params: { additionalProperty: key1 },
                                                                    message: 'must NOT have additional properties',
                                                                },
                                                            ];
                                                            return false;
                                                            break;
                                                        }
                                                    }
                                                    if (_errs9 === errors) {
                                                        if (data2.type !== undefined) {
                                                            var data3 = data2.type;
                                                            var _errs10 = errors;
                                                            if (typeof data3 !== 'string') {
                                                                validate26.errors = [
                                                                    {
                                                                        instancePath: instancePath + '/answers/' + i0 + '/type',
                                                                        schemaPath: '#/definitions/IntegerAnswer/properties/type/type',
                                                                        keyword: 'type',
                                                                        params: { type: 'string' },
                                                                        message: 'must be string',
                                                                    },
                                                                ];
                                                                return false;
                                                            }
                                                            if ('INTEGER' !== data3) {
                                                                validate26.errors = [
                                                                    {
                                                                        instancePath: instancePath + '/answers/' + i0 + '/type',
                                                                        schemaPath: '#/definitions/IntegerAnswer/properties/type/const',
                                                                        keyword: 'const',
                                                                        params: { allowedValue: 'INTEGER' },
                                                                        message: 'must be equal to constant',
                                                                    },
                                                                ];
                                                                return false;
                                                            }
                                                            var valid3 = _errs10 === errors;
                                                        }
                                                        else {
                                                            var valid3 = true;
                                                        }
                                                        if (valid3) {
                                                            if (data2.value !== undefined) {
                                                                var data4 = data2.value;
                                                                var _errs12 = errors;
                                                                if (!(typeof data4 == 'number' && isFinite(data4))) {
                                                                    validate26.errors = [
                                                                        {
                                                                            instancePath: instancePath +
                                                                                '/answers/' +
                                                                                i0 +
                                                                                '/value',
                                                                            schemaPath: '#/definitions/IntegerAnswer/properties/value/type',
                                                                            keyword: 'type',
                                                                            params: { type: 'number' },
                                                                            message: 'must be number',
                                                                        },
                                                                    ];
                                                                    return false;
                                                                }
                                                                var valid3 = _errs12 === errors;
                                                            }
                                                            else {
                                                                var valid3 = true;
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                            else {
                                                validate26.errors = [
                                                    {
                                                        instancePath: instancePath + '/answers/' + i0,
                                                        schemaPath: '#/definitions/IntegerAnswer/type',
                                                        keyword: 'type',
                                                        params: { type: 'object' },
                                                        message: 'must be object',
                                                    },
                                                ];
                                                return false;
                                            }
                                        }
                                        var valid1 = _errs6 === errors;
                                        if (!valid1) {
                                            break;
                                        }
                                    }
                                }
                                else {
                                    validate26.errors = [
                                        {
                                            instancePath: instancePath + '/answers',
                                            schemaPath: '#/properties/answers/type',
                                            keyword: 'type',
                                            params: { type: 'array' },
                                            message: 'must be array',
                                        },
                                    ];
                                    return false;
                                }
                            }
                            var valid0 = _errs4 === errors;
                        }
                        else {
                            var valid0 = true;
                        }
                        if (valid0) {
                            if (data.stepUuid !== undefined) {
                                var _errs14 = errors;
                                if (typeof data.stepUuid !== 'string') {
                                    validate26.errors = [
                                        {
                                            instancePath: instancePath + '/stepUuid',
                                            schemaPath: '#/properties/stepUuid/type',
                                            keyword: 'type',
                                            params: { type: 'string' },
                                            message: 'must be string',
                                        },
                                    ];
                                    return false;
                                }
                                var valid0 = _errs14 === errors;
                            }
                            else {
                                var valid0 = true;
                            }
                            if (valid0) {
                                if (data.stepRevision !== undefined) {
                                    var data6 = data.stepRevision;
                                    var _errs16 = errors;
                                    if (!(typeof data6 == 'number' && isFinite(data6))) {
                                        validate26.errors = [
                                            {
                                                instancePath: instancePath + '/stepRevision',
                                                schemaPath: '#/properties/stepRevision/type',
                                                keyword: 'type',
                                                params: { type: 'number' },
                                                message: 'must be number',
                                            },
                                        ];
                                        return false;
                                    }
                                    var valid0 = _errs16 === errors;
                                }
                                else {
                                    var valid0 = true;
                                }
                                if (valid0) {
                                    if (data.skipped !== undefined) {
                                        var _errs18 = errors;
                                        if (typeof data.skipped !== 'boolean') {
                                            validate26.errors = [
                                                {
                                                    instancePath: instancePath + '/skipped',
                                                    schemaPath: '#/properties/skipped/type',
                                                    keyword: 'type',
                                                    params: { type: 'boolean' },
                                                    message: 'must be boolean',
                                                },
                                            ];
                                            return false;
                                        }
                                        var valid0 = _errs18 === errors;
                                    }
                                    else {
                                        var valid0 = true;
                                    }
                                    if (valid0) {
                                        if (data.duration !== undefined) {
                                            var data8 = data.duration;
                                            var _errs20 = errors;
                                            if (!(typeof data8 == 'number' && isFinite(data8)) &&
                                                data8 !== null) {
                                                validate26.errors = [
                                                    {
                                                        instancePath: instancePath + '/duration',
                                                        schemaPath: '#/properties/duration/type',
                                                        keyword: 'type',
                                                        params: { type: schema49.properties.duration.type },
                                                        message: 'must be number,null',
                                                    },
                                                ];
                                                return false;
                                            }
                                            var valid0 = _errs20 === errors;
                                        }
                                        else {
                                            var valid0 = true;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        else {
            validate26.errors = [
                {
                    instancePath: instancePath,
                    schemaPath: '#/type',
                    keyword: 'type',
                    params: { type: 'object' },
                    message: 'must be object',
                },
            ];
            return false;
        }
    }
    validate26.errors = vErrors;
    return errors === 0;
}
var schema51 = {
    type: 'object',
    additionalProperties: false,
    properties: {
        type: { type: 'string', const: 'STEP_WEBSITE_TASK' },
        downloadRecording: {
            anyOf: [
                {
                    type: 'object',
                    properties: {
                        pk: { type: 'number' },
                        videoUrl: { type: ['string', 'null'] },
                        uploadStatus: {
                            type: 'string',
                            enum: [
                                'UPLOAD_FAILED',
                                'UPLOAD_PENDING',
                                'UPLOAD_SUCCESS',
                                'UPLOAD_IN_PROGRESS',
                                'UPLOAD_ACQUIRING_MEDIA',
                            ],
                        },
                        failedStatus: { type: ['string', 'null'] },
                        failedtype: { type: ['string', 'null'] },
                        permissions: {
                            anyOf: [
                                { $ref: '#/definitions/RecordingPermissions' },
                                { type: 'null' },
                            ],
                        },
                        startTime: { type: ['string', 'null'] },
                        missingDataWarning: { type: 'boolean' },
                        duration: { type: ['number', 'null'] },
                        width: { type: ['number', 'null'] },
                        height: { type: ['number', 'null'] },
                    },
                    required: [
                        'pk',
                        'videoUrl',
                        'uploadStatus',
                        'failedStatus',
                        'failedtype',
                        'permissions',
                        'startTime',
                        'missingDataWarning',
                        'duration',
                        'width',
                        'height',
                    ],
                    additionalProperties: false,
                },
                { type: 'null' },
            ],
        },
        stepUuid: { type: 'string' },
        stepRevision: { type: 'number' },
        skipped: { type: 'boolean' },
        duration: { type: ['number', 'null'] },
    },
    required: ['duration', 'skipped', 'stepRevision', 'stepUuid', 'type'],
};
var schema52 = {
    type: 'object',
    properties: {
        microphone: { type: 'boolean' },
        webcam: { type: 'boolean' },
        screen: { type: 'boolean' },
    },
    required: ['microphone', 'webcam', 'screen'],
    additionalProperties: false,
};
function validate28(data, _a) {
    var _b = _a === void 0 ? {} : _a, _c = _b.instancePath, instancePath = _c === void 0 ? '' : _c, parentData = _b.parentData, parentDataProperty = _b.parentDataProperty, _d = _b.rootData, rootData = _d === void 0 ? data : _d;
    var vErrors = null;
    var errors = 0;
    if (errors === 0) {
        if (data && typeof data == 'object' && !Array.isArray(data)) {
            var missing0 = void 0;
            if ((data.duration === undefined && (missing0 = 'duration')) ||
                (data.skipped === undefined && (missing0 = 'skipped')) ||
                (data.stepRevision === undefined && (missing0 = 'stepRevision')) ||
                (data.stepUuid === undefined && (missing0 = 'stepUuid')) ||
                (data.type === undefined && (missing0 = 'type'))) {
                validate28.errors = [
                    {
                        instancePath: instancePath,
                        schemaPath: '#/required',
                        keyword: 'required',
                        params: { missingProperty: missing0 },
                        message: "must have required property '" + missing0 + "'",
                    },
                ];
                return false;
            }
            else {
                var _errs1 = errors;
                for (var key0 in data) {
                    if (!(key0 === 'type' ||
                        key0 === 'downloadRecording' ||
                        key0 === 'stepUuid' ||
                        key0 === 'stepRevision' ||
                        key0 === 'skipped' ||
                        key0 === 'duration')) {
                        validate28.errors = [
                            {
                                instancePath: instancePath,
                                schemaPath: '#/additionalProperties',
                                keyword: 'additionalProperties',
                                params: { additionalProperty: key0 },
                                message: 'must NOT have additional properties',
                            },
                        ];
                        return false;
                        break;
                    }
                }
                if (_errs1 === errors) {
                    if (data.type !== undefined) {
                        var data0 = data.type;
                        var _errs2 = errors;
                        if (typeof data0 !== 'string') {
                            validate28.errors = [
                                {
                                    instancePath: instancePath + '/type',
                                    schemaPath: '#/properties/type/type',
                                    keyword: 'type',
                                    params: { type: 'string' },
                                    message: 'must be string',
                                },
                            ];
                            return false;
                        }
                        if ('STEP_WEBSITE_TASK' !== data0) {
                            validate28.errors = [
                                {
                                    instancePath: instancePath + '/type',
                                    schemaPath: '#/properties/type/const',
                                    keyword: 'const',
                                    params: { allowedValue: 'STEP_WEBSITE_TASK' },
                                    message: 'must be equal to constant',
                                },
                            ];
                            return false;
                        }
                        var valid0 = _errs2 === errors;
                    }
                    else {
                        var valid0 = true;
                    }
                    if (valid0) {
                        if (data.downloadRecording !== undefined) {
                            var data1 = data.downloadRecording;
                            var _errs4 = errors;
                            var _errs5 = errors;
                            var valid1 = false;
                            var _errs6 = errors;
                            if (errors === _errs6) {
                                if (data1 &&
                                    typeof data1 == 'object' &&
                                    !Array.isArray(data1)) {
                                    var missing1 = void 0;
                                    if ((data1.pk === undefined && (missing1 = 'pk')) ||
                                        (data1.videoUrl === undefined && (missing1 = 'videoUrl')) ||
                                        (data1.uploadStatus === undefined &&
                                            (missing1 = 'uploadStatus')) ||
                                        (data1.failedStatus === undefined &&
                                            (missing1 = 'failedStatus')) ||
                                        (data1.failedtype === undefined &&
                                            (missing1 = 'failedtype')) ||
                                        (data1.permissions === undefined &&
                                            (missing1 = 'permissions')) ||
                                        (data1.startTime === undefined &&
                                            (missing1 = 'startTime')) ||
                                        (data1.missingDataWarning === undefined &&
                                            (missing1 = 'missingDataWarning')) ||
                                        (data1.duration === undefined && (missing1 = 'duration')) ||
                                        (data1.width === undefined && (missing1 = 'width')) ||
                                        (data1.height === undefined && (missing1 = 'height'))) {
                                        var err0 = {
                                            instancePath: instancePath + '/downloadRecording',
                                            schemaPath: '#/properties/downloadRecording/anyOf/0/required',
                                            keyword: 'required',
                                            params: { missingProperty: missing1 },
                                            message: "must have required property '" + missing1 + "'",
                                        };
                                        if (vErrors === null) {
                                            vErrors = [err0];
                                        }
                                        else {
                                            vErrors.push(err0);
                                        }
                                        errors++;
                                    }
                                    else {
                                        var _errs8 = errors;
                                        for (var key1 in data1) {
                                            if (!func2.call(schema51.properties.downloadRecording.anyOf[0]
                                                .properties, key1)) {
                                                var err1 = {
                                                    instancePath: instancePath + '/downloadRecording',
                                                    schemaPath: '#/properties/downloadRecording/anyOf/0/additionalProperties',
                                                    keyword: 'additionalProperties',
                                                    params: { additionalProperty: key1 },
                                                    message: 'must NOT have additional properties',
                                                };
                                                if (vErrors === null) {
                                                    vErrors = [err1];
                                                }
                                                else {
                                                    vErrors.push(err1);
                                                }
                                                errors++;
                                                break;
                                            }
                                        }
                                        if (_errs8 === errors) {
                                            if (data1.pk !== undefined) {
                                                var data2 = data1.pk;
                                                var _errs9 = errors;
                                                if (!(typeof data2 == 'number' && isFinite(data2))) {
                                                    var err2 = {
                                                        instancePath: instancePath + '/downloadRecording/pk',
                                                        schemaPath: '#/properties/downloadRecording/anyOf/0/properties/pk/type',
                                                        keyword: 'type',
                                                        params: { type: 'number' },
                                                        message: 'must be number',
                                                    };
                                                    if (vErrors === null) {
                                                        vErrors = [err2];
                                                    }
                                                    else {
                                                        vErrors.push(err2);
                                                    }
                                                    errors++;
                                                }
                                                var valid2 = _errs9 === errors;
                                            }
                                            else {
                                                var valid2 = true;
                                            }
                                            if (valid2) {
                                                if (data1.videoUrl !== undefined) {
                                                    var data3 = data1.videoUrl;
                                                    var _errs11 = errors;
                                                    if (typeof data3 !== 'string' && data3 !== null) {
                                                        var err3 = {
                                                            instancePath: instancePath + '/downloadRecording/videoUrl',
                                                            schemaPath: '#/properties/downloadRecording/anyOf/0/properties/videoUrl/type',
                                                            keyword: 'type',
                                                            params: {
                                                                type: schema51.properties.downloadRecording
                                                                    .anyOf[0].properties.videoUrl.type,
                                                            },
                                                            message: 'must be string,null',
                                                        };
                                                        if (vErrors === null) {
                                                            vErrors = [err3];
                                                        }
                                                        else {
                                                            vErrors.push(err3);
                                                        }
                                                        errors++;
                                                    }
                                                    var valid2 = _errs11 === errors;
                                                }
                                                else {
                                                    var valid2 = true;
                                                }
                                                if (valid2) {
                                                    if (data1.uploadStatus !== undefined) {
                                                        var data4 = data1.uploadStatus;
                                                        var _errs13 = errors;
                                                        if (typeof data4 !== 'string') {
                                                            var err4 = {
                                                                instancePath: instancePath +
                                                                    '/downloadRecording/uploadStatus',
                                                                schemaPath: '#/properties/downloadRecording/anyOf/0/properties/uploadStatus/type',
                                                                keyword: 'type',
                                                                params: { type: 'string' },
                                                                message: 'must be string',
                                                            };
                                                            if (vErrors === null) {
                                                                vErrors = [err4];
                                                            }
                                                            else {
                                                                vErrors.push(err4);
                                                            }
                                                            errors++;
                                                        }
                                                        if (!(data4 === 'UPLOAD_FAILED' ||
                                                            data4 === 'UPLOAD_PENDING' ||
                                                            data4 === 'UPLOAD_SUCCESS' ||
                                                            data4 === 'UPLOAD_IN_PROGRESS' ||
                                                            data4 === 'UPLOAD_ACQUIRING_MEDIA')) {
                                                            var err5 = {
                                                                instancePath: instancePath +
                                                                    '/downloadRecording/uploadStatus',
                                                                schemaPath: '#/properties/downloadRecording/anyOf/0/properties/uploadStatus/enum',
                                                                keyword: 'enum',
                                                                params: {
                                                                    allowedValues: schema51.properties.downloadRecording
                                                                        .anyOf[0].properties.uploadStatus.enum,
                                                                },
                                                                message: 'must be equal to one of the allowed values',
                                                            };
                                                            if (vErrors === null) {
                                                                vErrors = [err5];
                                                            }
                                                            else {
                                                                vErrors.push(err5);
                                                            }
                                                            errors++;
                                                        }
                                                        var valid2 = _errs13 === errors;
                                                    }
                                                    else {
                                                        var valid2 = true;
                                                    }
                                                    if (valid2) {
                                                        if (data1.failedStatus !== undefined) {
                                                            var data5 = data1.failedStatus;
                                                            var _errs15 = errors;
                                                            if (typeof data5 !== 'string' && data5 !== null) {
                                                                var err6 = {
                                                                    instancePath: instancePath +
                                                                        '/downloadRecording/failedStatus',
                                                                    schemaPath: '#/properties/downloadRecording/anyOf/0/properties/failedStatus/type',
                                                                    keyword: 'type',
                                                                    params: {
                                                                        type: schema51.properties.downloadRecording
                                                                            .anyOf[0].properties.failedStatus.type,
                                                                    },
                                                                    message: 'must be string,null',
                                                                };
                                                                if (vErrors === null) {
                                                                    vErrors = [err6];
                                                                }
                                                                else {
                                                                    vErrors.push(err6);
                                                                }
                                                                errors++;
                                                            }
                                                            var valid2 = _errs15 === errors;
                                                        }
                                                        else {
                                                            var valid2 = true;
                                                        }
                                                        if (valid2) {
                                                            if (data1.failedtype !== undefined) {
                                                                var data6 = data1.failedtype;
                                                                var _errs17 = errors;
                                                                if (typeof data6 !== 'string' &&
                                                                    data6 !== null) {
                                                                    var err7 = {
                                                                        instancePath: instancePath +
                                                                            '/downloadRecording/failedtype',
                                                                        schemaPath: '#/properties/downloadRecording/anyOf/0/properties/failedtype/type',
                                                                        keyword: 'type',
                                                                        params: {
                                                                            type: schema51.properties
                                                                                .downloadRecording.anyOf[0].properties
                                                                                .failedtype.type,
                                                                        },
                                                                        message: 'must be string,null',
                                                                    };
                                                                    if (vErrors === null) {
                                                                        vErrors = [err7];
                                                                    }
                                                                    else {
                                                                        vErrors.push(err7);
                                                                    }
                                                                    errors++;
                                                                }
                                                                var valid2 = _errs17 === errors;
                                                            }
                                                            else {
                                                                var valid2 = true;
                                                            }
                                                            if (valid2) {
                                                                if (data1.permissions !== undefined) {
                                                                    var data7 = data1.permissions;
                                                                    var _errs19 = errors;
                                                                    var _errs20 = errors;
                                                                    var valid3 = false;
                                                                    var _errs21 = errors;
                                                                    var _errs22 = errors;
                                                                    if (errors === _errs22) {
                                                                        if (data7 &&
                                                                            typeof data7 == 'object' &&
                                                                            !Array.isArray(data7)) {
                                                                            var missing2 = void 0;
                                                                            if ((data7.microphone === undefined &&
                                                                                (missing2 = 'microphone')) ||
                                                                                (data7.webcam === undefined &&
                                                                                    (missing2 = 'webcam')) ||
                                                                                (data7.screen === undefined &&
                                                                                    (missing2 = 'screen'))) {
                                                                                var err8 = {
                                                                                    instancePath: instancePath +
                                                                                        '/downloadRecording/permissions',
                                                                                    schemaPath: '#/definitions/RecordingPermissions/required',
                                                                                    keyword: 'required',
                                                                                    params: { missingProperty: missing2 },
                                                                                    message: "must have required property '" +
                                                                                        missing2 +
                                                                                        "'",
                                                                                };
                                                                                if (vErrors === null) {
                                                                                    vErrors = [err8];
                                                                                }
                                                                                else {
                                                                                    vErrors.push(err8);
                                                                                }
                                                                                errors++;
                                                                            }
                                                                            else {
                                                                                var _errs24 = errors;
                                                                                for (var key2 in data7) {
                                                                                    if (!(key2 === 'microphone' ||
                                                                                        key2 === 'webcam' ||
                                                                                        key2 === 'screen')) {
                                                                                        var err9 = {
                                                                                            instancePath: instancePath +
                                                                                                '/downloadRecording/permissions',
                                                                                            schemaPath: '#/definitions/RecordingPermissions/additionalProperties',
                                                                                            keyword: 'additionalProperties',
                                                                                            params: {
                                                                                                additionalProperty: key2,
                                                                                            },
                                                                                            message: 'must NOT have additional properties',
                                                                                        };
                                                                                        if (vErrors === null) {
                                                                                            vErrors = [err9];
                                                                                        }
                                                                                        else {
                                                                                            vErrors.push(err9);
                                                                                        }
                                                                                        errors++;
                                                                                        break;
                                                                                    }
                                                                                }
                                                                                if (_errs24 === errors) {
                                                                                    if (data7.microphone !== undefined) {
                                                                                        var _errs25 = errors;
                                                                                        if (typeof data7.microphone !==
                                                                                            'boolean') {
                                                                                            var err10 = {
                                                                                                instancePath: instancePath +
                                                                                                    '/downloadRecording/permissions/microphone',
                                                                                                schemaPath: '#/definitions/RecordingPermissions/properties/microphone/type',
                                                                                                keyword: 'type',
                                                                                                params: { type: 'boolean' },
                                                                                                message: 'must be boolean',
                                                                                            };
                                                                                            if (vErrors === null) {
                                                                                                vErrors = [err10];
                                                                                            }
                                                                                            else {
                                                                                                vErrors.push(err10);
                                                                                            }
                                                                                            errors++;
                                                                                        }
                                                                                        var valid5 = _errs25 === errors;
                                                                                    }
                                                                                    else {
                                                                                        var valid5 = true;
                                                                                    }
                                                                                    if (valid5) {
                                                                                        if (data7.webcam !== undefined) {
                                                                                            var _errs27 = errors;
                                                                                            if (typeof data7.webcam !==
                                                                                                'boolean') {
                                                                                                var err11 = {
                                                                                                    instancePath: instancePath +
                                                                                                        '/downloadRecording/permissions/webcam',
                                                                                                    schemaPath: '#/definitions/RecordingPermissions/properties/webcam/type',
                                                                                                    keyword: 'type',
                                                                                                    params: { type: 'boolean' },
                                                                                                    message: 'must be boolean',
                                                                                                };
                                                                                                if (vErrors === null) {
                                                                                                    vErrors = [err11];
                                                                                                }
                                                                                                else {
                                                                                                    vErrors.push(err11);
                                                                                                }
                                                                                                errors++;
                                                                                            }
                                                                                            var valid5 = _errs27 === errors;
                                                                                        }
                                                                                        else {
                                                                                            var valid5 = true;
                                                                                        }
                                                                                        if (valid5) {
                                                                                            if (data7.screen !== undefined) {
                                                                                                var _errs29 = errors;
                                                                                                if (typeof data7.screen !==
                                                                                                    'boolean') {
                                                                                                    var err12 = {
                                                                                                        instancePath: instancePath +
                                                                                                            '/downloadRecording/permissions/screen',
                                                                                                        schemaPath: '#/definitions/RecordingPermissions/properties/screen/type',
                                                                                                        keyword: 'type',
                                                                                                        params: { type: 'boolean' },
                                                                                                        message: 'must be boolean',
                                                                                                    };
                                                                                                    if (vErrors === null) {
                                                                                                        vErrors = [err12];
                                                                                                    }
                                                                                                    else {
                                                                                                        vErrors.push(err12);
                                                                                                    }
                                                                                                    errors++;
                                                                                                }
                                                                                                var valid5 = _errs29 === errors;
                                                                                            }
                                                                                            else {
                                                                                                var valid5 = true;
                                                                                            }
                                                                                        }
                                                                                    }
                                                                                }
                                                                            }
                                                                        }
                                                                        else {
                                                                            var err13 = {
                                                                                instancePath: instancePath +
                                                                                    '/downloadRecording/permissions',
                                                                                schemaPath: '#/definitions/RecordingPermissions/type',
                                                                                keyword: 'type',
                                                                                params: { type: 'object' },
                                                                                message: 'must be object',
                                                                            };
                                                                            if (vErrors === null) {
                                                                                vErrors = [err13];
                                                                            }
                                                                            else {
                                                                                vErrors.push(err13);
                                                                            }
                                                                            errors++;
                                                                        }
                                                                    }
                                                                    var _valid1 = _errs21 === errors;
                                                                    valid3 = valid3 || _valid1;
                                                                    if (!valid3) {
                                                                        var _errs31 = errors;
                                                                        if (data7 !== null) {
                                                                            var err14 = {
                                                                                instancePath: instancePath +
                                                                                    '/downloadRecording/permissions',
                                                                                schemaPath: '#/properties/downloadRecording/anyOf/0/properties/permissions/anyOf/1/type',
                                                                                keyword: 'type',
                                                                                params: { type: 'null' },
                                                                                message: 'must be null',
                                                                            };
                                                                            if (vErrors === null) {
                                                                                vErrors = [err14];
                                                                            }
                                                                            else {
                                                                                vErrors.push(err14);
                                                                            }
                                                                            errors++;
                                                                        }
                                                                        var _valid1 = _errs31 === errors;
                                                                        valid3 = valid3 || _valid1;
                                                                    }
                                                                    if (!valid3) {
                                                                        var err15 = {
                                                                            instancePath: instancePath +
                                                                                '/downloadRecording/permissions',
                                                                            schemaPath: '#/properties/downloadRecording/anyOf/0/properties/permissions/anyOf',
                                                                            keyword: 'anyOf',
                                                                            params: {},
                                                                            message: 'must match a schema in anyOf',
                                                                        };
                                                                        if (vErrors === null) {
                                                                            vErrors = [err15];
                                                                        }
                                                                        else {
                                                                            vErrors.push(err15);
                                                                        }
                                                                        errors++;
                                                                    }
                                                                    else {
                                                                        errors = _errs20;
                                                                        if (vErrors !== null) {
                                                                            if (_errs20) {
                                                                                vErrors.length = _errs20;
                                                                            }
                                                                            else {
                                                                                vErrors = null;
                                                                            }
                                                                        }
                                                                    }
                                                                    var valid2 = _errs19 === errors;
                                                                }
                                                                else {
                                                                    var valid2 = true;
                                                                }
                                                                if (valid2) {
                                                                    if (data1.startTime !== undefined) {
                                                                        var data11 = data1.startTime;
                                                                        var _errs33 = errors;
                                                                        if (typeof data11 !== 'string' &&
                                                                            data11 !== null) {
                                                                            var err16 = {
                                                                                instancePath: instancePath +
                                                                                    '/downloadRecording/startTime',
                                                                                schemaPath: '#/properties/downloadRecording/anyOf/0/properties/startTime/type',
                                                                                keyword: 'type',
                                                                                params: {
                                                                                    type: schema51.properties
                                                                                        .downloadRecording.anyOf[0]
                                                                                        .properties.startTime.type,
                                                                                },
                                                                                message: 'must be string,null',
                                                                            };
                                                                            if (vErrors === null) {
                                                                                vErrors = [err16];
                                                                            }
                                                                            else {
                                                                                vErrors.push(err16);
                                                                            }
                                                                            errors++;
                                                                        }
                                                                        var valid2 = _errs33 === errors;
                                                                    }
                                                                    else {
                                                                        var valid2 = true;
                                                                    }
                                                                    if (valid2) {
                                                                        if (data1.missingDataWarning !== undefined) {
                                                                            var _errs35 = errors;
                                                                            if (typeof data1.missingDataWarning !==
                                                                                'boolean') {
                                                                                var err17 = {
                                                                                    instancePath: instancePath +
                                                                                        '/downloadRecording/missingDataWarning',
                                                                                    schemaPath: '#/properties/downloadRecording/anyOf/0/properties/missingDataWarning/type',
                                                                                    keyword: 'type',
                                                                                    params: { type: 'boolean' },
                                                                                    message: 'must be boolean',
                                                                                };
                                                                                if (vErrors === null) {
                                                                                    vErrors = [err17];
                                                                                }
                                                                                else {
                                                                                    vErrors.push(err17);
                                                                                }
                                                                                errors++;
                                                                            }
                                                                            var valid2 = _errs35 === errors;
                                                                        }
                                                                        else {
                                                                            var valid2 = true;
                                                                        }
                                                                        if (valid2) {
                                                                            if (data1.duration !== undefined) {
                                                                                var data13 = data1.duration;
                                                                                var _errs37 = errors;
                                                                                if (!(typeof data13 == 'number' &&
                                                                                    isFinite(data13)) &&
                                                                                    data13 !== null) {
                                                                                    var err18 = {
                                                                                        instancePath: instancePath +
                                                                                            '/downloadRecording/duration',
                                                                                        schemaPath: '#/properties/downloadRecording/anyOf/0/properties/duration/type',
                                                                                        keyword: 'type',
                                                                                        params: {
                                                                                            type: schema51.properties
                                                                                                .downloadRecording.anyOf[0]
                                                                                                .properties.duration.type,
                                                                                        },
                                                                                        message: 'must be number,null',
                                                                                    };
                                                                                    if (vErrors === null) {
                                                                                        vErrors = [err18];
                                                                                    }
                                                                                    else {
                                                                                        vErrors.push(err18);
                                                                                    }
                                                                                    errors++;
                                                                                }
                                                                                var valid2 = _errs37 === errors;
                                                                            }
                                                                            else {
                                                                                var valid2 = true;
                                                                            }
                                                                            if (valid2) {
                                                                                if (data1.width !== undefined) {
                                                                                    var data14 = data1.width;
                                                                                    var _errs39 = errors;
                                                                                    if (!(typeof data14 == 'number' &&
                                                                                        isFinite(data14)) &&
                                                                                        data14 !== null) {
                                                                                        var err19 = {
                                                                                            instancePath: instancePath +
                                                                                                '/downloadRecording/width',
                                                                                            schemaPath: '#/properties/downloadRecording/anyOf/0/properties/width/type',
                                                                                            keyword: 'type',
                                                                                            params: {
                                                                                                type: schema51.properties
                                                                                                    .downloadRecording.anyOf[0]
                                                                                                    .properties.width.type,
                                                                                            },
                                                                                            message: 'must be number,null',
                                                                                        };
                                                                                        if (vErrors === null) {
                                                                                            vErrors = [err19];
                                                                                        }
                                                                                        else {
                                                                                            vErrors.push(err19);
                                                                                        }
                                                                                        errors++;
                                                                                    }
                                                                                    var valid2 = _errs39 === errors;
                                                                                }
                                                                                else {
                                                                                    var valid2 = true;
                                                                                }
                                                                                if (valid2) {
                                                                                    if (data1.height !== undefined) {
                                                                                        var data15 = data1.height;
                                                                                        var _errs41 = errors;
                                                                                        if (!(typeof data15 == 'number' &&
                                                                                            isFinite(data15)) &&
                                                                                            data15 !== null) {
                                                                                            var err20 = {
                                                                                                instancePath: instancePath +
                                                                                                    '/downloadRecording/height',
                                                                                                schemaPath: '#/properties/downloadRecording/anyOf/0/properties/height/type',
                                                                                                keyword: 'type',
                                                                                                params: {
                                                                                                    type: schema51.properties
                                                                                                        .downloadRecording.anyOf[0]
                                                                                                        .properties.height.type,
                                                                                                },
                                                                                                message: 'must be number,null',
                                                                                            };
                                                                                            if (vErrors === null) {
                                                                                                vErrors = [err20];
                                                                                            }
                                                                                            else {
                                                                                                vErrors.push(err20);
                                                                                            }
                                                                                            errors++;
                                                                                        }
                                                                                        var valid2 = _errs41 === errors;
                                                                                    }
                                                                                    else {
                                                                                        var valid2 = true;
                                                                                    }
                                                                                }
                                                                            }
                                                                        }
                                                                    }
                                                                }
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                                else {
                                    var err21 = {
                                        instancePath: instancePath + '/downloadRecording',
                                        schemaPath: '#/properties/downloadRecording/anyOf/0/type',
                                        keyword: 'type',
                                        params: { type: 'object' },
                                        message: 'must be object',
                                    };
                                    if (vErrors === null) {
                                        vErrors = [err21];
                                    }
                                    else {
                                        vErrors.push(err21);
                                    }
                                    errors++;
                                }
                            }
                            var _valid0 = _errs6 === errors;
                            valid1 = valid1 || _valid0;
                            if (!valid1) {
                                var _errs43 = errors;
                                if (data1 !== null) {
                                    var err22 = {
                                        instancePath: instancePath + '/downloadRecording',
                                        schemaPath: '#/properties/downloadRecording/anyOf/1/type',
                                        keyword: 'type',
                                        params: { type: 'null' },
                                        message: 'must be null',
                                    };
                                    if (vErrors === null) {
                                        vErrors = [err22];
                                    }
                                    else {
                                        vErrors.push(err22);
                                    }
                                    errors++;
                                }
                                var _valid0 = _errs43 === errors;
                                valid1 = valid1 || _valid0;
                            }
                            if (!valid1) {
                                var err23 = {
                                    instancePath: instancePath + '/downloadRecording',
                                    schemaPath: '#/properties/downloadRecording/anyOf',
                                    keyword: 'anyOf',
                                    params: {},
                                    message: 'must match a schema in anyOf',
                                };
                                if (vErrors === null) {
                                    vErrors = [err23];
                                }
                                else {
                                    vErrors.push(err23);
                                }
                                errors++;
                                validate28.errors = vErrors;
                                return false;
                            }
                            else {
                                errors = _errs5;
                                if (vErrors !== null) {
                                    if (_errs5) {
                                        vErrors.length = _errs5;
                                    }
                                    else {
                                        vErrors = null;
                                    }
                                }
                            }
                            var valid0 = _errs4 === errors;
                        }
                        else {
                            var valid0 = true;
                        }
                        if (valid0) {
                            if (data.stepUuid !== undefined) {
                                var _errs45 = errors;
                                if (typeof data.stepUuid !== 'string') {
                                    validate28.errors = [
                                        {
                                            instancePath: instancePath + '/stepUuid',
                                            schemaPath: '#/properties/stepUuid/type',
                                            keyword: 'type',
                                            params: { type: 'string' },
                                            message: 'must be string',
                                        },
                                    ];
                                    return false;
                                }
                                var valid0 = _errs45 === errors;
                            }
                            else {
                                var valid0 = true;
                            }
                            if (valid0) {
                                if (data.stepRevision !== undefined) {
                                    var data17 = data.stepRevision;
                                    var _errs47 = errors;
                                    if (!(typeof data17 == 'number' && isFinite(data17))) {
                                        validate28.errors = [
                                            {
                                                instancePath: instancePath + '/stepRevision',
                                                schemaPath: '#/properties/stepRevision/type',
                                                keyword: 'type',
                                                params: { type: 'number' },
                                                message: 'must be number',
                                            },
                                        ];
                                        return false;
                                    }
                                    var valid0 = _errs47 === errors;
                                }
                                else {
                                    var valid0 = true;
                                }
                                if (valid0) {
                                    if (data.skipped !== undefined) {
                                        var _errs49 = errors;
                                        if (typeof data.skipped !== 'boolean') {
                                            validate28.errors = [
                                                {
                                                    instancePath: instancePath + '/skipped',
                                                    schemaPath: '#/properties/skipped/type',
                                                    keyword: 'type',
                                                    params: { type: 'boolean' },
                                                    message: 'must be boolean',
                                                },
                                            ];
                                            return false;
                                        }
                                        var valid0 = _errs49 === errors;
                                    }
                                    else {
                                        var valid0 = true;
                                    }
                                    if (valid0) {
                                        if (data.duration !== undefined) {
                                            var data19 = data.duration;
                                            var _errs51 = errors;
                                            if (!(typeof data19 == 'number' && isFinite(data19)) &&
                                                data19 !== null) {
                                                validate28.errors = [
                                                    {
                                                        instancePath: instancePath + '/duration',
                                                        schemaPath: '#/properties/duration/type',
                                                        keyword: 'type',
                                                        params: { type: schema51.properties.duration.type },
                                                        message: 'must be number,null',
                                                    },
                                                ];
                                                return false;
                                            }
                                            var valid0 = _errs51 === errors;
                                        }
                                        else {
                                            var valid0 = true;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        else {
            validate28.errors = [
                {
                    instancePath: instancePath,
                    schemaPath: '#/type',
                    keyword: 'type',
                    params: { type: 'object' },
                    message: 'must be object',
                },
            ];
            return false;
        }
    }
    validate28.errors = vErrors;
    return errors === 0;
}
var schema53 = {
    type: 'object',
    additionalProperties: false,
    properties: {
        type: {
            type: 'string',
            enum: [
                'STEP_CARDSORT_HYBRID',
                'STEP_CARDSORT_OPEN',
                'STEP_CARDSORT_CLOSED',
            ],
        },
        answers: { type: 'array', items: { $ref: '#/definitions/TextAnswer' } },
        duration: { type: ['number', 'null'] },
        stepUuid: { type: 'string' },
        stepRevision: { type: 'number' },
        skipped: { type: 'boolean' },
    },
    required: [
        'answers',
        'duration',
        'skipped',
        'stepRevision',
        'stepUuid',
        'type',
    ],
};
function validate30(data, _a) {
    var _b = _a === void 0 ? {} : _a, _c = _b.instancePath, instancePath = _c === void 0 ? '' : _c, parentData = _b.parentData, parentDataProperty = _b.parentDataProperty, _d = _b.rootData, rootData = _d === void 0 ? data : _d;
    var vErrors = null;
    var errors = 0;
    if (errors === 0) {
        if (data && typeof data == 'object' && !Array.isArray(data)) {
            var missing0 = void 0;
            if ((data.answers === undefined && (missing0 = 'answers')) ||
                (data.duration === undefined && (missing0 = 'duration')) ||
                (data.skipped === undefined && (missing0 = 'skipped')) ||
                (data.stepRevision === undefined && (missing0 = 'stepRevision')) ||
                (data.stepUuid === undefined && (missing0 = 'stepUuid')) ||
                (data.type === undefined && (missing0 = 'type'))) {
                validate30.errors = [
                    {
                        instancePath: instancePath,
                        schemaPath: '#/required',
                        keyword: 'required',
                        params: { missingProperty: missing0 },
                        message: "must have required property '" + missing0 + "'",
                    },
                ];
                return false;
            }
            else {
                var _errs1 = errors;
                for (var key0 in data) {
                    if (!(key0 === 'type' ||
                        key0 === 'answers' ||
                        key0 === 'duration' ||
                        key0 === 'stepUuid' ||
                        key0 === 'stepRevision' ||
                        key0 === 'skipped')) {
                        validate30.errors = [
                            {
                                instancePath: instancePath,
                                schemaPath: '#/additionalProperties',
                                keyword: 'additionalProperties',
                                params: { additionalProperty: key0 },
                                message: 'must NOT have additional properties',
                            },
                        ];
                        return false;
                        break;
                    }
                }
                if (_errs1 === errors) {
                    if (data.type !== undefined) {
                        var data0 = data.type;
                        var _errs2 = errors;
                        if (typeof data0 !== 'string') {
                            validate30.errors = [
                                {
                                    instancePath: instancePath + '/type',
                                    schemaPath: '#/properties/type/type',
                                    keyword: 'type',
                                    params: { type: 'string' },
                                    message: 'must be string',
                                },
                            ];
                            return false;
                        }
                        if (!(data0 === 'STEP_CARDSORT_HYBRID' ||
                            data0 === 'STEP_CARDSORT_OPEN' ||
                            data0 === 'STEP_CARDSORT_CLOSED')) {
                            validate30.errors = [
                                {
                                    instancePath: instancePath + '/type',
                                    schemaPath: '#/properties/type/enum',
                                    keyword: 'enum',
                                    params: { allowedValues: schema53.properties.type.enum },
                                    message: 'must be equal to one of the allowed values',
                                },
                            ];
                            return false;
                        }
                        var valid0 = _errs2 === errors;
                    }
                    else {
                        var valid0 = true;
                    }
                    if (valid0) {
                        if (data.answers !== undefined) {
                            var data1 = data.answers;
                            var _errs4 = errors;
                            if (errors === _errs4) {
                                if (Array.isArray(data1)) {
                                    var valid1 = true;
                                    var len0 = data1.length;
                                    for (var i0 = 0; i0 < len0; i0++) {
                                        var data2 = data1[i0];
                                        var _errs6 = errors;
                                        var _errs7 = errors;
                                        if (errors === _errs7) {
                                            if (data2 &&
                                                typeof data2 == 'object' &&
                                                !Array.isArray(data2)) {
                                                var missing1 = void 0;
                                                if ((data2.type === undefined && (missing1 = 'type')) ||
                                                    (data2.value === undefined && (missing1 = 'value'))) {
                                                    validate30.errors = [
                                                        {
                                                            instancePath: instancePath + '/answers/' + i0,
                                                            schemaPath: '#/definitions/TextAnswer/required',
                                                            keyword: 'required',
                                                            params: { missingProperty: missing1 },
                                                            message: "must have required property '" +
                                                                missing1 +
                                                                "'",
                                                        },
                                                    ];
                                                    return false;
                                                }
                                                else {
                                                    var _errs9 = errors;
                                                    for (var key1 in data2) {
                                                        if (!(key1 === 'type' || key1 === 'value')) {
                                                            validate30.errors = [
                                                                {
                                                                    instancePath: instancePath + '/answers/' + i0,
                                                                    schemaPath: '#/definitions/TextAnswer/additionalProperties',
                                                                    keyword: 'additionalProperties',
                                                                    params: { additionalProperty: key1 },
                                                                    message: 'must NOT have additional properties',
                                                                },
                                                            ];
                                                            return false;
                                                            break;
                                                        }
                                                    }
                                                    if (_errs9 === errors) {
                                                        if (data2.type !== undefined) {
                                                            var data3 = data2.type;
                                                            var _errs10 = errors;
                                                            if (typeof data3 !== 'string') {
                                                                validate30.errors = [
                                                                    {
                                                                        instancePath: instancePath + '/answers/' + i0 + '/type',
                                                                        schemaPath: '#/definitions/TextAnswer/properties/type/type',
                                                                        keyword: 'type',
                                                                        params: { type: 'string' },
                                                                        message: 'must be string',
                                                                    },
                                                                ];
                                                                return false;
                                                            }
                                                            if ('TEXT' !== data3) {
                                                                validate30.errors = [
                                                                    {
                                                                        instancePath: instancePath + '/answers/' + i0 + '/type',
                                                                        schemaPath: '#/definitions/TextAnswer/properties/type/const',
                                                                        keyword: 'const',
                                                                        params: { allowedValue: 'TEXT' },
                                                                        message: 'must be equal to constant',
                                                                    },
                                                                ];
                                                                return false;
                                                            }
                                                            var valid3 = _errs10 === errors;
                                                        }
                                                        else {
                                                            var valid3 = true;
                                                        }
                                                        if (valid3) {
                                                            if (data2.value !== undefined) {
                                                                var _errs12 = errors;
                                                                if (typeof data2.value !== 'string') {
                                                                    validate30.errors = [
                                                                        {
                                                                            instancePath: instancePath +
                                                                                '/answers/' +
                                                                                i0 +
                                                                                '/value',
                                                                            schemaPath: '#/definitions/TextAnswer/properties/value/type',
                                                                            keyword: 'type',
                                                                            params: { type: 'string' },
                                                                            message: 'must be string',
                                                                        },
                                                                    ];
                                                                    return false;
                                                                }
                                                                var valid3 = _errs12 === errors;
                                                            }
                                                            else {
                                                                var valid3 = true;
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                            else {
                                                validate30.errors = [
                                                    {
                                                        instancePath: instancePath + '/answers/' + i0,
                                                        schemaPath: '#/definitions/TextAnswer/type',
                                                        keyword: 'type',
                                                        params: { type: 'object' },
                                                        message: 'must be object',
                                                    },
                                                ];
                                                return false;
                                            }
                                        }
                                        var valid1 = _errs6 === errors;
                                        if (!valid1) {
                                            break;
                                        }
                                    }
                                }
                                else {
                                    validate30.errors = [
                                        {
                                            instancePath: instancePath + '/answers',
                                            schemaPath: '#/properties/answers/type',
                                            keyword: 'type',
                                            params: { type: 'array' },
                                            message: 'must be array',
                                        },
                                    ];
                                    return false;
                                }
                            }
                            var valid0 = _errs4 === errors;
                        }
                        else {
                            var valid0 = true;
                        }
                        if (valid0) {
                            if (data.duration !== undefined) {
                                var data5 = data.duration;
                                var _errs14 = errors;
                                if (!(typeof data5 == 'number' && isFinite(data5)) &&
                                    data5 !== null) {
                                    validate30.errors = [
                                        {
                                            instancePath: instancePath + '/duration',
                                            schemaPath: '#/properties/duration/type',
                                            keyword: 'type',
                                            params: { type: schema53.properties.duration.type },
                                            message: 'must be number,null',
                                        },
                                    ];
                                    return false;
                                }
                                var valid0 = _errs14 === errors;
                            }
                            else {
                                var valid0 = true;
                            }
                            if (valid0) {
                                if (data.stepUuid !== undefined) {
                                    var _errs16 = errors;
                                    if (typeof data.stepUuid !== 'string') {
                                        validate30.errors = [
                                            {
                                                instancePath: instancePath + '/stepUuid',
                                                schemaPath: '#/properties/stepUuid/type',
                                                keyword: 'type',
                                                params: { type: 'string' },
                                                message: 'must be string',
                                            },
                                        ];
                                        return false;
                                    }
                                    var valid0 = _errs16 === errors;
                                }
                                else {
                                    var valid0 = true;
                                }
                                if (valid0) {
                                    if (data.stepRevision !== undefined) {
                                        var data7 = data.stepRevision;
                                        var _errs18 = errors;
                                        if (!(typeof data7 == 'number' && isFinite(data7))) {
                                            validate30.errors = [
                                                {
                                                    instancePath: instancePath + '/stepRevision',
                                                    schemaPath: '#/properties/stepRevision/type',
                                                    keyword: 'type',
                                                    params: { type: 'number' },
                                                    message: 'must be number',
                                                },
                                            ];
                                            return false;
                                        }
                                        var valid0 = _errs18 === errors;
                                    }
                                    else {
                                        var valid0 = true;
                                    }
                                    if (valid0) {
                                        if (data.skipped !== undefined) {
                                            var _errs20 = errors;
                                            if (typeof data.skipped !== 'boolean') {
                                                validate30.errors = [
                                                    {
                                                        instancePath: instancePath + '/skipped',
                                                        schemaPath: '#/properties/skipped/type',
                                                        keyword: 'type',
                                                        params: { type: 'boolean' },
                                                        message: 'must be boolean',
                                                    },
                                                ];
                                                return false;
                                            }
                                            var valid0 = _errs20 === errors;
                                        }
                                        else {
                                            var valid0 = true;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        else {
            validate30.errors = [
                {
                    instancePath: instancePath,
                    schemaPath: '#/type',
                    keyword: 'type',
                    params: { type: 'object' },
                    message: 'must be object',
                },
            ];
            return false;
        }
    }
    validate30.errors = vErrors;
    return errors === 0;
}
function validate13(data, _a) {
    var _b = _a === void 0 ? {} : _a, _c = _b.instancePath, instancePath = _c === void 0 ? '' : _c, parentData = _b.parentData, parentDataProperty = _b.parentDataProperty, _d = _b.rootData, rootData = _d === void 0 ? data : _d;
    var vErrors = null;
    var errors = 0;
    var _errs0 = errors;
    var valid0 = false;
    var _errs1 = errors;
    var _errs2 = errors;
    if (errors === _errs2) {
        if (data && typeof data == 'object' && !Array.isArray(data)) {
            var missing0 = void 0;
            if ((data.duration === undefined && (missing0 = 'duration')) ||
                (data.skipped === undefined && (missing0 = 'skipped')) ||
                (data.stepRevision === undefined && (missing0 = 'stepRevision')) ||
                (data.stepUuid === undefined && (missing0 = 'stepUuid')) ||
                (data.type === undefined && (missing0 = 'type'))) {
                var err0 = {
                    instancePath: instancePath,
                    schemaPath: '#/definitions/InstructionResponse/required',
                    keyword: 'required',
                    params: { missingProperty: missing0 },
                    message: "must have required property '" + missing0 + "'",
                };
                if (vErrors === null) {
                    vErrors = [err0];
                }
                else {
                    vErrors.push(err0);
                }
                errors++;
            }
            else {
                var _errs4 = errors;
                for (var key0 in data) {
                    if (!(key0 === 'type' ||
                        key0 === 'stepUuid' ||
                        key0 === 'stepRevision' ||
                        key0 === 'skipped' ||
                        key0 === 'duration')) {
                        var err1 = {
                            instancePath: instancePath,
                            schemaPath: '#/definitions/InstructionResponse/additionalProperties',
                            keyword: 'additionalProperties',
                            params: { additionalProperty: key0 },
                            message: 'must NOT have additional properties',
                        };
                        if (vErrors === null) {
                            vErrors = [err1];
                        }
                        else {
                            vErrors.push(err1);
                        }
                        errors++;
                        break;
                    }
                }
                if (_errs4 === errors) {
                    if (data.type !== undefined) {
                        var data0 = data.type;
                        var _errs5 = errors;
                        if (typeof data0 !== 'string') {
                            var err2 = {
                                instancePath: instancePath + '/type',
                                schemaPath: '#/definitions/InstructionResponse/properties/type/type',
                                keyword: 'type',
                                params: { type: 'string' },
                                message: 'must be string',
                            };
                            if (vErrors === null) {
                                vErrors = [err2];
                            }
                            else {
                                vErrors.push(err2);
                            }
                            errors++;
                        }
                        if ('STEP_INSTRUCTION' !== data0) {
                            var err3 = {
                                instancePath: instancePath + '/type',
                                schemaPath: '#/definitions/InstructionResponse/properties/type/const',
                                keyword: 'const',
                                params: { allowedValue: 'STEP_INSTRUCTION' },
                                message: 'must be equal to constant',
                            };
                            if (vErrors === null) {
                                vErrors = [err3];
                            }
                            else {
                                vErrors.push(err3);
                            }
                            errors++;
                        }
                        var valid2 = _errs5 === errors;
                    }
                    else {
                        var valid2 = true;
                    }
                    if (valid2) {
                        if (data.stepUuid !== undefined) {
                            var _errs7 = errors;
                            if (typeof data.stepUuid !== 'string') {
                                var err4 = {
                                    instancePath: instancePath + '/stepUuid',
                                    schemaPath: '#/definitions/InstructionResponse/properties/stepUuid/type',
                                    keyword: 'type',
                                    params: { type: 'string' },
                                    message: 'must be string',
                                };
                                if (vErrors === null) {
                                    vErrors = [err4];
                                }
                                else {
                                    vErrors.push(err4);
                                }
                                errors++;
                            }
                            var valid2 = _errs7 === errors;
                        }
                        else {
                            var valid2 = true;
                        }
                        if (valid2) {
                            if (data.stepRevision !== undefined) {
                                var data2 = data.stepRevision;
                                var _errs9 = errors;
                                if (!(typeof data2 == 'number' && isFinite(data2))) {
                                    var err5 = {
                                        instancePath: instancePath + '/stepRevision',
                                        schemaPath: '#/definitions/InstructionResponse/properties/stepRevision/type',
                                        keyword: 'type',
                                        params: { type: 'number' },
                                        message: 'must be number',
                                    };
                                    if (vErrors === null) {
                                        vErrors = [err5];
                                    }
                                    else {
                                        vErrors.push(err5);
                                    }
                                    errors++;
                                }
                                var valid2 = _errs9 === errors;
                            }
                            else {
                                var valid2 = true;
                            }
                            if (valid2) {
                                if (data.skipped !== undefined) {
                                    var _errs11 = errors;
                                    if (typeof data.skipped !== 'boolean') {
                                        var err6 = {
                                            instancePath: instancePath + '/skipped',
                                            schemaPath: '#/definitions/InstructionResponse/properties/skipped/type',
                                            keyword: 'type',
                                            params: { type: 'boolean' },
                                            message: 'must be boolean',
                                        };
                                        if (vErrors === null) {
                                            vErrors = [err6];
                                        }
                                        else {
                                            vErrors.push(err6);
                                        }
                                        errors++;
                                    }
                                    var valid2 = _errs11 === errors;
                                }
                                else {
                                    var valid2 = true;
                                }
                                if (valid2) {
                                    if (data.duration !== undefined) {
                                        var data4 = data.duration;
                                        var _errs13 = errors;
                                        if (!(typeof data4 == 'number' && isFinite(data4)) &&
                                            data4 !== null) {
                                            var err7 = {
                                                instancePath: instancePath + '/duration',
                                                schemaPath: '#/definitions/InstructionResponse/properties/duration/type',
                                                keyword: 'type',
                                                params: { type: schema32.properties.duration.type },
                                                message: 'must be number,null',
                                            };
                                            if (vErrors === null) {
                                                vErrors = [err7];
                                            }
                                            else {
                                                vErrors.push(err7);
                                            }
                                            errors++;
                                        }
                                        var valid2 = _errs13 === errors;
                                    }
                                    else {
                                        var valid2 = true;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        else {
            var err8 = {
                instancePath: instancePath,
                schemaPath: '#/definitions/InstructionResponse/type',
                keyword: 'type',
                params: { type: 'object' },
                message: 'must be object',
            };
            if (vErrors === null) {
                vErrors = [err8];
            }
            else {
                vErrors.push(err8);
            }
            errors++;
        }
    }
    var _valid0 = _errs1 === errors;
    valid0 = valid0 || _valid0;
    if (!valid0) {
        var _errs15 = errors;
        if (!validate14(data, {
            instancePath: instancePath,
            parentData: parentData,
            parentDataProperty: parentDataProperty,
            rootData: rootData,
        })) {
            vErrors =
                vErrors === null
                    ? validate14.errors
                    : vErrors.concat(validate14.errors);
            errors = vErrors.length;
        }
        var _valid0 = _errs15 === errors;
        valid0 = valid0 || _valid0;
        if (!valid0) {
            var _errs16 = errors;
            if (!validate16(data, {
                instancePath: instancePath,
                parentData: parentData,
                parentDataProperty: parentDataProperty,
                rootData: rootData,
            })) {
                vErrors =
                    vErrors === null
                        ? validate16.errors
                        : vErrors.concat(validate16.errors);
                errors = vErrors.length;
            }
            var _valid0 = _errs16 === errors;
            valid0 = valid0 || _valid0;
            if (!valid0) {
                var _errs17 = errors;
                if (!validate18(data, {
                    instancePath: instancePath,
                    parentData: parentData,
                    parentDataProperty: parentDataProperty,
                    rootData: rootData,
                })) {
                    vErrors =
                        vErrors === null
                            ? validate18.errors
                            : vErrors.concat(validate18.errors);
                    errors = vErrors.length;
                }
                var _valid0 = _errs17 === errors;
                valid0 = valid0 || _valid0;
                if (!valid0) {
                    var _errs18 = errors;
                    if (!validate20(data, {
                        instancePath: instancePath,
                        parentData: parentData,
                        parentDataProperty: parentDataProperty,
                        rootData: rootData,
                    })) {
                        vErrors =
                            vErrors === null
                                ? validate20.errors
                                : vErrors.concat(validate20.errors);
                        errors = vErrors.length;
                    }
                    var _valid0 = _errs18 === errors;
                    valid0 = valid0 || _valid0;
                    if (!valid0) {
                        var _errs19 = errors;
                        if (!validate22(data, {
                            instancePath: instancePath,
                            parentData: parentData,
                            parentDataProperty: parentDataProperty,
                            rootData: rootData,
                        })) {
                            vErrors =
                                vErrors === null
                                    ? validate22.errors
                                    : vErrors.concat(validate22.errors);
                            errors = vErrors.length;
                        }
                        var _valid0 = _errs19 === errors;
                        valid0 = valid0 || _valid0;
                        if (!valid0) {
                            var _errs20 = errors;
                            var _errs21 = errors;
                            if (errors === _errs21) {
                                if (data && typeof data == 'object' && !Array.isArray(data)) {
                                    var missing1 = void 0;
                                    if ((data.duration === undefined && (missing1 = 'duration')) ||
                                        (data.skipped === undefined && (missing1 = 'skipped')) ||
                                        (data.stats === undefined && (missing1 = 'stats')) ||
                                        (data.stepRevision === undefined &&
                                            (missing1 = 'stepRevision')) ||
                                        (data.stepUuid === undefined && (missing1 = 'stepUuid')) ||
                                        (data.type === undefined && (missing1 = 'type'))) {
                                        var err9 = {
                                            instancePath: instancePath,
                                            schemaPath: '#/definitions/PrototypeTaskResponse/required',
                                            keyword: 'required',
                                            params: { missingProperty: missing1 },
                                            message: "must have required property '" + missing1 + "'",
                                        };
                                        if (vErrors === null) {
                                            vErrors = [err9];
                                        }
                                        else {
                                            vErrors.push(err9);
                                        }
                                        errors++;
                                    }
                                    else {
                                        var _errs23 = errors;
                                        for (var key1 in data) {
                                            if (!(key1 === 'type' ||
                                                key1 === 'stats' ||
                                                key1 === 'stepUuid' ||
                                                key1 === 'stepRevision' ||
                                                key1 === 'skipped' ||
                                                key1 === 'duration')) {
                                                var err10 = {
                                                    instancePath: instancePath,
                                                    schemaPath: '#/definitions/PrototypeTaskResponse/additionalProperties',
                                                    keyword: 'additionalProperties',
                                                    params: { additionalProperty: key1 },
                                                    message: 'must NOT have additional properties',
                                                };
                                                if (vErrors === null) {
                                                    vErrors = [err10];
                                                }
                                                else {
                                                    vErrors.push(err10);
                                                }
                                                errors++;
                                                break;
                                            }
                                        }
                                        if (_errs23 === errors) {
                                            if (data.type !== undefined) {
                                                var data5 = data.type;
                                                var _errs24 = errors;
                                                if (typeof data5 !== 'string') {
                                                    var err11 = {
                                                        instancePath: instancePath + '/type',
                                                        schemaPath: '#/definitions/PrototypeTaskResponse/properties/type/type',
                                                        keyword: 'type',
                                                        params: { type: 'string' },
                                                        message: 'must be string',
                                                    };
                                                    if (vErrors === null) {
                                                        vErrors = [err11];
                                                    }
                                                    else {
                                                        vErrors.push(err11);
                                                    }
                                                    errors++;
                                                }
                                                if ('STEP_PROTOTYPE_TASK' !== data5) {
                                                    var err12 = {
                                                        instancePath: instancePath + '/type',
                                                        schemaPath: '#/definitions/PrototypeTaskResponse/properties/type/const',
                                                        keyword: 'const',
                                                        params: { allowedValue: 'STEP_PROTOTYPE_TASK' },
                                                        message: 'must be equal to constant',
                                                    };
                                                    if (vErrors === null) {
                                                        vErrors = [err12];
                                                    }
                                                    else {
                                                        vErrors.push(err12);
                                                    }
                                                    errors++;
                                                }
                                                var valid4 = _errs24 === errors;
                                            }
                                            else {
                                                var valid4 = true;
                                            }
                                            if (valid4) {
                                                if (data.stats !== undefined) {
                                                    var data6 = data.stats;
                                                    var _errs26 = errors;
                                                    var _errs27 = errors;
                                                    var valid5 = false;
                                                    var _errs28 = errors;
                                                    if (errors === _errs28) {
                                                        if (data6 &&
                                                            typeof data6 == 'object' &&
                                                            !Array.isArray(data6)) {
                                                            var missing2 = void 0;
                                                            if ((data6.goalHit === undefined &&
                                                                (missing2 = 'goalHit')) ||
                                                                (data6.misclickRate === undefined &&
                                                                    (missing2 = 'misclickRate')) ||
                                                                (data6.durationSeconds === undefined &&
                                                                    (missing2 = 'durationSeconds')) ||
                                                                (data6.clicksPerResult === undefined &&
                                                                    (missing2 = 'clicksPerResult'))) {
                                                                var err13 = {
                                                                    instancePath: instancePath + '/stats',
                                                                    schemaPath: '#/definitions/PrototypeTaskResponse/properties/stats/anyOf/0/required',
                                                                    keyword: 'required',
                                                                    params: { missingProperty: missing2 },
                                                                    message: "must have required property '" +
                                                                        missing2 +
                                                                        "'",
                                                                };
                                                                if (vErrors === null) {
                                                                    vErrors = [err13];
                                                                }
                                                                else {
                                                                    vErrors.push(err13);
                                                                }
                                                                errors++;
                                                            }
                                                            else {
                                                                var _errs30 = errors;
                                                                for (var key2 in data6) {
                                                                    if (!(key2 === 'goalHit' ||
                                                                        key2 === 'misclickRate' ||
                                                                        key2 === 'durationSeconds' ||
                                                                        key2 === 'clicksPerResult')) {
                                                                        var err14 = {
                                                                            instancePath: instancePath + '/stats',
                                                                            schemaPath: '#/definitions/PrototypeTaskResponse/properties/stats/anyOf/0/additionalProperties',
                                                                            keyword: 'additionalProperties',
                                                                            params: { additionalProperty: key2 },
                                                                            message: 'must NOT have additional properties',
                                                                        };
                                                                        if (vErrors === null) {
                                                                            vErrors = [err14];
                                                                        }
                                                                        else {
                                                                            vErrors.push(err14);
                                                                        }
                                                                        errors++;
                                                                        break;
                                                                    }
                                                                }
                                                                if (_errs30 === errors) {
                                                                    if (data6.goalHit !== undefined) {
                                                                        var data7 = data6.goalHit;
                                                                        var _errs31 = errors;
                                                                        if (typeof data7 !== 'boolean' &&
                                                                            data7 !== null) {
                                                                            var err15 = {
                                                                                instancePath: instancePath + '/stats/goalHit',
                                                                                schemaPath: '#/definitions/PrototypeTaskResponse/properties/stats/anyOf/0/properties/goalHit/type',
                                                                                keyword: 'type',
                                                                                params: {
                                                                                    type: schema43.properties.stats
                                                                                        .anyOf[0].properties.goalHit.type,
                                                                                },
                                                                                message: 'must be boolean,null',
                                                                            };
                                                                            if (vErrors === null) {
                                                                                vErrors = [err15];
                                                                            }
                                                                            else {
                                                                                vErrors.push(err15);
                                                                            }
                                                                            errors++;
                                                                        }
                                                                        var valid6 = _errs31 === errors;
                                                                    }
                                                                    else {
                                                                        var valid6 = true;
                                                                    }
                                                                    if (valid6) {
                                                                        if (data6.misclickRate !== undefined) {
                                                                            var data8 = data6.misclickRate;
                                                                            var _errs33 = errors;
                                                                            if (!(typeof data8 == 'number' &&
                                                                                isFinite(data8)) &&
                                                                                data8 !== null) {
                                                                                var err16 = {
                                                                                    instancePath: instancePath +
                                                                                        '/stats/misclickRate',
                                                                                    schemaPath: '#/definitions/PrototypeTaskResponse/properties/stats/anyOf/0/properties/misclickRate/type',
                                                                                    keyword: 'type',
                                                                                    params: {
                                                                                        type: schema43.properties.stats
                                                                                            .anyOf[0].properties.misclickRate
                                                                                            .type,
                                                                                    },
                                                                                    message: 'must be number,null',
                                                                                };
                                                                                if (vErrors === null) {
                                                                                    vErrors = [err16];
                                                                                }
                                                                                else {
                                                                                    vErrors.push(err16);
                                                                                }
                                                                                errors++;
                                                                            }
                                                                            var valid6 = _errs33 === errors;
                                                                        }
                                                                        else {
                                                                            var valid6 = true;
                                                                        }
                                                                        if (valid6) {
                                                                            if (data6.durationSeconds !== undefined) {
                                                                                var data9 = data6.durationSeconds;
                                                                                var _errs35 = errors;
                                                                                if (!(typeof data9 == 'number' &&
                                                                                    isFinite(data9)) &&
                                                                                    data9 !== null) {
                                                                                    var err17 = {
                                                                                        instancePath: instancePath +
                                                                                            '/stats/durationSeconds',
                                                                                        schemaPath: '#/definitions/PrototypeTaskResponse/properties/stats/anyOf/0/properties/durationSeconds/type',
                                                                                        keyword: 'type',
                                                                                        params: {
                                                                                            type: schema43.properties.stats
                                                                                                .anyOf[0].properties
                                                                                                .durationSeconds.type,
                                                                                        },
                                                                                        message: 'must be number,null',
                                                                                    };
                                                                                    if (vErrors === null) {
                                                                                        vErrors = [err17];
                                                                                    }
                                                                                    else {
                                                                                        vErrors.push(err17);
                                                                                    }
                                                                                    errors++;
                                                                                }
                                                                                var valid6 = _errs35 === errors;
                                                                            }
                                                                            else {
                                                                                var valid6 = true;
                                                                            }
                                                                            if (valid6) {
                                                                                if (data6.clicksPerResult !== undefined) {
                                                                                    var data10 = data6.clicksPerResult;
                                                                                    var _errs37 = errors;
                                                                                    if (!(typeof data10 == 'number' &&
                                                                                        isFinite(data10)) &&
                                                                                        data10 !== null) {
                                                                                        var err18 = {
                                                                                            instancePath: instancePath +
                                                                                                '/stats/clicksPerResult',
                                                                                            schemaPath: '#/definitions/PrototypeTaskResponse/properties/stats/anyOf/0/properties/clicksPerResult/type',
                                                                                            keyword: 'type',
                                                                                            params: {
                                                                                                type: schema43.properties.stats
                                                                                                    .anyOf[0].properties
                                                                                                    .clicksPerResult.type,
                                                                                            },
                                                                                            message: 'must be number,null',
                                                                                        };
                                                                                        if (vErrors === null) {
                                                                                            vErrors = [err18];
                                                                                        }
                                                                                        else {
                                                                                            vErrors.push(err18);
                                                                                        }
                                                                                        errors++;
                                                                                    }
                                                                                    var valid6 = _errs37 === errors;
                                                                                }
                                                                                else {
                                                                                    var valid6 = true;
                                                                                }
                                                                            }
                                                                        }
                                                                    }
                                                                }
                                                            }
                                                        }
                                                        else {
                                                            var err19 = {
                                                                instancePath: instancePath + '/stats',
                                                                schemaPath: '#/definitions/PrototypeTaskResponse/properties/stats/anyOf/0/type',
                                                                keyword: 'type',
                                                                params: { type: 'object' },
                                                                message: 'must be object',
                                                            };
                                                            if (vErrors === null) {
                                                                vErrors = [err19];
                                                            }
                                                            else {
                                                                vErrors.push(err19);
                                                            }
                                                            errors++;
                                                        }
                                                    }
                                                    var _valid1 = _errs28 === errors;
                                                    valid5 = valid5 || _valid1;
                                                    if (!valid5) {
                                                        var _errs39 = errors;
                                                        if (data6 !== null) {
                                                            var err20 = {
                                                                instancePath: instancePath + '/stats',
                                                                schemaPath: '#/definitions/PrototypeTaskResponse/properties/stats/anyOf/1/type',
                                                                keyword: 'type',
                                                                params: { type: 'null' },
                                                                message: 'must be null',
                                                            };
                                                            if (vErrors === null) {
                                                                vErrors = [err20];
                                                            }
                                                            else {
                                                                vErrors.push(err20);
                                                            }
                                                            errors++;
                                                        }
                                                        var _valid1 = _errs39 === errors;
                                                        valid5 = valid5 || _valid1;
                                                    }
                                                    if (!valid5) {
                                                        var err21 = {
                                                            instancePath: instancePath + '/stats',
                                                            schemaPath: '#/definitions/PrototypeTaskResponse/properties/stats/anyOf',
                                                            keyword: 'anyOf',
                                                            params: {},
                                                            message: 'must match a schema in anyOf',
                                                        };
                                                        if (vErrors === null) {
                                                            vErrors = [err21];
                                                        }
                                                        else {
                                                            vErrors.push(err21);
                                                        }
                                                        errors++;
                                                    }
                                                    else {
                                                        errors = _errs27;
                                                        if (vErrors !== null) {
                                                            if (_errs27) {
                                                                vErrors.length = _errs27;
                                                            }
                                                            else {
                                                                vErrors = null;
                                                            }
                                                        }
                                                    }
                                                    var valid4 = _errs26 === errors;
                                                }
                                                else {
                                                    var valid4 = true;
                                                }
                                                if (valid4) {
                                                    if (data.stepUuid !== undefined) {
                                                        var _errs41 = errors;
                                                        if (typeof data.stepUuid !== 'string') {
                                                            var err22 = {
                                                                instancePath: instancePath + '/stepUuid',
                                                                schemaPath: '#/definitions/PrototypeTaskResponse/properties/stepUuid/type',
                                                                keyword: 'type',
                                                                params: { type: 'string' },
                                                                message: 'must be string',
                                                            };
                                                            if (vErrors === null) {
                                                                vErrors = [err22];
                                                            }
                                                            else {
                                                                vErrors.push(err22);
                                                            }
                                                            errors++;
                                                        }
                                                        var valid4 = _errs41 === errors;
                                                    }
                                                    else {
                                                        var valid4 = true;
                                                    }
                                                    if (valid4) {
                                                        if (data.stepRevision !== undefined) {
                                                            var data12 = data.stepRevision;
                                                            var _errs43 = errors;
                                                            if (!(typeof data12 == 'number' && isFinite(data12))) {
                                                                var err23 = {
                                                                    instancePath: instancePath + '/stepRevision',
                                                                    schemaPath: '#/definitions/PrototypeTaskResponse/properties/stepRevision/type',
                                                                    keyword: 'type',
                                                                    params: { type: 'number' },
                                                                    message: 'must be number',
                                                                };
                                                                if (vErrors === null) {
                                                                    vErrors = [err23];
                                                                }
                                                                else {
                                                                    vErrors.push(err23);
                                                                }
                                                                errors++;
                                                            }
                                                            var valid4 = _errs43 === errors;
                                                        }
                                                        else {
                                                            var valid4 = true;
                                                        }
                                                        if (valid4) {
                                                            if (data.skipped !== undefined) {
                                                                var _errs45 = errors;
                                                                if (typeof data.skipped !== 'boolean') {
                                                                    var err24 = {
                                                                        instancePath: instancePath + '/skipped',
                                                                        schemaPath: '#/definitions/PrototypeTaskResponse/properties/skipped/type',
                                                                        keyword: 'type',
                                                                        params: { type: 'boolean' },
                                                                        message: 'must be boolean',
                                                                    };
                                                                    if (vErrors === null) {
                                                                        vErrors = [err24];
                                                                    }
                                                                    else {
                                                                        vErrors.push(err24);
                                                                    }
                                                                    errors++;
                                                                }
                                                                var valid4 = _errs45 === errors;
                                                            }
                                                            else {
                                                                var valid4 = true;
                                                            }
                                                            if (valid4) {
                                                                if (data.duration !== undefined) {
                                                                    var data14 = data.duration;
                                                                    var _errs47 = errors;
                                                                    if (!(typeof data14 == 'number' &&
                                                                        isFinite(data14)) &&
                                                                        data14 !== null) {
                                                                        var err25 = {
                                                                            instancePath: instancePath + '/duration',
                                                                            schemaPath: '#/definitions/PrototypeTaskResponse/properties/duration/type',
                                                                            keyword: 'type',
                                                                            params: {
                                                                                type: schema43.properties.duration.type,
                                                                            },
                                                                            message: 'must be number,null',
                                                                        };
                                                                        if (vErrors === null) {
                                                                            vErrors = [err25];
                                                                        }
                                                                        else {
                                                                            vErrors.push(err25);
                                                                        }
                                                                        errors++;
                                                                    }
                                                                    var valid4 = _errs47 === errors;
                                                                }
                                                                else {
                                                                    var valid4 = true;
                                                                }
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                                else {
                                    var err26 = {
                                        instancePath: instancePath,
                                        schemaPath: '#/definitions/PrototypeTaskResponse/type',
                                        keyword: 'type',
                                        params: { type: 'object' },
                                        message: 'must be object',
                                    };
                                    if (vErrors === null) {
                                        vErrors = [err26];
                                    }
                                    else {
                                        vErrors.push(err26);
                                    }
                                    errors++;
                                }
                            }
                            var _valid0 = _errs20 === errors;
                            valid0 = valid0 || _valid0;
                            if (!valid0) {
                                var _errs49 = errors;
                                if (!validate24(data, {
                                    instancePath: instancePath,
                                    parentData: parentData,
                                    parentDataProperty: parentDataProperty,
                                    rootData: rootData,
                                })) {
                                    vErrors =
                                        vErrors === null
                                            ? validate24.errors
                                            : vErrors.concat(validate24.errors);
                                    errors = vErrors.length;
                                }
                                var _valid0 = _errs49 === errors;
                                valid0 = valid0 || _valid0;
                                if (!valid0) {
                                    var _errs50 = errors;
                                    if (!validate26(data, {
                                        instancePath: instancePath,
                                        parentData: parentData,
                                        parentDataProperty: parentDataProperty,
                                        rootData: rootData,
                                    })) {
                                        vErrors =
                                            vErrors === null
                                                ? validate26.errors
                                                : vErrors.concat(validate26.errors);
                                        errors = vErrors.length;
                                    }
                                    var _valid0 = _errs50 === errors;
                                    valid0 = valid0 || _valid0;
                                    if (!valid0) {
                                        var _errs51 = errors;
                                        if (!validate28(data, {
                                            instancePath: instancePath,
                                            parentData: parentData,
                                            parentDataProperty: parentDataProperty,
                                            rootData: rootData,
                                        })) {
                                            vErrors =
                                                vErrors === null
                                                    ? validate28.errors
                                                    : vErrors.concat(validate28.errors);
                                            errors = vErrors.length;
                                        }
                                        var _valid0 = _errs51 === errors;
                                        valid0 = valid0 || _valid0;
                                        if (!valid0) {
                                            var _errs52 = errors;
                                            if (!validate30(data, {
                                                instancePath: instancePath,
                                                parentData: parentData,
                                                parentDataProperty: parentDataProperty,
                                                rootData: rootData,
                                            })) {
                                                vErrors =
                                                    vErrors === null
                                                        ? validate30.errors
                                                        : vErrors.concat(validate30.errors);
                                                errors = vErrors.length;
                                            }
                                            var _valid0 = _errs52 === errors;
                                            valid0 = valid0 || _valid0;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    if (!valid0) {
        var err27 = {
            instancePath: instancePath,
            schemaPath: '#/anyOf',
            keyword: 'anyOf',
            params: {},
            message: 'must match a schema in anyOf',
        };
        if (vErrors === null) {
            vErrors = [err27];
        }
        else {
            vErrors.push(err27);
        }
        errors++;
        validate13.errors = vErrors;
        return false;
    }
    else {
        errors = _errs0;
        if (vErrors !== null) {
            if (_errs0) {
                vErrors.length = _errs0;
            }
            else {
                vErrors = null;
            }
        }
    }
    validate13.errors = vErrors;
    return errors === 0;
}
function validate12(data, _a) {
    var _b = _a === void 0 ? {} : _a, _c = _b.instancePath, instancePath = _c === void 0 ? '' : _c, parentData = _b.parentData, parentDataProperty = _b.parentDataProperty, _d = _b.rootData, rootData = _d === void 0 ? data : _d;
    var vErrors = null;
    var errors = 0;
    if (errors === 0) {
        if (data && typeof data == 'object' && !Array.isArray(data)) {
            var missing0 = void 0;
            if ((data.pk === undefined && (missing0 = 'pk')) ||
                (data.participant === undefined && (missing0 = 'participant')) ||
                (data.submittedAt === undefined && (missing0 = 'submittedAt')) ||
                (data.device === undefined && (missing0 = 'device')) ||
                (data.hasRecording === undefined && (missing0 = 'hasRecording')) ||
                (data.status === undefined && (missing0 = 'status')) ||
                (data.stepResponses === undefined && (missing0 = 'stepResponses')) ||
                (data.responseUUID === undefined && (missing0 = 'responseUUID')) ||
                (data.recruitment === undefined && (missing0 = 'recruitment'))) {
                validate12.errors = [
                    {
                        instancePath: instancePath,
                        schemaPath: '#/required',
                        keyword: 'required',
                        params: { missingProperty: missing0 },
                        message: "must have required property '" + missing0 + "'",
                    },
                ];
                return false;
            }
            else {
                var _errs1 = errors;
                for (var key0 in data) {
                    if (!func2.call(schema29.properties, key0)) {
                        validate12.errors = [
                            {
                                instancePath: instancePath,
                                schemaPath: '#/additionalProperties',
                                keyword: 'additionalProperties',
                                params: { additionalProperty: key0 },
                                message: 'must NOT have additional properties',
                            },
                        ];
                        return false;
                        break;
                    }
                }
                if (_errs1 === errors) {
                    if (data.pk !== undefined) {
                        var data0 = data.pk;
                        var _errs2 = errors;
                        if (!(typeof data0 == 'number' && isFinite(data0))) {
                            validate12.errors = [
                                {
                                    instancePath: instancePath + '/pk',
                                    schemaPath: '#/properties/pk/type',
                                    keyword: 'type',
                                    params: { type: 'number' },
                                    message: 'must be number',
                                },
                            ];
                            return false;
                        }
                        var valid0 = _errs2 === errors;
                    }
                    else {
                        var valid0 = true;
                    }
                    if (valid0) {
                        if (data.participant !== undefined) {
                            var data1 = data.participant;
                            var _errs4 = errors;
                            if (errors === _errs4) {
                                if (data1 &&
                                    typeof data1 == 'object' &&
                                    !Array.isArray(data1)) {
                                    var missing1 = void 0;
                                    if (data1.name === undefined && (missing1 = 'name')) {
                                        validate12.errors = [
                                            {
                                                instancePath: instancePath + '/participant',
                                                schemaPath: '#/properties/participant/required',
                                                keyword: 'required',
                                                params: { missingProperty: missing1 },
                                                message: "must have required property '" + missing1 + "'",
                                            },
                                        ];
                                        return false;
                                    }
                                    else {
                                        var _errs6 = errors;
                                        for (var key1 in data1) {
                                            if (!(key1 === 'name')) {
                                                validate12.errors = [
                                                    {
                                                        instancePath: instancePath + '/participant',
                                                        schemaPath: '#/properties/participant/additionalProperties',
                                                        keyword: 'additionalProperties',
                                                        params: { additionalProperty: key1 },
                                                        message: 'must NOT have additional properties',
                                                    },
                                                ];
                                                return false;
                                                break;
                                            }
                                        }
                                        if (_errs6 === errors) {
                                            if (data1.name !== undefined) {
                                                if (typeof data1.name !== 'string') {
                                                    validate12.errors = [
                                                        {
                                                            instancePath: instancePath + '/participant/name',
                                                            schemaPath: '#/properties/participant/properties/name/type',
                                                            keyword: 'type',
                                                            params: { type: 'string' },
                                                            message: 'must be string',
                                                        },
                                                    ];
                                                    return false;
                                                }
                                            }
                                        }
                                    }
                                }
                                else {
                                    validate12.errors = [
                                        {
                                            instancePath: instancePath + '/participant',
                                            schemaPath: '#/properties/participant/type',
                                            keyword: 'type',
                                            params: { type: 'object' },
                                            message: 'must be object',
                                        },
                                    ];
                                    return false;
                                }
                            }
                            var valid0 = _errs4 === errors;
                        }
                        else {
                            var valid0 = true;
                        }
                        if (valid0) {
                            if (data.submittedAt !== undefined) {
                                var _errs9 = errors;
                                if (typeof data.submittedAt !== 'string') {
                                    validate12.errors = [
                                        {
                                            instancePath: instancePath + '/submittedAt',
                                            schemaPath: '#/properties/submittedAt/type',
                                            keyword: 'type',
                                            params: { type: 'string' },
                                            message: 'must be string',
                                        },
                                    ];
                                    return false;
                                }
                                var valid0 = _errs9 === errors;
                            }
                            else {
                                var valid0 = true;
                            }
                            if (valid0) {
                                if (data.device !== undefined) {
                                    var data4 = data.device;
                                    var _errs11 = errors;
                                    if (errors === _errs11) {
                                        if (data4 &&
                                            typeof data4 == 'object' &&
                                            !Array.isArray(data4)) {
                                            var missing2 = void 0;
                                            if ((data4.model === undefined && (missing2 = 'model')) ||
                                                (data4.operatingSystem === undefined &&
                                                    (missing2 = 'operatingSystem')) ||
                                                (data4.make === undefined && (missing2 = 'make'))) {
                                                validate12.errors = [
                                                    {
                                                        instancePath: instancePath + '/device',
                                                        schemaPath: '#/properties/device/required',
                                                        keyword: 'required',
                                                        params: { missingProperty: missing2 },
                                                        message: "must have required property '" + missing2 + "'",
                                                    },
                                                ];
                                                return false;
                                            }
                                            else {
                                                var _errs13 = errors;
                                                for (var key2 in data4) {
                                                    if (!(key2 === 'model' ||
                                                        key2 === 'operatingSystem' ||
                                                        key2 === 'make')) {
                                                        validate12.errors = [
                                                            {
                                                                instancePath: instancePath + '/device',
                                                                schemaPath: '#/properties/device/additionalProperties',
                                                                keyword: 'additionalProperties',
                                                                params: { additionalProperty: key2 },
                                                                message: 'must NOT have additional properties',
                                                            },
                                                        ];
                                                        return false;
                                                        break;
                                                    }
                                                }
                                                if (_errs13 === errors) {
                                                    if (data4.model !== undefined) {
                                                        var data5 = data4.model;
                                                        var _errs14 = errors;
                                                        if (typeof data5 !== 'string' && data5 !== null) {
                                                            validate12.errors = [
                                                                {
                                                                    instancePath: instancePath + '/device/model',
                                                                    schemaPath: '#/properties/device/properties/model/type',
                                                                    keyword: 'type',
                                                                    params: {
                                                                        type: schema29.properties.device.properties
                                                                            .model.type,
                                                                    },
                                                                    message: 'must be string,null',
                                                                },
                                                            ];
                                                            return false;
                                                        }
                                                        var valid2 = _errs14 === errors;
                                                    }
                                                    else {
                                                        var valid2 = true;
                                                    }
                                                    if (valid2) {
                                                        if (data4.operatingSystem !== undefined) {
                                                            var data6 = data4.operatingSystem;
                                                            var _errs16 = errors;
                                                            if (typeof data6 !== 'string' && data6 !== null) {
                                                                validate12.errors = [
                                                                    {
                                                                        instancePath: instancePath + '/device/operatingSystem',
                                                                        schemaPath: '#/properties/device/properties/operatingSystem/type',
                                                                        keyword: 'type',
                                                                        params: {
                                                                            type: schema29.properties.device
                                                                                .properties.operatingSystem.type,
                                                                        },
                                                                        message: 'must be string,null',
                                                                    },
                                                                ];
                                                                return false;
                                                            }
                                                            var valid2 = _errs16 === errors;
                                                        }
                                                        else {
                                                            var valid2 = true;
                                                        }
                                                        if (valid2) {
                                                            if (data4.make !== undefined) {
                                                                var data7 = data4.make;
                                                                var _errs18 = errors;
                                                                if (typeof data7 !== 'string' &&
                                                                    data7 !== null) {
                                                                    validate12.errors = [
                                                                        {
                                                                            instancePath: instancePath + '/device/make',
                                                                            schemaPath: '#/properties/device/properties/make/type',
                                                                            keyword: 'type',
                                                                            params: {
                                                                                type: schema29.properties.device
                                                                                    .properties.make.type,
                                                                            },
                                                                            message: 'must be string,null',
                                                                        },
                                                                    ];
                                                                    return false;
                                                                }
                                                                if (!(data7 === 'Web' ||
                                                                    data7 === 'Tablet' ||
                                                                    data7 === 'Mobile' ||
                                                                    data7 === null)) {
                                                                    validate12.errors = [
                                                                        {
                                                                            instancePath: instancePath + '/device/make',
                                                                            schemaPath: '#/properties/device/properties/make/enum',
                                                                            keyword: 'enum',
                                                                            params: {
                                                                                allowedValues: schema29.properties.device.properties
                                                                                    .make.enum,
                                                                            },
                                                                            message: 'must be equal to one of the allowed values',
                                                                        },
                                                                    ];
                                                                    return false;
                                                                }
                                                                var valid2 = _errs18 === errors;
                                                            }
                                                            else {
                                                                var valid2 = true;
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                        else {
                                            validate12.errors = [
                                                {
                                                    instancePath: instancePath + '/device',
                                                    schemaPath: '#/properties/device/type',
                                                    keyword: 'type',
                                                    params: { type: 'object' },
                                                    message: 'must be object',
                                                },
                                            ];
                                            return false;
                                        }
                                    }
                                    var valid0 = _errs11 === errors;
                                }
                                else {
                                    var valid0 = true;
                                }
                                if (valid0) {
                                    if (data.hasRecording !== undefined) {
                                        var _errs20 = errors;
                                        if (typeof data.hasRecording !== 'boolean') {
                                            validate12.errors = [
                                                {
                                                    instancePath: instancePath + '/hasRecording',
                                                    schemaPath: '#/properties/hasRecording/type',
                                                    keyword: 'type',
                                                    params: { type: 'boolean' },
                                                    message: 'must be boolean',
                                                },
                                            ];
                                            return false;
                                        }
                                        var valid0 = _errs20 === errors;
                                    }
                                    else {
                                        var valid0 = true;
                                    }
                                    if (valid0) {
                                        if (data.status !== undefined) {
                                            var data9 = data.status;
                                            var _errs22 = errors;
                                            if (typeof data9 !== 'string') {
                                                validate12.errors = [
                                                    {
                                                        instancePath: instancePath + '/status',
                                                        schemaPath: '#/definitions/UserTestResponseStatus/type',
                                                        keyword: 'type',
                                                        params: { type: 'string' },
                                                        message: 'must be string',
                                                    },
                                                ];
                                                return false;
                                            }
                                            if (!(data9 === 'COMPLETED' ||
                                                data9 === 'FAILED' ||
                                                data9 === 'INCOMPLETE' ||
                                                data9 === 'IN_PROGRESS' ||
                                                data9 === 'QUALIFIED_VIEWED' ||
                                                data9 === 'SCREENING_ABANDONED' ||
                                                data9 === 'SCREENING_DECLINED' ||
                                                data9 === 'SCREENING_STARTED')) {
                                                validate12.errors = [
                                                    {
                                                        instancePath: instancePath + '/status',
                                                        schemaPath: '#/definitions/UserTestResponseStatus/enum',
                                                        keyword: 'enum',
                                                        params: { allowedValues: schema30.enum },
                                                        message: 'must be equal to one of the allowed values',
                                                    },
                                                ];
                                                return false;
                                            }
                                            var valid0 = _errs22 === errors;
                                        }
                                        else {
                                            var valid0 = true;
                                        }
                                        if (valid0) {
                                            if (data.stepResponses !== undefined) {
                                                var data10 = data.stepResponses;
                                                var _errs25 = errors;
                                                if (errors === _errs25) {
                                                    if (Array.isArray(data10)) {
                                                        var valid4 = true;
                                                        var len0 = data10.length;
                                                        for (var i0 = 0; i0 < len0; i0++) {
                                                            var _errs27 = errors;
                                                            if (!validate13(data10[i0], {
                                                                instancePath: instancePath + '/stepResponses/' + i0,
                                                                parentData: data10,
                                                                parentDataProperty: i0,
                                                                rootData: rootData,
                                                            })) {
                                                                vErrors =
                                                                    vErrors === null
                                                                        ? validate13.errors
                                                                        : vErrors.concat(validate13.errors);
                                                                errors = vErrors.length;
                                                            }
                                                            var valid4 = _errs27 === errors;
                                                            if (!valid4) {
                                                                break;
                                                            }
                                                        }
                                                    }
                                                    else {
                                                        validate12.errors = [
                                                            {
                                                                instancePath: instancePath + '/stepResponses',
                                                                schemaPath: '#/properties/stepResponses/type',
                                                                keyword: 'type',
                                                                params: { type: 'array' },
                                                                message: 'must be array',
                                                            },
                                                        ];
                                                        return false;
                                                    }
                                                }
                                                var valid0 = _errs25 === errors;
                                            }
                                            else {
                                                var valid0 = true;
                                            }
                                            if (valid0) {
                                                if (data.responseUUID !== undefined) {
                                                    var _errs28 = errors;
                                                    if (typeof data.responseUUID !== 'string') {
                                                        validate12.errors = [
                                                            {
                                                                instancePath: instancePath + '/responseUUID',
                                                                schemaPath: '#/properties/responseUUID/type',
                                                                keyword: 'type',
                                                                params: { type: 'string' },
                                                                message: 'must be string',
                                                            },
                                                        ];
                                                        return false;
                                                    }
                                                    var valid0 = _errs28 === errors;
                                                }
                                                else {
                                                    var valid0 = true;
                                                }
                                                if (valid0) {
                                                    if (data.recruitment !== undefined) {
                                                        var data13 = data.recruitment;
                                                        var _errs30 = errors;
                                                        if (errors === _errs30) {
                                                            if (data13 &&
                                                                typeof data13 == 'object' &&
                                                                !Array.isArray(data13)) {
                                                                var missing3 = void 0;
                                                                if ((data13.participantSource === undefined &&
                                                                    (missing3 = 'participantSource')) ||
                                                                    (data13.participantId === undefined &&
                                                                        (missing3 = 'participantId')) ||
                                                                    (data13.studyId === undefined &&
                                                                        (missing3 = 'studyId'))) {
                                                                    validate12.errors = [
                                                                        {
                                                                            instancePath: instancePath + '/recruitment',
                                                                            schemaPath: '#/properties/recruitment/required',
                                                                            keyword: 'required',
                                                                            params: { missingProperty: missing3 },
                                                                            message: "must have required property '" +
                                                                                missing3 +
                                                                                "'",
                                                                        },
                                                                    ];
                                                                    return false;
                                                                }
                                                                else {
                                                                    var _errs32 = errors;
                                                                    for (var key3 in data13) {
                                                                        if (!(key3 === 'participantSource' ||
                                                                            key3 === 'participantId' ||
                                                                            key3 === 'studyId')) {
                                                                            validate12.errors = [
                                                                                {
                                                                                    instancePath: instancePath + '/recruitment',
                                                                                    schemaPath: '#/properties/recruitment/additionalProperties',
                                                                                    keyword: 'additionalProperties',
                                                                                    params: { additionalProperty: key3 },
                                                                                    message: 'must NOT have additional properties',
                                                                                },
                                                                            ];
                                                                            return false;
                                                                            break;
                                                                        }
                                                                    }
                                                                    if (_errs32 === errors) {
                                                                        if (data13.participantSource !== undefined) {
                                                                            var data14 = data13.participantSource;
                                                                            var _errs33 = errors;
                                                                            if (typeof data14 !== 'string') {
                                                                                validate12.errors = [
                                                                                    {
                                                                                        instancePath: instancePath +
                                                                                            '/recruitment/participantSource',
                                                                                        schemaPath: '#/definitions/ParticipantSource/type',
                                                                                        keyword: 'type',
                                                                                        params: { type: 'string' },
                                                                                        message: 'must be string',
                                                                                    },
                                                                                ];
                                                                                return false;
                                                                            }
                                                                            if (!(data14 === 'Rally' ||
                                                                                data14 === 'Share' ||
                                                                                data14 === 'Enhanced' ||
                                                                                data14 === 'Enhanced B2B' ||
                                                                                data14 === 'Standard')) {
                                                                                validate12.errors = [
                                                                                    {
                                                                                        instancePath: instancePath +
                                                                                            '/recruitment/participantSource',
                                                                                        schemaPath: '#/definitions/ParticipantSource/enum',
                                                                                        keyword: 'enum',
                                                                                        params: {
                                                                                            allowedValues: schema55.enum,
                                                                                        },
                                                                                        message: 'must be equal to one of the allowed values',
                                                                                    },
                                                                                ];
                                                                                return false;
                                                                            }
                                                                            var valid5 = _errs33 === errors;
                                                                        }
                                                                        else {
                                                                            var valid5 = true;
                                                                        }
                                                                        if (valid5) {
                                                                            if (data13.participantId !== undefined) {
                                                                                var data15 = data13.participantId;
                                                                                var _errs36 = errors;
                                                                                if (typeof data15 !== 'string' &&
                                                                                    data15 !== null) {
                                                                                    validate12.errors = [
                                                                                        {
                                                                                            instancePath: instancePath +
                                                                                                '/recruitment/participantId',
                                                                                            schemaPath: '#/properties/recruitment/properties/participantId/type',
                                                                                            keyword: 'type',
                                                                                            params: {
                                                                                                type: schema29.properties
                                                                                                    .recruitment.properties
                                                                                                    .participantId.type,
                                                                                            },
                                                                                            message: 'must be string,null',
                                                                                        },
                                                                                    ];
                                                                                    return false;
                                                                                }
                                                                                var valid5 = _errs36 === errors;
                                                                            }
                                                                            else {
                                                                                var valid5 = true;
                                                                            }
                                                                            if (valid5) {
                                                                                if (data13.studyId !== undefined) {
                                                                                    var data16 = data13.studyId;
                                                                                    var _errs38 = errors;
                                                                                    if (typeof data16 !== 'string' &&
                                                                                        data16 !== null) {
                                                                                        validate12.errors = [
                                                                                            {
                                                                                                instancePath: instancePath +
                                                                                                    '/recruitment/studyId',
                                                                                                schemaPath: '#/properties/recruitment/properties/studyId/type',
                                                                                                keyword: 'type',
                                                                                                params: {
                                                                                                    type: schema29.properties
                                                                                                        .recruitment.properties
                                                                                                        .studyId.type,
                                                                                                },
                                                                                                message: 'must be string,null',
                                                                                            },
                                                                                        ];
                                                                                        return false;
                                                                                    }
                                                                                    var valid5 = _errs38 === errors;
                                                                                }
                                                                                else {
                                                                                    var valid5 = true;
                                                                                }
                                                                            }
                                                                        }
                                                                    }
                                                                }
                                                            }
                                                            else {
                                                                validate12.errors = [
                                                                    {
                                                                        instancePath: instancePath + '/recruitment',
                                                                        schemaPath: '#/properties/recruitment/type',
                                                                        keyword: 'type',
                                                                        params: { type: 'object' },
                                                                        message: 'must be object',
                                                                    },
                                                                ];
                                                                return false;
                                                            }
                                                        }
                                                        var valid0 = _errs30 === errors;
                                                    }
                                                    else {
                                                        var valid0 = true;
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        else {
            validate12.errors = [
                {
                    instancePath: instancePath,
                    schemaPath: '#/type',
                    keyword: 'type',
                    params: { type: 'object' },
                    message: 'must be object',
                },
            ];
            return false;
        }
    }
    validate12.errors = vErrors;
    return errors === 0;
}
function validate11(data, _a) {
    var _b = _a === void 0 ? {} : _a, _c = _b.instancePath, instancePath = _c === void 0 ? '' : _c, parentData = _b.parentData, parentDataProperty = _b.parentDataProperty, _d = _b.rootData, rootData = _d === void 0 ? data : _d;
    var vErrors = null;
    var errors = 0;
    if (errors === 0) {
        if (data && typeof data == 'object' && !Array.isArray(data)) {
            var missing0 = void 0;
            if ((data.pk === undefined && (missing0 = 'pk')) ||
                (data.uuid === undefined && (missing0 = 'uuid')) ||
                (data.responses === undefined && (missing0 = 'responses'))) {
                validate11.errors = [
                    {
                        instancePath: instancePath,
                        schemaPath: '#/required',
                        keyword: 'required',
                        params: { missingProperty: missing0 },
                        message: "must have required property '" + missing0 + "'",
                    },
                ];
                return false;
            }
            else {
                var _errs1 = errors;
                for (var key0 in data) {
                    if (!(key0 === 'pk' || key0 === 'uuid' || key0 === 'responses')) {
                        validate11.errors = [
                            {
                                instancePath: instancePath,
                                schemaPath: '#/additionalProperties',
                                keyword: 'additionalProperties',
                                params: { additionalProperty: key0 },
                                message: 'must NOT have additional properties',
                            },
                        ];
                        return false;
                        break;
                    }
                }
                if (_errs1 === errors) {
                    if (data.pk !== undefined) {
                        var data0 = data.pk;
                        var _errs2 = errors;
                        if (!(typeof data0 == 'number' && isFinite(data0))) {
                            validate11.errors = [
                                {
                                    instancePath: instancePath + '/pk',
                                    schemaPath: '#/properties/pk/type',
                                    keyword: 'type',
                                    params: { type: 'number' },
                                    message: 'must be number',
                                },
                            ];
                            return false;
                        }
                        var valid0 = _errs2 === errors;
                    }
                    else {
                        var valid0 = true;
                    }
                    if (valid0) {
                        if (data.uuid !== undefined) {
                            var _errs4 = errors;
                            if (typeof data.uuid !== 'string') {
                                validate11.errors = [
                                    {
                                        instancePath: instancePath + '/uuid',
                                        schemaPath: '#/properties/uuid/type',
                                        keyword: 'type',
                                        params: { type: 'string' },
                                        message: 'must be string',
                                    },
                                ];
                                return false;
                            }
                            var valid0 = _errs4 === errors;
                        }
                        else {
                            var valid0 = true;
                        }
                        if (valid0) {
                            if (data.responses !== undefined) {
                                var data2 = data.responses;
                                var _errs6 = errors;
                                if (errors === _errs6) {
                                    if (Array.isArray(data2)) {
                                        var valid1 = true;
                                        var len0 = data2.length;
                                        for (var i0 = 0; i0 < len0; i0++) {
                                            var _errs8 = errors;
                                            if (!validate12(data2[i0], {
                                                instancePath: instancePath + '/responses/' + i0,
                                                parentData: data2,
                                                parentDataProperty: i0,
                                                rootData: rootData,
                                            })) {
                                                vErrors =
                                                    vErrors === null
                                                        ? validate12.errors
                                                        : vErrors.concat(validate12.errors);
                                                errors = vErrors.length;
                                            }
                                            var valid1 = _errs8 === errors;
                                            if (!valid1) {
                                                break;
                                            }
                                        }
                                    }
                                    else {
                                        validate11.errors = [
                                            {
                                                instancePath: instancePath + '/responses',
                                                schemaPath: '#/properties/responses/type',
                                                keyword: 'type',
                                                params: { type: 'array' },
                                                message: 'must be array',
                                            },
                                        ];
                                        return false;
                                    }
                                }
                                var valid0 = _errs6 === errors;
                            }
                            else {
                                var valid0 = true;
                            }
                        }
                    }
                }
            }
        }
        else {
            validate11.errors = [
                {
                    instancePath: instancePath,
                    schemaPath: '#/type',
                    keyword: 'type',
                    params: { type: 'object' },
                    message: 'must be object',
                },
            ];
            return false;
        }
    }
    validate11.errors = vErrors;
    return errors === 0;
}
function validate10(data, _a) {
    var _b = _a === void 0 ? {} : _a, _c = _b.instancePath, instancePath = _c === void 0 ? '' : _c, parentData = _b.parentData, parentDataProperty = _b.parentDataProperty, _d = _b.rootData, rootData = _d === void 0 ? data : _d;
    var vErrors = null;
    var errors = 0;
    if (!validate11(data, {
        instancePath: instancePath,
        parentData: parentData,
        parentDataProperty: parentDataProperty,
        rootData: rootData,
    })) {
        vErrors =
            vErrors === null ? validate11.errors : vErrors.concat(validate11.errors);
        errors = vErrors.length;
    }
    validate10.errors = vErrors;
    return errors === 0;
}
export default validate10;
