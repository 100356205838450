import { useEffect, useRef } from 'react';
/* Animates the title while picking your display so it's more obvious */
export default function useAnimateTabTitle(animate) {
    const originalTitle = useRef(document.title);
    const animationInterval = useRef();
    useEffect(() => {
        let position = 0;
        const title = originalTitle.current;
        if (animate) {
            animationInterval.current = setInterval(() => {
                const pickingTitle = '➡️➡️➡️ Pick this screen ';
                document.title =
                    pickingTitle.substring(position, pickingTitle.length) +
                        pickingTitle.substring(0, position);
                position++;
                if (position > pickingTitle.length)
                    position = 0;
            }, 500);
        }
        else {
            document.title = originalTitle.current;
            if (animationInterval.current) {
                clearInterval(animationInterval.current);
                animationInterval.current = undefined;
            }
        }
        return () => {
            document.title = title;
            clearInterval(animationInterval.current);
        };
    }, [animate]);
}
