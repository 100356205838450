import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useRef } from 'react';
import { usePaginatedQuery } from '@marvelapp/ballpark-application';
import { useOnClickOutside } from '@marvelapp/hooks';
import styled, { css, keyframes } from '@marvelapp/styled';
import { Popover, Position } from '@marvelapp/ui';
import SimpleSearchInput from '../SimpleSearchInput';
import SearchPeopleList from './SearchPeopleList';
export default function SearchPeople({ debouncedSearchTerm, renderPersonRowButtonComponent, inputRef, inviteUrl, isSearchDisabled, latestSearchTerm, onRemove, queryData, setSearchTerm, shouldShakeSearchInput, }) {
    const { dataConnection, isCollaborator, query, onAdd, searchVariable, variables, } = queryData;
    const { edges: people, loadNextPage, hasNextPage, isLoading, isLoadingMore, } = usePaginatedQuery(query, {
        dataConnection,
        variables: Object.assign(Object.assign({}, variables), { [searchVariable]: debouncedSearchTerm }),
        skip: !debouncedSearchTerm,
    });
    const popoverRef = useRef();
    const containerRef = useRef();
    const onInputChange = (event) => {
        setSearchTerm(event.target.value);
    };
    useOnClickOutside(containerRef, () => {
        setSearchTerm('');
    });
    return (_jsxs(Position, { position: "relative", ref: containerRef, zIndex: "popover", children: [_jsx(Shake, { shouldShake: shouldShakeSearchInput, children: _jsx(SimpleSearchInput, { isDisabled: isSearchDisabled, onChange: onInputChange, onClear: () => setSearchTerm(''), placeholder: "Find people in your workspace", ref: inputRef, showClearButton: debouncedSearchTerm, value: latestSearchTerm }) }), _jsx(Popover, { boxShadow: "popover.raised", isActive: debouncedSearchTerm, position: "bottom", showOn: "custom", width: "calc(100% + 40px)", children: debouncedSearchTerm && (_jsx("div", { ref: popoverRef, children: _jsx(SearchPeopleList, { hasNextPage: hasNextPage, inviteUrl: inviteUrl, isLoading: isLoading, isLoadingMore: isLoadingMore, loadNextPage: loadNextPage, onAdd: onAdd, onRemove: onRemove, people: people, personIsAdded: isCollaborator, renderPersonRowButtonComponent: renderPersonRowButtonComponent, searchTerm: debouncedSearchTerm }) })) })] }));
}
const shake = keyframes `
  10%, 90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%, 80% {
    transform: translate3d(2px, 0, 0);
  }
  30%, 50%, 70% {
    transform: translate3d(-4px, 0, 0);
  }
  40%, 60% {
    transform: translate3d(4px, 0, 0);
  }
`;
const Shake = styled.div `
  transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
  perspective: 1000px;
  ${(props) => props.shouldShake &&
    css `
      animation: ${shake} 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
    `};
`;
