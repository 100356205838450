import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { gql, useMutation } from '@apollo/client';
import { observer } from 'mobx-react';
import * as React from 'react';
import { BtwHeading, BtwSettingsItem, Stack, } from '@marvelapp/ballpark-components';
import { showGenericServerError } from '@marvelapp/ui-internal';
const UPDATE_USER_TEST_SETTING = gql `
  mutation updateUserTestDeviceSettings($input: UpdateUserTestInput!) {
    updateUserTest(input: $input) {
      ok
      error {
        message
        code
      }
      userTest {
        pk
        prototype {
          pk
          settings {
            isAvailableOnDesktopDevice
            isAvailableOnTabletDevice
            isAvailableOnMobileDevice
          }
        }
      }
    }
  }
`;
export const DeviceSettings = observer(function DeviceSettings({ deviceSettings, prototypePk, userTestPk, }) {
    const [updateUserTest] = useMutation(UPDATE_USER_TEST_SETTING, {
        onError: showGenericServerError,
    });
    const updateDeviceSetting = (enabled, setting) => {
        updateUserTest({
            variables: {
                input: {
                    pk: userTestPk,
                    prototypeSettings: {
                        [setting]: enabled,
                    },
                },
                [setting]: true,
            },
            optimisticResponse: {
                updateUserTest: {
                    __typename: 'UpdateUserTest',
                    error: null,
                    ok: true,
                    userTest: {
                        __typename: 'UserTestNode',
                        pk: userTestPk,
                        prototype: {
                            __typename: 'PrototypeNode',
                            pk: prototypePk,
                            settings: Object.assign(Object.assign({}, deviceSettings), { [setting]: enabled }),
                        },
                    },
                },
            },
        });
    };
    if (!deviceSettings) {
        return null;
    }
    const { isAvailableOnDesktopDevice, isAvailableOnMobileDevice, isAvailableOnTabletDevice, } = deviceSettings;
    const selectedDevicesCount = Number(isAvailableOnDesktopDevice) +
        Number(isAvailableOnMobileDevice) +
        Number(isAvailableOnTabletDevice);
    const disabledTooltipText = selectedDevicesCount === 1
        ? 'You need at least one device type selected'
        : '';
    return (_jsxs(Stack, { gap: "4", width: "full", align: "stretch", children: [_jsx(BtwHeading, { size: "sm", weight: "medium", children: "Restrict devices that can access the test" }), _jsx(BtwSettingsItem, { "data-testid": "device-setting-toggle--desktop", description: "Allow desktop devices", disabled: !!disabledTooltipText && !!isAvailableOnDesktopDevice, disabledTooltipText: disabledTooltipText, onPressedChange: () => updateDeviceSetting(!isAvailableOnDesktopDevice, 'isAvailableOnDesktopDevice'), pressed: !!isAvailableOnDesktopDevice, variant: "legacyBlue" }), _jsx(BtwSettingsItem, { "data-testid": "device-setting-toggle--mobile", description: "Allow mobile devices", disabled: !!disabledTooltipText && !!isAvailableOnMobileDevice, disabledTooltipText: disabledTooltipText, onPressedChange: () => updateDeviceSetting(!isAvailableOnMobileDevice, 'isAvailableOnMobileDevice'), pressed: !!isAvailableOnMobileDevice, variant: "legacyBlue" }), _jsx(BtwSettingsItem, { "data-testid": "device-setting-toggle--tablet", description: "Allow tablet devices", disabled: !!disabledTooltipText && !!isAvailableOnTabletDevice, disabledTooltipText: disabledTooltipText, onPressedChange: () => updateDeviceSetting(!isAvailableOnTabletDevice, 'isAvailableOnTabletDevice'), pressed: !!isAvailableOnTabletDevice, variant: "legacyBlue" })] }));
});
