import { useEffect } from 'react';
import { useConfig, usePusher } from '@marvelapp/ballpark-application';
import { PusherEvent } from '@marvelapp/core';
export function usePusherAggregationUpdated(projectPk, callback) {
    const config = useConfig();
    const { pusher } = usePusher();
    useEffect(() => {
        if (!projectPk)
            return;
        const channelName = `${config.pusher.projectChannelPrefix}${projectPk}`;
        const { channel, unsubscribe } = pusher.subscribe(channelName);
        channel.bind(PusherEvent.UserTestAggregated, (eventData) => {
            callback(eventData);
        });
        return () => unsubscribe();
    }, [config.pusher.projectChannelPrefix, pusher, projectPk, callback]);
}
