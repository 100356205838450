import { gql, useMutation } from '@apollo/client';
import LogRocket from 'logrocket';
import { action } from 'mobx';
import { useMemo } from 'react';
import { useAction } from '@marvelapp/ballpark-application';
import { showErrorNotification } from '@marvelapp/ui-internal';
import { StoreSelectedErrorEnum, StudyTypeEnum, } from '../../__generated__/queryTypes';
import { RequestsQueue } from './requestsQueue';
import { GET_SAVED_STUDY_ORDER, selectedOptionFragment, } from './useGetRecruitmentData';
const SAVE_SELECTED_OPTIONS = gql `
  mutation storeSelected($input: StudyOrderDetailsInputNode!) {
    storeSelectedOptions(input: $input) {
      ok
      failed {
        code
        message
        option
        filter
      }
      error {
        code
        message
      }
      studyOrderDetails {
        numParticipants
        studyDuration
        studyId
        studyType
        eligibilityDetails {
          countParticipants
          error
        }
        filters {
          id
          name
          options(first: 100) {
            edges {
              node {
                ...selectedOption
              }
            }
          }
        }
        unsupportedFilters {
          id
          name
          isAvailableForStudyType
          options(first: 100) {
            __typename
            edges {
              node {
                ...selectedOption
              }
            }
          }
        }
      }
    }
  }
  ${selectedOptionFragment}
`;
export function useStoreSelectedOptionsMutation(mutable, testUUID) {
    const [storeSelectedOptions] = useMutation(SAVE_SELECTED_OPTIONS);
    const requestsQueue = useMemo(() => new RequestsQueue(), []);
    const storeSelectedFn = useAction((payload) => {
        const revertToLastGoodStateAndClearQueue = (error) => {
            requestsQueue.clearQueue();
            // If the mutation fails for some reason, we want to
            // revert local state to last known good state
            mutable.setLocalOrderStateToLastSavedOrder();
            if (error) {
                LogRocket.captureException(error, {
                    extra: {
                        errorInfo: `Failed on payload: ${JSON.stringify(payload)}`,
                    },
                });
            }
        };
        requestsQueue.queueRequest(payload);
        if (!requestsQueue.requestInProgress) {
            requestsQueue.requestInProgress = true;
            storeSelectedOptions({
                variables: {
                    input: payload,
                },
                onCompleted: action((data) => {
                    var _a, _b;
                    mutable.isUpdatingSelectedFilters = false;
                    if ((_a = data === null || data === void 0 ? void 0 : data.storeSelectedOptions) === null || _a === void 0 ? void 0 : _a.error) {
                        const { error } = data.storeSelectedOptions;
                        revertToLastGoodStateAndClearQueue({
                            name: error.code,
                            message: error.message,
                        });
                        if (data.storeSelectedOptions.error.code ===
                            StoreSelectedErrorEnum.INVALID_NUMBER_PARTICIPANTS) {
                            showErrorNotification({
                                autoClose: 5000,
                                content: `Number of responses must be between ${mutable.allowedNumberOfResponses.min} and ${
                                // TODO: this needs to be reverted once the backend is updated with new data
                                mutable.studyType === StudyTypeEnum.SURVEY
                                    ? 750
                                    : mutable.allowedNumberOfResponses.max}.`,
                                toastId: 'errorStoringSelected',
                            });
                        }
                        else if (data.storeSelectedOptions.error.code ===
                            StoreSelectedErrorEnum.INVALID_STUDY_DURATION) {
                            showErrorNotification({
                                autoClose: 5000,
                                content: `Study duration must be between ${mutable.allowedDuration.min} and ${mutable.allowedDuration.max}.`,
                                toastId: 'errorStoringSelected',
                            });
                        }
                        else {
                            mutable.storeSelectedOptionsError =
                                data.storeSelectedOptions.error.code;
                        }
                    }
                    else if ((_b = data === null || data === void 0 ? void 0 : data.storeSelectedOptions) === null || _b === void 0 ? void 0 : _b.studyOrderDetails) {
                        mutable.updateSavedDraftOrder(data.storeSelectedOptions.studyOrderDetails);
                    }
                    requestsQueue.dequeueRequest(storeSelectedFn);
                }),
                onError: (error) => {
                    mutable.isUpdatingSelectedFilters = false;
                    revertToLastGoodStateAndClearQueue(error);
                    showErrorNotification({
                        autoClose: 5000,
                        content: 'An error occurred while storing your selected options.',
                        toastId: 'errorStoringSelected',
                    });
                },
                update: (client, { data }) => {
                    client.updateQuery({
                        query: GET_SAVED_STUDY_ORDER,
                        variables: { testUUID },
                    }, (originalData) => (Object.assign(Object.assign({}, originalData), { studyOrderDetails: data.storeSelectedOptions.studyOrderDetails })));
                },
            });
        }
    }, [mutable, requestsQueue, storeSelectedOptions, testUUID]);
    return storeSelectedFn;
}
