import { StepClass } from '../common';
import { generateUUID } from '../utils/generateUUID';
import { STEP_PREFIXES } from './constants';
import { YesOrNoStepType } from './types';
export function createYesNoStep(partial = {}) {
    return Object.assign({ uuid: generateUUID(STEP_PREFIXES[StepClass.YesOrNo]), className: StepClass.YesOrNo, title: null, description: [], isRequired: false, revision: 0, type: YesOrNoStepType.YesNo, conditions: {
            ALWAYS: null,
            answer: {
                true: null,
                false: null,
            },
        } }, partial);
}
export function createLegalStep(partial = {}) {
    return Object.assign({ uuid: generateUUID(STEP_PREFIXES[StepClass.YesOrNo]), className: StepClass.YesOrNo, title: null, description: [], isRequired: false, revision: 0, type: YesOrNoStepType.Legal }, partial);
}
