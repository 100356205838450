import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { observer } from 'mobx-react';
import { useEffect, useRef } from 'react';
import { useIntl } from 'react-intl';
import { useGetUserTest } from '@marvelapp/ballpark-application';
import { Badge, BtwButton, BtwModal, BtwText, ConditionsIcon, Stack, getIconColor, } from '@marvelapp/ballpark-components';
import { formatStringOrMessage, getStepName, getStepTitle, } from '@marvelapp/ballpark-copy';
import { QuestionMarkIcon } from '@marvelapp/ballpark-icons';
import { ConditionalLogicUtils, getFixedStepType, getOrderedListOfAllSteps, getStepIndex, getStepType, } from '@marvelapp/user-test-creator';
import { useConditionalLogicState } from '../ConditionalLogicContext';
export const LogicStepSidebar = observer(function LogicStepSidebar() {
    const { activeStepUUID, setActiveStepUUID } = useConditionalLogicState();
    const userTest = useGetUserTest();
    const steps = getOrderedListOfAllSteps({ userTest });
    return (_jsxs(Stack, { className: "w-[40%] min-w-[320px] max-w-sm", "data-testid": "logic-step-sidebar", height: "full", children: [_jsx(BtwModal.Header, { title: "Conditional logic", icon: _jsx(BtwButton, { asChild: true, size: "sm", variant: "ghost", standaloneIcon: _jsx(QuestionMarkIcon, { className: "h-3.5 w-3.5" }), children: _jsx("a", { href: "https://help.ballparkhq.com/en/articles/8329799-how-to-use-conditional-logic", target: "_blank", rel: "noreferrer" }) }) }), _jsx(Stack, { className: "w-full gap-3 overflow-y-scroll p-3", height: "full", children: steps.map((step) => (_jsx(ConditionalStepCard, { step: step, isActive: activeStepUUID === step.uuid, onClickStepCard: () => setActiveStepUUID(step.uuid) }, step.uuid))) })] }));
});
const ConditionalStepCard = observer(function ConditionalStepCard({ step, isActive, onClickStepCard, }) {
    const intl = useIntl();
    const userTest = useGetUserTest();
    const { iconColor, stepNumber, stepName, stepTitle, ruleCount, fixedType } = getStepData({
        userTest,
        step,
    });
    const stepCardRef = useRef(null);
    useScrollIntoView(isActive, stepCardRef);
    return (_jsxs(Stack, { className: `w-full cursor-pointer rounded-lg p-4 shadow-sm ring-1 ring-gray-600/20 transition-shadow duration-150 hover:bg-slate-50 ${isActive
            ? 'outline-3 outline outline-offset-1 outline-gray-300 ring-1 ring-gray-600'
            : 'border-gray-200'}`, "data-testid": `${'type' in step ? step.type : fixedType || step.className}-logic-card`, "data-state": isActive ? 'active' : 'inactive', onClick: onClickStepCard, ref: stepCardRef, gap: "2", children: [_jsx(BtwText, { className: "line-clamp-2", weight: "medium", variant: "primary", size: "sm", children: formatStringOrMessage(intl, stepTitle) }), _jsxs(Stack, { direction: "row", gap: "2", justify: "between", width: "full", align: "center", children: [_jsxs(Stack, { direction: "row", align: "baseline", gap: "2", children: [_jsx(Badge, { variant: iconColor, size: "sm", children: stepNumber }), _jsx(BtwText, { size: "xs", weight: "semibold", className: "relative top-px", children: intl.formatMessage(stepName) })] }), ruleCount > 0 && (_jsxs(Stack, { direction: "row", gap: "1", align: "center", children: [_jsx(ConditionsIcon, { fill: "#6B7280" }), ' ', _jsx(BtwText, { size: "xs", weight: "semibold", children: ruleCount })] }))] })] }));
});
function getStepData({ userTest, step }) {
    const fixedType = getFixedStepType(userTest, step.uuid);
    const iconColor = getIconColor(fixedType !== null && fixedType !== void 0 ? fixedType : getStepType(step));
    const stepName = getStepName(step, fixedType);
    const stepNumber = getStepIndex(userTest, step.uuid) + 1;
    const stepTitle = getStepTitle(step);
    const ruleCount = ConditionalLogicUtils.getConditionsCount(step, userTest);
    return {
        stepNumber,
        stepName,
        stepTitle,
        iconColor,
        ruleCount,
        fixedType,
    };
}
function useScrollIntoView(isActive, stepCardRef) {
    useEffect(() => {
        // upon opening the modal, scroll to active step
        if (isActive && stepCardRef.current) {
            stepCardRef.current.scrollIntoView({
                behavior: 'smooth',
                block: 'center',
            });
        }
    }, [isActive, stepCardRef]);
}
