import { gql, useMutation } from '@apollo/client';
import { useCallback, useEffect, useState } from 'react';
import { usePermissions } from '@marvelapp/ballpark-application';
import { usePollForQueryComplete } from '@marvelapp/hooks';
import { showErrorNotification } from '@marvelapp/ui-internal';
const TOAST_ID = 'csvGenerator';
const errorMessage = 'Error generating your CSV file';
const CREATE_USER_TEST_CSV = gql `
  mutation newGenerateUserTestCSV(
    $testPk: Int!
    $queryData: JSONString
    $stepUuid: String
  ) {
    generateUserTestCSV(
      input: { testPk: $testPk, queryData: $queryData, stepUuid: $stepUuid }
    ) {
      ok
      csvFileStatus {
        pollId
        name
        testPk
        url
        complete
      }
      error {
        message
        code
      }
    }
  }
`;
const QUERY_POLL_ID = gql `
  query newPollCSVCreationStatus($pollId: Int!) {
    resultsCsvStatus(pollId: $pollId) {
      name
      url
      complete
      failed
    }
  }
`;
export function useDownloadCSV(args) {
    var _a;
    let projectPk;
    let dataQuery;
    let stepUuid;
    if ('dataQuery' in args) {
        ({ projectPk, dataQuery } = args);
    }
    else {
        ({ projectPk, stepUuid } = args);
    }
    const [isLoading, setIsLoading] = useState(false);
    const permissions = usePermissions();
    const isDownloadCSVAvailable = permissions.canDownloadResultsCSV();
    const [generateUserTestCSV, { data: csvData, error: createCsvError }] = useMutation(CREATE_USER_TEST_CSV, {
        fetchPolicy: 'network-only',
        variables: {
            testPk: Number(projectPk),
            queryData: isDataQueryDefined(dataQuery)
                ? JSON.stringify(dataQuery)
                : null,
            stepUuid,
        },
    });
    const pollId = (_a = csvData === null || csvData === void 0 ? void 0 : csvData.generateUserTestCSV.csvFileStatus) === null || _a === void 0 ? void 0 : _a.pollId;
    const { pollForStatus } = usePollForQueryComplete({
        onQueryFail: () => {
            setIsLoading(false);
            showErrorNotification({
                toastId: TOAST_ID,
                content: errorMessage,
            });
        },
        onQueryComplete: (data) => {
            setIsLoading(false);
            const fileUrl = data.resultsCsvStatus.url;
            // Validate the URL to prevent downloading from untrusted sources
            if (!isValidGoogleApisUrl(fileUrl)) {
                return;
            }
            const hiddenElement = document.createElement('a');
            hiddenElement.href = fileUrl;
            hiddenElement.download = fileUrl;
            hiddenElement.click();
        },
        query: QUERY_POLL_ID,
        queryName: 'resultsCsvStatus',
        pollTimeout: 5000 * 15, // 15 minutes timeout
    });
    useEffect(() => {
        if (createCsvError) {
            setIsLoading(false);
            showErrorNotification({
                toastId: TOAST_ID,
                content: errorMessage,
            });
        }
    }, [createCsvError]);
    useEffect(() => {
        if (pollId) {
            pollForStatus({ variables: { pollId } });
        }
    }, [pollForStatus, pollId]);
    const triggerDownload = useCallback(() => {
        setIsLoading(true);
        generateUserTestCSV();
    }, [generateUserTestCSV]);
    return { triggerDownload, isLoading, isDownloadCSVAvailable };
}
function isDataQueryDefined(dataQuery) {
    return Boolean(dataQuery &&
        (dataQuery.sorting || dataQuery.operatorType || dataQuery.stepFilters));
}
function isValidGoogleApisUrl(urlString) {
    try {
        const url = new URL(urlString);
        return (url.protocol === 'https:' && url.hostname.endsWith('.googleapis.com'));
    }
    catch (_) {
        return false;
    }
}
