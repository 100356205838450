import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { AnimatePresence, motion } from 'framer-motion';
import { observer } from 'mobx-react';
import * as React from 'react';
import { useIntl } from 'react-intl';
import { useAction, useGetUserTest } from '@marvelapp/ballpark-application';
import { UI_STEPS_COPY } from '@marvelapp/ballpark-copy';
import theme from '@marvelapp/ballpark-theme';
import { FixedStep, InstructionStepUtils, getSteps, } from '@marvelapp/user-test-creator';
import { DecoratedSlide } from '../common/DecoratedSlide';
import MainCTA from '../common/MainCTA';
import SlideContent from '../common/SlideContent';
const animation = {
    animate: {
        opacity: 1,
        scale: 1,
    },
    exit: {
        opacity: 0,
        scale: 0,
    },
    transition: {
        ease: theme.easings.smooth.array,
        duration: 0.35,
    },
};
export default observer(function ExitEdit() {
    const intl = useIntl();
    const userTest = useGetUserTest();
    const { exitStep } = getSteps(userTest);
    const setButtonText = useAction((value) => InstructionStepUtils.setButtonText(exitStep, value), [exitStep]);
    return (_jsxs(DecoratedSlide, { children: [_jsx(SlideContent, { maxHeadingLength: 512, maxParagraphLength: 512 }), _jsx(AnimatePresence, { children: exitStep.hasButton && (_jsx(motion.div, { animate: animation.animate, exit: animation.exit, initial: animation.exit, transition: animation.transition, children: _jsx(MainCTA, { testId: "edit-exit-step-cta", initialButtonValue: exitStep.buttonText || '', placeholder: intl.formatMessage(UI_STEPS_COPY[FixedStep.Exit].buttonText), onSaveButtonText: setButtonText }) }, "main-cta")) })] }));
});
