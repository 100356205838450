import { gql, useQuery } from '@apollo/client';
const GET_PROTOTYPE_EVENTS_DATA = gql `
  query getPrototypeEventData($stepUuid: String!, $resultsPk: [Int!]!) {
    prototypeStepMappers(stepUuid: $stepUuid, resultsPk: $resultsPk) {
      pk
      stepUuid
      prototype {
        prototypeUrl
      }
    }
  }
`;
export function useGetPrototypeEventsData({ stepUuid, 
// resultPk,
skip = false, }) {
    var _a, _b;
    const { data } = useQuery(GET_PROTOTYPE_EVENTS_DATA, {
        variables: {
            stepUuid,
            // resultsPk: [resultPk],
            resultsPk: [],
        },
        skip,
    });
    if (!((_a = data === null || data === void 0 ? void 0 : data.prototypeStepMappers) === null || _a === void 0 ? void 0 : _a.length)) {
        return null;
    }
    return {
        prototypeUrl: ((_b = data.prototypeStepMappers[0]) === null || _b === void 0 ? void 0 : _b.prototype.prototypeUrl) || '',
    };
}
