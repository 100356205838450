import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { difference } from 'lodash-es';
import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useCurrentUser, userSelectors } from '@marvelapp/ballpark-application';
import { PlanLabel, UserRole, UserRoleType, getRoleDescription, isValidEmail, } from '@marvelapp/core';
import styled from '@marvelapp/styled';
import { Box, Button, Flex, Message, Relative, Text } from '@marvelapp/ui';
import EmailsInput from '../EmailsInput';
import InputError from '../InputError';
import Link from '../Link';
import ModalTitle from '../ModalTitle';
import { showErrorNotification } from '../ToastContainer';
import RoleRow from './RoleRow';
const GUEST_LIMIT = 5;
// TODO: This component will move out of `ui-internal` eventually. When this happens,
// we should remove `upgradeBadge` from props, and import it directly from @marvelapp/ballpark-components
// (currently not a dependency of ui-internal, and we wouldn't want to make it one)
export default function InviteUsers({ buttonIsLoading, onInviteUsers, upgradeBadge, }) {
    const { search } = useLocation();
    const user = useCurrentUser();
    const { guestCount, billing } = user.company;
    const planLabel = billing.plan.label;
    const isEnterprise = planLabel === PlanLabel.Enterprise;
    const currentUserIsOwnerOrAdmin = [UserRole.Owner, UserRole.Admin].includes(user.role);
    const [inviteType, setInviteType] = useState(getInitialInviteType(search));
    const [selectedMemberRole, setSelectedMemberRole] = useState(UserRole.Editor);
    const [emails, setEmails] = useState([]);
    const [showFormError, setShowFormError] = useState(false);
    const getValidEmails = () => {
        const validEmails = [];
        emails.forEach((e) => {
            const email = e.trim();
            if (isValidEmail(email)) {
                validEmails.push(email);
            }
        });
        return validEmails;
    };
    const suffix = emails.length > 1 ? ' people' : ' person';
    const canInviteAllRoles = !(userSelectors.isLockedFreePlan(user) || userSelectors.isPro(user));
    const buttonText = emails.length
        ? `Invite ${emails.length}${suffix}`
        : 'Enter some emails';
    const errorMessage = 'Please enter 20 emails or less.';
    const warningMessage = (_jsxs(_Fragment, { children: ["You", "'", "ve entered a few emails and inviting everyone may take a while.", ' ', _jsx("br", {}), " Don", "'", "t leave this page!"] }));
    const formErrorMessage = `There was an issue adding the email addresses below to your project.
    Please try again or alternatively contact support at`;
    const memberRoleOptions = [
        {
            value: UserRole.Editor,
            label: 'Editor',
            description: getRoleDescription(UserRole.Editor),
        },
        {
            value: UserRole.InternalReviewer,
            label: (_jsxs(Flex, { alignItems: "center", className: "gap-2", children: ["Viewer", !canInviteAllRoles && upgradeBadge] })),
            description: getRoleDescription(UserRole.InternalReviewer),
            disabled: !canInviteAllRoles,
            url: canInviteAllRoles ? null : '/plans?intent=more-user-roles',
        },
    ];
    // only allow owners or admins to invite other Admins.
    if (currentUserIsOwnerOrAdmin) {
        memberRoleOptions.push({
            value: UserRole.Admin,
            label: (_jsxs(Flex, { alignItems: "center", className: "gap-2", children: ["Admin", !canInviteAllRoles && upgradeBadge] })),
            description: getRoleDescription(UserRole.Admin),
            disabled: !canInviteAllRoles,
            url: canInviteAllRoles ? null : '/plans?intent=more-user-roles',
        });
    }
    const guestRoleOptions = [
        {
            value: UserRole.ExternalReviewer,
            label: 'Viewer',
            description: getRoleDescription(UserRole.ExternalReviewer),
        },
    ];
    const onGuestRoleChange = (guestRole) => {
        const isRedirectedToDemoPage = guestRole === UserRole.ExternalEditor && !isEnterprise;
        if (!isRedirectedToDemoPage) {
            if (inviteType !== UserRoleType.Guest) {
                setInviteType(UserRoleType.Guest);
            }
        }
    };
    const onMemberRoleChange = (memberRole) => {
        setSelectedMemberRole(memberRole);
        if (inviteType !== UserRoleType.Member) {
            setInviteType(UserRoleType.Member);
        }
    };
    const onInviteButtonClick = () => {
        const validEmails = getValidEmails();
        const invalidEmails = difference(emails, validEmails);
        if (validEmails.length) {
            if (invalidEmails.length) {
                invalidEmails.forEach((email) => {
                    showErrorNotification({
                        content: `${email} is not a valid email address. Please try again`,
                    });
                });
            }
            else {
                onInviteUsers({
                    emails: validEmails,
                    role: inviteType === UserRoleType.Member
                        ? selectedMemberRole
                        : UserRole.ExternalReviewer,
                    setEmails,
                    setShowFormError,
                });
            }
        }
        else {
            showErrorNotification({
                content: 'Please submit valid email addresses',
            });
        }
    };
    return (_jsxs(Box, { "data-testid": "invite-people-form", pb: 5, children: [_jsx(ModalTitle, { mb: 5, subtitle: "Work together on projects.", title: "Invite people to your workspace" }), _jsxs(Relative, { children: [showFormError && (_jsxs(InputError, { "data-testid": "invite-user-error-message", mb: 3, children: [formErrorMessage, ' ', _jsx(Link, { color: "marvel", to: "mailto:help@ballparkhq.com", children: "help@ballparkhq.com" })] })), emails.length > 10 && (_jsx(Message, { kind: emails.length > 20 ? 'error' : 'information', py: 1, children: _jsx(Text, { children: emails.length > 20 ? errorMessage : warningMessage }) })), _jsx(Flex, { justifyContent: "space-between", my: 3, children: _jsx(Text, { color: "licorice", fontSize: 3, fontWeight: 500, children: "Enter emails" }) }), _jsx(EmailsInput, { emails: emails, placeholder: "Separate by hitting comma or enter", setEmails: setEmails, setShowFormError: setShowFormError, showFormError: showFormError }), _jsxs(RolesBox, { children: [_jsx(RoleRow, { isRowSelected: inviteType === UserRoleType.Member, onRadioClick: () => setInviteType(UserRoleType.Member), onRoleChange: onMemberRoleChange, roleOptions: memberRoleOptions, roleType: UserRoleType.Member, selectedRole: selectedMemberRole }), _jsx(RoleRow, { isRowSelected: inviteType === UserRoleType.Guest, maxAllowance: !isEnterprise && GUEST_LIMIT, onRadioClick: () => setInviteType(UserRoleType.Guest), onRoleChange: onGuestRoleChange, readOnly: !canInviteAllRoles, roleOptions: guestRoleOptions, roleSelectDirection: "top", roleType: UserRoleType.Guest, seatsUsed: guestCount, selectedRole: UserRole.ExternalReviewer })] })] }), _jsx(Flex, { alignItems: "center", children: _jsx(Button, { borderRadius: "12px", "data-testid": "invite-button", isDisabled: emails.length > 20, isLoading: buttonIsLoading, kind: "green", onClick: onInviteButtonClick, size: 3, children: buttonText }) })] }));
}
function getInitialInviteType(search) {
    return search !== '?add_contributors'
        ? UserRoleType.Member
        : UserRoleType.Guest;
}
const RolesBox = styled(Box).attrs({
    as: 'form',
    my: 'm',
    borderRadius: 'm',
    boxShadow: 'button.ghost',
}) `
  display: block;

  > :first-child {
    border-top: 0px;
  }
`;
