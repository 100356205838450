import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { gql, useMutation } from '@apollo/client';
import { xor } from 'lodash-es';
import LogRocket from 'logrocket';
import { observer } from 'mobx-react';
import { useCallback } from 'react';
import * as React from 'react';
import { useRouteMatch } from 'react-router-dom';
import { BtwButton, BtwInput, BtwLink, BtwModal, BtwText, Combobox, Stack, } from '@marvelapp/ballpark-components';
import { CONTACT_SUPPORT, REPORTING_RESPONSES } from '@marvelapp/ballpark-help';
import { AsteriskIcon, CheckRadioIcon, FeatherIcon, } from '@marvelapp/ballpark-icons';
import { showErrorNotification, showSuccessNotification, } from '@marvelapp/ui-internal';
import { ReasonEnum, ReportResultErrorEnum, StudyStatusEnum, } from '../../../__generated__/queryTypes';
import { removeResultFromCache } from '../Toolbar/DeleteResultsAction';
const reasonToLabelMap = {
    ANSWERS_IMPROPER: 'Poor quality answers',
    ANSWERS_TOO_SHORT: 'Answers were too short',
    DUPLICATE_PARTICIPANT: 'Duplicate participant',
    INCORRECT_SCREEN: 'Shared the incorrect screen',
    NO_AUDIO: 'Audio problems',
    NO_SCREEN_RECORDING: 'Screenshare problems',
    NO_WEBCAM: 'Video problems',
    WRONG_DEMOGRAPHICS: "Didn't match the demographic requirements",
    OTHER: 'Other reason',
};
const REASONS = [
    ReasonEnum.NO_AUDIO,
    ReasonEnum.NO_WEBCAM,
    ReasonEnum.INCORRECT_SCREEN,
    ReasonEnum.NO_SCREEN_RECORDING,
    ReasonEnum.ANSWERS_TOO_SHORT,
    ReasonEnum.ANSWERS_IMPROPER,
    ReasonEnum.DUPLICATE_PARTICIPANT,
    ReasonEnum.WRONG_DEMOGRAPHICS,
    ReasonEnum.OTHER,
];
const REPORT_RESULT = gql `
  mutation reportResult(
    $resultUUID: String!
    $reasons: [ReasonEnum!]!
    $message: String
  ) {
    reportResult(
      input: { resultUUID: $resultUUID, reasons: $reasons, message: $message }
    ) {
      ok
      error {
        code
        message
      }
    }
  }
`;
export const ReportResponse = observer(function ReportResponse({ isLoadingOrder, modalView, order, onSuccess, resultUUID, resultPk, setModalView, }) {
    const [selectedReasons, setSelectedReasons] = React.useState([]);
    const [message, setMessage] = React.useState('');
    const shouldBlockSubmission = !selectedReasons.length || message.length < 100 || isLoadingOrder;
    const showConfirmation = useCallback(() => setModalView('CONFIRM_REPORT_RESPONSE'), [setModalView]);
    const clearSelectedReasons = useCallback(() => setSelectedReasons([]), []);
    if (modalView === null)
        return null;
    return modalView === 'REPORT_RESPONSE' ? (_jsx(SubmitReportForm, { clearSelectedReasons: clearSelectedReasons, message: message, onSubmit: showConfirmation, selectedReasons: selectedReasons, setMessage: setMessage, setSelectedReasons: setSelectedReasons, shouldBlockSubmission: shouldBlockSubmission })) : (_jsx(ConfirmReport, { message: message, order: order, onSuccess: onSuccess, resultPk: resultPk, resultUUID: resultUUID, selectedReasons: selectedReasons, setModalView: setModalView }));
});
export const SubmitReportForm = observer(function SubmitReportForm({ clearSelectedReasons, message, onSubmit, selectedReasons, setMessage, setSelectedReasons, shouldBlockSubmission, }) {
    const selectedReasonsCount = selectedReasons.length;
    const searchForReason = useCallback((value, search) => {
        const label = reasonToLabelMap[value].toLowerCase();
        return label.includes(search.toLowerCase()) ? 1 : 0;
    }, []);
    const handleUpdateMessage = useCallback((e) => {
        setMessage(e.target.value);
    }, [setMessage]);
    return (_jsxs(_Fragment, { children: [_jsx(BtwModal.Header, { title: "Report response" }), _jsxs(BtwModal.Body, { className: "w-full gap-4", children: [_jsx(BtwText, { variant: "primary", size: "13", children: "Why are you reporting this response? Select any that apply." }), _jsxs(Combobox.Root, { onClear: clearSelectedReasons, selected: selectedReasonsCount, total: REASONS.length, variant: "multi-select", children: [_jsx(Combobox.Trigger, { "data-testid": "report-reason-select", hasPlaceholder: !selectedReasonsCount, size: "intermediate", children: selectedReasonsCount
                                    ? selectedReasons
                                        .map((reason) => reasonToLabelMap[reason])
                                        .join(', ')
                                    : 'Select reasons...' }), _jsx(Combobox.Content, { placeholder: "Search for reasons...", filter: searchForReason, onFocusOutside: (e) => e.preventDefault(), children: REASONS.map((reasonId) => (_jsx(Reason, { id: reasonId, selectedReasons: selectedReasons, setSelectedReasons: setSelectedReasons }, reasonId))) })] }), _jsx(BtwInput.Label, { htmlFor: "written-reason", className: "font-normal", children: _jsx(RequiredFieldText, {}) }), _jsx(BtwInput.Root, { children: _jsx(BtwInput.Textarea, { "data-testid": "written-reason", id: "written-reason", maxLength: 500, placeholder: "Please provide as much detail as possible", rows: 4, onChange: handleUpdateMessage, value: message }) }), _jsx(CharacterLimitText, { message: message })] }), _jsxs(BtwModal.Footer, { children: [_jsx(BtwModal.Close, { asChild: true, children: _jsx(BtwButton, { "data-testid": "cancel-button", size: "intermediate", children: "Cancel" }) }), _jsx(BtwButton, { "data-testid": "submit-report-button", disabled: shouldBlockSubmission, onClick: onSubmit, variant: "primary", size: "intermediate", children: "Submit report" })] })] }));
});
const Reason = observer(function Reason({ id, selectedReasons, setSelectedReasons, }) {
    const isSelected = selectedReasons.includes(id);
    const toggleReason = useCallback(() => {
        setSelectedReasons(xor(selectedReasons, [id]));
    }, [id, selectedReasons, setSelectedReasons]);
    return (_jsx(Combobox.Item, { check: "checkbox", "data-testid": `report-reason-${id}`, onSelect: toggleReason, selected: isSelected, value: id, children: reasonToLabelMap[id] }));
});
const toastId = 'report-response';
export const ConfirmReport = observer(function ConfirmReport({ message, order, onSuccess, resultPk, resultUUID, selectedReasons, setModalView, }) {
    const match = useRouteMatch();
    const { id } = match.params;
    // If the response is not replaced, it will be refunded
    const willResponseBeReplaced = checkIfResponseWillBeReplaced(order);
    const [reportResult] = useMutation(REPORT_RESULT, {
        variables: { resultUUID, reasons: selectedReasons, message },
        onCompleted: (data) => {
            var _a;
            const { ok } = data.reportResult;
            if (ok) {
                showSuccessNotification({
                    toastId,
                    content: 'The response has been successfully reported',
                    options: {
                        autoClose: 5000,
                    },
                });
            }
            else {
                showErrorMessage((_a = data.reportResult.error) === null || _a === void 0 ? void 0 : _a.code);
            }
        },
        onError: (error) => {
            // eslint-disable-next-line no-console
            console.error(error.message);
            showErrorMessage(null);
        },
        optimisticResponse: {
            reportResult: {
                ok: true,
                error: null,
                __typename: 'ReportResult',
            },
        },
        update: (cache, { data }) => {
            if (!(data === null || data === void 0 ? void 0 : data.reportResult.ok))
                return;
            if (onSuccess)
                onSuccess(cache);
            removeResultFromCache(cache, {
                pk: Number(id),
            }, [resultPk]);
        },
    });
    const handleConfirmReport = useCallback(() => {
        reportResult();
        setModalView(null);
    }, [reportResult, setModalView]);
    return (_jsxs(Stack, { align: "stretch", children: [_jsxs(Stack, { align: "start", className: "pl-8 pr-4 pt-4", direction: "row", gap: "4", justify: "between", children: [_jsxs(Stack, { gap: "1.5", children: [_jsx(BtwText, { className: "pt-4", size: "xl", variant: "primary", weight: "bold", children: "Report response" }), _jsx(BtwText, { size: "sm", children: "Are you sure you want to report this response?" })] }), _jsx(BtwModal.CloseButton, {})] }), _jsxs(Stack, { className: "px-8 pb-16 pt-6", gap: "6", children: [_jsxs(Stack, { className: "rounded-xl border border-gray-600/10 bg-gray-50 p-5", gap: "3", width: "full", children: [_jsx(BtwText, { size: "13", variant: "primary", children: "Reporting this response will also:" }), ' ', _jsxs(Stack, { gap: "2", children: [_jsx(ConfirmItem, { children: "Delete the response" }), ' ', order && (_jsx(ConfirmItem, { children: willResponseBeReplaced
                                            ? 'Automatically request a new response'
                                            : 'Refund credits' }))] })] }), _jsx(ReconciliationMessage, { order: order })] }), _jsxs(BtwModal.Footer, { children: [_jsx(BtwModal.Close, { asChild: true, children: _jsx(BtwButton, { "data-testid": "cancel-button", size: "intermediate", children: "Cancel" }) }), _jsx(BtwButton, { "data-testid": "report-response-button", onClick: handleConfirmReport, variant: "danger", size: "intermediate", children: "Report and delete response" })] })] }));
});
const ReconciliationMessage = observer(function ReconciliationMessage({ order, }) {
    var _a;
    if (!((_a = order === null || order === void 0 ? void 0 : order.reconciliation) === null || _a === void 0 ? void 0 : _a.reconciledAt))
        return null;
    const { reconciledAt } = order.reconciliation;
    const isReconciliationDateInTheFuture = new Date(reconciledAt) > new Date();
    const reconciliationDateString = new Date(reconciledAt).toLocaleDateString('en-GB', {
        weekday: 'short',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        hourCycle: 'h12',
    });
    return (_jsx(Stack, { "data-testid": "reconciliation-message", children: _jsxs(BtwText, { size: "xs", children: [isReconciliationDateInTheFuture
                    ? `This response is within the review period (until ${reconciliationDateString}), which means we’ll automatically request a replacement once you report it.`
                    : 'This response is no longer within the review period, which means we’ll refund this response once you report it.', ' ', _jsx(BtwLink, { href: REPORTING_RESPONSES, target: "_blank", variant: "current", children: "Learn more" })] }) }));
});
function checkIfResponseWillBeReplaced(order) {
    if (!order)
        return false;
    const { status, reconciliation } = order;
    if (status === StudyStatusEnum.COMPLETED && (reconciliation === null || reconciliation === void 0 ? void 0 : reconciliation.reconciledAt)) {
        const isReconciliationDateInTheFuture = new Date(reconciliation.reconciledAt) > new Date();
        return (status === StudyStatusEnum.COMPLETED && isReconciliationDateInTheFuture);
    }
    if (status === StudyStatusEnum.ACTIVE)
        return true;
    return false;
}
function showErrorMessage(errorCode) {
    LogRocket.track(`reportResponseError-${errorCode}`);
    let errorMessage;
    switch (errorCode) {
        case ReportResultErrorEnum.RESULT_ALREADY_REPORTED:
        case ReportResultErrorEnum.RESULT_NOT_FOUND:
            errorMessage =
                'The result could not be found or has already been reported. Try refreshing the page and try again later';
            break;
        case ReportResultErrorEnum.PARTNER_REQUEST_ERROR:
            errorMessage = (_jsxs(_Fragment, { children: ["There was an issue reporting this response.Please", ' ', _jsx(BtwLink, { href: CONTACT_SUPPORT, target: "_blank", variant: "muted", children: "contact support." })] }));
            break;
        case ReportResultErrorEnum.INVALID_REASON:
        case ReportResultErrorEnum.OTHER_REASON_NOT_PROVIDED:
        case ReportResultErrorEnum.PERMISSION_DENIED:
        default:
            errorMessage = (_jsxs(_Fragment, { children: ["There was an issue reporting the response, please try again later. If the issue persists,", ' ', _jsx(BtwLink, { href: CONTACT_SUPPORT, target: "_blank", variant: "current", children: "contact support." })] }));
            break;
    }
    showErrorNotification({
        toastId: 'reportResultError',
        content: errorMessage,
        options: {
            autoClose: 5000,
        },
    });
}
const ConfirmItem = observer(function ConfirmItem({ children, }) {
    return (_jsxs(Stack, { align: "center", "data-testid": "report-outcome", direction: "row", gap: "2", children: [_jsx(CheckRadioIcon, { class: "size-4 text-gray-900" }), _jsx(BtwText, { size: "13", variant: "primary", children: children })] }));
});
const RequiredFieldText = observer(function RequiredFieldText() {
    return (_jsxs(Stack, { gap: "1.5", width: "full", children: [_jsx(Stack, { asChild: true, align: "center", direction: "row", gap: "1.5", width: "full", children: _jsxs(BtwText, { variant: "primary", size: "13", children: ["Please describe why are you reporting this response", ' ', _jsx(AsteriskIcon, { className: "size-3 text-red-500" })] }) }), _jsx(BtwText, { size: "13", children: "This explanation might be visible to the participant, so please be cordial" })] }));
});
const CharacterLimitText = observer(function CharacterLimitText({ message, }) {
    const characterCount = message.length;
    let variant = 'secondary';
    let copy = `${characterCount} of min. 100 characters`;
    if (characterCount >= 100 && characterCount < 500) {
        variant = 'green';
        copy = `${characterCount} of max. 500 characters`;
    }
    else if (characterCount === 500) {
        variant = 'red';
        copy = '500 max. characters reached';
    }
    return (_jsxs(BtwText, { className: "flex items-center gap-0.5", "data-testid": "character-limit-text", size: "13", variant: variant, weight: "medium", children: [_jsx(FeatherIcon, { className: "size-4" }), copy] }));
});
