import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { BtwLink, CtaMessage, Stack, cn } from '@marvelapp/ballpark-components';
import { CONTACT_SUPPORT } from '@marvelapp/ballpark-help';
import { LoaderIcon, WarningIcon } from '@marvelapp/ballpark-icons';
export function MediaStatus(props) {
    const { message, mediaKind } = props;
    return (_jsx(Stack, { width: "full", justify: "center", align: "center", className: cn(
        // Base
        'bg-gray-200', 'p-5', 'ring-1', 'ring-gray-600/10', 'ring-inset', 'rounded-xl', 'aspect-video'), children: getStatusMessage({ message, mediaKind }) }));
}
function getStatusMessage(props) {
    const { message, mediaKind } = props;
    if (message === 'processing') {
        return (_jsx(Stack, { "data-testid": "uploading-status", children: _jsx(CtaMessage.Root, { size: "sm", children: _jsxs(CtaMessage.Header, { icon: _jsx(LoaderIcon, { className: "animate-spin" }), children: [_jsxs(CtaMessage.Heading, { children: ["We're preparing your ", mediaKind || 'recording'] }), _jsx(CtaMessage.Description, { children: "This may take a little while with longer recordings..." })] }) }) }));
    }
    if (message === 'error') {
        return (_jsx(Stack, { "data-testid": "error-status", children: _jsx(CtaMessage.Root, { size: "sm", children: _jsxs(CtaMessage.Header, { icon: _jsx(WarningIcon, {}), children: [_jsxs(CtaMessage.Heading, { children: ["We were unable to process this ", mediaKind || 'recording'] }), _jsxs(CtaMessage.Description, { children: ["Please", ' ', _jsx(BtwLink, { underline: true, variant: "muted", href: CONTACT_SUPPORT, target: "_blank", children: "contact support" }), ' ', "for more information"] })] }) }) }));
    }
}
