import { __rest } from "tslib";
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Flex, Loader, Text } from '@marvelapp/ui';
export default function LoadingText(_a) {
    var { alignItems, children, color, fontSize, loaderColor } = _a, props = __rest(_a, ["alignItems", "children", "color", "fontSize", "loaderColor"]);
    return (_jsxs(Flex, { alignItems: alignItems, children: [_jsx(Box, { mr: 12, children: _jsx(Loader, Object.assign({ color: loaderColor, size: 24 }, props)) }), _jsx(Flex, { flex: "1", flexDirection: "column", justifyContent: "center", minHeight: "26px", children: _jsx(Text, { color: color, fontSize: fontSize, children: children }) })] }));
}
LoadingText.defaultProps = {
    alignItems: 'flex-start',
    color: 'slate',
    fontSize: 14,
};
