export var GeneralTargetingFilter;
(function (GeneralTargetingFilter) {
    GeneralTargetingFilter["Country"] = "FILTER_COUNTRY";
    GeneralTargetingFilter["Age"] = "FILTER_AGE";
})(GeneralTargetingFilter || (GeneralTargetingFilter = {}));
export var CustomValueAgeOption;
(function (CustomValueAgeOption) {
    CustomValueAgeOption["Min"] = "AGE_MINIMUM_AGE";
    CustomValueAgeOption["Max"] = "AGE_MAXIMUM_AGE";
})(CustomValueAgeOption || (CustomValueAgeOption = {}));
export const VIRTUALIZED_LIST_OPTION_COUNT_THRESHOLD = 2000;
