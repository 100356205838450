import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { isNil } from 'lodash-es';
import { observer } from 'mobx-react';
import { useEffect } from 'react';
import { StepIcon, getIconType } from '@marvelapp/ballpark-components';
import { getEnglishStepName } from '@marvelapp/ballpark-copy';
import styled from '@marvelapp/styled';
import { Box } from '@marvelapp/ui';
import { FixedStep, ScreenerType, ScreenerUtils, getMainScreenerSteps, getStepIndex, getSteps, } from '@marvelapp/user-test-creator';
import { CardContainer } from '../../components/CardContainer';
import { FixedCard } from '../../components/FixedCard';
import SlideRowCard from '../../components/SlideRowCard';
import { ResponseStatus } from '../../graphql/graphqlTypes';
export const SlideCards = observer(function SlideCards({ activeSlideUUID, bounceStepIndex, resultStatus, setActiveSlideUUID, userTest, }) {
    const { welcomeStep, middleSteps, exitStep } = getSteps(userTest);
    const allSteps = [welcomeStep, ...middleSteps, exitStep];
    const skippedSteps = resultStatus === ResponseStatus.Incomplete && !isNil(bounceStepIndex)
        ? allSteps.slice(bounceStepIndex)
        : [];
    useEffect(() => {
        const elementId = `sidebar-${activeSlideUUID}`;
        if (elementId) {
            const element = document.getElementById(elementId);
            // Smooth scrolling doesn't work here
            element === null || element === void 0 ? void 0 : element.scrollIntoView();
        }
    }, [activeSlideUUID]);
    return (_jsxs(Slides, { children: [_jsx(ScreenerSteps, { activeSlideUUID: activeSlideUUID, setActiveSlideUUID: setActiveSlideUUID, skippedSteps: skippedSteps, userTest: userTest }), _jsx(CardContainer, { userTest: userTest, uuid: welcomeStep.uuid, children: _jsx(FixedCard, { activeSlideUUID: activeSlideUUID, fixedStepType: FixedStep.Welcome, getCardTypeIcon: getCardTypeIcon, getStepText: getStepText, setActiveSlideUUID: setActiveSlideUUID, stepUUID: welcomeStep.uuid, testId: `sidebar-step-${FixedStep.Welcome}-0`, userTest: userTest }) }), middleSteps.map((step, index) => (_jsx(CardContainer, { userTest: userTest, uuid: step.uuid, children: _jsx(SlideRowCard, { active: activeSlideUUID === step.uuid, getCardTypeIcon: getCardTypeIcon, getStepText: getStepText, id: `sidebar-${step.uuid}`, setActiveSlideUUID: setActiveSlideUUID, skipped: skippedSteps.some((skippedStep) => skippedStep.uuid === step.uuid), testId: `sidebar-${step.className}-${index + 1}`, userTest: userTest }, step.uuid) }, step.uuid))), _jsx(FixedCard, { activeSlideUUID: activeSlideUUID, fixedStepType: FixedStep.Exit, getCardTypeIcon: getCardTypeIcon, getStepText: getStepText, setActiveSlideUUID: setActiveSlideUUID, stepUUID: exitStep.uuid, testId: `sidebar-step-${FixedStep.Exit}-0`, userTest: userTest })] }));
});
const Slides = styled(Box).attrs({
    padding: 's',
}) ``;
const getCardTypeIcon = (step, fixedStepType) => (_jsx(StepIcon, { size: "xs", type: getIconType(step, fixedStepType) }));
const ScreenerSteps = observer(function ScreenerSteps({ activeSlideUUID, setActiveSlideUUID, skippedSteps, userTest, }) {
    if (!userTest.screeners)
        return null;
    const screenerSteps = getMainScreenerSteps(userTest);
    return (_jsx(_Fragment, { children: screenerSteps.map((step, index) => (_jsx(CardContainer, { userTest: userTest, uuid: step.uuid, children: _jsx(SlideRowCard, { active: activeSlideUUID === step.uuid, getCardTypeIcon: () => (_jsx(StepIcon, { size: "xs", type: ScreenerType.Screener })), getStepText: getStepText, id: `sidebar-${step.uuid}`, setActiveSlideUUID: setActiveSlideUUID, skipped: skippedSteps.some((skippedStep) => skippedStep.uuid === step.uuid), testId: `sidebar-${step.className}-${index + 1}`, userTest: userTest }, step.uuid) }, step.uuid))) }));
});
const getStepText = (userTest, step, fixedStepType) => {
    if (ScreenerUtils.isScreenerStep(userTest, step.uuid)) {
        return ScreenerUtils.getStepName(userTest, step.uuid);
    }
    return `${getStepIndex(userTest, step.uuid) + 1}:${` `}${getEnglishStepName(step, fixedStepType)}`;
};
