import { StepClass } from '../common';
import { generateUUID } from '../utils/generateUUID';
import { STEP_PREFIXES } from './constants';
import { QuestionStepType, } from './types';
// Couldn't figure out how to type a generic `create` function here so we've
// split out the four specific types of question steps into their own functions
export function createText(partial = {}) {
    return Object.assign({ uuid: generateUUID(STEP_PREFIXES[StepClass.Question]), className: StepClass.Question, title: null, description: [], isRequired: false, revision: 0, type: QuestionStepType.Text, conditions: { ALWAYS: null, rules: [] } }, partial);
}
export function createEmail(partial = {}) {
    return Object.assign({ uuid: generateUUID(STEP_PREFIXES[StepClass.Question]), className: StepClass.Question, title: null, description: [], isRequired: false, revision: 0, type: QuestionStepType.Email, conditions: { ALWAYS: null, rules: [] } }, partial);
}
export function createDate(partial = {}) {
    return Object.assign({ uuid: generateUUID(STEP_PREFIXES[StepClass.Question]), className: StepClass.Question, title: null, description: [], isRequired: false, revision: 0, type: QuestionStepType.QuestionDate, conditions: { ALWAYS: null, rules: [] } }, partial);
}
export function createNumerical(partial = {}) {
    return Object.assign({ uuid: generateUUID(STEP_PREFIXES[StepClass.Question]), className: StepClass.Question, title: null, description: [], isRequired: false, revision: 0, type: QuestionStepType.Numerical, conditions: { ALWAYS: null, rules: [] } }, partial);
}
export function getDateFormat(step) {
    return step.format;
}
