import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { observer } from 'mobx-react';
import { useAction } from '@marvelapp/ballpark-application';
import { BtwCheckbox, BtwLabel, BtwMessage, BtwText, Stack, baseButtonStyles, cn, ghostButtonStyles, } from '@marvelapp/ballpark-components';
import { InfoCircleIcon } from '@marvelapp/ballpark-icons';
import { useRecruitmentState } from '../RecruitmentContext';
import { getSelectionCopy } from '../common';
import { ListWrapper } from './ListWrapper';
export const FilterOptionsColumn = observer(function FilterOptionsColumn() {
    const { currentlyOpenDetailedFilter, currentlyOpenDetailedFilterGroup, getFilterByGroupAndId, } = useRecruitmentState();
    if (!currentlyOpenDetailedFilter)
        return null;
    const filter = getFilterByGroupAndId(currentlyOpenDetailedFilterGroup, currentlyOpenDetailedFilter);
    return (_jsxs(Stack, { className: cn(
        // Base styles
        'max-h-full flex-1 items-stretch bg-white', 
        // Responsive & interactive styles
        'peer-hover:z-10 peer-hover:translate-x-1/4  sm:peer-hover:translate-x-0'), "data-testid": "filter-options", direction: "col", children: [_jsx(Stack, { align: "center", className: "min-h-10 border-b border-gray-600/10 pl-5 pr-3", direction: "row", width: "full", justify: "between", children: _jsxs(Stack, { direction: "row", gap: "3", align: "center", children: [_jsx(ClearSelectionButton, {}), _jsx(SelectionCopy, {})] }) }), _jsxs(ListWrapper, { "data-testid": "filter-options-column", children: [filter.description && (_jsx(BtwMessage, { className: "w-full gap-2.5 p-2.5", leadingIcon: _jsx(InfoCircleIcon, { className: cn('!size-5 text-gray-600') }), children: _jsxs(Stack, { gap: "0.5", children: [_jsx(BtwText, { size: "xs", variant: "secondary", weight: "semibold", children: "Participants were asked:" }), _jsx(BtwText, { size: "xs", variant: "secondary", children: filter.description })] }) })), filter.options.map((option) => {
                        if (!option)
                            return null;
                        return _jsx(OptionElement, { option: option }, option.id);
                    })] })] }));
});
const SelectionCopy = observer(function SelectionCopy() {
    const { currentlyOpenDetailedFilter, currentlyOpenDetailedFilterGroup, getSelectedOptionsByFilterAndGroupId, getFilterByGroupAndId, } = useRecruitmentState();
    const filter = getFilterByGroupAndId(currentlyOpenDetailedFilterGroup, currentlyOpenDetailedFilter);
    if (!filter.options.length)
        return null;
    const selectedOptionsCount = getSelectedOptionsByFilterAndGroupId(currentlyOpenDetailedFilter, currentlyOpenDetailedFilterGroup).length;
    const selectionCopy = getSelectionCopy(filter.minSelection, filter.maxSelection);
    return (_jsxs(BtwText, { "data-testid": "selected-options", size: "13", children: [selectedOptionsCount, " of ", filter.options.length, " selected", ' ', selectionCopy && `(${selectionCopy})`] }));
});
const ClearSelectionButton = observer(function ClearSelectionButton() {
    const { clearAllOptionsOfCurrentlyOpenDetailedFilter, currentlyOpenDetailedFilter, currentlyOpenDetailedFilterGroup, getSelectedOptionsByFilterAndGroupId, } = useRecruitmentState();
    const selectedOptionsCount = getSelectedOptionsByFilterAndGroupId(currentlyOpenDetailedFilter, currentlyOpenDetailedFilterGroup).length;
    const hasSelections = !!selectedOptionsCount;
    return (_jsx("div", { className: "relative", children: _jsx(BtwCheckbox, { "data-testid": "clear-selection-cta-detailed-targeting", size: "sm", onCheckedChange: clearAllOptionsOfCurrentlyOpenDetailedFilter, checked: hasSelections && 'indeterminate', disabled: !hasSelections }) }));
});
const OptionElement = observer(function OptionElement({ option, }) {
    const { currentlyOpenDetailedFilter, currentlyOpenDetailedFilterGroup, getFilterByGroupAndId, getSelectedOptionsByFilterAndGroupId, updateMultiSelectFilterSelection, } = useRecruitmentState();
    const selectedOptions = getSelectedOptionsByFilterAndGroupId(currentlyOpenDetailedFilter, currentlyOpenDetailedFilterGroup);
    const filter = getFilterByGroupAndId(currentlyOpenDetailedFilterGroup, currentlyOpenDetailedFilter);
    const toggleOption = useAction(() => {
        updateMultiSelectFilterSelection({ name: filter.name, id: filter.id, maxSelection: filter.maxSelection }, Object.assign(Object.assign({}, option), { filterId: filter.id, groupId: currentlyOpenDetailedFilterGroup, customValue: null }));
    }, [
        currentlyOpenDetailedFilterGroup,
        filter,
        option,
        updateMultiSelectFilterSelection,
    ]);
    const isSelected = selectedOptions.some((selectedOption) => selectedOption.id === option.id);
    const isDisabled = !!filter.maxSelection &&
        !isSelected &&
        selectedOptions.length >= filter.maxSelection;
    return (_jsx(Stack, { width: "full", title: option.name, children: _jsx(Stack, { asChild: true, className: cn(
            // Importing base button styles unless the options are disabled
            !isDisabled && Object.assign(Object.assign({}, baseButtonStyles), ghostButtonStyles), 
            // Some necessary custom overrides
            'relative min-h-9 min-w-0 rounded-md px-3 py-2 font-normal hover:bg-gray-200/80 hover:ring-0', 
            // Removing hover background if disabled
            {
                'hover:bg-transparent': isDisabled,
            }), "data-testid": "filter-option", direction: "row", gap: "3", width: "full", align: "center", children: _jsxs(BtwLabel, { className: cn({
                    'cursor-pointer': !isDisabled,
                    'cursor-not-allowed': isDisabled,
                }, 
                // Temporarily adding a class selector for the checkbox, because it doesn't
                // currently support classes (it breaks; needs to be fixed separately)
                '*:shrink-0', 'min-w-0 flex-1'), disabled: isDisabled, htmlFor: option.id, size: "13", weight: "normal", children: [_jsx(BtwCheckbox, { id: option.id, checked: isSelected, disabled: isDisabled, onCheckedChange: toggleOption, size: "sm" }), _jsx("span", { className: "line-clamp-1 w-full flex-1 text-left", children: option.name })] }) }) }));
});
