import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { gql, useQuery } from '@apollo/client';
import { observer } from 'mobx-react';
import { useState } from 'react';
import { InfoBlock, Link } from '@marvelapp/ballpark-components';
import { OPTIMISING_PROTOTYPES_FOR_TESTING } from '@marvelapp/ballpark-help';
import { getPrototypeShareId } from '@marvelapp/core';
import styled from '@marvelapp/styled';
import { Box } from '@marvelapp/ui';
import { TriggerTypeEnum, } from '../../../__generated__/queryTypes';
import { ScreenPickerWithModal } from '../../../slides/PrototypeTask/PrototypeTaskEdit/ScreenPicker/ScreenPicker';
import HeatmapPlayer from './HeatmapPlayer';
import { useEventQueryDataToHeatmapData } from './utils';
const GET_USER_TEST_SCREEN_TRIGGERS = gql `
  query getTriggers(
    $shareId: String!
    $eventsBefore: String
    $eventsAfter: String
    $maxEvents: Int!
    $filters: [TriggerTypeEnum]
    $eventsResultsPk: [Int!]
    $stepUuid: String!
    $skipEvents: Boolean!
  ) {
    project(shareId: $shareId) {
      pk
      screens(first: 1, after: $eventsAfter, before: $eventsBefore)
        @connection(key: "screens") {
        __typename
        pageInfo {
          startCursor
          endCursor
          hasNextPage
          hasPreviousPage
        }
        edges {
          cursor
          node {
            pk
            displayName
            content {
              __typename
              ... on ImageScreen {
                url
                height
                width
              }
            }
            resultStats(resultsPk: $eventsResultsPk) {
              edges {
                node {
                  resultPk
                  totalClicks
                  misclickRate
                  dwellTime
                  averageTimeToClick
                }
              }
            }
            events(
              first: $maxEvents
              triggerTypes: $filters
              resultsPk: $eventsResultsPk
              stepUuid: $stepUuid
              fetchByExternalId: true
            ) @skip(if: $skipEvents) {
              edges {
                node {
                  __typename
                  trigger {
                    coordX
                    coordY
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
const HeatmapContainer = observer(function HeatmapContainer({ prototypeUrl, resultPk, isExternalPrototype, stepUUID, isPickingScreen, closeScreenPicker, openScreenPicker, }) {
    var _a, _b, _c, _d, _e;
    const [cursor, setCursor] = useState({
        before: undefined,
        after: undefined,
    });
    const [selectionMap] = useState(resultPk ? { [resultPk]: true } : {});
    const resultPks = Object.entries(selectionMap)
        .filter(([, isSelected]) => isSelected)
        .map(([pk]) => Number(pk));
    const { data, loading: triggersLoading } = useQuery(GET_USER_TEST_SCREEN_TRIGGERS, {
        variables: {
            shareId: getPrototypeShareId(prototypeUrl),
            eventsResultsPk: resultPks,
            eventsAfter: cursor.after,
            maxEvents: 600, // TODO won't this skew the results? We need _all_ events surely??
            filters: [TriggerTypeEnum.CLICK_OR_TAP],
            eventsBefore: cursor.before,
            skipEvents: !resultPks.length,
            stepUuid: stepUUID,
        },
    });
    // 🧠 Data Screens & Events
    const prototypePk = (_a = data === null || data === void 0 ? void 0 : data.project) === null || _a === void 0 ? void 0 : _a.pk;
    const pageInfo = (_c = (_b = data === null || data === void 0 ? void 0 : data.project) === null || _b === void 0 ? void 0 : _b.screens) === null || _c === void 0 ? void 0 : _c.pageInfo;
    const hasNextPage = (_d = pageInfo === null || pageInfo === void 0 ? void 0 : pageInfo.hasNextPage) !== null && _d !== void 0 ? _d : false;
    const hasPreviousPage = (_e = pageInfo === null || pageInfo === void 0 ? void 0 : pageInfo.hasPreviousPage) !== null && _e !== void 0 ? _e : false;
    const { imageUrl, originalWidth, originalHeight, screenDisplayName, coordinates, } = useEventQueryDataToHeatmapData(data);
    const setNextScreenCursor = () => {
        var _a;
        setCursor({ before: undefined, after: (_a = pageInfo === null || pageInfo === void 0 ? void 0 : pageInfo.endCursor) !== null && _a !== void 0 ? _a : undefined });
    };
    const setPreviousScreenCursor = () => {
        var _a;
        setCursor({ before: (_a = pageInfo === null || pageInfo === void 0 ? void 0 : pageInfo.startCursor) !== null && _a !== void 0 ? _a : undefined, after: undefined });
    };
    const selectAfterCursor = (c) => {
        setCursor({ before: undefined, after: c });
        closeScreenPicker();
    };
    const onSelectScreen = (screenPk, project) => {
        var _a;
        if (!project) {
            return;
        }
        const screens = ((_a = project === null || project === void 0 ? void 0 : project.screens) === null || _a === void 0 ? void 0 : _a.edges) || [];
        const selectedIndex = screens.findIndex((s) => { var _a; return ((_a = s === null || s === void 0 ? void 0 : s.node) === null || _a === void 0 ? void 0 : _a.pk) === screenPk; });
        if (selectedIndex === -1) {
            return;
        }
        if (selectedIndex === 0) {
            selectAfterCursor(undefined);
        }
        const screenToSelectAfter = screens[selectedIndex - 1];
        if (screenToSelectAfter) {
            selectAfterCursor(screenToSelectAfter === null || screenToSelectAfter === void 0 ? void 0 : screenToSelectAfter.cursor);
        }
    };
    return (_jsxs(_Fragment, { children: [isExternalPrototype && (_jsx(FigmaMessageContainer, { children: _jsx(InfoBlock, { fontSize: "s", hasIcon: false, kind: "amber", maxWidth: 650, children: _jsxs(Box, { children: ["Note: If your Figma file contains screens that are not in your prototype, those will also be imported to Ballpark and show up in your heatmap.", ' ', _jsx(Link, { display: "inline", to: OPTIMISING_PROTOTYPES_FOR_TESTING, target: "_blank", children: "Read our guide on optimising your Figma file." })] }) }) })), _jsx(ScreenPickerWithModal, { isOpen: isPickingScreen, onSelectScreen: onSelectScreen, otherScreen: prototypePk !== null && prototypePk !== void 0 ? prototypePk : null, isExternalPrototype: isExternalPrototype, prototypeUrl: prototypeUrl, onOpenChange: closeScreenPicker }), _jsx(Box, { padding: "xl", mt: isExternalPrototype ? 'xl' : 0, children: _jsx(Box, { mx: "auto", maxWidth: 1080, children: _jsx(HeatmapPlayer, { coordinates: coordinates, hasNextPage: hasNextPage, hasPreviousPage: hasPreviousPage, imageUrl: imageUrl !== null && imageUrl !== void 0 ? imageUrl : undefined, isLoading: triggersLoading, openScreenPicker: openScreenPicker, screensSelected: !!resultPks.length, screenDisplayName: screenDisplayName, screenHeight: originalHeight, screenWidth: originalWidth, setNextScreenCursor: setNextScreenCursor, setPreviousScreenCursor: setPreviousScreenCursor }) }) })] }));
});
export default HeatmapContainer;
const FigmaMessageContainer = styled(Box) `
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0);
  max-width: 650px;
  width: 100%;
`;
