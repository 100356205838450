import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { observer } from 'mobx-react';
import * as React from 'react';
import { Icon, Label } from '@marvelapp/ballpark-components';
import { PLUS_ICON } from '@marvelapp/ballpark-theme';
import styled from '@marvelapp/styled';
import { Box, Loader } from '@marvelapp/ui';
import DottedBox from './DottedBox';
import { UppyStyle } from './UppyStyle';
import { useUppy } from './useUppy';
export default observer(function AddImageCTA({ onImageUploaded, aspectRatio, heading = 'Click to add image or drop one in', isDisabled, onClick, subheading, testId, decoration, customiseUppy, }) {
    const { dndRef, isLoading, draggedOver } = useUppy({
        onImageUploaded,
        customiseUppy,
    });
    return (_jsxs(_Fragment, { children: [_jsx(UppyStyle, {}), _jsxs(DottedBox, { aspectRatio: aspectRatio, isActive: draggedOver, isDisabled: isDisabled, onClick: onClick, ref: dndRef, testId: testId, children: [isLoading ? (_jsx(Loader, {})) : (decoration || (_jsx(PlusIconWrapper, { isActive: draggedOver, children: _jsx(Icon, { paths: PLUS_ICON }) }))), !isLoading && (_jsxs(_Fragment, { children: [heading && (_jsx(Label, { kind: draggedOver ? 'blue12' : 'primary', marginTop: "s", children: heading })), subheading && (_jsx(Label, { fontWeight: "regular", kind: draggedOver ? 'blue12' : 'secondary', lineHeight: "sub", marginTop: "xs", size: "s", children: subheading }))] }))] })] }));
});
const PlusIconWrapper = styled(Box).attrs((props) => ({
    bg: props.isActive ? 'blue9' : 'slate7',
    color: 'background',
    borderRadius: 'circle',
    marginBottom: 'xs',
})) `
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
