export function capitalizeFirstLetter(s) {
    return s.charAt(0).toUpperCase() + s.slice(1);
}
export default function formatUsername(name) {
    if (!name)
        return '';
    if (name.indexOf(' ') > -1) {
        const names = name.split(' ');
        const firstName = names[0];
        const middleOrLastNameInitial = names[1].charAt(0);
        return `${firstName} ${middleOrLastNameInitial}`;
    }
    if (name.indexOf('@') > -1) {
        const firstPartOfEmail = name.split('@')[0];
        if (firstPartOfEmail.indexOf('.') > -1) {
            return capitalizeFirstLetter(firstPartOfEmail.split('.')[0]);
        }
        if (firstPartOfEmail.indexOf('_') > -1) {
            return capitalizeFirstLetter(firstPartOfEmail.split('_')[0]);
        }
        return capitalizeFirstLetter(firstPartOfEmail);
    }
    return name;
}
