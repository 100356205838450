import { __awaiter } from "tslib";
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { AnimatePresence, motion } from 'framer-motion';
import { observer } from 'mobx-react';
import { useCallback, useEffect, useRef, useState } from 'react';
import * as React from 'react';
import { useFeatureFlag } from '@marvelapp/ballpark-application';
import { Icon } from '@marvelapp/ballpark-components';
import { NO_VIDEO_ICON } from '@marvelapp/ballpark-theme';
import { deviceDetection } from '@marvelapp/core';
import styled, { css } from '@marvelapp/styled';
import { Box, PinCenter, boxStyles } from '@marvelapp/ui';
import { StepUtils } from '@marvelapp/user-test-creator';
import { usePrototypeState } from '../../../components/Record/PrototypeContext';
import VideoGuideHolder from '../../../components/VideoGuideHolder';
import { useGetVideoGuideUrl } from '../RecordVideo/useGetVideoGuideUrl';
import { PlayButton } from './Buttons';
import { subtitlesAnimation } from './Motion';
import Subtitles from './Subtitles';
import { MediaType, getMediaType } from './utils';
export default observer(function SlideInstructions({ step, }) {
    const { hasReachedGoalScreenOrDoneFirstClick } = usePrototypeState();
    const mediaSource = StepUtils.getReadyInstruction(step);
    const videoGuideUrl = useGetVideoGuideUrl(step);
    const [isOpen, setIsOpen] = useState(false);
    const [showSubtitles, setShowSubtitles] = useState(false);
    const [isPlaying, setIsPlaying] = useState(false);
    const [showControl, setShowControl] = useState(false);
    const mediaRef = useRef(null);
    const hasStaticUi = useFeatureFlag('marvel-3-static-ui');
    useEffect(() => {
        const media = mediaRef.current;
        if (!media)
            return;
        media.addEventListener('play', () => {
            setIsPlaying(true);
        }, false);
        media.addEventListener('pause', () => {
            setIsPlaying(false);
        }, false);
    }, []);
    useEffect(() => {
        const videoElement = mediaRef.current;
        if (!videoElement || !deviceDetection.isMobile)
            return;
        const onEndFullScreen = () => {
            setIsOpen(false);
            setShowSubtitles(false);
        };
        videoElement.addEventListener('webkitendfullscreen', () => onEndFullScreen);
        return () => videoElement.removeEventListener('webkitendfullscreen', onEndFullScreen);
    }, []);
    const onPlay = useCallback(() => __awaiter(this, void 0, void 0, function* () {
        const media = mediaRef.current;
        if (!media)
            return;
        setIsOpen(true);
        if (media.paused || media.ended) {
            yield media.play();
            setIsPlaying(true);
            setShowControl(true);
        }
    }), []);
    const onPause = useCallback(() => {
        const media = mediaRef.current;
        if (!media)
            return;
        if (!media.paused && !media.ended) {
            media.pause();
        }
    }, []);
    useEffect(() => {
        if (isOpen)
            onPlay();
    }, [isOpen, onPlay]);
    if (hasReachedGoalScreenOrDoneFirstClick || !mediaSource)
        return null;
    const mediaType = getMediaType(mediaSource);
    if (mediaType === MediaType.None)
        return null;
    if (!videoGuideUrl)
        return null;
    const isVideo = mediaType === MediaType.Video;
    const mediaUrl = `${videoGuideUrl}#t=1`;
    return (_jsx(_Fragment, { children: _jsx(Container, { "data-testid": "video-guide-record", isOpen: isOpen, children: _jsxs(VideoGuideHolder, { children: [isVideo ? (_jsx(Video, { playsinline: true, preload: "metadata", ref: mediaRef, controls: showControl, disablePictureInPicture: true, children: _jsx("source", { src: mediaUrl, type: mediaSource.mimeType }, mediaUrl) })) : (_jsxs(_Fragment, { children: [_jsx("audio", { preload: "metadata", ref: mediaRef, children: _jsx("source", { src: mediaSource.url, type: mediaSource.mimeType }, mediaSource.url) }), _jsx(PinCenter, { color: "slate8", children: _jsx(Icon, { paths: NO_VIDEO_ICON, width: 128, height: 128, viewBox: "0 0 30 30" }) })] })), !showControl && (_jsx(PlayButtonHolder, { children: _jsx(PlayButton, { isOpen: isOpen, isPlaying: isPlaying, isVideo: isVideo, onPause: onPause, onPlay: onPlay }) })), hasStaticUi && (_jsx(AnimatePresence, { children: isOpen && showSubtitles && (_jsx(SubtitlesHolder, Object.assign({}, subtitlesAnimation, { children: _jsx(Subtitles, { isVideo: isVideo, children: "When testing, make sure you reach the goal it\u2019s most important" }) }), "subtitles")) }))] }) }) }));
});
const PlayButtonHolder = styled(Box).attrs({
    transition: 'smooth.medium',
}) `
  top: 50%;
  left: 50%;
  position: absolute;
  transform: translate(-50%, -50%);
`;
const Container = styled(motion.div).attrs({
    borderRadius: ['s', 's', 'm', 'l'],
}) `
  ${boxStyles};
  overflow: hidden;
  position: relative;
  width: 100%;
  width: 100%;
  margin-top: 10px;
  margin-bottom: 12px;
  z-index: ${(props) => props.theme.zIndices.record.controls};

  ${(props) => props.isOpen &&
    css `
      ${PlayButtonHolder} {
        opacity: 0;
        visibility: hidden;
      }
      &:hover ${PlayButtonHolder} {
        opacity: 1;
        visibility: visible;
      }
    `}
`;
const Video = styled(Box).attrs({
    as: 'video',
    preload: 'metadata',
    borderRadius: ['s', 's', 'm', 'l'],
}) `
  width: 100%;
  display: block;
  object-fit: cover;
`;
const SubtitlesHolder = styled(motion.div) `
  position: absolute;
  width: 100%;
  left: 0;
  bottom: 0;
  padding: 6px;
`;
