import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { observer } from 'mobx-react';
import { useCallback, useState } from 'react';
import * as React from 'react';
import { useAction } from '@marvelapp/ballpark-application';
import { BtwButton, BtwModal } from '@marvelapp/ballpark-components';
import { OrderConfirmationDialog, } from './OrderConfirmationDialog';
import { useRecruitmentState } from './RecruitmentContext';
export const ProceedToCheckout = observer(function ProceedToCheckout({ disabled, }) {
    const [dialogState, setDialogState] = React.useState('DRAFT');
    const canCloseModal = !['PUBLISHING', 'SUCCESS'].includes(dialogState);
    const recruitmentState = useRecruitmentState();
    const { currentBalance, isFilterSelected, requiredFilters, selectedFilters, savedUnsupportedFilters, } = recruitmentState;
    const [isViewingOrderConfirmation, setIsViewingOrderConfirmation] = useState(false);
    const checkIfAllRequiredFiltersSelected = useCallback(() => {
        return Object.keys(requiredFilters).every((filterId) => {
            if (!isFilterSelected(filterId))
                return false;
            const { maxSelection, minSelection } = requiredFilters[filterId];
            const unsupportedFilter = savedUnsupportedFilters[filterId];
            const selectedOptions = selectedFilters[filterId].options.filter((option) => {
                if (!unsupportedFilter)
                    return true;
                return !unsupportedFilter.options.some((unsupportedOption) => unsupportedOption.id === option.id);
            });
            const selectedOptionsCount = selectedOptions.length;
            return (selectedOptionsCount >= Number(minSelection) &&
                selectedOptionsCount <= Number(maxSelection));
        });
    }, [
        isFilterSelected,
        requiredFilters,
        savedUnsupportedFilters,
        selectedFilters,
    ]);
    const handleOpenOrderConfirmation = useAction((isOpeningOrderConfirmation) => {
        if (isOpeningOrderConfirmation && !checkIfAllRequiredFiltersSelected()) {
            recruitmentState.hasValidationErrors = true;
        }
        else {
            setIsViewingOrderConfirmation(isOpeningOrderConfirmation);
            recruitmentState.hasValidationErrors = false;
        }
    }, [checkIfAllRequiredFiltersSelected, recruitmentState]);
    const closeModal = useCallback(() => {
        setIsViewingOrderConfirmation(false);
        // The dialog state needs to be reset to 'DRAFT' any time it closes,
        // so that we make sure it always shows the correct state when it opens again.
        setDialogState('DRAFT');
    }, []);
    const preventInteractOutside = useCallback((e) => {
        if (canCloseModal) {
            closeModal();
        }
        else {
            e.preventDefault();
        }
    }, [canCloseModal, closeModal]);
    const preventEscapeKeyDown = useCallback((e) => {
        if (!canCloseModal) {
            e.preventDefault();
        }
    }, [canCloseModal]);
    return (_jsxs(BtwModal.Root, { open: isViewingOrderConfirmation, onOpenChange: handleOpenOrderConfirmation, children: [_jsx(BtwModal.Trigger, { asChild: true, children: _jsx(BtwButton, { "data-testid": "proceed-to-checkout-button", disabled: !currentBalance || disabled, type: "submit", variant: "primary", width: "full", size: "intermediate", children: "Proceed to checkout" }) }), _jsx(BtwModal.Content, { contentClassName: "max-h-[800px] sm:max-h-[640px] min-h-[500px] w-full max-w-5xl flex-row rounded-xl", "data-testid": "order-confirmation-dialog", onInteractOutside: preventInteractOutside, onEscapeKeyDown: preventEscapeKeyDown, overlayClassName: "p-10", children: _jsx(OrderConfirmationDialog, { closeModal: closeModal, dialogState: dialogState, setDialogState: setDialogState }) })] }));
});
