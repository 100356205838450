import { themeGet } from '@styled-system/theme-get';
import styled from '@marvelapp/styled';
import { Box } from '@marvelapp/ui';
export const TagsInputWrapper = styled(Box) `
  border-width: 0;
  border-radius: 4px;
  padding: 16px 16px 40px;
  transition: 150ms ease-in-out;

  .react-tagsinput {
    padding-bottom: 20px;
  }

  .react-tagsinput-input input {
    border: 0;
    outline: 0;
    font-size: 14px;
    padding-right: 8px;
    min-height: 24px;
    &:disabled {
      background: ${themeGet('colors.snow')};
    }
  }

  .react-tagsinput-tag {
    position: relative;
    display: inline-block;
    margin-right: 8px;
    margin-bottom: 8px;
    padding: 5px 8px 4px;

    border-radius: 3px;
    border: 1px solid ${themeGet('colors.greenDark')};
    background: ${themeGet('colors.green')};

    color: ${themeGet('colors.white')};
    font-size: 13px;
    font-weight: 500;
    word-break: break-word;

    &:hover {
      .react-tagsinput-remove {
        display: block;
      }
    }
  }

  .react-tagsinput-remove {
    position: absolute;
    top: -8px;
    right: -8px;
    width: 16px;
    height: 16px;
    display: none;
    background: white;
    border-radius: 50%;
    box-shadow: ${themeGet('shadows.popover.default')};

    &:before,
    &:after {
      top: 50%;
      left: 50%;
      content: '';
      position: absolute;

      width: 2px;
      height: 60%;
      border-radius: 1px;
      background-color: ${themeGet('colors.slate')};
    }

    &:before {
      transform: translate(-50%, -50%) rotate(45deg);
    }

    &:after {
      transform: translate(-50%, -50%) rotate(-45deg);
    }
  }
`;
