export function getPageError(queryResult, errorMessage) {
    var _a, _b, _c, _d, _e, _f;
    if ((!queryResult.loading && ((_b = (_a = queryResult.data) === null || _a === void 0 ? void 0 : _a.userTest) === null || _b === void 0 ? void 0 : _b.prototype) === null) ||
        (errorMessage && ((_c = queryResult.error) === null || _c === void 0 ? void 0 : _c.message.includes(errorMessage)))) {
        return 'doesNotExist';
    }
    if ((_f = (_e = (_d = queryResult.data) === null || _d === void 0 ? void 0 : _d.userTest) === null || _e === void 0 ? void 0 : _e.prototype) === null || _f === void 0 ? void 0 : _f.isArchived) {
        return 'isArchived';
    }
    return null;
}
export function getPageErrorCopy(pageError) {
    if (pageError === 'isArchived') {
        return {
            heading: 'This project has been archived',
            subheading: 'Please contact the project owner or account admin to get access to this project.',
            testId: 'project-archived-title',
        };
    }
    if (pageError === 'doesNotExist') {
        return {
            heading: 'Project not found',
            subheading: "We can't find the project you're looking for. Please contact the project owner or account admin for more info.",
            testId: 'project-not-found-title',
        };
    }
    return null;
}
