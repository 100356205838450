import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { gql, useMutation } from '@apollo/client';
import { UIPlugin } from '@uppy/core';
import { observer } from 'mobx-react';
import { useCallback, useMemo, useState } from 'react';
import * as React from 'react';
import { useAction, useAuth, useGetUserTest, useSlideUIContext, } from '@marvelapp/ballpark-application';
import { showGenericServerError } from '@marvelapp/ui-internal';
import { PrototypeTaskUtils, StepClass, StepUtils, } from '@marvelapp/user-test-creator';
import HalfSlide, { LeftScrollableHalfSlide } from '../common/HalfSlide';
import ImageUpload from '../common/ImageUpload';
import SlideContent from '../common/SlideContent';
import TwoColumnSlide from '../common/TwoColumnSlide';
export default observer(function FirstClickEdit({ onMainCTAClick, }) {
    const userTest = useGetUserTest();
    const { step } = useSlideUIContext();
    const { Plugin: UppyPlugin, hasError } = useFirstClickUppyPlugin(userTest.uuid, step);
    const onDelete = useAction(() => {
        if (!StepUtils.isOfType(step, StepClass.PrototypeTask))
            return;
        PrototypeTaskUtils.removeImage(step);
    }, [step]);
    const customiseUppy = useCallback((uppy) => {
        uppy.use(UppyPlugin);
    }, [UppyPlugin]);
    const onImageUploaded = useAction((url) => {
        if (hasError)
            return;
        if (!StepUtils.isOfType(step, StepClass.PrototypeTask))
            return;
        PrototypeTaskUtils.setImageUrl(step, url);
    }, [hasError, step]);
    if (!StepUtils.isOfType(step, StepClass.PrototypeTask))
        return null;
    return (_jsxs(TwoColumnSlide, { children: [_jsx(LeftScrollableHalfSlide, { children: _jsx(SlideContent, { ctaOnClick: onMainCTAClick, ctaText: "Reveal image" }) }), _jsx(HalfSlide, { flex: 1, children: _jsx(ImageUpload, { onImageUploaded: onImageUploaded, customiseUppy: customiseUppy, onDelete: onDelete, url: hasError ? undefined : step.imageUrl, testId: 'first-click-image-upload' }) })] }));
});
const CREATE_FIRST_CLICK_PROTOTYPE = gql `
  mutation CreateFirstClickPrototype(
    $input: CreatePrototypeForFirstClickStepInput!
  ) {
    createPrototypeForFirstClickStep(input: $input) {
      ok
      uploadUrl
      project {
        pk
        prototypeUrl
        screens(first: 1) {
          edges {
            node {
              pk
            }
          }
        }
      }
      error {
        code
        message
      }
    }
  }
`;
/** The first click test uses the prototype step under the hood.
 * When the user uploads an image we create a prototype from it so
 * we can capture the click event and use all of the event processing
 * that's already setup for prototypes to generate click heatmaps.
 *
 * We use the common AddImageCTA component to handle the upload, which
 * uses the Uppy library internally. The default functionality is to
 * upload the image to our CDN. This plugin customises Uppy to
 * create a prototype and then upload the image using the prototype
 * ingest endpoint.
 *
 * (We're following this example https://uppy.io/docs/writing-plugins/#Example-of-a-custom-plugin
 * as the docs say the various hooks uppy provides are not suitable for async actions )
 */
function useFirstClickUppyPlugin(testUuid, step) {
    const [createPrototype] = useMutation(CREATE_FIRST_CLICK_PROTOTYPE);
    const accessToken = useAuth();
    const [hasError, setHasError] = useState(false);
    const Plugin = useMemo(() => {
        class UppyPlugin extends UIPlugin {
            constructor(uppy) {
                super(uppy);
                this.prepareUpload = (fileIDs) => {
                    const promises = fileIDs.map((fileID) => {
                        var _a;
                        const file = this.uppy.getFile(fileID);
                        this.uppy.emit('preprocess-progress', file, {
                            mode: 'indeterminate',
                            message: 'Creating prototype...',
                        });
                        if (!((_a = file.type) === null || _a === void 0 ? void 0 : _a.startsWith('image/'))) {
                            return;
                        }
                        return this.createPrototype()
                            .then((uploadUrl) => {
                            this.uppy.log(`Created prototype for ${file.name} with url ${uploadUrl}`);
                            this.uppy.setFileState(fileID, {
                                xhrUpload: {
                                    endpoint: uploadUrl,
                                    headers: {
                                        authorization: `Bearer ${accessToken}`,
                                    },
                                    getResponseData(_, response) {
                                        return {
                                            url: response.getResponseHeader('Location'),
                                        };
                                    },
                                },
                            });
                        })
                            .catch((err) => {
                            this.uppy.log(`[First Click] Failed to create prototype for ${file.id}:`, 'warning');
                            this.uppy.log(err, 'warning');
                            setHasError(true);
                            showGenericServerError();
                        });
                    });
                    const emitPreprocessCompleteForAll = () => {
                        fileIDs.forEach((fileID) => {
                            const file = this.uppy.getFile(fileID);
                            this.uppy.emit('preprocess-complete', file);
                        });
                    };
                    // Why emit `preprocess-complete` for all files at once, instead of
                    // above when each is processed?
                    // Because it leads to StatusBar showing a weird “upload 6 files” button,
                    // while waiting for all the files to complete pre-processing.
                    return Promise.all(promises).then(emitPreprocessCompleteForAll);
                };
                this.id = 'FirstClickPlugin';
                this.type = 'modifier';
            }
            // eslint-disable-next-line class-methods-use-this
            createPrototype() {
                return createPrototype({
                    variables: {
                        input: {
                            testUuid,
                            stepUuid: step.uuid,
                            version: 0,
                        },
                    },
                }).then(({ data }) => {
                    if (data.createPrototypeForFirstClickStep.ok) {
                        // TODO runInAction
                        PrototypeTaskUtils.setImportedPrototypeUrl(step, data.createPrototypeForFirstClickStep.project.prototypeUrl);
                        return data.createPrototypeForFirstClickStep.uploadUrl;
                    }
                    throw new Error('Something went wrong');
                });
            }
            install() {
                this.uppy.addPreProcessor(this.prepareUpload);
            }
            uninstall() {
                this.uppy.removePreProcessor(this.prepareUpload);
            }
        }
        return UppyPlugin;
    }, [accessToken, createPrototype, step, testUuid]);
    return { Plugin, hasError };
}
