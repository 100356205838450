import { jsx as _jsx } from "react/jsx-runtime";
import { observer } from 'mobx-react';
import * as React from 'react';
import { useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { FullScreenLoader, NotFound } from '@marvelapp/ballpark-components';
import { RecordTestSessionErrorEnum } from '../../__generated__/queryTypes';
import { EnsureBrowserSupported } from '../../components/BrowserCheck';
import VerifyPassword, { PasswordValidationStatus, } from '../../components/PasswordVerification';
import { DeviceBlock } from './DeviceBlock';
import { Localize } from './Localize';
import { MutationStatus, useRecordTestResult, } from './useRecordTestResult';
const CreateTestResultMessage = observer(function CreateTestResultMessage({ error, prototype, setToken, recordTestResult, }) {
    const [validationState, setValidationState] = useState(PasswordValidationStatus.Ready);
    const intl = useIntl();
    if (error === RecordTestSessionErrorEnum.TEST_DOES_NOT_EXIST ||
        error === RecordTestSessionErrorEnum.TEST_RESULT_DUPLICATE ||
        error === RecordTestSessionErrorEnum.TEST_RESULT_NOT_MATCHED) {
        return (_jsx(NotFound, { heading: intl.formatMessage({ id: "MsQB/S", defaultMessage: "We couldn't find the project" }), subheading: intl.formatMessage({ id: "+Zz51J", defaultMessage: "It may have been deleted or never existed, or the URL may be incorrect." }), testId: "project-not-found-title" }));
    }
    if (error === RecordTestSessionErrorEnum.USERTEST_PAUSED) {
        return (_jsx(NotFound, { heading: intl.formatMessage({ id: "1kkuqC", defaultMessage: "This research project is now closed" }), subheading: intl.formatMessage({ id: "yjC+WU", defaultMessage: "Please contact the researcher with any further questions or feedback" }), testId: "project-paused-message" }));
    }
    if (error === RecordTestSessionErrorEnum.DEVICE_NOT_SUPPORTED && prototype) {
        return _jsx(DeviceBlock, { settings: prototype.settings });
    }
    if (error === RecordTestSessionErrorEnum.PASSWORD_TOKEN_NOT_PROVIDED &&
        prototype) {
        return (_jsx(VerifyPassword, { projectId: prototype.shareId, validationState: validationState, setValidationState: setValidationState, setToken: setToken, recordTestResult: recordTestResult }));
    }
    if (error === RecordTestSessionErrorEnum.USERTEST_PROJECT_UNAVAILABLE &&
        (prototype === null || prototype === void 0 ? void 0 : prototype.isArchived)) {
        return (_jsx(NotFound, { heading: intl.formatMessage({ id: "svFNDW", defaultMessage: "This project has been archived" }), subheading: intl.formatMessage({ id: "uVKlAR", defaultMessage: "Please contact the project owner or account admin to get access to this project." }), testId: "project-archived-title" }));
    }
    // ignore any errors related to partner params as \they should not block a user from taking a test
    // but handle all other errors as generic
    if (error && error !== RecordTestSessionErrorEnum.UNEXPECTED_URL_PARAMS) {
        return (_jsx(NotFound, { heading: intl.formatMessage({ id: "gL1MAi", defaultMessage: "Oops something went wrong" }), subheading: intl.formatMessage({ id: "lfZk1P", defaultMessage: "Please check the link is correct and try again later" }) }));
    }
    console.log('error fullscreenloader');
    return _jsx(FullScreenLoader, {});
});
export default observer(function CreateTestResult({ onTestCreate, setToken, testUUID, shareUUID, setPartnerData, resumableLiveSessionResponseUUID, isLive, }) {
    const { error, responseUUID, recordTestResult, prototype, mutationStatus, partnerData, locale, roomName, } = useRecordTestResult({
        shareUUID,
        testUUID,
        resumableLiveSessionResponseUUID,
        isLive,
    });
    const isCreatingTestResult = useRef(false);
    useEffect(() => {
        if (mutationStatus === MutationStatus.Success && responseUUID) {
            onTestCreate(responseUUID, roomName);
        }
    }, [
        error,
        mutationStatus,
        onTestCreate,
        responseUUID,
        resumableLiveSessionResponseUUID,
        roomName,
        testUUID,
    ]);
    useEffect(() => {
        if (mutationStatus === MutationStatus.Success) {
            if (partnerData) {
                setPartnerData({
                    redirectUrl: partnerData.bounceUrl,
                    source: partnerData.source,
                });
                // partner data errors are returned at the top level alongside the rest of the result data
                // these errors should not block the user from taking the test and should be handled separately
                // to any other recordTestResult mutation errors
            }
        }
    }, [error, mutationStatus, partnerData, setPartnerData]);
    useEffect(() => {
        // guard against React strict mode double effects
        if (isCreatingTestResult.current)
            return;
        isCreatingTestResult.current = true;
        recordTestResult();
    }, [recordTestResult]);
    if (mutationStatus === MutationStatus.Pending)
        return _jsx(FullScreenLoader, {});
    return (_jsx(Localize, { locale: locale, children: _jsx(EnsureBrowserSupported, { children: _jsx(CreateTestResultMessage, { error: error, prototype: prototype, recordTestResult: recordTestResult, setToken: setToken }) }) }));
});
