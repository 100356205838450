import { jsx as _jsx } from "react/jsx-runtime";
import { observer } from 'mobx-react';
import * as React from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import styled, { css } from '@marvelapp/styled';
import { Box } from '@marvelapp/ui';
export default observer(function SimpleSlideTemplate({ children, maxWidth = 866, paddingTop, justifyContent = ['flex-start', 'flex-start', 'space-between'], isPrototype = false, }) {
    return (_jsx(Scrollbars, { autoHide: true, children: _jsx(Inner, { alignItems: ['flex-start', 'flex-start', 'space-between'], flexDirection: "column", justifyContent: justifyContent, maxWidth: maxWidth, paddingTop: paddingTop, "data-testid": "inner", isPrototype: isPrototype, children: children }) }));
});
const Inner = styled(Box).attrs((props) => ({
    paddingRight: ['s', 's', 74, 'xl'],
    paddingLeft: ['s', 's', 74, props.isPrototype ? 'xl' : 88],
    paddingBottom: [74, 74, 'l'],
})) `
  width: 100%;
  min-height: 100%;
  display: flex;

  ${(props) => props.isPrototype &&
    css `
      overflow-y: scroll;
      position: absolute;
      bottom: 0;
      top: 0;
    `}
`;
