import { defaultDropAnimationSideEffects } from '@dnd-kit/core';
import { useSortable as UseDndSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { useMemo } from 'react';
export const dropAnimation = {
    sideEffects: defaultDropAnimationSideEffects({
        styles: {
            active: {
                opacity: '0.5',
            },
        },
    }),
};
export function useSortable(...args) {
    const sortable = UseDndSortable(...args);
    const { transition, transform, isDragging, attributes, listeners, setNodeRef, } = sortable;
    const style = useMemo(() => ({
        transition,
        transform: CSS.Transform.toString(transform),
        zIndex: isDragging ? 100 : 'unset',
    }), [transition, transform, isDragging]);
    const dragHandleProps = useMemo(() => (Object.assign(Object.assign({}, attributes), listeners)), [attributes, listeners]);
    return Object.assign(Object.assign({}, sortable), { style, dragHandleProps, ref: setNodeRef });
}
