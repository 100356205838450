import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { observer } from 'mobx-react';
import { BtwText, Stack } from '@marvelapp/ballpark-components';
import { BuildingsIcon, ChecklistBoxIcon, LinkIcon, RallyIcon, WebcamIcon, } from '@marvelapp/ballpark-icons';
export const ParticipantSourceCell = observer(function ParticipantSourceCell({ source, }) {
    return (_jsxs(Stack, { direction: "row", gap: "2", align: "center", children: [getSourceIcon(source), _jsx(BtwText, { size: "13", children: source })] }));
});
function getSourceIcon(source) {
    if (source === 'Rally') {
        return _jsx(RallyIcon, { className: "size-4" });
    }
    if (source === 'Enhanced B2B') {
        return _jsx(BuildingsIcon, { className: "size-4" });
    }
    if (source === 'Enhanced') {
        return _jsx(WebcamIcon, { className: "size-4" });
    }
    if (source === 'Standard') {
        return _jsx(ChecklistBoxIcon, { className: "size-4" });
    }
    if (source === 'Share') {
        return _jsx(LinkIcon, { className: "size-4" });
    }
    return null;
}
