import { jsx as _jsx } from "react/jsx-runtime";
import { observer } from 'mobx-react';
import * as React from 'react';
import { useSlideUIContext } from '@marvelapp/ballpark-application';
import { DateDropdowns, Stack } from '@marvelapp/ballpark-components';
import { DateFormat, QuestionStepUtils, StepUtils, } from '@marvelapp/user-test-creator';
import BaseQuestionEdit from '../Question/BaseQuestionEdit';
export default observer(function DateEdit(props) {
    var _a;
    const { step } = useSlideUIContext();
    if (!StepUtils.isDateStep(step))
        return null;
    const dateFormat = (_a = QuestionStepUtils.getDateFormat(step)) !== null && _a !== void 0 ? _a : DateFormat.DDMMYYYY;
    return (_jsx(BaseQuestionEdit, Object.assign({}, props, { children: _jsx(Stack, { className: "sm:max-w-xs", children: _jsx(DateDropdowns, { isDisabled: true, dateFormat: dateFormat, value: "" }) }) })));
});
