import Uppy from '@uppy/core';
import DragDropPlugin from '@uppy/drag-drop';
import XHRUpload from '@uppy/xhr-upload';
import { runInAction } from 'mobx';
import { useEffect, useRef, useState } from 'react';
import { showErrorNotification } from '@marvelapp/ui-internal';
import { generateUUID } from '@marvelapp/user-test-creator';
const allowedFileTypes = [
    'image/gif',
    'image/jpeg',
    'image/jpg',
    'image/png',
    'image/webp',
];
export function useUppy(
// Note, onImageUploaded is optional because we use the same component in the prototype step
// for setting start and goal screens but no upload is involved (screens are chosen on the
// screen picker)
//
// I reverted a refactor that pushed useUppy to the parent and make onImageUploaded required
// as the parent doesn't need to know or care _how_ uploads are done.
{ onImageUploaded, customiseUppy }) {
    const dndRef = useRef(null);
    const [isLoading, setIsLoading] = useState(false);
    const [draggedOver, setDraggedOver] = useState(false);
    useEffect(() => {
        if (!dndRef.current || !onImageUploaded)
            return;
        const _uppy = new Uppy({
            debug: process.env.NODE_ENV !== 'production',
            restrictions: {
                maxFileSize: 6 * 1024 * 1024,
                allowedFileTypes,
            },
            autoProceed: true,
            onBeforeFileAdded(currentFile) {
                const id = generateUUID();
                const modifiedFile = Object.assign(Object.assign({}, currentFile), { id, xhrUpload: {
                        endpoint: `/api/content/canvas/upload/${id}/`,
                    } });
                return modifiedFile;
            },
        })
            .use(DragDropPlugin, {
            target: dndRef.current,
            onDragLeave: () => setDraggedOver(false),
            onDragOver: () => setDraggedOver(true),
            onDrop: () => setDraggedOver(false),
        })
            .use(XHRUpload, {
            // This parameter is required, but it's being overriden onBeforeFileAdded
            // since we're using a different URL for each file
            endpoint: '',
        })
            .on('restriction-failed', (_, error) => {
            // You can only upload: image/gif, image/jpeg, image/jpg, image/png, image/webp
            if (error.message.includes('exceeds maximum allowed size')) {
                showErrorNotification({
                    toastId: 'image-upload-failure',
                    content: 'There is a 6MB limit on image uploads. Try compressing your image and trying again.',
                    options: { autoClose: 5000 },
                });
            }
            if (error.message.includes('You can only upload')) {
                showErrorNotification({
                    toastId: 'file-upload-failure',
                    content: 'This file type is not supported.',
                    options: { autoClose: 5000 },
                });
            }
        })
            .on('upload', () => {
            setIsLoading(true);
        })
            .on('complete', (result) => {
            setIsLoading(false);
            if (result.failed.length) {
                // TODO: add error handling?
                // eslint-disable-next-line no-console
                console.error('There was an error when uploading the following files:', result.failed);
            }
            result.successful.forEach((file) => {
                runInAction(() => onImageUploaded(file.uploadURL));
            });
        });
        if (customiseUppy) {
            customiseUppy(_uppy);
        }
        return () => {
            _uppy === null || _uppy === void 0 ? void 0 : _uppy.close();
        };
    }, [customiseUppy, onImageUploaded]);
    return {
        isLoading,
        draggedOver,
        dndRef,
    };
}
