import { __awaiter } from "tslib";
import { Fragment as _Fragment, jsx as _jsx } from "react/jsx-runtime";
import { gql, useQuery } from '@apollo/client';
import { noop } from 'lodash-es';
import LogRocket from 'logrocket';
import { action, comparer, makeAutoObservable, observable, reaction, } from 'mobx';
import { observer } from 'mobx-react';
import { createContext, useContext, useEffect, useReducer, } from 'react';
import { useConfig } from '@marvelapp/ballpark-application';
import { deviceDetection, getPrototypeShareId } from '@marvelapp/core';
import { PrototypeTaskResponseUtils, getResponses, } from '@marvelapp/user-test-creator';
import { FrameEnum, } from '../../__generated__/queryTypes';
import { CachedPlayerSetupStatus, FigmaStatus, PlayerStatus, } from '../../slides/PrototypeTask/PrototypeRecord/types';
import { getCodeAndMessageForResponse, getProxyUrl, } from '../../slides/PrototypeTask/PrototypeTaskEdit/useCheckFigmaPermissions';
import { useRecorderState } from './RecorderContext';
const PrototypeStateContext = createContext(undefined);
const GET_DEVICE_FRAME = gql `
  query getDeviceFrame($shareId: String!) {
    project(shareId: $shareId) {
      pk
      settings {
        deviceFrame
      }
    }
  }
`;
export const usePrototypeState = () => {
    const prototypeState = useContext(PrototypeStateContext);
    if (prototypeState === undefined)
        throw new Error('usePrototypeState used outside of Provider');
    return prototypeState;
};
export const PrototypeStateProvider = observer(function PrototypeStateProvider({ children, }) {
    const recorderState = useRecorderState();
    const config = useConfig();
    const { nextPrototypeTaskStep } = recorderState;
    const prototypeStepStateFactory = ({ prevState, step, reloadWithoutVersionId, }) => {
        var _a, _b;
        if (!step) {
            // return a null object here to avoid having to check for null everywhere
            const nullObject = {
                deviceFrame: null,
                figmaStatus: FigmaStatus.Pending,
                hasReachedGoalScreenOrDoneFirstClick: false,
                isPrototypeFullWidth: false,
                isViewingMobileProtoOnIpad: false,
                stepResponse: undefined,
                playerStatus: null,
                cachedPlayerSetupStatus: CachedPlayerSetupStatus.NotCached,
                externalPrototypeUrl: undefined,
                prototypeUrl: undefined,
                prototypeKey: undefined,
                prototypeViewerEl: null,
                pointerEventsDisabled: false,
                shareId: undefined,
                screens: null,
                startPrototypeTask: noop,
                stopPrototypeTask: noop,
                startScreenLoaded: false,
                step,
                isInvalid: false,
                figmaEmbedUrlWithoutNodeParams: undefined,
                embedPermissionStatus: undefined,
                checkEmbedPermissions: noop,
                embedUrl: undefined,
            };
            return nullObject;
        }
        const { embedUrl } = step;
        let modifiedEmbedUrl;
        if (embedUrl && reloadWithoutVersionId) {
            const url = new URL(embedUrl);
            url.searchParams.delete('version-id');
            modifiedEmbedUrl = url.toString();
        }
        else {
            modifiedEmbedUrl = embedUrl;
        }
        const getFigmaEmbedUrlWithoutNodeParams = (embedUrl) => {
            if (embedUrl) {
                const url = new URL(embedUrl);
                if (!url.hostname.includes('figma.com')) {
                    return;
                }
                url.searchParams.delete('node-id');
                url.searchParams.delete('starting-point-node-id');
                return url;
            }
        };
        const getPrototypeKey = () => {
            const figmaEmbedUrlWithoutNodeParams = getFigmaEmbedUrlWithoutNodeParams(modifiedEmbedUrl);
            if (figmaEmbedUrlWithoutNodeParams) {
                return figmaEmbedUrlWithoutNodeParams.toString();
            }
            if (step.importedPrototypeUrl) {
                return step.uuid;
            }
        };
        const prototypeKey = getPrototypeKey();
        const wontRemount = prevState && prevState.prototypeKey === prototypeKey;
        if (wontRemount && ((_a = process.env.DEPLOY_ENV) === null || _a === void 0 ? void 0 : _a.includes('production'))) {
            LogRocket.track('CachedPrototype');
        }
        const wontReloadScreens = wontRemount &&
            ((_b = prevState.step) === null || _b === void 0 ? void 0 : _b.importedPrototypeUrl) === step.importedPrototypeUrl;
        // object needs to be typed immediately so `this` uses the full type and not the inferred type
        // from the object definition
        const state = {
            figmaStatus: wontRemount ? prevState.figmaStatus : FigmaStatus.Pending,
            playerStatus: wontRemount ? PlayerStatus.Ready : PlayerStatus.Loading,
            prototypeViewerEl: wontRemount ? prevState.prototypeViewerEl : null,
            screens: wontReloadScreens ? prevState.screens : null,
            // Don't reset the device frame if we're not remounting the player as the
            // query for the device frame only reruns when the prototype shareId
            // changes -- in the case of a cloned prototype step the shareId will be
            // the same so the query won't rerun
            deviceFrame: wontRemount ? prevState.deviceFrame : null,
            embedPermissionStatus: wontRemount
                ? prevState.embedPermissionStatus
                : undefined,
            // Don't reset the embedUrl if we're not remounting the player as the
            // iframe would reload if the node-id or starting-point-node-id query
            // params changed. If the next step starts on a different screen then we
            // programatically set that using the player API.
            embedUrl: wontRemount ? prevState.embedUrl : modifiedEmbedUrl,
            // Now that we're not unmounting the player between steps that use the
            // same Figma prototype we can't just rely on playerStatus. We need to
            // check if we're loading new screens or not and if we're waiting for
            // the start screen to be set or not.
            get cachedPlayerSetupStatus() {
                if (!wontRemount) {
                    return CachedPlayerSetupStatus.NotCached;
                }
                if (!this.screens) {
                    return CachedPlayerSetupStatus.Loading;
                }
                if (!this.startScreenLoaded) {
                    return CachedPlayerSetupStatus.Loading;
                }
                if (this.playerStatus === PlayerStatus.Loading) {
                    return CachedPlayerSetupStatus.Loading;
                }
                return CachedPlayerSetupStatus.Ready;
            },
            startScreenLoaded: false, // TODO move tracking of current screen to here then we can work out if this needs reset?
            hasReachedGoalScreenOrDoneFirstClick: false,
            pointerEventsDisabled: false,
            isPrototypeFullWidth: false,
            get isViewingMobileProtoOnIpad() {
                const isMobileFrame = this.deviceFrame === FrameEnum.IPHONEX ||
                    this.deviceFrame === FrameEnum.PIXEL_4;
                const { isIPad } = deviceDetection;
                return !!(isIPad && isMobileFrame);
            },
            prototypeKey,
            get figmaEmbedUrlWithoutNodeParams() {
                var _a;
                return (_a = getFigmaEmbedUrlWithoutNodeParams(modifiedEmbedUrl)) === null || _a === void 0 ? void 0 : _a.toString();
            },
            step,
            get prototypeUrl() {
                return step === null || step === void 0 ? void 0 : step.importedPrototypeUrl;
            },
            get isInvalid() {
                var _a;
                return !this.prototypeUrl || ((_a = this.embedPermissionStatus) === null || _a === void 0 ? void 0 : _a.code) === 401;
            },
            get externalPrototypeUrl() {
                return step === null || step === void 0 ? void 0 : step.originalPrototypeUrl;
            },
            get shareId() {
                return this.prototypeUrl && getPrototypeShareId(this.prototypeUrl);
            },
            get stepResponse() {
                const stepResponses = getResponses(recorderState.response);
                return stepResponses[recorderState.allSteps.findIndex((currentStep) => currentStep.uuid === step.uuid)];
            },
            startPrototypeTask() {
                var _a;
                this.isPrototypeFullWidth = true;
                if (!step || !this.stepResponse)
                    throw new Error('startPrototypeTask called without nextPrototypeTaskStepResponse');
                this.playerStatus = PlayerStatus.Recording;
                PrototypeTaskResponseUtils.clearEvents(this.stepResponse); // TODO warn users about this
                (_a = this.prototypeViewerEl) === null || _a === void 0 ? void 0 : _a.startRecording(step.goalScreen ? { goalScreenPk: step.goalScreen } : {});
            },
            stopPrototypeTask() {
                var _a;
                this.isPrototypeFullWidth = false;
                (_a = this.prototypeViewerEl) === null || _a === void 0 ? void 0 : _a.stopRecording();
                this.playerStatus = PlayerStatus.Stopped;
            },
            checkEmbedPermissions() {
                return __awaiter(this, void 0, void 0, function* () {
                    try {
                        const figmaProxyUrl = getProxyUrl(modifiedEmbedUrl, config);
                        const response = yield fetch(figmaProxyUrl.toString());
                        const [code, message] = getCodeAndMessageForResponse(response.status, 'Recorder');
                        this.embedPermissionStatus = {
                            code,
                            message,
                        };
                    }
                    catch (e) {
                        // if the check fails, we don't want to show any error to the user
                        this.embedPermissionStatus = {
                            code: 200,
                            message: _jsx(_Fragment, {}),
                        };
                    }
                });
            },
        };
        return makeAutoObservable(state, {
            prototypeViewerEl: observable.ref,
        });
    };
    const [prototypeState, resetPrototypeState] = useReducer((prevState, { nextPrototypeTaskStep, reloadWithoutVersionId, }) => {
        return prototypeStepStateFactory({
            prevState,
            step: nextPrototypeTaskStep,
            reloadWithoutVersionId,
        });
    }, null, () => prototypeStepStateFactory({
        prevState: null,
        step: nextPrototypeTaskStep,
    }));
    useEffect(() => reaction(() => {
        var _a, _b;
        return {
            nextPrototypeTaskStep: recorderState.nextPrototypeTaskStep,
            reloadWithoutVersionId: ((_a = prototypeState === null || prototypeState === void 0 ? void 0 : prototypeState.embedPermissionStatus) === null || _a === void 0 ? void 0 : _a.code)
                ? figmaPrototypeHasVersionIdBug((_b = prototypeState.embedPermissionStatus) === null || _b === void 0 ? void 0 : _b.code)
                : false,
        };
    }, resetPrototypeState, { equals: comparer.shallow }), [prototypeState, recorderState]);
    useQuery(GET_DEVICE_FRAME, {
        onCompleted: action((data) => {
            var _a, _b;
            const deviceFrame = (_b = (_a = data === null || data === void 0 ? void 0 : data.project) === null || _a === void 0 ? void 0 : _a.settings) === null || _b === void 0 ? void 0 : _b.deviceFrame;
            prototypeState.deviceFrame = deviceFrame;
        }),
        variables: { shareId: (prototypeState === null || prototypeState === void 0 ? void 0 : prototypeState.shareId) || '' },
        skip: !prototypeState || !prototypeState.shareId,
    });
    return (_jsx(PrototypeStateContext.Provider, { value: prototypeState, children: children }));
});
function figmaPrototypeHasVersionIdBug(permissionsCheckStatus) {
    return permissionsCheckStatus === 412 || permissionsCheckStatus === 409;
}
