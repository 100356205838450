import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { capitalize } from 'lodash-es';
import { observer } from 'mobx-react';
import { useCallback } from 'react';
import * as React from 'react';
import { Badge, BtwButton, BtwText, cn, getIconForFilter, } from '@marvelapp/ballpark-components';
import { AsteriskIcon } from '@marvelapp/ballpark-icons';
export const FilterButton = observer(function FilterElement({ hasValidationError = false, showIcon = true, isRequired = false, isSelected, itemId, itemName, selectionCount, setSelectedFilter, }) {
    const handleButtonClick = useCallback(() => {
        setSelectedFilter(itemId);
    }, [itemId, setSelectedFilter]);
    return (_jsx(FilterRow, { isSelected: isSelected, children: _jsxs("button", { type: "button", onClick: handleButtonClick, children: [_jsxs(BtwText, { className: cn(
                    // Basic flex styles
                    'relative flex w-full items-center gap-2', 
                    // Styling for icons
                    '[&>svg:first-child]:size-4 [&>svg:first-child]:shrink-0', 
                    // Line-height override because BtwText size 13 is borked
                    'leading-6', { 'font-medium': hasValidationError }), size: "13", variant: hasValidationError ? 'red' : 'primary', children: [showIcon && getIconForFilter(itemId), _jsx("span", { className: "line-clamp-1 text-left", children: capitalize(itemName) }), isRequired && (_jsx(AsteriskIcon, { className: "size-3 text-red-500", "data-testid": "required-filter" }))] }), _jsx(SelectionBadge, { selectionCount: selectionCount })] }) }));
});
export const FilterRow = observer(function FilterRow({ children, isSelected, }) {
    return (_jsx(BtwButton, { asChild: true, className: cn(
        // Adding a Tailwind group modifier to modify children based on the parent
        'group', 
        // The rest
        'relative', 'cursor-pointer', 'px-3 py-1.5', 'hover:bg-gray-400/25', 'hover:ring-0', 'rounded-md', 'justify-start', 'font-normal', {
            // Adding an is-selected Tailwind modifier to add styles to children
            // based on the selected state
            'is-selected bg-gray-400/25': isSelected,
        }), "data-testid": "filter-element", width: "full", "data-selected": isSelected, variant: "ghost", children: children }));
});
const SelectionBadge = observer(function SelectionBadge({ selectionCount, }) {
    if (selectionCount < 1)
        return null;
    return (_jsx(Badge
    // Adding a min-width to make it square. Adjusting background for selected state.
    , { 
        // Adding a min-width to make it square. Adjusting background for selected state.
        className: "relative min-w-[18px] group-hover:bg-gray-300 group-[.is-selected]:bg-white group-[.is-selected]:text-gray-600", "data-testid": "selection-badge", size: "sm", children: selectionCount }));
});
