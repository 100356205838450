import { createGlobalStyle } from '@marvelapp/styled';
const UppyStyle = createGlobalStyle `
  .uppy-Root {
    position: absolute;
    background-color: transparent;
    border: 0;
  }

  .uppy-DragDrop-inner {
    display: none;
  }
`;
export { UppyStyle };
