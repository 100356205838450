import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { gql, useLazyQuery } from '@apollo/client';
import { action } from 'mobx';
import { observer } from 'mobx-react';
import { useCallback, useState } from 'react';
import { useCurrentUser, userSelectors } from '@marvelapp/ballpark-application';
import { BtwHeading, BtwModal, BtwText, BtwTooltip, RadioToggle, Stack, cn, } from '@marvelapp/ballpark-components';
import { pluralize } from '@marvelapp/core';
import { StudyTypeEnum, } from '../../__generated__/queryTypes';
import { studyTypeLabelMap, useRecruitmentState } from './RecruitmentContext';
import { UnsupportedFiltersWarning } from './UnsupportedFiltersWarning';
import { StudyFocusIcon } from './common';
import { normalizeUnsupportedFilters, selectedOptionFragment, } from './useGetRecruitmentData';
const GET_UNSUPPORTED_FILTERS = gql `
  query getUnsupportedFilters($testUUID: String!, $studyType: StudyTypeEnum!) {
    unsupportedFilters(testUUID: $testUUID, studyType: $studyType) {
      id
      name
      isAvailableForStudyType
      options(first: 100) {
        edges {
          node {
            ...selectedOption
          }
        }
      }
    }
  }
  ${selectedOptionFragment}
`;
export const StudyFocusOptions = observer(function StudyFocusOptions() {
    const user = useCurrentUser();
    const recruitmentState = useRecruitmentState();
    const { studyType, switchStudyType, testUUID } = recruitmentState;
    const [getUnsupportedFilters] = useLazyQuery(GET_UNSUPPORTED_FILTERS);
    const [pendingStudyType, setPendingStudyType] = useState(null);
    const [pendingUnsupportedFilters, setPendingUnsupportedFilters] = useState(null);
    const checkForUnsupportedFilters = useCallback((type) => {
        getUnsupportedFilters({
            variables: {
                testUUID,
                studyType: type,
            },
            onCompleted: action((data) => {
                var _a;
                if ((_a = data === null || data === void 0 ? void 0 : data.unsupportedFilters) === null || _a === void 0 ? void 0 : _a.length) {
                    setPendingStudyType(type);
                    setPendingUnsupportedFilters(normalizeUnsupportedFilters(data === null || data === void 0 ? void 0 : data.unsupportedFilters));
                }
                else {
                    switchStudyType(type, {});
                }
            }),
            onError: action(() => {
                switchStudyType(type, {});
            }),
        });
    }, [getUnsupportedFilters, switchStudyType, testUUID]);
    const clearPendingData = useCallback(() => {
        setPendingStudyType(null);
        setPendingUnsupportedFilters(null);
    }, []);
    return (_jsxs(BtwModal.Root, { open: !!pendingStudyType, onOpenChange: clearPendingData, children: [_jsx(RadioToggle.Root, { "aria-label": "Choose study focus", asChild: true, value: studyType, onValueChange: checkForUnsupportedFilters, children: _jsxs(Stack, { direction: { initial: 'col', sm: 'row' }, align: "stretch", gap: "4", width: "full", children: [_jsx(StudyFocus, { description: "Best for simple surveys and screen recordings", studyType: StudyTypeEnum.SURVEY, title: studyTypeLabelMap[StudyTypeEnum.SURVEY] }), _jsx(StudyFocus, { description: "Best for open-ended survey questions or \u2018think out loud\u2019 videos", studyType: StudyTypeEnum.VIDEO, title: studyTypeLabelMap[StudyTypeEnum.VIDEO] }), _jsx(StudyFocus, { description: "Best for targeting specific job roles, industries and skills", disabledTooltip: !userSelectors.isEnterprise(user)
                                ? 'Available on Enterprise plans'
                                : '', studyType: StudyTypeEnum.B2B, title: studyTypeLabelMap[StudyTypeEnum.B2B] })] }) }), pendingStudyType && pendingUnsupportedFilters && (_jsx(UnsupportedFiltersWarning, { clearPendingData: clearPendingData, pendingStudyType: pendingStudyType, pendingUnsupportedFilters: pendingUnsupportedFilters }))] }));
});
const StudyFocus = observer(function StudyFocus({ description, disabledTooltip, studyType, title, }) {
    return (_jsx(BtwTooltip, { className: "max-w-80", "data-testid": "disabled-study-focus-tooltip", content: disabledTooltip, children: _jsx(Stack, { asChild: true, className: cn('cursor-pointer rounded-lg p-4 hover:bg-gray-50', 'ring-0', 'shadow-sm data-[state=unchecked]:border-gray-600/20', 'data-[state=checked]:bg-gray-50 data-[state=checked]:ring-1 data-[state=checked]:ring-gray-600', 'disabled:bg-gray-100 disabled:text-gray-400 disabled:ring-0 disabled:hover:bg-gray-100', 'w-full', 'sm:w-[calc((100%_-_(16px*2))/3)]'), "data-testid": `study-focus-${studyType}`, gap: "4", justify: "between", children: _jsxs(RadioToggle.Item, { disabled: !!disabledTooltip, value: studyType, id: title, children: [_jsxs(Stack, { direction: {
                            initial: 'row',
                            sm: 'col',
                            md: 'row',
                            lg: 'col',
                            xl: 'row',
                        }, gap: {
                            initial: '6',
                            sm: '4',
                            md: '6',
                            lg: '4',
                            xl: '6',
                        }, height: "full", width: "full", children: [_jsxs(Stack, { gap: "1.5", className: "order-first sm:order-last md:order-first lg:order-last xl:order-first", width: "full", children: [_jsx(BtwHeading, { variant: disabledTooltip ? 'disabled' : 'primary', size: "13", children: title }), _jsx(BtwText, { variant: disabledTooltip ? 'disabled' : 'secondary', size: "xs", children: description })] }), _jsx(StudyFocusIcon, { isDisabled: !!disabledTooltip, studyType: studyType, withBg: true })] }), _jsx(CardFooter, { isDisabled: !!disabledTooltip, studyType: studyType })] }) }) }));
});
const CardFooter = observer(function CardFooter({ isDisabled, studyType, }) {
    const { getCreditsPerMinuteByStudyType } = useRecruitmentState();
    const credits = getCreditsPerMinuteByStudyType(studyType);
    return (_jsx(Stack, { direction: { initial: 'row', sm: 'col', md: 'row', lg: 'col', xl: 'row' }, gap: "2", justify: "between", width: "full", align: {
            initial: 'center',
            sm: 'start',
            md: 'center',
            lg: 'start',
            xl: 'center',
        }, children: _jsx(Stack, { className: "min-w-0 flex-1", children: _jsxs(BtwText, { className: "w-full uppercase", "data-testid": "credits-per-minute", variant: isDisabled ? 'disabled' : 'secondary', size: "xxs", weight: "semibold", truncate: true, children: [credits, " ", pluralize(credits, 'credit'), " /minute"] }) }) }));
});
