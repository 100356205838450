import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { observer } from 'mobx-react';
import { useMemo } from 'react';
import { useAction } from '@marvelapp/ballpark-application';
import { Badge, BtwButton, BtwModal, BtwText, Stack, Strong, } from '@marvelapp/ballpark-components';
import { ArrowRightIcon } from '@marvelapp/ballpark-icons';
import { studyTypeLabelMap, useRecruitmentState } from './RecruitmentContext';
import { StudyFocusIcon } from './common';
export const UnsupportedFiltersWarning = observer(function UnsupportedFiltersWarning({ clearPendingData, pendingStudyType, pendingUnsupportedFilters, }) {
    const recruitmentState = useRecruitmentState();
    const { studyType: currentStudyType, switchStudyType } = recruitmentState;
    const updateStudyType = useAction(() => {
        switchStudyType(pendingStudyType, pendingUnsupportedFilters);
        clearPendingData();
    }, [
        clearPendingData,
        pendingStudyType,
        pendingUnsupportedFilters,
        switchStudyType,
    ]);
    return (_jsxs(BtwModal.Content, { "data-testid": "unsupported-filters-warning", contentClassName: "w-full max-w-lg flex-row", children: [_jsxs(Stack, { className: "p-8", gap: "6", children: [_jsxs(Stack, { align: "center", direction: "row", gap: "5", children: [_jsx(StudyFocusIcon, { iconSize: "6", studyType: currentStudyType, withBg: true }), _jsx(ArrowRightIcon, {}), _jsx(StudyFocusIcon, { iconSize: "6", studyType: pendingStudyType, withBg: true })] }), _jsxs(BtwText, { variant: "primary", size: "sm", children: ["You\u2019re about to change the study focus from", ' ', _jsx(Strong, { children: studyTypeLabelMap[currentStudyType] }), " to", ' ', _jsx(Strong, { children: studyTypeLabelMap[pendingStudyType] }), ", which means some targeting attributes won\u2019t carry over."] }), _jsx(UnsupportedFiltersList, { pendingStudyType: pendingStudyType, pendingUnsupportedFilters: pendingUnsupportedFilters }), _jsxs(BtwText, { variant: "secondary", size: "xs", children: ["Don\u2019t worry, we\u2019ll save your details, in case you want to switch back to ", studyTypeLabelMap[currentStudyType], " focus."] })] }), _jsxs(BtwModal.Footer, { children: [_jsx(BtwModal.Close, { asChild: true, children: _jsx(BtwButton, { "data-testid": "cancel-cta", variant: "secondary", children: "Cancel" }) }), _jsx(BtwButton, { "data-testid": "confirm-cta", onClick: updateStudyType, variant: "primary", children: "Continue" })] })] }));
});
const UnsupportedFiltersList = observer(function UnsupportedFiltersList({ pendingStudyType, pendingUnsupportedFilters, }) {
    const { unsupportedFilters, filtersWithUnsupportedOptions } = useMemo(() => Object.values(pendingUnsupportedFilters).reduce((sortedFilters, filter) => {
        if (filter.isAvailableForStudyType) {
            sortedFilters.filtersWithUnsupportedOptions.push(filter);
        }
        else {
            sortedFilters.unsupportedFilters.push(filter);
        }
        return sortedFilters;
    }, { unsupportedFilters: [], filtersWithUnsupportedOptions: [] }), [pendingUnsupportedFilters]);
    return (_jsxs(_Fragment, { children: [!!unsupportedFilters.length && (_jsxs(Stack, { gap: "2", width: "full", children: [_jsxs(BtwText, { size: "sm", variant: "primary", children: ["The following attributes are not available with", ' ', studyTypeLabelMap[pendingStudyType], " focus:"] }), _jsx(Stack, { "data-testid": "unsupported-filters-list", direction: "row", gap: "2", width: "full", wrap: "wrap", children: unsupportedFilters.map((filter) => (_jsx(FilterBadge, { label: filter.name }, filter.id))) })] })), !!filtersWithUnsupportedOptions.length && (_jsxs(Stack, { gap: "2", width: "full", children: [_jsx(BtwText, { size: "sm", variant: "primary", children: "The following attributes are available, but not all values will match:" }), _jsx(Stack, { "data-testid": "filters-with-unsupported-options", direction: "row", gap: "2", width: "full", wrap: "wrap", children: filtersWithUnsupportedOptions.map((filter) => (_jsx(FilterBadge, { label: filter.name }, filter.id))) })] }))] }));
});
const FilterBadge = observer(function FilterBadge({ label, }) {
    return (_jsx(Badge, { className: "inline-block max-w-md", "data-testid": "unsupported-filter", children: _jsx("p", { className: "truncate", children: label }) }));
});
