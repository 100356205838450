import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { clone, isNil } from 'lodash-es';
import { observer } from 'mobx-react';
import { useMemo } from 'react';
import { getEnglishStepName } from '@marvelapp/ballpark-copy';
import { MediaPlayer, formatTime, generateWebVTT, } from '@marvelapp/media-player';
import { MediaUtils, RecordingPipeline, StepRecordingPermission, } from '@marvelapp/user-test-creator';
import { RecordingFailureType, RecordingStatus, } from '../types';
// This is wrapper to render the media player using Ballpark's recording object
export const BallparkMediaPlayer = observer(function BallparkMediaPlayer({ recordings, chaptersURL, onTimeUpdate, currentTime, }) {
    var _a, _b, _c;
    const { download: recording } = recordings;
    const mediaSrc = useMemo(() => {
        if (!(recording === null || recording === void 0 ? void 0 : recording.videoUrl))
            return undefined;
        return {
            src: recording.videoUrl,
            // TODO: the backend should return the mime type rather than hardcoding it here
            type: recording.isAudio ? 'audio/mp3' : 'video/mp4',
        };
    }, [recording]);
    if (!recording)
        return null;
    const warningMessage = getVideoWarningMessage({ recordings });
    return (_jsx("div", { children: _jsxs(MediaPlayer.Root, { mediaSrc: mediaSrc, mediaKind: recording.isAudio ? 'audio' : 'video', status: getMediaPlayerStatus(recording.uploadStatus), waveformDataUrl: (_a = recording.waveform) === null || _a === void 0 ? void 0 : _a.url, onTimeUpdate: onTimeUpdate, currentTime: currentTime, children: [((_b = recording.subtitles) === null || _b === void 0 ? void 0 : _b.url) && (_jsx(MediaPlayer.Track
                // TODO: The language should ideally come from the server
                // in case we need to support more than one language
                , { 
                    // TODO: The language should ideally come from the server
                    // in case we need to support more than one language
                    label: "On", kind: "subtitles", default: true, src: (_c = recording.subtitles) === null || _c === void 0 ? void 0 : _c.url })), chaptersURL && (_jsx(MediaPlayer.Track, { kind: "chapters", src: chaptersURL, default: true })), warningMessage && _jsx(MediaPlayer.MessageBadge, Object.assign({}, warningMessage))] }) }));
});
function getMediaPlayerStatus(recordingStatus) {
    switch (recordingStatus) {
        case RecordingStatus.AcquiringMedia:
        case RecordingStatus.InProgress:
        case RecordingStatus.Pending:
            return 'processing';
        case RecordingStatus.Failed:
            return 'error';
        case RecordingStatus.Success:
        default:
            return undefined;
    }
}
export function generateWebVTTChaptersURL(response, recordings) {
    var _a, _b;
    if (!response.userTest)
        return undefined;
    const stepDefinitions = clone(response.userTest.stepDefinitions);
    const { welcomeStep, exitStep, mediaSettingsStep } = response.userTest;
    if (!mediaSettingsStep) {
        return undefined;
    }
    stepDefinitions[welcomeStep.uuid] = Object.assign(Object.assign({}, welcomeStep), { title: (_a = welcomeStep.title) !== null && _a !== void 0 ? _a : 'Welcome Step' });
    stepDefinitions[exitStep.uuid] = Object.assign(Object.assign({}, exitStep), { title: (_b = exitStep.title) !== null && _b !== void 0 ? _b : 'Exit Step' });
    stepDefinitions[mediaSettingsStep.uuid] = Object.assign(Object.assign({}, mediaSettingsStep), { title: 'Media Settings Step' });
    const chapters = [];
    const { endTimestamp } = response;
    if (!endTimestamp)
        return undefined;
    const mediaSettingsStepIndex = response.timeline.findIndex((event) => event.stepUUID === mediaSettingsStep.uuid);
    if (mediaSettingsStepIndex === -1)
        return undefined;
    // Exclude the media settings step from the recording timeline
    const recordingTimeline = response.timeline.slice(mediaSettingsStepIndex + 1);
    if (!recordingTimeline.length)
        return undefined;
    const startTimestamp = getRecordingStartTimestamp(recordings);
    if (!startTimestamp)
        return undefined;
    if (process.env.NODE_ENV !== 'production') {
        console.log('Start timestamp:', new Date(startTimestamp).toISOString());
        console.log('End timestamp:', new Date(endTimestamp).toISOString());
        console.log('Recording duration:', (endTimestamp - startTimestamp) / 1000, 'seconds');
    }
    // Add media settings step
    // This step is added to account for the time between when recording starts
    // and when the actual test begins. We use a fixed duration (800ms) because
    // the actual media settings step is excluded from the recording timeline
    // and there's often a brief delay between when recording starts and the first
    // test step
    const mediaSettingsEnd = 800;
    chapters.push({
        start: 0,
        end: mediaSettingsEnd,
        title: 'Media Settings Step',
    });
    recordingTimeline.forEach(({ startTimestamp: eventTimeStamp, stepUUID }, index) => {
        var _a;
        const step = stepDefinitions[stepUUID];
        const title = (_a = step.title) !== null && _a !== void 0 ? _a : getEnglishStepName(step);
        const start = index === 0
            ? mediaSettingsEnd
            : eventTimeStamp - startTimestamp + mediaSettingsEnd;
        const nextEvent = recordingTimeline[index + 1];
        let end;
        if (nextEvent) {
            end = nextEvent.startTimestamp - startTimestamp + mediaSettingsEnd;
        }
        else {
            end = endTimestamp - startTimestamp + mediaSettingsEnd;
        }
        if (process.env.NODE_ENV !== 'production') {
            console.log(`Chapter: ${title}`);
            console.log(`  Start: ${formatTime(start)} (${start}ms)`);
            console.log(`  End: ${formatTime(end)} (${end}ms)`);
            console.log(`  Duration: ${(end - start) / 1000} seconds`);
        }
        chapters.push({
            start,
            end,
            title,
        });
    });
    const webVTTContent = generateWebVTT(chapters);
    const blob = new Blob([webVTTContent], { type: 'text/vtt' });
    return URL.createObjectURL(blob);
}
function getRecordingStartTimestamp(recordings) {
    return Object.values(recordings)
        .map((recording) => recording === null || recording === void 0 ? void 0 : recording.startTime)
        .filter((startTime) => Boolean(startTime))
        .map((startTime) => new Date(startTime).getTime())
        .sort((a, b) => a - b)[0];
}
export function isRecordingProcessing(uploadStatus) {
    if (!uploadStatus)
        return false;
    return (uploadStatus === RecordingStatus.AcquiringMedia ||
        uploadStatus === RecordingStatus.Pending ||
        uploadStatus === RecordingStatus.InProgress);
}
export function shouldShowMediaPlayer(recording) {
    if (!recording)
        return false;
    if (!recording.failedType)
        return true;
    return recording.failedType === RecordingFailureType.Server;
}
// This could be simplified if we either merge the download permissions backend-side
// or expose a new field in the API that tells us if the recording is a video or audio
export function getMediaKind(recording) {
    var _a, _b, _c, _d;
    const { download } = recording;
    // check for its existence, otherwise fall back to checking permissions to determine kind
    if (!isNil(download === null || download === void 0 ? void 0 : download.isAudio)) {
        return (download === null || download === void 0 ? void 0 : download.isAudio) ? 'audio' : 'video';
    }
    // When we don't have any permissions (older recordings before the permission
    // system was implemented) fall back to video, which will be the most common case
    if (!((_a = recording === null || recording === void 0 ? void 0 : recording.user) === null || _a === void 0 ? void 0 : _a.permissions) && !((_b = recording === null || recording === void 0 ? void 0 : recording.screen) === null || _b === void 0 ? void 0 : _b.permissions)) {
        return 'video';
    }
    // When download media is still processing, or there was an error in the
    // processing pipeline, use the recording permissions to determine the media kind
    const permissions = MediaUtils.mergeRecordingPermissions((_c = recording === null || recording === void 0 ? void 0 : recording.user) === null || _c === void 0 ? void 0 : _c.permissions, (_d = recording === null || recording === void 0 ? void 0 : recording.screen) === null || _d === void 0 ? void 0 : _d.permissions);
    return permissions.screen === StepRecordingPermission.Granted ||
        permissions.webcam === StepRecordingPermission.Granted
        ? 'video'
        : 'audio';
}
function getVideoWarningMessage({ recordings, }) {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p;
    if (!recordings)
        return null;
    const { hasNetworkDisconnected, hasReconnected } = getNetworkEvents(recordings);
    const hasUserInitiatedDisconnectUser = (_b = (_a = recordings.user) === null || _a === void 0 ? void 0 : _a.statusLog) === null || _b === void 0 ? void 0 : _b.includes(RecordingPipeline.UserInitiatedDisconnect);
    const hasUserInitiatedDisconnectScreen = (_d = (_c = recordings.screen) === null || _c === void 0 ? void 0 : _c.statusLog) === null || _d === void 0 ? void 0 : _d.includes(RecordingPipeline.UserInitiatedDisconnect);
    const hasBothUserAndNetworkDisconnects = hasNetworkDisconnected &&
        (hasUserInitiatedDisconnectUser || hasUserInitiatedDisconnectScreen);
    const isMicOnly = ((_f = (_e = recordings.user) === null || _e === void 0 ? void 0 : _e.permissions) === null || _f === void 0 ? void 0 : _f.microphone) ===
        StepRecordingPermission.Granted &&
        ((_h = (_g = recordings.user) === null || _g === void 0 ? void 0 : _g.permissions) === null || _h === void 0 ? void 0 : _h.webcam) !== StepRecordingPermission.Granted;
    if (hasBothUserAndNetworkDisconnects) {
        return {
            type: 'warning',
            message: "The participant's network/device disconnected and also the participant chose to disconnect before they reached the end of the session",
        };
    }
    if (hasNetworkDisconnected) {
        if (hasNetworkDisconnected && ((_j = recordings.download) === null || _j === void 0 ? void 0 : _j.missingDataWarning)) {
            const message = `We detected that the participant’s connection was unstable. You may see the recording pause ${hasReconnected ? 'and resume ' : ''}during this session, recording quality may be affected.`;
            return { message, type: 'warning' };
        }
        if (hasReconnected) {
            return {
                message: 'The participant’s network/device disconnected and reconnected. You may see the recording pause and resume during this session.',
                type: 'warning',
            };
        }
        return {
            message: 'The participant’s network/device disconnected before they reached the end of the session.',
            type: 'warning',
        };
    }
    if (hasUserInitiatedDisconnectUser && hasUserInitiatedDisconnectScreen) {
        const message = `The participant chose to disconnect their ${isMicOnly ? 'audio' : 'camera'} and screen before they reached the end of the session.`;
        return { message, type: 'warning' };
    }
    if (hasUserInitiatedDisconnectUser) {
        const message = `The participant chose to disconnect their ${isMicOnly ? 'audio' : 'camera/mic recording'} before they reached the end of the session${((_k = recordings.user) === null || _k === void 0 ? void 0 : _k.missingDataWarning)
            ? ' and their connection was unstable, recording quality may be affected'
            : ''}.`;
        return { message, type: 'warning' };
    }
    if (hasUserInitiatedDisconnectScreen) {
        const message = `The participant chose to disconnect their ${((_m = (_l = recordings.screen) === null || _l === void 0 ? void 0 : _l.permissions) === null || _m === void 0 ? void 0 : _m.microphone) ===
            StepRecordingPermission.Granted
            ? 'audio and '
            : ''}screen before they reached the end of the session.`;
        return { message, type: 'warning' };
    }
    if (((_o = recordings.user) === null || _o === void 0 ? void 0 : _o.missingDataWarning) ||
        ((_p = recordings.screen) === null || _p === void 0 ? void 0 : _p.missingDataWarning)) {
        const message = `We detected that the participant’s device or connection was unable to send the ${checkForMissingData(recordings).join(' and ')} recording at full-quality. We are showing you the rescued recording.`;
        return { message, type: 'warning' };
    }
    return null;
}
function getNetworkEvents(recordings) {
    var _a, _b, _c, _d;
    let hasUserNetworkDisconnected = false;
    let hasUserNetworkReconnected = false;
    let hasScreenNetworkDisconnected = false;
    let hasScreenNetworkReconnected = false;
    // we have seen a couple of rogue logs where the network disconnect event is added after the stream has ended
    // these checks return true when the disconnect event is before the video pipeline processing logs
    if ((_a = recordings.user) === null || _a === void 0 ? void 0 : _a.statusLog) {
        const hasBothEvents = recordings.user.statusLog.includes(RecordingPipeline.ClientDisconnect) &&
            recordings.user.statusLog.includes(RecordingPipeline.ProcessingInstructionSent);
        if (hasBothEvents &&
            recordings.user.statusLog.indexOf(RecordingPipeline.ClientDisconnect) <
                recordings.user.statusLog.indexOf(RecordingPipeline.ProcessingInstructionSent)) {
            hasUserNetworkDisconnected = true;
            hasUserNetworkReconnected = recordings.user.statusLog.includes(RecordingPipeline.ClientReconnectAttemptSuccess);
        }
    }
    if ((_b = recordings.screen) === null || _b === void 0 ? void 0 : _b.statusLog) {
        const hasBothEvents = ((_c = recordings.screen.statusLog) === null || _c === void 0 ? void 0 : _c.includes(RecordingPipeline.ClientDisconnect)) &&
            ((_d = recordings.screen.statusLog) === null || _d === void 0 ? void 0 : _d.includes(RecordingPipeline.ProcessingInstructionSent));
        if (hasBothEvents &&
            recordings.screen.statusLog.indexOf(RecordingPipeline.ClientDisconnect) <
                recordings.screen.statusLog.indexOf(RecordingPipeline.ProcessingInstructionSent)) {
            hasScreenNetworkDisconnected = true;
            hasScreenNetworkReconnected = recordings.screen.statusLog.includes(RecordingPipeline.ClientReconnectAttemptSuccess);
        }
    }
    return {
        hasNetworkDisconnected: hasUserNetworkDisconnected || hasScreenNetworkDisconnected,
        hasReconnected: hasUserNetworkReconnected || hasScreenNetworkReconnected,
    };
}
function checkForMissingData(recordings) {
    var _a;
    const { user, screen } = recordings;
    const missingData = [];
    if (user && user.missingDataWarning) {
        if (((_a = user.permissions) === null || _a === void 0 ? void 0 : _a.webcam) === StepRecordingPermission.Granted &&
            user.permissions.microphone === StepRecordingPermission.Granted) {
            missingData.push('camera/mic');
        }
        else {
            missingData.push('audio');
        }
    }
    if (screen && screen.missingDataWarning) {
        missingData.push('screen');
    }
    return missingData;
}
