import { __rest } from "tslib";
import { jsx as _jsx } from "react/jsx-runtime";
import { observer } from 'mobx-react';
import { useState } from 'react';
import * as React from 'react';
import { useIntl } from 'react-intl';
import { QuestionStepType, } from '@marvelapp/user-test-creator';
import TextareaGrowing from '../../components/TextareaGrowing';
import { useAutoFocus } from '../../hooks/useAutoFocus';
import BaseQuestionRecord from './BaseQuestionRecord';
export default observer(function QuestionRecord(_a) {
    var rest = __rest(_a, []);
    const [inputRef, setInputRef] = useState(null);
    useAutoFocus(inputRef);
    const intl = useIntl();
    return (_jsx(BaseQuestionRecord, Object.assign({ renderInput: ({ value, onChange }) => (_jsx(TextareaGrowing, { "data-testid": "answer-step", minHeight: 46, onChange: (event) => onChange(event.target.value), placeholder: intl.formatMessage({ id: "p+7CMb", defaultMessage: "Enter your answer" }), size: "xl", value: value, width: "100%", ref: setInputRef })), type: QuestionStepType.Text }, rest)));
});
