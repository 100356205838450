import { __rest } from "tslib";
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { observer } from 'mobx-react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Badge as BadgePrimitive, BtwHeading, BtwLink, BtwModal, Stack, cn, } from '@marvelapp/ballpark-components';
import { formatStringOrMessage } from '@marvelapp/ballpark-copy';
import { AsteriskIcon } from '@marvelapp/ballpark-icons';
import { TextEditor } from '@marvelapp/text-editor';
import { StepUtils, TextUtils, getStepDescription, isExitStep, } from '@marvelapp/user-test-creator';
import { useRecorderState } from '../../../components/Record/RecorderContext';
import SlideInstructions from '../SlideInstructions';
import { getStepTitleCopy } from './utils';
/**
 * This is a newer version of SlideRecordContent. Currently only used in CardSortingRecorder,
 * but it'll end up replacing SlideRecordContent with the redesign.
 */
const RecordContentBase = observer(function RecordContentBase({ customDescription, customTitle, descriptionClassName, hasError, isNarrow, step, withSlideBadges = true, }) {
    return (_jsxs(Stack, { gap: "6", width: "full", children: [withSlideBadges && _jsx(Badge, { step: step, hasError: hasError }), _jsxs(Stack, { gap: "2.5", width: "full", children: [_jsx(Title, { step: step, customTitle: customTitle }), _jsx(SlideInstructions, { step: step }), _jsx(Description, { className: descriptionClassName, customDescription: customDescription, isNarrow: isNarrow, step: step })] })] }));
});
const WithModal = observer(function WithModal(props) {
    const { descriptionClassName } = props, rest = __rest(props, ["descriptionClassName"]);
    const intl = useIntl();
    const descriptionLength = TextUtils.getValueLength(getStepDescription(intl, rest.step));
    const truncateDescription = descriptionLength > 70;
    return (_jsxs(Stack, { gap: "2", width: "full", children: [_jsx(RecordContentBase, Object.assign({ descriptionClassName: cn({
                    'line-clamp-2 overflow-hidden truncate': truncateDescription,
                }, descriptionClassName) }, rest)), truncateDescription && (_jsxs(BtwModal.Root, { children: [_jsx(BtwModal.Trigger, { children: _jsx(BtwLink, { variant: "primary", size: "15", children: _jsx(FormattedMessage, { id: 'M6Ukj5', defaultMessage: 'Read more' }) }) }), _jsxs(BtwModal.Content, { children: [_jsx(BtwModal.BigHeader, { onlyChild: false, title: _jsx(Title, { step: rest.step, customTitle: rest.customTitle }) }), _jsx(BtwModal.Body, { className: "-mt-4", children: _jsx(Description, { customDescription: rest.customDescription, isNarrow: rest.isNarrow, step: rest.step }) })] })] }))] }));
});
const Title = observer(function Title(props) {
    const intl = useIntl();
    const { userTestDoc } = useRecorderState();
    const { step, customTitle } = props;
    return (_jsx(BtwHeading, { asChild: true, className: "break-words", size: "xl", weight: "bold", children: _jsx("h1", { "data-testid": "recorder-slide-title", children: customTitle ||
                formatStringOrMessage(intl, getStepTitleCopy(userTestDoc, step)) }) }));
});
const Badge = observer(function Badge(props) {
    const { step, hasError } = props;
    const { currentSlideNumber, userTestDoc, steps } = useRecorderState();
    const isRequired = StepUtils.getIsRequired(step);
    const totalSlidesNumber = steps.length;
    return (_jsxs(Stack, { align: "center", direction: "row", gap: "2", children: [!isExitStep(userTestDoc, step.uuid) && (
            // TODO: Remove the step count badge with the redesign
            _jsxs(BadgePrimitive, { "data-testid": "slide-count", variant: hasError ? 'red' : 'sky', children: [currentSlideNumber, " / ", totalSlidesNumber - 1] })), isRequired && (_jsx(BadgePrimitive, { variant: "red", "data-testid": "required-badge", leadingIcon: _jsx(AsteriskIcon, {}), children: _jsx(FormattedMessage, { id: 'Gjzr/5', defaultMessage: 'Required' }) }))] }));
});
const Description = observer(function Description(props) {
    const { customDescription, isNarrow, step, className } = props;
    const intl = useIntl();
    const description = getStepDescription(intl, step);
    const isDescriptionEmpty = TextUtils.checkIfValueIsEmpty(description);
    if (isDescriptionEmpty && !customDescription) {
        return null;
    }
    return (_jsx(_Fragment, { children: _jsx(TextEditor, { className: cn(className, '-ml-[3px]', 'w-[calc(100%+3px)]'), disabled: true, id: `${step.uuid}-${isNarrow ? 'narrow' : 'wide'}-description`, size: "15", testId: "recorder-slide-description", value: customDescription
                ? [{ type: 'p', children: [{ text: customDescription }] }]
                : description }) }));
});
export const RecordContent = Object.assign(RecordContentBase, {
    WithModal,
});
