import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, ContentLoader, Flex, Heading, Row, Text } from '@marvelapp/ui';
import Highlighter from './Highlighter';
export default function SimpleListItem({ badge, children, hasSingleAvatar, heading, highlightHeading, hoverBg = 'white', isLastChild, isLoading, onClick, px = 2, renderCollaborators, renderImage, searchWords = [], status, subheading, }) {
    return (_jsx(Row, { cursor: onClick ? 'pointer' : 'default', "data-testid": "person-row", hoverBg: hoverBg, isLastChild: isLastChild, onClick: onClick, px: px, children: _jsxs(Flex, { alignItems: "center", justifyContent: "space-between", children: [hasSingleAvatar ? (_jsxs(Flex, { alignItems: "center", flex: 1, minWidth: 0, children: [_jsx(Avatar, { isLoading: isLoading, renderImage: renderImage, status: status }), _jsx(NameAndMeta, { badge: badge, heading: heading, highlightHeading: highlightHeading, isLoading: isLoading, searchWords: searchWords, subheading: subheading })] })) : (_jsx(NameAndMeta, { heading: heading, highlightHeading: highlightHeading, isLoading: isLoading, searchWords: searchWords, subheading: subheading })), _jsxs(Flex, { alignItems: "center", ml: 2, children: [renderCollaborators && (
                        // eslint-disable-next-line
                        _jsx(Box, { borderRight: "1px solid", borderColor: "smoke", pr: 2, mr: 16, children: renderCollaborators({ isLoading }) })), children] })] }) }));
}
function Avatar({ isLoading, renderImage, status }) {
    if (isLoading) {
        return (_jsx(Box, { marginRight: 3, children: _jsx(ContentLoader, { borderRadius: 20, height: 40, kind: "medium", width: 40 }) }));
    }
    return _jsx(Box, { marginRight: 3, children: renderImage(status) });
}
function NameAndMeta({ badge, heading, highlightHeading, isLoading, searchWords, subheading, }) {
    if (isLoading) {
        return (_jsxs(Box, { flex: 1, minWidth: 0, children: [_jsx(ContentLoader, { borderRadius: 4, height: 8, kind: "medium", marginBottom: 2, width: 0.8 }), _jsx(ContentLoader, { borderRadius: 4, height: 8, kind: "medium", width: 0.4 })] }));
    }
    return (_jsxs(Box, { flex: 1, minWidth: 0, wordWrap: "break-word", children: [_jsx(Flex, { alignItems: "center", marginBottom: "4px", children: _jsx(Heading, { fontSize: 2, fontWeight: 500, lineHeight: 1.3, truncated: true, children: highlightHeading ? (_jsx(Highlighter, { searchWords: searchWords, textToHighlight: heading })) : (heading) }) }), _jsxs(Text, { "data-testid": "row-subtext", fontSize: 2, lineHeight: 1.3, truncated: true, children: [badge && `${badge} •`, ' ', _jsx(Highlighter, { searchWords: searchWords, textToHighlight: subheading })] })] }));
}
