import { __rest } from "tslib";
import { jsx as _jsx } from "react/jsx-runtime";
import { observer } from 'mobx-react';
import * as React from 'react';
import Scrollbars from 'react-custom-scrollbars';
import styled from '@marvelapp/styled';
import { Box } from '@marvelapp/ui';
import { BUILDER_RIGHT_HAND_SIDE_SLIDE_PADDING_X, BUILDER_RIGHT_HAND_SIDE_SLIDE_PADDING_Y, } from './config';
export const OneColumnSlide = observer(function OneColumnSlide(_a) {
    var { children } = _a, containerProps = __rest(_a, ["children"]);
    return (_jsx(Body, Object.assign({}, containerProps, { children: _jsx(Scrollbars, { autoHide: true, children: _jsx(BodyInner, { children: children }) }) })));
});
const Body = styled(Box).attrs({
    bg: 'background',
    borderBottomLeftRadius: 'xl',
    borderBottomRightRadius: 'xl',
}) `
  width: 100%;
  height: 100%;
`;
const BodyInner = styled(Box).attrs({
    paddingX: BUILDER_RIGHT_HAND_SIDE_SLIDE_PADDING_X,
    paddingY: BUILDER_RIGHT_HAND_SIDE_SLIDE_PADDING_Y,
}) `
  min-height: 100%;
  display: flex;
  flex-direction: column;
  width: 100%;

  flex: 1;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
`;
