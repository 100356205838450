import { __rest } from "tslib";
import { jsx as _jsx } from "react/jsx-runtime";
import { observer } from 'mobx-react';
import { useState } from 'react';
import * as React from 'react';
import { useIntl } from 'react-intl';
import { Input } from '@marvelapp/ballpark-components';
import { UI_STEPS_COPY } from '@marvelapp/ballpark-copy';
import { QuestionStepType } from '@marvelapp/user-test-creator';
import { useAutoFocus } from '../../hooks/useAutoFocus';
import BaseQuestionRecord from '../Question/BaseQuestionRecord';
export default observer(function NumericalRecord(_a) {
    var { stepResponse } = _a, rest = __rest(_a, ["stepResponse"]);
    const intl = useIntl();
    const [inputRef, setInputRef] = useState(null);
    useAutoFocus(inputRef);
    return (_jsx(BaseQuestionRecord, Object.assign({ renderInput: ({ value, onChange, error }) => (_jsx(Input, { "data-testid": "answer-step", error: error, hideArrows: true, kind: error ? 'error' : 'primary', onChange: (event) => {
                const numericalValue = parseInt(event.target.value, 10);
                if (numericalValue > 2147483647) {
                    return null;
                }
                onChange(numericalValue);
            }, onKeyDown: (e) => {
                if (e.key === '.' || e.key === '-') {
                    e.preventDefault();
                    return null;
                }
            }, placeholder: intl.formatMessage(UI_STEPS_COPY[QuestionStepType.Numerical].inputPlaceholder), size: "xl", type: "number", value: value, width: "100%", min: "0", ref: setInputRef })), stepResponse: stepResponse, type: QuestionStepType.Numerical }, rest)));
});
