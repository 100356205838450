import { NetworkStatus, gql, useLazyQuery } from '@apollo/client';
import { useServer } from '@ballpark/realtime-plugin-server';
// eslint-disable-next-line camelcase
import jwt_decode from 'jwt-decode';
import { useEffect, useMemo, useRef, useState } from 'react';
import { delay } from '@marvelapp/core';
const GET_RUS_AUTH_TOKEN = gql `
  query getRUSToken($uuid: String!) {
    rusAuthToken(uuid: $uuid)
  }
`;
const MAX_TRIES = 5;
export function useAuthToken(id) {
    const attempts = useRef(0);
    const [deleted, setDeleted] = useState(false);
    const [getToken, { data, error, refetch, networkStatus }] = useLazyQuery(GET_RUS_AUTH_TOKEN, {
        notifyOnNetworkStatusChange: true,
        errorPolicy: 'all',
    });
    useEffect(() => {
        if (!id)
            return;
        setDeleted(false);
        attempts.current = 0;
        getToken({ variables: { uuid: id } });
    }, [id, getToken]);
    useEffect(() => {
        if (deleted)
            return;
        if (!error)
            return;
        // 410 means the resource was deleted / no longer exists so don't retry
        if (error.message.includes('410')) {
            setDeleted(true);
            return;
        }
        if (attempts.current >= MAX_TRIES) {
            return;
        }
        let cancelled = false;
        delay(100 * Math.pow(2, attempts.current) + Math.random() * 50).then(() => {
            if (!cancelled) {
                attempts.current += 1;
                refetch();
            }
        });
        return () => {
            cancelled = true;
        };
    }, [error, deleted, refetch]);
    const token = data === null || data === void 0 ? void 0 : data.rusAuthToken;
    // Only show an error if we have finished retrying to load the token.
    const errored = deleted ||
        (attempts.current >= MAX_TRIES && networkStatus === NetworkStatus.error);
    return {
        token,
        loading: token === undefined && !errored,
        error: errored ? error : undefined,
        deleted,
    };
}
export var Permissions;
(function (Permissions) {
    Permissions[Permissions["Unknown"] = 0] = "Unknown";
    Permissions[Permissions["None"] = 1] = "None";
    Permissions[Permissions["Read"] = 2] = "Read";
    Permissions[Permissions["Write"] = 3] = "Write";
})(Permissions || (Permissions = {}));
export function useTokenPermission() {
    const { token } = useServer();
    return useMemo(() => {
        return getTokenPermission(token);
    }, [token]);
}
export function getTokenPermission(token) {
    if (typeof token !== 'string')
        return Permissions.Unknown;
    try {
        const { access } = jwt_decode(token);
        if (access === 'read')
            return Permissions.Read;
        if (access === 'write')
            return Permissions.Write;
        // eslint-disable-next-line no-empty
    }
    catch (err) { }
    return Permissions.Unknown;
}
