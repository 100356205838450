import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { observer } from 'mobx-react';
import * as React from 'react';
import { useWholeTestRecording } from '@marvelapp/askhub-ui/src/WholeTestRecordingContext';
import { BtwTooltip, Icon, SquareButton } from '@marvelapp/ballpark-components';
import { ARROW_DOWN_ICON, ARROW_UP_ICON } from '@marvelapp/ballpark-theme';
import { deviceDetection } from '@marvelapp/core';
import styled from '@marvelapp/styled';
import { Box } from '@marvelapp/ui';
import { ScreenerUtils, isExitStep as utcIsExitStep, } from '@marvelapp/user-test-creator';
import { usePrototypeState } from '../../components/Record/PrototypeContext';
import { useRecorderState } from '../../components/Record/RecorderContext';
import { PlayerStatus } from '../PrototypeTask/PrototypeRecord/types';
export default observer(function SlideFooter() {
    const { currentStep, userTestDoc, isPrototypeTask, isLive } = useRecorderState();
    const { playerStatus, hasReachedGoalScreenOrDoneFirstClick } = usePrototypeState();
    const isExitStep = utcIsExitStep(userTestDoc, currentStep.uuid);
    const isScreenerStep = ScreenerUtils.isScreenerStep(userTestDoc, currentStep.uuid);
    const hideNavigationOnPrototypeStep = deviceDetection.isMobile
        ? playerStatus === PlayerStatus.Recording ||
            hasReachedGoalScreenOrDoneFirstClick
        : playerStatus === PlayerStatus.Recording;
    if ((isPrototypeTask && hideNavigationOnPrototypeStep) ||
        isExitStep ||
        isScreenerStep)
        return null;
    return (_jsxs(Footer, { position: isLive ? 'absolute' : 'fixed', children: [_jsx(ForwardArrow, {}), _jsx(BackwardArrow, {})] }));
});
const ForwardArrow = observer(function ForwardArrow() {
    var _a;
    const { canGoForward, validationErrors, goForward, currentStep, userTestDoc, } = useRecorderState();
    const recording = useWholeTestRecording();
    const isOnMediaSettingsStep = currentStep.uuid === ((_a = userTestDoc.mediaSettingsStep) === null || _a === void 0 ? void 0 : _a.uuid);
    return (_jsx(BtwTooltip, { content: validationErrors, children: _jsx("div", { children: _jsx(SquareButton, { "data-testid": "next-slide-arrow", isDisabled: !canGoForward, kind: "ghost", onClick: recording && isOnMediaSettingsStep
                    ? recording.onGoForward
                    : goForward, isLoading: (recording === null || recording === void 0 ? void 0 : recording.connectionStatus) === 'connecting', size: "m", children: _jsx(Icon, { paths: ARROW_DOWN_ICON }) }) }) }));
});
const BackwardArrow = observer(function BackwardArrow() {
    const { currentSlideNumber, goBackward } = useRecorderState();
    const isFirstSlide = currentSlideNumber === 1;
    return (_jsx(SquareButton, { "data-testid": "previous-slide-arrow", isDisabled: isFirstSlide, kind: "ghost", onClick: goBackward, size: "m", children: _jsx(Icon, { paths: ARROW_UP_ICON }) }));
});
const Footer = styled(Box).attrs({
    padding: 12, // Same as the padding of the video guides unit
}) `
  bottom: 0;
  right: 0;
  gap: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 899;
`;
