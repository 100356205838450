import { __rest } from "tslib";
import { jsx as _jsx } from "react/jsx-runtime";
import { observer } from 'mobx-react';
import { useAction, useSlideUIContext } from '@marvelapp/ballpark-application';
import { CardSortingUtils, StepUtils } from '@marvelapp/user-test-creator';
import { BaseCategory } from '../BaseCategory';
import { useSortable } from '../dndUtils';
export const Category = observer(function Category(_a) {
    var { category } = _a, otherProps = __rest(_a, ["category"]);
    const { step } = useSlideUIContext();
    const { uuid } = category;
    const sortable = useSortable({ id: uuid });
    const onUpdateTitle = useAction((newTitle) => {
        if (!StepUtils.isCardSortingStep(step))
            return;
        CardSortingUtils.updateCategory(step, uuid, {
            title: newTitle,
        });
    }, [uuid, step]);
    const onDelete = useAction(() => {
        if (!StepUtils.isCardSortingStep(step))
            return;
        CardSortingUtils.removeCategory(step, uuid);
    }, [uuid, step]);
    if (!StepUtils.isCardSortingStep(step))
        return null;
    return (_jsx(BaseCategory, Object.assign({ category: category, onUpdateTitle: onUpdateTitle, onDelete: onDelete, maxCardsPerCategory: CardSortingUtils.getMaxCardsPerCategory(step) }, sortable, otherProps)));
});
