import { useEffect } from 'react';
import { deviceDetection } from '@marvelapp/core';
// focus the target when the component mounts but do not scroll to it, if the step has a long
// title or description this ensures the user reads that before scrolling to the input
export function useAutoFocus(target) {
    useEffect(() => {
        if (deviceDetection.isMobile)
            return;
        if (target) {
            target.focus({
                preventScroll: true,
            });
        }
    }, [target]);
}
