import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { NetworkStatus } from '@apollo/client';
import { observer } from 'mobx-react';
import { useCallback } from 'react';
import * as React from 'react';
import { BtwButton, BtwText, Stack, BtwVirtualizedDataTable as VirtualizedDataTable, } from '@marvelapp/ballpark-components';
import { FilterFunnelIcon, MessagesIcon } from '@marvelapp/ballpark-icons';
import { useResponsesContext } from './ResponsesContext';
export const ResponsesDataTable = observer(function ResponsesDataTable({ tableContainerRef, }) {
    const { networkStatus, table, totalResults } = useResponsesContext();
    const getItemKey = useCallback((index) => {
        const { rows } = table.getRowModel();
        const row = rows[index];
        return row.original.pk;
    }, [table]);
    const renderNoItems = useCallback(() => _jsx(NoMatchingResults, {}), []);
    if (!table)
        return null;
    if (totalResults === 0) {
        return _jsx(NoResults, {});
    }
    return (_jsx(VirtualizedDataTable, { table: table, tableContainerRef: tableContainerRef, loadingStatus: networkStatusToLoadingStatus(networkStatus), overscan: 10, estimateSize: 28 + 10 * 2, withStickyHeader: true, getItemKey: getItemKey, renderNoItems: renderNoItems }));
});
function networkStatusToLoadingStatus(networkStatus) {
    switch (networkStatus) {
        case NetworkStatus.loading:
            return 'loading';
        case NetworkStatus.refetch:
        case NetworkStatus.setVariables:
            return 'reloading';
        default:
            return 'none';
    }
}
const NoResults = observer(function NoResults() {
    return (_jsx(Stack, { align: "center", justify: "center", className: "h-full", width: "full", "data-testid": "empty-state", children: _jsx(Stack, { className: "fixed p-8", align: "center", justify: "center", gap: "4", children: _jsxs(Stack, { align: "center", gap: "2", className: "max-w-96", children: [_jsx(MessagesIcon, {}), _jsx(BtwText, { size: "base", variant: "primary", weight: "semibold", children: "No results just yet\u2026" }), _jsxs(BtwText, { size: "sm", children: ["Nobody has completed the test yet. ", _jsx("br", {}), " Come back to check later!"] })] }) }) }));
});
const NoMatchingResults = observer(function NoMatchingResults() {
    var _a;
    const { clearFilters, totalResults, table } = useResponsesContext();
    const numberOfColumns = (_a = table === null || table === void 0 ? void 0 : table.getAllFlatColumns().length) !== null && _a !== void 0 ? _a : 0;
    return (_jsx("tr", { children: _jsx("td", { colSpan: numberOfColumns, children: _jsx(Stack, { align: "center", justify: "center", 
                // Doing some funky calcs here, but it's basically doing 100vh/vw excluding
                // the paddings, borders and other elements on the page.
                className: "absolute left-0 top-0 z-10 h-[calc(100vh_-_128px)] w-[calc(100vw_-_34px)]", "data-testid": "empty-state", children: _jsxs(Stack, { className: "fixed p-8", align: "center", justify: "center", gap: "4", children: [_jsxs(Stack, { align: "center", gap: "2", className: "max-w-96", children: [_jsx(FilterFunnelIcon, {}), _jsx(BtwText, { size: "base", variant: "primary", weight: "semibold", children: "No matching results\u2026" }), _jsxs(BtwText, { size: "sm", children: [totalResults, " results hidden by filters"] })] }), _jsx(BtwButton, { onClick: clearFilters, variant: "secondary", children: "Clear filters" })] }) }) }) }));
});
