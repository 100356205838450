import querystring from 'query-string';
export function getOptimisedContainedImageUrl(originalUrl, { width, height }) {
    const parsed = querystring.parseUrl(originalUrl);
    parsed.query.dpr = window.devicePixelRatio.toString();
    parsed.query.width = width.toString();
    if (height) {
        parsed.query.height = height.toString();
    }
    parsed.query.fit = 'bounds';
    const queryStringified = querystring.stringify(parsed.query, {
        encode: false,
    });
    return `${parsed.url}?${queryStringified}`;
}
