import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { observer } from 'mobx-react';
import { FormattedMessage } from 'react-intl';
import { Button, Icon } from '@marvelapp/ballpark-components';
import { EXTERNAL_LINK_ICON } from '@marvelapp/ballpark-theme';
import styled from '@marvelapp/styled';
import { PinTL } from '@marvelapp/ui';
export default observer(function WebsiteTaskButton({ onClick, href, }) {
    return (_jsxs(Button, { as: href ? 'a' : 'button', href: href, target: href ? '_blank' : undefined, kind: "primary", paddingLeft: "38px", "data-testid": "open-website-task-link", size: "l", onClick: onClick, children: [_jsx(IconWrapper, { children: _jsx(Icon, { paths: EXTERNAL_LINK_ICON }) }), _jsx(FormattedMessage, { id: '3yp7YV', defaultMessage: 'Open link in new window' })] }));
});
const IconWrapper = styled(PinTL) `
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;

  left: -1px;
  top: -1px;

  svg {
    display: block;
  }
`;
