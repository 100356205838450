import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useHistoryState } from '@ballpark/realtime-plugin-history';
import { observer } from 'mobx-react';
import { useCallback, useState } from 'react';
import * as React from 'react';
import { useAction, useSlideUIContext } from '@marvelapp/ballpark-application';
import { Popover } from '@marvelapp/ballpark-components';
import { AsteriskIcon } from '@marvelapp/ballpark-icons';
import { StepUtils, getClassName } from '@marvelapp/user-test-creator';
import SlideControlsButton from './SlideControlsButton';
export const StepOptions = observer(function StepOptions({ onOpenChange, children, shouldShowRequiredSetting = true, }) {
    const [isOpen, setIsOpen] = useState(false);
    const { step } = useSlideUIContext();
    const isRequirable = StepUtils.isRequirable(step) && shouldShowRequiredSetting;
    const historyKey = `step-options-${step.uuid}`;
    const storeState = useCallback(() => {
        return isOpen;
    }, [isOpen]);
    const replayState = useCallback((isOpen) => {
        setIsOpen(isOpen !== null && isOpen !== void 0 ? isOpen : false);
    }, []);
    useHistoryState(historyKey, storeState, replayState);
    if (!isRequirable && !children)
        return null;
    return (_jsxs(Popover.Root, { open: isOpen, onOpenChange: (open) => {
            setIsOpen(open);
            if (onOpenChange)
                onOpenChange(open);
        }, children: [_jsx(SlideControlsButton, { as: Popover.Trigger, "data-testid": `dotmenu-toggle`, paddingLeft: "16px", isOpen: isOpen, position: "relative", width: "fit-content", children: "Options" }), _jsx(Popover.Portal, { children: _jsx(Popover.Content, { align: "end", "data-testid": `${getClassName(step)}-settings-modal`, children: _jsxs(Popover.Inner, { children: [isRequirable && _jsx(RequiredToggle, {}), children] }) }) })] }));
});
const RequiredToggle = observer(function RequiredToggle() {
    const { step } = useSlideUIContext();
    const onRequireChange = useAction(() => {
        const currentIsRequiredSetting = StepUtils.getIsRequired(step);
        StepUtils.setIsRequired(step, !currentIsRequiredSetting);
    }, [step]);
    return (_jsx(Popover.Toggle, { checked: !!StepUtils.getIsRequired(step), description: "Make sure participants complete this step before continuing", icon: _jsx(AsteriskIcon, { "data-testid": "required-icon", className: "h-2.5 w-2.5 text-red-700" }), id: "required-step-toggle", testId: `required-step-toggle`, onChange: onRequireChange, title: "Required step" }));
});
