// THIS FILE WAS AUTOGENERATED - DO NOT EDIT DIRECTLY
// RUN "yarn schema:generate"
//
/* eslint-disable */
// @ts-nocheck
'use strict';
var schema27 = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    $ref: '#/definitions/RoomEvent',
    definitions: {
        RoomEvent: {
            type: 'object',
            properties: {
                name: {
                    enum: [
                        'connected',
                        'reconnecting',
                        'signalReconnecting',
                        'reconnected',
                        'disconnected',
                        'connectionStateChanged',
                        'mediaDevicesChanged',
                        'participantConnected',
                        'participantDisconnected',
                        'trackPublished',
                        'trackSubscribed',
                        'trackSubscriptionFailed',
                        'trackUnpublished',
                        'trackUnsubscribed',
                        'trackMuted',
                        'trackUnmuted',
                        'localTrackPublished',
                        'localTrackUnpublished',
                        'localAudioSilenceDetected',
                        'participantMetadataChanged',
                        'participantNameChanged',
                        'participantPermissionsChanged',
                        'participantAttributesChanged',
                        'activeSpeakersChanged',
                        'roomMetadataChanged',
                        'connectionQualityChanged',
                        'mediaDevicesError',
                        'trackStreamStateChanged',
                        'trackSubscriptionPermissionChanged',
                        'trackSubscriptionStatusChanged',
                        'audioPlaybackChanged',
                        'videoPlaybackChanged',
                        'signalConnected',
                        'recordingStatusChanged',
                        'activeDeviceChanged',
                        'localTrackSubscribed',
                    ],
                },
            },
            required: ['name'],
            allOf: [
                {
                    if: {
                        properties: {
                            name: {
                                type: 'string',
                                const: 'connected',
                                description: 'When the connection to the server has been established',
                            },
                        },
                    },
                    then: {
                        type: 'object',
                        properties: {
                            name: {
                                type: 'string',
                                const: 'connected',
                                description: 'When the connection to the server has been established',
                            },
                            timestamp: { type: 'number' },
                        },
                        required: ['name', 'timestamp'],
                        additionalProperties: false,
                    },
                },
                {
                    if: {
                        properties: {
                            name: {
                                type: 'string',
                                const: 'reconnecting',
                                description: "When the connection to the server has been interrupted and it's attempting to reconnect.",
                            },
                        },
                    },
                    then: {
                        type: 'object',
                        properties: {
                            name: {
                                type: 'string',
                                const: 'reconnecting',
                                description: "When the connection to the server has been interrupted and it's attempting to reconnect.",
                            },
                            timestamp: { type: 'number' },
                        },
                        required: ['name', 'timestamp'],
                        additionalProperties: false,
                    },
                },
                {
                    if: {
                        properties: {
                            name: {
                                type: 'string',
                                const: 'signalReconnecting',
                                description: "When the signal connection to the server has been interrupted. This isn't noticeable to users most of the time. It will resolve with a `RoomEvent.Reconnected` once the signal connection has been re-established. If media fails additionally it an additional `RoomEvent.Reconnecting` will be emitted.",
                            },
                        },
                    },
                    then: {
                        type: 'object',
                        properties: {
                            name: {
                                type: 'string',
                                const: 'signalReconnecting',
                                description: "When the signal connection to the server has been interrupted. This isn't noticeable to users most of the time. It will resolve with a `RoomEvent.Reconnected` once the signal connection has been re-established. If media fails additionally it an additional `RoomEvent.Reconnecting` will be emitted.",
                            },
                            timestamp: { type: 'number' },
                        },
                        required: ['name', 'timestamp'],
                        additionalProperties: false,
                    },
                },
                {
                    if: {
                        properties: {
                            name: {
                                type: 'string',
                                const: 'reconnected',
                                description: 'Fires when a reconnection has been successful.',
                            },
                        },
                    },
                    then: {
                        type: 'object',
                        properties: {
                            name: {
                                type: 'string',
                                const: 'reconnected',
                                description: 'Fires when a reconnection has been successful.',
                            },
                            timestamp: { type: 'number' },
                        },
                        required: ['name', 'timestamp'],
                        additionalProperties: false,
                    },
                },
                {
                    if: {
                        properties: {
                            name: {
                                type: 'string',
                                const: 'disconnected',
                                description: 'When disconnected from room. This fires when room.disconnect() is called or when an unrecoverable connection issue had occured.\n\nDisconnectReason can be used to determine why the participant was disconnected. Notable reasons are\n- DUPLICATE_IDENTITY: another client with the same identity has joined the room\n- PARTICIPANT_REMOVED: participant was removed by RemoveParticipant API\n- ROOM_DELETED: the room has ended via DeleteRoom API\n\nargs: ([[DisconnectReason]])',
                            },
                        },
                    },
                    then: {
                        type: 'object',
                        additionalProperties: false,
                        properties: {
                            reason: { $ref: '#/definitions/DisconnectReason' },
                            name: {
                                type: 'string',
                                const: 'disconnected',
                                description: 'When disconnected from room. This fires when room.disconnect() is called or when an unrecoverable connection issue had occured.\n\nDisconnectReason can be used to determine why the participant was disconnected. Notable reasons are\n- DUPLICATE_IDENTITY: another client with the same identity has joined the room\n- PARTICIPANT_REMOVED: participant was removed by RemoveParticipant API\n- ROOM_DELETED: the room has ended via DeleteRoom API\n\nargs: ([[DisconnectReason]])',
                            },
                            timestamp: { type: 'number' },
                        },
                        required: ['name', 'timestamp'],
                    },
                },
                {
                    if: {
                        properties: {
                            name: {
                                type: 'string',
                                const: 'connectionStateChanged',
                                description: 'Whenever the connection state of the room changes\n\nargs: ([[ConnectionState]])',
                            },
                        },
                    },
                    then: {
                        type: 'object',
                        additionalProperties: false,
                        properties: {
                            state: { $ref: '#/definitions/ConnectionState' },
                            name: {
                                type: 'string',
                                const: 'connectionStateChanged',
                                description: 'Whenever the connection state of the room changes\n\nargs: ([[ConnectionState]])',
                            },
                            timestamp: { type: 'number' },
                        },
                        required: ['name', 'state', 'timestamp'],
                    },
                },
                {
                    if: {
                        properties: {
                            name: {
                                type: 'string',
                                const: 'mediaDevicesChanged',
                                description: 'When input or output devices on the machine have changed.',
                            },
                        },
                    },
                    then: {
                        type: 'object',
                        properties: {
                            name: {
                                type: 'string',
                                const: 'mediaDevicesChanged',
                                description: 'When input or output devices on the machine have changed.',
                            },
                            timestamp: { type: 'number' },
                        },
                        required: ['name', 'timestamp'],
                        additionalProperties: false,
                    },
                },
                {
                    if: {
                        properties: {
                            name: {
                                type: 'string',
                                const: 'participantConnected',
                                description: 'When a [[RemoteParticipant]] joins *after* the local participant. It will not emit events for participants that are already in the room\n\nargs: ([[RemoteParticipant]])',
                            },
                        },
                    },
                    then: {
                        type: 'object',
                        additionalProperties: false,
                        properties: {
                            participant: {
                                type: 'object',
                                properties: {
                                    identity: { type: 'string' },
                                    sid: { type: 'string' },
                                    permissions: {
                                        type: 'object',
                                        properties: {
                                            canSubscribe: { type: 'boolean' },
                                            canPublish: { type: 'boolean' },
                                            canPublishData: { type: 'boolean' },
                                            hidden: { type: 'boolean' },
                                            canUpdateMetadata: { type: 'boolean' },
                                            canSubscribeMetrics: { type: 'boolean' },
                                        },
                                        required: [
                                            'canSubscribe',
                                            'canPublish',
                                            'canPublishData',
                                            'hidden',
                                            'canUpdateMetadata',
                                            'canSubscribeMetrics',
                                        ],
                                        additionalProperties: false,
                                    },
                                },
                                required: ['identity', 'sid'],
                                additionalProperties: false,
                            },
                            name: {
                                type: 'string',
                                const: 'participantConnected',
                                description: 'When a [[RemoteParticipant]] joins *after* the local participant. It will not emit events for participants that are already in the room\n\nargs: ([[RemoteParticipant]])',
                            },
                            timestamp: { type: 'number' },
                        },
                        required: ['name', 'participant', 'timestamp'],
                    },
                },
                {
                    if: {
                        properties: {
                            name: {
                                type: 'string',
                                const: 'participantDisconnected',
                                description: 'When a [[RemoteParticipant]] leaves *after* the local participant has joined.\n\nargs: ([[RemoteParticipant]])',
                            },
                        },
                    },
                    then: {
                        type: 'object',
                        additionalProperties: false,
                        properties: {
                            participant: {
                                type: 'object',
                                properties: {
                                    identity: { type: 'string' },
                                    sid: { type: 'string' },
                                    permissions: {
                                        type: 'object',
                                        properties: {
                                            canSubscribe: { type: 'boolean' },
                                            canPublish: { type: 'boolean' },
                                            canPublishData: { type: 'boolean' },
                                            hidden: { type: 'boolean' },
                                            canUpdateMetadata: { type: 'boolean' },
                                            canSubscribeMetrics: { type: 'boolean' },
                                        },
                                        required: [
                                            'canSubscribe',
                                            'canPublish',
                                            'canPublishData',
                                            'hidden',
                                            'canUpdateMetadata',
                                            'canSubscribeMetrics',
                                        ],
                                        additionalProperties: false,
                                    },
                                },
                                required: ['identity', 'sid'],
                                additionalProperties: false,
                            },
                            name: {
                                type: 'string',
                                const: 'participantDisconnected',
                                description: 'When a [[RemoteParticipant]] leaves *after* the local participant has joined.\n\nargs: ([[RemoteParticipant]])',
                            },
                            timestamp: { type: 'number' },
                        },
                        required: ['name', 'participant', 'timestamp'],
                    },
                },
                {
                    if: {
                        properties: {
                            name: {
                                type: 'string',
                                const: 'trackPublished',
                                description: "When a new track is published to room *after* the local participant has joined. It will not fire for tracks that are already published.\n\nA track published doesn't mean the participant has subscribed to it. It's simply reflecting the state of the room.\n\nargs: ([[RemoteTrackPublication]], [[RemoteParticipant]])",
                            },
                        },
                    },
                    then: {
                        type: 'object',
                        additionalProperties: false,
                        properties: {
                            track: {
                                type: 'object',
                                properties: {
                                    sid: { type: 'string' },
                                    source: { $ref: '#/definitions/Track.Source' },
                                    mimeType: { type: 'string' },
                                    dimensions: {
                                        type: 'object',
                                        properties: {
                                            width: { type: 'number' },
                                            height: { type: 'number' },
                                        },
                                        required: ['width', 'height'],
                                        additionalProperties: false,
                                    },
                                },
                                required: ['sid', 'source'],
                                additionalProperties: false,
                            },
                            participant: {
                                type: 'object',
                                properties: {
                                    identity: { type: 'string' },
                                    sid: { type: 'string' },
                                    permissions: {
                                        type: 'object',
                                        properties: {
                                            canSubscribe: { type: 'boolean' },
                                            canPublish: { type: 'boolean' },
                                            canPublishData: { type: 'boolean' },
                                            hidden: { type: 'boolean' },
                                            canUpdateMetadata: { type: 'boolean' },
                                            canSubscribeMetrics: { type: 'boolean' },
                                        },
                                        required: [
                                            'canSubscribe',
                                            'canPublish',
                                            'canPublishData',
                                            'hidden',
                                            'canUpdateMetadata',
                                            'canSubscribeMetrics',
                                        ],
                                        additionalProperties: false,
                                    },
                                },
                                required: ['identity', 'sid'],
                                additionalProperties: false,
                            },
                            name: {
                                type: 'string',
                                const: 'trackPublished',
                                description: "When a new track is published to room *after* the local participant has joined. It will not fire for tracks that are already published.\n\nA track published doesn't mean the participant has subscribed to it. It's simply reflecting the state of the room.\n\nargs: ([[RemoteTrackPublication]], [[RemoteParticipant]])",
                            },
                            timestamp: { type: 'number' },
                        },
                        required: ['name', 'participant', 'timestamp', 'track'],
                    },
                },
                {
                    if: {
                        properties: {
                            name: {
                                type: 'string',
                                const: 'trackSubscribed',
                                description: 'The [[LocalParticipant]] has subscribed to a new track. This event will **always** fire as long as new tracks are ready for use.\n\nargs: ([[RemoteTrack]], [[RemoteTrackPublication]], [[RemoteParticipant]])',
                            },
                        },
                    },
                    then: {
                        type: 'object',
                        additionalProperties: false,
                        properties: {
                            track: {
                                type: 'object',
                                properties: {
                                    sid: { type: 'string' },
                                    source: { $ref: '#/definitions/Track.Source' },
                                    mimeType: { type: 'string' },
                                    dimensions: {
                                        type: 'object',
                                        properties: {
                                            width: { type: 'number' },
                                            height: { type: 'number' },
                                        },
                                        required: ['width', 'height'],
                                        additionalProperties: false,
                                    },
                                },
                                required: ['sid', 'source'],
                                additionalProperties: false,
                            },
                            participant: {
                                type: 'object',
                                properties: {
                                    identity: { type: 'string' },
                                    sid: { type: 'string' },
                                    permissions: {
                                        type: 'object',
                                        properties: {
                                            canSubscribe: { type: 'boolean' },
                                            canPublish: { type: 'boolean' },
                                            canPublishData: { type: 'boolean' },
                                            hidden: { type: 'boolean' },
                                            canUpdateMetadata: { type: 'boolean' },
                                            canSubscribeMetrics: { type: 'boolean' },
                                        },
                                        required: [
                                            'canSubscribe',
                                            'canPublish',
                                            'canPublishData',
                                            'hidden',
                                            'canUpdateMetadata',
                                            'canSubscribeMetrics',
                                        ],
                                        additionalProperties: false,
                                    },
                                },
                                required: ['identity', 'sid'],
                                additionalProperties: false,
                            },
                            name: {
                                type: 'string',
                                const: 'trackSubscribed',
                                description: 'The [[LocalParticipant]] has subscribed to a new track. This event will **always** fire as long as new tracks are ready for use.\n\nargs: ([[RemoteTrack]], [[RemoteTrackPublication]], [[RemoteParticipant]])',
                            },
                            timestamp: { type: 'number' },
                        },
                        required: ['name', 'participant', 'timestamp', 'track'],
                    },
                },
                {
                    if: {
                        properties: {
                            name: {
                                type: 'string',
                                const: 'trackSubscriptionFailed',
                                description: 'Could not subscribe to a track\n\nargs: (track sid, [[RemoteParticipant]])',
                            },
                        },
                    },
                    then: {
                        type: 'object',
                        additionalProperties: false,
                        properties: {
                            track: {
                                type: 'object',
                                properties: { sid: { type: 'string' } },
                                required: ['sid'],
                                additionalProperties: false,
                            },
                            reason: { $ref: '#/definitions/SubscriptionError' },
                            participant: {
                                type: 'object',
                                properties: {
                                    identity: { type: 'string' },
                                    sid: { type: 'string' },
                                    permissions: {
                                        type: 'object',
                                        properties: {
                                            canSubscribe: { type: 'boolean' },
                                            canPublish: { type: 'boolean' },
                                            canPublishData: { type: 'boolean' },
                                            hidden: { type: 'boolean' },
                                            canUpdateMetadata: { type: 'boolean' },
                                            canSubscribeMetrics: { type: 'boolean' },
                                        },
                                        required: [
                                            'canSubscribe',
                                            'canPublish',
                                            'canPublishData',
                                            'hidden',
                                            'canUpdateMetadata',
                                            'canSubscribeMetrics',
                                        ],
                                        additionalProperties: false,
                                    },
                                },
                                required: ['identity', 'sid'],
                                additionalProperties: false,
                            },
                            name: {
                                type: 'string',
                                const: 'trackSubscriptionFailed',
                                description: 'Could not subscribe to a track\n\nargs: (track sid, [[RemoteParticipant]])',
                            },
                            timestamp: { type: 'number' },
                        },
                        required: ['name', 'participant', 'timestamp', 'track'],
                    },
                },
                {
                    if: {
                        properties: {
                            name: {
                                type: 'string',
                                const: 'trackUnpublished',
                                description: 'A [[RemoteParticipant]] has unpublished a track\n\nargs: ([[RemoteTrackPublication]], [[RemoteParticipant]])',
                            },
                        },
                    },
                    then: {
                        type: 'object',
                        additionalProperties: false,
                        properties: {
                            track: {
                                type: 'object',
                                properties: {
                                    sid: { type: 'string' },
                                    source: { $ref: '#/definitions/Track.Source' },
                                    mimeType: { type: 'string' },
                                    dimensions: {
                                        type: 'object',
                                        properties: {
                                            width: { type: 'number' },
                                            height: { type: 'number' },
                                        },
                                        required: ['width', 'height'],
                                        additionalProperties: false,
                                    },
                                },
                                required: ['sid', 'source'],
                                additionalProperties: false,
                            },
                            participant: {
                                type: 'object',
                                properties: {
                                    identity: { type: 'string' },
                                    sid: { type: 'string' },
                                    permissions: {
                                        type: 'object',
                                        properties: {
                                            canSubscribe: { type: 'boolean' },
                                            canPublish: { type: 'boolean' },
                                            canPublishData: { type: 'boolean' },
                                            hidden: { type: 'boolean' },
                                            canUpdateMetadata: { type: 'boolean' },
                                            canSubscribeMetrics: { type: 'boolean' },
                                        },
                                        required: [
                                            'canSubscribe',
                                            'canPublish',
                                            'canPublishData',
                                            'hidden',
                                            'canUpdateMetadata',
                                            'canSubscribeMetrics',
                                        ],
                                        additionalProperties: false,
                                    },
                                },
                                required: ['identity', 'sid'],
                                additionalProperties: false,
                            },
                            name: {
                                type: 'string',
                                const: 'trackUnpublished',
                                description: 'A [[RemoteParticipant]] has unpublished a track\n\nargs: ([[RemoteTrackPublication]], [[RemoteParticipant]])',
                            },
                            timestamp: { type: 'number' },
                        },
                        required: ['name', 'participant', 'timestamp', 'track'],
                    },
                },
                {
                    if: {
                        properties: {
                            name: {
                                type: 'string',
                                const: 'trackUnsubscribed',
                                description: 'A subscribed track is no longer available. Clients should listen to this event and ensure they detach tracks.\n\nargs: ([[Track]], [[RemoteTrackPublication]], [[RemoteParticipant]])',
                            },
                        },
                    },
                    then: {
                        type: 'object',
                        additionalProperties: false,
                        properties: {
                            track: {
                                type: 'object',
                                properties: {
                                    sid: { type: 'string' },
                                    source: { $ref: '#/definitions/Track.Source' },
                                    mimeType: { type: 'string' },
                                    dimensions: {
                                        type: 'object',
                                        properties: {
                                            width: { type: 'number' },
                                            height: { type: 'number' },
                                        },
                                        required: ['width', 'height'],
                                        additionalProperties: false,
                                    },
                                },
                                required: ['sid', 'source'],
                                additionalProperties: false,
                            },
                            participant: {
                                type: 'object',
                                properties: {
                                    identity: { type: 'string' },
                                    sid: { type: 'string' },
                                    permissions: {
                                        type: 'object',
                                        properties: {
                                            canSubscribe: { type: 'boolean' },
                                            canPublish: { type: 'boolean' },
                                            canPublishData: { type: 'boolean' },
                                            hidden: { type: 'boolean' },
                                            canUpdateMetadata: { type: 'boolean' },
                                            canSubscribeMetrics: { type: 'boolean' },
                                        },
                                        required: [
                                            'canSubscribe',
                                            'canPublish',
                                            'canPublishData',
                                            'hidden',
                                            'canUpdateMetadata',
                                            'canSubscribeMetrics',
                                        ],
                                        additionalProperties: false,
                                    },
                                },
                                required: ['identity', 'sid'],
                                additionalProperties: false,
                            },
                            name: {
                                type: 'string',
                                const: 'trackUnsubscribed',
                                description: 'A subscribed track is no longer available. Clients should listen to this event and ensure they detach tracks.\n\nargs: ([[Track]], [[RemoteTrackPublication]], [[RemoteParticipant]])',
                            },
                            timestamp: { type: 'number' },
                        },
                        required: ['name', 'participant', 'timestamp', 'track'],
                    },
                },
                {
                    if: {
                        properties: {
                            name: {
                                type: 'string',
                                const: 'trackMuted',
                                description: 'A track that was muted, fires on both [[RemoteParticipant]]s and [[LocalParticipant]]\n\nargs: ([[TrackPublication]], [[Participant]])',
                            },
                        },
                    },
                    then: {
                        type: 'object',
                        additionalProperties: false,
                        properties: {
                            track: {
                                type: 'object',
                                properties: {
                                    sid: { type: 'string' },
                                    source: { $ref: '#/definitions/Track.Source' },
                                    mimeType: { type: 'string' },
                                    dimensions: {
                                        type: 'object',
                                        properties: {
                                            width: { type: 'number' },
                                            height: { type: 'number' },
                                        },
                                        required: ['width', 'height'],
                                        additionalProperties: false,
                                    },
                                },
                                required: ['sid', 'source'],
                                additionalProperties: false,
                            },
                            participant: {
                                type: 'object',
                                properties: {
                                    identity: { type: 'string' },
                                    sid: { type: 'string' },
                                    permissions: {
                                        type: 'object',
                                        properties: {
                                            canSubscribe: { type: 'boolean' },
                                            canPublish: { type: 'boolean' },
                                            canPublishData: { type: 'boolean' },
                                            hidden: { type: 'boolean' },
                                            canUpdateMetadata: { type: 'boolean' },
                                            canSubscribeMetrics: { type: 'boolean' },
                                        },
                                        required: [
                                            'canSubscribe',
                                            'canPublish',
                                            'canPublishData',
                                            'hidden',
                                            'canUpdateMetadata',
                                            'canSubscribeMetrics',
                                        ],
                                        additionalProperties: false,
                                    },
                                },
                                required: ['identity', 'sid'],
                                additionalProperties: false,
                            },
                            name: {
                                type: 'string',
                                const: 'trackMuted',
                                description: 'A track that was muted, fires on both [[RemoteParticipant]]s and [[LocalParticipant]]\n\nargs: ([[TrackPublication]], [[Participant]])',
                            },
                            timestamp: { type: 'number' },
                        },
                        required: ['name', 'participant', 'timestamp', 'track'],
                    },
                },
                {
                    if: {
                        properties: {
                            name: {
                                type: 'string',
                                const: 'trackUnmuted',
                                description: 'A track that was unmuted, fires on both [[RemoteParticipant]]s and [[LocalParticipant]]\n\nargs: ([[TrackPublication]], [[Participant]])',
                            },
                        },
                    },
                    then: {
                        type: 'object',
                        additionalProperties: false,
                        properties: {
                            track: {
                                type: 'object',
                                properties: {
                                    sid: { type: 'string' },
                                    source: { $ref: '#/definitions/Track.Source' },
                                    mimeType: { type: 'string' },
                                    dimensions: {
                                        type: 'object',
                                        properties: {
                                            width: { type: 'number' },
                                            height: { type: 'number' },
                                        },
                                        required: ['width', 'height'],
                                        additionalProperties: false,
                                    },
                                },
                                required: ['sid', 'source'],
                                additionalProperties: false,
                            },
                            participant: {
                                type: 'object',
                                properties: {
                                    identity: { type: 'string' },
                                    sid: { type: 'string' },
                                    permissions: {
                                        type: 'object',
                                        properties: {
                                            canSubscribe: { type: 'boolean' },
                                            canPublish: { type: 'boolean' },
                                            canPublishData: { type: 'boolean' },
                                            hidden: { type: 'boolean' },
                                            canUpdateMetadata: { type: 'boolean' },
                                            canSubscribeMetrics: { type: 'boolean' },
                                        },
                                        required: [
                                            'canSubscribe',
                                            'canPublish',
                                            'canPublishData',
                                            'hidden',
                                            'canUpdateMetadata',
                                            'canSubscribeMetrics',
                                        ],
                                        additionalProperties: false,
                                    },
                                },
                                required: ['identity', 'sid'],
                                additionalProperties: false,
                            },
                            name: {
                                type: 'string',
                                const: 'trackUnmuted',
                                description: 'A track that was unmuted, fires on both [[RemoteParticipant]]s and [[LocalParticipant]]\n\nargs: ([[TrackPublication]], [[Participant]])',
                            },
                            timestamp: { type: 'number' },
                        },
                        required: ['name', 'participant', 'timestamp', 'track'],
                    },
                },
                {
                    if: {
                        properties: {
                            name: {
                                type: 'string',
                                const: 'localTrackPublished',
                                description: 'A local track was published successfully. This event is helpful to know when to update your local UI with the newly published track.\n\nargs: ([[LocalTrackPublication]], [[LocalParticipant]])',
                            },
                        },
                    },
                    then: {
                        type: 'object',
                        additionalProperties: false,
                        properties: {
                            track: {
                                type: 'object',
                                properties: {
                                    sid: { type: 'string' },
                                    source: { $ref: '#/definitions/Track.Source' },
                                    mimeType: { type: 'string' },
                                    dimensions: {
                                        type: 'object',
                                        properties: {
                                            width: { type: 'number' },
                                            height: { type: 'number' },
                                        },
                                        required: ['width', 'height'],
                                        additionalProperties: false,
                                    },
                                },
                                required: ['sid', 'source'],
                                additionalProperties: false,
                            },
                            participant: {
                                type: 'object',
                                properties: {
                                    identity: { type: 'string' },
                                    sid: { type: 'string' },
                                    permissions: {
                                        type: 'object',
                                        properties: {
                                            canSubscribe: { type: 'boolean' },
                                            canPublish: { type: 'boolean' },
                                            canPublishData: { type: 'boolean' },
                                            hidden: { type: 'boolean' },
                                            canUpdateMetadata: { type: 'boolean' },
                                            canSubscribeMetrics: { type: 'boolean' },
                                        },
                                        required: [
                                            'canSubscribe',
                                            'canPublish',
                                            'canPublishData',
                                            'hidden',
                                            'canUpdateMetadata',
                                            'canSubscribeMetrics',
                                        ],
                                        additionalProperties: false,
                                    },
                                },
                                required: ['identity', 'sid'],
                                additionalProperties: false,
                            },
                            name: {
                                type: 'string',
                                const: 'localTrackPublished',
                                description: 'A local track was published successfully. This event is helpful to know when to update your local UI with the newly published track.\n\nargs: ([[LocalTrackPublication]], [[LocalParticipant]])',
                            },
                            timestamp: { type: 'number' },
                        },
                        required: ['name', 'participant', 'timestamp', 'track'],
                    },
                },
                {
                    if: {
                        properties: {
                            name: {
                                type: 'string',
                                const: 'localTrackUnpublished',
                                description: 'A local track was unpublished. This event is helpful to know when to remove the local track from your UI.\n\nWhen a user stops sharing their screen by pressing "End" on the browser UI, this event will also fire.\n\nargs: ([[LocalTrackPublication]], [[LocalParticipant]])',
                            },
                        },
                    },
                    then: {
                        type: 'object',
                        additionalProperties: false,
                        properties: {
                            track: {
                                type: 'object',
                                properties: {
                                    sid: { type: 'string' },
                                    source: { $ref: '#/definitions/Track.Source' },
                                    mimeType: { type: 'string' },
                                    dimensions: {
                                        type: 'object',
                                        properties: {
                                            width: { type: 'number' },
                                            height: { type: 'number' },
                                        },
                                        required: ['width', 'height'],
                                        additionalProperties: false,
                                    },
                                },
                                required: ['sid', 'source'],
                                additionalProperties: false,
                            },
                            participant: {
                                type: 'object',
                                properties: {
                                    identity: { type: 'string' },
                                    sid: { type: 'string' },
                                    permissions: {
                                        type: 'object',
                                        properties: {
                                            canSubscribe: { type: 'boolean' },
                                            canPublish: { type: 'boolean' },
                                            canPublishData: { type: 'boolean' },
                                            hidden: { type: 'boolean' },
                                            canUpdateMetadata: { type: 'boolean' },
                                            canSubscribeMetrics: { type: 'boolean' },
                                        },
                                        required: [
                                            'canSubscribe',
                                            'canPublish',
                                            'canPublishData',
                                            'hidden',
                                            'canUpdateMetadata',
                                            'canSubscribeMetrics',
                                        ],
                                        additionalProperties: false,
                                    },
                                },
                                required: ['identity', 'sid'],
                                additionalProperties: false,
                            },
                            name: {
                                type: 'string',
                                const: 'localTrackUnpublished',
                                description: 'A local track was unpublished. This event is helpful to know when to remove the local track from your UI.\n\nWhen a user stops sharing their screen by pressing "End" on the browser UI, this event will also fire.\n\nargs: ([[LocalTrackPublication]], [[LocalParticipant]])',
                            },
                            timestamp: { type: 'number' },
                        },
                        required: ['name', 'participant', 'timestamp', 'track'],
                    },
                },
                {
                    if: {
                        properties: {
                            name: {
                                type: 'string',
                                const: 'localAudioSilenceDetected',
                                description: 'When a local audio track is published the SDK checks whether there is complete silence on that track and emits the LocalAudioSilenceDetected event in that case. This allows for applications to show UI informing users that they might have to reset their audio hardware or check for proper device connectivity.',
                            },
                        },
                    },
                    then: {
                        type: 'object',
                        additionalProperties: false,
                        properties: {
                            track: {
                                type: 'object',
                                properties: {
                                    sid: { type: 'string' },
                                    source: { $ref: '#/definitions/Track.Source' },
                                    mimeType: { type: 'string' },
                                    dimensions: {
                                        type: 'object',
                                        properties: {
                                            width: { type: 'number' },
                                            height: { type: 'number' },
                                        },
                                        required: ['width', 'height'],
                                        additionalProperties: false,
                                    },
                                },
                                required: ['sid', 'source'],
                                additionalProperties: false,
                            },
                            name: {
                                type: 'string',
                                const: 'localAudioSilenceDetected',
                                description: 'When a local audio track is published the SDK checks whether there is complete silence on that track and emits the LocalAudioSilenceDetected event in that case. This allows for applications to show UI informing users that they might have to reset their audio hardware or check for proper device connectivity.',
                            },
                            timestamp: { type: 'number' },
                        },
                        required: ['name', 'timestamp', 'track'],
                    },
                },
                {
                    if: {
                        properties: {
                            name: {
                                type: 'string',
                                const: 'participantMetadataChanged',
                                description: "Participant metadata is a simple way for app-specific state to be pushed to all users. When RoomService.UpdateParticipantMetadata is called to change a participant's state, *all*  participants in the room will fire this event.\n\nargs: (prevMetadata: string, [[Participant]])",
                            },
                        },
                    },
                    then: {
                        type: 'object',
                        additionalProperties: false,
                        properties: {
                            metadata: { type: 'string' },
                            participant: {
                                type: 'object',
                                properties: {
                                    identity: { type: 'string' },
                                    sid: { type: 'string' },
                                    permissions: {
                                        type: 'object',
                                        properties: {
                                            canSubscribe: { type: 'boolean' },
                                            canPublish: { type: 'boolean' },
                                            canPublishData: { type: 'boolean' },
                                            hidden: { type: 'boolean' },
                                            canUpdateMetadata: { type: 'boolean' },
                                            canSubscribeMetrics: { type: 'boolean' },
                                        },
                                        required: [
                                            'canSubscribe',
                                            'canPublish',
                                            'canPublishData',
                                            'hidden',
                                            'canUpdateMetadata',
                                            'canSubscribeMetrics',
                                        ],
                                        additionalProperties: false,
                                    },
                                },
                                required: ['identity', 'sid'],
                                additionalProperties: false,
                            },
                            name: {
                                type: 'string',
                                const: 'participantMetadataChanged',
                                description: "Participant metadata is a simple way for app-specific state to be pushed to all users. When RoomService.UpdateParticipantMetadata is called to change a participant's state, *all*  participants in the room will fire this event.\n\nargs: (prevMetadata: string, [[Participant]])",
                            },
                            timestamp: { type: 'number' },
                        },
                        required: ['name', 'participant', 'timestamp'],
                    },
                },
                {
                    if: {
                        properties: {
                            name: {
                                type: 'string',
                                const: 'participantNameChanged',
                                description: "Participant's display name changed\n\nargs: (name: string, [[Participant]])",
                            },
                        },
                    },
                    then: {
                        type: 'object',
                        additionalProperties: false,
                        properties: {
                            newName: { type: 'string' },
                            participant: {
                                type: 'object',
                                properties: {
                                    identity: { type: 'string' },
                                    sid: { type: 'string' },
                                    permissions: {
                                        type: 'object',
                                        properties: {
                                            canSubscribe: { type: 'boolean' },
                                            canPublish: { type: 'boolean' },
                                            canPublishData: { type: 'boolean' },
                                            hidden: { type: 'boolean' },
                                            canUpdateMetadata: { type: 'boolean' },
                                            canSubscribeMetrics: { type: 'boolean' },
                                        },
                                        required: [
                                            'canSubscribe',
                                            'canPublish',
                                            'canPublishData',
                                            'hidden',
                                            'canUpdateMetadata',
                                            'canSubscribeMetrics',
                                        ],
                                        additionalProperties: false,
                                    },
                                },
                                required: ['identity', 'sid'],
                                additionalProperties: false,
                            },
                            name: {
                                type: 'string',
                                const: 'participantNameChanged',
                                description: "Participant's display name changed\n\nargs: (name: string, [[Participant]])",
                            },
                            timestamp: { type: 'number' },
                        },
                        required: ['name', 'newName', 'participant', 'timestamp'],
                    },
                },
                {
                    if: {
                        properties: {
                            name: {
                                type: 'string',
                                const: 'participantPermissionsChanged',
                                description: "A participant's permission has changed. Currently only fired on LocalParticipant. args: (prevPermissions: [[ParticipantPermission]], participant: [[Participant]])",
                            },
                        },
                    },
                    then: {
                        type: 'object',
                        additionalProperties: false,
                        properties: {
                            prevPermissions: {
                                type: 'object',
                                properties: {
                                    canSubscribe: { type: 'boolean' },
                                    canPublish: { type: 'boolean' },
                                    canPublishData: { type: 'boolean' },
                                    hidden: { type: 'boolean' },
                                    canUpdateMetadata: { type: 'boolean' },
                                    canSubscribeMetrics: { type: 'boolean' },
                                },
                                required: [
                                    'canSubscribe',
                                    'canPublish',
                                    'canPublishData',
                                    'hidden',
                                    'canUpdateMetadata',
                                    'canSubscribeMetrics',
                                ],
                                additionalProperties: false,
                            },
                            participant: {
                                type: 'object',
                                properties: {
                                    identity: { type: 'string' },
                                    sid: { type: 'string' },
                                    permissions: {
                                        type: 'object',
                                        properties: {
                                            canSubscribe: { type: 'boolean' },
                                            canPublish: { type: 'boolean' },
                                            canPublishData: { type: 'boolean' },
                                            hidden: { type: 'boolean' },
                                            canUpdateMetadata: { type: 'boolean' },
                                            canSubscribeMetrics: { type: 'boolean' },
                                        },
                                        required: [
                                            'canSubscribe',
                                            'canPublish',
                                            'canPublishData',
                                            'hidden',
                                            'canUpdateMetadata',
                                            'canSubscribeMetrics',
                                        ],
                                        additionalProperties: false,
                                    },
                                },
                                required: ['identity', 'sid'],
                                additionalProperties: false,
                            },
                            name: {
                                type: 'string',
                                const: 'participantPermissionsChanged',
                                description: "A participant's permission has changed. Currently only fired on LocalParticipant. args: (prevPermissions: [[ParticipantPermission]], participant: [[Participant]])",
                            },
                            timestamp: { type: 'number' },
                        },
                        required: ['name', 'participant', 'timestamp'],
                    },
                },
                {
                    if: {
                        properties: {
                            name: {
                                type: 'string',
                                const: 'participantAttributesChanged',
                                description: "Participant attributes is an app-specific key value state to be pushed to all users. When a participant's attributes changed, this event will be emitted with the changed attributes and the participant args: (changedAttributes: [[Record<string, string]], participant: [[Participant]])",
                            },
                        },
                    },
                    then: {
                        type: 'object',
                        additionalProperties: false,
                        properties: {
                            changedAttributes: {
                                type: 'object',
                                additionalProperties: { type: 'string' },
                            },
                            participant: {
                                type: 'object',
                                properties: {
                                    identity: { type: 'string' },
                                    sid: { type: 'string' },
                                    permissions: {
                                        type: 'object',
                                        properties: {
                                            canSubscribe: { type: 'boolean' },
                                            canPublish: { type: 'boolean' },
                                            canPublishData: { type: 'boolean' },
                                            hidden: { type: 'boolean' },
                                            canUpdateMetadata: { type: 'boolean' },
                                            canSubscribeMetrics: { type: 'boolean' },
                                        },
                                        required: [
                                            'canSubscribe',
                                            'canPublish',
                                            'canPublishData',
                                            'hidden',
                                            'canUpdateMetadata',
                                            'canSubscribeMetrics',
                                        ],
                                        additionalProperties: false,
                                    },
                                },
                                required: ['identity', 'sid'],
                                additionalProperties: false,
                            },
                            name: {
                                type: 'string',
                                const: 'participantAttributesChanged',
                                description: "Participant attributes is an app-specific key value state to be pushed to all users. When a participant's attributes changed, this event will be emitted with the changed attributes and the participant args: (changedAttributes: [[Record<string, string]], participant: [[Participant]])",
                            },
                            timestamp: { type: 'number' },
                        },
                        required: ['changedAttributes', 'name', 'participant', 'timestamp'],
                    },
                },
                {
                    if: {
                        properties: {
                            name: {
                                type: 'string',
                                const: 'activeSpeakersChanged',
                                description: 'Active speakers changed. List of speakers are ordered by their audio level. loudest speakers first. This will include the LocalParticipant too.\n\nSpeaker updates are sent only to the publishing participant and their subscribers.\n\nargs: (Array<[[Participant]]>)',
                            },
                        },
                    },
                    then: {
                        type: 'object',
                        additionalProperties: false,
                        properties: {
                            speakers: {
                                type: 'array',
                                items: {
                                    type: 'object',
                                    properties: {
                                        identity: { type: 'string' },
                                        sid: { type: 'string' },
                                        permissions: {
                                            type: 'object',
                                            properties: {
                                                canSubscribe: { type: 'boolean' },
                                                canPublish: { type: 'boolean' },
                                                canPublishData: { type: 'boolean' },
                                                hidden: { type: 'boolean' },
                                                canUpdateMetadata: { type: 'boolean' },
                                                canSubscribeMetrics: { type: 'boolean' },
                                            },
                                            required: [
                                                'canSubscribe',
                                                'canPublish',
                                                'canPublishData',
                                                'hidden',
                                                'canUpdateMetadata',
                                                'canSubscribeMetrics',
                                            ],
                                            additionalProperties: false,
                                        },
                                    },
                                    required: ['identity', 'sid'],
                                    additionalProperties: false,
                                },
                            },
                            name: {
                                type: 'string',
                                const: 'activeSpeakersChanged',
                                description: 'Active speakers changed. List of speakers are ordered by their audio level. loudest speakers first. This will include the LocalParticipant too.\n\nSpeaker updates are sent only to the publishing participant and their subscribers.\n\nargs: (Array<[[Participant]]>)',
                            },
                            timestamp: { type: 'number' },
                        },
                        required: ['name', 'speakers', 'timestamp'],
                    },
                },
                {
                    if: {
                        properties: {
                            name: {
                                type: 'string',
                                const: 'roomMetadataChanged',
                                description: "Room metadata is a simple way for app-specific state to be pushed to all users. When RoomService.UpdateRoomMetadata is called to change a room's state,\n*all*  participants in the room will fire this event.\n\nargs: (string)",
                            },
                        },
                    },
                    then: {
                        type: 'object',
                        additionalProperties: false,
                        properties: {
                            metadata: { type: 'string' },
                            name: {
                                type: 'string',
                                const: 'roomMetadataChanged',
                                description: "Room metadata is a simple way for app-specific state to be pushed to all users. When RoomService.UpdateRoomMetadata is called to change a room's state,\n*all*  participants in the room will fire this event.\n\nargs: (string)",
                            },
                            timestamp: { type: 'number' },
                        },
                        required: ['metadata', 'name', 'timestamp'],
                    },
                },
                {
                    if: {
                        properties: {
                            name: {
                                type: 'string',
                                const: 'connectionQualityChanged',
                                description: "Connection quality was changed for a Participant. It'll receive updates from the local participant, as well as any [[RemoteParticipant]]s that we are subscribed to.\n\nargs: (connectionQuality: [[ConnectionQuality]], participant: [[Participant]])",
                            },
                        },
                    },
                    then: {
                        type: 'object',
                        additionalProperties: false,
                        properties: {
                            quality: { $ref: '#/definitions/ConnectionQuality' },
                            participant: {
                                type: 'object',
                                properties: {
                                    identity: { type: 'string' },
                                    sid: { type: 'string' },
                                    permissions: {
                                        type: 'object',
                                        properties: {
                                            canSubscribe: { type: 'boolean' },
                                            canPublish: { type: 'boolean' },
                                            canPublishData: { type: 'boolean' },
                                            hidden: { type: 'boolean' },
                                            canUpdateMetadata: { type: 'boolean' },
                                            canSubscribeMetrics: { type: 'boolean' },
                                        },
                                        required: [
                                            'canSubscribe',
                                            'canPublish',
                                            'canPublishData',
                                            'hidden',
                                            'canUpdateMetadata',
                                            'canSubscribeMetrics',
                                        ],
                                        additionalProperties: false,
                                    },
                                },
                                required: ['identity', 'sid'],
                                additionalProperties: false,
                            },
                            name: {
                                type: 'string',
                                const: 'connectionQualityChanged',
                                description: "Connection quality was changed for a Participant. It'll receive updates from the local participant, as well as any [[RemoteParticipant]]s that we are subscribed to.\n\nargs: (connectionQuality: [[ConnectionQuality]], participant: [[Participant]])",
                            },
                            timestamp: { type: 'number' },
                        },
                        required: ['name', 'participant', 'quality', 'timestamp'],
                    },
                },
                {
                    if: {
                        properties: {
                            name: {
                                type: 'string',
                                const: 'mediaDevicesError',
                                description: 'When we have encountered an error while attempting to create a track. The errors take place in getUserMedia(). Use MediaDeviceFailure.getFailure(error) to get the reason of failure. [[LocalParticipant.lastCameraError]] and [[LocalParticipant.lastMicrophoneError]] will indicate if it had an error while creating the audio or video track respectively.\n\nargs: (error: Error)',
                            },
                        },
                    },
                    then: {
                        type: 'object',
                        additionalProperties: false,
                        properties: {
                            error: { type: 'string' },
                            name: {
                                type: 'string',
                                const: 'mediaDevicesError',
                                description: 'When we have encountered an error while attempting to create a track. The errors take place in getUserMedia(). Use MediaDeviceFailure.getFailure(error) to get the reason of failure. [[LocalParticipant.lastCameraError]] and [[LocalParticipant.lastMicrophoneError]] will indicate if it had an error while creating the audio or video track respectively.\n\nargs: (error: Error)',
                            },
                            timestamp: { type: 'number' },
                        },
                        required: ['error', 'name', 'timestamp'],
                    },
                },
                {
                    if: {
                        properties: {
                            name: {
                                type: 'string',
                                const: 'trackStreamStateChanged',
                                description: "StreamState indicates if a subscribed (remote) track has been paused by the SFU (typically this happens because of subscriber's bandwidth constraints)\n\nWhen bandwidth conditions allow, the track will be resumed automatically. TrackStreamStateChanged will also be emitted when that happens.\n\nargs: (pub: [[RemoteTrackPublication]], streamState: [[Track.StreamState]],        participant: [[RemoteParticipant]])",
                            },
                        },
                    },
                    then: {
                        type: 'object',
                        additionalProperties: false,
                        properties: {
                            state: { $ref: '#/definitions/Track.StreamState' },
                            track: {
                                type: 'object',
                                properties: {
                                    sid: { type: 'string' },
                                    source: { $ref: '#/definitions/Track.Source' },
                                    mimeType: { type: 'string' },
                                    dimensions: {
                                        type: 'object',
                                        properties: {
                                            width: { type: 'number' },
                                            height: { type: 'number' },
                                        },
                                        required: ['width', 'height'],
                                        additionalProperties: false,
                                    },
                                },
                                required: ['sid', 'source'],
                                additionalProperties: false,
                            },
                            participant: {
                                type: 'object',
                                properties: {
                                    identity: { type: 'string' },
                                    sid: { type: 'string' },
                                    permissions: {
                                        type: 'object',
                                        properties: {
                                            canSubscribe: { type: 'boolean' },
                                            canPublish: { type: 'boolean' },
                                            canPublishData: { type: 'boolean' },
                                            hidden: { type: 'boolean' },
                                            canUpdateMetadata: { type: 'boolean' },
                                            canSubscribeMetrics: { type: 'boolean' },
                                        },
                                        required: [
                                            'canSubscribe',
                                            'canPublish',
                                            'canPublishData',
                                            'hidden',
                                            'canUpdateMetadata',
                                            'canSubscribeMetrics',
                                        ],
                                        additionalProperties: false,
                                    },
                                },
                                required: ['identity', 'sid'],
                                additionalProperties: false,
                            },
                            name: {
                                type: 'string',
                                const: 'trackStreamStateChanged',
                                description: "StreamState indicates if a subscribed (remote) track has been paused by the SFU (typically this happens because of subscriber's bandwidth constraints)\n\nWhen bandwidth conditions allow, the track will be resumed automatically. TrackStreamStateChanged will also be emitted when that happens.\n\nargs: (pub: [[RemoteTrackPublication]], streamState: [[Track.StreamState]],        participant: [[RemoteParticipant]])",
                            },
                            timestamp: { type: 'number' },
                        },
                        required: ['name', 'participant', 'state', 'timestamp', 'track'],
                    },
                },
                {
                    if: {
                        properties: {
                            name: {
                                type: 'string',
                                const: 'trackSubscriptionPermissionChanged',
                                description: 'One of subscribed tracks have changed its permissions for the current participant. If permission was revoked, then the track will no longer be subscribed. If permission was granted, a TrackSubscribed event will be emitted.\n\nargs: (pub: [[RemoteTrackPublication]],        status: [[TrackPublication.PermissionStatus]],        participant: [[RemoteParticipant]])',
                            },
                        },
                    },
                    then: {
                        type: 'object',
                        additionalProperties: false,
                        properties: {
                            status: {
                                $ref: '#/definitions/TrackPublication.PermissionStatus',
                            },
                            track: {
                                type: 'object',
                                properties: {
                                    sid: { type: 'string' },
                                    source: { $ref: '#/definitions/Track.Source' },
                                    mimeType: { type: 'string' },
                                    dimensions: {
                                        type: 'object',
                                        properties: {
                                            width: { type: 'number' },
                                            height: { type: 'number' },
                                        },
                                        required: ['width', 'height'],
                                        additionalProperties: false,
                                    },
                                },
                                required: ['sid', 'source'],
                                additionalProperties: false,
                            },
                            participant: {
                                type: 'object',
                                properties: {
                                    identity: { type: 'string' },
                                    sid: { type: 'string' },
                                    permissions: {
                                        type: 'object',
                                        properties: {
                                            canSubscribe: { type: 'boolean' },
                                            canPublish: { type: 'boolean' },
                                            canPublishData: { type: 'boolean' },
                                            hidden: { type: 'boolean' },
                                            canUpdateMetadata: { type: 'boolean' },
                                            canSubscribeMetrics: { type: 'boolean' },
                                        },
                                        required: [
                                            'canSubscribe',
                                            'canPublish',
                                            'canPublishData',
                                            'hidden',
                                            'canUpdateMetadata',
                                            'canSubscribeMetrics',
                                        ],
                                        additionalProperties: false,
                                    },
                                },
                                required: ['identity', 'sid'],
                                additionalProperties: false,
                            },
                            name: {
                                type: 'string',
                                const: 'trackSubscriptionPermissionChanged',
                                description: 'One of subscribed tracks have changed its permissions for the current participant. If permission was revoked, then the track will no longer be subscribed. If permission was granted, a TrackSubscribed event will be emitted.\n\nargs: (pub: [[RemoteTrackPublication]],        status: [[TrackPublication.PermissionStatus]],        participant: [[RemoteParticipant]])',
                            },
                            timestamp: { type: 'number' },
                        },
                        required: ['name', 'participant', 'status', 'timestamp', 'track'],
                    },
                },
                {
                    if: {
                        properties: {
                            name: {
                                type: 'string',
                                const: 'trackSubscriptionStatusChanged',
                                description: 'One of subscribed tracks have changed its status for the current participant.\n\nargs: (pub: [[RemoteTrackPublication]],        status: [[TrackPublication.SubscriptionStatus]],        participant: [[RemoteParticipant]])',
                            },
                        },
                    },
                    then: {
                        type: 'object',
                        additionalProperties: false,
                        properties: {
                            status: {
                                $ref: '#/definitions/TrackPublication.SubscriptionStatus',
                            },
                            track: {
                                type: 'object',
                                properties: {
                                    sid: { type: 'string' },
                                    source: { $ref: '#/definitions/Track.Source' },
                                    mimeType: { type: 'string' },
                                    dimensions: {
                                        type: 'object',
                                        properties: {
                                            width: { type: 'number' },
                                            height: { type: 'number' },
                                        },
                                        required: ['width', 'height'],
                                        additionalProperties: false,
                                    },
                                },
                                required: ['sid', 'source'],
                                additionalProperties: false,
                            },
                            participant: {
                                type: 'object',
                                properties: {
                                    identity: { type: 'string' },
                                    sid: { type: 'string' },
                                    permissions: {
                                        type: 'object',
                                        properties: {
                                            canSubscribe: { type: 'boolean' },
                                            canPublish: { type: 'boolean' },
                                            canPublishData: { type: 'boolean' },
                                            hidden: { type: 'boolean' },
                                            canUpdateMetadata: { type: 'boolean' },
                                            canSubscribeMetrics: { type: 'boolean' },
                                        },
                                        required: [
                                            'canSubscribe',
                                            'canPublish',
                                            'canPublishData',
                                            'hidden',
                                            'canUpdateMetadata',
                                            'canSubscribeMetrics',
                                        ],
                                        additionalProperties: false,
                                    },
                                },
                                required: ['identity', 'sid'],
                                additionalProperties: false,
                            },
                            name: {
                                type: 'string',
                                const: 'trackSubscriptionStatusChanged',
                                description: 'One of subscribed tracks have changed its status for the current participant.\n\nargs: (pub: [[RemoteTrackPublication]],        status: [[TrackPublication.SubscriptionStatus]],        participant: [[RemoteParticipant]])',
                            },
                            timestamp: { type: 'number' },
                        },
                        required: ['name', 'participant', 'status', 'timestamp', 'track'],
                    },
                },
                {
                    if: {
                        properties: {
                            name: {
                                type: 'string',
                                const: 'audioPlaybackChanged',
                                description: "LiveKit will attempt to autoplay all audio tracks when you attach them to audio elements. However, if that fails, we'll notify you via AudioPlaybackStatusChanged. `Room.canPlaybackAudio` will indicate if audio playback is permitted.",
                            },
                        },
                    },
                    then: {
                        type: 'object',
                        additionalProperties: false,
                        properties: {
                            playing: { type: 'boolean' },
                            name: {
                                type: 'string',
                                const: 'audioPlaybackChanged',
                                description: "LiveKit will attempt to autoplay all audio tracks when you attach them to audio elements. However, if that fails, we'll notify you via AudioPlaybackStatusChanged. `Room.canPlaybackAudio` will indicate if audio playback is permitted.",
                            },
                            timestamp: { type: 'number' },
                        },
                        required: ['name', 'playing', 'timestamp'],
                    },
                },
                {
                    if: {
                        properties: {
                            name: {
                                type: 'string',
                                const: 'videoPlaybackChanged',
                                description: "LiveKit will attempt to autoplay all video tracks when you attach them to a video element. However, if that fails, we'll notify you via VideoPlaybackStatusChanged. Calling `room.startVideo()` in a user gesture event handler will resume the video playback.",
                            },
                        },
                    },
                    then: {
                        type: 'object',
                        additionalProperties: false,
                        properties: {
                            playing: { type: 'boolean' },
                            name: {
                                type: 'string',
                                const: 'videoPlaybackChanged',
                                description: "LiveKit will attempt to autoplay all video tracks when you attach them to a video element. However, if that fails, we'll notify you via VideoPlaybackStatusChanged. Calling `room.startVideo()` in a user gesture event handler will resume the video playback.",
                            },
                            timestamp: { type: 'number' },
                        },
                        required: ['name', 'playing', 'timestamp'],
                    },
                },
                {
                    if: {
                        properties: {
                            name: {
                                type: 'string',
                                const: 'signalConnected',
                                description: 'Signal connected, can publish tracks.',
                            },
                        },
                    },
                    then: {
                        type: 'object',
                        properties: {
                            name: {
                                type: 'string',
                                const: 'signalConnected',
                                description: 'Signal connected, can publish tracks.',
                            },
                            timestamp: { type: 'number' },
                        },
                        required: ['name', 'timestamp'],
                        additionalProperties: false,
                    },
                },
                {
                    if: {
                        properties: {
                            name: {
                                type: 'string',
                                const: 'recordingStatusChanged',
                                description: 'Recording of a room has started/stopped. Room.isRecording will be updated too. args: (isRecording: boolean)',
                            },
                        },
                    },
                    then: {
                        type: 'object',
                        additionalProperties: false,
                        properties: {
                            recording: { type: 'boolean' },
                            name: {
                                type: 'string',
                                const: 'recordingStatusChanged',
                                description: 'Recording of a room has started/stopped. Room.isRecording will be updated too. args: (isRecording: boolean)',
                            },
                            timestamp: { type: 'number' },
                        },
                        required: ['name', 'recording', 'timestamp'],
                    },
                },
                {
                    if: {
                        properties: {
                            name: {
                                type: 'string',
                                const: 'activeDeviceChanged',
                                description: 'Triggered by a call to room.switchActiveDevice args: (kind: MediaDeviceKind, deviceId: string)',
                            },
                        },
                    },
                    then: {
                        type: 'object',
                        additionalProperties: false,
                        properties: {
                            kind: {
                                type: 'string',
                                enum: ['audioinput', 'audiooutput', 'videoinput'],
                            },
                            deviceId: { type: 'string' },
                            name: {
                                type: 'string',
                                const: 'activeDeviceChanged',
                                description: 'Triggered by a call to room.switchActiveDevice args: (kind: MediaDeviceKind, deviceId: string)',
                            },
                            timestamp: { type: 'number' },
                        },
                        required: ['deviceId', 'kind', 'name', 'timestamp'],
                    },
                },
                {
                    if: {
                        properties: {
                            name: {
                                type: 'string',
                                const: 'localTrackSubscribed',
                                description: "fired when the first remote participant has subscribed to the localParticipant's track",
                            },
                        },
                    },
                    then: {
                        type: 'object',
                        additionalProperties: false,
                        properties: {
                            track: {
                                type: 'object',
                                properties: {
                                    sid: { type: 'string' },
                                    source: { $ref: '#/definitions/Track.Source' },
                                    mimeType: { type: 'string' },
                                    dimensions: {
                                        type: 'object',
                                        properties: {
                                            width: { type: 'number' },
                                            height: { type: 'number' },
                                        },
                                        required: ['width', 'height'],
                                        additionalProperties: false,
                                    },
                                },
                                required: ['sid', 'source'],
                                additionalProperties: false,
                            },
                            participant: {
                                type: 'object',
                                properties: {
                                    identity: { type: 'string' },
                                    sid: { type: 'string' },
                                    permissions: {
                                        type: 'object',
                                        properties: {
                                            canSubscribe: { type: 'boolean' },
                                            canPublish: { type: 'boolean' },
                                            canPublishData: { type: 'boolean' },
                                            hidden: { type: 'boolean' },
                                            canUpdateMetadata: { type: 'boolean' },
                                            canSubscribeMetrics: { type: 'boolean' },
                                        },
                                        required: [
                                            'canSubscribe',
                                            'canPublish',
                                            'canPublishData',
                                            'hidden',
                                            'canUpdateMetadata',
                                            'canSubscribeMetrics',
                                        ],
                                        additionalProperties: false,
                                    },
                                },
                                required: ['identity', 'sid'],
                                additionalProperties: false,
                            },
                            name: {
                                type: 'string',
                                const: 'localTrackSubscribed',
                                description: "fired when the first remote participant has subscribed to the localParticipant's track",
                            },
                            timestamp: { type: 'number' },
                        },
                        required: ['name', 'participant', 'timestamp', 'track'],
                    },
                },
            ],
        },
        DisconnectReason: {
            type: 'number',
            enum: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
        },
        ConnectionState: {
            type: 'string',
            enum: [
                'disconnected',
                'connecting',
                'connected',
                'reconnecting',
                'signalReconnecting',
            ],
        },
        'Track.Source': {
            type: 'string',
            enum: [
                'camera',
                'microphone',
                'screen_share',
                'screen_share_audio',
                'unknown',
            ],
        },
        SubscriptionError: { type: 'number', enum: [0, 1, 2] },
        ConnectionQuality: {
            type: 'string',
            enum: ['excellent', 'good', 'poor', 'lost', 'unknown'],
        },
        'Track.StreamState': {
            type: 'string',
            enum: ['active', 'paused', 'unknown'],
        },
        'TrackPublication.PermissionStatus': {
            type: 'string',
            enum: ['allowed', 'not_allowed'],
        },
        'TrackPublication.SubscriptionStatus': {
            type: 'string',
            enum: ['desired', 'subscribed', 'unsubscribed'],
        },
    },
};
var schema28 = {
    type: 'object',
    properties: {
        name: {
            enum: [
                'connected',
                'reconnecting',
                'signalReconnecting',
                'reconnected',
                'disconnected',
                'connectionStateChanged',
                'mediaDevicesChanged',
                'participantConnected',
                'participantDisconnected',
                'trackPublished',
                'trackSubscribed',
                'trackSubscriptionFailed',
                'trackUnpublished',
                'trackUnsubscribed',
                'trackMuted',
                'trackUnmuted',
                'localTrackPublished',
                'localTrackUnpublished',
                'localAudioSilenceDetected',
                'participantMetadataChanged',
                'participantNameChanged',
                'participantPermissionsChanged',
                'participantAttributesChanged',
                'activeSpeakersChanged',
                'roomMetadataChanged',
                'connectionQualityChanged',
                'mediaDevicesError',
                'trackStreamStateChanged',
                'trackSubscriptionPermissionChanged',
                'trackSubscriptionStatusChanged',
                'audioPlaybackChanged',
                'videoPlaybackChanged',
                'signalConnected',
                'recordingStatusChanged',
                'activeDeviceChanged',
                'localTrackSubscribed',
            ],
        },
    },
    required: ['name'],
    allOf: [
        {
            if: {
                properties: {
                    name: {
                        type: 'string',
                        const: 'connected',
                        description: 'When the connection to the server has been established',
                    },
                },
            },
            then: {
                type: 'object',
                properties: {
                    name: {
                        type: 'string',
                        const: 'connected',
                        description: 'When the connection to the server has been established',
                    },
                    timestamp: { type: 'number' },
                },
                required: ['name', 'timestamp'],
                additionalProperties: false,
            },
        },
        {
            if: {
                properties: {
                    name: {
                        type: 'string',
                        const: 'reconnecting',
                        description: "When the connection to the server has been interrupted and it's attempting to reconnect.",
                    },
                },
            },
            then: {
                type: 'object',
                properties: {
                    name: {
                        type: 'string',
                        const: 'reconnecting',
                        description: "When the connection to the server has been interrupted and it's attempting to reconnect.",
                    },
                    timestamp: { type: 'number' },
                },
                required: ['name', 'timestamp'],
                additionalProperties: false,
            },
        },
        {
            if: {
                properties: {
                    name: {
                        type: 'string',
                        const: 'signalReconnecting',
                        description: "When the signal connection to the server has been interrupted. This isn't noticeable to users most of the time. It will resolve with a `RoomEvent.Reconnected` once the signal connection has been re-established. If media fails additionally it an additional `RoomEvent.Reconnecting` will be emitted.",
                    },
                },
            },
            then: {
                type: 'object',
                properties: {
                    name: {
                        type: 'string',
                        const: 'signalReconnecting',
                        description: "When the signal connection to the server has been interrupted. This isn't noticeable to users most of the time. It will resolve with a `RoomEvent.Reconnected` once the signal connection has been re-established. If media fails additionally it an additional `RoomEvent.Reconnecting` will be emitted.",
                    },
                    timestamp: { type: 'number' },
                },
                required: ['name', 'timestamp'],
                additionalProperties: false,
            },
        },
        {
            if: {
                properties: {
                    name: {
                        type: 'string',
                        const: 'reconnected',
                        description: 'Fires when a reconnection has been successful.',
                    },
                },
            },
            then: {
                type: 'object',
                properties: {
                    name: {
                        type: 'string',
                        const: 'reconnected',
                        description: 'Fires when a reconnection has been successful.',
                    },
                    timestamp: { type: 'number' },
                },
                required: ['name', 'timestamp'],
                additionalProperties: false,
            },
        },
        {
            if: {
                properties: {
                    name: {
                        type: 'string',
                        const: 'disconnected',
                        description: 'When disconnected from room. This fires when room.disconnect() is called or when an unrecoverable connection issue had occured.\n\nDisconnectReason can be used to determine why the participant was disconnected. Notable reasons are\n- DUPLICATE_IDENTITY: another client with the same identity has joined the room\n- PARTICIPANT_REMOVED: participant was removed by RemoveParticipant API\n- ROOM_DELETED: the room has ended via DeleteRoom API\n\nargs: ([[DisconnectReason]])',
                    },
                },
            },
            then: {
                type: 'object',
                additionalProperties: false,
                properties: {
                    reason: { $ref: '#/definitions/DisconnectReason' },
                    name: {
                        type: 'string',
                        const: 'disconnected',
                        description: 'When disconnected from room. This fires when room.disconnect() is called or when an unrecoverable connection issue had occured.\n\nDisconnectReason can be used to determine why the participant was disconnected. Notable reasons are\n- DUPLICATE_IDENTITY: another client with the same identity has joined the room\n- PARTICIPANT_REMOVED: participant was removed by RemoveParticipant API\n- ROOM_DELETED: the room has ended via DeleteRoom API\n\nargs: ([[DisconnectReason]])',
                    },
                    timestamp: { type: 'number' },
                },
                required: ['name', 'timestamp'],
            },
        },
        {
            if: {
                properties: {
                    name: {
                        type: 'string',
                        const: 'connectionStateChanged',
                        description: 'Whenever the connection state of the room changes\n\nargs: ([[ConnectionState]])',
                    },
                },
            },
            then: {
                type: 'object',
                additionalProperties: false,
                properties: {
                    state: { $ref: '#/definitions/ConnectionState' },
                    name: {
                        type: 'string',
                        const: 'connectionStateChanged',
                        description: 'Whenever the connection state of the room changes\n\nargs: ([[ConnectionState]])',
                    },
                    timestamp: { type: 'number' },
                },
                required: ['name', 'state', 'timestamp'],
            },
        },
        {
            if: {
                properties: {
                    name: {
                        type: 'string',
                        const: 'mediaDevicesChanged',
                        description: 'When input or output devices on the machine have changed.',
                    },
                },
            },
            then: {
                type: 'object',
                properties: {
                    name: {
                        type: 'string',
                        const: 'mediaDevicesChanged',
                        description: 'When input or output devices on the machine have changed.',
                    },
                    timestamp: { type: 'number' },
                },
                required: ['name', 'timestamp'],
                additionalProperties: false,
            },
        },
        {
            if: {
                properties: {
                    name: {
                        type: 'string',
                        const: 'participantConnected',
                        description: 'When a [[RemoteParticipant]] joins *after* the local participant. It will not emit events for participants that are already in the room\n\nargs: ([[RemoteParticipant]])',
                    },
                },
            },
            then: {
                type: 'object',
                additionalProperties: false,
                properties: {
                    participant: {
                        type: 'object',
                        properties: {
                            identity: { type: 'string' },
                            sid: { type: 'string' },
                            permissions: {
                                type: 'object',
                                properties: {
                                    canSubscribe: { type: 'boolean' },
                                    canPublish: { type: 'boolean' },
                                    canPublishData: { type: 'boolean' },
                                    hidden: { type: 'boolean' },
                                    canUpdateMetadata: { type: 'boolean' },
                                    canSubscribeMetrics: { type: 'boolean' },
                                },
                                required: [
                                    'canSubscribe',
                                    'canPublish',
                                    'canPublishData',
                                    'hidden',
                                    'canUpdateMetadata',
                                    'canSubscribeMetrics',
                                ],
                                additionalProperties: false,
                            },
                        },
                        required: ['identity', 'sid'],
                        additionalProperties: false,
                    },
                    name: {
                        type: 'string',
                        const: 'participantConnected',
                        description: 'When a [[RemoteParticipant]] joins *after* the local participant. It will not emit events for participants that are already in the room\n\nargs: ([[RemoteParticipant]])',
                    },
                    timestamp: { type: 'number' },
                },
                required: ['name', 'participant', 'timestamp'],
            },
        },
        {
            if: {
                properties: {
                    name: {
                        type: 'string',
                        const: 'participantDisconnected',
                        description: 'When a [[RemoteParticipant]] leaves *after* the local participant has joined.\n\nargs: ([[RemoteParticipant]])',
                    },
                },
            },
            then: {
                type: 'object',
                additionalProperties: false,
                properties: {
                    participant: {
                        type: 'object',
                        properties: {
                            identity: { type: 'string' },
                            sid: { type: 'string' },
                            permissions: {
                                type: 'object',
                                properties: {
                                    canSubscribe: { type: 'boolean' },
                                    canPublish: { type: 'boolean' },
                                    canPublishData: { type: 'boolean' },
                                    hidden: { type: 'boolean' },
                                    canUpdateMetadata: { type: 'boolean' },
                                    canSubscribeMetrics: { type: 'boolean' },
                                },
                                required: [
                                    'canSubscribe',
                                    'canPublish',
                                    'canPublishData',
                                    'hidden',
                                    'canUpdateMetadata',
                                    'canSubscribeMetrics',
                                ],
                                additionalProperties: false,
                            },
                        },
                        required: ['identity', 'sid'],
                        additionalProperties: false,
                    },
                    name: {
                        type: 'string',
                        const: 'participantDisconnected',
                        description: 'When a [[RemoteParticipant]] leaves *after* the local participant has joined.\n\nargs: ([[RemoteParticipant]])',
                    },
                    timestamp: { type: 'number' },
                },
                required: ['name', 'participant', 'timestamp'],
            },
        },
        {
            if: {
                properties: {
                    name: {
                        type: 'string',
                        const: 'trackPublished',
                        description: "When a new track is published to room *after* the local participant has joined. It will not fire for tracks that are already published.\n\nA track published doesn't mean the participant has subscribed to it. It's simply reflecting the state of the room.\n\nargs: ([[RemoteTrackPublication]], [[RemoteParticipant]])",
                    },
                },
            },
            then: {
                type: 'object',
                additionalProperties: false,
                properties: {
                    track: {
                        type: 'object',
                        properties: {
                            sid: { type: 'string' },
                            source: { $ref: '#/definitions/Track.Source' },
                            mimeType: { type: 'string' },
                            dimensions: {
                                type: 'object',
                                properties: {
                                    width: { type: 'number' },
                                    height: { type: 'number' },
                                },
                                required: ['width', 'height'],
                                additionalProperties: false,
                            },
                        },
                        required: ['sid', 'source'],
                        additionalProperties: false,
                    },
                    participant: {
                        type: 'object',
                        properties: {
                            identity: { type: 'string' },
                            sid: { type: 'string' },
                            permissions: {
                                type: 'object',
                                properties: {
                                    canSubscribe: { type: 'boolean' },
                                    canPublish: { type: 'boolean' },
                                    canPublishData: { type: 'boolean' },
                                    hidden: { type: 'boolean' },
                                    canUpdateMetadata: { type: 'boolean' },
                                    canSubscribeMetrics: { type: 'boolean' },
                                },
                                required: [
                                    'canSubscribe',
                                    'canPublish',
                                    'canPublishData',
                                    'hidden',
                                    'canUpdateMetadata',
                                    'canSubscribeMetrics',
                                ],
                                additionalProperties: false,
                            },
                        },
                        required: ['identity', 'sid'],
                        additionalProperties: false,
                    },
                    name: {
                        type: 'string',
                        const: 'trackPublished',
                        description: "When a new track is published to room *after* the local participant has joined. It will not fire for tracks that are already published.\n\nA track published doesn't mean the participant has subscribed to it. It's simply reflecting the state of the room.\n\nargs: ([[RemoteTrackPublication]], [[RemoteParticipant]])",
                    },
                    timestamp: { type: 'number' },
                },
                required: ['name', 'participant', 'timestamp', 'track'],
            },
        },
        {
            if: {
                properties: {
                    name: {
                        type: 'string',
                        const: 'trackSubscribed',
                        description: 'The [[LocalParticipant]] has subscribed to a new track. This event will **always** fire as long as new tracks are ready for use.\n\nargs: ([[RemoteTrack]], [[RemoteTrackPublication]], [[RemoteParticipant]])',
                    },
                },
            },
            then: {
                type: 'object',
                additionalProperties: false,
                properties: {
                    track: {
                        type: 'object',
                        properties: {
                            sid: { type: 'string' },
                            source: { $ref: '#/definitions/Track.Source' },
                            mimeType: { type: 'string' },
                            dimensions: {
                                type: 'object',
                                properties: {
                                    width: { type: 'number' },
                                    height: { type: 'number' },
                                },
                                required: ['width', 'height'],
                                additionalProperties: false,
                            },
                        },
                        required: ['sid', 'source'],
                        additionalProperties: false,
                    },
                    participant: {
                        type: 'object',
                        properties: {
                            identity: { type: 'string' },
                            sid: { type: 'string' },
                            permissions: {
                                type: 'object',
                                properties: {
                                    canSubscribe: { type: 'boolean' },
                                    canPublish: { type: 'boolean' },
                                    canPublishData: { type: 'boolean' },
                                    hidden: { type: 'boolean' },
                                    canUpdateMetadata: { type: 'boolean' },
                                    canSubscribeMetrics: { type: 'boolean' },
                                },
                                required: [
                                    'canSubscribe',
                                    'canPublish',
                                    'canPublishData',
                                    'hidden',
                                    'canUpdateMetadata',
                                    'canSubscribeMetrics',
                                ],
                                additionalProperties: false,
                            },
                        },
                        required: ['identity', 'sid'],
                        additionalProperties: false,
                    },
                    name: {
                        type: 'string',
                        const: 'trackSubscribed',
                        description: 'The [[LocalParticipant]] has subscribed to a new track. This event will **always** fire as long as new tracks are ready for use.\n\nargs: ([[RemoteTrack]], [[RemoteTrackPublication]], [[RemoteParticipant]])',
                    },
                    timestamp: { type: 'number' },
                },
                required: ['name', 'participant', 'timestamp', 'track'],
            },
        },
        {
            if: {
                properties: {
                    name: {
                        type: 'string',
                        const: 'trackSubscriptionFailed',
                        description: 'Could not subscribe to a track\n\nargs: (track sid, [[RemoteParticipant]])',
                    },
                },
            },
            then: {
                type: 'object',
                additionalProperties: false,
                properties: {
                    track: {
                        type: 'object',
                        properties: { sid: { type: 'string' } },
                        required: ['sid'],
                        additionalProperties: false,
                    },
                    reason: { $ref: '#/definitions/SubscriptionError' },
                    participant: {
                        type: 'object',
                        properties: {
                            identity: { type: 'string' },
                            sid: { type: 'string' },
                            permissions: {
                                type: 'object',
                                properties: {
                                    canSubscribe: { type: 'boolean' },
                                    canPublish: { type: 'boolean' },
                                    canPublishData: { type: 'boolean' },
                                    hidden: { type: 'boolean' },
                                    canUpdateMetadata: { type: 'boolean' },
                                    canSubscribeMetrics: { type: 'boolean' },
                                },
                                required: [
                                    'canSubscribe',
                                    'canPublish',
                                    'canPublishData',
                                    'hidden',
                                    'canUpdateMetadata',
                                    'canSubscribeMetrics',
                                ],
                                additionalProperties: false,
                            },
                        },
                        required: ['identity', 'sid'],
                        additionalProperties: false,
                    },
                    name: {
                        type: 'string',
                        const: 'trackSubscriptionFailed',
                        description: 'Could not subscribe to a track\n\nargs: (track sid, [[RemoteParticipant]])',
                    },
                    timestamp: { type: 'number' },
                },
                required: ['name', 'participant', 'timestamp', 'track'],
            },
        },
        {
            if: {
                properties: {
                    name: {
                        type: 'string',
                        const: 'trackUnpublished',
                        description: 'A [[RemoteParticipant]] has unpublished a track\n\nargs: ([[RemoteTrackPublication]], [[RemoteParticipant]])',
                    },
                },
            },
            then: {
                type: 'object',
                additionalProperties: false,
                properties: {
                    track: {
                        type: 'object',
                        properties: {
                            sid: { type: 'string' },
                            source: { $ref: '#/definitions/Track.Source' },
                            mimeType: { type: 'string' },
                            dimensions: {
                                type: 'object',
                                properties: {
                                    width: { type: 'number' },
                                    height: { type: 'number' },
                                },
                                required: ['width', 'height'],
                                additionalProperties: false,
                            },
                        },
                        required: ['sid', 'source'],
                        additionalProperties: false,
                    },
                    participant: {
                        type: 'object',
                        properties: {
                            identity: { type: 'string' },
                            sid: { type: 'string' },
                            permissions: {
                                type: 'object',
                                properties: {
                                    canSubscribe: { type: 'boolean' },
                                    canPublish: { type: 'boolean' },
                                    canPublishData: { type: 'boolean' },
                                    hidden: { type: 'boolean' },
                                    canUpdateMetadata: { type: 'boolean' },
                                    canSubscribeMetrics: { type: 'boolean' },
                                },
                                required: [
                                    'canSubscribe',
                                    'canPublish',
                                    'canPublishData',
                                    'hidden',
                                    'canUpdateMetadata',
                                    'canSubscribeMetrics',
                                ],
                                additionalProperties: false,
                            },
                        },
                        required: ['identity', 'sid'],
                        additionalProperties: false,
                    },
                    name: {
                        type: 'string',
                        const: 'trackUnpublished',
                        description: 'A [[RemoteParticipant]] has unpublished a track\n\nargs: ([[RemoteTrackPublication]], [[RemoteParticipant]])',
                    },
                    timestamp: { type: 'number' },
                },
                required: ['name', 'participant', 'timestamp', 'track'],
            },
        },
        {
            if: {
                properties: {
                    name: {
                        type: 'string',
                        const: 'trackUnsubscribed',
                        description: 'A subscribed track is no longer available. Clients should listen to this event and ensure they detach tracks.\n\nargs: ([[Track]], [[RemoteTrackPublication]], [[RemoteParticipant]])',
                    },
                },
            },
            then: {
                type: 'object',
                additionalProperties: false,
                properties: {
                    track: {
                        type: 'object',
                        properties: {
                            sid: { type: 'string' },
                            source: { $ref: '#/definitions/Track.Source' },
                            mimeType: { type: 'string' },
                            dimensions: {
                                type: 'object',
                                properties: {
                                    width: { type: 'number' },
                                    height: { type: 'number' },
                                },
                                required: ['width', 'height'],
                                additionalProperties: false,
                            },
                        },
                        required: ['sid', 'source'],
                        additionalProperties: false,
                    },
                    participant: {
                        type: 'object',
                        properties: {
                            identity: { type: 'string' },
                            sid: { type: 'string' },
                            permissions: {
                                type: 'object',
                                properties: {
                                    canSubscribe: { type: 'boolean' },
                                    canPublish: { type: 'boolean' },
                                    canPublishData: { type: 'boolean' },
                                    hidden: { type: 'boolean' },
                                    canUpdateMetadata: { type: 'boolean' },
                                    canSubscribeMetrics: { type: 'boolean' },
                                },
                                required: [
                                    'canSubscribe',
                                    'canPublish',
                                    'canPublishData',
                                    'hidden',
                                    'canUpdateMetadata',
                                    'canSubscribeMetrics',
                                ],
                                additionalProperties: false,
                            },
                        },
                        required: ['identity', 'sid'],
                        additionalProperties: false,
                    },
                    name: {
                        type: 'string',
                        const: 'trackUnsubscribed',
                        description: 'A subscribed track is no longer available. Clients should listen to this event and ensure they detach tracks.\n\nargs: ([[Track]], [[RemoteTrackPublication]], [[RemoteParticipant]])',
                    },
                    timestamp: { type: 'number' },
                },
                required: ['name', 'participant', 'timestamp', 'track'],
            },
        },
        {
            if: {
                properties: {
                    name: {
                        type: 'string',
                        const: 'trackMuted',
                        description: 'A track that was muted, fires on both [[RemoteParticipant]]s and [[LocalParticipant]]\n\nargs: ([[TrackPublication]], [[Participant]])',
                    },
                },
            },
            then: {
                type: 'object',
                additionalProperties: false,
                properties: {
                    track: {
                        type: 'object',
                        properties: {
                            sid: { type: 'string' },
                            source: { $ref: '#/definitions/Track.Source' },
                            mimeType: { type: 'string' },
                            dimensions: {
                                type: 'object',
                                properties: {
                                    width: { type: 'number' },
                                    height: { type: 'number' },
                                },
                                required: ['width', 'height'],
                                additionalProperties: false,
                            },
                        },
                        required: ['sid', 'source'],
                        additionalProperties: false,
                    },
                    participant: {
                        type: 'object',
                        properties: {
                            identity: { type: 'string' },
                            sid: { type: 'string' },
                            permissions: {
                                type: 'object',
                                properties: {
                                    canSubscribe: { type: 'boolean' },
                                    canPublish: { type: 'boolean' },
                                    canPublishData: { type: 'boolean' },
                                    hidden: { type: 'boolean' },
                                    canUpdateMetadata: { type: 'boolean' },
                                    canSubscribeMetrics: { type: 'boolean' },
                                },
                                required: [
                                    'canSubscribe',
                                    'canPublish',
                                    'canPublishData',
                                    'hidden',
                                    'canUpdateMetadata',
                                    'canSubscribeMetrics',
                                ],
                                additionalProperties: false,
                            },
                        },
                        required: ['identity', 'sid'],
                        additionalProperties: false,
                    },
                    name: {
                        type: 'string',
                        const: 'trackMuted',
                        description: 'A track that was muted, fires on both [[RemoteParticipant]]s and [[LocalParticipant]]\n\nargs: ([[TrackPublication]], [[Participant]])',
                    },
                    timestamp: { type: 'number' },
                },
                required: ['name', 'participant', 'timestamp', 'track'],
            },
        },
        {
            if: {
                properties: {
                    name: {
                        type: 'string',
                        const: 'trackUnmuted',
                        description: 'A track that was unmuted, fires on both [[RemoteParticipant]]s and [[LocalParticipant]]\n\nargs: ([[TrackPublication]], [[Participant]])',
                    },
                },
            },
            then: {
                type: 'object',
                additionalProperties: false,
                properties: {
                    track: {
                        type: 'object',
                        properties: {
                            sid: { type: 'string' },
                            source: { $ref: '#/definitions/Track.Source' },
                            mimeType: { type: 'string' },
                            dimensions: {
                                type: 'object',
                                properties: {
                                    width: { type: 'number' },
                                    height: { type: 'number' },
                                },
                                required: ['width', 'height'],
                                additionalProperties: false,
                            },
                        },
                        required: ['sid', 'source'],
                        additionalProperties: false,
                    },
                    participant: {
                        type: 'object',
                        properties: {
                            identity: { type: 'string' },
                            sid: { type: 'string' },
                            permissions: {
                                type: 'object',
                                properties: {
                                    canSubscribe: { type: 'boolean' },
                                    canPublish: { type: 'boolean' },
                                    canPublishData: { type: 'boolean' },
                                    hidden: { type: 'boolean' },
                                    canUpdateMetadata: { type: 'boolean' },
                                    canSubscribeMetrics: { type: 'boolean' },
                                },
                                required: [
                                    'canSubscribe',
                                    'canPublish',
                                    'canPublishData',
                                    'hidden',
                                    'canUpdateMetadata',
                                    'canSubscribeMetrics',
                                ],
                                additionalProperties: false,
                            },
                        },
                        required: ['identity', 'sid'],
                        additionalProperties: false,
                    },
                    name: {
                        type: 'string',
                        const: 'trackUnmuted',
                        description: 'A track that was unmuted, fires on both [[RemoteParticipant]]s and [[LocalParticipant]]\n\nargs: ([[TrackPublication]], [[Participant]])',
                    },
                    timestamp: { type: 'number' },
                },
                required: ['name', 'participant', 'timestamp', 'track'],
            },
        },
        {
            if: {
                properties: {
                    name: {
                        type: 'string',
                        const: 'localTrackPublished',
                        description: 'A local track was published successfully. This event is helpful to know when to update your local UI with the newly published track.\n\nargs: ([[LocalTrackPublication]], [[LocalParticipant]])',
                    },
                },
            },
            then: {
                type: 'object',
                additionalProperties: false,
                properties: {
                    track: {
                        type: 'object',
                        properties: {
                            sid: { type: 'string' },
                            source: { $ref: '#/definitions/Track.Source' },
                            mimeType: { type: 'string' },
                            dimensions: {
                                type: 'object',
                                properties: {
                                    width: { type: 'number' },
                                    height: { type: 'number' },
                                },
                                required: ['width', 'height'],
                                additionalProperties: false,
                            },
                        },
                        required: ['sid', 'source'],
                        additionalProperties: false,
                    },
                    participant: {
                        type: 'object',
                        properties: {
                            identity: { type: 'string' },
                            sid: { type: 'string' },
                            permissions: {
                                type: 'object',
                                properties: {
                                    canSubscribe: { type: 'boolean' },
                                    canPublish: { type: 'boolean' },
                                    canPublishData: { type: 'boolean' },
                                    hidden: { type: 'boolean' },
                                    canUpdateMetadata: { type: 'boolean' },
                                    canSubscribeMetrics: { type: 'boolean' },
                                },
                                required: [
                                    'canSubscribe',
                                    'canPublish',
                                    'canPublishData',
                                    'hidden',
                                    'canUpdateMetadata',
                                    'canSubscribeMetrics',
                                ],
                                additionalProperties: false,
                            },
                        },
                        required: ['identity', 'sid'],
                        additionalProperties: false,
                    },
                    name: {
                        type: 'string',
                        const: 'localTrackPublished',
                        description: 'A local track was published successfully. This event is helpful to know when to update your local UI with the newly published track.\n\nargs: ([[LocalTrackPublication]], [[LocalParticipant]])',
                    },
                    timestamp: { type: 'number' },
                },
                required: ['name', 'participant', 'timestamp', 'track'],
            },
        },
        {
            if: {
                properties: {
                    name: {
                        type: 'string',
                        const: 'localTrackUnpublished',
                        description: 'A local track was unpublished. This event is helpful to know when to remove the local track from your UI.\n\nWhen a user stops sharing their screen by pressing "End" on the browser UI, this event will also fire.\n\nargs: ([[LocalTrackPublication]], [[LocalParticipant]])',
                    },
                },
            },
            then: {
                type: 'object',
                additionalProperties: false,
                properties: {
                    track: {
                        type: 'object',
                        properties: {
                            sid: { type: 'string' },
                            source: { $ref: '#/definitions/Track.Source' },
                            mimeType: { type: 'string' },
                            dimensions: {
                                type: 'object',
                                properties: {
                                    width: { type: 'number' },
                                    height: { type: 'number' },
                                },
                                required: ['width', 'height'],
                                additionalProperties: false,
                            },
                        },
                        required: ['sid', 'source'],
                        additionalProperties: false,
                    },
                    participant: {
                        type: 'object',
                        properties: {
                            identity: { type: 'string' },
                            sid: { type: 'string' },
                            permissions: {
                                type: 'object',
                                properties: {
                                    canSubscribe: { type: 'boolean' },
                                    canPublish: { type: 'boolean' },
                                    canPublishData: { type: 'boolean' },
                                    hidden: { type: 'boolean' },
                                    canUpdateMetadata: { type: 'boolean' },
                                    canSubscribeMetrics: { type: 'boolean' },
                                },
                                required: [
                                    'canSubscribe',
                                    'canPublish',
                                    'canPublishData',
                                    'hidden',
                                    'canUpdateMetadata',
                                    'canSubscribeMetrics',
                                ],
                                additionalProperties: false,
                            },
                        },
                        required: ['identity', 'sid'],
                        additionalProperties: false,
                    },
                    name: {
                        type: 'string',
                        const: 'localTrackUnpublished',
                        description: 'A local track was unpublished. This event is helpful to know when to remove the local track from your UI.\n\nWhen a user stops sharing their screen by pressing "End" on the browser UI, this event will also fire.\n\nargs: ([[LocalTrackPublication]], [[LocalParticipant]])',
                    },
                    timestamp: { type: 'number' },
                },
                required: ['name', 'participant', 'timestamp', 'track'],
            },
        },
        {
            if: {
                properties: {
                    name: {
                        type: 'string',
                        const: 'localAudioSilenceDetected',
                        description: 'When a local audio track is published the SDK checks whether there is complete silence on that track and emits the LocalAudioSilenceDetected event in that case. This allows for applications to show UI informing users that they might have to reset their audio hardware or check for proper device connectivity.',
                    },
                },
            },
            then: {
                type: 'object',
                additionalProperties: false,
                properties: {
                    track: {
                        type: 'object',
                        properties: {
                            sid: { type: 'string' },
                            source: { $ref: '#/definitions/Track.Source' },
                            mimeType: { type: 'string' },
                            dimensions: {
                                type: 'object',
                                properties: {
                                    width: { type: 'number' },
                                    height: { type: 'number' },
                                },
                                required: ['width', 'height'],
                                additionalProperties: false,
                            },
                        },
                        required: ['sid', 'source'],
                        additionalProperties: false,
                    },
                    name: {
                        type: 'string',
                        const: 'localAudioSilenceDetected',
                        description: 'When a local audio track is published the SDK checks whether there is complete silence on that track and emits the LocalAudioSilenceDetected event in that case. This allows for applications to show UI informing users that they might have to reset their audio hardware or check for proper device connectivity.',
                    },
                    timestamp: { type: 'number' },
                },
                required: ['name', 'timestamp', 'track'],
            },
        },
        {
            if: {
                properties: {
                    name: {
                        type: 'string',
                        const: 'participantMetadataChanged',
                        description: "Participant metadata is a simple way for app-specific state to be pushed to all users. When RoomService.UpdateParticipantMetadata is called to change a participant's state, *all*  participants in the room will fire this event.\n\nargs: (prevMetadata: string, [[Participant]])",
                    },
                },
            },
            then: {
                type: 'object',
                additionalProperties: false,
                properties: {
                    metadata: { type: 'string' },
                    participant: {
                        type: 'object',
                        properties: {
                            identity: { type: 'string' },
                            sid: { type: 'string' },
                            permissions: {
                                type: 'object',
                                properties: {
                                    canSubscribe: { type: 'boolean' },
                                    canPublish: { type: 'boolean' },
                                    canPublishData: { type: 'boolean' },
                                    hidden: { type: 'boolean' },
                                    canUpdateMetadata: { type: 'boolean' },
                                    canSubscribeMetrics: { type: 'boolean' },
                                },
                                required: [
                                    'canSubscribe',
                                    'canPublish',
                                    'canPublishData',
                                    'hidden',
                                    'canUpdateMetadata',
                                    'canSubscribeMetrics',
                                ],
                                additionalProperties: false,
                            },
                        },
                        required: ['identity', 'sid'],
                        additionalProperties: false,
                    },
                    name: {
                        type: 'string',
                        const: 'participantMetadataChanged',
                        description: "Participant metadata is a simple way for app-specific state to be pushed to all users. When RoomService.UpdateParticipantMetadata is called to change a participant's state, *all*  participants in the room will fire this event.\n\nargs: (prevMetadata: string, [[Participant]])",
                    },
                    timestamp: { type: 'number' },
                },
                required: ['name', 'participant', 'timestamp'],
            },
        },
        {
            if: {
                properties: {
                    name: {
                        type: 'string',
                        const: 'participantNameChanged',
                        description: "Participant's display name changed\n\nargs: (name: string, [[Participant]])",
                    },
                },
            },
            then: {
                type: 'object',
                additionalProperties: false,
                properties: {
                    newName: { type: 'string' },
                    participant: {
                        type: 'object',
                        properties: {
                            identity: { type: 'string' },
                            sid: { type: 'string' },
                            permissions: {
                                type: 'object',
                                properties: {
                                    canSubscribe: { type: 'boolean' },
                                    canPublish: { type: 'boolean' },
                                    canPublishData: { type: 'boolean' },
                                    hidden: { type: 'boolean' },
                                    canUpdateMetadata: { type: 'boolean' },
                                    canSubscribeMetrics: { type: 'boolean' },
                                },
                                required: [
                                    'canSubscribe',
                                    'canPublish',
                                    'canPublishData',
                                    'hidden',
                                    'canUpdateMetadata',
                                    'canSubscribeMetrics',
                                ],
                                additionalProperties: false,
                            },
                        },
                        required: ['identity', 'sid'],
                        additionalProperties: false,
                    },
                    name: {
                        type: 'string',
                        const: 'participantNameChanged',
                        description: "Participant's display name changed\n\nargs: (name: string, [[Participant]])",
                    },
                    timestamp: { type: 'number' },
                },
                required: ['name', 'newName', 'participant', 'timestamp'],
            },
        },
        {
            if: {
                properties: {
                    name: {
                        type: 'string',
                        const: 'participantPermissionsChanged',
                        description: "A participant's permission has changed. Currently only fired on LocalParticipant. args: (prevPermissions: [[ParticipantPermission]], participant: [[Participant]])",
                    },
                },
            },
            then: {
                type: 'object',
                additionalProperties: false,
                properties: {
                    prevPermissions: {
                        type: 'object',
                        properties: {
                            canSubscribe: { type: 'boolean' },
                            canPublish: { type: 'boolean' },
                            canPublishData: { type: 'boolean' },
                            hidden: { type: 'boolean' },
                            canUpdateMetadata: { type: 'boolean' },
                            canSubscribeMetrics: { type: 'boolean' },
                        },
                        required: [
                            'canSubscribe',
                            'canPublish',
                            'canPublishData',
                            'hidden',
                            'canUpdateMetadata',
                            'canSubscribeMetrics',
                        ],
                        additionalProperties: false,
                    },
                    participant: {
                        type: 'object',
                        properties: {
                            identity: { type: 'string' },
                            sid: { type: 'string' },
                            permissions: {
                                type: 'object',
                                properties: {
                                    canSubscribe: { type: 'boolean' },
                                    canPublish: { type: 'boolean' },
                                    canPublishData: { type: 'boolean' },
                                    hidden: { type: 'boolean' },
                                    canUpdateMetadata: { type: 'boolean' },
                                    canSubscribeMetrics: { type: 'boolean' },
                                },
                                required: [
                                    'canSubscribe',
                                    'canPublish',
                                    'canPublishData',
                                    'hidden',
                                    'canUpdateMetadata',
                                    'canSubscribeMetrics',
                                ],
                                additionalProperties: false,
                            },
                        },
                        required: ['identity', 'sid'],
                        additionalProperties: false,
                    },
                    name: {
                        type: 'string',
                        const: 'participantPermissionsChanged',
                        description: "A participant's permission has changed. Currently only fired on LocalParticipant. args: (prevPermissions: [[ParticipantPermission]], participant: [[Participant]])",
                    },
                    timestamp: { type: 'number' },
                },
                required: ['name', 'participant', 'timestamp'],
            },
        },
        {
            if: {
                properties: {
                    name: {
                        type: 'string',
                        const: 'participantAttributesChanged',
                        description: "Participant attributes is an app-specific key value state to be pushed to all users. When a participant's attributes changed, this event will be emitted with the changed attributes and the participant args: (changedAttributes: [[Record<string, string]], participant: [[Participant]])",
                    },
                },
            },
            then: {
                type: 'object',
                additionalProperties: false,
                properties: {
                    changedAttributes: {
                        type: 'object',
                        additionalProperties: { type: 'string' },
                    },
                    participant: {
                        type: 'object',
                        properties: {
                            identity: { type: 'string' },
                            sid: { type: 'string' },
                            permissions: {
                                type: 'object',
                                properties: {
                                    canSubscribe: { type: 'boolean' },
                                    canPublish: { type: 'boolean' },
                                    canPublishData: { type: 'boolean' },
                                    hidden: { type: 'boolean' },
                                    canUpdateMetadata: { type: 'boolean' },
                                    canSubscribeMetrics: { type: 'boolean' },
                                },
                                required: [
                                    'canSubscribe',
                                    'canPublish',
                                    'canPublishData',
                                    'hidden',
                                    'canUpdateMetadata',
                                    'canSubscribeMetrics',
                                ],
                                additionalProperties: false,
                            },
                        },
                        required: ['identity', 'sid'],
                        additionalProperties: false,
                    },
                    name: {
                        type: 'string',
                        const: 'participantAttributesChanged',
                        description: "Participant attributes is an app-specific key value state to be pushed to all users. When a participant's attributes changed, this event will be emitted with the changed attributes and the participant args: (changedAttributes: [[Record<string, string]], participant: [[Participant]])",
                    },
                    timestamp: { type: 'number' },
                },
                required: ['changedAttributes', 'name', 'participant', 'timestamp'],
            },
        },
        {
            if: {
                properties: {
                    name: {
                        type: 'string',
                        const: 'activeSpeakersChanged',
                        description: 'Active speakers changed. List of speakers are ordered by their audio level. loudest speakers first. This will include the LocalParticipant too.\n\nSpeaker updates are sent only to the publishing participant and their subscribers.\n\nargs: (Array<[[Participant]]>)',
                    },
                },
            },
            then: {
                type: 'object',
                additionalProperties: false,
                properties: {
                    speakers: {
                        type: 'array',
                        items: {
                            type: 'object',
                            properties: {
                                identity: { type: 'string' },
                                sid: { type: 'string' },
                                permissions: {
                                    type: 'object',
                                    properties: {
                                        canSubscribe: { type: 'boolean' },
                                        canPublish: { type: 'boolean' },
                                        canPublishData: { type: 'boolean' },
                                        hidden: { type: 'boolean' },
                                        canUpdateMetadata: { type: 'boolean' },
                                        canSubscribeMetrics: { type: 'boolean' },
                                    },
                                    required: [
                                        'canSubscribe',
                                        'canPublish',
                                        'canPublishData',
                                        'hidden',
                                        'canUpdateMetadata',
                                        'canSubscribeMetrics',
                                    ],
                                    additionalProperties: false,
                                },
                            },
                            required: ['identity', 'sid'],
                            additionalProperties: false,
                        },
                    },
                    name: {
                        type: 'string',
                        const: 'activeSpeakersChanged',
                        description: 'Active speakers changed. List of speakers are ordered by their audio level. loudest speakers first. This will include the LocalParticipant too.\n\nSpeaker updates are sent only to the publishing participant and their subscribers.\n\nargs: (Array<[[Participant]]>)',
                    },
                    timestamp: { type: 'number' },
                },
                required: ['name', 'speakers', 'timestamp'],
            },
        },
        {
            if: {
                properties: {
                    name: {
                        type: 'string',
                        const: 'roomMetadataChanged',
                        description: "Room metadata is a simple way for app-specific state to be pushed to all users. When RoomService.UpdateRoomMetadata is called to change a room's state,\n*all*  participants in the room will fire this event.\n\nargs: (string)",
                    },
                },
            },
            then: {
                type: 'object',
                additionalProperties: false,
                properties: {
                    metadata: { type: 'string' },
                    name: {
                        type: 'string',
                        const: 'roomMetadataChanged',
                        description: "Room metadata is a simple way for app-specific state to be pushed to all users. When RoomService.UpdateRoomMetadata is called to change a room's state,\n*all*  participants in the room will fire this event.\n\nargs: (string)",
                    },
                    timestamp: { type: 'number' },
                },
                required: ['metadata', 'name', 'timestamp'],
            },
        },
        {
            if: {
                properties: {
                    name: {
                        type: 'string',
                        const: 'connectionQualityChanged',
                        description: "Connection quality was changed for a Participant. It'll receive updates from the local participant, as well as any [[RemoteParticipant]]s that we are subscribed to.\n\nargs: (connectionQuality: [[ConnectionQuality]], participant: [[Participant]])",
                    },
                },
            },
            then: {
                type: 'object',
                additionalProperties: false,
                properties: {
                    quality: { $ref: '#/definitions/ConnectionQuality' },
                    participant: {
                        type: 'object',
                        properties: {
                            identity: { type: 'string' },
                            sid: { type: 'string' },
                            permissions: {
                                type: 'object',
                                properties: {
                                    canSubscribe: { type: 'boolean' },
                                    canPublish: { type: 'boolean' },
                                    canPublishData: { type: 'boolean' },
                                    hidden: { type: 'boolean' },
                                    canUpdateMetadata: { type: 'boolean' },
                                    canSubscribeMetrics: { type: 'boolean' },
                                },
                                required: [
                                    'canSubscribe',
                                    'canPublish',
                                    'canPublishData',
                                    'hidden',
                                    'canUpdateMetadata',
                                    'canSubscribeMetrics',
                                ],
                                additionalProperties: false,
                            },
                        },
                        required: ['identity', 'sid'],
                        additionalProperties: false,
                    },
                    name: {
                        type: 'string',
                        const: 'connectionQualityChanged',
                        description: "Connection quality was changed for a Participant. It'll receive updates from the local participant, as well as any [[RemoteParticipant]]s that we are subscribed to.\n\nargs: (connectionQuality: [[ConnectionQuality]], participant: [[Participant]])",
                    },
                    timestamp: { type: 'number' },
                },
                required: ['name', 'participant', 'quality', 'timestamp'],
            },
        },
        {
            if: {
                properties: {
                    name: {
                        type: 'string',
                        const: 'mediaDevicesError',
                        description: 'When we have encountered an error while attempting to create a track. The errors take place in getUserMedia(). Use MediaDeviceFailure.getFailure(error) to get the reason of failure. [[LocalParticipant.lastCameraError]] and [[LocalParticipant.lastMicrophoneError]] will indicate if it had an error while creating the audio or video track respectively.\n\nargs: (error: Error)',
                    },
                },
            },
            then: {
                type: 'object',
                additionalProperties: false,
                properties: {
                    error: { type: 'string' },
                    name: {
                        type: 'string',
                        const: 'mediaDevicesError',
                        description: 'When we have encountered an error while attempting to create a track. The errors take place in getUserMedia(). Use MediaDeviceFailure.getFailure(error) to get the reason of failure. [[LocalParticipant.lastCameraError]] and [[LocalParticipant.lastMicrophoneError]] will indicate if it had an error while creating the audio or video track respectively.\n\nargs: (error: Error)',
                    },
                    timestamp: { type: 'number' },
                },
                required: ['error', 'name', 'timestamp'],
            },
        },
        {
            if: {
                properties: {
                    name: {
                        type: 'string',
                        const: 'trackStreamStateChanged',
                        description: "StreamState indicates if a subscribed (remote) track has been paused by the SFU (typically this happens because of subscriber's bandwidth constraints)\n\nWhen bandwidth conditions allow, the track will be resumed automatically. TrackStreamStateChanged will also be emitted when that happens.\n\nargs: (pub: [[RemoteTrackPublication]], streamState: [[Track.StreamState]],        participant: [[RemoteParticipant]])",
                    },
                },
            },
            then: {
                type: 'object',
                additionalProperties: false,
                properties: {
                    state: { $ref: '#/definitions/Track.StreamState' },
                    track: {
                        type: 'object',
                        properties: {
                            sid: { type: 'string' },
                            source: { $ref: '#/definitions/Track.Source' },
                            mimeType: { type: 'string' },
                            dimensions: {
                                type: 'object',
                                properties: {
                                    width: { type: 'number' },
                                    height: { type: 'number' },
                                },
                                required: ['width', 'height'],
                                additionalProperties: false,
                            },
                        },
                        required: ['sid', 'source'],
                        additionalProperties: false,
                    },
                    participant: {
                        type: 'object',
                        properties: {
                            identity: { type: 'string' },
                            sid: { type: 'string' },
                            permissions: {
                                type: 'object',
                                properties: {
                                    canSubscribe: { type: 'boolean' },
                                    canPublish: { type: 'boolean' },
                                    canPublishData: { type: 'boolean' },
                                    hidden: { type: 'boolean' },
                                    canUpdateMetadata: { type: 'boolean' },
                                    canSubscribeMetrics: { type: 'boolean' },
                                },
                                required: [
                                    'canSubscribe',
                                    'canPublish',
                                    'canPublishData',
                                    'hidden',
                                    'canUpdateMetadata',
                                    'canSubscribeMetrics',
                                ],
                                additionalProperties: false,
                            },
                        },
                        required: ['identity', 'sid'],
                        additionalProperties: false,
                    },
                    name: {
                        type: 'string',
                        const: 'trackStreamStateChanged',
                        description: "StreamState indicates if a subscribed (remote) track has been paused by the SFU (typically this happens because of subscriber's bandwidth constraints)\n\nWhen bandwidth conditions allow, the track will be resumed automatically. TrackStreamStateChanged will also be emitted when that happens.\n\nargs: (pub: [[RemoteTrackPublication]], streamState: [[Track.StreamState]],        participant: [[RemoteParticipant]])",
                    },
                    timestamp: { type: 'number' },
                },
                required: ['name', 'participant', 'state', 'timestamp', 'track'],
            },
        },
        {
            if: {
                properties: {
                    name: {
                        type: 'string',
                        const: 'trackSubscriptionPermissionChanged',
                        description: 'One of subscribed tracks have changed its permissions for the current participant. If permission was revoked, then the track will no longer be subscribed. If permission was granted, a TrackSubscribed event will be emitted.\n\nargs: (pub: [[RemoteTrackPublication]],        status: [[TrackPublication.PermissionStatus]],        participant: [[RemoteParticipant]])',
                    },
                },
            },
            then: {
                type: 'object',
                additionalProperties: false,
                properties: {
                    status: { $ref: '#/definitions/TrackPublication.PermissionStatus' },
                    track: {
                        type: 'object',
                        properties: {
                            sid: { type: 'string' },
                            source: { $ref: '#/definitions/Track.Source' },
                            mimeType: { type: 'string' },
                            dimensions: {
                                type: 'object',
                                properties: {
                                    width: { type: 'number' },
                                    height: { type: 'number' },
                                },
                                required: ['width', 'height'],
                                additionalProperties: false,
                            },
                        },
                        required: ['sid', 'source'],
                        additionalProperties: false,
                    },
                    participant: {
                        type: 'object',
                        properties: {
                            identity: { type: 'string' },
                            sid: { type: 'string' },
                            permissions: {
                                type: 'object',
                                properties: {
                                    canSubscribe: { type: 'boolean' },
                                    canPublish: { type: 'boolean' },
                                    canPublishData: { type: 'boolean' },
                                    hidden: { type: 'boolean' },
                                    canUpdateMetadata: { type: 'boolean' },
                                    canSubscribeMetrics: { type: 'boolean' },
                                },
                                required: [
                                    'canSubscribe',
                                    'canPublish',
                                    'canPublishData',
                                    'hidden',
                                    'canUpdateMetadata',
                                    'canSubscribeMetrics',
                                ],
                                additionalProperties: false,
                            },
                        },
                        required: ['identity', 'sid'],
                        additionalProperties: false,
                    },
                    name: {
                        type: 'string',
                        const: 'trackSubscriptionPermissionChanged',
                        description: 'One of subscribed tracks have changed its permissions for the current participant. If permission was revoked, then the track will no longer be subscribed. If permission was granted, a TrackSubscribed event will be emitted.\n\nargs: (pub: [[RemoteTrackPublication]],        status: [[TrackPublication.PermissionStatus]],        participant: [[RemoteParticipant]])',
                    },
                    timestamp: { type: 'number' },
                },
                required: ['name', 'participant', 'status', 'timestamp', 'track'],
            },
        },
        {
            if: {
                properties: {
                    name: {
                        type: 'string',
                        const: 'trackSubscriptionStatusChanged',
                        description: 'One of subscribed tracks have changed its status for the current participant.\n\nargs: (pub: [[RemoteTrackPublication]],        status: [[TrackPublication.SubscriptionStatus]],        participant: [[RemoteParticipant]])',
                    },
                },
            },
            then: {
                type: 'object',
                additionalProperties: false,
                properties: {
                    status: { $ref: '#/definitions/TrackPublication.SubscriptionStatus' },
                    track: {
                        type: 'object',
                        properties: {
                            sid: { type: 'string' },
                            source: { $ref: '#/definitions/Track.Source' },
                            mimeType: { type: 'string' },
                            dimensions: {
                                type: 'object',
                                properties: {
                                    width: { type: 'number' },
                                    height: { type: 'number' },
                                },
                                required: ['width', 'height'],
                                additionalProperties: false,
                            },
                        },
                        required: ['sid', 'source'],
                        additionalProperties: false,
                    },
                    participant: {
                        type: 'object',
                        properties: {
                            identity: { type: 'string' },
                            sid: { type: 'string' },
                            permissions: {
                                type: 'object',
                                properties: {
                                    canSubscribe: { type: 'boolean' },
                                    canPublish: { type: 'boolean' },
                                    canPublishData: { type: 'boolean' },
                                    hidden: { type: 'boolean' },
                                    canUpdateMetadata: { type: 'boolean' },
                                    canSubscribeMetrics: { type: 'boolean' },
                                },
                                required: [
                                    'canSubscribe',
                                    'canPublish',
                                    'canPublishData',
                                    'hidden',
                                    'canUpdateMetadata',
                                    'canSubscribeMetrics',
                                ],
                                additionalProperties: false,
                            },
                        },
                        required: ['identity', 'sid'],
                        additionalProperties: false,
                    },
                    name: {
                        type: 'string',
                        const: 'trackSubscriptionStatusChanged',
                        description: 'One of subscribed tracks have changed its status for the current participant.\n\nargs: (pub: [[RemoteTrackPublication]],        status: [[TrackPublication.SubscriptionStatus]],        participant: [[RemoteParticipant]])',
                    },
                    timestamp: { type: 'number' },
                },
                required: ['name', 'participant', 'status', 'timestamp', 'track'],
            },
        },
        {
            if: {
                properties: {
                    name: {
                        type: 'string',
                        const: 'audioPlaybackChanged',
                        description: "LiveKit will attempt to autoplay all audio tracks when you attach them to audio elements. However, if that fails, we'll notify you via AudioPlaybackStatusChanged. `Room.canPlaybackAudio` will indicate if audio playback is permitted.",
                    },
                },
            },
            then: {
                type: 'object',
                additionalProperties: false,
                properties: {
                    playing: { type: 'boolean' },
                    name: {
                        type: 'string',
                        const: 'audioPlaybackChanged',
                        description: "LiveKit will attempt to autoplay all audio tracks when you attach them to audio elements. However, if that fails, we'll notify you via AudioPlaybackStatusChanged. `Room.canPlaybackAudio` will indicate if audio playback is permitted.",
                    },
                    timestamp: { type: 'number' },
                },
                required: ['name', 'playing', 'timestamp'],
            },
        },
        {
            if: {
                properties: {
                    name: {
                        type: 'string',
                        const: 'videoPlaybackChanged',
                        description: "LiveKit will attempt to autoplay all video tracks when you attach them to a video element. However, if that fails, we'll notify you via VideoPlaybackStatusChanged. Calling `room.startVideo()` in a user gesture event handler will resume the video playback.",
                    },
                },
            },
            then: {
                type: 'object',
                additionalProperties: false,
                properties: {
                    playing: { type: 'boolean' },
                    name: {
                        type: 'string',
                        const: 'videoPlaybackChanged',
                        description: "LiveKit will attempt to autoplay all video tracks when you attach them to a video element. However, if that fails, we'll notify you via VideoPlaybackStatusChanged. Calling `room.startVideo()` in a user gesture event handler will resume the video playback.",
                    },
                    timestamp: { type: 'number' },
                },
                required: ['name', 'playing', 'timestamp'],
            },
        },
        {
            if: {
                properties: {
                    name: {
                        type: 'string',
                        const: 'signalConnected',
                        description: 'Signal connected, can publish tracks.',
                    },
                },
            },
            then: {
                type: 'object',
                properties: {
                    name: {
                        type: 'string',
                        const: 'signalConnected',
                        description: 'Signal connected, can publish tracks.',
                    },
                    timestamp: { type: 'number' },
                },
                required: ['name', 'timestamp'],
                additionalProperties: false,
            },
        },
        {
            if: {
                properties: {
                    name: {
                        type: 'string',
                        const: 'recordingStatusChanged',
                        description: 'Recording of a room has started/stopped. Room.isRecording will be updated too. args: (isRecording: boolean)',
                    },
                },
            },
            then: {
                type: 'object',
                additionalProperties: false,
                properties: {
                    recording: { type: 'boolean' },
                    name: {
                        type: 'string',
                        const: 'recordingStatusChanged',
                        description: 'Recording of a room has started/stopped. Room.isRecording will be updated too. args: (isRecording: boolean)',
                    },
                    timestamp: { type: 'number' },
                },
                required: ['name', 'recording', 'timestamp'],
            },
        },
        {
            if: {
                properties: {
                    name: {
                        type: 'string',
                        const: 'activeDeviceChanged',
                        description: 'Triggered by a call to room.switchActiveDevice args: (kind: MediaDeviceKind, deviceId: string)',
                    },
                },
            },
            then: {
                type: 'object',
                additionalProperties: false,
                properties: {
                    kind: {
                        type: 'string',
                        enum: ['audioinput', 'audiooutput', 'videoinput'],
                    },
                    deviceId: { type: 'string' },
                    name: {
                        type: 'string',
                        const: 'activeDeviceChanged',
                        description: 'Triggered by a call to room.switchActiveDevice args: (kind: MediaDeviceKind, deviceId: string)',
                    },
                    timestamp: { type: 'number' },
                },
                required: ['deviceId', 'kind', 'name', 'timestamp'],
            },
        },
        {
            if: {
                properties: {
                    name: {
                        type: 'string',
                        const: 'localTrackSubscribed',
                        description: "fired when the first remote participant has subscribed to the localParticipant's track",
                    },
                },
            },
            then: {
                type: 'object',
                additionalProperties: false,
                properties: {
                    track: {
                        type: 'object',
                        properties: {
                            sid: { type: 'string' },
                            source: { $ref: '#/definitions/Track.Source' },
                            mimeType: { type: 'string' },
                            dimensions: {
                                type: 'object',
                                properties: {
                                    width: { type: 'number' },
                                    height: { type: 'number' },
                                },
                                required: ['width', 'height'],
                                additionalProperties: false,
                            },
                        },
                        required: ['sid', 'source'],
                        additionalProperties: false,
                    },
                    participant: {
                        type: 'object',
                        properties: {
                            identity: { type: 'string' },
                            sid: { type: 'string' },
                            permissions: {
                                type: 'object',
                                properties: {
                                    canSubscribe: { type: 'boolean' },
                                    canPublish: { type: 'boolean' },
                                    canPublishData: { type: 'boolean' },
                                    hidden: { type: 'boolean' },
                                    canUpdateMetadata: { type: 'boolean' },
                                    canSubscribeMetrics: { type: 'boolean' },
                                },
                                required: [
                                    'canSubscribe',
                                    'canPublish',
                                    'canPublishData',
                                    'hidden',
                                    'canUpdateMetadata',
                                    'canSubscribeMetrics',
                                ],
                                additionalProperties: false,
                            },
                        },
                        required: ['identity', 'sid'],
                        additionalProperties: false,
                    },
                    name: {
                        type: 'string',
                        const: 'localTrackSubscribed',
                        description: "fired when the first remote participant has subscribed to the localParticipant's track",
                    },
                    timestamp: { type: 'number' },
                },
                required: ['name', 'participant', 'timestamp', 'track'],
            },
        },
    ],
};
var schema29 = { type: 'number', enum: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10] };
var schema30 = {
    type: 'string',
    enum: [
        'disconnected',
        'connecting',
        'connected',
        'reconnecting',
        'signalReconnecting',
    ],
};
var schema31 = {
    type: 'string',
    enum: [
        'camera',
        'microphone',
        'screen_share',
        'screen_share_audio',
        'unknown',
    ],
};
var schema33 = { type: 'number', enum: [0, 1, 2] };
var schema41 = {
    type: 'string',
    enum: ['excellent', 'good', 'poor', 'lost', 'unknown'],
};
var schema42 = { type: 'string', enum: ['active', 'paused', 'unknown'] };
var schema44 = { type: 'string', enum: ['allowed', 'not_allowed'] };
var schema46 = {
    type: 'string',
    enum: ['desired', 'subscribed', 'unsubscribed'],
};
function validate11(data, _a) {
    var _b = _a === void 0 ? {} : _a, _c = _b.instancePath, instancePath = _c === void 0 ? '' : _c, parentData = _b.parentData, parentDataProperty = _b.parentDataProperty, _d = _b.rootData, rootData = _d === void 0 ? data : _d;
    var vErrors = null;
    var errors = 0;
    var _errs1 = errors;
    var _errs2 = errors;
    var valid1 = true;
    var _errs3 = errors;
    if (data && typeof data == 'object' && !Array.isArray(data)) {
        if (data.name !== undefined) {
            var data0 = data.name;
            if (typeof data0 !== 'string') {
                var err0 = {};
                if (vErrors === null) {
                    vErrors = [err0];
                }
                else {
                    vErrors.push(err0);
                }
                errors++;
            }
            if ('connected' !== data0) {
                var err1 = {};
                if (vErrors === null) {
                    vErrors = [err1];
                }
                else {
                    vErrors.push(err1);
                }
                errors++;
            }
        }
    }
    var _valid0 = _errs3 === errors;
    errors = _errs2;
    if (vErrors !== null) {
        if (_errs2) {
            vErrors.length = _errs2;
        }
        else {
            vErrors = null;
        }
    }
    if (_valid0) {
        var _errs6 = errors;
        if (errors === _errs6) {
            if (data && typeof data == 'object' && !Array.isArray(data)) {
                var missing0 = void 0;
                if ((data.name === undefined && (missing0 = 'name')) ||
                    (data.timestamp === undefined && (missing0 = 'timestamp'))) {
                    validate11.errors = [
                        {
                            instancePath: instancePath,
                            schemaPath: '#/allOf/0/then/required',
                            keyword: 'required',
                            params: { missingProperty: missing0 },
                            message: "must have required property '" + missing0 + "'",
                        },
                    ];
                    return false;
                }
                else {
                    var _errs8 = errors;
                    for (var key0 in data) {
                        if (!(key0 === 'name' || key0 === 'timestamp')) {
                            validate11.errors = [
                                {
                                    instancePath: instancePath,
                                    schemaPath: '#/allOf/0/then/additionalProperties',
                                    keyword: 'additionalProperties',
                                    params: { additionalProperty: key0 },
                                    message: 'must NOT have additional properties',
                                },
                            ];
                            return false;
                            break;
                        }
                    }
                    if (_errs8 === errors) {
                        if (data.name !== undefined) {
                            var data1 = data.name;
                            var _errs9 = errors;
                            if (typeof data1 !== 'string') {
                                validate11.errors = [
                                    {
                                        instancePath: instancePath + '/name',
                                        schemaPath: '#/allOf/0/then/properties/name/type',
                                        keyword: 'type',
                                        params: { type: 'string' },
                                        message: 'must be string',
                                    },
                                ];
                                return false;
                            }
                            if ('connected' !== data1) {
                                validate11.errors = [
                                    {
                                        instancePath: instancePath + '/name',
                                        schemaPath: '#/allOf/0/then/properties/name/const',
                                        keyword: 'const',
                                        params: { allowedValue: 'connected' },
                                        message: 'must be equal to constant',
                                    },
                                ];
                                return false;
                            }
                            var valid3 = _errs9 === errors;
                        }
                        else {
                            var valid3 = true;
                        }
                        if (valid3) {
                            if (data.timestamp !== undefined) {
                                var data2 = data.timestamp;
                                var _errs11 = errors;
                                if (!(typeof data2 == 'number' && isFinite(data2))) {
                                    validate11.errors = [
                                        {
                                            instancePath: instancePath + '/timestamp',
                                            schemaPath: '#/allOf/0/then/properties/timestamp/type',
                                            keyword: 'type',
                                            params: { type: 'number' },
                                            message: 'must be number',
                                        },
                                    ];
                                    return false;
                                }
                                var valid3 = _errs11 === errors;
                            }
                            else {
                                var valid3 = true;
                            }
                        }
                    }
                }
            }
            else {
                validate11.errors = [
                    {
                        instancePath: instancePath,
                        schemaPath: '#/allOf/0/then/type',
                        keyword: 'type',
                        params: { type: 'object' },
                        message: 'must be object',
                    },
                ];
                return false;
            }
        }
        var _valid0 = _errs6 === errors;
        valid1 = _valid0;
    }
    if (!valid1) {
        var err2 = {
            instancePath: instancePath,
            schemaPath: '#/allOf/0/if',
            keyword: 'if',
            params: { failingKeyword: 'then' },
            message: 'must match "then" schema',
        };
        if (vErrors === null) {
            vErrors = [err2];
        }
        else {
            vErrors.push(err2);
        }
        errors++;
        validate11.errors = vErrors;
        return false;
    }
    var valid0 = _errs1 === errors;
    if (valid0) {
        var _errs13 = errors;
        var _errs14 = errors;
        var valid4 = true;
        var _errs15 = errors;
        if (data && typeof data == 'object' && !Array.isArray(data)) {
            if (data.name !== undefined) {
                var data3 = data.name;
                if (typeof data3 !== 'string') {
                    var err3 = {};
                    if (vErrors === null) {
                        vErrors = [err3];
                    }
                    else {
                        vErrors.push(err3);
                    }
                    errors++;
                }
                if ('reconnecting' !== data3) {
                    var err4 = {};
                    if (vErrors === null) {
                        vErrors = [err4];
                    }
                    else {
                        vErrors.push(err4);
                    }
                    errors++;
                }
            }
        }
        var _valid1 = _errs15 === errors;
        errors = _errs14;
        if (vErrors !== null) {
            if (_errs14) {
                vErrors.length = _errs14;
            }
            else {
                vErrors = null;
            }
        }
        if (_valid1) {
            var _errs18 = errors;
            if (errors === _errs18) {
                if (data && typeof data == 'object' && !Array.isArray(data)) {
                    var missing1 = void 0;
                    if ((data.name === undefined && (missing1 = 'name')) ||
                        (data.timestamp === undefined && (missing1 = 'timestamp'))) {
                        validate11.errors = [
                            {
                                instancePath: instancePath,
                                schemaPath: '#/allOf/1/then/required',
                                keyword: 'required',
                                params: { missingProperty: missing1 },
                                message: "must have required property '" + missing1 + "'",
                            },
                        ];
                        return false;
                    }
                    else {
                        var _errs20 = errors;
                        for (var key1 in data) {
                            if (!(key1 === 'name' || key1 === 'timestamp')) {
                                validate11.errors = [
                                    {
                                        instancePath: instancePath,
                                        schemaPath: '#/allOf/1/then/additionalProperties',
                                        keyword: 'additionalProperties',
                                        params: { additionalProperty: key1 },
                                        message: 'must NOT have additional properties',
                                    },
                                ];
                                return false;
                                break;
                            }
                        }
                        if (_errs20 === errors) {
                            if (data.name !== undefined) {
                                var data4 = data.name;
                                var _errs21 = errors;
                                if (typeof data4 !== 'string') {
                                    validate11.errors = [
                                        {
                                            instancePath: instancePath + '/name',
                                            schemaPath: '#/allOf/1/then/properties/name/type',
                                            keyword: 'type',
                                            params: { type: 'string' },
                                            message: 'must be string',
                                        },
                                    ];
                                    return false;
                                }
                                if ('reconnecting' !== data4) {
                                    validate11.errors = [
                                        {
                                            instancePath: instancePath + '/name',
                                            schemaPath: '#/allOf/1/then/properties/name/const',
                                            keyword: 'const',
                                            params: { allowedValue: 'reconnecting' },
                                            message: 'must be equal to constant',
                                        },
                                    ];
                                    return false;
                                }
                                var valid6 = _errs21 === errors;
                            }
                            else {
                                var valid6 = true;
                            }
                            if (valid6) {
                                if (data.timestamp !== undefined) {
                                    var data5 = data.timestamp;
                                    var _errs23 = errors;
                                    if (!(typeof data5 == 'number' && isFinite(data5))) {
                                        validate11.errors = [
                                            {
                                                instancePath: instancePath + '/timestamp',
                                                schemaPath: '#/allOf/1/then/properties/timestamp/type',
                                                keyword: 'type',
                                                params: { type: 'number' },
                                                message: 'must be number',
                                            },
                                        ];
                                        return false;
                                    }
                                    var valid6 = _errs23 === errors;
                                }
                                else {
                                    var valid6 = true;
                                }
                            }
                        }
                    }
                }
                else {
                    validate11.errors = [
                        {
                            instancePath: instancePath,
                            schemaPath: '#/allOf/1/then/type',
                            keyword: 'type',
                            params: { type: 'object' },
                            message: 'must be object',
                        },
                    ];
                    return false;
                }
            }
            var _valid1 = _errs18 === errors;
            valid4 = _valid1;
        }
        if (!valid4) {
            var err5 = {
                instancePath: instancePath,
                schemaPath: '#/allOf/1/if',
                keyword: 'if',
                params: { failingKeyword: 'then' },
                message: 'must match "then" schema',
            };
            if (vErrors === null) {
                vErrors = [err5];
            }
            else {
                vErrors.push(err5);
            }
            errors++;
            validate11.errors = vErrors;
            return false;
        }
        var valid0 = _errs13 === errors;
        if (valid0) {
            var _errs25 = errors;
            var _errs26 = errors;
            var valid7 = true;
            var _errs27 = errors;
            if (data && typeof data == 'object' && !Array.isArray(data)) {
                if (data.name !== undefined) {
                    var data6 = data.name;
                    if (typeof data6 !== 'string') {
                        var err6 = {};
                        if (vErrors === null) {
                            vErrors = [err6];
                        }
                        else {
                            vErrors.push(err6);
                        }
                        errors++;
                    }
                    if ('signalReconnecting' !== data6) {
                        var err7 = {};
                        if (vErrors === null) {
                            vErrors = [err7];
                        }
                        else {
                            vErrors.push(err7);
                        }
                        errors++;
                    }
                }
            }
            var _valid2 = _errs27 === errors;
            errors = _errs26;
            if (vErrors !== null) {
                if (_errs26) {
                    vErrors.length = _errs26;
                }
                else {
                    vErrors = null;
                }
            }
            if (_valid2) {
                var _errs30 = errors;
                if (errors === _errs30) {
                    if (data && typeof data == 'object' && !Array.isArray(data)) {
                        var missing2 = void 0;
                        if ((data.name === undefined && (missing2 = 'name')) ||
                            (data.timestamp === undefined && (missing2 = 'timestamp'))) {
                            validate11.errors = [
                                {
                                    instancePath: instancePath,
                                    schemaPath: '#/allOf/2/then/required',
                                    keyword: 'required',
                                    params: { missingProperty: missing2 },
                                    message: "must have required property '" + missing2 + "'",
                                },
                            ];
                            return false;
                        }
                        else {
                            var _errs32 = errors;
                            for (var key2 in data) {
                                if (!(key2 === 'name' || key2 === 'timestamp')) {
                                    validate11.errors = [
                                        {
                                            instancePath: instancePath,
                                            schemaPath: '#/allOf/2/then/additionalProperties',
                                            keyword: 'additionalProperties',
                                            params: { additionalProperty: key2 },
                                            message: 'must NOT have additional properties',
                                        },
                                    ];
                                    return false;
                                    break;
                                }
                            }
                            if (_errs32 === errors) {
                                if (data.name !== undefined) {
                                    var data7 = data.name;
                                    var _errs33 = errors;
                                    if (typeof data7 !== 'string') {
                                        validate11.errors = [
                                            {
                                                instancePath: instancePath + '/name',
                                                schemaPath: '#/allOf/2/then/properties/name/type',
                                                keyword: 'type',
                                                params: { type: 'string' },
                                                message: 'must be string',
                                            },
                                        ];
                                        return false;
                                    }
                                    if ('signalReconnecting' !== data7) {
                                        validate11.errors = [
                                            {
                                                instancePath: instancePath + '/name',
                                                schemaPath: '#/allOf/2/then/properties/name/const',
                                                keyword: 'const',
                                                params: { allowedValue: 'signalReconnecting' },
                                                message: 'must be equal to constant',
                                            },
                                        ];
                                        return false;
                                    }
                                    var valid9 = _errs33 === errors;
                                }
                                else {
                                    var valid9 = true;
                                }
                                if (valid9) {
                                    if (data.timestamp !== undefined) {
                                        var data8 = data.timestamp;
                                        var _errs35 = errors;
                                        if (!(typeof data8 == 'number' && isFinite(data8))) {
                                            validate11.errors = [
                                                {
                                                    instancePath: instancePath + '/timestamp',
                                                    schemaPath: '#/allOf/2/then/properties/timestamp/type',
                                                    keyword: 'type',
                                                    params: { type: 'number' },
                                                    message: 'must be number',
                                                },
                                            ];
                                            return false;
                                        }
                                        var valid9 = _errs35 === errors;
                                    }
                                    else {
                                        var valid9 = true;
                                    }
                                }
                            }
                        }
                    }
                    else {
                        validate11.errors = [
                            {
                                instancePath: instancePath,
                                schemaPath: '#/allOf/2/then/type',
                                keyword: 'type',
                                params: { type: 'object' },
                                message: 'must be object',
                            },
                        ];
                        return false;
                    }
                }
                var _valid2 = _errs30 === errors;
                valid7 = _valid2;
            }
            if (!valid7) {
                var err8 = {
                    instancePath: instancePath,
                    schemaPath: '#/allOf/2/if',
                    keyword: 'if',
                    params: { failingKeyword: 'then' },
                    message: 'must match "then" schema',
                };
                if (vErrors === null) {
                    vErrors = [err8];
                }
                else {
                    vErrors.push(err8);
                }
                errors++;
                validate11.errors = vErrors;
                return false;
            }
            var valid0 = _errs25 === errors;
            if (valid0) {
                var _errs37 = errors;
                var _errs38 = errors;
                var valid10 = true;
                var _errs39 = errors;
                if (data && typeof data == 'object' && !Array.isArray(data)) {
                    if (data.name !== undefined) {
                        var data9 = data.name;
                        if (typeof data9 !== 'string') {
                            var err9 = {};
                            if (vErrors === null) {
                                vErrors = [err9];
                            }
                            else {
                                vErrors.push(err9);
                            }
                            errors++;
                        }
                        if ('reconnected' !== data9) {
                            var err10 = {};
                            if (vErrors === null) {
                                vErrors = [err10];
                            }
                            else {
                                vErrors.push(err10);
                            }
                            errors++;
                        }
                    }
                }
                var _valid3 = _errs39 === errors;
                errors = _errs38;
                if (vErrors !== null) {
                    if (_errs38) {
                        vErrors.length = _errs38;
                    }
                    else {
                        vErrors = null;
                    }
                }
                if (_valid3) {
                    var _errs42 = errors;
                    if (errors === _errs42) {
                        if (data && typeof data == 'object' && !Array.isArray(data)) {
                            var missing3 = void 0;
                            if ((data.name === undefined && (missing3 = 'name')) ||
                                (data.timestamp === undefined && (missing3 = 'timestamp'))) {
                                validate11.errors = [
                                    {
                                        instancePath: instancePath,
                                        schemaPath: '#/allOf/3/then/required',
                                        keyword: 'required',
                                        params: { missingProperty: missing3 },
                                        message: "must have required property '" + missing3 + "'",
                                    },
                                ];
                                return false;
                            }
                            else {
                                var _errs44 = errors;
                                for (var key3 in data) {
                                    if (!(key3 === 'name' || key3 === 'timestamp')) {
                                        validate11.errors = [
                                            {
                                                instancePath: instancePath,
                                                schemaPath: '#/allOf/3/then/additionalProperties',
                                                keyword: 'additionalProperties',
                                                params: { additionalProperty: key3 },
                                                message: 'must NOT have additional properties',
                                            },
                                        ];
                                        return false;
                                        break;
                                    }
                                }
                                if (_errs44 === errors) {
                                    if (data.name !== undefined) {
                                        var data10 = data.name;
                                        var _errs45 = errors;
                                        if (typeof data10 !== 'string') {
                                            validate11.errors = [
                                                {
                                                    instancePath: instancePath + '/name',
                                                    schemaPath: '#/allOf/3/then/properties/name/type',
                                                    keyword: 'type',
                                                    params: { type: 'string' },
                                                    message: 'must be string',
                                                },
                                            ];
                                            return false;
                                        }
                                        if ('reconnected' !== data10) {
                                            validate11.errors = [
                                                {
                                                    instancePath: instancePath + '/name',
                                                    schemaPath: '#/allOf/3/then/properties/name/const',
                                                    keyword: 'const',
                                                    params: { allowedValue: 'reconnected' },
                                                    message: 'must be equal to constant',
                                                },
                                            ];
                                            return false;
                                        }
                                        var valid12 = _errs45 === errors;
                                    }
                                    else {
                                        var valid12 = true;
                                    }
                                    if (valid12) {
                                        if (data.timestamp !== undefined) {
                                            var data11 = data.timestamp;
                                            var _errs47 = errors;
                                            if (!(typeof data11 == 'number' && isFinite(data11))) {
                                                validate11.errors = [
                                                    {
                                                        instancePath: instancePath + '/timestamp',
                                                        schemaPath: '#/allOf/3/then/properties/timestamp/type',
                                                        keyword: 'type',
                                                        params: { type: 'number' },
                                                        message: 'must be number',
                                                    },
                                                ];
                                                return false;
                                            }
                                            var valid12 = _errs47 === errors;
                                        }
                                        else {
                                            var valid12 = true;
                                        }
                                    }
                                }
                            }
                        }
                        else {
                            validate11.errors = [
                                {
                                    instancePath: instancePath,
                                    schemaPath: '#/allOf/3/then/type',
                                    keyword: 'type',
                                    params: { type: 'object' },
                                    message: 'must be object',
                                },
                            ];
                            return false;
                        }
                    }
                    var _valid3 = _errs42 === errors;
                    valid10 = _valid3;
                }
                if (!valid10) {
                    var err11 = {
                        instancePath: instancePath,
                        schemaPath: '#/allOf/3/if',
                        keyword: 'if',
                        params: { failingKeyword: 'then' },
                        message: 'must match "then" schema',
                    };
                    if (vErrors === null) {
                        vErrors = [err11];
                    }
                    else {
                        vErrors.push(err11);
                    }
                    errors++;
                    validate11.errors = vErrors;
                    return false;
                }
                var valid0 = _errs37 === errors;
                if (valid0) {
                    var _errs49 = errors;
                    var _errs50 = errors;
                    var valid13 = true;
                    var _errs51 = errors;
                    if (data && typeof data == 'object' && !Array.isArray(data)) {
                        if (data.name !== undefined) {
                            var data12 = data.name;
                            if (typeof data12 !== 'string') {
                                var err12 = {};
                                if (vErrors === null) {
                                    vErrors = [err12];
                                }
                                else {
                                    vErrors.push(err12);
                                }
                                errors++;
                            }
                            if ('disconnected' !== data12) {
                                var err13 = {};
                                if (vErrors === null) {
                                    vErrors = [err13];
                                }
                                else {
                                    vErrors.push(err13);
                                }
                                errors++;
                            }
                        }
                    }
                    var _valid4 = _errs51 === errors;
                    errors = _errs50;
                    if (vErrors !== null) {
                        if (_errs50) {
                            vErrors.length = _errs50;
                        }
                        else {
                            vErrors = null;
                        }
                    }
                    if (_valid4) {
                        var _errs54 = errors;
                        if (errors === _errs54) {
                            if (data && typeof data == 'object' && !Array.isArray(data)) {
                                var missing4 = void 0;
                                if ((data.name === undefined && (missing4 = 'name')) ||
                                    (data.timestamp === undefined && (missing4 = 'timestamp'))) {
                                    validate11.errors = [
                                        {
                                            instancePath: instancePath,
                                            schemaPath: '#/allOf/4/then/required',
                                            keyword: 'required',
                                            params: { missingProperty: missing4 },
                                            message: "must have required property '" + missing4 + "'",
                                        },
                                    ];
                                    return false;
                                }
                                else {
                                    var _errs56 = errors;
                                    for (var key4 in data) {
                                        if (!(key4 === 'reason' ||
                                            key4 === 'name' ||
                                            key4 === 'timestamp')) {
                                            validate11.errors = [
                                                {
                                                    instancePath: instancePath,
                                                    schemaPath: '#/allOf/4/then/additionalProperties',
                                                    keyword: 'additionalProperties',
                                                    params: { additionalProperty: key4 },
                                                    message: 'must NOT have additional properties',
                                                },
                                            ];
                                            return false;
                                            break;
                                        }
                                    }
                                    if (_errs56 === errors) {
                                        if (data.reason !== undefined) {
                                            var data13 = data.reason;
                                            var _errs57 = errors;
                                            if (!(typeof data13 == 'number' && isFinite(data13))) {
                                                validate11.errors = [
                                                    {
                                                        instancePath: instancePath + '/reason',
                                                        schemaPath: '#/definitions/DisconnectReason/type',
                                                        keyword: 'type',
                                                        params: { type: 'number' },
                                                        message: 'must be number',
                                                    },
                                                ];
                                                return false;
                                            }
                                            if (!(data13 === 0 ||
                                                data13 === 1 ||
                                                data13 === 2 ||
                                                data13 === 3 ||
                                                data13 === 4 ||
                                                data13 === 5 ||
                                                data13 === 6 ||
                                                data13 === 7 ||
                                                data13 === 8 ||
                                                data13 === 9 ||
                                                data13 === 10)) {
                                                validate11.errors = [
                                                    {
                                                        instancePath: instancePath + '/reason',
                                                        schemaPath: '#/definitions/DisconnectReason/enum',
                                                        keyword: 'enum',
                                                        params: { allowedValues: schema29.enum },
                                                        message: 'must be equal to one of the allowed values',
                                                    },
                                                ];
                                                return false;
                                            }
                                            var valid15 = _errs57 === errors;
                                        }
                                        else {
                                            var valid15 = true;
                                        }
                                        if (valid15) {
                                            if (data.name !== undefined) {
                                                var data14 = data.name;
                                                var _errs60 = errors;
                                                if (typeof data14 !== 'string') {
                                                    validate11.errors = [
                                                        {
                                                            instancePath: instancePath + '/name',
                                                            schemaPath: '#/allOf/4/then/properties/name/type',
                                                            keyword: 'type',
                                                            params: { type: 'string' },
                                                            message: 'must be string',
                                                        },
                                                    ];
                                                    return false;
                                                }
                                                if ('disconnected' !== data14) {
                                                    validate11.errors = [
                                                        {
                                                            instancePath: instancePath + '/name',
                                                            schemaPath: '#/allOf/4/then/properties/name/const',
                                                            keyword: 'const',
                                                            params: { allowedValue: 'disconnected' },
                                                            message: 'must be equal to constant',
                                                        },
                                                    ];
                                                    return false;
                                                }
                                                var valid15 = _errs60 === errors;
                                            }
                                            else {
                                                var valid15 = true;
                                            }
                                            if (valid15) {
                                                if (data.timestamp !== undefined) {
                                                    var data15 = data.timestamp;
                                                    var _errs62 = errors;
                                                    if (!(typeof data15 == 'number' && isFinite(data15))) {
                                                        validate11.errors = [
                                                            {
                                                                instancePath: instancePath + '/timestamp',
                                                                schemaPath: '#/allOf/4/then/properties/timestamp/type',
                                                                keyword: 'type',
                                                                params: { type: 'number' },
                                                                message: 'must be number',
                                                            },
                                                        ];
                                                        return false;
                                                    }
                                                    var valid15 = _errs62 === errors;
                                                }
                                                else {
                                                    var valid15 = true;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                            else {
                                validate11.errors = [
                                    {
                                        instancePath: instancePath,
                                        schemaPath: '#/allOf/4/then/type',
                                        keyword: 'type',
                                        params: { type: 'object' },
                                        message: 'must be object',
                                    },
                                ];
                                return false;
                            }
                        }
                        var _valid4 = _errs54 === errors;
                        valid13 = _valid4;
                    }
                    if (!valid13) {
                        var err14 = {
                            instancePath: instancePath,
                            schemaPath: '#/allOf/4/if',
                            keyword: 'if',
                            params: { failingKeyword: 'then' },
                            message: 'must match "then" schema',
                        };
                        if (vErrors === null) {
                            vErrors = [err14];
                        }
                        else {
                            vErrors.push(err14);
                        }
                        errors++;
                        validate11.errors = vErrors;
                        return false;
                    }
                    var valid0 = _errs49 === errors;
                    if (valid0) {
                        var _errs64 = errors;
                        var _errs65 = errors;
                        var valid17 = true;
                        var _errs66 = errors;
                        if (data && typeof data == 'object' && !Array.isArray(data)) {
                            if (data.name !== undefined) {
                                var data16 = data.name;
                                if (typeof data16 !== 'string') {
                                    var err15 = {};
                                    if (vErrors === null) {
                                        vErrors = [err15];
                                    }
                                    else {
                                        vErrors.push(err15);
                                    }
                                    errors++;
                                }
                                if ('connectionStateChanged' !== data16) {
                                    var err16 = {};
                                    if (vErrors === null) {
                                        vErrors = [err16];
                                    }
                                    else {
                                        vErrors.push(err16);
                                    }
                                    errors++;
                                }
                            }
                        }
                        var _valid5 = _errs66 === errors;
                        errors = _errs65;
                        if (vErrors !== null) {
                            if (_errs65) {
                                vErrors.length = _errs65;
                            }
                            else {
                                vErrors = null;
                            }
                        }
                        if (_valid5) {
                            var _errs69 = errors;
                            if (errors === _errs69) {
                                if (data && typeof data == 'object' && !Array.isArray(data)) {
                                    var missing5 = void 0;
                                    if ((data.name === undefined && (missing5 = 'name')) ||
                                        (data.state === undefined && (missing5 = 'state')) ||
                                        (data.timestamp === undefined && (missing5 = 'timestamp'))) {
                                        validate11.errors = [
                                            {
                                                instancePath: instancePath,
                                                schemaPath: '#/allOf/5/then/required',
                                                keyword: 'required',
                                                params: { missingProperty: missing5 },
                                                message: "must have required property '" + missing5 + "'",
                                            },
                                        ];
                                        return false;
                                    }
                                    else {
                                        var _errs71 = errors;
                                        for (var key5 in data) {
                                            if (!(key5 === 'state' ||
                                                key5 === 'name' ||
                                                key5 === 'timestamp')) {
                                                validate11.errors = [
                                                    {
                                                        instancePath: instancePath,
                                                        schemaPath: '#/allOf/5/then/additionalProperties',
                                                        keyword: 'additionalProperties',
                                                        params: { additionalProperty: key5 },
                                                        message: 'must NOT have additional properties',
                                                    },
                                                ];
                                                return false;
                                                break;
                                            }
                                        }
                                        if (_errs71 === errors) {
                                            if (data.state !== undefined) {
                                                var data17 = data.state;
                                                var _errs72 = errors;
                                                if (typeof data17 !== 'string') {
                                                    validate11.errors = [
                                                        {
                                                            instancePath: instancePath + '/state',
                                                            schemaPath: '#/definitions/ConnectionState/type',
                                                            keyword: 'type',
                                                            params: { type: 'string' },
                                                            message: 'must be string',
                                                        },
                                                    ];
                                                    return false;
                                                }
                                                if (!(data17 === 'disconnected' ||
                                                    data17 === 'connecting' ||
                                                    data17 === 'connected' ||
                                                    data17 === 'reconnecting' ||
                                                    data17 === 'signalReconnecting')) {
                                                    validate11.errors = [
                                                        {
                                                            instancePath: instancePath + '/state',
                                                            schemaPath: '#/definitions/ConnectionState/enum',
                                                            keyword: 'enum',
                                                            params: { allowedValues: schema30.enum },
                                                            message: 'must be equal to one of the allowed values',
                                                        },
                                                    ];
                                                    return false;
                                                }
                                                var valid19 = _errs72 === errors;
                                            }
                                            else {
                                                var valid19 = true;
                                            }
                                            if (valid19) {
                                                if (data.name !== undefined) {
                                                    var data18 = data.name;
                                                    var _errs75 = errors;
                                                    if (typeof data18 !== 'string') {
                                                        validate11.errors = [
                                                            {
                                                                instancePath: instancePath + '/name',
                                                                schemaPath: '#/allOf/5/then/properties/name/type',
                                                                keyword: 'type',
                                                                params: { type: 'string' },
                                                                message: 'must be string',
                                                            },
                                                        ];
                                                        return false;
                                                    }
                                                    if ('connectionStateChanged' !== data18) {
                                                        validate11.errors = [
                                                            {
                                                                instancePath: instancePath + '/name',
                                                                schemaPath: '#/allOf/5/then/properties/name/const',
                                                                keyword: 'const',
                                                                params: {
                                                                    allowedValue: 'connectionStateChanged',
                                                                },
                                                                message: 'must be equal to constant',
                                                            },
                                                        ];
                                                        return false;
                                                    }
                                                    var valid19 = _errs75 === errors;
                                                }
                                                else {
                                                    var valid19 = true;
                                                }
                                                if (valid19) {
                                                    if (data.timestamp !== undefined) {
                                                        var data19 = data.timestamp;
                                                        var _errs77 = errors;
                                                        if (!(typeof data19 == 'number' && isFinite(data19))) {
                                                            validate11.errors = [
                                                                {
                                                                    instancePath: instancePath + '/timestamp',
                                                                    schemaPath: '#/allOf/5/then/properties/timestamp/type',
                                                                    keyword: 'type',
                                                                    params: { type: 'number' },
                                                                    message: 'must be number',
                                                                },
                                                            ];
                                                            return false;
                                                        }
                                                        var valid19 = _errs77 === errors;
                                                    }
                                                    else {
                                                        var valid19 = true;
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                                else {
                                    validate11.errors = [
                                        {
                                            instancePath: instancePath,
                                            schemaPath: '#/allOf/5/then/type',
                                            keyword: 'type',
                                            params: { type: 'object' },
                                            message: 'must be object',
                                        },
                                    ];
                                    return false;
                                }
                            }
                            var _valid5 = _errs69 === errors;
                            valid17 = _valid5;
                        }
                        if (!valid17) {
                            var err17 = {
                                instancePath: instancePath,
                                schemaPath: '#/allOf/5/if',
                                keyword: 'if',
                                params: { failingKeyword: 'then' },
                                message: 'must match "then" schema',
                            };
                            if (vErrors === null) {
                                vErrors = [err17];
                            }
                            else {
                                vErrors.push(err17);
                            }
                            errors++;
                            validate11.errors = vErrors;
                            return false;
                        }
                        var valid0 = _errs64 === errors;
                        if (valid0) {
                            var _errs79 = errors;
                            var _errs80 = errors;
                            var valid21 = true;
                            var _errs81 = errors;
                            if (data && typeof data == 'object' && !Array.isArray(data)) {
                                if (data.name !== undefined) {
                                    var data20 = data.name;
                                    if (typeof data20 !== 'string') {
                                        var err18 = {};
                                        if (vErrors === null) {
                                            vErrors = [err18];
                                        }
                                        else {
                                            vErrors.push(err18);
                                        }
                                        errors++;
                                    }
                                    if ('mediaDevicesChanged' !== data20) {
                                        var err19 = {};
                                        if (vErrors === null) {
                                            vErrors = [err19];
                                        }
                                        else {
                                            vErrors.push(err19);
                                        }
                                        errors++;
                                    }
                                }
                            }
                            var _valid6 = _errs81 === errors;
                            errors = _errs80;
                            if (vErrors !== null) {
                                if (_errs80) {
                                    vErrors.length = _errs80;
                                }
                                else {
                                    vErrors = null;
                                }
                            }
                            if (_valid6) {
                                var _errs84 = errors;
                                if (errors === _errs84) {
                                    if (data && typeof data == 'object' && !Array.isArray(data)) {
                                        var missing6 = void 0;
                                        if ((data.name === undefined && (missing6 = 'name')) ||
                                            (data.timestamp === undefined && (missing6 = 'timestamp'))) {
                                            validate11.errors = [
                                                {
                                                    instancePath: instancePath,
                                                    schemaPath: '#/allOf/6/then/required',
                                                    keyword: 'required',
                                                    params: { missingProperty: missing6 },
                                                    message: "must have required property '" + missing6 + "'",
                                                },
                                            ];
                                            return false;
                                        }
                                        else {
                                            var _errs86 = errors;
                                            for (var key6 in data) {
                                                if (!(key6 === 'name' || key6 === 'timestamp')) {
                                                    validate11.errors = [
                                                        {
                                                            instancePath: instancePath,
                                                            schemaPath: '#/allOf/6/then/additionalProperties',
                                                            keyword: 'additionalProperties',
                                                            params: { additionalProperty: key6 },
                                                            message: 'must NOT have additional properties',
                                                        },
                                                    ];
                                                    return false;
                                                    break;
                                                }
                                            }
                                            if (_errs86 === errors) {
                                                if (data.name !== undefined) {
                                                    var data21 = data.name;
                                                    var _errs87 = errors;
                                                    if (typeof data21 !== 'string') {
                                                        validate11.errors = [
                                                            {
                                                                instancePath: instancePath + '/name',
                                                                schemaPath: '#/allOf/6/then/properties/name/type',
                                                                keyword: 'type',
                                                                params: { type: 'string' },
                                                                message: 'must be string',
                                                            },
                                                        ];
                                                        return false;
                                                    }
                                                    if ('mediaDevicesChanged' !== data21) {
                                                        validate11.errors = [
                                                            {
                                                                instancePath: instancePath + '/name',
                                                                schemaPath: '#/allOf/6/then/properties/name/const',
                                                                keyword: 'const',
                                                                params: { allowedValue: 'mediaDevicesChanged' },
                                                                message: 'must be equal to constant',
                                                            },
                                                        ];
                                                        return false;
                                                    }
                                                    var valid23 = _errs87 === errors;
                                                }
                                                else {
                                                    var valid23 = true;
                                                }
                                                if (valid23) {
                                                    if (data.timestamp !== undefined) {
                                                        var data22 = data.timestamp;
                                                        var _errs89 = errors;
                                                        if (!(typeof data22 == 'number' && isFinite(data22))) {
                                                            validate11.errors = [
                                                                {
                                                                    instancePath: instancePath + '/timestamp',
                                                                    schemaPath: '#/allOf/6/then/properties/timestamp/type',
                                                                    keyword: 'type',
                                                                    params: { type: 'number' },
                                                                    message: 'must be number',
                                                                },
                                                            ];
                                                            return false;
                                                        }
                                                        var valid23 = _errs89 === errors;
                                                    }
                                                    else {
                                                        var valid23 = true;
                                                    }
                                                }
                                            }
                                        }
                                    }
                                    else {
                                        validate11.errors = [
                                            {
                                                instancePath: instancePath,
                                                schemaPath: '#/allOf/6/then/type',
                                                keyword: 'type',
                                                params: { type: 'object' },
                                                message: 'must be object',
                                            },
                                        ];
                                        return false;
                                    }
                                }
                                var _valid6 = _errs84 === errors;
                                valid21 = _valid6;
                            }
                            if (!valid21) {
                                var err20 = {
                                    instancePath: instancePath,
                                    schemaPath: '#/allOf/6/if',
                                    keyword: 'if',
                                    params: { failingKeyword: 'then' },
                                    message: 'must match "then" schema',
                                };
                                if (vErrors === null) {
                                    vErrors = [err20];
                                }
                                else {
                                    vErrors.push(err20);
                                }
                                errors++;
                                validate11.errors = vErrors;
                                return false;
                            }
                            var valid0 = _errs79 === errors;
                            if (valid0) {
                                var _errs91 = errors;
                                var _errs92 = errors;
                                var valid24 = true;
                                var _errs93 = errors;
                                if (data && typeof data == 'object' && !Array.isArray(data)) {
                                    if (data.name !== undefined) {
                                        var data23 = data.name;
                                        if (typeof data23 !== 'string') {
                                            var err21 = {};
                                            if (vErrors === null) {
                                                vErrors = [err21];
                                            }
                                            else {
                                                vErrors.push(err21);
                                            }
                                            errors++;
                                        }
                                        if ('participantConnected' !== data23) {
                                            var err22 = {};
                                            if (vErrors === null) {
                                                vErrors = [err22];
                                            }
                                            else {
                                                vErrors.push(err22);
                                            }
                                            errors++;
                                        }
                                    }
                                }
                                var _valid7 = _errs93 === errors;
                                errors = _errs92;
                                if (vErrors !== null) {
                                    if (_errs92) {
                                        vErrors.length = _errs92;
                                    }
                                    else {
                                        vErrors = null;
                                    }
                                }
                                if (_valid7) {
                                    var _errs96 = errors;
                                    if (errors === _errs96) {
                                        if (data &&
                                            typeof data == 'object' &&
                                            !Array.isArray(data)) {
                                            var missing7 = void 0;
                                            if ((data.name === undefined && (missing7 = 'name')) ||
                                                (data.participant === undefined &&
                                                    (missing7 = 'participant')) ||
                                                (data.timestamp === undefined &&
                                                    (missing7 = 'timestamp'))) {
                                                validate11.errors = [
                                                    {
                                                        instancePath: instancePath,
                                                        schemaPath: '#/allOf/7/then/required',
                                                        keyword: 'required',
                                                        params: { missingProperty: missing7 },
                                                        message: "must have required property '" + missing7 + "'",
                                                    },
                                                ];
                                                return false;
                                            }
                                            else {
                                                var _errs98 = errors;
                                                for (var key7 in data) {
                                                    if (!(key7 === 'participant' ||
                                                        key7 === 'name' ||
                                                        key7 === 'timestamp')) {
                                                        validate11.errors = [
                                                            {
                                                                instancePath: instancePath,
                                                                schemaPath: '#/allOf/7/then/additionalProperties',
                                                                keyword: 'additionalProperties',
                                                                params: { additionalProperty: key7 },
                                                                message: 'must NOT have additional properties',
                                                            },
                                                        ];
                                                        return false;
                                                        break;
                                                    }
                                                }
                                                if (_errs98 === errors) {
                                                    if (data.participant !== undefined) {
                                                        var data24 = data.participant;
                                                        var _errs99 = errors;
                                                        if (errors === _errs99) {
                                                            if (data24 &&
                                                                typeof data24 == 'object' &&
                                                                !Array.isArray(data24)) {
                                                                var missing8 = void 0;
                                                                if ((data24.identity === undefined &&
                                                                    (missing8 = 'identity')) ||
                                                                    (data24.sid === undefined &&
                                                                        (missing8 = 'sid'))) {
                                                                    validate11.errors = [
                                                                        {
                                                                            instancePath: instancePath + '/participant',
                                                                            schemaPath: '#/allOf/7/then/properties/participant/required',
                                                                            keyword: 'required',
                                                                            params: { missingProperty: missing8 },
                                                                            message: "must have required property '" +
                                                                                missing8 +
                                                                                "'",
                                                                        },
                                                                    ];
                                                                    return false;
                                                                }
                                                                else {
                                                                    var _errs101 = errors;
                                                                    for (var key8 in data24) {
                                                                        if (!(key8 === 'identity' ||
                                                                            key8 === 'sid' ||
                                                                            key8 === 'permissions')) {
                                                                            validate11.errors = [
                                                                                {
                                                                                    instancePath: instancePath + '/participant',
                                                                                    schemaPath: '#/allOf/7/then/properties/participant/additionalProperties',
                                                                                    keyword: 'additionalProperties',
                                                                                    params: { additionalProperty: key8 },
                                                                                    message: 'must NOT have additional properties',
                                                                                },
                                                                            ];
                                                                            return false;
                                                                            break;
                                                                        }
                                                                    }
                                                                    if (_errs101 === errors) {
                                                                        if (data24.identity !== undefined) {
                                                                            var _errs102 = errors;
                                                                            if (typeof data24.identity !== 'string') {
                                                                                validate11.errors = [
                                                                                    {
                                                                                        instancePath: instancePath +
                                                                                            '/participant/identity',
                                                                                        schemaPath: '#/allOf/7/then/properties/participant/properties/identity/type',
                                                                                        keyword: 'type',
                                                                                        params: { type: 'string' },
                                                                                        message: 'must be string',
                                                                                    },
                                                                                ];
                                                                                return false;
                                                                            }
                                                                            var valid27 = _errs102 === errors;
                                                                        }
                                                                        else {
                                                                            var valid27 = true;
                                                                        }
                                                                        if (valid27) {
                                                                            if (data24.sid !== undefined) {
                                                                                var _errs104 = errors;
                                                                                if (typeof data24.sid !== 'string') {
                                                                                    validate11.errors = [
                                                                                        {
                                                                                            instancePath: instancePath +
                                                                                                '/participant/sid',
                                                                                            schemaPath: '#/allOf/7/then/properties/participant/properties/sid/type',
                                                                                            keyword: 'type',
                                                                                            params: { type: 'string' },
                                                                                            message: 'must be string',
                                                                                        },
                                                                                    ];
                                                                                    return false;
                                                                                }
                                                                                var valid27 = _errs104 === errors;
                                                                            }
                                                                            else {
                                                                                var valid27 = true;
                                                                            }
                                                                            if (valid27) {
                                                                                if (data24.permissions !== undefined) {
                                                                                    var data27 = data24.permissions;
                                                                                    var _errs106 = errors;
                                                                                    if (errors === _errs106) {
                                                                                        if (data27 &&
                                                                                            typeof data27 == 'object' &&
                                                                                            !Array.isArray(data27)) {
                                                                                            var missing9 = void 0;
                                                                                            if ((data27.canSubscribe ===
                                                                                                undefined &&
                                                                                                (missing9 =
                                                                                                    'canSubscribe')) ||
                                                                                                (data27.canPublish ===
                                                                                                    undefined &&
                                                                                                    (missing9 = 'canPublish')) ||
                                                                                                (data27.canPublishData ===
                                                                                                    undefined &&
                                                                                                    (missing9 =
                                                                                                        'canPublishData')) ||
                                                                                                (data27.hidden === undefined &&
                                                                                                    (missing9 = 'hidden')) ||
                                                                                                (data27.canUpdateMetadata ===
                                                                                                    undefined &&
                                                                                                    (missing9 =
                                                                                                        'canUpdateMetadata')) ||
                                                                                                (data27.canSubscribeMetrics ===
                                                                                                    undefined &&
                                                                                                    (missing9 =
                                                                                                        'canSubscribeMetrics'))) {
                                                                                                validate11.errors = [
                                                                                                    {
                                                                                                        instancePath: instancePath +
                                                                                                            '/participant/permissions',
                                                                                                        schemaPath: '#/allOf/7/then/properties/participant/properties/permissions/required',
                                                                                                        keyword: 'required',
                                                                                                        params: {
                                                                                                            missingProperty: missing9,
                                                                                                        },
                                                                                                        message: "must have required property '" +
                                                                                                            missing9 +
                                                                                                            "'",
                                                                                                    },
                                                                                                ];
                                                                                                return false;
                                                                                            }
                                                                                            else {
                                                                                                var _errs108 = errors;
                                                                                                for (var key9 in data27) {
                                                                                                    if (!(key9 === 'canSubscribe' ||
                                                                                                        key9 === 'canPublish' ||
                                                                                                        key9 ===
                                                                                                            'canPublishData' ||
                                                                                                        key9 === 'hidden' ||
                                                                                                        key9 ===
                                                                                                            'canUpdateMetadata' ||
                                                                                                        key9 ===
                                                                                                            'canSubscribeMetrics')) {
                                                                                                        validate11.errors = [
                                                                                                            {
                                                                                                                instancePath: instancePath +
                                                                                                                    '/participant/permissions',
                                                                                                                schemaPath: '#/allOf/7/then/properties/participant/properties/permissions/additionalProperties',
                                                                                                                keyword: 'additionalProperties',
                                                                                                                params: {
                                                                                                                    additionalProperty: key9,
                                                                                                                },
                                                                                                                message: 'must NOT have additional properties',
                                                                                                            },
                                                                                                        ];
                                                                                                        return false;
                                                                                                        break;
                                                                                                    }
                                                                                                }
                                                                                                if (_errs108 === errors) {
                                                                                                    if (data27.canSubscribe !==
                                                                                                        undefined) {
                                                                                                        var _errs109 = errors;
                                                                                                        if (typeof data27.canSubscribe !==
                                                                                                            'boolean') {
                                                                                                            validate11.errors = [
                                                                                                                {
                                                                                                                    instancePath: instancePath +
                                                                                                                        '/participant/permissions/canSubscribe',
                                                                                                                    schemaPath: '#/allOf/7/then/properties/participant/properties/permissions/properties/canSubscribe/type',
                                                                                                                    keyword: 'type',
                                                                                                                    params: {
                                                                                                                        type: 'boolean',
                                                                                                                    },
                                                                                                                    message: 'must be boolean',
                                                                                                                },
                                                                                                            ];
                                                                                                            return false;
                                                                                                        }
                                                                                                        var valid28 = _errs109 === errors;
                                                                                                    }
                                                                                                    else {
                                                                                                        var valid28 = true;
                                                                                                    }
                                                                                                    if (valid28) {
                                                                                                        if (data27.canPublish !==
                                                                                                            undefined) {
                                                                                                            var _errs111 = errors;
                                                                                                            if (typeof data27.canPublish !==
                                                                                                                'boolean') {
                                                                                                                validate11.errors = [
                                                                                                                    {
                                                                                                                        instancePath: instancePath +
                                                                                                                            '/participant/permissions/canPublish',
                                                                                                                        schemaPath: '#/allOf/7/then/properties/participant/properties/permissions/properties/canPublish/type',
                                                                                                                        keyword: 'type',
                                                                                                                        params: {
                                                                                                                            type: 'boolean',
                                                                                                                        },
                                                                                                                        message: 'must be boolean',
                                                                                                                    },
                                                                                                                ];
                                                                                                                return false;
                                                                                                            }
                                                                                                            var valid28 = _errs111 === errors;
                                                                                                        }
                                                                                                        else {
                                                                                                            var valid28 = true;
                                                                                                        }
                                                                                                        if (valid28) {
                                                                                                            if (data27.canPublishData !==
                                                                                                                undefined) {
                                                                                                                var _errs113 = errors;
                                                                                                                if (typeof data27.canPublishData !==
                                                                                                                    'boolean') {
                                                                                                                    validate11.errors = [
                                                                                                                        {
                                                                                                                            instancePath: instancePath +
                                                                                                                                '/participant/permissions/canPublishData',
                                                                                                                            schemaPath: '#/allOf/7/then/properties/participant/properties/permissions/properties/canPublishData/type',
                                                                                                                            keyword: 'type',
                                                                                                                            params: {
                                                                                                                                type: 'boolean',
                                                                                                                            },
                                                                                                                            message: 'must be boolean',
                                                                                                                        },
                                                                                                                    ];
                                                                                                                    return false;
                                                                                                                }
                                                                                                                var valid28 = _errs113 === errors;
                                                                                                            }
                                                                                                            else {
                                                                                                                var valid28 = true;
                                                                                                            }
                                                                                                            if (valid28) {
                                                                                                                if (data27.hidden !==
                                                                                                                    undefined) {
                                                                                                                    var _errs115 = errors;
                                                                                                                    if (typeof data27.hidden !==
                                                                                                                        'boolean') {
                                                                                                                        validate11.errors =
                                                                                                                            [
                                                                                                                                {
                                                                                                                                    instancePath: instancePath +
                                                                                                                                        '/participant/permissions/hidden',
                                                                                                                                    schemaPath: '#/allOf/7/then/properties/participant/properties/permissions/properties/hidden/type',
                                                                                                                                    keyword: 'type',
                                                                                                                                    params: {
                                                                                                                                        type: 'boolean',
                                                                                                                                    },
                                                                                                                                    message: 'must be boolean',
                                                                                                                                },
                                                                                                                            ];
                                                                                                                        return false;
                                                                                                                    }
                                                                                                                    var valid28 = _errs115 === errors;
                                                                                                                }
                                                                                                                else {
                                                                                                                    var valid28 = true;
                                                                                                                }
                                                                                                                if (valid28) {
                                                                                                                    if (data27.canUpdateMetadata !==
                                                                                                                        undefined) {
                                                                                                                        var _errs117 = errors;
                                                                                                                        if (typeof data27.canUpdateMetadata !==
                                                                                                                            'boolean') {
                                                                                                                            validate11.errors =
                                                                                                                                [
                                                                                                                                    {
                                                                                                                                        instancePath: instancePath +
                                                                                                                                            '/participant/permissions/canUpdateMetadata',
                                                                                                                                        schemaPath: '#/allOf/7/then/properties/participant/properties/permissions/properties/canUpdateMetadata/type',
                                                                                                                                        keyword: 'type',
                                                                                                                                        params: {
                                                                                                                                            type: 'boolean',
                                                                                                                                        },
                                                                                                                                        message: 'must be boolean',
                                                                                                                                    },
                                                                                                                                ];
                                                                                                                            return false;
                                                                                                                        }
                                                                                                                        var valid28 = _errs117 ===
                                                                                                                            errors;
                                                                                                                    }
                                                                                                                    else {
                                                                                                                        var valid28 = true;
                                                                                                                    }
                                                                                                                    if (valid28) {
                                                                                                                        if (data27.canSubscribeMetrics !==
                                                                                                                            undefined) {
                                                                                                                            var _errs119 = errors;
                                                                                                                            if (typeof data27.canSubscribeMetrics !==
                                                                                                                                'boolean') {
                                                                                                                                validate11.errors =
                                                                                                                                    [
                                                                                                                                        {
                                                                                                                                            instancePath: instancePath +
                                                                                                                                                '/participant/permissions/canSubscribeMetrics',
                                                                                                                                            schemaPath: '#/allOf/7/then/properties/participant/properties/permissions/properties/canSubscribeMetrics/type',
                                                                                                                                            keyword: 'type',
                                                                                                                                            params: {
                                                                                                                                                type: 'boolean',
                                                                                                                                            },
                                                                                                                                            message: 'must be boolean',
                                                                                                                                        },
                                                                                                                                    ];
                                                                                                                                return false;
                                                                                                                            }
                                                                                                                            var valid28 = _errs119 ===
                                                                                                                                errors;
                                                                                                                        }
                                                                                                                        else {
                                                                                                                            var valid28 = true;
                                                                                                                        }
                                                                                                                    }
                                                                                                                }
                                                                                                            }
                                                                                                        }
                                                                                                    }
                                                                                                }
                                                                                            }
                                                                                        }
                                                                                        else {
                                                                                            validate11.errors = [
                                                                                                {
                                                                                                    instancePath: instancePath +
                                                                                                        '/participant/permissions',
                                                                                                    schemaPath: '#/allOf/7/then/properties/participant/properties/permissions/type',
                                                                                                    keyword: 'type',
                                                                                                    params: { type: 'object' },
                                                                                                    message: 'must be object',
                                                                                                },
                                                                                            ];
                                                                                            return false;
                                                                                        }
                                                                                    }
                                                                                    var valid27 = _errs106 === errors;
                                                                                }
                                                                                else {
                                                                                    var valid27 = true;
                                                                                }
                                                                            }
                                                                        }
                                                                    }
                                                                }
                                                            }
                                                            else {
                                                                validate11.errors = [
                                                                    {
                                                                        instancePath: instancePath + '/participant',
                                                                        schemaPath: '#/allOf/7/then/properties/participant/type',
                                                                        keyword: 'type',
                                                                        params: { type: 'object' },
                                                                        message: 'must be object',
                                                                    },
                                                                ];
                                                                return false;
                                                            }
                                                        }
                                                        var valid26 = _errs99 === errors;
                                                    }
                                                    else {
                                                        var valid26 = true;
                                                    }
                                                    if (valid26) {
                                                        if (data.name !== undefined) {
                                                            var data34 = data.name;
                                                            var _errs121 = errors;
                                                            if (typeof data34 !== 'string') {
                                                                validate11.errors = [
                                                                    {
                                                                        instancePath: instancePath + '/name',
                                                                        schemaPath: '#/allOf/7/then/properties/name/type',
                                                                        keyword: 'type',
                                                                        params: { type: 'string' },
                                                                        message: 'must be string',
                                                                    },
                                                                ];
                                                                return false;
                                                            }
                                                            if ('participantConnected' !== data34) {
                                                                validate11.errors = [
                                                                    {
                                                                        instancePath: instancePath + '/name',
                                                                        schemaPath: '#/allOf/7/then/properties/name/const',
                                                                        keyword: 'const',
                                                                        params: {
                                                                            allowedValue: 'participantConnected',
                                                                        },
                                                                        message: 'must be equal to constant',
                                                                    },
                                                                ];
                                                                return false;
                                                            }
                                                            var valid26 = _errs121 === errors;
                                                        }
                                                        else {
                                                            var valid26 = true;
                                                        }
                                                        if (valid26) {
                                                            if (data.timestamp !== undefined) {
                                                                var data35 = data.timestamp;
                                                                var _errs123 = errors;
                                                                if (!(typeof data35 == 'number' &&
                                                                    isFinite(data35))) {
                                                                    validate11.errors = [
                                                                        {
                                                                            instancePath: instancePath + '/timestamp',
                                                                            schemaPath: '#/allOf/7/then/properties/timestamp/type',
                                                                            keyword: 'type',
                                                                            params: { type: 'number' },
                                                                            message: 'must be number',
                                                                        },
                                                                    ];
                                                                    return false;
                                                                }
                                                                var valid26 = _errs123 === errors;
                                                            }
                                                            else {
                                                                var valid26 = true;
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                        else {
                                            validate11.errors = [
                                                {
                                                    instancePath: instancePath,
                                                    schemaPath: '#/allOf/7/then/type',
                                                    keyword: 'type',
                                                    params: { type: 'object' },
                                                    message: 'must be object',
                                                },
                                            ];
                                            return false;
                                        }
                                    }
                                    var _valid7 = _errs96 === errors;
                                    valid24 = _valid7;
                                }
                                if (!valid24) {
                                    var err23 = {
                                        instancePath: instancePath,
                                        schemaPath: '#/allOf/7/if',
                                        keyword: 'if',
                                        params: { failingKeyword: 'then' },
                                        message: 'must match "then" schema',
                                    };
                                    if (vErrors === null) {
                                        vErrors = [err23];
                                    }
                                    else {
                                        vErrors.push(err23);
                                    }
                                    errors++;
                                    validate11.errors = vErrors;
                                    return false;
                                }
                                var valid0 = _errs91 === errors;
                                if (valid0) {
                                    var _errs125 = errors;
                                    var _errs126 = errors;
                                    var valid29 = true;
                                    var _errs127 = errors;
                                    if (data && typeof data == 'object' && !Array.isArray(data)) {
                                        if (data.name !== undefined) {
                                            var data36 = data.name;
                                            if (typeof data36 !== 'string') {
                                                var err24 = {};
                                                if (vErrors === null) {
                                                    vErrors = [err24];
                                                }
                                                else {
                                                    vErrors.push(err24);
                                                }
                                                errors++;
                                            }
                                            if ('participantDisconnected' !== data36) {
                                                var err25 = {};
                                                if (vErrors === null) {
                                                    vErrors = [err25];
                                                }
                                                else {
                                                    vErrors.push(err25);
                                                }
                                                errors++;
                                            }
                                        }
                                    }
                                    var _valid8 = _errs127 === errors;
                                    errors = _errs126;
                                    if (vErrors !== null) {
                                        if (_errs126) {
                                            vErrors.length = _errs126;
                                        }
                                        else {
                                            vErrors = null;
                                        }
                                    }
                                    if (_valid8) {
                                        var _errs130 = errors;
                                        if (errors === _errs130) {
                                            if (data &&
                                                typeof data == 'object' &&
                                                !Array.isArray(data)) {
                                                var missing10 = void 0;
                                                if ((data.name === undefined && (missing10 = 'name')) ||
                                                    (data.participant === undefined &&
                                                        (missing10 = 'participant')) ||
                                                    (data.timestamp === undefined &&
                                                        (missing10 = 'timestamp'))) {
                                                    validate11.errors = [
                                                        {
                                                            instancePath: instancePath,
                                                            schemaPath: '#/allOf/8/then/required',
                                                            keyword: 'required',
                                                            params: { missingProperty: missing10 },
                                                            message: "must have required property '" +
                                                                missing10 +
                                                                "'",
                                                        },
                                                    ];
                                                    return false;
                                                }
                                                else {
                                                    var _errs132 = errors;
                                                    for (var key10 in data) {
                                                        if (!(key10 === 'participant' ||
                                                            key10 === 'name' ||
                                                            key10 === 'timestamp')) {
                                                            validate11.errors = [
                                                                {
                                                                    instancePath: instancePath,
                                                                    schemaPath: '#/allOf/8/then/additionalProperties',
                                                                    keyword: 'additionalProperties',
                                                                    params: { additionalProperty: key10 },
                                                                    message: 'must NOT have additional properties',
                                                                },
                                                            ];
                                                            return false;
                                                            break;
                                                        }
                                                    }
                                                    if (_errs132 === errors) {
                                                        if (data.participant !== undefined) {
                                                            var data37 = data.participant;
                                                            var _errs133 = errors;
                                                            if (errors === _errs133) {
                                                                if (data37 &&
                                                                    typeof data37 == 'object' &&
                                                                    !Array.isArray(data37)) {
                                                                    var missing11 = void 0;
                                                                    if ((data37.identity === undefined &&
                                                                        (missing11 = 'identity')) ||
                                                                        (data37.sid === undefined &&
                                                                            (missing11 = 'sid'))) {
                                                                        validate11.errors = [
                                                                            {
                                                                                instancePath: instancePath + '/participant',
                                                                                schemaPath: '#/allOf/8/then/properties/participant/required',
                                                                                keyword: 'required',
                                                                                params: { missingProperty: missing11 },
                                                                                message: "must have required property '" +
                                                                                    missing11 +
                                                                                    "'",
                                                                            },
                                                                        ];
                                                                        return false;
                                                                    }
                                                                    else {
                                                                        var _errs135 = errors;
                                                                        for (var key11 in data37) {
                                                                            if (!(key11 === 'identity' ||
                                                                                key11 === 'sid' ||
                                                                                key11 === 'permissions')) {
                                                                                validate11.errors = [
                                                                                    {
                                                                                        instancePath: instancePath + '/participant',
                                                                                        schemaPath: '#/allOf/8/then/properties/participant/additionalProperties',
                                                                                        keyword: 'additionalProperties',
                                                                                        params: {
                                                                                            additionalProperty: key11,
                                                                                        },
                                                                                        message: 'must NOT have additional properties',
                                                                                    },
                                                                                ];
                                                                                return false;
                                                                                break;
                                                                            }
                                                                        }
                                                                        if (_errs135 === errors) {
                                                                            if (data37.identity !== undefined) {
                                                                                var _errs136 = errors;
                                                                                if (typeof data37.identity !== 'string') {
                                                                                    validate11.errors = [
                                                                                        {
                                                                                            instancePath: instancePath +
                                                                                                '/participant/identity',
                                                                                            schemaPath: '#/allOf/8/then/properties/participant/properties/identity/type',
                                                                                            keyword: 'type',
                                                                                            params: { type: 'string' },
                                                                                            message: 'must be string',
                                                                                        },
                                                                                    ];
                                                                                    return false;
                                                                                }
                                                                                var valid32 = _errs136 === errors;
                                                                            }
                                                                            else {
                                                                                var valid32 = true;
                                                                            }
                                                                            if (valid32) {
                                                                                if (data37.sid !== undefined) {
                                                                                    var _errs138 = errors;
                                                                                    if (typeof data37.sid !== 'string') {
                                                                                        validate11.errors = [
                                                                                            {
                                                                                                instancePath: instancePath +
                                                                                                    '/participant/sid',
                                                                                                schemaPath: '#/allOf/8/then/properties/participant/properties/sid/type',
                                                                                                keyword: 'type',
                                                                                                params: { type: 'string' },
                                                                                                message: 'must be string',
                                                                                            },
                                                                                        ];
                                                                                        return false;
                                                                                    }
                                                                                    var valid32 = _errs138 === errors;
                                                                                }
                                                                                else {
                                                                                    var valid32 = true;
                                                                                }
                                                                                if (valid32) {
                                                                                    if (data37.permissions !== undefined) {
                                                                                        var data40 = data37.permissions;
                                                                                        var _errs140 = errors;
                                                                                        if (errors === _errs140) {
                                                                                            if (data40 &&
                                                                                                typeof data40 == 'object' &&
                                                                                                !Array.isArray(data40)) {
                                                                                                var missing12 = void 0;
                                                                                                if ((data40.canSubscribe ===
                                                                                                    undefined &&
                                                                                                    (missing12 =
                                                                                                        'canSubscribe')) ||
                                                                                                    (data40.canPublish ===
                                                                                                        undefined &&
                                                                                                        (missing12 =
                                                                                                            'canPublish')) ||
                                                                                                    (data40.canPublishData ===
                                                                                                        undefined &&
                                                                                                        (missing12 =
                                                                                                            'canPublishData')) ||
                                                                                                    (data40.hidden ===
                                                                                                        undefined &&
                                                                                                        (missing12 = 'hidden')) ||
                                                                                                    (data40.canUpdateMetadata ===
                                                                                                        undefined &&
                                                                                                        (missing12 =
                                                                                                            'canUpdateMetadata')) ||
                                                                                                    (data40.canSubscribeMetrics ===
                                                                                                        undefined &&
                                                                                                        (missing12 =
                                                                                                            'canSubscribeMetrics'))) {
                                                                                                    validate11.errors = [
                                                                                                        {
                                                                                                            instancePath: instancePath +
                                                                                                                '/participant/permissions',
                                                                                                            schemaPath: '#/allOf/8/then/properties/participant/properties/permissions/required',
                                                                                                            keyword: 'required',
                                                                                                            params: {
                                                                                                                missingProperty: missing12,
                                                                                                            },
                                                                                                            message: "must have required property '" +
                                                                                                                missing12 +
                                                                                                                "'",
                                                                                                        },
                                                                                                    ];
                                                                                                    return false;
                                                                                                }
                                                                                                else {
                                                                                                    var _errs142 = errors;
                                                                                                    for (var key12 in data40) {
                                                                                                        if (!(key12 ===
                                                                                                            'canSubscribe' ||
                                                                                                            key12 ===
                                                                                                                'canPublish' ||
                                                                                                            key12 ===
                                                                                                                'canPublishData' ||
                                                                                                            key12 === 'hidden' ||
                                                                                                            key12 ===
                                                                                                                'canUpdateMetadata' ||
                                                                                                            key12 ===
                                                                                                                'canSubscribeMetrics')) {
                                                                                                            validate11.errors = [
                                                                                                                {
                                                                                                                    instancePath: instancePath +
                                                                                                                        '/participant/permissions',
                                                                                                                    schemaPath: '#/allOf/8/then/properties/participant/properties/permissions/additionalProperties',
                                                                                                                    keyword: 'additionalProperties',
                                                                                                                    params: {
                                                                                                                        additionalProperty: key12,
                                                                                                                    },
                                                                                                                    message: 'must NOT have additional properties',
                                                                                                                },
                                                                                                            ];
                                                                                                            return false;
                                                                                                            break;
                                                                                                        }
                                                                                                    }
                                                                                                    if (_errs142 === errors) {
                                                                                                        if (data40.canSubscribe !==
                                                                                                            undefined) {
                                                                                                            var _errs143 = errors;
                                                                                                            if (typeof data40.canSubscribe !==
                                                                                                                'boolean') {
                                                                                                                validate11.errors = [
                                                                                                                    {
                                                                                                                        instancePath: instancePath +
                                                                                                                            '/participant/permissions/canSubscribe',
                                                                                                                        schemaPath: '#/allOf/8/then/properties/participant/properties/permissions/properties/canSubscribe/type',
                                                                                                                        keyword: 'type',
                                                                                                                        params: {
                                                                                                                            type: 'boolean',
                                                                                                                        },
                                                                                                                        message: 'must be boolean',
                                                                                                                    },
                                                                                                                ];
                                                                                                                return false;
                                                                                                            }
                                                                                                            var valid33 = _errs143 === errors;
                                                                                                        }
                                                                                                        else {
                                                                                                            var valid33 = true;
                                                                                                        }
                                                                                                        if (valid33) {
                                                                                                            if (data40.canPublish !==
                                                                                                                undefined) {
                                                                                                                var _errs145 = errors;
                                                                                                                if (typeof data40.canPublish !==
                                                                                                                    'boolean') {
                                                                                                                    validate11.errors = [
                                                                                                                        {
                                                                                                                            instancePath: instancePath +
                                                                                                                                '/participant/permissions/canPublish',
                                                                                                                            schemaPath: '#/allOf/8/then/properties/participant/properties/permissions/properties/canPublish/type',
                                                                                                                            keyword: 'type',
                                                                                                                            params: {
                                                                                                                                type: 'boolean',
                                                                                                                            },
                                                                                                                            message: 'must be boolean',
                                                                                                                        },
                                                                                                                    ];
                                                                                                                    return false;
                                                                                                                }
                                                                                                                var valid33 = _errs145 === errors;
                                                                                                            }
                                                                                                            else {
                                                                                                                var valid33 = true;
                                                                                                            }
                                                                                                            if (valid33) {
                                                                                                                if (data40.canPublishData !==
                                                                                                                    undefined) {
                                                                                                                    var _errs147 = errors;
                                                                                                                    if (typeof data40.canPublishData !==
                                                                                                                        'boolean') {
                                                                                                                        validate11.errors =
                                                                                                                            [
                                                                                                                                {
                                                                                                                                    instancePath: instancePath +
                                                                                                                                        '/participant/permissions/canPublishData',
                                                                                                                                    schemaPath: '#/allOf/8/then/properties/participant/properties/permissions/properties/canPublishData/type',
                                                                                                                                    keyword: 'type',
                                                                                                                                    params: {
                                                                                                                                        type: 'boolean',
                                                                                                                                    },
                                                                                                                                    message: 'must be boolean',
                                                                                                                                },
                                                                                                                            ];
                                                                                                                        return false;
                                                                                                                    }
                                                                                                                    var valid33 = _errs147 === errors;
                                                                                                                }
                                                                                                                else {
                                                                                                                    var valid33 = true;
                                                                                                                }
                                                                                                                if (valid33) {
                                                                                                                    if (data40.hidden !==
                                                                                                                        undefined) {
                                                                                                                        var _errs149 = errors;
                                                                                                                        if (typeof data40.hidden !==
                                                                                                                            'boolean') {
                                                                                                                            validate11.errors =
                                                                                                                                [
                                                                                                                                    {
                                                                                                                                        instancePath: instancePath +
                                                                                                                                            '/participant/permissions/hidden',
                                                                                                                                        schemaPath: '#/allOf/8/then/properties/participant/properties/permissions/properties/hidden/type',
                                                                                                                                        keyword: 'type',
                                                                                                                                        params: {
                                                                                                                                            type: 'boolean',
                                                                                                                                        },
                                                                                                                                        message: 'must be boolean',
                                                                                                                                    },
                                                                                                                                ];
                                                                                                                            return false;
                                                                                                                        }
                                                                                                                        var valid33 = _errs149 ===
                                                                                                                            errors;
                                                                                                                    }
                                                                                                                    else {
                                                                                                                        var valid33 = true;
                                                                                                                    }
                                                                                                                    if (valid33) {
                                                                                                                        if (data40.canUpdateMetadata !==
                                                                                                                            undefined) {
                                                                                                                            var _errs151 = errors;
                                                                                                                            if (typeof data40.canUpdateMetadata !==
                                                                                                                                'boolean') {
                                                                                                                                validate11.errors =
                                                                                                                                    [
                                                                                                                                        {
                                                                                                                                            instancePath: instancePath +
                                                                                                                                                '/participant/permissions/canUpdateMetadata',
                                                                                                                                            schemaPath: '#/allOf/8/then/properties/participant/properties/permissions/properties/canUpdateMetadata/type',
                                                                                                                                            keyword: 'type',
                                                                                                                                            params: {
                                                                                                                                                type: 'boolean',
                                                                                                                                            },
                                                                                                                                            message: 'must be boolean',
                                                                                                                                        },
                                                                                                                                    ];
                                                                                                                                return false;
                                                                                                                            }
                                                                                                                            var valid33 = _errs151 ===
                                                                                                                                errors;
                                                                                                                        }
                                                                                                                        else {
                                                                                                                            var valid33 = true;
                                                                                                                        }
                                                                                                                        if (valid33) {
                                                                                                                            if (data40.canSubscribeMetrics !==
                                                                                                                                undefined) {
                                                                                                                                var _errs153 = errors;
                                                                                                                                if (typeof data40.canSubscribeMetrics !==
                                                                                                                                    'boolean') {
                                                                                                                                    validate11.errors =
                                                                                                                                        [
                                                                                                                                            {
                                                                                                                                                instancePath: instancePath +
                                                                                                                                                    '/participant/permissions/canSubscribeMetrics',
                                                                                                                                                schemaPath: '#/allOf/8/then/properties/participant/properties/permissions/properties/canSubscribeMetrics/type',
                                                                                                                                                keyword: 'type',
                                                                                                                                                params: {
                                                                                                                                                    type: 'boolean',
                                                                                                                                                },
                                                                                                                                                message: 'must be boolean',
                                                                                                                                            },
                                                                                                                                        ];
                                                                                                                                    return false;
                                                                                                                                }
                                                                                                                                var valid33 = _errs153 ===
                                                                                                                                    errors;
                                                                                                                            }
                                                                                                                            else {
                                                                                                                                var valid33 = true;
                                                                                                                            }
                                                                                                                        }
                                                                                                                    }
                                                                                                                }
                                                                                                            }
                                                                                                        }
                                                                                                    }
                                                                                                }
                                                                                            }
                                                                                            else {
                                                                                                validate11.errors = [
                                                                                                    {
                                                                                                        instancePath: instancePath +
                                                                                                            '/participant/permissions',
                                                                                                        schemaPath: '#/allOf/8/then/properties/participant/properties/permissions/type',
                                                                                                        keyword: 'type',
                                                                                                        params: { type: 'object' },
                                                                                                        message: 'must be object',
                                                                                                    },
                                                                                                ];
                                                                                                return false;
                                                                                            }
                                                                                        }
                                                                                        var valid32 = _errs140 === errors;
                                                                                    }
                                                                                    else {
                                                                                        var valid32 = true;
                                                                                    }
                                                                                }
                                                                            }
                                                                        }
                                                                    }
                                                                }
                                                                else {
                                                                    validate11.errors = [
                                                                        {
                                                                            instancePath: instancePath + '/participant',
                                                                            schemaPath: '#/allOf/8/then/properties/participant/type',
                                                                            keyword: 'type',
                                                                            params: { type: 'object' },
                                                                            message: 'must be object',
                                                                        },
                                                                    ];
                                                                    return false;
                                                                }
                                                            }
                                                            var valid31 = _errs133 === errors;
                                                        }
                                                        else {
                                                            var valid31 = true;
                                                        }
                                                        if (valid31) {
                                                            if (data.name !== undefined) {
                                                                var data47 = data.name;
                                                                var _errs155 = errors;
                                                                if (typeof data47 !== 'string') {
                                                                    validate11.errors = [
                                                                        {
                                                                            instancePath: instancePath + '/name',
                                                                            schemaPath: '#/allOf/8/then/properties/name/type',
                                                                            keyword: 'type',
                                                                            params: { type: 'string' },
                                                                            message: 'must be string',
                                                                        },
                                                                    ];
                                                                    return false;
                                                                }
                                                                if ('participantDisconnected' !== data47) {
                                                                    validate11.errors = [
                                                                        {
                                                                            instancePath: instancePath + '/name',
                                                                            schemaPath: '#/allOf/8/then/properties/name/const',
                                                                            keyword: 'const',
                                                                            params: {
                                                                                allowedValue: 'participantDisconnected',
                                                                            },
                                                                            message: 'must be equal to constant',
                                                                        },
                                                                    ];
                                                                    return false;
                                                                }
                                                                var valid31 = _errs155 === errors;
                                                            }
                                                            else {
                                                                var valid31 = true;
                                                            }
                                                            if (valid31) {
                                                                if (data.timestamp !== undefined) {
                                                                    var data48 = data.timestamp;
                                                                    var _errs157 = errors;
                                                                    if (!(typeof data48 == 'number' &&
                                                                        isFinite(data48))) {
                                                                        validate11.errors = [
                                                                            {
                                                                                instancePath: instancePath + '/timestamp',
                                                                                schemaPath: '#/allOf/8/then/properties/timestamp/type',
                                                                                keyword: 'type',
                                                                                params: { type: 'number' },
                                                                                message: 'must be number',
                                                                            },
                                                                        ];
                                                                        return false;
                                                                    }
                                                                    var valid31 = _errs157 === errors;
                                                                }
                                                                else {
                                                                    var valid31 = true;
                                                                }
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                            else {
                                                validate11.errors = [
                                                    {
                                                        instancePath: instancePath,
                                                        schemaPath: '#/allOf/8/then/type',
                                                        keyword: 'type',
                                                        params: { type: 'object' },
                                                        message: 'must be object',
                                                    },
                                                ];
                                                return false;
                                            }
                                        }
                                        var _valid8 = _errs130 === errors;
                                        valid29 = _valid8;
                                    }
                                    if (!valid29) {
                                        var err26 = {
                                            instancePath: instancePath,
                                            schemaPath: '#/allOf/8/if',
                                            keyword: 'if',
                                            params: { failingKeyword: 'then' },
                                            message: 'must match "then" schema',
                                        };
                                        if (vErrors === null) {
                                            vErrors = [err26];
                                        }
                                        else {
                                            vErrors.push(err26);
                                        }
                                        errors++;
                                        validate11.errors = vErrors;
                                        return false;
                                    }
                                    var valid0 = _errs125 === errors;
                                    if (valid0) {
                                        var _errs159 = errors;
                                        var _errs160 = errors;
                                        var valid34 = true;
                                        var _errs161 = errors;
                                        if (data &&
                                            typeof data == 'object' &&
                                            !Array.isArray(data)) {
                                            if (data.name !== undefined) {
                                                var data49 = data.name;
                                                if (typeof data49 !== 'string') {
                                                    var err27 = {};
                                                    if (vErrors === null) {
                                                        vErrors = [err27];
                                                    }
                                                    else {
                                                        vErrors.push(err27);
                                                    }
                                                    errors++;
                                                }
                                                if ('trackPublished' !== data49) {
                                                    var err28 = {};
                                                    if (vErrors === null) {
                                                        vErrors = [err28];
                                                    }
                                                    else {
                                                        vErrors.push(err28);
                                                    }
                                                    errors++;
                                                }
                                            }
                                        }
                                        var _valid9 = _errs161 === errors;
                                        errors = _errs160;
                                        if (vErrors !== null) {
                                            if (_errs160) {
                                                vErrors.length = _errs160;
                                            }
                                            else {
                                                vErrors = null;
                                            }
                                        }
                                        if (_valid9) {
                                            var _errs164 = errors;
                                            if (errors === _errs164) {
                                                if (data &&
                                                    typeof data == 'object' &&
                                                    !Array.isArray(data)) {
                                                    var missing13 = void 0;
                                                    if ((data.name === undefined && (missing13 = 'name')) ||
                                                        (data.participant === undefined &&
                                                            (missing13 = 'participant')) ||
                                                        (data.timestamp === undefined &&
                                                            (missing13 = 'timestamp')) ||
                                                        (data.track === undefined && (missing13 = 'track'))) {
                                                        validate11.errors = [
                                                            {
                                                                instancePath: instancePath,
                                                                schemaPath: '#/allOf/9/then/required',
                                                                keyword: 'required',
                                                                params: { missingProperty: missing13 },
                                                                message: "must have required property '" +
                                                                    missing13 +
                                                                    "'",
                                                            },
                                                        ];
                                                        return false;
                                                    }
                                                    else {
                                                        var _errs166 = errors;
                                                        for (var key13 in data) {
                                                            if (!(key13 === 'track' ||
                                                                key13 === 'participant' ||
                                                                key13 === 'name' ||
                                                                key13 === 'timestamp')) {
                                                                validate11.errors = [
                                                                    {
                                                                        instancePath: instancePath,
                                                                        schemaPath: '#/allOf/9/then/additionalProperties',
                                                                        keyword: 'additionalProperties',
                                                                        params: { additionalProperty: key13 },
                                                                        message: 'must NOT have additional properties',
                                                                    },
                                                                ];
                                                                return false;
                                                                break;
                                                            }
                                                        }
                                                        if (_errs166 === errors) {
                                                            if (data.track !== undefined) {
                                                                var data50 = data.track;
                                                                var _errs167 = errors;
                                                                if (errors === _errs167) {
                                                                    if (data50 &&
                                                                        typeof data50 == 'object' &&
                                                                        !Array.isArray(data50)) {
                                                                        var missing14 = void 0;
                                                                        if ((data50.sid === undefined &&
                                                                            (missing14 = 'sid')) ||
                                                                            (data50.source === undefined &&
                                                                                (missing14 = 'source'))) {
                                                                            validate11.errors = [
                                                                                {
                                                                                    instancePath: instancePath + '/track',
                                                                                    schemaPath: '#/allOf/9/then/properties/track/required',
                                                                                    keyword: 'required',
                                                                                    params: {
                                                                                        missingProperty: missing14,
                                                                                    },
                                                                                    message: "must have required property '" +
                                                                                        missing14 +
                                                                                        "'",
                                                                                },
                                                                            ];
                                                                            return false;
                                                                        }
                                                                        else {
                                                                            var _errs169 = errors;
                                                                            for (var key14 in data50) {
                                                                                if (!(key14 === 'sid' ||
                                                                                    key14 === 'source' ||
                                                                                    key14 === 'mimeType' ||
                                                                                    key14 === 'dimensions')) {
                                                                                    validate11.errors = [
                                                                                        {
                                                                                            instancePath: instancePath + '/track',
                                                                                            schemaPath: '#/allOf/9/then/properties/track/additionalProperties',
                                                                                            keyword: 'additionalProperties',
                                                                                            params: {
                                                                                                additionalProperty: key14,
                                                                                            },
                                                                                            message: 'must NOT have additional properties',
                                                                                        },
                                                                                    ];
                                                                                    return false;
                                                                                    break;
                                                                                }
                                                                            }
                                                                            if (_errs169 === errors) {
                                                                                if (data50.sid !== undefined) {
                                                                                    var _errs170 = errors;
                                                                                    if (typeof data50.sid !== 'string') {
                                                                                        validate11.errors = [
                                                                                            {
                                                                                                instancePath: instancePath + '/track/sid',
                                                                                                schemaPath: '#/allOf/9/then/properties/track/properties/sid/type',
                                                                                                keyword: 'type',
                                                                                                params: { type: 'string' },
                                                                                                message: 'must be string',
                                                                                            },
                                                                                        ];
                                                                                        return false;
                                                                                    }
                                                                                    var valid37 = _errs170 === errors;
                                                                                }
                                                                                else {
                                                                                    var valid37 = true;
                                                                                }
                                                                                if (valid37) {
                                                                                    if (data50.source !== undefined) {
                                                                                        var data52 = data50.source;
                                                                                        var _errs172 = errors;
                                                                                        if (typeof data52 !== 'string') {
                                                                                            validate11.errors = [
                                                                                                {
                                                                                                    instancePath: instancePath +
                                                                                                        '/track/source',
                                                                                                    schemaPath: '#/definitions/Track.Source/type',
                                                                                                    keyword: 'type',
                                                                                                    params: { type: 'string' },
                                                                                                    message: 'must be string',
                                                                                                },
                                                                                            ];
                                                                                            return false;
                                                                                        }
                                                                                        if (!(data52 === 'camera' ||
                                                                                            data52 === 'microphone' ||
                                                                                            data52 === 'screen_share' ||
                                                                                            data52 ===
                                                                                                'screen_share_audio' ||
                                                                                            data52 === 'unknown')) {
                                                                                            validate11.errors = [
                                                                                                {
                                                                                                    instancePath: instancePath +
                                                                                                        '/track/source',
                                                                                                    schemaPath: '#/definitions/Track.Source/enum',
                                                                                                    keyword: 'enum',
                                                                                                    params: {
                                                                                                        allowedValues: schema31.enum,
                                                                                                    },
                                                                                                    message: 'must be equal to one of the allowed values',
                                                                                                },
                                                                                            ];
                                                                                            return false;
                                                                                        }
                                                                                        var valid37 = _errs172 === errors;
                                                                                    }
                                                                                    else {
                                                                                        var valid37 = true;
                                                                                    }
                                                                                    if (valid37) {
                                                                                        if (data50.mimeType !== undefined) {
                                                                                            var _errs175 = errors;
                                                                                            if (typeof data50.mimeType !==
                                                                                                'string') {
                                                                                                validate11.errors = [
                                                                                                    {
                                                                                                        instancePath: instancePath +
                                                                                                            '/track/mimeType',
                                                                                                        schemaPath: '#/allOf/9/then/properties/track/properties/mimeType/type',
                                                                                                        keyword: 'type',
                                                                                                        params: { type: 'string' },
                                                                                                        message: 'must be string',
                                                                                                    },
                                                                                                ];
                                                                                                return false;
                                                                                            }
                                                                                            var valid37 = _errs175 === errors;
                                                                                        }
                                                                                        else {
                                                                                            var valid37 = true;
                                                                                        }
                                                                                        if (valid37) {
                                                                                            if (data50.dimensions !== undefined) {
                                                                                                var data54 = data50.dimensions;
                                                                                                var _errs177 = errors;
                                                                                                if (errors === _errs177) {
                                                                                                    if (data54 &&
                                                                                                        typeof data54 == 'object' &&
                                                                                                        !Array.isArray(data54)) {
                                                                                                        var missing15 = void 0;
                                                                                                        if ((data54.width ===
                                                                                                            undefined &&
                                                                                                            (missing15 =
                                                                                                                'width')) ||
                                                                                                            (data54.height ===
                                                                                                                undefined &&
                                                                                                                (missing15 = 'height'))) {
                                                                                                            validate11.errors = [
                                                                                                                {
                                                                                                                    instancePath: instancePath +
                                                                                                                        '/track/dimensions',
                                                                                                                    schemaPath: '#/allOf/9/then/properties/track/properties/dimensions/required',
                                                                                                                    keyword: 'required',
                                                                                                                    params: {
                                                                                                                        missingProperty: missing15,
                                                                                                                    },
                                                                                                                    message: "must have required property '" +
                                                                                                                        missing15 +
                                                                                                                        "'",
                                                                                                                },
                                                                                                            ];
                                                                                                            return false;
                                                                                                        }
                                                                                                        else {
                                                                                                            var _errs179 = errors;
                                                                                                            for (var key15 in data54) {
                                                                                                                if (!(key15 === 'width' ||
                                                                                                                    key15 === 'height')) {
                                                                                                                    validate11.errors = [
                                                                                                                        {
                                                                                                                            instancePath: instancePath +
                                                                                                                                '/track/dimensions',
                                                                                                                            schemaPath: '#/allOf/9/then/properties/track/properties/dimensions/additionalProperties',
                                                                                                                            keyword: 'additionalProperties',
                                                                                                                            params: {
                                                                                                                                additionalProperty: key15,
                                                                                                                            },
                                                                                                                            message: 'must NOT have additional properties',
                                                                                                                        },
                                                                                                                    ];
                                                                                                                    return false;
                                                                                                                    break;
                                                                                                                }
                                                                                                            }
                                                                                                            if (_errs179 === errors) {
                                                                                                                if (data54.width !==
                                                                                                                    undefined) {
                                                                                                                    var data55 = data54.width;
                                                                                                                    var _errs180 = errors;
                                                                                                                    if (!(typeof data55 ==
                                                                                                                        'number' &&
                                                                                                                        isFinite(data55))) {
                                                                                                                        validate11.errors =
                                                                                                                            [
                                                                                                                                {
                                                                                                                                    instancePath: instancePath +
                                                                                                                                        '/track/dimensions/width',
                                                                                                                                    schemaPath: '#/allOf/9/then/properties/track/properties/dimensions/properties/width/type',
                                                                                                                                    keyword: 'type',
                                                                                                                                    params: {
                                                                                                                                        type: 'number',
                                                                                                                                    },
                                                                                                                                    message: 'must be number',
                                                                                                                                },
                                                                                                                            ];
                                                                                                                        return false;
                                                                                                                    }
                                                                                                                    var valid39 = _errs180 === errors;
                                                                                                                }
                                                                                                                else {
                                                                                                                    var valid39 = true;
                                                                                                                }
                                                                                                                if (valid39) {
                                                                                                                    if (data54.height !==
                                                                                                                        undefined) {
                                                                                                                        var data56 = data54.height;
                                                                                                                        var _errs182 = errors;
                                                                                                                        if (!(typeof data56 ==
                                                                                                                            'number' &&
                                                                                                                            isFinite(data56))) {
                                                                                                                            validate11.errors =
                                                                                                                                [
                                                                                                                                    {
                                                                                                                                        instancePath: instancePath +
                                                                                                                                            '/track/dimensions/height',
                                                                                                                                        schemaPath: '#/allOf/9/then/properties/track/properties/dimensions/properties/height/type',
                                                                                                                                        keyword: 'type',
                                                                                                                                        params: {
                                                                                                                                            type: 'number',
                                                                                                                                        },
                                                                                                                                        message: 'must be number',
                                                                                                                                    },
                                                                                                                                ];
                                                                                                                            return false;
                                                                                                                        }
                                                                                                                        var valid39 = _errs182 ===
                                                                                                                            errors;
                                                                                                                    }
                                                                                                                    else {
                                                                                                                        var valid39 = true;
                                                                                                                    }
                                                                                                                }
                                                                                                            }
                                                                                                        }
                                                                                                    }
                                                                                                    else {
                                                                                                        validate11.errors = [
                                                                                                            {
                                                                                                                instancePath: instancePath +
                                                                                                                    '/track/dimensions',
                                                                                                                schemaPath: '#/allOf/9/then/properties/track/properties/dimensions/type',
                                                                                                                keyword: 'type',
                                                                                                                params: {
                                                                                                                    type: 'object',
                                                                                                                },
                                                                                                                message: 'must be object',
                                                                                                            },
                                                                                                        ];
                                                                                                        return false;
                                                                                                    }
                                                                                                }
                                                                                                var valid37 = _errs177 === errors;
                                                                                            }
                                                                                            else {
                                                                                                var valid37 = true;
                                                                                            }
                                                                                        }
                                                                                    }
                                                                                }
                                                                            }
                                                                        }
                                                                    }
                                                                    else {
                                                                        validate11.errors = [
                                                                            {
                                                                                instancePath: instancePath + '/track',
                                                                                schemaPath: '#/allOf/9/then/properties/track/type',
                                                                                keyword: 'type',
                                                                                params: { type: 'object' },
                                                                                message: 'must be object',
                                                                            },
                                                                        ];
                                                                        return false;
                                                                    }
                                                                }
                                                                var valid36 = _errs167 === errors;
                                                            }
                                                            else {
                                                                var valid36 = true;
                                                            }
                                                            if (valid36) {
                                                                if (data.participant !== undefined) {
                                                                    var data57 = data.participant;
                                                                    var _errs184 = errors;
                                                                    if (errors === _errs184) {
                                                                        if (data57 &&
                                                                            typeof data57 == 'object' &&
                                                                            !Array.isArray(data57)) {
                                                                            var missing16 = void 0;
                                                                            if ((data57.identity === undefined &&
                                                                                (missing16 = 'identity')) ||
                                                                                (data57.sid === undefined &&
                                                                                    (missing16 = 'sid'))) {
                                                                                validate11.errors = [
                                                                                    {
                                                                                        instancePath: instancePath + '/participant',
                                                                                        schemaPath: '#/allOf/9/then/properties/participant/required',
                                                                                        keyword: 'required',
                                                                                        params: {
                                                                                            missingProperty: missing16,
                                                                                        },
                                                                                        message: "must have required property '" +
                                                                                            missing16 +
                                                                                            "'",
                                                                                    },
                                                                                ];
                                                                                return false;
                                                                            }
                                                                            else {
                                                                                var _errs186 = errors;
                                                                                for (var key16 in data57) {
                                                                                    if (!(key16 === 'identity' ||
                                                                                        key16 === 'sid' ||
                                                                                        key16 === 'permissions')) {
                                                                                        validate11.errors = [
                                                                                            {
                                                                                                instancePath: instancePath + '/participant',
                                                                                                schemaPath: '#/allOf/9/then/properties/participant/additionalProperties',
                                                                                                keyword: 'additionalProperties',
                                                                                                params: {
                                                                                                    additionalProperty: key16,
                                                                                                },
                                                                                                message: 'must NOT have additional properties',
                                                                                            },
                                                                                        ];
                                                                                        return false;
                                                                                        break;
                                                                                    }
                                                                                }
                                                                                if (_errs186 === errors) {
                                                                                    if (data57.identity !== undefined) {
                                                                                        var _errs187 = errors;
                                                                                        if (typeof data57.identity !==
                                                                                            'string') {
                                                                                            validate11.errors = [
                                                                                                {
                                                                                                    instancePath: instancePath +
                                                                                                        '/participant/identity',
                                                                                                    schemaPath: '#/allOf/9/then/properties/participant/properties/identity/type',
                                                                                                    keyword: 'type',
                                                                                                    params: { type: 'string' },
                                                                                                    message: 'must be string',
                                                                                                },
                                                                                            ];
                                                                                            return false;
                                                                                        }
                                                                                        var valid40 = _errs187 === errors;
                                                                                    }
                                                                                    else {
                                                                                        var valid40 = true;
                                                                                    }
                                                                                    if (valid40) {
                                                                                        if (data57.sid !== undefined) {
                                                                                            var _errs189 = errors;
                                                                                            if (typeof data57.sid !== 'string') {
                                                                                                validate11.errors = [
                                                                                                    {
                                                                                                        instancePath: instancePath +
                                                                                                            '/participant/sid',
                                                                                                        schemaPath: '#/allOf/9/then/properties/participant/properties/sid/type',
                                                                                                        keyword: 'type',
                                                                                                        params: { type: 'string' },
                                                                                                        message: 'must be string',
                                                                                                    },
                                                                                                ];
                                                                                                return false;
                                                                                            }
                                                                                            var valid40 = _errs189 === errors;
                                                                                        }
                                                                                        else {
                                                                                            var valid40 = true;
                                                                                        }
                                                                                        if (valid40) {
                                                                                            if (data57.permissions !== undefined) {
                                                                                                var data60 = data57.permissions;
                                                                                                var _errs191 = errors;
                                                                                                if (errors === _errs191) {
                                                                                                    if (data60 &&
                                                                                                        typeof data60 == 'object' &&
                                                                                                        !Array.isArray(data60)) {
                                                                                                        var missing17 = void 0;
                                                                                                        if ((data60.canSubscribe ===
                                                                                                            undefined &&
                                                                                                            (missing17 =
                                                                                                                'canSubscribe')) ||
                                                                                                            (data60.canPublish ===
                                                                                                                undefined &&
                                                                                                                (missing17 =
                                                                                                                    'canPublish')) ||
                                                                                                            (data60.canPublishData ===
                                                                                                                undefined &&
                                                                                                                (missing17 =
                                                                                                                    'canPublishData')) ||
                                                                                                            (data60.hidden ===
                                                                                                                undefined &&
                                                                                                                (missing17 =
                                                                                                                    'hidden')) ||
                                                                                                            (data60.canUpdateMetadata ===
                                                                                                                undefined &&
                                                                                                                (missing17 =
                                                                                                                    'canUpdateMetadata')) ||
                                                                                                            (data60.canSubscribeMetrics ===
                                                                                                                undefined &&
                                                                                                                (missing17 =
                                                                                                                    'canSubscribeMetrics'))) {
                                                                                                            validate11.errors = [
                                                                                                                {
                                                                                                                    instancePath: instancePath +
                                                                                                                        '/participant/permissions',
                                                                                                                    schemaPath: '#/allOf/9/then/properties/participant/properties/permissions/required',
                                                                                                                    keyword: 'required',
                                                                                                                    params: {
                                                                                                                        missingProperty: missing17,
                                                                                                                    },
                                                                                                                    message: "must have required property '" +
                                                                                                                        missing17 +
                                                                                                                        "'",
                                                                                                                },
                                                                                                            ];
                                                                                                            return false;
                                                                                                        }
                                                                                                        else {
                                                                                                            var _errs193 = errors;
                                                                                                            for (var key17 in data60) {
                                                                                                                if (!(key17 ===
                                                                                                                    'canSubscribe' ||
                                                                                                                    key17 ===
                                                                                                                        'canPublish' ||
                                                                                                                    key17 ===
                                                                                                                        'canPublishData' ||
                                                                                                                    key17 ===
                                                                                                                        'hidden' ||
                                                                                                                    key17 ===
                                                                                                                        'canUpdateMetadata' ||
                                                                                                                    key17 ===
                                                                                                                        'canSubscribeMetrics')) {
                                                                                                                    validate11.errors = [
                                                                                                                        {
                                                                                                                            instancePath: instancePath +
                                                                                                                                '/participant/permissions',
                                                                                                                            schemaPath: '#/allOf/9/then/properties/participant/properties/permissions/additionalProperties',
                                                                                                                            keyword: 'additionalProperties',
                                                                                                                            params: {
                                                                                                                                additionalProperty: key17,
                                                                                                                            },
                                                                                                                            message: 'must NOT have additional properties',
                                                                                                                        },
                                                                                                                    ];
                                                                                                                    return false;
                                                                                                                    break;
                                                                                                                }
                                                                                                            }
                                                                                                            if (_errs193 === errors) {
                                                                                                                if (data60.canSubscribe !==
                                                                                                                    undefined) {
                                                                                                                    var _errs194 = errors;
                                                                                                                    if (typeof data60.canSubscribe !==
                                                                                                                        'boolean') {
                                                                                                                        validate11.errors =
                                                                                                                            [
                                                                                                                                {
                                                                                                                                    instancePath: instancePath +
                                                                                                                                        '/participant/permissions/canSubscribe',
                                                                                                                                    schemaPath: '#/allOf/9/then/properties/participant/properties/permissions/properties/canSubscribe/type',
                                                                                                                                    keyword: 'type',
                                                                                                                                    params: {
                                                                                                                                        type: 'boolean',
                                                                                                                                    },
                                                                                                                                    message: 'must be boolean',
                                                                                                                                },
                                                                                                                            ];
                                                                                                                        return false;
                                                                                                                    }
                                                                                                                    var valid41 = _errs194 === errors;
                                                                                                                }
                                                                                                                else {
                                                                                                                    var valid41 = true;
                                                                                                                }
                                                                                                                if (valid41) {
                                                                                                                    if (data60.canPublish !==
                                                                                                                        undefined) {
                                                                                                                        var _errs196 = errors;
                                                                                                                        if (typeof data60.canPublish !==
                                                                                                                            'boolean') {
                                                                                                                            validate11.errors =
                                                                                                                                [
                                                                                                                                    {
                                                                                                                                        instancePath: instancePath +
                                                                                                                                            '/participant/permissions/canPublish',
                                                                                                                                        schemaPath: '#/allOf/9/then/properties/participant/properties/permissions/properties/canPublish/type',
                                                                                                                                        keyword: 'type',
                                                                                                                                        params: {
                                                                                                                                            type: 'boolean',
                                                                                                                                        },
                                                                                                                                        message: 'must be boolean',
                                                                                                                                    },
                                                                                                                                ];
                                                                                                                            return false;
                                                                                                                        }
                                                                                                                        var valid41 = _errs196 ===
                                                                                                                            errors;
                                                                                                                    }
                                                                                                                    else {
                                                                                                                        var valid41 = true;
                                                                                                                    }
                                                                                                                    if (valid41) {
                                                                                                                        if (data60.canPublishData !==
                                                                                                                            undefined) {
                                                                                                                            var _errs198 = errors;
                                                                                                                            if (typeof data60.canPublishData !==
                                                                                                                                'boolean') {
                                                                                                                                validate11.errors =
                                                                                                                                    [
                                                                                                                                        {
                                                                                                                                            instancePath: instancePath +
                                                                                                                                                '/participant/permissions/canPublishData',
                                                                                                                                            schemaPath: '#/allOf/9/then/properties/participant/properties/permissions/properties/canPublishData/type',
                                                                                                                                            keyword: 'type',
                                                                                                                                            params: {
                                                                                                                                                type: 'boolean',
                                                                                                                                            },
                                                                                                                                            message: 'must be boolean',
                                                                                                                                        },
                                                                                                                                    ];
                                                                                                                                return false;
                                                                                                                            }
                                                                                                                            var valid41 = _errs198 ===
                                                                                                                                errors;
                                                                                                                        }
                                                                                                                        else {
                                                                                                                            var valid41 = true;
                                                                                                                        }
                                                                                                                        if (valid41) {
                                                                                                                            if (data60.hidden !==
                                                                                                                                undefined) {
                                                                                                                                var _errs200 = errors;
                                                                                                                                if (typeof data60.hidden !==
                                                                                                                                    'boolean') {
                                                                                                                                    validate11.errors =
                                                                                                                                        [
                                                                                                                                            {
                                                                                                                                                instancePath: instancePath +
                                                                                                                                                    '/participant/permissions/hidden',
                                                                                                                                                schemaPath: '#/allOf/9/then/properties/participant/properties/permissions/properties/hidden/type',
                                                                                                                                                keyword: 'type',
                                                                                                                                                params: {
                                                                                                                                                    type: 'boolean',
                                                                                                                                                },
                                                                                                                                                message: 'must be boolean',
                                                                                                                                            },
                                                                                                                                        ];
                                                                                                                                    return false;
                                                                                                                                }
                                                                                                                                var valid41 = _errs200 ===
                                                                                                                                    errors;
                                                                                                                            }
                                                                                                                            else {
                                                                                                                                var valid41 = true;
                                                                                                                            }
                                                                                                                            if (valid41) {
                                                                                                                                if (data60.canUpdateMetadata !==
                                                                                                                                    undefined) {
                                                                                                                                    var _errs202 = errors;
                                                                                                                                    if (typeof data60.canUpdateMetadata !==
                                                                                                                                        'boolean') {
                                                                                                                                        validate11.errors =
                                                                                                                                            [
                                                                                                                                                {
                                                                                                                                                    instancePath: instancePath +
                                                                                                                                                        '/participant/permissions/canUpdateMetadata',
                                                                                                                                                    schemaPath: '#/allOf/9/then/properties/participant/properties/permissions/properties/canUpdateMetadata/type',
                                                                                                                                                    keyword: 'type',
                                                                                                                                                    params: {
                                                                                                                                                        type: 'boolean',
                                                                                                                                                    },
                                                                                                                                                    message: 'must be boolean',
                                                                                                                                                },
                                                                                                                                            ];
                                                                                                                                        return false;
                                                                                                                                    }
                                                                                                                                    var valid41 = _errs202 ===
                                                                                                                                        errors;
                                                                                                                                }
                                                                                                                                else {
                                                                                                                                    var valid41 = true;
                                                                                                                                }
                                                                                                                                if (valid41) {
                                                                                                                                    if (data60.canSubscribeMetrics !==
                                                                                                                                        undefined) {
                                                                                                                                        var _errs204 = errors;
                                                                                                                                        if (typeof data60.canSubscribeMetrics !==
                                                                                                                                            'boolean') {
                                                                                                                                            validate11.errors =
                                                                                                                                                [
                                                                                                                                                    {
                                                                                                                                                        instancePath: instancePath +
                                                                                                                                                            '/participant/permissions/canSubscribeMetrics',
                                                                                                                                                        schemaPath: '#/allOf/9/then/properties/participant/properties/permissions/properties/canSubscribeMetrics/type',
                                                                                                                                                        keyword: 'type',
                                                                                                                                                        params: {
                                                                                                                                                            type: 'boolean',
                                                                                                                                                        },
                                                                                                                                                        message: 'must be boolean',
                                                                                                                                                    },
                                                                                                                                                ];
                                                                                                                                            return false;
                                                                                                                                        }
                                                                                                                                        var valid41 = _errs204 ===
                                                                                                                                            errors;
                                                                                                                                    }
                                                                                                                                    else {
                                                                                                                                        var valid41 = true;
                                                                                                                                    }
                                                                                                                                }
                                                                                                                            }
                                                                                                                        }
                                                                                                                    }
                                                                                                                }
                                                                                                            }
                                                                                                        }
                                                                                                    }
                                                                                                    else {
                                                                                                        validate11.errors = [
                                                                                                            {
                                                                                                                instancePath: instancePath +
                                                                                                                    '/participant/permissions',
                                                                                                                schemaPath: '#/allOf/9/then/properties/participant/properties/permissions/type',
                                                                                                                keyword: 'type',
                                                                                                                params: {
                                                                                                                    type: 'object',
                                                                                                                },
                                                                                                                message: 'must be object',
                                                                                                            },
                                                                                                        ];
                                                                                                        return false;
                                                                                                    }
                                                                                                }
                                                                                                var valid40 = _errs191 === errors;
                                                                                            }
                                                                                            else {
                                                                                                var valid40 = true;
                                                                                            }
                                                                                        }
                                                                                    }
                                                                                }
                                                                            }
                                                                        }
                                                                        else {
                                                                            validate11.errors = [
                                                                                {
                                                                                    instancePath: instancePath + '/participant',
                                                                                    schemaPath: '#/allOf/9/then/properties/participant/type',
                                                                                    keyword: 'type',
                                                                                    params: { type: 'object' },
                                                                                    message: 'must be object',
                                                                                },
                                                                            ];
                                                                            return false;
                                                                        }
                                                                    }
                                                                    var valid36 = _errs184 === errors;
                                                                }
                                                                else {
                                                                    var valid36 = true;
                                                                }
                                                                if (valid36) {
                                                                    if (data.name !== undefined) {
                                                                        var data67 = data.name;
                                                                        var _errs206 = errors;
                                                                        if (typeof data67 !== 'string') {
                                                                            validate11.errors = [
                                                                                {
                                                                                    instancePath: instancePath + '/name',
                                                                                    schemaPath: '#/allOf/9/then/properties/name/type',
                                                                                    keyword: 'type',
                                                                                    params: { type: 'string' },
                                                                                    message: 'must be string',
                                                                                },
                                                                            ];
                                                                            return false;
                                                                        }
                                                                        if ('trackPublished' !== data67) {
                                                                            validate11.errors = [
                                                                                {
                                                                                    instancePath: instancePath + '/name',
                                                                                    schemaPath: '#/allOf/9/then/properties/name/const',
                                                                                    keyword: 'const',
                                                                                    params: {
                                                                                        allowedValue: 'trackPublished',
                                                                                    },
                                                                                    message: 'must be equal to constant',
                                                                                },
                                                                            ];
                                                                            return false;
                                                                        }
                                                                        var valid36 = _errs206 === errors;
                                                                    }
                                                                    else {
                                                                        var valid36 = true;
                                                                    }
                                                                    if (valid36) {
                                                                        if (data.timestamp !== undefined) {
                                                                            var data68 = data.timestamp;
                                                                            var _errs208 = errors;
                                                                            if (!(typeof data68 == 'number' &&
                                                                                isFinite(data68))) {
                                                                                validate11.errors = [
                                                                                    {
                                                                                        instancePath: instancePath + '/timestamp',
                                                                                        schemaPath: '#/allOf/9/then/properties/timestamp/type',
                                                                                        keyword: 'type',
                                                                                        params: { type: 'number' },
                                                                                        message: 'must be number',
                                                                                    },
                                                                                ];
                                                                                return false;
                                                                            }
                                                                            var valid36 = _errs208 === errors;
                                                                        }
                                                                        else {
                                                                            var valid36 = true;
                                                                        }
                                                                    }
                                                                }
                                                            }
                                                        }
                                                    }
                                                }
                                                else {
                                                    validate11.errors = [
                                                        {
                                                            instancePath: instancePath,
                                                            schemaPath: '#/allOf/9/then/type',
                                                            keyword: 'type',
                                                            params: { type: 'object' },
                                                            message: 'must be object',
                                                        },
                                                    ];
                                                    return false;
                                                }
                                            }
                                            var _valid9 = _errs164 === errors;
                                            valid34 = _valid9;
                                        }
                                        if (!valid34) {
                                            var err29 = {
                                                instancePath: instancePath,
                                                schemaPath: '#/allOf/9/if',
                                                keyword: 'if',
                                                params: { failingKeyword: 'then' },
                                                message: 'must match "then" schema',
                                            };
                                            if (vErrors === null) {
                                                vErrors = [err29];
                                            }
                                            else {
                                                vErrors.push(err29);
                                            }
                                            errors++;
                                            validate11.errors = vErrors;
                                            return false;
                                        }
                                        var valid0 = _errs159 === errors;
                                        if (valid0) {
                                            var _errs210 = errors;
                                            var _errs211 = errors;
                                            var valid42 = true;
                                            var _errs212 = errors;
                                            if (data &&
                                                typeof data == 'object' &&
                                                !Array.isArray(data)) {
                                                if (data.name !== undefined) {
                                                    var data69 = data.name;
                                                    if (typeof data69 !== 'string') {
                                                        var err30 = {};
                                                        if (vErrors === null) {
                                                            vErrors = [err30];
                                                        }
                                                        else {
                                                            vErrors.push(err30);
                                                        }
                                                        errors++;
                                                    }
                                                    if ('trackSubscribed' !== data69) {
                                                        var err31 = {};
                                                        if (vErrors === null) {
                                                            vErrors = [err31];
                                                        }
                                                        else {
                                                            vErrors.push(err31);
                                                        }
                                                        errors++;
                                                    }
                                                }
                                            }
                                            var _valid10 = _errs212 === errors;
                                            errors = _errs211;
                                            if (vErrors !== null) {
                                                if (_errs211) {
                                                    vErrors.length = _errs211;
                                                }
                                                else {
                                                    vErrors = null;
                                                }
                                            }
                                            if (_valid10) {
                                                var _errs215 = errors;
                                                if (errors === _errs215) {
                                                    if (data &&
                                                        typeof data == 'object' &&
                                                        !Array.isArray(data)) {
                                                        var missing18 = void 0;
                                                        if ((data.name === undefined &&
                                                            (missing18 = 'name')) ||
                                                            (data.participant === undefined &&
                                                                (missing18 = 'participant')) ||
                                                            (data.timestamp === undefined &&
                                                                (missing18 = 'timestamp')) ||
                                                            (data.track === undefined &&
                                                                (missing18 = 'track'))) {
                                                            validate11.errors = [
                                                                {
                                                                    instancePath: instancePath,
                                                                    schemaPath: '#/allOf/10/then/required',
                                                                    keyword: 'required',
                                                                    params: { missingProperty: missing18 },
                                                                    message: "must have required property '" +
                                                                        missing18 +
                                                                        "'",
                                                                },
                                                            ];
                                                            return false;
                                                        }
                                                        else {
                                                            var _errs217 = errors;
                                                            for (var key18 in data) {
                                                                if (!(key18 === 'track' ||
                                                                    key18 === 'participant' ||
                                                                    key18 === 'name' ||
                                                                    key18 === 'timestamp')) {
                                                                    validate11.errors = [
                                                                        {
                                                                            instancePath: instancePath,
                                                                            schemaPath: '#/allOf/10/then/additionalProperties',
                                                                            keyword: 'additionalProperties',
                                                                            params: { additionalProperty: key18 },
                                                                            message: 'must NOT have additional properties',
                                                                        },
                                                                    ];
                                                                    return false;
                                                                    break;
                                                                }
                                                            }
                                                            if (_errs217 === errors) {
                                                                if (data.track !== undefined) {
                                                                    var data70 = data.track;
                                                                    var _errs218 = errors;
                                                                    if (errors === _errs218) {
                                                                        if (data70 &&
                                                                            typeof data70 == 'object' &&
                                                                            !Array.isArray(data70)) {
                                                                            var missing19 = void 0;
                                                                            if ((data70.sid === undefined &&
                                                                                (missing19 = 'sid')) ||
                                                                                (data70.source === undefined &&
                                                                                    (missing19 = 'source'))) {
                                                                                validate11.errors = [
                                                                                    {
                                                                                        instancePath: instancePath + '/track',
                                                                                        schemaPath: '#/allOf/10/then/properties/track/required',
                                                                                        keyword: 'required',
                                                                                        params: {
                                                                                            missingProperty: missing19,
                                                                                        },
                                                                                        message: "must have required property '" +
                                                                                            missing19 +
                                                                                            "'",
                                                                                    },
                                                                                ];
                                                                                return false;
                                                                            }
                                                                            else {
                                                                                var _errs220 = errors;
                                                                                for (var key19 in data70) {
                                                                                    if (!(key19 === 'sid' ||
                                                                                        key19 === 'source' ||
                                                                                        key19 === 'mimeType' ||
                                                                                        key19 === 'dimensions')) {
                                                                                        validate11.errors = [
                                                                                            {
                                                                                                instancePath: instancePath + '/track',
                                                                                                schemaPath: '#/allOf/10/then/properties/track/additionalProperties',
                                                                                                keyword: 'additionalProperties',
                                                                                                params: {
                                                                                                    additionalProperty: key19,
                                                                                                },
                                                                                                message: 'must NOT have additional properties',
                                                                                            },
                                                                                        ];
                                                                                        return false;
                                                                                        break;
                                                                                    }
                                                                                }
                                                                                if (_errs220 === errors) {
                                                                                    if (data70.sid !== undefined) {
                                                                                        var _errs221 = errors;
                                                                                        if (typeof data70.sid !== 'string') {
                                                                                            validate11.errors = [
                                                                                                {
                                                                                                    instancePath: instancePath + '/track/sid',
                                                                                                    schemaPath: '#/allOf/10/then/properties/track/properties/sid/type',
                                                                                                    keyword: 'type',
                                                                                                    params: { type: 'string' },
                                                                                                    message: 'must be string',
                                                                                                },
                                                                                            ];
                                                                                            return false;
                                                                                        }
                                                                                        var valid45 = _errs221 === errors;
                                                                                    }
                                                                                    else {
                                                                                        var valid45 = true;
                                                                                    }
                                                                                    if (valid45) {
                                                                                        if (data70.source !== undefined) {
                                                                                            var data72 = data70.source;
                                                                                            var _errs223 = errors;
                                                                                            if (typeof data72 !== 'string') {
                                                                                                validate11.errors = [
                                                                                                    {
                                                                                                        instancePath: instancePath +
                                                                                                            '/track/source',
                                                                                                        schemaPath: '#/definitions/Track.Source/type',
                                                                                                        keyword: 'type',
                                                                                                        params: { type: 'string' },
                                                                                                        message: 'must be string',
                                                                                                    },
                                                                                                ];
                                                                                                return false;
                                                                                            }
                                                                                            if (!(data72 === 'camera' ||
                                                                                                data72 === 'microphone' ||
                                                                                                data72 === 'screen_share' ||
                                                                                                data72 ===
                                                                                                    'screen_share_audio' ||
                                                                                                data72 === 'unknown')) {
                                                                                                validate11.errors = [
                                                                                                    {
                                                                                                        instancePath: instancePath +
                                                                                                            '/track/source',
                                                                                                        schemaPath: '#/definitions/Track.Source/enum',
                                                                                                        keyword: 'enum',
                                                                                                        params: {
                                                                                                            allowedValues: schema31.enum,
                                                                                                        },
                                                                                                        message: 'must be equal to one of the allowed values',
                                                                                                    },
                                                                                                ];
                                                                                                return false;
                                                                                            }
                                                                                            var valid45 = _errs223 === errors;
                                                                                        }
                                                                                        else {
                                                                                            var valid45 = true;
                                                                                        }
                                                                                        if (valid45) {
                                                                                            if (data70.mimeType !== undefined) {
                                                                                                var _errs226 = errors;
                                                                                                if (typeof data70.mimeType !==
                                                                                                    'string') {
                                                                                                    validate11.errors = [
                                                                                                        {
                                                                                                            instancePath: instancePath +
                                                                                                                '/track/mimeType',
                                                                                                            schemaPath: '#/allOf/10/then/properties/track/properties/mimeType/type',
                                                                                                            keyword: 'type',
                                                                                                            params: {
                                                                                                                type: 'string',
                                                                                                            },
                                                                                                            message: 'must be string',
                                                                                                        },
                                                                                                    ];
                                                                                                    return false;
                                                                                                }
                                                                                                var valid45 = _errs226 === errors;
                                                                                            }
                                                                                            else {
                                                                                                var valid45 = true;
                                                                                            }
                                                                                            if (valid45) {
                                                                                                if (data70.dimensions !==
                                                                                                    undefined) {
                                                                                                    var data74 = data70.dimensions;
                                                                                                    var _errs228 = errors;
                                                                                                    if (errors === _errs228) {
                                                                                                        if (data74 &&
                                                                                                            typeof data74 ==
                                                                                                                'object' &&
                                                                                                            !Array.isArray(data74)) {
                                                                                                            var missing20 = void 0;
                                                                                                            if ((data74.width ===
                                                                                                                undefined &&
                                                                                                                (missing20 =
                                                                                                                    'width')) ||
                                                                                                                (data74.height ===
                                                                                                                    undefined &&
                                                                                                                    (missing20 =
                                                                                                                        'height'))) {
                                                                                                                validate11.errors = [
                                                                                                                    {
                                                                                                                        instancePath: instancePath +
                                                                                                                            '/track/dimensions',
                                                                                                                        schemaPath: '#/allOf/10/then/properties/track/properties/dimensions/required',
                                                                                                                        keyword: 'required',
                                                                                                                        params: {
                                                                                                                            missingProperty: missing20,
                                                                                                                        },
                                                                                                                        message: "must have required property '" +
                                                                                                                            missing20 +
                                                                                                                            "'",
                                                                                                                    },
                                                                                                                ];
                                                                                                                return false;
                                                                                                            }
                                                                                                            else {
                                                                                                                var _errs230 = errors;
                                                                                                                for (var key20 in data74) {
                                                                                                                    if (!(key20 ===
                                                                                                                        'width' ||
                                                                                                                        key20 === 'height')) {
                                                                                                                        validate11.errors =
                                                                                                                            [
                                                                                                                                {
                                                                                                                                    instancePath: instancePath +
                                                                                                                                        '/track/dimensions',
                                                                                                                                    schemaPath: '#/allOf/10/then/properties/track/properties/dimensions/additionalProperties',
                                                                                                                                    keyword: 'additionalProperties',
                                                                                                                                    params: {
                                                                                                                                        additionalProperty: key20,
                                                                                                                                    },
                                                                                                                                    message: 'must NOT have additional properties',
                                                                                                                                },
                                                                                                                            ];
                                                                                                                        return false;
                                                                                                                        break;
                                                                                                                    }
                                                                                                                }
                                                                                                                if (_errs230 === errors) {
                                                                                                                    if (data74.width !==
                                                                                                                        undefined) {
                                                                                                                        var data75 = data74.width;
                                                                                                                        var _errs231 = errors;
                                                                                                                        if (!(typeof data75 ==
                                                                                                                            'number' &&
                                                                                                                            isFinite(data75))) {
                                                                                                                            validate11.errors =
                                                                                                                                [
                                                                                                                                    {
                                                                                                                                        instancePath: instancePath +
                                                                                                                                            '/track/dimensions/width',
                                                                                                                                        schemaPath: '#/allOf/10/then/properties/track/properties/dimensions/properties/width/type',
                                                                                                                                        keyword: 'type',
                                                                                                                                        params: {
                                                                                                                                            type: 'number',
                                                                                                                                        },
                                                                                                                                        message: 'must be number',
                                                                                                                                    },
                                                                                                                                ];
                                                                                                                            return false;
                                                                                                                        }
                                                                                                                        var valid47 = _errs231 ===
                                                                                                                            errors;
                                                                                                                    }
                                                                                                                    else {
                                                                                                                        var valid47 = true;
                                                                                                                    }
                                                                                                                    if (valid47) {
                                                                                                                        if (data74.height !==
                                                                                                                            undefined) {
                                                                                                                            var data76 = data74.height;
                                                                                                                            var _errs233 = errors;
                                                                                                                            if (!(typeof data76 ==
                                                                                                                                'number' &&
                                                                                                                                isFinite(data76))) {
                                                                                                                                validate11.errors =
                                                                                                                                    [
                                                                                                                                        {
                                                                                                                                            instancePath: instancePath +
                                                                                                                                                '/track/dimensions/height',
                                                                                                                                            schemaPath: '#/allOf/10/then/properties/track/properties/dimensions/properties/height/type',
                                                                                                                                            keyword: 'type',
                                                                                                                                            params: {
                                                                                                                                                type: 'number',
                                                                                                                                            },
                                                                                                                                            message: 'must be number',
                                                                                                                                        },
                                                                                                                                    ];
                                                                                                                                return false;
                                                                                                                            }
                                                                                                                            var valid47 = _errs233 ===
                                                                                                                                errors;
                                                                                                                        }
                                                                                                                        else {
                                                                                                                            var valid47 = true;
                                                                                                                        }
                                                                                                                    }
                                                                                                                }
                                                                                                            }
                                                                                                        }
                                                                                                        else {
                                                                                                            validate11.errors = [
                                                                                                                {
                                                                                                                    instancePath: instancePath +
                                                                                                                        '/track/dimensions',
                                                                                                                    schemaPath: '#/allOf/10/then/properties/track/properties/dimensions/type',
                                                                                                                    keyword: 'type',
                                                                                                                    params: {
                                                                                                                        type: 'object',
                                                                                                                    },
                                                                                                                    message: 'must be object',
                                                                                                                },
                                                                                                            ];
                                                                                                            return false;
                                                                                                        }
                                                                                                    }
                                                                                                    var valid45 = _errs228 === errors;
                                                                                                }
                                                                                                else {
                                                                                                    var valid45 = true;
                                                                                                }
                                                                                            }
                                                                                        }
                                                                                    }
                                                                                }
                                                                            }
                                                                        }
                                                                        else {
                                                                            validate11.errors = [
                                                                                {
                                                                                    instancePath: instancePath + '/track',
                                                                                    schemaPath: '#/allOf/10/then/properties/track/type',
                                                                                    keyword: 'type',
                                                                                    params: { type: 'object' },
                                                                                    message: 'must be object',
                                                                                },
                                                                            ];
                                                                            return false;
                                                                        }
                                                                    }
                                                                    var valid44 = _errs218 === errors;
                                                                }
                                                                else {
                                                                    var valid44 = true;
                                                                }
                                                                if (valid44) {
                                                                    if (data.participant !== undefined) {
                                                                        var data77 = data.participant;
                                                                        var _errs235 = errors;
                                                                        if (errors === _errs235) {
                                                                            if (data77 &&
                                                                                typeof data77 == 'object' &&
                                                                                !Array.isArray(data77)) {
                                                                                var missing21 = void 0;
                                                                                if ((data77.identity === undefined &&
                                                                                    (missing21 = 'identity')) ||
                                                                                    (data77.sid === undefined &&
                                                                                        (missing21 = 'sid'))) {
                                                                                    validate11.errors = [
                                                                                        {
                                                                                            instancePath: instancePath + '/participant',
                                                                                            schemaPath: '#/allOf/10/then/properties/participant/required',
                                                                                            keyword: 'required',
                                                                                            params: {
                                                                                                missingProperty: missing21,
                                                                                            },
                                                                                            message: "must have required property '" +
                                                                                                missing21 +
                                                                                                "'",
                                                                                        },
                                                                                    ];
                                                                                    return false;
                                                                                }
                                                                                else {
                                                                                    var _errs237 = errors;
                                                                                    for (var key21 in data77) {
                                                                                        if (!(key21 === 'identity' ||
                                                                                            key21 === 'sid' ||
                                                                                            key21 === 'permissions')) {
                                                                                            validate11.errors = [
                                                                                                {
                                                                                                    instancePath: instancePath +
                                                                                                        '/participant',
                                                                                                    schemaPath: '#/allOf/10/then/properties/participant/additionalProperties',
                                                                                                    keyword: 'additionalProperties',
                                                                                                    params: {
                                                                                                        additionalProperty: key21,
                                                                                                    },
                                                                                                    message: 'must NOT have additional properties',
                                                                                                },
                                                                                            ];
                                                                                            return false;
                                                                                            break;
                                                                                        }
                                                                                    }
                                                                                    if (_errs237 === errors) {
                                                                                        if (data77.identity !== undefined) {
                                                                                            var _errs238 = errors;
                                                                                            if (typeof data77.identity !==
                                                                                                'string') {
                                                                                                validate11.errors = [
                                                                                                    {
                                                                                                        instancePath: instancePath +
                                                                                                            '/participant/identity',
                                                                                                        schemaPath: '#/allOf/10/then/properties/participant/properties/identity/type',
                                                                                                        keyword: 'type',
                                                                                                        params: { type: 'string' },
                                                                                                        message: 'must be string',
                                                                                                    },
                                                                                                ];
                                                                                                return false;
                                                                                            }
                                                                                            var valid48 = _errs238 === errors;
                                                                                        }
                                                                                        else {
                                                                                            var valid48 = true;
                                                                                        }
                                                                                        if (valid48) {
                                                                                            if (data77.sid !== undefined) {
                                                                                                var _errs240 = errors;
                                                                                                if (typeof data77.sid !== 'string') {
                                                                                                    validate11.errors = [
                                                                                                        {
                                                                                                            instancePath: instancePath +
                                                                                                                '/participant/sid',
                                                                                                            schemaPath: '#/allOf/10/then/properties/participant/properties/sid/type',
                                                                                                            keyword: 'type',
                                                                                                            params: {
                                                                                                                type: 'string',
                                                                                                            },
                                                                                                            message: 'must be string',
                                                                                                        },
                                                                                                    ];
                                                                                                    return false;
                                                                                                }
                                                                                                var valid48 = _errs240 === errors;
                                                                                            }
                                                                                            else {
                                                                                                var valid48 = true;
                                                                                            }
                                                                                            if (valid48) {
                                                                                                if (data77.permissions !==
                                                                                                    undefined) {
                                                                                                    var data80 = data77.permissions;
                                                                                                    var _errs242 = errors;
                                                                                                    if (errors === _errs242) {
                                                                                                        if (data80 &&
                                                                                                            typeof data80 ==
                                                                                                                'object' &&
                                                                                                            !Array.isArray(data80)) {
                                                                                                            var missing22 = void 0;
                                                                                                            if ((data80.canSubscribe ===
                                                                                                                undefined &&
                                                                                                                (missing22 =
                                                                                                                    'canSubscribe')) ||
                                                                                                                (data80.canPublish ===
                                                                                                                    undefined &&
                                                                                                                    (missing22 =
                                                                                                                        'canPublish')) ||
                                                                                                                (data80.canPublishData ===
                                                                                                                    undefined &&
                                                                                                                    (missing22 =
                                                                                                                        'canPublishData')) ||
                                                                                                                (data80.hidden ===
                                                                                                                    undefined &&
                                                                                                                    (missing22 =
                                                                                                                        'hidden')) ||
                                                                                                                (data80.canUpdateMetadata ===
                                                                                                                    undefined &&
                                                                                                                    (missing22 =
                                                                                                                        'canUpdateMetadata')) ||
                                                                                                                (data80.canSubscribeMetrics ===
                                                                                                                    undefined &&
                                                                                                                    (missing22 =
                                                                                                                        'canSubscribeMetrics'))) {
                                                                                                                validate11.errors = [
                                                                                                                    {
                                                                                                                        instancePath: instancePath +
                                                                                                                            '/participant/permissions',
                                                                                                                        schemaPath: '#/allOf/10/then/properties/participant/properties/permissions/required',
                                                                                                                        keyword: 'required',
                                                                                                                        params: {
                                                                                                                            missingProperty: missing22,
                                                                                                                        },
                                                                                                                        message: "must have required property '" +
                                                                                                                            missing22 +
                                                                                                                            "'",
                                                                                                                    },
                                                                                                                ];
                                                                                                                return false;
                                                                                                            }
                                                                                                            else {
                                                                                                                var _errs244 = errors;
                                                                                                                for (var key22 in data80) {
                                                                                                                    if (!(key22 ===
                                                                                                                        'canSubscribe' ||
                                                                                                                        key22 ===
                                                                                                                            'canPublish' ||
                                                                                                                        key22 ===
                                                                                                                            'canPublishData' ||
                                                                                                                        key22 ===
                                                                                                                            'hidden' ||
                                                                                                                        key22 ===
                                                                                                                            'canUpdateMetadata' ||
                                                                                                                        key22 ===
                                                                                                                            'canSubscribeMetrics')) {
                                                                                                                        validate11.errors =
                                                                                                                            [
                                                                                                                                {
                                                                                                                                    instancePath: instancePath +
                                                                                                                                        '/participant/permissions',
                                                                                                                                    schemaPath: '#/allOf/10/then/properties/participant/properties/permissions/additionalProperties',
                                                                                                                                    keyword: 'additionalProperties',
                                                                                                                                    params: {
                                                                                                                                        additionalProperty: key22,
                                                                                                                                    },
                                                                                                                                    message: 'must NOT have additional properties',
                                                                                                                                },
                                                                                                                            ];
                                                                                                                        return false;
                                                                                                                        break;
                                                                                                                    }
                                                                                                                }
                                                                                                                if (_errs244 === errors) {
                                                                                                                    if (data80.canSubscribe !==
                                                                                                                        undefined) {
                                                                                                                        var _errs245 = errors;
                                                                                                                        if (typeof data80.canSubscribe !==
                                                                                                                            'boolean') {
                                                                                                                            validate11.errors =
                                                                                                                                [
                                                                                                                                    {
                                                                                                                                        instancePath: instancePath +
                                                                                                                                            '/participant/permissions/canSubscribe',
                                                                                                                                        schemaPath: '#/allOf/10/then/properties/participant/properties/permissions/properties/canSubscribe/type',
                                                                                                                                        keyword: 'type',
                                                                                                                                        params: {
                                                                                                                                            type: 'boolean',
                                                                                                                                        },
                                                                                                                                        message: 'must be boolean',
                                                                                                                                    },
                                                                                                                                ];
                                                                                                                            return false;
                                                                                                                        }
                                                                                                                        var valid49 = _errs245 ===
                                                                                                                            errors;
                                                                                                                    }
                                                                                                                    else {
                                                                                                                        var valid49 = true;
                                                                                                                    }
                                                                                                                    if (valid49) {
                                                                                                                        if (data80.canPublish !==
                                                                                                                            undefined) {
                                                                                                                            var _errs247 = errors;
                                                                                                                            if (typeof data80.canPublish !==
                                                                                                                                'boolean') {
                                                                                                                                validate11.errors =
                                                                                                                                    [
                                                                                                                                        {
                                                                                                                                            instancePath: instancePath +
                                                                                                                                                '/participant/permissions/canPublish',
                                                                                                                                            schemaPath: '#/allOf/10/then/properties/participant/properties/permissions/properties/canPublish/type',
                                                                                                                                            keyword: 'type',
                                                                                                                                            params: {
                                                                                                                                                type: 'boolean',
                                                                                                                                            },
                                                                                                                                            message: 'must be boolean',
                                                                                                                                        },
                                                                                                                                    ];
                                                                                                                                return false;
                                                                                                                            }
                                                                                                                            var valid49 = _errs247 ===
                                                                                                                                errors;
                                                                                                                        }
                                                                                                                        else {
                                                                                                                            var valid49 = true;
                                                                                                                        }
                                                                                                                        if (valid49) {
                                                                                                                            if (data80.canPublishData !==
                                                                                                                                undefined) {
                                                                                                                                var _errs249 = errors;
                                                                                                                                if (typeof data80.canPublishData !==
                                                                                                                                    'boolean') {
                                                                                                                                    validate11.errors =
                                                                                                                                        [
                                                                                                                                            {
                                                                                                                                                instancePath: instancePath +
                                                                                                                                                    '/participant/permissions/canPublishData',
                                                                                                                                                schemaPath: '#/allOf/10/then/properties/participant/properties/permissions/properties/canPublishData/type',
                                                                                                                                                keyword: 'type',
                                                                                                                                                params: {
                                                                                                                                                    type: 'boolean',
                                                                                                                                                },
                                                                                                                                                message: 'must be boolean',
                                                                                                                                            },
                                                                                                                                        ];
                                                                                                                                    return false;
                                                                                                                                }
                                                                                                                                var valid49 = _errs249 ===
                                                                                                                                    errors;
                                                                                                                            }
                                                                                                                            else {
                                                                                                                                var valid49 = true;
                                                                                                                            }
                                                                                                                            if (valid49) {
                                                                                                                                if (data80.hidden !==
                                                                                                                                    undefined) {
                                                                                                                                    var _errs251 = errors;
                                                                                                                                    if (typeof data80.hidden !==
                                                                                                                                        'boolean') {
                                                                                                                                        validate11.errors =
                                                                                                                                            [
                                                                                                                                                {
                                                                                                                                                    instancePath: instancePath +
                                                                                                                                                        '/participant/permissions/hidden',
                                                                                                                                                    schemaPath: '#/allOf/10/then/properties/participant/properties/permissions/properties/hidden/type',
                                                                                                                                                    keyword: 'type',
                                                                                                                                                    params: {
                                                                                                                                                        type: 'boolean',
                                                                                                                                                    },
                                                                                                                                                    message: 'must be boolean',
                                                                                                                                                },
                                                                                                                                            ];
                                                                                                                                        return false;
                                                                                                                                    }
                                                                                                                                    var valid49 = _errs251 ===
                                                                                                                                        errors;
                                                                                                                                }
                                                                                                                                else {
                                                                                                                                    var valid49 = true;
                                                                                                                                }
                                                                                                                                if (valid49) {
                                                                                                                                    if (data80.canUpdateMetadata !==
                                                                                                                                        undefined) {
                                                                                                                                        var _errs253 = errors;
                                                                                                                                        if (typeof data80.canUpdateMetadata !==
                                                                                                                                            'boolean') {
                                                                                                                                            validate11.errors =
                                                                                                                                                [
                                                                                                                                                    {
                                                                                                                                                        instancePath: instancePath +
                                                                                                                                                            '/participant/permissions/canUpdateMetadata',
                                                                                                                                                        schemaPath: '#/allOf/10/then/properties/participant/properties/permissions/properties/canUpdateMetadata/type',
                                                                                                                                                        keyword: 'type',
                                                                                                                                                        params: {
                                                                                                                                                            type: 'boolean',
                                                                                                                                                        },
                                                                                                                                                        message: 'must be boolean',
                                                                                                                                                    },
                                                                                                                                                ];
                                                                                                                                            return false;
                                                                                                                                        }
                                                                                                                                        var valid49 = _errs253 ===
                                                                                                                                            errors;
                                                                                                                                    }
                                                                                                                                    else {
                                                                                                                                        var valid49 = true;
                                                                                                                                    }
                                                                                                                                    if (valid49) {
                                                                                                                                        if (data80.canSubscribeMetrics !==
                                                                                                                                            undefined) {
                                                                                                                                            var _errs255 = errors;
                                                                                                                                            if (typeof data80.canSubscribeMetrics !==
                                                                                                                                                'boolean') {
                                                                                                                                                validate11.errors =
                                                                                                                                                    [
                                                                                                                                                        {
                                                                                                                                                            instancePath: instancePath +
                                                                                                                                                                '/participant/permissions/canSubscribeMetrics',
                                                                                                                                                            schemaPath: '#/allOf/10/then/properties/participant/properties/permissions/properties/canSubscribeMetrics/type',
                                                                                                                                                            keyword: 'type',
                                                                                                                                                            params: {
                                                                                                                                                                type: 'boolean',
                                                                                                                                                            },
                                                                                                                                                            message: 'must be boolean',
                                                                                                                                                        },
                                                                                                                                                    ];
                                                                                                                                                return false;
                                                                                                                                            }
                                                                                                                                            var valid49 = _errs255 ===
                                                                                                                                                errors;
                                                                                                                                        }
                                                                                                                                        else {
                                                                                                                                            var valid49 = true;
                                                                                                                                        }
                                                                                                                                    }
                                                                                                                                }
                                                                                                                            }
                                                                                                                        }
                                                                                                                    }
                                                                                                                }
                                                                                                            }
                                                                                                        }
                                                                                                        else {
                                                                                                            validate11.errors = [
                                                                                                                {
                                                                                                                    instancePath: instancePath +
                                                                                                                        '/participant/permissions',
                                                                                                                    schemaPath: '#/allOf/10/then/properties/participant/properties/permissions/type',
                                                                                                                    keyword: 'type',
                                                                                                                    params: {
                                                                                                                        type: 'object',
                                                                                                                    },
                                                                                                                    message: 'must be object',
                                                                                                                },
                                                                                                            ];
                                                                                                            return false;
                                                                                                        }
                                                                                                    }
                                                                                                    var valid48 = _errs242 === errors;
                                                                                                }
                                                                                                else {
                                                                                                    var valid48 = true;
                                                                                                }
                                                                                            }
                                                                                        }
                                                                                    }
                                                                                }
                                                                            }
                                                                            else {
                                                                                validate11.errors = [
                                                                                    {
                                                                                        instancePath: instancePath + '/participant',
                                                                                        schemaPath: '#/allOf/10/then/properties/participant/type',
                                                                                        keyword: 'type',
                                                                                        params: { type: 'object' },
                                                                                        message: 'must be object',
                                                                                    },
                                                                                ];
                                                                                return false;
                                                                            }
                                                                        }
                                                                        var valid44 = _errs235 === errors;
                                                                    }
                                                                    else {
                                                                        var valid44 = true;
                                                                    }
                                                                    if (valid44) {
                                                                        if (data.name !== undefined) {
                                                                            var data87 = data.name;
                                                                            var _errs257 = errors;
                                                                            if (typeof data87 !== 'string') {
                                                                                validate11.errors = [
                                                                                    {
                                                                                        instancePath: instancePath + '/name',
                                                                                        schemaPath: '#/allOf/10/then/properties/name/type',
                                                                                        keyword: 'type',
                                                                                        params: { type: 'string' },
                                                                                        message: 'must be string',
                                                                                    },
                                                                                ];
                                                                                return false;
                                                                            }
                                                                            if ('trackSubscribed' !== data87) {
                                                                                validate11.errors = [
                                                                                    {
                                                                                        instancePath: instancePath + '/name',
                                                                                        schemaPath: '#/allOf/10/then/properties/name/const',
                                                                                        keyword: 'const',
                                                                                        params: {
                                                                                            allowedValue: 'trackSubscribed',
                                                                                        },
                                                                                        message: 'must be equal to constant',
                                                                                    },
                                                                                ];
                                                                                return false;
                                                                            }
                                                                            var valid44 = _errs257 === errors;
                                                                        }
                                                                        else {
                                                                            var valid44 = true;
                                                                        }
                                                                        if (valid44) {
                                                                            if (data.timestamp !== undefined) {
                                                                                var data88 = data.timestamp;
                                                                                var _errs259 = errors;
                                                                                if (!(typeof data88 == 'number' &&
                                                                                    isFinite(data88))) {
                                                                                    validate11.errors = [
                                                                                        {
                                                                                            instancePath: instancePath + '/timestamp',
                                                                                            schemaPath: '#/allOf/10/then/properties/timestamp/type',
                                                                                            keyword: 'type',
                                                                                            params: { type: 'number' },
                                                                                            message: 'must be number',
                                                                                        },
                                                                                    ];
                                                                                    return false;
                                                                                }
                                                                                var valid44 = _errs259 === errors;
                                                                            }
                                                                            else {
                                                                                var valid44 = true;
                                                                            }
                                                                        }
                                                                    }
                                                                }
                                                            }
                                                        }
                                                    }
                                                    else {
                                                        validate11.errors = [
                                                            {
                                                                instancePath: instancePath,
                                                                schemaPath: '#/allOf/10/then/type',
                                                                keyword: 'type',
                                                                params: { type: 'object' },
                                                                message: 'must be object',
                                                            },
                                                        ];
                                                        return false;
                                                    }
                                                }
                                                var _valid10 = _errs215 === errors;
                                                valid42 = _valid10;
                                            }
                                            if (!valid42) {
                                                var err32 = {
                                                    instancePath: instancePath,
                                                    schemaPath: '#/allOf/10/if',
                                                    keyword: 'if',
                                                    params: { failingKeyword: 'then' },
                                                    message: 'must match "then" schema',
                                                };
                                                if (vErrors === null) {
                                                    vErrors = [err32];
                                                }
                                                else {
                                                    vErrors.push(err32);
                                                }
                                                errors++;
                                                validate11.errors = vErrors;
                                                return false;
                                            }
                                            var valid0 = _errs210 === errors;
                                            if (valid0) {
                                                var _errs261 = errors;
                                                var _errs262 = errors;
                                                var valid50 = true;
                                                var _errs263 = errors;
                                                if (data &&
                                                    typeof data == 'object' &&
                                                    !Array.isArray(data)) {
                                                    if (data.name !== undefined) {
                                                        var data89 = data.name;
                                                        if (typeof data89 !== 'string') {
                                                            var err33 = {};
                                                            if (vErrors === null) {
                                                                vErrors = [err33];
                                                            }
                                                            else {
                                                                vErrors.push(err33);
                                                            }
                                                            errors++;
                                                        }
                                                        if ('trackSubscriptionFailed' !== data89) {
                                                            var err34 = {};
                                                            if (vErrors === null) {
                                                                vErrors = [err34];
                                                            }
                                                            else {
                                                                vErrors.push(err34);
                                                            }
                                                            errors++;
                                                        }
                                                    }
                                                }
                                                var _valid11 = _errs263 === errors;
                                                errors = _errs262;
                                                if (vErrors !== null) {
                                                    if (_errs262) {
                                                        vErrors.length = _errs262;
                                                    }
                                                    else {
                                                        vErrors = null;
                                                    }
                                                }
                                                if (_valid11) {
                                                    var _errs266 = errors;
                                                    if (errors === _errs266) {
                                                        if (data &&
                                                            typeof data == 'object' &&
                                                            !Array.isArray(data)) {
                                                            var missing23 = void 0;
                                                            if ((data.name === undefined &&
                                                                (missing23 = 'name')) ||
                                                                (data.participant === undefined &&
                                                                    (missing23 = 'participant')) ||
                                                                (data.timestamp === undefined &&
                                                                    (missing23 = 'timestamp')) ||
                                                                (data.track === undefined &&
                                                                    (missing23 = 'track'))) {
                                                                validate11.errors = [
                                                                    {
                                                                        instancePath: instancePath,
                                                                        schemaPath: '#/allOf/11/then/required',
                                                                        keyword: 'required',
                                                                        params: { missingProperty: missing23 },
                                                                        message: "must have required property '" +
                                                                            missing23 +
                                                                            "'",
                                                                    },
                                                                ];
                                                                return false;
                                                            }
                                                            else {
                                                                var _errs268 = errors;
                                                                for (var key23 in data) {
                                                                    if (!(key23 === 'track' ||
                                                                        key23 === 'reason' ||
                                                                        key23 === 'participant' ||
                                                                        key23 === 'name' ||
                                                                        key23 === 'timestamp')) {
                                                                        validate11.errors = [
                                                                            {
                                                                                instancePath: instancePath,
                                                                                schemaPath: '#/allOf/11/then/additionalProperties',
                                                                                keyword: 'additionalProperties',
                                                                                params: { additionalProperty: key23 },
                                                                                message: 'must NOT have additional properties',
                                                                            },
                                                                        ];
                                                                        return false;
                                                                        break;
                                                                    }
                                                                }
                                                                if (_errs268 === errors) {
                                                                    if (data.track !== undefined) {
                                                                        var data90 = data.track;
                                                                        var _errs269 = errors;
                                                                        if (errors === _errs269) {
                                                                            if (data90 &&
                                                                                typeof data90 == 'object' &&
                                                                                !Array.isArray(data90)) {
                                                                                var missing24 = void 0;
                                                                                if (data90.sid === undefined &&
                                                                                    (missing24 = 'sid')) {
                                                                                    validate11.errors = [
                                                                                        {
                                                                                            instancePath: instancePath + '/track',
                                                                                            schemaPath: '#/allOf/11/then/properties/track/required',
                                                                                            keyword: 'required',
                                                                                            params: {
                                                                                                missingProperty: missing24,
                                                                                            },
                                                                                            message: "must have required property '" +
                                                                                                missing24 +
                                                                                                "'",
                                                                                        },
                                                                                    ];
                                                                                    return false;
                                                                                }
                                                                                else {
                                                                                    var _errs271 = errors;
                                                                                    for (var key24 in data90) {
                                                                                        if (!(key24 === 'sid')) {
                                                                                            validate11.errors = [
                                                                                                {
                                                                                                    instancePath: instancePath + '/track',
                                                                                                    schemaPath: '#/allOf/11/then/properties/track/additionalProperties',
                                                                                                    keyword: 'additionalProperties',
                                                                                                    params: {
                                                                                                        additionalProperty: key24,
                                                                                                    },
                                                                                                    message: 'must NOT have additional properties',
                                                                                                },
                                                                                            ];
                                                                                            return false;
                                                                                            break;
                                                                                        }
                                                                                    }
                                                                                    if (_errs271 === errors) {
                                                                                        if (data90.sid !== undefined) {
                                                                                            if (typeof data90.sid !== 'string') {
                                                                                                validate11.errors = [
                                                                                                    {
                                                                                                        instancePath: instancePath +
                                                                                                            '/track/sid',
                                                                                                        schemaPath: '#/allOf/11/then/properties/track/properties/sid/type',
                                                                                                        keyword: 'type',
                                                                                                        params: { type: 'string' },
                                                                                                        message: 'must be string',
                                                                                                    },
                                                                                                ];
                                                                                                return false;
                                                                                            }
                                                                                        }
                                                                                    }
                                                                                }
                                                                            }
                                                                            else {
                                                                                validate11.errors = [
                                                                                    {
                                                                                        instancePath: instancePath + '/track',
                                                                                        schemaPath: '#/allOf/11/then/properties/track/type',
                                                                                        keyword: 'type',
                                                                                        params: { type: 'object' },
                                                                                        message: 'must be object',
                                                                                    },
                                                                                ];
                                                                                return false;
                                                                            }
                                                                        }
                                                                        var valid52 = _errs269 === errors;
                                                                    }
                                                                    else {
                                                                        var valid52 = true;
                                                                    }
                                                                    if (valid52) {
                                                                        if (data.reason !== undefined) {
                                                                            var data92 = data.reason;
                                                                            var _errs274 = errors;
                                                                            if (!(typeof data92 == 'number' &&
                                                                                isFinite(data92))) {
                                                                                validate11.errors = [
                                                                                    {
                                                                                        instancePath: instancePath + '/reason',
                                                                                        schemaPath: '#/definitions/SubscriptionError/type',
                                                                                        keyword: 'type',
                                                                                        params: { type: 'number' },
                                                                                        message: 'must be number',
                                                                                    },
                                                                                ];
                                                                                return false;
                                                                            }
                                                                            if (!(data92 === 0 ||
                                                                                data92 === 1 ||
                                                                                data92 === 2)) {
                                                                                validate11.errors = [
                                                                                    {
                                                                                        instancePath: instancePath + '/reason',
                                                                                        schemaPath: '#/definitions/SubscriptionError/enum',
                                                                                        keyword: 'enum',
                                                                                        params: {
                                                                                            allowedValues: schema33.enum,
                                                                                        },
                                                                                        message: 'must be equal to one of the allowed values',
                                                                                    },
                                                                                ];
                                                                                return false;
                                                                            }
                                                                            var valid52 = _errs274 === errors;
                                                                        }
                                                                        else {
                                                                            var valid52 = true;
                                                                        }
                                                                        if (valid52) {
                                                                            if (data.participant !== undefined) {
                                                                                var data93 = data.participant;
                                                                                var _errs277 = errors;
                                                                                if (errors === _errs277) {
                                                                                    if (data93 &&
                                                                                        typeof data93 == 'object' &&
                                                                                        !Array.isArray(data93)) {
                                                                                        var missing25 = void 0;
                                                                                        if ((data93.identity === undefined &&
                                                                                            (missing25 = 'identity')) ||
                                                                                            (data93.sid === undefined &&
                                                                                                (missing25 = 'sid'))) {
                                                                                            validate11.errors = [
                                                                                                {
                                                                                                    instancePath: instancePath +
                                                                                                        '/participant',
                                                                                                    schemaPath: '#/allOf/11/then/properties/participant/required',
                                                                                                    keyword: 'required',
                                                                                                    params: {
                                                                                                        missingProperty: missing25,
                                                                                                    },
                                                                                                    message: "must have required property '" +
                                                                                                        missing25 +
                                                                                                        "'",
                                                                                                },
                                                                                            ];
                                                                                            return false;
                                                                                        }
                                                                                        else {
                                                                                            var _errs279 = errors;
                                                                                            for (var key25 in data93) {
                                                                                                if (!(key25 === 'identity' ||
                                                                                                    key25 === 'sid' ||
                                                                                                    key25 === 'permissions')) {
                                                                                                    validate11.errors = [
                                                                                                        {
                                                                                                            instancePath: instancePath +
                                                                                                                '/participant',
                                                                                                            schemaPath: '#/allOf/11/then/properties/participant/additionalProperties',
                                                                                                            keyword: 'additionalProperties',
                                                                                                            params: {
                                                                                                                additionalProperty: key25,
                                                                                                            },
                                                                                                            message: 'must NOT have additional properties',
                                                                                                        },
                                                                                                    ];
                                                                                                    return false;
                                                                                                    break;
                                                                                                }
                                                                                            }
                                                                                            if (_errs279 === errors) {
                                                                                                if (data93.identity !== undefined) {
                                                                                                    var _errs280 = errors;
                                                                                                    if (typeof data93.identity !==
                                                                                                        'string') {
                                                                                                        validate11.errors = [
                                                                                                            {
                                                                                                                instancePath: instancePath +
                                                                                                                    '/participant/identity',
                                                                                                                schemaPath: '#/allOf/11/then/properties/participant/properties/identity/type',
                                                                                                                keyword: 'type',
                                                                                                                params: {
                                                                                                                    type: 'string',
                                                                                                                },
                                                                                                                message: 'must be string',
                                                                                                            },
                                                                                                        ];
                                                                                                        return false;
                                                                                                    }
                                                                                                    var valid55 = _errs280 === errors;
                                                                                                }
                                                                                                else {
                                                                                                    var valid55 = true;
                                                                                                }
                                                                                                if (valid55) {
                                                                                                    if (data93.sid !== undefined) {
                                                                                                        var _errs282 = errors;
                                                                                                        if (typeof data93.sid !==
                                                                                                            'string') {
                                                                                                            validate11.errors = [
                                                                                                                {
                                                                                                                    instancePath: instancePath +
                                                                                                                        '/participant/sid',
                                                                                                                    schemaPath: '#/allOf/11/then/properties/participant/properties/sid/type',
                                                                                                                    keyword: 'type',
                                                                                                                    params: {
                                                                                                                        type: 'string',
                                                                                                                    },
                                                                                                                    message: 'must be string',
                                                                                                                },
                                                                                                            ];
                                                                                                            return false;
                                                                                                        }
                                                                                                        var valid55 = _errs282 === errors;
                                                                                                    }
                                                                                                    else {
                                                                                                        var valid55 = true;
                                                                                                    }
                                                                                                    if (valid55) {
                                                                                                        if (data93.permissions !==
                                                                                                            undefined) {
                                                                                                            var data96 = data93.permissions;
                                                                                                            var _errs284 = errors;
                                                                                                            if (errors === _errs284) {
                                                                                                                if (data96 &&
                                                                                                                    typeof data96 ==
                                                                                                                        'object' &&
                                                                                                                    !Array.isArray(data96)) {
                                                                                                                    var missing26 = void 0;
                                                                                                                    if ((data96.canSubscribe ===
                                                                                                                        undefined &&
                                                                                                                        (missing26 =
                                                                                                                            'canSubscribe')) ||
                                                                                                                        (data96.canPublish ===
                                                                                                                            undefined &&
                                                                                                                            (missing26 =
                                                                                                                                'canPublish')) ||
                                                                                                                        (data96.canPublishData ===
                                                                                                                            undefined &&
                                                                                                                            (missing26 =
                                                                                                                                'canPublishData')) ||
                                                                                                                        (data96.hidden ===
                                                                                                                            undefined &&
                                                                                                                            (missing26 =
                                                                                                                                'hidden')) ||
                                                                                                                        (data96.canUpdateMetadata ===
                                                                                                                            undefined &&
                                                                                                                            (missing26 =
                                                                                                                                'canUpdateMetadata')) ||
                                                                                                                        (data96.canSubscribeMetrics ===
                                                                                                                            undefined &&
                                                                                                                            (missing26 =
                                                                                                                                'canSubscribeMetrics'))) {
                                                                                                                        validate11.errors =
                                                                                                                            [
                                                                                                                                {
                                                                                                                                    instancePath: instancePath +
                                                                                                                                        '/participant/permissions',
                                                                                                                                    schemaPath: '#/allOf/11/then/properties/participant/properties/permissions/required',
                                                                                                                                    keyword: 'required',
                                                                                                                                    params: {
                                                                                                                                        missingProperty: missing26,
                                                                                                                                    },
                                                                                                                                    message: "must have required property '" +
                                                                                                                                        missing26 +
                                                                                                                                        "'",
                                                                                                                                },
                                                                                                                            ];
                                                                                                                        return false;
                                                                                                                    }
                                                                                                                    else {
                                                                                                                        var _errs286 = errors;
                                                                                                                        for (var key26 in data96) {
                                                                                                                            if (!(key26 ===
                                                                                                                                'canSubscribe' ||
                                                                                                                                key26 ===
                                                                                                                                    'canPublish' ||
                                                                                                                                key26 ===
                                                                                                                                    'canPublishData' ||
                                                                                                                                key26 ===
                                                                                                                                    'hidden' ||
                                                                                                                                key26 ===
                                                                                                                                    'canUpdateMetadata' ||
                                                                                                                                key26 ===
                                                                                                                                    'canSubscribeMetrics')) {
                                                                                                                                validate11.errors =
                                                                                                                                    [
                                                                                                                                        {
                                                                                                                                            instancePath: instancePath +
                                                                                                                                                '/participant/permissions',
                                                                                                                                            schemaPath: '#/allOf/11/then/properties/participant/properties/permissions/additionalProperties',
                                                                                                                                            keyword: 'additionalProperties',
                                                                                                                                            params: {
                                                                                                                                                additionalProperty: key26,
                                                                                                                                            },
                                                                                                                                            message: 'must NOT have additional properties',
                                                                                                                                        },
                                                                                                                                    ];
                                                                                                                                return false;
                                                                                                                                break;
                                                                                                                            }
                                                                                                                        }
                                                                                                                        if (_errs286 ===
                                                                                                                            errors) {
                                                                                                                            if (data96.canSubscribe !==
                                                                                                                                undefined) {
                                                                                                                                var _errs287 = errors;
                                                                                                                                if (typeof data96.canSubscribe !==
                                                                                                                                    'boolean') {
                                                                                                                                    validate11.errors =
                                                                                                                                        [
                                                                                                                                            {
                                                                                                                                                instancePath: instancePath +
                                                                                                                                                    '/participant/permissions/canSubscribe',
                                                                                                                                                schemaPath: '#/allOf/11/then/properties/participant/properties/permissions/properties/canSubscribe/type',
                                                                                                                                                keyword: 'type',
                                                                                                                                                params: {
                                                                                                                                                    type: 'boolean',
                                                                                                                                                },
                                                                                                                                                message: 'must be boolean',
                                                                                                                                            },
                                                                                                                                        ];
                                                                                                                                    return false;
                                                                                                                                }
                                                                                                                                var valid56 = _errs287 ===
                                                                                                                                    errors;
                                                                                                                            }
                                                                                                                            else {
                                                                                                                                var valid56 = true;
                                                                                                                            }
                                                                                                                            if (valid56) {
                                                                                                                                if (data96.canPublish !==
                                                                                                                                    undefined) {
                                                                                                                                    var _errs289 = errors;
                                                                                                                                    if (typeof data96.canPublish !==
                                                                                                                                        'boolean') {
                                                                                                                                        validate11.errors =
                                                                                                                                            [
                                                                                                                                                {
                                                                                                                                                    instancePath: instancePath +
                                                                                                                                                        '/participant/permissions/canPublish',
                                                                                                                                                    schemaPath: '#/allOf/11/then/properties/participant/properties/permissions/properties/canPublish/type',
                                                                                                                                                    keyword: 'type',
                                                                                                                                                    params: {
                                                                                                                                                        type: 'boolean',
                                                                                                                                                    },
                                                                                                                                                    message: 'must be boolean',
                                                                                                                                                },
                                                                                                                                            ];
                                                                                                                                        return false;
                                                                                                                                    }
                                                                                                                                    var valid56 = _errs289 ===
                                                                                                                                        errors;
                                                                                                                                }
                                                                                                                                else {
                                                                                                                                    var valid56 = true;
                                                                                                                                }
                                                                                                                                if (valid56) {
                                                                                                                                    if (data96.canPublishData !==
                                                                                                                                        undefined) {
                                                                                                                                        var _errs291 = errors;
                                                                                                                                        if (typeof data96.canPublishData !==
                                                                                                                                            'boolean') {
                                                                                                                                            validate11.errors =
                                                                                                                                                [
                                                                                                                                                    {
                                                                                                                                                        instancePath: instancePath +
                                                                                                                                                            '/participant/permissions/canPublishData',
                                                                                                                                                        schemaPath: '#/allOf/11/then/properties/participant/properties/permissions/properties/canPublishData/type',
                                                                                                                                                        keyword: 'type',
                                                                                                                                                        params: {
                                                                                                                                                            type: 'boolean',
                                                                                                                                                        },
                                                                                                                                                        message: 'must be boolean',
                                                                                                                                                    },
                                                                                                                                                ];
                                                                                                                                            return false;
                                                                                                                                        }
                                                                                                                                        var valid56 = _errs291 ===
                                                                                                                                            errors;
                                                                                                                                    }
                                                                                                                                    else {
                                                                                                                                        var valid56 = true;
                                                                                                                                    }
                                                                                                                                    if (valid56) {
                                                                                                                                        if (data96.hidden !==
                                                                                                                                            undefined) {
                                                                                                                                            var _errs293 = errors;
                                                                                                                                            if (typeof data96.hidden !==
                                                                                                                                                'boolean') {
                                                                                                                                                validate11.errors =
                                                                                                                                                    [
                                                                                                                                                        {
                                                                                                                                                            instancePath: instancePath +
                                                                                                                                                                '/participant/permissions/hidden',
                                                                                                                                                            schemaPath: '#/allOf/11/then/properties/participant/properties/permissions/properties/hidden/type',
                                                                                                                                                            keyword: 'type',
                                                                                                                                                            params: {
                                                                                                                                                                type: 'boolean',
                                                                                                                                                            },
                                                                                                                                                            message: 'must be boolean',
                                                                                                                                                        },
                                                                                                                                                    ];
                                                                                                                                                return false;
                                                                                                                                            }
                                                                                                                                            var valid56 = _errs293 ===
                                                                                                                                                errors;
                                                                                                                                        }
                                                                                                                                        else {
                                                                                                                                            var valid56 = true;
                                                                                                                                        }
                                                                                                                                        if (valid56) {
                                                                                                                                            if (data96.canUpdateMetadata !==
                                                                                                                                                undefined) {
                                                                                                                                                var _errs295 = errors;
                                                                                                                                                if (typeof data96.canUpdateMetadata !==
                                                                                                                                                    'boolean') {
                                                                                                                                                    validate11.errors =
                                                                                                                                                        [
                                                                                                                                                            {
                                                                                                                                                                instancePath: instancePath +
                                                                                                                                                                    '/participant/permissions/canUpdateMetadata',
                                                                                                                                                                schemaPath: '#/allOf/11/then/properties/participant/properties/permissions/properties/canUpdateMetadata/type',
                                                                                                                                                                keyword: 'type',
                                                                                                                                                                params: {
                                                                                                                                                                    type: 'boolean',
                                                                                                                                                                },
                                                                                                                                                                message: 'must be boolean',
                                                                                                                                                            },
                                                                                                                                                        ];
                                                                                                                                                    return false;
                                                                                                                                                }
                                                                                                                                                var valid56 = _errs295 ===
                                                                                                                                                    errors;
                                                                                                                                            }
                                                                                                                                            else {
                                                                                                                                                var valid56 = true;
                                                                                                                                            }
                                                                                                                                            if (valid56) {
                                                                                                                                                if (data96.canSubscribeMetrics !==
                                                                                                                                                    undefined) {
                                                                                                                                                    var _errs297 = errors;
                                                                                                                                                    if (typeof data96.canSubscribeMetrics !==
                                                                                                                                                        'boolean') {
                                                                                                                                                        validate11.errors =
                                                                                                                                                            [
                                                                                                                                                                {
                                                                                                                                                                    instancePath: instancePath +
                                                                                                                                                                        '/participant/permissions/canSubscribeMetrics',
                                                                                                                                                                    schemaPath: '#/allOf/11/then/properties/participant/properties/permissions/properties/canSubscribeMetrics/type',
                                                                                                                                                                    keyword: 'type',
                                                                                                                                                                    params: {
                                                                                                                                                                        type: 'boolean',
                                                                                                                                                                    },
                                                                                                                                                                    message: 'must be boolean',
                                                                                                                                                                },
                                                                                                                                                            ];
                                                                                                                                                        return false;
                                                                                                                                                    }
                                                                                                                                                    var valid56 = _errs297 ===
                                                                                                                                                        errors;
                                                                                                                                                }
                                                                                                                                                else {
                                                                                                                                                    var valid56 = true;
                                                                                                                                                }
                                                                                                                                            }
                                                                                                                                        }
                                                                                                                                    }
                                                                                                                                }
                                                                                                                            }
                                                                                                                        }
                                                                                                                    }
                                                                                                                }
                                                                                                                else {
                                                                                                                    validate11.errors = [
                                                                                                                        {
                                                                                                                            instancePath: instancePath +
                                                                                                                                '/participant/permissions',
                                                                                                                            schemaPath: '#/allOf/11/then/properties/participant/properties/permissions/type',
                                                                                                                            keyword: 'type',
                                                                                                                            params: {
                                                                                                                                type: 'object',
                                                                                                                            },
                                                                                                                            message: 'must be object',
                                                                                                                        },
                                                                                                                    ];
                                                                                                                    return false;
                                                                                                                }
                                                                                                            }
                                                                                                            var valid55 = _errs284 === errors;
                                                                                                        }
                                                                                                        else {
                                                                                                            var valid55 = true;
                                                                                                        }
                                                                                                    }
                                                                                                }
                                                                                            }
                                                                                        }
                                                                                    }
                                                                                    else {
                                                                                        validate11.errors = [
                                                                                            {
                                                                                                instancePath: instancePath + '/participant',
                                                                                                schemaPath: '#/allOf/11/then/properties/participant/type',
                                                                                                keyword: 'type',
                                                                                                params: { type: 'object' },
                                                                                                message: 'must be object',
                                                                                            },
                                                                                        ];
                                                                                        return false;
                                                                                    }
                                                                                }
                                                                                var valid52 = _errs277 === errors;
                                                                            }
                                                                            else {
                                                                                var valid52 = true;
                                                                            }
                                                                            if (valid52) {
                                                                                if (data.name !== undefined) {
                                                                                    var data103 = data.name;
                                                                                    var _errs299 = errors;
                                                                                    if (typeof data103 !== 'string') {
                                                                                        validate11.errors = [
                                                                                            {
                                                                                                instancePath: instancePath + '/name',
                                                                                                schemaPath: '#/allOf/11/then/properties/name/type',
                                                                                                keyword: 'type',
                                                                                                params: { type: 'string' },
                                                                                                message: 'must be string',
                                                                                            },
                                                                                        ];
                                                                                        return false;
                                                                                    }
                                                                                    if ('trackSubscriptionFailed' !==
                                                                                        data103) {
                                                                                        validate11.errors = [
                                                                                            {
                                                                                                instancePath: instancePath + '/name',
                                                                                                schemaPath: '#/allOf/11/then/properties/name/const',
                                                                                                keyword: 'const',
                                                                                                params: {
                                                                                                    allowedValue: 'trackSubscriptionFailed',
                                                                                                },
                                                                                                message: 'must be equal to constant',
                                                                                            },
                                                                                        ];
                                                                                        return false;
                                                                                    }
                                                                                    var valid52 = _errs299 === errors;
                                                                                }
                                                                                else {
                                                                                    var valid52 = true;
                                                                                }
                                                                                if (valid52) {
                                                                                    if (data.timestamp !== undefined) {
                                                                                        var data104 = data.timestamp;
                                                                                        var _errs301 = errors;
                                                                                        if (!(typeof data104 == 'number' &&
                                                                                            isFinite(data104))) {
                                                                                            validate11.errors = [
                                                                                                {
                                                                                                    instancePath: instancePath + '/timestamp',
                                                                                                    schemaPath: '#/allOf/11/then/properties/timestamp/type',
                                                                                                    keyword: 'type',
                                                                                                    params: { type: 'number' },
                                                                                                    message: 'must be number',
                                                                                                },
                                                                                            ];
                                                                                            return false;
                                                                                        }
                                                                                        var valid52 = _errs301 === errors;
                                                                                    }
                                                                                    else {
                                                                                        var valid52 = true;
                                                                                    }
                                                                                }
                                                                            }
                                                                        }
                                                                    }
                                                                }
                                                            }
                                                        }
                                                        else {
                                                            validate11.errors = [
                                                                {
                                                                    instancePath: instancePath,
                                                                    schemaPath: '#/allOf/11/then/type',
                                                                    keyword: 'type',
                                                                    params: { type: 'object' },
                                                                    message: 'must be object',
                                                                },
                                                            ];
                                                            return false;
                                                        }
                                                    }
                                                    var _valid11 = _errs266 === errors;
                                                    valid50 = _valid11;
                                                }
                                                if (!valid50) {
                                                    var err35 = {
                                                        instancePath: instancePath,
                                                        schemaPath: '#/allOf/11/if',
                                                        keyword: 'if',
                                                        params: { failingKeyword: 'then' },
                                                        message: 'must match "then" schema',
                                                    };
                                                    if (vErrors === null) {
                                                        vErrors = [err35];
                                                    }
                                                    else {
                                                        vErrors.push(err35);
                                                    }
                                                    errors++;
                                                    validate11.errors = vErrors;
                                                    return false;
                                                }
                                                var valid0 = _errs261 === errors;
                                                if (valid0) {
                                                    var _errs303 = errors;
                                                    var _errs304 = errors;
                                                    var valid57 = true;
                                                    var _errs305 = errors;
                                                    if (data &&
                                                        typeof data == 'object' &&
                                                        !Array.isArray(data)) {
                                                        if (data.name !== undefined) {
                                                            var data105 = data.name;
                                                            if (typeof data105 !== 'string') {
                                                                var err36 = {};
                                                                if (vErrors === null) {
                                                                    vErrors = [err36];
                                                                }
                                                                else {
                                                                    vErrors.push(err36);
                                                                }
                                                                errors++;
                                                            }
                                                            if ('trackUnpublished' !== data105) {
                                                                var err37 = {};
                                                                if (vErrors === null) {
                                                                    vErrors = [err37];
                                                                }
                                                                else {
                                                                    vErrors.push(err37);
                                                                }
                                                                errors++;
                                                            }
                                                        }
                                                    }
                                                    var _valid12 = _errs305 === errors;
                                                    errors = _errs304;
                                                    if (vErrors !== null) {
                                                        if (_errs304) {
                                                            vErrors.length = _errs304;
                                                        }
                                                        else {
                                                            vErrors = null;
                                                        }
                                                    }
                                                    if (_valid12) {
                                                        var _errs308 = errors;
                                                        if (errors === _errs308) {
                                                            if (data &&
                                                                typeof data == 'object' &&
                                                                !Array.isArray(data)) {
                                                                var missing27 = void 0;
                                                                if ((data.name === undefined &&
                                                                    (missing27 = 'name')) ||
                                                                    (data.participant === undefined &&
                                                                        (missing27 = 'participant')) ||
                                                                    (data.timestamp === undefined &&
                                                                        (missing27 = 'timestamp')) ||
                                                                    (data.track === undefined &&
                                                                        (missing27 = 'track'))) {
                                                                    validate11.errors = [
                                                                        {
                                                                            instancePath: instancePath,
                                                                            schemaPath: '#/allOf/12/then/required',
                                                                            keyword: 'required',
                                                                            params: { missingProperty: missing27 },
                                                                            message: "must have required property '" +
                                                                                missing27 +
                                                                                "'",
                                                                        },
                                                                    ];
                                                                    return false;
                                                                }
                                                                else {
                                                                    var _errs310 = errors;
                                                                    for (var key27 in data) {
                                                                        if (!(key27 === 'track' ||
                                                                            key27 === 'participant' ||
                                                                            key27 === 'name' ||
                                                                            key27 === 'timestamp')) {
                                                                            validate11.errors = [
                                                                                {
                                                                                    instancePath: instancePath,
                                                                                    schemaPath: '#/allOf/12/then/additionalProperties',
                                                                                    keyword: 'additionalProperties',
                                                                                    params: { additionalProperty: key27 },
                                                                                    message: 'must NOT have additional properties',
                                                                                },
                                                                            ];
                                                                            return false;
                                                                            break;
                                                                        }
                                                                    }
                                                                    if (_errs310 === errors) {
                                                                        if (data.track !== undefined) {
                                                                            var data106 = data.track;
                                                                            var _errs311 = errors;
                                                                            if (errors === _errs311) {
                                                                                if (data106 &&
                                                                                    typeof data106 == 'object' &&
                                                                                    !Array.isArray(data106)) {
                                                                                    var missing28 = void 0;
                                                                                    if ((data106.sid === undefined &&
                                                                                        (missing28 = 'sid')) ||
                                                                                        (data106.source === undefined &&
                                                                                            (missing28 = 'source'))) {
                                                                                        validate11.errors = [
                                                                                            {
                                                                                                instancePath: instancePath + '/track',
                                                                                                schemaPath: '#/allOf/12/then/properties/track/required',
                                                                                                keyword: 'required',
                                                                                                params: {
                                                                                                    missingProperty: missing28,
                                                                                                },
                                                                                                message: "must have required property '" +
                                                                                                    missing28 +
                                                                                                    "'",
                                                                                            },
                                                                                        ];
                                                                                        return false;
                                                                                    }
                                                                                    else {
                                                                                        var _errs313 = errors;
                                                                                        for (var key28 in data106) {
                                                                                            if (!(key28 === 'sid' ||
                                                                                                key28 === 'source' ||
                                                                                                key28 === 'mimeType' ||
                                                                                                key28 === 'dimensions')) {
                                                                                                validate11.errors = [
                                                                                                    {
                                                                                                        instancePath: instancePath + '/track',
                                                                                                        schemaPath: '#/allOf/12/then/properties/track/additionalProperties',
                                                                                                        keyword: 'additionalProperties',
                                                                                                        params: {
                                                                                                            additionalProperty: key28,
                                                                                                        },
                                                                                                        message: 'must NOT have additional properties',
                                                                                                    },
                                                                                                ];
                                                                                                return false;
                                                                                                break;
                                                                                            }
                                                                                        }
                                                                                        if (_errs313 === errors) {
                                                                                            if (data106.sid !== undefined) {
                                                                                                var _errs314 = errors;
                                                                                                if (typeof data106.sid !==
                                                                                                    'string') {
                                                                                                    validate11.errors = [
                                                                                                        {
                                                                                                            instancePath: instancePath +
                                                                                                                '/track/sid',
                                                                                                            schemaPath: '#/allOf/12/then/properties/track/properties/sid/type',
                                                                                                            keyword: 'type',
                                                                                                            params: {
                                                                                                                type: 'string',
                                                                                                            },
                                                                                                            message: 'must be string',
                                                                                                        },
                                                                                                    ];
                                                                                                    return false;
                                                                                                }
                                                                                                var valid60 = _errs314 === errors;
                                                                                            }
                                                                                            else {
                                                                                                var valid60 = true;
                                                                                            }
                                                                                            if (valid60) {
                                                                                                if (data106.source !== undefined) {
                                                                                                    var data108 = data106.source;
                                                                                                    var _errs316 = errors;
                                                                                                    if (typeof data108 !== 'string') {
                                                                                                        validate11.errors = [
                                                                                                            {
                                                                                                                instancePath: instancePath +
                                                                                                                    '/track/source',
                                                                                                                schemaPath: '#/definitions/Track.Source/type',
                                                                                                                keyword: 'type',
                                                                                                                params: {
                                                                                                                    type: 'string',
                                                                                                                },
                                                                                                                message: 'must be string',
                                                                                                            },
                                                                                                        ];
                                                                                                        return false;
                                                                                                    }
                                                                                                    if (!(data108 === 'camera' ||
                                                                                                        data108 ===
                                                                                                            'microphone' ||
                                                                                                        data108 ===
                                                                                                            'screen_share' ||
                                                                                                        data108 ===
                                                                                                            'screen_share_audio' ||
                                                                                                        data108 === 'unknown')) {
                                                                                                        validate11.errors = [
                                                                                                            {
                                                                                                                instancePath: instancePath +
                                                                                                                    '/track/source',
                                                                                                                schemaPath: '#/definitions/Track.Source/enum',
                                                                                                                keyword: 'enum',
                                                                                                                params: {
                                                                                                                    allowedValues: schema31.enum,
                                                                                                                },
                                                                                                                message: 'must be equal to one of the allowed values',
                                                                                                            },
                                                                                                        ];
                                                                                                        return false;
                                                                                                    }
                                                                                                    var valid60 = _errs316 === errors;
                                                                                                }
                                                                                                else {
                                                                                                    var valid60 = true;
                                                                                                }
                                                                                                if (valid60) {
                                                                                                    if (data106.mimeType !==
                                                                                                        undefined) {
                                                                                                        var _errs319 = errors;
                                                                                                        if (typeof data106.mimeType !==
                                                                                                            'string') {
                                                                                                            validate11.errors = [
                                                                                                                {
                                                                                                                    instancePath: instancePath +
                                                                                                                        '/track/mimeType',
                                                                                                                    schemaPath: '#/allOf/12/then/properties/track/properties/mimeType/type',
                                                                                                                    keyword: 'type',
                                                                                                                    params: {
                                                                                                                        type: 'string',
                                                                                                                    },
                                                                                                                    message: 'must be string',
                                                                                                                },
                                                                                                            ];
                                                                                                            return false;
                                                                                                        }
                                                                                                        var valid60 = _errs319 === errors;
                                                                                                    }
                                                                                                    else {
                                                                                                        var valid60 = true;
                                                                                                    }
                                                                                                    if (valid60) {
                                                                                                        if (data106.dimensions !==
                                                                                                            undefined) {
                                                                                                            var data110 = data106.dimensions;
                                                                                                            var _errs321 = errors;
                                                                                                            if (errors === _errs321) {
                                                                                                                if (data110 &&
                                                                                                                    typeof data110 ==
                                                                                                                        'object' &&
                                                                                                                    !Array.isArray(data110)) {
                                                                                                                    var missing29 = void 0;
                                                                                                                    if ((data110.width ===
                                                                                                                        undefined &&
                                                                                                                        (missing29 =
                                                                                                                            'width')) ||
                                                                                                                        (data110.height ===
                                                                                                                            undefined &&
                                                                                                                            (missing29 =
                                                                                                                                'height'))) {
                                                                                                                        validate11.errors =
                                                                                                                            [
                                                                                                                                {
                                                                                                                                    instancePath: instancePath +
                                                                                                                                        '/track/dimensions',
                                                                                                                                    schemaPath: '#/allOf/12/then/properties/track/properties/dimensions/required',
                                                                                                                                    keyword: 'required',
                                                                                                                                    params: {
                                                                                                                                        missingProperty: missing29,
                                                                                                                                    },
                                                                                                                                    message: "must have required property '" +
                                                                                                                                        missing29 +
                                                                                                                                        "'",
                                                                                                                                },
                                                                                                                            ];
                                                                                                                        return false;
                                                                                                                    }
                                                                                                                    else {
                                                                                                                        var _errs323 = errors;
                                                                                                                        for (var key29 in data110) {
                                                                                                                            if (!(key29 ===
                                                                                                                                'width' ||
                                                                                                                                key29 ===
                                                                                                                                    'height')) {
                                                                                                                                validate11.errors =
                                                                                                                                    [
                                                                                                                                        {
                                                                                                                                            instancePath: instancePath +
                                                                                                                                                '/track/dimensions',
                                                                                                                                            schemaPath: '#/allOf/12/then/properties/track/properties/dimensions/additionalProperties',
                                                                                                                                            keyword: 'additionalProperties',
                                                                                                                                            params: {
                                                                                                                                                additionalProperty: key29,
                                                                                                                                            },
                                                                                                                                            message: 'must NOT have additional properties',
                                                                                                                                        },
                                                                                                                                    ];
                                                                                                                                return false;
                                                                                                                                break;
                                                                                                                            }
                                                                                                                        }
                                                                                                                        if (_errs323 ===
                                                                                                                            errors) {
                                                                                                                            if (data110.width !==
                                                                                                                                undefined) {
                                                                                                                                var data111 = data110.width;
                                                                                                                                var _errs324 = errors;
                                                                                                                                if (!(typeof data111 ==
                                                                                                                                    'number' &&
                                                                                                                                    isFinite(data111))) {
                                                                                                                                    validate11.errors =
                                                                                                                                        [
                                                                                                                                            {
                                                                                                                                                instancePath: instancePath +
                                                                                                                                                    '/track/dimensions/width',
                                                                                                                                                schemaPath: '#/allOf/12/then/properties/track/properties/dimensions/properties/width/type',
                                                                                                                                                keyword: 'type',
                                                                                                                                                params: {
                                                                                                                                                    type: 'number',
                                                                                                                                                },
                                                                                                                                                message: 'must be number',
                                                                                                                                            },
                                                                                                                                        ];
                                                                                                                                    return false;
                                                                                                                                }
                                                                                                                                var valid62 = _errs324 ===
                                                                                                                                    errors;
                                                                                                                            }
                                                                                                                            else {
                                                                                                                                var valid62 = true;
                                                                                                                            }
                                                                                                                            if (valid62) {
                                                                                                                                if (data110.height !==
                                                                                                                                    undefined) {
                                                                                                                                    var data112 = data110.height;
                                                                                                                                    var _errs326 = errors;
                                                                                                                                    if (!(typeof data112 ==
                                                                                                                                        'number' &&
                                                                                                                                        isFinite(data112))) {
                                                                                                                                        validate11.errors =
                                                                                                                                            [
                                                                                                                                                {
                                                                                                                                                    instancePath: instancePath +
                                                                                                                                                        '/track/dimensions/height',
                                                                                                                                                    schemaPath: '#/allOf/12/then/properties/track/properties/dimensions/properties/height/type',
                                                                                                                                                    keyword: 'type',
                                                                                                                                                    params: {
                                                                                                                                                        type: 'number',
                                                                                                                                                    },
                                                                                                                                                    message: 'must be number',
                                                                                                                                                },
                                                                                                                                            ];
                                                                                                                                        return false;
                                                                                                                                    }
                                                                                                                                    var valid62 = _errs326 ===
                                                                                                                                        errors;
                                                                                                                                }
                                                                                                                                else {
                                                                                                                                    var valid62 = true;
                                                                                                                                }
                                                                                                                            }
                                                                                                                        }
                                                                                                                    }
                                                                                                                }
                                                                                                                else {
                                                                                                                    validate11.errors = [
                                                                                                                        {
                                                                                                                            instancePath: instancePath +
                                                                                                                                '/track/dimensions',
                                                                                                                            schemaPath: '#/allOf/12/then/properties/track/properties/dimensions/type',
                                                                                                                            keyword: 'type',
                                                                                                                            params: {
                                                                                                                                type: 'object',
                                                                                                                            },
                                                                                                                            message: 'must be object',
                                                                                                                        },
                                                                                                                    ];
                                                                                                                    return false;
                                                                                                                }
                                                                                                            }
                                                                                                            var valid60 = _errs321 === errors;
                                                                                                        }
                                                                                                        else {
                                                                                                            var valid60 = true;
                                                                                                        }
                                                                                                    }
                                                                                                }
                                                                                            }
                                                                                        }
                                                                                    }
                                                                                }
                                                                                else {
                                                                                    validate11.errors = [
                                                                                        {
                                                                                            instancePath: instancePath + '/track',
                                                                                            schemaPath: '#/allOf/12/then/properties/track/type',
                                                                                            keyword: 'type',
                                                                                            params: { type: 'object' },
                                                                                            message: 'must be object',
                                                                                        },
                                                                                    ];
                                                                                    return false;
                                                                                }
                                                                            }
                                                                            var valid59 = _errs311 === errors;
                                                                        }
                                                                        else {
                                                                            var valid59 = true;
                                                                        }
                                                                        if (valid59) {
                                                                            if (data.participant !== undefined) {
                                                                                var data113 = data.participant;
                                                                                var _errs328 = errors;
                                                                                if (errors === _errs328) {
                                                                                    if (data113 &&
                                                                                        typeof data113 == 'object' &&
                                                                                        !Array.isArray(data113)) {
                                                                                        var missing30 = void 0;
                                                                                        if ((data113.identity === undefined &&
                                                                                            (missing30 = 'identity')) ||
                                                                                            (data113.sid === undefined &&
                                                                                                (missing30 = 'sid'))) {
                                                                                            validate11.errors = [
                                                                                                {
                                                                                                    instancePath: instancePath +
                                                                                                        '/participant',
                                                                                                    schemaPath: '#/allOf/12/then/properties/participant/required',
                                                                                                    keyword: 'required',
                                                                                                    params: {
                                                                                                        missingProperty: missing30,
                                                                                                    },
                                                                                                    message: "must have required property '" +
                                                                                                        missing30 +
                                                                                                        "'",
                                                                                                },
                                                                                            ];
                                                                                            return false;
                                                                                        }
                                                                                        else {
                                                                                            var _errs330 = errors;
                                                                                            for (var key30 in data113) {
                                                                                                if (!(key30 === 'identity' ||
                                                                                                    key30 === 'sid' ||
                                                                                                    key30 === 'permissions')) {
                                                                                                    validate11.errors = [
                                                                                                        {
                                                                                                            instancePath: instancePath +
                                                                                                                '/participant',
                                                                                                            schemaPath: '#/allOf/12/then/properties/participant/additionalProperties',
                                                                                                            keyword: 'additionalProperties',
                                                                                                            params: {
                                                                                                                additionalProperty: key30,
                                                                                                            },
                                                                                                            message: 'must NOT have additional properties',
                                                                                                        },
                                                                                                    ];
                                                                                                    return false;
                                                                                                    break;
                                                                                                }
                                                                                            }
                                                                                            if (_errs330 === errors) {
                                                                                                if (data113.identity !== undefined) {
                                                                                                    var _errs331 = errors;
                                                                                                    if (typeof data113.identity !==
                                                                                                        'string') {
                                                                                                        validate11.errors = [
                                                                                                            {
                                                                                                                instancePath: instancePath +
                                                                                                                    '/participant/identity',
                                                                                                                schemaPath: '#/allOf/12/then/properties/participant/properties/identity/type',
                                                                                                                keyword: 'type',
                                                                                                                params: {
                                                                                                                    type: 'string',
                                                                                                                },
                                                                                                                message: 'must be string',
                                                                                                            },
                                                                                                        ];
                                                                                                        return false;
                                                                                                    }
                                                                                                    var valid63 = _errs331 === errors;
                                                                                                }
                                                                                                else {
                                                                                                    var valid63 = true;
                                                                                                }
                                                                                                if (valid63) {
                                                                                                    if (data113.sid !== undefined) {
                                                                                                        var _errs333 = errors;
                                                                                                        if (typeof data113.sid !==
                                                                                                            'string') {
                                                                                                            validate11.errors = [
                                                                                                                {
                                                                                                                    instancePath: instancePath +
                                                                                                                        '/participant/sid',
                                                                                                                    schemaPath: '#/allOf/12/then/properties/participant/properties/sid/type',
                                                                                                                    keyword: 'type',
                                                                                                                    params: {
                                                                                                                        type: 'string',
                                                                                                                    },
                                                                                                                    message: 'must be string',
                                                                                                                },
                                                                                                            ];
                                                                                                            return false;
                                                                                                        }
                                                                                                        var valid63 = _errs333 === errors;
                                                                                                    }
                                                                                                    else {
                                                                                                        var valid63 = true;
                                                                                                    }
                                                                                                    if (valid63) {
                                                                                                        if (data113.permissions !==
                                                                                                            undefined) {
                                                                                                            var data116 = data113.permissions;
                                                                                                            var _errs335 = errors;
                                                                                                            if (errors === _errs335) {
                                                                                                                if (data116 &&
                                                                                                                    typeof data116 ==
                                                                                                                        'object' &&
                                                                                                                    !Array.isArray(data116)) {
                                                                                                                    var missing31 = void 0;
                                                                                                                    if ((data116.canSubscribe ===
                                                                                                                        undefined &&
                                                                                                                        (missing31 =
                                                                                                                            'canSubscribe')) ||
                                                                                                                        (data116.canPublish ===
                                                                                                                            undefined &&
                                                                                                                            (missing31 =
                                                                                                                                'canPublish')) ||
                                                                                                                        (data116.canPublishData ===
                                                                                                                            undefined &&
                                                                                                                            (missing31 =
                                                                                                                                'canPublishData')) ||
                                                                                                                        (data116.hidden ===
                                                                                                                            undefined &&
                                                                                                                            (missing31 =
                                                                                                                                'hidden')) ||
                                                                                                                        (data116.canUpdateMetadata ===
                                                                                                                            undefined &&
                                                                                                                            (missing31 =
                                                                                                                                'canUpdateMetadata')) ||
                                                                                                                        (data116.canSubscribeMetrics ===
                                                                                                                            undefined &&
                                                                                                                            (missing31 =
                                                                                                                                'canSubscribeMetrics'))) {
                                                                                                                        validate11.errors =
                                                                                                                            [
                                                                                                                                {
                                                                                                                                    instancePath: instancePath +
                                                                                                                                        '/participant/permissions',
                                                                                                                                    schemaPath: '#/allOf/12/then/properties/participant/properties/permissions/required',
                                                                                                                                    keyword: 'required',
                                                                                                                                    params: {
                                                                                                                                        missingProperty: missing31,
                                                                                                                                    },
                                                                                                                                    message: "must have required property '" +
                                                                                                                                        missing31 +
                                                                                                                                        "'",
                                                                                                                                },
                                                                                                                            ];
                                                                                                                        return false;
                                                                                                                    }
                                                                                                                    else {
                                                                                                                        var _errs337 = errors;
                                                                                                                        for (var key31 in data116) {
                                                                                                                            if (!(key31 ===
                                                                                                                                'canSubscribe' ||
                                                                                                                                key31 ===
                                                                                                                                    'canPublish' ||
                                                                                                                                key31 ===
                                                                                                                                    'canPublishData' ||
                                                                                                                                key31 ===
                                                                                                                                    'hidden' ||
                                                                                                                                key31 ===
                                                                                                                                    'canUpdateMetadata' ||
                                                                                                                                key31 ===
                                                                                                                                    'canSubscribeMetrics')) {
                                                                                                                                validate11.errors =
                                                                                                                                    [
                                                                                                                                        {
                                                                                                                                            instancePath: instancePath +
                                                                                                                                                '/participant/permissions',
                                                                                                                                            schemaPath: '#/allOf/12/then/properties/participant/properties/permissions/additionalProperties',
                                                                                                                                            keyword: 'additionalProperties',
                                                                                                                                            params: {
                                                                                                                                                additionalProperty: key31,
                                                                                                                                            },
                                                                                                                                            message: 'must NOT have additional properties',
                                                                                                                                        },
                                                                                                                                    ];
                                                                                                                                return false;
                                                                                                                                break;
                                                                                                                            }
                                                                                                                        }
                                                                                                                        if (_errs337 ===
                                                                                                                            errors) {
                                                                                                                            if (data116.canSubscribe !==
                                                                                                                                undefined) {
                                                                                                                                var _errs338 = errors;
                                                                                                                                if (typeof data116.canSubscribe !==
                                                                                                                                    'boolean') {
                                                                                                                                    validate11.errors =
                                                                                                                                        [
                                                                                                                                            {
                                                                                                                                                instancePath: instancePath +
                                                                                                                                                    '/participant/permissions/canSubscribe',
                                                                                                                                                schemaPath: '#/allOf/12/then/properties/participant/properties/permissions/properties/canSubscribe/type',
                                                                                                                                                keyword: 'type',
                                                                                                                                                params: {
                                                                                                                                                    type: 'boolean',
                                                                                                                                                },
                                                                                                                                                message: 'must be boolean',
                                                                                                                                            },
                                                                                                                                        ];
                                                                                                                                    return false;
                                                                                                                                }
                                                                                                                                var valid64 = _errs338 ===
                                                                                                                                    errors;
                                                                                                                            }
                                                                                                                            else {
                                                                                                                                var valid64 = true;
                                                                                                                            }
                                                                                                                            if (valid64) {
                                                                                                                                if (data116.canPublish !==
                                                                                                                                    undefined) {
                                                                                                                                    var _errs340 = errors;
                                                                                                                                    if (typeof data116.canPublish !==
                                                                                                                                        'boolean') {
                                                                                                                                        validate11.errors =
                                                                                                                                            [
                                                                                                                                                {
                                                                                                                                                    instancePath: instancePath +
                                                                                                                                                        '/participant/permissions/canPublish',
                                                                                                                                                    schemaPath: '#/allOf/12/then/properties/participant/properties/permissions/properties/canPublish/type',
                                                                                                                                                    keyword: 'type',
                                                                                                                                                    params: {
                                                                                                                                                        type: 'boolean',
                                                                                                                                                    },
                                                                                                                                                    message: 'must be boolean',
                                                                                                                                                },
                                                                                                                                            ];
                                                                                                                                        return false;
                                                                                                                                    }
                                                                                                                                    var valid64 = _errs340 ===
                                                                                                                                        errors;
                                                                                                                                }
                                                                                                                                else {
                                                                                                                                    var valid64 = true;
                                                                                                                                }
                                                                                                                                if (valid64) {
                                                                                                                                    if (data116.canPublishData !==
                                                                                                                                        undefined) {
                                                                                                                                        var _errs342 = errors;
                                                                                                                                        if (typeof data116.canPublishData !==
                                                                                                                                            'boolean') {
                                                                                                                                            validate11.errors =
                                                                                                                                                [
                                                                                                                                                    {
                                                                                                                                                        instancePath: instancePath +
                                                                                                                                                            '/participant/permissions/canPublishData',
                                                                                                                                                        schemaPath: '#/allOf/12/then/properties/participant/properties/permissions/properties/canPublishData/type',
                                                                                                                                                        keyword: 'type',
                                                                                                                                                        params: {
                                                                                                                                                            type: 'boolean',
                                                                                                                                                        },
                                                                                                                                                        message: 'must be boolean',
                                                                                                                                                    },
                                                                                                                                                ];
                                                                                                                                            return false;
                                                                                                                                        }
                                                                                                                                        var valid64 = _errs342 ===
                                                                                                                                            errors;
                                                                                                                                    }
                                                                                                                                    else {
                                                                                                                                        var valid64 = true;
                                                                                                                                    }
                                                                                                                                    if (valid64) {
                                                                                                                                        if (data116.hidden !==
                                                                                                                                            undefined) {
                                                                                                                                            var _errs344 = errors;
                                                                                                                                            if (typeof data116.hidden !==
                                                                                                                                                'boolean') {
                                                                                                                                                validate11.errors =
                                                                                                                                                    [
                                                                                                                                                        {
                                                                                                                                                            instancePath: instancePath +
                                                                                                                                                                '/participant/permissions/hidden',
                                                                                                                                                            schemaPath: '#/allOf/12/then/properties/participant/properties/permissions/properties/hidden/type',
                                                                                                                                                            keyword: 'type',
                                                                                                                                                            params: {
                                                                                                                                                                type: 'boolean',
                                                                                                                                                            },
                                                                                                                                                            message: 'must be boolean',
                                                                                                                                                        },
                                                                                                                                                    ];
                                                                                                                                                return false;
                                                                                                                                            }
                                                                                                                                            var valid64 = _errs344 ===
                                                                                                                                                errors;
                                                                                                                                        }
                                                                                                                                        else {
                                                                                                                                            var valid64 = true;
                                                                                                                                        }
                                                                                                                                        if (valid64) {
                                                                                                                                            if (data116.canUpdateMetadata !==
                                                                                                                                                undefined) {
                                                                                                                                                var _errs346 = errors;
                                                                                                                                                if (typeof data116.canUpdateMetadata !==
                                                                                                                                                    'boolean') {
                                                                                                                                                    validate11.errors =
                                                                                                                                                        [
                                                                                                                                                            {
                                                                                                                                                                instancePath: instancePath +
                                                                                                                                                                    '/participant/permissions/canUpdateMetadata',
                                                                                                                                                                schemaPath: '#/allOf/12/then/properties/participant/properties/permissions/properties/canUpdateMetadata/type',
                                                                                                                                                                keyword: 'type',
                                                                                                                                                                params: {
                                                                                                                                                                    type: 'boolean',
                                                                                                                                                                },
                                                                                                                                                                message: 'must be boolean',
                                                                                                                                                            },
                                                                                                                                                        ];
                                                                                                                                                    return false;
                                                                                                                                                }
                                                                                                                                                var valid64 = _errs346 ===
                                                                                                                                                    errors;
                                                                                                                                            }
                                                                                                                                            else {
                                                                                                                                                var valid64 = true;
                                                                                                                                            }
                                                                                                                                            if (valid64) {
                                                                                                                                                if (data116.canSubscribeMetrics !==
                                                                                                                                                    undefined) {
                                                                                                                                                    var _errs348 = errors;
                                                                                                                                                    if (typeof data116.canSubscribeMetrics !==
                                                                                                                                                        'boolean') {
                                                                                                                                                        validate11.errors =
                                                                                                                                                            [
                                                                                                                                                                {
                                                                                                                                                                    instancePath: instancePath +
                                                                                                                                                                        '/participant/permissions/canSubscribeMetrics',
                                                                                                                                                                    schemaPath: '#/allOf/12/then/properties/participant/properties/permissions/properties/canSubscribeMetrics/type',
                                                                                                                                                                    keyword: 'type',
                                                                                                                                                                    params: {
                                                                                                                                                                        type: 'boolean',
                                                                                                                                                                    },
                                                                                                                                                                    message: 'must be boolean',
                                                                                                                                                                },
                                                                                                                                                            ];
                                                                                                                                                        return false;
                                                                                                                                                    }
                                                                                                                                                    var valid64 = _errs348 ===
                                                                                                                                                        errors;
                                                                                                                                                }
                                                                                                                                                else {
                                                                                                                                                    var valid64 = true;
                                                                                                                                                }
                                                                                                                                            }
                                                                                                                                        }
                                                                                                                                    }
                                                                                                                                }
                                                                                                                            }
                                                                                                                        }
                                                                                                                    }
                                                                                                                }
                                                                                                                else {
                                                                                                                    validate11.errors = [
                                                                                                                        {
                                                                                                                            instancePath: instancePath +
                                                                                                                                '/participant/permissions',
                                                                                                                            schemaPath: '#/allOf/12/then/properties/participant/properties/permissions/type',
                                                                                                                            keyword: 'type',
                                                                                                                            params: {
                                                                                                                                type: 'object',
                                                                                                                            },
                                                                                                                            message: 'must be object',
                                                                                                                        },
                                                                                                                    ];
                                                                                                                    return false;
                                                                                                                }
                                                                                                            }
                                                                                                            var valid63 = _errs335 === errors;
                                                                                                        }
                                                                                                        else {
                                                                                                            var valid63 = true;
                                                                                                        }
                                                                                                    }
                                                                                                }
                                                                                            }
                                                                                        }
                                                                                    }
                                                                                    else {
                                                                                        validate11.errors = [
                                                                                            {
                                                                                                instancePath: instancePath + '/participant',
                                                                                                schemaPath: '#/allOf/12/then/properties/participant/type',
                                                                                                keyword: 'type',
                                                                                                params: { type: 'object' },
                                                                                                message: 'must be object',
                                                                                            },
                                                                                        ];
                                                                                        return false;
                                                                                    }
                                                                                }
                                                                                var valid59 = _errs328 === errors;
                                                                            }
                                                                            else {
                                                                                var valid59 = true;
                                                                            }
                                                                            if (valid59) {
                                                                                if (data.name !== undefined) {
                                                                                    var data123 = data.name;
                                                                                    var _errs350 = errors;
                                                                                    if (typeof data123 !== 'string') {
                                                                                        validate11.errors = [
                                                                                            {
                                                                                                instancePath: instancePath + '/name',
                                                                                                schemaPath: '#/allOf/12/then/properties/name/type',
                                                                                                keyword: 'type',
                                                                                                params: { type: 'string' },
                                                                                                message: 'must be string',
                                                                                            },
                                                                                        ];
                                                                                        return false;
                                                                                    }
                                                                                    if ('trackUnpublished' !== data123) {
                                                                                        validate11.errors = [
                                                                                            {
                                                                                                instancePath: instancePath + '/name',
                                                                                                schemaPath: '#/allOf/12/then/properties/name/const',
                                                                                                keyword: 'const',
                                                                                                params: {
                                                                                                    allowedValue: 'trackUnpublished',
                                                                                                },
                                                                                                message: 'must be equal to constant',
                                                                                            },
                                                                                        ];
                                                                                        return false;
                                                                                    }
                                                                                    var valid59 = _errs350 === errors;
                                                                                }
                                                                                else {
                                                                                    var valid59 = true;
                                                                                }
                                                                                if (valid59) {
                                                                                    if (data.timestamp !== undefined) {
                                                                                        var data124 = data.timestamp;
                                                                                        var _errs352 = errors;
                                                                                        if (!(typeof data124 == 'number' &&
                                                                                            isFinite(data124))) {
                                                                                            validate11.errors = [
                                                                                                {
                                                                                                    instancePath: instancePath + '/timestamp',
                                                                                                    schemaPath: '#/allOf/12/then/properties/timestamp/type',
                                                                                                    keyword: 'type',
                                                                                                    params: { type: 'number' },
                                                                                                    message: 'must be number',
                                                                                                },
                                                                                            ];
                                                                                            return false;
                                                                                        }
                                                                                        var valid59 = _errs352 === errors;
                                                                                    }
                                                                                    else {
                                                                                        var valid59 = true;
                                                                                    }
                                                                                }
                                                                            }
                                                                        }
                                                                    }
                                                                }
                                                            }
                                                            else {
                                                                validate11.errors = [
                                                                    {
                                                                        instancePath: instancePath,
                                                                        schemaPath: '#/allOf/12/then/type',
                                                                        keyword: 'type',
                                                                        params: { type: 'object' },
                                                                        message: 'must be object',
                                                                    },
                                                                ];
                                                                return false;
                                                            }
                                                        }
                                                        var _valid12 = _errs308 === errors;
                                                        valid57 = _valid12;
                                                    }
                                                    if (!valid57) {
                                                        var err38 = {
                                                            instancePath: instancePath,
                                                            schemaPath: '#/allOf/12/if',
                                                            keyword: 'if',
                                                            params: { failingKeyword: 'then' },
                                                            message: 'must match "then" schema',
                                                        };
                                                        if (vErrors === null) {
                                                            vErrors = [err38];
                                                        }
                                                        else {
                                                            vErrors.push(err38);
                                                        }
                                                        errors++;
                                                        validate11.errors = vErrors;
                                                        return false;
                                                    }
                                                    var valid0 = _errs303 === errors;
                                                    if (valid0) {
                                                        var _errs354 = errors;
                                                        var _errs355 = errors;
                                                        var valid65 = true;
                                                        var _errs356 = errors;
                                                        if (data &&
                                                            typeof data == 'object' &&
                                                            !Array.isArray(data)) {
                                                            if (data.name !== undefined) {
                                                                var data125 = data.name;
                                                                if (typeof data125 !== 'string') {
                                                                    var err39 = {};
                                                                    if (vErrors === null) {
                                                                        vErrors = [err39];
                                                                    }
                                                                    else {
                                                                        vErrors.push(err39);
                                                                    }
                                                                    errors++;
                                                                }
                                                                if ('trackUnsubscribed' !== data125) {
                                                                    var err40 = {};
                                                                    if (vErrors === null) {
                                                                        vErrors = [err40];
                                                                    }
                                                                    else {
                                                                        vErrors.push(err40);
                                                                    }
                                                                    errors++;
                                                                }
                                                            }
                                                        }
                                                        var _valid13 = _errs356 === errors;
                                                        errors = _errs355;
                                                        if (vErrors !== null) {
                                                            if (_errs355) {
                                                                vErrors.length = _errs355;
                                                            }
                                                            else {
                                                                vErrors = null;
                                                            }
                                                        }
                                                        if (_valid13) {
                                                            var _errs359 = errors;
                                                            if (errors === _errs359) {
                                                                if (data &&
                                                                    typeof data == 'object' &&
                                                                    !Array.isArray(data)) {
                                                                    var missing32 = void 0;
                                                                    if ((data.name === undefined &&
                                                                        (missing32 = 'name')) ||
                                                                        (data.participant === undefined &&
                                                                            (missing32 = 'participant')) ||
                                                                        (data.timestamp === undefined &&
                                                                            (missing32 = 'timestamp')) ||
                                                                        (data.track === undefined &&
                                                                            (missing32 = 'track'))) {
                                                                        validate11.errors = [
                                                                            {
                                                                                instancePath: instancePath,
                                                                                schemaPath: '#/allOf/13/then/required',
                                                                                keyword: 'required',
                                                                                params: { missingProperty: missing32 },
                                                                                message: "must have required property '" +
                                                                                    missing32 +
                                                                                    "'",
                                                                            },
                                                                        ];
                                                                        return false;
                                                                    }
                                                                    else {
                                                                        var _errs361 = errors;
                                                                        for (var key32 in data) {
                                                                            if (!(key32 === 'track' ||
                                                                                key32 === 'participant' ||
                                                                                key32 === 'name' ||
                                                                                key32 === 'timestamp')) {
                                                                                validate11.errors = [
                                                                                    {
                                                                                        instancePath: instancePath,
                                                                                        schemaPath: '#/allOf/13/then/additionalProperties',
                                                                                        keyword: 'additionalProperties',
                                                                                        params: {
                                                                                            additionalProperty: key32,
                                                                                        },
                                                                                        message: 'must NOT have additional properties',
                                                                                    },
                                                                                ];
                                                                                return false;
                                                                                break;
                                                                            }
                                                                        }
                                                                        if (_errs361 === errors) {
                                                                            if (data.track !== undefined) {
                                                                                var data126 = data.track;
                                                                                var _errs362 = errors;
                                                                                if (errors === _errs362) {
                                                                                    if (data126 &&
                                                                                        typeof data126 == 'object' &&
                                                                                        !Array.isArray(data126)) {
                                                                                        var missing33 = void 0;
                                                                                        if ((data126.sid === undefined &&
                                                                                            (missing33 = 'sid')) ||
                                                                                            (data126.source === undefined &&
                                                                                                (missing33 = 'source'))) {
                                                                                            validate11.errors = [
                                                                                                {
                                                                                                    instancePath: instancePath + '/track',
                                                                                                    schemaPath: '#/allOf/13/then/properties/track/required',
                                                                                                    keyword: 'required',
                                                                                                    params: {
                                                                                                        missingProperty: missing33,
                                                                                                    },
                                                                                                    message: "must have required property '" +
                                                                                                        missing33 +
                                                                                                        "'",
                                                                                                },
                                                                                            ];
                                                                                            return false;
                                                                                        }
                                                                                        else {
                                                                                            var _errs364 = errors;
                                                                                            for (var key33 in data126) {
                                                                                                if (!(key33 === 'sid' ||
                                                                                                    key33 === 'source' ||
                                                                                                    key33 === 'mimeType' ||
                                                                                                    key33 === 'dimensions')) {
                                                                                                    validate11.errors = [
                                                                                                        {
                                                                                                            instancePath: instancePath + '/track',
                                                                                                            schemaPath: '#/allOf/13/then/properties/track/additionalProperties',
                                                                                                            keyword: 'additionalProperties',
                                                                                                            params: {
                                                                                                                additionalProperty: key33,
                                                                                                            },
                                                                                                            message: 'must NOT have additional properties',
                                                                                                        },
                                                                                                    ];
                                                                                                    return false;
                                                                                                    break;
                                                                                                }
                                                                                            }
                                                                                            if (_errs364 === errors) {
                                                                                                if (data126.sid !== undefined) {
                                                                                                    var _errs365 = errors;
                                                                                                    if (typeof data126.sid !==
                                                                                                        'string') {
                                                                                                        validate11.errors = [
                                                                                                            {
                                                                                                                instancePath: instancePath +
                                                                                                                    '/track/sid',
                                                                                                                schemaPath: '#/allOf/13/then/properties/track/properties/sid/type',
                                                                                                                keyword: 'type',
                                                                                                                params: {
                                                                                                                    type: 'string',
                                                                                                                },
                                                                                                                message: 'must be string',
                                                                                                            },
                                                                                                        ];
                                                                                                        return false;
                                                                                                    }
                                                                                                    var valid68 = _errs365 === errors;
                                                                                                }
                                                                                                else {
                                                                                                    var valid68 = true;
                                                                                                }
                                                                                                if (valid68) {
                                                                                                    if (data126.source !== undefined) {
                                                                                                        var data128 = data126.source;
                                                                                                        var _errs367 = errors;
                                                                                                        if (typeof data128 !==
                                                                                                            'string') {
                                                                                                            validate11.errors = [
                                                                                                                {
                                                                                                                    instancePath: instancePath +
                                                                                                                        '/track/source',
                                                                                                                    schemaPath: '#/definitions/Track.Source/type',
                                                                                                                    keyword: 'type',
                                                                                                                    params: {
                                                                                                                        type: 'string',
                                                                                                                    },
                                                                                                                    message: 'must be string',
                                                                                                                },
                                                                                                            ];
                                                                                                            return false;
                                                                                                        }
                                                                                                        if (!(data128 === 'camera' ||
                                                                                                            data128 ===
                                                                                                                'microphone' ||
                                                                                                            data128 ===
                                                                                                                'screen_share' ||
                                                                                                            data128 ===
                                                                                                                'screen_share_audio' ||
                                                                                                            data128 === 'unknown')) {
                                                                                                            validate11.errors = [
                                                                                                                {
                                                                                                                    instancePath: instancePath +
                                                                                                                        '/track/source',
                                                                                                                    schemaPath: '#/definitions/Track.Source/enum',
                                                                                                                    keyword: 'enum',
                                                                                                                    params: {
                                                                                                                        allowedValues: schema31.enum,
                                                                                                                    },
                                                                                                                    message: 'must be equal to one of the allowed values',
                                                                                                                },
                                                                                                            ];
                                                                                                            return false;
                                                                                                        }
                                                                                                        var valid68 = _errs367 === errors;
                                                                                                    }
                                                                                                    else {
                                                                                                        var valid68 = true;
                                                                                                    }
                                                                                                    if (valid68) {
                                                                                                        if (data126.mimeType !==
                                                                                                            undefined) {
                                                                                                            var _errs370 = errors;
                                                                                                            if (typeof data126.mimeType !==
                                                                                                                'string') {
                                                                                                                validate11.errors = [
                                                                                                                    {
                                                                                                                        instancePath: instancePath +
                                                                                                                            '/track/mimeType',
                                                                                                                        schemaPath: '#/allOf/13/then/properties/track/properties/mimeType/type',
                                                                                                                        keyword: 'type',
                                                                                                                        params: {
                                                                                                                            type: 'string',
                                                                                                                        },
                                                                                                                        message: 'must be string',
                                                                                                                    },
                                                                                                                ];
                                                                                                                return false;
                                                                                                            }
                                                                                                            var valid68 = _errs370 === errors;
                                                                                                        }
                                                                                                        else {
                                                                                                            var valid68 = true;
                                                                                                        }
                                                                                                        if (valid68) {
                                                                                                            if (data126.dimensions !==
                                                                                                                undefined) {
                                                                                                                var data130 = data126.dimensions;
                                                                                                                var _errs372 = errors;
                                                                                                                if (errors === _errs372) {
                                                                                                                    if (data130 &&
                                                                                                                        typeof data130 ==
                                                                                                                            'object' &&
                                                                                                                        !Array.isArray(data130)) {
                                                                                                                        var missing34 = void 0;
                                                                                                                        if ((data130.width ===
                                                                                                                            undefined &&
                                                                                                                            (missing34 =
                                                                                                                                'width')) ||
                                                                                                                            (data130.height ===
                                                                                                                                undefined &&
                                                                                                                                (missing34 =
                                                                                                                                    'height'))) {
                                                                                                                            validate11.errors =
                                                                                                                                [
                                                                                                                                    {
                                                                                                                                        instancePath: instancePath +
                                                                                                                                            '/track/dimensions',
                                                                                                                                        schemaPath: '#/allOf/13/then/properties/track/properties/dimensions/required',
                                                                                                                                        keyword: 'required',
                                                                                                                                        params: {
                                                                                                                                            missingProperty: missing34,
                                                                                                                                        },
                                                                                                                                        message: "must have required property '" +
                                                                                                                                            missing34 +
                                                                                                                                            "'",
                                                                                                                                    },
                                                                                                                                ];
                                                                                                                            return false;
                                                                                                                        }
                                                                                                                        else {
                                                                                                                            var _errs374 = errors;
                                                                                                                            for (var key34 in data130) {
                                                                                                                                if (!(key34 ===
                                                                                                                                    'width' ||
                                                                                                                                    key34 ===
                                                                                                                                        'height')) {
                                                                                                                                    validate11.errors =
                                                                                                                                        [
                                                                                                                                            {
                                                                                                                                                instancePath: instancePath +
                                                                                                                                                    '/track/dimensions',
                                                                                                                                                schemaPath: '#/allOf/13/then/properties/track/properties/dimensions/additionalProperties',
                                                                                                                                                keyword: 'additionalProperties',
                                                                                                                                                params: {
                                                                                                                                                    additionalProperty: key34,
                                                                                                                                                },
                                                                                                                                                message: 'must NOT have additional properties',
                                                                                                                                            },
                                                                                                                                        ];
                                                                                                                                    return false;
                                                                                                                                    break;
                                                                                                                                }
                                                                                                                            }
                                                                                                                            if (_errs374 ===
                                                                                                                                errors) {
                                                                                                                                if (data130.width !==
                                                                                                                                    undefined) {
                                                                                                                                    var data131 = data130.width;
                                                                                                                                    var _errs375 = errors;
                                                                                                                                    if (!(typeof data131 ==
                                                                                                                                        'number' &&
                                                                                                                                        isFinite(data131))) {
                                                                                                                                        validate11.errors =
                                                                                                                                            [
                                                                                                                                                {
                                                                                                                                                    instancePath: instancePath +
                                                                                                                                                        '/track/dimensions/width',
                                                                                                                                                    schemaPath: '#/allOf/13/then/properties/track/properties/dimensions/properties/width/type',
                                                                                                                                                    keyword: 'type',
                                                                                                                                                    params: {
                                                                                                                                                        type: 'number',
                                                                                                                                                    },
                                                                                                                                                    message: 'must be number',
                                                                                                                                                },
                                                                                                                                            ];
                                                                                                                                        return false;
                                                                                                                                    }
                                                                                                                                    var valid70 = _errs375 ===
                                                                                                                                        errors;
                                                                                                                                }
                                                                                                                                else {
                                                                                                                                    var valid70 = true;
                                                                                                                                }
                                                                                                                                if (valid70) {
                                                                                                                                    if (data130.height !==
                                                                                                                                        undefined) {
                                                                                                                                        var data132 = data130.height;
                                                                                                                                        var _errs377 = errors;
                                                                                                                                        if (!(typeof data132 ==
                                                                                                                                            'number' &&
                                                                                                                                            isFinite(data132))) {
                                                                                                                                            validate11.errors =
                                                                                                                                                [
                                                                                                                                                    {
                                                                                                                                                        instancePath: instancePath +
                                                                                                                                                            '/track/dimensions/height',
                                                                                                                                                        schemaPath: '#/allOf/13/then/properties/track/properties/dimensions/properties/height/type',
                                                                                                                                                        keyword: 'type',
                                                                                                                                                        params: {
                                                                                                                                                            type: 'number',
                                                                                                                                                        },
                                                                                                                                                        message: 'must be number',
                                                                                                                                                    },
                                                                                                                                                ];
                                                                                                                                            return false;
                                                                                                                                        }
                                                                                                                                        var valid70 = _errs377 ===
                                                                                                                                            errors;
                                                                                                                                    }
                                                                                                                                    else {
                                                                                                                                        var valid70 = true;
                                                                                                                                    }
                                                                                                                                }
                                                                                                                            }
                                                                                                                        }
                                                                                                                    }
                                                                                                                    else {
                                                                                                                        validate11.errors =
                                                                                                                            [
                                                                                                                                {
                                                                                                                                    instancePath: instancePath +
                                                                                                                                        '/track/dimensions',
                                                                                                                                    schemaPath: '#/allOf/13/then/properties/track/properties/dimensions/type',
                                                                                                                                    keyword: 'type',
                                                                                                                                    params: {
                                                                                                                                        type: 'object',
                                                                                                                                    },
                                                                                                                                    message: 'must be object',
                                                                                                                                },
                                                                                                                            ];
                                                                                                                        return false;
                                                                                                                    }
                                                                                                                }
                                                                                                                var valid68 = _errs372 === errors;
                                                                                                            }
                                                                                                            else {
                                                                                                                var valid68 = true;
                                                                                                            }
                                                                                                        }
                                                                                                    }
                                                                                                }
                                                                                            }
                                                                                        }
                                                                                    }
                                                                                    else {
                                                                                        validate11.errors = [
                                                                                            {
                                                                                                instancePath: instancePath + '/track',
                                                                                                schemaPath: '#/allOf/13/then/properties/track/type',
                                                                                                keyword: 'type',
                                                                                                params: { type: 'object' },
                                                                                                message: 'must be object',
                                                                                            },
                                                                                        ];
                                                                                        return false;
                                                                                    }
                                                                                }
                                                                                var valid67 = _errs362 === errors;
                                                                            }
                                                                            else {
                                                                                var valid67 = true;
                                                                            }
                                                                            if (valid67) {
                                                                                if (data.participant !== undefined) {
                                                                                    var data133 = data.participant;
                                                                                    var _errs379 = errors;
                                                                                    if (errors === _errs379) {
                                                                                        if (data133 &&
                                                                                            typeof data133 == 'object' &&
                                                                                            !Array.isArray(data133)) {
                                                                                            var missing35 = void 0;
                                                                                            if ((data133.identity ===
                                                                                                undefined &&
                                                                                                (missing35 = 'identity')) ||
                                                                                                (data133.sid === undefined &&
                                                                                                    (missing35 = 'sid'))) {
                                                                                                validate11.errors = [
                                                                                                    {
                                                                                                        instancePath: instancePath +
                                                                                                            '/participant',
                                                                                                        schemaPath: '#/allOf/13/then/properties/participant/required',
                                                                                                        keyword: 'required',
                                                                                                        params: {
                                                                                                            missingProperty: missing35,
                                                                                                        },
                                                                                                        message: "must have required property '" +
                                                                                                            missing35 +
                                                                                                            "'",
                                                                                                    },
                                                                                                ];
                                                                                                return false;
                                                                                            }
                                                                                            else {
                                                                                                var _errs381 = errors;
                                                                                                for (var key35 in data133) {
                                                                                                    if (!(key35 === 'identity' ||
                                                                                                        key35 === 'sid' ||
                                                                                                        key35 === 'permissions')) {
                                                                                                        validate11.errors = [
                                                                                                            {
                                                                                                                instancePath: instancePath +
                                                                                                                    '/participant',
                                                                                                                schemaPath: '#/allOf/13/then/properties/participant/additionalProperties',
                                                                                                                keyword: 'additionalProperties',
                                                                                                                params: {
                                                                                                                    additionalProperty: key35,
                                                                                                                },
                                                                                                                message: 'must NOT have additional properties',
                                                                                                            },
                                                                                                        ];
                                                                                                        return false;
                                                                                                        break;
                                                                                                    }
                                                                                                }
                                                                                                if (_errs381 === errors) {
                                                                                                    if (data133.identity !==
                                                                                                        undefined) {
                                                                                                        var _errs382 = errors;
                                                                                                        if (typeof data133.identity !==
                                                                                                            'string') {
                                                                                                            validate11.errors = [
                                                                                                                {
                                                                                                                    instancePath: instancePath +
                                                                                                                        '/participant/identity',
                                                                                                                    schemaPath: '#/allOf/13/then/properties/participant/properties/identity/type',
                                                                                                                    keyword: 'type',
                                                                                                                    params: {
                                                                                                                        type: 'string',
                                                                                                                    },
                                                                                                                    message: 'must be string',
                                                                                                                },
                                                                                                            ];
                                                                                                            return false;
                                                                                                        }
                                                                                                        var valid71 = _errs382 === errors;
                                                                                                    }
                                                                                                    else {
                                                                                                        var valid71 = true;
                                                                                                    }
                                                                                                    if (valid71) {
                                                                                                        if (data133.sid !== undefined) {
                                                                                                            var _errs384 = errors;
                                                                                                            if (typeof data133.sid !==
                                                                                                                'string') {
                                                                                                                validate11.errors = [
                                                                                                                    {
                                                                                                                        instancePath: instancePath +
                                                                                                                            '/participant/sid',
                                                                                                                        schemaPath: '#/allOf/13/then/properties/participant/properties/sid/type',
                                                                                                                        keyword: 'type',
                                                                                                                        params: {
                                                                                                                            type: 'string',
                                                                                                                        },
                                                                                                                        message: 'must be string',
                                                                                                                    },
                                                                                                                ];
                                                                                                                return false;
                                                                                                            }
                                                                                                            var valid71 = _errs384 === errors;
                                                                                                        }
                                                                                                        else {
                                                                                                            var valid71 = true;
                                                                                                        }
                                                                                                        if (valid71) {
                                                                                                            if (data133.permissions !==
                                                                                                                undefined) {
                                                                                                                var data136 = data133.permissions;
                                                                                                                var _errs386 = errors;
                                                                                                                if (errors === _errs386) {
                                                                                                                    if (data136 &&
                                                                                                                        typeof data136 ==
                                                                                                                            'object' &&
                                                                                                                        !Array.isArray(data136)) {
                                                                                                                        var missing36 = void 0;
                                                                                                                        if ((data136.canSubscribe ===
                                                                                                                            undefined &&
                                                                                                                            (missing36 =
                                                                                                                                'canSubscribe')) ||
                                                                                                                            (data136.canPublish ===
                                                                                                                                undefined &&
                                                                                                                                (missing36 =
                                                                                                                                    'canPublish')) ||
                                                                                                                            (data136.canPublishData ===
                                                                                                                                undefined &&
                                                                                                                                (missing36 =
                                                                                                                                    'canPublishData')) ||
                                                                                                                            (data136.hidden ===
                                                                                                                                undefined &&
                                                                                                                                (missing36 =
                                                                                                                                    'hidden')) ||
                                                                                                                            (data136.canUpdateMetadata ===
                                                                                                                                undefined &&
                                                                                                                                (missing36 =
                                                                                                                                    'canUpdateMetadata')) ||
                                                                                                                            (data136.canSubscribeMetrics ===
                                                                                                                                undefined &&
                                                                                                                                (missing36 =
                                                                                                                                    'canSubscribeMetrics'))) {
                                                                                                                            validate11.errors =
                                                                                                                                [
                                                                                                                                    {
                                                                                                                                        instancePath: instancePath +
                                                                                                                                            '/participant/permissions',
                                                                                                                                        schemaPath: '#/allOf/13/then/properties/participant/properties/permissions/required',
                                                                                                                                        keyword: 'required',
                                                                                                                                        params: {
                                                                                                                                            missingProperty: missing36,
                                                                                                                                        },
                                                                                                                                        message: "must have required property '" +
                                                                                                                                            missing36 +
                                                                                                                                            "'",
                                                                                                                                    },
                                                                                                                                ];
                                                                                                                            return false;
                                                                                                                        }
                                                                                                                        else {
                                                                                                                            var _errs388 = errors;
                                                                                                                            for (var key36 in data136) {
                                                                                                                                if (!(key36 ===
                                                                                                                                    'canSubscribe' ||
                                                                                                                                    key36 ===
                                                                                                                                        'canPublish' ||
                                                                                                                                    key36 ===
                                                                                                                                        'canPublishData' ||
                                                                                                                                    key36 ===
                                                                                                                                        'hidden' ||
                                                                                                                                    key36 ===
                                                                                                                                        'canUpdateMetadata' ||
                                                                                                                                    key36 ===
                                                                                                                                        'canSubscribeMetrics')) {
                                                                                                                                    validate11.errors =
                                                                                                                                        [
                                                                                                                                            {
                                                                                                                                                instancePath: instancePath +
                                                                                                                                                    '/participant/permissions',
                                                                                                                                                schemaPath: '#/allOf/13/then/properties/participant/properties/permissions/additionalProperties',
                                                                                                                                                keyword: 'additionalProperties',
                                                                                                                                                params: {
                                                                                                                                                    additionalProperty: key36,
                                                                                                                                                },
                                                                                                                                                message: 'must NOT have additional properties',
                                                                                                                                            },
                                                                                                                                        ];
                                                                                                                                    return false;
                                                                                                                                    break;
                                                                                                                                }
                                                                                                                            }
                                                                                                                            if (_errs388 ===
                                                                                                                                errors) {
                                                                                                                                if (data136.canSubscribe !==
                                                                                                                                    undefined) {
                                                                                                                                    var _errs389 = errors;
                                                                                                                                    if (typeof data136.canSubscribe !==
                                                                                                                                        'boolean') {
                                                                                                                                        validate11.errors =
                                                                                                                                            [
                                                                                                                                                {
                                                                                                                                                    instancePath: instancePath +
                                                                                                                                                        '/participant/permissions/canSubscribe',
                                                                                                                                                    schemaPath: '#/allOf/13/then/properties/participant/properties/permissions/properties/canSubscribe/type',
                                                                                                                                                    keyword: 'type',
                                                                                                                                                    params: {
                                                                                                                                                        type: 'boolean',
                                                                                                                                                    },
                                                                                                                                                    message: 'must be boolean',
                                                                                                                                                },
                                                                                                                                            ];
                                                                                                                                        return false;
                                                                                                                                    }
                                                                                                                                    var valid72 = _errs389 ===
                                                                                                                                        errors;
                                                                                                                                }
                                                                                                                                else {
                                                                                                                                    var valid72 = true;
                                                                                                                                }
                                                                                                                                if (valid72) {
                                                                                                                                    if (data136.canPublish !==
                                                                                                                                        undefined) {
                                                                                                                                        var _errs391 = errors;
                                                                                                                                        if (typeof data136.canPublish !==
                                                                                                                                            'boolean') {
                                                                                                                                            validate11.errors =
                                                                                                                                                [
                                                                                                                                                    {
                                                                                                                                                        instancePath: instancePath +
                                                                                                                                                            '/participant/permissions/canPublish',
                                                                                                                                                        schemaPath: '#/allOf/13/then/properties/participant/properties/permissions/properties/canPublish/type',
                                                                                                                                                        keyword: 'type',
                                                                                                                                                        params: {
                                                                                                                                                            type: 'boolean',
                                                                                                                                                        },
                                                                                                                                                        message: 'must be boolean',
                                                                                                                                                    },
                                                                                                                                                ];
                                                                                                                                            return false;
                                                                                                                                        }
                                                                                                                                        var valid72 = _errs391 ===
                                                                                                                                            errors;
                                                                                                                                    }
                                                                                                                                    else {
                                                                                                                                        var valid72 = true;
                                                                                                                                    }
                                                                                                                                    if (valid72) {
                                                                                                                                        if (data136.canPublishData !==
                                                                                                                                            undefined) {
                                                                                                                                            var _errs393 = errors;
                                                                                                                                            if (typeof data136.canPublishData !==
                                                                                                                                                'boolean') {
                                                                                                                                                validate11.errors =
                                                                                                                                                    [
                                                                                                                                                        {
                                                                                                                                                            instancePath: instancePath +
                                                                                                                                                                '/participant/permissions/canPublishData',
                                                                                                                                                            schemaPath: '#/allOf/13/then/properties/participant/properties/permissions/properties/canPublishData/type',
                                                                                                                                                            keyword: 'type',
                                                                                                                                                            params: {
                                                                                                                                                                type: 'boolean',
                                                                                                                                                            },
                                                                                                                                                            message: 'must be boolean',
                                                                                                                                                        },
                                                                                                                                                    ];
                                                                                                                                                return false;
                                                                                                                                            }
                                                                                                                                            var valid72 = _errs393 ===
                                                                                                                                                errors;
                                                                                                                                        }
                                                                                                                                        else {
                                                                                                                                            var valid72 = true;
                                                                                                                                        }
                                                                                                                                        if (valid72) {
                                                                                                                                            if (data136.hidden !==
                                                                                                                                                undefined) {
                                                                                                                                                var _errs395 = errors;
                                                                                                                                                if (typeof data136.hidden !==
                                                                                                                                                    'boolean') {
                                                                                                                                                    validate11.errors =
                                                                                                                                                        [
                                                                                                                                                            {
                                                                                                                                                                instancePath: instancePath +
                                                                                                                                                                    '/participant/permissions/hidden',
                                                                                                                                                                schemaPath: '#/allOf/13/then/properties/participant/properties/permissions/properties/hidden/type',
                                                                                                                                                                keyword: 'type',
                                                                                                                                                                params: {
                                                                                                                                                                    type: 'boolean',
                                                                                                                                                                },
                                                                                                                                                                message: 'must be boolean',
                                                                                                                                                            },
                                                                                                                                                        ];
                                                                                                                                                    return false;
                                                                                                                                                }
                                                                                                                                                var valid72 = _errs395 ===
                                                                                                                                                    errors;
                                                                                                                                            }
                                                                                                                                            else {
                                                                                                                                                var valid72 = true;
                                                                                                                                            }
                                                                                                                                            if (valid72) {
                                                                                                                                                if (data136.canUpdateMetadata !==
                                                                                                                                                    undefined) {
                                                                                                                                                    var _errs397 = errors;
                                                                                                                                                    if (typeof data136.canUpdateMetadata !==
                                                                                                                                                        'boolean') {
                                                                                                                                                        validate11.errors =
                                                                                                                                                            [
                                                                                                                                                                {
                                                                                                                                                                    instancePath: instancePath +
                                                                                                                                                                        '/participant/permissions/canUpdateMetadata',
                                                                                                                                                                    schemaPath: '#/allOf/13/then/properties/participant/properties/permissions/properties/canUpdateMetadata/type',
                                                                                                                                                                    keyword: 'type',
                                                                                                                                                                    params: {
                                                                                                                                                                        type: 'boolean',
                                                                                                                                                                    },
                                                                                                                                                                    message: 'must be boolean',
                                                                                                                                                                },
                                                                                                                                                            ];
                                                                                                                                                        return false;
                                                                                                                                                    }
                                                                                                                                                    var valid72 = _errs397 ===
                                                                                                                                                        errors;
                                                                                                                                                }
                                                                                                                                                else {
                                                                                                                                                    var valid72 = true;
                                                                                                                                                }
                                                                                                                                                if (valid72) {
                                                                                                                                                    if (data136.canSubscribeMetrics !==
                                                                                                                                                        undefined) {
                                                                                                                                                        var _errs399 = errors;
                                                                                                                                                        if (typeof data136.canSubscribeMetrics !==
                                                                                                                                                            'boolean') {
                                                                                                                                                            validate11.errors =
                                                                                                                                                                [
                                                                                                                                                                    {
                                                                                                                                                                        instancePath: instancePath +
                                                                                                                                                                            '/participant/permissions/canSubscribeMetrics',
                                                                                                                                                                        schemaPath: '#/allOf/13/then/properties/participant/properties/permissions/properties/canSubscribeMetrics/type',
                                                                                                                                                                        keyword: 'type',
                                                                                                                                                                        params: {
                                                                                                                                                                            type: 'boolean',
                                                                                                                                                                        },
                                                                                                                                                                        message: 'must be boolean',
                                                                                                                                                                    },
                                                                                                                                                                ];
                                                                                                                                                            return false;
                                                                                                                                                        }
                                                                                                                                                        var valid72 = _errs399 ===
                                                                                                                                                            errors;
                                                                                                                                                    }
                                                                                                                                                    else {
                                                                                                                                                        var valid72 = true;
                                                                                                                                                    }
                                                                                                                                                }
                                                                                                                                            }
                                                                                                                                        }
                                                                                                                                    }
                                                                                                                                }
                                                                                                                            }
                                                                                                                        }
                                                                                                                    }
                                                                                                                    else {
                                                                                                                        validate11.errors =
                                                                                                                            [
                                                                                                                                {
                                                                                                                                    instancePath: instancePath +
                                                                                                                                        '/participant/permissions',
                                                                                                                                    schemaPath: '#/allOf/13/then/properties/participant/properties/permissions/type',
                                                                                                                                    keyword: 'type',
                                                                                                                                    params: {
                                                                                                                                        type: 'object',
                                                                                                                                    },
                                                                                                                                    message: 'must be object',
                                                                                                                                },
                                                                                                                            ];
                                                                                                                        return false;
                                                                                                                    }
                                                                                                                }
                                                                                                                var valid71 = _errs386 === errors;
                                                                                                            }
                                                                                                            else {
                                                                                                                var valid71 = true;
                                                                                                            }
                                                                                                        }
                                                                                                    }
                                                                                                }
                                                                                            }
                                                                                        }
                                                                                        else {
                                                                                            validate11.errors = [
                                                                                                {
                                                                                                    instancePath: instancePath +
                                                                                                        '/participant',
                                                                                                    schemaPath: '#/allOf/13/then/properties/participant/type',
                                                                                                    keyword: 'type',
                                                                                                    params: { type: 'object' },
                                                                                                    message: 'must be object',
                                                                                                },
                                                                                            ];
                                                                                            return false;
                                                                                        }
                                                                                    }
                                                                                    var valid67 = _errs379 === errors;
                                                                                }
                                                                                else {
                                                                                    var valid67 = true;
                                                                                }
                                                                                if (valid67) {
                                                                                    if (data.name !== undefined) {
                                                                                        var data143 = data.name;
                                                                                        var _errs401 = errors;
                                                                                        if (typeof data143 !== 'string') {
                                                                                            validate11.errors = [
                                                                                                {
                                                                                                    instancePath: instancePath + '/name',
                                                                                                    schemaPath: '#/allOf/13/then/properties/name/type',
                                                                                                    keyword: 'type',
                                                                                                    params: { type: 'string' },
                                                                                                    message: 'must be string',
                                                                                                },
                                                                                            ];
                                                                                            return false;
                                                                                        }
                                                                                        if ('trackUnsubscribed' !== data143) {
                                                                                            validate11.errors = [
                                                                                                {
                                                                                                    instancePath: instancePath + '/name',
                                                                                                    schemaPath: '#/allOf/13/then/properties/name/const',
                                                                                                    keyword: 'const',
                                                                                                    params: {
                                                                                                        allowedValue: 'trackUnsubscribed',
                                                                                                    },
                                                                                                    message: 'must be equal to constant',
                                                                                                },
                                                                                            ];
                                                                                            return false;
                                                                                        }
                                                                                        var valid67 = _errs401 === errors;
                                                                                    }
                                                                                    else {
                                                                                        var valid67 = true;
                                                                                    }
                                                                                    if (valid67) {
                                                                                        if (data.timestamp !== undefined) {
                                                                                            var data144 = data.timestamp;
                                                                                            var _errs403 = errors;
                                                                                            if (!(typeof data144 == 'number' &&
                                                                                                isFinite(data144))) {
                                                                                                validate11.errors = [
                                                                                                    {
                                                                                                        instancePath: instancePath +
                                                                                                            '/timestamp',
                                                                                                        schemaPath: '#/allOf/13/then/properties/timestamp/type',
                                                                                                        keyword: 'type',
                                                                                                        params: { type: 'number' },
                                                                                                        message: 'must be number',
                                                                                                    },
                                                                                                ];
                                                                                                return false;
                                                                                            }
                                                                                            var valid67 = _errs403 === errors;
                                                                                        }
                                                                                        else {
                                                                                            var valid67 = true;
                                                                                        }
                                                                                    }
                                                                                }
                                                                            }
                                                                        }
                                                                    }
                                                                }
                                                                else {
                                                                    validate11.errors = [
                                                                        {
                                                                            instancePath: instancePath,
                                                                            schemaPath: '#/allOf/13/then/type',
                                                                            keyword: 'type',
                                                                            params: { type: 'object' },
                                                                            message: 'must be object',
                                                                        },
                                                                    ];
                                                                    return false;
                                                                }
                                                            }
                                                            var _valid13 = _errs359 === errors;
                                                            valid65 = _valid13;
                                                        }
                                                        if (!valid65) {
                                                            var err41 = {
                                                                instancePath: instancePath,
                                                                schemaPath: '#/allOf/13/if',
                                                                keyword: 'if',
                                                                params: { failingKeyword: 'then' },
                                                                message: 'must match "then" schema',
                                                            };
                                                            if (vErrors === null) {
                                                                vErrors = [err41];
                                                            }
                                                            else {
                                                                vErrors.push(err41);
                                                            }
                                                            errors++;
                                                            validate11.errors = vErrors;
                                                            return false;
                                                        }
                                                        var valid0 = _errs354 === errors;
                                                        if (valid0) {
                                                            var _errs405 = errors;
                                                            var _errs406 = errors;
                                                            var valid73 = true;
                                                            var _errs407 = errors;
                                                            if (data &&
                                                                typeof data == 'object' &&
                                                                !Array.isArray(data)) {
                                                                if (data.name !== undefined) {
                                                                    var data145 = data.name;
                                                                    if (typeof data145 !== 'string') {
                                                                        var err42 = {};
                                                                        if (vErrors === null) {
                                                                            vErrors = [err42];
                                                                        }
                                                                        else {
                                                                            vErrors.push(err42);
                                                                        }
                                                                        errors++;
                                                                    }
                                                                    if ('trackMuted' !== data145) {
                                                                        var err43 = {};
                                                                        if (vErrors === null) {
                                                                            vErrors = [err43];
                                                                        }
                                                                        else {
                                                                            vErrors.push(err43);
                                                                        }
                                                                        errors++;
                                                                    }
                                                                }
                                                            }
                                                            var _valid14 = _errs407 === errors;
                                                            errors = _errs406;
                                                            if (vErrors !== null) {
                                                                if (_errs406) {
                                                                    vErrors.length = _errs406;
                                                                }
                                                                else {
                                                                    vErrors = null;
                                                                }
                                                            }
                                                            if (_valid14) {
                                                                var _errs410 = errors;
                                                                if (errors === _errs410) {
                                                                    if (data &&
                                                                        typeof data == 'object' &&
                                                                        !Array.isArray(data)) {
                                                                        var missing37 = void 0;
                                                                        if ((data.name === undefined &&
                                                                            (missing37 = 'name')) ||
                                                                            (data.participant === undefined &&
                                                                                (missing37 = 'participant')) ||
                                                                            (data.timestamp === undefined &&
                                                                                (missing37 = 'timestamp')) ||
                                                                            (data.track === undefined &&
                                                                                (missing37 = 'track'))) {
                                                                            validate11.errors = [
                                                                                {
                                                                                    instancePath: instancePath,
                                                                                    schemaPath: '#/allOf/14/then/required',
                                                                                    keyword: 'required',
                                                                                    params: {
                                                                                        missingProperty: missing37,
                                                                                    },
                                                                                    message: "must have required property '" +
                                                                                        missing37 +
                                                                                        "'",
                                                                                },
                                                                            ];
                                                                            return false;
                                                                        }
                                                                        else {
                                                                            var _errs412 = errors;
                                                                            for (var key37 in data) {
                                                                                if (!(key37 === 'track' ||
                                                                                    key37 === 'participant' ||
                                                                                    key37 === 'name' ||
                                                                                    key37 === 'timestamp')) {
                                                                                    validate11.errors = [
                                                                                        {
                                                                                            instancePath: instancePath,
                                                                                            schemaPath: '#/allOf/14/then/additionalProperties',
                                                                                            keyword: 'additionalProperties',
                                                                                            params: {
                                                                                                additionalProperty: key37,
                                                                                            },
                                                                                            message: 'must NOT have additional properties',
                                                                                        },
                                                                                    ];
                                                                                    return false;
                                                                                    break;
                                                                                }
                                                                            }
                                                                            if (_errs412 === errors) {
                                                                                if (data.track !== undefined) {
                                                                                    var data146 = data.track;
                                                                                    var _errs413 = errors;
                                                                                    if (errors === _errs413) {
                                                                                        if (data146 &&
                                                                                            typeof data146 == 'object' &&
                                                                                            !Array.isArray(data146)) {
                                                                                            var missing38 = void 0;
                                                                                            if ((data146.sid === undefined &&
                                                                                                (missing38 = 'sid')) ||
                                                                                                (data146.source === undefined &&
                                                                                                    (missing38 = 'source'))) {
                                                                                                validate11.errors = [
                                                                                                    {
                                                                                                        instancePath: instancePath + '/track',
                                                                                                        schemaPath: '#/allOf/14/then/properties/track/required',
                                                                                                        keyword: 'required',
                                                                                                        params: {
                                                                                                            missingProperty: missing38,
                                                                                                        },
                                                                                                        message: "must have required property '" +
                                                                                                            missing38 +
                                                                                                            "'",
                                                                                                    },
                                                                                                ];
                                                                                                return false;
                                                                                            }
                                                                                            else {
                                                                                                var _errs415 = errors;
                                                                                                for (var key38 in data146) {
                                                                                                    if (!(key38 === 'sid' ||
                                                                                                        key38 === 'source' ||
                                                                                                        key38 === 'mimeType' ||
                                                                                                        key38 === 'dimensions')) {
                                                                                                        validate11.errors = [
                                                                                                            {
                                                                                                                instancePath: instancePath +
                                                                                                                    '/track',
                                                                                                                schemaPath: '#/allOf/14/then/properties/track/additionalProperties',
                                                                                                                keyword: 'additionalProperties',
                                                                                                                params: {
                                                                                                                    additionalProperty: key38,
                                                                                                                },
                                                                                                                message: 'must NOT have additional properties',
                                                                                                            },
                                                                                                        ];
                                                                                                        return false;
                                                                                                        break;
                                                                                                    }
                                                                                                }
                                                                                                if (_errs415 === errors) {
                                                                                                    if (data146.sid !== undefined) {
                                                                                                        var _errs416 = errors;
                                                                                                        if (typeof data146.sid !==
                                                                                                            'string') {
                                                                                                            validate11.errors = [
                                                                                                                {
                                                                                                                    instancePath: instancePath +
                                                                                                                        '/track/sid',
                                                                                                                    schemaPath: '#/allOf/14/then/properties/track/properties/sid/type',
                                                                                                                    keyword: 'type',
                                                                                                                    params: {
                                                                                                                        type: 'string',
                                                                                                                    },
                                                                                                                    message: 'must be string',
                                                                                                                },
                                                                                                            ];
                                                                                                            return false;
                                                                                                        }
                                                                                                        var valid76 = _errs416 === errors;
                                                                                                    }
                                                                                                    else {
                                                                                                        var valid76 = true;
                                                                                                    }
                                                                                                    if (valid76) {
                                                                                                        if (data146.source !==
                                                                                                            undefined) {
                                                                                                            var data148 = data146.source;
                                                                                                            var _errs418 = errors;
                                                                                                            if (typeof data148 !==
                                                                                                                'string') {
                                                                                                                validate11.errors = [
                                                                                                                    {
                                                                                                                        instancePath: instancePath +
                                                                                                                            '/track/source',
                                                                                                                        schemaPath: '#/definitions/Track.Source/type',
                                                                                                                        keyword: 'type',
                                                                                                                        params: {
                                                                                                                            type: 'string',
                                                                                                                        },
                                                                                                                        message: 'must be string',
                                                                                                                    },
                                                                                                                ];
                                                                                                                return false;
                                                                                                            }
                                                                                                            if (!(data148 ===
                                                                                                                'camera' ||
                                                                                                                data148 ===
                                                                                                                    'microphone' ||
                                                                                                                data148 ===
                                                                                                                    'screen_share' ||
                                                                                                                data148 ===
                                                                                                                    'screen_share_audio' ||
                                                                                                                data148 === 'unknown')) {
                                                                                                                validate11.errors = [
                                                                                                                    {
                                                                                                                        instancePath: instancePath +
                                                                                                                            '/track/source',
                                                                                                                        schemaPath: '#/definitions/Track.Source/enum',
                                                                                                                        keyword: 'enum',
                                                                                                                        params: {
                                                                                                                            allowedValues: schema31.enum,
                                                                                                                        },
                                                                                                                        message: 'must be equal to one of the allowed values',
                                                                                                                    },
                                                                                                                ];
                                                                                                                return false;
                                                                                                            }
                                                                                                            var valid76 = _errs418 === errors;
                                                                                                        }
                                                                                                        else {
                                                                                                            var valid76 = true;
                                                                                                        }
                                                                                                        if (valid76) {
                                                                                                            if (data146.mimeType !==
                                                                                                                undefined) {
                                                                                                                var _errs421 = errors;
                                                                                                                if (typeof data146.mimeType !==
                                                                                                                    'string') {
                                                                                                                    validate11.errors = [
                                                                                                                        {
                                                                                                                            instancePath: instancePath +
                                                                                                                                '/track/mimeType',
                                                                                                                            schemaPath: '#/allOf/14/then/properties/track/properties/mimeType/type',
                                                                                                                            keyword: 'type',
                                                                                                                            params: {
                                                                                                                                type: 'string',
                                                                                                                            },
                                                                                                                            message: 'must be string',
                                                                                                                        },
                                                                                                                    ];
                                                                                                                    return false;
                                                                                                                }
                                                                                                                var valid76 = _errs421 === errors;
                                                                                                            }
                                                                                                            else {
                                                                                                                var valid76 = true;
                                                                                                            }
                                                                                                            if (valid76) {
                                                                                                                if (data146.dimensions !==
                                                                                                                    undefined) {
                                                                                                                    var data150 = data146.dimensions;
                                                                                                                    var _errs423 = errors;
                                                                                                                    if (errors === _errs423) {
                                                                                                                        if (data150 &&
                                                                                                                            typeof data150 ==
                                                                                                                                'object' &&
                                                                                                                            !Array.isArray(data150)) {
                                                                                                                            var missing39 = void 0;
                                                                                                                            if ((data150.width ===
                                                                                                                                undefined &&
                                                                                                                                (missing39 =
                                                                                                                                    'width')) ||
                                                                                                                                (data150.height ===
                                                                                                                                    undefined &&
                                                                                                                                    (missing39 =
                                                                                                                                        'height'))) {
                                                                                                                                validate11.errors =
                                                                                                                                    [
                                                                                                                                        {
                                                                                                                                            instancePath: instancePath +
                                                                                                                                                '/track/dimensions',
                                                                                                                                            schemaPath: '#/allOf/14/then/properties/track/properties/dimensions/required',
                                                                                                                                            keyword: 'required',
                                                                                                                                            params: {
                                                                                                                                                missingProperty: missing39,
                                                                                                                                            },
                                                                                                                                            message: "must have required property '" +
                                                                                                                                                missing39 +
                                                                                                                                                "'",
                                                                                                                                        },
                                                                                                                                    ];
                                                                                                                                return false;
                                                                                                                            }
                                                                                                                            else {
                                                                                                                                var _errs425 = errors;
                                                                                                                                for (var key39 in data150) {
                                                                                                                                    if (!(key39 ===
                                                                                                                                        'width' ||
                                                                                                                                        key39 ===
                                                                                                                                            'height')) {
                                                                                                                                        validate11.errors =
                                                                                                                                            [
                                                                                                                                                {
                                                                                                                                                    instancePath: instancePath +
                                                                                                                                                        '/track/dimensions',
                                                                                                                                                    schemaPath: '#/allOf/14/then/properties/track/properties/dimensions/additionalProperties',
                                                                                                                                                    keyword: 'additionalProperties',
                                                                                                                                                    params: {
                                                                                                                                                        additionalProperty: key39,
                                                                                                                                                    },
                                                                                                                                                    message: 'must NOT have additional properties',
                                                                                                                                                },
                                                                                                                                            ];
                                                                                                                                        return false;
                                                                                                                                        break;
                                                                                                                                    }
                                                                                                                                }
                                                                                                                                if (_errs425 ===
                                                                                                                                    errors) {
                                                                                                                                    if (data150.width !==
                                                                                                                                        undefined) {
                                                                                                                                        var data151 = data150.width;
                                                                                                                                        var _errs426 = errors;
                                                                                                                                        if (!(typeof data151 ==
                                                                                                                                            'number' &&
                                                                                                                                            isFinite(data151))) {
                                                                                                                                            validate11.errors =
                                                                                                                                                [
                                                                                                                                                    {
                                                                                                                                                        instancePath: instancePath +
                                                                                                                                                            '/track/dimensions/width',
                                                                                                                                                        schemaPath: '#/allOf/14/then/properties/track/properties/dimensions/properties/width/type',
                                                                                                                                                        keyword: 'type',
                                                                                                                                                        params: {
                                                                                                                                                            type: 'number',
                                                                                                                                                        },
                                                                                                                                                        message: 'must be number',
                                                                                                                                                    },
                                                                                                                                                ];
                                                                                                                                            return false;
                                                                                                                                        }
                                                                                                                                        var valid78 = _errs426 ===
                                                                                                                                            errors;
                                                                                                                                    }
                                                                                                                                    else {
                                                                                                                                        var valid78 = true;
                                                                                                                                    }
                                                                                                                                    if (valid78) {
                                                                                                                                        if (data150.height !==
                                                                                                                                            undefined) {
                                                                                                                                            var data152 = data150.height;
                                                                                                                                            var _errs428 = errors;
                                                                                                                                            if (!(typeof data152 ==
                                                                                                                                                'number' &&
                                                                                                                                                isFinite(data152))) {
                                                                                                                                                validate11.errors =
                                                                                                                                                    [
                                                                                                                                                        {
                                                                                                                                                            instancePath: instancePath +
                                                                                                                                                                '/track/dimensions/height',
                                                                                                                                                            schemaPath: '#/allOf/14/then/properties/track/properties/dimensions/properties/height/type',
                                                                                                                                                            keyword: 'type',
                                                                                                                                                            params: {
                                                                                                                                                                type: 'number',
                                                                                                                                                            },
                                                                                                                                                            message: 'must be number',
                                                                                                                                                        },
                                                                                                                                                    ];
                                                                                                                                                return false;
                                                                                                                                            }
                                                                                                                                            var valid78 = _errs428 ===
                                                                                                                                                errors;
                                                                                                                                        }
                                                                                                                                        else {
                                                                                                                                            var valid78 = true;
                                                                                                                                        }
                                                                                                                                    }
                                                                                                                                }
                                                                                                                            }
                                                                                                                        }
                                                                                                                        else {
                                                                                                                            validate11.errors =
                                                                                                                                [
                                                                                                                                    {
                                                                                                                                        instancePath: instancePath +
                                                                                                                                            '/track/dimensions',
                                                                                                                                        schemaPath: '#/allOf/14/then/properties/track/properties/dimensions/type',
                                                                                                                                        keyword: 'type',
                                                                                                                                        params: {
                                                                                                                                            type: 'object',
                                                                                                                                        },
                                                                                                                                        message: 'must be object',
                                                                                                                                    },
                                                                                                                                ];
                                                                                                                            return false;
                                                                                                                        }
                                                                                                                    }
                                                                                                                    var valid76 = _errs423 === errors;
                                                                                                                }
                                                                                                                else {
                                                                                                                    var valid76 = true;
                                                                                                                }
                                                                                                            }
                                                                                                        }
                                                                                                    }
                                                                                                }
                                                                                            }
                                                                                        }
                                                                                        else {
                                                                                            validate11.errors = [
                                                                                                {
                                                                                                    instancePath: instancePath + '/track',
                                                                                                    schemaPath: '#/allOf/14/then/properties/track/type',
                                                                                                    keyword: 'type',
                                                                                                    params: { type: 'object' },
                                                                                                    message: 'must be object',
                                                                                                },
                                                                                            ];
                                                                                            return false;
                                                                                        }
                                                                                    }
                                                                                    var valid75 = _errs413 === errors;
                                                                                }
                                                                                else {
                                                                                    var valid75 = true;
                                                                                }
                                                                                if (valid75) {
                                                                                    if (data.participant !== undefined) {
                                                                                        var data153 = data.participant;
                                                                                        var _errs430 = errors;
                                                                                        if (errors === _errs430) {
                                                                                            if (data153 &&
                                                                                                typeof data153 == 'object' &&
                                                                                                !Array.isArray(data153)) {
                                                                                                var missing40 = void 0;
                                                                                                if ((data153.identity ===
                                                                                                    undefined &&
                                                                                                    (missing40 = 'identity')) ||
                                                                                                    (data153.sid === undefined &&
                                                                                                        (missing40 = 'sid'))) {
                                                                                                    validate11.errors = [
                                                                                                        {
                                                                                                            instancePath: instancePath +
                                                                                                                '/participant',
                                                                                                            schemaPath: '#/allOf/14/then/properties/participant/required',
                                                                                                            keyword: 'required',
                                                                                                            params: {
                                                                                                                missingProperty: missing40,
                                                                                                            },
                                                                                                            message: "must have required property '" +
                                                                                                                missing40 +
                                                                                                                "'",
                                                                                                        },
                                                                                                    ];
                                                                                                    return false;
                                                                                                }
                                                                                                else {
                                                                                                    var _errs432 = errors;
                                                                                                    for (var key40 in data153) {
                                                                                                        if (!(key40 === 'identity' ||
                                                                                                            key40 === 'sid' ||
                                                                                                            key40 === 'permissions')) {
                                                                                                            validate11.errors = [
                                                                                                                {
                                                                                                                    instancePath: instancePath +
                                                                                                                        '/participant',
                                                                                                                    schemaPath: '#/allOf/14/then/properties/participant/additionalProperties',
                                                                                                                    keyword: 'additionalProperties',
                                                                                                                    params: {
                                                                                                                        additionalProperty: key40,
                                                                                                                    },
                                                                                                                    message: 'must NOT have additional properties',
                                                                                                                },
                                                                                                            ];
                                                                                                            return false;
                                                                                                            break;
                                                                                                        }
                                                                                                    }
                                                                                                    if (_errs432 === errors) {
                                                                                                        if (data153.identity !==
                                                                                                            undefined) {
                                                                                                            var _errs433 = errors;
                                                                                                            if (typeof data153.identity !==
                                                                                                                'string') {
                                                                                                                validate11.errors = [
                                                                                                                    {
                                                                                                                        instancePath: instancePath +
                                                                                                                            '/participant/identity',
                                                                                                                        schemaPath: '#/allOf/14/then/properties/participant/properties/identity/type',
                                                                                                                        keyword: 'type',
                                                                                                                        params: {
                                                                                                                            type: 'string',
                                                                                                                        },
                                                                                                                        message: 'must be string',
                                                                                                                    },
                                                                                                                ];
                                                                                                                return false;
                                                                                                            }
                                                                                                            var valid79 = _errs433 === errors;
                                                                                                        }
                                                                                                        else {
                                                                                                            var valid79 = true;
                                                                                                        }
                                                                                                        if (valid79) {
                                                                                                            if (data153.sid !==
                                                                                                                undefined) {
                                                                                                                var _errs435 = errors;
                                                                                                                if (typeof data153.sid !==
                                                                                                                    'string') {
                                                                                                                    validate11.errors = [
                                                                                                                        {
                                                                                                                            instancePath: instancePath +
                                                                                                                                '/participant/sid',
                                                                                                                            schemaPath: '#/allOf/14/then/properties/participant/properties/sid/type',
                                                                                                                            keyword: 'type',
                                                                                                                            params: {
                                                                                                                                type: 'string',
                                                                                                                            },
                                                                                                                            message: 'must be string',
                                                                                                                        },
                                                                                                                    ];
                                                                                                                    return false;
                                                                                                                }
                                                                                                                var valid79 = _errs435 === errors;
                                                                                                            }
                                                                                                            else {
                                                                                                                var valid79 = true;
                                                                                                            }
                                                                                                            if (valid79) {
                                                                                                                if (data153.permissions !==
                                                                                                                    undefined) {
                                                                                                                    var data156 = data153.permissions;
                                                                                                                    var _errs437 = errors;
                                                                                                                    if (errors === _errs437) {
                                                                                                                        if (data156 &&
                                                                                                                            typeof data156 ==
                                                                                                                                'object' &&
                                                                                                                            !Array.isArray(data156)) {
                                                                                                                            var missing41 = void 0;
                                                                                                                            if ((data156.canSubscribe ===
                                                                                                                                undefined &&
                                                                                                                                (missing41 =
                                                                                                                                    'canSubscribe')) ||
                                                                                                                                (data156.canPublish ===
                                                                                                                                    undefined &&
                                                                                                                                    (missing41 =
                                                                                                                                        'canPublish')) ||
                                                                                                                                (data156.canPublishData ===
                                                                                                                                    undefined &&
                                                                                                                                    (missing41 =
                                                                                                                                        'canPublishData')) ||
                                                                                                                                (data156.hidden ===
                                                                                                                                    undefined &&
                                                                                                                                    (missing41 =
                                                                                                                                        'hidden')) ||
                                                                                                                                (data156.canUpdateMetadata ===
                                                                                                                                    undefined &&
                                                                                                                                    (missing41 =
                                                                                                                                        'canUpdateMetadata')) ||
                                                                                                                                (data156.canSubscribeMetrics ===
                                                                                                                                    undefined &&
                                                                                                                                    (missing41 =
                                                                                                                                        'canSubscribeMetrics'))) {
                                                                                                                                validate11.errors =
                                                                                                                                    [
                                                                                                                                        {
                                                                                                                                            instancePath: instancePath +
                                                                                                                                                '/participant/permissions',
                                                                                                                                            schemaPath: '#/allOf/14/then/properties/participant/properties/permissions/required',
                                                                                                                                            keyword: 'required',
                                                                                                                                            params: {
                                                                                                                                                missingProperty: missing41,
                                                                                                                                            },
                                                                                                                                            message: "must have required property '" +
                                                                                                                                                missing41 +
                                                                                                                                                "'",
                                                                                                                                        },
                                                                                                                                    ];
                                                                                                                                return false;
                                                                                                                            }
                                                                                                                            else {
                                                                                                                                var _errs439 = errors;
                                                                                                                                for (var key41 in data156) {
                                                                                                                                    if (!(key41 ===
                                                                                                                                        'canSubscribe' ||
                                                                                                                                        key41 ===
                                                                                                                                            'canPublish' ||
                                                                                                                                        key41 ===
                                                                                                                                            'canPublishData' ||
                                                                                                                                        key41 ===
                                                                                                                                            'hidden' ||
                                                                                                                                        key41 ===
                                                                                                                                            'canUpdateMetadata' ||
                                                                                                                                        key41 ===
                                                                                                                                            'canSubscribeMetrics')) {
                                                                                                                                        validate11.errors =
                                                                                                                                            [
                                                                                                                                                {
                                                                                                                                                    instancePath: instancePath +
                                                                                                                                                        '/participant/permissions',
                                                                                                                                                    schemaPath: '#/allOf/14/then/properties/participant/properties/permissions/additionalProperties',
                                                                                                                                                    keyword: 'additionalProperties',
                                                                                                                                                    params: {
                                                                                                                                                        additionalProperty: key41,
                                                                                                                                                    },
                                                                                                                                                    message: 'must NOT have additional properties',
                                                                                                                                                },
                                                                                                                                            ];
                                                                                                                                        return false;
                                                                                                                                        break;
                                                                                                                                    }
                                                                                                                                }
                                                                                                                                if (_errs439 ===
                                                                                                                                    errors) {
                                                                                                                                    if (data156.canSubscribe !==
                                                                                                                                        undefined) {
                                                                                                                                        var _errs440 = errors;
                                                                                                                                        if (typeof data156.canSubscribe !==
                                                                                                                                            'boolean') {
                                                                                                                                            validate11.errors =
                                                                                                                                                [
                                                                                                                                                    {
                                                                                                                                                        instancePath: instancePath +
                                                                                                                                                            '/participant/permissions/canSubscribe',
                                                                                                                                                        schemaPath: '#/allOf/14/then/properties/participant/properties/permissions/properties/canSubscribe/type',
                                                                                                                                                        keyword: 'type',
                                                                                                                                                        params: {
                                                                                                                                                            type: 'boolean',
                                                                                                                                                        },
                                                                                                                                                        message: 'must be boolean',
                                                                                                                                                    },
                                                                                                                                                ];
                                                                                                                                            return false;
                                                                                                                                        }
                                                                                                                                        var valid80 = _errs440 ===
                                                                                                                                            errors;
                                                                                                                                    }
                                                                                                                                    else {
                                                                                                                                        var valid80 = true;
                                                                                                                                    }
                                                                                                                                    if (valid80) {
                                                                                                                                        if (data156.canPublish !==
                                                                                                                                            undefined) {
                                                                                                                                            var _errs442 = errors;
                                                                                                                                            if (typeof data156.canPublish !==
                                                                                                                                                'boolean') {
                                                                                                                                                validate11.errors =
                                                                                                                                                    [
                                                                                                                                                        {
                                                                                                                                                            instancePath: instancePath +
                                                                                                                                                                '/participant/permissions/canPublish',
                                                                                                                                                            schemaPath: '#/allOf/14/then/properties/participant/properties/permissions/properties/canPublish/type',
                                                                                                                                                            keyword: 'type',
                                                                                                                                                            params: {
                                                                                                                                                                type: 'boolean',
                                                                                                                                                            },
                                                                                                                                                            message: 'must be boolean',
                                                                                                                                                        },
                                                                                                                                                    ];
                                                                                                                                                return false;
                                                                                                                                            }
                                                                                                                                            var valid80 = _errs442 ===
                                                                                                                                                errors;
                                                                                                                                        }
                                                                                                                                        else {
                                                                                                                                            var valid80 = true;
                                                                                                                                        }
                                                                                                                                        if (valid80) {
                                                                                                                                            if (data156.canPublishData !==
                                                                                                                                                undefined) {
                                                                                                                                                var _errs444 = errors;
                                                                                                                                                if (typeof data156.canPublishData !==
                                                                                                                                                    'boolean') {
                                                                                                                                                    validate11.errors =
                                                                                                                                                        [
                                                                                                                                                            {
                                                                                                                                                                instancePath: instancePath +
                                                                                                                                                                    '/participant/permissions/canPublishData',
                                                                                                                                                                schemaPath: '#/allOf/14/then/properties/participant/properties/permissions/properties/canPublishData/type',
                                                                                                                                                                keyword: 'type',
                                                                                                                                                                params: {
                                                                                                                                                                    type: 'boolean',
                                                                                                                                                                },
                                                                                                                                                                message: 'must be boolean',
                                                                                                                                                            },
                                                                                                                                                        ];
                                                                                                                                                    return false;
                                                                                                                                                }
                                                                                                                                                var valid80 = _errs444 ===
                                                                                                                                                    errors;
                                                                                                                                            }
                                                                                                                                            else {
                                                                                                                                                var valid80 = true;
                                                                                                                                            }
                                                                                                                                            if (valid80) {
                                                                                                                                                if (data156.hidden !==
                                                                                                                                                    undefined) {
                                                                                                                                                    var _errs446 = errors;
                                                                                                                                                    if (typeof data156.hidden !==
                                                                                                                                                        'boolean') {
                                                                                                                                                        validate11.errors =
                                                                                                                                                            [
                                                                                                                                                                {
                                                                                                                                                                    instancePath: instancePath +
                                                                                                                                                                        '/participant/permissions/hidden',
                                                                                                                                                                    schemaPath: '#/allOf/14/then/properties/participant/properties/permissions/properties/hidden/type',
                                                                                                                                                                    keyword: 'type',
                                                                                                                                                                    params: {
                                                                                                                                                                        type: 'boolean',
                                                                                                                                                                    },
                                                                                                                                                                    message: 'must be boolean',
                                                                                                                                                                },
                                                                                                                                                            ];
                                                                                                                                                        return false;
                                                                                                                                                    }
                                                                                                                                                    var valid80 = _errs446 ===
                                                                                                                                                        errors;
                                                                                                                                                }
                                                                                                                                                else {
                                                                                                                                                    var valid80 = true;
                                                                                                                                                }
                                                                                                                                                if (valid80) {
                                                                                                                                                    if (data156.canUpdateMetadata !==
                                                                                                                                                        undefined) {
                                                                                                                                                        var _errs448 = errors;
                                                                                                                                                        if (typeof data156.canUpdateMetadata !==
                                                                                                                                                            'boolean') {
                                                                                                                                                            validate11.errors =
                                                                                                                                                                [
                                                                                                                                                                    {
                                                                                                                                                                        instancePath: instancePath +
                                                                                                                                                                            '/participant/permissions/canUpdateMetadata',
                                                                                                                                                                        schemaPath: '#/allOf/14/then/properties/participant/properties/permissions/properties/canUpdateMetadata/type',
                                                                                                                                                                        keyword: 'type',
                                                                                                                                                                        params: {
                                                                                                                                                                            type: 'boolean',
                                                                                                                                                                        },
                                                                                                                                                                        message: 'must be boolean',
                                                                                                                                                                    },
                                                                                                                                                                ];
                                                                                                                                                            return false;
                                                                                                                                                        }
                                                                                                                                                        var valid80 = _errs448 ===
                                                                                                                                                            errors;
                                                                                                                                                    }
                                                                                                                                                    else {
                                                                                                                                                        var valid80 = true;
                                                                                                                                                    }
                                                                                                                                                    if (valid80) {
                                                                                                                                                        if (data156.canSubscribeMetrics !==
                                                                                                                                                            undefined) {
                                                                                                                                                            var _errs450 = errors;
                                                                                                                                                            if (typeof data156.canSubscribeMetrics !==
                                                                                                                                                                'boolean') {
                                                                                                                                                                validate11.errors =
                                                                                                                                                                    [
                                                                                                                                                                        {
                                                                                                                                                                            instancePath: instancePath +
                                                                                                                                                                                '/participant/permissions/canSubscribeMetrics',
                                                                                                                                                                            schemaPath: '#/allOf/14/then/properties/participant/properties/permissions/properties/canSubscribeMetrics/type',
                                                                                                                                                                            keyword: 'type',
                                                                                                                                                                            params: {
                                                                                                                                                                                type: 'boolean',
                                                                                                                                                                            },
                                                                                                                                                                            message: 'must be boolean',
                                                                                                                                                                        },
                                                                                                                                                                    ];
                                                                                                                                                                return false;
                                                                                                                                                            }
                                                                                                                                                            var valid80 = _errs450 ===
                                                                                                                                                                errors;
                                                                                                                                                        }
                                                                                                                                                        else {
                                                                                                                                                            var valid80 = true;
                                                                                                                                                        }
                                                                                                                                                    }
                                                                                                                                                }
                                                                                                                                            }
                                                                                                                                        }
                                                                                                                                    }
                                                                                                                                }
                                                                                                                            }
                                                                                                                        }
                                                                                                                        else {
                                                                                                                            validate11.errors =
                                                                                                                                [
                                                                                                                                    {
                                                                                                                                        instancePath: instancePath +
                                                                                                                                            '/participant/permissions',
                                                                                                                                        schemaPath: '#/allOf/14/then/properties/participant/properties/permissions/type',
                                                                                                                                        keyword: 'type',
                                                                                                                                        params: {
                                                                                                                                            type: 'object',
                                                                                                                                        },
                                                                                                                                        message: 'must be object',
                                                                                                                                    },
                                                                                                                                ];
                                                                                                                            return false;
                                                                                                                        }
                                                                                                                    }
                                                                                                                    var valid79 = _errs437 === errors;
                                                                                                                }
                                                                                                                else {
                                                                                                                    var valid79 = true;
                                                                                                                }
                                                                                                            }
                                                                                                        }
                                                                                                    }
                                                                                                }
                                                                                            }
                                                                                            else {
                                                                                                validate11.errors = [
                                                                                                    {
                                                                                                        instancePath: instancePath +
                                                                                                            '/participant',
                                                                                                        schemaPath: '#/allOf/14/then/properties/participant/type',
                                                                                                        keyword: 'type',
                                                                                                        params: { type: 'object' },
                                                                                                        message: 'must be object',
                                                                                                    },
                                                                                                ];
                                                                                                return false;
                                                                                            }
                                                                                        }
                                                                                        var valid75 = _errs430 === errors;
                                                                                    }
                                                                                    else {
                                                                                        var valid75 = true;
                                                                                    }
                                                                                    if (valid75) {
                                                                                        if (data.name !== undefined) {
                                                                                            var data163 = data.name;
                                                                                            var _errs452 = errors;
                                                                                            if (typeof data163 !== 'string') {
                                                                                                validate11.errors = [
                                                                                                    {
                                                                                                        instancePath: instancePath + '/name',
                                                                                                        schemaPath: '#/allOf/14/then/properties/name/type',
                                                                                                        keyword: 'type',
                                                                                                        params: { type: 'string' },
                                                                                                        message: 'must be string',
                                                                                                    },
                                                                                                ];
                                                                                                return false;
                                                                                            }
                                                                                            if ('trackMuted' !== data163) {
                                                                                                validate11.errors = [
                                                                                                    {
                                                                                                        instancePath: instancePath + '/name',
                                                                                                        schemaPath: '#/allOf/14/then/properties/name/const',
                                                                                                        keyword: 'const',
                                                                                                        params: {
                                                                                                            allowedValue: 'trackMuted',
                                                                                                        },
                                                                                                        message: 'must be equal to constant',
                                                                                                    },
                                                                                                ];
                                                                                                return false;
                                                                                            }
                                                                                            var valid75 = _errs452 === errors;
                                                                                        }
                                                                                        else {
                                                                                            var valid75 = true;
                                                                                        }
                                                                                        if (valid75) {
                                                                                            if (data.timestamp !== undefined) {
                                                                                                var data164 = data.timestamp;
                                                                                                var _errs454 = errors;
                                                                                                if (!(typeof data164 ==
                                                                                                    'number' &&
                                                                                                    isFinite(data164))) {
                                                                                                    validate11.errors = [
                                                                                                        {
                                                                                                            instancePath: instancePath +
                                                                                                                '/timestamp',
                                                                                                            schemaPath: '#/allOf/14/then/properties/timestamp/type',
                                                                                                            keyword: 'type',
                                                                                                            params: {
                                                                                                                type: 'number',
                                                                                                            },
                                                                                                            message: 'must be number',
                                                                                                        },
                                                                                                    ];
                                                                                                    return false;
                                                                                                }
                                                                                                var valid75 = _errs454 === errors;
                                                                                            }
                                                                                            else {
                                                                                                var valid75 = true;
                                                                                            }
                                                                                        }
                                                                                    }
                                                                                }
                                                                            }
                                                                        }
                                                                    }
                                                                    else {
                                                                        validate11.errors = [
                                                                            {
                                                                                instancePath: instancePath,
                                                                                schemaPath: '#/allOf/14/then/type',
                                                                                keyword: 'type',
                                                                                params: { type: 'object' },
                                                                                message: 'must be object',
                                                                            },
                                                                        ];
                                                                        return false;
                                                                    }
                                                                }
                                                                var _valid14 = _errs410 === errors;
                                                                valid73 = _valid14;
                                                            }
                                                            if (!valid73) {
                                                                var err44 = {
                                                                    instancePath: instancePath,
                                                                    schemaPath: '#/allOf/14/if',
                                                                    keyword: 'if',
                                                                    params: { failingKeyword: 'then' },
                                                                    message: 'must match "then" schema',
                                                                };
                                                                if (vErrors === null) {
                                                                    vErrors = [err44];
                                                                }
                                                                else {
                                                                    vErrors.push(err44);
                                                                }
                                                                errors++;
                                                                validate11.errors = vErrors;
                                                                return false;
                                                            }
                                                            var valid0 = _errs405 === errors;
                                                            if (valid0) {
                                                                var _errs456 = errors;
                                                                var _errs457 = errors;
                                                                var valid81 = true;
                                                                var _errs458 = errors;
                                                                if (data &&
                                                                    typeof data == 'object' &&
                                                                    !Array.isArray(data)) {
                                                                    if (data.name !== undefined) {
                                                                        var data165 = data.name;
                                                                        if (typeof data165 !== 'string') {
                                                                            var err45 = {};
                                                                            if (vErrors === null) {
                                                                                vErrors = [err45];
                                                                            }
                                                                            else {
                                                                                vErrors.push(err45);
                                                                            }
                                                                            errors++;
                                                                        }
                                                                        if ('trackUnmuted' !== data165) {
                                                                            var err46 = {};
                                                                            if (vErrors === null) {
                                                                                vErrors = [err46];
                                                                            }
                                                                            else {
                                                                                vErrors.push(err46);
                                                                            }
                                                                            errors++;
                                                                        }
                                                                    }
                                                                }
                                                                var _valid15 = _errs458 === errors;
                                                                errors = _errs457;
                                                                if (vErrors !== null) {
                                                                    if (_errs457) {
                                                                        vErrors.length = _errs457;
                                                                    }
                                                                    else {
                                                                        vErrors = null;
                                                                    }
                                                                }
                                                                if (_valid15) {
                                                                    var _errs461 = errors;
                                                                    if (errors === _errs461) {
                                                                        if (data &&
                                                                            typeof data == 'object' &&
                                                                            !Array.isArray(data)) {
                                                                            var missing42 = void 0;
                                                                            if ((data.name === undefined &&
                                                                                (missing42 = 'name')) ||
                                                                                (data.participant === undefined &&
                                                                                    (missing42 = 'participant')) ||
                                                                                (data.timestamp === undefined &&
                                                                                    (missing42 = 'timestamp')) ||
                                                                                (data.track === undefined &&
                                                                                    (missing42 = 'track'))) {
                                                                                validate11.errors = [
                                                                                    {
                                                                                        instancePath: instancePath,
                                                                                        schemaPath: '#/allOf/15/then/required',
                                                                                        keyword: 'required',
                                                                                        params: {
                                                                                            missingProperty: missing42,
                                                                                        },
                                                                                        message: "must have required property '" +
                                                                                            missing42 +
                                                                                            "'",
                                                                                    },
                                                                                ];
                                                                                return false;
                                                                            }
                                                                            else {
                                                                                var _errs463 = errors;
                                                                                for (var key42 in data) {
                                                                                    if (!(key42 === 'track' ||
                                                                                        key42 === 'participant' ||
                                                                                        key42 === 'name' ||
                                                                                        key42 === 'timestamp')) {
                                                                                        validate11.errors = [
                                                                                            {
                                                                                                instancePath: instancePath,
                                                                                                schemaPath: '#/allOf/15/then/additionalProperties',
                                                                                                keyword: 'additionalProperties',
                                                                                                params: {
                                                                                                    additionalProperty: key42,
                                                                                                },
                                                                                                message: 'must NOT have additional properties',
                                                                                            },
                                                                                        ];
                                                                                        return false;
                                                                                        break;
                                                                                    }
                                                                                }
                                                                                if (_errs463 === errors) {
                                                                                    if (data.track !== undefined) {
                                                                                        var data166 = data.track;
                                                                                        var _errs464 = errors;
                                                                                        if (errors === _errs464) {
                                                                                            if (data166 &&
                                                                                                typeof data166 == 'object' &&
                                                                                                !Array.isArray(data166)) {
                                                                                                var missing43 = void 0;
                                                                                                if ((data166.sid === undefined &&
                                                                                                    (missing43 = 'sid')) ||
                                                                                                    (data166.source ===
                                                                                                        undefined &&
                                                                                                        (missing43 = 'source'))) {
                                                                                                    validate11.errors = [
                                                                                                        {
                                                                                                            instancePath: instancePath + '/track',
                                                                                                            schemaPath: '#/allOf/15/then/properties/track/required',
                                                                                                            keyword: 'required',
                                                                                                            params: {
                                                                                                                missingProperty: missing43,
                                                                                                            },
                                                                                                            message: "must have required property '" +
                                                                                                                missing43 +
                                                                                                                "'",
                                                                                                        },
                                                                                                    ];
                                                                                                    return false;
                                                                                                }
                                                                                                else {
                                                                                                    var _errs466 = errors;
                                                                                                    for (var key43 in data166) {
                                                                                                        if (!(key43 === 'sid' ||
                                                                                                            key43 === 'source' ||
                                                                                                            key43 === 'mimeType' ||
                                                                                                            key43 === 'dimensions')) {
                                                                                                            validate11.errors = [
                                                                                                                {
                                                                                                                    instancePath: instancePath +
                                                                                                                        '/track',
                                                                                                                    schemaPath: '#/allOf/15/then/properties/track/additionalProperties',
                                                                                                                    keyword: 'additionalProperties',
                                                                                                                    params: {
                                                                                                                        additionalProperty: key43,
                                                                                                                    },
                                                                                                                    message: 'must NOT have additional properties',
                                                                                                                },
                                                                                                            ];
                                                                                                            return false;
                                                                                                            break;
                                                                                                        }
                                                                                                    }
                                                                                                    if (_errs466 === errors) {
                                                                                                        if (data166.sid !== undefined) {
                                                                                                            var _errs467 = errors;
                                                                                                            if (typeof data166.sid !==
                                                                                                                'string') {
                                                                                                                validate11.errors = [
                                                                                                                    {
                                                                                                                        instancePath: instancePath +
                                                                                                                            '/track/sid',
                                                                                                                        schemaPath: '#/allOf/15/then/properties/track/properties/sid/type',
                                                                                                                        keyword: 'type',
                                                                                                                        params: {
                                                                                                                            type: 'string',
                                                                                                                        },
                                                                                                                        message: 'must be string',
                                                                                                                    },
                                                                                                                ];
                                                                                                                return false;
                                                                                                            }
                                                                                                            var valid84 = _errs467 === errors;
                                                                                                        }
                                                                                                        else {
                                                                                                            var valid84 = true;
                                                                                                        }
                                                                                                        if (valid84) {
                                                                                                            if (data166.source !==
                                                                                                                undefined) {
                                                                                                                var data168 = data166.source;
                                                                                                                var _errs469 = errors;
                                                                                                                if (typeof data168 !==
                                                                                                                    'string') {
                                                                                                                    validate11.errors = [
                                                                                                                        {
                                                                                                                            instancePath: instancePath +
                                                                                                                                '/track/source',
                                                                                                                            schemaPath: '#/definitions/Track.Source/type',
                                                                                                                            keyword: 'type',
                                                                                                                            params: {
                                                                                                                                type: 'string',
                                                                                                                            },
                                                                                                                            message: 'must be string',
                                                                                                                        },
                                                                                                                    ];
                                                                                                                    return false;
                                                                                                                }
                                                                                                                if (!(data168 ===
                                                                                                                    'camera' ||
                                                                                                                    data168 ===
                                                                                                                        'microphone' ||
                                                                                                                    data168 ===
                                                                                                                        'screen_share' ||
                                                                                                                    data168 ===
                                                                                                                        'screen_share_audio' ||
                                                                                                                    data168 ===
                                                                                                                        'unknown')) {
                                                                                                                    validate11.errors = [
                                                                                                                        {
                                                                                                                            instancePath: instancePath +
                                                                                                                                '/track/source',
                                                                                                                            schemaPath: '#/definitions/Track.Source/enum',
                                                                                                                            keyword: 'enum',
                                                                                                                            params: {
                                                                                                                                allowedValues: schema31.enum,
                                                                                                                            },
                                                                                                                            message: 'must be equal to one of the allowed values',
                                                                                                                        },
                                                                                                                    ];
                                                                                                                    return false;
                                                                                                                }
                                                                                                                var valid84 = _errs469 === errors;
                                                                                                            }
                                                                                                            else {
                                                                                                                var valid84 = true;
                                                                                                            }
                                                                                                            if (valid84) {
                                                                                                                if (data166.mimeType !==
                                                                                                                    undefined) {
                                                                                                                    var _errs472 = errors;
                                                                                                                    if (typeof data166.mimeType !==
                                                                                                                        'string') {
                                                                                                                        validate11.errors =
                                                                                                                            [
                                                                                                                                {
                                                                                                                                    instancePath: instancePath +
                                                                                                                                        '/track/mimeType',
                                                                                                                                    schemaPath: '#/allOf/15/then/properties/track/properties/mimeType/type',
                                                                                                                                    keyword: 'type',
                                                                                                                                    params: {
                                                                                                                                        type: 'string',
                                                                                                                                    },
                                                                                                                                    message: 'must be string',
                                                                                                                                },
                                                                                                                            ];
                                                                                                                        return false;
                                                                                                                    }
                                                                                                                    var valid84 = _errs472 === errors;
                                                                                                                }
                                                                                                                else {
                                                                                                                    var valid84 = true;
                                                                                                                }
                                                                                                                if (valid84) {
                                                                                                                    if (data166.dimensions !==
                                                                                                                        undefined) {
                                                                                                                        var data170 = data166.dimensions;
                                                                                                                        var _errs474 = errors;
                                                                                                                        if (errors ===
                                                                                                                            _errs474) {
                                                                                                                            if (data170 &&
                                                                                                                                typeof data170 ==
                                                                                                                                    'object' &&
                                                                                                                                !Array.isArray(data170)) {
                                                                                                                                var missing44 = void 0;
                                                                                                                                if ((data170.width ===
                                                                                                                                    undefined &&
                                                                                                                                    (missing44 =
                                                                                                                                        'width')) ||
                                                                                                                                    (data170.height ===
                                                                                                                                        undefined &&
                                                                                                                                        (missing44 =
                                                                                                                                            'height'))) {
                                                                                                                                    validate11.errors =
                                                                                                                                        [
                                                                                                                                            {
                                                                                                                                                instancePath: instancePath +
                                                                                                                                                    '/track/dimensions',
                                                                                                                                                schemaPath: '#/allOf/15/then/properties/track/properties/dimensions/required',
                                                                                                                                                keyword: 'required',
                                                                                                                                                params: {
                                                                                                                                                    missingProperty: missing44,
                                                                                                                                                },
                                                                                                                                                message: "must have required property '" +
                                                                                                                                                    missing44 +
                                                                                                                                                    "'",
                                                                                                                                            },
                                                                                                                                        ];
                                                                                                                                    return false;
                                                                                                                                }
                                                                                                                                else {
                                                                                                                                    var _errs476 = errors;
                                                                                                                                    for (var key44 in data170) {
                                                                                                                                        if (!(key44 ===
                                                                                                                                            'width' ||
                                                                                                                                            key44 ===
                                                                                                                                                'height')) {
                                                                                                                                            validate11.errors =
                                                                                                                                                [
                                                                                                                                                    {
                                                                                                                                                        instancePath: instancePath +
                                                                                                                                                            '/track/dimensions',
                                                                                                                                                        schemaPath: '#/allOf/15/then/properties/track/properties/dimensions/additionalProperties',
                                                                                                                                                        keyword: 'additionalProperties',
                                                                                                                                                        params: {
                                                                                                                                                            additionalProperty: key44,
                                                                                                                                                        },
                                                                                                                                                        message: 'must NOT have additional properties',
                                                                                                                                                    },
                                                                                                                                                ];
                                                                                                                                            return false;
                                                                                                                                            break;
                                                                                                                                        }
                                                                                                                                    }
                                                                                                                                    if (_errs476 ===
                                                                                                                                        errors) {
                                                                                                                                        if (data170.width !==
                                                                                                                                            undefined) {
                                                                                                                                            var data171 = data170.width;
                                                                                                                                            var _errs477 = errors;
                                                                                                                                            if (!(typeof data171 ==
                                                                                                                                                'number' &&
                                                                                                                                                isFinite(data171))) {
                                                                                                                                                validate11.errors =
                                                                                                                                                    [
                                                                                                                                                        {
                                                                                                                                                            instancePath: instancePath +
                                                                                                                                                                '/track/dimensions/width',
                                                                                                                                                            schemaPath: '#/allOf/15/then/properties/track/properties/dimensions/properties/width/type',
                                                                                                                                                            keyword: 'type',
                                                                                                                                                            params: {
                                                                                                                                                                type: 'number',
                                                                                                                                                            },
                                                                                                                                                            message: 'must be number',
                                                                                                                                                        },
                                                                                                                                                    ];
                                                                                                                                                return false;
                                                                                                                                            }
                                                                                                                                            var valid86 = _errs477 ===
                                                                                                                                                errors;
                                                                                                                                        }
                                                                                                                                        else {
                                                                                                                                            var valid86 = true;
                                                                                                                                        }
                                                                                                                                        if (valid86) {
                                                                                                                                            if (data170.height !==
                                                                                                                                                undefined) {
                                                                                                                                                var data172 = data170.height;
                                                                                                                                                var _errs479 = errors;
                                                                                                                                                if (!(typeof data172 ==
                                                                                                                                                    'number' &&
                                                                                                                                                    isFinite(data172))) {
                                                                                                                                                    validate11.errors =
                                                                                                                                                        [
                                                                                                                                                            {
                                                                                                                                                                instancePath: instancePath +
                                                                                                                                                                    '/track/dimensions/height',
                                                                                                                                                                schemaPath: '#/allOf/15/then/properties/track/properties/dimensions/properties/height/type',
                                                                                                                                                                keyword: 'type',
                                                                                                                                                                params: {
                                                                                                                                                                    type: 'number',
                                                                                                                                                                },
                                                                                                                                                                message: 'must be number',
                                                                                                                                                            },
                                                                                                                                                        ];
                                                                                                                                                    return false;
                                                                                                                                                }
                                                                                                                                                var valid86 = _errs479 ===
                                                                                                                                                    errors;
                                                                                                                                            }
                                                                                                                                            else {
                                                                                                                                                var valid86 = true;
                                                                                                                                            }
                                                                                                                                        }
                                                                                                                                    }
                                                                                                                                }
                                                                                                                            }
                                                                                                                            else {
                                                                                                                                validate11.errors =
                                                                                                                                    [
                                                                                                                                        {
                                                                                                                                            instancePath: instancePath +
                                                                                                                                                '/track/dimensions',
                                                                                                                                            schemaPath: '#/allOf/15/then/properties/track/properties/dimensions/type',
                                                                                                                                            keyword: 'type',
                                                                                                                                            params: {
                                                                                                                                                type: 'object',
                                                                                                                                            },
                                                                                                                                            message: 'must be object',
                                                                                                                                        },
                                                                                                                                    ];
                                                                                                                                return false;
                                                                                                                            }
                                                                                                                        }
                                                                                                                        var valid84 = _errs474 ===
                                                                                                                            errors;
                                                                                                                    }
                                                                                                                    else {
                                                                                                                        var valid84 = true;
                                                                                                                    }
                                                                                                                }
                                                                                                            }
                                                                                                        }
                                                                                                    }
                                                                                                }
                                                                                            }
                                                                                            else {
                                                                                                validate11.errors = [
                                                                                                    {
                                                                                                        instancePath: instancePath + '/track',
                                                                                                        schemaPath: '#/allOf/15/then/properties/track/type',
                                                                                                        keyword: 'type',
                                                                                                        params: { type: 'object' },
                                                                                                        message: 'must be object',
                                                                                                    },
                                                                                                ];
                                                                                                return false;
                                                                                            }
                                                                                        }
                                                                                        var valid83 = _errs464 === errors;
                                                                                    }
                                                                                    else {
                                                                                        var valid83 = true;
                                                                                    }
                                                                                    if (valid83) {
                                                                                        if (data.participant !== undefined) {
                                                                                            var data173 = data.participant;
                                                                                            var _errs481 = errors;
                                                                                            if (errors === _errs481) {
                                                                                                if (data173 &&
                                                                                                    typeof data173 == 'object' &&
                                                                                                    !Array.isArray(data173)) {
                                                                                                    var missing45 = void 0;
                                                                                                    if ((data173.identity ===
                                                                                                        undefined &&
                                                                                                        (missing45 =
                                                                                                            'identity')) ||
                                                                                                        (data173.sid ===
                                                                                                            undefined &&
                                                                                                            (missing45 = 'sid'))) {
                                                                                                        validate11.errors = [
                                                                                                            {
                                                                                                                instancePath: instancePath +
                                                                                                                    '/participant',
                                                                                                                schemaPath: '#/allOf/15/then/properties/participant/required',
                                                                                                                keyword: 'required',
                                                                                                                params: {
                                                                                                                    missingProperty: missing45,
                                                                                                                },
                                                                                                                message: "must have required property '" +
                                                                                                                    missing45 +
                                                                                                                    "'",
                                                                                                            },
                                                                                                        ];
                                                                                                        return false;
                                                                                                    }
                                                                                                    else {
                                                                                                        var _errs483 = errors;
                                                                                                        for (var key45 in data173) {
                                                                                                            if (!(key45 ===
                                                                                                                'identity' ||
                                                                                                                key45 === 'sid' ||
                                                                                                                key45 ===
                                                                                                                    'permissions')) {
                                                                                                                validate11.errors = [
                                                                                                                    {
                                                                                                                        instancePath: instancePath +
                                                                                                                            '/participant',
                                                                                                                        schemaPath: '#/allOf/15/then/properties/participant/additionalProperties',
                                                                                                                        keyword: 'additionalProperties',
                                                                                                                        params: {
                                                                                                                            additionalProperty: key45,
                                                                                                                        },
                                                                                                                        message: 'must NOT have additional properties',
                                                                                                                    },
                                                                                                                ];
                                                                                                                return false;
                                                                                                                break;
                                                                                                            }
                                                                                                        }
                                                                                                        if (_errs483 === errors) {
                                                                                                            if (data173.identity !==
                                                                                                                undefined) {
                                                                                                                var _errs484 = errors;
                                                                                                                if (typeof data173.identity !==
                                                                                                                    'string') {
                                                                                                                    validate11.errors = [
                                                                                                                        {
                                                                                                                            instancePath: instancePath +
                                                                                                                                '/participant/identity',
                                                                                                                            schemaPath: '#/allOf/15/then/properties/participant/properties/identity/type',
                                                                                                                            keyword: 'type',
                                                                                                                            params: {
                                                                                                                                type: 'string',
                                                                                                                            },
                                                                                                                            message: 'must be string',
                                                                                                                        },
                                                                                                                    ];
                                                                                                                    return false;
                                                                                                                }
                                                                                                                var valid87 = _errs484 === errors;
                                                                                                            }
                                                                                                            else {
                                                                                                                var valid87 = true;
                                                                                                            }
                                                                                                            if (valid87) {
                                                                                                                if (data173.sid !==
                                                                                                                    undefined) {
                                                                                                                    var _errs486 = errors;
                                                                                                                    if (typeof data173.sid !==
                                                                                                                        'string') {
                                                                                                                        validate11.errors =
                                                                                                                            [
                                                                                                                                {
                                                                                                                                    instancePath: instancePath +
                                                                                                                                        '/participant/sid',
                                                                                                                                    schemaPath: '#/allOf/15/then/properties/participant/properties/sid/type',
                                                                                                                                    keyword: 'type',
                                                                                                                                    params: {
                                                                                                                                        type: 'string',
                                                                                                                                    },
                                                                                                                                    message: 'must be string',
                                                                                                                                },
                                                                                                                            ];
                                                                                                                        return false;
                                                                                                                    }
                                                                                                                    var valid87 = _errs486 === errors;
                                                                                                                }
                                                                                                                else {
                                                                                                                    var valid87 = true;
                                                                                                                }
                                                                                                                if (valid87) {
                                                                                                                    if (data173.permissions !==
                                                                                                                        undefined) {
                                                                                                                        var data176 = data173.permissions;
                                                                                                                        var _errs488 = errors;
                                                                                                                        if (errors ===
                                                                                                                            _errs488) {
                                                                                                                            if (data176 &&
                                                                                                                                typeof data176 ==
                                                                                                                                    'object' &&
                                                                                                                                !Array.isArray(data176)) {
                                                                                                                                var missing46 = void 0;
                                                                                                                                if ((data176.canSubscribe ===
                                                                                                                                    undefined &&
                                                                                                                                    (missing46 =
                                                                                                                                        'canSubscribe')) ||
                                                                                                                                    (data176.canPublish ===
                                                                                                                                        undefined &&
                                                                                                                                        (missing46 =
                                                                                                                                            'canPublish')) ||
                                                                                                                                    (data176.canPublishData ===
                                                                                                                                        undefined &&
                                                                                                                                        (missing46 =
                                                                                                                                            'canPublishData')) ||
                                                                                                                                    (data176.hidden ===
                                                                                                                                        undefined &&
                                                                                                                                        (missing46 =
                                                                                                                                            'hidden')) ||
                                                                                                                                    (data176.canUpdateMetadata ===
                                                                                                                                        undefined &&
                                                                                                                                        (missing46 =
                                                                                                                                            'canUpdateMetadata')) ||
                                                                                                                                    (data176.canSubscribeMetrics ===
                                                                                                                                        undefined &&
                                                                                                                                        (missing46 =
                                                                                                                                            'canSubscribeMetrics'))) {
                                                                                                                                    validate11.errors =
                                                                                                                                        [
                                                                                                                                            {
                                                                                                                                                instancePath: instancePath +
                                                                                                                                                    '/participant/permissions',
                                                                                                                                                schemaPath: '#/allOf/15/then/properties/participant/properties/permissions/required',
                                                                                                                                                keyword: 'required',
                                                                                                                                                params: {
                                                                                                                                                    missingProperty: missing46,
                                                                                                                                                },
                                                                                                                                                message: "must have required property '" +
                                                                                                                                                    missing46 +
                                                                                                                                                    "'",
                                                                                                                                            },
                                                                                                                                        ];
                                                                                                                                    return false;
                                                                                                                                }
                                                                                                                                else {
                                                                                                                                    var _errs490 = errors;
                                                                                                                                    for (var key46 in data176) {
                                                                                                                                        if (!(key46 ===
                                                                                                                                            'canSubscribe' ||
                                                                                                                                            key46 ===
                                                                                                                                                'canPublish' ||
                                                                                                                                            key46 ===
                                                                                                                                                'canPublishData' ||
                                                                                                                                            key46 ===
                                                                                                                                                'hidden' ||
                                                                                                                                            key46 ===
                                                                                                                                                'canUpdateMetadata' ||
                                                                                                                                            key46 ===
                                                                                                                                                'canSubscribeMetrics')) {
                                                                                                                                            validate11.errors =
                                                                                                                                                [
                                                                                                                                                    {
                                                                                                                                                        instancePath: instancePath +
                                                                                                                                                            '/participant/permissions',
                                                                                                                                                        schemaPath: '#/allOf/15/then/properties/participant/properties/permissions/additionalProperties',
                                                                                                                                                        keyword: 'additionalProperties',
                                                                                                                                                        params: {
                                                                                                                                                            additionalProperty: key46,
                                                                                                                                                        },
                                                                                                                                                        message: 'must NOT have additional properties',
                                                                                                                                                    },
                                                                                                                                                ];
                                                                                                                                            return false;
                                                                                                                                            break;
                                                                                                                                        }
                                                                                                                                    }
                                                                                                                                    if (_errs490 ===
                                                                                                                                        errors) {
                                                                                                                                        if (data176.canSubscribe !==
                                                                                                                                            undefined) {
                                                                                                                                            var _errs491 = errors;
                                                                                                                                            if (typeof data176.canSubscribe !==
                                                                                                                                                'boolean') {
                                                                                                                                                validate11.errors =
                                                                                                                                                    [
                                                                                                                                                        {
                                                                                                                                                            instancePath: instancePath +
                                                                                                                                                                '/participant/permissions/canSubscribe',
                                                                                                                                                            schemaPath: '#/allOf/15/then/properties/participant/properties/permissions/properties/canSubscribe/type',
                                                                                                                                                            keyword: 'type',
                                                                                                                                                            params: {
                                                                                                                                                                type: 'boolean',
                                                                                                                                                            },
                                                                                                                                                            message: 'must be boolean',
                                                                                                                                                        },
                                                                                                                                                    ];
                                                                                                                                                return false;
                                                                                                                                            }
                                                                                                                                            var valid88 = _errs491 ===
                                                                                                                                                errors;
                                                                                                                                        }
                                                                                                                                        else {
                                                                                                                                            var valid88 = true;
                                                                                                                                        }
                                                                                                                                        if (valid88) {
                                                                                                                                            if (data176.canPublish !==
                                                                                                                                                undefined) {
                                                                                                                                                var _errs493 = errors;
                                                                                                                                                if (typeof data176.canPublish !==
                                                                                                                                                    'boolean') {
                                                                                                                                                    validate11.errors =
                                                                                                                                                        [
                                                                                                                                                            {
                                                                                                                                                                instancePath: instancePath +
                                                                                                                                                                    '/participant/permissions/canPublish',
                                                                                                                                                                schemaPath: '#/allOf/15/then/properties/participant/properties/permissions/properties/canPublish/type',
                                                                                                                                                                keyword: 'type',
                                                                                                                                                                params: {
                                                                                                                                                                    type: 'boolean',
                                                                                                                                                                },
                                                                                                                                                                message: 'must be boolean',
                                                                                                                                                            },
                                                                                                                                                        ];
                                                                                                                                                    return false;
                                                                                                                                                }
                                                                                                                                                var valid88 = _errs493 ===
                                                                                                                                                    errors;
                                                                                                                                            }
                                                                                                                                            else {
                                                                                                                                                var valid88 = true;
                                                                                                                                            }
                                                                                                                                            if (valid88) {
                                                                                                                                                if (data176.canPublishData !==
                                                                                                                                                    undefined) {
                                                                                                                                                    var _errs495 = errors;
                                                                                                                                                    if (typeof data176.canPublishData !==
                                                                                                                                                        'boolean') {
                                                                                                                                                        validate11.errors =
                                                                                                                                                            [
                                                                                                                                                                {
                                                                                                                                                                    instancePath: instancePath +
                                                                                                                                                                        '/participant/permissions/canPublishData',
                                                                                                                                                                    schemaPath: '#/allOf/15/then/properties/participant/properties/permissions/properties/canPublishData/type',
                                                                                                                                                                    keyword: 'type',
                                                                                                                                                                    params: {
                                                                                                                                                                        type: 'boolean',
                                                                                                                                                                    },
                                                                                                                                                                    message: 'must be boolean',
                                                                                                                                                                },
                                                                                                                                                            ];
                                                                                                                                                        return false;
                                                                                                                                                    }
                                                                                                                                                    var valid88 = _errs495 ===
                                                                                                                                                        errors;
                                                                                                                                                }
                                                                                                                                                else {
                                                                                                                                                    var valid88 = true;
                                                                                                                                                }
                                                                                                                                                if (valid88) {
                                                                                                                                                    if (data176.hidden !==
                                                                                                                                                        undefined) {
                                                                                                                                                        var _errs497 = errors;
                                                                                                                                                        if (typeof data176.hidden !==
                                                                                                                                                            'boolean') {
                                                                                                                                                            validate11.errors =
                                                                                                                                                                [
                                                                                                                                                                    {
                                                                                                                                                                        instancePath: instancePath +
                                                                                                                                                                            '/participant/permissions/hidden',
                                                                                                                                                                        schemaPath: '#/allOf/15/then/properties/participant/properties/permissions/properties/hidden/type',
                                                                                                                                                                        keyword: 'type',
                                                                                                                                                                        params: {
                                                                                                                                                                            type: 'boolean',
                                                                                                                                                                        },
                                                                                                                                                                        message: 'must be boolean',
                                                                                                                                                                    },
                                                                                                                                                                ];
                                                                                                                                                            return false;
                                                                                                                                                        }
                                                                                                                                                        var valid88 = _errs497 ===
                                                                                                                                                            errors;
                                                                                                                                                    }
                                                                                                                                                    else {
                                                                                                                                                        var valid88 = true;
                                                                                                                                                    }
                                                                                                                                                    if (valid88) {
                                                                                                                                                        if (data176.canUpdateMetadata !==
                                                                                                                                                            undefined) {
                                                                                                                                                            var _errs499 = errors;
                                                                                                                                                            if (typeof data176.canUpdateMetadata !==
                                                                                                                                                                'boolean') {
                                                                                                                                                                validate11.errors =
                                                                                                                                                                    [
                                                                                                                                                                        {
                                                                                                                                                                            instancePath: instancePath +
                                                                                                                                                                                '/participant/permissions/canUpdateMetadata',
                                                                                                                                                                            schemaPath: '#/allOf/15/then/properties/participant/properties/permissions/properties/canUpdateMetadata/type',
                                                                                                                                                                            keyword: 'type',
                                                                                                                                                                            params: {
                                                                                                                                                                                type: 'boolean',
                                                                                                                                                                            },
                                                                                                                                                                            message: 'must be boolean',
                                                                                                                                                                        },
                                                                                                                                                                    ];
                                                                                                                                                                return false;
                                                                                                                                                            }
                                                                                                                                                            var valid88 = _errs499 ===
                                                                                                                                                                errors;
                                                                                                                                                        }
                                                                                                                                                        else {
                                                                                                                                                            var valid88 = true;
                                                                                                                                                        }
                                                                                                                                                        if (valid88) {
                                                                                                                                                            if (data176.canSubscribeMetrics !==
                                                                                                                                                                undefined) {
                                                                                                                                                                var _errs501 = errors;
                                                                                                                                                                if (typeof data176.canSubscribeMetrics !==
                                                                                                                                                                    'boolean') {
                                                                                                                                                                    validate11.errors =
                                                                                                                                                                        [
                                                                                                                                                                            {
                                                                                                                                                                                instancePath: instancePath +
                                                                                                                                                                                    '/participant/permissions/canSubscribeMetrics',
                                                                                                                                                                                schemaPath: '#/allOf/15/then/properties/participant/properties/permissions/properties/canSubscribeMetrics/type',
                                                                                                                                                                                keyword: 'type',
                                                                                                                                                                                params: {
                                                                                                                                                                                    type: 'boolean',
                                                                                                                                                                                },
                                                                                                                                                                                message: 'must be boolean',
                                                                                                                                                                            },
                                                                                                                                                                        ];
                                                                                                                                                                    return false;
                                                                                                                                                                }
                                                                                                                                                                var valid88 = _errs501 ===
                                                                                                                                                                    errors;
                                                                                                                                                            }
                                                                                                                                                            else {
                                                                                                                                                                var valid88 = true;
                                                                                                                                                            }
                                                                                                                                                        }
                                                                                                                                                    }
                                                                                                                                                }
                                                                                                                                            }
                                                                                                                                        }
                                                                                                                                    }
                                                                                                                                }
                                                                                                                            }
                                                                                                                            else {
                                                                                                                                validate11.errors =
                                                                                                                                    [
                                                                                                                                        {
                                                                                                                                            instancePath: instancePath +
                                                                                                                                                '/participant/permissions',
                                                                                                                                            schemaPath: '#/allOf/15/then/properties/participant/properties/permissions/type',
                                                                                                                                            keyword: 'type',
                                                                                                                                            params: {
                                                                                                                                                type: 'object',
                                                                                                                                            },
                                                                                                                                            message: 'must be object',
                                                                                                                                        },
                                                                                                                                    ];
                                                                                                                                return false;
                                                                                                                            }
                                                                                                                        }
                                                                                                                        var valid87 = _errs488 ===
                                                                                                                            errors;
                                                                                                                    }
                                                                                                                    else {
                                                                                                                        var valid87 = true;
                                                                                                                    }
                                                                                                                }
                                                                                                            }
                                                                                                        }
                                                                                                    }
                                                                                                }
                                                                                                else {
                                                                                                    validate11.errors = [
                                                                                                        {
                                                                                                            instancePath: instancePath +
                                                                                                                '/participant',
                                                                                                            schemaPath: '#/allOf/15/then/properties/participant/type',
                                                                                                            keyword: 'type',
                                                                                                            params: {
                                                                                                                type: 'object',
                                                                                                            },
                                                                                                            message: 'must be object',
                                                                                                        },
                                                                                                    ];
                                                                                                    return false;
                                                                                                }
                                                                                            }
                                                                                            var valid83 = _errs481 === errors;
                                                                                        }
                                                                                        else {
                                                                                            var valid83 = true;
                                                                                        }
                                                                                        if (valid83) {
                                                                                            if (data.name !== undefined) {
                                                                                                var data183 = data.name;
                                                                                                var _errs503 = errors;
                                                                                                if (typeof data183 !== 'string') {
                                                                                                    validate11.errors = [
                                                                                                        {
                                                                                                            instancePath: instancePath + '/name',
                                                                                                            schemaPath: '#/allOf/15/then/properties/name/type',
                                                                                                            keyword: 'type',
                                                                                                            params: {
                                                                                                                type: 'string',
                                                                                                            },
                                                                                                            message: 'must be string',
                                                                                                        },
                                                                                                    ];
                                                                                                    return false;
                                                                                                }
                                                                                                if ('trackUnmuted' !== data183) {
                                                                                                    validate11.errors = [
                                                                                                        {
                                                                                                            instancePath: instancePath + '/name',
                                                                                                            schemaPath: '#/allOf/15/then/properties/name/const',
                                                                                                            keyword: 'const',
                                                                                                            params: {
                                                                                                                allowedValue: 'trackUnmuted',
                                                                                                            },
                                                                                                            message: 'must be equal to constant',
                                                                                                        },
                                                                                                    ];
                                                                                                    return false;
                                                                                                }
                                                                                                var valid83 = _errs503 === errors;
                                                                                            }
                                                                                            else {
                                                                                                var valid83 = true;
                                                                                            }
                                                                                            if (valid83) {
                                                                                                if (data.timestamp !== undefined) {
                                                                                                    var data184 = data.timestamp;
                                                                                                    var _errs505 = errors;
                                                                                                    if (!(typeof data184 ==
                                                                                                        'number' &&
                                                                                                        isFinite(data184))) {
                                                                                                        validate11.errors = [
                                                                                                            {
                                                                                                                instancePath: instancePath +
                                                                                                                    '/timestamp',
                                                                                                                schemaPath: '#/allOf/15/then/properties/timestamp/type',
                                                                                                                keyword: 'type',
                                                                                                                params: {
                                                                                                                    type: 'number',
                                                                                                                },
                                                                                                                message: 'must be number',
                                                                                                            },
                                                                                                        ];
                                                                                                        return false;
                                                                                                    }
                                                                                                    var valid83 = _errs505 === errors;
                                                                                                }
                                                                                                else {
                                                                                                    var valid83 = true;
                                                                                                }
                                                                                            }
                                                                                        }
                                                                                    }
                                                                                }
                                                                            }
                                                                        }
                                                                        else {
                                                                            validate11.errors = [
                                                                                {
                                                                                    instancePath: instancePath,
                                                                                    schemaPath: '#/allOf/15/then/type',
                                                                                    keyword: 'type',
                                                                                    params: { type: 'object' },
                                                                                    message: 'must be object',
                                                                                },
                                                                            ];
                                                                            return false;
                                                                        }
                                                                    }
                                                                    var _valid15 = _errs461 === errors;
                                                                    valid81 = _valid15;
                                                                }
                                                                if (!valid81) {
                                                                    var err47 = {
                                                                        instancePath: instancePath,
                                                                        schemaPath: '#/allOf/15/if',
                                                                        keyword: 'if',
                                                                        params: { failingKeyword: 'then' },
                                                                        message: 'must match "then" schema',
                                                                    };
                                                                    if (vErrors === null) {
                                                                        vErrors = [err47];
                                                                    }
                                                                    else {
                                                                        vErrors.push(err47);
                                                                    }
                                                                    errors++;
                                                                    validate11.errors = vErrors;
                                                                    return false;
                                                                }
                                                                var valid0 = _errs456 === errors;
                                                                if (valid0) {
                                                                    var _errs507 = errors;
                                                                    var _errs508 = errors;
                                                                    var valid89 = true;
                                                                    var _errs509 = errors;
                                                                    if (data &&
                                                                        typeof data == 'object' &&
                                                                        !Array.isArray(data)) {
                                                                        if (data.name !== undefined) {
                                                                            var data185 = data.name;
                                                                            if (typeof data185 !== 'string') {
                                                                                var err48 = {};
                                                                                if (vErrors === null) {
                                                                                    vErrors = [err48];
                                                                                }
                                                                                else {
                                                                                    vErrors.push(err48);
                                                                                }
                                                                                errors++;
                                                                            }
                                                                            if ('localTrackPublished' !== data185) {
                                                                                var err49 = {};
                                                                                if (vErrors === null) {
                                                                                    vErrors = [err49];
                                                                                }
                                                                                else {
                                                                                    vErrors.push(err49);
                                                                                }
                                                                                errors++;
                                                                            }
                                                                        }
                                                                    }
                                                                    var _valid16 = _errs509 === errors;
                                                                    errors = _errs508;
                                                                    if (vErrors !== null) {
                                                                        if (_errs508) {
                                                                            vErrors.length = _errs508;
                                                                        }
                                                                        else {
                                                                            vErrors = null;
                                                                        }
                                                                    }
                                                                    if (_valid16) {
                                                                        var _errs512 = errors;
                                                                        if (errors === _errs512) {
                                                                            if (data &&
                                                                                typeof data == 'object' &&
                                                                                !Array.isArray(data)) {
                                                                                var missing47 = void 0;
                                                                                if ((data.name === undefined &&
                                                                                    (missing47 = 'name')) ||
                                                                                    (data.participant === undefined &&
                                                                                        (missing47 = 'participant')) ||
                                                                                    (data.timestamp === undefined &&
                                                                                        (missing47 = 'timestamp')) ||
                                                                                    (data.track === undefined &&
                                                                                        (missing47 = 'track'))) {
                                                                                    validate11.errors = [
                                                                                        {
                                                                                            instancePath: instancePath,
                                                                                            schemaPath: '#/allOf/16/then/required',
                                                                                            keyword: 'required',
                                                                                            params: {
                                                                                                missingProperty: missing47,
                                                                                            },
                                                                                            message: "must have required property '" +
                                                                                                missing47 +
                                                                                                "'",
                                                                                        },
                                                                                    ];
                                                                                    return false;
                                                                                }
                                                                                else {
                                                                                    var _errs514 = errors;
                                                                                    for (var key47 in data) {
                                                                                        if (!(key47 === 'track' ||
                                                                                            key47 === 'participant' ||
                                                                                            key47 === 'name' ||
                                                                                            key47 === 'timestamp')) {
                                                                                            validate11.errors = [
                                                                                                {
                                                                                                    instancePath: instancePath,
                                                                                                    schemaPath: '#/allOf/16/then/additionalProperties',
                                                                                                    keyword: 'additionalProperties',
                                                                                                    params: {
                                                                                                        additionalProperty: key47,
                                                                                                    },
                                                                                                    message: 'must NOT have additional properties',
                                                                                                },
                                                                                            ];
                                                                                            return false;
                                                                                            break;
                                                                                        }
                                                                                    }
                                                                                    if (_errs514 === errors) {
                                                                                        if (data.track !== undefined) {
                                                                                            var data186 = data.track;
                                                                                            var _errs515 = errors;
                                                                                            if (errors === _errs515) {
                                                                                                if (data186 &&
                                                                                                    typeof data186 == 'object' &&
                                                                                                    !Array.isArray(data186)) {
                                                                                                    var missing48 = void 0;
                                                                                                    if ((data186.sid ===
                                                                                                        undefined &&
                                                                                                        (missing48 = 'sid')) ||
                                                                                                        (data186.source ===
                                                                                                            undefined &&
                                                                                                            (missing48 = 'source'))) {
                                                                                                        validate11.errors = [
                                                                                                            {
                                                                                                                instancePath: instancePath +
                                                                                                                    '/track',
                                                                                                                schemaPath: '#/allOf/16/then/properties/track/required',
                                                                                                                keyword: 'required',
                                                                                                                params: {
                                                                                                                    missingProperty: missing48,
                                                                                                                },
                                                                                                                message: "must have required property '" +
                                                                                                                    missing48 +
                                                                                                                    "'",
                                                                                                            },
                                                                                                        ];
                                                                                                        return false;
                                                                                                    }
                                                                                                    else {
                                                                                                        var _errs517 = errors;
                                                                                                        for (var key48 in data186) {
                                                                                                            if (!(key48 === 'sid' ||
                                                                                                                key48 === 'source' ||
                                                                                                                key48 ===
                                                                                                                    'mimeType' ||
                                                                                                                key48 === 'dimensions')) {
                                                                                                                validate11.errors = [
                                                                                                                    {
                                                                                                                        instancePath: instancePath +
                                                                                                                            '/track',
                                                                                                                        schemaPath: '#/allOf/16/then/properties/track/additionalProperties',
                                                                                                                        keyword: 'additionalProperties',
                                                                                                                        params: {
                                                                                                                            additionalProperty: key48,
                                                                                                                        },
                                                                                                                        message: 'must NOT have additional properties',
                                                                                                                    },
                                                                                                                ];
                                                                                                                return false;
                                                                                                                break;
                                                                                                            }
                                                                                                        }
                                                                                                        if (_errs517 === errors) {
                                                                                                            if (data186.sid !==
                                                                                                                undefined) {
                                                                                                                var _errs518 = errors;
                                                                                                                if (typeof data186.sid !==
                                                                                                                    'string') {
                                                                                                                    validate11.errors = [
                                                                                                                        {
                                                                                                                            instancePath: instancePath +
                                                                                                                                '/track/sid',
                                                                                                                            schemaPath: '#/allOf/16/then/properties/track/properties/sid/type',
                                                                                                                            keyword: 'type',
                                                                                                                            params: {
                                                                                                                                type: 'string',
                                                                                                                            },
                                                                                                                            message: 'must be string',
                                                                                                                        },
                                                                                                                    ];
                                                                                                                    return false;
                                                                                                                }
                                                                                                                var valid92 = _errs518 === errors;
                                                                                                            }
                                                                                                            else {
                                                                                                                var valid92 = true;
                                                                                                            }
                                                                                                            if (valid92) {
                                                                                                                if (data186.source !==
                                                                                                                    undefined) {
                                                                                                                    var data188 = data186.source;
                                                                                                                    var _errs520 = errors;
                                                                                                                    if (typeof data188 !==
                                                                                                                        'string') {
                                                                                                                        validate11.errors =
                                                                                                                            [
                                                                                                                                {
                                                                                                                                    instancePath: instancePath +
                                                                                                                                        '/track/source',
                                                                                                                                    schemaPath: '#/definitions/Track.Source/type',
                                                                                                                                    keyword: 'type',
                                                                                                                                    params: {
                                                                                                                                        type: 'string',
                                                                                                                                    },
                                                                                                                                    message: 'must be string',
                                                                                                                                },
                                                                                                                            ];
                                                                                                                        return false;
                                                                                                                    }
                                                                                                                    if (!(data188 ===
                                                                                                                        'camera' ||
                                                                                                                        data188 ===
                                                                                                                            'microphone' ||
                                                                                                                        data188 ===
                                                                                                                            'screen_share' ||
                                                                                                                        data188 ===
                                                                                                                            'screen_share_audio' ||
                                                                                                                        data188 ===
                                                                                                                            'unknown')) {
                                                                                                                        validate11.errors =
                                                                                                                            [
                                                                                                                                {
                                                                                                                                    instancePath: instancePath +
                                                                                                                                        '/track/source',
                                                                                                                                    schemaPath: '#/definitions/Track.Source/enum',
                                                                                                                                    keyword: 'enum',
                                                                                                                                    params: {
                                                                                                                                        allowedValues: schema31.enum,
                                                                                                                                    },
                                                                                                                                    message: 'must be equal to one of the allowed values',
                                                                                                                                },
                                                                                                                            ];
                                                                                                                        return false;
                                                                                                                    }
                                                                                                                    var valid92 = _errs520 === errors;
                                                                                                                }
                                                                                                                else {
                                                                                                                    var valid92 = true;
                                                                                                                }
                                                                                                                if (valid92) {
                                                                                                                    if (data186.mimeType !==
                                                                                                                        undefined) {
                                                                                                                        var _errs523 = errors;
                                                                                                                        if (typeof data186.mimeType !==
                                                                                                                            'string') {
                                                                                                                            validate11.errors =
                                                                                                                                [
                                                                                                                                    {
                                                                                                                                        instancePath: instancePath +
                                                                                                                                            '/track/mimeType',
                                                                                                                                        schemaPath: '#/allOf/16/then/properties/track/properties/mimeType/type',
                                                                                                                                        keyword: 'type',
                                                                                                                                        params: {
                                                                                                                                            type: 'string',
                                                                                                                                        },
                                                                                                                                        message: 'must be string',
                                                                                                                                    },
                                                                                                                                ];
                                                                                                                            return false;
                                                                                                                        }
                                                                                                                        var valid92 = _errs523 ===
                                                                                                                            errors;
                                                                                                                    }
                                                                                                                    else {
                                                                                                                        var valid92 = true;
                                                                                                                    }
                                                                                                                    if (valid92) {
                                                                                                                        if (data186.dimensions !==
                                                                                                                            undefined) {
                                                                                                                            var data190 = data186.dimensions;
                                                                                                                            var _errs525 = errors;
                                                                                                                            if (errors ===
                                                                                                                                _errs525) {
                                                                                                                                if (data190 &&
                                                                                                                                    typeof data190 ==
                                                                                                                                        'object' &&
                                                                                                                                    !Array.isArray(data190)) {
                                                                                                                                    var missing49 = void 0;
                                                                                                                                    if ((data190.width ===
                                                                                                                                        undefined &&
                                                                                                                                        (missing49 =
                                                                                                                                            'width')) ||
                                                                                                                                        (data190.height ===
                                                                                                                                            undefined &&
                                                                                                                                            (missing49 =
                                                                                                                                                'height'))) {
                                                                                                                                        validate11.errors =
                                                                                                                                            [
                                                                                                                                                {
                                                                                                                                                    instancePath: instancePath +
                                                                                                                                                        '/track/dimensions',
                                                                                                                                                    schemaPath: '#/allOf/16/then/properties/track/properties/dimensions/required',
                                                                                                                                                    keyword: 'required',
                                                                                                                                                    params: {
                                                                                                                                                        missingProperty: missing49,
                                                                                                                                                    },
                                                                                                                                                    message: "must have required property '" +
                                                                                                                                                        missing49 +
                                                                                                                                                        "'",
                                                                                                                                                },
                                                                                                                                            ];
                                                                                                                                        return false;
                                                                                                                                    }
                                                                                                                                    else {
                                                                                                                                        var _errs527 = errors;
                                                                                                                                        for (var key49 in data190) {
                                                                                                                                            if (!(key49 ===
                                                                                                                                                'width' ||
                                                                                                                                                key49 ===
                                                                                                                                                    'height')) {
                                                                                                                                                validate11.errors =
                                                                                                                                                    [
                                                                                                                                                        {
                                                                                                                                                            instancePath: instancePath +
                                                                                                                                                                '/track/dimensions',
                                                                                                                                                            schemaPath: '#/allOf/16/then/properties/track/properties/dimensions/additionalProperties',
                                                                                                                                                            keyword: 'additionalProperties',
                                                                                                                                                            params: {
                                                                                                                                                                additionalProperty: key49,
                                                                                                                                                            },
                                                                                                                                                            message: 'must NOT have additional properties',
                                                                                                                                                        },
                                                                                                                                                    ];
                                                                                                                                                return false;
                                                                                                                                                break;
                                                                                                                                            }
                                                                                                                                        }
                                                                                                                                        if (_errs527 ===
                                                                                                                                            errors) {
                                                                                                                                            if (data190.width !==
                                                                                                                                                undefined) {
                                                                                                                                                var data191 = data190.width;
                                                                                                                                                var _errs528 = errors;
                                                                                                                                                if (!(typeof data191 ==
                                                                                                                                                    'number' &&
                                                                                                                                                    isFinite(data191))) {
                                                                                                                                                    validate11.errors =
                                                                                                                                                        [
                                                                                                                                                            {
                                                                                                                                                                instancePath: instancePath +
                                                                                                                                                                    '/track/dimensions/width',
                                                                                                                                                                schemaPath: '#/allOf/16/then/properties/track/properties/dimensions/properties/width/type',
                                                                                                                                                                keyword: 'type',
                                                                                                                                                                params: {
                                                                                                                                                                    type: 'number',
                                                                                                                                                                },
                                                                                                                                                                message: 'must be number',
                                                                                                                                                            },
                                                                                                                                                        ];
                                                                                                                                                    return false;
                                                                                                                                                }
                                                                                                                                                var valid94 = _errs528 ===
                                                                                                                                                    errors;
                                                                                                                                            }
                                                                                                                                            else {
                                                                                                                                                var valid94 = true;
                                                                                                                                            }
                                                                                                                                            if (valid94) {
                                                                                                                                                if (data190.height !==
                                                                                                                                                    undefined) {
                                                                                                                                                    var data192 = data190.height;
                                                                                                                                                    var _errs530 = errors;
                                                                                                                                                    if (!(typeof data192 ==
                                                                                                                                                        'number' &&
                                                                                                                                                        isFinite(data192))) {
                                                                                                                                                        validate11.errors =
                                                                                                                                                            [
                                                                                                                                                                {
                                                                                                                                                                    instancePath: instancePath +
                                                                                                                                                                        '/track/dimensions/height',
                                                                                                                                                                    schemaPath: '#/allOf/16/then/properties/track/properties/dimensions/properties/height/type',
                                                                                                                                                                    keyword: 'type',
                                                                                                                                                                    params: {
                                                                                                                                                                        type: 'number',
                                                                                                                                                                    },
                                                                                                                                                                    message: 'must be number',
                                                                                                                                                                },
                                                                                                                                                            ];
                                                                                                                                                        return false;
                                                                                                                                                    }
                                                                                                                                                    var valid94 = _errs530 ===
                                                                                                                                                        errors;
                                                                                                                                                }
                                                                                                                                                else {
                                                                                                                                                    var valid94 = true;
                                                                                                                                                }
                                                                                                                                            }
                                                                                                                                        }
                                                                                                                                    }
                                                                                                                                }
                                                                                                                                else {
                                                                                                                                    validate11.errors =
                                                                                                                                        [
                                                                                                                                            {
                                                                                                                                                instancePath: instancePath +
                                                                                                                                                    '/track/dimensions',
                                                                                                                                                schemaPath: '#/allOf/16/then/properties/track/properties/dimensions/type',
                                                                                                                                                keyword: 'type',
                                                                                                                                                params: {
                                                                                                                                                    type: 'object',
                                                                                                                                                },
                                                                                                                                                message: 'must be object',
                                                                                                                                            },
                                                                                                                                        ];
                                                                                                                                    return false;
                                                                                                                                }
                                                                                                                            }
                                                                                                                            var valid92 = _errs525 ===
                                                                                                                                errors;
                                                                                                                        }
                                                                                                                        else {
                                                                                                                            var valid92 = true;
                                                                                                                        }
                                                                                                                    }
                                                                                                                }
                                                                                                            }
                                                                                                        }
                                                                                                    }
                                                                                                }
                                                                                                else {
                                                                                                    validate11.errors = [
                                                                                                        {
                                                                                                            instancePath: instancePath + '/track',
                                                                                                            schemaPath: '#/allOf/16/then/properties/track/type',
                                                                                                            keyword: 'type',
                                                                                                            params: {
                                                                                                                type: 'object',
                                                                                                            },
                                                                                                            message: 'must be object',
                                                                                                        },
                                                                                                    ];
                                                                                                    return false;
                                                                                                }
                                                                                            }
                                                                                            var valid91 = _errs515 === errors;
                                                                                        }
                                                                                        else {
                                                                                            var valid91 = true;
                                                                                        }
                                                                                        if (valid91) {
                                                                                            if (data.participant !== undefined) {
                                                                                                var data193 = data.participant;
                                                                                                var _errs532 = errors;
                                                                                                if (errors === _errs532) {
                                                                                                    if (data193 &&
                                                                                                        typeof data193 ==
                                                                                                            'object' &&
                                                                                                        !Array.isArray(data193)) {
                                                                                                        var missing50 = void 0;
                                                                                                        if ((data193.identity ===
                                                                                                            undefined &&
                                                                                                            (missing50 =
                                                                                                                'identity')) ||
                                                                                                            (data193.sid ===
                                                                                                                undefined &&
                                                                                                                (missing50 = 'sid'))) {
                                                                                                            validate11.errors = [
                                                                                                                {
                                                                                                                    instancePath: instancePath +
                                                                                                                        '/participant',
                                                                                                                    schemaPath: '#/allOf/16/then/properties/participant/required',
                                                                                                                    keyword: 'required',
                                                                                                                    params: {
                                                                                                                        missingProperty: missing50,
                                                                                                                    },
                                                                                                                    message: "must have required property '" +
                                                                                                                        missing50 +
                                                                                                                        "'",
                                                                                                                },
                                                                                                            ];
                                                                                                            return false;
                                                                                                        }
                                                                                                        else {
                                                                                                            var _errs534 = errors;
                                                                                                            for (var key50 in data193) {
                                                                                                                if (!(key50 ===
                                                                                                                    'identity' ||
                                                                                                                    key50 === 'sid' ||
                                                                                                                    key50 ===
                                                                                                                        'permissions')) {
                                                                                                                    validate11.errors = [
                                                                                                                        {
                                                                                                                            instancePath: instancePath +
                                                                                                                                '/participant',
                                                                                                                            schemaPath: '#/allOf/16/then/properties/participant/additionalProperties',
                                                                                                                            keyword: 'additionalProperties',
                                                                                                                            params: {
                                                                                                                                additionalProperty: key50,
                                                                                                                            },
                                                                                                                            message: 'must NOT have additional properties',
                                                                                                                        },
                                                                                                                    ];
                                                                                                                    return false;
                                                                                                                    break;
                                                                                                                }
                                                                                                            }
                                                                                                            if (_errs534 === errors) {
                                                                                                                if (data193.identity !==
                                                                                                                    undefined) {
                                                                                                                    var _errs535 = errors;
                                                                                                                    if (typeof data193.identity !==
                                                                                                                        'string') {
                                                                                                                        validate11.errors =
                                                                                                                            [
                                                                                                                                {
                                                                                                                                    instancePath: instancePath +
                                                                                                                                        '/participant/identity',
                                                                                                                                    schemaPath: '#/allOf/16/then/properties/participant/properties/identity/type',
                                                                                                                                    keyword: 'type',
                                                                                                                                    params: {
                                                                                                                                        type: 'string',
                                                                                                                                    },
                                                                                                                                    message: 'must be string',
                                                                                                                                },
                                                                                                                            ];
                                                                                                                        return false;
                                                                                                                    }
                                                                                                                    var valid95 = _errs535 === errors;
                                                                                                                }
                                                                                                                else {
                                                                                                                    var valid95 = true;
                                                                                                                }
                                                                                                                if (valid95) {
                                                                                                                    if (data193.sid !==
                                                                                                                        undefined) {
                                                                                                                        var _errs537 = errors;
                                                                                                                        if (typeof data193.sid !==
                                                                                                                            'string') {
                                                                                                                            validate11.errors =
                                                                                                                                [
                                                                                                                                    {
                                                                                                                                        instancePath: instancePath +
                                                                                                                                            '/participant/sid',
                                                                                                                                        schemaPath: '#/allOf/16/then/properties/participant/properties/sid/type',
                                                                                                                                        keyword: 'type',
                                                                                                                                        params: {
                                                                                                                                            type: 'string',
                                                                                                                                        },
                                                                                                                                        message: 'must be string',
                                                                                                                                    },
                                                                                                                                ];
                                                                                                                            return false;
                                                                                                                        }
                                                                                                                        var valid95 = _errs537 ===
                                                                                                                            errors;
                                                                                                                    }
                                                                                                                    else {
                                                                                                                        var valid95 = true;
                                                                                                                    }
                                                                                                                    if (valid95) {
                                                                                                                        if (data193.permissions !==
                                                                                                                            undefined) {
                                                                                                                            var data196 = data193.permissions;
                                                                                                                            var _errs539 = errors;
                                                                                                                            if (errors ===
                                                                                                                                _errs539) {
                                                                                                                                if (data196 &&
                                                                                                                                    typeof data196 ==
                                                                                                                                        'object' &&
                                                                                                                                    !Array.isArray(data196)) {
                                                                                                                                    var missing51 = void 0;
                                                                                                                                    if ((data196.canSubscribe ===
                                                                                                                                        undefined &&
                                                                                                                                        (missing51 =
                                                                                                                                            'canSubscribe')) ||
                                                                                                                                        (data196.canPublish ===
                                                                                                                                            undefined &&
                                                                                                                                            (missing51 =
                                                                                                                                                'canPublish')) ||
                                                                                                                                        (data196.canPublishData ===
                                                                                                                                            undefined &&
                                                                                                                                            (missing51 =
                                                                                                                                                'canPublishData')) ||
                                                                                                                                        (data196.hidden ===
                                                                                                                                            undefined &&
                                                                                                                                            (missing51 =
                                                                                                                                                'hidden')) ||
                                                                                                                                        (data196.canUpdateMetadata ===
                                                                                                                                            undefined &&
                                                                                                                                            (missing51 =
                                                                                                                                                'canUpdateMetadata')) ||
                                                                                                                                        (data196.canSubscribeMetrics ===
                                                                                                                                            undefined &&
                                                                                                                                            (missing51 =
                                                                                                                                                'canSubscribeMetrics'))) {
                                                                                                                                        validate11.errors =
                                                                                                                                            [
                                                                                                                                                {
                                                                                                                                                    instancePath: instancePath +
                                                                                                                                                        '/participant/permissions',
                                                                                                                                                    schemaPath: '#/allOf/16/then/properties/participant/properties/permissions/required',
                                                                                                                                                    keyword: 'required',
                                                                                                                                                    params: {
                                                                                                                                                        missingProperty: missing51,
                                                                                                                                                    },
                                                                                                                                                    message: "must have required property '" +
                                                                                                                                                        missing51 +
                                                                                                                                                        "'",
                                                                                                                                                },
                                                                                                                                            ];
                                                                                                                                        return false;
                                                                                                                                    }
                                                                                                                                    else {
                                                                                                                                        var _errs541 = errors;
                                                                                                                                        for (var key51 in data196) {
                                                                                                                                            if (!(key51 ===
                                                                                                                                                'canSubscribe' ||
                                                                                                                                                key51 ===
                                                                                                                                                    'canPublish' ||
                                                                                                                                                key51 ===
                                                                                                                                                    'canPublishData' ||
                                                                                                                                                key51 ===
                                                                                                                                                    'hidden' ||
                                                                                                                                                key51 ===
                                                                                                                                                    'canUpdateMetadata' ||
                                                                                                                                                key51 ===
                                                                                                                                                    'canSubscribeMetrics')) {
                                                                                                                                                validate11.errors =
                                                                                                                                                    [
                                                                                                                                                        {
                                                                                                                                                            instancePath: instancePath +
                                                                                                                                                                '/participant/permissions',
                                                                                                                                                            schemaPath: '#/allOf/16/then/properties/participant/properties/permissions/additionalProperties',
                                                                                                                                                            keyword: 'additionalProperties',
                                                                                                                                                            params: {
                                                                                                                                                                additionalProperty: key51,
                                                                                                                                                            },
                                                                                                                                                            message: 'must NOT have additional properties',
                                                                                                                                                        },
                                                                                                                                                    ];
                                                                                                                                                return false;
                                                                                                                                                break;
                                                                                                                                            }
                                                                                                                                        }
                                                                                                                                        if (_errs541 ===
                                                                                                                                            errors) {
                                                                                                                                            if (data196.canSubscribe !==
                                                                                                                                                undefined) {
                                                                                                                                                var _errs542 = errors;
                                                                                                                                                if (typeof data196.canSubscribe !==
                                                                                                                                                    'boolean') {
                                                                                                                                                    validate11.errors =
                                                                                                                                                        [
                                                                                                                                                            {
                                                                                                                                                                instancePath: instancePath +
                                                                                                                                                                    '/participant/permissions/canSubscribe',
                                                                                                                                                                schemaPath: '#/allOf/16/then/properties/participant/properties/permissions/properties/canSubscribe/type',
                                                                                                                                                                keyword: 'type',
                                                                                                                                                                params: {
                                                                                                                                                                    type: 'boolean',
                                                                                                                                                                },
                                                                                                                                                                message: 'must be boolean',
                                                                                                                                                            },
                                                                                                                                                        ];
                                                                                                                                                    return false;
                                                                                                                                                }
                                                                                                                                                var valid96 = _errs542 ===
                                                                                                                                                    errors;
                                                                                                                                            }
                                                                                                                                            else {
                                                                                                                                                var valid96 = true;
                                                                                                                                            }
                                                                                                                                            if (valid96) {
                                                                                                                                                if (data196.canPublish !==
                                                                                                                                                    undefined) {
                                                                                                                                                    var _errs544 = errors;
                                                                                                                                                    if (typeof data196.canPublish !==
                                                                                                                                                        'boolean') {
                                                                                                                                                        validate11.errors =
                                                                                                                                                            [
                                                                                                                                                                {
                                                                                                                                                                    instancePath: instancePath +
                                                                                                                                                                        '/participant/permissions/canPublish',
                                                                                                                                                                    schemaPath: '#/allOf/16/then/properties/participant/properties/permissions/properties/canPublish/type',
                                                                                                                                                                    keyword: 'type',
                                                                                                                                                                    params: {
                                                                                                                                                                        type: 'boolean',
                                                                                                                                                                    },
                                                                                                                                                                    message: 'must be boolean',
                                                                                                                                                                },
                                                                                                                                                            ];
                                                                                                                                                        return false;
                                                                                                                                                    }
                                                                                                                                                    var valid96 = _errs544 ===
                                                                                                                                                        errors;
                                                                                                                                                }
                                                                                                                                                else {
                                                                                                                                                    var valid96 = true;
                                                                                                                                                }
                                                                                                                                                if (valid96) {
                                                                                                                                                    if (data196.canPublishData !==
                                                                                                                                                        undefined) {
                                                                                                                                                        var _errs546 = errors;
                                                                                                                                                        if (typeof data196.canPublishData !==
                                                                                                                                                            'boolean') {
                                                                                                                                                            validate11.errors =
                                                                                                                                                                [
                                                                                                                                                                    {
                                                                                                                                                                        instancePath: instancePath +
                                                                                                                                                                            '/participant/permissions/canPublishData',
                                                                                                                                                                        schemaPath: '#/allOf/16/then/properties/participant/properties/permissions/properties/canPublishData/type',
                                                                                                                                                                        keyword: 'type',
                                                                                                                                                                        params: {
                                                                                                                                                                            type: 'boolean',
                                                                                                                                                                        },
                                                                                                                                                                        message: 'must be boolean',
                                                                                                                                                                    },
                                                                                                                                                                ];
                                                                                                                                                            return false;
                                                                                                                                                        }
                                                                                                                                                        var valid96 = _errs546 ===
                                                                                                                                                            errors;
                                                                                                                                                    }
                                                                                                                                                    else {
                                                                                                                                                        var valid96 = true;
                                                                                                                                                    }
                                                                                                                                                    if (valid96) {
                                                                                                                                                        if (data196.hidden !==
                                                                                                                                                            undefined) {
                                                                                                                                                            var _errs548 = errors;
                                                                                                                                                            if (typeof data196.hidden !==
                                                                                                                                                                'boolean') {
                                                                                                                                                                validate11.errors =
                                                                                                                                                                    [
                                                                                                                                                                        {
                                                                                                                                                                            instancePath: instancePath +
                                                                                                                                                                                '/participant/permissions/hidden',
                                                                                                                                                                            schemaPath: '#/allOf/16/then/properties/participant/properties/permissions/properties/hidden/type',
                                                                                                                                                                            keyword: 'type',
                                                                                                                                                                            params: {
                                                                                                                                                                                type: 'boolean',
                                                                                                                                                                            },
                                                                                                                                                                            message: 'must be boolean',
                                                                                                                                                                        },
                                                                                                                                                                    ];
                                                                                                                                                                return false;
                                                                                                                                                            }
                                                                                                                                                            var valid96 = _errs548 ===
                                                                                                                                                                errors;
                                                                                                                                                        }
                                                                                                                                                        else {
                                                                                                                                                            var valid96 = true;
                                                                                                                                                        }
                                                                                                                                                        if (valid96) {
                                                                                                                                                            if (data196.canUpdateMetadata !==
                                                                                                                                                                undefined) {
                                                                                                                                                                var _errs550 = errors;
                                                                                                                                                                if (typeof data196.canUpdateMetadata !==
                                                                                                                                                                    'boolean') {
                                                                                                                                                                    validate11.errors =
                                                                                                                                                                        [
                                                                                                                                                                            {
                                                                                                                                                                                instancePath: instancePath +
                                                                                                                                                                                    '/participant/permissions/canUpdateMetadata',
                                                                                                                                                                                schemaPath: '#/allOf/16/then/properties/participant/properties/permissions/properties/canUpdateMetadata/type',
                                                                                                                                                                                keyword: 'type',
                                                                                                                                                                                params: {
                                                                                                                                                                                    type: 'boolean',
                                                                                                                                                                                },
                                                                                                                                                                                message: 'must be boolean',
                                                                                                                                                                            },
                                                                                                                                                                        ];
                                                                                                                                                                    return false;
                                                                                                                                                                }
                                                                                                                                                                var valid96 = _errs550 ===
                                                                                                                                                                    errors;
                                                                                                                                                            }
                                                                                                                                                            else {
                                                                                                                                                                var valid96 = true;
                                                                                                                                                            }
                                                                                                                                                            if (valid96) {
                                                                                                                                                                if (data196.canSubscribeMetrics !==
                                                                                                                                                                    undefined) {
                                                                                                                                                                    var _errs552 = errors;
                                                                                                                                                                    if (typeof data196.canSubscribeMetrics !==
                                                                                                                                                                        'boolean') {
                                                                                                                                                                        validate11.errors =
                                                                                                                                                                            [
                                                                                                                                                                                {
                                                                                                                                                                                    instancePath: instancePath +
                                                                                                                                                                                        '/participant/permissions/canSubscribeMetrics',
                                                                                                                                                                                    schemaPath: '#/allOf/16/then/properties/participant/properties/permissions/properties/canSubscribeMetrics/type',
                                                                                                                                                                                    keyword: 'type',
                                                                                                                                                                                    params: {
                                                                                                                                                                                        type: 'boolean',
                                                                                                                                                                                    },
                                                                                                                                                                                    message: 'must be boolean',
                                                                                                                                                                                },
                                                                                                                                                                            ];
                                                                                                                                                                        return false;
                                                                                                                                                                    }
                                                                                                                                                                    var valid96 = _errs552 ===
                                                                                                                                                                        errors;
                                                                                                                                                                }
                                                                                                                                                                else {
                                                                                                                                                                    var valid96 = true;
                                                                                                                                                                }
                                                                                                                                                            }
                                                                                                                                                        }
                                                                                                                                                    }
                                                                                                                                                }
                                                                                                                                            }
                                                                                                                                        }
                                                                                                                                    }
                                                                                                                                }
                                                                                                                                else {
                                                                                                                                    validate11.errors =
                                                                                                                                        [
                                                                                                                                            {
                                                                                                                                                instancePath: instancePath +
                                                                                                                                                    '/participant/permissions',
                                                                                                                                                schemaPath: '#/allOf/16/then/properties/participant/properties/permissions/type',
                                                                                                                                                keyword: 'type',
                                                                                                                                                params: {
                                                                                                                                                    type: 'object',
                                                                                                                                                },
                                                                                                                                                message: 'must be object',
                                                                                                                                            },
                                                                                                                                        ];
                                                                                                                                    return false;
                                                                                                                                }
                                                                                                                            }
                                                                                                                            var valid95 = _errs539 ===
                                                                                                                                errors;
                                                                                                                        }
                                                                                                                        else {
                                                                                                                            var valid95 = true;
                                                                                                                        }
                                                                                                                    }
                                                                                                                }
                                                                                                            }
                                                                                                        }
                                                                                                    }
                                                                                                    else {
                                                                                                        validate11.errors = [
                                                                                                            {
                                                                                                                instancePath: instancePath +
                                                                                                                    '/participant',
                                                                                                                schemaPath: '#/allOf/16/then/properties/participant/type',
                                                                                                                keyword: 'type',
                                                                                                                params: {
                                                                                                                    type: 'object',
                                                                                                                },
                                                                                                                message: 'must be object',
                                                                                                            },
                                                                                                        ];
                                                                                                        return false;
                                                                                                    }
                                                                                                }
                                                                                                var valid91 = _errs532 === errors;
                                                                                            }
                                                                                            else {
                                                                                                var valid91 = true;
                                                                                            }
                                                                                            if (valid91) {
                                                                                                if (data.name !== undefined) {
                                                                                                    var data203 = data.name;
                                                                                                    var _errs554 = errors;
                                                                                                    if (typeof data203 !== 'string') {
                                                                                                        validate11.errors = [
                                                                                                            {
                                                                                                                instancePath: instancePath +
                                                                                                                    '/name',
                                                                                                                schemaPath: '#/allOf/16/then/properties/name/type',
                                                                                                                keyword: 'type',
                                                                                                                params: {
                                                                                                                    type: 'string',
                                                                                                                },
                                                                                                                message: 'must be string',
                                                                                                            },
                                                                                                        ];
                                                                                                        return false;
                                                                                                    }
                                                                                                    if ('localTrackPublished' !==
                                                                                                        data203) {
                                                                                                        validate11.errors = [
                                                                                                            {
                                                                                                                instancePath: instancePath +
                                                                                                                    '/name',
                                                                                                                schemaPath: '#/allOf/16/then/properties/name/const',
                                                                                                                keyword: 'const',
                                                                                                                params: {
                                                                                                                    allowedValue: 'localTrackPublished',
                                                                                                                },
                                                                                                                message: 'must be equal to constant',
                                                                                                            },
                                                                                                        ];
                                                                                                        return false;
                                                                                                    }
                                                                                                    var valid91 = _errs554 === errors;
                                                                                                }
                                                                                                else {
                                                                                                    var valid91 = true;
                                                                                                }
                                                                                                if (valid91) {
                                                                                                    if (data.timestamp !== undefined) {
                                                                                                        var data204 = data.timestamp;
                                                                                                        var _errs556 = errors;
                                                                                                        if (!(typeof data204 ==
                                                                                                            'number' &&
                                                                                                            isFinite(data204))) {
                                                                                                            validate11.errors = [
                                                                                                                {
                                                                                                                    instancePath: instancePath +
                                                                                                                        '/timestamp',
                                                                                                                    schemaPath: '#/allOf/16/then/properties/timestamp/type',
                                                                                                                    keyword: 'type',
                                                                                                                    params: {
                                                                                                                        type: 'number',
                                                                                                                    },
                                                                                                                    message: 'must be number',
                                                                                                                },
                                                                                                            ];
                                                                                                            return false;
                                                                                                        }
                                                                                                        var valid91 = _errs556 === errors;
                                                                                                    }
                                                                                                    else {
                                                                                                        var valid91 = true;
                                                                                                    }
                                                                                                }
                                                                                            }
                                                                                        }
                                                                                    }
                                                                                }
                                                                            }
                                                                            else {
                                                                                validate11.errors = [
                                                                                    {
                                                                                        instancePath: instancePath,
                                                                                        schemaPath: '#/allOf/16/then/type',
                                                                                        keyword: 'type',
                                                                                        params: { type: 'object' },
                                                                                        message: 'must be object',
                                                                                    },
                                                                                ];
                                                                                return false;
                                                                            }
                                                                        }
                                                                        var _valid16 = _errs512 === errors;
                                                                        valid89 = _valid16;
                                                                    }
                                                                    if (!valid89) {
                                                                        var err50 = {
                                                                            instancePath: instancePath,
                                                                            schemaPath: '#/allOf/16/if',
                                                                            keyword: 'if',
                                                                            params: { failingKeyword: 'then' },
                                                                            message: 'must match "then" schema',
                                                                        };
                                                                        if (vErrors === null) {
                                                                            vErrors = [err50];
                                                                        }
                                                                        else {
                                                                            vErrors.push(err50);
                                                                        }
                                                                        errors++;
                                                                        validate11.errors = vErrors;
                                                                        return false;
                                                                    }
                                                                    var valid0 = _errs507 === errors;
                                                                    if (valid0) {
                                                                        var _errs558 = errors;
                                                                        var _errs559 = errors;
                                                                        var valid97 = true;
                                                                        var _errs560 = errors;
                                                                        if (data &&
                                                                            typeof data == 'object' &&
                                                                            !Array.isArray(data)) {
                                                                            if (data.name !== undefined) {
                                                                                var data205 = data.name;
                                                                                if (typeof data205 !== 'string') {
                                                                                    var err51 = {};
                                                                                    if (vErrors === null) {
                                                                                        vErrors = [err51];
                                                                                    }
                                                                                    else {
                                                                                        vErrors.push(err51);
                                                                                    }
                                                                                    errors++;
                                                                                }
                                                                                if ('localTrackUnpublished' !== data205) {
                                                                                    var err52 = {};
                                                                                    if (vErrors === null) {
                                                                                        vErrors = [err52];
                                                                                    }
                                                                                    else {
                                                                                        vErrors.push(err52);
                                                                                    }
                                                                                    errors++;
                                                                                }
                                                                            }
                                                                        }
                                                                        var _valid17 = _errs560 === errors;
                                                                        errors = _errs559;
                                                                        if (vErrors !== null) {
                                                                            if (_errs559) {
                                                                                vErrors.length = _errs559;
                                                                            }
                                                                            else {
                                                                                vErrors = null;
                                                                            }
                                                                        }
                                                                        if (_valid17) {
                                                                            var _errs563 = errors;
                                                                            if (errors === _errs563) {
                                                                                if (data &&
                                                                                    typeof data == 'object' &&
                                                                                    !Array.isArray(data)) {
                                                                                    var missing52 = void 0;
                                                                                    if ((data.name === undefined &&
                                                                                        (missing52 = 'name')) ||
                                                                                        (data.participant === undefined &&
                                                                                            (missing52 = 'participant')) ||
                                                                                        (data.timestamp === undefined &&
                                                                                            (missing52 = 'timestamp')) ||
                                                                                        (data.track === undefined &&
                                                                                            (missing52 = 'track'))) {
                                                                                        validate11.errors = [
                                                                                            {
                                                                                                instancePath: instancePath,
                                                                                                schemaPath: '#/allOf/17/then/required',
                                                                                                keyword: 'required',
                                                                                                params: {
                                                                                                    missingProperty: missing52,
                                                                                                },
                                                                                                message: "must have required property '" +
                                                                                                    missing52 +
                                                                                                    "'",
                                                                                            },
                                                                                        ];
                                                                                        return false;
                                                                                    }
                                                                                    else {
                                                                                        var _errs565 = errors;
                                                                                        for (var key52 in data) {
                                                                                            if (!(key52 === 'track' ||
                                                                                                key52 === 'participant' ||
                                                                                                key52 === 'name' ||
                                                                                                key52 === 'timestamp')) {
                                                                                                validate11.errors = [
                                                                                                    {
                                                                                                        instancePath: instancePath,
                                                                                                        schemaPath: '#/allOf/17/then/additionalProperties',
                                                                                                        keyword: 'additionalProperties',
                                                                                                        params: {
                                                                                                            additionalProperty: key52,
                                                                                                        },
                                                                                                        message: 'must NOT have additional properties',
                                                                                                    },
                                                                                                ];
                                                                                                return false;
                                                                                                break;
                                                                                            }
                                                                                        }
                                                                                        if (_errs565 === errors) {
                                                                                            if (data.track !== undefined) {
                                                                                                var data206 = data.track;
                                                                                                var _errs566 = errors;
                                                                                                if (errors === _errs566) {
                                                                                                    if (data206 &&
                                                                                                        typeof data206 ==
                                                                                                            'object' &&
                                                                                                        !Array.isArray(data206)) {
                                                                                                        var missing53 = void 0;
                                                                                                        if ((data206.sid ===
                                                                                                            undefined &&
                                                                                                            (missing53 = 'sid')) ||
                                                                                                            (data206.source ===
                                                                                                                undefined &&
                                                                                                                (missing53 = 'source'))) {
                                                                                                            validate11.errors = [
                                                                                                                {
                                                                                                                    instancePath: instancePath +
                                                                                                                        '/track',
                                                                                                                    schemaPath: '#/allOf/17/then/properties/track/required',
                                                                                                                    keyword: 'required',
                                                                                                                    params: {
                                                                                                                        missingProperty: missing53,
                                                                                                                    },
                                                                                                                    message: "must have required property '" +
                                                                                                                        missing53 +
                                                                                                                        "'",
                                                                                                                },
                                                                                                            ];
                                                                                                            return false;
                                                                                                        }
                                                                                                        else {
                                                                                                            var _errs568 = errors;
                                                                                                            for (var key53 in data206) {
                                                                                                                if (!(key53 === 'sid' ||
                                                                                                                    key53 ===
                                                                                                                        'source' ||
                                                                                                                    key53 ===
                                                                                                                        'mimeType' ||
                                                                                                                    key53 ===
                                                                                                                        'dimensions')) {
                                                                                                                    validate11.errors = [
                                                                                                                        {
                                                                                                                            instancePath: instancePath +
                                                                                                                                '/track',
                                                                                                                            schemaPath: '#/allOf/17/then/properties/track/additionalProperties',
                                                                                                                            keyword: 'additionalProperties',
                                                                                                                            params: {
                                                                                                                                additionalProperty: key53,
                                                                                                                            },
                                                                                                                            message: 'must NOT have additional properties',
                                                                                                                        },
                                                                                                                    ];
                                                                                                                    return false;
                                                                                                                    break;
                                                                                                                }
                                                                                                            }
                                                                                                            if (_errs568 === errors) {
                                                                                                                if (data206.sid !==
                                                                                                                    undefined) {
                                                                                                                    var _errs569 = errors;
                                                                                                                    if (typeof data206.sid !==
                                                                                                                        'string') {
                                                                                                                        validate11.errors =
                                                                                                                            [
                                                                                                                                {
                                                                                                                                    instancePath: instancePath +
                                                                                                                                        '/track/sid',
                                                                                                                                    schemaPath: '#/allOf/17/then/properties/track/properties/sid/type',
                                                                                                                                    keyword: 'type',
                                                                                                                                    params: {
                                                                                                                                        type: 'string',
                                                                                                                                    },
                                                                                                                                    message: 'must be string',
                                                                                                                                },
                                                                                                                            ];
                                                                                                                        return false;
                                                                                                                    }
                                                                                                                    var valid100 = _errs569 === errors;
                                                                                                                }
                                                                                                                else {
                                                                                                                    var valid100 = true;
                                                                                                                }
                                                                                                                if (valid100) {
                                                                                                                    if (data206.source !==
                                                                                                                        undefined) {
                                                                                                                        var data208 = data206.source;
                                                                                                                        var _errs571 = errors;
                                                                                                                        if (typeof data208 !==
                                                                                                                            'string') {
                                                                                                                            validate11.errors =
                                                                                                                                [
                                                                                                                                    {
                                                                                                                                        instancePath: instancePath +
                                                                                                                                            '/track/source',
                                                                                                                                        schemaPath: '#/definitions/Track.Source/type',
                                                                                                                                        keyword: 'type',
                                                                                                                                        params: {
                                                                                                                                            type: 'string',
                                                                                                                                        },
                                                                                                                                        message: 'must be string',
                                                                                                                                    },
                                                                                                                                ];
                                                                                                                            return false;
                                                                                                                        }
                                                                                                                        if (!(data208 ===
                                                                                                                            'camera' ||
                                                                                                                            data208 ===
                                                                                                                                'microphone' ||
                                                                                                                            data208 ===
                                                                                                                                'screen_share' ||
                                                                                                                            data208 ===
                                                                                                                                'screen_share_audio' ||
                                                                                                                            data208 ===
                                                                                                                                'unknown')) {
                                                                                                                            validate11.errors =
                                                                                                                                [
                                                                                                                                    {
                                                                                                                                        instancePath: instancePath +
                                                                                                                                            '/track/source',
                                                                                                                                        schemaPath: '#/definitions/Track.Source/enum',
                                                                                                                                        keyword: 'enum',
                                                                                                                                        params: {
                                                                                                                                            allowedValues: schema31.enum,
                                                                                                                                        },
                                                                                                                                        message: 'must be equal to one of the allowed values',
                                                                                                                                    },
                                                                                                                                ];
                                                                                                                            return false;
                                                                                                                        }
                                                                                                                        var valid100 = _errs571 ===
                                                                                                                            errors;
                                                                                                                    }
                                                                                                                    else {
                                                                                                                        var valid100 = true;
                                                                                                                    }
                                                                                                                    if (valid100) {
                                                                                                                        if (data206.mimeType !==
                                                                                                                            undefined) {
                                                                                                                            var _errs574 = errors;
                                                                                                                            if (typeof data206.mimeType !==
                                                                                                                                'string') {
                                                                                                                                validate11.errors =
                                                                                                                                    [
                                                                                                                                        {
                                                                                                                                            instancePath: instancePath +
                                                                                                                                                '/track/mimeType',
                                                                                                                                            schemaPath: '#/allOf/17/then/properties/track/properties/mimeType/type',
                                                                                                                                            keyword: 'type',
                                                                                                                                            params: {
                                                                                                                                                type: 'string',
                                                                                                                                            },
                                                                                                                                            message: 'must be string',
                                                                                                                                        },
                                                                                                                                    ];
                                                                                                                                return false;
                                                                                                                            }
                                                                                                                            var valid100 = _errs574 ===
                                                                                                                                errors;
                                                                                                                        }
                                                                                                                        else {
                                                                                                                            var valid100 = true;
                                                                                                                        }
                                                                                                                        if (valid100) {
                                                                                                                            if (data206.dimensions !==
                                                                                                                                undefined) {
                                                                                                                                var data210 = data206.dimensions;
                                                                                                                                var _errs576 = errors;
                                                                                                                                if (errors ===
                                                                                                                                    _errs576) {
                                                                                                                                    if (data210 &&
                                                                                                                                        typeof data210 ==
                                                                                                                                            'object' &&
                                                                                                                                        !Array.isArray(data210)) {
                                                                                                                                        var missing54 = void 0;
                                                                                                                                        if ((data210.width ===
                                                                                                                                            undefined &&
                                                                                                                                            (missing54 =
                                                                                                                                                'width')) ||
                                                                                                                                            (data210.height ===
                                                                                                                                                undefined &&
                                                                                                                                                (missing54 =
                                                                                                                                                    'height'))) {
                                                                                                                                            validate11.errors =
                                                                                                                                                [
                                                                                                                                                    {
                                                                                                                                                        instancePath: instancePath +
                                                                                                                                                            '/track/dimensions',
                                                                                                                                                        schemaPath: '#/allOf/17/then/properties/track/properties/dimensions/required',
                                                                                                                                                        keyword: 'required',
                                                                                                                                                        params: {
                                                                                                                                                            missingProperty: missing54,
                                                                                                                                                        },
                                                                                                                                                        message: "must have required property '" +
                                                                                                                                                            missing54 +
                                                                                                                                                            "'",
                                                                                                                                                    },
                                                                                                                                                ];
                                                                                                                                            return false;
                                                                                                                                        }
                                                                                                                                        else {
                                                                                                                                            var _errs578 = errors;
                                                                                                                                            for (var key54 in data210) {
                                                                                                                                                if (!(key54 ===
                                                                                                                                                    'width' ||
                                                                                                                                                    key54 ===
                                                                                                                                                        'height')) {
                                                                                                                                                    validate11.errors =
                                                                                                                                                        [
                                                                                                                                                            {
                                                                                                                                                                instancePath: instancePath +
                                                                                                                                                                    '/track/dimensions',
                                                                                                                                                                schemaPath: '#/allOf/17/then/properties/track/properties/dimensions/additionalProperties',
                                                                                                                                                                keyword: 'additionalProperties',
                                                                                                                                                                params: {
                                                                                                                                                                    additionalProperty: key54,
                                                                                                                                                                },
                                                                                                                                                                message: 'must NOT have additional properties',
                                                                                                                                                            },
                                                                                                                                                        ];
                                                                                                                                                    return false;
                                                                                                                                                    break;
                                                                                                                                                }
                                                                                                                                            }
                                                                                                                                            if (_errs578 ===
                                                                                                                                                errors) {
                                                                                                                                                if (data210.width !==
                                                                                                                                                    undefined) {
                                                                                                                                                    var data211 = data210.width;
                                                                                                                                                    var _errs579 = errors;
                                                                                                                                                    if (!(typeof data211 ==
                                                                                                                                                        'number' &&
                                                                                                                                                        isFinite(data211))) {
                                                                                                                                                        validate11.errors =
                                                                                                                                                            [
                                                                                                                                                                {
                                                                                                                                                                    instancePath: instancePath +
                                                                                                                                                                        '/track/dimensions/width',
                                                                                                                                                                    schemaPath: '#/allOf/17/then/properties/track/properties/dimensions/properties/width/type',
                                                                                                                                                                    keyword: 'type',
                                                                                                                                                                    params: {
                                                                                                                                                                        type: 'number',
                                                                                                                                                                    },
                                                                                                                                                                    message: 'must be number',
                                                                                                                                                                },
                                                                                                                                                            ];
                                                                                                                                                        return false;
                                                                                                                                                    }
                                                                                                                                                    var valid102 = _errs579 ===
                                                                                                                                                        errors;
                                                                                                                                                }
                                                                                                                                                else {
                                                                                                                                                    var valid102 = true;
                                                                                                                                                }
                                                                                                                                                if (valid102) {
                                                                                                                                                    if (data210.height !==
                                                                                                                                                        undefined) {
                                                                                                                                                        var data212 = data210.height;
                                                                                                                                                        var _errs581 = errors;
                                                                                                                                                        if (!(typeof data212 ==
                                                                                                                                                            'number' &&
                                                                                                                                                            isFinite(data212))) {
                                                                                                                                                            validate11.errors =
                                                                                                                                                                [
                                                                                                                                                                    {
                                                                                                                                                                        instancePath: instancePath +
                                                                                                                                                                            '/track/dimensions/height',
                                                                                                                                                                        schemaPath: '#/allOf/17/then/properties/track/properties/dimensions/properties/height/type',
                                                                                                                                                                        keyword: 'type',
                                                                                                                                                                        params: {
                                                                                                                                                                            type: 'number',
                                                                                                                                                                        },
                                                                                                                                                                        message: 'must be number',
                                                                                                                                                                    },
                                                                                                                                                                ];
                                                                                                                                                            return false;
                                                                                                                                                        }
                                                                                                                                                        var valid102 = _errs581 ===
                                                                                                                                                            errors;
                                                                                                                                                    }
                                                                                                                                                    else {
                                                                                                                                                        var valid102 = true;
                                                                                                                                                    }
                                                                                                                                                }
                                                                                                                                            }
                                                                                                                                        }
                                                                                                                                    }
                                                                                                                                    else {
                                                                                                                                        validate11.errors =
                                                                                                                                            [
                                                                                                                                                {
                                                                                                                                                    instancePath: instancePath +
                                                                                                                                                        '/track/dimensions',
                                                                                                                                                    schemaPath: '#/allOf/17/then/properties/track/properties/dimensions/type',
                                                                                                                                                    keyword: 'type',
                                                                                                                                                    params: {
                                                                                                                                                        type: 'object',
                                                                                                                                                    },
                                                                                                                                                    message: 'must be object',
                                                                                                                                                },
                                                                                                                                            ];
                                                                                                                                        return false;
                                                                                                                                    }
                                                                                                                                }
                                                                                                                                var valid100 = _errs576 ===
                                                                                                                                    errors;
                                                                                                                            }
                                                                                                                            else {
                                                                                                                                var valid100 = true;
                                                                                                                            }
                                                                                                                        }
                                                                                                                    }
                                                                                                                }
                                                                                                            }
                                                                                                        }
                                                                                                    }
                                                                                                    else {
                                                                                                        validate11.errors = [
                                                                                                            {
                                                                                                                instancePath: instancePath +
                                                                                                                    '/track',
                                                                                                                schemaPath: '#/allOf/17/then/properties/track/type',
                                                                                                                keyword: 'type',
                                                                                                                params: {
                                                                                                                    type: 'object',
                                                                                                                },
                                                                                                                message: 'must be object',
                                                                                                            },
                                                                                                        ];
                                                                                                        return false;
                                                                                                    }
                                                                                                }
                                                                                                var valid99 = _errs566 === errors;
                                                                                            }
                                                                                            else {
                                                                                                var valid99 = true;
                                                                                            }
                                                                                            if (valid99) {
                                                                                                if (data.participant !== undefined) {
                                                                                                    var data213 = data.participant;
                                                                                                    var _errs583 = errors;
                                                                                                    if (errors === _errs583) {
                                                                                                        if (data213 &&
                                                                                                            typeof data213 ==
                                                                                                                'object' &&
                                                                                                            !Array.isArray(data213)) {
                                                                                                            var missing55 = void 0;
                                                                                                            if ((data213.identity ===
                                                                                                                undefined &&
                                                                                                                (missing55 =
                                                                                                                    'identity')) ||
                                                                                                                (data213.sid ===
                                                                                                                    undefined &&
                                                                                                                    (missing55 = 'sid'))) {
                                                                                                                validate11.errors = [
                                                                                                                    {
                                                                                                                        instancePath: instancePath +
                                                                                                                            '/participant',
                                                                                                                        schemaPath: '#/allOf/17/then/properties/participant/required',
                                                                                                                        keyword: 'required',
                                                                                                                        params: {
                                                                                                                            missingProperty: missing55,
                                                                                                                        },
                                                                                                                        message: "must have required property '" +
                                                                                                                            missing55 +
                                                                                                                            "'",
                                                                                                                    },
                                                                                                                ];
                                                                                                                return false;
                                                                                                            }
                                                                                                            else {
                                                                                                                var _errs585 = errors;
                                                                                                                for (var key55 in data213) {
                                                                                                                    if (!(key55 ===
                                                                                                                        'identity' ||
                                                                                                                        key55 === 'sid' ||
                                                                                                                        key55 ===
                                                                                                                            'permissions')) {
                                                                                                                        validate11.errors =
                                                                                                                            [
                                                                                                                                {
                                                                                                                                    instancePath: instancePath +
                                                                                                                                        '/participant',
                                                                                                                                    schemaPath: '#/allOf/17/then/properties/participant/additionalProperties',
                                                                                                                                    keyword: 'additionalProperties',
                                                                                                                                    params: {
                                                                                                                                        additionalProperty: key55,
                                                                                                                                    },
                                                                                                                                    message: 'must NOT have additional properties',
                                                                                                                                },
                                                                                                                            ];
                                                                                                                        return false;
                                                                                                                        break;
                                                                                                                    }
                                                                                                                }
                                                                                                                if (_errs585 === errors) {
                                                                                                                    if (data213.identity !==
                                                                                                                        undefined) {
                                                                                                                        var _errs586 = errors;
                                                                                                                        if (typeof data213.identity !==
                                                                                                                            'string') {
                                                                                                                            validate11.errors =
                                                                                                                                [
                                                                                                                                    {
                                                                                                                                        instancePath: instancePath +
                                                                                                                                            '/participant/identity',
                                                                                                                                        schemaPath: '#/allOf/17/then/properties/participant/properties/identity/type',
                                                                                                                                        keyword: 'type',
                                                                                                                                        params: {
                                                                                                                                            type: 'string',
                                                                                                                                        },
                                                                                                                                        message: 'must be string',
                                                                                                                                    },
                                                                                                                                ];
                                                                                                                            return false;
                                                                                                                        }
                                                                                                                        var valid103 = _errs586 ===
                                                                                                                            errors;
                                                                                                                    }
                                                                                                                    else {
                                                                                                                        var valid103 = true;
                                                                                                                    }
                                                                                                                    if (valid103) {
                                                                                                                        if (data213.sid !==
                                                                                                                            undefined) {
                                                                                                                            var _errs588 = errors;
                                                                                                                            if (typeof data213.sid !==
                                                                                                                                'string') {
                                                                                                                                validate11.errors =
                                                                                                                                    [
                                                                                                                                        {
                                                                                                                                            instancePath: instancePath +
                                                                                                                                                '/participant/sid',
                                                                                                                                            schemaPath: '#/allOf/17/then/properties/participant/properties/sid/type',
                                                                                                                                            keyword: 'type',
                                                                                                                                            params: {
                                                                                                                                                type: 'string',
                                                                                                                                            },
                                                                                                                                            message: 'must be string',
                                                                                                                                        },
                                                                                                                                    ];
                                                                                                                                return false;
                                                                                                                            }
                                                                                                                            var valid103 = _errs588 ===
                                                                                                                                errors;
                                                                                                                        }
                                                                                                                        else {
                                                                                                                            var valid103 = true;
                                                                                                                        }
                                                                                                                        if (valid103) {
                                                                                                                            if (data213.permissions !==
                                                                                                                                undefined) {
                                                                                                                                var data216 = data213.permissions;
                                                                                                                                var _errs590 = errors;
                                                                                                                                if (errors ===
                                                                                                                                    _errs590) {
                                                                                                                                    if (data216 &&
                                                                                                                                        typeof data216 ==
                                                                                                                                            'object' &&
                                                                                                                                        !Array.isArray(data216)) {
                                                                                                                                        var missing56 = void 0;
                                                                                                                                        if ((data216.canSubscribe ===
                                                                                                                                            undefined &&
                                                                                                                                            (missing56 =
                                                                                                                                                'canSubscribe')) ||
                                                                                                                                            (data216.canPublish ===
                                                                                                                                                undefined &&
                                                                                                                                                (missing56 =
                                                                                                                                                    'canPublish')) ||
                                                                                                                                            (data216.canPublishData ===
                                                                                                                                                undefined &&
                                                                                                                                                (missing56 =
                                                                                                                                                    'canPublishData')) ||
                                                                                                                                            (data216.hidden ===
                                                                                                                                                undefined &&
                                                                                                                                                (missing56 =
                                                                                                                                                    'hidden')) ||
                                                                                                                                            (data216.canUpdateMetadata ===
                                                                                                                                                undefined &&
                                                                                                                                                (missing56 =
                                                                                                                                                    'canUpdateMetadata')) ||
                                                                                                                                            (data216.canSubscribeMetrics ===
                                                                                                                                                undefined &&
                                                                                                                                                (missing56 =
                                                                                                                                                    'canSubscribeMetrics'))) {
                                                                                                                                            validate11.errors =
                                                                                                                                                [
                                                                                                                                                    {
                                                                                                                                                        instancePath: instancePath +
                                                                                                                                                            '/participant/permissions',
                                                                                                                                                        schemaPath: '#/allOf/17/then/properties/participant/properties/permissions/required',
                                                                                                                                                        keyword: 'required',
                                                                                                                                                        params: {
                                                                                                                                                            missingProperty: missing56,
                                                                                                                                                        },
                                                                                                                                                        message: "must have required property '" +
                                                                                                                                                            missing56 +
                                                                                                                                                            "'",
                                                                                                                                                    },
                                                                                                                                                ];
                                                                                                                                            return false;
                                                                                                                                        }
                                                                                                                                        else {
                                                                                                                                            var _errs592 = errors;
                                                                                                                                            for (var key56 in data216) {
                                                                                                                                                if (!(key56 ===
                                                                                                                                                    'canSubscribe' ||
                                                                                                                                                    key56 ===
                                                                                                                                                        'canPublish' ||
                                                                                                                                                    key56 ===
                                                                                                                                                        'canPublishData' ||
                                                                                                                                                    key56 ===
                                                                                                                                                        'hidden' ||
                                                                                                                                                    key56 ===
                                                                                                                                                        'canUpdateMetadata' ||
                                                                                                                                                    key56 ===
                                                                                                                                                        'canSubscribeMetrics')) {
                                                                                                                                                    validate11.errors =
                                                                                                                                                        [
                                                                                                                                                            {
                                                                                                                                                                instancePath: instancePath +
                                                                                                                                                                    '/participant/permissions',
                                                                                                                                                                schemaPath: '#/allOf/17/then/properties/participant/properties/permissions/additionalProperties',
                                                                                                                                                                keyword: 'additionalProperties',
                                                                                                                                                                params: {
                                                                                                                                                                    additionalProperty: key56,
                                                                                                                                                                },
                                                                                                                                                                message: 'must NOT have additional properties',
                                                                                                                                                            },
                                                                                                                                                        ];
                                                                                                                                                    return false;
                                                                                                                                                    break;
                                                                                                                                                }
                                                                                                                                            }
                                                                                                                                            if (_errs592 ===
                                                                                                                                                errors) {
                                                                                                                                                if (data216.canSubscribe !==
                                                                                                                                                    undefined) {
                                                                                                                                                    var _errs593 = errors;
                                                                                                                                                    if (typeof data216.canSubscribe !==
                                                                                                                                                        'boolean') {
                                                                                                                                                        validate11.errors =
                                                                                                                                                            [
                                                                                                                                                                {
                                                                                                                                                                    instancePath: instancePath +
                                                                                                                                                                        '/participant/permissions/canSubscribe',
                                                                                                                                                                    schemaPath: '#/allOf/17/then/properties/participant/properties/permissions/properties/canSubscribe/type',
                                                                                                                                                                    keyword: 'type',
                                                                                                                                                                    params: {
                                                                                                                                                                        type: 'boolean',
                                                                                                                                                                    },
                                                                                                                                                                    message: 'must be boolean',
                                                                                                                                                                },
                                                                                                                                                            ];
                                                                                                                                                        return false;
                                                                                                                                                    }
                                                                                                                                                    var valid104 = _errs593 ===
                                                                                                                                                        errors;
                                                                                                                                                }
                                                                                                                                                else {
                                                                                                                                                    var valid104 = true;
                                                                                                                                                }
                                                                                                                                                if (valid104) {
                                                                                                                                                    if (data216.canPublish !==
                                                                                                                                                        undefined) {
                                                                                                                                                        var _errs595 = errors;
                                                                                                                                                        if (typeof data216.canPublish !==
                                                                                                                                                            'boolean') {
                                                                                                                                                            validate11.errors =
                                                                                                                                                                [
                                                                                                                                                                    {
                                                                                                                                                                        instancePath: instancePath +
                                                                                                                                                                            '/participant/permissions/canPublish',
                                                                                                                                                                        schemaPath: '#/allOf/17/then/properties/participant/properties/permissions/properties/canPublish/type',
                                                                                                                                                                        keyword: 'type',
                                                                                                                                                                        params: {
                                                                                                                                                                            type: 'boolean',
                                                                                                                                                                        },
                                                                                                                                                                        message: 'must be boolean',
                                                                                                                                                                    },
                                                                                                                                                                ];
                                                                                                                                                            return false;
                                                                                                                                                        }
                                                                                                                                                        var valid104 = _errs595 ===
                                                                                                                                                            errors;
                                                                                                                                                    }
                                                                                                                                                    else {
                                                                                                                                                        var valid104 = true;
                                                                                                                                                    }
                                                                                                                                                    if (valid104) {
                                                                                                                                                        if (data216.canPublishData !==
                                                                                                                                                            undefined) {
                                                                                                                                                            var _errs597 = errors;
                                                                                                                                                            if (typeof data216.canPublishData !==
                                                                                                                                                                'boolean') {
                                                                                                                                                                validate11.errors =
                                                                                                                                                                    [
                                                                                                                                                                        {
                                                                                                                                                                            instancePath: instancePath +
                                                                                                                                                                                '/participant/permissions/canPublishData',
                                                                                                                                                                            schemaPath: '#/allOf/17/then/properties/participant/properties/permissions/properties/canPublishData/type',
                                                                                                                                                                            keyword: 'type',
                                                                                                                                                                            params: {
                                                                                                                                                                                type: 'boolean',
                                                                                                                                                                            },
                                                                                                                                                                            message: 'must be boolean',
                                                                                                                                                                        },
                                                                                                                                                                    ];
                                                                                                                                                                return false;
                                                                                                                                                            }
                                                                                                                                                            var valid104 = _errs597 ===
                                                                                                                                                                errors;
                                                                                                                                                        }
                                                                                                                                                        else {
                                                                                                                                                            var valid104 = true;
                                                                                                                                                        }
                                                                                                                                                        if (valid104) {
                                                                                                                                                            if (data216.hidden !==
                                                                                                                                                                undefined) {
                                                                                                                                                                var _errs599 = errors;
                                                                                                                                                                if (typeof data216.hidden !==
                                                                                                                                                                    'boolean') {
                                                                                                                                                                    validate11.errors =
                                                                                                                                                                        [
                                                                                                                                                                            {
                                                                                                                                                                                instancePath: instancePath +
                                                                                                                                                                                    '/participant/permissions/hidden',
                                                                                                                                                                                schemaPath: '#/allOf/17/then/properties/participant/properties/permissions/properties/hidden/type',
                                                                                                                                                                                keyword: 'type',
                                                                                                                                                                                params: {
                                                                                                                                                                                    type: 'boolean',
                                                                                                                                                                                },
                                                                                                                                                                                message: 'must be boolean',
                                                                                                                                                                            },
                                                                                                                                                                        ];
                                                                                                                                                                    return false;
                                                                                                                                                                }
                                                                                                                                                                var valid104 = _errs599 ===
                                                                                                                                                                    errors;
                                                                                                                                                            }
                                                                                                                                                            else {
                                                                                                                                                                var valid104 = true;
                                                                                                                                                            }
                                                                                                                                                            if (valid104) {
                                                                                                                                                                if (data216.canUpdateMetadata !==
                                                                                                                                                                    undefined) {
                                                                                                                                                                    var _errs601 = errors;
                                                                                                                                                                    if (typeof data216.canUpdateMetadata !==
                                                                                                                                                                        'boolean') {
                                                                                                                                                                        validate11.errors =
                                                                                                                                                                            [
                                                                                                                                                                                {
                                                                                                                                                                                    instancePath: instancePath +
                                                                                                                                                                                        '/participant/permissions/canUpdateMetadata',
                                                                                                                                                                                    schemaPath: '#/allOf/17/then/properties/participant/properties/permissions/properties/canUpdateMetadata/type',
                                                                                                                                                                                    keyword: 'type',
                                                                                                                                                                                    params: {
                                                                                                                                                                                        type: 'boolean',
                                                                                                                                                                                    },
                                                                                                                                                                                    message: 'must be boolean',
                                                                                                                                                                                },
                                                                                                                                                                            ];
                                                                                                                                                                        return false;
                                                                                                                                                                    }
                                                                                                                                                                    var valid104 = _errs601 ===
                                                                                                                                                                        errors;
                                                                                                                                                                }
                                                                                                                                                                else {
                                                                                                                                                                    var valid104 = true;
                                                                                                                                                                }
                                                                                                                                                                if (valid104) {
                                                                                                                                                                    if (data216.canSubscribeMetrics !==
                                                                                                                                                                        undefined) {
                                                                                                                                                                        var _errs603 = errors;
                                                                                                                                                                        if (typeof data216.canSubscribeMetrics !==
                                                                                                                                                                            'boolean') {
                                                                                                                                                                            validate11.errors =
                                                                                                                                                                                [
                                                                                                                                                                                    {
                                                                                                                                                                                        instancePath: instancePath +
                                                                                                                                                                                            '/participant/permissions/canSubscribeMetrics',
                                                                                                                                                                                        schemaPath: '#/allOf/17/then/properties/participant/properties/permissions/properties/canSubscribeMetrics/type',
                                                                                                                                                                                        keyword: 'type',
                                                                                                                                                                                        params: {
                                                                                                                                                                                            type: 'boolean',
                                                                                                                                                                                        },
                                                                                                                                                                                        message: 'must be boolean',
                                                                                                                                                                                    },
                                                                                                                                                                                ];
                                                                                                                                                                            return false;
                                                                                                                                                                        }
                                                                                                                                                                        var valid104 = _errs603 ===
                                                                                                                                                                            errors;
                                                                                                                                                                    }
                                                                                                                                                                    else {
                                                                                                                                                                        var valid104 = true;
                                                                                                                                                                    }
                                                                                                                                                                }
                                                                                                                                                            }
                                                                                                                                                        }
                                                                                                                                                    }
                                                                                                                                                }
                                                                                                                                            }
                                                                                                                                        }
                                                                                                                                    }
                                                                                                                                    else {
                                                                                                                                        validate11.errors =
                                                                                                                                            [
                                                                                                                                                {
                                                                                                                                                    instancePath: instancePath +
                                                                                                                                                        '/participant/permissions',
                                                                                                                                                    schemaPath: '#/allOf/17/then/properties/participant/properties/permissions/type',
                                                                                                                                                    keyword: 'type',
                                                                                                                                                    params: {
                                                                                                                                                        type: 'object',
                                                                                                                                                    },
                                                                                                                                                    message: 'must be object',
                                                                                                                                                },
                                                                                                                                            ];
                                                                                                                                        return false;
                                                                                                                                    }
                                                                                                                                }
                                                                                                                                var valid103 = _errs590 ===
                                                                                                                                    errors;
                                                                                                                            }
                                                                                                                            else {
                                                                                                                                var valid103 = true;
                                                                                                                            }
                                                                                                                        }
                                                                                                                    }
                                                                                                                }
                                                                                                            }
                                                                                                        }
                                                                                                        else {
                                                                                                            validate11.errors = [
                                                                                                                {
                                                                                                                    instancePath: instancePath +
                                                                                                                        '/participant',
                                                                                                                    schemaPath: '#/allOf/17/then/properties/participant/type',
                                                                                                                    keyword: 'type',
                                                                                                                    params: {
                                                                                                                        type: 'object',
                                                                                                                    },
                                                                                                                    message: 'must be object',
                                                                                                                },
                                                                                                            ];
                                                                                                            return false;
                                                                                                        }
                                                                                                    }
                                                                                                    var valid99 = _errs583 === errors;
                                                                                                }
                                                                                                else {
                                                                                                    var valid99 = true;
                                                                                                }
                                                                                                if (valid99) {
                                                                                                    if (data.name !== undefined) {
                                                                                                        var data223 = data.name;
                                                                                                        var _errs605 = errors;
                                                                                                        if (typeof data223 !==
                                                                                                            'string') {
                                                                                                            validate11.errors = [
                                                                                                                {
                                                                                                                    instancePath: instancePath +
                                                                                                                        '/name',
                                                                                                                    schemaPath: '#/allOf/17/then/properties/name/type',
                                                                                                                    keyword: 'type',
                                                                                                                    params: {
                                                                                                                        type: 'string',
                                                                                                                    },
                                                                                                                    message: 'must be string',
                                                                                                                },
                                                                                                            ];
                                                                                                            return false;
                                                                                                        }
                                                                                                        if ('localTrackUnpublished' !==
                                                                                                            data223) {
                                                                                                            validate11.errors = [
                                                                                                                {
                                                                                                                    instancePath: instancePath +
                                                                                                                        '/name',
                                                                                                                    schemaPath: '#/allOf/17/then/properties/name/const',
                                                                                                                    keyword: 'const',
                                                                                                                    params: {
                                                                                                                        allowedValue: 'localTrackUnpublished',
                                                                                                                    },
                                                                                                                    message: 'must be equal to constant',
                                                                                                                },
                                                                                                            ];
                                                                                                            return false;
                                                                                                        }
                                                                                                        var valid99 = _errs605 === errors;
                                                                                                    }
                                                                                                    else {
                                                                                                        var valid99 = true;
                                                                                                    }
                                                                                                    if (valid99) {
                                                                                                        if (data.timestamp !==
                                                                                                            undefined) {
                                                                                                            var data224 = data.timestamp;
                                                                                                            var _errs607 = errors;
                                                                                                            if (!(typeof data224 ==
                                                                                                                'number' &&
                                                                                                                isFinite(data224))) {
                                                                                                                validate11.errors = [
                                                                                                                    {
                                                                                                                        instancePath: instancePath +
                                                                                                                            '/timestamp',
                                                                                                                        schemaPath: '#/allOf/17/then/properties/timestamp/type',
                                                                                                                        keyword: 'type',
                                                                                                                        params: {
                                                                                                                            type: 'number',
                                                                                                                        },
                                                                                                                        message: 'must be number',
                                                                                                                    },
                                                                                                                ];
                                                                                                                return false;
                                                                                                            }
                                                                                                            var valid99 = _errs607 === errors;
                                                                                                        }
                                                                                                        else {
                                                                                                            var valid99 = true;
                                                                                                        }
                                                                                                    }
                                                                                                }
                                                                                            }
                                                                                        }
                                                                                    }
                                                                                }
                                                                                else {
                                                                                    validate11.errors = [
                                                                                        {
                                                                                            instancePath: instancePath,
                                                                                            schemaPath: '#/allOf/17/then/type',
                                                                                            keyword: 'type',
                                                                                            params: { type: 'object' },
                                                                                            message: 'must be object',
                                                                                        },
                                                                                    ];
                                                                                    return false;
                                                                                }
                                                                            }
                                                                            var _valid17 = _errs563 === errors;
                                                                            valid97 = _valid17;
                                                                        }
                                                                        if (!valid97) {
                                                                            var err53 = {
                                                                                instancePath: instancePath,
                                                                                schemaPath: '#/allOf/17/if',
                                                                                keyword: 'if',
                                                                                params: { failingKeyword: 'then' },
                                                                                message: 'must match "then" schema',
                                                                            };
                                                                            if (vErrors === null) {
                                                                                vErrors = [err53];
                                                                            }
                                                                            else {
                                                                                vErrors.push(err53);
                                                                            }
                                                                            errors++;
                                                                            validate11.errors = vErrors;
                                                                            return false;
                                                                        }
                                                                        var valid0 = _errs558 === errors;
                                                                        if (valid0) {
                                                                            var _errs609 = errors;
                                                                            var _errs610 = errors;
                                                                            var valid105 = true;
                                                                            var _errs611 = errors;
                                                                            if (data &&
                                                                                typeof data == 'object' &&
                                                                                !Array.isArray(data)) {
                                                                                if (data.name !== undefined) {
                                                                                    var data225 = data.name;
                                                                                    if (typeof data225 !== 'string') {
                                                                                        var err54 = {};
                                                                                        if (vErrors === null) {
                                                                                            vErrors = [err54];
                                                                                        }
                                                                                        else {
                                                                                            vErrors.push(err54);
                                                                                        }
                                                                                        errors++;
                                                                                    }
                                                                                    if ('localAudioSilenceDetected' !==
                                                                                        data225) {
                                                                                        var err55 = {};
                                                                                        if (vErrors === null) {
                                                                                            vErrors = [err55];
                                                                                        }
                                                                                        else {
                                                                                            vErrors.push(err55);
                                                                                        }
                                                                                        errors++;
                                                                                    }
                                                                                }
                                                                            }
                                                                            var _valid18 = _errs611 === errors;
                                                                            errors = _errs610;
                                                                            if (vErrors !== null) {
                                                                                if (_errs610) {
                                                                                    vErrors.length = _errs610;
                                                                                }
                                                                                else {
                                                                                    vErrors = null;
                                                                                }
                                                                            }
                                                                            if (_valid18) {
                                                                                var _errs614 = errors;
                                                                                if (errors === _errs614) {
                                                                                    if (data &&
                                                                                        typeof data == 'object' &&
                                                                                        !Array.isArray(data)) {
                                                                                        var missing57 = void 0;
                                                                                        if ((data.name === undefined &&
                                                                                            (missing57 = 'name')) ||
                                                                                            (data.timestamp === undefined &&
                                                                                                (missing57 = 'timestamp')) ||
                                                                                            (data.track === undefined &&
                                                                                                (missing57 = 'track'))) {
                                                                                            validate11.errors = [
                                                                                                {
                                                                                                    instancePath: instancePath,
                                                                                                    schemaPath: '#/allOf/18/then/required',
                                                                                                    keyword: 'required',
                                                                                                    params: {
                                                                                                        missingProperty: missing57,
                                                                                                    },
                                                                                                    message: "must have required property '" +
                                                                                                        missing57 +
                                                                                                        "'",
                                                                                                },
                                                                                            ];
                                                                                            return false;
                                                                                        }
                                                                                        else {
                                                                                            var _errs616 = errors;
                                                                                            for (var key57 in data) {
                                                                                                if (!(key57 === 'track' ||
                                                                                                    key57 === 'name' ||
                                                                                                    key57 === 'timestamp')) {
                                                                                                    validate11.errors = [
                                                                                                        {
                                                                                                            instancePath: instancePath,
                                                                                                            schemaPath: '#/allOf/18/then/additionalProperties',
                                                                                                            keyword: 'additionalProperties',
                                                                                                            params: {
                                                                                                                additionalProperty: key57,
                                                                                                            },
                                                                                                            message: 'must NOT have additional properties',
                                                                                                        },
                                                                                                    ];
                                                                                                    return false;
                                                                                                    break;
                                                                                                }
                                                                                            }
                                                                                            if (_errs616 === errors) {
                                                                                                if (data.track !== undefined) {
                                                                                                    var data226 = data.track;
                                                                                                    var _errs617 = errors;
                                                                                                    if (errors === _errs617) {
                                                                                                        if (data226 &&
                                                                                                            typeof data226 ==
                                                                                                                'object' &&
                                                                                                            !Array.isArray(data226)) {
                                                                                                            var missing58 = void 0;
                                                                                                            if ((data226.sid ===
                                                                                                                undefined &&
                                                                                                                (missing58 =
                                                                                                                    'sid')) ||
                                                                                                                (data226.source ===
                                                                                                                    undefined &&
                                                                                                                    (missing58 =
                                                                                                                        'source'))) {
                                                                                                                validate11.errors = [
                                                                                                                    {
                                                                                                                        instancePath: instancePath +
                                                                                                                            '/track',
                                                                                                                        schemaPath: '#/allOf/18/then/properties/track/required',
                                                                                                                        keyword: 'required',
                                                                                                                        params: {
                                                                                                                            missingProperty: missing58,
                                                                                                                        },
                                                                                                                        message: "must have required property '" +
                                                                                                                            missing58 +
                                                                                                                            "'",
                                                                                                                    },
                                                                                                                ];
                                                                                                                return false;
                                                                                                            }
                                                                                                            else {
                                                                                                                var _errs619 = errors;
                                                                                                                for (var key58 in data226) {
                                                                                                                    if (!(key58 === 'sid' ||
                                                                                                                        key58 ===
                                                                                                                            'source' ||
                                                                                                                        key58 ===
                                                                                                                            'mimeType' ||
                                                                                                                        key58 ===
                                                                                                                            'dimensions')) {
                                                                                                                        validate11.errors =
                                                                                                                            [
                                                                                                                                {
                                                                                                                                    instancePath: instancePath +
                                                                                                                                        '/track',
                                                                                                                                    schemaPath: '#/allOf/18/then/properties/track/additionalProperties',
                                                                                                                                    keyword: 'additionalProperties',
                                                                                                                                    params: {
                                                                                                                                        additionalProperty: key58,
                                                                                                                                    },
                                                                                                                                    message: 'must NOT have additional properties',
                                                                                                                                },
                                                                                                                            ];
                                                                                                                        return false;
                                                                                                                        break;
                                                                                                                    }
                                                                                                                }
                                                                                                                if (_errs619 === errors) {
                                                                                                                    if (data226.sid !==
                                                                                                                        undefined) {
                                                                                                                        var _errs620 = errors;
                                                                                                                        if (typeof data226.sid !==
                                                                                                                            'string') {
                                                                                                                            validate11.errors =
                                                                                                                                [
                                                                                                                                    {
                                                                                                                                        instancePath: instancePath +
                                                                                                                                            '/track/sid',
                                                                                                                                        schemaPath: '#/allOf/18/then/properties/track/properties/sid/type',
                                                                                                                                        keyword: 'type',
                                                                                                                                        params: {
                                                                                                                                            type: 'string',
                                                                                                                                        },
                                                                                                                                        message: 'must be string',
                                                                                                                                    },
                                                                                                                                ];
                                                                                                                            return false;
                                                                                                                        }
                                                                                                                        var valid108 = _errs620 ===
                                                                                                                            errors;
                                                                                                                    }
                                                                                                                    else {
                                                                                                                        var valid108 = true;
                                                                                                                    }
                                                                                                                    if (valid108) {
                                                                                                                        if (data226.source !==
                                                                                                                            undefined) {
                                                                                                                            var data228 = data226.source;
                                                                                                                            var _errs622 = errors;
                                                                                                                            if (typeof data228 !==
                                                                                                                                'string') {
                                                                                                                                validate11.errors =
                                                                                                                                    [
                                                                                                                                        {
                                                                                                                                            instancePath: instancePath +
                                                                                                                                                '/track/source',
                                                                                                                                            schemaPath: '#/definitions/Track.Source/type',
                                                                                                                                            keyword: 'type',
                                                                                                                                            params: {
                                                                                                                                                type: 'string',
                                                                                                                                            },
                                                                                                                                            message: 'must be string',
                                                                                                                                        },
                                                                                                                                    ];
                                                                                                                                return false;
                                                                                                                            }
                                                                                                                            if (!(data228 ===
                                                                                                                                'camera' ||
                                                                                                                                data228 ===
                                                                                                                                    'microphone' ||
                                                                                                                                data228 ===
                                                                                                                                    'screen_share' ||
                                                                                                                                data228 ===
                                                                                                                                    'screen_share_audio' ||
                                                                                                                                data228 ===
                                                                                                                                    'unknown')) {
                                                                                                                                validate11.errors =
                                                                                                                                    [
                                                                                                                                        {
                                                                                                                                            instancePath: instancePath +
                                                                                                                                                '/track/source',
                                                                                                                                            schemaPath: '#/definitions/Track.Source/enum',
                                                                                                                                            keyword: 'enum',
                                                                                                                                            params: {
                                                                                                                                                allowedValues: schema31.enum,
                                                                                                                                            },
                                                                                                                                            message: 'must be equal to one of the allowed values',
                                                                                                                                        },
                                                                                                                                    ];
                                                                                                                                return false;
                                                                                                                            }
                                                                                                                            var valid108 = _errs622 ===
                                                                                                                                errors;
                                                                                                                        }
                                                                                                                        else {
                                                                                                                            var valid108 = true;
                                                                                                                        }
                                                                                                                        if (valid108) {
                                                                                                                            if (data226.mimeType !==
                                                                                                                                undefined) {
                                                                                                                                var _errs625 = errors;
                                                                                                                                if (typeof data226.mimeType !==
                                                                                                                                    'string') {
                                                                                                                                    validate11.errors =
                                                                                                                                        [
                                                                                                                                            {
                                                                                                                                                instancePath: instancePath +
                                                                                                                                                    '/track/mimeType',
                                                                                                                                                schemaPath: '#/allOf/18/then/properties/track/properties/mimeType/type',
                                                                                                                                                keyword: 'type',
                                                                                                                                                params: {
                                                                                                                                                    type: 'string',
                                                                                                                                                },
                                                                                                                                                message: 'must be string',
                                                                                                                                            },
                                                                                                                                        ];
                                                                                                                                    return false;
                                                                                                                                }
                                                                                                                                var valid108 = _errs625 ===
                                                                                                                                    errors;
                                                                                                                            }
                                                                                                                            else {
                                                                                                                                var valid108 = true;
                                                                                                                            }
                                                                                                                            if (valid108) {
                                                                                                                                if (data226.dimensions !==
                                                                                                                                    undefined) {
                                                                                                                                    var data230 = data226.dimensions;
                                                                                                                                    var _errs627 = errors;
                                                                                                                                    if (errors ===
                                                                                                                                        _errs627) {
                                                                                                                                        if (data230 &&
                                                                                                                                            typeof data230 ==
                                                                                                                                                'object' &&
                                                                                                                                            !Array.isArray(data230)) {
                                                                                                                                            var missing59 = void 0;
                                                                                                                                            if ((data230.width ===
                                                                                                                                                undefined &&
                                                                                                                                                (missing59 =
                                                                                                                                                    'width')) ||
                                                                                                                                                (data230.height ===
                                                                                                                                                    undefined &&
                                                                                                                                                    (missing59 =
                                                                                                                                                        'height'))) {
                                                                                                                                                validate11.errors =
                                                                                                                                                    [
                                                                                                                                                        {
                                                                                                                                                            instancePath: instancePath +
                                                                                                                                                                '/track/dimensions',
                                                                                                                                                            schemaPath: '#/allOf/18/then/properties/track/properties/dimensions/required',
                                                                                                                                                            keyword: 'required',
                                                                                                                                                            params: {
                                                                                                                                                                missingProperty: missing59,
                                                                                                                                                            },
                                                                                                                                                            message: "must have required property '" +
                                                                                                                                                                missing59 +
                                                                                                                                                                "'",
                                                                                                                                                        },
                                                                                                                                                    ];
                                                                                                                                                return false;
                                                                                                                                            }
                                                                                                                                            else {
                                                                                                                                                var _errs629 = errors;
                                                                                                                                                for (var key59 in data230) {
                                                                                                                                                    if (!(key59 ===
                                                                                                                                                        'width' ||
                                                                                                                                                        key59 ===
                                                                                                                                                            'height')) {
                                                                                                                                                        validate11.errors =
                                                                                                                                                            [
                                                                                                                                                                {
                                                                                                                                                                    instancePath: instancePath +
                                                                                                                                                                        '/track/dimensions',
                                                                                                                                                                    schemaPath: '#/allOf/18/then/properties/track/properties/dimensions/additionalProperties',
                                                                                                                                                                    keyword: 'additionalProperties',
                                                                                                                                                                    params: {
                                                                                                                                                                        additionalProperty: key59,
                                                                                                                                                                    },
                                                                                                                                                                    message: 'must NOT have additional properties',
                                                                                                                                                                },
                                                                                                                                                            ];
                                                                                                                                                        return false;
                                                                                                                                                        break;
                                                                                                                                                    }
                                                                                                                                                }
                                                                                                                                                if (_errs629 ===
                                                                                                                                                    errors) {
                                                                                                                                                    if (data230.width !==
                                                                                                                                                        undefined) {
                                                                                                                                                        var data231 = data230.width;
                                                                                                                                                        var _errs630 = errors;
                                                                                                                                                        if (!(typeof data231 ==
                                                                                                                                                            'number' &&
                                                                                                                                                            isFinite(data231))) {
                                                                                                                                                            validate11.errors =
                                                                                                                                                                [
                                                                                                                                                                    {
                                                                                                                                                                        instancePath: instancePath +
                                                                                                                                                                            '/track/dimensions/width',
                                                                                                                                                                        schemaPath: '#/allOf/18/then/properties/track/properties/dimensions/properties/width/type',
                                                                                                                                                                        keyword: 'type',
                                                                                                                                                                        params: {
                                                                                                                                                                            type: 'number',
                                                                                                                                                                        },
                                                                                                                                                                        message: 'must be number',
                                                                                                                                                                    },
                                                                                                                                                                ];
                                                                                                                                                            return false;
                                                                                                                                                        }
                                                                                                                                                        var valid110 = _errs630 ===
                                                                                                                                                            errors;
                                                                                                                                                    }
                                                                                                                                                    else {
                                                                                                                                                        var valid110 = true;
                                                                                                                                                    }
                                                                                                                                                    if (valid110) {
                                                                                                                                                        if (data230.height !==
                                                                                                                                                            undefined) {
                                                                                                                                                            var data232 = data230.height;
                                                                                                                                                            var _errs632 = errors;
                                                                                                                                                            if (!(typeof data232 ==
                                                                                                                                                                'number' &&
                                                                                                                                                                isFinite(data232))) {
                                                                                                                                                                validate11.errors =
                                                                                                                                                                    [
                                                                                                                                                                        {
                                                                                                                                                                            instancePath: instancePath +
                                                                                                                                                                                '/track/dimensions/height',
                                                                                                                                                                            schemaPath: '#/allOf/18/then/properties/track/properties/dimensions/properties/height/type',
                                                                                                                                                                            keyword: 'type',
                                                                                                                                                                            params: {
                                                                                                                                                                                type: 'number',
                                                                                                                                                                            },
                                                                                                                                                                            message: 'must be number',
                                                                                                                                                                        },
                                                                                                                                                                    ];
                                                                                                                                                                return false;
                                                                                                                                                            }
                                                                                                                                                            var valid110 = _errs632 ===
                                                                                                                                                                errors;
                                                                                                                                                        }
                                                                                                                                                        else {
                                                                                                                                                            var valid110 = true;
                                                                                                                                                        }
                                                                                                                                                    }
                                                                                                                                                }
                                                                                                                                            }
                                                                                                                                        }
                                                                                                                                        else {
                                                                                                                                            validate11.errors =
                                                                                                                                                [
                                                                                                                                                    {
                                                                                                                                                        instancePath: instancePath +
                                                                                                                                                            '/track/dimensions',
                                                                                                                                                        schemaPath: '#/allOf/18/then/properties/track/properties/dimensions/type',
                                                                                                                                                        keyword: 'type',
                                                                                                                                                        params: {
                                                                                                                                                            type: 'object',
                                                                                                                                                        },
                                                                                                                                                        message: 'must be object',
                                                                                                                                                    },
                                                                                                                                                ];
                                                                                                                                            return false;
                                                                                                                                        }
                                                                                                                                    }
                                                                                                                                    var valid108 = _errs627 ===
                                                                                                                                        errors;
                                                                                                                                }
                                                                                                                                else {
                                                                                                                                    var valid108 = true;
                                                                                                                                }
                                                                                                                            }
                                                                                                                        }
                                                                                                                    }
                                                                                                                }
                                                                                                            }
                                                                                                        }
                                                                                                        else {
                                                                                                            validate11.errors = [
                                                                                                                {
                                                                                                                    instancePath: instancePath +
                                                                                                                        '/track',
                                                                                                                    schemaPath: '#/allOf/18/then/properties/track/type',
                                                                                                                    keyword: 'type',
                                                                                                                    params: {
                                                                                                                        type: 'object',
                                                                                                                    },
                                                                                                                    message: 'must be object',
                                                                                                                },
                                                                                                            ];
                                                                                                            return false;
                                                                                                        }
                                                                                                    }
                                                                                                    var valid107 = _errs617 === errors;
                                                                                                }
                                                                                                else {
                                                                                                    var valid107 = true;
                                                                                                }
                                                                                                if (valid107) {
                                                                                                    if (data.name !== undefined) {
                                                                                                        var data233 = data.name;
                                                                                                        var _errs634 = errors;
                                                                                                        if (typeof data233 !==
                                                                                                            'string') {
                                                                                                            validate11.errors = [
                                                                                                                {
                                                                                                                    instancePath: instancePath +
                                                                                                                        '/name',
                                                                                                                    schemaPath: '#/allOf/18/then/properties/name/type',
                                                                                                                    keyword: 'type',
                                                                                                                    params: {
                                                                                                                        type: 'string',
                                                                                                                    },
                                                                                                                    message: 'must be string',
                                                                                                                },
                                                                                                            ];
                                                                                                            return false;
                                                                                                        }
                                                                                                        if ('localAudioSilenceDetected' !==
                                                                                                            data233) {
                                                                                                            validate11.errors = [
                                                                                                                {
                                                                                                                    instancePath: instancePath +
                                                                                                                        '/name',
                                                                                                                    schemaPath: '#/allOf/18/then/properties/name/const',
                                                                                                                    keyword: 'const',
                                                                                                                    params: {
                                                                                                                        allowedValue: 'localAudioSilenceDetected',
                                                                                                                    },
                                                                                                                    message: 'must be equal to constant',
                                                                                                                },
                                                                                                            ];
                                                                                                            return false;
                                                                                                        }
                                                                                                        var valid107 = _errs634 === errors;
                                                                                                    }
                                                                                                    else {
                                                                                                        var valid107 = true;
                                                                                                    }
                                                                                                    if (valid107) {
                                                                                                        if (data.timestamp !==
                                                                                                            undefined) {
                                                                                                            var data234 = data.timestamp;
                                                                                                            var _errs636 = errors;
                                                                                                            if (!(typeof data234 ==
                                                                                                                'number' &&
                                                                                                                isFinite(data234))) {
                                                                                                                validate11.errors = [
                                                                                                                    {
                                                                                                                        instancePath: instancePath +
                                                                                                                            '/timestamp',
                                                                                                                        schemaPath: '#/allOf/18/then/properties/timestamp/type',
                                                                                                                        keyword: 'type',
                                                                                                                        params: {
                                                                                                                            type: 'number',
                                                                                                                        },
                                                                                                                        message: 'must be number',
                                                                                                                    },
                                                                                                                ];
                                                                                                                return false;
                                                                                                            }
                                                                                                            var valid107 = _errs636 === errors;
                                                                                                        }
                                                                                                        else {
                                                                                                            var valid107 = true;
                                                                                                        }
                                                                                                    }
                                                                                                }
                                                                                            }
                                                                                        }
                                                                                    }
                                                                                    else {
                                                                                        validate11.errors = [
                                                                                            {
                                                                                                instancePath: instancePath,
                                                                                                schemaPath: '#/allOf/18/then/type',
                                                                                                keyword: 'type',
                                                                                                params: { type: 'object' },
                                                                                                message: 'must be object',
                                                                                            },
                                                                                        ];
                                                                                        return false;
                                                                                    }
                                                                                }
                                                                                var _valid18 = _errs614 === errors;
                                                                                valid105 = _valid18;
                                                                            }
                                                                            if (!valid105) {
                                                                                var err56 = {
                                                                                    instancePath: instancePath,
                                                                                    schemaPath: '#/allOf/18/if',
                                                                                    keyword: 'if',
                                                                                    params: { failingKeyword: 'then' },
                                                                                    message: 'must match "then" schema',
                                                                                };
                                                                                if (vErrors === null) {
                                                                                    vErrors = [err56];
                                                                                }
                                                                                else {
                                                                                    vErrors.push(err56);
                                                                                }
                                                                                errors++;
                                                                                validate11.errors = vErrors;
                                                                                return false;
                                                                            }
                                                                            var valid0 = _errs609 === errors;
                                                                            if (valid0) {
                                                                                var _errs638 = errors;
                                                                                var _errs639 = errors;
                                                                                var valid111 = true;
                                                                                var _errs640 = errors;
                                                                                if (data &&
                                                                                    typeof data == 'object' &&
                                                                                    !Array.isArray(data)) {
                                                                                    if (data.name !== undefined) {
                                                                                        var data235 = data.name;
                                                                                        if (typeof data235 !== 'string') {
                                                                                            var err57 = {};
                                                                                            if (vErrors === null) {
                                                                                                vErrors = [err57];
                                                                                            }
                                                                                            else {
                                                                                                vErrors.push(err57);
                                                                                            }
                                                                                            errors++;
                                                                                        }
                                                                                        if ('participantMetadataChanged' !==
                                                                                            data235) {
                                                                                            var err58 = {};
                                                                                            if (vErrors === null) {
                                                                                                vErrors = [err58];
                                                                                            }
                                                                                            else {
                                                                                                vErrors.push(err58);
                                                                                            }
                                                                                            errors++;
                                                                                        }
                                                                                    }
                                                                                }
                                                                                var _valid19 = _errs640 === errors;
                                                                                errors = _errs639;
                                                                                if (vErrors !== null) {
                                                                                    if (_errs639) {
                                                                                        vErrors.length = _errs639;
                                                                                    }
                                                                                    else {
                                                                                        vErrors = null;
                                                                                    }
                                                                                }
                                                                                if (_valid19) {
                                                                                    var _errs643 = errors;
                                                                                    if (errors === _errs643) {
                                                                                        if (data &&
                                                                                            typeof data == 'object' &&
                                                                                            !Array.isArray(data)) {
                                                                                            var missing60 = void 0;
                                                                                            if ((data.name === undefined &&
                                                                                                (missing60 = 'name')) ||
                                                                                                (data.participant ===
                                                                                                    undefined &&
                                                                                                    (missing60 =
                                                                                                        'participant')) ||
                                                                                                (data.timestamp === undefined &&
                                                                                                    (missing60 = 'timestamp'))) {
                                                                                                validate11.errors = [
                                                                                                    {
                                                                                                        instancePath: instancePath,
                                                                                                        schemaPath: '#/allOf/19/then/required',
                                                                                                        keyword: 'required',
                                                                                                        params: {
                                                                                                            missingProperty: missing60,
                                                                                                        },
                                                                                                        message: "must have required property '" +
                                                                                                            missing60 +
                                                                                                            "'",
                                                                                                    },
                                                                                                ];
                                                                                                return false;
                                                                                            }
                                                                                            else {
                                                                                                var _errs645 = errors;
                                                                                                for (var key60 in data) {
                                                                                                    if (!(key60 === 'metadata' ||
                                                                                                        key60 === 'participant' ||
                                                                                                        key60 === 'name' ||
                                                                                                        key60 === 'timestamp')) {
                                                                                                        validate11.errors = [
                                                                                                            {
                                                                                                                instancePath: instancePath,
                                                                                                                schemaPath: '#/allOf/19/then/additionalProperties',
                                                                                                                keyword: 'additionalProperties',
                                                                                                                params: {
                                                                                                                    additionalProperty: key60,
                                                                                                                },
                                                                                                                message: 'must NOT have additional properties',
                                                                                                            },
                                                                                                        ];
                                                                                                        return false;
                                                                                                        break;
                                                                                                    }
                                                                                                }
                                                                                                if (_errs645 === errors) {
                                                                                                    if (data.metadata !== undefined) {
                                                                                                        var _errs646 = errors;
                                                                                                        if (typeof data.metadata !==
                                                                                                            'string') {
                                                                                                            validate11.errors = [
                                                                                                                {
                                                                                                                    instancePath: instancePath +
                                                                                                                        '/metadata',
                                                                                                                    schemaPath: '#/allOf/19/then/properties/metadata/type',
                                                                                                                    keyword: 'type',
                                                                                                                    params: {
                                                                                                                        type: 'string',
                                                                                                                    },
                                                                                                                    message: 'must be string',
                                                                                                                },
                                                                                                            ];
                                                                                                            return false;
                                                                                                        }
                                                                                                        var valid113 = _errs646 === errors;
                                                                                                    }
                                                                                                    else {
                                                                                                        var valid113 = true;
                                                                                                    }
                                                                                                    if (valid113) {
                                                                                                        if (data.participant !==
                                                                                                            undefined) {
                                                                                                            var data237 = data.participant;
                                                                                                            var _errs648 = errors;
                                                                                                            if (errors === _errs648) {
                                                                                                                if (data237 &&
                                                                                                                    typeof data237 ==
                                                                                                                        'object' &&
                                                                                                                    !Array.isArray(data237)) {
                                                                                                                    var missing61 = void 0;
                                                                                                                    if ((data237.identity ===
                                                                                                                        undefined &&
                                                                                                                        (missing61 =
                                                                                                                            'identity')) ||
                                                                                                                        (data237.sid ===
                                                                                                                            undefined &&
                                                                                                                            (missing61 =
                                                                                                                                'sid'))) {
                                                                                                                        validate11.errors =
                                                                                                                            [
                                                                                                                                {
                                                                                                                                    instancePath: instancePath +
                                                                                                                                        '/participant',
                                                                                                                                    schemaPath: '#/allOf/19/then/properties/participant/required',
                                                                                                                                    keyword: 'required',
                                                                                                                                    params: {
                                                                                                                                        missingProperty: missing61,
                                                                                                                                    },
                                                                                                                                    message: "must have required property '" +
                                                                                                                                        missing61 +
                                                                                                                                        "'",
                                                                                                                                },
                                                                                                                            ];
                                                                                                                        return false;
                                                                                                                    }
                                                                                                                    else {
                                                                                                                        var _errs650 = errors;
                                                                                                                        for (var key61 in data237) {
                                                                                                                            if (!(key61 ===
                                                                                                                                'identity' ||
                                                                                                                                key61 ===
                                                                                                                                    'sid' ||
                                                                                                                                key61 ===
                                                                                                                                    'permissions')) {
                                                                                                                                validate11.errors =
                                                                                                                                    [
                                                                                                                                        {
                                                                                                                                            instancePath: instancePath +
                                                                                                                                                '/participant',
                                                                                                                                            schemaPath: '#/allOf/19/then/properties/participant/additionalProperties',
                                                                                                                                            keyword: 'additionalProperties',
                                                                                                                                            params: {
                                                                                                                                                additionalProperty: key61,
                                                                                                                                            },
                                                                                                                                            message: 'must NOT have additional properties',
                                                                                                                                        },
                                                                                                                                    ];
                                                                                                                                return false;
                                                                                                                                break;
                                                                                                                            }
                                                                                                                        }
                                                                                                                        if (_errs650 ===
                                                                                                                            errors) {
                                                                                                                            if (data237.identity !==
                                                                                                                                undefined) {
                                                                                                                                var _errs651 = errors;
                                                                                                                                if (typeof data237.identity !==
                                                                                                                                    'string') {
                                                                                                                                    validate11.errors =
                                                                                                                                        [
                                                                                                                                            {
                                                                                                                                                instancePath: instancePath +
                                                                                                                                                    '/participant/identity',
                                                                                                                                                schemaPath: '#/allOf/19/then/properties/participant/properties/identity/type',
                                                                                                                                                keyword: 'type',
                                                                                                                                                params: {
                                                                                                                                                    type: 'string',
                                                                                                                                                },
                                                                                                                                                message: 'must be string',
                                                                                                                                            },
                                                                                                                                        ];
                                                                                                                                    return false;
                                                                                                                                }
                                                                                                                                var valid114 = _errs651 ===
                                                                                                                                    errors;
                                                                                                                            }
                                                                                                                            else {
                                                                                                                                var valid114 = true;
                                                                                                                            }
                                                                                                                            if (valid114) {
                                                                                                                                if (data237.sid !==
                                                                                                                                    undefined) {
                                                                                                                                    var _errs653 = errors;
                                                                                                                                    if (typeof data237.sid !==
                                                                                                                                        'string') {
                                                                                                                                        validate11.errors =
                                                                                                                                            [
                                                                                                                                                {
                                                                                                                                                    instancePath: instancePath +
                                                                                                                                                        '/participant/sid',
                                                                                                                                                    schemaPath: '#/allOf/19/then/properties/participant/properties/sid/type',
                                                                                                                                                    keyword: 'type',
                                                                                                                                                    params: {
                                                                                                                                                        type: 'string',
                                                                                                                                                    },
                                                                                                                                                    message: 'must be string',
                                                                                                                                                },
                                                                                                                                            ];
                                                                                                                                        return false;
                                                                                                                                    }
                                                                                                                                    var valid114 = _errs653 ===
                                                                                                                                        errors;
                                                                                                                                }
                                                                                                                                else {
                                                                                                                                    var valid114 = true;
                                                                                                                                }
                                                                                                                                if (valid114) {
                                                                                                                                    if (data237.permissions !==
                                                                                                                                        undefined) {
                                                                                                                                        var data240 = data237.permissions;
                                                                                                                                        var _errs655 = errors;
                                                                                                                                        if (errors ===
                                                                                                                                            _errs655) {
                                                                                                                                            if (data240 &&
                                                                                                                                                typeof data240 ==
                                                                                                                                                    'object' &&
                                                                                                                                                !Array.isArray(data240)) {
                                                                                                                                                var missing62 = void 0;
                                                                                                                                                if ((data240.canSubscribe ===
                                                                                                                                                    undefined &&
                                                                                                                                                    (missing62 =
                                                                                                                                                        'canSubscribe')) ||
                                                                                                                                                    (data240.canPublish ===
                                                                                                                                                        undefined &&
                                                                                                                                                        (missing62 =
                                                                                                                                                            'canPublish')) ||
                                                                                                                                                    (data240.canPublishData ===
                                                                                                                                                        undefined &&
                                                                                                                                                        (missing62 =
                                                                                                                                                            'canPublishData')) ||
                                                                                                                                                    (data240.hidden ===
                                                                                                                                                        undefined &&
                                                                                                                                                        (missing62 =
                                                                                                                                                            'hidden')) ||
                                                                                                                                                    (data240.canUpdateMetadata ===
                                                                                                                                                        undefined &&
                                                                                                                                                        (missing62 =
                                                                                                                                                            'canUpdateMetadata')) ||
                                                                                                                                                    (data240.canSubscribeMetrics ===
                                                                                                                                                        undefined &&
                                                                                                                                                        (missing62 =
                                                                                                                                                            'canSubscribeMetrics'))) {
                                                                                                                                                    validate11.errors =
                                                                                                                                                        [
                                                                                                                                                            {
                                                                                                                                                                instancePath: instancePath +
                                                                                                                                                                    '/participant/permissions',
                                                                                                                                                                schemaPath: '#/allOf/19/then/properties/participant/properties/permissions/required',
                                                                                                                                                                keyword: 'required',
                                                                                                                                                                params: {
                                                                                                                                                                    missingProperty: missing62,
                                                                                                                                                                },
                                                                                                                                                                message: "must have required property '" +
                                                                                                                                                                    missing62 +
                                                                                                                                                                    "'",
                                                                                                                                                            },
                                                                                                                                                        ];
                                                                                                                                                    return false;
                                                                                                                                                }
                                                                                                                                                else {
                                                                                                                                                    var _errs657 = errors;
                                                                                                                                                    for (var key62 in data240) {
                                                                                                                                                        if (!(key62 ===
                                                                                                                                                            'canSubscribe' ||
                                                                                                                                                            key62 ===
                                                                                                                                                                'canPublish' ||
                                                                                                                                                            key62 ===
                                                                                                                                                                'canPublishData' ||
                                                                                                                                                            key62 ===
                                                                                                                                                                'hidden' ||
                                                                                                                                                            key62 ===
                                                                                                                                                                'canUpdateMetadata' ||
                                                                                                                                                            key62 ===
                                                                                                                                                                'canSubscribeMetrics')) {
                                                                                                                                                            validate11.errors =
                                                                                                                                                                [
                                                                                                                                                                    {
                                                                                                                                                                        instancePath: instancePath +
                                                                                                                                                                            '/participant/permissions',
                                                                                                                                                                        schemaPath: '#/allOf/19/then/properties/participant/properties/permissions/additionalProperties',
                                                                                                                                                                        keyword: 'additionalProperties',
                                                                                                                                                                        params: {
                                                                                                                                                                            additionalProperty: key62,
                                                                                                                                                                        },
                                                                                                                                                                        message: 'must NOT have additional properties',
                                                                                                                                                                    },
                                                                                                                                                                ];
                                                                                                                                                            return false;
                                                                                                                                                            break;
                                                                                                                                                        }
                                                                                                                                                    }
                                                                                                                                                    if (_errs657 ===
                                                                                                                                                        errors) {
                                                                                                                                                        if (data240.canSubscribe !==
                                                                                                                                                            undefined) {
                                                                                                                                                            var _errs658 = errors;
                                                                                                                                                            if (typeof data240.canSubscribe !==
                                                                                                                                                                'boolean') {
                                                                                                                                                                validate11.errors =
                                                                                                                                                                    [
                                                                                                                                                                        {
                                                                                                                                                                            instancePath: instancePath +
                                                                                                                                                                                '/participant/permissions/canSubscribe',
                                                                                                                                                                            schemaPath: '#/allOf/19/then/properties/participant/properties/permissions/properties/canSubscribe/type',
                                                                                                                                                                            keyword: 'type',
                                                                                                                                                                            params: {
                                                                                                                                                                                type: 'boolean',
                                                                                                                                                                            },
                                                                                                                                                                            message: 'must be boolean',
                                                                                                                                                                        },
                                                                                                                                                                    ];
                                                                                                                                                                return false;
                                                                                                                                                            }
                                                                                                                                                            var valid115 = _errs658 ===
                                                                                                                                                                errors;
                                                                                                                                                        }
                                                                                                                                                        else {
                                                                                                                                                            var valid115 = true;
                                                                                                                                                        }
                                                                                                                                                        if (valid115) {
                                                                                                                                                            if (data240.canPublish !==
                                                                                                                                                                undefined) {
                                                                                                                                                                var _errs660 = errors;
                                                                                                                                                                if (typeof data240.canPublish !==
                                                                                                                                                                    'boolean') {
                                                                                                                                                                    validate11.errors =
                                                                                                                                                                        [
                                                                                                                                                                            {
                                                                                                                                                                                instancePath: instancePath +
                                                                                                                                                                                    '/participant/permissions/canPublish',
                                                                                                                                                                                schemaPath: '#/allOf/19/then/properties/participant/properties/permissions/properties/canPublish/type',
                                                                                                                                                                                keyword: 'type',
                                                                                                                                                                                params: {
                                                                                                                                                                                    type: 'boolean',
                                                                                                                                                                                },
                                                                                                                                                                                message: 'must be boolean',
                                                                                                                                                                            },
                                                                                                                                                                        ];
                                                                                                                                                                    return false;
                                                                                                                                                                }
                                                                                                                                                                var valid115 = _errs660 ===
                                                                                                                                                                    errors;
                                                                                                                                                            }
                                                                                                                                                            else {
                                                                                                                                                                var valid115 = true;
                                                                                                                                                            }
                                                                                                                                                            if (valid115) {
                                                                                                                                                                if (data240.canPublishData !==
                                                                                                                                                                    undefined) {
                                                                                                                                                                    var _errs662 = errors;
                                                                                                                                                                    if (typeof data240.canPublishData !==
                                                                                                                                                                        'boolean') {
                                                                                                                                                                        validate11.errors =
                                                                                                                                                                            [
                                                                                                                                                                                {
                                                                                                                                                                                    instancePath: instancePath +
                                                                                                                                                                                        '/participant/permissions/canPublishData',
                                                                                                                                                                                    schemaPath: '#/allOf/19/then/properties/participant/properties/permissions/properties/canPublishData/type',
                                                                                                                                                                                    keyword: 'type',
                                                                                                                                                                                    params: {
                                                                                                                                                                                        type: 'boolean',
                                                                                                                                                                                    },
                                                                                                                                                                                    message: 'must be boolean',
                                                                                                                                                                                },
                                                                                                                                                                            ];
                                                                                                                                                                        return false;
                                                                                                                                                                    }
                                                                                                                                                                    var valid115 = _errs662 ===
                                                                                                                                                                        errors;
                                                                                                                                                                }
                                                                                                                                                                else {
                                                                                                                                                                    var valid115 = true;
                                                                                                                                                                }
                                                                                                                                                                if (valid115) {
                                                                                                                                                                    if (data240.hidden !==
                                                                                                                                                                        undefined) {
                                                                                                                                                                        var _errs664 = errors;
                                                                                                                                                                        if (typeof data240.hidden !==
                                                                                                                                                                            'boolean') {
                                                                                                                                                                            validate11.errors =
                                                                                                                                                                                [
                                                                                                                                                                                    {
                                                                                                                                                                                        instancePath: instancePath +
                                                                                                                                                                                            '/participant/permissions/hidden',
                                                                                                                                                                                        schemaPath: '#/allOf/19/then/properties/participant/properties/permissions/properties/hidden/type',
                                                                                                                                                                                        keyword: 'type',
                                                                                                                                                                                        params: {
                                                                                                                                                                                            type: 'boolean',
                                                                                                                                                                                        },
                                                                                                                                                                                        message: 'must be boolean',
                                                                                                                                                                                    },
                                                                                                                                                                                ];
                                                                                                                                                                            return false;
                                                                                                                                                                        }
                                                                                                                                                                        var valid115 = _errs664 ===
                                                                                                                                                                            errors;
                                                                                                                                                                    }
                                                                                                                                                                    else {
                                                                                                                                                                        var valid115 = true;
                                                                                                                                                                    }
                                                                                                                                                                    if (valid115) {
                                                                                                                                                                        if (data240.canUpdateMetadata !==
                                                                                                                                                                            undefined) {
                                                                                                                                                                            var _errs666 = errors;
                                                                                                                                                                            if (typeof data240.canUpdateMetadata !==
                                                                                                                                                                                'boolean') {
                                                                                                                                                                                validate11.errors =
                                                                                                                                                                                    [
                                                                                                                                                                                        {
                                                                                                                                                                                            instancePath: instancePath +
                                                                                                                                                                                                '/participant/permissions/canUpdateMetadata',
                                                                                                                                                                                            schemaPath: '#/allOf/19/then/properties/participant/properties/permissions/properties/canUpdateMetadata/type',
                                                                                                                                                                                            keyword: 'type',
                                                                                                                                                                                            params: {
                                                                                                                                                                                                type: 'boolean',
                                                                                                                                                                                            },
                                                                                                                                                                                            message: 'must be boolean',
                                                                                                                                                                                        },
                                                                                                                                                                                    ];
                                                                                                                                                                                return false;
                                                                                                                                                                            }
                                                                                                                                                                            var valid115 = _errs666 ===
                                                                                                                                                                                errors;
                                                                                                                                                                        }
                                                                                                                                                                        else {
                                                                                                                                                                            var valid115 = true;
                                                                                                                                                                        }
                                                                                                                                                                        if (valid115) {
                                                                                                                                                                            if (data240.canSubscribeMetrics !==
                                                                                                                                                                                undefined) {
                                                                                                                                                                                var _errs668 = errors;
                                                                                                                                                                                if (typeof data240.canSubscribeMetrics !==
                                                                                                                                                                                    'boolean') {
                                                                                                                                                                                    validate11.errors =
                                                                                                                                                                                        [
                                                                                                                                                                                            {
                                                                                                                                                                                                instancePath: instancePath +
                                                                                                                                                                                                    '/participant/permissions/canSubscribeMetrics',
                                                                                                                                                                                                schemaPath: '#/allOf/19/then/properties/participant/properties/permissions/properties/canSubscribeMetrics/type',
                                                                                                                                                                                                keyword: 'type',
                                                                                                                                                                                                params: {
                                                                                                                                                                                                    type: 'boolean',
                                                                                                                                                                                                },
                                                                                                                                                                                                message: 'must be boolean',
                                                                                                                                                                                            },
                                                                                                                                                                                        ];
                                                                                                                                                                                    return false;
                                                                                                                                                                                }
                                                                                                                                                                                var valid115 = _errs668 ===
                                                                                                                                                                                    errors;
                                                                                                                                                                            }
                                                                                                                                                                            else {
                                                                                                                                                                                var valid115 = true;
                                                                                                                                                                            }
                                                                                                                                                                        }
                                                                                                                                                                    }
                                                                                                                                                                }
                                                                                                                                                            }
                                                                                                                                                        }
                                                                                                                                                    }
                                                                                                                                                }
                                                                                                                                            }
                                                                                                                                            else {
                                                                                                                                                validate11.errors =
                                                                                                                                                    [
                                                                                                                                                        {
                                                                                                                                                            instancePath: instancePath +
                                                                                                                                                                '/participant/permissions',
                                                                                                                                                            schemaPath: '#/allOf/19/then/properties/participant/properties/permissions/type',
                                                                                                                                                            keyword: 'type',
                                                                                                                                                            params: {
                                                                                                                                                                type: 'object',
                                                                                                                                                            },
                                                                                                                                                            message: 'must be object',
                                                                                                                                                        },
                                                                                                                                                    ];
                                                                                                                                                return false;
                                                                                                                                            }
                                                                                                                                        }
                                                                                                                                        var valid114 = _errs655 ===
                                                                                                                                            errors;
                                                                                                                                    }
                                                                                                                                    else {
                                                                                                                                        var valid114 = true;
                                                                                                                                    }
                                                                                                                                }
                                                                                                                            }
                                                                                                                        }
                                                                                                                    }
                                                                                                                }
                                                                                                                else {
                                                                                                                    validate11.errors = [
                                                                                                                        {
                                                                                                                            instancePath: instancePath +
                                                                                                                                '/participant',
                                                                                                                            schemaPath: '#/allOf/19/then/properties/participant/type',
                                                                                                                            keyword: 'type',
                                                                                                                            params: {
                                                                                                                                type: 'object',
                                                                                                                            },
                                                                                                                            message: 'must be object',
                                                                                                                        },
                                                                                                                    ];
                                                                                                                    return false;
                                                                                                                }
                                                                                                            }
                                                                                                            var valid113 = _errs648 === errors;
                                                                                                        }
                                                                                                        else {
                                                                                                            var valid113 = true;
                                                                                                        }
                                                                                                        if (valid113) {
                                                                                                            if (data.name !== undefined) {
                                                                                                                var data247 = data.name;
                                                                                                                var _errs670 = errors;
                                                                                                                if (typeof data247 !==
                                                                                                                    'string') {
                                                                                                                    validate11.errors = [
                                                                                                                        {
                                                                                                                            instancePath: instancePath +
                                                                                                                                '/name',
                                                                                                                            schemaPath: '#/allOf/19/then/properties/name/type',
                                                                                                                            keyword: 'type',
                                                                                                                            params: {
                                                                                                                                type: 'string',
                                                                                                                            },
                                                                                                                            message: 'must be string',
                                                                                                                        },
                                                                                                                    ];
                                                                                                                    return false;
                                                                                                                }
                                                                                                                if ('participantMetadataChanged' !==
                                                                                                                    data247) {
                                                                                                                    validate11.errors = [
                                                                                                                        {
                                                                                                                            instancePath: instancePath +
                                                                                                                                '/name',
                                                                                                                            schemaPath: '#/allOf/19/then/properties/name/const',
                                                                                                                            keyword: 'const',
                                                                                                                            params: {
                                                                                                                                allowedValue: 'participantMetadataChanged',
                                                                                                                            },
                                                                                                                            message: 'must be equal to constant',
                                                                                                                        },
                                                                                                                    ];
                                                                                                                    return false;
                                                                                                                }
                                                                                                                var valid113 = _errs670 === errors;
                                                                                                            }
                                                                                                            else {
                                                                                                                var valid113 = true;
                                                                                                            }
                                                                                                            if (valid113) {
                                                                                                                if (data.timestamp !==
                                                                                                                    undefined) {
                                                                                                                    var data248 = data.timestamp;
                                                                                                                    var _errs672 = errors;
                                                                                                                    if (!(typeof data248 ==
                                                                                                                        'number' &&
                                                                                                                        isFinite(data248))) {
                                                                                                                        validate11.errors =
                                                                                                                            [
                                                                                                                                {
                                                                                                                                    instancePath: instancePath +
                                                                                                                                        '/timestamp',
                                                                                                                                    schemaPath: '#/allOf/19/then/properties/timestamp/type',
                                                                                                                                    keyword: 'type',
                                                                                                                                    params: {
                                                                                                                                        type: 'number',
                                                                                                                                    },
                                                                                                                                    message: 'must be number',
                                                                                                                                },
                                                                                                                            ];
                                                                                                                        return false;
                                                                                                                    }
                                                                                                                    var valid113 = _errs672 === errors;
                                                                                                                }
                                                                                                                else {
                                                                                                                    var valid113 = true;
                                                                                                                }
                                                                                                            }
                                                                                                        }
                                                                                                    }
                                                                                                }
                                                                                            }
                                                                                        }
                                                                                        else {
                                                                                            validate11.errors = [
                                                                                                {
                                                                                                    instancePath: instancePath,
                                                                                                    schemaPath: '#/allOf/19/then/type',
                                                                                                    keyword: 'type',
                                                                                                    params: { type: 'object' },
                                                                                                    message: 'must be object',
                                                                                                },
                                                                                            ];
                                                                                            return false;
                                                                                        }
                                                                                    }
                                                                                    var _valid19 = _errs643 === errors;
                                                                                    valid111 = _valid19;
                                                                                }
                                                                                if (!valid111) {
                                                                                    var err59 = {
                                                                                        instancePath: instancePath,
                                                                                        schemaPath: '#/allOf/19/if',
                                                                                        keyword: 'if',
                                                                                        params: { failingKeyword: 'then' },
                                                                                        message: 'must match "then" schema',
                                                                                    };
                                                                                    if (vErrors === null) {
                                                                                        vErrors = [err59];
                                                                                    }
                                                                                    else {
                                                                                        vErrors.push(err59);
                                                                                    }
                                                                                    errors++;
                                                                                    validate11.errors = vErrors;
                                                                                    return false;
                                                                                }
                                                                                var valid0 = _errs638 === errors;
                                                                                if (valid0) {
                                                                                    var _errs674 = errors;
                                                                                    var _errs675 = errors;
                                                                                    var valid116 = true;
                                                                                    var _errs676 = errors;
                                                                                    if (data &&
                                                                                        typeof data == 'object' &&
                                                                                        !Array.isArray(data)) {
                                                                                        if (data.name !== undefined) {
                                                                                            var data249 = data.name;
                                                                                            if (typeof data249 !== 'string') {
                                                                                                var err60 = {};
                                                                                                if (vErrors === null) {
                                                                                                    vErrors = [err60];
                                                                                                }
                                                                                                else {
                                                                                                    vErrors.push(err60);
                                                                                                }
                                                                                                errors++;
                                                                                            }
                                                                                            if ('participantNameChanged' !==
                                                                                                data249) {
                                                                                                var err61 = {};
                                                                                                if (vErrors === null) {
                                                                                                    vErrors = [err61];
                                                                                                }
                                                                                                else {
                                                                                                    vErrors.push(err61);
                                                                                                }
                                                                                                errors++;
                                                                                            }
                                                                                        }
                                                                                    }
                                                                                    var _valid20 = _errs676 === errors;
                                                                                    errors = _errs675;
                                                                                    if (vErrors !== null) {
                                                                                        if (_errs675) {
                                                                                            vErrors.length = _errs675;
                                                                                        }
                                                                                        else {
                                                                                            vErrors = null;
                                                                                        }
                                                                                    }
                                                                                    if (_valid20) {
                                                                                        var _errs679 = errors;
                                                                                        if (errors === _errs679) {
                                                                                            if (data &&
                                                                                                typeof data == 'object' &&
                                                                                                !Array.isArray(data)) {
                                                                                                var missing63 = void 0;
                                                                                                if ((data.name === undefined &&
                                                                                                    (missing63 = 'name')) ||
                                                                                                    (data.newName === undefined &&
                                                                                                        (missing63 = 'newName')) ||
                                                                                                    (data.participant ===
                                                                                                        undefined &&
                                                                                                        (missing63 =
                                                                                                            'participant')) ||
                                                                                                    (data.timestamp ===
                                                                                                        undefined &&
                                                                                                        (missing63 = 'timestamp'))) {
                                                                                                    validate11.errors = [
                                                                                                        {
                                                                                                            instancePath: instancePath,
                                                                                                            schemaPath: '#/allOf/20/then/required',
                                                                                                            keyword: 'required',
                                                                                                            params: {
                                                                                                                missingProperty: missing63,
                                                                                                            },
                                                                                                            message: "must have required property '" +
                                                                                                                missing63 +
                                                                                                                "'",
                                                                                                        },
                                                                                                    ];
                                                                                                    return false;
                                                                                                }
                                                                                                else {
                                                                                                    var _errs681 = errors;
                                                                                                    for (var key63 in data) {
                                                                                                        if (!(key63 === 'newName' ||
                                                                                                            key63 ===
                                                                                                                'participant' ||
                                                                                                            key63 === 'name' ||
                                                                                                            key63 === 'timestamp')) {
                                                                                                            validate11.errors = [
                                                                                                                {
                                                                                                                    instancePath: instancePath,
                                                                                                                    schemaPath: '#/allOf/20/then/additionalProperties',
                                                                                                                    keyword: 'additionalProperties',
                                                                                                                    params: {
                                                                                                                        additionalProperty: key63,
                                                                                                                    },
                                                                                                                    message: 'must NOT have additional properties',
                                                                                                                },
                                                                                                            ];
                                                                                                            return false;
                                                                                                            break;
                                                                                                        }
                                                                                                    }
                                                                                                    if (_errs681 === errors) {
                                                                                                        if (data.newName !== undefined) {
                                                                                                            var _errs682 = errors;
                                                                                                            if (typeof data.newName !==
                                                                                                                'string') {
                                                                                                                validate11.errors = [
                                                                                                                    {
                                                                                                                        instancePath: instancePath +
                                                                                                                            '/newName',
                                                                                                                        schemaPath: '#/allOf/20/then/properties/newName/type',
                                                                                                                        keyword: 'type',
                                                                                                                        params: {
                                                                                                                            type: 'string',
                                                                                                                        },
                                                                                                                        message: 'must be string',
                                                                                                                    },
                                                                                                                ];
                                                                                                                return false;
                                                                                                            }
                                                                                                            var valid118 = _errs682 === errors;
                                                                                                        }
                                                                                                        else {
                                                                                                            var valid118 = true;
                                                                                                        }
                                                                                                        if (valid118) {
                                                                                                            if (data.participant !==
                                                                                                                undefined) {
                                                                                                                var data251 = data.participant;
                                                                                                                var _errs684 = errors;
                                                                                                                if (errors === _errs684) {
                                                                                                                    if (data251 &&
                                                                                                                        typeof data251 ==
                                                                                                                            'object' &&
                                                                                                                        !Array.isArray(data251)) {
                                                                                                                        var missing64 = void 0;
                                                                                                                        if ((data251.identity ===
                                                                                                                            undefined &&
                                                                                                                            (missing64 =
                                                                                                                                'identity')) ||
                                                                                                                            (data251.sid ===
                                                                                                                                undefined &&
                                                                                                                                (missing64 =
                                                                                                                                    'sid'))) {
                                                                                                                            validate11.errors =
                                                                                                                                [
                                                                                                                                    {
                                                                                                                                        instancePath: instancePath +
                                                                                                                                            '/participant',
                                                                                                                                        schemaPath: '#/allOf/20/then/properties/participant/required',
                                                                                                                                        keyword: 'required',
                                                                                                                                        params: {
                                                                                                                                            missingProperty: missing64,
                                                                                                                                        },
                                                                                                                                        message: "must have required property '" +
                                                                                                                                            missing64 +
                                                                                                                                            "'",
                                                                                                                                    },
                                                                                                                                ];
                                                                                                                            return false;
                                                                                                                        }
                                                                                                                        else {
                                                                                                                            var _errs686 = errors;
                                                                                                                            for (var key64 in data251) {
                                                                                                                                if (!(key64 ===
                                                                                                                                    'identity' ||
                                                                                                                                    key64 ===
                                                                                                                                        'sid' ||
                                                                                                                                    key64 ===
                                                                                                                                        'permissions')) {
                                                                                                                                    validate11.errors =
                                                                                                                                        [
                                                                                                                                            {
                                                                                                                                                instancePath: instancePath +
                                                                                                                                                    '/participant',
                                                                                                                                                schemaPath: '#/allOf/20/then/properties/participant/additionalProperties',
                                                                                                                                                keyword: 'additionalProperties',
                                                                                                                                                params: {
                                                                                                                                                    additionalProperty: key64,
                                                                                                                                                },
                                                                                                                                                message: 'must NOT have additional properties',
                                                                                                                                            },
                                                                                                                                        ];
                                                                                                                                    return false;
                                                                                                                                    break;
                                                                                                                                }
                                                                                                                            }
                                                                                                                            if (_errs686 ===
                                                                                                                                errors) {
                                                                                                                                if (data251.identity !==
                                                                                                                                    undefined) {
                                                                                                                                    var _errs687 = errors;
                                                                                                                                    if (typeof data251.identity !==
                                                                                                                                        'string') {
                                                                                                                                        validate11.errors =
                                                                                                                                            [
                                                                                                                                                {
                                                                                                                                                    instancePath: instancePath +
                                                                                                                                                        '/participant/identity',
                                                                                                                                                    schemaPath: '#/allOf/20/then/properties/participant/properties/identity/type',
                                                                                                                                                    keyword: 'type',
                                                                                                                                                    params: {
                                                                                                                                                        type: 'string',
                                                                                                                                                    },
                                                                                                                                                    message: 'must be string',
                                                                                                                                                },
                                                                                                                                            ];
                                                                                                                                        return false;
                                                                                                                                    }
                                                                                                                                    var valid119 = _errs687 ===
                                                                                                                                        errors;
                                                                                                                                }
                                                                                                                                else {
                                                                                                                                    var valid119 = true;
                                                                                                                                }
                                                                                                                                if (valid119) {
                                                                                                                                    if (data251.sid !==
                                                                                                                                        undefined) {
                                                                                                                                        var _errs689 = errors;
                                                                                                                                        if (typeof data251.sid !==
                                                                                                                                            'string') {
                                                                                                                                            validate11.errors =
                                                                                                                                                [
                                                                                                                                                    {
                                                                                                                                                        instancePath: instancePath +
                                                                                                                                                            '/participant/sid',
                                                                                                                                                        schemaPath: '#/allOf/20/then/properties/participant/properties/sid/type',
                                                                                                                                                        keyword: 'type',
                                                                                                                                                        params: {
                                                                                                                                                            type: 'string',
                                                                                                                                                        },
                                                                                                                                                        message: 'must be string',
                                                                                                                                                    },
                                                                                                                                                ];
                                                                                                                                            return false;
                                                                                                                                        }
                                                                                                                                        var valid119 = _errs689 ===
                                                                                                                                            errors;
                                                                                                                                    }
                                                                                                                                    else {
                                                                                                                                        var valid119 = true;
                                                                                                                                    }
                                                                                                                                    if (valid119) {
                                                                                                                                        if (data251.permissions !==
                                                                                                                                            undefined) {
                                                                                                                                            var data254 = data251.permissions;
                                                                                                                                            var _errs691 = errors;
                                                                                                                                            if (errors ===
                                                                                                                                                _errs691) {
                                                                                                                                                if (data254 &&
                                                                                                                                                    typeof data254 ==
                                                                                                                                                        'object' &&
                                                                                                                                                    !Array.isArray(data254)) {
                                                                                                                                                    var missing65 = void 0;
                                                                                                                                                    if ((data254.canSubscribe ===
                                                                                                                                                        undefined &&
                                                                                                                                                        (missing65 =
                                                                                                                                                            'canSubscribe')) ||
                                                                                                                                                        (data254.canPublish ===
                                                                                                                                                            undefined &&
                                                                                                                                                            (missing65 =
                                                                                                                                                                'canPublish')) ||
                                                                                                                                                        (data254.canPublishData ===
                                                                                                                                                            undefined &&
                                                                                                                                                            (missing65 =
                                                                                                                                                                'canPublishData')) ||
                                                                                                                                                        (data254.hidden ===
                                                                                                                                                            undefined &&
                                                                                                                                                            (missing65 =
                                                                                                                                                                'hidden')) ||
                                                                                                                                                        (data254.canUpdateMetadata ===
                                                                                                                                                            undefined &&
                                                                                                                                                            (missing65 =
                                                                                                                                                                'canUpdateMetadata')) ||
                                                                                                                                                        (data254.canSubscribeMetrics ===
                                                                                                                                                            undefined &&
                                                                                                                                                            (missing65 =
                                                                                                                                                                'canSubscribeMetrics'))) {
                                                                                                                                                        validate11.errors =
                                                                                                                                                            [
                                                                                                                                                                {
                                                                                                                                                                    instancePath: instancePath +
                                                                                                                                                                        '/participant/permissions',
                                                                                                                                                                    schemaPath: '#/allOf/20/then/properties/participant/properties/permissions/required',
                                                                                                                                                                    keyword: 'required',
                                                                                                                                                                    params: {
                                                                                                                                                                        missingProperty: missing65,
                                                                                                                                                                    },
                                                                                                                                                                    message: "must have required property '" +
                                                                                                                                                                        missing65 +
                                                                                                                                                                        "'",
                                                                                                                                                                },
                                                                                                                                                            ];
                                                                                                                                                        return false;
                                                                                                                                                    }
                                                                                                                                                    else {
                                                                                                                                                        var _errs693 = errors;
                                                                                                                                                        for (var key65 in data254) {
                                                                                                                                                            if (!(key65 ===
                                                                                                                                                                'canSubscribe' ||
                                                                                                                                                                key65 ===
                                                                                                                                                                    'canPublish' ||
                                                                                                                                                                key65 ===
                                                                                                                                                                    'canPublishData' ||
                                                                                                                                                                key65 ===
                                                                                                                                                                    'hidden' ||
                                                                                                                                                                key65 ===
                                                                                                                                                                    'canUpdateMetadata' ||
                                                                                                                                                                key65 ===
                                                                                                                                                                    'canSubscribeMetrics')) {
                                                                                                                                                                validate11.errors =
                                                                                                                                                                    [
                                                                                                                                                                        {
                                                                                                                                                                            instancePath: instancePath +
                                                                                                                                                                                '/participant/permissions',
                                                                                                                                                                            schemaPath: '#/allOf/20/then/properties/participant/properties/permissions/additionalProperties',
                                                                                                                                                                            keyword: 'additionalProperties',
                                                                                                                                                                            params: {
                                                                                                                                                                                additionalProperty: key65,
                                                                                                                                                                            },
                                                                                                                                                                            message: 'must NOT have additional properties',
                                                                                                                                                                        },
                                                                                                                                                                    ];
                                                                                                                                                                return false;
                                                                                                                                                                break;
                                                                                                                                                            }
                                                                                                                                                        }
                                                                                                                                                        if (_errs693 ===
                                                                                                                                                            errors) {
                                                                                                                                                            if (data254.canSubscribe !==
                                                                                                                                                                undefined) {
                                                                                                                                                                var _errs694 = errors;
                                                                                                                                                                if (typeof data254.canSubscribe !==
                                                                                                                                                                    'boolean') {
                                                                                                                                                                    validate11.errors =
                                                                                                                                                                        [
                                                                                                                                                                            {
                                                                                                                                                                                instancePath: instancePath +
                                                                                                                                                                                    '/participant/permissions/canSubscribe',
                                                                                                                                                                                schemaPath: '#/allOf/20/then/properties/participant/properties/permissions/properties/canSubscribe/type',
                                                                                                                                                                                keyword: 'type',
                                                                                                                                                                                params: {
                                                                                                                                                                                    type: 'boolean',
                                                                                                                                                                                },
                                                                                                                                                                                message: 'must be boolean',
                                                                                                                                                                            },
                                                                                                                                                                        ];
                                                                                                                                                                    return false;
                                                                                                                                                                }
                                                                                                                                                                var valid120 = _errs694 ===
                                                                                                                                                                    errors;
                                                                                                                                                            }
                                                                                                                                                            else {
                                                                                                                                                                var valid120 = true;
                                                                                                                                                            }
                                                                                                                                                            if (valid120) {
                                                                                                                                                                if (data254.canPublish !==
                                                                                                                                                                    undefined) {
                                                                                                                                                                    var _errs696 = errors;
                                                                                                                                                                    if (typeof data254.canPublish !==
                                                                                                                                                                        'boolean') {
                                                                                                                                                                        validate11.errors =
                                                                                                                                                                            [
                                                                                                                                                                                {
                                                                                                                                                                                    instancePath: instancePath +
                                                                                                                                                                                        '/participant/permissions/canPublish',
                                                                                                                                                                                    schemaPath: '#/allOf/20/then/properties/participant/properties/permissions/properties/canPublish/type',
                                                                                                                                                                                    keyword: 'type',
                                                                                                                                                                                    params: {
                                                                                                                                                                                        type: 'boolean',
                                                                                                                                                                                    },
                                                                                                                                                                                    message: 'must be boolean',
                                                                                                                                                                                },
                                                                                                                                                                            ];
                                                                                                                                                                        return false;
                                                                                                                                                                    }
                                                                                                                                                                    var valid120 = _errs696 ===
                                                                                                                                                                        errors;
                                                                                                                                                                }
                                                                                                                                                                else {
                                                                                                                                                                    var valid120 = true;
                                                                                                                                                                }
                                                                                                                                                                if (valid120) {
                                                                                                                                                                    if (data254.canPublishData !==
                                                                                                                                                                        undefined) {
                                                                                                                                                                        var _errs698 = errors;
                                                                                                                                                                        if (typeof data254.canPublishData !==
                                                                                                                                                                            'boolean') {
                                                                                                                                                                            validate11.errors =
                                                                                                                                                                                [
                                                                                                                                                                                    {
                                                                                                                                                                                        instancePath: instancePath +
                                                                                                                                                                                            '/participant/permissions/canPublishData',
                                                                                                                                                                                        schemaPath: '#/allOf/20/then/properties/participant/properties/permissions/properties/canPublishData/type',
                                                                                                                                                                                        keyword: 'type',
                                                                                                                                                                                        params: {
                                                                                                                                                                                            type: 'boolean',
                                                                                                                                                                                        },
                                                                                                                                                                                        message: 'must be boolean',
                                                                                                                                                                                    },
                                                                                                                                                                                ];
                                                                                                                                                                            return false;
                                                                                                                                                                        }
                                                                                                                                                                        var valid120 = _errs698 ===
                                                                                                                                                                            errors;
                                                                                                                                                                    }
                                                                                                                                                                    else {
                                                                                                                                                                        var valid120 = true;
                                                                                                                                                                    }
                                                                                                                                                                    if (valid120) {
                                                                                                                                                                        if (data254.hidden !==
                                                                                                                                                                            undefined) {
                                                                                                                                                                            var _errs700 = errors;
                                                                                                                                                                            if (typeof data254.hidden !==
                                                                                                                                                                                'boolean') {
                                                                                                                                                                                validate11.errors =
                                                                                                                                                                                    [
                                                                                                                                                                                        {
                                                                                                                                                                                            instancePath: instancePath +
                                                                                                                                                                                                '/participant/permissions/hidden',
                                                                                                                                                                                            schemaPath: '#/allOf/20/then/properties/participant/properties/permissions/properties/hidden/type',
                                                                                                                                                                                            keyword: 'type',
                                                                                                                                                                                            params: {
                                                                                                                                                                                                type: 'boolean',
                                                                                                                                                                                            },
                                                                                                                                                                                            message: 'must be boolean',
                                                                                                                                                                                        },
                                                                                                                                                                                    ];
                                                                                                                                                                                return false;
                                                                                                                                                                            }
                                                                                                                                                                            var valid120 = _errs700 ===
                                                                                                                                                                                errors;
                                                                                                                                                                        }
                                                                                                                                                                        else {
                                                                                                                                                                            var valid120 = true;
                                                                                                                                                                        }
                                                                                                                                                                        if (valid120) {
                                                                                                                                                                            if (data254.canUpdateMetadata !==
                                                                                                                                                                                undefined) {
                                                                                                                                                                                var _errs702 = errors;
                                                                                                                                                                                if (typeof data254.canUpdateMetadata !==
                                                                                                                                                                                    'boolean') {
                                                                                                                                                                                    validate11.errors =
                                                                                                                                                                                        [
                                                                                                                                                                                            {
                                                                                                                                                                                                instancePath: instancePath +
                                                                                                                                                                                                    '/participant/permissions/canUpdateMetadata',
                                                                                                                                                                                                schemaPath: '#/allOf/20/then/properties/participant/properties/permissions/properties/canUpdateMetadata/type',
                                                                                                                                                                                                keyword: 'type',
                                                                                                                                                                                                params: {
                                                                                                                                                                                                    type: 'boolean',
                                                                                                                                                                                                },
                                                                                                                                                                                                message: 'must be boolean',
                                                                                                                                                                                            },
                                                                                                                                                                                        ];
                                                                                                                                                                                    return false;
                                                                                                                                                                                }
                                                                                                                                                                                var valid120 = _errs702 ===
                                                                                                                                                                                    errors;
                                                                                                                                                                            }
                                                                                                                                                                            else {
                                                                                                                                                                                var valid120 = true;
                                                                                                                                                                            }
                                                                                                                                                                            if (valid120) {
                                                                                                                                                                                if (data254.canSubscribeMetrics !==
                                                                                                                                                                                    undefined) {
                                                                                                                                                                                    var _errs704 = errors;
                                                                                                                                                                                    if (typeof data254.canSubscribeMetrics !==
                                                                                                                                                                                        'boolean') {
                                                                                                                                                                                        validate11.errors =
                                                                                                                                                                                            [
                                                                                                                                                                                                {
                                                                                                                                                                                                    instancePath: instancePath +
                                                                                                                                                                                                        '/participant/permissions/canSubscribeMetrics',
                                                                                                                                                                                                    schemaPath: '#/allOf/20/then/properties/participant/properties/permissions/properties/canSubscribeMetrics/type',
                                                                                                                                                                                                    keyword: 'type',
                                                                                                                                                                                                    params: {
                                                                                                                                                                                                        type: 'boolean',
                                                                                                                                                                                                    },
                                                                                                                                                                                                    message: 'must be boolean',
                                                                                                                                                                                                },
                                                                                                                                                                                            ];
                                                                                                                                                                                        return false;
                                                                                                                                                                                    }
                                                                                                                                                                                    var valid120 = _errs704 ===
                                                                                                                                                                                        errors;
                                                                                                                                                                                }
                                                                                                                                                                                else {
                                                                                                                                                                                    var valid120 = true;
                                                                                                                                                                                }
                                                                                                                                                                            }
                                                                                                                                                                        }
                                                                                                                                                                    }
                                                                                                                                                                }
                                                                                                                                                            }
                                                                                                                                                        }
                                                                                                                                                    }
                                                                                                                                                }
                                                                                                                                                else {
                                                                                                                                                    validate11.errors =
                                                                                                                                                        [
                                                                                                                                                            {
                                                                                                                                                                instancePath: instancePath +
                                                                                                                                                                    '/participant/permissions',
                                                                                                                                                                schemaPath: '#/allOf/20/then/properties/participant/properties/permissions/type',
                                                                                                                                                                keyword: 'type',
                                                                                                                                                                params: {
                                                                                                                                                                    type: 'object',
                                                                                                                                                                },
                                                                                                                                                                message: 'must be object',
                                                                                                                                                            },
                                                                                                                                                        ];
                                                                                                                                                    return false;
                                                                                                                                                }
                                                                                                                                            }
                                                                                                                                            var valid119 = _errs691 ===
                                                                                                                                                errors;
                                                                                                                                        }
                                                                                                                                        else {
                                                                                                                                            var valid119 = true;
                                                                                                                                        }
                                                                                                                                    }
                                                                                                                                }
                                                                                                                            }
                                                                                                                        }
                                                                                                                    }
                                                                                                                    else {
                                                                                                                        validate11.errors =
                                                                                                                            [
                                                                                                                                {
                                                                                                                                    instancePath: instancePath +
                                                                                                                                        '/participant',
                                                                                                                                    schemaPath: '#/allOf/20/then/properties/participant/type',
                                                                                                                                    keyword: 'type',
                                                                                                                                    params: {
                                                                                                                                        type: 'object',
                                                                                                                                    },
                                                                                                                                    message: 'must be object',
                                                                                                                                },
                                                                                                                            ];
                                                                                                                        return false;
                                                                                                                    }
                                                                                                                }
                                                                                                                var valid118 = _errs684 === errors;
                                                                                                            }
                                                                                                            else {
                                                                                                                var valid118 = true;
                                                                                                            }
                                                                                                            if (valid118) {
                                                                                                                if (data.name !==
                                                                                                                    undefined) {
                                                                                                                    var data261 = data.name;
                                                                                                                    var _errs706 = errors;
                                                                                                                    if (typeof data261 !==
                                                                                                                        'string') {
                                                                                                                        validate11.errors =
                                                                                                                            [
                                                                                                                                {
                                                                                                                                    instancePath: instancePath +
                                                                                                                                        '/name',
                                                                                                                                    schemaPath: '#/allOf/20/then/properties/name/type',
                                                                                                                                    keyword: 'type',
                                                                                                                                    params: {
                                                                                                                                        type: 'string',
                                                                                                                                    },
                                                                                                                                    message: 'must be string',
                                                                                                                                },
                                                                                                                            ];
                                                                                                                        return false;
                                                                                                                    }
                                                                                                                    if ('participantNameChanged' !==
                                                                                                                        data261) {
                                                                                                                        validate11.errors =
                                                                                                                            [
                                                                                                                                {
                                                                                                                                    instancePath: instancePath +
                                                                                                                                        '/name',
                                                                                                                                    schemaPath: '#/allOf/20/then/properties/name/const',
                                                                                                                                    keyword: 'const',
                                                                                                                                    params: {
                                                                                                                                        allowedValue: 'participantNameChanged',
                                                                                                                                    },
                                                                                                                                    message: 'must be equal to constant',
                                                                                                                                },
                                                                                                                            ];
                                                                                                                        return false;
                                                                                                                    }
                                                                                                                    var valid118 = _errs706 === errors;
                                                                                                                }
                                                                                                                else {
                                                                                                                    var valid118 = true;
                                                                                                                }
                                                                                                                if (valid118) {
                                                                                                                    if (data.timestamp !==
                                                                                                                        undefined) {
                                                                                                                        var data262 = data.timestamp;
                                                                                                                        var _errs708 = errors;
                                                                                                                        if (!(typeof data262 ==
                                                                                                                            'number' &&
                                                                                                                            isFinite(data262))) {
                                                                                                                            validate11.errors =
                                                                                                                                [
                                                                                                                                    {
                                                                                                                                        instancePath: instancePath +
                                                                                                                                            '/timestamp',
                                                                                                                                        schemaPath: '#/allOf/20/then/properties/timestamp/type',
                                                                                                                                        keyword: 'type',
                                                                                                                                        params: {
                                                                                                                                            type: 'number',
                                                                                                                                        },
                                                                                                                                        message: 'must be number',
                                                                                                                                    },
                                                                                                                                ];
                                                                                                                            return false;
                                                                                                                        }
                                                                                                                        var valid118 = _errs708 ===
                                                                                                                            errors;
                                                                                                                    }
                                                                                                                    else {
                                                                                                                        var valid118 = true;
                                                                                                                    }
                                                                                                                }
                                                                                                            }
                                                                                                        }
                                                                                                    }
                                                                                                }
                                                                                            }
                                                                                            else {
                                                                                                validate11.errors = [
                                                                                                    {
                                                                                                        instancePath: instancePath,
                                                                                                        schemaPath: '#/allOf/20/then/type',
                                                                                                        keyword: 'type',
                                                                                                        params: { type: 'object' },
                                                                                                        message: 'must be object',
                                                                                                    },
                                                                                                ];
                                                                                                return false;
                                                                                            }
                                                                                        }
                                                                                        var _valid20 = _errs679 === errors;
                                                                                        valid116 = _valid20;
                                                                                    }
                                                                                    if (!valid116) {
                                                                                        var err62 = {
                                                                                            instancePath: instancePath,
                                                                                            schemaPath: '#/allOf/20/if',
                                                                                            keyword: 'if',
                                                                                            params: {
                                                                                                failingKeyword: 'then',
                                                                                            },
                                                                                            message: 'must match "then" schema',
                                                                                        };
                                                                                        if (vErrors === null) {
                                                                                            vErrors = [err62];
                                                                                        }
                                                                                        else {
                                                                                            vErrors.push(err62);
                                                                                        }
                                                                                        errors++;
                                                                                        validate11.errors = vErrors;
                                                                                        return false;
                                                                                    }
                                                                                    var valid0 = _errs674 === errors;
                                                                                    if (valid0) {
                                                                                        var _errs710 = errors;
                                                                                        var _errs711 = errors;
                                                                                        var valid121 = true;
                                                                                        var _errs712 = errors;
                                                                                        if (data &&
                                                                                            typeof data == 'object' &&
                                                                                            !Array.isArray(data)) {
                                                                                            if (data.name !== undefined) {
                                                                                                var data263 = data.name;
                                                                                                if (typeof data263 !== 'string') {
                                                                                                    var err63 = {};
                                                                                                    if (vErrors === null) {
                                                                                                        vErrors = [err63];
                                                                                                    }
                                                                                                    else {
                                                                                                        vErrors.push(err63);
                                                                                                    }
                                                                                                    errors++;
                                                                                                }
                                                                                                if ('participantPermissionsChanged' !==
                                                                                                    data263) {
                                                                                                    var err64 = {};
                                                                                                    if (vErrors === null) {
                                                                                                        vErrors = [err64];
                                                                                                    }
                                                                                                    else {
                                                                                                        vErrors.push(err64);
                                                                                                    }
                                                                                                    errors++;
                                                                                                }
                                                                                            }
                                                                                        }
                                                                                        var _valid21 = _errs712 === errors;
                                                                                        errors = _errs711;
                                                                                        if (vErrors !== null) {
                                                                                            if (_errs711) {
                                                                                                vErrors.length = _errs711;
                                                                                            }
                                                                                            else {
                                                                                                vErrors = null;
                                                                                            }
                                                                                        }
                                                                                        if (_valid21) {
                                                                                            var _errs715 = errors;
                                                                                            if (errors === _errs715) {
                                                                                                if (data &&
                                                                                                    typeof data == 'object' &&
                                                                                                    !Array.isArray(data)) {
                                                                                                    var missing66 = void 0;
                                                                                                    if ((data.name === undefined &&
                                                                                                        (missing66 = 'name')) ||
                                                                                                        (data.participant ===
                                                                                                            undefined &&
                                                                                                            (missing66 =
                                                                                                                'participant')) ||
                                                                                                        (data.timestamp ===
                                                                                                            undefined &&
                                                                                                            (missing66 = 'timestamp'))) {
                                                                                                        validate11.errors = [
                                                                                                            {
                                                                                                                instancePath: instancePath,
                                                                                                                schemaPath: '#/allOf/21/then/required',
                                                                                                                keyword: 'required',
                                                                                                                params: {
                                                                                                                    missingProperty: missing66,
                                                                                                                },
                                                                                                                message: "must have required property '" +
                                                                                                                    missing66 +
                                                                                                                    "'",
                                                                                                            },
                                                                                                        ];
                                                                                                        return false;
                                                                                                    }
                                                                                                    else {
                                                                                                        var _errs717 = errors;
                                                                                                        for (var key66 in data) {
                                                                                                            if (!(key66 ===
                                                                                                                'prevPermissions' ||
                                                                                                                key66 ===
                                                                                                                    'participant' ||
                                                                                                                key66 === 'name' ||
                                                                                                                key66 === 'timestamp')) {
                                                                                                                validate11.errors = [
                                                                                                                    {
                                                                                                                        instancePath: instancePath,
                                                                                                                        schemaPath: '#/allOf/21/then/additionalProperties',
                                                                                                                        keyword: 'additionalProperties',
                                                                                                                        params: {
                                                                                                                            additionalProperty: key66,
                                                                                                                        },
                                                                                                                        message: 'must NOT have additional properties',
                                                                                                                    },
                                                                                                                ];
                                                                                                                return false;
                                                                                                                break;
                                                                                                            }
                                                                                                        }
                                                                                                        if (_errs717 === errors) {
                                                                                                            if (data.prevPermissions !==
                                                                                                                undefined) {
                                                                                                                var data264 = data.prevPermissions;
                                                                                                                var _errs718 = errors;
                                                                                                                if (errors === _errs718) {
                                                                                                                    if (data264 &&
                                                                                                                        typeof data264 ==
                                                                                                                            'object' &&
                                                                                                                        !Array.isArray(data264)) {
                                                                                                                        var missing67 = void 0;
                                                                                                                        if ((data264.canSubscribe ===
                                                                                                                            undefined &&
                                                                                                                            (missing67 =
                                                                                                                                'canSubscribe')) ||
                                                                                                                            (data264.canPublish ===
                                                                                                                                undefined &&
                                                                                                                                (missing67 =
                                                                                                                                    'canPublish')) ||
                                                                                                                            (data264.canPublishData ===
                                                                                                                                undefined &&
                                                                                                                                (missing67 =
                                                                                                                                    'canPublishData')) ||
                                                                                                                            (data264.hidden ===
                                                                                                                                undefined &&
                                                                                                                                (missing67 =
                                                                                                                                    'hidden')) ||
                                                                                                                            (data264.canUpdateMetadata ===
                                                                                                                                undefined &&
                                                                                                                                (missing67 =
                                                                                                                                    'canUpdateMetadata')) ||
                                                                                                                            (data264.canSubscribeMetrics ===
                                                                                                                                undefined &&
                                                                                                                                (missing67 =
                                                                                                                                    'canSubscribeMetrics'))) {
                                                                                                                            validate11.errors =
                                                                                                                                [
                                                                                                                                    {
                                                                                                                                        instancePath: instancePath +
                                                                                                                                            '/prevPermissions',
                                                                                                                                        schemaPath: '#/allOf/21/then/properties/prevPermissions/required',
                                                                                                                                        keyword: 'required',
                                                                                                                                        params: {
                                                                                                                                            missingProperty: missing67,
                                                                                                                                        },
                                                                                                                                        message: "must have required property '" +
                                                                                                                                            missing67 +
                                                                                                                                            "'",
                                                                                                                                    },
                                                                                                                                ];
                                                                                                                            return false;
                                                                                                                        }
                                                                                                                        else {
                                                                                                                            var _errs720 = errors;
                                                                                                                            for (var key67 in data264) {
                                                                                                                                if (!(key67 ===
                                                                                                                                    'canSubscribe' ||
                                                                                                                                    key67 ===
                                                                                                                                        'canPublish' ||
                                                                                                                                    key67 ===
                                                                                                                                        'canPublishData' ||
                                                                                                                                    key67 ===
                                                                                                                                        'hidden' ||
                                                                                                                                    key67 ===
                                                                                                                                        'canUpdateMetadata' ||
                                                                                                                                    key67 ===
                                                                                                                                        'canSubscribeMetrics')) {
                                                                                                                                    validate11.errors =
                                                                                                                                        [
                                                                                                                                            {
                                                                                                                                                instancePath: instancePath +
                                                                                                                                                    '/prevPermissions',
                                                                                                                                                schemaPath: '#/allOf/21/then/properties/prevPermissions/additionalProperties',
                                                                                                                                                keyword: 'additionalProperties',
                                                                                                                                                params: {
                                                                                                                                                    additionalProperty: key67,
                                                                                                                                                },
                                                                                                                                                message: 'must NOT have additional properties',
                                                                                                                                            },
                                                                                                                                        ];
                                                                                                                                    return false;
                                                                                                                                    break;
                                                                                                                                }
                                                                                                                            }
                                                                                                                            if (_errs720 ===
                                                                                                                                errors) {
                                                                                                                                if (data264.canSubscribe !==
                                                                                                                                    undefined) {
                                                                                                                                    var _errs721 = errors;
                                                                                                                                    if (typeof data264.canSubscribe !==
                                                                                                                                        'boolean') {
                                                                                                                                        validate11.errors =
                                                                                                                                            [
                                                                                                                                                {
                                                                                                                                                    instancePath: instancePath +
                                                                                                                                                        '/prevPermissions/canSubscribe',
                                                                                                                                                    schemaPath: '#/allOf/21/then/properties/prevPermissions/properties/canSubscribe/type',
                                                                                                                                                    keyword: 'type',
                                                                                                                                                    params: {
                                                                                                                                                        type: 'boolean',
                                                                                                                                                    },
                                                                                                                                                    message: 'must be boolean',
                                                                                                                                                },
                                                                                                                                            ];
                                                                                                                                        return false;
                                                                                                                                    }
                                                                                                                                    var valid124 = _errs721 ===
                                                                                                                                        errors;
                                                                                                                                }
                                                                                                                                else {
                                                                                                                                    var valid124 = true;
                                                                                                                                }
                                                                                                                                if (valid124) {
                                                                                                                                    if (data264.canPublish !==
                                                                                                                                        undefined) {
                                                                                                                                        var _errs723 = errors;
                                                                                                                                        if (typeof data264.canPublish !==
                                                                                                                                            'boolean') {
                                                                                                                                            validate11.errors =
                                                                                                                                                [
                                                                                                                                                    {
                                                                                                                                                        instancePath: instancePath +
                                                                                                                                                            '/prevPermissions/canPublish',
                                                                                                                                                        schemaPath: '#/allOf/21/then/properties/prevPermissions/properties/canPublish/type',
                                                                                                                                                        keyword: 'type',
                                                                                                                                                        params: {
                                                                                                                                                            type: 'boolean',
                                                                                                                                                        },
                                                                                                                                                        message: 'must be boolean',
                                                                                                                                                    },
                                                                                                                                                ];
                                                                                                                                            return false;
                                                                                                                                        }
                                                                                                                                        var valid124 = _errs723 ===
                                                                                                                                            errors;
                                                                                                                                    }
                                                                                                                                    else {
                                                                                                                                        var valid124 = true;
                                                                                                                                    }
                                                                                                                                    if (valid124) {
                                                                                                                                        if (data264.canPublishData !==
                                                                                                                                            undefined) {
                                                                                                                                            var _errs725 = errors;
                                                                                                                                            if (typeof data264.canPublishData !==
                                                                                                                                                'boolean') {
                                                                                                                                                validate11.errors =
                                                                                                                                                    [
                                                                                                                                                        {
                                                                                                                                                            instancePath: instancePath +
                                                                                                                                                                '/prevPermissions/canPublishData',
                                                                                                                                                            schemaPath: '#/allOf/21/then/properties/prevPermissions/properties/canPublishData/type',
                                                                                                                                                            keyword: 'type',
                                                                                                                                                            params: {
                                                                                                                                                                type: 'boolean',
                                                                                                                                                            },
                                                                                                                                                            message: 'must be boolean',
                                                                                                                                                        },
                                                                                                                                                    ];
                                                                                                                                                return false;
                                                                                                                                            }
                                                                                                                                            var valid124 = _errs725 ===
                                                                                                                                                errors;
                                                                                                                                        }
                                                                                                                                        else {
                                                                                                                                            var valid124 = true;
                                                                                                                                        }
                                                                                                                                        if (valid124) {
                                                                                                                                            if (data264.hidden !==
                                                                                                                                                undefined) {
                                                                                                                                                var _errs727 = errors;
                                                                                                                                                if (typeof data264.hidden !==
                                                                                                                                                    'boolean') {
                                                                                                                                                    validate11.errors =
                                                                                                                                                        [
                                                                                                                                                            {
                                                                                                                                                                instancePath: instancePath +
                                                                                                                                                                    '/prevPermissions/hidden',
                                                                                                                                                                schemaPath: '#/allOf/21/then/properties/prevPermissions/properties/hidden/type',
                                                                                                                                                                keyword: 'type',
                                                                                                                                                                params: {
                                                                                                                                                                    type: 'boolean',
                                                                                                                                                                },
                                                                                                                                                                message: 'must be boolean',
                                                                                                                                                            },
                                                                                                                                                        ];
                                                                                                                                                    return false;
                                                                                                                                                }
                                                                                                                                                var valid124 = _errs727 ===
                                                                                                                                                    errors;
                                                                                                                                            }
                                                                                                                                            else {
                                                                                                                                                var valid124 = true;
                                                                                                                                            }
                                                                                                                                            if (valid124) {
                                                                                                                                                if (data264.canUpdateMetadata !==
                                                                                                                                                    undefined) {
                                                                                                                                                    var _errs729 = errors;
                                                                                                                                                    if (typeof data264.canUpdateMetadata !==
                                                                                                                                                        'boolean') {
                                                                                                                                                        validate11.errors =
                                                                                                                                                            [
                                                                                                                                                                {
                                                                                                                                                                    instancePath: instancePath +
                                                                                                                                                                        '/prevPermissions/canUpdateMetadata',
                                                                                                                                                                    schemaPath: '#/allOf/21/then/properties/prevPermissions/properties/canUpdateMetadata/type',
                                                                                                                                                                    keyword: 'type',
                                                                                                                                                                    params: {
                                                                                                                                                                        type: 'boolean',
                                                                                                                                                                    },
                                                                                                                                                                    message: 'must be boolean',
                                                                                                                                                                },
                                                                                                                                                            ];
                                                                                                                                                        return false;
                                                                                                                                                    }
                                                                                                                                                    var valid124 = _errs729 ===
                                                                                                                                                        errors;
                                                                                                                                                }
                                                                                                                                                else {
                                                                                                                                                    var valid124 = true;
                                                                                                                                                }
                                                                                                                                                if (valid124) {
                                                                                                                                                    if (data264.canSubscribeMetrics !==
                                                                                                                                                        undefined) {
                                                                                                                                                        var _errs731 = errors;
                                                                                                                                                        if (typeof data264.canSubscribeMetrics !==
                                                                                                                                                            'boolean') {
                                                                                                                                                            validate11.errors =
                                                                                                                                                                [
                                                                                                                                                                    {
                                                                                                                                                                        instancePath: instancePath +
                                                                                                                                                                            '/prevPermissions/canSubscribeMetrics',
                                                                                                                                                                        schemaPath: '#/allOf/21/then/properties/prevPermissions/properties/canSubscribeMetrics/type',
                                                                                                                                                                        keyword: 'type',
                                                                                                                                                                        params: {
                                                                                                                                                                            type: 'boolean',
                                                                                                                                                                        },
                                                                                                                                                                        message: 'must be boolean',
                                                                                                                                                                    },
                                                                                                                                                                ];
                                                                                                                                                            return false;
                                                                                                                                                        }
                                                                                                                                                        var valid124 = _errs731 ===
                                                                                                                                                            errors;
                                                                                                                                                    }
                                                                                                                                                    else {
                                                                                                                                                        var valid124 = true;
                                                                                                                                                    }
                                                                                                                                                }
                                                                                                                                            }
                                                                                                                                        }
                                                                                                                                    }
                                                                                                                                }
                                                                                                                            }
                                                                                                                        }
                                                                                                                    }
                                                                                                                    else {
                                                                                                                        validate11.errors =
                                                                                                                            [
                                                                                                                                {
                                                                                                                                    instancePath: instancePath +
                                                                                                                                        '/prevPermissions',
                                                                                                                                    schemaPath: '#/allOf/21/then/properties/prevPermissions/type',
                                                                                                                                    keyword: 'type',
                                                                                                                                    params: {
                                                                                                                                        type: 'object',
                                                                                                                                    },
                                                                                                                                    message: 'must be object',
                                                                                                                                },
                                                                                                                            ];
                                                                                                                        return false;
                                                                                                                    }
                                                                                                                }
                                                                                                                var valid123 = _errs718 === errors;
                                                                                                            }
                                                                                                            else {
                                                                                                                var valid123 = true;
                                                                                                            }
                                                                                                            if (valid123) {
                                                                                                                if (data.participant !==
                                                                                                                    undefined) {
                                                                                                                    var data271 = data.participant;
                                                                                                                    var _errs733 = errors;
                                                                                                                    if (errors === _errs733) {
                                                                                                                        if (data271 &&
                                                                                                                            typeof data271 ==
                                                                                                                                'object' &&
                                                                                                                            !Array.isArray(data271)) {
                                                                                                                            var missing68 = void 0;
                                                                                                                            if ((data271.identity ===
                                                                                                                                undefined &&
                                                                                                                                (missing68 =
                                                                                                                                    'identity')) ||
                                                                                                                                (data271.sid ===
                                                                                                                                    undefined &&
                                                                                                                                    (missing68 =
                                                                                                                                        'sid'))) {
                                                                                                                                validate11.errors =
                                                                                                                                    [
                                                                                                                                        {
                                                                                                                                            instancePath: instancePath +
                                                                                                                                                '/participant',
                                                                                                                                            schemaPath: '#/allOf/21/then/properties/participant/required',
                                                                                                                                            keyword: 'required',
                                                                                                                                            params: {
                                                                                                                                                missingProperty: missing68,
                                                                                                                                            },
                                                                                                                                            message: "must have required property '" +
                                                                                                                                                missing68 +
                                                                                                                                                "'",
                                                                                                                                        },
                                                                                                                                    ];
                                                                                                                                return false;
                                                                                                                            }
                                                                                                                            else {
                                                                                                                                var _errs735 = errors;
                                                                                                                                for (var key68 in data271) {
                                                                                                                                    if (!(key68 ===
                                                                                                                                        'identity' ||
                                                                                                                                        key68 ===
                                                                                                                                            'sid' ||
                                                                                                                                        key68 ===
                                                                                                                                            'permissions')) {
                                                                                                                                        validate11.errors =
                                                                                                                                            [
                                                                                                                                                {
                                                                                                                                                    instancePath: instancePath +
                                                                                                                                                        '/participant',
                                                                                                                                                    schemaPath: '#/allOf/21/then/properties/participant/additionalProperties',
                                                                                                                                                    keyword: 'additionalProperties',
                                                                                                                                                    params: {
                                                                                                                                                        additionalProperty: key68,
                                                                                                                                                    },
                                                                                                                                                    message: 'must NOT have additional properties',
                                                                                                                                                },
                                                                                                                                            ];
                                                                                                                                        return false;
                                                                                                                                        break;
                                                                                                                                    }
                                                                                                                                }
                                                                                                                                if (_errs735 ===
                                                                                                                                    errors) {
                                                                                                                                    if (data271.identity !==
                                                                                                                                        undefined) {
                                                                                                                                        var _errs736 = errors;
                                                                                                                                        if (typeof data271.identity !==
                                                                                                                                            'string') {
                                                                                                                                            validate11.errors =
                                                                                                                                                [
                                                                                                                                                    {
                                                                                                                                                        instancePath: instancePath +
                                                                                                                                                            '/participant/identity',
                                                                                                                                                        schemaPath: '#/allOf/21/then/properties/participant/properties/identity/type',
                                                                                                                                                        keyword: 'type',
                                                                                                                                                        params: {
                                                                                                                                                            type: 'string',
                                                                                                                                                        },
                                                                                                                                                        message: 'must be string',
                                                                                                                                                    },
                                                                                                                                                ];
                                                                                                                                            return false;
                                                                                                                                        }
                                                                                                                                        var valid125 = _errs736 ===
                                                                                                                                            errors;
                                                                                                                                    }
                                                                                                                                    else {
                                                                                                                                        var valid125 = true;
                                                                                                                                    }
                                                                                                                                    if (valid125) {
                                                                                                                                        if (data271.sid !==
                                                                                                                                            undefined) {
                                                                                                                                            var _errs738 = errors;
                                                                                                                                            if (typeof data271.sid !==
                                                                                                                                                'string') {
                                                                                                                                                validate11.errors =
                                                                                                                                                    [
                                                                                                                                                        {
                                                                                                                                                            instancePath: instancePath +
                                                                                                                                                                '/participant/sid',
                                                                                                                                                            schemaPath: '#/allOf/21/then/properties/participant/properties/sid/type',
                                                                                                                                                            keyword: 'type',
                                                                                                                                                            params: {
                                                                                                                                                                type: 'string',
                                                                                                                                                            },
                                                                                                                                                            message: 'must be string',
                                                                                                                                                        },
                                                                                                                                                    ];
                                                                                                                                                return false;
                                                                                                                                            }
                                                                                                                                            var valid125 = _errs738 ===
                                                                                                                                                errors;
                                                                                                                                        }
                                                                                                                                        else {
                                                                                                                                            var valid125 = true;
                                                                                                                                        }
                                                                                                                                        if (valid125) {
                                                                                                                                            if (data271.permissions !==
                                                                                                                                                undefined) {
                                                                                                                                                var data274 = data271.permissions;
                                                                                                                                                var _errs740 = errors;
                                                                                                                                                if (errors ===
                                                                                                                                                    _errs740) {
                                                                                                                                                    if (data274 &&
                                                                                                                                                        typeof data274 ==
                                                                                                                                                            'object' &&
                                                                                                                                                        !Array.isArray(data274)) {
                                                                                                                                                        var missing69 = void 0;
                                                                                                                                                        if ((data274.canSubscribe ===
                                                                                                                                                            undefined &&
                                                                                                                                                            (missing69 =
                                                                                                                                                                'canSubscribe')) ||
                                                                                                                                                            (data274.canPublish ===
                                                                                                                                                                undefined &&
                                                                                                                                                                (missing69 =
                                                                                                                                                                    'canPublish')) ||
                                                                                                                                                            (data274.canPublishData ===
                                                                                                                                                                undefined &&
                                                                                                                                                                (missing69 =
                                                                                                                                                                    'canPublishData')) ||
                                                                                                                                                            (data274.hidden ===
                                                                                                                                                                undefined &&
                                                                                                                                                                (missing69 =
                                                                                                                                                                    'hidden')) ||
                                                                                                                                                            (data274.canUpdateMetadata ===
                                                                                                                                                                undefined &&
                                                                                                                                                                (missing69 =
                                                                                                                                                                    'canUpdateMetadata')) ||
                                                                                                                                                            (data274.canSubscribeMetrics ===
                                                                                                                                                                undefined &&
                                                                                                                                                                (missing69 =
                                                                                                                                                                    'canSubscribeMetrics'))) {
                                                                                                                                                            validate11.errors =
                                                                                                                                                                [
                                                                                                                                                                    {
                                                                                                                                                                        instancePath: instancePath +
                                                                                                                                                                            '/participant/permissions',
                                                                                                                                                                        schemaPath: '#/allOf/21/then/properties/participant/properties/permissions/required',
                                                                                                                                                                        keyword: 'required',
                                                                                                                                                                        params: {
                                                                                                                                                                            missingProperty: missing69,
                                                                                                                                                                        },
                                                                                                                                                                        message: "must have required property '" +
                                                                                                                                                                            missing69 +
                                                                                                                                                                            "'",
                                                                                                                                                                    },
                                                                                                                                                                ];
                                                                                                                                                            return false;
                                                                                                                                                        }
                                                                                                                                                        else {
                                                                                                                                                            var _errs742 = errors;
                                                                                                                                                            for (var key69 in data274) {
                                                                                                                                                                if (!(key69 ===
                                                                                                                                                                    'canSubscribe' ||
                                                                                                                                                                    key69 ===
                                                                                                                                                                        'canPublish' ||
                                                                                                                                                                    key69 ===
                                                                                                                                                                        'canPublishData' ||
                                                                                                                                                                    key69 ===
                                                                                                                                                                        'hidden' ||
                                                                                                                                                                    key69 ===
                                                                                                                                                                        'canUpdateMetadata' ||
                                                                                                                                                                    key69 ===
                                                                                                                                                                        'canSubscribeMetrics')) {
                                                                                                                                                                    validate11.errors =
                                                                                                                                                                        [
                                                                                                                                                                            {
                                                                                                                                                                                instancePath: instancePath +
                                                                                                                                                                                    '/participant/permissions',
                                                                                                                                                                                schemaPath: '#/allOf/21/then/properties/participant/properties/permissions/additionalProperties',
                                                                                                                                                                                keyword: 'additionalProperties',
                                                                                                                                                                                params: {
                                                                                                                                                                                    additionalProperty: key69,
                                                                                                                                                                                },
                                                                                                                                                                                message: 'must NOT have additional properties',
                                                                                                                                                                            },
                                                                                                                                                                        ];
                                                                                                                                                                    return false;
                                                                                                                                                                    break;
                                                                                                                                                                }
                                                                                                                                                            }
                                                                                                                                                            if (_errs742 ===
                                                                                                                                                                errors) {
                                                                                                                                                                if (data274.canSubscribe !==
                                                                                                                                                                    undefined) {
                                                                                                                                                                    var _errs743 = errors;
                                                                                                                                                                    if (typeof data274.canSubscribe !==
                                                                                                                                                                        'boolean') {
                                                                                                                                                                        validate11.errors =
                                                                                                                                                                            [
                                                                                                                                                                                {
                                                                                                                                                                                    instancePath: instancePath +
                                                                                                                                                                                        '/participant/permissions/canSubscribe',
                                                                                                                                                                                    schemaPath: '#/allOf/21/then/properties/participant/properties/permissions/properties/canSubscribe/type',
                                                                                                                                                                                    keyword: 'type',
                                                                                                                                                                                    params: {
                                                                                                                                                                                        type: 'boolean',
                                                                                                                                                                                    },
                                                                                                                                                                                    message: 'must be boolean',
                                                                                                                                                                                },
                                                                                                                                                                            ];
                                                                                                                                                                        return false;
                                                                                                                                                                    }
                                                                                                                                                                    var valid126 = _errs743 ===
                                                                                                                                                                        errors;
                                                                                                                                                                }
                                                                                                                                                                else {
                                                                                                                                                                    var valid126 = true;
                                                                                                                                                                }
                                                                                                                                                                if (valid126) {
                                                                                                                                                                    if (data274.canPublish !==
                                                                                                                                                                        undefined) {
                                                                                                                                                                        var _errs745 = errors;
                                                                                                                                                                        if (typeof data274.canPublish !==
                                                                                                                                                                            'boolean') {
                                                                                                                                                                            validate11.errors =
                                                                                                                                                                                [
                                                                                                                                                                                    {
                                                                                                                                                                                        instancePath: instancePath +
                                                                                                                                                                                            '/participant/permissions/canPublish',
                                                                                                                                                                                        schemaPath: '#/allOf/21/then/properties/participant/properties/permissions/properties/canPublish/type',
                                                                                                                                                                                        keyword: 'type',
                                                                                                                                                                                        params: {
                                                                                                                                                                                            type: 'boolean',
                                                                                                                                                                                        },
                                                                                                                                                                                        message: 'must be boolean',
                                                                                                                                                                                    },
                                                                                                                                                                                ];
                                                                                                                                                                            return false;
                                                                                                                                                                        }
                                                                                                                                                                        var valid126 = _errs745 ===
                                                                                                                                                                            errors;
                                                                                                                                                                    }
                                                                                                                                                                    else {
                                                                                                                                                                        var valid126 = true;
                                                                                                                                                                    }
                                                                                                                                                                    if (valid126) {
                                                                                                                                                                        if (data274.canPublishData !==
                                                                                                                                                                            undefined) {
                                                                                                                                                                            var _errs747 = errors;
                                                                                                                                                                            if (typeof data274.canPublishData !==
                                                                                                                                                                                'boolean') {
                                                                                                                                                                                validate11.errors =
                                                                                                                                                                                    [
                                                                                                                                                                                        {
                                                                                                                                                                                            instancePath: instancePath +
                                                                                                                                                                                                '/participant/permissions/canPublishData',
                                                                                                                                                                                            schemaPath: '#/allOf/21/then/properties/participant/properties/permissions/properties/canPublishData/type',
                                                                                                                                                                                            keyword: 'type',
                                                                                                                                                                                            params: {
                                                                                                                                                                                                type: 'boolean',
                                                                                                                                                                                            },
                                                                                                                                                                                            message: 'must be boolean',
                                                                                                                                                                                        },
                                                                                                                                                                                    ];
                                                                                                                                                                                return false;
                                                                                                                                                                            }
                                                                                                                                                                            var valid126 = _errs747 ===
                                                                                                                                                                                errors;
                                                                                                                                                                        }
                                                                                                                                                                        else {
                                                                                                                                                                            var valid126 = true;
                                                                                                                                                                        }
                                                                                                                                                                        if (valid126) {
                                                                                                                                                                            if (data274.hidden !==
                                                                                                                                                                                undefined) {
                                                                                                                                                                                var _errs749 = errors;
                                                                                                                                                                                if (typeof data274.hidden !==
                                                                                                                                                                                    'boolean') {
                                                                                                                                                                                    validate11.errors =
                                                                                                                                                                                        [
                                                                                                                                                                                            {
                                                                                                                                                                                                instancePath: instancePath +
                                                                                                                                                                                                    '/participant/permissions/hidden',
                                                                                                                                                                                                schemaPath: '#/allOf/21/then/properties/participant/properties/permissions/properties/hidden/type',
                                                                                                                                                                                                keyword: 'type',
                                                                                                                                                                                                params: {
                                                                                                                                                                                                    type: 'boolean',
                                                                                                                                                                                                },
                                                                                                                                                                                                message: 'must be boolean',
                                                                                                                                                                                            },
                                                                                                                                                                                        ];
                                                                                                                                                                                    return false;
                                                                                                                                                                                }
                                                                                                                                                                                var valid126 = _errs749 ===
                                                                                                                                                                                    errors;
                                                                                                                                                                            }
                                                                                                                                                                            else {
                                                                                                                                                                                var valid126 = true;
                                                                                                                                                                            }
                                                                                                                                                                            if (valid126) {
                                                                                                                                                                                if (data274.canUpdateMetadata !==
                                                                                                                                                                                    undefined) {
                                                                                                                                                                                    var _errs751 = errors;
                                                                                                                                                                                    if (typeof data274.canUpdateMetadata !==
                                                                                                                                                                                        'boolean') {
                                                                                                                                                                                        validate11.errors =
                                                                                                                                                                                            [
                                                                                                                                                                                                {
                                                                                                                                                                                                    instancePath: instancePath +
                                                                                                                                                                                                        '/participant/permissions/canUpdateMetadata',
                                                                                                                                                                                                    schemaPath: '#/allOf/21/then/properties/participant/properties/permissions/properties/canUpdateMetadata/type',
                                                                                                                                                                                                    keyword: 'type',
                                                                                                                                                                                                    params: {
                                                                                                                                                                                                        type: 'boolean',
                                                                                                                                                                                                    },
                                                                                                                                                                                                    message: 'must be boolean',
                                                                                                                                                                                                },
                                                                                                                                                                                            ];
                                                                                                                                                                                        return false;
                                                                                                                                                                                    }
                                                                                                                                                                                    var valid126 = _errs751 ===
                                                                                                                                                                                        errors;
                                                                                                                                                                                }
                                                                                                                                                                                else {
                                                                                                                                                                                    var valid126 = true;
                                                                                                                                                                                }
                                                                                                                                                                                if (valid126) {
                                                                                                                                                                                    if (data274.canSubscribeMetrics !==
                                                                                                                                                                                        undefined) {
                                                                                                                                                                                        var _errs753 = errors;
                                                                                                                                                                                        if (typeof data274.canSubscribeMetrics !==
                                                                                                                                                                                            'boolean') {
                                                                                                                                                                                            validate11.errors =
                                                                                                                                                                                                [
                                                                                                                                                                                                    {
                                                                                                                                                                                                        instancePath: instancePath +
                                                                                                                                                                                                            '/participant/permissions/canSubscribeMetrics',
                                                                                                                                                                                                        schemaPath: '#/allOf/21/then/properties/participant/properties/permissions/properties/canSubscribeMetrics/type',
                                                                                                                                                                                                        keyword: 'type',
                                                                                                                                                                                                        params: {
                                                                                                                                                                                                            type: 'boolean',
                                                                                                                                                                                                        },
                                                                                                                                                                                                        message: 'must be boolean',
                                                                                                                                                                                                    },
                                                                                                                                                                                                ];
                                                                                                                                                                                            return false;
                                                                                                                                                                                        }
                                                                                                                                                                                        var valid126 = _errs753 ===
                                                                                                                                                                                            errors;
                                                                                                                                                                                    }
                                                                                                                                                                                    else {
                                                                                                                                                                                        var valid126 = true;
                                                                                                                                                                                    }
                                                                                                                                                                                }
                                                                                                                                                                            }
                                                                                                                                                                        }
                                                                                                                                                                    }
                                                                                                                                                                }
                                                                                                                                                            }
                                                                                                                                                        }
                                                                                                                                                    }
                                                                                                                                                    else {
                                                                                                                                                        validate11.errors =
                                                                                                                                                            [
                                                                                                                                                                {
                                                                                                                                                                    instancePath: instancePath +
                                                                                                                                                                        '/participant/permissions',
                                                                                                                                                                    schemaPath: '#/allOf/21/then/properties/participant/properties/permissions/type',
                                                                                                                                                                    keyword: 'type',
                                                                                                                                                                    params: {
                                                                                                                                                                        type: 'object',
                                                                                                                                                                    },
                                                                                                                                                                    message: 'must be object',
                                                                                                                                                                },
                                                                                                                                                            ];
                                                                                                                                                        return false;
                                                                                                                                                    }
                                                                                                                                                }
                                                                                                                                                var valid125 = _errs740 ===
                                                                                                                                                    errors;
                                                                                                                                            }
                                                                                                                                            else {
                                                                                                                                                var valid125 = true;
                                                                                                                                            }
                                                                                                                                        }
                                                                                                                                    }
                                                                                                                                }
                                                                                                                            }
                                                                                                                        }
                                                                                                                        else {
                                                                                                                            validate11.errors =
                                                                                                                                [
                                                                                                                                    {
                                                                                                                                        instancePath: instancePath +
                                                                                                                                            '/participant',
                                                                                                                                        schemaPath: '#/allOf/21/then/properties/participant/type',
                                                                                                                                        keyword: 'type',
                                                                                                                                        params: {
                                                                                                                                            type: 'object',
                                                                                                                                        },
                                                                                                                                        message: 'must be object',
                                                                                                                                    },
                                                                                                                                ];
                                                                                                                            return false;
                                                                                                                        }
                                                                                                                    }
                                                                                                                    var valid123 = _errs733 === errors;
                                                                                                                }
                                                                                                                else {
                                                                                                                    var valid123 = true;
                                                                                                                }
                                                                                                                if (valid123) {
                                                                                                                    if (data.name !==
                                                                                                                        undefined) {
                                                                                                                        var data281 = data.name;
                                                                                                                        var _errs755 = errors;
                                                                                                                        if (typeof data281 !==
                                                                                                                            'string') {
                                                                                                                            validate11.errors =
                                                                                                                                [
                                                                                                                                    {
                                                                                                                                        instancePath: instancePath +
                                                                                                                                            '/name',
                                                                                                                                        schemaPath: '#/allOf/21/then/properties/name/type',
                                                                                                                                        keyword: 'type',
                                                                                                                                        params: {
                                                                                                                                            type: 'string',
                                                                                                                                        },
                                                                                                                                        message: 'must be string',
                                                                                                                                    },
                                                                                                                                ];
                                                                                                                            return false;
                                                                                                                        }
                                                                                                                        if ('participantPermissionsChanged' !==
                                                                                                                            data281) {
                                                                                                                            validate11.errors =
                                                                                                                                [
                                                                                                                                    {
                                                                                                                                        instancePath: instancePath +
                                                                                                                                            '/name',
                                                                                                                                        schemaPath: '#/allOf/21/then/properties/name/const',
                                                                                                                                        keyword: 'const',
                                                                                                                                        params: {
                                                                                                                                            allowedValue: 'participantPermissionsChanged',
                                                                                                                                        },
                                                                                                                                        message: 'must be equal to constant',
                                                                                                                                    },
                                                                                                                                ];
                                                                                                                            return false;
                                                                                                                        }
                                                                                                                        var valid123 = _errs755 ===
                                                                                                                            errors;
                                                                                                                    }
                                                                                                                    else {
                                                                                                                        var valid123 = true;
                                                                                                                    }
                                                                                                                    if (valid123) {
                                                                                                                        if (data.timestamp !==
                                                                                                                            undefined) {
                                                                                                                            var data282 = data.timestamp;
                                                                                                                            var _errs757 = errors;
                                                                                                                            if (!(typeof data282 ==
                                                                                                                                'number' &&
                                                                                                                                isFinite(data282))) {
                                                                                                                                validate11.errors =
                                                                                                                                    [
                                                                                                                                        {
                                                                                                                                            instancePath: instancePath +
                                                                                                                                                '/timestamp',
                                                                                                                                            schemaPath: '#/allOf/21/then/properties/timestamp/type',
                                                                                                                                            keyword: 'type',
                                                                                                                                            params: {
                                                                                                                                                type: 'number',
                                                                                                                                            },
                                                                                                                                            message: 'must be number',
                                                                                                                                        },
                                                                                                                                    ];
                                                                                                                                return false;
                                                                                                                            }
                                                                                                                            var valid123 = _errs757 ===
                                                                                                                                errors;
                                                                                                                        }
                                                                                                                        else {
                                                                                                                            var valid123 = true;
                                                                                                                        }
                                                                                                                    }
                                                                                                                }
                                                                                                            }
                                                                                                        }
                                                                                                    }
                                                                                                }
                                                                                                else {
                                                                                                    validate11.errors = [
                                                                                                        {
                                                                                                            instancePath: instancePath,
                                                                                                            schemaPath: '#/allOf/21/then/type',
                                                                                                            keyword: 'type',
                                                                                                            params: {
                                                                                                                type: 'object',
                                                                                                            },
                                                                                                            message: 'must be object',
                                                                                                        },
                                                                                                    ];
                                                                                                    return false;
                                                                                                }
                                                                                            }
                                                                                            var _valid21 = _errs715 === errors;
                                                                                            valid121 = _valid21;
                                                                                        }
                                                                                        if (!valid121) {
                                                                                            var err65 = {
                                                                                                instancePath: instancePath,
                                                                                                schemaPath: '#/allOf/21/if',
                                                                                                keyword: 'if',
                                                                                                params: {
                                                                                                    failingKeyword: 'then',
                                                                                                },
                                                                                                message: 'must match "then" schema',
                                                                                            };
                                                                                            if (vErrors === null) {
                                                                                                vErrors = [err65];
                                                                                            }
                                                                                            else {
                                                                                                vErrors.push(err65);
                                                                                            }
                                                                                            errors++;
                                                                                            validate11.errors = vErrors;
                                                                                            return false;
                                                                                        }
                                                                                        var valid0 = _errs710 === errors;
                                                                                        if (valid0) {
                                                                                            var _errs759 = errors;
                                                                                            var _errs760 = errors;
                                                                                            var valid127 = true;
                                                                                            var _errs761 = errors;
                                                                                            if (data &&
                                                                                                typeof data == 'object' &&
                                                                                                !Array.isArray(data)) {
                                                                                                if (data.name !== undefined) {
                                                                                                    var data283 = data.name;
                                                                                                    if (typeof data283 !== 'string') {
                                                                                                        var err66 = {};
                                                                                                        if (vErrors === null) {
                                                                                                            vErrors = [err66];
                                                                                                        }
                                                                                                        else {
                                                                                                            vErrors.push(err66);
                                                                                                        }
                                                                                                        errors++;
                                                                                                    }
                                                                                                    if ('participantAttributesChanged' !==
                                                                                                        data283) {
                                                                                                        var err67 = {};
                                                                                                        if (vErrors === null) {
                                                                                                            vErrors = [err67];
                                                                                                        }
                                                                                                        else {
                                                                                                            vErrors.push(err67);
                                                                                                        }
                                                                                                        errors++;
                                                                                                    }
                                                                                                }
                                                                                            }
                                                                                            var _valid22 = _errs761 === errors;
                                                                                            errors = _errs760;
                                                                                            if (vErrors !== null) {
                                                                                                if (_errs760) {
                                                                                                    vErrors.length = _errs760;
                                                                                                }
                                                                                                else {
                                                                                                    vErrors = null;
                                                                                                }
                                                                                            }
                                                                                            if (_valid22) {
                                                                                                var _errs764 = errors;
                                                                                                if (errors === _errs764) {
                                                                                                    if (data &&
                                                                                                        typeof data == 'object' &&
                                                                                                        !Array.isArray(data)) {
                                                                                                        var missing70 = void 0;
                                                                                                        if ((data.changedAttributes ===
                                                                                                            undefined &&
                                                                                                            (missing70 =
                                                                                                                'changedAttributes')) ||
                                                                                                            (data.name ===
                                                                                                                undefined &&
                                                                                                                (missing70 = 'name')) ||
                                                                                                            (data.participant ===
                                                                                                                undefined &&
                                                                                                                (missing70 =
                                                                                                                    'participant')) ||
                                                                                                            (data.timestamp ===
                                                                                                                undefined &&
                                                                                                                (missing70 =
                                                                                                                    'timestamp'))) {
                                                                                                            validate11.errors = [
                                                                                                                {
                                                                                                                    instancePath: instancePath,
                                                                                                                    schemaPath: '#/allOf/22/then/required',
                                                                                                                    keyword: 'required',
                                                                                                                    params: {
                                                                                                                        missingProperty: missing70,
                                                                                                                    },
                                                                                                                    message: "must have required property '" +
                                                                                                                        missing70 +
                                                                                                                        "'",
                                                                                                                },
                                                                                                            ];
                                                                                                            return false;
                                                                                                        }
                                                                                                        else {
                                                                                                            var _errs766 = errors;
                                                                                                            for (var key70 in data) {
                                                                                                                if (!(key70 ===
                                                                                                                    'changedAttributes' ||
                                                                                                                    key70 ===
                                                                                                                        'participant' ||
                                                                                                                    key70 === 'name' ||
                                                                                                                    key70 ===
                                                                                                                        'timestamp')) {
                                                                                                                    validate11.errors = [
                                                                                                                        {
                                                                                                                            instancePath: instancePath,
                                                                                                                            schemaPath: '#/allOf/22/then/additionalProperties',
                                                                                                                            keyword: 'additionalProperties',
                                                                                                                            params: {
                                                                                                                                additionalProperty: key70,
                                                                                                                            },
                                                                                                                            message: 'must NOT have additional properties',
                                                                                                                        },
                                                                                                                    ];
                                                                                                                    return false;
                                                                                                                    break;
                                                                                                                }
                                                                                                            }
                                                                                                            if (_errs766 === errors) {
                                                                                                                if (data.changedAttributes !==
                                                                                                                    undefined) {
                                                                                                                    var data284 = data.changedAttributes;
                                                                                                                    var _errs767 = errors;
                                                                                                                    if (errors === _errs767) {
                                                                                                                        if (data284 &&
                                                                                                                            typeof data284 ==
                                                                                                                                'object' &&
                                                                                                                            !Array.isArray(data284)) {
                                                                                                                            for (var key71 in data284) {
                                                                                                                                var _errs770 = errors;
                                                                                                                                if (typeof data284[key71] !== 'string') {
                                                                                                                                    validate11.errors =
                                                                                                                                        [
                                                                                                                                            {
                                                                                                                                                instancePath: instancePath +
                                                                                                                                                    '/changedAttributes/' +
                                                                                                                                                    key71
                                                                                                                                                        .replace(/~/g, '~0')
                                                                                                                                                        .replace(/\//g, '~1'),
                                                                                                                                                schemaPath: '#/allOf/22/then/properties/changedAttributes/additionalProperties/type',
                                                                                                                                                keyword: 'type',
                                                                                                                                                params: {
                                                                                                                                                    type: 'string',
                                                                                                                                                },
                                                                                                                                                message: 'must be string',
                                                                                                                                            },
                                                                                                                                        ];
                                                                                                                                    return false;
                                                                                                                                }
                                                                                                                                var valid130 = _errs770 ===
                                                                                                                                    errors;
                                                                                                                                if (!valid130) {
                                                                                                                                    break;
                                                                                                                                }
                                                                                                                            }
                                                                                                                        }
                                                                                                                        else {
                                                                                                                            validate11.errors =
                                                                                                                                [
                                                                                                                                    {
                                                                                                                                        instancePath: instancePath +
                                                                                                                                            '/changedAttributes',
                                                                                                                                        schemaPath: '#/allOf/22/then/properties/changedAttributes/type',
                                                                                                                                        keyword: 'type',
                                                                                                                                        params: {
                                                                                                                                            type: 'object',
                                                                                                                                        },
                                                                                                                                        message: 'must be object',
                                                                                                                                    },
                                                                                                                                ];
                                                                                                                            return false;
                                                                                                                        }
                                                                                                                    }
                                                                                                                    var valid129 = _errs767 === errors;
                                                                                                                }
                                                                                                                else {
                                                                                                                    var valid129 = true;
                                                                                                                }
                                                                                                                if (valid129) {
                                                                                                                    if (data.participant !==
                                                                                                                        undefined) {
                                                                                                                        var data286 = data.participant;
                                                                                                                        var _errs772 = errors;
                                                                                                                        if (errors ===
                                                                                                                            _errs772) {
                                                                                                                            if (data286 &&
                                                                                                                                typeof data286 ==
                                                                                                                                    'object' &&
                                                                                                                                !Array.isArray(data286)) {
                                                                                                                                var missing71 = void 0;
                                                                                                                                if ((data286.identity ===
                                                                                                                                    undefined &&
                                                                                                                                    (missing71 =
                                                                                                                                        'identity')) ||
                                                                                                                                    (data286.sid ===
                                                                                                                                        undefined &&
                                                                                                                                        (missing71 =
                                                                                                                                            'sid'))) {
                                                                                                                                    validate11.errors =
                                                                                                                                        [
                                                                                                                                            {
                                                                                                                                                instancePath: instancePath +
                                                                                                                                                    '/participant',
                                                                                                                                                schemaPath: '#/allOf/22/then/properties/participant/required',
                                                                                                                                                keyword: 'required',
                                                                                                                                                params: {
                                                                                                                                                    missingProperty: missing71,
                                                                                                                                                },
                                                                                                                                                message: "must have required property '" +
                                                                                                                                                    missing71 +
                                                                                                                                                    "'",
                                                                                                                                            },
                                                                                                                                        ];
                                                                                                                                    return false;
                                                                                                                                }
                                                                                                                                else {
                                                                                                                                    var _errs774 = errors;
                                                                                                                                    for (var key72 in data286) {
                                                                                                                                        if (!(key72 ===
                                                                                                                                            'identity' ||
                                                                                                                                            key72 ===
                                                                                                                                                'sid' ||
                                                                                                                                            key72 ===
                                                                                                                                                'permissions')) {
                                                                                                                                            validate11.errors =
                                                                                                                                                [
                                                                                                                                                    {
                                                                                                                                                        instancePath: instancePath +
                                                                                                                                                            '/participant',
                                                                                                                                                        schemaPath: '#/allOf/22/then/properties/participant/additionalProperties',
                                                                                                                                                        keyword: 'additionalProperties',
                                                                                                                                                        params: {
                                                                                                                                                            additionalProperty: key72,
                                                                                                                                                        },
                                                                                                                                                        message: 'must NOT have additional properties',
                                                                                                                                                    },
                                                                                                                                                ];
                                                                                                                                            return false;
                                                                                                                                            break;
                                                                                                                                        }
                                                                                                                                    }
                                                                                                                                    if (_errs774 ===
                                                                                                                                        errors) {
                                                                                                                                        if (data286.identity !==
                                                                                                                                            undefined) {
                                                                                                                                            var _errs775 = errors;
                                                                                                                                            if (typeof data286.identity !==
                                                                                                                                                'string') {
                                                                                                                                                validate11.errors =
                                                                                                                                                    [
                                                                                                                                                        {
                                                                                                                                                            instancePath: instancePath +
                                                                                                                                                                '/participant/identity',
                                                                                                                                                            schemaPath: '#/allOf/22/then/properties/participant/properties/identity/type',
                                                                                                                                                            keyword: 'type',
                                                                                                                                                            params: {
                                                                                                                                                                type: 'string',
                                                                                                                                                            },
                                                                                                                                                            message: 'must be string',
                                                                                                                                                        },
                                                                                                                                                    ];
                                                                                                                                                return false;
                                                                                                                                            }
                                                                                                                                            var valid131 = _errs775 ===
                                                                                                                                                errors;
                                                                                                                                        }
                                                                                                                                        else {
                                                                                                                                            var valid131 = true;
                                                                                                                                        }
                                                                                                                                        if (valid131) {
                                                                                                                                            if (data286.sid !==
                                                                                                                                                undefined) {
                                                                                                                                                var _errs777 = errors;
                                                                                                                                                if (typeof data286.sid !==
                                                                                                                                                    'string') {
                                                                                                                                                    validate11.errors =
                                                                                                                                                        [
                                                                                                                                                            {
                                                                                                                                                                instancePath: instancePath +
                                                                                                                                                                    '/participant/sid',
                                                                                                                                                                schemaPath: '#/allOf/22/then/properties/participant/properties/sid/type',
                                                                                                                                                                keyword: 'type',
                                                                                                                                                                params: {
                                                                                                                                                                    type: 'string',
                                                                                                                                                                },
                                                                                                                                                                message: 'must be string',
                                                                                                                                                            },
                                                                                                                                                        ];
                                                                                                                                                    return false;
                                                                                                                                                }
                                                                                                                                                var valid131 = _errs777 ===
                                                                                                                                                    errors;
                                                                                                                                            }
                                                                                                                                            else {
                                                                                                                                                var valid131 = true;
                                                                                                                                            }
                                                                                                                                            if (valid131) {
                                                                                                                                                if (data286.permissions !==
                                                                                                                                                    undefined) {
                                                                                                                                                    var data289 = data286.permissions;
                                                                                                                                                    var _errs779 = errors;
                                                                                                                                                    if (errors ===
                                                                                                                                                        _errs779) {
                                                                                                                                                        if (data289 &&
                                                                                                                                                            typeof data289 ==
                                                                                                                                                                'object' &&
                                                                                                                                                            !Array.isArray(data289)) {
                                                                                                                                                            var missing72 = void 0;
                                                                                                                                                            if ((data289.canSubscribe ===
                                                                                                                                                                undefined &&
                                                                                                                                                                (missing72 =
                                                                                                                                                                    'canSubscribe')) ||
                                                                                                                                                                (data289.canPublish ===
                                                                                                                                                                    undefined &&
                                                                                                                                                                    (missing72 =
                                                                                                                                                                        'canPublish')) ||
                                                                                                                                                                (data289.canPublishData ===
                                                                                                                                                                    undefined &&
                                                                                                                                                                    (missing72 =
                                                                                                                                                                        'canPublishData')) ||
                                                                                                                                                                (data289.hidden ===
                                                                                                                                                                    undefined &&
                                                                                                                                                                    (missing72 =
                                                                                                                                                                        'hidden')) ||
                                                                                                                                                                (data289.canUpdateMetadata ===
                                                                                                                                                                    undefined &&
                                                                                                                                                                    (missing72 =
                                                                                                                                                                        'canUpdateMetadata')) ||
                                                                                                                                                                (data289.canSubscribeMetrics ===
                                                                                                                                                                    undefined &&
                                                                                                                                                                    (missing72 =
                                                                                                                                                                        'canSubscribeMetrics'))) {
                                                                                                                                                                validate11.errors =
                                                                                                                                                                    [
                                                                                                                                                                        {
                                                                                                                                                                            instancePath: instancePath +
                                                                                                                                                                                '/participant/permissions',
                                                                                                                                                                            schemaPath: '#/allOf/22/then/properties/participant/properties/permissions/required',
                                                                                                                                                                            keyword: 'required',
                                                                                                                                                                            params: {
                                                                                                                                                                                missingProperty: missing72,
                                                                                                                                                                            },
                                                                                                                                                                            message: "must have required property '" +
                                                                                                                                                                                missing72 +
                                                                                                                                                                                "'",
                                                                                                                                                                        },
                                                                                                                                                                    ];
                                                                                                                                                                return false;
                                                                                                                                                            }
                                                                                                                                                            else {
                                                                                                                                                                var _errs781 = errors;
                                                                                                                                                                for (var key73 in data289) {
                                                                                                                                                                    if (!(key73 ===
                                                                                                                                                                        'canSubscribe' ||
                                                                                                                                                                        key73 ===
                                                                                                                                                                            'canPublish' ||
                                                                                                                                                                        key73 ===
                                                                                                                                                                            'canPublishData' ||
                                                                                                                                                                        key73 ===
                                                                                                                                                                            'hidden' ||
                                                                                                                                                                        key73 ===
                                                                                                                                                                            'canUpdateMetadata' ||
                                                                                                                                                                        key73 ===
                                                                                                                                                                            'canSubscribeMetrics')) {
                                                                                                                                                                        validate11.errors =
                                                                                                                                                                            [
                                                                                                                                                                                {
                                                                                                                                                                                    instancePath: instancePath +
                                                                                                                                                                                        '/participant/permissions',
                                                                                                                                                                                    schemaPath: '#/allOf/22/then/properties/participant/properties/permissions/additionalProperties',
                                                                                                                                                                                    keyword: 'additionalProperties',
                                                                                                                                                                                    params: {
                                                                                                                                                                                        additionalProperty: key73,
                                                                                                                                                                                    },
                                                                                                                                                                                    message: 'must NOT have additional properties',
                                                                                                                                                                                },
                                                                                                                                                                            ];
                                                                                                                                                                        return false;
                                                                                                                                                                        break;
                                                                                                                                                                    }
                                                                                                                                                                }
                                                                                                                                                                if (_errs781 ===
                                                                                                                                                                    errors) {
                                                                                                                                                                    if (data289.canSubscribe !==
                                                                                                                                                                        undefined) {
                                                                                                                                                                        var _errs782 = errors;
                                                                                                                                                                        if (typeof data289.canSubscribe !==
                                                                                                                                                                            'boolean') {
                                                                                                                                                                            validate11.errors =
                                                                                                                                                                                [
                                                                                                                                                                                    {
                                                                                                                                                                                        instancePath: instancePath +
                                                                                                                                                                                            '/participant/permissions/canSubscribe',
                                                                                                                                                                                        schemaPath: '#/allOf/22/then/properties/participant/properties/permissions/properties/canSubscribe/type',
                                                                                                                                                                                        keyword: 'type',
                                                                                                                                                                                        params: {
                                                                                                                                                                                            type: 'boolean',
                                                                                                                                                                                        },
                                                                                                                                                                                        message: 'must be boolean',
                                                                                                                                                                                    },
                                                                                                                                                                                ];
                                                                                                                                                                            return false;
                                                                                                                                                                        }
                                                                                                                                                                        var valid132 = _errs782 ===
                                                                                                                                                                            errors;
                                                                                                                                                                    }
                                                                                                                                                                    else {
                                                                                                                                                                        var valid132 = true;
                                                                                                                                                                    }
                                                                                                                                                                    if (valid132) {
                                                                                                                                                                        if (data289.canPublish !==
                                                                                                                                                                            undefined) {
                                                                                                                                                                            var _errs784 = errors;
                                                                                                                                                                            if (typeof data289.canPublish !==
                                                                                                                                                                                'boolean') {
                                                                                                                                                                                validate11.errors =
                                                                                                                                                                                    [
                                                                                                                                                                                        {
                                                                                                                                                                                            instancePath: instancePath +
                                                                                                                                                                                                '/participant/permissions/canPublish',
                                                                                                                                                                                            schemaPath: '#/allOf/22/then/properties/participant/properties/permissions/properties/canPublish/type',
                                                                                                                                                                                            keyword: 'type',
                                                                                                                                                                                            params: {
                                                                                                                                                                                                type: 'boolean',
                                                                                                                                                                                            },
                                                                                                                                                                                            message: 'must be boolean',
                                                                                                                                                                                        },
                                                                                                                                                                                    ];
                                                                                                                                                                                return false;
                                                                                                                                                                            }
                                                                                                                                                                            var valid132 = _errs784 ===
                                                                                                                                                                                errors;
                                                                                                                                                                        }
                                                                                                                                                                        else {
                                                                                                                                                                            var valid132 = true;
                                                                                                                                                                        }
                                                                                                                                                                        if (valid132) {
                                                                                                                                                                            if (data289.canPublishData !==
                                                                                                                                                                                undefined) {
                                                                                                                                                                                var _errs786 = errors;
                                                                                                                                                                                if (typeof data289.canPublishData !==
                                                                                                                                                                                    'boolean') {
                                                                                                                                                                                    validate11.errors =
                                                                                                                                                                                        [
                                                                                                                                                                                            {
                                                                                                                                                                                                instancePath: instancePath +
                                                                                                                                                                                                    '/participant/permissions/canPublishData',
                                                                                                                                                                                                schemaPath: '#/allOf/22/then/properties/participant/properties/permissions/properties/canPublishData/type',
                                                                                                                                                                                                keyword: 'type',
                                                                                                                                                                                                params: {
                                                                                                                                                                                                    type: 'boolean',
                                                                                                                                                                                                },
                                                                                                                                                                                                message: 'must be boolean',
                                                                                                                                                                                            },
                                                                                                                                                                                        ];
                                                                                                                                                                                    return false;
                                                                                                                                                                                }
                                                                                                                                                                                var valid132 = _errs786 ===
                                                                                                                                                                                    errors;
                                                                                                                                                                            }
                                                                                                                                                                            else {
                                                                                                                                                                                var valid132 = true;
                                                                                                                                                                            }
                                                                                                                                                                            if (valid132) {
                                                                                                                                                                                if (data289.hidden !==
                                                                                                                                                                                    undefined) {
                                                                                                                                                                                    var _errs788 = errors;
                                                                                                                                                                                    if (typeof data289.hidden !==
                                                                                                                                                                                        'boolean') {
                                                                                                                                                                                        validate11.errors =
                                                                                                                                                                                            [
                                                                                                                                                                                                {
                                                                                                                                                                                                    instancePath: instancePath +
                                                                                                                                                                                                        '/participant/permissions/hidden',
                                                                                                                                                                                                    schemaPath: '#/allOf/22/then/properties/participant/properties/permissions/properties/hidden/type',
                                                                                                                                                                                                    keyword: 'type',
                                                                                                                                                                                                    params: {
                                                                                                                                                                                                        type: 'boolean',
                                                                                                                                                                                                    },
                                                                                                                                                                                                    message: 'must be boolean',
                                                                                                                                                                                                },
                                                                                                                                                                                            ];
                                                                                                                                                                                        return false;
                                                                                                                                                                                    }
                                                                                                                                                                                    var valid132 = _errs788 ===
                                                                                                                                                                                        errors;
                                                                                                                                                                                }
                                                                                                                                                                                else {
                                                                                                                                                                                    var valid132 = true;
                                                                                                                                                                                }
                                                                                                                                                                                if (valid132) {
                                                                                                                                                                                    if (data289.canUpdateMetadata !==
                                                                                                                                                                                        undefined) {
                                                                                                                                                                                        var _errs790 = errors;
                                                                                                                                                                                        if (typeof data289.canUpdateMetadata !==
                                                                                                                                                                                            'boolean') {
                                                                                                                                                                                            validate11.errors =
                                                                                                                                                                                                [
                                                                                                                                                                                                    {
                                                                                                                                                                                                        instancePath: instancePath +
                                                                                                                                                                                                            '/participant/permissions/canUpdateMetadata',
                                                                                                                                                                                                        schemaPath: '#/allOf/22/then/properties/participant/properties/permissions/properties/canUpdateMetadata/type',
                                                                                                                                                                                                        keyword: 'type',
                                                                                                                                                                                                        params: {
                                                                                                                                                                                                            type: 'boolean',
                                                                                                                                                                                                        },
                                                                                                                                                                                                        message: 'must be boolean',
                                                                                                                                                                                                    },
                                                                                                                                                                                                ];
                                                                                                                                                                                            return false;
                                                                                                                                                                                        }
                                                                                                                                                                                        var valid132 = _errs790 ===
                                                                                                                                                                                            errors;
                                                                                                                                                                                    }
                                                                                                                                                                                    else {
                                                                                                                                                                                        var valid132 = true;
                                                                                                                                                                                    }
                                                                                                                                                                                    if (valid132) {
                                                                                                                                                                                        if (data289.canSubscribeMetrics !==
                                                                                                                                                                                            undefined) {
                                                                                                                                                                                            var _errs792 = errors;
                                                                                                                                                                                            if (typeof data289.canSubscribeMetrics !==
                                                                                                                                                                                                'boolean') {
                                                                                                                                                                                                validate11.errors =
                                                                                                                                                                                                    [
                                                                                                                                                                                                        {
                                                                                                                                                                                                            instancePath: instancePath +
                                                                                                                                                                                                                '/participant/permissions/canSubscribeMetrics',
                                                                                                                                                                                                            schemaPath: '#/allOf/22/then/properties/participant/properties/permissions/properties/canSubscribeMetrics/type',
                                                                                                                                                                                                            keyword: 'type',
                                                                                                                                                                                                            params: {
                                                                                                                                                                                                                type: 'boolean',
                                                                                                                                                                                                            },
                                                                                                                                                                                                            message: 'must be boolean',
                                                                                                                                                                                                        },
                                                                                                                                                                                                    ];
                                                                                                                                                                                                return false;
                                                                                                                                                                                            }
                                                                                                                                                                                            var valid132 = _errs792 ===
                                                                                                                                                                                                errors;
                                                                                                                                                                                        }
                                                                                                                                                                                        else {
                                                                                                                                                                                            var valid132 = true;
                                                                                                                                                                                        }
                                                                                                                                                                                    }
                                                                                                                                                                                }
                                                                                                                                                                            }
                                                                                                                                                                        }
                                                                                                                                                                    }
                                                                                                                                                                }
                                                                                                                                                            }
                                                                                                                                                        }
                                                                                                                                                        else {
                                                                                                                                                            validate11.errors =
                                                                                                                                                                [
                                                                                                                                                                    {
                                                                                                                                                                        instancePath: instancePath +
                                                                                                                                                                            '/participant/permissions',
                                                                                                                                                                        schemaPath: '#/allOf/22/then/properties/participant/properties/permissions/type',
                                                                                                                                                                        keyword: 'type',
                                                                                                                                                                        params: {
                                                                                                                                                                            type: 'object',
                                                                                                                                                                        },
                                                                                                                                                                        message: 'must be object',
                                                                                                                                                                    },
                                                                                                                                                                ];
                                                                                                                                                            return false;
                                                                                                                                                        }
                                                                                                                                                    }
                                                                                                                                                    var valid131 = _errs779 ===
                                                                                                                                                        errors;
                                                                                                                                                }
                                                                                                                                                else {
                                                                                                                                                    var valid131 = true;
                                                                                                                                                }
                                                                                                                                            }
                                                                                                                                        }
                                                                                                                                    }
                                                                                                                                }
                                                                                                                            }
                                                                                                                            else {
                                                                                                                                validate11.errors =
                                                                                                                                    [
                                                                                                                                        {
                                                                                                                                            instancePath: instancePath +
                                                                                                                                                '/participant',
                                                                                                                                            schemaPath: '#/allOf/22/then/properties/participant/type',
                                                                                                                                            keyword: 'type',
                                                                                                                                            params: {
                                                                                                                                                type: 'object',
                                                                                                                                            },
                                                                                                                                            message: 'must be object',
                                                                                                                                        },
                                                                                                                                    ];
                                                                                                                                return false;
                                                                                                                            }
                                                                                                                        }
                                                                                                                        var valid129 = _errs772 ===
                                                                                                                            errors;
                                                                                                                    }
                                                                                                                    else {
                                                                                                                        var valid129 = true;
                                                                                                                    }
                                                                                                                    if (valid129) {
                                                                                                                        if (data.name !==
                                                                                                                            undefined) {
                                                                                                                            var data296 = data.name;
                                                                                                                            var _errs794 = errors;
                                                                                                                            if (typeof data296 !==
                                                                                                                                'string') {
                                                                                                                                validate11.errors =
                                                                                                                                    [
                                                                                                                                        {
                                                                                                                                            instancePath: instancePath +
                                                                                                                                                '/name',
                                                                                                                                            schemaPath: '#/allOf/22/then/properties/name/type',
                                                                                                                                            keyword: 'type',
                                                                                                                                            params: {
                                                                                                                                                type: 'string',
                                                                                                                                            },
                                                                                                                                            message: 'must be string',
                                                                                                                                        },
                                                                                                                                    ];
                                                                                                                                return false;
                                                                                                                            }
                                                                                                                            if ('participantAttributesChanged' !==
                                                                                                                                data296) {
                                                                                                                                validate11.errors =
                                                                                                                                    [
                                                                                                                                        {
                                                                                                                                            instancePath: instancePath +
                                                                                                                                                '/name',
                                                                                                                                            schemaPath: '#/allOf/22/then/properties/name/const',
                                                                                                                                            keyword: 'const',
                                                                                                                                            params: {
                                                                                                                                                allowedValue: 'participantAttributesChanged',
                                                                                                                                            },
                                                                                                                                            message: 'must be equal to constant',
                                                                                                                                        },
                                                                                                                                    ];
                                                                                                                                return false;
                                                                                                                            }
                                                                                                                            var valid129 = _errs794 ===
                                                                                                                                errors;
                                                                                                                        }
                                                                                                                        else {
                                                                                                                            var valid129 = true;
                                                                                                                        }
                                                                                                                        if (valid129) {
                                                                                                                            if (data.timestamp !==
                                                                                                                                undefined) {
                                                                                                                                var data297 = data.timestamp;
                                                                                                                                var _errs796 = errors;
                                                                                                                                if (!(typeof data297 ==
                                                                                                                                    'number' &&
                                                                                                                                    isFinite(data297))) {
                                                                                                                                    validate11.errors =
                                                                                                                                        [
                                                                                                                                            {
                                                                                                                                                instancePath: instancePath +
                                                                                                                                                    '/timestamp',
                                                                                                                                                schemaPath: '#/allOf/22/then/properties/timestamp/type',
                                                                                                                                                keyword: 'type',
                                                                                                                                                params: {
                                                                                                                                                    type: 'number',
                                                                                                                                                },
                                                                                                                                                message: 'must be number',
                                                                                                                                            },
                                                                                                                                        ];
                                                                                                                                    return false;
                                                                                                                                }
                                                                                                                                var valid129 = _errs796 ===
                                                                                                                                    errors;
                                                                                                                            }
                                                                                                                            else {
                                                                                                                                var valid129 = true;
                                                                                                                            }
                                                                                                                        }
                                                                                                                    }
                                                                                                                }
                                                                                                            }
                                                                                                        }
                                                                                                    }
                                                                                                    else {
                                                                                                        validate11.errors = [
                                                                                                            {
                                                                                                                instancePath: instancePath,
                                                                                                                schemaPath: '#/allOf/22/then/type',
                                                                                                                keyword: 'type',
                                                                                                                params: {
                                                                                                                    type: 'object',
                                                                                                                },
                                                                                                                message: 'must be object',
                                                                                                            },
                                                                                                        ];
                                                                                                        return false;
                                                                                                    }
                                                                                                }
                                                                                                var _valid22 = _errs764 === errors;
                                                                                                valid127 = _valid22;
                                                                                            }
                                                                                            if (!valid127) {
                                                                                                var err68 = {
                                                                                                    instancePath: instancePath,
                                                                                                    schemaPath: '#/allOf/22/if',
                                                                                                    keyword: 'if',
                                                                                                    params: {
                                                                                                        failingKeyword: 'then',
                                                                                                    },
                                                                                                    message: 'must match "then" schema',
                                                                                                };
                                                                                                if (vErrors === null) {
                                                                                                    vErrors = [err68];
                                                                                                }
                                                                                                else {
                                                                                                    vErrors.push(err68);
                                                                                                }
                                                                                                errors++;
                                                                                                validate11.errors = vErrors;
                                                                                                return false;
                                                                                            }
                                                                                            var valid0 = _errs759 === errors;
                                                                                            if (valid0) {
                                                                                                var _errs798 = errors;
                                                                                                var _errs799 = errors;
                                                                                                var valid133 = true;
                                                                                                var _errs800 = errors;
                                                                                                if (data &&
                                                                                                    typeof data == 'object' &&
                                                                                                    !Array.isArray(data)) {
                                                                                                    if (data.name !== undefined) {
                                                                                                        var data298 = data.name;
                                                                                                        if (typeof data298 !==
                                                                                                            'string') {
                                                                                                            var err69 = {};
                                                                                                            if (vErrors === null) {
                                                                                                                vErrors = [err69];
                                                                                                            }
                                                                                                            else {
                                                                                                                vErrors.push(err69);
                                                                                                            }
                                                                                                            errors++;
                                                                                                        }
                                                                                                        if ('activeSpeakersChanged' !==
                                                                                                            data298) {
                                                                                                            var err70 = {};
                                                                                                            if (vErrors === null) {
                                                                                                                vErrors = [err70];
                                                                                                            }
                                                                                                            else {
                                                                                                                vErrors.push(err70);
                                                                                                            }
                                                                                                            errors++;
                                                                                                        }
                                                                                                    }
                                                                                                }
                                                                                                var _valid23 = _errs800 === errors;
                                                                                                errors = _errs799;
                                                                                                if (vErrors !== null) {
                                                                                                    if (_errs799) {
                                                                                                        vErrors.length = _errs799;
                                                                                                    }
                                                                                                    else {
                                                                                                        vErrors = null;
                                                                                                    }
                                                                                                }
                                                                                                if (_valid23) {
                                                                                                    var _errs803 = errors;
                                                                                                    if (errors === _errs803) {
                                                                                                        if (data &&
                                                                                                            typeof data == 'object' &&
                                                                                                            !Array.isArray(data)) {
                                                                                                            var missing73 = void 0;
                                                                                                            if ((data.name ===
                                                                                                                undefined &&
                                                                                                                (missing73 =
                                                                                                                    'name')) ||
                                                                                                                (data.speakers ===
                                                                                                                    undefined &&
                                                                                                                    (missing73 =
                                                                                                                        'speakers')) ||
                                                                                                                (data.timestamp ===
                                                                                                                    undefined &&
                                                                                                                    (missing73 =
                                                                                                                        'timestamp'))) {
                                                                                                                validate11.errors = [
                                                                                                                    {
                                                                                                                        instancePath: instancePath,
                                                                                                                        schemaPath: '#/allOf/23/then/required',
                                                                                                                        keyword: 'required',
                                                                                                                        params: {
                                                                                                                            missingProperty: missing73,
                                                                                                                        },
                                                                                                                        message: "must have required property '" +
                                                                                                                            missing73 +
                                                                                                                            "'",
                                                                                                                    },
                                                                                                                ];
                                                                                                                return false;
                                                                                                            }
                                                                                                            else {
                                                                                                                var _errs805 = errors;
                                                                                                                for (var key74 in data) {
                                                                                                                    if (!(key74 ===
                                                                                                                        'speakers' ||
                                                                                                                        key74 ===
                                                                                                                            'name' ||
                                                                                                                        key74 ===
                                                                                                                            'timestamp')) {
                                                                                                                        validate11.errors =
                                                                                                                            [
                                                                                                                                {
                                                                                                                                    instancePath: instancePath,
                                                                                                                                    schemaPath: '#/allOf/23/then/additionalProperties',
                                                                                                                                    keyword: 'additionalProperties',
                                                                                                                                    params: {
                                                                                                                                        additionalProperty: key74,
                                                                                                                                    },
                                                                                                                                    message: 'must NOT have additional properties',
                                                                                                                                },
                                                                                                                            ];
                                                                                                                        return false;
                                                                                                                        break;
                                                                                                                    }
                                                                                                                }
                                                                                                                if (_errs805 === errors) {
                                                                                                                    if (data.speakers !==
                                                                                                                        undefined) {
                                                                                                                        var data299 = data.speakers;
                                                                                                                        var _errs806 = errors;
                                                                                                                        if (errors ===
                                                                                                                            _errs806) {
                                                                                                                            if (Array.isArray(data299)) {
                                                                                                                                var valid136 = true;
                                                                                                                                var len0 = data299.length;
                                                                                                                                for (var i0 = 0; i0 < len0; i0++) {
                                                                                                                                    var data300 = data299[i0];
                                                                                                                                    var _errs808 = errors;
                                                                                                                                    if (errors ===
                                                                                                                                        _errs808) {
                                                                                                                                        if (data300 &&
                                                                                                                                            typeof data300 ==
                                                                                                                                                'object' &&
                                                                                                                                            !Array.isArray(data300)) {
                                                                                                                                            var missing74 = void 0;
                                                                                                                                            if ((data300.identity ===
                                                                                                                                                undefined &&
                                                                                                                                                (missing74 =
                                                                                                                                                    'identity')) ||
                                                                                                                                                (data300.sid ===
                                                                                                                                                    undefined &&
                                                                                                                                                    (missing74 =
                                                                                                                                                        'sid'))) {
                                                                                                                                                validate11.errors =
                                                                                                                                                    [
                                                                                                                                                        {
                                                                                                                                                            instancePath: instancePath +
                                                                                                                                                                '/speakers/' +
                                                                                                                                                                i0,
                                                                                                                                                            schemaPath: '#/allOf/23/then/properties/speakers/items/required',
                                                                                                                                                            keyword: 'required',
                                                                                                                                                            params: {
                                                                                                                                                                missingProperty: missing74,
                                                                                                                                                            },
                                                                                                                                                            message: "must have required property '" +
                                                                                                                                                                missing74 +
                                                                                                                                                                "'",
                                                                                                                                                        },
                                                                                                                                                    ];
                                                                                                                                                return false;
                                                                                                                                            }
                                                                                                                                            else {
                                                                                                                                                var _errs810 = errors;
                                                                                                                                                for (var key75 in data300) {
                                                                                                                                                    if (!(key75 ===
                                                                                                                                                        'identity' ||
                                                                                                                                                        key75 ===
                                                                                                                                                            'sid' ||
                                                                                                                                                        key75 ===
                                                                                                                                                            'permissions')) {
                                                                                                                                                        validate11.errors =
                                                                                                                                                            [
                                                                                                                                                                {
                                                                                                                                                                    instancePath: instancePath +
                                                                                                                                                                        '/speakers/' +
                                                                                                                                                                        i0,
                                                                                                                                                                    schemaPath: '#/allOf/23/then/properties/speakers/items/additionalProperties',
                                                                                                                                                                    keyword: 'additionalProperties',
                                                                                                                                                                    params: {
                                                                                                                                                                        additionalProperty: key75,
                                                                                                                                                                    },
                                                                                                                                                                    message: 'must NOT have additional properties',
                                                                                                                                                                },
                                                                                                                                                            ];
                                                                                                                                                        return false;
                                                                                                                                                        break;
                                                                                                                                                    }
                                                                                                                                                }
                                                                                                                                                if (_errs810 ===
                                                                                                                                                    errors) {
                                                                                                                                                    if (data300.identity !==
                                                                                                                                                        undefined) {
                                                                                                                                                        var _errs811 = errors;
                                                                                                                                                        if (typeof data300.identity !==
                                                                                                                                                            'string') {
                                                                                                                                                            validate11.errors =
                                                                                                                                                                [
                                                                                                                                                                    {
                                                                                                                                                                        instancePath: instancePath +
                                                                                                                                                                            '/speakers/' +
                                                                                                                                                                            i0 +
                                                                                                                                                                            '/identity',
                                                                                                                                                                        schemaPath: '#/allOf/23/then/properties/speakers/items/properties/identity/type',
                                                                                                                                                                        keyword: 'type',
                                                                                                                                                                        params: {
                                                                                                                                                                            type: 'string',
                                                                                                                                                                        },
                                                                                                                                                                        message: 'must be string',
                                                                                                                                                                    },
                                                                                                                                                                ];
                                                                                                                                                            return false;
                                                                                                                                                        }
                                                                                                                                                        var valid137 = _errs811 ===
                                                                                                                                                            errors;
                                                                                                                                                    }
                                                                                                                                                    else {
                                                                                                                                                        var valid137 = true;
                                                                                                                                                    }
                                                                                                                                                    if (valid137) {
                                                                                                                                                        if (data300.sid !==
                                                                                                                                                            undefined) {
                                                                                                                                                            var _errs813 = errors;
                                                                                                                                                            if (typeof data300.sid !==
                                                                                                                                                                'string') {
                                                                                                                                                                validate11.errors =
                                                                                                                                                                    [
                                                                                                                                                                        {
                                                                                                                                                                            instancePath: instancePath +
                                                                                                                                                                                '/speakers/' +
                                                                                                                                                                                i0 +
                                                                                                                                                                                '/sid',
                                                                                                                                                                            schemaPath: '#/allOf/23/then/properties/speakers/items/properties/sid/type',
                                                                                                                                                                            keyword: 'type',
                                                                                                                                                                            params: {
                                                                                                                                                                                type: 'string',
                                                                                                                                                                            },
                                                                                                                                                                            message: 'must be string',
                                                                                                                                                                        },
                                                                                                                                                                    ];
                                                                                                                                                                return false;
                                                                                                                                                            }
                                                                                                                                                            var valid137 = _errs813 ===
                                                                                                                                                                errors;
                                                                                                                                                        }
                                                                                                                                                        else {
                                                                                                                                                            var valid137 = true;
                                                                                                                                                        }
                                                                                                                                                        if (valid137) {
                                                                                                                                                            if (data300.permissions !==
                                                                                                                                                                undefined) {
                                                                                                                                                                var data303 = data300.permissions;
                                                                                                                                                                var _errs815 = errors;
                                                                                                                                                                if (errors ===
                                                                                                                                                                    _errs815) {
                                                                                                                                                                    if (data303 &&
                                                                                                                                                                        typeof data303 ==
                                                                                                                                                                            'object' &&
                                                                                                                                                                        !Array.isArray(data303)) {
                                                                                                                                                                        var missing75 = void 0;
                                                                                                                                                                        if ((data303.canSubscribe ===
                                                                                                                                                                            undefined &&
                                                                                                                                                                            (missing75 =
                                                                                                                                                                                'canSubscribe')) ||
                                                                                                                                                                            (data303.canPublish ===
                                                                                                                                                                                undefined &&
                                                                                                                                                                                (missing75 =
                                                                                                                                                                                    'canPublish')) ||
                                                                                                                                                                            (data303.canPublishData ===
                                                                                                                                                                                undefined &&
                                                                                                                                                                                (missing75 =
                                                                                                                                                                                    'canPublishData')) ||
                                                                                                                                                                            (data303.hidden ===
                                                                                                                                                                                undefined &&
                                                                                                                                                                                (missing75 =
                                                                                                                                                                                    'hidden')) ||
                                                                                                                                                                            (data303.canUpdateMetadata ===
                                                                                                                                                                                undefined &&
                                                                                                                                                                                (missing75 =
                                                                                                                                                                                    'canUpdateMetadata')) ||
                                                                                                                                                                            (data303.canSubscribeMetrics ===
                                                                                                                                                                                undefined &&
                                                                                                                                                                                (missing75 =
                                                                                                                                                                                    'canSubscribeMetrics'))) {
                                                                                                                                                                            validate11.errors =
                                                                                                                                                                                [
                                                                                                                                                                                    {
                                                                                                                                                                                        instancePath: instancePath +
                                                                                                                                                                                            '/speakers/' +
                                                                                                                                                                                            i0 +
                                                                                                                                                                                            '/permissions',
                                                                                                                                                                                        schemaPath: '#/allOf/23/then/properties/speakers/items/properties/permissions/required',
                                                                                                                                                                                        keyword: 'required',
                                                                                                                                                                                        params: {
                                                                                                                                                                                            missingProperty: missing75,
                                                                                                                                                                                        },
                                                                                                                                                                                        message: "must have required property '" +
                                                                                                                                                                                            missing75 +
                                                                                                                                                                                            "'",
                                                                                                                                                                                    },
                                                                                                                                                                                ];
                                                                                                                                                                            return false;
                                                                                                                                                                        }
                                                                                                                                                                        else {
                                                                                                                                                                            var _errs817 = errors;
                                                                                                                                                                            for (var key76 in data303) {
                                                                                                                                                                                if (!(key76 ===
                                                                                                                                                                                    'canSubscribe' ||
                                                                                                                                                                                    key76 ===
                                                                                                                                                                                        'canPublish' ||
                                                                                                                                                                                    key76 ===
                                                                                                                                                                                        'canPublishData' ||
                                                                                                                                                                                    key76 ===
                                                                                                                                                                                        'hidden' ||
                                                                                                                                                                                    key76 ===
                                                                                                                                                                                        'canUpdateMetadata' ||
                                                                                                                                                                                    key76 ===
                                                                                                                                                                                        'canSubscribeMetrics')) {
                                                                                                                                                                                    validate11.errors =
                                                                                                                                                                                        [
                                                                                                                                                                                            {
                                                                                                                                                                                                instancePath: instancePath +
                                                                                                                                                                                                    '/speakers/' +
                                                                                                                                                                                                    i0 +
                                                                                                                                                                                                    '/permissions',
                                                                                                                                                                                                schemaPath: '#/allOf/23/then/properties/speakers/items/properties/permissions/additionalProperties',
                                                                                                                                                                                                keyword: 'additionalProperties',
                                                                                                                                                                                                params: {
                                                                                                                                                                                                    additionalProperty: key76,
                                                                                                                                                                                                },
                                                                                                                                                                                                message: 'must NOT have additional properties',
                                                                                                                                                                                            },
                                                                                                                                                                                        ];
                                                                                                                                                                                    return false;
                                                                                                                                                                                    break;
                                                                                                                                                                                }
                                                                                                                                                                            }
                                                                                                                                                                            if (_errs817 ===
                                                                                                                                                                                errors) {
                                                                                                                                                                                if (data303.canSubscribe !==
                                                                                                                                                                                    undefined) {
                                                                                                                                                                                    var _errs818 = errors;
                                                                                                                                                                                    if (typeof data303.canSubscribe !==
                                                                                                                                                                                        'boolean') {
                                                                                                                                                                                        validate11.errors =
                                                                                                                                                                                            [
                                                                                                                                                                                                {
                                                                                                                                                                                                    instancePath: instancePath +
                                                                                                                                                                                                        '/speakers/' +
                                                                                                                                                                                                        i0 +
                                                                                                                                                                                                        '/permissions/canSubscribe',
                                                                                                                                                                                                    schemaPath: '#/allOf/23/then/properties/speakers/items/properties/permissions/properties/canSubscribe/type',
                                                                                                                                                                                                    keyword: 'type',
                                                                                                                                                                                                    params: {
                                                                                                                                                                                                        type: 'boolean',
                                                                                                                                                                                                    },
                                                                                                                                                                                                    message: 'must be boolean',
                                                                                                                                                                                                },
                                                                                                                                                                                            ];
                                                                                                                                                                                        return false;
                                                                                                                                                                                    }
                                                                                                                                                                                    var valid138 = _errs818 ===
                                                                                                                                                                                        errors;
                                                                                                                                                                                }
                                                                                                                                                                                else {
                                                                                                                                                                                    var valid138 = true;
                                                                                                                                                                                }
                                                                                                                                                                                if (valid138) {
                                                                                                                                                                                    if (data303.canPublish !==
                                                                                                                                                                                        undefined) {
                                                                                                                                                                                        var _errs820 = errors;
                                                                                                                                                                                        if (typeof data303.canPublish !==
                                                                                                                                                                                            'boolean') {
                                                                                                                                                                                            validate11.errors =
                                                                                                                                                                                                [
                                                                                                                                                                                                    {
                                                                                                                                                                                                        instancePath: instancePath +
                                                                                                                                                                                                            '/speakers/' +
                                                                                                                                                                                                            i0 +
                                                                                                                                                                                                            '/permissions/canPublish',
                                                                                                                                                                                                        schemaPath: '#/allOf/23/then/properties/speakers/items/properties/permissions/properties/canPublish/type',
                                                                                                                                                                                                        keyword: 'type',
                                                                                                                                                                                                        params: {
                                                                                                                                                                                                            type: 'boolean',
                                                                                                                                                                                                        },
                                                                                                                                                                                                        message: 'must be boolean',
                                                                                                                                                                                                    },
                                                                                                                                                                                                ];
                                                                                                                                                                                            return false;
                                                                                                                                                                                        }
                                                                                                                                                                                        var valid138 = _errs820 ===
                                                                                                                                                                                            errors;
                                                                                                                                                                                    }
                                                                                                                                                                                    else {
                                                                                                                                                                                        var valid138 = true;
                                                                                                                                                                                    }
                                                                                                                                                                                    if (valid138) {
                                                                                                                                                                                        if (data303.canPublishData !==
                                                                                                                                                                                            undefined) {
                                                                                                                                                                                            var _errs822 = errors;
                                                                                                                                                                                            if (typeof data303.canPublishData !==
                                                                                                                                                                                                'boolean') {
                                                                                                                                                                                                validate11.errors =
                                                                                                                                                                                                    [
                                                                                                                                                                                                        {
                                                                                                                                                                                                            instancePath: instancePath +
                                                                                                                                                                                                                '/speakers/' +
                                                                                                                                                                                                                i0 +
                                                                                                                                                                                                                '/permissions/canPublishData',
                                                                                                                                                                                                            schemaPath: '#/allOf/23/then/properties/speakers/items/properties/permissions/properties/canPublishData/type',
                                                                                                                                                                                                            keyword: 'type',
                                                                                                                                                                                                            params: {
                                                                                                                                                                                                                type: 'boolean',
                                                                                                                                                                                                            },
                                                                                                                                                                                                            message: 'must be boolean',
                                                                                                                                                                                                        },
                                                                                                                                                                                                    ];
                                                                                                                                                                                                return false;
                                                                                                                                                                                            }
                                                                                                                                                                                            var valid138 = _errs822 ===
                                                                                                                                                                                                errors;
                                                                                                                                                                                        }
                                                                                                                                                                                        else {
                                                                                                                                                                                            var valid138 = true;
                                                                                                                                                                                        }
                                                                                                                                                                                        if (valid138) {
                                                                                                                                                                                            if (data303.hidden !==
                                                                                                                                                                                                undefined) {
                                                                                                                                                                                                var _errs824 = errors;
                                                                                                                                                                                                if (typeof data303.hidden !==
                                                                                                                                                                                                    'boolean') {
                                                                                                                                                                                                    validate11.errors =
                                                                                                                                                                                                        [
                                                                                                                                                                                                            {
                                                                                                                                                                                                                instancePath: instancePath +
                                                                                                                                                                                                                    '/speakers/' +
                                                                                                                                                                                                                    i0 +
                                                                                                                                                                                                                    '/permissions/hidden',
                                                                                                                                                                                                                schemaPath: '#/allOf/23/then/properties/speakers/items/properties/permissions/properties/hidden/type',
                                                                                                                                                                                                                keyword: 'type',
                                                                                                                                                                                                                params: {
                                                                                                                                                                                                                    type: 'boolean',
                                                                                                                                                                                                                },
                                                                                                                                                                                                                message: 'must be boolean',
                                                                                                                                                                                                            },
                                                                                                                                                                                                        ];
                                                                                                                                                                                                    return false;
                                                                                                                                                                                                }
                                                                                                                                                                                                var valid138 = _errs824 ===
                                                                                                                                                                                                    errors;
                                                                                                                                                                                            }
                                                                                                                                                                                            else {
                                                                                                                                                                                                var valid138 = true;
                                                                                                                                                                                            }
                                                                                                                                                                                            if (valid138) {
                                                                                                                                                                                                if (data303.canUpdateMetadata !==
                                                                                                                                                                                                    undefined) {
                                                                                                                                                                                                    var _errs826 = errors;
                                                                                                                                                                                                    if (typeof data303.canUpdateMetadata !==
                                                                                                                                                                                                        'boolean') {
                                                                                                                                                                                                        validate11.errors =
                                                                                                                                                                                                            [
                                                                                                                                                                                                                {
                                                                                                                                                                                                                    instancePath: instancePath +
                                                                                                                                                                                                                        '/speakers/' +
                                                                                                                                                                                                                        i0 +
                                                                                                                                                                                                                        '/permissions/canUpdateMetadata',
                                                                                                                                                                                                                    schemaPath: '#/allOf/23/then/properties/speakers/items/properties/permissions/properties/canUpdateMetadata/type',
                                                                                                                                                                                                                    keyword: 'type',
                                                                                                                                                                                                                    params: {
                                                                                                                                                                                                                        type: 'boolean',
                                                                                                                                                                                                                    },
                                                                                                                                                                                                                    message: 'must be boolean',
                                                                                                                                                                                                                },
                                                                                                                                                                                                            ];
                                                                                                                                                                                                        return false;
                                                                                                                                                                                                    }
                                                                                                                                                                                                    var valid138 = _errs826 ===
                                                                                                                                                                                                        errors;
                                                                                                                                                                                                }
                                                                                                                                                                                                else {
                                                                                                                                                                                                    var valid138 = true;
                                                                                                                                                                                                }
                                                                                                                                                                                                if (valid138) {
                                                                                                                                                                                                    if (data303.canSubscribeMetrics !==
                                                                                                                                                                                                        undefined) {
                                                                                                                                                                                                        var _errs828 = errors;
                                                                                                                                                                                                        if (typeof data303.canSubscribeMetrics !==
                                                                                                                                                                                                            'boolean') {
                                                                                                                                                                                                            validate11.errors =
                                                                                                                                                                                                                [
                                                                                                                                                                                                                    {
                                                                                                                                                                                                                        instancePath: instancePath +
                                                                                                                                                                                                                            '/speakers/' +
                                                                                                                                                                                                                            i0 +
                                                                                                                                                                                                                            '/permissions/canSubscribeMetrics',
                                                                                                                                                                                                                        schemaPath: '#/allOf/23/then/properties/speakers/items/properties/permissions/properties/canSubscribeMetrics/type',
                                                                                                                                                                                                                        keyword: 'type',
                                                                                                                                                                                                                        params: {
                                                                                                                                                                                                                            type: 'boolean',
                                                                                                                                                                                                                        },
                                                                                                                                                                                                                        message: 'must be boolean',
                                                                                                                                                                                                                    },
                                                                                                                                                                                                                ];
                                                                                                                                                                                                            return false;
                                                                                                                                                                                                        }
                                                                                                                                                                                                        var valid138 = _errs828 ===
                                                                                                                                                                                                            errors;
                                                                                                                                                                                                    }
                                                                                                                                                                                                    else {
                                                                                                                                                                                                        var valid138 = true;
                                                                                                                                                                                                    }
                                                                                                                                                                                                }
                                                                                                                                                                                            }
                                                                                                                                                                                        }
                                                                                                                                                                                    }
                                                                                                                                                                                }
                                                                                                                                                                            }
                                                                                                                                                                        }
                                                                                                                                                                    }
                                                                                                                                                                    else {
                                                                                                                                                                        validate11.errors =
                                                                                                                                                                            [
                                                                                                                                                                                {
                                                                                                                                                                                    instancePath: instancePath +
                                                                                                                                                                                        '/speakers/' +
                                                                                                                                                                                        i0 +
                                                                                                                                                                                        '/permissions',
                                                                                                                                                                                    schemaPath: '#/allOf/23/then/properties/speakers/items/properties/permissions/type',
                                                                                                                                                                                    keyword: 'type',
                                                                                                                                                                                    params: {
                                                                                                                                                                                        type: 'object',
                                                                                                                                                                                    },
                                                                                                                                                                                    message: 'must be object',
                                                                                                                                                                                },
                                                                                                                                                                            ];
                                                                                                                                                                        return false;
                                                                                                                                                                    }
                                                                                                                                                                }
                                                                                                                                                                var valid137 = _errs815 ===
                                                                                                                                                                    errors;
                                                                                                                                                            }
                                                                                                                                                            else {
                                                                                                                                                                var valid137 = true;
                                                                                                                                                            }
                                                                                                                                                        }
                                                                                                                                                    }
                                                                                                                                                }
                                                                                                                                            }
                                                                                                                                        }
                                                                                                                                        else {
                                                                                                                                            validate11.errors =
                                                                                                                                                [
                                                                                                                                                    {
                                                                                                                                                        instancePath: instancePath +
                                                                                                                                                            '/speakers/' +
                                                                                                                                                            i0,
                                                                                                                                                        schemaPath: '#/allOf/23/then/properties/speakers/items/type',
                                                                                                                                                        keyword: 'type',
                                                                                                                                                        params: {
                                                                                                                                                            type: 'object',
                                                                                                                                                        },
                                                                                                                                                        message: 'must be object',
                                                                                                                                                    },
                                                                                                                                                ];
                                                                                                                                            return false;
                                                                                                                                        }
                                                                                                                                    }
                                                                                                                                    var valid136 = _errs808 ===
                                                                                                                                        errors;
                                                                                                                                    if (!valid136) {
                                                                                                                                        break;
                                                                                                                                    }
                                                                                                                                }
                                                                                                                            }
                                                                                                                            else {
                                                                                                                                validate11.errors =
                                                                                                                                    [
                                                                                                                                        {
                                                                                                                                            instancePath: instancePath +
                                                                                                                                                '/speakers',
                                                                                                                                            schemaPath: '#/allOf/23/then/properties/speakers/type',
                                                                                                                                            keyword: 'type',
                                                                                                                                            params: {
                                                                                                                                                type: 'array',
                                                                                                                                            },
                                                                                                                                            message: 'must be array',
                                                                                                                                        },
                                                                                                                                    ];
                                                                                                                                return false;
                                                                                                                            }
                                                                                                                        }
                                                                                                                        var valid135 = _errs806 ===
                                                                                                                            errors;
                                                                                                                    }
                                                                                                                    else {
                                                                                                                        var valid135 = true;
                                                                                                                    }
                                                                                                                    if (valid135) {
                                                                                                                        if (data.name !==
                                                                                                                            undefined) {
                                                                                                                            var data310 = data.name;
                                                                                                                            var _errs830 = errors;
                                                                                                                            if (typeof data310 !==
                                                                                                                                'string') {
                                                                                                                                validate11.errors =
                                                                                                                                    [
                                                                                                                                        {
                                                                                                                                            instancePath: instancePath +
                                                                                                                                                '/name',
                                                                                                                                            schemaPath: '#/allOf/23/then/properties/name/type',
                                                                                                                                            keyword: 'type',
                                                                                                                                            params: {
                                                                                                                                                type: 'string',
                                                                                                                                            },
                                                                                                                                            message: 'must be string',
                                                                                                                                        },
                                                                                                                                    ];
                                                                                                                                return false;
                                                                                                                            }
                                                                                                                            if ('activeSpeakersChanged' !==
                                                                                                                                data310) {
                                                                                                                                validate11.errors =
                                                                                                                                    [
                                                                                                                                        {
                                                                                                                                            instancePath: instancePath +
                                                                                                                                                '/name',
                                                                                                                                            schemaPath: '#/allOf/23/then/properties/name/const',
                                                                                                                                            keyword: 'const',
                                                                                                                                            params: {
                                                                                                                                                allowedValue: 'activeSpeakersChanged',
                                                                                                                                            },
                                                                                                                                            message: 'must be equal to constant',
                                                                                                                                        },
                                                                                                                                    ];
                                                                                                                                return false;
                                                                                                                            }
                                                                                                                            var valid135 = _errs830 ===
                                                                                                                                errors;
                                                                                                                        }
                                                                                                                        else {
                                                                                                                            var valid135 = true;
                                                                                                                        }
                                                                                                                        if (valid135) {
                                                                                                                            if (data.timestamp !==
                                                                                                                                undefined) {
                                                                                                                                var data311 = data.timestamp;
                                                                                                                                var _errs832 = errors;
                                                                                                                                if (!(typeof data311 ==
                                                                                                                                    'number' &&
                                                                                                                                    isFinite(data311))) {
                                                                                                                                    validate11.errors =
                                                                                                                                        [
                                                                                                                                            {
                                                                                                                                                instancePath: instancePath +
                                                                                                                                                    '/timestamp',
                                                                                                                                                schemaPath: '#/allOf/23/then/properties/timestamp/type',
                                                                                                                                                keyword: 'type',
                                                                                                                                                params: {
                                                                                                                                                    type: 'number',
                                                                                                                                                },
                                                                                                                                                message: 'must be number',
                                                                                                                                            },
                                                                                                                                        ];
                                                                                                                                    return false;
                                                                                                                                }
                                                                                                                                var valid135 = _errs832 ===
                                                                                                                                    errors;
                                                                                                                            }
                                                                                                                            else {
                                                                                                                                var valid135 = true;
                                                                                                                            }
                                                                                                                        }
                                                                                                                    }
                                                                                                                }
                                                                                                            }
                                                                                                        }
                                                                                                        else {
                                                                                                            validate11.errors = [
                                                                                                                {
                                                                                                                    instancePath: instancePath,
                                                                                                                    schemaPath: '#/allOf/23/then/type',
                                                                                                                    keyword: 'type',
                                                                                                                    params: {
                                                                                                                        type: 'object',
                                                                                                                    },
                                                                                                                    message: 'must be object',
                                                                                                                },
                                                                                                            ];
                                                                                                            return false;
                                                                                                        }
                                                                                                    }
                                                                                                    var _valid23 = _errs803 === errors;
                                                                                                    valid133 = _valid23;
                                                                                                }
                                                                                                if (!valid133) {
                                                                                                    var err71 = {
                                                                                                        instancePath: instancePath,
                                                                                                        schemaPath: '#/allOf/23/if',
                                                                                                        keyword: 'if',
                                                                                                        params: {
                                                                                                            failingKeyword: 'then',
                                                                                                        },
                                                                                                        message: 'must match "then" schema',
                                                                                                    };
                                                                                                    if (vErrors === null) {
                                                                                                        vErrors = [err71];
                                                                                                    }
                                                                                                    else {
                                                                                                        vErrors.push(err71);
                                                                                                    }
                                                                                                    errors++;
                                                                                                    validate11.errors = vErrors;
                                                                                                    return false;
                                                                                                }
                                                                                                var valid0 = _errs798 === errors;
                                                                                                if (valid0) {
                                                                                                    var _errs834 = errors;
                                                                                                    var _errs835 = errors;
                                                                                                    var valid139 = true;
                                                                                                    var _errs836 = errors;
                                                                                                    if (data &&
                                                                                                        typeof data == 'object' &&
                                                                                                        !Array.isArray(data)) {
                                                                                                        if (data.name !== undefined) {
                                                                                                            var data312 = data.name;
                                                                                                            if (typeof data312 !==
                                                                                                                'string') {
                                                                                                                var err72 = {};
                                                                                                                if (vErrors === null) {
                                                                                                                    vErrors = [err72];
                                                                                                                }
                                                                                                                else {
                                                                                                                    vErrors.push(err72);
                                                                                                                }
                                                                                                                errors++;
                                                                                                            }
                                                                                                            if ('roomMetadataChanged' !==
                                                                                                                data312) {
                                                                                                                var err73 = {};
                                                                                                                if (vErrors === null) {
                                                                                                                    vErrors = [err73];
                                                                                                                }
                                                                                                                else {
                                                                                                                    vErrors.push(err73);
                                                                                                                }
                                                                                                                errors++;
                                                                                                            }
                                                                                                        }
                                                                                                    }
                                                                                                    var _valid24 = _errs836 === errors;
                                                                                                    errors = _errs835;
                                                                                                    if (vErrors !== null) {
                                                                                                        if (_errs835) {
                                                                                                            vErrors.length = _errs835;
                                                                                                        }
                                                                                                        else {
                                                                                                            vErrors = null;
                                                                                                        }
                                                                                                    }
                                                                                                    if (_valid24) {
                                                                                                        var _errs839 = errors;
                                                                                                        if (errors === _errs839) {
                                                                                                            if (data &&
                                                                                                                typeof data ==
                                                                                                                    'object' &&
                                                                                                                !Array.isArray(data)) {
                                                                                                                var missing76 = void 0;
                                                                                                                if ((data.metadata ===
                                                                                                                    undefined &&
                                                                                                                    (missing76 =
                                                                                                                        'metadata')) ||
                                                                                                                    (data.name ===
                                                                                                                        undefined &&
                                                                                                                        (missing76 =
                                                                                                                            'name')) ||
                                                                                                                    (data.timestamp ===
                                                                                                                        undefined &&
                                                                                                                        (missing76 =
                                                                                                                            'timestamp'))) {
                                                                                                                    validate11.errors = [
                                                                                                                        {
                                                                                                                            instancePath: instancePath,
                                                                                                                            schemaPath: '#/allOf/24/then/required',
                                                                                                                            keyword: 'required',
                                                                                                                            params: {
                                                                                                                                missingProperty: missing76,
                                                                                                                            },
                                                                                                                            message: "must have required property '" +
                                                                                                                                missing76 +
                                                                                                                                "'",
                                                                                                                        },
                                                                                                                    ];
                                                                                                                    return false;
                                                                                                                }
                                                                                                                else {
                                                                                                                    var _errs841 = errors;
                                                                                                                    for (var key77 in data) {
                                                                                                                        if (!(key77 ===
                                                                                                                            'metadata' ||
                                                                                                                            key77 ===
                                                                                                                                'name' ||
                                                                                                                            key77 ===
                                                                                                                                'timestamp')) {
                                                                                                                            validate11.errors =
                                                                                                                                [
                                                                                                                                    {
                                                                                                                                        instancePath: instancePath,
                                                                                                                                        schemaPath: '#/allOf/24/then/additionalProperties',
                                                                                                                                        keyword: 'additionalProperties',
                                                                                                                                        params: {
                                                                                                                                            additionalProperty: key77,
                                                                                                                                        },
                                                                                                                                        message: 'must NOT have additional properties',
                                                                                                                                    },
                                                                                                                                ];
                                                                                                                            return false;
                                                                                                                            break;
                                                                                                                        }
                                                                                                                    }
                                                                                                                    if (_errs841 === errors) {
                                                                                                                        if (data.metadata !==
                                                                                                                            undefined) {
                                                                                                                            var _errs842 = errors;
                                                                                                                            if (typeof data.metadata !==
                                                                                                                                'string') {
                                                                                                                                validate11.errors =
                                                                                                                                    [
                                                                                                                                        {
                                                                                                                                            instancePath: instancePath +
                                                                                                                                                '/metadata',
                                                                                                                                            schemaPath: '#/allOf/24/then/properties/metadata/type',
                                                                                                                                            keyword: 'type',
                                                                                                                                            params: {
                                                                                                                                                type: 'string',
                                                                                                                                            },
                                                                                                                                            message: 'must be string',
                                                                                                                                        },
                                                                                                                                    ];
                                                                                                                                return false;
                                                                                                                            }
                                                                                                                            var valid141 = _errs842 ===
                                                                                                                                errors;
                                                                                                                        }
                                                                                                                        else {
                                                                                                                            var valid141 = true;
                                                                                                                        }
                                                                                                                        if (valid141) {
                                                                                                                            if (data.name !==
                                                                                                                                undefined) {
                                                                                                                                var data314 = data.name;
                                                                                                                                var _errs844 = errors;
                                                                                                                                if (typeof data314 !==
                                                                                                                                    'string') {
                                                                                                                                    validate11.errors =
                                                                                                                                        [
                                                                                                                                            {
                                                                                                                                                instancePath: instancePath +
                                                                                                                                                    '/name',
                                                                                                                                                schemaPath: '#/allOf/24/then/properties/name/type',
                                                                                                                                                keyword: 'type',
                                                                                                                                                params: {
                                                                                                                                                    type: 'string',
                                                                                                                                                },
                                                                                                                                                message: 'must be string',
                                                                                                                                            },
                                                                                                                                        ];
                                                                                                                                    return false;
                                                                                                                                }
                                                                                                                                if ('roomMetadataChanged' !==
                                                                                                                                    data314) {
                                                                                                                                    validate11.errors =
                                                                                                                                        [
                                                                                                                                            {
                                                                                                                                                instancePath: instancePath +
                                                                                                                                                    '/name',
                                                                                                                                                schemaPath: '#/allOf/24/then/properties/name/const',
                                                                                                                                                keyword: 'const',
                                                                                                                                                params: {
                                                                                                                                                    allowedValue: 'roomMetadataChanged',
                                                                                                                                                },
                                                                                                                                                message: 'must be equal to constant',
                                                                                                                                            },
                                                                                                                                        ];
                                                                                                                                    return false;
                                                                                                                                }
                                                                                                                                var valid141 = _errs844 ===
                                                                                                                                    errors;
                                                                                                                            }
                                                                                                                            else {
                                                                                                                                var valid141 = true;
                                                                                                                            }
                                                                                                                            if (valid141) {
                                                                                                                                if (data.timestamp !==
                                                                                                                                    undefined) {
                                                                                                                                    var data315 = data.timestamp;
                                                                                                                                    var _errs846 = errors;
                                                                                                                                    if (!(typeof data315 ==
                                                                                                                                        'number' &&
                                                                                                                                        isFinite(data315))) {
                                                                                                                                        validate11.errors =
                                                                                                                                            [
                                                                                                                                                {
                                                                                                                                                    instancePath: instancePath +
                                                                                                                                                        '/timestamp',
                                                                                                                                                    schemaPath: '#/allOf/24/then/properties/timestamp/type',
                                                                                                                                                    keyword: 'type',
                                                                                                                                                    params: {
                                                                                                                                                        type: 'number',
                                                                                                                                                    },
                                                                                                                                                    message: 'must be number',
                                                                                                                                                },
                                                                                                                                            ];
                                                                                                                                        return false;
                                                                                                                                    }
                                                                                                                                    var valid141 = _errs846 ===
                                                                                                                                        errors;
                                                                                                                                }
                                                                                                                                else {
                                                                                                                                    var valid141 = true;
                                                                                                                                }
                                                                                                                            }
                                                                                                                        }
                                                                                                                    }
                                                                                                                }
                                                                                                            }
                                                                                                            else {
                                                                                                                validate11.errors = [
                                                                                                                    {
                                                                                                                        instancePath: instancePath,
                                                                                                                        schemaPath: '#/allOf/24/then/type',
                                                                                                                        keyword: 'type',
                                                                                                                        params: {
                                                                                                                            type: 'object',
                                                                                                                        },
                                                                                                                        message: 'must be object',
                                                                                                                    },
                                                                                                                ];
                                                                                                                return false;
                                                                                                            }
                                                                                                        }
                                                                                                        var _valid24 = _errs839 === errors;
                                                                                                        valid139 = _valid24;
                                                                                                    }
                                                                                                    if (!valid139) {
                                                                                                        var err74 = {
                                                                                                            instancePath: instancePath,
                                                                                                            schemaPath: '#/allOf/24/if',
                                                                                                            keyword: 'if',
                                                                                                            params: {
                                                                                                                failingKeyword: 'then',
                                                                                                            },
                                                                                                            message: 'must match "then" schema',
                                                                                                        };
                                                                                                        if (vErrors === null) {
                                                                                                            vErrors = [err74];
                                                                                                        }
                                                                                                        else {
                                                                                                            vErrors.push(err74);
                                                                                                        }
                                                                                                        errors++;
                                                                                                        validate11.errors = vErrors;
                                                                                                        return false;
                                                                                                    }
                                                                                                    var valid0 = _errs834 === errors;
                                                                                                    if (valid0) {
                                                                                                        var _errs848 = errors;
                                                                                                        var _errs849 = errors;
                                                                                                        var valid142 = true;
                                                                                                        var _errs850 = errors;
                                                                                                        if (data &&
                                                                                                            typeof data == 'object' &&
                                                                                                            !Array.isArray(data)) {
                                                                                                            if (data.name !== undefined) {
                                                                                                                var data316 = data.name;
                                                                                                                if (typeof data316 !==
                                                                                                                    'string') {
                                                                                                                    var err75 = {};
                                                                                                                    if (vErrors === null) {
                                                                                                                        vErrors = [err75];
                                                                                                                    }
                                                                                                                    else {
                                                                                                                        vErrors.push(err75);
                                                                                                                    }
                                                                                                                    errors++;
                                                                                                                }
                                                                                                                if ('connectionQualityChanged' !==
                                                                                                                    data316) {
                                                                                                                    var err76 = {};
                                                                                                                    if (vErrors === null) {
                                                                                                                        vErrors = [err76];
                                                                                                                    }
                                                                                                                    else {
                                                                                                                        vErrors.push(err76);
                                                                                                                    }
                                                                                                                    errors++;
                                                                                                                }
                                                                                                            }
                                                                                                        }
                                                                                                        var _valid25 = _errs850 === errors;
                                                                                                        errors = _errs849;
                                                                                                        if (vErrors !== null) {
                                                                                                            if (_errs849) {
                                                                                                                vErrors.length =
                                                                                                                    _errs849;
                                                                                                            }
                                                                                                            else {
                                                                                                                vErrors = null;
                                                                                                            }
                                                                                                        }
                                                                                                        if (_valid25) {
                                                                                                            var _errs853 = errors;
                                                                                                            if (errors === _errs853) {
                                                                                                                if (data &&
                                                                                                                    typeof data ==
                                                                                                                        'object' &&
                                                                                                                    !Array.isArray(data)) {
                                                                                                                    var missing77 = void 0;
                                                                                                                    if ((data.name ===
                                                                                                                        undefined &&
                                                                                                                        (missing77 =
                                                                                                                            'name')) ||
                                                                                                                        (data.participant ===
                                                                                                                            undefined &&
                                                                                                                            (missing77 =
                                                                                                                                'participant')) ||
                                                                                                                        (data.quality ===
                                                                                                                            undefined &&
                                                                                                                            (missing77 =
                                                                                                                                'quality')) ||
                                                                                                                        (data.timestamp ===
                                                                                                                            undefined &&
                                                                                                                            (missing77 =
                                                                                                                                'timestamp'))) {
                                                                                                                        validate11.errors =
                                                                                                                            [
                                                                                                                                {
                                                                                                                                    instancePath: instancePath,
                                                                                                                                    schemaPath: '#/allOf/25/then/required',
                                                                                                                                    keyword: 'required',
                                                                                                                                    params: {
                                                                                                                                        missingProperty: missing77,
                                                                                                                                    },
                                                                                                                                    message: "must have required property '" +
                                                                                                                                        missing77 +
                                                                                                                                        "'",
                                                                                                                                },
                                                                                                                            ];
                                                                                                                        return false;
                                                                                                                    }
                                                                                                                    else {
                                                                                                                        var _errs855 = errors;
                                                                                                                        for (var key78 in data) {
                                                                                                                            if (!(key78 ===
                                                                                                                                'quality' ||
                                                                                                                                key78 ===
                                                                                                                                    'participant' ||
                                                                                                                                key78 ===
                                                                                                                                    'name' ||
                                                                                                                                key78 ===
                                                                                                                                    'timestamp')) {
                                                                                                                                validate11.errors =
                                                                                                                                    [
                                                                                                                                        {
                                                                                                                                            instancePath: instancePath,
                                                                                                                                            schemaPath: '#/allOf/25/then/additionalProperties',
                                                                                                                                            keyword: 'additionalProperties',
                                                                                                                                            params: {
                                                                                                                                                additionalProperty: key78,
                                                                                                                                            },
                                                                                                                                            message: 'must NOT have additional properties',
                                                                                                                                        },
                                                                                                                                    ];
                                                                                                                                return false;
                                                                                                                                break;
                                                                                                                            }
                                                                                                                        }
                                                                                                                        if (_errs855 ===
                                                                                                                            errors) {
                                                                                                                            if (data.quality !==
                                                                                                                                undefined) {
                                                                                                                                var data317 = data.quality;
                                                                                                                                var _errs856 = errors;
                                                                                                                                if (typeof data317 !==
                                                                                                                                    'string') {
                                                                                                                                    validate11.errors =
                                                                                                                                        [
                                                                                                                                            {
                                                                                                                                                instancePath: instancePath +
                                                                                                                                                    '/quality',
                                                                                                                                                schemaPath: '#/definitions/ConnectionQuality/type',
                                                                                                                                                keyword: 'type',
                                                                                                                                                params: {
                                                                                                                                                    type: 'string',
                                                                                                                                                },
                                                                                                                                                message: 'must be string',
                                                                                                                                            },
                                                                                                                                        ];
                                                                                                                                    return false;
                                                                                                                                }
                                                                                                                                if (!(data317 ===
                                                                                                                                    'excellent' ||
                                                                                                                                    data317 ===
                                                                                                                                        'good' ||
                                                                                                                                    data317 ===
                                                                                                                                        'poor' ||
                                                                                                                                    data317 ===
                                                                                                                                        'lost' ||
                                                                                                                                    data317 ===
                                                                                                                                        'unknown')) {
                                                                                                                                    validate11.errors =
                                                                                                                                        [
                                                                                                                                            {
                                                                                                                                                instancePath: instancePath +
                                                                                                                                                    '/quality',
                                                                                                                                                schemaPath: '#/definitions/ConnectionQuality/enum',
                                                                                                                                                keyword: 'enum',
                                                                                                                                                params: {
                                                                                                                                                    allowedValues: schema41.enum,
                                                                                                                                                },
                                                                                                                                                message: 'must be equal to one of the allowed values',
                                                                                                                                            },
                                                                                                                                        ];
                                                                                                                                    return false;
                                                                                                                                }
                                                                                                                                var valid144 = _errs856 ===
                                                                                                                                    errors;
                                                                                                                            }
                                                                                                                            else {
                                                                                                                                var valid144 = true;
                                                                                                                            }
                                                                                                                            if (valid144) {
                                                                                                                                if (data.participant !==
                                                                                                                                    undefined) {
                                                                                                                                    var data318 = data.participant;
                                                                                                                                    var _errs859 = errors;
                                                                                                                                    if (errors ===
                                                                                                                                        _errs859) {
                                                                                                                                        if (data318 &&
                                                                                                                                            typeof data318 ==
                                                                                                                                                'object' &&
                                                                                                                                            !Array.isArray(data318)) {
                                                                                                                                            var missing78 = void 0;
                                                                                                                                            if ((data318.identity ===
                                                                                                                                                undefined &&
                                                                                                                                                (missing78 =
                                                                                                                                                    'identity')) ||
                                                                                                                                                (data318.sid ===
                                                                                                                                                    undefined &&
                                                                                                                                                    (missing78 =
                                                                                                                                                        'sid'))) {
                                                                                                                                                validate11.errors =
                                                                                                                                                    [
                                                                                                                                                        {
                                                                                                                                                            instancePath: instancePath +
                                                                                                                                                                '/participant',
                                                                                                                                                            schemaPath: '#/allOf/25/then/properties/participant/required',
                                                                                                                                                            keyword: 'required',
                                                                                                                                                            params: {
                                                                                                                                                                missingProperty: missing78,
                                                                                                                                                            },
                                                                                                                                                            message: "must have required property '" +
                                                                                                                                                                missing78 +
                                                                                                                                                                "'",
                                                                                                                                                        },
                                                                                                                                                    ];
                                                                                                                                                return false;
                                                                                                                                            }
                                                                                                                                            else {
                                                                                                                                                var _errs861 = errors;
                                                                                                                                                for (var key79 in data318) {
                                                                                                                                                    if (!(key79 ===
                                                                                                                                                        'identity' ||
                                                                                                                                                        key79 ===
                                                                                                                                                            'sid' ||
                                                                                                                                                        key79 ===
                                                                                                                                                            'permissions')) {
                                                                                                                                                        validate11.errors =
                                                                                                                                                            [
                                                                                                                                                                {
                                                                                                                                                                    instancePath: instancePath +
                                                                                                                                                                        '/participant',
                                                                                                                                                                    schemaPath: '#/allOf/25/then/properties/participant/additionalProperties',
                                                                                                                                                                    keyword: 'additionalProperties',
                                                                                                                                                                    params: {
                                                                                                                                                                        additionalProperty: key79,
                                                                                                                                                                    },
                                                                                                                                                                    message: 'must NOT have additional properties',
                                                                                                                                                                },
                                                                                                                                                            ];
                                                                                                                                                        return false;
                                                                                                                                                        break;
                                                                                                                                                    }
                                                                                                                                                }
                                                                                                                                                if (_errs861 ===
                                                                                                                                                    errors) {
                                                                                                                                                    if (data318.identity !==
                                                                                                                                                        undefined) {
                                                                                                                                                        var _errs862 = errors;
                                                                                                                                                        if (typeof data318.identity !==
                                                                                                                                                            'string') {
                                                                                                                                                            validate11.errors =
                                                                                                                                                                [
                                                                                                                                                                    {
                                                                                                                                                                        instancePath: instancePath +
                                                                                                                                                                            '/participant/identity',
                                                                                                                                                                        schemaPath: '#/allOf/25/then/properties/participant/properties/identity/type',
                                                                                                                                                                        keyword: 'type',
                                                                                                                                                                        params: {
                                                                                                                                                                            type: 'string',
                                                                                                                                                                        },
                                                                                                                                                                        message: 'must be string',
                                                                                                                                                                    },
                                                                                                                                                                ];
                                                                                                                                                            return false;
                                                                                                                                                        }
                                                                                                                                                        var valid146 = _errs862 ===
                                                                                                                                                            errors;
                                                                                                                                                    }
                                                                                                                                                    else {
                                                                                                                                                        var valid146 = true;
                                                                                                                                                    }
                                                                                                                                                    if (valid146) {
                                                                                                                                                        if (data318.sid !==
                                                                                                                                                            undefined) {
                                                                                                                                                            var _errs864 = errors;
                                                                                                                                                            if (typeof data318.sid !==
                                                                                                                                                                'string') {
                                                                                                                                                                validate11.errors =
                                                                                                                                                                    [
                                                                                                                                                                        {
                                                                                                                                                                            instancePath: instancePath +
                                                                                                                                                                                '/participant/sid',
                                                                                                                                                                            schemaPath: '#/allOf/25/then/properties/participant/properties/sid/type',
                                                                                                                                                                            keyword: 'type',
                                                                                                                                                                            params: {
                                                                                                                                                                                type: 'string',
                                                                                                                                                                            },
                                                                                                                                                                            message: 'must be string',
                                                                                                                                                                        },
                                                                                                                                                                    ];
                                                                                                                                                                return false;
                                                                                                                                                            }
                                                                                                                                                            var valid146 = _errs864 ===
                                                                                                                                                                errors;
                                                                                                                                                        }
                                                                                                                                                        else {
                                                                                                                                                            var valid146 = true;
                                                                                                                                                        }
                                                                                                                                                        if (valid146) {
                                                                                                                                                            if (data318.permissions !==
                                                                                                                                                                undefined) {
                                                                                                                                                                var data321 = data318.permissions;
                                                                                                                                                                var _errs866 = errors;
                                                                                                                                                                if (errors ===
                                                                                                                                                                    _errs866) {
                                                                                                                                                                    if (data321 &&
                                                                                                                                                                        typeof data321 ==
                                                                                                                                                                            'object' &&
                                                                                                                                                                        !Array.isArray(data321)) {
                                                                                                                                                                        var missing79 = void 0;
                                                                                                                                                                        if ((data321.canSubscribe ===
                                                                                                                                                                            undefined &&
                                                                                                                                                                            (missing79 =
                                                                                                                                                                                'canSubscribe')) ||
                                                                                                                                                                            (data321.canPublish ===
                                                                                                                                                                                undefined &&
                                                                                                                                                                                (missing79 =
                                                                                                                                                                                    'canPublish')) ||
                                                                                                                                                                            (data321.canPublishData ===
                                                                                                                                                                                undefined &&
                                                                                                                                                                                (missing79 =
                                                                                                                                                                                    'canPublishData')) ||
                                                                                                                                                                            (data321.hidden ===
                                                                                                                                                                                undefined &&
                                                                                                                                                                                (missing79 =
                                                                                                                                                                                    'hidden')) ||
                                                                                                                                                                            (data321.canUpdateMetadata ===
                                                                                                                                                                                undefined &&
                                                                                                                                                                                (missing79 =
                                                                                                                                                                                    'canUpdateMetadata')) ||
                                                                                                                                                                            (data321.canSubscribeMetrics ===
                                                                                                                                                                                undefined &&
                                                                                                                                                                                (missing79 =
                                                                                                                                                                                    'canSubscribeMetrics'))) {
                                                                                                                                                                            validate11.errors =
                                                                                                                                                                                [
                                                                                                                                                                                    {
                                                                                                                                                                                        instancePath: instancePath +
                                                                                                                                                                                            '/participant/permissions',
                                                                                                                                                                                        schemaPath: '#/allOf/25/then/properties/participant/properties/permissions/required',
                                                                                                                                                                                        keyword: 'required',
                                                                                                                                                                                        params: {
                                                                                                                                                                                            missingProperty: missing79,
                                                                                                                                                                                        },
                                                                                                                                                                                        message: "must have required property '" +
                                                                                                                                                                                            missing79 +
                                                                                                                                                                                            "'",
                                                                                                                                                                                    },
                                                                                                                                                                                ];
                                                                                                                                                                            return false;
                                                                                                                                                                        }
                                                                                                                                                                        else {
                                                                                                                                                                            var _errs868 = errors;
                                                                                                                                                                            for (var key80 in data321) {
                                                                                                                                                                                if (!(key80 ===
                                                                                                                                                                                    'canSubscribe' ||
                                                                                                                                                                                    key80 ===
                                                                                                                                                                                        'canPublish' ||
                                                                                                                                                                                    key80 ===
                                                                                                                                                                                        'canPublishData' ||
                                                                                                                                                                                    key80 ===
                                                                                                                                                                                        'hidden' ||
                                                                                                                                                                                    key80 ===
                                                                                                                                                                                        'canUpdateMetadata' ||
                                                                                                                                                                                    key80 ===
                                                                                                                                                                                        'canSubscribeMetrics')) {
                                                                                                                                                                                    validate11.errors =
                                                                                                                                                                                        [
                                                                                                                                                                                            {
                                                                                                                                                                                                instancePath: instancePath +
                                                                                                                                                                                                    '/participant/permissions',
                                                                                                                                                                                                schemaPath: '#/allOf/25/then/properties/participant/properties/permissions/additionalProperties',
                                                                                                                                                                                                keyword: 'additionalProperties',
                                                                                                                                                                                                params: {
                                                                                                                                                                                                    additionalProperty: key80,
                                                                                                                                                                                                },
                                                                                                                                                                                                message: 'must NOT have additional properties',
                                                                                                                                                                                            },
                                                                                                                                                                                        ];
                                                                                                                                                                                    return false;
                                                                                                                                                                                    break;
                                                                                                                                                                                }
                                                                                                                                                                            }
                                                                                                                                                                            if (_errs868 ===
                                                                                                                                                                                errors) {
                                                                                                                                                                                if (data321.canSubscribe !==
                                                                                                                                                                                    undefined) {
                                                                                                                                                                                    var _errs869 = errors;
                                                                                                                                                                                    if (typeof data321.canSubscribe !==
                                                                                                                                                                                        'boolean') {
                                                                                                                                                                                        validate11.errors =
                                                                                                                                                                                            [
                                                                                                                                                                                                {
                                                                                                                                                                                                    instancePath: instancePath +
                                                                                                                                                                                                        '/participant/permissions/canSubscribe',
                                                                                                                                                                                                    schemaPath: '#/allOf/25/then/properties/participant/properties/permissions/properties/canSubscribe/type',
                                                                                                                                                                                                    keyword: 'type',
                                                                                                                                                                                                    params: {
                                                                                                                                                                                                        type: 'boolean',
                                                                                                                                                                                                    },
                                                                                                                                                                                                    message: 'must be boolean',
                                                                                                                                                                                                },
                                                                                                                                                                                            ];
                                                                                                                                                                                        return false;
                                                                                                                                                                                    }
                                                                                                                                                                                    var valid147 = _errs869 ===
                                                                                                                                                                                        errors;
                                                                                                                                                                                }
                                                                                                                                                                                else {
                                                                                                                                                                                    var valid147 = true;
                                                                                                                                                                                }
                                                                                                                                                                                if (valid147) {
                                                                                                                                                                                    if (data321.canPublish !==
                                                                                                                                                                                        undefined) {
                                                                                                                                                                                        var _errs871 = errors;
                                                                                                                                                                                        if (typeof data321.canPublish !==
                                                                                                                                                                                            'boolean') {
                                                                                                                                                                                            validate11.errors =
                                                                                                                                                                                                [
                                                                                                                                                                                                    {
                                                                                                                                                                                                        instancePath: instancePath +
                                                                                                                                                                                                            '/participant/permissions/canPublish',
                                                                                                                                                                                                        schemaPath: '#/allOf/25/then/properties/participant/properties/permissions/properties/canPublish/type',
                                                                                                                                                                                                        keyword: 'type',
                                                                                                                                                                                                        params: {
                                                                                                                                                                                                            type: 'boolean',
                                                                                                                                                                                                        },
                                                                                                                                                                                                        message: 'must be boolean',
                                                                                                                                                                                                    },
                                                                                                                                                                                                ];
                                                                                                                                                                                            return false;
                                                                                                                                                                                        }
                                                                                                                                                                                        var valid147 = _errs871 ===
                                                                                                                                                                                            errors;
                                                                                                                                                                                    }
                                                                                                                                                                                    else {
                                                                                                                                                                                        var valid147 = true;
                                                                                                                                                                                    }
                                                                                                                                                                                    if (valid147) {
                                                                                                                                                                                        if (data321.canPublishData !==
                                                                                                                                                                                            undefined) {
                                                                                                                                                                                            var _errs873 = errors;
                                                                                                                                                                                            if (typeof data321.canPublishData !==
                                                                                                                                                                                                'boolean') {
                                                                                                                                                                                                validate11.errors =
                                                                                                                                                                                                    [
                                                                                                                                                                                                        {
                                                                                                                                                                                                            instancePath: instancePath +
                                                                                                                                                                                                                '/participant/permissions/canPublishData',
                                                                                                                                                                                                            schemaPath: '#/allOf/25/then/properties/participant/properties/permissions/properties/canPublishData/type',
                                                                                                                                                                                                            keyword: 'type',
                                                                                                                                                                                                            params: {
                                                                                                                                                                                                                type: 'boolean',
                                                                                                                                                                                                            },
                                                                                                                                                                                                            message: 'must be boolean',
                                                                                                                                                                                                        },
                                                                                                                                                                                                    ];
                                                                                                                                                                                                return false;
                                                                                                                                                                                            }
                                                                                                                                                                                            var valid147 = _errs873 ===
                                                                                                                                                                                                errors;
                                                                                                                                                                                        }
                                                                                                                                                                                        else {
                                                                                                                                                                                            var valid147 = true;
                                                                                                                                                                                        }
                                                                                                                                                                                        if (valid147) {
                                                                                                                                                                                            if (data321.hidden !==
                                                                                                                                                                                                undefined) {
                                                                                                                                                                                                var _errs875 = errors;
                                                                                                                                                                                                if (typeof data321.hidden !==
                                                                                                                                                                                                    'boolean') {
                                                                                                                                                                                                    validate11.errors =
                                                                                                                                                                                                        [
                                                                                                                                                                                                            {
                                                                                                                                                                                                                instancePath: instancePath +
                                                                                                                                                                                                                    '/participant/permissions/hidden',
                                                                                                                                                                                                                schemaPath: '#/allOf/25/then/properties/participant/properties/permissions/properties/hidden/type',
                                                                                                                                                                                                                keyword: 'type',
                                                                                                                                                                                                                params: {
                                                                                                                                                                                                                    type: 'boolean',
                                                                                                                                                                                                                },
                                                                                                                                                                                                                message: 'must be boolean',
                                                                                                                                                                                                            },
                                                                                                                                                                                                        ];
                                                                                                                                                                                                    return false;
                                                                                                                                                                                                }
                                                                                                                                                                                                var valid147 = _errs875 ===
                                                                                                                                                                                                    errors;
                                                                                                                                                                                            }
                                                                                                                                                                                            else {
                                                                                                                                                                                                var valid147 = true;
                                                                                                                                                                                            }
                                                                                                                                                                                            if (valid147) {
                                                                                                                                                                                                if (data321.canUpdateMetadata !==
                                                                                                                                                                                                    undefined) {
                                                                                                                                                                                                    var _errs877 = errors;
                                                                                                                                                                                                    if (typeof data321.canUpdateMetadata !==
                                                                                                                                                                                                        'boolean') {
                                                                                                                                                                                                        validate11.errors =
                                                                                                                                                                                                            [
                                                                                                                                                                                                                {
                                                                                                                                                                                                                    instancePath: instancePath +
                                                                                                                                                                                                                        '/participant/permissions/canUpdateMetadata',
                                                                                                                                                                                                                    schemaPath: '#/allOf/25/then/properties/participant/properties/permissions/properties/canUpdateMetadata/type',
                                                                                                                                                                                                                    keyword: 'type',
                                                                                                                                                                                                                    params: {
                                                                                                                                                                                                                        type: 'boolean',
                                                                                                                                                                                                                    },
                                                                                                                                                                                                                    message: 'must be boolean',
                                                                                                                                                                                                                },
                                                                                                                                                                                                            ];
                                                                                                                                                                                                        return false;
                                                                                                                                                                                                    }
                                                                                                                                                                                                    var valid147 = _errs877 ===
                                                                                                                                                                                                        errors;
                                                                                                                                                                                                }
                                                                                                                                                                                                else {
                                                                                                                                                                                                    var valid147 = true;
                                                                                                                                                                                                }
                                                                                                                                                                                                if (valid147) {
                                                                                                                                                                                                    if (data321.canSubscribeMetrics !==
                                                                                                                                                                                                        undefined) {
                                                                                                                                                                                                        var _errs879 = errors;
                                                                                                                                                                                                        if (typeof data321.canSubscribeMetrics !==
                                                                                                                                                                                                            'boolean') {
                                                                                                                                                                                                            validate11.errors =
                                                                                                                                                                                                                [
                                                                                                                                                                                                                    {
                                                                                                                                                                                                                        instancePath: instancePath +
                                                                                                                                                                                                                            '/participant/permissions/canSubscribeMetrics',
                                                                                                                                                                                                                        schemaPath: '#/allOf/25/then/properties/participant/properties/permissions/properties/canSubscribeMetrics/type',
                                                                                                                                                                                                                        keyword: 'type',
                                                                                                                                                                                                                        params: {
                                                                                                                                                                                                                            type: 'boolean',
                                                                                                                                                                                                                        },
                                                                                                                                                                                                                        message: 'must be boolean',
                                                                                                                                                                                                                    },
                                                                                                                                                                                                                ];
                                                                                                                                                                                                            return false;
                                                                                                                                                                                                        }
                                                                                                                                                                                                        var valid147 = _errs879 ===
                                                                                                                                                                                                            errors;
                                                                                                                                                                                                    }
                                                                                                                                                                                                    else {
                                                                                                                                                                                                        var valid147 = true;
                                                                                                                                                                                                    }
                                                                                                                                                                                                }
                                                                                                                                                                                            }
                                                                                                                                                                                        }
                                                                                                                                                                                    }
                                                                                                                                                                                }
                                                                                                                                                                            }
                                                                                                                                                                        }
                                                                                                                                                                    }
                                                                                                                                                                    else {
                                                                                                                                                                        validate11.errors =
                                                                                                                                                                            [
                                                                                                                                                                                {
                                                                                                                                                                                    instancePath: instancePath +
                                                                                                                                                                                        '/participant/permissions',
                                                                                                                                                                                    schemaPath: '#/allOf/25/then/properties/participant/properties/permissions/type',
                                                                                                                                                                                    keyword: 'type',
                                                                                                                                                                                    params: {
                                                                                                                                                                                        type: 'object',
                                                                                                                                                                                    },
                                                                                                                                                                                    message: 'must be object',
                                                                                                                                                                                },
                                                                                                                                                                            ];
                                                                                                                                                                        return false;
                                                                                                                                                                    }
                                                                                                                                                                }
                                                                                                                                                                var valid146 = _errs866 ===
                                                                                                                                                                    errors;
                                                                                                                                                            }
                                                                                                                                                            else {
                                                                                                                                                                var valid146 = true;
                                                                                                                                                            }
                                                                                                                                                        }
                                                                                                                                                    }
                                                                                                                                                }
                                                                                                                                            }
                                                                                                                                        }
                                                                                                                                        else {
                                                                                                                                            validate11.errors =
                                                                                                                                                [
                                                                                                                                                    {
                                                                                                                                                        instancePath: instancePath +
                                                                                                                                                            '/participant',
                                                                                                                                                        schemaPath: '#/allOf/25/then/properties/participant/type',
                                                                                                                                                        keyword: 'type',
                                                                                                                                                        params: {
                                                                                                                                                            type: 'object',
                                                                                                                                                        },
                                                                                                                                                        message: 'must be object',
                                                                                                                                                    },
                                                                                                                                                ];
                                                                                                                                            return false;
                                                                                                                                        }
                                                                                                                                    }
                                                                                                                                    var valid144 = _errs859 ===
                                                                                                                                        errors;
                                                                                                                                }
                                                                                                                                else {
                                                                                                                                    var valid144 = true;
                                                                                                                                }
                                                                                                                                if (valid144) {
                                                                                                                                    if (data.name !==
                                                                                                                                        undefined) {
                                                                                                                                        var data328 = data.name;
                                                                                                                                        var _errs881 = errors;
                                                                                                                                        if (typeof data328 !==
                                                                                                                                            'string') {
                                                                                                                                            validate11.errors =
                                                                                                                                                [
                                                                                                                                                    {
                                                                                                                                                        instancePath: instancePath +
                                                                                                                                                            '/name',
                                                                                                                                                        schemaPath: '#/allOf/25/then/properties/name/type',
                                                                                                                                                        keyword: 'type',
                                                                                                                                                        params: {
                                                                                                                                                            type: 'string',
                                                                                                                                                        },
                                                                                                                                                        message: 'must be string',
                                                                                                                                                    },
                                                                                                                                                ];
                                                                                                                                            return false;
                                                                                                                                        }
                                                                                                                                        if ('connectionQualityChanged' !==
                                                                                                                                            data328) {
                                                                                                                                            validate11.errors =
                                                                                                                                                [
                                                                                                                                                    {
                                                                                                                                                        instancePath: instancePath +
                                                                                                                                                            '/name',
                                                                                                                                                        schemaPath: '#/allOf/25/then/properties/name/const',
                                                                                                                                                        keyword: 'const',
                                                                                                                                                        params: {
                                                                                                                                                            allowedValue: 'connectionQualityChanged',
                                                                                                                                                        },
                                                                                                                                                        message: 'must be equal to constant',
                                                                                                                                                    },
                                                                                                                                                ];
                                                                                                                                            return false;
                                                                                                                                        }
                                                                                                                                        var valid144 = _errs881 ===
                                                                                                                                            errors;
                                                                                                                                    }
                                                                                                                                    else {
                                                                                                                                        var valid144 = true;
                                                                                                                                    }
                                                                                                                                    if (valid144) {
                                                                                                                                        if (data.timestamp !==
                                                                                                                                            undefined) {
                                                                                                                                            var data329 = data.timestamp;
                                                                                                                                            var _errs883 = errors;
                                                                                                                                            if (!(typeof data329 ==
                                                                                                                                                'number' &&
                                                                                                                                                isFinite(data329))) {
                                                                                                                                                validate11.errors =
                                                                                                                                                    [
                                                                                                                                                        {
                                                                                                                                                            instancePath: instancePath +
                                                                                                                                                                '/timestamp',
                                                                                                                                                            schemaPath: '#/allOf/25/then/properties/timestamp/type',
                                                                                                                                                            keyword: 'type',
                                                                                                                                                            params: {
                                                                                                                                                                type: 'number',
                                                                                                                                                            },
                                                                                                                                                            message: 'must be number',
                                                                                                                                                        },
                                                                                                                                                    ];
                                                                                                                                                return false;
                                                                                                                                            }
                                                                                                                                            var valid144 = _errs883 ===
                                                                                                                                                errors;
                                                                                                                                        }
                                                                                                                                        else {
                                                                                                                                            var valid144 = true;
                                                                                                                                        }
                                                                                                                                    }
                                                                                                                                }
                                                                                                                            }
                                                                                                                        }
                                                                                                                    }
                                                                                                                }
                                                                                                                else {
                                                                                                                    validate11.errors = [
                                                                                                                        {
                                                                                                                            instancePath: instancePath,
                                                                                                                            schemaPath: '#/allOf/25/then/type',
                                                                                                                            keyword: 'type',
                                                                                                                            params: {
                                                                                                                                type: 'object',
                                                                                                                            },
                                                                                                                            message: 'must be object',
                                                                                                                        },
                                                                                                                    ];
                                                                                                                    return false;
                                                                                                                }
                                                                                                            }
                                                                                                            var _valid25 = _errs853 === errors;
                                                                                                            valid142 = _valid25;
                                                                                                        }
                                                                                                        if (!valid142) {
                                                                                                            var err77 = {
                                                                                                                instancePath: instancePath,
                                                                                                                schemaPath: '#/allOf/25/if',
                                                                                                                keyword: 'if',
                                                                                                                params: {
                                                                                                                    failingKeyword: 'then',
                                                                                                                },
                                                                                                                message: 'must match "then" schema',
                                                                                                            };
                                                                                                            if (vErrors === null) {
                                                                                                                vErrors = [err77];
                                                                                                            }
                                                                                                            else {
                                                                                                                vErrors.push(err77);
                                                                                                            }
                                                                                                            errors++;
                                                                                                            validate11.errors =
                                                                                                                vErrors;
                                                                                                            return false;
                                                                                                        }
                                                                                                        var valid0 = _errs848 === errors;
                                                                                                        if (valid0) {
                                                                                                            var _errs885 = errors;
                                                                                                            var _errs886 = errors;
                                                                                                            var valid148 = true;
                                                                                                            var _errs887 = errors;
                                                                                                            if (data &&
                                                                                                                typeof data ==
                                                                                                                    'object' &&
                                                                                                                !Array.isArray(data)) {
                                                                                                                if (data.name !==
                                                                                                                    undefined) {
                                                                                                                    var data330 = data.name;
                                                                                                                    if (typeof data330 !==
                                                                                                                        'string') {
                                                                                                                        var err78 = {};
                                                                                                                        if (vErrors === null) {
                                                                                                                            vErrors = [err78];
                                                                                                                        }
                                                                                                                        else {
                                                                                                                            vErrors.push(err78);
                                                                                                                        }
                                                                                                                        errors++;
                                                                                                                    }
                                                                                                                    if ('mediaDevicesError' !==
                                                                                                                        data330) {
                                                                                                                        var err79 = {};
                                                                                                                        if (vErrors === null) {
                                                                                                                            vErrors = [err79];
                                                                                                                        }
                                                                                                                        else {
                                                                                                                            vErrors.push(err79);
                                                                                                                        }
                                                                                                                        errors++;
                                                                                                                    }
                                                                                                                }
                                                                                                            }
                                                                                                            var _valid26 = _errs887 === errors;
                                                                                                            errors = _errs886;
                                                                                                            if (vErrors !== null) {
                                                                                                                if (_errs886) {
                                                                                                                    vErrors.length =
                                                                                                                        _errs886;
                                                                                                                }
                                                                                                                else {
                                                                                                                    vErrors = null;
                                                                                                                }
                                                                                                            }
                                                                                                            if (_valid26) {
                                                                                                                var _errs890 = errors;
                                                                                                                if (errors === _errs890) {
                                                                                                                    if (data &&
                                                                                                                        typeof data ==
                                                                                                                            'object' &&
                                                                                                                        !Array.isArray(data)) {
                                                                                                                        var missing80 = void 0;
                                                                                                                        if ((data.error ===
                                                                                                                            undefined &&
                                                                                                                            (missing80 =
                                                                                                                                'error')) ||
                                                                                                                            (data.name ===
                                                                                                                                undefined &&
                                                                                                                                (missing80 =
                                                                                                                                    'name')) ||
                                                                                                                            (data.timestamp ===
                                                                                                                                undefined &&
                                                                                                                                (missing80 =
                                                                                                                                    'timestamp'))) {
                                                                                                                            validate11.errors =
                                                                                                                                [
                                                                                                                                    {
                                                                                                                                        instancePath: instancePath,
                                                                                                                                        schemaPath: '#/allOf/26/then/required',
                                                                                                                                        keyword: 'required',
                                                                                                                                        params: {
                                                                                                                                            missingProperty: missing80,
                                                                                                                                        },
                                                                                                                                        message: "must have required property '" +
                                                                                                                                            missing80 +
                                                                                                                                            "'",
                                                                                                                                    },
                                                                                                                                ];
                                                                                                                            return false;
                                                                                                                        }
                                                                                                                        else {
                                                                                                                            var _errs892 = errors;
                                                                                                                            for (var key81 in data) {
                                                                                                                                if (!(key81 ===
                                                                                                                                    'error' ||
                                                                                                                                    key81 ===
                                                                                                                                        'name' ||
                                                                                                                                    key81 ===
                                                                                                                                        'timestamp')) {
                                                                                                                                    validate11.errors =
                                                                                                                                        [
                                                                                                                                            {
                                                                                                                                                instancePath: instancePath,
                                                                                                                                                schemaPath: '#/allOf/26/then/additionalProperties',
                                                                                                                                                keyword: 'additionalProperties',
                                                                                                                                                params: {
                                                                                                                                                    additionalProperty: key81,
                                                                                                                                                },
                                                                                                                                                message: 'must NOT have additional properties',
                                                                                                                                            },
                                                                                                                                        ];
                                                                                                                                    return false;
                                                                                                                                    break;
                                                                                                                                }
                                                                                                                            }
                                                                                                                            if (_errs892 ===
                                                                                                                                errors) {
                                                                                                                                if (data.error !==
                                                                                                                                    undefined) {
                                                                                                                                    var _errs893 = errors;
                                                                                                                                    if (typeof data.error !==
                                                                                                                                        'string') {
                                                                                                                                        validate11.errors =
                                                                                                                                            [
                                                                                                                                                {
                                                                                                                                                    instancePath: instancePath +
                                                                                                                                                        '/error',
                                                                                                                                                    schemaPath: '#/allOf/26/then/properties/error/type',
                                                                                                                                                    keyword: 'type',
                                                                                                                                                    params: {
                                                                                                                                                        type: 'string',
                                                                                                                                                    },
                                                                                                                                                    message: 'must be string',
                                                                                                                                                },
                                                                                                                                            ];
                                                                                                                                        return false;
                                                                                                                                    }
                                                                                                                                    var valid150 = _errs893 ===
                                                                                                                                        errors;
                                                                                                                                }
                                                                                                                                else {
                                                                                                                                    var valid150 = true;
                                                                                                                                }
                                                                                                                                if (valid150) {
                                                                                                                                    if (data.name !==
                                                                                                                                        undefined) {
                                                                                                                                        var data332 = data.name;
                                                                                                                                        var _errs895 = errors;
                                                                                                                                        if (typeof data332 !==
                                                                                                                                            'string') {
                                                                                                                                            validate11.errors =
                                                                                                                                                [
                                                                                                                                                    {
                                                                                                                                                        instancePath: instancePath +
                                                                                                                                                            '/name',
                                                                                                                                                        schemaPath: '#/allOf/26/then/properties/name/type',
                                                                                                                                                        keyword: 'type',
                                                                                                                                                        params: {
                                                                                                                                                            type: 'string',
                                                                                                                                                        },
                                                                                                                                                        message: 'must be string',
                                                                                                                                                    },
                                                                                                                                                ];
                                                                                                                                            return false;
                                                                                                                                        }
                                                                                                                                        if ('mediaDevicesError' !==
                                                                                                                                            data332) {
                                                                                                                                            validate11.errors =
                                                                                                                                                [
                                                                                                                                                    {
                                                                                                                                                        instancePath: instancePath +
                                                                                                                                                            '/name',
                                                                                                                                                        schemaPath: '#/allOf/26/then/properties/name/const',
                                                                                                                                                        keyword: 'const',
                                                                                                                                                        params: {
                                                                                                                                                            allowedValue: 'mediaDevicesError',
                                                                                                                                                        },
                                                                                                                                                        message: 'must be equal to constant',
                                                                                                                                                    },
                                                                                                                                                ];
                                                                                                                                            return false;
                                                                                                                                        }
                                                                                                                                        var valid150 = _errs895 ===
                                                                                                                                            errors;
                                                                                                                                    }
                                                                                                                                    else {
                                                                                                                                        var valid150 = true;
                                                                                                                                    }
                                                                                                                                    if (valid150) {
                                                                                                                                        if (data.timestamp !==
                                                                                                                                            undefined) {
                                                                                                                                            var data333 = data.timestamp;
                                                                                                                                            var _errs897 = errors;
                                                                                                                                            if (!(typeof data333 ==
                                                                                                                                                'number' &&
                                                                                                                                                isFinite(data333))) {
                                                                                                                                                validate11.errors =
                                                                                                                                                    [
                                                                                                                                                        {
                                                                                                                                                            instancePath: instancePath +
                                                                                                                                                                '/timestamp',
                                                                                                                                                            schemaPath: '#/allOf/26/then/properties/timestamp/type',
                                                                                                                                                            keyword: 'type',
                                                                                                                                                            params: {
                                                                                                                                                                type: 'number',
                                                                                                                                                            },
                                                                                                                                                            message: 'must be number',
                                                                                                                                                        },
                                                                                                                                                    ];
                                                                                                                                                return false;
                                                                                                                                            }
                                                                                                                                            var valid150 = _errs897 ===
                                                                                                                                                errors;
                                                                                                                                        }
                                                                                                                                        else {
                                                                                                                                            var valid150 = true;
                                                                                                                                        }
                                                                                                                                    }
                                                                                                                                }
                                                                                                                            }
                                                                                                                        }
                                                                                                                    }
                                                                                                                    else {
                                                                                                                        validate11.errors =
                                                                                                                            [
                                                                                                                                {
                                                                                                                                    instancePath: instancePath,
                                                                                                                                    schemaPath: '#/allOf/26/then/type',
                                                                                                                                    keyword: 'type',
                                                                                                                                    params: {
                                                                                                                                        type: 'object',
                                                                                                                                    },
                                                                                                                                    message: 'must be object',
                                                                                                                                },
                                                                                                                            ];
                                                                                                                        return false;
                                                                                                                    }
                                                                                                                }
                                                                                                                var _valid26 = _errs890 === errors;
                                                                                                                valid148 = _valid26;
                                                                                                            }
                                                                                                            if (!valid148) {
                                                                                                                var err80 = {
                                                                                                                    instancePath: instancePath,
                                                                                                                    schemaPath: '#/allOf/26/if',
                                                                                                                    keyword: 'if',
                                                                                                                    params: {
                                                                                                                        failingKeyword: 'then',
                                                                                                                    },
                                                                                                                    message: 'must match "then" schema',
                                                                                                                };
                                                                                                                if (vErrors === null) {
                                                                                                                    vErrors = [err80];
                                                                                                                }
                                                                                                                else {
                                                                                                                    vErrors.push(err80);
                                                                                                                }
                                                                                                                errors++;
                                                                                                                validate11.errors =
                                                                                                                    vErrors;
                                                                                                                return false;
                                                                                                            }
                                                                                                            var valid0 = _errs885 === errors;
                                                                                                            if (valid0) {
                                                                                                                var _errs899 = errors;
                                                                                                                var _errs900 = errors;
                                                                                                                var valid151 = true;
                                                                                                                var _errs901 = errors;
                                                                                                                if (data &&
                                                                                                                    typeof data ==
                                                                                                                        'object' &&
                                                                                                                    !Array.isArray(data)) {
                                                                                                                    if (data.name !==
                                                                                                                        undefined) {
                                                                                                                        var data334 = data.name;
                                                                                                                        if (typeof data334 !==
                                                                                                                            'string') {
                                                                                                                            var err81 = {};
                                                                                                                            if (vErrors === null) {
                                                                                                                                vErrors = [
                                                                                                                                    err81,
                                                                                                                                ];
                                                                                                                            }
                                                                                                                            else {
                                                                                                                                vErrors.push(err81);
                                                                                                                            }
                                                                                                                            errors++;
                                                                                                                        }
                                                                                                                        if ('trackStreamStateChanged' !==
                                                                                                                            data334) {
                                                                                                                            var err82 = {};
                                                                                                                            if (vErrors === null) {
                                                                                                                                vErrors = [
                                                                                                                                    err82,
                                                                                                                                ];
                                                                                                                            }
                                                                                                                            else {
                                                                                                                                vErrors.push(err82);
                                                                                                                            }
                                                                                                                            errors++;
                                                                                                                        }
                                                                                                                    }
                                                                                                                }
                                                                                                                var _valid27 = _errs901 === errors;
                                                                                                                errors = _errs900;
                                                                                                                if (vErrors !== null) {
                                                                                                                    if (_errs900) {
                                                                                                                        vErrors.length =
                                                                                                                            _errs900;
                                                                                                                    }
                                                                                                                    else {
                                                                                                                        vErrors = null;
                                                                                                                    }
                                                                                                                }
                                                                                                                if (_valid27) {
                                                                                                                    var _errs904 = errors;
                                                                                                                    if (errors === _errs904) {
                                                                                                                        if (data &&
                                                                                                                            typeof data ==
                                                                                                                                'object' &&
                                                                                                                            !Array.isArray(data)) {
                                                                                                                            var missing81 = void 0;
                                                                                                                            if ((data.name ===
                                                                                                                                undefined &&
                                                                                                                                (missing81 =
                                                                                                                                    'name')) ||
                                                                                                                                (data.participant ===
                                                                                                                                    undefined &&
                                                                                                                                    (missing81 =
                                                                                                                                        'participant')) ||
                                                                                                                                (data.state ===
                                                                                                                                    undefined &&
                                                                                                                                    (missing81 =
                                                                                                                                        'state')) ||
                                                                                                                                (data.timestamp ===
                                                                                                                                    undefined &&
                                                                                                                                    (missing81 =
                                                                                                                                        'timestamp')) ||
                                                                                                                                (data.track ===
                                                                                                                                    undefined &&
                                                                                                                                    (missing81 =
                                                                                                                                        'track'))) {
                                                                                                                                validate11.errors =
                                                                                                                                    [
                                                                                                                                        {
                                                                                                                                            instancePath: instancePath,
                                                                                                                                            schemaPath: '#/allOf/27/then/required',
                                                                                                                                            keyword: 'required',
                                                                                                                                            params: {
                                                                                                                                                missingProperty: missing81,
                                                                                                                                            },
                                                                                                                                            message: "must have required property '" +
                                                                                                                                                missing81 +
                                                                                                                                                "'",
                                                                                                                                        },
                                                                                                                                    ];
                                                                                                                                return false;
                                                                                                                            }
                                                                                                                            else {
                                                                                                                                var _errs906 = errors;
                                                                                                                                for (var key82 in data) {
                                                                                                                                    if (!(key82 ===
                                                                                                                                        'state' ||
                                                                                                                                        key82 ===
                                                                                                                                            'track' ||
                                                                                                                                        key82 ===
                                                                                                                                            'participant' ||
                                                                                                                                        key82 ===
                                                                                                                                            'name' ||
                                                                                                                                        key82 ===
                                                                                                                                            'timestamp')) {
                                                                                                                                        validate11.errors =
                                                                                                                                            [
                                                                                                                                                {
                                                                                                                                                    instancePath: instancePath,
                                                                                                                                                    schemaPath: '#/allOf/27/then/additionalProperties',
                                                                                                                                                    keyword: 'additionalProperties',
                                                                                                                                                    params: {
                                                                                                                                                        additionalProperty: key82,
                                                                                                                                                    },
                                                                                                                                                    message: 'must NOT have additional properties',
                                                                                                                                                },
                                                                                                                                            ];
                                                                                                                                        return false;
                                                                                                                                        break;
                                                                                                                                    }
                                                                                                                                }
                                                                                                                                if (_errs906 ===
                                                                                                                                    errors) {
                                                                                                                                    if (data.state !==
                                                                                                                                        undefined) {
                                                                                                                                        var data335 = data.state;
                                                                                                                                        var _errs907 = errors;
                                                                                                                                        if (typeof data335 !==
                                                                                                                                            'string') {
                                                                                                                                            validate11.errors =
                                                                                                                                                [
                                                                                                                                                    {
                                                                                                                                                        instancePath: instancePath +
                                                                                                                                                            '/state',
                                                                                                                                                        schemaPath: '#/definitions/Track.StreamState/type',
                                                                                                                                                        keyword: 'type',
                                                                                                                                                        params: {
                                                                                                                                                            type: 'string',
                                                                                                                                                        },
                                                                                                                                                        message: 'must be string',
                                                                                                                                                    },
                                                                                                                                                ];
                                                                                                                                            return false;
                                                                                                                                        }
                                                                                                                                        if (!(data335 ===
                                                                                                                                            'active' ||
                                                                                                                                            data335 ===
                                                                                                                                                'paused' ||
                                                                                                                                            data335 ===
                                                                                                                                                'unknown')) {
                                                                                                                                            validate11.errors =
                                                                                                                                                [
                                                                                                                                                    {
                                                                                                                                                        instancePath: instancePath +
                                                                                                                                                            '/state',
                                                                                                                                                        schemaPath: '#/definitions/Track.StreamState/enum',
                                                                                                                                                        keyword: 'enum',
                                                                                                                                                        params: {
                                                                                                                                                            allowedValues: schema42.enum,
                                                                                                                                                        },
                                                                                                                                                        message: 'must be equal to one of the allowed values',
                                                                                                                                                    },
                                                                                                                                                ];
                                                                                                                                            return false;
                                                                                                                                        }
                                                                                                                                        var valid153 = _errs907 ===
                                                                                                                                            errors;
                                                                                                                                    }
                                                                                                                                    else {
                                                                                                                                        var valid153 = true;
                                                                                                                                    }
                                                                                                                                    if (valid153) {
                                                                                                                                        if (data.track !==
                                                                                                                                            undefined) {
                                                                                                                                            var data336 = data.track;
                                                                                                                                            var _errs910 = errors;
                                                                                                                                            if (errors ===
                                                                                                                                                _errs910) {
                                                                                                                                                if (data336 &&
                                                                                                                                                    typeof data336 ==
                                                                                                                                                        'object' &&
                                                                                                                                                    !Array.isArray(data336)) {
                                                                                                                                                    var missing82 = void 0;
                                                                                                                                                    if ((data336.sid ===
                                                                                                                                                        undefined &&
                                                                                                                                                        (missing82 =
                                                                                                                                                            'sid')) ||
                                                                                                                                                        (data336.source ===
                                                                                                                                                            undefined &&
                                                                                                                                                            (missing82 =
                                                                                                                                                                'source'))) {
                                                                                                                                                        validate11.errors =
                                                                                                                                                            [
                                                                                                                                                                {
                                                                                                                                                                    instancePath: instancePath +
                                                                                                                                                                        '/track',
                                                                                                                                                                    schemaPath: '#/allOf/27/then/properties/track/required',
                                                                                                                                                                    keyword: 'required',
                                                                                                                                                                    params: {
                                                                                                                                                                        missingProperty: missing82,
                                                                                                                                                                    },
                                                                                                                                                                    message: "must have required property '" +
                                                                                                                                                                        missing82 +
                                                                                                                                                                        "'",
                                                                                                                                                                },
                                                                                                                                                            ];
                                                                                                                                                        return false;
                                                                                                                                                    }
                                                                                                                                                    else {
                                                                                                                                                        var _errs912 = errors;
                                                                                                                                                        for (var key83 in data336) {
                                                                                                                                                            if (!(key83 ===
                                                                                                                                                                'sid' ||
                                                                                                                                                                key83 ===
                                                                                                                                                                    'source' ||
                                                                                                                                                                key83 ===
                                                                                                                                                                    'mimeType' ||
                                                                                                                                                                key83 ===
                                                                                                                                                                    'dimensions')) {
                                                                                                                                                                validate11.errors =
                                                                                                                                                                    [
                                                                                                                                                                        {
                                                                                                                                                                            instancePath: instancePath +
                                                                                                                                                                                '/track',
                                                                                                                                                                            schemaPath: '#/allOf/27/then/properties/track/additionalProperties',
                                                                                                                                                                            keyword: 'additionalProperties',
                                                                                                                                                                            params: {
                                                                                                                                                                                additionalProperty: key83,
                                                                                                                                                                            },
                                                                                                                                                                            message: 'must NOT have additional properties',
                                                                                                                                                                        },
                                                                                                                                                                    ];
                                                                                                                                                                return false;
                                                                                                                                                                break;
                                                                                                                                                            }
                                                                                                                                                        }
                                                                                                                                                        if (_errs912 ===
                                                                                                                                                            errors) {
                                                                                                                                                            if (data336.sid !==
                                                                                                                                                                undefined) {
                                                                                                                                                                var _errs913 = errors;
                                                                                                                                                                if (typeof data336.sid !==
                                                                                                                                                                    'string') {
                                                                                                                                                                    validate11.errors =
                                                                                                                                                                        [
                                                                                                                                                                            {
                                                                                                                                                                                instancePath: instancePath +
                                                                                                                                                                                    '/track/sid',
                                                                                                                                                                                schemaPath: '#/allOf/27/then/properties/track/properties/sid/type',
                                                                                                                                                                                keyword: 'type',
                                                                                                                                                                                params: {
                                                                                                                                                                                    type: 'string',
                                                                                                                                                                                },
                                                                                                                                                                                message: 'must be string',
                                                                                                                                                                            },
                                                                                                                                                                        ];
                                                                                                                                                                    return false;
                                                                                                                                                                }
                                                                                                                                                                var valid155 = _errs913 ===
                                                                                                                                                                    errors;
                                                                                                                                                            }
                                                                                                                                                            else {
                                                                                                                                                                var valid155 = true;
                                                                                                                                                            }
                                                                                                                                                            if (valid155) {
                                                                                                                                                                if (data336.source !==
                                                                                                                                                                    undefined) {
                                                                                                                                                                    var data338 = data336.source;
                                                                                                                                                                    var _errs915 = errors;
                                                                                                                                                                    if (typeof data338 !==
                                                                                                                                                                        'string') {
                                                                                                                                                                        validate11.errors =
                                                                                                                                                                            [
                                                                                                                                                                                {
                                                                                                                                                                                    instancePath: instancePath +
                                                                                                                                                                                        '/track/source',
                                                                                                                                                                                    schemaPath: '#/definitions/Track.Source/type',
                                                                                                                                                                                    keyword: 'type',
                                                                                                                                                                                    params: {
                                                                                                                                                                                        type: 'string',
                                                                                                                                                                                    },
                                                                                                                                                                                    message: 'must be string',
                                                                                                                                                                                },
                                                                                                                                                                            ];
                                                                                                                                                                        return false;
                                                                                                                                                                    }
                                                                                                                                                                    if (!(data338 ===
                                                                                                                                                                        'camera' ||
                                                                                                                                                                        data338 ===
                                                                                                                                                                            'microphone' ||
                                                                                                                                                                        data338 ===
                                                                                                                                                                            'screen_share' ||
                                                                                                                                                                        data338 ===
                                                                                                                                                                            'screen_share_audio' ||
                                                                                                                                                                        data338 ===
                                                                                                                                                                            'unknown')) {
                                                                                                                                                                        validate11.errors =
                                                                                                                                                                            [
                                                                                                                                                                                {
                                                                                                                                                                                    instancePath: instancePath +
                                                                                                                                                                                        '/track/source',
                                                                                                                                                                                    schemaPath: '#/definitions/Track.Source/enum',
                                                                                                                                                                                    keyword: 'enum',
                                                                                                                                                                                    params: {
                                                                                                                                                                                        allowedValues: schema31.enum,
                                                                                                                                                                                    },
                                                                                                                                                                                    message: 'must be equal to one of the allowed values',
                                                                                                                                                                                },
                                                                                                                                                                            ];
                                                                                                                                                                        return false;
                                                                                                                                                                    }
                                                                                                                                                                    var valid155 = _errs915 ===
                                                                                                                                                                        errors;
                                                                                                                                                                }
                                                                                                                                                                else {
                                                                                                                                                                    var valid155 = true;
                                                                                                                                                                }
                                                                                                                                                                if (valid155) {
                                                                                                                                                                    if (data336.mimeType !==
                                                                                                                                                                        undefined) {
                                                                                                                                                                        var _errs918 = errors;
                                                                                                                                                                        if (typeof data336.mimeType !==
                                                                                                                                                                            'string') {
                                                                                                                                                                            validate11.errors =
                                                                                                                                                                                [
                                                                                                                                                                                    {
                                                                                                                                                                                        instancePath: instancePath +
                                                                                                                                                                                            '/track/mimeType',
                                                                                                                                                                                        schemaPath: '#/allOf/27/then/properties/track/properties/mimeType/type',
                                                                                                                                                                                        keyword: 'type',
                                                                                                                                                                                        params: {
                                                                                                                                                                                            type: 'string',
                                                                                                                                                                                        },
                                                                                                                                                                                        message: 'must be string',
                                                                                                                                                                                    },
                                                                                                                                                                                ];
                                                                                                                                                                            return false;
                                                                                                                                                                        }
                                                                                                                                                                        var valid155 = _errs918 ===
                                                                                                                                                                            errors;
                                                                                                                                                                    }
                                                                                                                                                                    else {
                                                                                                                                                                        var valid155 = true;
                                                                                                                                                                    }
                                                                                                                                                                    if (valid155) {
                                                                                                                                                                        if (data336.dimensions !==
                                                                                                                                                                            undefined) {
                                                                                                                                                                            var data340 = data336.dimensions;
                                                                                                                                                                            var _errs920 = errors;
                                                                                                                                                                            if (errors ===
                                                                                                                                                                                _errs920) {
                                                                                                                                                                                if (data340 &&
                                                                                                                                                                                    typeof data340 ==
                                                                                                                                                                                        'object' &&
                                                                                                                                                                                    !Array.isArray(data340)) {
                                                                                                                                                                                    var missing83 = void 0;
                                                                                                                                                                                    if ((data340.width ===
                                                                                                                                                                                        undefined &&
                                                                                                                                                                                        (missing83 =
                                                                                                                                                                                            'width')) ||
                                                                                                                                                                                        (data340.height ===
                                                                                                                                                                                            undefined &&
                                                                                                                                                                                            (missing83 =
                                                                                                                                                                                                'height'))) {
                                                                                                                                                                                        validate11.errors =
                                                                                                                                                                                            [
                                                                                                                                                                                                {
                                                                                                                                                                                                    instancePath: instancePath +
                                                                                                                                                                                                        '/track/dimensions',
                                                                                                                                                                                                    schemaPath: '#/allOf/27/then/properties/track/properties/dimensions/required',
                                                                                                                                                                                                    keyword: 'required',
                                                                                                                                                                                                    params: {
                                                                                                                                                                                                        missingProperty: missing83,
                                                                                                                                                                                                    },
                                                                                                                                                                                                    message: "must have required property '" +
                                                                                                                                                                                                        missing83 +
                                                                                                                                                                                                        "'",
                                                                                                                                                                                                },
                                                                                                                                                                                            ];
                                                                                                                                                                                        return false;
                                                                                                                                                                                    }
                                                                                                                                                                                    else {
                                                                                                                                                                                        var _errs922 = errors;
                                                                                                                                                                                        for (var key84 in data340) {
                                                                                                                                                                                            if (!(key84 ===
                                                                                                                                                                                                'width' ||
                                                                                                                                                                                                key84 ===
                                                                                                                                                                                                    'height')) {
                                                                                                                                                                                                validate11.errors =
                                                                                                                                                                                                    [
                                                                                                                                                                                                        {
                                                                                                                                                                                                            instancePath: instancePath +
                                                                                                                                                                                                                '/track/dimensions',
                                                                                                                                                                                                            schemaPath: '#/allOf/27/then/properties/track/properties/dimensions/additionalProperties',
                                                                                                                                                                                                            keyword: 'additionalProperties',
                                                                                                                                                                                                            params: {
                                                                                                                                                                                                                additionalProperty: key84,
                                                                                                                                                                                                            },
                                                                                                                                                                                                            message: 'must NOT have additional properties',
                                                                                                                                                                                                        },
                                                                                                                                                                                                    ];
                                                                                                                                                                                                return false;
                                                                                                                                                                                                break;
                                                                                                                                                                                            }
                                                                                                                                                                                        }
                                                                                                                                                                                        if (_errs922 ===
                                                                                                                                                                                            errors) {
                                                                                                                                                                                            if (data340.width !==
                                                                                                                                                                                                undefined) {
                                                                                                                                                                                                var data341 = data340.width;
                                                                                                                                                                                                var _errs923 = errors;
                                                                                                                                                                                                if (!(typeof data341 ==
                                                                                                                                                                                                    'number' &&
                                                                                                                                                                                                    isFinite(data341))) {
                                                                                                                                                                                                    validate11.errors =
                                                                                                                                                                                                        [
                                                                                                                                                                                                            {
                                                                                                                                                                                                                instancePath: instancePath +
                                                                                                                                                                                                                    '/track/dimensions/width',
                                                                                                                                                                                                                schemaPath: '#/allOf/27/then/properties/track/properties/dimensions/properties/width/type',
                                                                                                                                                                                                                keyword: 'type',
                                                                                                                                                                                                                params: {
                                                                                                                                                                                                                    type: 'number',
                                                                                                                                                                                                                },
                                                                                                                                                                                                                message: 'must be number',
                                                                                                                                                                                                            },
                                                                                                                                                                                                        ];
                                                                                                                                                                                                    return false;
                                                                                                                                                                                                }
                                                                                                                                                                                                var valid157 = _errs923 ===
                                                                                                                                                                                                    errors;
                                                                                                                                                                                            }
                                                                                                                                                                                            else {
                                                                                                                                                                                                var valid157 = true;
                                                                                                                                                                                            }
                                                                                                                                                                                            if (valid157) {
                                                                                                                                                                                                if (data340.height !==
                                                                                                                                                                                                    undefined) {
                                                                                                                                                                                                    var data342 = data340.height;
                                                                                                                                                                                                    var _errs925 = errors;
                                                                                                                                                                                                    if (!(typeof data342 ==
                                                                                                                                                                                                        'number' &&
                                                                                                                                                                                                        isFinite(data342))) {
                                                                                                                                                                                                        validate11.errors =
                                                                                                                                                                                                            [
                                                                                                                                                                                                                {
                                                                                                                                                                                                                    instancePath: instancePath +
                                                                                                                                                                                                                        '/track/dimensions/height',
                                                                                                                                                                                                                    schemaPath: '#/allOf/27/then/properties/track/properties/dimensions/properties/height/type',
                                                                                                                                                                                                                    keyword: 'type',
                                                                                                                                                                                                                    params: {
                                                                                                                                                                                                                        type: 'number',
                                                                                                                                                                                                                    },
                                                                                                                                                                                                                    message: 'must be number',
                                                                                                                                                                                                                },
                                                                                                                                                                                                            ];
                                                                                                                                                                                                        return false;
                                                                                                                                                                                                    }
                                                                                                                                                                                                    var valid157 = _errs925 ===
                                                                                                                                                                                                        errors;
                                                                                                                                                                                                }
                                                                                                                                                                                                else {
                                                                                                                                                                                                    var valid157 = true;
                                                                                                                                                                                                }
                                                                                                                                                                                            }
                                                                                                                                                                                        }
                                                                                                                                                                                    }
                                                                                                                                                                                }
                                                                                                                                                                                else {
                                                                                                                                                                                    validate11.errors =
                                                                                                                                                                                        [
                                                                                                                                                                                            {
                                                                                                                                                                                                instancePath: instancePath +
                                                                                                                                                                                                    '/track/dimensions',
                                                                                                                                                                                                schemaPath: '#/allOf/27/then/properties/track/properties/dimensions/type',
                                                                                                                                                                                                keyword: 'type',
                                                                                                                                                                                                params: {
                                                                                                                                                                                                    type: 'object',
                                                                                                                                                                                                },
                                                                                                                                                                                                message: 'must be object',
                                                                                                                                                                                            },
                                                                                                                                                                                        ];
                                                                                                                                                                                    return false;
                                                                                                                                                                                }
                                                                                                                                                                            }
                                                                                                                                                                            var valid155 = _errs920 ===
                                                                                                                                                                                errors;
                                                                                                                                                                        }
                                                                                                                                                                        else {
                                                                                                                                                                            var valid155 = true;
                                                                                                                                                                        }
                                                                                                                                                                    }
                                                                                                                                                                }
                                                                                                                                                            }
                                                                                                                                                        }
                                                                                                                                                    }
                                                                                                                                                }
                                                                                                                                                else {
                                                                                                                                                    validate11.errors =
                                                                                                                                                        [
                                                                                                                                                            {
                                                                                                                                                                instancePath: instancePath +
                                                                                                                                                                    '/track',
                                                                                                                                                                schemaPath: '#/allOf/27/then/properties/track/type',
                                                                                                                                                                keyword: 'type',
                                                                                                                                                                params: {
                                                                                                                                                                    type: 'object',
                                                                                                                                                                },
                                                                                                                                                                message: 'must be object',
                                                                                                                                                            },
                                                                                                                                                        ];
                                                                                                                                                    return false;
                                                                                                                                                }
                                                                                                                                            }
                                                                                                                                            var valid153 = _errs910 ===
                                                                                                                                                errors;
                                                                                                                                        }
                                                                                                                                        else {
                                                                                                                                            var valid153 = true;
                                                                                                                                        }
                                                                                                                                        if (valid153) {
                                                                                                                                            if (data.participant !==
                                                                                                                                                undefined) {
                                                                                                                                                var data343 = data.participant;
                                                                                                                                                var _errs927 = errors;
                                                                                                                                                if (errors ===
                                                                                                                                                    _errs927) {
                                                                                                                                                    if (data343 &&
                                                                                                                                                        typeof data343 ==
                                                                                                                                                            'object' &&
                                                                                                                                                        !Array.isArray(data343)) {
                                                                                                                                                        var missing84 = void 0;
                                                                                                                                                        if ((data343.identity ===
                                                                                                                                                            undefined &&
                                                                                                                                                            (missing84 =
                                                                                                                                                                'identity')) ||
                                                                                                                                                            (data343.sid ===
                                                                                                                                                                undefined &&
                                                                                                                                                                (missing84 =
                                                                                                                                                                    'sid'))) {
                                                                                                                                                            validate11.errors =
                                                                                                                                                                [
                                                                                                                                                                    {
                                                                                                                                                                        instancePath: instancePath +
                                                                                                                                                                            '/participant',
                                                                                                                                                                        schemaPath: '#/allOf/27/then/properties/participant/required',
                                                                                                                                                                        keyword: 'required',
                                                                                                                                                                        params: {
                                                                                                                                                                            missingProperty: missing84,
                                                                                                                                                                        },
                                                                                                                                                                        message: "must have required property '" +
                                                                                                                                                                            missing84 +
                                                                                                                                                                            "'",
                                                                                                                                                                    },
                                                                                                                                                                ];
                                                                                                                                                            return false;
                                                                                                                                                        }
                                                                                                                                                        else {
                                                                                                                                                            var _errs929 = errors;
                                                                                                                                                            for (var key85 in data343) {
                                                                                                                                                                if (!(key85 ===
                                                                                                                                                                    'identity' ||
                                                                                                                                                                    key85 ===
                                                                                                                                                                        'sid' ||
                                                                                                                                                                    key85 ===
                                                                                                                                                                        'permissions')) {
                                                                                                                                                                    validate11.errors =
                                                                                                                                                                        [
                                                                                                                                                                            {
                                                                                                                                                                                instancePath: instancePath +
                                                                                                                                                                                    '/participant',
                                                                                                                                                                                schemaPath: '#/allOf/27/then/properties/participant/additionalProperties',
                                                                                                                                                                                keyword: 'additionalProperties',
                                                                                                                                                                                params: {
                                                                                                                                                                                    additionalProperty: key85,
                                                                                                                                                                                },
                                                                                                                                                                                message: 'must NOT have additional properties',
                                                                                                                                                                            },
                                                                                                                                                                        ];
                                                                                                                                                                    return false;
                                                                                                                                                                    break;
                                                                                                                                                                }
                                                                                                                                                            }
                                                                                                                                                            if (_errs929 ===
                                                                                                                                                                errors) {
                                                                                                                                                                if (data343.identity !==
                                                                                                                                                                    undefined) {
                                                                                                                                                                    var _errs930 = errors;
                                                                                                                                                                    if (typeof data343.identity !==
                                                                                                                                                                        'string') {
                                                                                                                                                                        validate11.errors =
                                                                                                                                                                            [
                                                                                                                                                                                {
                                                                                                                                                                                    instancePath: instancePath +
                                                                                                                                                                                        '/participant/identity',
                                                                                                                                                                                    schemaPath: '#/allOf/27/then/properties/participant/properties/identity/type',
                                                                                                                                                                                    keyword: 'type',
                                                                                                                                                                                    params: {
                                                                                                                                                                                        type: 'string',
                                                                                                                                                                                    },
                                                                                                                                                                                    message: 'must be string',
                                                                                                                                                                                },
                                                                                                                                                                            ];
                                                                                                                                                                        return false;
                                                                                                                                                                    }
                                                                                                                                                                    var valid158 = _errs930 ===
                                                                                                                                                                        errors;
                                                                                                                                                                }
                                                                                                                                                                else {
                                                                                                                                                                    var valid158 = true;
                                                                                                                                                                }
                                                                                                                                                                if (valid158) {
                                                                                                                                                                    if (data343.sid !==
                                                                                                                                                                        undefined) {
                                                                                                                                                                        var _errs932 = errors;
                                                                                                                                                                        if (typeof data343.sid !==
                                                                                                                                                                            'string') {
                                                                                                                                                                            validate11.errors =
                                                                                                                                                                                [
                                                                                                                                                                                    {
                                                                                                                                                                                        instancePath: instancePath +
                                                                                                                                                                                            '/participant/sid',
                                                                                                                                                                                        schemaPath: '#/allOf/27/then/properties/participant/properties/sid/type',
                                                                                                                                                                                        keyword: 'type',
                                                                                                                                                                                        params: {
                                                                                                                                                                                            type: 'string',
                                                                                                                                                                                        },
                                                                                                                                                                                        message: 'must be string',
                                                                                                                                                                                    },
                                                                                                                                                                                ];
                                                                                                                                                                            return false;
                                                                                                                                                                        }
                                                                                                                                                                        var valid158 = _errs932 ===
                                                                                                                                                                            errors;
                                                                                                                                                                    }
                                                                                                                                                                    else {
                                                                                                                                                                        var valid158 = true;
                                                                                                                                                                    }
                                                                                                                                                                    if (valid158) {
                                                                                                                                                                        if (data343.permissions !==
                                                                                                                                                                            undefined) {
                                                                                                                                                                            var data346 = data343.permissions;
                                                                                                                                                                            var _errs934 = errors;
                                                                                                                                                                            if (errors ===
                                                                                                                                                                                _errs934) {
                                                                                                                                                                                if (data346 &&
                                                                                                                                                                                    typeof data346 ==
                                                                                                                                                                                        'object' &&
                                                                                                                                                                                    !Array.isArray(data346)) {
                                                                                                                                                                                    var missing85 = void 0;
                                                                                                                                                                                    if ((data346.canSubscribe ===
                                                                                                                                                                                        undefined &&
                                                                                                                                                                                        (missing85 =
                                                                                                                                                                                            'canSubscribe')) ||
                                                                                                                                                                                        (data346.canPublish ===
                                                                                                                                                                                            undefined &&
                                                                                                                                                                                            (missing85 =
                                                                                                                                                                                                'canPublish')) ||
                                                                                                                                                                                        (data346.canPublishData ===
                                                                                                                                                                                            undefined &&
                                                                                                                                                                                            (missing85 =
                                                                                                                                                                                                'canPublishData')) ||
                                                                                                                                                                                        (data346.hidden ===
                                                                                                                                                                                            undefined &&
                                                                                                                                                                                            (missing85 =
                                                                                                                                                                                                'hidden')) ||
                                                                                                                                                                                        (data346.canUpdateMetadata ===
                                                                                                                                                                                            undefined &&
                                                                                                                                                                                            (missing85 =
                                                                                                                                                                                                'canUpdateMetadata')) ||
                                                                                                                                                                                        (data346.canSubscribeMetrics ===
                                                                                                                                                                                            undefined &&
                                                                                                                                                                                            (missing85 =
                                                                                                                                                                                                'canSubscribeMetrics'))) {
                                                                                                                                                                                        validate11.errors =
                                                                                                                                                                                            [
                                                                                                                                                                                                {
                                                                                                                                                                                                    instancePath: instancePath +
                                                                                                                                                                                                        '/participant/permissions',
                                                                                                                                                                                                    schemaPath: '#/allOf/27/then/properties/participant/properties/permissions/required',
                                                                                                                                                                                                    keyword: 'required',
                                                                                                                                                                                                    params: {
                                                                                                                                                                                                        missingProperty: missing85,
                                                                                                                                                                                                    },
                                                                                                                                                                                                    message: "must have required property '" +
                                                                                                                                                                                                        missing85 +
                                                                                                                                                                                                        "'",
                                                                                                                                                                                                },
                                                                                                                                                                                            ];
                                                                                                                                                                                        return false;
                                                                                                                                                                                    }
                                                                                                                                                                                    else {
                                                                                                                                                                                        var _errs936 = errors;
                                                                                                                                                                                        for (var key86 in data346) {
                                                                                                                                                                                            if (!(key86 ===
                                                                                                                                                                                                'canSubscribe' ||
                                                                                                                                                                                                key86 ===
                                                                                                                                                                                                    'canPublish' ||
                                                                                                                                                                                                key86 ===
                                                                                                                                                                                                    'canPublishData' ||
                                                                                                                                                                                                key86 ===
                                                                                                                                                                                                    'hidden' ||
                                                                                                                                                                                                key86 ===
                                                                                                                                                                                                    'canUpdateMetadata' ||
                                                                                                                                                                                                key86 ===
                                                                                                                                                                                                    'canSubscribeMetrics')) {
                                                                                                                                                                                                validate11.errors =
                                                                                                                                                                                                    [
                                                                                                                                                                                                        {
                                                                                                                                                                                                            instancePath: instancePath +
                                                                                                                                                                                                                '/participant/permissions',
                                                                                                                                                                                                            schemaPath: '#/allOf/27/then/properties/participant/properties/permissions/additionalProperties',
                                                                                                                                                                                                            keyword: 'additionalProperties',
                                                                                                                                                                                                            params: {
                                                                                                                                                                                                                additionalProperty: key86,
                                                                                                                                                                                                            },
                                                                                                                                                                                                            message: 'must NOT have additional properties',
                                                                                                                                                                                                        },
                                                                                                                                                                                                    ];
                                                                                                                                                                                                return false;
                                                                                                                                                                                                break;
                                                                                                                                                                                            }
                                                                                                                                                                                        }
                                                                                                                                                                                        if (_errs936 ===
                                                                                                                                                                                            errors) {
                                                                                                                                                                                            if (data346.canSubscribe !==
                                                                                                                                                                                                undefined) {
                                                                                                                                                                                                var _errs937 = errors;
                                                                                                                                                                                                if (typeof data346.canSubscribe !==
                                                                                                                                                                                                    'boolean') {
                                                                                                                                                                                                    validate11.errors =
                                                                                                                                                                                                        [
                                                                                                                                                                                                            {
                                                                                                                                                                                                                instancePath: instancePath +
                                                                                                                                                                                                                    '/participant/permissions/canSubscribe',
                                                                                                                                                                                                                schemaPath: '#/allOf/27/then/properties/participant/properties/permissions/properties/canSubscribe/type',
                                                                                                                                                                                                                keyword: 'type',
                                                                                                                                                                                                                params: {
                                                                                                                                                                                                                    type: 'boolean',
                                                                                                                                                                                                                },
                                                                                                                                                                                                                message: 'must be boolean',
                                                                                                                                                                                                            },
                                                                                                                                                                                                        ];
                                                                                                                                                                                                    return false;
                                                                                                                                                                                                }
                                                                                                                                                                                                var valid159 = _errs937 ===
                                                                                                                                                                                                    errors;
                                                                                                                                                                                            }
                                                                                                                                                                                            else {
                                                                                                                                                                                                var valid159 = true;
                                                                                                                                                                                            }
                                                                                                                                                                                            if (valid159) {
                                                                                                                                                                                                if (data346.canPublish !==
                                                                                                                                                                                                    undefined) {
                                                                                                                                                                                                    var _errs939 = errors;
                                                                                                                                                                                                    if (typeof data346.canPublish !==
                                                                                                                                                                                                        'boolean') {
                                                                                                                                                                                                        validate11.errors =
                                                                                                                                                                                                            [
                                                                                                                                                                                                                {
                                                                                                                                                                                                                    instancePath: instancePath +
                                                                                                                                                                                                                        '/participant/permissions/canPublish',
                                                                                                                                                                                                                    schemaPath: '#/allOf/27/then/properties/participant/properties/permissions/properties/canPublish/type',
                                                                                                                                                                                                                    keyword: 'type',
                                                                                                                                                                                                                    params: {
                                                                                                                                                                                                                        type: 'boolean',
                                                                                                                                                                                                                    },
                                                                                                                                                                                                                    message: 'must be boolean',
                                                                                                                                                                                                                },
                                                                                                                                                                                                            ];
                                                                                                                                                                                                        return false;
                                                                                                                                                                                                    }
                                                                                                                                                                                                    var valid159 = _errs939 ===
                                                                                                                                                                                                        errors;
                                                                                                                                                                                                }
                                                                                                                                                                                                else {
                                                                                                                                                                                                    var valid159 = true;
                                                                                                                                                                                                }
                                                                                                                                                                                                if (valid159) {
                                                                                                                                                                                                    if (data346.canPublishData !==
                                                                                                                                                                                                        undefined) {
                                                                                                                                                                                                        var _errs941 = errors;
                                                                                                                                                                                                        if (typeof data346.canPublishData !==
                                                                                                                                                                                                            'boolean') {
                                                                                                                                                                                                            validate11.errors =
                                                                                                                                                                                                                [
                                                                                                                                                                                                                    {
                                                                                                                                                                                                                        instancePath: instancePath +
                                                                                                                                                                                                                            '/participant/permissions/canPublishData',
                                                                                                                                                                                                                        schemaPath: '#/allOf/27/then/properties/participant/properties/permissions/properties/canPublishData/type',
                                                                                                                                                                                                                        keyword: 'type',
                                                                                                                                                                                                                        params: {
                                                                                                                                                                                                                            type: 'boolean',
                                                                                                                                                                                                                        },
                                                                                                                                                                                                                        message: 'must be boolean',
                                                                                                                                                                                                                    },
                                                                                                                                                                                                                ];
                                                                                                                                                                                                            return false;
                                                                                                                                                                                                        }
                                                                                                                                                                                                        var valid159 = _errs941 ===
                                                                                                                                                                                                            errors;
                                                                                                                                                                                                    }
                                                                                                                                                                                                    else {
                                                                                                                                                                                                        var valid159 = true;
                                                                                                                                                                                                    }
                                                                                                                                                                                                    if (valid159) {
                                                                                                                                                                                                        if (data346.hidden !==
                                                                                                                                                                                                            undefined) {
                                                                                                                                                                                                            var _errs943 = errors;
                                                                                                                                                                                                            if (typeof data346.hidden !==
                                                                                                                                                                                                                'boolean') {
                                                                                                                                                                                                                validate11.errors =
                                                                                                                                                                                                                    [
                                                                                                                                                                                                                        {
                                                                                                                                                                                                                            instancePath: instancePath +
                                                                                                                                                                                                                                '/participant/permissions/hidden',
                                                                                                                                                                                                                            schemaPath: '#/allOf/27/then/properties/participant/properties/permissions/properties/hidden/type',
                                                                                                                                                                                                                            keyword: 'type',
                                                                                                                                                                                                                            params: {
                                                                                                                                                                                                                                type: 'boolean',
                                                                                                                                                                                                                            },
                                                                                                                                                                                                                            message: 'must be boolean',
                                                                                                                                                                                                                        },
                                                                                                                                                                                                                    ];
                                                                                                                                                                                                                return false;
                                                                                                                                                                                                            }
                                                                                                                                                                                                            var valid159 = _errs943 ===
                                                                                                                                                                                                                errors;
                                                                                                                                                                                                        }
                                                                                                                                                                                                        else {
                                                                                                                                                                                                            var valid159 = true;
                                                                                                                                                                                                        }
                                                                                                                                                                                                        if (valid159) {
                                                                                                                                                                                                            if (data346.canUpdateMetadata !==
                                                                                                                                                                                                                undefined) {
                                                                                                                                                                                                                var _errs945 = errors;
                                                                                                                                                                                                                if (typeof data346.canUpdateMetadata !==
                                                                                                                                                                                                                    'boolean') {
                                                                                                                                                                                                                    validate11.errors =
                                                                                                                                                                                                                        [
                                                                                                                                                                                                                            {
                                                                                                                                                                                                                                instancePath: instancePath +
                                                                                                                                                                                                                                    '/participant/permissions/canUpdateMetadata',
                                                                                                                                                                                                                                schemaPath: '#/allOf/27/then/properties/participant/properties/permissions/properties/canUpdateMetadata/type',
                                                                                                                                                                                                                                keyword: 'type',
                                                                                                                                                                                                                                params: {
                                                                                                                                                                                                                                    type: 'boolean',
                                                                                                                                                                                                                                },
                                                                                                                                                                                                                                message: 'must be boolean',
                                                                                                                                                                                                                            },
                                                                                                                                                                                                                        ];
                                                                                                                                                                                                                    return false;
                                                                                                                                                                                                                }
                                                                                                                                                                                                                var valid159 = _errs945 ===
                                                                                                                                                                                                                    errors;
                                                                                                                                                                                                            }
                                                                                                                                                                                                            else {
                                                                                                                                                                                                                var valid159 = true;
                                                                                                                                                                                                            }
                                                                                                                                                                                                            if (valid159) {
                                                                                                                                                                                                                if (data346.canSubscribeMetrics !==
                                                                                                                                                                                                                    undefined) {
                                                                                                                                                                                                                    var _errs947 = errors;
                                                                                                                                                                                                                    if (typeof data346.canSubscribeMetrics !==
                                                                                                                                                                                                                        'boolean') {
                                                                                                                                                                                                                        validate11.errors =
                                                                                                                                                                                                                            [
                                                                                                                                                                                                                                {
                                                                                                                                                                                                                                    instancePath: instancePath +
                                                                                                                                                                                                                                        '/participant/permissions/canSubscribeMetrics',
                                                                                                                                                                                                                                    schemaPath: '#/allOf/27/then/properties/participant/properties/permissions/properties/canSubscribeMetrics/type',
                                                                                                                                                                                                                                    keyword: 'type',
                                                                                                                                                                                                                                    params: {
                                                                                                                                                                                                                                        type: 'boolean',
                                                                                                                                                                                                                                    },
                                                                                                                                                                                                                                    message: 'must be boolean',
                                                                                                                                                                                                                                },
                                                                                                                                                                                                                            ];
                                                                                                                                                                                                                        return false;
                                                                                                                                                                                                                    }
                                                                                                                                                                                                                    var valid159 = _errs947 ===
                                                                                                                                                                                                                        errors;
                                                                                                                                                                                                                }
                                                                                                                                                                                                                else {
                                                                                                                                                                                                                    var valid159 = true;
                                                                                                                                                                                                                }
                                                                                                                                                                                                            }
                                                                                                                                                                                                        }
                                                                                                                                                                                                    }
                                                                                                                                                                                                }
                                                                                                                                                                                            }
                                                                                                                                                                                        }
                                                                                                                                                                                    }
                                                                                                                                                                                }
                                                                                                                                                                                else {
                                                                                                                                                                                    validate11.errors =
                                                                                                                                                                                        [
                                                                                                                                                                                            {
                                                                                                                                                                                                instancePath: instancePath +
                                                                                                                                                                                                    '/participant/permissions',
                                                                                                                                                                                                schemaPath: '#/allOf/27/then/properties/participant/properties/permissions/type',
                                                                                                                                                                                                keyword: 'type',
                                                                                                                                                                                                params: {
                                                                                                                                                                                                    type: 'object',
                                                                                                                                                                                                },
                                                                                                                                                                                                message: 'must be object',
                                                                                                                                                                                            },
                                                                                                                                                                                        ];
                                                                                                                                                                                    return false;
                                                                                                                                                                                }
                                                                                                                                                                            }
                                                                                                                                                                            var valid158 = _errs934 ===
                                                                                                                                                                                errors;
                                                                                                                                                                        }
                                                                                                                                                                        else {
                                                                                                                                                                            var valid158 = true;
                                                                                                                                                                        }
                                                                                                                                                                    }
                                                                                                                                                                }
                                                                                                                                                            }
                                                                                                                                                        }
                                                                                                                                                    }
                                                                                                                                                    else {
                                                                                                                                                        validate11.errors =
                                                                                                                                                            [
                                                                                                                                                                {
                                                                                                                                                                    instancePath: instancePath +
                                                                                                                                                                        '/participant',
                                                                                                                                                                    schemaPath: '#/allOf/27/then/properties/participant/type',
                                                                                                                                                                    keyword: 'type',
                                                                                                                                                                    params: {
                                                                                                                                                                        type: 'object',
                                                                                                                                                                    },
                                                                                                                                                                    message: 'must be object',
                                                                                                                                                                },
                                                                                                                                                            ];
                                                                                                                                                        return false;
                                                                                                                                                    }
                                                                                                                                                }
                                                                                                                                                var valid153 = _errs927 ===
                                                                                                                                                    errors;
                                                                                                                                            }
                                                                                                                                            else {
                                                                                                                                                var valid153 = true;
                                                                                                                                            }
                                                                                                                                            if (valid153) {
                                                                                                                                                if (data.name !==
                                                                                                                                                    undefined) {
                                                                                                                                                    var data353 = data.name;
                                                                                                                                                    var _errs949 = errors;
                                                                                                                                                    if (typeof data353 !==
                                                                                                                                                        'string') {
                                                                                                                                                        validate11.errors =
                                                                                                                                                            [
                                                                                                                                                                {
                                                                                                                                                                    instancePath: instancePath +
                                                                                                                                                                        '/name',
                                                                                                                                                                    schemaPath: '#/allOf/27/then/properties/name/type',
                                                                                                                                                                    keyword: 'type',
                                                                                                                                                                    params: {
                                                                                                                                                                        type: 'string',
                                                                                                                                                                    },
                                                                                                                                                                    message: 'must be string',
                                                                                                                                                                },
                                                                                                                                                            ];
                                                                                                                                                        return false;
                                                                                                                                                    }
                                                                                                                                                    if ('trackStreamStateChanged' !==
                                                                                                                                                        data353) {
                                                                                                                                                        validate11.errors =
                                                                                                                                                            [
                                                                                                                                                                {
                                                                                                                                                                    instancePath: instancePath +
                                                                                                                                                                        '/name',
                                                                                                                                                                    schemaPath: '#/allOf/27/then/properties/name/const',
                                                                                                                                                                    keyword: 'const',
                                                                                                                                                                    params: {
                                                                                                                                                                        allowedValue: 'trackStreamStateChanged',
                                                                                                                                                                    },
                                                                                                                                                                    message: 'must be equal to constant',
                                                                                                                                                                },
                                                                                                                                                            ];
                                                                                                                                                        return false;
                                                                                                                                                    }
                                                                                                                                                    var valid153 = _errs949 ===
                                                                                                                                                        errors;
                                                                                                                                                }
                                                                                                                                                else {
                                                                                                                                                    var valid153 = true;
                                                                                                                                                }
                                                                                                                                                if (valid153) {
                                                                                                                                                    if (data.timestamp !==
                                                                                                                                                        undefined) {
                                                                                                                                                        var data354 = data.timestamp;
                                                                                                                                                        var _errs951 = errors;
                                                                                                                                                        if (!(typeof data354 ==
                                                                                                                                                            'number' &&
                                                                                                                                                            isFinite(data354))) {
                                                                                                                                                            validate11.errors =
                                                                                                                                                                [
                                                                                                                                                                    {
                                                                                                                                                                        instancePath: instancePath +
                                                                                                                                                                            '/timestamp',
                                                                                                                                                                        schemaPath: '#/allOf/27/then/properties/timestamp/type',
                                                                                                                                                                        keyword: 'type',
                                                                                                                                                                        params: {
                                                                                                                                                                            type: 'number',
                                                                                                                                                                        },
                                                                                                                                                                        message: 'must be number',
                                                                                                                                                                    },
                                                                                                                                                                ];
                                                                                                                                                            return false;
                                                                                                                                                        }
                                                                                                                                                        var valid153 = _errs951 ===
                                                                                                                                                            errors;
                                                                                                                                                    }
                                                                                                                                                    else {
                                                                                                                                                        var valid153 = true;
                                                                                                                                                    }
                                                                                                                                                }
                                                                                                                                            }
                                                                                                                                        }
                                                                                                                                    }
                                                                                                                                }
                                                                                                                            }
                                                                                                                        }
                                                                                                                        else {
                                                                                                                            validate11.errors =
                                                                                                                                [
                                                                                                                                    {
                                                                                                                                        instancePath: instancePath,
                                                                                                                                        schemaPath: '#/allOf/27/then/type',
                                                                                                                                        keyword: 'type',
                                                                                                                                        params: {
                                                                                                                                            type: 'object',
                                                                                                                                        },
                                                                                                                                        message: 'must be object',
                                                                                                                                    },
                                                                                                                                ];
                                                                                                                            return false;
                                                                                                                        }
                                                                                                                    }
                                                                                                                    var _valid27 = _errs904 === errors;
                                                                                                                    valid151 = _valid27;
                                                                                                                }
                                                                                                                if (!valid151) {
                                                                                                                    var err83 = {
                                                                                                                        instancePath: instancePath,
                                                                                                                        schemaPath: '#/allOf/27/if',
                                                                                                                        keyword: 'if',
                                                                                                                        params: {
                                                                                                                            failingKeyword: 'then',
                                                                                                                        },
                                                                                                                        message: 'must match "then" schema',
                                                                                                                    };
                                                                                                                    if (vErrors === null) {
                                                                                                                        vErrors = [err83];
                                                                                                                    }
                                                                                                                    else {
                                                                                                                        vErrors.push(err83);
                                                                                                                    }
                                                                                                                    errors++;
                                                                                                                    validate11.errors =
                                                                                                                        vErrors;
                                                                                                                    return false;
                                                                                                                }
                                                                                                                var valid0 = _errs899 === errors;
                                                                                                                if (valid0) {
                                                                                                                    var _errs953 = errors;
                                                                                                                    var _errs954 = errors;
                                                                                                                    var valid160 = true;
                                                                                                                    var _errs955 = errors;
                                                                                                                    if (data &&
                                                                                                                        typeof data ==
                                                                                                                            'object' &&
                                                                                                                        !Array.isArray(data)) {
                                                                                                                        if (data.name !==
                                                                                                                            undefined) {
                                                                                                                            var data355 = data.name;
                                                                                                                            if (typeof data355 !==
                                                                                                                                'string') {
                                                                                                                                var err84 = {};
                                                                                                                                if (vErrors ===
                                                                                                                                    null) {
                                                                                                                                    vErrors = [
                                                                                                                                        err84,
                                                                                                                                    ];
                                                                                                                                }
                                                                                                                                else {
                                                                                                                                    vErrors.push(err84);
                                                                                                                                }
                                                                                                                                errors++;
                                                                                                                            }
                                                                                                                            if ('trackSubscriptionPermissionChanged' !==
                                                                                                                                data355) {
                                                                                                                                var err85 = {};
                                                                                                                                if (vErrors ===
                                                                                                                                    null) {
                                                                                                                                    vErrors = [
                                                                                                                                        err85,
                                                                                                                                    ];
                                                                                                                                }
                                                                                                                                else {
                                                                                                                                    vErrors.push(err85);
                                                                                                                                }
                                                                                                                                errors++;
                                                                                                                            }
                                                                                                                        }
                                                                                                                    }
                                                                                                                    var _valid28 = _errs955 === errors;
                                                                                                                    errors = _errs954;
                                                                                                                    if (vErrors !== null) {
                                                                                                                        if (_errs954) {
                                                                                                                            vErrors.length =
                                                                                                                                _errs954;
                                                                                                                        }
                                                                                                                        else {
                                                                                                                            vErrors = null;
                                                                                                                        }
                                                                                                                    }
                                                                                                                    if (_valid28) {
                                                                                                                        var _errs958 = errors;
                                                                                                                        if (errors ===
                                                                                                                            _errs958) {
                                                                                                                            if (data &&
                                                                                                                                typeof data ==
                                                                                                                                    'object' &&
                                                                                                                                !Array.isArray(data)) {
                                                                                                                                var missing86 = void 0;
                                                                                                                                if ((data.name ===
                                                                                                                                    undefined &&
                                                                                                                                    (missing86 =
                                                                                                                                        'name')) ||
                                                                                                                                    (data.participant ===
                                                                                                                                        undefined &&
                                                                                                                                        (missing86 =
                                                                                                                                            'participant')) ||
                                                                                                                                    (data.status ===
                                                                                                                                        undefined &&
                                                                                                                                        (missing86 =
                                                                                                                                            'status')) ||
                                                                                                                                    (data.timestamp ===
                                                                                                                                        undefined &&
                                                                                                                                        (missing86 =
                                                                                                                                            'timestamp')) ||
                                                                                                                                    (data.track ===
                                                                                                                                        undefined &&
                                                                                                                                        (missing86 =
                                                                                                                                            'track'))) {
                                                                                                                                    validate11.errors =
                                                                                                                                        [
                                                                                                                                            {
                                                                                                                                                instancePath: instancePath,
                                                                                                                                                schemaPath: '#/allOf/28/then/required',
                                                                                                                                                keyword: 'required',
                                                                                                                                                params: {
                                                                                                                                                    missingProperty: missing86,
                                                                                                                                                },
                                                                                                                                                message: "must have required property '" +
                                                                                                                                                    missing86 +
                                                                                                                                                    "'",
                                                                                                                                            },
                                                                                                                                        ];
                                                                                                                                    return false;
                                                                                                                                }
                                                                                                                                else {
                                                                                                                                    var _errs960 = errors;
                                                                                                                                    for (var key87 in data) {
                                                                                                                                        if (!(key87 ===
                                                                                                                                            'status' ||
                                                                                                                                            key87 ===
                                                                                                                                                'track' ||
                                                                                                                                            key87 ===
                                                                                                                                                'participant' ||
                                                                                                                                            key87 ===
                                                                                                                                                'name' ||
                                                                                                                                            key87 ===
                                                                                                                                                'timestamp')) {
                                                                                                                                            validate11.errors =
                                                                                                                                                [
                                                                                                                                                    {
                                                                                                                                                        instancePath: instancePath,
                                                                                                                                                        schemaPath: '#/allOf/28/then/additionalProperties',
                                                                                                                                                        keyword: 'additionalProperties',
                                                                                                                                                        params: {
                                                                                                                                                            additionalProperty: key87,
                                                                                                                                                        },
                                                                                                                                                        message: 'must NOT have additional properties',
                                                                                                                                                    },
                                                                                                                                                ];
                                                                                                                                            return false;
                                                                                                                                            break;
                                                                                                                                        }
                                                                                                                                    }
                                                                                                                                    if (_errs960 ===
                                                                                                                                        errors) {
                                                                                                                                        if (data.status !==
                                                                                                                                            undefined) {
                                                                                                                                            var data356 = data.status;
                                                                                                                                            var _errs961 = errors;
                                                                                                                                            if (typeof data356 !==
                                                                                                                                                'string') {
                                                                                                                                                validate11.errors =
                                                                                                                                                    [
                                                                                                                                                        {
                                                                                                                                                            instancePath: instancePath +
                                                                                                                                                                '/status',
                                                                                                                                                            schemaPath: '#/definitions/TrackPublication.PermissionStatus/type',
                                                                                                                                                            keyword: 'type',
                                                                                                                                                            params: {
                                                                                                                                                                type: 'string',
                                                                                                                                                            },
                                                                                                                                                            message: 'must be string',
                                                                                                                                                        },
                                                                                                                                                    ];
                                                                                                                                                return false;
                                                                                                                                            }
                                                                                                                                            if (!(data356 ===
                                                                                                                                                'allowed' ||
                                                                                                                                                data356 ===
                                                                                                                                                    'not_allowed')) {
                                                                                                                                                validate11.errors =
                                                                                                                                                    [
                                                                                                                                                        {
                                                                                                                                                            instancePath: instancePath +
                                                                                                                                                                '/status',
                                                                                                                                                            schemaPath: '#/definitions/TrackPublication.PermissionStatus/enum',
                                                                                                                                                            keyword: 'enum',
                                                                                                                                                            params: {
                                                                                                                                                                allowedValues: schema44.enum,
                                                                                                                                                            },
                                                                                                                                                            message: 'must be equal to one of the allowed values',
                                                                                                                                                        },
                                                                                                                                                    ];
                                                                                                                                                return false;
                                                                                                                                            }
                                                                                                                                            var valid162 = _errs961 ===
                                                                                                                                                errors;
                                                                                                                                        }
                                                                                                                                        else {
                                                                                                                                            var valid162 = true;
                                                                                                                                        }
                                                                                                                                        if (valid162) {
                                                                                                                                            if (data.track !==
                                                                                                                                                undefined) {
                                                                                                                                                var data357 = data.track;
                                                                                                                                                var _errs964 = errors;
                                                                                                                                                if (errors ===
                                                                                                                                                    _errs964) {
                                                                                                                                                    if (data357 &&
                                                                                                                                                        typeof data357 ==
                                                                                                                                                            'object' &&
                                                                                                                                                        !Array.isArray(data357)) {
                                                                                                                                                        var missing87 = void 0;
                                                                                                                                                        if ((data357.sid ===
                                                                                                                                                            undefined &&
                                                                                                                                                            (missing87 =
                                                                                                                                                                'sid')) ||
                                                                                                                                                            (data357.source ===
                                                                                                                                                                undefined &&
                                                                                                                                                                (missing87 =
                                                                                                                                                                    'source'))) {
                                                                                                                                                            validate11.errors =
                                                                                                                                                                [
                                                                                                                                                                    {
                                                                                                                                                                        instancePath: instancePath +
                                                                                                                                                                            '/track',
                                                                                                                                                                        schemaPath: '#/allOf/28/then/properties/track/required',
                                                                                                                                                                        keyword: 'required',
                                                                                                                                                                        params: {
                                                                                                                                                                            missingProperty: missing87,
                                                                                                                                                                        },
                                                                                                                                                                        message: "must have required property '" +
                                                                                                                                                                            missing87 +
                                                                                                                                                                            "'",
                                                                                                                                                                    },
                                                                                                                                                                ];
                                                                                                                                                            return false;
                                                                                                                                                        }
                                                                                                                                                        else {
                                                                                                                                                            var _errs966 = errors;
                                                                                                                                                            for (var key88 in data357) {
                                                                                                                                                                if (!(key88 ===
                                                                                                                                                                    'sid' ||
                                                                                                                                                                    key88 ===
                                                                                                                                                                        'source' ||
                                                                                                                                                                    key88 ===
                                                                                                                                                                        'mimeType' ||
                                                                                                                                                                    key88 ===
                                                                                                                                                                        'dimensions')) {
                                                                                                                                                                    validate11.errors =
                                                                                                                                                                        [
                                                                                                                                                                            {
                                                                                                                                                                                instancePath: instancePath +
                                                                                                                                                                                    '/track',
                                                                                                                                                                                schemaPath: '#/allOf/28/then/properties/track/additionalProperties',
                                                                                                                                                                                keyword: 'additionalProperties',
                                                                                                                                                                                params: {
                                                                                                                                                                                    additionalProperty: key88,
                                                                                                                                                                                },
                                                                                                                                                                                message: 'must NOT have additional properties',
                                                                                                                                                                            },
                                                                                                                                                                        ];
                                                                                                                                                                    return false;
                                                                                                                                                                    break;
                                                                                                                                                                }
                                                                                                                                                            }
                                                                                                                                                            if (_errs966 ===
                                                                                                                                                                errors) {
                                                                                                                                                                if (data357.sid !==
                                                                                                                                                                    undefined) {
                                                                                                                                                                    var _errs967 = errors;
                                                                                                                                                                    if (typeof data357.sid !==
                                                                                                                                                                        'string') {
                                                                                                                                                                        validate11.errors =
                                                                                                                                                                            [
                                                                                                                                                                                {
                                                                                                                                                                                    instancePath: instancePath +
                                                                                                                                                                                        '/track/sid',
                                                                                                                                                                                    schemaPath: '#/allOf/28/then/properties/track/properties/sid/type',
                                                                                                                                                                                    keyword: 'type',
                                                                                                                                                                                    params: {
                                                                                                                                                                                        type: 'string',
                                                                                                                                                                                    },
                                                                                                                                                                                    message: 'must be string',
                                                                                                                                                                                },
                                                                                                                                                                            ];
                                                                                                                                                                        return false;
                                                                                                                                                                    }
                                                                                                                                                                    var valid164 = _errs967 ===
                                                                                                                                                                        errors;
                                                                                                                                                                }
                                                                                                                                                                else {
                                                                                                                                                                    var valid164 = true;
                                                                                                                                                                }
                                                                                                                                                                if (valid164) {
                                                                                                                                                                    if (data357.source !==
                                                                                                                                                                        undefined) {
                                                                                                                                                                        var data359 = data357.source;
                                                                                                                                                                        var _errs969 = errors;
                                                                                                                                                                        if (typeof data359 !==
                                                                                                                                                                            'string') {
                                                                                                                                                                            validate11.errors =
                                                                                                                                                                                [
                                                                                                                                                                                    {
                                                                                                                                                                                        instancePath: instancePath +
                                                                                                                                                                                            '/track/source',
                                                                                                                                                                                        schemaPath: '#/definitions/Track.Source/type',
                                                                                                                                                                                        keyword: 'type',
                                                                                                                                                                                        params: {
                                                                                                                                                                                            type: 'string',
                                                                                                                                                                                        },
                                                                                                                                                                                        message: 'must be string',
                                                                                                                                                                                    },
                                                                                                                                                                                ];
                                                                                                                                                                            return false;
                                                                                                                                                                        }
                                                                                                                                                                        if (!(data359 ===
                                                                                                                                                                            'camera' ||
                                                                                                                                                                            data359 ===
                                                                                                                                                                                'microphone' ||
                                                                                                                                                                            data359 ===
                                                                                                                                                                                'screen_share' ||
                                                                                                                                                                            data359 ===
                                                                                                                                                                                'screen_share_audio' ||
                                                                                                                                                                            data359 ===
                                                                                                                                                                                'unknown')) {
                                                                                                                                                                            validate11.errors =
                                                                                                                                                                                [
                                                                                                                                                                                    {
                                                                                                                                                                                        instancePath: instancePath +
                                                                                                                                                                                            '/track/source',
                                                                                                                                                                                        schemaPath: '#/definitions/Track.Source/enum',
                                                                                                                                                                                        keyword: 'enum',
                                                                                                                                                                                        params: {
                                                                                                                                                                                            allowedValues: schema31.enum,
                                                                                                                                                                                        },
                                                                                                                                                                                        message: 'must be equal to one of the allowed values',
                                                                                                                                                                                    },
                                                                                                                                                                                ];
                                                                                                                                                                            return false;
                                                                                                                                                                        }
                                                                                                                                                                        var valid164 = _errs969 ===
                                                                                                                                                                            errors;
                                                                                                                                                                    }
                                                                                                                                                                    else {
                                                                                                                                                                        var valid164 = true;
                                                                                                                                                                    }
                                                                                                                                                                    if (valid164) {
                                                                                                                                                                        if (data357.mimeType !==
                                                                                                                                                                            undefined) {
                                                                                                                                                                            var _errs972 = errors;
                                                                                                                                                                            if (typeof data357.mimeType !==
                                                                                                                                                                                'string') {
                                                                                                                                                                                validate11.errors =
                                                                                                                                                                                    [
                                                                                                                                                                                        {
                                                                                                                                                                                            instancePath: instancePath +
                                                                                                                                                                                                '/track/mimeType',
                                                                                                                                                                                            schemaPath: '#/allOf/28/then/properties/track/properties/mimeType/type',
                                                                                                                                                                                            keyword: 'type',
                                                                                                                                                                                            params: {
                                                                                                                                                                                                type: 'string',
                                                                                                                                                                                            },
                                                                                                                                                                                            message: 'must be string',
                                                                                                                                                                                        },
                                                                                                                                                                                    ];
                                                                                                                                                                                return false;
                                                                                                                                                                            }
                                                                                                                                                                            var valid164 = _errs972 ===
                                                                                                                                                                                errors;
                                                                                                                                                                        }
                                                                                                                                                                        else {
                                                                                                                                                                            var valid164 = true;
                                                                                                                                                                        }
                                                                                                                                                                        if (valid164) {
                                                                                                                                                                            if (data357.dimensions !==
                                                                                                                                                                                undefined) {
                                                                                                                                                                                var data361 = data357.dimensions;
                                                                                                                                                                                var _errs974 = errors;
                                                                                                                                                                                if (errors ===
                                                                                                                                                                                    _errs974) {
                                                                                                                                                                                    if (data361 &&
                                                                                                                                                                                        typeof data361 ==
                                                                                                                                                                                            'object' &&
                                                                                                                                                                                        !Array.isArray(data361)) {
                                                                                                                                                                                        var missing88 = void 0;
                                                                                                                                                                                        if ((data361.width ===
                                                                                                                                                                                            undefined &&
                                                                                                                                                                                            (missing88 =
                                                                                                                                                                                                'width')) ||
                                                                                                                                                                                            (data361.height ===
                                                                                                                                                                                                undefined &&
                                                                                                                                                                                                (missing88 =
                                                                                                                                                                                                    'height'))) {
                                                                                                                                                                                            validate11.errors =
                                                                                                                                                                                                [
                                                                                                                                                                                                    {
                                                                                                                                                                                                        instancePath: instancePath +
                                                                                                                                                                                                            '/track/dimensions',
                                                                                                                                                                                                        schemaPath: '#/allOf/28/then/properties/track/properties/dimensions/required',
                                                                                                                                                                                                        keyword: 'required',
                                                                                                                                                                                                        params: {
                                                                                                                                                                                                            missingProperty: missing88,
                                                                                                                                                                                                        },
                                                                                                                                                                                                        message: "must have required property '" +
                                                                                                                                                                                                            missing88 +
                                                                                                                                                                                                            "'",
                                                                                                                                                                                                    },
                                                                                                                                                                                                ];
                                                                                                                                                                                            return false;
                                                                                                                                                                                        }
                                                                                                                                                                                        else {
                                                                                                                                                                                            var _errs976 = errors;
                                                                                                                                                                                            for (var key89 in data361) {
                                                                                                                                                                                                if (!(key89 ===
                                                                                                                                                                                                    'width' ||
                                                                                                                                                                                                    key89 ===
                                                                                                                                                                                                        'height')) {
                                                                                                                                                                                                    validate11.errors =
                                                                                                                                                                                                        [
                                                                                                                                                                                                            {
                                                                                                                                                                                                                instancePath: instancePath +
                                                                                                                                                                                                                    '/track/dimensions',
                                                                                                                                                                                                                schemaPath: '#/allOf/28/then/properties/track/properties/dimensions/additionalProperties',
                                                                                                                                                                                                                keyword: 'additionalProperties',
                                                                                                                                                                                                                params: {
                                                                                                                                                                                                                    additionalProperty: key89,
                                                                                                                                                                                                                },
                                                                                                                                                                                                                message: 'must NOT have additional properties',
                                                                                                                                                                                                            },
                                                                                                                                                                                                        ];
                                                                                                                                                                                                    return false;
                                                                                                                                                                                                    break;
                                                                                                                                                                                                }
                                                                                                                                                                                            }
                                                                                                                                                                                            if (_errs976 ===
                                                                                                                                                                                                errors) {
                                                                                                                                                                                                if (data361.width !==
                                                                                                                                                                                                    undefined) {
                                                                                                                                                                                                    var data362 = data361.width;
                                                                                                                                                                                                    var _errs977 = errors;
                                                                                                                                                                                                    if (!(typeof data362 ==
                                                                                                                                                                                                        'number' &&
                                                                                                                                                                                                        isFinite(data362))) {
                                                                                                                                                                                                        validate11.errors =
                                                                                                                                                                                                            [
                                                                                                                                                                                                                {
                                                                                                                                                                                                                    instancePath: instancePath +
                                                                                                                                                                                                                        '/track/dimensions/width',
                                                                                                                                                                                                                    schemaPath: '#/allOf/28/then/properties/track/properties/dimensions/properties/width/type',
                                                                                                                                                                                                                    keyword: 'type',
                                                                                                                                                                                                                    params: {
                                                                                                                                                                                                                        type: 'number',
                                                                                                                                                                                                                    },
                                                                                                                                                                                                                    message: 'must be number',
                                                                                                                                                                                                                },
                                                                                                                                                                                                            ];
                                                                                                                                                                                                        return false;
                                                                                                                                                                                                    }
                                                                                                                                                                                                    var valid166 = _errs977 ===
                                                                                                                                                                                                        errors;
                                                                                                                                                                                                }
                                                                                                                                                                                                else {
                                                                                                                                                                                                    var valid166 = true;
                                                                                                                                                                                                }
                                                                                                                                                                                                if (valid166) {
                                                                                                                                                                                                    if (data361.height !==
                                                                                                                                                                                                        undefined) {
                                                                                                                                                                                                        var data363 = data361.height;
                                                                                                                                                                                                        var _errs979 = errors;
                                                                                                                                                                                                        if (!(typeof data363 ==
                                                                                                                                                                                                            'number' &&
                                                                                                                                                                                                            isFinite(data363))) {
                                                                                                                                                                                                            validate11.errors =
                                                                                                                                                                                                                [
                                                                                                                                                                                                                    {
                                                                                                                                                                                                                        instancePath: instancePath +
                                                                                                                                                                                                                            '/track/dimensions/height',
                                                                                                                                                                                                                        schemaPath: '#/allOf/28/then/properties/track/properties/dimensions/properties/height/type',
                                                                                                                                                                                                                        keyword: 'type',
                                                                                                                                                                                                                        params: {
                                                                                                                                                                                                                            type: 'number',
                                                                                                                                                                                                                        },
                                                                                                                                                                                                                        message: 'must be number',
                                                                                                                                                                                                                    },
                                                                                                                                                                                                                ];
                                                                                                                                                                                                            return false;
                                                                                                                                                                                                        }
                                                                                                                                                                                                        var valid166 = _errs979 ===
                                                                                                                                                                                                            errors;
                                                                                                                                                                                                    }
                                                                                                                                                                                                    else {
                                                                                                                                                                                                        var valid166 = true;
                                                                                                                                                                                                    }
                                                                                                                                                                                                }
                                                                                                                                                                                            }
                                                                                                                                                                                        }
                                                                                                                                                                                    }
                                                                                                                                                                                    else {
                                                                                                                                                                                        validate11.errors =
                                                                                                                                                                                            [
                                                                                                                                                                                                {
                                                                                                                                                                                                    instancePath: instancePath +
                                                                                                                                                                                                        '/track/dimensions',
                                                                                                                                                                                                    schemaPath: '#/allOf/28/then/properties/track/properties/dimensions/type',
                                                                                                                                                                                                    keyword: 'type',
                                                                                                                                                                                                    params: {
                                                                                                                                                                                                        type: 'object',
                                                                                                                                                                                                    },
                                                                                                                                                                                                    message: 'must be object',
                                                                                                                                                                                                },
                                                                                                                                                                                            ];
                                                                                                                                                                                        return false;
                                                                                                                                                                                    }
                                                                                                                                                                                }
                                                                                                                                                                                var valid164 = _errs974 ===
                                                                                                                                                                                    errors;
                                                                                                                                                                            }
                                                                                                                                                                            else {
                                                                                                                                                                                var valid164 = true;
                                                                                                                                                                            }
                                                                                                                                                                        }
                                                                                                                                                                    }
                                                                                                                                                                }
                                                                                                                                                            }
                                                                                                                                                        }
                                                                                                                                                    }
                                                                                                                                                    else {
                                                                                                                                                        validate11.errors =
                                                                                                                                                            [
                                                                                                                                                                {
                                                                                                                                                                    instancePath: instancePath +
                                                                                                                                                                        '/track',
                                                                                                                                                                    schemaPath: '#/allOf/28/then/properties/track/type',
                                                                                                                                                                    keyword: 'type',
                                                                                                                                                                    params: {
                                                                                                                                                                        type: 'object',
                                                                                                                                                                    },
                                                                                                                                                                    message: 'must be object',
                                                                                                                                                                },
                                                                                                                                                            ];
                                                                                                                                                        return false;
                                                                                                                                                    }
                                                                                                                                                }
                                                                                                                                                var valid162 = _errs964 ===
                                                                                                                                                    errors;
                                                                                                                                            }
                                                                                                                                            else {
                                                                                                                                                var valid162 = true;
                                                                                                                                            }
                                                                                                                                            if (valid162) {
                                                                                                                                                if (data.participant !==
                                                                                                                                                    undefined) {
                                                                                                                                                    var data364 = data.participant;
                                                                                                                                                    var _errs981 = errors;
                                                                                                                                                    if (errors ===
                                                                                                                                                        _errs981) {
                                                                                                                                                        if (data364 &&
                                                                                                                                                            typeof data364 ==
                                                                                                                                                                'object' &&
                                                                                                                                                            !Array.isArray(data364)) {
                                                                                                                                                            var missing89 = void 0;
                                                                                                                                                            if ((data364.identity ===
                                                                                                                                                                undefined &&
                                                                                                                                                                (missing89 =
                                                                                                                                                                    'identity')) ||
                                                                                                                                                                (data364.sid ===
                                                                                                                                                                    undefined &&
                                                                                                                                                                    (missing89 =
                                                                                                                                                                        'sid'))) {
                                                                                                                                                                validate11.errors =
                                                                                                                                                                    [
                                                                                                                                                                        {
                                                                                                                                                                            instancePath: instancePath +
                                                                                                                                                                                '/participant',
                                                                                                                                                                            schemaPath: '#/allOf/28/then/properties/participant/required',
                                                                                                                                                                            keyword: 'required',
                                                                                                                                                                            params: {
                                                                                                                                                                                missingProperty: missing89,
                                                                                                                                                                            },
                                                                                                                                                                            message: "must have required property '" +
                                                                                                                                                                                missing89 +
                                                                                                                                                                                "'",
                                                                                                                                                                        },
                                                                                                                                                                    ];
                                                                                                                                                                return false;
                                                                                                                                                            }
                                                                                                                                                            else {
                                                                                                                                                                var _errs983 = errors;
                                                                                                                                                                for (var key90 in data364) {
                                                                                                                                                                    if (!(key90 ===
                                                                                                                                                                        'identity' ||
                                                                                                                                                                        key90 ===
                                                                                                                                                                            'sid' ||
                                                                                                                                                                        key90 ===
                                                                                                                                                                            'permissions')) {
                                                                                                                                                                        validate11.errors =
                                                                                                                                                                            [
                                                                                                                                                                                {
                                                                                                                                                                                    instancePath: instancePath +
                                                                                                                                                                                        '/participant',
                                                                                                                                                                                    schemaPath: '#/allOf/28/then/properties/participant/additionalProperties',
                                                                                                                                                                                    keyword: 'additionalProperties',
                                                                                                                                                                                    params: {
                                                                                                                                                                                        additionalProperty: key90,
                                                                                                                                                                                    },
                                                                                                                                                                                    message: 'must NOT have additional properties',
                                                                                                                                                                                },
                                                                                                                                                                            ];
                                                                                                                                                                        return false;
                                                                                                                                                                        break;
                                                                                                                                                                    }
                                                                                                                                                                }
                                                                                                                                                                if (_errs983 ===
                                                                                                                                                                    errors) {
                                                                                                                                                                    if (data364.identity !==
                                                                                                                                                                        undefined) {
                                                                                                                                                                        var _errs984 = errors;
                                                                                                                                                                        if (typeof data364.identity !==
                                                                                                                                                                            'string') {
                                                                                                                                                                            validate11.errors =
                                                                                                                                                                                [
                                                                                                                                                                                    {
                                                                                                                                                                                        instancePath: instancePath +
                                                                                                                                                                                            '/participant/identity',
                                                                                                                                                                                        schemaPath: '#/allOf/28/then/properties/participant/properties/identity/type',
                                                                                                                                                                                        keyword: 'type',
                                                                                                                                                                                        params: {
                                                                                                                                                                                            type: 'string',
                                                                                                                                                                                        },
                                                                                                                                                                                        message: 'must be string',
                                                                                                                                                                                    },
                                                                                                                                                                                ];
                                                                                                                                                                            return false;
                                                                                                                                                                        }
                                                                                                                                                                        var valid167 = _errs984 ===
                                                                                                                                                                            errors;
                                                                                                                                                                    }
                                                                                                                                                                    else {
                                                                                                                                                                        var valid167 = true;
                                                                                                                                                                    }
                                                                                                                                                                    if (valid167) {
                                                                                                                                                                        if (data364.sid !==
                                                                                                                                                                            undefined) {
                                                                                                                                                                            var _errs986 = errors;
                                                                                                                                                                            if (typeof data364.sid !==
                                                                                                                                                                                'string') {
                                                                                                                                                                                validate11.errors =
                                                                                                                                                                                    [
                                                                                                                                                                                        {
                                                                                                                                                                                            instancePath: instancePath +
                                                                                                                                                                                                '/participant/sid',
                                                                                                                                                                                            schemaPath: '#/allOf/28/then/properties/participant/properties/sid/type',
                                                                                                                                                                                            keyword: 'type',
                                                                                                                                                                                            params: {
                                                                                                                                                                                                type: 'string',
                                                                                                                                                                                            },
                                                                                                                                                                                            message: 'must be string',
                                                                                                                                                                                        },
                                                                                                                                                                                    ];
                                                                                                                                                                                return false;
                                                                                                                                                                            }
                                                                                                                                                                            var valid167 = _errs986 ===
                                                                                                                                                                                errors;
                                                                                                                                                                        }
                                                                                                                                                                        else {
                                                                                                                                                                            var valid167 = true;
                                                                                                                                                                        }
                                                                                                                                                                        if (valid167) {
                                                                                                                                                                            if (data364.permissions !==
                                                                                                                                                                                undefined) {
                                                                                                                                                                                var data367 = data364.permissions;
                                                                                                                                                                                var _errs988 = errors;
                                                                                                                                                                                if (errors ===
                                                                                                                                                                                    _errs988) {
                                                                                                                                                                                    if (data367 &&
                                                                                                                                                                                        typeof data367 ==
                                                                                                                                                                                            'object' &&
                                                                                                                                                                                        !Array.isArray(data367)) {
                                                                                                                                                                                        var missing90 = void 0;
                                                                                                                                                                                        if ((data367.canSubscribe ===
                                                                                                                                                                                            undefined &&
                                                                                                                                                                                            (missing90 =
                                                                                                                                                                                                'canSubscribe')) ||
                                                                                                                                                                                            (data367.canPublish ===
                                                                                                                                                                                                undefined &&
                                                                                                                                                                                                (missing90 =
                                                                                                                                                                                                    'canPublish')) ||
                                                                                                                                                                                            (data367.canPublishData ===
                                                                                                                                                                                                undefined &&
                                                                                                                                                                                                (missing90 =
                                                                                                                                                                                                    'canPublishData')) ||
                                                                                                                                                                                            (data367.hidden ===
                                                                                                                                                                                                undefined &&
                                                                                                                                                                                                (missing90 =
                                                                                                                                                                                                    'hidden')) ||
                                                                                                                                                                                            (data367.canUpdateMetadata ===
                                                                                                                                                                                                undefined &&
                                                                                                                                                                                                (missing90 =
                                                                                                                                                                                                    'canUpdateMetadata')) ||
                                                                                                                                                                                            (data367.canSubscribeMetrics ===
                                                                                                                                                                                                undefined &&
                                                                                                                                                                                                (missing90 =
                                                                                                                                                                                                    'canSubscribeMetrics'))) {
                                                                                                                                                                                            validate11.errors =
                                                                                                                                                                                                [
                                                                                                                                                                                                    {
                                                                                                                                                                                                        instancePath: instancePath +
                                                                                                                                                                                                            '/participant/permissions',
                                                                                                                                                                                                        schemaPath: '#/allOf/28/then/properties/participant/properties/permissions/required',
                                                                                                                                                                                                        keyword: 'required',
                                                                                                                                                                                                        params: {
                                                                                                                                                                                                            missingProperty: missing90,
                                                                                                                                                                                                        },
                                                                                                                                                                                                        message: "must have required property '" +
                                                                                                                                                                                                            missing90 +
                                                                                                                                                                                                            "'",
                                                                                                                                                                                                    },
                                                                                                                                                                                                ];
                                                                                                                                                                                            return false;
                                                                                                                                                                                        }
                                                                                                                                                                                        else {
                                                                                                                                                                                            var _errs990 = errors;
                                                                                                                                                                                            for (var key91 in data367) {
                                                                                                                                                                                                if (!(key91 ===
                                                                                                                                                                                                    'canSubscribe' ||
                                                                                                                                                                                                    key91 ===
                                                                                                                                                                                                        'canPublish' ||
                                                                                                                                                                                                    key91 ===
                                                                                                                                                                                                        'canPublishData' ||
                                                                                                                                                                                                    key91 ===
                                                                                                                                                                                                        'hidden' ||
                                                                                                                                                                                                    key91 ===
                                                                                                                                                                                                        'canUpdateMetadata' ||
                                                                                                                                                                                                    key91 ===
                                                                                                                                                                                                        'canSubscribeMetrics')) {
                                                                                                                                                                                                    validate11.errors =
                                                                                                                                                                                                        [
                                                                                                                                                                                                            {
                                                                                                                                                                                                                instancePath: instancePath +
                                                                                                                                                                                                                    '/participant/permissions',
                                                                                                                                                                                                                schemaPath: '#/allOf/28/then/properties/participant/properties/permissions/additionalProperties',
                                                                                                                                                                                                                keyword: 'additionalProperties',
                                                                                                                                                                                                                params: {
                                                                                                                                                                                                                    additionalProperty: key91,
                                                                                                                                                                                                                },
                                                                                                                                                                                                                message: 'must NOT have additional properties',
                                                                                                                                                                                                            },
                                                                                                                                                                                                        ];
                                                                                                                                                                                                    return false;
                                                                                                                                                                                                    break;
                                                                                                                                                                                                }
                                                                                                                                                                                            }
                                                                                                                                                                                            if (_errs990 ===
                                                                                                                                                                                                errors) {
                                                                                                                                                                                                if (data367.canSubscribe !==
                                                                                                                                                                                                    undefined) {
                                                                                                                                                                                                    var _errs991 = errors;
                                                                                                                                                                                                    if (typeof data367.canSubscribe !==
                                                                                                                                                                                                        'boolean') {
                                                                                                                                                                                                        validate11.errors =
                                                                                                                                                                                                            [
                                                                                                                                                                                                                {
                                                                                                                                                                                                                    instancePath: instancePath +
                                                                                                                                                                                                                        '/participant/permissions/canSubscribe',
                                                                                                                                                                                                                    schemaPath: '#/allOf/28/then/properties/participant/properties/permissions/properties/canSubscribe/type',
                                                                                                                                                                                                                    keyword: 'type',
                                                                                                                                                                                                                    params: {
                                                                                                                                                                                                                        type: 'boolean',
                                                                                                                                                                                                                    },
                                                                                                                                                                                                                    message: 'must be boolean',
                                                                                                                                                                                                                },
                                                                                                                                                                                                            ];
                                                                                                                                                                                                        return false;
                                                                                                                                                                                                    }
                                                                                                                                                                                                    var valid168 = _errs991 ===
                                                                                                                                                                                                        errors;
                                                                                                                                                                                                }
                                                                                                                                                                                                else {
                                                                                                                                                                                                    var valid168 = true;
                                                                                                                                                                                                }
                                                                                                                                                                                                if (valid168) {
                                                                                                                                                                                                    if (data367.canPublish !==
                                                                                                                                                                                                        undefined) {
                                                                                                                                                                                                        var _errs993 = errors;
                                                                                                                                                                                                        if (typeof data367.canPublish !==
                                                                                                                                                                                                            'boolean') {
                                                                                                                                                                                                            validate11.errors =
                                                                                                                                                                                                                [
                                                                                                                                                                                                                    {
                                                                                                                                                                                                                        instancePath: instancePath +
                                                                                                                                                                                                                            '/participant/permissions/canPublish',
                                                                                                                                                                                                                        schemaPath: '#/allOf/28/then/properties/participant/properties/permissions/properties/canPublish/type',
                                                                                                                                                                                                                        keyword: 'type',
                                                                                                                                                                                                                        params: {
                                                                                                                                                                                                                            type: 'boolean',
                                                                                                                                                                                                                        },
                                                                                                                                                                                                                        message: 'must be boolean',
                                                                                                                                                                                                                    },
                                                                                                                                                                                                                ];
                                                                                                                                                                                                            return false;
                                                                                                                                                                                                        }
                                                                                                                                                                                                        var valid168 = _errs993 ===
                                                                                                                                                                                                            errors;
                                                                                                                                                                                                    }
                                                                                                                                                                                                    else {
                                                                                                                                                                                                        var valid168 = true;
                                                                                                                                                                                                    }
                                                                                                                                                                                                    if (valid168) {
                                                                                                                                                                                                        if (data367.canPublishData !==
                                                                                                                                                                                                            undefined) {
                                                                                                                                                                                                            var _errs995 = errors;
                                                                                                                                                                                                            if (typeof data367.canPublishData !==
                                                                                                                                                                                                                'boolean') {
                                                                                                                                                                                                                validate11.errors =
                                                                                                                                                                                                                    [
                                                                                                                                                                                                                        {
                                                                                                                                                                                                                            instancePath: instancePath +
                                                                                                                                                                                                                                '/participant/permissions/canPublishData',
                                                                                                                                                                                                                            schemaPath: '#/allOf/28/then/properties/participant/properties/permissions/properties/canPublishData/type',
                                                                                                                                                                                                                            keyword: 'type',
                                                                                                                                                                                                                            params: {
                                                                                                                                                                                                                                type: 'boolean',
                                                                                                                                                                                                                            },
                                                                                                                                                                                                                            message: 'must be boolean',
                                                                                                                                                                                                                        },
                                                                                                                                                                                                                    ];
                                                                                                                                                                                                                return false;
                                                                                                                                                                                                            }
                                                                                                                                                                                                            var valid168 = _errs995 ===
                                                                                                                                                                                                                errors;
                                                                                                                                                                                                        }
                                                                                                                                                                                                        else {
                                                                                                                                                                                                            var valid168 = true;
                                                                                                                                                                                                        }
                                                                                                                                                                                                        if (valid168) {
                                                                                                                                                                                                            if (data367.hidden !==
                                                                                                                                                                                                                undefined) {
                                                                                                                                                                                                                var _errs997 = errors;
                                                                                                                                                                                                                if (typeof data367.hidden !==
                                                                                                                                                                                                                    'boolean') {
                                                                                                                                                                                                                    validate11.errors =
                                                                                                                                                                                                                        [
                                                                                                                                                                                                                            {
                                                                                                                                                                                                                                instancePath: instancePath +
                                                                                                                                                                                                                                    '/participant/permissions/hidden',
                                                                                                                                                                                                                                schemaPath: '#/allOf/28/then/properties/participant/properties/permissions/properties/hidden/type',
                                                                                                                                                                                                                                keyword: 'type',
                                                                                                                                                                                                                                params: {
                                                                                                                                                                                                                                    type: 'boolean',
                                                                                                                                                                                                                                },
                                                                                                                                                                                                                                message: 'must be boolean',
                                                                                                                                                                                                                            },
                                                                                                                                                                                                                        ];
                                                                                                                                                                                                                    return false;
                                                                                                                                                                                                                }
                                                                                                                                                                                                                var valid168 = _errs997 ===
                                                                                                                                                                                                                    errors;
                                                                                                                                                                                                            }
                                                                                                                                                                                                            else {
                                                                                                                                                                                                                var valid168 = true;
                                                                                                                                                                                                            }
                                                                                                                                                                                                            if (valid168) {
                                                                                                                                                                                                                if (data367.canUpdateMetadata !==
                                                                                                                                                                                                                    undefined) {
                                                                                                                                                                                                                    var _errs999 = errors;
                                                                                                                                                                                                                    if (typeof data367.canUpdateMetadata !==
                                                                                                                                                                                                                        'boolean') {
                                                                                                                                                                                                                        validate11.errors =
                                                                                                                                                                                                                            [
                                                                                                                                                                                                                                {
                                                                                                                                                                                                                                    instancePath: instancePath +
                                                                                                                                                                                                                                        '/participant/permissions/canUpdateMetadata',
                                                                                                                                                                                                                                    schemaPath: '#/allOf/28/then/properties/participant/properties/permissions/properties/canUpdateMetadata/type',
                                                                                                                                                                                                                                    keyword: 'type',
                                                                                                                                                                                                                                    params: {
                                                                                                                                                                                                                                        type: 'boolean',
                                                                                                                                                                                                                                    },
                                                                                                                                                                                                                                    message: 'must be boolean',
                                                                                                                                                                                                                                },
                                                                                                                                                                                                                            ];
                                                                                                                                                                                                                        return false;
                                                                                                                                                                                                                    }
                                                                                                                                                                                                                    var valid168 = _errs999 ===
                                                                                                                                                                                                                        errors;
                                                                                                                                                                                                                }
                                                                                                                                                                                                                else {
                                                                                                                                                                                                                    var valid168 = true;
                                                                                                                                                                                                                }
                                                                                                                                                                                                                if (valid168) {
                                                                                                                                                                                                                    if (data367.canSubscribeMetrics !==
                                                                                                                                                                                                                        undefined) {
                                                                                                                                                                                                                        var _errs1001 = errors;
                                                                                                                                                                                                                        if (typeof data367.canSubscribeMetrics !==
                                                                                                                                                                                                                            'boolean') {
                                                                                                                                                                                                                            validate11.errors =
                                                                                                                                                                                                                                [
                                                                                                                                                                                                                                    {
                                                                                                                                                                                                                                        instancePath: instancePath +
                                                                                                                                                                                                                                            '/participant/permissions/canSubscribeMetrics',
                                                                                                                                                                                                                                        schemaPath: '#/allOf/28/then/properties/participant/properties/permissions/properties/canSubscribeMetrics/type',
                                                                                                                                                                                                                                        keyword: 'type',
                                                                                                                                                                                                                                        params: {
                                                                                                                                                                                                                                            type: 'boolean',
                                                                                                                                                                                                                                        },
                                                                                                                                                                                                                                        message: 'must be boolean',
                                                                                                                                                                                                                                    },
                                                                                                                                                                                                                                ];
                                                                                                                                                                                                                            return false;
                                                                                                                                                                                                                        }
                                                                                                                                                                                                                        var valid168 = _errs1001 ===
                                                                                                                                                                                                                            errors;
                                                                                                                                                                                                                    }
                                                                                                                                                                                                                    else {
                                                                                                                                                                                                                        var valid168 = true;
                                                                                                                                                                                                                    }
                                                                                                                                                                                                                }
                                                                                                                                                                                                            }
                                                                                                                                                                                                        }
                                                                                                                                                                                                    }
                                                                                                                                                                                                }
                                                                                                                                                                                            }
                                                                                                                                                                                        }
                                                                                                                                                                                    }
                                                                                                                                                                                    else {
                                                                                                                                                                                        validate11.errors =
                                                                                                                                                                                            [
                                                                                                                                                                                                {
                                                                                                                                                                                                    instancePath: instancePath +
                                                                                                                                                                                                        '/participant/permissions',
                                                                                                                                                                                                    schemaPath: '#/allOf/28/then/properties/participant/properties/permissions/type',
                                                                                                                                                                                                    keyword: 'type',
                                                                                                                                                                                                    params: {
                                                                                                                                                                                                        type: 'object',
                                                                                                                                                                                                    },
                                                                                                                                                                                                    message: 'must be object',
                                                                                                                                                                                                },
                                                                                                                                                                                            ];
                                                                                                                                                                                        return false;
                                                                                                                                                                                    }
                                                                                                                                                                                }
                                                                                                                                                                                var valid167 = _errs988 ===
                                                                                                                                                                                    errors;
                                                                                                                                                                            }
                                                                                                                                                                            else {
                                                                                                                                                                                var valid167 = true;
                                                                                                                                                                            }
                                                                                                                                                                        }
                                                                                                                                                                    }
                                                                                                                                                                }
                                                                                                                                                            }
                                                                                                                                                        }
                                                                                                                                                        else {
                                                                                                                                                            validate11.errors =
                                                                                                                                                                [
                                                                                                                                                                    {
                                                                                                                                                                        instancePath: instancePath +
                                                                                                                                                                            '/participant',
                                                                                                                                                                        schemaPath: '#/allOf/28/then/properties/participant/type',
                                                                                                                                                                        keyword: 'type',
                                                                                                                                                                        params: {
                                                                                                                                                                            type: 'object',
                                                                                                                                                                        },
                                                                                                                                                                        message: 'must be object',
                                                                                                                                                                    },
                                                                                                                                                                ];
                                                                                                                                                            return false;
                                                                                                                                                        }
                                                                                                                                                    }
                                                                                                                                                    var valid162 = _errs981 ===
                                                                                                                                                        errors;
                                                                                                                                                }
                                                                                                                                                else {
                                                                                                                                                    var valid162 = true;
                                                                                                                                                }
                                                                                                                                                if (valid162) {
                                                                                                                                                    if (data.name !==
                                                                                                                                                        undefined) {
                                                                                                                                                        var data374 = data.name;
                                                                                                                                                        var _errs1003 = errors;
                                                                                                                                                        if (typeof data374 !==
                                                                                                                                                            'string') {
                                                                                                                                                            validate11.errors =
                                                                                                                                                                [
                                                                                                                                                                    {
                                                                                                                                                                        instancePath: instancePath +
                                                                                                                                                                            '/name',
                                                                                                                                                                        schemaPath: '#/allOf/28/then/properties/name/type',
                                                                                                                                                                        keyword: 'type',
                                                                                                                                                                        params: {
                                                                                                                                                                            type: 'string',
                                                                                                                                                                        },
                                                                                                                                                                        message: 'must be string',
                                                                                                                                                                    },
                                                                                                                                                                ];
                                                                                                                                                            return false;
                                                                                                                                                        }
                                                                                                                                                        if ('trackSubscriptionPermissionChanged' !==
                                                                                                                                                            data374) {
                                                                                                                                                            validate11.errors =
                                                                                                                                                                [
                                                                                                                                                                    {
                                                                                                                                                                        instancePath: instancePath +
                                                                                                                                                                            '/name',
                                                                                                                                                                        schemaPath: '#/allOf/28/then/properties/name/const',
                                                                                                                                                                        keyword: 'const',
                                                                                                                                                                        params: {
                                                                                                                                                                            allowedValue: 'trackSubscriptionPermissionChanged',
                                                                                                                                                                        },
                                                                                                                                                                        message: 'must be equal to constant',
                                                                                                                                                                    },
                                                                                                                                                                ];
                                                                                                                                                            return false;
                                                                                                                                                        }
                                                                                                                                                        var valid162 = _errs1003 ===
                                                                                                                                                            errors;
                                                                                                                                                    }
                                                                                                                                                    else {
                                                                                                                                                        var valid162 = true;
                                                                                                                                                    }
                                                                                                                                                    if (valid162) {
                                                                                                                                                        if (data.timestamp !==
                                                                                                                                                            undefined) {
                                                                                                                                                            var data375 = data.timestamp;
                                                                                                                                                            var _errs1005 = errors;
                                                                                                                                                            if (!(typeof data375 ==
                                                                                                                                                                'number' &&
                                                                                                                                                                isFinite(data375))) {
                                                                                                                                                                validate11.errors =
                                                                                                                                                                    [
                                                                                                                                                                        {
                                                                                                                                                                            instancePath: instancePath +
                                                                                                                                                                                '/timestamp',
                                                                                                                                                                            schemaPath: '#/allOf/28/then/properties/timestamp/type',
                                                                                                                                                                            keyword: 'type',
                                                                                                                                                                            params: {
                                                                                                                                                                                type: 'number',
                                                                                                                                                                            },
                                                                                                                                                                            message: 'must be number',
                                                                                                                                                                        },
                                                                                                                                                                    ];
                                                                                                                                                                return false;
                                                                                                                                                            }
                                                                                                                                                            var valid162 = _errs1005 ===
                                                                                                                                                                errors;
                                                                                                                                                        }
                                                                                                                                                        else {
                                                                                                                                                            var valid162 = true;
                                                                                                                                                        }
                                                                                                                                                    }
                                                                                                                                                }
                                                                                                                                            }
                                                                                                                                        }
                                                                                                                                    }
                                                                                                                                }
                                                                                                                            }
                                                                                                                            else {
                                                                                                                                validate11.errors =
                                                                                                                                    [
                                                                                                                                        {
                                                                                                                                            instancePath: instancePath,
                                                                                                                                            schemaPath: '#/allOf/28/then/type',
                                                                                                                                            keyword: 'type',
                                                                                                                                            params: {
                                                                                                                                                type: 'object',
                                                                                                                                            },
                                                                                                                                            message: 'must be object',
                                                                                                                                        },
                                                                                                                                    ];
                                                                                                                                return false;
                                                                                                                            }
                                                                                                                        }
                                                                                                                        var _valid28 = _errs958 ===
                                                                                                                            errors;
                                                                                                                        valid160 = _valid28;
                                                                                                                    }
                                                                                                                    if (!valid160) {
                                                                                                                        var err86 = {
                                                                                                                            instancePath: instancePath,
                                                                                                                            schemaPath: '#/allOf/28/if',
                                                                                                                            keyword: 'if',
                                                                                                                            params: {
                                                                                                                                failingKeyword: 'then',
                                                                                                                            },
                                                                                                                            message: 'must match "then" schema',
                                                                                                                        };
                                                                                                                        if (vErrors === null) {
                                                                                                                            vErrors = [err86];
                                                                                                                        }
                                                                                                                        else {
                                                                                                                            vErrors.push(err86);
                                                                                                                        }
                                                                                                                        errors++;
                                                                                                                        validate11.errors =
                                                                                                                            vErrors;
                                                                                                                        return false;
                                                                                                                    }
                                                                                                                    var valid0 = _errs953 === errors;
                                                                                                                    if (valid0) {
                                                                                                                        var _errs1007 = errors;
                                                                                                                        var _errs1008 = errors;
                                                                                                                        var valid169 = true;
                                                                                                                        var _errs1009 = errors;
                                                                                                                        if (data &&
                                                                                                                            typeof data ==
                                                                                                                                'object' &&
                                                                                                                            !Array.isArray(data)) {
                                                                                                                            if (data.name !==
                                                                                                                                undefined) {
                                                                                                                                var data376 = data.name;
                                                                                                                                if (typeof data376 !==
                                                                                                                                    'string') {
                                                                                                                                    var err87 = {};
                                                                                                                                    if (vErrors ===
                                                                                                                                        null) {
                                                                                                                                        vErrors = [
                                                                                                                                            err87,
                                                                                                                                        ];
                                                                                                                                    }
                                                                                                                                    else {
                                                                                                                                        vErrors.push(err87);
                                                                                                                                    }
                                                                                                                                    errors++;
                                                                                                                                }
                                                                                                                                if ('trackSubscriptionStatusChanged' !==
                                                                                                                                    data376) {
                                                                                                                                    var err88 = {};
                                                                                                                                    if (vErrors ===
                                                                                                                                        null) {
                                                                                                                                        vErrors = [
                                                                                                                                            err88,
                                                                                                                                        ];
                                                                                                                                    }
                                                                                                                                    else {
                                                                                                                                        vErrors.push(err88);
                                                                                                                                    }
                                                                                                                                    errors++;
                                                                                                                                }
                                                                                                                            }
                                                                                                                        }
                                                                                                                        var _valid29 = _errs1009 ===
                                                                                                                            errors;
                                                                                                                        errors = _errs1008;
                                                                                                                        if (vErrors !== null) {
                                                                                                                            if (_errs1008) {
                                                                                                                                vErrors.length =
                                                                                                                                    _errs1008;
                                                                                                                            }
                                                                                                                            else {
                                                                                                                                vErrors = null;
                                                                                                                            }
                                                                                                                        }
                                                                                                                        if (_valid29) {
                                                                                                                            var _errs1012 = errors;
                                                                                                                            if (errors ===
                                                                                                                                _errs1012) {
                                                                                                                                if (data &&
                                                                                                                                    typeof data ==
                                                                                                                                        'object' &&
                                                                                                                                    !Array.isArray(data)) {
                                                                                                                                    var missing91 = void 0;
                                                                                                                                    if ((data.name ===
                                                                                                                                        undefined &&
                                                                                                                                        (missing91 =
                                                                                                                                            'name')) ||
                                                                                                                                        (data.participant ===
                                                                                                                                            undefined &&
                                                                                                                                            (missing91 =
                                                                                                                                                'participant')) ||
                                                                                                                                        (data.status ===
                                                                                                                                            undefined &&
                                                                                                                                            (missing91 =
                                                                                                                                                'status')) ||
                                                                                                                                        (data.timestamp ===
                                                                                                                                            undefined &&
                                                                                                                                            (missing91 =
                                                                                                                                                'timestamp')) ||
                                                                                                                                        (data.track ===
                                                                                                                                            undefined &&
                                                                                                                                            (missing91 =
                                                                                                                                                'track'))) {
                                                                                                                                        validate11.errors =
                                                                                                                                            [
                                                                                                                                                {
                                                                                                                                                    instancePath: instancePath,
                                                                                                                                                    schemaPath: '#/allOf/29/then/required',
                                                                                                                                                    keyword: 'required',
                                                                                                                                                    params: {
                                                                                                                                                        missingProperty: missing91,
                                                                                                                                                    },
                                                                                                                                                    message: "must have required property '" +
                                                                                                                                                        missing91 +
                                                                                                                                                        "'",
                                                                                                                                                },
                                                                                                                                            ];
                                                                                                                                        return false;
                                                                                                                                    }
                                                                                                                                    else {
                                                                                                                                        var _errs1014 = errors;
                                                                                                                                        for (var key92 in data) {
                                                                                                                                            if (!(key92 ===
                                                                                                                                                'status' ||
                                                                                                                                                key92 ===
                                                                                                                                                    'track' ||
                                                                                                                                                key92 ===
                                                                                                                                                    'participant' ||
                                                                                                                                                key92 ===
                                                                                                                                                    'name' ||
                                                                                                                                                key92 ===
                                                                                                                                                    'timestamp')) {
                                                                                                                                                validate11.errors =
                                                                                                                                                    [
                                                                                                                                                        {
                                                                                                                                                            instancePath: instancePath,
                                                                                                                                                            schemaPath: '#/allOf/29/then/additionalProperties',
                                                                                                                                                            keyword: 'additionalProperties',
                                                                                                                                                            params: {
                                                                                                                                                                additionalProperty: key92,
                                                                                                                                                            },
                                                                                                                                                            message: 'must NOT have additional properties',
                                                                                                                                                        },
                                                                                                                                                    ];
                                                                                                                                                return false;
                                                                                                                                                break;
                                                                                                                                            }
                                                                                                                                        }
                                                                                                                                        if (_errs1014 ===
                                                                                                                                            errors) {
                                                                                                                                            if (data.status !==
                                                                                                                                                undefined) {
                                                                                                                                                var data377 = data.status;
                                                                                                                                                var _errs1015 = errors;
                                                                                                                                                if (typeof data377 !==
                                                                                                                                                    'string') {
                                                                                                                                                    validate11.errors =
                                                                                                                                                        [
                                                                                                                                                            {
                                                                                                                                                                instancePath: instancePath +
                                                                                                                                                                    '/status',
                                                                                                                                                                schemaPath: '#/definitions/TrackPublication.SubscriptionStatus/type',
                                                                                                                                                                keyword: 'type',
                                                                                                                                                                params: {
                                                                                                                                                                    type: 'string',
                                                                                                                                                                },
                                                                                                                                                                message: 'must be string',
                                                                                                                                                            },
                                                                                                                                                        ];
                                                                                                                                                    return false;
                                                                                                                                                }
                                                                                                                                                if (!(data377 ===
                                                                                                                                                    'desired' ||
                                                                                                                                                    data377 ===
                                                                                                                                                        'subscribed' ||
                                                                                                                                                    data377 ===
                                                                                                                                                        'unsubscribed')) {
                                                                                                                                                    validate11.errors =
                                                                                                                                                        [
                                                                                                                                                            {
                                                                                                                                                                instancePath: instancePath +
                                                                                                                                                                    '/status',
                                                                                                                                                                schemaPath: '#/definitions/TrackPublication.SubscriptionStatus/enum',
                                                                                                                                                                keyword: 'enum',
                                                                                                                                                                params: {
                                                                                                                                                                    allowedValues: schema46.enum,
                                                                                                                                                                },
                                                                                                                                                                message: 'must be equal to one of the allowed values',
                                                                                                                                                            },
                                                                                                                                                        ];
                                                                                                                                                    return false;
                                                                                                                                                }
                                                                                                                                                var valid171 = _errs1015 ===
                                                                                                                                                    errors;
                                                                                                                                            }
                                                                                                                                            else {
                                                                                                                                                var valid171 = true;
                                                                                                                                            }
                                                                                                                                            if (valid171) {
                                                                                                                                                if (data.track !==
                                                                                                                                                    undefined) {
                                                                                                                                                    var data378 = data.track;
                                                                                                                                                    var _errs1018 = errors;
                                                                                                                                                    if (errors ===
                                                                                                                                                        _errs1018) {
                                                                                                                                                        if (data378 &&
                                                                                                                                                            typeof data378 ==
                                                                                                                                                                'object' &&
                                                                                                                                                            !Array.isArray(data378)) {
                                                                                                                                                            var missing92 = void 0;
                                                                                                                                                            if ((data378.sid ===
                                                                                                                                                                undefined &&
                                                                                                                                                                (missing92 =
                                                                                                                                                                    'sid')) ||
                                                                                                                                                                (data378.source ===
                                                                                                                                                                    undefined &&
                                                                                                                                                                    (missing92 =
                                                                                                                                                                        'source'))) {
                                                                                                                                                                validate11.errors =
                                                                                                                                                                    [
                                                                                                                                                                        {
                                                                                                                                                                            instancePath: instancePath +
                                                                                                                                                                                '/track',
                                                                                                                                                                            schemaPath: '#/allOf/29/then/properties/track/required',
                                                                                                                                                                            keyword: 'required',
                                                                                                                                                                            params: {
                                                                                                                                                                                missingProperty: missing92,
                                                                                                                                                                            },
                                                                                                                                                                            message: "must have required property '" +
                                                                                                                                                                                missing92 +
                                                                                                                                                                                "'",
                                                                                                                                                                        },
                                                                                                                                                                    ];
                                                                                                                                                                return false;
                                                                                                                                                            }
                                                                                                                                                            else {
                                                                                                                                                                var _errs1020 = errors;
                                                                                                                                                                for (var key93 in data378) {
                                                                                                                                                                    if (!(key93 ===
                                                                                                                                                                        'sid' ||
                                                                                                                                                                        key93 ===
                                                                                                                                                                            'source' ||
                                                                                                                                                                        key93 ===
                                                                                                                                                                            'mimeType' ||
                                                                                                                                                                        key93 ===
                                                                                                                                                                            'dimensions')) {
                                                                                                                                                                        validate11.errors =
                                                                                                                                                                            [
                                                                                                                                                                                {
                                                                                                                                                                                    instancePath: instancePath +
                                                                                                                                                                                        '/track',
                                                                                                                                                                                    schemaPath: '#/allOf/29/then/properties/track/additionalProperties',
                                                                                                                                                                                    keyword: 'additionalProperties',
                                                                                                                                                                                    params: {
                                                                                                                                                                                        additionalProperty: key93,
                                                                                                                                                                                    },
                                                                                                                                                                                    message: 'must NOT have additional properties',
                                                                                                                                                                                },
                                                                                                                                                                            ];
                                                                                                                                                                        return false;
                                                                                                                                                                        break;
                                                                                                                                                                    }
                                                                                                                                                                }
                                                                                                                                                                if (_errs1020 ===
                                                                                                                                                                    errors) {
                                                                                                                                                                    if (data378.sid !==
                                                                                                                                                                        undefined) {
                                                                                                                                                                        var _errs1021 = errors;
                                                                                                                                                                        if (typeof data378.sid !==
                                                                                                                                                                            'string') {
                                                                                                                                                                            validate11.errors =
                                                                                                                                                                                [
                                                                                                                                                                                    {
                                                                                                                                                                                        instancePath: instancePath +
                                                                                                                                                                                            '/track/sid',
                                                                                                                                                                                        schemaPath: '#/allOf/29/then/properties/track/properties/sid/type',
                                                                                                                                                                                        keyword: 'type',
                                                                                                                                                                                        params: {
                                                                                                                                                                                            type: 'string',
                                                                                                                                                                                        },
                                                                                                                                                                                        message: 'must be string',
                                                                                                                                                                                    },
                                                                                                                                                                                ];
                                                                                                                                                                            return false;
                                                                                                                                                                        }
                                                                                                                                                                        var valid173 = _errs1021 ===
                                                                                                                                                                            errors;
                                                                                                                                                                    }
                                                                                                                                                                    else {
                                                                                                                                                                        var valid173 = true;
                                                                                                                                                                    }
                                                                                                                                                                    if (valid173) {
                                                                                                                                                                        if (data378.source !==
                                                                                                                                                                            undefined) {
                                                                                                                                                                            var data380 = data378.source;
                                                                                                                                                                            var _errs1023 = errors;
                                                                                                                                                                            if (typeof data380 !==
                                                                                                                                                                                'string') {
                                                                                                                                                                                validate11.errors =
                                                                                                                                                                                    [
                                                                                                                                                                                        {
                                                                                                                                                                                            instancePath: instancePath +
                                                                                                                                                                                                '/track/source',
                                                                                                                                                                                            schemaPath: '#/definitions/Track.Source/type',
                                                                                                                                                                                            keyword: 'type',
                                                                                                                                                                                            params: {
                                                                                                                                                                                                type: 'string',
                                                                                                                                                                                            },
                                                                                                                                                                                            message: 'must be string',
                                                                                                                                                                                        },
                                                                                                                                                                                    ];
                                                                                                                                                                                return false;
                                                                                                                                                                            }
                                                                                                                                                                            if (!(data380 ===
                                                                                                                                                                                'camera' ||
                                                                                                                                                                                data380 ===
                                                                                                                                                                                    'microphone' ||
                                                                                                                                                                                data380 ===
                                                                                                                                                                                    'screen_share' ||
                                                                                                                                                                                data380 ===
                                                                                                                                                                                    'screen_share_audio' ||
                                                                                                                                                                                data380 ===
                                                                                                                                                                                    'unknown')) {
                                                                                                                                                                                validate11.errors =
                                                                                                                                                                                    [
                                                                                                                                                                                        {
                                                                                                                                                                                            instancePath: instancePath +
                                                                                                                                                                                                '/track/source',
                                                                                                                                                                                            schemaPath: '#/definitions/Track.Source/enum',
                                                                                                                                                                                            keyword: 'enum',
                                                                                                                                                                                            params: {
                                                                                                                                                                                                allowedValues: schema31.enum,
                                                                                                                                                                                            },
                                                                                                                                                                                            message: 'must be equal to one of the allowed values',
                                                                                                                                                                                        },
                                                                                                                                                                                    ];
                                                                                                                                                                                return false;
                                                                                                                                                                            }
                                                                                                                                                                            var valid173 = _errs1023 ===
                                                                                                                                                                                errors;
                                                                                                                                                                        }
                                                                                                                                                                        else {
                                                                                                                                                                            var valid173 = true;
                                                                                                                                                                        }
                                                                                                                                                                        if (valid173) {
                                                                                                                                                                            if (data378.mimeType !==
                                                                                                                                                                                undefined) {
                                                                                                                                                                                var _errs1026 = errors;
                                                                                                                                                                                if (typeof data378.mimeType !==
                                                                                                                                                                                    'string') {
                                                                                                                                                                                    validate11.errors =
                                                                                                                                                                                        [
                                                                                                                                                                                            {
                                                                                                                                                                                                instancePath: instancePath +
                                                                                                                                                                                                    '/track/mimeType',
                                                                                                                                                                                                schemaPath: '#/allOf/29/then/properties/track/properties/mimeType/type',
                                                                                                                                                                                                keyword: 'type',
                                                                                                                                                                                                params: {
                                                                                                                                                                                                    type: 'string',
                                                                                                                                                                                                },
                                                                                                                                                                                                message: 'must be string',
                                                                                                                                                                                            },
                                                                                                                                                                                        ];
                                                                                                                                                                                    return false;
                                                                                                                                                                                }
                                                                                                                                                                                var valid173 = _errs1026 ===
                                                                                                                                                                                    errors;
                                                                                                                                                                            }
                                                                                                                                                                            else {
                                                                                                                                                                                var valid173 = true;
                                                                                                                                                                            }
                                                                                                                                                                            if (valid173) {
                                                                                                                                                                                if (data378.dimensions !==
                                                                                                                                                                                    undefined) {
                                                                                                                                                                                    var data382 = data378.dimensions;
                                                                                                                                                                                    var _errs1028 = errors;
                                                                                                                                                                                    if (errors ===
                                                                                                                                                                                        _errs1028) {
                                                                                                                                                                                        if (data382 &&
                                                                                                                                                                                            typeof data382 ==
                                                                                                                                                                                                'object' &&
                                                                                                                                                                                            !Array.isArray(data382)) {
                                                                                                                                                                                            var missing93 = void 0;
                                                                                                                                                                                            if ((data382.width ===
                                                                                                                                                                                                undefined &&
                                                                                                                                                                                                (missing93 =
                                                                                                                                                                                                    'width')) ||
                                                                                                                                                                                                (data382.height ===
                                                                                                                                                                                                    undefined &&
                                                                                                                                                                                                    (missing93 =
                                                                                                                                                                                                        'height'))) {
                                                                                                                                                                                                validate11.errors =
                                                                                                                                                                                                    [
                                                                                                                                                                                                        {
                                                                                                                                                                                                            instancePath: instancePath +
                                                                                                                                                                                                                '/track/dimensions',
                                                                                                                                                                                                            schemaPath: '#/allOf/29/then/properties/track/properties/dimensions/required',
                                                                                                                                                                                                            keyword: 'required',
                                                                                                                                                                                                            params: {
                                                                                                                                                                                                                missingProperty: missing93,
                                                                                                                                                                                                            },
                                                                                                                                                                                                            message: "must have required property '" +
                                                                                                                                                                                                                missing93 +
                                                                                                                                                                                                                "'",
                                                                                                                                                                                                        },
                                                                                                                                                                                                    ];
                                                                                                                                                                                                return false;
                                                                                                                                                                                            }
                                                                                                                                                                                            else {
                                                                                                                                                                                                var _errs1030 = errors;
                                                                                                                                                                                                for (var key94 in data382) {
                                                                                                                                                                                                    if (!(key94 ===
                                                                                                                                                                                                        'width' ||
                                                                                                                                                                                                        key94 ===
                                                                                                                                                                                                            'height')) {
                                                                                                                                                                                                        validate11.errors =
                                                                                                                                                                                                            [
                                                                                                                                                                                                                {
                                                                                                                                                                                                                    instancePath: instancePath +
                                                                                                                                                                                                                        '/track/dimensions',
                                                                                                                                                                                                                    schemaPath: '#/allOf/29/then/properties/track/properties/dimensions/additionalProperties',
                                                                                                                                                                                                                    keyword: 'additionalProperties',
                                                                                                                                                                                                                    params: {
                                                                                                                                                                                                                        additionalProperty: key94,
                                                                                                                                                                                                                    },
                                                                                                                                                                                                                    message: 'must NOT have additional properties',
                                                                                                                                                                                                                },
                                                                                                                                                                                                            ];
                                                                                                                                                                                                        return false;
                                                                                                                                                                                                        break;
                                                                                                                                                                                                    }
                                                                                                                                                                                                }
                                                                                                                                                                                                if (_errs1030 ===
                                                                                                                                                                                                    errors) {
                                                                                                                                                                                                    if (data382.width !==
                                                                                                                                                                                                        undefined) {
                                                                                                                                                                                                        var data383 = data382.width;
                                                                                                                                                                                                        var _errs1031 = errors;
                                                                                                                                                                                                        if (!(typeof data383 ==
                                                                                                                                                                                                            'number' &&
                                                                                                                                                                                                            isFinite(data383))) {
                                                                                                                                                                                                            validate11.errors =
                                                                                                                                                                                                                [
                                                                                                                                                                                                                    {
                                                                                                                                                                                                                        instancePath: instancePath +
                                                                                                                                                                                                                            '/track/dimensions/width',
                                                                                                                                                                                                                        schemaPath: '#/allOf/29/then/properties/track/properties/dimensions/properties/width/type',
                                                                                                                                                                                                                        keyword: 'type',
                                                                                                                                                                                                                        params: {
                                                                                                                                                                                                                            type: 'number',
                                                                                                                                                                                                                        },
                                                                                                                                                                                                                        message: 'must be number',
                                                                                                                                                                                                                    },
                                                                                                                                                                                                                ];
                                                                                                                                                                                                            return false;
                                                                                                                                                                                                        }
                                                                                                                                                                                                        var valid175 = _errs1031 ===
                                                                                                                                                                                                            errors;
                                                                                                                                                                                                    }
                                                                                                                                                                                                    else {
                                                                                                                                                                                                        var valid175 = true;
                                                                                                                                                                                                    }
                                                                                                                                                                                                    if (valid175) {
                                                                                                                                                                                                        if (data382.height !==
                                                                                                                                                                                                            undefined) {
                                                                                                                                                                                                            var data384 = data382.height;
                                                                                                                                                                                                            var _errs1033 = errors;
                                                                                                                                                                                                            if (!(typeof data384 ==
                                                                                                                                                                                                                'number' &&
                                                                                                                                                                                                                isFinite(data384))) {
                                                                                                                                                                                                                validate11.errors =
                                                                                                                                                                                                                    [
                                                                                                                                                                                                                        {
                                                                                                                                                                                                                            instancePath: instancePath +
                                                                                                                                                                                                                                '/track/dimensions/height',
                                                                                                                                                                                                                            schemaPath: '#/allOf/29/then/properties/track/properties/dimensions/properties/height/type',
                                                                                                                                                                                                                            keyword: 'type',
                                                                                                                                                                                                                            params: {
                                                                                                                                                                                                                                type: 'number',
                                                                                                                                                                                                                            },
                                                                                                                                                                                                                            message: 'must be number',
                                                                                                                                                                                                                        },
                                                                                                                                                                                                                    ];
                                                                                                                                                                                                                return false;
                                                                                                                                                                                                            }
                                                                                                                                                                                                            var valid175 = _errs1033 ===
                                                                                                                                                                                                                errors;
                                                                                                                                                                                                        }
                                                                                                                                                                                                        else {
                                                                                                                                                                                                            var valid175 = true;
                                                                                                                                                                                                        }
                                                                                                                                                                                                    }
                                                                                                                                                                                                }
                                                                                                                                                                                            }
                                                                                                                                                                                        }
                                                                                                                                                                                        else {
                                                                                                                                                                                            validate11.errors =
                                                                                                                                                                                                [
                                                                                                                                                                                                    {
                                                                                                                                                                                                        instancePath: instancePath +
                                                                                                                                                                                                            '/track/dimensions',
                                                                                                                                                                                                        schemaPath: '#/allOf/29/then/properties/track/properties/dimensions/type',
                                                                                                                                                                                                        keyword: 'type',
                                                                                                                                                                                                        params: {
                                                                                                                                                                                                            type: 'object',
                                                                                                                                                                                                        },
                                                                                                                                                                                                        message: 'must be object',
                                                                                                                                                                                                    },
                                                                                                                                                                                                ];
                                                                                                                                                                                            return false;
                                                                                                                                                                                        }
                                                                                                                                                                                    }
                                                                                                                                                                                    var valid173 = _errs1028 ===
                                                                                                                                                                                        errors;
                                                                                                                                                                                }
                                                                                                                                                                                else {
                                                                                                                                                                                    var valid173 = true;
                                                                                                                                                                                }
                                                                                                                                                                            }
                                                                                                                                                                        }
                                                                                                                                                                    }
                                                                                                                                                                }
                                                                                                                                                            }
                                                                                                                                                        }
                                                                                                                                                        else {
                                                                                                                                                            validate11.errors =
                                                                                                                                                                [
                                                                                                                                                                    {
                                                                                                                                                                        instancePath: instancePath +
                                                                                                                                                                            '/track',
                                                                                                                                                                        schemaPath: '#/allOf/29/then/properties/track/type',
                                                                                                                                                                        keyword: 'type',
                                                                                                                                                                        params: {
                                                                                                                                                                            type: 'object',
                                                                                                                                                                        },
                                                                                                                                                                        message: 'must be object',
                                                                                                                                                                    },
                                                                                                                                                                ];
                                                                                                                                                            return false;
                                                                                                                                                        }
                                                                                                                                                    }
                                                                                                                                                    var valid171 = _errs1018 ===
                                                                                                                                                        errors;
                                                                                                                                                }
                                                                                                                                                else {
                                                                                                                                                    var valid171 = true;
                                                                                                                                                }
                                                                                                                                                if (valid171) {
                                                                                                                                                    if (data.participant !==
                                                                                                                                                        undefined) {
                                                                                                                                                        var data385 = data.participant;
                                                                                                                                                        var _errs1035 = errors;
                                                                                                                                                        if (errors ===
                                                                                                                                                            _errs1035) {
                                                                                                                                                            if (data385 &&
                                                                                                                                                                typeof data385 ==
                                                                                                                                                                    'object' &&
                                                                                                                                                                !Array.isArray(data385)) {
                                                                                                                                                                var missing94 = void 0;
                                                                                                                                                                if ((data385.identity ===
                                                                                                                                                                    undefined &&
                                                                                                                                                                    (missing94 =
                                                                                                                                                                        'identity')) ||
                                                                                                                                                                    (data385.sid ===
                                                                                                                                                                        undefined &&
                                                                                                                                                                        (missing94 =
                                                                                                                                                                            'sid'))) {
                                                                                                                                                                    validate11.errors =
                                                                                                                                                                        [
                                                                                                                                                                            {
                                                                                                                                                                                instancePath: instancePath +
                                                                                                                                                                                    '/participant',
                                                                                                                                                                                schemaPath: '#/allOf/29/then/properties/participant/required',
                                                                                                                                                                                keyword: 'required',
                                                                                                                                                                                params: {
                                                                                                                                                                                    missingProperty: missing94,
                                                                                                                                                                                },
                                                                                                                                                                                message: "must have required property '" +
                                                                                                                                                                                    missing94 +
                                                                                                                                                                                    "'",
                                                                                                                                                                            },
                                                                                                                                                                        ];
                                                                                                                                                                    return false;
                                                                                                                                                                }
                                                                                                                                                                else {
                                                                                                                                                                    var _errs1037 = errors;
                                                                                                                                                                    for (var key95 in data385) {
                                                                                                                                                                        if (!(key95 ===
                                                                                                                                                                            'identity' ||
                                                                                                                                                                            key95 ===
                                                                                                                                                                                'sid' ||
                                                                                                                                                                            key95 ===
                                                                                                                                                                                'permissions')) {
                                                                                                                                                                            validate11.errors =
                                                                                                                                                                                [
                                                                                                                                                                                    {
                                                                                                                                                                                        instancePath: instancePath +
                                                                                                                                                                                            '/participant',
                                                                                                                                                                                        schemaPath: '#/allOf/29/then/properties/participant/additionalProperties',
                                                                                                                                                                                        keyword: 'additionalProperties',
                                                                                                                                                                                        params: {
                                                                                                                                                                                            additionalProperty: key95,
                                                                                                                                                                                        },
                                                                                                                                                                                        message: 'must NOT have additional properties',
                                                                                                                                                                                    },
                                                                                                                                                                                ];
                                                                                                                                                                            return false;
                                                                                                                                                                            break;
                                                                                                                                                                        }
                                                                                                                                                                    }
                                                                                                                                                                    if (_errs1037 ===
                                                                                                                                                                        errors) {
                                                                                                                                                                        if (data385.identity !==
                                                                                                                                                                            undefined) {
                                                                                                                                                                            var _errs1038 = errors;
                                                                                                                                                                            if (typeof data385.identity !==
                                                                                                                                                                                'string') {
                                                                                                                                                                                validate11.errors =
                                                                                                                                                                                    [
                                                                                                                                                                                        {
                                                                                                                                                                                            instancePath: instancePath +
                                                                                                                                                                                                '/participant/identity',
                                                                                                                                                                                            schemaPath: '#/allOf/29/then/properties/participant/properties/identity/type',
                                                                                                                                                                                            keyword: 'type',
                                                                                                                                                                                            params: {
                                                                                                                                                                                                type: 'string',
                                                                                                                                                                                            },
                                                                                                                                                                                            message: 'must be string',
                                                                                                                                                                                        },
                                                                                                                                                                                    ];
                                                                                                                                                                                return false;
                                                                                                                                                                            }
                                                                                                                                                                            var valid176 = _errs1038 ===
                                                                                                                                                                                errors;
                                                                                                                                                                        }
                                                                                                                                                                        else {
                                                                                                                                                                            var valid176 = true;
                                                                                                                                                                        }
                                                                                                                                                                        if (valid176) {
                                                                                                                                                                            if (data385.sid !==
                                                                                                                                                                                undefined) {
                                                                                                                                                                                var _errs1040 = errors;
                                                                                                                                                                                if (typeof data385.sid !==
                                                                                                                                                                                    'string') {
                                                                                                                                                                                    validate11.errors =
                                                                                                                                                                                        [
                                                                                                                                                                                            {
                                                                                                                                                                                                instancePath: instancePath +
                                                                                                                                                                                                    '/participant/sid',
                                                                                                                                                                                                schemaPath: '#/allOf/29/then/properties/participant/properties/sid/type',
                                                                                                                                                                                                keyword: 'type',
                                                                                                                                                                                                params: {
                                                                                                                                                                                                    type: 'string',
                                                                                                                                                                                                },
                                                                                                                                                                                                message: 'must be string',
                                                                                                                                                                                            },
                                                                                                                                                                                        ];
                                                                                                                                                                                    return false;
                                                                                                                                                                                }
                                                                                                                                                                                var valid176 = _errs1040 ===
                                                                                                                                                                                    errors;
                                                                                                                                                                            }
                                                                                                                                                                            else {
                                                                                                                                                                                var valid176 = true;
                                                                                                                                                                            }
                                                                                                                                                                            if (valid176) {
                                                                                                                                                                                if (data385.permissions !==
                                                                                                                                                                                    undefined) {
                                                                                                                                                                                    var data388 = data385.permissions;
                                                                                                                                                                                    var _errs1042 = errors;
                                                                                                                                                                                    if (errors ===
                                                                                                                                                                                        _errs1042) {
                                                                                                                                                                                        if (data388 &&
                                                                                                                                                                                            typeof data388 ==
                                                                                                                                                                                                'object' &&
                                                                                                                                                                                            !Array.isArray(data388)) {
                                                                                                                                                                                            var missing95 = void 0;
                                                                                                                                                                                            if ((data388.canSubscribe ===
                                                                                                                                                                                                undefined &&
                                                                                                                                                                                                (missing95 =
                                                                                                                                                                                                    'canSubscribe')) ||
                                                                                                                                                                                                (data388.canPublish ===
                                                                                                                                                                                                    undefined &&
                                                                                                                                                                                                    (missing95 =
                                                                                                                                                                                                        'canPublish')) ||
                                                                                                                                                                                                (data388.canPublishData ===
                                                                                                                                                                                                    undefined &&
                                                                                                                                                                                                    (missing95 =
                                                                                                                                                                                                        'canPublishData')) ||
                                                                                                                                                                                                (data388.hidden ===
                                                                                                                                                                                                    undefined &&
                                                                                                                                                                                                    (missing95 =
                                                                                                                                                                                                        'hidden')) ||
                                                                                                                                                                                                (data388.canUpdateMetadata ===
                                                                                                                                                                                                    undefined &&
                                                                                                                                                                                                    (missing95 =
                                                                                                                                                                                                        'canUpdateMetadata')) ||
                                                                                                                                                                                                (data388.canSubscribeMetrics ===
                                                                                                                                                                                                    undefined &&
                                                                                                                                                                                                    (missing95 =
                                                                                                                                                                                                        'canSubscribeMetrics'))) {
                                                                                                                                                                                                validate11.errors =
                                                                                                                                                                                                    [
                                                                                                                                                                                                        {
                                                                                                                                                                                                            instancePath: instancePath +
                                                                                                                                                                                                                '/participant/permissions',
                                                                                                                                                                                                            schemaPath: '#/allOf/29/then/properties/participant/properties/permissions/required',
                                                                                                                                                                                                            keyword: 'required',
                                                                                                                                                                                                            params: {
                                                                                                                                                                                                                missingProperty: missing95,
                                                                                                                                                                                                            },
                                                                                                                                                                                                            message: "must have required property '" +
                                                                                                                                                                                                                missing95 +
                                                                                                                                                                                                                "'",
                                                                                                                                                                                                        },
                                                                                                                                                                                                    ];
                                                                                                                                                                                                return false;
                                                                                                                                                                                            }
                                                                                                                                                                                            else {
                                                                                                                                                                                                var _errs1044 = errors;
                                                                                                                                                                                                for (var key96 in data388) {
                                                                                                                                                                                                    if (!(key96 ===
                                                                                                                                                                                                        'canSubscribe' ||
                                                                                                                                                                                                        key96 ===
                                                                                                                                                                                                            'canPublish' ||
                                                                                                                                                                                                        key96 ===
                                                                                                                                                                                                            'canPublishData' ||
                                                                                                                                                                                                        key96 ===
                                                                                                                                                                                                            'hidden' ||
                                                                                                                                                                                                        key96 ===
                                                                                                                                                                                                            'canUpdateMetadata' ||
                                                                                                                                                                                                        key96 ===
                                                                                                                                                                                                            'canSubscribeMetrics')) {
                                                                                                                                                                                                        validate11.errors =
                                                                                                                                                                                                            [
                                                                                                                                                                                                                {
                                                                                                                                                                                                                    instancePath: instancePath +
                                                                                                                                                                                                                        '/participant/permissions',
                                                                                                                                                                                                                    schemaPath: '#/allOf/29/then/properties/participant/properties/permissions/additionalProperties',
                                                                                                                                                                                                                    keyword: 'additionalProperties',
                                                                                                                                                                                                                    params: {
                                                                                                                                                                                                                        additionalProperty: key96,
                                                                                                                                                                                                                    },
                                                                                                                                                                                                                    message: 'must NOT have additional properties',
                                                                                                                                                                                                                },
                                                                                                                                                                                                            ];
                                                                                                                                                                                                        return false;
                                                                                                                                                                                                        break;
                                                                                                                                                                                                    }
                                                                                                                                                                                                }
                                                                                                                                                                                                if (_errs1044 ===
                                                                                                                                                                                                    errors) {
                                                                                                                                                                                                    if (data388.canSubscribe !==
                                                                                                                                                                                                        undefined) {
                                                                                                                                                                                                        var _errs1045 = errors;
                                                                                                                                                                                                        if (typeof data388.canSubscribe !==
                                                                                                                                                                                                            'boolean') {
                                                                                                                                                                                                            validate11.errors =
                                                                                                                                                                                                                [
                                                                                                                                                                                                                    {
                                                                                                                                                                                                                        instancePath: instancePath +
                                                                                                                                                                                                                            '/participant/permissions/canSubscribe',
                                                                                                                                                                                                                        schemaPath: '#/allOf/29/then/properties/participant/properties/permissions/properties/canSubscribe/type',
                                                                                                                                                                                                                        keyword: 'type',
                                                                                                                                                                                                                        params: {
                                                                                                                                                                                                                            type: 'boolean',
                                                                                                                                                                                                                        },
                                                                                                                                                                                                                        message: 'must be boolean',
                                                                                                                                                                                                                    },
                                                                                                                                                                                                                ];
                                                                                                                                                                                                            return false;
                                                                                                                                                                                                        }
                                                                                                                                                                                                        var valid177 = _errs1045 ===
                                                                                                                                                                                                            errors;
                                                                                                                                                                                                    }
                                                                                                                                                                                                    else {
                                                                                                                                                                                                        var valid177 = true;
                                                                                                                                                                                                    }
                                                                                                                                                                                                    if (valid177) {
                                                                                                                                                                                                        if (data388.canPublish !==
                                                                                                                                                                                                            undefined) {
                                                                                                                                                                                                            var _errs1047 = errors;
                                                                                                                                                                                                            if (typeof data388.canPublish !==
                                                                                                                                                                                                                'boolean') {
                                                                                                                                                                                                                validate11.errors =
                                                                                                                                                                                                                    [
                                                                                                                                                                                                                        {
                                                                                                                                                                                                                            instancePath: instancePath +
                                                                                                                                                                                                                                '/participant/permissions/canPublish',
                                                                                                                                                                                                                            schemaPath: '#/allOf/29/then/properties/participant/properties/permissions/properties/canPublish/type',
                                                                                                                                                                                                                            keyword: 'type',
                                                                                                                                                                                                                            params: {
                                                                                                                                                                                                                                type: 'boolean',
                                                                                                                                                                                                                            },
                                                                                                                                                                                                                            message: 'must be boolean',
                                                                                                                                                                                                                        },
                                                                                                                                                                                                                    ];
                                                                                                                                                                                                                return false;
                                                                                                                                                                                                            }
                                                                                                                                                                                                            var valid177 = _errs1047 ===
                                                                                                                                                                                                                errors;
                                                                                                                                                                                                        }
                                                                                                                                                                                                        else {
                                                                                                                                                                                                            var valid177 = true;
                                                                                                                                                                                                        }
                                                                                                                                                                                                        if (valid177) {
                                                                                                                                                                                                            if (data388.canPublishData !==
                                                                                                                                                                                                                undefined) {
                                                                                                                                                                                                                var _errs1049 = errors;
                                                                                                                                                                                                                if (typeof data388.canPublishData !==
                                                                                                                                                                                                                    'boolean') {
                                                                                                                                                                                                                    validate11.errors =
                                                                                                                                                                                                                        [
                                                                                                                                                                                                                            {
                                                                                                                                                                                                                                instancePath: instancePath +
                                                                                                                                                                                                                                    '/participant/permissions/canPublishData',
                                                                                                                                                                                                                                schemaPath: '#/allOf/29/then/properties/participant/properties/permissions/properties/canPublishData/type',
                                                                                                                                                                                                                                keyword: 'type',
                                                                                                                                                                                                                                params: {
                                                                                                                                                                                                                                    type: 'boolean',
                                                                                                                                                                                                                                },
                                                                                                                                                                                                                                message: 'must be boolean',
                                                                                                                                                                                                                            },
                                                                                                                                                                                                                        ];
                                                                                                                                                                                                                    return false;
                                                                                                                                                                                                                }
                                                                                                                                                                                                                var valid177 = _errs1049 ===
                                                                                                                                                                                                                    errors;
                                                                                                                                                                                                            }
                                                                                                                                                                                                            else {
                                                                                                                                                                                                                var valid177 = true;
                                                                                                                                                                                                            }
                                                                                                                                                                                                            if (valid177) {
                                                                                                                                                                                                                if (data388.hidden !==
                                                                                                                                                                                                                    undefined) {
                                                                                                                                                                                                                    var _errs1051 = errors;
                                                                                                                                                                                                                    if (typeof data388.hidden !==
                                                                                                                                                                                                                        'boolean') {
                                                                                                                                                                                                                        validate11.errors =
                                                                                                                                                                                                                            [
                                                                                                                                                                                                                                {
                                                                                                                                                                                                                                    instancePath: instancePath +
                                                                                                                                                                                                                                        '/participant/permissions/hidden',
                                                                                                                                                                                                                                    schemaPath: '#/allOf/29/then/properties/participant/properties/permissions/properties/hidden/type',
                                                                                                                                                                                                                                    keyword: 'type',
                                                                                                                                                                                                                                    params: {
                                                                                                                                                                                                                                        type: 'boolean',
                                                                                                                                                                                                                                    },
                                                                                                                                                                                                                                    message: 'must be boolean',
                                                                                                                                                                                                                                },
                                                                                                                                                                                                                            ];
                                                                                                                                                                                                                        return false;
                                                                                                                                                                                                                    }
                                                                                                                                                                                                                    var valid177 = _errs1051 ===
                                                                                                                                                                                                                        errors;
                                                                                                                                                                                                                }
                                                                                                                                                                                                                else {
                                                                                                                                                                                                                    var valid177 = true;
                                                                                                                                                                                                                }
                                                                                                                                                                                                                if (valid177) {
                                                                                                                                                                                                                    if (data388.canUpdateMetadata !==
                                                                                                                                                                                                                        undefined) {
                                                                                                                                                                                                                        var _errs1053 = errors;
                                                                                                                                                                                                                        if (typeof data388.canUpdateMetadata !==
                                                                                                                                                                                                                            'boolean') {
                                                                                                                                                                                                                            validate11.errors =
                                                                                                                                                                                                                                [
                                                                                                                                                                                                                                    {
                                                                                                                                                                                                                                        instancePath: instancePath +
                                                                                                                                                                                                                                            '/participant/permissions/canUpdateMetadata',
                                                                                                                                                                                                                                        schemaPath: '#/allOf/29/then/properties/participant/properties/permissions/properties/canUpdateMetadata/type',
                                                                                                                                                                                                                                        keyword: 'type',
                                                                                                                                                                                                                                        params: {
                                                                                                                                                                                                                                            type: 'boolean',
                                                                                                                                                                                                                                        },
                                                                                                                                                                                                                                        message: 'must be boolean',
                                                                                                                                                                                                                                    },
                                                                                                                                                                                                                                ];
                                                                                                                                                                                                                            return false;
                                                                                                                                                                                                                        }
                                                                                                                                                                                                                        var valid177 = _errs1053 ===
                                                                                                                                                                                                                            errors;
                                                                                                                                                                                                                    }
                                                                                                                                                                                                                    else {
                                                                                                                                                                                                                        var valid177 = true;
                                                                                                                                                                                                                    }
                                                                                                                                                                                                                    if (valid177) {
                                                                                                                                                                                                                        if (data388.canSubscribeMetrics !==
                                                                                                                                                                                                                            undefined) {
                                                                                                                                                                                                                            var _errs1055 = errors;
                                                                                                                                                                                                                            if (typeof data388.canSubscribeMetrics !==
                                                                                                                                                                                                                                'boolean') {
                                                                                                                                                                                                                                validate11.errors =
                                                                                                                                                                                                                                    [
                                                                                                                                                                                                                                        {
                                                                                                                                                                                                                                            instancePath: instancePath +
                                                                                                                                                                                                                                                '/participant/permissions/canSubscribeMetrics',
                                                                                                                                                                                                                                            schemaPath: '#/allOf/29/then/properties/participant/properties/permissions/properties/canSubscribeMetrics/type',
                                                                                                                                                                                                                                            keyword: 'type',
                                                                                                                                                                                                                                            params: {
                                                                                                                                                                                                                                                type: 'boolean',
                                                                                                                                                                                                                                            },
                                                                                                                                                                                                                                            message: 'must be boolean',
                                                                                                                                                                                                                                        },
                                                                                                                                                                                                                                    ];
                                                                                                                                                                                                                                return false;
                                                                                                                                                                                                                            }
                                                                                                                                                                                                                            var valid177 = _errs1055 ===
                                                                                                                                                                                                                                errors;
                                                                                                                                                                                                                        }
                                                                                                                                                                                                                        else {
                                                                                                                                                                                                                            var valid177 = true;
                                                                                                                                                                                                                        }
                                                                                                                                                                                                                    }
                                                                                                                                                                                                                }
                                                                                                                                                                                                            }
                                                                                                                                                                                                        }
                                                                                                                                                                                                    }
                                                                                                                                                                                                }
                                                                                                                                                                                            }
                                                                                                                                                                                        }
                                                                                                                                                                                        else {
                                                                                                                                                                                            validate11.errors =
                                                                                                                                                                                                [
                                                                                                                                                                                                    {
                                                                                                                                                                                                        instancePath: instancePath +
                                                                                                                                                                                                            '/participant/permissions',
                                                                                                                                                                                                        schemaPath: '#/allOf/29/then/properties/participant/properties/permissions/type',
                                                                                                                                                                                                        keyword: 'type',
                                                                                                                                                                                                        params: {
                                                                                                                                                                                                            type: 'object',
                                                                                                                                                                                                        },
                                                                                                                                                                                                        message: 'must be object',
                                                                                                                                                                                                    },
                                                                                                                                                                                                ];
                                                                                                                                                                                            return false;
                                                                                                                                                                                        }
                                                                                                                                                                                    }
                                                                                                                                                                                    var valid176 = _errs1042 ===
                                                                                                                                                                                        errors;
                                                                                                                                                                                }
                                                                                                                                                                                else {
                                                                                                                                                                                    var valid176 = true;
                                                                                                                                                                                }
                                                                                                                                                                            }
                                                                                                                                                                        }
                                                                                                                                                                    }
                                                                                                                                                                }
                                                                                                                                                            }
                                                                                                                                                            else {
                                                                                                                                                                validate11.errors =
                                                                                                                                                                    [
                                                                                                                                                                        {
                                                                                                                                                                            instancePath: instancePath +
                                                                                                                                                                                '/participant',
                                                                                                                                                                            schemaPath: '#/allOf/29/then/properties/participant/type',
                                                                                                                                                                            keyword: 'type',
                                                                                                                                                                            params: {
                                                                                                                                                                                type: 'object',
                                                                                                                                                                            },
                                                                                                                                                                            message: 'must be object',
                                                                                                                                                                        },
                                                                                                                                                                    ];
                                                                                                                                                                return false;
                                                                                                                                                            }
                                                                                                                                                        }
                                                                                                                                                        var valid171 = _errs1035 ===
                                                                                                                                                            errors;
                                                                                                                                                    }
                                                                                                                                                    else {
                                                                                                                                                        var valid171 = true;
                                                                                                                                                    }
                                                                                                                                                    if (valid171) {
                                                                                                                                                        if (data.name !==
                                                                                                                                                            undefined) {
                                                                                                                                                            var data395 = data.name;
                                                                                                                                                            var _errs1057 = errors;
                                                                                                                                                            if (typeof data395 !==
                                                                                                                                                                'string') {
                                                                                                                                                                validate11.errors =
                                                                                                                                                                    [
                                                                                                                                                                        {
                                                                                                                                                                            instancePath: instancePath +
                                                                                                                                                                                '/name',
                                                                                                                                                                            schemaPath: '#/allOf/29/then/properties/name/type',
                                                                                                                                                                            keyword: 'type',
                                                                                                                                                                            params: {
                                                                                                                                                                                type: 'string',
                                                                                                                                                                            },
                                                                                                                                                                            message: 'must be string',
                                                                                                                                                                        },
                                                                                                                                                                    ];
                                                                                                                                                                return false;
                                                                                                                                                            }
                                                                                                                                                            if ('trackSubscriptionStatusChanged' !==
                                                                                                                                                                data395) {
                                                                                                                                                                validate11.errors =
                                                                                                                                                                    [
                                                                                                                                                                        {
                                                                                                                                                                            instancePath: instancePath +
                                                                                                                                                                                '/name',
                                                                                                                                                                            schemaPath: '#/allOf/29/then/properties/name/const',
                                                                                                                                                                            keyword: 'const',
                                                                                                                                                                            params: {
                                                                                                                                                                                allowedValue: 'trackSubscriptionStatusChanged',
                                                                                                                                                                            },
                                                                                                                                                                            message: 'must be equal to constant',
                                                                                                                                                                        },
                                                                                                                                                                    ];
                                                                                                                                                                return false;
                                                                                                                                                            }
                                                                                                                                                            var valid171 = _errs1057 ===
                                                                                                                                                                errors;
                                                                                                                                                        }
                                                                                                                                                        else {
                                                                                                                                                            var valid171 = true;
                                                                                                                                                        }
                                                                                                                                                        if (valid171) {
                                                                                                                                                            if (data.timestamp !==
                                                                                                                                                                undefined) {
                                                                                                                                                                var data396 = data.timestamp;
                                                                                                                                                                var _errs1059 = errors;
                                                                                                                                                                if (!(typeof data396 ==
                                                                                                                                                                    'number' &&
                                                                                                                                                                    isFinite(data396))) {
                                                                                                                                                                    validate11.errors =
                                                                                                                                                                        [
                                                                                                                                                                            {
                                                                                                                                                                                instancePath: instancePath +
                                                                                                                                                                                    '/timestamp',
                                                                                                                                                                                schemaPath: '#/allOf/29/then/properties/timestamp/type',
                                                                                                                                                                                keyword: 'type',
                                                                                                                                                                                params: {
                                                                                                                                                                                    type: 'number',
                                                                                                                                                                                },
                                                                                                                                                                                message: 'must be number',
                                                                                                                                                                            },
                                                                                                                                                                        ];
                                                                                                                                                                    return false;
                                                                                                                                                                }
                                                                                                                                                                var valid171 = _errs1059 ===
                                                                                                                                                                    errors;
                                                                                                                                                            }
                                                                                                                                                            else {
                                                                                                                                                                var valid171 = true;
                                                                                                                                                            }
                                                                                                                                                        }
                                                                                                                                                    }
                                                                                                                                                }
                                                                                                                                            }
                                                                                                                                        }
                                                                                                                                    }
                                                                                                                                }
                                                                                                                                else {
                                                                                                                                    validate11.errors =
                                                                                                                                        [
                                                                                                                                            {
                                                                                                                                                instancePath: instancePath,
                                                                                                                                                schemaPath: '#/allOf/29/then/type',
                                                                                                                                                keyword: 'type',
                                                                                                                                                params: {
                                                                                                                                                    type: 'object',
                                                                                                                                                },
                                                                                                                                                message: 'must be object',
                                                                                                                                            },
                                                                                                                                        ];
                                                                                                                                    return false;
                                                                                                                                }
                                                                                                                            }
                                                                                                                            var _valid29 = _errs1012 ===
                                                                                                                                errors;
                                                                                                                            valid169 =
                                                                                                                                _valid29;
                                                                                                                        }
                                                                                                                        if (!valid169) {
                                                                                                                            var err89 = {
                                                                                                                                instancePath: instancePath,
                                                                                                                                schemaPath: '#/allOf/29/if',
                                                                                                                                keyword: 'if',
                                                                                                                                params: {
                                                                                                                                    failingKeyword: 'then',
                                                                                                                                },
                                                                                                                                message: 'must match "then" schema',
                                                                                                                            };
                                                                                                                            if (vErrors === null) {
                                                                                                                                vErrors = [
                                                                                                                                    err89,
                                                                                                                                ];
                                                                                                                            }
                                                                                                                            else {
                                                                                                                                vErrors.push(err89);
                                                                                                                            }
                                                                                                                            errors++;
                                                                                                                            validate11.errors =
                                                                                                                                vErrors;
                                                                                                                            return false;
                                                                                                                        }
                                                                                                                        var valid0 = _errs1007 ===
                                                                                                                            errors;
                                                                                                                        if (valid0) {
                                                                                                                            var _errs1061 = errors;
                                                                                                                            var _errs1062 = errors;
                                                                                                                            var valid178 = true;
                                                                                                                            var _errs1063 = errors;
                                                                                                                            if (data &&
                                                                                                                                typeof data ==
                                                                                                                                    'object' &&
                                                                                                                                !Array.isArray(data)) {
                                                                                                                                if (data.name !==
                                                                                                                                    undefined) {
                                                                                                                                    var data397 = data.name;
                                                                                                                                    if (typeof data397 !==
                                                                                                                                        'string') {
                                                                                                                                        var err90 = {};
                                                                                                                                        if (vErrors ===
                                                                                                                                            null) {
                                                                                                                                            vErrors =
                                                                                                                                                [err90];
                                                                                                                                        }
                                                                                                                                        else {
                                                                                                                                            vErrors.push(err90);
                                                                                                                                        }
                                                                                                                                        errors++;
                                                                                                                                    }
                                                                                                                                    if ('audioPlaybackChanged' !==
                                                                                                                                        data397) {
                                                                                                                                        var err91 = {};
                                                                                                                                        if (vErrors ===
                                                                                                                                            null) {
                                                                                                                                            vErrors =
                                                                                                                                                [err91];
                                                                                                                                        }
                                                                                                                                        else {
                                                                                                                                            vErrors.push(err91);
                                                                                                                                        }
                                                                                                                                        errors++;
                                                                                                                                    }
                                                                                                                                }
                                                                                                                            }
                                                                                                                            var _valid30 = _errs1063 ===
                                                                                                                                errors;
                                                                                                                            errors =
                                                                                                                                _errs1062;
                                                                                                                            if (vErrors !== null) {
                                                                                                                                if (_errs1062) {
                                                                                                                                    vErrors.length =
                                                                                                                                        _errs1062;
                                                                                                                                }
                                                                                                                                else {
                                                                                                                                    vErrors =
                                                                                                                                        null;
                                                                                                                                }
                                                                                                                            }
                                                                                                                            if (_valid30) {
                                                                                                                                var _errs1066 = errors;
                                                                                                                                if (errors ===
                                                                                                                                    _errs1066) {
                                                                                                                                    if (data &&
                                                                                                                                        typeof data ==
                                                                                                                                            'object' &&
                                                                                                                                        !Array.isArray(data)) {
                                                                                                                                        var missing96 = void 0;
                                                                                                                                        if ((data.name ===
                                                                                                                                            undefined &&
                                                                                                                                            (missing96 =
                                                                                                                                                'name')) ||
                                                                                                                                            (data.playing ===
                                                                                                                                                undefined &&
                                                                                                                                                (missing96 =
                                                                                                                                                    'playing')) ||
                                                                                                                                            (data.timestamp ===
                                                                                                                                                undefined &&
                                                                                                                                                (missing96 =
                                                                                                                                                    'timestamp'))) {
                                                                                                                                            validate11.errors =
                                                                                                                                                [
                                                                                                                                                    {
                                                                                                                                                        instancePath: instancePath,
                                                                                                                                                        schemaPath: '#/allOf/30/then/required',
                                                                                                                                                        keyword: 'required',
                                                                                                                                                        params: {
                                                                                                                                                            missingProperty: missing96,
                                                                                                                                                        },
                                                                                                                                                        message: "must have required property '" +
                                                                                                                                                            missing96 +
                                                                                                                                                            "'",
                                                                                                                                                    },
                                                                                                                                                ];
                                                                                                                                            return false;
                                                                                                                                        }
                                                                                                                                        else {
                                                                                                                                            var _errs1068 = errors;
                                                                                                                                            for (var key97 in data) {
                                                                                                                                                if (!(key97 ===
                                                                                                                                                    'playing' ||
                                                                                                                                                    key97 ===
                                                                                                                                                        'name' ||
                                                                                                                                                    key97 ===
                                                                                                                                                        'timestamp')) {
                                                                                                                                                    validate11.errors =
                                                                                                                                                        [
                                                                                                                                                            {
                                                                                                                                                                instancePath: instancePath,
                                                                                                                                                                schemaPath: '#/allOf/30/then/additionalProperties',
                                                                                                                                                                keyword: 'additionalProperties',
                                                                                                                                                                params: {
                                                                                                                                                                    additionalProperty: key97,
                                                                                                                                                                },
                                                                                                                                                                message: 'must NOT have additional properties',
                                                                                                                                                            },
                                                                                                                                                        ];
                                                                                                                                                    return false;
                                                                                                                                                    break;
                                                                                                                                                }
                                                                                                                                            }
                                                                                                                                            if (_errs1068 ===
                                                                                                                                                errors) {
                                                                                                                                                if (data.playing !==
                                                                                                                                                    undefined) {
                                                                                                                                                    var _errs1069 = errors;
                                                                                                                                                    if (typeof data.playing !==
                                                                                                                                                        'boolean') {
                                                                                                                                                        validate11.errors =
                                                                                                                                                            [
                                                                                                                                                                {
                                                                                                                                                                    instancePath: instancePath +
                                                                                                                                                                        '/playing',
                                                                                                                                                                    schemaPath: '#/allOf/30/then/properties/playing/type',
                                                                                                                                                                    keyword: 'type',
                                                                                                                                                                    params: {
                                                                                                                                                                        type: 'boolean',
                                                                                                                                                                    },
                                                                                                                                                                    message: 'must be boolean',
                                                                                                                                                                },
                                                                                                                                                            ];
                                                                                                                                                        return false;
                                                                                                                                                    }
                                                                                                                                                    var valid180 = _errs1069 ===
                                                                                                                                                        errors;
                                                                                                                                                }
                                                                                                                                                else {
                                                                                                                                                    var valid180 = true;
                                                                                                                                                }
                                                                                                                                                if (valid180) {
                                                                                                                                                    if (data.name !==
                                                                                                                                                        undefined) {
                                                                                                                                                        var data399 = data.name;
                                                                                                                                                        var _errs1071 = errors;
                                                                                                                                                        if (typeof data399 !==
                                                                                                                                                            'string') {
                                                                                                                                                            validate11.errors =
                                                                                                                                                                [
                                                                                                                                                                    {
                                                                                                                                                                        instancePath: instancePath +
                                                                                                                                                                            '/name',
                                                                                                                                                                        schemaPath: '#/allOf/30/then/properties/name/type',
                                                                                                                                                                        keyword: 'type',
                                                                                                                                                                        params: {
                                                                                                                                                                            type: 'string',
                                                                                                                                                                        },
                                                                                                                                                                        message: 'must be string',
                                                                                                                                                                    },
                                                                                                                                                                ];
                                                                                                                                                            return false;
                                                                                                                                                        }
                                                                                                                                                        if ('audioPlaybackChanged' !==
                                                                                                                                                            data399) {
                                                                                                                                                            validate11.errors =
                                                                                                                                                                [
                                                                                                                                                                    {
                                                                                                                                                                        instancePath: instancePath +
                                                                                                                                                                            '/name',
                                                                                                                                                                        schemaPath: '#/allOf/30/then/properties/name/const',
                                                                                                                                                                        keyword: 'const',
                                                                                                                                                                        params: {
                                                                                                                                                                            allowedValue: 'audioPlaybackChanged',
                                                                                                                                                                        },
                                                                                                                                                                        message: 'must be equal to constant',
                                                                                                                                                                    },
                                                                                                                                                                ];
                                                                                                                                                            return false;
                                                                                                                                                        }
                                                                                                                                                        var valid180 = _errs1071 ===
                                                                                                                                                            errors;
                                                                                                                                                    }
                                                                                                                                                    else {
                                                                                                                                                        var valid180 = true;
                                                                                                                                                    }
                                                                                                                                                    if (valid180) {
                                                                                                                                                        if (data.timestamp !==
                                                                                                                                                            undefined) {
                                                                                                                                                            var data400 = data.timestamp;
                                                                                                                                                            var _errs1073 = errors;
                                                                                                                                                            if (!(typeof data400 ==
                                                                                                                                                                'number' &&
                                                                                                                                                                isFinite(data400))) {
                                                                                                                                                                validate11.errors =
                                                                                                                                                                    [
                                                                                                                                                                        {
                                                                                                                                                                            instancePath: instancePath +
                                                                                                                                                                                '/timestamp',
                                                                                                                                                                            schemaPath: '#/allOf/30/then/properties/timestamp/type',
                                                                                                                                                                            keyword: 'type',
                                                                                                                                                                            params: {
                                                                                                                                                                                type: 'number',
                                                                                                                                                                            },
                                                                                                                                                                            message: 'must be number',
                                                                                                                                                                        },
                                                                                                                                                                    ];
                                                                                                                                                                return false;
                                                                                                                                                            }
                                                                                                                                                            var valid180 = _errs1073 ===
                                                                                                                                                                errors;
                                                                                                                                                        }
                                                                                                                                                        else {
                                                                                                                                                            var valid180 = true;
                                                                                                                                                        }
                                                                                                                                                    }
                                                                                                                                                }
                                                                                                                                            }
                                                                                                                                        }
                                                                                                                                    }
                                                                                                                                    else {
                                                                                                                                        validate11.errors =
                                                                                                                                            [
                                                                                                                                                {
                                                                                                                                                    instancePath: instancePath,
                                                                                                                                                    schemaPath: '#/allOf/30/then/type',
                                                                                                                                                    keyword: 'type',
                                                                                                                                                    params: {
                                                                                                                                                        type: 'object',
                                                                                                                                                    },
                                                                                                                                                    message: 'must be object',
                                                                                                                                                },
                                                                                                                                            ];
                                                                                                                                        return false;
                                                                                                                                    }
                                                                                                                                }
                                                                                                                                var _valid30 = _errs1066 ===
                                                                                                                                    errors;
                                                                                                                                valid178 =
                                                                                                                                    _valid30;
                                                                                                                            }
                                                                                                                            if (!valid178) {
                                                                                                                                var err92 = {
                                                                                                                                    instancePath: instancePath,
                                                                                                                                    schemaPath: '#/allOf/30/if',
                                                                                                                                    keyword: 'if',
                                                                                                                                    params: {
                                                                                                                                        failingKeyword: 'then',
                                                                                                                                    },
                                                                                                                                    message: 'must match "then" schema',
                                                                                                                                };
                                                                                                                                if (vErrors ===
                                                                                                                                    null) {
                                                                                                                                    vErrors = [
                                                                                                                                        err92,
                                                                                                                                    ];
                                                                                                                                }
                                                                                                                                else {
                                                                                                                                    vErrors.push(err92);
                                                                                                                                }
                                                                                                                                errors++;
                                                                                                                                validate11.errors =
                                                                                                                                    vErrors;
                                                                                                                                return false;
                                                                                                                            }
                                                                                                                            var valid0 = _errs1061 ===
                                                                                                                                errors;
                                                                                                                            if (valid0) {
                                                                                                                                var _errs1075 = errors;
                                                                                                                                var _errs1076 = errors;
                                                                                                                                var valid181 = true;
                                                                                                                                var _errs1077 = errors;
                                                                                                                                if (data &&
                                                                                                                                    typeof data ==
                                                                                                                                        'object' &&
                                                                                                                                    !Array.isArray(data)) {
                                                                                                                                    if (data.name !==
                                                                                                                                        undefined) {
                                                                                                                                        var data401 = data.name;
                                                                                                                                        if (typeof data401 !==
                                                                                                                                            'string') {
                                                                                                                                            var err93 = {};
                                                                                                                                            if (vErrors ===
                                                                                                                                                null) {
                                                                                                                                                vErrors =
                                                                                                                                                    [
                                                                                                                                                        err93,
                                                                                                                                                    ];
                                                                                                                                            }
                                                                                                                                            else {
                                                                                                                                                vErrors.push(err93);
                                                                                                                                            }
                                                                                                                                            errors++;
                                                                                                                                        }
                                                                                                                                        if ('videoPlaybackChanged' !==
                                                                                                                                            data401) {
                                                                                                                                            var err94 = {};
                                                                                                                                            if (vErrors ===
                                                                                                                                                null) {
                                                                                                                                                vErrors =
                                                                                                                                                    [
                                                                                                                                                        err94,
                                                                                                                                                    ];
                                                                                                                                            }
                                                                                                                                            else {
                                                                                                                                                vErrors.push(err94);
                                                                                                                                            }
                                                                                                                                            errors++;
                                                                                                                                        }
                                                                                                                                    }
                                                                                                                                }
                                                                                                                                var _valid31 = _errs1077 ===
                                                                                                                                    errors;
                                                                                                                                errors =
                                                                                                                                    _errs1076;
                                                                                                                                if (vErrors !==
                                                                                                                                    null) {
                                                                                                                                    if (_errs1076) {
                                                                                                                                        vErrors.length =
                                                                                                                                            _errs1076;
                                                                                                                                    }
                                                                                                                                    else {
                                                                                                                                        vErrors =
                                                                                                                                            null;
                                                                                                                                    }
                                                                                                                                }
                                                                                                                                if (_valid31) {
                                                                                                                                    var _errs1080 = errors;
                                                                                                                                    if (errors ===
                                                                                                                                        _errs1080) {
                                                                                                                                        if (data &&
                                                                                                                                            typeof data ==
                                                                                                                                                'object' &&
                                                                                                                                            !Array.isArray(data)) {
                                                                                                                                            var missing97 = void 0;
                                                                                                                                            if ((data.name ===
                                                                                                                                                undefined &&
                                                                                                                                                (missing97 =
                                                                                                                                                    'name')) ||
                                                                                                                                                (data.playing ===
                                                                                                                                                    undefined &&
                                                                                                                                                    (missing97 =
                                                                                                                                                        'playing')) ||
                                                                                                                                                (data.timestamp ===
                                                                                                                                                    undefined &&
                                                                                                                                                    (missing97 =
                                                                                                                                                        'timestamp'))) {
                                                                                                                                                validate11.errors =
                                                                                                                                                    [
                                                                                                                                                        {
                                                                                                                                                            instancePath: instancePath,
                                                                                                                                                            schemaPath: '#/allOf/31/then/required',
                                                                                                                                                            keyword: 'required',
                                                                                                                                                            params: {
                                                                                                                                                                missingProperty: missing97,
                                                                                                                                                            },
                                                                                                                                                            message: "must have required property '" +
                                                                                                                                                                missing97 +
                                                                                                                                                                "'",
                                                                                                                                                        },
                                                                                                                                                    ];
                                                                                                                                                return false;
                                                                                                                                            }
                                                                                                                                            else {
                                                                                                                                                var _errs1082 = errors;
                                                                                                                                                for (var key98 in data) {
                                                                                                                                                    if (!(key98 ===
                                                                                                                                                        'playing' ||
                                                                                                                                                        key98 ===
                                                                                                                                                            'name' ||
                                                                                                                                                        key98 ===
                                                                                                                                                            'timestamp')) {
                                                                                                                                                        validate11.errors =
                                                                                                                                                            [
                                                                                                                                                                {
                                                                                                                                                                    instancePath: instancePath,
                                                                                                                                                                    schemaPath: '#/allOf/31/then/additionalProperties',
                                                                                                                                                                    keyword: 'additionalProperties',
                                                                                                                                                                    params: {
                                                                                                                                                                        additionalProperty: key98,
                                                                                                                                                                    },
                                                                                                                                                                    message: 'must NOT have additional properties',
                                                                                                                                                                },
                                                                                                                                                            ];
                                                                                                                                                        return false;
                                                                                                                                                        break;
                                                                                                                                                    }
                                                                                                                                                }
                                                                                                                                                if (_errs1082 ===
                                                                                                                                                    errors) {
                                                                                                                                                    if (data.playing !==
                                                                                                                                                        undefined) {
                                                                                                                                                        var _errs1083 = errors;
                                                                                                                                                        if (typeof data.playing !==
                                                                                                                                                            'boolean') {
                                                                                                                                                            validate11.errors =
                                                                                                                                                                [
                                                                                                                                                                    {
                                                                                                                                                                        instancePath: instancePath +
                                                                                                                                                                            '/playing',
                                                                                                                                                                        schemaPath: '#/allOf/31/then/properties/playing/type',
                                                                                                                                                                        keyword: 'type',
                                                                                                                                                                        params: {
                                                                                                                                                                            type: 'boolean',
                                                                                                                                                                        },
                                                                                                                                                                        message: 'must be boolean',
                                                                                                                                                                    },
                                                                                                                                                                ];
                                                                                                                                                            return false;
                                                                                                                                                        }
                                                                                                                                                        var valid183 = _errs1083 ===
                                                                                                                                                            errors;
                                                                                                                                                    }
                                                                                                                                                    else {
                                                                                                                                                        var valid183 = true;
                                                                                                                                                    }
                                                                                                                                                    if (valid183) {
                                                                                                                                                        if (data.name !==
                                                                                                                                                            undefined) {
                                                                                                                                                            var data403 = data.name;
                                                                                                                                                            var _errs1085 = errors;
                                                                                                                                                            if (typeof data403 !==
                                                                                                                                                                'string') {
                                                                                                                                                                validate11.errors =
                                                                                                                                                                    [
                                                                                                                                                                        {
                                                                                                                                                                            instancePath: instancePath +
                                                                                                                                                                                '/name',
                                                                                                                                                                            schemaPath: '#/allOf/31/then/properties/name/type',
                                                                                                                                                                            keyword: 'type',
                                                                                                                                                                            params: {
                                                                                                                                                                                type: 'string',
                                                                                                                                                                            },
                                                                                                                                                                            message: 'must be string',
                                                                                                                                                                        },
                                                                                                                                                                    ];
                                                                                                                                                                return false;
                                                                                                                                                            }
                                                                                                                                                            if ('videoPlaybackChanged' !==
                                                                                                                                                                data403) {
                                                                                                                                                                validate11.errors =
                                                                                                                                                                    [
                                                                                                                                                                        {
                                                                                                                                                                            instancePath: instancePath +
                                                                                                                                                                                '/name',
                                                                                                                                                                            schemaPath: '#/allOf/31/then/properties/name/const',
                                                                                                                                                                            keyword: 'const',
                                                                                                                                                                            params: {
                                                                                                                                                                                allowedValue: 'videoPlaybackChanged',
                                                                                                                                                                            },
                                                                                                                                                                            message: 'must be equal to constant',
                                                                                                                                                                        },
                                                                                                                                                                    ];
                                                                                                                                                                return false;
                                                                                                                                                            }
                                                                                                                                                            var valid183 = _errs1085 ===
                                                                                                                                                                errors;
                                                                                                                                                        }
                                                                                                                                                        else {
                                                                                                                                                            var valid183 = true;
                                                                                                                                                        }
                                                                                                                                                        if (valid183) {
                                                                                                                                                            if (data.timestamp !==
                                                                                                                                                                undefined) {
                                                                                                                                                                var data404 = data.timestamp;
                                                                                                                                                                var _errs1087 = errors;
                                                                                                                                                                if (!(typeof data404 ==
                                                                                                                                                                    'number' &&
                                                                                                                                                                    isFinite(data404))) {
                                                                                                                                                                    validate11.errors =
                                                                                                                                                                        [
                                                                                                                                                                            {
                                                                                                                                                                                instancePath: instancePath +
                                                                                                                                                                                    '/timestamp',
                                                                                                                                                                                schemaPath: '#/allOf/31/then/properties/timestamp/type',
                                                                                                                                                                                keyword: 'type',
                                                                                                                                                                                params: {
                                                                                                                                                                                    type: 'number',
                                                                                                                                                                                },
                                                                                                                                                                                message: 'must be number',
                                                                                                                                                                            },
                                                                                                                                                                        ];
                                                                                                                                                                    return false;
                                                                                                                                                                }
                                                                                                                                                                var valid183 = _errs1087 ===
                                                                                                                                                                    errors;
                                                                                                                                                            }
                                                                                                                                                            else {
                                                                                                                                                                var valid183 = true;
                                                                                                                                                            }
                                                                                                                                                        }
                                                                                                                                                    }
                                                                                                                                                }
                                                                                                                                            }
                                                                                                                                        }
                                                                                                                                        else {
                                                                                                                                            validate11.errors =
                                                                                                                                                [
                                                                                                                                                    {
                                                                                                                                                        instancePath: instancePath,
                                                                                                                                                        schemaPath: '#/allOf/31/then/type',
                                                                                                                                                        keyword: 'type',
                                                                                                                                                        params: {
                                                                                                                                                            type: 'object',
                                                                                                                                                        },
                                                                                                                                                        message: 'must be object',
                                                                                                                                                    },
                                                                                                                                                ];
                                                                                                                                            return false;
                                                                                                                                        }
                                                                                                                                    }
                                                                                                                                    var _valid31 = _errs1080 ===
                                                                                                                                        errors;
                                                                                                                                    valid181 =
                                                                                                                                        _valid31;
                                                                                                                                }
                                                                                                                                if (!valid181) {
                                                                                                                                    var err95 = {
                                                                                                                                        instancePath: instancePath,
                                                                                                                                        schemaPath: '#/allOf/31/if',
                                                                                                                                        keyword: 'if',
                                                                                                                                        params: {
                                                                                                                                            failingKeyword: 'then',
                                                                                                                                        },
                                                                                                                                        message: 'must match "then" schema',
                                                                                                                                    };
                                                                                                                                    if (vErrors ===
                                                                                                                                        null) {
                                                                                                                                        vErrors = [
                                                                                                                                            err95,
                                                                                                                                        ];
                                                                                                                                    }
                                                                                                                                    else {
                                                                                                                                        vErrors.push(err95);
                                                                                                                                    }
                                                                                                                                    errors++;
                                                                                                                                    validate11.errors =
                                                                                                                                        vErrors;
                                                                                                                                    return false;
                                                                                                                                }
                                                                                                                                var valid0 = _errs1075 ===
                                                                                                                                    errors;
                                                                                                                                if (valid0) {
                                                                                                                                    var _errs1089 = errors;
                                                                                                                                    var _errs1090 = errors;
                                                                                                                                    var valid184 = true;
                                                                                                                                    var _errs1091 = errors;
                                                                                                                                    if (data &&
                                                                                                                                        typeof data ==
                                                                                                                                            'object' &&
                                                                                                                                        !Array.isArray(data)) {
                                                                                                                                        if (data.name !==
                                                                                                                                            undefined) {
                                                                                                                                            var data405 = data.name;
                                                                                                                                            if (typeof data405 !==
                                                                                                                                                'string') {
                                                                                                                                                var err96 = {};
                                                                                                                                                if (vErrors ===
                                                                                                                                                    null) {
                                                                                                                                                    vErrors =
                                                                                                                                                        [
                                                                                                                                                            err96,
                                                                                                                                                        ];
                                                                                                                                                }
                                                                                                                                                else {
                                                                                                                                                    vErrors.push(err96);
                                                                                                                                                }
                                                                                                                                                errors++;
                                                                                                                                            }
                                                                                                                                            if ('signalConnected' !==
                                                                                                                                                data405) {
                                                                                                                                                var err97 = {};
                                                                                                                                                if (vErrors ===
                                                                                                                                                    null) {
                                                                                                                                                    vErrors =
                                                                                                                                                        [
                                                                                                                                                            err97,
                                                                                                                                                        ];
                                                                                                                                                }
                                                                                                                                                else {
                                                                                                                                                    vErrors.push(err97);
                                                                                                                                                }
                                                                                                                                                errors++;
                                                                                                                                            }
                                                                                                                                        }
                                                                                                                                    }
                                                                                                                                    var _valid32 = _errs1091 ===
                                                                                                                                        errors;
                                                                                                                                    errors =
                                                                                                                                        _errs1090;
                                                                                                                                    if (vErrors !==
                                                                                                                                        null) {
                                                                                                                                        if (_errs1090) {
                                                                                                                                            vErrors.length =
                                                                                                                                                _errs1090;
                                                                                                                                        }
                                                                                                                                        else {
                                                                                                                                            vErrors =
                                                                                                                                                null;
                                                                                                                                        }
                                                                                                                                    }
                                                                                                                                    if (_valid32) {
                                                                                                                                        var _errs1094 = errors;
                                                                                                                                        if (errors ===
                                                                                                                                            _errs1094) {
                                                                                                                                            if (data &&
                                                                                                                                                typeof data ==
                                                                                                                                                    'object' &&
                                                                                                                                                !Array.isArray(data)) {
                                                                                                                                                var missing98 = void 0;
                                                                                                                                                if ((data.name ===
                                                                                                                                                    undefined &&
                                                                                                                                                    (missing98 =
                                                                                                                                                        'name')) ||
                                                                                                                                                    (data.timestamp ===
                                                                                                                                                        undefined &&
                                                                                                                                                        (missing98 =
                                                                                                                                                            'timestamp'))) {
                                                                                                                                                    validate11.errors =
                                                                                                                                                        [
                                                                                                                                                            {
                                                                                                                                                                instancePath: instancePath,
                                                                                                                                                                schemaPath: '#/allOf/32/then/required',
                                                                                                                                                                keyword: 'required',
                                                                                                                                                                params: {
                                                                                                                                                                    missingProperty: missing98,
                                                                                                                                                                },
                                                                                                                                                                message: "must have required property '" +
                                                                                                                                                                    missing98 +
                                                                                                                                                                    "'",
                                                                                                                                                            },
                                                                                                                                                        ];
                                                                                                                                                    return false;
                                                                                                                                                }
                                                                                                                                                else {
                                                                                                                                                    var _errs1096 = errors;
                                                                                                                                                    for (var key99 in data) {
                                                                                                                                                        if (!(key99 ===
                                                                                                                                                            'name' ||
                                                                                                                                                            key99 ===
                                                                                                                                                                'timestamp')) {
                                                                                                                                                            validate11.errors =
                                                                                                                                                                [
                                                                                                                                                                    {
                                                                                                                                                                        instancePath: instancePath,
                                                                                                                                                                        schemaPath: '#/allOf/32/then/additionalProperties',
                                                                                                                                                                        keyword: 'additionalProperties',
                                                                                                                                                                        params: {
                                                                                                                                                                            additionalProperty: key99,
                                                                                                                                                                        },
                                                                                                                                                                        message: 'must NOT have additional properties',
                                                                                                                                                                    },
                                                                                                                                                                ];
                                                                                                                                                            return false;
                                                                                                                                                            break;
                                                                                                                                                        }
                                                                                                                                                    }
                                                                                                                                                    if (_errs1096 ===
                                                                                                                                                        errors) {
                                                                                                                                                        if (data.name !==
                                                                                                                                                            undefined) {
                                                                                                                                                            var data406 = data.name;
                                                                                                                                                            var _errs1097 = errors;
                                                                                                                                                            if (typeof data406 !==
                                                                                                                                                                'string') {
                                                                                                                                                                validate11.errors =
                                                                                                                                                                    [
                                                                                                                                                                        {
                                                                                                                                                                            instancePath: instancePath +
                                                                                                                                                                                '/name',
                                                                                                                                                                            schemaPath: '#/allOf/32/then/properties/name/type',
                                                                                                                                                                            keyword: 'type',
                                                                                                                                                                            params: {
                                                                                                                                                                                type: 'string',
                                                                                                                                                                            },
                                                                                                                                                                            message: 'must be string',
                                                                                                                                                                        },
                                                                                                                                                                    ];
                                                                                                                                                                return false;
                                                                                                                                                            }
                                                                                                                                                            if ('signalConnected' !==
                                                                                                                                                                data406) {
                                                                                                                                                                validate11.errors =
                                                                                                                                                                    [
                                                                                                                                                                        {
                                                                                                                                                                            instancePath: instancePath +
                                                                                                                                                                                '/name',
                                                                                                                                                                            schemaPath: '#/allOf/32/then/properties/name/const',
                                                                                                                                                                            keyword: 'const',
                                                                                                                                                                            params: {
                                                                                                                                                                                allowedValue: 'signalConnected',
                                                                                                                                                                            },
                                                                                                                                                                            message: 'must be equal to constant',
                                                                                                                                                                        },
                                                                                                                                                                    ];
                                                                                                                                                                return false;
                                                                                                                                                            }
                                                                                                                                                            var valid186 = _errs1097 ===
                                                                                                                                                                errors;
                                                                                                                                                        }
                                                                                                                                                        else {
                                                                                                                                                            var valid186 = true;
                                                                                                                                                        }
                                                                                                                                                        if (valid186) {
                                                                                                                                                            if (data.timestamp !==
                                                                                                                                                                undefined) {
                                                                                                                                                                var data407 = data.timestamp;
                                                                                                                                                                var _errs1099 = errors;
                                                                                                                                                                if (!(typeof data407 ==
                                                                                                                                                                    'number' &&
                                                                                                                                                                    isFinite(data407))) {
                                                                                                                                                                    validate11.errors =
                                                                                                                                                                        [
                                                                                                                                                                            {
                                                                                                                                                                                instancePath: instancePath +
                                                                                                                                                                                    '/timestamp',
                                                                                                                                                                                schemaPath: '#/allOf/32/then/properties/timestamp/type',
                                                                                                                                                                                keyword: 'type',
                                                                                                                                                                                params: {
                                                                                                                                                                                    type: 'number',
                                                                                                                                                                                },
                                                                                                                                                                                message: 'must be number',
                                                                                                                                                                            },
                                                                                                                                                                        ];
                                                                                                                                                                    return false;
                                                                                                                                                                }
                                                                                                                                                                var valid186 = _errs1099 ===
                                                                                                                                                                    errors;
                                                                                                                                                            }
                                                                                                                                                            else {
                                                                                                                                                                var valid186 = true;
                                                                                                                                                            }
                                                                                                                                                        }
                                                                                                                                                    }
                                                                                                                                                }
                                                                                                                                            }
                                                                                                                                            else {
                                                                                                                                                validate11.errors =
                                                                                                                                                    [
                                                                                                                                                        {
                                                                                                                                                            instancePath: instancePath,
                                                                                                                                                            schemaPath: '#/allOf/32/then/type',
                                                                                                                                                            keyword: 'type',
                                                                                                                                                            params: {
                                                                                                                                                                type: 'object',
                                                                                                                                                            },
                                                                                                                                                            message: 'must be object',
                                                                                                                                                        },
                                                                                                                                                    ];
                                                                                                                                                return false;
                                                                                                                                            }
                                                                                                                                        }
                                                                                                                                        var _valid32 = _errs1094 ===
                                                                                                                                            errors;
                                                                                                                                        valid184 =
                                                                                                                                            _valid32;
                                                                                                                                    }
                                                                                                                                    if (!valid184) {
                                                                                                                                        var err98 = {
                                                                                                                                            instancePath: instancePath,
                                                                                                                                            schemaPath: '#/allOf/32/if',
                                                                                                                                            keyword: 'if',
                                                                                                                                            params: {
                                                                                                                                                failingKeyword: 'then',
                                                                                                                                            },
                                                                                                                                            message: 'must match "then" schema',
                                                                                                                                        };
                                                                                                                                        if (vErrors ===
                                                                                                                                            null) {
                                                                                                                                            vErrors =
                                                                                                                                                [err98];
                                                                                                                                        }
                                                                                                                                        else {
                                                                                                                                            vErrors.push(err98);
                                                                                                                                        }
                                                                                                                                        errors++;
                                                                                                                                        validate11.errors =
                                                                                                                                            vErrors;
                                                                                                                                        return false;
                                                                                                                                    }
                                                                                                                                    var valid0 = _errs1089 ===
                                                                                                                                        errors;
                                                                                                                                    if (valid0) {
                                                                                                                                        var _errs1101 = errors;
                                                                                                                                        var _errs1102 = errors;
                                                                                                                                        var valid187 = true;
                                                                                                                                        var _errs1103 = errors;
                                                                                                                                        if (data &&
                                                                                                                                            typeof data ==
                                                                                                                                                'object' &&
                                                                                                                                            !Array.isArray(data)) {
                                                                                                                                            if (data.name !==
                                                                                                                                                undefined) {
                                                                                                                                                var data408 = data.name;
                                                                                                                                                if (typeof data408 !==
                                                                                                                                                    'string') {
                                                                                                                                                    var err99 = {};
                                                                                                                                                    if (vErrors ===
                                                                                                                                                        null) {
                                                                                                                                                        vErrors =
                                                                                                                                                            [
                                                                                                                                                                err99,
                                                                                                                                                            ];
                                                                                                                                                    }
                                                                                                                                                    else {
                                                                                                                                                        vErrors.push(err99);
                                                                                                                                                    }
                                                                                                                                                    errors++;
                                                                                                                                                }
                                                                                                                                                if ('recordingStatusChanged' !==
                                                                                                                                                    data408) {
                                                                                                                                                    var err100 = {};
                                                                                                                                                    if (vErrors ===
                                                                                                                                                        null) {
                                                                                                                                                        vErrors =
                                                                                                                                                            [
                                                                                                                                                                err100,
                                                                                                                                                            ];
                                                                                                                                                    }
                                                                                                                                                    else {
                                                                                                                                                        vErrors.push(err100);
                                                                                                                                                    }
                                                                                                                                                    errors++;
                                                                                                                                                }
                                                                                                                                            }
                                                                                                                                        }
                                                                                                                                        var _valid33 = _errs1103 ===
                                                                                                                                            errors;
                                                                                                                                        errors =
                                                                                                                                            _errs1102;
                                                                                                                                        if (vErrors !==
                                                                                                                                            null) {
                                                                                                                                            if (_errs1102) {
                                                                                                                                                vErrors.length =
                                                                                                                                                    _errs1102;
                                                                                                                                            }
                                                                                                                                            else {
                                                                                                                                                vErrors =
                                                                                                                                                    null;
                                                                                                                                            }
                                                                                                                                        }
                                                                                                                                        if (_valid33) {
                                                                                                                                            var _errs1106 = errors;
                                                                                                                                            if (errors ===
                                                                                                                                                _errs1106) {
                                                                                                                                                if (data &&
                                                                                                                                                    typeof data ==
                                                                                                                                                        'object' &&
                                                                                                                                                    !Array.isArray(data)) {
                                                                                                                                                    var missing99 = void 0;
                                                                                                                                                    if ((data.name ===
                                                                                                                                                        undefined &&
                                                                                                                                                        (missing99 =
                                                                                                                                                            'name')) ||
                                                                                                                                                        (data.recording ===
                                                                                                                                                            undefined &&
                                                                                                                                                            (missing99 =
                                                                                                                                                                'recording')) ||
                                                                                                                                                        (data.timestamp ===
                                                                                                                                                            undefined &&
                                                                                                                                                            (missing99 =
                                                                                                                                                                'timestamp'))) {
                                                                                                                                                        validate11.errors =
                                                                                                                                                            [
                                                                                                                                                                {
                                                                                                                                                                    instancePath: instancePath,
                                                                                                                                                                    schemaPath: '#/allOf/33/then/required',
                                                                                                                                                                    keyword: 'required',
                                                                                                                                                                    params: {
                                                                                                                                                                        missingProperty: missing99,
                                                                                                                                                                    },
                                                                                                                                                                    message: "must have required property '" +
                                                                                                                                                                        missing99 +
                                                                                                                                                                        "'",
                                                                                                                                                                },
                                                                                                                                                            ];
                                                                                                                                                        return false;
                                                                                                                                                    }
                                                                                                                                                    else {
                                                                                                                                                        var _errs1108 = errors;
                                                                                                                                                        for (var key100 in data) {
                                                                                                                                                            if (!(key100 ===
                                                                                                                                                                'recording' ||
                                                                                                                                                                key100 ===
                                                                                                                                                                    'name' ||
                                                                                                                                                                key100 ===
                                                                                                                                                                    'timestamp')) {
                                                                                                                                                                validate11.errors =
                                                                                                                                                                    [
                                                                                                                                                                        {
                                                                                                                                                                            instancePath: instancePath,
                                                                                                                                                                            schemaPath: '#/allOf/33/then/additionalProperties',
                                                                                                                                                                            keyword: 'additionalProperties',
                                                                                                                                                                            params: {
                                                                                                                                                                                additionalProperty: key100,
                                                                                                                                                                            },
                                                                                                                                                                            message: 'must NOT have additional properties',
                                                                                                                                                                        },
                                                                                                                                                                    ];
                                                                                                                                                                return false;
                                                                                                                                                                break;
                                                                                                                                                            }
                                                                                                                                                        }
                                                                                                                                                        if (_errs1108 ===
                                                                                                                                                            errors) {
                                                                                                                                                            if (data.recording !==
                                                                                                                                                                undefined) {
                                                                                                                                                                var _errs1109 = errors;
                                                                                                                                                                if (typeof data.recording !==
                                                                                                                                                                    'boolean') {
                                                                                                                                                                    validate11.errors =
                                                                                                                                                                        [
                                                                                                                                                                            {
                                                                                                                                                                                instancePath: instancePath +
                                                                                                                                                                                    '/recording',
                                                                                                                                                                                schemaPath: '#/allOf/33/then/properties/recording/type',
                                                                                                                                                                                keyword: 'type',
                                                                                                                                                                                params: {
                                                                                                                                                                                    type: 'boolean',
                                                                                                                                                                                },
                                                                                                                                                                                message: 'must be boolean',
                                                                                                                                                                            },
                                                                                                                                                                        ];
                                                                                                                                                                    return false;
                                                                                                                                                                }
                                                                                                                                                                var valid189 = _errs1109 ===
                                                                                                                                                                    errors;
                                                                                                                                                            }
                                                                                                                                                            else {
                                                                                                                                                                var valid189 = true;
                                                                                                                                                            }
                                                                                                                                                            if (valid189) {
                                                                                                                                                                if (data.name !==
                                                                                                                                                                    undefined) {
                                                                                                                                                                    var data410 = data.name;
                                                                                                                                                                    var _errs1111 = errors;
                                                                                                                                                                    if (typeof data410 !==
                                                                                                                                                                        'string') {
                                                                                                                                                                        validate11.errors =
                                                                                                                                                                            [
                                                                                                                                                                                {
                                                                                                                                                                                    instancePath: instancePath +
                                                                                                                                                                                        '/name',
                                                                                                                                                                                    schemaPath: '#/allOf/33/then/properties/name/type',
                                                                                                                                                                                    keyword: 'type',
                                                                                                                                                                                    params: {
                                                                                                                                                                                        type: 'string',
                                                                                                                                                                                    },
                                                                                                                                                                                    message: 'must be string',
                                                                                                                                                                                },
                                                                                                                                                                            ];
                                                                                                                                                                        return false;
                                                                                                                                                                    }
                                                                                                                                                                    if ('recordingStatusChanged' !==
                                                                                                                                                                        data410) {
                                                                                                                                                                        validate11.errors =
                                                                                                                                                                            [
                                                                                                                                                                                {
                                                                                                                                                                                    instancePath: instancePath +
                                                                                                                                                                                        '/name',
                                                                                                                                                                                    schemaPath: '#/allOf/33/then/properties/name/const',
                                                                                                                                                                                    keyword: 'const',
                                                                                                                                                                                    params: {
                                                                                                                                                                                        allowedValue: 'recordingStatusChanged',
                                                                                                                                                                                    },
                                                                                                                                                                                    message: 'must be equal to constant',
                                                                                                                                                                                },
                                                                                                                                                                            ];
                                                                                                                                                                        return false;
                                                                                                                                                                    }
                                                                                                                                                                    var valid189 = _errs1111 ===
                                                                                                                                                                        errors;
                                                                                                                                                                }
                                                                                                                                                                else {
                                                                                                                                                                    var valid189 = true;
                                                                                                                                                                }
                                                                                                                                                                if (valid189) {
                                                                                                                                                                    if (data.timestamp !==
                                                                                                                                                                        undefined) {
                                                                                                                                                                        var data411 = data.timestamp;
                                                                                                                                                                        var _errs1113 = errors;
                                                                                                                                                                        if (!(typeof data411 ==
                                                                                                                                                                            'number' &&
                                                                                                                                                                            isFinite(data411))) {
                                                                                                                                                                            validate11.errors =
                                                                                                                                                                                [
                                                                                                                                                                                    {
                                                                                                                                                                                        instancePath: instancePath +
                                                                                                                                                                                            '/timestamp',
                                                                                                                                                                                        schemaPath: '#/allOf/33/then/properties/timestamp/type',
                                                                                                                                                                                        keyword: 'type',
                                                                                                                                                                                        params: {
                                                                                                                                                                                            type: 'number',
                                                                                                                                                                                        },
                                                                                                                                                                                        message: 'must be number',
                                                                                                                                                                                    },
                                                                                                                                                                                ];
                                                                                                                                                                            return false;
                                                                                                                                                                        }
                                                                                                                                                                        var valid189 = _errs1113 ===
                                                                                                                                                                            errors;
                                                                                                                                                                    }
                                                                                                                                                                    else {
                                                                                                                                                                        var valid189 = true;
                                                                                                                                                                    }
                                                                                                                                                                }
                                                                                                                                                            }
                                                                                                                                                        }
                                                                                                                                                    }
                                                                                                                                                }
                                                                                                                                                else {
                                                                                                                                                    validate11.errors =
                                                                                                                                                        [
                                                                                                                                                            {
                                                                                                                                                                instancePath: instancePath,
                                                                                                                                                                schemaPath: '#/allOf/33/then/type',
                                                                                                                                                                keyword: 'type',
                                                                                                                                                                params: {
                                                                                                                                                                    type: 'object',
                                                                                                                                                                },
                                                                                                                                                                message: 'must be object',
                                                                                                                                                            },
                                                                                                                                                        ];
                                                                                                                                                    return false;
                                                                                                                                                }
                                                                                                                                            }
                                                                                                                                            var _valid33 = _errs1106 ===
                                                                                                                                                errors;
                                                                                                                                            valid187 =
                                                                                                                                                _valid33;
                                                                                                                                        }
                                                                                                                                        if (!valid187) {
                                                                                                                                            var err101 = {
                                                                                                                                                instancePath: instancePath,
                                                                                                                                                schemaPath: '#/allOf/33/if',
                                                                                                                                                keyword: 'if',
                                                                                                                                                params: {
                                                                                                                                                    failingKeyword: 'then',
                                                                                                                                                },
                                                                                                                                                message: 'must match "then" schema',
                                                                                                                                            };
                                                                                                                                            if (vErrors ===
                                                                                                                                                null) {
                                                                                                                                                vErrors =
                                                                                                                                                    [
                                                                                                                                                        err101,
                                                                                                                                                    ];
                                                                                                                                            }
                                                                                                                                            else {
                                                                                                                                                vErrors.push(err101);
                                                                                                                                            }
                                                                                                                                            errors++;
                                                                                                                                            validate11.errors =
                                                                                                                                                vErrors;
                                                                                                                                            return false;
                                                                                                                                        }
                                                                                                                                        var valid0 = _errs1101 ===
                                                                                                                                            errors;
                                                                                                                                        if (valid0) {
                                                                                                                                            var _errs1115 = errors;
                                                                                                                                            var _errs1116 = errors;
                                                                                                                                            var valid190 = true;
                                                                                                                                            var _errs1117 = errors;
                                                                                                                                            if (data &&
                                                                                                                                                typeof data ==
                                                                                                                                                    'object' &&
                                                                                                                                                !Array.isArray(data)) {
                                                                                                                                                if (data.name !==
                                                                                                                                                    undefined) {
                                                                                                                                                    var data412 = data.name;
                                                                                                                                                    if (typeof data412 !==
                                                                                                                                                        'string') {
                                                                                                                                                        var err102 = {};
                                                                                                                                                        if (vErrors ===
                                                                                                                                                            null) {
                                                                                                                                                            vErrors =
                                                                                                                                                                [
                                                                                                                                                                    err102,
                                                                                                                                                                ];
                                                                                                                                                        }
                                                                                                                                                        else {
                                                                                                                                                            vErrors.push(err102);
                                                                                                                                                        }
                                                                                                                                                        errors++;
                                                                                                                                                    }
                                                                                                                                                    if ('activeDeviceChanged' !==
                                                                                                                                                        data412) {
                                                                                                                                                        var err103 = {};
                                                                                                                                                        if (vErrors ===
                                                                                                                                                            null) {
                                                                                                                                                            vErrors =
                                                                                                                                                                [
                                                                                                                                                                    err103,
                                                                                                                                                                ];
                                                                                                                                                        }
                                                                                                                                                        else {
                                                                                                                                                            vErrors.push(err103);
                                                                                                                                                        }
                                                                                                                                                        errors++;
                                                                                                                                                    }
                                                                                                                                                }
                                                                                                                                            }
                                                                                                                                            var _valid34 = _errs1117 ===
                                                                                                                                                errors;
                                                                                                                                            errors =
                                                                                                                                                _errs1116;
                                                                                                                                            if (vErrors !==
                                                                                                                                                null) {
                                                                                                                                                if (_errs1116) {
                                                                                                                                                    vErrors.length =
                                                                                                                                                        _errs1116;
                                                                                                                                                }
                                                                                                                                                else {
                                                                                                                                                    vErrors =
                                                                                                                                                        null;
                                                                                                                                                }
                                                                                                                                            }
                                                                                                                                            if (_valid34) {
                                                                                                                                                var _errs1120 = errors;
                                                                                                                                                if (errors ===
                                                                                                                                                    _errs1120) {
                                                                                                                                                    if (data &&
                                                                                                                                                        typeof data ==
                                                                                                                                                            'object' &&
                                                                                                                                                        !Array.isArray(data)) {
                                                                                                                                                        var missing100 = void 0;
                                                                                                                                                        if ((data.deviceId ===
                                                                                                                                                            undefined &&
                                                                                                                                                            (missing100 =
                                                                                                                                                                'deviceId')) ||
                                                                                                                                                            (data.kind ===
                                                                                                                                                                undefined &&
                                                                                                                                                                (missing100 =
                                                                                                                                                                    'kind')) ||
                                                                                                                                                            (data.name ===
                                                                                                                                                                undefined &&
                                                                                                                                                                (missing100 =
                                                                                                                                                                    'name')) ||
                                                                                                                                                            (data.timestamp ===
                                                                                                                                                                undefined &&
                                                                                                                                                                (missing100 =
                                                                                                                                                                    'timestamp'))) {
                                                                                                                                                            validate11.errors =
                                                                                                                                                                [
                                                                                                                                                                    {
                                                                                                                                                                        instancePath: instancePath,
                                                                                                                                                                        schemaPath: '#/allOf/34/then/required',
                                                                                                                                                                        keyword: 'required',
                                                                                                                                                                        params: {
                                                                                                                                                                            missingProperty: missing100,
                                                                                                                                                                        },
                                                                                                                                                                        message: "must have required property '" +
                                                                                                                                                                            missing100 +
                                                                                                                                                                            "'",
                                                                                                                                                                    },
                                                                                                                                                                ];
                                                                                                                                                            return false;
                                                                                                                                                        }
                                                                                                                                                        else {
                                                                                                                                                            var _errs1122 = errors;
                                                                                                                                                            for (var key101 in data) {
                                                                                                                                                                if (!(key101 ===
                                                                                                                                                                    'kind' ||
                                                                                                                                                                    key101 ===
                                                                                                                                                                        'deviceId' ||
                                                                                                                                                                    key101 ===
                                                                                                                                                                        'name' ||
                                                                                                                                                                    key101 ===
                                                                                                                                                                        'timestamp')) {
                                                                                                                                                                    validate11.errors =
                                                                                                                                                                        [
                                                                                                                                                                            {
                                                                                                                                                                                instancePath: instancePath,
                                                                                                                                                                                schemaPath: '#/allOf/34/then/additionalProperties',
                                                                                                                                                                                keyword: 'additionalProperties',
                                                                                                                                                                                params: {
                                                                                                                                                                                    additionalProperty: key101,
                                                                                                                                                                                },
                                                                                                                                                                                message: 'must NOT have additional properties',
                                                                                                                                                                            },
                                                                                                                                                                        ];
                                                                                                                                                                    return false;
                                                                                                                                                                    break;
                                                                                                                                                                }
                                                                                                                                                            }
                                                                                                                                                            if (_errs1122 ===
                                                                                                                                                                errors) {
                                                                                                                                                                if (data.kind !==
                                                                                                                                                                    undefined) {
                                                                                                                                                                    var data413 = data.kind;
                                                                                                                                                                    var _errs1123 = errors;
                                                                                                                                                                    if (typeof data413 !==
                                                                                                                                                                        'string') {
                                                                                                                                                                        validate11.errors =
                                                                                                                                                                            [
                                                                                                                                                                                {
                                                                                                                                                                                    instancePath: instancePath +
                                                                                                                                                                                        '/kind',
                                                                                                                                                                                    schemaPath: '#/allOf/34/then/properties/kind/type',
                                                                                                                                                                                    keyword: 'type',
                                                                                                                                                                                    params: {
                                                                                                                                                                                        type: 'string',
                                                                                                                                                                                    },
                                                                                                                                                                                    message: 'must be string',
                                                                                                                                                                                },
                                                                                                                                                                            ];
                                                                                                                                                                        return false;
                                                                                                                                                                    }
                                                                                                                                                                    if (!(data413 ===
                                                                                                                                                                        'audioinput' ||
                                                                                                                                                                        data413 ===
                                                                                                                                                                            'audiooutput' ||
                                                                                                                                                                        data413 ===
                                                                                                                                                                            'videoinput')) {
                                                                                                                                                                        validate11.errors =
                                                                                                                                                                            [
                                                                                                                                                                                {
                                                                                                                                                                                    instancePath: instancePath +
                                                                                                                                                                                        '/kind',
                                                                                                                                                                                    schemaPath: '#/allOf/34/then/properties/kind/enum',
                                                                                                                                                                                    keyword: 'enum',
                                                                                                                                                                                    params: {
                                                                                                                                                                                        allowedValues: schema28
                                                                                                                                                                                            .allOf[34]
                                                                                                                                                                                            .then
                                                                                                                                                                                            .properties
                                                                                                                                                                                            .kind
                                                                                                                                                                                            .enum,
                                                                                                                                                                                    },
                                                                                                                                                                                    message: 'must be equal to one of the allowed values',
                                                                                                                                                                                },
                                                                                                                                                                            ];
                                                                                                                                                                        return false;
                                                                                                                                                                    }
                                                                                                                                                                    var valid192 = _errs1123 ===
                                                                                                                                                                        errors;
                                                                                                                                                                }
                                                                                                                                                                else {
                                                                                                                                                                    var valid192 = true;
                                                                                                                                                                }
                                                                                                                                                                if (valid192) {
                                                                                                                                                                    if (data.deviceId !==
                                                                                                                                                                        undefined) {
                                                                                                                                                                        var _errs1125 = errors;
                                                                                                                                                                        if (typeof data.deviceId !==
                                                                                                                                                                            'string') {
                                                                                                                                                                            validate11.errors =
                                                                                                                                                                                [
                                                                                                                                                                                    {
                                                                                                                                                                                        instancePath: instancePath +
                                                                                                                                                                                            '/deviceId',
                                                                                                                                                                                        schemaPath: '#/allOf/34/then/properties/deviceId/type',
                                                                                                                                                                                        keyword: 'type',
                                                                                                                                                                                        params: {
                                                                                                                                                                                            type: 'string',
                                                                                                                                                                                        },
                                                                                                                                                                                        message: 'must be string',
                                                                                                                                                                                    },
                                                                                                                                                                                ];
                                                                                                                                                                            return false;
                                                                                                                                                                        }
                                                                                                                                                                        var valid192 = _errs1125 ===
                                                                                                                                                                            errors;
                                                                                                                                                                    }
                                                                                                                                                                    else {
                                                                                                                                                                        var valid192 = true;
                                                                                                                                                                    }
                                                                                                                                                                    if (valid192) {
                                                                                                                                                                        if (data.name !==
                                                                                                                                                                            undefined) {
                                                                                                                                                                            var data415 = data.name;
                                                                                                                                                                            var _errs1127 = errors;
                                                                                                                                                                            if (typeof data415 !==
                                                                                                                                                                                'string') {
                                                                                                                                                                                validate11.errors =
                                                                                                                                                                                    [
                                                                                                                                                                                        {
                                                                                                                                                                                            instancePath: instancePath +
                                                                                                                                                                                                '/name',
                                                                                                                                                                                            schemaPath: '#/allOf/34/then/properties/name/type',
                                                                                                                                                                                            keyword: 'type',
                                                                                                                                                                                            params: {
                                                                                                                                                                                                type: 'string',
                                                                                                                                                                                            },
                                                                                                                                                                                            message: 'must be string',
                                                                                                                                                                                        },
                                                                                                                                                                                    ];
                                                                                                                                                                                return false;
                                                                                                                                                                            }
                                                                                                                                                                            if ('activeDeviceChanged' !==
                                                                                                                                                                                data415) {
                                                                                                                                                                                validate11.errors =
                                                                                                                                                                                    [
                                                                                                                                                                                        {
                                                                                                                                                                                            instancePath: instancePath +
                                                                                                                                                                                                '/name',
                                                                                                                                                                                            schemaPath: '#/allOf/34/then/properties/name/const',
                                                                                                                                                                                            keyword: 'const',
                                                                                                                                                                                            params: {
                                                                                                                                                                                                allowedValue: 'activeDeviceChanged',
                                                                                                                                                                                            },
                                                                                                                                                                                            message: 'must be equal to constant',
                                                                                                                                                                                        },
                                                                                                                                                                                    ];
                                                                                                                                                                                return false;
                                                                                                                                                                            }
                                                                                                                                                                            var valid192 = _errs1127 ===
                                                                                                                                                                                errors;
                                                                                                                                                                        }
                                                                                                                                                                        else {
                                                                                                                                                                            var valid192 = true;
                                                                                                                                                                        }
                                                                                                                                                                        if (valid192) {
                                                                                                                                                                            if (data.timestamp !==
                                                                                                                                                                                undefined) {
                                                                                                                                                                                var data416 = data.timestamp;
                                                                                                                                                                                var _errs1129 = errors;
                                                                                                                                                                                if (!(typeof data416 ==
                                                                                                                                                                                    'number' &&
                                                                                                                                                                                    isFinite(data416))) {
                                                                                                                                                                                    validate11.errors =
                                                                                                                                                                                        [
                                                                                                                                                                                            {
                                                                                                                                                                                                instancePath: instancePath +
                                                                                                                                                                                                    '/timestamp',
                                                                                                                                                                                                schemaPath: '#/allOf/34/then/properties/timestamp/type',
                                                                                                                                                                                                keyword: 'type',
                                                                                                                                                                                                params: {
                                                                                                                                                                                                    type: 'number',
                                                                                                                                                                                                },
                                                                                                                                                                                                message: 'must be number',
                                                                                                                                                                                            },
                                                                                                                                                                                        ];
                                                                                                                                                                                    return false;
                                                                                                                                                                                }
                                                                                                                                                                                var valid192 = _errs1129 ===
                                                                                                                                                                                    errors;
                                                                                                                                                                            }
                                                                                                                                                                            else {
                                                                                                                                                                                var valid192 = true;
                                                                                                                                                                            }
                                                                                                                                                                        }
                                                                                                                                                                    }
                                                                                                                                                                }
                                                                                                                                                            }
                                                                                                                                                        }
                                                                                                                                                    }
                                                                                                                                                    else {
                                                                                                                                                        validate11.errors =
                                                                                                                                                            [
                                                                                                                                                                {
                                                                                                                                                                    instancePath: instancePath,
                                                                                                                                                                    schemaPath: '#/allOf/34/then/type',
                                                                                                                                                                    keyword: 'type',
                                                                                                                                                                    params: {
                                                                                                                                                                        type: 'object',
                                                                                                                                                                    },
                                                                                                                                                                    message: 'must be object',
                                                                                                                                                                },
                                                                                                                                                            ];
                                                                                                                                                        return false;
                                                                                                                                                    }
                                                                                                                                                }
                                                                                                                                                var _valid34 = _errs1120 ===
                                                                                                                                                    errors;
                                                                                                                                                valid190 =
                                                                                                                                                    _valid34;
                                                                                                                                            }
                                                                                                                                            if (!valid190) {
                                                                                                                                                var err104 = {
                                                                                                                                                    instancePath: instancePath,
                                                                                                                                                    schemaPath: '#/allOf/34/if',
                                                                                                                                                    keyword: 'if',
                                                                                                                                                    params: {
                                                                                                                                                        failingKeyword: 'then',
                                                                                                                                                    },
                                                                                                                                                    message: 'must match "then" schema',
                                                                                                                                                };
                                                                                                                                                if (vErrors ===
                                                                                                                                                    null) {
                                                                                                                                                    vErrors =
                                                                                                                                                        [
                                                                                                                                                            err104,
                                                                                                                                                        ];
                                                                                                                                                }
                                                                                                                                                else {
                                                                                                                                                    vErrors.push(err104);
                                                                                                                                                }
                                                                                                                                                errors++;
                                                                                                                                                validate11.errors =
                                                                                                                                                    vErrors;
                                                                                                                                                return false;
                                                                                                                                            }
                                                                                                                                            var valid0 = _errs1115 ===
                                                                                                                                                errors;
                                                                                                                                            if (valid0) {
                                                                                                                                                var _errs1131 = errors;
                                                                                                                                                var _errs1132 = errors;
                                                                                                                                                var valid193 = true;
                                                                                                                                                var _errs1133 = errors;
                                                                                                                                                if (data &&
                                                                                                                                                    typeof data ==
                                                                                                                                                        'object' &&
                                                                                                                                                    !Array.isArray(data)) {
                                                                                                                                                    if (data.name !==
                                                                                                                                                        undefined) {
                                                                                                                                                        var data417 = data.name;
                                                                                                                                                        if (typeof data417 !==
                                                                                                                                                            'string') {
                                                                                                                                                            var err105 = {};
                                                                                                                                                            if (vErrors ===
                                                                                                                                                                null) {
                                                                                                                                                                vErrors =
                                                                                                                                                                    [
                                                                                                                                                                        err105,
                                                                                                                                                                    ];
                                                                                                                                                            }
                                                                                                                                                            else {
                                                                                                                                                                vErrors.push(err105);
                                                                                                                                                            }
                                                                                                                                                            errors++;
                                                                                                                                                        }
                                                                                                                                                        if ('localTrackSubscribed' !==
                                                                                                                                                            data417) {
                                                                                                                                                            var err106 = {};
                                                                                                                                                            if (vErrors ===
                                                                                                                                                                null) {
                                                                                                                                                                vErrors =
                                                                                                                                                                    [
                                                                                                                                                                        err106,
                                                                                                                                                                    ];
                                                                                                                                                            }
                                                                                                                                                            else {
                                                                                                                                                                vErrors.push(err106);
                                                                                                                                                            }
                                                                                                                                                            errors++;
                                                                                                                                                        }
                                                                                                                                                    }
                                                                                                                                                }
                                                                                                                                                var _valid35 = _errs1133 ===
                                                                                                                                                    errors;
                                                                                                                                                errors =
                                                                                                                                                    _errs1132;
                                                                                                                                                if (vErrors !==
                                                                                                                                                    null) {
                                                                                                                                                    if (_errs1132) {
                                                                                                                                                        vErrors.length =
                                                                                                                                                            _errs1132;
                                                                                                                                                    }
                                                                                                                                                    else {
                                                                                                                                                        vErrors =
                                                                                                                                                            null;
                                                                                                                                                    }
                                                                                                                                                }
                                                                                                                                                if (_valid35) {
                                                                                                                                                    var _errs1136 = errors;
                                                                                                                                                    if (errors ===
                                                                                                                                                        _errs1136) {
                                                                                                                                                        if (data &&
                                                                                                                                                            typeof data ==
                                                                                                                                                                'object' &&
                                                                                                                                                            !Array.isArray(data)) {
                                                                                                                                                            var missing101 = void 0;
                                                                                                                                                            if ((data.name ===
                                                                                                                                                                undefined &&
                                                                                                                                                                (missing101 =
                                                                                                                                                                    'name')) ||
                                                                                                                                                                (data.participant ===
                                                                                                                                                                    undefined &&
                                                                                                                                                                    (missing101 =
                                                                                                                                                                        'participant')) ||
                                                                                                                                                                (data.timestamp ===
                                                                                                                                                                    undefined &&
                                                                                                                                                                    (missing101 =
                                                                                                                                                                        'timestamp')) ||
                                                                                                                                                                (data.track ===
                                                                                                                                                                    undefined &&
                                                                                                                                                                    (missing101 =
                                                                                                                                                                        'track'))) {
                                                                                                                                                                validate11.errors =
                                                                                                                                                                    [
                                                                                                                                                                        {
                                                                                                                                                                            instancePath: instancePath,
                                                                                                                                                                            schemaPath: '#/allOf/35/then/required',
                                                                                                                                                                            keyword: 'required',
                                                                                                                                                                            params: {
                                                                                                                                                                                missingProperty: missing101,
                                                                                                                                                                            },
                                                                                                                                                                            message: "must have required property '" +
                                                                                                                                                                                missing101 +
                                                                                                                                                                                "'",
                                                                                                                                                                        },
                                                                                                                                                                    ];
                                                                                                                                                                return false;
                                                                                                                                                            }
                                                                                                                                                            else {
                                                                                                                                                                var _errs1138 = errors;
                                                                                                                                                                for (var key102 in data) {
                                                                                                                                                                    if (!(key102 ===
                                                                                                                                                                        'track' ||
                                                                                                                                                                        key102 ===
                                                                                                                                                                            'participant' ||
                                                                                                                                                                        key102 ===
                                                                                                                                                                            'name' ||
                                                                                                                                                                        key102 ===
                                                                                                                                                                            'timestamp')) {
                                                                                                                                                                        validate11.errors =
                                                                                                                                                                            [
                                                                                                                                                                                {
                                                                                                                                                                                    instancePath: instancePath,
                                                                                                                                                                                    schemaPath: '#/allOf/35/then/additionalProperties',
                                                                                                                                                                                    keyword: 'additionalProperties',
                                                                                                                                                                                    params: {
                                                                                                                                                                                        additionalProperty: key102,
                                                                                                                                                                                    },
                                                                                                                                                                                    message: 'must NOT have additional properties',
                                                                                                                                                                                },
                                                                                                                                                                            ];
                                                                                                                                                                        return false;
                                                                                                                                                                        break;
                                                                                                                                                                    }
                                                                                                                                                                }
                                                                                                                                                                if (_errs1138 ===
                                                                                                                                                                    errors) {
                                                                                                                                                                    if (data.track !==
                                                                                                                                                                        undefined) {
                                                                                                                                                                        var data418 = data.track;
                                                                                                                                                                        var _errs1139 = errors;
                                                                                                                                                                        if (errors ===
                                                                                                                                                                            _errs1139) {
                                                                                                                                                                            if (data418 &&
                                                                                                                                                                                typeof data418 ==
                                                                                                                                                                                    'object' &&
                                                                                                                                                                                !Array.isArray(data418)) {
                                                                                                                                                                                var missing102 = void 0;
                                                                                                                                                                                if ((data418.sid ===
                                                                                                                                                                                    undefined &&
                                                                                                                                                                                    (missing102 =
                                                                                                                                                                                        'sid')) ||
                                                                                                                                                                                    (data418.source ===
                                                                                                                                                                                        undefined &&
                                                                                                                                                                                        (missing102 =
                                                                                                                                                                                            'source'))) {
                                                                                                                                                                                    validate11.errors =
                                                                                                                                                                                        [
                                                                                                                                                                                            {
                                                                                                                                                                                                instancePath: instancePath +
                                                                                                                                                                                                    '/track',
                                                                                                                                                                                                schemaPath: '#/allOf/35/then/properties/track/required',
                                                                                                                                                                                                keyword: 'required',
                                                                                                                                                                                                params: {
                                                                                                                                                                                                    missingProperty: missing102,
                                                                                                                                                                                                },
                                                                                                                                                                                                message: "must have required property '" +
                                                                                                                                                                                                    missing102 +
                                                                                                                                                                                                    "'",
                                                                                                                                                                                            },
                                                                                                                                                                                        ];
                                                                                                                                                                                    return false;
                                                                                                                                                                                }
                                                                                                                                                                                else {
                                                                                                                                                                                    var _errs1141 = errors;
                                                                                                                                                                                    for (var key103 in data418) {
                                                                                                                                                                                        if (!(key103 ===
                                                                                                                                                                                            'sid' ||
                                                                                                                                                                                            key103 ===
                                                                                                                                                                                                'source' ||
                                                                                                                                                                                            key103 ===
                                                                                                                                                                                                'mimeType' ||
                                                                                                                                                                                            key103 ===
                                                                                                                                                                                                'dimensions')) {
                                                                                                                                                                                            validate11.errors =
                                                                                                                                                                                                [
                                                                                                                                                                                                    {
                                                                                                                                                                                                        instancePath: instancePath +
                                                                                                                                                                                                            '/track',
                                                                                                                                                                                                        schemaPath: '#/allOf/35/then/properties/track/additionalProperties',
                                                                                                                                                                                                        keyword: 'additionalProperties',
                                                                                                                                                                                                        params: {
                                                                                                                                                                                                            additionalProperty: key103,
                                                                                                                                                                                                        },
                                                                                                                                                                                                        message: 'must NOT have additional properties',
                                                                                                                                                                                                    },
                                                                                                                                                                                                ];
                                                                                                                                                                                            return false;
                                                                                                                                                                                            break;
                                                                                                                                                                                        }
                                                                                                                                                                                    }
                                                                                                                                                                                    if (_errs1141 ===
                                                                                                                                                                                        errors) {
                                                                                                                                                                                        if (data418.sid !==
                                                                                                                                                                                            undefined) {
                                                                                                                                                                                            var _errs1142 = errors;
                                                                                                                                                                                            if (typeof data418.sid !==
                                                                                                                                                                                                'string') {
                                                                                                                                                                                                validate11.errors =
                                                                                                                                                                                                    [
                                                                                                                                                                                                        {
                                                                                                                                                                                                            instancePath: instancePath +
                                                                                                                                                                                                                '/track/sid',
                                                                                                                                                                                                            schemaPath: '#/allOf/35/then/properties/track/properties/sid/type',
                                                                                                                                                                                                            keyword: 'type',
                                                                                                                                                                                                            params: {
                                                                                                                                                                                                                type: 'string',
                                                                                                                                                                                                            },
                                                                                                                                                                                                            message: 'must be string',
                                                                                                                                                                                                        },
                                                                                                                                                                                                    ];
                                                                                                                                                                                                return false;
                                                                                                                                                                                            }
                                                                                                                                                                                            var valid196 = _errs1142 ===
                                                                                                                                                                                                errors;
                                                                                                                                                                                        }
                                                                                                                                                                                        else {
                                                                                                                                                                                            var valid196 = true;
                                                                                                                                                                                        }
                                                                                                                                                                                        if (valid196) {
                                                                                                                                                                                            if (data418.source !==
                                                                                                                                                                                                undefined) {
                                                                                                                                                                                                var data420 = data418.source;
                                                                                                                                                                                                var _errs1144 = errors;
                                                                                                                                                                                                if (typeof data420 !==
                                                                                                                                                                                                    'string') {
                                                                                                                                                                                                    validate11.errors =
                                                                                                                                                                                                        [
                                                                                                                                                                                                            {
                                                                                                                                                                                                                instancePath: instancePath +
                                                                                                                                                                                                                    '/track/source',
                                                                                                                                                                                                                schemaPath: '#/definitions/Track.Source/type',
                                                                                                                                                                                                                keyword: 'type',
                                                                                                                                                                                                                params: {
                                                                                                                                                                                                                    type: 'string',
                                                                                                                                                                                                                },
                                                                                                                                                                                                                message: 'must be string',
                                                                                                                                                                                                            },
                                                                                                                                                                                                        ];
                                                                                                                                                                                                    return false;
                                                                                                                                                                                                }
                                                                                                                                                                                                if (!(data420 ===
                                                                                                                                                                                                    'camera' ||
                                                                                                                                                                                                    data420 ===
                                                                                                                                                                                                        'microphone' ||
                                                                                                                                                                                                    data420 ===
                                                                                                                                                                                                        'screen_share' ||
                                                                                                                                                                                                    data420 ===
                                                                                                                                                                                                        'screen_share_audio' ||
                                                                                                                                                                                                    data420 ===
                                                                                                                                                                                                        'unknown')) {
                                                                                                                                                                                                    validate11.errors =
                                                                                                                                                                                                        [
                                                                                                                                                                                                            {
                                                                                                                                                                                                                instancePath: instancePath +
                                                                                                                                                                                                                    '/track/source',
                                                                                                                                                                                                                schemaPath: '#/definitions/Track.Source/enum',
                                                                                                                                                                                                                keyword: 'enum',
                                                                                                                                                                                                                params: {
                                                                                                                                                                                                                    allowedValues: schema31.enum,
                                                                                                                                                                                                                },
                                                                                                                                                                                                                message: 'must be equal to one of the allowed values',
                                                                                                                                                                                                            },
                                                                                                                                                                                                        ];
                                                                                                                                                                                                    return false;
                                                                                                                                                                                                }
                                                                                                                                                                                                var valid196 = _errs1144 ===
                                                                                                                                                                                                    errors;
                                                                                                                                                                                            }
                                                                                                                                                                                            else {
                                                                                                                                                                                                var valid196 = true;
                                                                                                                                                                                            }
                                                                                                                                                                                            if (valid196) {
                                                                                                                                                                                                if (data418.mimeType !==
                                                                                                                                                                                                    undefined) {
                                                                                                                                                                                                    var _errs1147 = errors;
                                                                                                                                                                                                    if (typeof data418.mimeType !==
                                                                                                                                                                                                        'string') {
                                                                                                                                                                                                        validate11.errors =
                                                                                                                                                                                                            [
                                                                                                                                                                                                                {
                                                                                                                                                                                                                    instancePath: instancePath +
                                                                                                                                                                                                                        '/track/mimeType',
                                                                                                                                                                                                                    schemaPath: '#/allOf/35/then/properties/track/properties/mimeType/type',
                                                                                                                                                                                                                    keyword: 'type',
                                                                                                                                                                                                                    params: {
                                                                                                                                                                                                                        type: 'string',
                                                                                                                                                                                                                    },
                                                                                                                                                                                                                    message: 'must be string',
                                                                                                                                                                                                                },
                                                                                                                                                                                                            ];
                                                                                                                                                                                                        return false;
                                                                                                                                                                                                    }
                                                                                                                                                                                                    var valid196 = _errs1147 ===
                                                                                                                                                                                                        errors;
                                                                                                                                                                                                }
                                                                                                                                                                                                else {
                                                                                                                                                                                                    var valid196 = true;
                                                                                                                                                                                                }
                                                                                                                                                                                                if (valid196) {
                                                                                                                                                                                                    if (data418.dimensions !==
                                                                                                                                                                                                        undefined) {
                                                                                                                                                                                                        var data422 = data418.dimensions;
                                                                                                                                                                                                        var _errs1149 = errors;
                                                                                                                                                                                                        if (errors ===
                                                                                                                                                                                                            _errs1149) {
                                                                                                                                                                                                            if (data422 &&
                                                                                                                                                                                                                typeof data422 ==
                                                                                                                                                                                                                    'object' &&
                                                                                                                                                                                                                !Array.isArray(data422)) {
                                                                                                                                                                                                                var missing103 = void 0;
                                                                                                                                                                                                                if ((data422.width ===
                                                                                                                                                                                                                    undefined &&
                                                                                                                                                                                                                    (missing103 =
                                                                                                                                                                                                                        'width')) ||
                                                                                                                                                                                                                    (data422.height ===
                                                                                                                                                                                                                        undefined &&
                                                                                                                                                                                                                        (missing103 =
                                                                                                                                                                                                                            'height'))) {
                                                                                                                                                                                                                    validate11.errors =
                                                                                                                                                                                                                        [
                                                                                                                                                                                                                            {
                                                                                                                                                                                                                                instancePath: instancePath +
                                                                                                                                                                                                                                    '/track/dimensions',
                                                                                                                                                                                                                                schemaPath: '#/allOf/35/then/properties/track/properties/dimensions/required',
                                                                                                                                                                                                                                keyword: 'required',
                                                                                                                                                                                                                                params: {
                                                                                                                                                                                                                                    missingProperty: missing103,
                                                                                                                                                                                                                                },
                                                                                                                                                                                                                                message: "must have required property '" +
                                                                                                                                                                                                                                    missing103 +
                                                                                                                                                                                                                                    "'",
                                                                                                                                                                                                                            },
                                                                                                                                                                                                                        ];
                                                                                                                                                                                                                    return false;
                                                                                                                                                                                                                }
                                                                                                                                                                                                                else {
                                                                                                                                                                                                                    var _errs1151 = errors;
                                                                                                                                                                                                                    for (var key104 in data422) {
                                                                                                                                                                                                                        if (!(key104 ===
                                                                                                                                                                                                                            'width' ||
                                                                                                                                                                                                                            key104 ===
                                                                                                                                                                                                                                'height')) {
                                                                                                                                                                                                                            validate11.errors =
                                                                                                                                                                                                                                [
                                                                                                                                                                                                                                    {
                                                                                                                                                                                                                                        instancePath: instancePath +
                                                                                                                                                                                                                                            '/track/dimensions',
                                                                                                                                                                                                                                        schemaPath: '#/allOf/35/then/properties/track/properties/dimensions/additionalProperties',
                                                                                                                                                                                                                                        keyword: 'additionalProperties',
                                                                                                                                                                                                                                        params: {
                                                                                                                                                                                                                                            additionalProperty: key104,
                                                                                                                                                                                                                                        },
                                                                                                                                                                                                                                        message: 'must NOT have additional properties',
                                                                                                                                                                                                                                    },
                                                                                                                                                                                                                                ];
                                                                                                                                                                                                                            return false;
                                                                                                                                                                                                                            break;
                                                                                                                                                                                                                        }
                                                                                                                                                                                                                    }
                                                                                                                                                                                                                    if (_errs1151 ===
                                                                                                                                                                                                                        errors) {
                                                                                                                                                                                                                        if (data422.width !==
                                                                                                                                                                                                                            undefined) {
                                                                                                                                                                                                                            var data423 = data422.width;
                                                                                                                                                                                                                            var _errs1152 = errors;
                                                                                                                                                                                                                            if (!(typeof data423 ==
                                                                                                                                                                                                                                'number' &&
                                                                                                                                                                                                                                isFinite(data423))) {
                                                                                                                                                                                                                                validate11.errors =
                                                                                                                                                                                                                                    [
                                                                                                                                                                                                                                        {
                                                                                                                                                                                                                                            instancePath: instancePath +
                                                                                                                                                                                                                                                '/track/dimensions/width',
                                                                                                                                                                                                                                            schemaPath: '#/allOf/35/then/properties/track/properties/dimensions/properties/width/type',
                                                                                                                                                                                                                                            keyword: 'type',
                                                                                                                                                                                                                                            params: {
                                                                                                                                                                                                                                                type: 'number',
                                                                                                                                                                                                                                            },
                                                                                                                                                                                                                                            message: 'must be number',
                                                                                                                                                                                                                                        },
                                                                                                                                                                                                                                    ];
                                                                                                                                                                                                                                return false;
                                                                                                                                                                                                                            }
                                                                                                                                                                                                                            var valid198 = _errs1152 ===
                                                                                                                                                                                                                                errors;
                                                                                                                                                                                                                        }
                                                                                                                                                                                                                        else {
                                                                                                                                                                                                                            var valid198 = true;
                                                                                                                                                                                                                        }
                                                                                                                                                                                                                        if (valid198) {
                                                                                                                                                                                                                            if (data422.height !==
                                                                                                                                                                                                                                undefined) {
                                                                                                                                                                                                                                var data424 = data422.height;
                                                                                                                                                                                                                                var _errs1154 = errors;
                                                                                                                                                                                                                                if (!(typeof data424 ==
                                                                                                                                                                                                                                    'number' &&
                                                                                                                                                                                                                                    isFinite(data424))) {
                                                                                                                                                                                                                                    validate11.errors =
                                                                                                                                                                                                                                        [
                                                                                                                                                                                                                                            {
                                                                                                                                                                                                                                                instancePath: instancePath +
                                                                                                                                                                                                                                                    '/track/dimensions/height',
                                                                                                                                                                                                                                                schemaPath: '#/allOf/35/then/properties/track/properties/dimensions/properties/height/type',
                                                                                                                                                                                                                                                keyword: 'type',
                                                                                                                                                                                                                                                params: {
                                                                                                                                                                                                                                                    type: 'number',
                                                                                                                                                                                                                                                },
                                                                                                                                                                                                                                                message: 'must be number',
                                                                                                                                                                                                                                            },
                                                                                                                                                                                                                                        ];
                                                                                                                                                                                                                                    return false;
                                                                                                                                                                                                                                }
                                                                                                                                                                                                                                var valid198 = _errs1154 ===
                                                                                                                                                                                                                                    errors;
                                                                                                                                                                                                                            }
                                                                                                                                                                                                                            else {
                                                                                                                                                                                                                                var valid198 = true;
                                                                                                                                                                                                                            }
                                                                                                                                                                                                                        }
                                                                                                                                                                                                                    }
                                                                                                                                                                                                                }
                                                                                                                                                                                                            }
                                                                                                                                                                                                            else {
                                                                                                                                                                                                                validate11.errors =
                                                                                                                                                                                                                    [
                                                                                                                                                                                                                        {
                                                                                                                                                                                                                            instancePath: instancePath +
                                                                                                                                                                                                                                '/track/dimensions',
                                                                                                                                                                                                                            schemaPath: '#/allOf/35/then/properties/track/properties/dimensions/type',
                                                                                                                                                                                                                            keyword: 'type',
                                                                                                                                                                                                                            params: {
                                                                                                                                                                                                                                type: 'object',
                                                                                                                                                                                                                            },
                                                                                                                                                                                                                            message: 'must be object',
                                                                                                                                                                                                                        },
                                                                                                                                                                                                                    ];
                                                                                                                                                                                                                return false;
                                                                                                                                                                                                            }
                                                                                                                                                                                                        }
                                                                                                                                                                                                        var valid196 = _errs1149 ===
                                                                                                                                                                                                            errors;
                                                                                                                                                                                                    }
                                                                                                                                                                                                    else {
                                                                                                                                                                                                        var valid196 = true;
                                                                                                                                                                                                    }
                                                                                                                                                                                                }
                                                                                                                                                                                            }
                                                                                                                                                                                        }
                                                                                                                                                                                    }
                                                                                                                                                                                }
                                                                                                                                                                            }
                                                                                                                                                                            else {
                                                                                                                                                                                validate11.errors =
                                                                                                                                                                                    [
                                                                                                                                                                                        {
                                                                                                                                                                                            instancePath: instancePath +
                                                                                                                                                                                                '/track',
                                                                                                                                                                                            schemaPath: '#/allOf/35/then/properties/track/type',
                                                                                                                                                                                            keyword: 'type',
                                                                                                                                                                                            params: {
                                                                                                                                                                                                type: 'object',
                                                                                                                                                                                            },
                                                                                                                                                                                            message: 'must be object',
                                                                                                                                                                                        },
                                                                                                                                                                                    ];
                                                                                                                                                                                return false;
                                                                                                                                                                            }
                                                                                                                                                                        }
                                                                                                                                                                        var valid195 = _errs1139 ===
                                                                                                                                                                            errors;
                                                                                                                                                                    }
                                                                                                                                                                    else {
                                                                                                                                                                        var valid195 = true;
                                                                                                                                                                    }
                                                                                                                                                                    if (valid195) {
                                                                                                                                                                        if (data.participant !==
                                                                                                                                                                            undefined) {
                                                                                                                                                                            var data425 = data.participant;
                                                                                                                                                                            var _errs1156 = errors;
                                                                                                                                                                            if (errors ===
                                                                                                                                                                                _errs1156) {
                                                                                                                                                                                if (data425 &&
                                                                                                                                                                                    typeof data425 ==
                                                                                                                                                                                        'object' &&
                                                                                                                                                                                    !Array.isArray(data425)) {
                                                                                                                                                                                    var missing104 = void 0;
                                                                                                                                                                                    if ((data425.identity ===
                                                                                                                                                                                        undefined &&
                                                                                                                                                                                        (missing104 =
                                                                                                                                                                                            'identity')) ||
                                                                                                                                                                                        (data425.sid ===
                                                                                                                                                                                            undefined &&
                                                                                                                                                                                            (missing104 =
                                                                                                                                                                                                'sid'))) {
                                                                                                                                                                                        validate11.errors =
                                                                                                                                                                                            [
                                                                                                                                                                                                {
                                                                                                                                                                                                    instancePath: instancePath +
                                                                                                                                                                                                        '/participant',
                                                                                                                                                                                                    schemaPath: '#/allOf/35/then/properties/participant/required',
                                                                                                                                                                                                    keyword: 'required',
                                                                                                                                                                                                    params: {
                                                                                                                                                                                                        missingProperty: missing104,
                                                                                                                                                                                                    },
                                                                                                                                                                                                    message: "must have required property '" +
                                                                                                                                                                                                        missing104 +
                                                                                                                                                                                                        "'",
                                                                                                                                                                                                },
                                                                                                                                                                                            ];
                                                                                                                                                                                        return false;
                                                                                                                                                                                    }
                                                                                                                                                                                    else {
                                                                                                                                                                                        var _errs1158 = errors;
                                                                                                                                                                                        for (var key105 in data425) {
                                                                                                                                                                                            if (!(key105 ===
                                                                                                                                                                                                'identity' ||
                                                                                                                                                                                                key105 ===
                                                                                                                                                                                                    'sid' ||
                                                                                                                                                                                                key105 ===
                                                                                                                                                                                                    'permissions')) {
                                                                                                                                                                                                validate11.errors =
                                                                                                                                                                                                    [
                                                                                                                                                                                                        {
                                                                                                                                                                                                            instancePath: instancePath +
                                                                                                                                                                                                                '/participant',
                                                                                                                                                                                                            schemaPath: '#/allOf/35/then/properties/participant/additionalProperties',
                                                                                                                                                                                                            keyword: 'additionalProperties',
                                                                                                                                                                                                            params: {
                                                                                                                                                                                                                additionalProperty: key105,
                                                                                                                                                                                                            },
                                                                                                                                                                                                            message: 'must NOT have additional properties',
                                                                                                                                                                                                        },
                                                                                                                                                                                                    ];
                                                                                                                                                                                                return false;
                                                                                                                                                                                                break;
                                                                                                                                                                                            }
                                                                                                                                                                                        }
                                                                                                                                                                                        if (_errs1158 ===
                                                                                                                                                                                            errors) {
                                                                                                                                                                                            if (data425.identity !==
                                                                                                                                                                                                undefined) {
                                                                                                                                                                                                var _errs1159 = errors;
                                                                                                                                                                                                if (typeof data425.identity !==
                                                                                                                                                                                                    'string') {
                                                                                                                                                                                                    validate11.errors =
                                                                                                                                                                                                        [
                                                                                                                                                                                                            {
                                                                                                                                                                                                                instancePath: instancePath +
                                                                                                                                                                                                                    '/participant/identity',
                                                                                                                                                                                                                schemaPath: '#/allOf/35/then/properties/participant/properties/identity/type',
                                                                                                                                                                                                                keyword: 'type',
                                                                                                                                                                                                                params: {
                                                                                                                                                                                                                    type: 'string',
                                                                                                                                                                                                                },
                                                                                                                                                                                                                message: 'must be string',
                                                                                                                                                                                                            },
                                                                                                                                                                                                        ];
                                                                                                                                                                                                    return false;
                                                                                                                                                                                                }
                                                                                                                                                                                                var valid199 = _errs1159 ===
                                                                                                                                                                                                    errors;
                                                                                                                                                                                            }
                                                                                                                                                                                            else {
                                                                                                                                                                                                var valid199 = true;
                                                                                                                                                                                            }
                                                                                                                                                                                            if (valid199) {
                                                                                                                                                                                                if (data425.sid !==
                                                                                                                                                                                                    undefined) {
                                                                                                                                                                                                    var _errs1161 = errors;
                                                                                                                                                                                                    if (typeof data425.sid !==
                                                                                                                                                                                                        'string') {
                                                                                                                                                                                                        validate11.errors =
                                                                                                                                                                                                            [
                                                                                                                                                                                                                {
                                                                                                                                                                                                                    instancePath: instancePath +
                                                                                                                                                                                                                        '/participant/sid',
                                                                                                                                                                                                                    schemaPath: '#/allOf/35/then/properties/participant/properties/sid/type',
                                                                                                                                                                                                                    keyword: 'type',
                                                                                                                                                                                                                    params: {
                                                                                                                                                                                                                        type: 'string',
                                                                                                                                                                                                                    },
                                                                                                                                                                                                                    message: 'must be string',
                                                                                                                                                                                                                },
                                                                                                                                                                                                            ];
                                                                                                                                                                                                        return false;
                                                                                                                                                                                                    }
                                                                                                                                                                                                    var valid199 = _errs1161 ===
                                                                                                                                                                                                        errors;
                                                                                                                                                                                                }
                                                                                                                                                                                                else {
                                                                                                                                                                                                    var valid199 = true;
                                                                                                                                                                                                }
                                                                                                                                                                                                if (valid199) {
                                                                                                                                                                                                    if (data425.permissions !==
                                                                                                                                                                                                        undefined) {
                                                                                                                                                                                                        var data428 = data425.permissions;
                                                                                                                                                                                                        var _errs1163 = errors;
                                                                                                                                                                                                        if (errors ===
                                                                                                                                                                                                            _errs1163) {
                                                                                                                                                                                                            if (data428 &&
                                                                                                                                                                                                                typeof data428 ==
                                                                                                                                                                                                                    'object' &&
                                                                                                                                                                                                                !Array.isArray(data428)) {
                                                                                                                                                                                                                var missing105 = void 0;
                                                                                                                                                                                                                if ((data428.canSubscribe ===
                                                                                                                                                                                                                    undefined &&
                                                                                                                                                                                                                    (missing105 =
                                                                                                                                                                                                                        'canSubscribe')) ||
                                                                                                                                                                                                                    (data428.canPublish ===
                                                                                                                                                                                                                        undefined &&
                                                                                                                                                                                                                        (missing105 =
                                                                                                                                                                                                                            'canPublish')) ||
                                                                                                                                                                                                                    (data428.canPublishData ===
                                                                                                                                                                                                                        undefined &&
                                                                                                                                                                                                                        (missing105 =
                                                                                                                                                                                                                            'canPublishData')) ||
                                                                                                                                                                                                                    (data428.hidden ===
                                                                                                                                                                                                                        undefined &&
                                                                                                                                                                                                                        (missing105 =
                                                                                                                                                                                                                            'hidden')) ||
                                                                                                                                                                                                                    (data428.canUpdateMetadata ===
                                                                                                                                                                                                                        undefined &&
                                                                                                                                                                                                                        (missing105 =
                                                                                                                                                                                                                            'canUpdateMetadata')) ||
                                                                                                                                                                                                                    (data428.canSubscribeMetrics ===
                                                                                                                                                                                                                        undefined &&
                                                                                                                                                                                                                        (missing105 =
                                                                                                                                                                                                                            'canSubscribeMetrics'))) {
                                                                                                                                                                                                                    validate11.errors =
                                                                                                                                                                                                                        [
                                                                                                                                                                                                                            {
                                                                                                                                                                                                                                instancePath: instancePath +
                                                                                                                                                                                                                                    '/participant/permissions',
                                                                                                                                                                                                                                schemaPath: '#/allOf/35/then/properties/participant/properties/permissions/required',
                                                                                                                                                                                                                                keyword: 'required',
                                                                                                                                                                                                                                params: {
                                                                                                                                                                                                                                    missingProperty: missing105,
                                                                                                                                                                                                                                },
                                                                                                                                                                                                                                message: "must have required property '" +
                                                                                                                                                                                                                                    missing105 +
                                                                                                                                                                                                                                    "'",
                                                                                                                                                                                                                            },
                                                                                                                                                                                                                        ];
                                                                                                                                                                                                                    return false;
                                                                                                                                                                                                                }
                                                                                                                                                                                                                else {
                                                                                                                                                                                                                    var _errs1165 = errors;
                                                                                                                                                                                                                    for (var key106 in data428) {
                                                                                                                                                                                                                        if (!(key106 ===
                                                                                                                                                                                                                            'canSubscribe' ||
                                                                                                                                                                                                                            key106 ===
                                                                                                                                                                                                                                'canPublish' ||
                                                                                                                                                                                                                            key106 ===
                                                                                                                                                                                                                                'canPublishData' ||
                                                                                                                                                                                                                            key106 ===
                                                                                                                                                                                                                                'hidden' ||
                                                                                                                                                                                                                            key106 ===
                                                                                                                                                                                                                                'canUpdateMetadata' ||
                                                                                                                                                                                                                            key106 ===
                                                                                                                                                                                                                                'canSubscribeMetrics')) {
                                                                                                                                                                                                                            validate11.errors =
                                                                                                                                                                                                                                [
                                                                                                                                                                                                                                    {
                                                                                                                                                                                                                                        instancePath: instancePath +
                                                                                                                                                                                                                                            '/participant/permissions',
                                                                                                                                                                                                                                        schemaPath: '#/allOf/35/then/properties/participant/properties/permissions/additionalProperties',
                                                                                                                                                                                                                                        keyword: 'additionalProperties',
                                                                                                                                                                                                                                        params: {
                                                                                                                                                                                                                                            additionalProperty: key106,
                                                                                                                                                                                                                                        },
                                                                                                                                                                                                                                        message: 'must NOT have additional properties',
                                                                                                                                                                                                                                    },
                                                                                                                                                                                                                                ];
                                                                                                                                                                                                                            return false;
                                                                                                                                                                                                                            break;
                                                                                                                                                                                                                        }
                                                                                                                                                                                                                    }
                                                                                                                                                                                                                    if (_errs1165 ===
                                                                                                                                                                                                                        errors) {
                                                                                                                                                                                                                        if (data428.canSubscribe !==
                                                                                                                                                                                                                            undefined) {
                                                                                                                                                                                                                            var _errs1166 = errors;
                                                                                                                                                                                                                            if (typeof data428.canSubscribe !==
                                                                                                                                                                                                                                'boolean') {
                                                                                                                                                                                                                                validate11.errors =
                                                                                                                                                                                                                                    [
                                                                                                                                                                                                                                        {
                                                                                                                                                                                                                                            instancePath: instancePath +
                                                                                                                                                                                                                                                '/participant/permissions/canSubscribe',
                                                                                                                                                                                                                                            schemaPath: '#/allOf/35/then/properties/participant/properties/permissions/properties/canSubscribe/type',
                                                                                                                                                                                                                                            keyword: 'type',
                                                                                                                                                                                                                                            params: {
                                                                                                                                                                                                                                                type: 'boolean',
                                                                                                                                                                                                                                            },
                                                                                                                                                                                                                                            message: 'must be boolean',
                                                                                                                                                                                                                                        },
                                                                                                                                                                                                                                    ];
                                                                                                                                                                                                                                return false;
                                                                                                                                                                                                                            }
                                                                                                                                                                                                                            var valid200 = _errs1166 ===
                                                                                                                                                                                                                                errors;
                                                                                                                                                                                                                        }
                                                                                                                                                                                                                        else {
                                                                                                                                                                                                                            var valid200 = true;
                                                                                                                                                                                                                        }
                                                                                                                                                                                                                        if (valid200) {
                                                                                                                                                                                                                            if (data428.canPublish !==
                                                                                                                                                                                                                                undefined) {
                                                                                                                                                                                                                                var _errs1168 = errors;
                                                                                                                                                                                                                                if (typeof data428.canPublish !==
                                                                                                                                                                                                                                    'boolean') {
                                                                                                                                                                                                                                    validate11.errors =
                                                                                                                                                                                                                                        [
                                                                                                                                                                                                                                            {
                                                                                                                                                                                                                                                instancePath: instancePath +
                                                                                                                                                                                                                                                    '/participant/permissions/canPublish',
                                                                                                                                                                                                                                                schemaPath: '#/allOf/35/then/properties/participant/properties/permissions/properties/canPublish/type',
                                                                                                                                                                                                                                                keyword: 'type',
                                                                                                                                                                                                                                                params: {
                                                                                                                                                                                                                                                    type: 'boolean',
                                                                                                                                                                                                                                                },
                                                                                                                                                                                                                                                message: 'must be boolean',
                                                                                                                                                                                                                                            },
                                                                                                                                                                                                                                        ];
                                                                                                                                                                                                                                    return false;
                                                                                                                                                                                                                                }
                                                                                                                                                                                                                                var valid200 = _errs1168 ===
                                                                                                                                                                                                                                    errors;
                                                                                                                                                                                                                            }
                                                                                                                                                                                                                            else {
                                                                                                                                                                                                                                var valid200 = true;
                                                                                                                                                                                                                            }
                                                                                                                                                                                                                            if (valid200) {
                                                                                                                                                                                                                                if (data428.canPublishData !==
                                                                                                                                                                                                                                    undefined) {
                                                                                                                                                                                                                                    var _errs1170 = errors;
                                                                                                                                                                                                                                    if (typeof data428.canPublishData !==
                                                                                                                                                                                                                                        'boolean') {
                                                                                                                                                                                                                                        validate11.errors =
                                                                                                                                                                                                                                            [
                                                                                                                                                                                                                                                {
                                                                                                                                                                                                                                                    instancePath: instancePath +
                                                                                                                                                                                                                                                        '/participant/permissions/canPublishData',
                                                                                                                                                                                                                                                    schemaPath: '#/allOf/35/then/properties/participant/properties/permissions/properties/canPublishData/type',
                                                                                                                                                                                                                                                    keyword: 'type',
                                                                                                                                                                                                                                                    params: {
                                                                                                                                                                                                                                                        type: 'boolean',
                                                                                                                                                                                                                                                    },
                                                                                                                                                                                                                                                    message: 'must be boolean',
                                                                                                                                                                                                                                                },
                                                                                                                                                                                                                                            ];
                                                                                                                                                                                                                                        return false;
                                                                                                                                                                                                                                    }
                                                                                                                                                                                                                                    var valid200 = _errs1170 ===
                                                                                                                                                                                                                                        errors;
                                                                                                                                                                                                                                }
                                                                                                                                                                                                                                else {
                                                                                                                                                                                                                                    var valid200 = true;
                                                                                                                                                                                                                                }
                                                                                                                                                                                                                                if (valid200) {
                                                                                                                                                                                                                                    if (data428.hidden !==
                                                                                                                                                                                                                                        undefined) {
                                                                                                                                                                                                                                        var _errs1172 = errors;
                                                                                                                                                                                                                                        if (typeof data428.hidden !==
                                                                                                                                                                                                                                            'boolean') {
                                                                                                                                                                                                                                            validate11.errors =
                                                                                                                                                                                                                                                [
                                                                                                                                                                                                                                                    {
                                                                                                                                                                                                                                                        instancePath: instancePath +
                                                                                                                                                                                                                                                            '/participant/permissions/hidden',
                                                                                                                                                                                                                                                        schemaPath: '#/allOf/35/then/properties/participant/properties/permissions/properties/hidden/type',
                                                                                                                                                                                                                                                        keyword: 'type',
                                                                                                                                                                                                                                                        params: {
                                                                                                                                                                                                                                                            type: 'boolean',
                                                                                                                                                                                                                                                        },
                                                                                                                                                                                                                                                        message: 'must be boolean',
                                                                                                                                                                                                                                                    },
                                                                                                                                                                                                                                                ];
                                                                                                                                                                                                                                            return false;
                                                                                                                                                                                                                                        }
                                                                                                                                                                                                                                        var valid200 = _errs1172 ===
                                                                                                                                                                                                                                            errors;
                                                                                                                                                                                                                                    }
                                                                                                                                                                                                                                    else {
                                                                                                                                                                                                                                        var valid200 = true;
                                                                                                                                                                                                                                    }
                                                                                                                                                                                                                                    if (valid200) {
                                                                                                                                                                                                                                        if (data428.canUpdateMetadata !==
                                                                                                                                                                                                                                            undefined) {
                                                                                                                                                                                                                                            var _errs1174 = errors;
                                                                                                                                                                                                                                            if (typeof data428.canUpdateMetadata !==
                                                                                                                                                                                                                                                'boolean') {
                                                                                                                                                                                                                                                validate11.errors =
                                                                                                                                                                                                                                                    [
                                                                                                                                                                                                                                                        {
                                                                                                                                                                                                                                                            instancePath: instancePath +
                                                                                                                                                                                                                                                                '/participant/permissions/canUpdateMetadata',
                                                                                                                                                                                                                                                            schemaPath: '#/allOf/35/then/properties/participant/properties/permissions/properties/canUpdateMetadata/type',
                                                                                                                                                                                                                                                            keyword: 'type',
                                                                                                                                                                                                                                                            params: {
                                                                                                                                                                                                                                                                type: 'boolean',
                                                                                                                                                                                                                                                            },
                                                                                                                                                                                                                                                            message: 'must be boolean',
                                                                                                                                                                                                                                                        },
                                                                                                                                                                                                                                                    ];
                                                                                                                                                                                                                                                return false;
                                                                                                                                                                                                                                            }
                                                                                                                                                                                                                                            var valid200 = _errs1174 ===
                                                                                                                                                                                                                                                errors;
                                                                                                                                                                                                                                        }
                                                                                                                                                                                                                                        else {
                                                                                                                                                                                                                                            var valid200 = true;
                                                                                                                                                                                                                                        }
                                                                                                                                                                                                                                        if (valid200) {
                                                                                                                                                                                                                                            if (data428.canSubscribeMetrics !==
                                                                                                                                                                                                                                                undefined) {
                                                                                                                                                                                                                                                var _errs1176 = errors;
                                                                                                                                                                                                                                                if (typeof data428.canSubscribeMetrics !==
                                                                                                                                                                                                                                                    'boolean') {
                                                                                                                                                                                                                                                    validate11.errors =
                                                                                                                                                                                                                                                        [
                                                                                                                                                                                                                                                            {
                                                                                                                                                                                                                                                                instancePath: instancePath +
                                                                                                                                                                                                                                                                    '/participant/permissions/canSubscribeMetrics',
                                                                                                                                                                                                                                                                schemaPath: '#/allOf/35/then/properties/participant/properties/permissions/properties/canSubscribeMetrics/type',
                                                                                                                                                                                                                                                                keyword: 'type',
                                                                                                                                                                                                                                                                params: {
                                                                                                                                                                                                                                                                    type: 'boolean',
                                                                                                                                                                                                                                                                },
                                                                                                                                                                                                                                                                message: 'must be boolean',
                                                                                                                                                                                                                                                            },
                                                                                                                                                                                                                                                        ];
                                                                                                                                                                                                                                                    return false;
                                                                                                                                                                                                                                                }
                                                                                                                                                                                                                                                var valid200 = _errs1176 ===
                                                                                                                                                                                                                                                    errors;
                                                                                                                                                                                                                                            }
                                                                                                                                                                                                                                            else {
                                                                                                                                                                                                                                                var valid200 = true;
                                                                                                                                                                                                                                            }
                                                                                                                                                                                                                                        }
                                                                                                                                                                                                                                    }
                                                                                                                                                                                                                                }
                                                                                                                                                                                                                            }
                                                                                                                                                                                                                        }
                                                                                                                                                                                                                    }
                                                                                                                                                                                                                }
                                                                                                                                                                                                            }
                                                                                                                                                                                                            else {
                                                                                                                                                                                                                validate11.errors =
                                                                                                                                                                                                                    [
                                                                                                                                                                                                                        {
                                                                                                                                                                                                                            instancePath: instancePath +
                                                                                                                                                                                                                                '/participant/permissions',
                                                                                                                                                                                                                            schemaPath: '#/allOf/35/then/properties/participant/properties/permissions/type',
                                                                                                                                                                                                                            keyword: 'type',
                                                                                                                                                                                                                            params: {
                                                                                                                                                                                                                                type: 'object',
                                                                                                                                                                                                                            },
                                                                                                                                                                                                                            message: 'must be object',
                                                                                                                                                                                                                        },
                                                                                                                                                                                                                    ];
                                                                                                                                                                                                                return false;
                                                                                                                                                                                                            }
                                                                                                                                                                                                        }
                                                                                                                                                                                                        var valid199 = _errs1163 ===
                                                                                                                                                                                                            errors;
                                                                                                                                                                                                    }
                                                                                                                                                                                                    else {
                                                                                                                                                                                                        var valid199 = true;
                                                                                                                                                                                                    }
                                                                                                                                                                                                }
                                                                                                                                                                                            }
                                                                                                                                                                                        }
                                                                                                                                                                                    }
                                                                                                                                                                                }
                                                                                                                                                                                else {
                                                                                                                                                                                    validate11.errors =
                                                                                                                                                                                        [
                                                                                                                                                                                            {
                                                                                                                                                                                                instancePath: instancePath +
                                                                                                                                                                                                    '/participant',
                                                                                                                                                                                                schemaPath: '#/allOf/35/then/properties/participant/type',
                                                                                                                                                                                                keyword: 'type',
                                                                                                                                                                                                params: {
                                                                                                                                                                                                    type: 'object',
                                                                                                                                                                                                },
                                                                                                                                                                                                message: 'must be object',
                                                                                                                                                                                            },
                                                                                                                                                                                        ];
                                                                                                                                                                                    return false;
                                                                                                                                                                                }
                                                                                                                                                                            }
                                                                                                                                                                            var valid195 = _errs1156 ===
                                                                                                                                                                                errors;
                                                                                                                                                                        }
                                                                                                                                                                        else {
                                                                                                                                                                            var valid195 = true;
                                                                                                                                                                        }
                                                                                                                                                                        if (valid195) {
                                                                                                                                                                            if (data.name !==
                                                                                                                                                                                undefined) {
                                                                                                                                                                                var data435 = data.name;
                                                                                                                                                                                var _errs1178 = errors;
                                                                                                                                                                                if (typeof data435 !==
                                                                                                                                                                                    'string') {
                                                                                                                                                                                    validate11.errors =
                                                                                                                                                                                        [
                                                                                                                                                                                            {
                                                                                                                                                                                                instancePath: instancePath +
                                                                                                                                                                                                    '/name',
                                                                                                                                                                                                schemaPath: '#/allOf/35/then/properties/name/type',
                                                                                                                                                                                                keyword: 'type',
                                                                                                                                                                                                params: {
                                                                                                                                                                                                    type: 'string',
                                                                                                                                                                                                },
                                                                                                                                                                                                message: 'must be string',
                                                                                                                                                                                            },
                                                                                                                                                                                        ];
                                                                                                                                                                                    return false;
                                                                                                                                                                                }
                                                                                                                                                                                if ('localTrackSubscribed' !==
                                                                                                                                                                                    data435) {
                                                                                                                                                                                    validate11.errors =
                                                                                                                                                                                        [
                                                                                                                                                                                            {
                                                                                                                                                                                                instancePath: instancePath +
                                                                                                                                                                                                    '/name',
                                                                                                                                                                                                schemaPath: '#/allOf/35/then/properties/name/const',
                                                                                                                                                                                                keyword: 'const',
                                                                                                                                                                                                params: {
                                                                                                                                                                                                    allowedValue: 'localTrackSubscribed',
                                                                                                                                                                                                },
                                                                                                                                                                                                message: 'must be equal to constant',
                                                                                                                                                                                            },
                                                                                                                                                                                        ];
                                                                                                                                                                                    return false;
                                                                                                                                                                                }
                                                                                                                                                                                var valid195 = _errs1178 ===
                                                                                                                                                                                    errors;
                                                                                                                                                                            }
                                                                                                                                                                            else {
                                                                                                                                                                                var valid195 = true;
                                                                                                                                                                            }
                                                                                                                                                                            if (valid195) {
                                                                                                                                                                                if (data.timestamp !==
                                                                                                                                                                                    undefined) {
                                                                                                                                                                                    var data436 = data.timestamp;
                                                                                                                                                                                    var _errs1180 = errors;
                                                                                                                                                                                    if (!(typeof data436 ==
                                                                                                                                                                                        'number' &&
                                                                                                                                                                                        isFinite(data436))) {
                                                                                                                                                                                        validate11.errors =
                                                                                                                                                                                            [
                                                                                                                                                                                                {
                                                                                                                                                                                                    instancePath: instancePath +
                                                                                                                                                                                                        '/timestamp',
                                                                                                                                                                                                    schemaPath: '#/allOf/35/then/properties/timestamp/type',
                                                                                                                                                                                                    keyword: 'type',
                                                                                                                                                                                                    params: {
                                                                                                                                                                                                        type: 'number',
                                                                                                                                                                                                    },
                                                                                                                                                                                                    message: 'must be number',
                                                                                                                                                                                                },
                                                                                                                                                                                            ];
                                                                                                                                                                                        return false;
                                                                                                                                                                                    }
                                                                                                                                                                                    var valid195 = _errs1180 ===
                                                                                                                                                                                        errors;
                                                                                                                                                                                }
                                                                                                                                                                                else {
                                                                                                                                                                                    var valid195 = true;
                                                                                                                                                                                }
                                                                                                                                                                            }
                                                                                                                                                                        }
                                                                                                                                                                    }
                                                                                                                                                                }
                                                                                                                                                            }
                                                                                                                                                        }
                                                                                                                                                        else {
                                                                                                                                                            validate11.errors =
                                                                                                                                                                [
                                                                                                                                                                    {
                                                                                                                                                                        instancePath: instancePath,
                                                                                                                                                                        schemaPath: '#/allOf/35/then/type',
                                                                                                                                                                        keyword: 'type',
                                                                                                                                                                        params: {
                                                                                                                                                                            type: 'object',
                                                                                                                                                                        },
                                                                                                                                                                        message: 'must be object',
                                                                                                                                                                    },
                                                                                                                                                                ];
                                                                                                                                                            return false;
                                                                                                                                                        }
                                                                                                                                                    }
                                                                                                                                                    var _valid35 = _errs1136 ===
                                                                                                                                                        errors;
                                                                                                                                                    valid193 =
                                                                                                                                                        _valid35;
                                                                                                                                                }
                                                                                                                                                if (!valid193) {
                                                                                                                                                    var err107 = {
                                                                                                                                                        instancePath: instancePath,
                                                                                                                                                        schemaPath: '#/allOf/35/if',
                                                                                                                                                        keyword: 'if',
                                                                                                                                                        params: {
                                                                                                                                                            failingKeyword: 'then',
                                                                                                                                                        },
                                                                                                                                                        message: 'must match "then" schema',
                                                                                                                                                    };
                                                                                                                                                    if (vErrors ===
                                                                                                                                                        null) {
                                                                                                                                                        vErrors =
                                                                                                                                                            [
                                                                                                                                                                err107,
                                                                                                                                                            ];
                                                                                                                                                    }
                                                                                                                                                    else {
                                                                                                                                                        vErrors.push(err107);
                                                                                                                                                    }
                                                                                                                                                    errors++;
                                                                                                                                                    validate11.errors =
                                                                                                                                                        vErrors;
                                                                                                                                                    return false;
                                                                                                                                                }
                                                                                                                                                var valid0 = _errs1131 ===
                                                                                                                                                    errors;
                                                                                                                                            }
                                                                                                                                        }
                                                                                                                                    }
                                                                                                                                }
                                                                                                                            }
                                                                                                                        }
                                                                                                                    }
                                                                                                                }
                                                                                                            }
                                                                                                        }
                                                                                                    }
                                                                                                }
                                                                                            }
                                                                                        }
                                                                                    }
                                                                                }
                                                                            }
                                                                        }
                                                                    }
                                                                }
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    if (errors === 0) {
        if (data && typeof data == 'object' && !Array.isArray(data)) {
            var missing106 = void 0;
            if (data.name === undefined && (missing106 = 'name')) {
                validate11.errors = [
                    {
                        instancePath: instancePath,
                        schemaPath: '#/required',
                        keyword: 'required',
                        params: { missingProperty: missing106 },
                        message: "must have required property '" + missing106 + "'",
                    },
                ];
                return false;
            }
            else {
                if (data.name !== undefined) {
                    var data437 = data.name;
                    if (!(data437 === 'connected' ||
                        data437 === 'reconnecting' ||
                        data437 === 'signalReconnecting' ||
                        data437 === 'reconnected' ||
                        data437 === 'disconnected' ||
                        data437 === 'connectionStateChanged' ||
                        data437 === 'mediaDevicesChanged' ||
                        data437 === 'participantConnected' ||
                        data437 === 'participantDisconnected' ||
                        data437 === 'trackPublished' ||
                        data437 === 'trackSubscribed' ||
                        data437 === 'trackSubscriptionFailed' ||
                        data437 === 'trackUnpublished' ||
                        data437 === 'trackUnsubscribed' ||
                        data437 === 'trackMuted' ||
                        data437 === 'trackUnmuted' ||
                        data437 === 'localTrackPublished' ||
                        data437 === 'localTrackUnpublished' ||
                        data437 === 'localAudioSilenceDetected' ||
                        data437 === 'participantMetadataChanged' ||
                        data437 === 'participantNameChanged' ||
                        data437 === 'participantPermissionsChanged' ||
                        data437 === 'participantAttributesChanged' ||
                        data437 === 'activeSpeakersChanged' ||
                        data437 === 'roomMetadataChanged' ||
                        data437 === 'connectionQualityChanged' ||
                        data437 === 'mediaDevicesError' ||
                        data437 === 'trackStreamStateChanged' ||
                        data437 === 'trackSubscriptionPermissionChanged' ||
                        data437 === 'trackSubscriptionStatusChanged' ||
                        data437 === 'audioPlaybackChanged' ||
                        data437 === 'videoPlaybackChanged' ||
                        data437 === 'signalConnected' ||
                        data437 === 'recordingStatusChanged' ||
                        data437 === 'activeDeviceChanged' ||
                        data437 === 'localTrackSubscribed')) {
                        validate11.errors = [
                            {
                                instancePath: instancePath + '/name',
                                schemaPath: '#/properties/name/enum',
                                keyword: 'enum',
                                params: { allowedValues: schema28.properties.name.enum },
                                message: 'must be equal to one of the allowed values',
                            },
                        ];
                        return false;
                    }
                }
            }
        }
        else {
            validate11.errors = [
                {
                    instancePath: instancePath,
                    schemaPath: '#/type',
                    keyword: 'type',
                    params: { type: 'object' },
                    message: 'must be object',
                },
            ];
            return false;
        }
    }
    validate11.errors = vErrors;
    return errors === 0;
}
function validate10(data, _a) {
    var _b = _a === void 0 ? {} : _a, _c = _b.instancePath, instancePath = _c === void 0 ? '' : _c, parentData = _b.parentData, parentDataProperty = _b.parentDataProperty, _d = _b.rootData, rootData = _d === void 0 ? data : _d;
    var vErrors = null;
    var errors = 0;
    if (!validate11(data, {
        instancePath: instancePath,
        parentData: parentData,
        parentDataProperty: parentDataProperty,
        rootData: rootData,
    })) {
        vErrors =
            vErrors === null ? validate11.errors : vErrors.concat(validate11.errors);
        errors = vErrors.length;
    }
    validate10.errors = vErrors;
    return errors === 0;
}
export default validate10;
