import { gql, useApolloClient, useLazyQuery } from '@apollo/client';
import { useCallback, useEffect, useState } from 'react';
import { useStepContext } from '../../../../utils/stepContext';
import { usePusherAggregationUpdated, } from '../../usePusherAggregationUpdated';
import { STEP_AGGREGATE_CARD_SORT_NODE_FRAGMENT } from '../../useQueryUserTestAggregates';
const GET_CARDSORT_STATS = gql `
  ${STEP_AGGREGATE_CARD_SORT_NODE_FRAGMENT}
  query getCardSortStats($uuid: String!) {
    userTestStepStats(uuid: $uuid, aggregatedOn: COMPLETE_AND_INCOMPLETE) {
      ...stepAggregateCardsortNode
    }
  }
`;
export function useWaitOnCardSortStatsUpdate({ userTestUUID, categoryUUID, setCategoryUUID, operation, }) {
    const [isWaiting, setIsWaiting] = useState(false);
    const client = useApolloClient();
    const { backingProjectPk } = useStepContext();
    const [getStats, { data, networkStatus, error }] = useLazyQuery(GET_CARDSORT_STATS, {
        variables: { uuid: userTestUUID },
        fetchPolicy: 'network-only',
        onCompleted(newData) {
            const newNode = getCardSortNode(newData.userTestStepStats);
            client.writeFragment({
                id: `StepAggregateCardsortNode:${newNode === null || newNode === void 0 ? void 0 : newNode.pk}`,
                fragment: STEP_AGGREGATE_CARD_SORT_NODE_FRAGMENT,
                data: newNode,
            });
            // Note: we need to refetch the category aliases *after* updating the
            // card sort metrics because otherwise the pusher subscription will be
            // cancelled by the upper component re-rendering.
            client.refetchQueries({
                include: ['cardsortCategoryAliases'],
            });
            resetState();
        },
    });
    const resetState = useCallback(() => {
        setCategoryUUID(undefined);
        setIsWaiting(false);
    }, [setCategoryUUID]);
    useEffect(() => {
        if (!isWaiting && categoryUUID) {
            setIsWaiting(true);
        }
    }, [categoryUUID, isWaiting]);
    const updateStatsOnAggregationUpdated = useCallback((eventData) => {
        if (!isWaiting || !categoryUUID)
            return;
        const categoryAliases = Object.values(eventData.content.aggregation).flat();
        const categoryMetricExists = categoryAliases.some((category) => (category === null || category === void 0 ? void 0 : category[2]) === categoryUUID);
        if ((operation === 'create' && categoryMetricExists) ||
            (operation === 'delete' && !categoryMetricExists)) {
            getStats();
        }
    }, [isWaiting, operation, categoryUUID, getStats]);
    usePusherAggregationUpdated(backingProjectPk, updateStatsOnAggregationUpdated);
    return { data, networkStatus, error, isWaiting };
}
function getCardSortNode(userTestStepStats, pk) {
    return userTestStepStats === null || userTestStepStats === void 0 ? void 0 : userTestStepStats.find((stat) => (stat === null || stat === void 0 ? void 0 : stat.__typename) === 'StepAggregateCardsortNode' &&
        (pk === undefined || stat.pk === pk));
}
