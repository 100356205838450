import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { useResponsiveValue } from '@theme-ui/match-media';
import { runInAction } from 'mobx';
import { observer } from 'mobx-react';
import { useEffect } from 'react';
import { useIntl } from 'react-intl';
import styled, { css } from '@marvelapp/styled';
import { Box } from '@marvelapp/ui';
import { getRecordingPermissions, getWholeScreenRecordingRequired, } from '@marvelapp/user-test-creator';
import { useWholeTestRecording } from '../../WholeTestRecordingContext';
import { useRecorderState } from '../../components/Record/RecorderContext';
import { removeScreenPermissionIfOnMobile } from '../../utils';
import { SetupRecording } from '../common/MediaAccess/SetupRecording';
export const SetupWholeTestRecording = observer(function SetupWholeTestRecording() {
    const intl = useIntl();
    const { userTestDoc, resetParticipantRecordings } = useRecorderState();
    const originalPermissions = getRecordingPermissions(userTestDoc);
    const permissions = removeScreenPermissionIfOnMobile(originalPermissions);
    const recording = useWholeTestRecording();
    const isMobile = useResponsiveValue([true, true, false]);
    // Users can land on this slide when resuming a test, so we need to reset
    // the participant recordings and the granted permissions to their default
    // values.
    useEffect(() => {
        resetParticipantRecordings();
    }, [resetParticipantRecordings]);
    if (recording === null)
        return null;
    const { recordingPermissionState, send, startRecording, skipRecording, connectionStatus, } = recording;
    return (_jsx(_Fragment, { children: _jsx(RecordingWrapper, { isMobile: isMobile, "data-testid": "recording-wrapper", children: _jsx(SetupRecording, { withHeading: false, recordEntireScreen: runInAction(() => getWholeScreenRecordingRequired(userTestDoc)), permissions: permissions, state: recordingPermissionState, send: send, isConnecting: connectionStatus === 'connecting', onNext: startRecording, onSkip: skipRecording, nextButtonText: intl.formatMessage({ id: "gc3GmN", defaultMessage: "Start test" }), isRequired: recording.isRequired }) }) }));
});
const RecordingWrapper = styled(Box) `
  ${({ isMobile }) => !isMobile &&
    css `
      display: flex;
      flex: 1;
      align-items: center;
    `}

  ${({ isMobile }) => isMobile &&
    css `
      padding-top: 24px;
    `}
`;
